class DialogError extends Error
{
    constructor( errors = null , message = '' )
    {
        super(message);
        Error.captureStackTrace(this, this.constructor);
        this.name = this.constructor.name;
        this.errors = errors ;
    }
}

export default DialogError ;