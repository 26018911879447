import React from 'react'

import moment from 'moment/moment'

import compose  from 'vegas-js-core/src/functors/compose'
import isString from 'vegas-js-core/src/isString'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import initApp from '../../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { Grid } from '@material-ui/core'

import IdentityIcon from '@material-ui/icons/AssignmentInd'

import LabelItem from '../../../components/items/LabelItem'

import ThingVO from '../../../things/Thing'
import PersonVO from '../../../things/Person'

import ThingContainer from '../../containers/ThingContainer'

const styles = () => ({});

class Identity extends ThingContainer
{
    getEntry = init =>
    {
        const { thing } = this.props ;

        let empty = {} ;
        if( init instanceof ThingVO )
        {
            empty = init.toObject() ;
        }
        else if( init )
        {
            empty = { ...init } ;
        }

        const value = new PersonVO(empty) ;
        value.subjectOf = thing ;
        return value ;
    };

    getContent = () =>
    {
        const { lang, thing } = this.props;
        if(thing instanceof PersonVO)
        {
            let {
                name,
                alternateName,
                familyName,
                gender,
                givenName,
                honorificPrefix,
                honorificSuffix
            }
            = thing ;

            const locale = this.getLocale();

            const { labels } = locale ;

            if( gender instanceof ThingVO )
            {
                gender = gender.getLocaleName(lang) ;
            }
            else
            {
                gender = null ;
            }

            if( honorificPrefix instanceof ThingVO )
            {
                honorificPrefix = honorificPrefix.getLocaleName(lang) ;
            }
            else
            {
                honorificPrefix = null ;
            }

            return (
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={6}>
                        { this.getLabelItem( name , labels.name )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        { this.getLabelItem( alternateName , labels.alternateName , false )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        { this.getLabelItem( givenName , labels.givenName )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        { this.getLabelItem( familyName , labels.familyName )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        { this.getLabelItem( honorificPrefix , labels.prefix )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        { this.getLabelItem( honorificSuffix , labels.suffix )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        { this.getLabelItem( gender , labels.gender )}
                    </Grid>
                </Grid>
            );

        }else{
            console.log(this, " thing is not an instance of Person ");
            return null;
        }
    };

    getLabelItem = ( label , title , upperFirst = true ) =>
    {
        const locale = this.getLocale();
        if( locale )
        {
            let empty = null ;
            const { labels } = locale ;
            if( labels )
            {
                const { empty:e } = labels ;
                empty = e ;
            }
            const flag = isString(label) && (label !== '') ;
            if( upperFirst )
            {
                label = ucFirst(label) ;
                if( isString( title ) )
                {
                    title = ucFirst(title);
                }
            }
            return <LabelItem key={'item_'+title} title={title} enabled={flag}>{ flag ? label : empty }</LabelItem>
        }

        return null ;
    };

    getLocale = () => this.props.locale.things.person.identity ;

    onEdit = ( item ) =>
    {
        const { onChange, thing } = this.props ;
        if( item && thing instanceof ThingVO )
        {
            const {
                name ,
                alternateName,
                givenName,
                familyName,
                gender,
                honorificPrefix,
                honorificSuffix,
            } = item ;

            thing.alternateName   = alternateName;
            thing.gender          = gender;
            thing.givenName       = givenName;
            thing.familyName      = familyName;
            thing.honorificPrefix = honorificPrefix ;
            thing.honorificSuffix = honorificSuffix ;
            thing.modified        = moment(new Date()).toISOString();
            thing.name            = name ;

            thing.populate();

            if( this._mounted )
            {
                this.forceUpdate() ;
            }

            if( onChange instanceof Function )
            {
                onChange(thing);
            }
        }
    };
}

Identity.defaultProps =
{
    ...ThingContainer.defaultProps,
    icon       : <IdentityIcon/>,
    optionMode : 'edit'
};

Identity.propTypes =
{
    ...ThingContainer.propTypes
};

export default compose( withStyles( styles ) , withRouter , initApp )( Identity ) ;
