
import activities from './stage/activities'
import websites from './websites'

import Form from '../../../display/forms/FormElements'

import api from '../../../configs/api'

import Place from '../../../things/Place'
import Thing from '../../../things/Thing'
import Word  from '../../../things/Word'

const elements =
[
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'name',
            key          : 'name',
            label        : 'Nom',
            autoFocus    : false,
            fullWidth    : true,
            helperText   : null,
            margin       : 'normal',
            required     : false,
            select       : false,
            autoComplete : 'off' ,
            type         : 'text',
            variant      : 'outlined'
        }
    },
    {
        type       : Form.SEARCH_SELECTOR ,
        path       : api.thesaurus.courses_status ,
        clearLabel : 'Vider' ,
        closeLabel : 'Fermer' ,
        init       :
        {
            id         : 'status',
            key        : 'status',
            label      : 'État du point d\'intérêt',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            required   : true,
            type       : 'search',
            variant    : 'outlined'
        },
        inputProps :
        {
            label : 'État du point d\'intérêt'
        },
        limit  : 0,
        logics :
        {
            disabledSuggestions : props =>
            {
                const { rel } = props ;
                let suggestions = [] ;
                if( rel instanceof Thing )
                {
                    const { status } = rel ;
                    if( status instanceof Word )
                    {
                        suggestions.push( status ) ;
                    }
                }
                return suggestions ;
            }
        }
    },
    {
        type       : Form.SEARCH_SELECTOR ,
        clazz      : Place,
        path       : api.places.url ,
        clearLabel : 'Vider' ,
        closeLabel : 'Fermer' ,
        init       :
        {
            id         : 'location',
            key        : 'location',
            label      : 'Localisation',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            required   : true,
            type       : 'search',
            variant    : 'outlined'
        },
        inputProps :
        {
            label : 'Lieu...'
        }
    }
];


const stage =
{
    // -------- dependencies

    activities,
    websites,

    // -------- settings

    edit :
    {
        elements,
        agree       : 'Sauver' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour modifier ce Point d\'intérêt.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...' ,
        success     : 'Mise à jour réussie!' ,
        title       : 'Modifier le Point d\'intérêt ' ,
    },
    labels :
    {
        empty    : '----' ,
        location : 'Localisation',
        name     : 'Nom' ,
        status   : 'État du point d\'intérêt'
    },
    title    : 'Point d\'intérêt' ,
    tooltips :
    {
        add    : 'Ajouter',
        edit   : 'Modifier',
        remove : 'Supprimer'
    }
};

export default stage ;
