
const sectorReview =
{
    rows :
    {
        numAttendee : 'Effectif' ,
        capacity    : 'Capacité'
    }
};

export default sectorReview ;