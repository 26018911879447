import compose from 'vegas-js-core/src/functors/compose'

import initApp   from '../../../contexts/app/initApp'
import withSnack from '../../../contexts/snack/withSnack'

import { withRouter } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'

import { Image } from '../medias/Image'

import api from '../../../configs/api'

import PATCH         from '../../../net/PATCH'
import RequestStatus from '../../../net/RequestStatus'

import Answer       from '../../../things/Answer'
import ImageObject  from '../../../things/creativework/media/ImageObject'
import Question     from '../../../things/Question'
import QuestionGame from '../../../things/game/QuestionGame'

import generify from '../../../things/generify'

const styles = () => ({});

class AnswerImage extends Image
{
    getLocale = () => this.props.locale.things.game.answerImage;

    onRemove = this.change ;

    removePrepare = ( item ) =>
    {
        let { thing:answer } = this.props ;
        if( answer )
        {
            let { subjectOf:question } = answer ;
            if( (question instanceof Question) && (item instanceof Answer) )
            {
                let { subjectOf:game } = question ;
                if( game instanceof QuestionGame )
                {
                    game = game.toObject() ;

                    let { quest } = game ;

                    if( (quest instanceof Array) && (quest.length > 0) )
                    {
                        let findQuestion = quest.find( element => element.id === question.id ) ;
                        if( findQuestion )
                        {
                            const { choices } = findQuestion ;
                            if( choices instanceof Array )
                            {
                                let findAnswer = choices.find( element => element.id === answer.id ) ;
                                if( findAnswer )
                                {
                                    findAnswer.image = null ;
                                }
                            }
                        }
                    }

                    quest = JSON.stringify(quest) ;

                    return { quest };
                }
            }
        }
        return null ;
    };

    save = ( media ) =>
    {
        if( media instanceof ImageObject )
        {
            let { uri, thing:answer } = this.props ;
            if( uri && answer )
            {
                let { subjectOf:question } = answer ;
                if( question instanceof Question )
                {
                    let { subjectOf:game } = question ;
                    if( game instanceof QuestionGame  )
                    {
                        game = game.toObject() ;

                        let { quest } = game ;

                        if( (quest instanceof Array) && (quest.length > 0) )
                        {
                            let findQuestion = quest.find( item => item.id === question.id ) ;
                            if( findQuestion )
                            {
                                const { choices } = findQuestion ;
                                if( choices instanceof Array )
                                {
                                    let findAnswer = choices.find( item => item.id === answer.id ) ;
                                    if( findAnswer )
                                    {
                                        findAnswer.image = generify(media,true) ;
                                    }
                                }
                            }
                        }

                        quest = JSON.stringify(quest) ;

                        this.setState({ status:RequestStatus.PROGRESS });

                        this.canceler = PATCH(
                            api.url + uri ,
                            {
                                success : this._success,
                                fail    : this._fail,
                                cancel  : this._cancel,
                                datas   : { quest }
                            }
                        );
                    }
                }
            }
        }
    };

    _success = ( response ) =>
    {
        const { notifySnack, thing } = this.props ;
        if( thing && response )
        {
            const { data } = response ;
            if( data )
            {
                const { result } = data ;
                if( result )
                {
                    this.setState({ status:RequestStatus.NEW } ) ;

                    const locale = this.getLocale() ;
                    if( locale )
                    {
                        const { success } = locale ;
                        if( success && notifySnack )
                        {
                            notifySnack( success , 'success' )
                        }
                    }

                    const { onChange } = this.props ;
                    if( onChange instanceof Function )
                    {
                        onChange( result ) ;
                    }
                }
            }
        }
    };
}

AnswerImage.defaultProps =
{
    ...Image.defaultProps

};

AnswerImage.propTypes =
{
    ...Image.propTypes
};

export default compose( withStyles( styles ) , withRouter , initApp , withSnack )( AnswerImage ) ;
