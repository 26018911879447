import dialogType from '../../../../../../../display/dialogs/dialogType'

import Method from '../../../../../../../net/Method'

const symptoms =
{
    title : 'Symptômes' ,
    edit  :
    {
        agree       : 'Enregistrer' ,
        description : 'Sélectionner des symptômes dans la liste ci-dessous.',
        disagree    : 'Annuler' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...',
        method      : Method.PATCH,
        mock        : false ,
        refresh     : 'Charger',
        success     : 'Mise à jour réussie!' ,
        title       : 'Symptômes' ,
        type        : dialogType.THESAURUS_CHECKER
    },
    tooltips :
    {
        add    : "Ajouter" ,
        edit   : "Modifier" ,
        remove : "Supprimer"
    }
};

export default symptoms ;