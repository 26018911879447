import React, {Fragment} from 'react'

import copy from 'copy-to-clipboard'

import compose  from 'vegas-js-core/src/functors/compose'
import isString from 'vegas-js-core/src/isString'

import initApp   from '../../../../contexts/app/initApp'
import withSnack from '../../../../contexts/snack/withSnack'

import { withRouter } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'

import { IconButton } from '@material-ui/core'

import EditIcon     from '@material-ui/icons/Autorenew'
import FileCopyIcon from '@material-ui/icons/FileCopy'

import LabelItem from '../../../../components/items/LabelItem'

import SnackVariant   from '../../../../components/snackbars/SnackVariant'
import ThingContainer from '../../../containers/ThingContainer'

import OAuthVO from '../../../../vo/OAuth'
import ThingVO from '../../../../things/Thing'

const styles = () => ({});

class Secret extends ThingContainer
{
    getEntry = init =>
    {
        let empty = {} ;
        if( init instanceof ThingVO )
        {
            empty = init.toObject() ;
        }
        else if( init )
        {
            empty = { ...init } ;
        }
        return new OAuthVO(empty);
    };

    getLocale = () => this.props.locale.things.application.oauth.secret;

    getTitleHeader = () =>
    {
        const { header } = this.props ;
        if( header )
        {
            return(
            <Fragment>
                <div className="flex flew-row justify-between ">
                    <div className="flex flex-row">
                        { this.getTitle() }
                    </div>
                    <div className="flex flex-row">
                        { this.getOptions() }
                    </div>
                </div>
            </Fragment>);
        }
        return null ;
    };

    getTitle = () =>
    {
        let { thing } = this.props ;

        const locale = this.getLocale() ;

        const { labels } = locale ;

        let client_secret ;

        if( thing instanceof OAuthVO )
        {
            let {
                client_secret : s
            } = thing ;

            if( isString( s ) )
            {
                client_secret = s ;
            }
        }

        if( isString(client_secret) )
        {
            return (
                <LabelItem enabled={!!(client_secret)} title={labels.client_secret} >
                    <IconButton
                        size='small'
                        onClick   = { () =>
                        {
                            copy( client_secret ) ;
                            if( locale )
                            {
                                if( labels )
                                {
                                    this.notify( labels.copy , SnackVariant.SUCCESS ) ;
                                }
                            }
                        }}
                    >
                        <FileCopyIcon />
                    </IconButton>
                    { isString(client_secret) ? client_secret : labels.empty }
                </LabelItem>) ;
        }
        return null ;
    };

    notify = ( message , variant = SnackVariant.DEFAULT ) =>
    {
        const { notifySnack } = this.props ;
        if( notifySnack )
        {
            notifySnack(message, variant);
        }
    };
}

Secret.defaultProps =
{
    ...ThingContainer.defaultProps ,
    containerProps :
    {
        className : 'w-full',
        elevation : 0
    },
    editIcon   : <EditIcon/>,
    optionMode : 'edit'
};

Secret.propTypes =
{
    ...ThingContainer.propTypes
};

export default compose( withStyles( styles ) , withRouter , initApp , withSnack )( Secret ) ;
