import React from 'react'

import compose from 'vegas-js-core/src/functors/compose'

import isString from 'vegas-js-core/src/isString'

import initApp from '../../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import MeasureIcon from '@material-ui/icons/Straighten'

import ThingChildren , { styles } from '../ThingChildren'

import MeasurementVO from '../../../things/Measurement'
import Measurement   from './Measurement'

import Thing from "../../../things/Thing"

class Measurements extends ThingChildren
{
    createChild = ( child , editable = false ) =>
    {
        if( child )
        {
            const { lang, locale } = this.props ;
            return (
            <Measurement
                editable = { editable }
                lang     = { lang }
                locale   = { locale }
                onClick  = { () => this.openEditDialog(child) }
                thing    = { child }
            />) ;
        }
        return null ;
    };

    getLocale = () => this.props.locale.things.conceptualObject.measurements ;

    prepare = item =>
    {
        let { alternateName, description, value, dimension, unit } = item ;

        const { config : { languages } = {} } = this.props ;

        if( alternateName )
        {
            languages.forEach( lang => {
                if( !isString(alternateName[lang]) )
                {
                    alternateName[lang] = '' ;
                }
            })
        }

        if( description )
        {
            languages.forEach( lang =>
            {
                if( !isString(description[lang]) )
                {
                    description[lang] = '' ;
                }
            })
        }

        if( dimension instanceof Thing )
        {
            dimension = String(dimension.id)
        }
        else
        {
            dimension = null ;
        }

        if( unit instanceof Thing )
        {
            unit = String(unit.id)
        }
        else
        {
            unit = null ;
        }

        return (
        {
            alternateName ,
            description ,
            value,
            dimension,
            unit
        });
    }
}

Measurements.defaultProps =
{
    ...ThingChildren.defaultProps ,
    clazz     : MeasurementVO,
    emptyIcon : null,
    icon      : <MeasureIcon/>,
    member    : 'measurements'
};

Measurements.propTypes =
{
    ...ThingChildren.propTypes
};

export default compose( withStyles( styles ) , withRouter , initApp )( Measurements ) ;
