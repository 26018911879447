import React from 'react'

import PropTypes from 'prop-types'

import { Button } from '@material-ui/core'

import PrintIcon from '@material-ui/icons/Print'

import Printer from '../../components/print/Printer'

import initDialog from './initDialog'

import CoreDialog from '../../components/dialogs/CoreDialog'

const styles = () => (
{
    content :
    {
        overflowX: 'hidden',
        '@media print'  :
        {
            transform      : 'scale(0.9)',
            transformOrigin: 'top'
        },
        '& table' :
        {
            '& th:first-child, & td:first-child':
            {
                paddingLeft: 0 + '!important'
            },
            '& th:last-child, & td:last-child'  :
            {
                paddingRight: 0 + '!important'
            }
        }
    }
});

export class PrintDialog extends CoreDialog
{
    constructor( props )
    {
        super(props) ;
        this.printer = React.createRef();
    }

    getAgreeButton = () =>
    {
        const {
            agreeClassName,
            agreeStyle
        }
        = this.props ;

        return (
        <Printer content = { () => ( this.printer.current ) } >
            <Button
                disabled  = { this.disabled }
                onClick   = { this.agree }
                color     = 'secondary'
                variant   = 'contained'
                className = { agreeClassName }
                style     = { agreeStyle }
            >
                { this.getAgreeIcon() }
                { this.getAgreeLabel() }
            </Button>
        </Printer>);
    };

    getContent = () =>
    {
        const { print } = this.props  ;
        return (
        <div
            className = 'p-16 print:p-0'
            ref       = { this.printer }
        >
            { print }
        </div>) ;
    };

    getLocale = () => this.props.locale.components.dialogs.print ;
}

PrintDialog.defaultProps =
{
    ...CoreDialog.defaultProps,
    agreeIcon : <PrintIcon className='mr-12'/>,
    maxWidth  : 'md',
    icon      : <PrintIcon className='mr-12'/>,
    print     : null,
    useIcon   : true
};

PrintDialog.propTypes =
{
    ...CoreDialog.propTypes,
    print : PropTypes.element
};

export default initDialog({ styles })( PrintDialog ) ;
