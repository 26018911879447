const settings =
{
    helmet :
    {
        title : 'Configurations' ,
        metas : []
    },
    loading : 'Chargement ...' ,
    empty   : 'Aucune configuration disponible' ,
    remove  :
    {
        agree        : 'Supprimer' ,
        description  : 'Souhaitez-vous supprimer le paramètrage:' ,
        descriptionX : 'Souhaitez-vous supprimer les paramétrages :' ,
        disagree     : 'Annuler' ,
        loading      : 'Suppression en cours...' ,
        title        : 'Supprimer ce paramétrage ?',
        titleX       : 'Supprimer des paramétrages ?'
    },
    removeAll :
    {
        agree        : 'Supprimer' ,
        description  : 'Souhaitez-vous supprimer le paramétrage:' ,
        descriptionX : 'Souhaitez-vous supprimer les paramétrages :' ,
        disagree     : 'Annuler' ,
        loading      : 'Suppression en cours...' ,
        title        : 'Supprimer un paramétrage ?' ,
        titleX       : 'Supprimer des paramétrages ?'
    },
    removeItem  : "Supprimer un paramétrage" ,
    removeItemX : "Supprimer {0} paramétrages" ,
    tooltips    :
    {
        add    : 'Ajouter' ,
        remove : 'Supprimer'
    },
    search : 'Rechercher un paramétrage ...' ,
    title  : 'Configurations'
};

export default settings ;
