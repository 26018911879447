import React from 'react'

import LoadingConsumer from './LoadingConsumer'

export const withLoading = Component => React.forwardRef( ( props , ref ) =>
(
    <LoadingConsumer>
        { store => <Component {...props} {...store} ref={ref} /> }
    </LoadingConsumer>
));

export default withLoading ;