import initDialog from '../initDialog'

import { AddDialog } from '../AddDialog'

import Thing from '../../../things/Thing'

import api from '../../../configs/api'

const styles = () => ({});

export class AddStageDialog extends AddDialog
{
    getLocale = () => this.props.locale.dialogs.application.stage.add ;

    prepare = item =>
    {
        const { location, name } = item ;
        return (
        {
            location : location instanceof Thing ? String(location.id) : null ,
            name
        });
    };
}

AddStageDialog.defaultProps =
{
    ...AddDialog.defaultProps,
    uri : api.stages.url
};

AddStageDialog.propTypes =
{
    ...AddDialog.propTypes
};

export default initDialog({ styles })( AddStageDialog ) ;
