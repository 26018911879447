import axios from 'axios'

import notEmpty from 'vegas-js-core/src/strings/notEmpty'

import providers from './providers'

const { CancelToken } = axios ;

const timeout = process.env.REACT_APP_TIMEOUT ;

let timer ;

const DEFAULT_OPTIONS =
{
    cancel   : null   ,
    canceler : null   ,
    delay    : 200    ,
    format   : 'json' ,
    timeout
} ;

export const getCanceler = ( canceler = null ) =>
{
    if( canceler === null )
    {
        canceler = CancelToken.source() ;
    }
    return canceler ;
}

const getOembed = ( url , encodingFormat , options = null ) =>
{
    options = { ...DEFAULT_OPTIONS , ...options } ;

    clearTimeout( timer ) ;

    return new Promise(( resolve , reject ) =>
    {
        const { delay , ...params } = options;
        timer = setTimeout(
            () =>
            {
                if ( notEmpty( url ) )
                {
                    if( notEmpty( encodingFormat ) && providers.hasOwnProperty(encodingFormat) )
                    {
                        let {
                            cancel,
                            canceler,
                            format
                        }
                        = params ;

                        canceler = getCanceler(canceler) ;

                        let uri = providers[encodingFormat] ;
                        if( uri instanceof Function )
                        {
                            uri = uri( url , format ) ;
                        }

                        const request = axios.create(
                        {
                            cancelToken : canceler.token ,
                            timeout,
                        });

                        request
                        .get( uri.toString() )
                        .then( response =>
                        {
                            const { data } = response ;
                            if( data )
                            {
                                resolve( data ) ;
                            }
                            else
                            {
                                reject( 'The response data is null or undefined' ) ;
                            }

                        })
                        .catch( error =>
                        {
                            if( error )
                            {
                                if ( axios.isCancel( error ) )
                                {
                                    if( cancel instanceof Function )
                                    {
                                        cancel( error ) ;
                                    }
                                }

                                const { response } = error ;
                                if( response )
                                {
                                    const { status } = response ;
                                    if( status === 404 )
                                    {
                                        reject( 'Not found' ) ;
                                        return ;
                                    }
                                }

                                reject( 'Unknow error' ) ;
                            }
                        });

                    }
                    else
                    {
                        reject( 'The encodingFormat is not valid.' ) ;
                    }
                }
                else
                {
                    reject( 'The url not must be null or empty.' ) ;
                }
            },
            delay
        )
    });
};

export default getOembed ;
