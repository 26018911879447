const primaryColor = "#9c27b0";
const warningColor = "#ff9800";
const dangerColor  = "#f44336";
const successColor = "#4caf50";
const infoColor    = "#00acc1";
const roseColor    = "#e91e63";
const greyColor    = "#b8c2cc";

export
{
    primaryColor,
    warningColor,
    dangerColor,
    successColor,
    infoColor,
    roseColor,
    greyColor
}