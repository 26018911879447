import React from 'react'

import format from 'vegas-js-core/src/strings/fastformat'

import StepIcon from '@material-ui/icons/PinDrop'

import Form from '../../../../../display/forms/FormElements'

import CourseGame from '../../../../../things/game/CourseGame'

import api from '../../../../../configs/api'

import Course from '../../../../../things/Course'
import Step   from '../../../../../things/Step'
import Thing  from '../../../../../things/Thing'

const elements =
[
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'name',
            key          : 'name',
            label        : 'Nom du jeu',
            autoComplete : 'off' ,
            autoFocus    : true,
            fullWidth    : true,
            helperText   : 'Obligatoire',
            margin       : 'normal',
            required     : true,
            select       : false,
            type         : 'text',
            variant      : 'outlined'
        }
    },
    {
        type  : Form.SEARCH_SELECTOR ,
        path  : props =>
        {
            const { rel } = props ;
            if( rel instanceof Thing )
            {
                const { subjectOf:game } = rel ;
                if( game instanceof CourseGame )
                {
                    const { about } = game ;
                    if( about instanceof Course )
                    {
                        console.log('about => ', about);
                        const { id } = about ;
                        if( id )
                        {
                            return format( api.courses.steps , id ) ;
                        }
                    }
                }
            }
        },
        clazz      : Step ,
        clearLabel : 'Vider' ,
        closeLabel : 'Fermer' ,
        logics     :
        {
            disabledSuggestions : props =>
            {
                const { rel } = props ;
                let suggestions = [] ;
                if( rel )
                {
                    const { subjectOf } = rel ;
                    if( subjectOf instanceof CourseGame )
                    {
                        let { quest } = subjectOf ;
                        if( quest instanceof Array )
                        {
                            quest = quest.map( item =>
                            {
                                if( item )
                                {
                                    const { about } = item ;
                                    if( about instanceof Step )
                                    {
                                        return about ;
                                    }
                                }
                                return null ;
                            }).filter( item => item !== null );

                            if( quest.length > 0 )
                            {
                                suggestions = quest ;
                            }
                        }
                    }
                }
                return suggestions ;
            }
        },
        init :
        {
            id         : 'about',
            key        : 'about',
            label      : 'Étape',
            autoFocus  : false,
            fullWidth  : true,
            helperText : 'Obligatoire',
            margin     : 'normal',
            required   : true,
            type       : 'search',
            variant    : 'outlined'
        },
        inputProps :
        {
            label : 'Sélectionner une étape'
        },
        searchIcon : <StepIcon/>
    }
];

const course =
{
    add :
    {
        elements,
        agree       : 'Créer' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour créer un nouveau jeu.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...' ,
        success     : 'Ajout du nouveau jeu sur l\'étape réussie!' ,
        title       : 'Ajouter un jeu sur une étape',
    },
    remove :
    {
        agree       : 'Supprimer' ,
        description : 'Souhaitez-vous supprimer ce jeu sur l\'étape:' ,
        disagree    : 'Annuler' ,
        loading     : 'Suppression en cours...' ,
        title       : 'Supprimer le jeu sur l\'étape ?'
    },
    unknown  : 'Inconnu' ,
    title    : 'Jeux sur les étapes' ,
    tooltips :
    {
        active     : 'Activer',
        add        : 'Ajouter' ,
        deactivate : 'Désactiver' ,
        edit       : 'Éditer' ,
        remove     : 'Supprimer'
    }
};

export default course ;
