import React from 'react'

import clsx from 'clsx'

import PropTypes  from 'prop-types'

import moment from 'moment/moment'

import compose  from 'vegas-js-core/src/functors/compose'
import isString from 'vegas-js-core/src/isString'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import { withStyles } from '@material-ui/core/styles'

import { Grid } from '@material-ui/core'

import initApp from '../../../../contexts/app/initApp'

import Container from '../../../../components/containers/Container'
import LabelItem from '../../../../components/items/LabelItem'

import SectorEvent  from '../../../../things/livestock/events/SectorHealthEvent'
import Word         from '../../../../things/Word'

const styles = () => (
{
    root :
    {
        padding : 12
    }
});

const half =
{
    md : 6 ,
    sm : 6 ,
    xs : 12
};

class SectorHealthEvent extends Container
{
    render = () =>
    {
        const {
            classes,
            className,
            thing
        } = this.props ;

        if( thing instanceof SectorEvent )
        {
            const { lang } = this.props ;

            const locale = this.getLocale() ;

            let {
                endDate,
                morbidity,
                mortality,
                startDate
            }
            = thing ;

            morbidity = morbidity instanceof Word ? ucFirst(morbidity.getLocaleName(lang)) : locale.empty ;
            mortality = mortality instanceof Word ? ucFirst(mortality.getLocaleName(lang)) : locale.empty ;

            if( isString( endDate ) )
            {
                endDate = moment(endDate).format(locale.datePattern)
            }
            else
            {
                endDate = locale.empty ;
            }

            if( isString( startDate ) )
            {
                startDate = moment(startDate).format(locale.datePattern)
            }
            else
            {
                startDate = locale.empty ;
            }

            return (
            <div className={ clsx(classes.root,className) }>

                <Grid container spacing={4}>
                    <Grid item {...half}>
                        <LabelItem title = { locale.morbidity }>
                            { morbidity }
                        </LabelItem>
                    </Grid>
                    <Grid item {...half}>
                        <LabelItem title = { locale.mortality }>
                            { mortality }
                        </LabelItem>
                    </Grid>
                    <Grid item {...half}>
                        <LabelItem title = { locale.startDate }>
                            { startDate }
                        </LabelItem>
                    </Grid>
                    <Grid item {...half}>
                        <LabelItem title = { locale.endDate }>
                            { endDate }
                        </LabelItem>
                    </Grid>
                </Grid>

            </div>
            );
        }

        return null ;
    };

    getLocale = () => this.props.locale.things.livestock.events.sectorHealthEvent ;
}

SectorHealthEvent.defaultProps =
{
    ...Container.defaultProps,
    editable : false ,
    onClick  : null ,
    thing    : null
};

SectorHealthEvent.propTypes =
{
    ...Container.propTypes,
    editable : PropTypes.bool,
    onClick  : PropTypes.func,
    thing    : PropTypes.object
};

export default compose( withStyles( styles ) , initApp )( SectorHealthEvent ) ;
