import AccessToken from './AccessToken'

const defaultPrefix = process.env.REACT_APP_NAME ;

class TokensManager
{
    constructor( object = null  )
    {
        this.tokens = null ;

        if( object )
        {
            this.set( object , false ) ;
        }
    }

    static ID = '_tokens' ;

    get prefix()
    {
        return this._prefix ;
    }

    set prefix( value )
    {
        const flag = this.stored ;
        if( flag )
        {
            this.dispose() ;
        }
        this._prefix = (value instanceof String || typeof(value) === 'string') ? value : '' ;
        if( flag )
        {
            this.write() ;
        }
    }

    _prefix = defaultPrefix ;

    _updated = true ;

    /**
     * Indicates if the object is stored in the local storage context.
     * @returns {boolean} True if the object is stored.
     */
    get stored()
    {
        return (localStorage.getItem(this._prefix + TokensManager.ID) !== null) ;
    }

    /**
     * Clear the object.
     */
    clear( lock = false )
    {
        this.tokens  = null ;
        return lock === true ? this : this.write();
    }

    /**
     * Unregister the object in the local storage.
     * @param name {string}
     */
    dispose( name )
    {
        if( this.tokens !== null )
        {
            if( this.tokens.hasOwnProperty( name ) )
            {
                delete this.tokens[name] ;
                this.write();
            }
        }
    }

    /**
     * Initialize the object with the current local storage.
     * @param name {string}
     *
     * @return {AccessToken|null}
     */
    read( name )
    {
        if( this._updated === true )
        {
            let object = JSON.parse( localStorage.getItem( this._prefix + TokensManager.ID ) ) ;

            if( object != null )
            {
                for( let prop in object )
                {
                    if( object.hasOwnProperty( prop ) )
                    {
                        object[prop] = new AccessToken( object[prop] ) ;
                    }
                }
            }

            this.tokens = object ;

            this._updated = false ;
        }

        return this.tokens && this.tokens.hasOwnProperty( name ) ? this.tokens[name] : null ;
    }

    /**
     * Sets the object with a generic object and write it on the server.
     */
    set( name , object , lock = false )
    {
        if( this.tokens === null )
        {
            this.tokens = {} ;
        }
        this.tokens[name] = new AccessToken( object , true ) ;

        return (lock === true) ? this : this.write();
    }

    /**
     * Returns the generic object representation of the object.
     * @returns
     */
    toObject()
    {
        return this.tokens ;
    }

    /**
     * Write the object in the local storage with the specific id.
     */
    write()
    {
        localStorage.setItem( this._prefix + TokensManager.ID , JSON.stringify(this.toObject()) ) ;
        this._updated = true ;
        return this ;
    }
}

export { TokensManager } ;

const tokensManager = new TokensManager() ;

export default tokensManager ;