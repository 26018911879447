import React , { Component } from 'react'

import isString from 'vegas-js-core/src/isString'

//import blueGrey from '@material-ui/core/colors/blueGrey'

import PropTypes from 'prop-types'

import MaskedInput from 'react-text-mask'

class ColorInput extends Component
{
    render()
    {
        const {
            inputRef,
            mask,
            placeholderChar,
            onChange,
            upperCase,
            value,
            ...other
        } = this.props;
        
        // style = {{ color : isString(value) && value !== '' ? "#" + value : blueGrey[700] }}
        
        return (
        <MaskedInput
            mask            = { mask }
            ref             = { ref => { inputRef(ref ? ref.inputElement : null); } }
            placeholderChar = { placeholderChar }
            {...other}
            value = { isString(value) && upperCase ? value.toUpperCase() : value }
            onChange = { ( event ) =>
            {
                const { target } = event ;
                if( target )
                {
                    let { value } = target ;
                    if( isString(value) && upperCase )
                    {
                        value = value.toUpperCase() ;
                    }
                    if( onChange instanceof Function )
                    {
                        onChange( { target : { value } } ) ;
                    }
                }
            }}
        />
      );
    }
}

ColorInput.defaultProps =
{
    mask :
    [
        /[A-Fa-f0-9]/,
        /[A-Fa-f0-9]/,
        /[A-Fa-f0-9]/,
        /[A-Fa-f0-9]/,
        /[A-Fa-f0-9]/,
        /[A-Fa-f0-9]/
    ],
    onChange        : null,
    placeholderChar : '\u2000',
    upperCase       : true,
    value           : ''
};

ColorInput.propTypes =
{
    mask            : PropTypes.array,
    onChange        : PropTypes.func,
    placeholderChar : PropTypes.string,
    upperCase       : PropTypes.bool,
    value           : PropTypes.string
};

export default ColorInput ;