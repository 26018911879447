import React from 'react'

import clsx from 'clsx'

import format from 'vegas-js-core/src/strings/fastformat'

import Department               from '../../../display/things/organization/Department'
import Employees                from '../../../display/things/organization/Employees'
import Founder                  from '../../../display/things/organization/Founder'
import Metadatas                from '../../../display/things/Metadatas'
import MemberOf                 from '../../../display/things/organization/MemberOf'
import Members                  from '../../../display/things/organization/Members'
import ParentOrganization       from '../../../display/things/organization/ParentOrganization'
import Providers                from '../../../display/things/organization/Providers'
import RemoveOrganizationDialog from '../../../display/dialogs/remove/RemoveOrganizationDialog'
import SubOrganizations         from '../../../display/things/organization/SubOrganizations'
import ThingSubContainer        from '../../../display/containers/ThingSubContainer'

class Authorities extends ThingSubContainer
{
    render = () =>
    {
        const { thing } = this.props ;
        if( thing )
        {
            const { id } = thing ;

            const {
                className,
                path,
                onRemove,
                style
            } = this.props ;

            let view = (
            <div className="flex-1 flex flex-col space-y-24">

                <Metadatas
                    activable             = { true }
                    deletable             = { true }
                    RemoveDialogComponent = { RemoveOrganizationDialog }
                    onRemove              = { onRemove }
                    thing                 = { thing }
                    uri                   = { format(path.organization,id) }
                />

                <div className='grid grid-cols-1 sm:grid-cols-2 gap-16'>

                    <div>
                        <Founder
                            onChange = { this.change }
                            thing    = { thing }
                            uri      = { path.founder }
                        />
                    </div>

                    <div>
                        <Employees
                            onChange = { this.change }
                            thing    = { thing }
                            uri      = { path.employees }
                        />
                    </div>

                    <div>
                        <SubOrganizations
                            onChange = { this.change }
                            thing    = { thing }
                            uri      = { path.subOrganization }
                        />
                    </div>

                    <div>
                        <ParentOrganization
                            onChange   = { this.change }
                            thing      = { thing }
                            uri        = { path.parentOrganization }
                        />
                    </div>

                    <div>
                        <Members
                            onChange = { this.change }
                            thing    = { thing }
                            uri      = { path.members }
                        />
                    </div>

                    <div>
                        <MemberOf
                            onChange   = { this.change }
                            thing      = { thing }
                            uri        = { path.memberOf }
                        />
                    </div>

                    <div>
                        <Department
                            onChange = { this.change }
                            thing    = { thing }
                            uri      = { path.department }
                        />
                    </div>

                    <div>
                        <Providers
                            onChange = { this.change }
                            thing    = { thing }
                            uri      = { path.providers }
                        />
                    </div>
                </div>
            </div>);

            return (
            <div
                className = { clsx( 'flex-1 p-12' , className ) }
                style     = { style }
            >
                { this.renderWithTransition(view) }
            </div>
            );
        }
        return null ;
    }
}

Authorities.defaultProps =
{
    ...ThingSubContainer.defaultProps
};

Authorities.propTypes =
{
    ...ThingSubContainer.propTypes
};

export default Authorities ;
