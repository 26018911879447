import React from 'react'

import PropTypes  from 'prop-types'

import moment from 'moment/moment'

import { Fade } from '@material-ui/core'

import Thing from '../../things/Thing'

import RoleableContainer from '../../components/containers/RoleableContainer'

class ThingSubContainer extends RoleableContainer
{
    change = ( thing , callback = null ) =>
    {
        if( this._mounted )
        {
            this.forceUpdate( () =>
            {
                const { onChange } = this.props ;
                if( onChange instanceof Function )
                {
                    onChange( thing , callback ) ;
                }
            }) ;
        }
    };

    changeProperty = ( property , isMember = false , callback = null ) => item =>
    {
        const { thing } = this.props ;
        if( thing instanceof Thing )
        {
            thing[property] = isMember ? item[property] : item ;
            thing.modified = moment(new Date()).toISOString();

            if( thing instanceof Thing )
            {
                thing.populate();
            }

            if( this._mounted )
            {
                this.forceUpdate( () =>
                {
                    const { onChange } = this.props ;
                    if( onChange )
                    {
                        onChange( thing , callback ) ;
                    }
                });
            }
        }
    };

    renderWithTransition = content =>
    {
        const { animate } = this.props ;
        if( content && animate )
        {
            const { TransitionComponent, TransitionProps } = this.props ;
            content = (
                <TransitionComponent
                    in            = { true }
                    mountOnEnter  = { true }
                    unmountOnExit = { true }
                    timeout       = { 300  }
                    { ...TransitionProps }
                >
                    { content }
                </TransitionComponent>
            );
        }
        return content ;
    }
}

ThingSubContainer.defaultProps =
{
    ...RoleableContainer.defaultProps,
    animate             : true ,
    animateProps        : null,
    clazz               : null ,
    onChange            : null ,
    onRemove            : null ,
    path                : null ,
    thing               : null ,
    TransitionComponent : Fade ,
    TransitionProps     : {}
};

ThingSubContainer.propTypes =
{
    ...RoleableContainer.propTypes,
    animate             : PropTypes.bool,
    animateProps        : PropTypes.object,
    clazz               : PropTypes.func,
    onChange            : PropTypes.func,
    onRemove            : PropTypes.func,
    path                : PropTypes.object,
    thing               : PropTypes.object.isRequired,
    TransitionComponent : PropTypes.elementType ,
    TransitionProps     : PropTypes.object ,
};

export default ThingSubContainer ;
