import application      from './application'
import article          from './article'
import conceptualObject from './conceptualObject'
import course           from './course'
import discover         from './discover'
import disease          from './disease'
import event            from './event'
import game             from './game'
import livestock        from './livestock'
import observation      from './observation'
import organization     from './organization'
import mediaObject      from './mediaObject'
import place            from './place'
import person           from './person'
import session          from './session'
import stage            from './stage'
import step             from './step'
import team             from './team'
import technician       from './technician'
import thesaurus        from './thesaurus'
import user             from './user'
import veterinarian     from './veterinarian'

export default
{
    application,
    article,
    conceptualObject,
    course,
    discover,
    disease,
    event,
    game,
    livestock,
    mediaObject,
    observation,
    organization,
    place,
    person,
    session,
    stage,
    step,
    team,
    technician,
    thesaurus,
    user,
    veterinarian,
};
