import generify from '../../generify'

import Review from '../../Review'
import Word   from '../../Word'

/**
 * The ambience review.
 * @memberOf things.livestock.reviews
 * @extends things.Review
 */
class AmbienceReview extends Review
{
    /**
     * Creates a new AmbienceReview instance.
     * @constructor
     * @param object The generic object to initialize the object.
     */
    constructor( object = null )
    {
        super() ;
        
        /**
         * Comfort of the animals.
         * @type {Word}
         */
        this.comfort = null ;
        
        /**
         * The Heating and ventilation coupling flag.
         * @type {boolean}
         */
        this.couplingHeatingVentilation = false ;
    
        /**
         * The density Word of the sector.
         * @type {boolean}
         */
        this.density = null ;
        
        /**
         * The heating flag.
         * @type {boolean}
         */
        this.heating = false ;
    
        /**
         * The heating regulation flag.
         * @type {boolean}
         */
        this.heatingRegulation = false ;
    
        /**
         * The regulation tool mastering flag.
         * @type {boolean}
         */
        this.regulationToolMastering = false ;
        
        /**
         * The ventilation type of the sector.
         * @type {Word}
         */
        this.ventilation = null ;
        
        this.set( object ) ;
    }

    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        super.clear() ;
        
        this.comfort     =
        this.density     =
        this.ventilation = null ;
        
        this.couplingHeatingVentilation =
        this.heating                    =
        this.heatingRegulation          =
        this.regulationToolMastering    = false ;
        
        return this ;
    }
    
    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new AmbienceReview( this.toObject() ) ;
    }
    
    /**
     * Populate the object with specific behaviors or types. Launched by default in the constructor and in the set methods.
     * @returns {Thing} The current reference.
     */
    populate()
    {
        let {
            comfort,
            density,
            ventilation
        } = this ;
        
        if( comfort && !(comfort instanceof Word))
        {
            this.comfort = new Word(comfort);
        }
        
        if( density && !(density instanceof Word))
        {
            this.density = new Word(density);
        }
        
        if( ventilation && !(ventilation instanceof Word))
        {
            this.ventilation = new Word(ventilation);
        }
        
        return this ;
    }
    
    /**
     * Returns the generic object representation of the Object.
     * @return {Object} The generic object representation of the Object.
     */
    toObject( full = false )
    {
        let {
            comfort,
            couplingHeatingVentilation,
            ventilation,
            density,
            heating,
            heatingRegulation,
            regulationToolMastering
        } = this ;

        comfort     = generify(comfort) ;
        density     = generify(density) ;
        ventilation = generify(ventilation) ;

        return {
            ...( full && super.toObject() ),
            comfort,
            couplingHeatingVentilation,
            density,
            heating,
            heatingRegulation,
            regulationToolMastering,
            ventilation
        };
    }
    
}

export default AmbienceReview ;