import React , { Fragment } from 'react'

import clean    from 'vegas-js-core/src/objects/clean'
import compose  from 'vegas-js-core/src/functors/compose'
import format   from 'vegas-js-core/src/strings/fastformat'
import isString from 'vegas-js-core/src/isString'

import initApp from '../../../../../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { FaPenSquare } from 'react-icons/fa'
import { FaExclamationCircle } from 'react-icons/fa'

import { Typography } from '@material-ui/core'

import api from '../../../../../../configs/api'

import Factors        from './Factors'
import ThingContainer from '../../../../../containers/ThingContainer'

import generify from '../../../../../../things/generify'

import MedicalRisk         from '../../../../../../things/medical/MedicalRisk'
import AbortionSerieReview from '../../../../../../things/livestock/reviews/AbortionSerieReview'
import Thing               from '../../../../../../things/Thing'

const styles = () => ({});

class Risk extends ThingContainer
{
    change = thing =>
    {
        const { onChange } = this.props ;
        if( onChange instanceof Function )
        {
            onChange( thing ) ;
        }
    };

    getContent = () =>
    {
        let { thing } = this.props;
        if( thing instanceof AbortionSerieReview )
        {
            const {
                risk,
                subjectOf:observation
            } = thing ;

            const {
                lang,
                editUri,
                subjectOf:review
            }
            = this.props ;

            let factorsUri = '' ;
            if( observation )
            {
                const { additionalType } = observation ;
                if( additionalType instanceof Thing )
                {
                    const { id } = additionalType ;
                    factorsUri = format(api.settings.riskFactorsByObservationsTypes, id);
                }
            }

            let text ;
            if( risk instanceof MedicalRisk )
            {
                let { description } = risk ;
                if( description )
                {
                    description = risk.getLocaleDescription(lang) ;

                    if( isString(description) && description !== '' )
                    {
                        text = (
                        <div className='flex flex-row items-center px-16 my-24'>
                            <FaPenSquare size='1.5em' className='mr-16' />
                            <Typography
                                className = 'w-full'
                                variant   = 'body2'
                            >
                                { description }
                            </Typography>
                        </div>);
                    }
                }
            }

            return (
                <Fragment>

                    { text }

                    <Factors
                        editSearchUri = { factorsUri }
                        editUri       = { editUri }
                        onChange      = { this.change }
                        subjectOf     = { review }
                        thing         = { thing }
                    />

                </Fragment>
            )
        }

        return null ;
    };

    getEntry = init =>
    {
        if( init )
        {
            let empty = {} ;

            let { risk } = init ;

            if( risk instanceof Thing )
            {
                empty = risk.toObject() ;
            }
            else if( risk )
            {
                empty = { ...risk } ;
            }

            risk = new MedicalRisk(empty);

            const { subjectOf } = this.props ;
            if( subjectOf )
            {
                risk.subjectOf = subjectOf ;
            }

            return risk ;
        }

        return null ;
    };

    getLocale = () => this.props.locale.things.livestock.reviews.abortionSerie.medical.risk;

    onEdit = ( item ) => this.change( item ) ;

    editPrepare = ( datas ) =>
    {
        if( datas )
        {
            let {
                description,
                factors,
                subjectOf
            } = datas ;

            if( factors )
            {
                factors = generify(factors,true);
            }

            if( subjectOf )
            {
                let { review } = subjectOf ;
                if( review instanceof AbortionSerieReview )
                {
                    review = clean(review.toObject()) ;

                    review.risk = clean(
                    {
                        description,
                        factors
                    });
                    review = JSON.stringify(review) ;
                    return { review } ;
                }
            }
        }
        return null ;
    };
}

Risk.defaultProps =
{
    ...ThingContainer.defaultProps ,
    icon       : <FaExclamationCircle size={20}/> ,
    optionMode : 'none' ,
    titleMode  : 'edit'
};

Risk.propTypes =
{
    ...ThingContainer.propTypes
};

export default compose( withStyles( styles ) , withRouter , initApp )( Risk ) ;
