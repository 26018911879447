const step =
{
    agree    : 'Voir' ,
    disagree : 'Fermer',
    labels :
    {
        geoExist : 'GPS OK',
        geoNone  : 'GPS introuvable',
        location : 'Lieu: {0}',
        next     : 'Suivant' ,
        poi      : 'POI: {0}' ,
        previous : 'Précédent'
    },
    title    : 'Étape',
    tooltips :
    {
        remove : 'Supprimer'
    }
};

export default step ;
