/**
 * The thing setting singleton.
 */
const settings =
{
    application : { settings : {} } ,
    defaultLang : 'en' ,
    lang        : 'en'
};

export default settings ;