import React , { Component , Fragment } from 'react'

import clsx from 'clsx'

import PropTypes from 'prop-types'

import compose from 'vegas-js-core/src/functors/compose'
import format  from 'vegas-js-core/src/strings/fastformat'
import ucFirst from 'vegas-js-core/src/strings/ucFirst'

import initApp from '../../../../contexts/app/initApp'

import { withStyles } from '@material-ui/core/styles'

import { Grid } from '@material-ui/core'

import DashboardIcon from '@material-ui/icons/Dashboard'

import TitleHeader          from '../../../../components/headers/TitleHeader'
import WaterTreatmentReview from './annual/reviews/WaterTreatmentReview'
import WorkplaceReview      from './annual/reviews/WorkplaceReview'

import AnnualHealthReviewVO from '../../../../things/livestock/reviews/AnnualHealthReview'
import WorkplaceReviewVO    from '../../../../things/livestock/reviews/WorkplaceReview'

const styles = () => (
{
    root :
    {
        width : '100%'
    }

});

const full =
{
    xs : 12
};

class AnnualHealthReview extends Component
{
    change = thing =>
    {
        const { onChange } = this.props ;
        if( onChange instanceof Function )
        {
            onChange( thing ) ;
        }
    };

    getLocale = () => this.props.locale.things.livestock.reviews.annual ;

    getPath = () =>
    {
        const { path, thing } = this.props ;
        if( thing )
        {
            const { id } = thing ;
            if( id )
            {
                return format( path.observation , id ) ;
            }
        }
        return null ;
    };

    getSubReview = () =>
    {
        const { thing } = this.props ;
        if( thing )
        {
            const { review } = thing ;
            if( review )
            {
                const { subReview } = review ;
                if( subReview instanceof Array && subReview.length > 0 )
                {
                    const locale = this.getLocale() ;
                    return (
                    <Fragment>
                        <TitleHeader
                            badge     = { subReview.length }
                            className = 'pt-16'
                            icon      = { <DashboardIcon/> }
                            label     = { ucFirst(locale.workplaces) }
                        />
                        <Grid container spacing={4} className="py-32">
                            { subReview.map( this.getWorkplaceReview ) }
                        </Grid>
                    </Fragment>);
                }
            }
        }
        return null ;
    };

    getWaterReviewTreatment = () =>
    {
        const { thing } = this.props ;
        if( thing )
        {
            let subjectOf ;
            let waterTreatment ;

            const { review } = thing ;
            if( review instanceof AnnualHealthReviewVO )
            {
                const { waterTreatment:wt } = subjectOf = review ;
                waterTreatment = wt ;
            }

            return (
            <WaterTreatmentReview
                containerProps = {{ className : "bg-transparent w-full shadow-none p-12 mb-16" }}
                editUri        = { this.getPath() }
                onChange       = { this.change }
                subjectOf      = { subjectOf }
                thing          = { waterTreatment }
            />);
        }
        return null ;
    };

    getWorkplaceReview = ( workplaceReview, index ) =>
    {
        const { thing } = this.props ;
        if( thing )
        {
            const { review } = thing ;
            if( review )
            {
                if( workplaceReview instanceof WorkplaceReviewVO )
                {
                    return (
                    <WorkplaceReview
                        key       = { 'workplace_' + index }
                        onChange  = { this.change }
                        path      = { this.getPath() }
                        subjectOf = { review }
                        thing     = { workplaceReview }
                    />);
                }
            }
        }
        return null ;
    };

    render()
    {
        const {
            classes,
            className,
            style
        }
        = this.props ;

        // console.log( this + " render" , this.props.thing ) ;

        return (
        <div
            className = { clsx( classes.root, className ) }
            style     = { style }
        >
            <Grid container spacing={4}>
                <Grid item {...full}>
                    { this.getWaterReviewTreatment() }
                    { this.getSubReview() }
                </Grid>
            </Grid>
        </div>) ;
    }
}

AnnualHealthReview.defaultProps =
{
    className : null,
    locale    : null,
    onChange  : null,
    path      : null,
    style     : null,
    thing     : null
};

AnnualHealthReview.propTypes =
{
    className : PropTypes.string,
    locale    : PropTypes.object,
    onChange  : PropTypes.func,
    path      : PropTypes.object,
    style     : PropTypes.object,
    thing     : PropTypes.object.isRequired
};

export default compose( withStyles( styles ) , initApp )( AnnualHealthReview ) ;
