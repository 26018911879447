import isString from 'vegas-js-core/src/isString'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import Form from '../../../../display/forms/FormElements'

import Livestock  from '../../../../things/Livestock'
import Technician from '../../../../things/Technician'
import Thing      from '../../../../things/Thing'

import api from '../../../../configs/api'

const elements =
[
    {
        type       : Form.SEARCH_SELECTOR ,
        clazz      : Technician ,
        path       : api.technicians.url ,
        clearable  : false ,
        closeLabel : 'Fermer' ,
        init       :
        {
            id              : 'technician',
            key             : 'technician',
            autoComplete    : 'off' ,
            label           : 'Technicien',
            autoFocus       : true,
            fullWidth       : true,
            helperText      : 'Obligatoire',
            margin          : 'normal',
            required        : true,
            select          : false,
            type            : 'search',
            variant         : 'outlined'
        },
        inputProps :
        {
            label : 'Sélectionner un technicien...'
        },
        logics :
        {
            disabledSuggestions : ( props ) =>
            {
                const { rel } = props ;
                if( rel )
                {
                    const { subjectOf } = rel ;
                    if( subjectOf instanceof Livestock)
                    {
                        const { technicians } = subjectOf;
                        return technicians ;
                    }
                }
                return null ;
            }
        },
        suggestionRef : ( item ) =>
        {
            if( item instanceof Technician )
            {
                const { person } = item ;
                if( person instanceof Thing )
                {
                    return person ;
                }
            }
            return item ;
        },
        valueLabel : ( item , props ) =>
        {
            if( item instanceof Technician )
            {
                const { person } = item ;
                if( person instanceof Thing )
                {
                    return ucFirst(person.getLocaleName(props.lang)) ;
                }
            }
            return item ;
        }
    }
];

const prepare = ( item ) =>
{
    if( item )
    {
        const { veterinarian } = item ;
        return { veterinarian } ;
    }
    return null ;
};

const mapUri = ( item , uri ) =>
{
    if( item )
    {
        const { technician } = item ;
        if( technician instanceof Technician )
        {
            return uri + '/' + technician.id ;
        }
    }
    return uri ;
};

const technicians =
{
    add :
    {
        agree       : 'Ajouter' ,
        disagree    : 'Annuler' ,
        title       : 'Ajouter un technicien' ,
        description : 'Veuillez sélectionner un technicien ci-dessous.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en  cours...' ,
        success     : 'Ajout du nouveau technicien réussi!' ,
        elements,
        prepare,
        mapUri,
    },
    cell :
    {
        identifier : 'N° Ordinal:',
        unknow     : 'Iconnnu'
    } ,
    fail       : 'Données non trouvées...' ,
    loading    : "Chargement ..." ,
    noDataText : 'Aucun résultat' ,
    pagination :
    {
        first    : 'Première' ,
        last     : 'Dernière' ,
        next     : 'Suivante' ,
        pages    : 'Pages' ,
        previous : 'Précédente' ,
        select   : '1 technicien sélectionné' ,
        selectA  : 'Sélectionner' ,
        selectX  : '{0} techniciens sélectionnés' ,
        title    : 'Sélectionner',
        tooltips :
        {
            remove  : 'Supprimer (1)' ,
            removeX : 'Supprimer ({0})' ,
            select  : 'Sélectionner'
        }
    },
    remove :
    {
        agree        : 'Supprimer' ,
        description  : 'Souhaitez-vous supprimer ce technicien:' ,
        descriptionX : 'Souhaitez-vous supprimer les {0} techniciens:' ,
        disagree     : 'Annuler',
        item         :
        {
            text :
            {
                primary : ( element , props ) =>
                {
                    if( element instanceof Technician )
                    {
                        const { person } = element ;
                        if( person instanceof Thing )
                        {
                            return person.getLocaleName(props.lang);
                        }
                    }
                    return 'Inconnu' ;
                },
                secondary : ( element ) =>
                {
                    if( element instanceof Technician )
                    {
                        const { identifier } = element ;
                        if( isString(identifier) )
                        {
                            return technicians.cell.identifier + " " + identifier ;
                        }
                    }
                    return null ;
                }
            }
        } ,
        loading      : 'Suppression en cours...' ,
        title        : 'Supprimer le technicien ?' ,
        titleX       : 'Supprimer des techniciens ?'
    },
    removeAll :
    {
        agree        : 'Supprimer' ,
        description  : 'Souhaitez-vous supprimer le technicien:' ,
        descriptionX : 'Souhaitez-vous supprimer les {0} techniciens:' ,
        disagree     : 'Annuler' ,
        loading      : 'Suppression en cours...' ,
        title        : 'Supprimer un technicien ?' ,
        titleX       : 'Supprimer des techniciens ?'
    },
    removeItem  : "Supprimer un technicien" ,
    removeItemX : "Supprimer {0} techniciens" ,
    search      : 'Rechercher un technicien ...' ,
    title       : 'Techniciens' ,
    tooltips    :
    {
        add    : "Ajouter" ,
        remove : "Supprimer"
    }
};

export default technicians ;
