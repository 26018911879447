import React from 'react'

import isNotNull from 'vegas-js-core/src/isNotNull'

import Icon from '@material-ui/icons/Terrain'

import withDatasList from './withDatasList'

import ThingsList from './ThingsList'

import Livestock     from '../../things/Livestock'
import LivestockCell from '../cells/LivestockCell'

import AddLivestockDialog    from '../dialogs/add/AddLivestockDialog'
import RemoveLivestockDialog from '../dialogs/remove/RemoveLivestockDialog'

class LivestocksList extends ThingsList
{
    getEntry = () =>
    {
        let thing ;

        const { clazz } = this.props ;

        if( clazz )
        {
            thing = new clazz() ;
        }

        let { things } = this.state ;
        if( things instanceof Array && things.length > 0 )
        {
            things = things.map( item => item instanceof Livestock ? item.organization : null ).filter( isNotNull ) ;
            if( things.length > 0 )
            {
                thing.subjectOf = things ;
            }
        }

        return thing ;
    };

    getLocale = () => this.props.locale.display.lists.livestocks ;
}

LivestocksList.defaultProps =
{
    ...ThingsList.defaultProps ,
    AddDialogComponent    : AddLivestockDialog,
    CellComponent         : LivestockCell ,
    clazz                 : Livestock,
    icon                  : <Icon />,
    preferable            : true ,
    queries               : { sort : 'name' , skin : 'full' },
    RemoveDialogComponent : RemoveLivestockDialog
};

LivestocksList.propTypes =
{
    ...ThingsList.propTypes
};

export default withDatasList()( LivestocksList ) ;
