const place =
{
    agree    : 'Voir' ,
    disagree : 'Fermer',
    title    : 'Lieu',
    tooltips :
    {
        remove : 'Supprimer'
    }
};

export default place ;