import React, { Component } from 'react'

import PropTypes from 'prop-types'

import isString from 'vegas-js-core/src/isString'

import withLocale from '../../contexts/i18n/withLocale'

import { withStyles } from '@material-ui/core/styles'

import { Fab as IconButton, Tooltip } from '@material-ui/core'

import grey from '@material-ui/core/colors/grey'

import SearchIcon from '@material-ui/icons/Search'

import Animate from '../../transitions/Animate'

const styles =
{
    button :
    {
        backgroundColor : grey[900] ,
        height          : 44,
        width           : 44,
        '&:focus':
        {
            backgroundColor: grey[800]
        },
        '&:hover':
        {
            backgroundColor: grey[700]
        }
    }
};

class SearchButton extends Component
{
    getLocale = () => this.props.locale.components.buttons.search ;

    render = () =>
    {
        const { visible } = this.props;

        if( !visible )
        {
            return null ;
        }

        let {
            animate,
            className,
            classes,
            color,
            disabled,
            icon,
            onClick,
            placement,
            style
        } = this.props ;

        if( !icon )
        {
            icon = <SearchIcon/> ;
        }

        let button = (
        <IconButton
            className = { classes.button }
            color     = { color }
            disabled  = { disabled }
            onClick   = { onClick }
        >
            { icon }
        </IconButton>);

        if( !disabled )
        {
            const locale = this.getLocale() ;
            if( locale )
            {
                const { tooltip } = locale ;
                if( isString(tooltip) )
                {
                   button = (
                    <Tooltip placement={placement} title={tooltip}>
                        { button }
                    </Tooltip>
                    );
                }
            }
        }

        if( animate )
        {
            button = (
            <Animate delay={300}>
                { button }
            </Animate>)
        }

        return (
        <div className={className} style={style}>
            {button}
        </div>) ;
    };
}

SearchButton.defaultProps =
{
    animate   : true,
    className : null  ,
    color     : 'inherit',
    disabled  : false,
    icon      : <SearchIcon/>,
    locale    : null,
    placement : 'bottom',
    onClick   : null,
    style     : null,
    visible   : true
};

SearchButton.propTypes =
{
    animate   : PropTypes.bool,
    classes   : PropTypes.object.isRequired ,
    className : PropTypes.string,
    color     : PropTypes.oneOf(['default','inherit','primary','secondary']),
    disabled  : PropTypes.bool,
    icon      : PropTypes.element,
    locale    : PropTypes.object,
    onClick   : PropTypes.func,
    placement : PropTypes.oneOf(['bottom-end', 'bottom-start', 'bottom', 'left-end', 'left-start', 'left', 'right-end', 'right-start', 'right', 'top-end', 'top-start', 'top']),
    style     : PropTypes.object,
    visible   : PropTypes.bool
};

export default withStyles( styles )( withLocale(SearchButton) );
