import { AddDialog } from '../../../../dialogs/AddDialog'
import initDialog from '../../../../dialogs/initDialog'

import { prepare } from '../AnalysisMethods'

const styles = () => ({});

export class AddAnalysisMethodDialog extends AddDialog
{
    getLocale = () => this.props.locale.things.medical.analysisMethods.add ;

    prepare = prepare ;
}

AddAnalysisMethodDialog.defaultProps =
{
    ...AddDialog.defaultProps
};

AddAnalysisMethodDialog.propTypes =
{
    ...AddDialog.propTypes
};

export default initDialog({ styles })( AddAnalysisMethodDialog ) ;
