const death =
{
    date      : 'Date: {0}' ,
    empty     : '-----',
    pattern   : 'DD MMMM YYYY' ,
    place     : 'Lieu: {0}' ,
    separator : ' - ' ,
    title     : 'Décès'
};

export default death ;