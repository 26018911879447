import initDialog from '../initDialog'

import { RemoveDialog } from '../RemoveDialog'

const styles = () => ({});

class RemoveThesaurusDialog extends RemoveDialog
{
    getLocale = () => this.props.locale.dialogs.application.thesaurus.remove ;
}

RemoveThesaurusDialog.defaultProps =
{
    ...RemoveDialog.defaultProps,
    useUrl : true
};

RemoveThesaurusDialog.propTypes =
{
    ...RemoveDialog.propTypes
};

export default initDialog({ styles })( RemoveThesaurusDialog );
