const defaultProperties =
{
    closeSearch : () => {} ,
    initSearch  : () => {} ,
    openSearch  : () => {} ,
    resetSearch : () => {} ,
    setSearch   : () => {} ,
    
    facets      : null,
    limit       : parseInt(process.env.REACT_APP_DEFAULT_LIMIT),
    page        : null,
    searchable  : false,
    searchBar   : false,
    searchLabel : null,
    search      : null,
    sort        : null
};

export default defaultProperties;