import application from './quests/application'
import course      from './quests/course'
import question    from './quests/question'

const quests =
{
    application,
    course,
    question
};

export default quests ;