import React , { Component } from 'react'

import { Router } from 'react-router-dom'

import PropTypes from 'prop-types'

import { Helmet } from 'react-helmet'

import { renderRoutes } from "react-router-config"

import ApplicationLayout from './display/layout/ApplicationLayout'

import ConfigProvider   from './contexts/config/ConfigProvider'
import LangProvider     from './contexts/i18n/LangProvider'
import LoadingProvider  from './contexts/loading/LoadingProvider'
import LocaleProvider   from './contexts/i18n/LocaleProvider'
import SearchProvider   from './contexts/search/SearchProvider'
import SelectProvider   from './contexts/select/SelectProvider'
import SettingsProvider from './contexts/settings/SettingsProvider'
import SnackProvider    from './contexts/snack/SnackProvider'
import UserProvider     from './contexts/user/UserProvider'

import history     from './history'
import permissions from './permissions'
import routes      from './routes'
import settings    from './configs/settings'
import themes      from './configs/themes'
import user        from './contexts/user/user'

class Application extends Component
{
    render()
    {
        const {
            config,
            history,
            locale,
            permissions,
            routes,
            settings,
            style,
            themes
        } = this.props;

        const { languages } = locale || {} ;

        const {
            assets,
            helmet,
            homepage,
            lang,
            name,
            themeColor
        } = config ;

        const { icons } = assets ;

        const {
            androidIcon192,
            appleIcon57,
            appleIcon60,
            appleIcon72,
            appleIcon76,
            appleIcon114,
            appleIcon120,
            appleIcon144,
            appleIcon152,
            appleIcon180,
            favicon16,
            favicon32,
            favicon96,
            msIcon144
        } = icons ;

        return (
        <Router history={ history } >
            <div id='application' className="w-full h-full">
                <Helmet { ...helmet } >

                    <title>{ name }</title>

                    <base href={ homepage } />
                    <link rel="canonical" href={ homepage } />

                    <link rel="apple-touch-icon" sizes="57x57"   href={appleIcon57} />
                    <link rel="apple-touch-icon" sizes="60x60"   href={appleIcon60} />
                    <link rel="apple-touch-icon" sizes="72x72"   href={appleIcon72} />
                    <link rel="apple-touch-icon" sizes="76x76"   href={appleIcon76} />
                    <link rel="apple-touch-icon" sizes="114x114" href={appleIcon114} />
                    <link rel="apple-touch-icon" sizes="120x120" href={appleIcon120} />
                    <link rel="apple-touch-icon" sizes="144x144" href={appleIcon144} />
                    <link rel="apple-touch-icon" sizes="152x152" href={appleIcon152} />
                    <link rel="apple-touch-icon" sizes="180x180" href={appleIcon180} />

                    <link rel="icon" type="image/png" sizes="192x192" href={androidIcon192} />

                    <link rel="icon" type="image/png" sizes="16x16"   href={favicon16} />
                    <link rel="icon" type="image/png" sizes="32x32"   href={favicon32} />
                    <link rel="icon" type="image/png" sizes="96x96"   href={favicon96} />

                    <meta name="msapplication-TileColor" content={themeColor} />
                    <meta name="msapplication-TileImage" content={msIcon144} />
                    <meta name="theme-color"             content={themeColor} />

                    <style type="text/css">{`${style}`}</style>
                </Helmet>

                <ConfigProvider
                    config = { config }
                >
                    <LangProvider
                        lang      = { lang }
                        languages = { languages }
                    >
                        <LocaleProvider
                            defaultLang = { lang }
                            i18n        = { locale }
                        >
                            <UserProvider user={ user }>
                                <SettingsProvider
                                    defaultSettings = { settings }
                                    permissions     = { permissions }
                                    themes          = { themes }
                                >
                                    <SearchProvider>
                                        <SelectProvider>
                                            <LoadingProvider>
                                                    <SnackProvider>
                                                        <ApplicationLayout>
                                                            { renderRoutes( routes ) }
                                                        </ApplicationLayout>
                                                    </SnackProvider>
                                            </LoadingProvider>
                                        </SelectProvider>
                                    </SearchProvider>
                                </SettingsProvider>
                            </UserProvider>
                        </LocaleProvider>
                    </LangProvider>
                </ConfigProvider>
            </div>
        </Router>);
    }
}

Application.defaultProps =
{
    history,
    permissions,
    routes,
    settings,
    themes
};

Application.propTypes =
{
    config      : PropTypes.object.isRequired,
    history     : PropTypes.object.isRequired,
    locale      : PropTypes.object.isRequired,
    permissions : PropTypes.object.isRequired,
    routes      : PropTypes.array.isRequired,
    settings    : PropTypes.object.isRequired,
    style       : PropTypes.object.isRequired,
    themes      : PropTypes.object.isRequired
};

export default Application ;
