import generify       from '../things/generify';
import AmbuloSetting  from './application/settings/AmbuloSetting'
import SupportSetting from './application/settings/SupportSetting'
import CreativeWork   from '../things/CreativeWork'
import OAuth          from './OAuth'
import Website        from '../things/Website'
import Word           from '../things/Word'

import populateMedias from '../things/creativework/populateMedias'

/**
 * The Application.
 * @memberOf CreativeWork
 */
class Application extends CreativeWork
{
    /**
     * Creates a new Application instance.
     * @constructor
     * @param object The generic object to initialize the object the first time.
     */
    constructor( object = null )
    {
        super() ;

        /**
         * The audios medias of this organization.
         * @type {Array}
         */
        this.audios = null;

        /**
         * Indicates the number of audios medias of this thing.
         * @type {Array}
         */
        this.numAudios = null;

        /**
         * Indicates the number of videos medias of this thing.
         * @type {Array}
         */
        this.numPhotos = null;

        /**
         * Indicates the number of videos medias of this thing.
         * @type {Array}
         */
        this.numVideos = null;

        /**
         * The OAuth information
         * @type {OAuth}
         */
        this.oAuth = null ;

        /**
         * The photos collection of this organization.
         * @type {Array}
         */
        this.photos = null ;

        /**
         * The setting
         */
        this.setting = null ;

        /**
         * A slogan or motto associated with the item.
         * @type {Object|string}
         */
        this.slogan = null ;

        /**
         * The version of application.
         * @type {string}
         */
        this.version = null ;

        /**
         * The videos medias of this organization.
         * @type {Array}
         */
        this.videos = null;

        /**
         * The websites enumeration of this application.
         * @type {Website|array}
         */
        this.websites = null ;

        this.set( object ) ;
    }

    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        super.clear() ;
        this.audios =
        this.numAudios =
        this.numPhotos =
        this.numVideos =
        this.oAuth =
        this.photos =
        this.setting =
        this.slogan =
        this.version =
        this.videos =
        this.websites = null ;
        return this ;
    }

    /**
     * Returns a shallow copy of the object.
     * @return {Application} a shallow copy of the object.
     */
    clone()
    {
        return new Application( this.toObject() ) ;
    }

    /**
     * Populate the object with specific behaviors or types. Launched by default in the constructor and in the set methods.
     * @returns {Application} The current reference.
     */
    populate()
    {
        super.populate() ;

        populateMedias( this ) ;

        const {
            additionalType,
            oAuth,
            setting,
            websites
        } = this ;

        if( additionalType && !(additionalType instanceof Word))
        {
            this.additionalType = new Word(additionalType) ;
        }

        if( oAuth && !(oAuth instanceof OAuth) )
        {
            this.oAuth = new OAuth(oAuth) ;
        }

        if( setting && additionalType )
        {
            const { alternateName } = additionalType ;
            switch( alternateName )
            {
                case 'ambulo' :
                {
                    this.setting = new AmbuloSetting( setting ) ;
                    break;
                }

                case 'support' :
                {
                    this.setting = new SupportSetting( setting ) ;
                    break;
                }

                default:
                {
                    break;
                }
            }
        }

        if( websites )
        {
            if( websites instanceof Array )
            {
                this.websites = websites.map( item => item instanceof Website ? item : new Website(item) ) ;
            }
        }

        return this ;
    }

    /**
     * Returns the generic object representation of the item.
     * @return {Object} The generic object representation of the item.
     */
    toObject()
    {
        let {
            oAuth,
            audios,
            numAudios,
            numPhotos,
            numVideos,
            photos,
            setting,
            slogan,
            version,
            videos,
            websites
        } = this ;

        audios   = generify(audios);
        websites = generify(websites);
        photos   = generify(photos);
        videos   = generify(videos);

        return {
            ...super.toObject() ,
            audios,
            oAuth,
            numAudios,
            numPhotos,
            numVideos,
            photos,
            setting,
            slogan,
            version,
            videos,
            websites
        }
    }
}

export default Application ;
