import React , { Component } from 'react'

import PropTypes from 'prop-types'

import LangContext from './LangContext'

class LangProvider extends Component
{
    constructor(props)
    {
        super(props);
        const { lang } = props  ;
        this.state =
        {
            lang,
            setLang : this.setLang
        };
    }

    setLang = ( lang ) =>
    {
        const { languages } = this.props ;
        if( languages.indexOf(lang) === -1 )
        {
            lang = this.props.lang ;
        }
        this.setState( { lang } );
    };

    render()
    {
        const { children } = this.props;
        return (
            <LangContext.Provider value={ this.state }>
                {children}
            </LangContext.Provider>
        )
    }
}

LangProvider.propTypes =
{
    lang      : PropTypes.string.isRequired,
    languages : PropTypes.array.isRequired
};

export default LangProvider ;
