import React from 'react'

import { Avatar } from '@material-ui/core'

import MediaObject from '../../things/creativework/MediaObject'

import getMediaIcon from './getMediaIcon'

import { getMediaObjectIconClassName } from '../../configs/mediaColors'

const defaultOptions =
{
    className : 'ml-8 mr-12' ,
    variant   : 'rounded' ,
    style     : null
};

const getMediaAvatar = ( media , options = null ) =>
{
    if( media instanceof MediaObject )
    {
        options = { ...defaultOptions , ...options } ;

        let {
            className ,
            style ,
            variant
        }
        = options ;

        return (
            <Avatar
                className = { getMediaObjectIconClassName( media , className ) }
                style     = { style }
                variant   = { variant }
            >
                { getMediaIcon( media ) }
            </Avatar>
        );
    }
    return null ;
};

export default getMediaAvatar ;
