
const sortSource = ( order , key = 'encodingFormat' ) => ( a , b ) =>
{
    const { [key]:A } = a ;
    const { [key]:B } = b ;

    return order.indexOf( A ) > order.indexOf( B ) ? 1 : -1 ;
}

export default sortSource ;
