import Application from '../vo/Application'
import Thing       from '../things/Thing'

import generify from '../things/generify'

class Session extends Thing
{
    /**
     * Creates a new Session instance.
     * @constructor
     * @param object The generic object to initialize the object the first time.
     */
    constructor( object = null )
    {
        super() ;

        /**
         * The application informations.
         * @type {Application}
         */
        this.app = null ;

        /**
         * The user agent that is acting on behalf of a user, such as a web browser that retrieves, renders and facilitates end user interaction with Web content.
         * @type {string}
         */
        this.agent = null ;

        /** 
         * The expired date of the current activity.
         * @type {string}
         */
        this.expired = null ;

        /**
         * The user ip value.
         * @type {string}
         */
        this.ip = null ;

        /**
         * Indicates if the session is current.
         * @type {null}
         */
        this.isCurrent = null ;

        /**
         * The logout date of the current activity.
         * @type {string}
         */
        this.logout = null ;

        /** 
         * Indicates if the session is revoked.
         * @type {boolean}
         */
        this.revoked = false ;
        
        /** 
         * Indicates if the type of the session.
         * @type {string}
         */
        this.type = false ;
        
        /**
         * The user agent that is acting on behalf of a user, such as a web browser that retrieves, renders and facilitates end user interaction with Web content.
         * @type {string}
         */   
        this.user_agent = null ;

        /**
         * The user reference of this session.
         * @type {User|Object}
         */
        this.user = null ;
        
        this.set( object ) ;
    }

    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        super.clear();
        this.app        =
        this.agent      =
        this.expired    =
        this.ip         =
        this.isCurrent  =
        this.logout     =
        this.revoked    =
        this.type       =
        this.user       =
        this.user_agent = null ;
        return this ;
    }

    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new Session( this.toObject() ) ;
    }

    /**
     * Populate the object with specific behaviors or types. Launched by default in the constructor and in the set methods.
     * @returns {Thing} The current reference.
     */
    populate()
    {
        const { app, user_agent } = this ;

        if( app && !( app instanceof Application) )
        {
            this.app = new Application(app) ;
        }

        if( user_agent )
        {
            this.agent = user_agent ;
        }

        return this ;
    }

    /**
     * Returns the generic object representation of the object.
     * @returns {Object}
     */
    toObject()
    {
        let {
            agent,
            app,
            expired,
            ip,
            isCurrent,
            logout,
            revoked,
            type,
            user,
            user_agent
        } = this ;

        app = generify(app) ;

        return {
            ...super.toObject() ,
            agent,
            app,
            expired,
            ip,
            isCurrent,
            logout,
            revoked,
            type,
            user,
            user_agent
        }
    }
}

export default Session ;