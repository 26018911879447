
import AbortionEvent      from '../events/AbortionEvent'
import AnimalHealthReview from './AnimalHealthReview'
import Workplace          from '../../Workplace'

import generify from '../../generify'

/**
 * An animal abortion review.
 * @memberOf things.livestock.reviews
 * @extends things.livestock.AnimalHealthReview
 */
class AbortionReview extends AnimalHealthReview
{
    /**
     * Creates a new AbortionReview instance.
     * @constructor
     * @param object The generic object to initialize the object.
     */
    constructor( object = null )
    {
        super() ;
    
        /**
         * The date of the sampling.
         * @type {string}
         */
        this.date = null ;
        
        /**
         * The traveled distance.
         * @type {number}
         */
        this.distance = 0 ;
        
        /**
         * The abortion event.
         * @type {AbortionEvent}
         */
        this.event = null ;
        
        this.set( object ) ;
    }

    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        super.clear() ;
        this.distance = 0 ;
        this.date     =
        this.event    = null ;
        return this ;
    }

    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new AbortionReview( this.toObject() ) ;
    }
    
    /**
     * Populate the object with specific behaviors or types. Launched by default in the constructor and in the set methods.
     * @returns {Thing} The current reference.
     */
    populate()
    {
        super.populate() ;
        
        let {
            event,
            subject
        } = this ;
        
        if( event && !(event instanceof AbortionEvent) )
        {
            this.event = new AbortionEvent( event ) ;
            this.event.subjectOf = this ;
        }
        
        if( subject && !(subject instanceof Workplace) )
        {
            this.subject = new Workplace(subject);
        }
        
        return this ;
    }
    /**
     * Returns the generic object representation of the Object.
     * @return {Object} The generic object representation of the Object.
     */
    toObject( full = false )
    {
        let {
            about,
            date,
            distance,
            event,
            subject
        } = this ;

        about   = generify(about) ;
        event   = generify(event,true);
        subject = generify(subject,true);

        return {
            ...super.toObject(full) ,
            about,
            date,
            distance,
            event,
            subject
        };
    }
}

export default AbortionReview ;