import Form from '../../../../display/forms/FormElements'

const elements =
[
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id         : 'name',
            key        : 'name',
            label      : 'Nom',
            autoFocus  : true,
            fullWidth  : true,
            helperText : 'Obligatoire',
            margin     : 'normal',
            required   : true,
            select     : false,
            type       : 'text',
            variant    : 'outlined'
        }
    },
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'identifier',
            key          : 'identifier',
            label        : 'Identifiant',
            autoFocus    : false,
            fullWidth    : true,
            margin       : 'normal',
            required     : true,
            select       : false,
            autoComplete : 'off' ,
            type         : 'text',
            variant      : 'outlined'
        }
    }
];

export default
{
    add :
    {
        elements,
        agree       : 'Créer' ,
        disagree    : 'Annuler' ,
        title       : 'Ajouter une équipe' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour créer une nouvelle fiche.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en  cours...' ,
        success     : 'Ajout du nouvel enregistrement réussi!'
    },
    remove :
    {
        agree        : 'Supprimer' ,
        description  : 'Souhaitez-vous supprimer l\'équipe:' ,
        descriptionX : 'Souhaitez-vous supprimer les {0} équipes:' ,
        disagree     : 'Annuler' ,
        loading      : 'Suppression en cours...' ,
        title        : 'Supprimer cette équipe ?',
        titleX       : 'Supprimer des équipes ?'
    }
};
