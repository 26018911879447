const abortionDeclaration =
{
    authority           : 'Vétérinaire',
    alternativeHeadline : 'Document d’accompagnement de prélèvement',
    datePattern         : 'L',
    empty               : '---' ,
    event               :
    {
        analysis   :
        {
            disease  : 'Maladie' ,
            method   : 'Méthodes d\'analyse',
            sampling : 'Matrices prélevées'
        },
        date       : 'Date de l\'avortement ' ,
        identifier : 'Identifiant individu ',
        period     : 'Stade de gestation ',
        title      : 'Événement d\'avortement'
    },
    headline   : 'Déclaration d\'avortement de ruminant',
    livestock  : 'Propriétaire',
    notes      : 'Penser à coller les étiquettes sur les prélèvements effectués.',
    order      : 'N° ordre : ' ,
    production : 'Production : ',
    review     :
    {
        date          : 'Date : ' ,
        distance      : 'Distance parcourue : ' ,
        distanceUnity : 'Km' ,
        title         : 'Visite et prélèvements'
    },
    separator    : ' - ',
    sign         : 'Nom et signature du vétérinaire :',
    veterinarian : 'Vétérinaire',
    workshop     : 'Atelier'
};

export default abortionDeclaration ;