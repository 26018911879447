import User from './User'

/**
 * Check if the passed-in path is a valid.
 * @param {User} user - The user reference to check.
 * @param {string} path - The path to check.
 * @param {Object} permissions - The permissions to check.
 * @returns {boolean} Indicates if the path is valid.
 */
const checkScopeByPath = ( user, path , permissions ) =>
{
    if( user instanceof User )
    {
        const { scope } = user ;

        if( path.indexOf('/') === 0 )
        {
            path = path.slice(1) ;
        }
        path = path.split('/')[0] ;

        let current = (scope && scope.hasOwnProperty(path)) ? scope[path] : null ;
        let scopes  = permissions && permissions.hasOwnProperty(path) ? permissions[path] : null ;

        if( !current )
        {
            return scopes == null ;
        }

        if( scopes instanceof Array )
        {
            return scopes.indexOf(current) > -1 ;
        }
    }
    return false ;
};

export default checkScopeByPath ;