import React from 'react'

import PropTypes from 'prop-types'

import withi18n from '../../contexts/i18n/withi18n'

import { ThingCell } from './ThingCell'

import AddressLabel from '../labels/AddressLabel'
import PersonLabel  from '../labels/PersonLabel'

import Person from '../../things/Person'

import getHorizontalElements from '../getHorizontalElements'

class TechnicianCell extends ThingCell
{
    getAddress = ( { className = null } = {} ) =>
    {
        const { addressable:visible } = this.props;
        const thing = this.getThing() ;
        if( visible && thing instanceof Person )
        {
            const { address } = thing ;
            if ( address )
            {
                return <AddressLabel className={className} thing={thing} />;
            }
        }
        return null;
    }

    getContent = () =>
    {
        const { thing } = this.props;
        if ( thing )
        {
            const elements = [ 'person' , 'address' ] ;
            return getHorizontalElements( { elements , factory:this } ) ;
        }
        return null;
    };

    getLocale = () => this.props.locale.display.cells.technician;

    getLocaleTitle = () =>
    {
        const person = this.getThing() ;
        if( person instanceof Person )
        {
            return person.getFullNameOrUsername(this.props.lang) ;
        }
        return null ;
    };

    getPerson = ({ className = null } = {} ) =>
    {
        const { person:visible } = this.props;
        const thing = this.getThing();
        if ( visible && thing instanceof Person )
        {
            const locale = this.getLocale() ;
            const { person : { title } = {} } = locale ;
            return <PersonLabel className={className} thing={thing} title={title}  />;
        }
        return null;
    };

    getThing = () =>
    {
        const { thing : { person } = {} } = this.props ;
        return person ;
    };
}

TechnicianCell.defaultProps =
{
    ...ThingCell.defaultProps,
    addressable : true,
    copiable    : false,
    person      : true
};

TechnicianCell.propTypes =
{
    ...ThingCell.propTypes,
    addressable : PropTypes.bool,
    person      : PropTypes.bool
};

export default withi18n(TechnicianCell);
