import list from './list'

const photos =
{
    ...list,
    actions :
    {
        embed  : 'Ajouter une image externe',
        sort   : 'Trier les images' ,
        upload : 'Téléverser une image'
    },
    helmet :
    {
        title : 'Photos' ,
        metas : []
    },
    empty  : 'Aucune photo trouvée',
    search : 'Rechercher une photo ...' ,
    title  : 'Photos'
};

export default photos ;
