const hourSpecification =
{
    labels :
    {
        close        : "Fermé" ,
        defaultTitle : 'Horaire' ,
        endPattern   : 'DD/MM/YYYY' ,
        period       :
        {
            from : "À partir du {0}" ,
            full : "Du {0} au {1}" ,
            to   : "Jusqu'au {0}"
        },
        startPattern : 'DD/MM/YYYY' ,
        time         : 'De {0} à {1}' ,
    }
};

export default hourSpecification;