import api from '../../../../configs/api'

import Form from '../../../../display/forms/FormElements'

import Article from '../../../../things/creativework/Article'

import getExistingSuggestions from '../../../../things/getExistingSuggestions'

const elements =
[
    {
        type  : Form.SEARCH_SELECTOR ,
        path  : api.articles.url ,
        clazz : Article,
        init  :
        {
            id        : 'value',
            key       : 'value',
            label     : 'Article',
            autoFocus : false,
            fullWidth : true,
            margin    : 'normal',
            required  : true,
            type      : 'search',
            variant   : 'outlined'
        },
        disabledSuggestions : getExistingSuggestions({ member:['hasPart','isPartOf'] , clazz:Article , include:true }) ,
        selector : { i18n :
        {
            empty       : 'Aucun article disponible.' ,
            title       : 'Sélectionner un article',
            description : 'Veuillez sélectionner un article ci-dessous.' ,
        }}
    }
];

const hasPart =
{
    add :
    {
        elements,
        agree       : 'Ajouter' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez sélectionner un sous-article en cliquant ci-dessous.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en  cours...' ,
        success     : 'Ajout d\'un nouvel article réussi!' ,
        title       : 'Ajouter un sous-article'
    },
    remove :
    {
        agree       : 'Supprimer' ,
        description : 'Souhaitez-vous supprimer cet article:' ,
        disagree    : 'Annuler' ,
        loading     : 'Suppression en cours...' ,
        title       : 'Supprimer l\'article ?'
    },
    title : 'Sous Articles' ,
    tooltips :
    {
        add    : "Ajouter" ,
        edit   : "Éditer" ,
        remove : "Supprimer"
    }
};

export default hasPart ;