export const AUTO   = 'auto' ;
export const NORMAL = 'normal' ;
export const NONE   = 'none' ;

export const visibilityModes = [ AUTO , NORMAL , NONE ] ;

export default
{
    AUTO,
    NORMAL,
    NONE
}