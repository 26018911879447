import React from 'react'

import PropTypes from 'prop-types'

import format    from 'vegas-js-core/src/strings/fastformat'
import isBoolean from 'vegas-js-core/src/isBoolean'
import isString  from 'vegas-js-core/src/isString'

import { Typography } from '@material-ui/core'

import DialogError from '../../errors/DialogError'

import Form             from '../forms/Form'
import ThesaurusChecker from '../checkers/ThesaurusChecker'
import ThingAvatar      from '../avatars/ThingAvatar'

import PATCH from '../../net/PATCH'
import POST  from '../../net/POST'
import PUT   from '../../net/PUT'

import Method        from '../../net/Method'
import RequestStatus from '../../net/RequestStatus'

import Thing from '../../things/Thing'
import Word  from '../../things/Word'

import dialogType from './dialogType'

import api  from '../../configs/api'
import i18n from '../../locale/fr'

import initDialog from './initDialog'

import CoreDialog from '../../components/dialogs/CoreDialog'

const styles = () => (
{
    content :
    {
        padding : 16
    }
});

export class EditDialog extends CoreDialog
{
    agree = () =>
    {
        let {
            basePath,
            item,
            mapUri,
            method,
            mock,
            prepare,
            uri
        } = this.props ;

        if( !isString(basePath) )
        {
            basePath = api.url ;
        }

        mock    = isBoolean(mock) ? mock : this.mock ;
        prepare = prepare || this.prepare ;

        let datas = null ;
        let path  = basePath + uri ;

        const locale = this.getLocale() ;

        if( locale )
        {
            const {
                mapUri  : map,
                method  : met,
                mock    : flag,
                prepare : prep,
                type,
                useUrl
            } = locale ;

            switch( type )
            {
                case dialogType.THESAURUS_CHECKER:
                {
                    datas = this.state.datas ;
                    break ;
                }

                case dialogType.FORM :
                default :
                {
                    datas = item ;
                }
            }

            if( map instanceof Function )
            {
                mapUri = map ;
            }

            if( met === Method.PATCH || met === Method.PUT || met === Method.POST )
            {
                method = met ;
            }

            if( isBoolean( flag ) )
            {
                mock = flag ;
            }

            if( prep instanceof Function )
            {
                prepare = prep ;
            }

            if( useUrl && item instanceof Thing )
            {
                path = item.url ;
            }
        }

        if( mapUri instanceof Function )
        {
            path = api.url + mapUri( item , uri ) ;
        }

        if( prepare instanceof Function )
        {
            try
            {
                datas = prepare( datas , this.props ) ;
            }
            catch( er )
            {
                if( er instanceof DialogError)
                {
                    this.setState( { errors:er.errors , status : RequestStatus.FAIL } ) ;
                }
                return null ;
            }
        }

        if( mock )
        {
            console.log( this + ' agree' , path , method , datas ) ;
        }
        else
        {
            this.setState( { errors:null , status : RequestStatus.PROGRESS } ) ;
            switch( method )
            {
                case Method.PATCH :
                {
                    this.canceler = PATCH( path, { success : this._success, fail : this._fail, cancel : this._cancel, datas : datas } ) ;
                    break ;
                }
                case Method.POST :
                {
                    this.canceler = POST( path, { success : this._success, fail : this._fail, cancel : this._cancel, datas : datas } ) ;
                    break ;
                }
                default :
                {
                    this.canceler = PUT( path, { success : this._success, fail : this._fail, cancel : this._cancel, datas : datas } ) ;
                }
            }
        }
    };

    getContent = () =>
    {
        let {
            item,
            name,
            uri:url
        } = this.props ;

        const { errors, status } = this.state ;

        const { elements, type, ...rest } = this.getLocale() || {} ;

        const progress = status === RequestStatus.PROGRESS ;

        let content = null ;

        switch( type )
        {
            case dialogType.THESAURUS_CHECKER :
            {
                let {
                    clazz,
                    init,
                    searchUri,
                    sortable
                } = this.props ;

                if( clazz === null )
                {
                    clazz = Word ;
                }

                let {
                    uri,
                    defaultValue,
                    ...locale
                } = rest ;

                if( isString(searchUri) && (searchUri !== '') )
                {
                    url = searchUri ;
                }
                else if( isString(uri) || (uri instanceof Function) )
                {
                    url = uri ;
                }

                if( defaultValue )
                {
                    init = defaultValue ;
                }

                if( init instanceof Function )
                {
                    init = init( item , this.props );
                }

                if( init instanceof Array )
                {
                    init = init.filter( element => element instanceof clazz ) ;
                }
                else
                {
                    init = null ;
                }

                content = (
                    <ThesaurusChecker
                        clazz        = { clazz }
                        defaultValue = { init }
                        disabled     = { progress }
                        locale       = { locale }
                        sortable     = { sortable }
                        uri          = { url }
                        onChange     = { element =>
                        {
                            this.setState( { datas:element } ) ;
                        }}
                    />
                );
                break ;
            }
            case dialogType.FORM :
            default :
            {
                if( elements instanceof Array && elements.length > 0 )
                {
                    content = (
                        <Form
                            name     = { name }
                            disabled = { progress }
                            elements = { elements }
                            errors   = { errors }
                            thing    = { item }
                        />
                    );
                }
            }
        }
        return content ;
    };

    getDescription = () =>
    {
        const { status } = this.state ;

        const locale = this.getLocale() || {} ;

        const {
            description,
            fail,
            loading,
            success
        } = locale ;

        let text ;

        switch( status )
        {
            case RequestStatus.FAIL :
            {
                text = fail ;
                break ;
            }
            case RequestStatus.PROGRESS :
            {
                text = loading ;
                break ;
            }
            case RequestStatus.SUCCESS :
            {
                text = success ;
                break ;
            }
            default :
            {
                text = description ;
            }
        }

        if( isString(text) )
        {
            return <Typography className='py-8' variant='body1'>{ text }</Typography>;
        }

        return null ;
    };

    getIcon = () =>
    {
        let { icon, item } = this.props ;
        if( item instanceof Thing )
        {
            return <ThingAvatar thing={item} className='mr-16' />;
        }
        if( icon instanceof Function )
        {
            icon = icon( this.props ) ;
        }
        return icon ;
    };

    unmount = () =>
    {
        const { status } = this.state ;
        if( status === RequestStatus.PROGRESS && !!(this.canceler) )
        {
            this.canceler.cancel(this + ' cancel') ;
        }
    };

    _cancel = () =>
    {
        this.setState( { errors:null , status : RequestStatus.NEW  } ) ;
    };

    _fail = response =>
    {
        if( response )
        {
            const { data } = response ;
            if( data )
            {
                const { message , status } = data ;

                console.log( this + ' fail' , data ) ;

                if( status === 'error' )
                {
                    this.setState( { errors:data.message , status : RequestStatus.FAIL } ) ;
                    return ;
                }

                if( message !== 'token revoked' )
                {
                    console.log( this + " failed, status:" + response.status + ", message:" + data.message , true );
                }
            }
        }
    };

    _success = response =>
    {
        if( response )
        {
            const { data } = response ;
            if( data )
            {
                const { result } = data ;
                if ( result )
                {
                    const { onChange, redirect, history, uri } = this.props ;

                    let { redirectUri } = this.props ;
                    if( redirect && (result instanceof Thing) )
                    {
                        if( isString(redirectUri) )
                        {
                            redirectUri = format(redirectUri, result.id);
                        }
                        else
                        {
                            redirectUri = uri + result.id ;
                        }
                        history.push ( redirectUri ) ;
                    }
                    else
                    {
                        this.setState( { status : RequestStatus.SUCCESS } ) ;
                        if( onChange instanceof Function )
                        {
                            onChange( result ) ;
                        }
                    }

                    const { closeOnSuccess } = this.props ;
                    if( closeOnSuccess )
                    {
                        this.close() ;
                    }
                }
            }
        }
    };
}

EditDialog.defaultProps =
{
    ...CoreDialog.defaultProps,
    basePath       : null,
    clazz          : null,
    closeOnSuccess : true ,
    init           : null,
    locale         : i18n.components.dialogs.edit,
    mapUri         : null,
    method         : Method.PUT,
    name           : 'edit_dialog' ,
    redirect       : false ,
    redirectUri    : null ,
    searchUri      : null ,
    sortable       : true ,
    uriMode        : 'normal'
};

EditDialog.propTypes =
{
    ...CoreDialog.propTypes,
    basePath       : PropTypes.string,
    clazz          : PropTypes.func,
    closeOnSuccess : PropTypes.bool ,
    init           : PropTypes.oneOfType([PropTypes.func,PropTypes.array]),
    method         : PropTypes.oneOf([Method.PATCH,Method.PUT,Method.POST]),
    mapUri         : PropTypes.func,
    redirect       : PropTypes.bool ,
    redirectUri    : PropTypes.string ,
    searchUri      : PropTypes.string,
    sortable       : PropTypes.bool,
};

export default initDialog({ useLocale:false, styles })( EditDialog ) ;
