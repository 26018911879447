import isNumber from 'vegas-js-core/src/isNumber'
import isString from 'vegas-js-core/src/isString'

import Thing from './Thing'

/**
 * Generates a function to sort two Things by position.
 * @param {object} options
 * @param {boolean} options.invert - Indicates if the sort is descending (true) or ascending (false).
 * @returns {Function} The Function to invoked to sort two things.
 */
const sortByModified = ( { invert = false } = {} ) => ( thing1 , thing2 ) =>
{
    if( thing1 instanceof Thing && thing2 instanceof Thing )
    {
        let { modified:date1 } = thing1 ;
        let { modified:date2 } = thing2 ;

        if( date1 instanceof Date )
        {
            date1 = date1.valueOf() ;
        }
        else if( isString(date1))
        {
            date1 = new Date(date1).valueOf() ;
        }

        if( date2 instanceof Date )
        {
            date2 = date2.valueOf() ;
        }
        else if( isString(date2))
        {
            date2 = new Date(date2).valueOf() ;
        }

        if( isNumber(date1) && isNumber(date2) )
        {
            if( date1 > date2 )
            {
                return invert ? -1 : 1 ;
            }
            else if( date1 < date2 )
            {
                return invert ? 1 : -1 ;
            }
        }
    }
    return 0 ;
};

export default sortByModified ;
