import initDialog from '../initDialog'

import { SelectThingDialog , styles } from '../SelectThingDialog'

import api from '../../../configs/api'

import Stage from '../../../things/Stage'

class SelectStageDialog extends SelectThingDialog
{
    getLocale = () => this.props.locale.dialogs.select.stage ;
}

SelectStageDialog.defaultProps =
{
    ...SelectThingDialog.defaultProps,
    clazz : Stage ,
    uri   : api.stages.url
};

SelectStageDialog.propTypes =
{
    ...SelectThingDialog.propTypes,
};

export default initDialog({ styles , useSearch:false })( SelectStageDialog ) ;
