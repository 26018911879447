import createAgent from './createAgent'
import generify from './generify'

import Thing from './Thing'

/**
 * The most generic kind of creative work, including books, movies, photographs, software programs, etc.
 * @memberOf things
 */
class CreativeWork extends Thing
{
    /**
     * Creates a new CreativeWork instance.
     * @constructor
     * @param object The generic object to initialize the object the first time.
     */
    constructor( object = null )
    {
        super() ;

        /**
         * The subject matter of the content.
         * @type {Object}
         */
        this.about = null ;

        /**
         * A secondary title of the CreativeWork.
         * @type {string}
         */
        this.alternativeHeadline = null ;

        /**
         * An intended audience, i.e. a group for whom something was created.
         * @type {Array}
         */
        this.audience = null ;

        /**
         * An embedded audio object.
         * @type {AudioObject}
         */
        this.audio = null ;

        /**
         * The author of this content or rating.
         * @type {string|Organization|Person|Array}
         */
        this.author = null ;

        /**
         * The location depicted or described in the content. For example, the location in a photograph or painting.
         * @type {Place}
         */
        this.contentLocation = null ;

        /**
         * Official rating of a piece of content—for example,'MPAA PG-13'.
         * @type {string}
         */
        this.contentRating = null ;

        /**
         * A secondary contributor to the CreativeWork or Event.
         * @type {string|Organization|Person|Array}
         */
        this.contributor = null ;

        /**
         * The party holding the legal copyright to the CreativeWork.
         * @type {string}
         */
        this.copyrightHolder = null ;

        /**
         * The year during which the claimed copyright for the CreativeWork was first asserted.
         * @type {string}
         */
        this.copyrightYear = null ;

        /**
         * The date on which the CreativeWork was created or the item was added to a DataFeed.
         * @type {Date|string}
         */
        this.dateCreated = null ;

        /**
         * The date on which the CreativeWork was most recently modified or when the item's entry was modified within a DataFeed.
         * @type {Date|string}
         */
        this.dateModified = null ;

        /**
         * Date of first broadcast/publication.
         * @type {Date|string}
         */
        this.datePublished = null ;

        /**
         * Specifies the Person who edited the CreativeWork.
         * @type {Array}
         */
        this.editor = null ;

        /**
         * A media object that encodes this CreativeWork.
         */
        this.encoding = null ;

        /**
         * Media type typically expressed using a MIME format (see IANA site and MDN reference) e.g. application/zip for a SoftwareApplication binary, audio/mpeg for .mp3 etc.).
         * @type {string}
         */
        this.encodingFormat = null ;

        /**
         * Date the content expires and is no longer useful or available. For example a VideoObject or NewsArticle whose availability or relevance is time-limited, or a ClaimReview fact check whose publisher wants to indicate that it may no longer be relevant (or helpful to highlight) after some date.
         * @type {Date}
         */
        this.expires = null ;

        /**
         * Indicates an item or CreativeWork that is part of this item, or CreativeWork (in some sense).
         * @type {Array}
         */
        this.hasPart = null ;

        /**
         * Headline of the article.
         * @type {string}
         */
        this.headline = null ;

        /**
         * The language of the content or performance or used in an action. Please use one of the language codes from the IETF BCP 47 standard.
         * @type {string}
         */
        this.inLanguage = null ;

        /**
         * A resource that was used in the creation of this resource. This term can be repeated for multiple sources.
         * @type {Array|CreativeWork}
         */
        this.isBasedOn = null ;

        /**
         * Indicates an item or CreativeWork that this item, or CreativeWork (in some sense), is part of.
         * @type {Array}
         */
        this.isPartOf = null ;

        /**
         * Keywords or tags used to describe this content. Multiple entries in a keywords list are typically delimited by commas.
         * @type {string|Array}
         */
        this.keywords = null ;

        /**
         * A license document that applies to this content, typically indicated by URL.
         * @type {string|Array}
         */
        this.license = null ;

        /**
         * The location where the CreativeWork was created, which may not be the same as the location depicted in the CreativeWork.
         * @type {Place}
         */
        this.locationCreated = null ;

        /**
         * Indicates the primary entity described in some page or other CreativeWork.
         * @type {CreativeWork}
         */
        this.mainEntity = null ;

        /**
         * A material that something is made from, e.g. leather, wool, cotton, paper.
         * @type {string}
         */
        this.material = null ;

        /**
         * Indicates that the CreativeWork contains a reference to, but is not necessarily about a concept.
         * @type {Thing}
         */
        this.mentions = null ;

        /**
         * An offer to provide this item
         * @type {Offer|Array}
         */
        this.offers = null ;

        /**
         * The position of an item in a series or sequence of items.
         * @type {number|string}
         */
        this.position = null ;

        /**
         * The person or organization who produced the work.
         * @type {Array}
         */
        this.producer = null ;

        /**
         * The publisher of the creative work.
         * @type {Array}
         */
        this.publisher = null ;

        /**
         * The Event where the CreativeWork was recorded. The CreativeWork may capture all or part of the event.
         * @type {Event}
         */
        this.recordedAt = null ;

        /**
         * The place and time the release was issued, expressed as a PublicationEvent.
         * @type {Event}
         */
        this.releaseEvent = null ;

        /**
         * A review of the item.
         * @type {string|Array}
         */
        this.review = null ;

        /**
         * The Organization on whose behalf the creator was working.
         * @type {Organization}
         */
        this.sourceOrganization = null ;

        /**
         * A person or organization that supports a thing through a pledge, promise, or financial contribution. e.g. a sponsor of a Medical Study or a corporate sponsor of an event.
         * @type {Array}
         */
        this.sponsor = null ;

        /**
         * The "temporal" property can be used in cases where more specific properties (e.g. temporalCoverage, dateCreated, dateModified, datePublished) are not known to be appropriate.
         */
        this.temporal = null ;

        /**
         * The temporalCoverage of a CreativeWork indicates the period that the content applies to, i.e. that it describes, either as a DateTime or as a textual string indicating a time period in ISO 8601 time interval format. In the case of a Dataset it will typically indicate the relevant time period in a precise notation (e.g. for a 2011 census dataset, the year 2011 would be written "2011/2012"). Other forms of content e.g. ScholarlyArticle, Book, TVSeries or TVEpisode may indicate their temporalCoverage in broader terms - textually or via well-known URL. Written works such as books may sometimes have precise temporal coverage too, e.g. a work set in 1939 - 1945 can be indicated in ISO 8601 interval format format via "1939/1945".
         * Open-ended date ranges can be written with ".." in place of the end date. For example, "2015-11/.." indicates a range beginning in November 2015 and with no specified final date. This is tentative and might be updated in future when ISO 8601 is officially updated. Supersedes datasetTimeInterval.
         */
        this.temporalCoverage = null ;

        /**
         * The textual content of this CreativeWork.
         * @type {string}
         */
        this.text = null ;

        /**
         * A thumbnail image relevant to the Thing.
         * @type {string}
         */
        this.thumbnailUrl = null ;

        /**
         * Approximate or typical time it takes to work with or through this learning resource for the typical intended target audience, e.g. 'PT30M', 'PT1H25M'.
         */
        this.timeRequired = null ;

        /**
         * Organization or person who adapts a creative work to different languages, regional differences and technical requirements of a target market, or that translates during some event.
         */
        this.translator = null ;

        /**
         * The typical expected age range, e.g. '7-9', '11-'.
         */
        this.typicalAgeRange = null ;

        /**
         * The version of the CreativeWork embodied by a specified resource.
         * @type {string}
         */
        this.version = null ;

        /**
         * An embedded video object.
         * @type {VideoObject}
         */
        this.video = null ;

        this.set( object ) ;
    }

    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        super.clear() ;
        this.about =
        this.alternativeHeadline =
        this.audience =
        this.audio =
        this.author =
        this.contentLocation =
        this.contentRating =
        this.contributor =
        this.copyrightHolder =
        this.copyrightYear =
        this.dateCreated =
        this.dateModified =
        this.datePublished =
        this.editor =
        this.encoding =
        this.encodingFormat =
        this.expires =
        this.hasPart =
        this.headline =
        this.inLanguage =
        this.isBasedOn =
        this.isPartOf =
        this.keywords =
        this.license =
        this.locationCreated =
        this.mainEntity =
        this.material =
        this.mentions =
        this.offers =
        this.position =
        this.producer =
        this.publisher =
        this.recordedAt =
        this.releaseEvent =
        this.review =
        this.sourceOrganization =
        this.sponsor =
        this.temporal =
        this.temporalCoverage =
        this.thumbnailUrl =
        this.timeRequired =
        this.translator =
        this.typicalAgeRange =
        this.version =
        this.video = null ;
        return this ;
    }

    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new CreativeWork( this.toObject() ) ;
    }

    /**
     * Returns the locale alternative headline of the thing.
     * @param {string} [lang=null] the current lang.
     * @param {string} [defaultLang=null] the default lang.
     * @return {String} The locale headline of the thing.
     */
    getLocaleAlternativeHeadline = this.getLocaleProperty('alternativeHeadline') ;

    /**
     * Returns the locale headline of the thing.
     * @param {string} [lang=null] the current lang.
     * @param {string} [defaultLang=null] the default lang.
     * @return {String} The locale headline of the thing.
     */
    getLocaleHeadline = this.getLocaleProperty('headline') ;

    /**
     * Populate the object with specific behaviors or types. Launched by default in the constructor and in the set methods.
     * @returns {Thing} The current reference.
     */
    populate()
    {
        super.populate() ;

        const {
            // hasPart
            // isPartOf
            producer,
            publisher,
            sponsor
        } = this ;

        if( producer instanceof Array )
        {
            this.producer = producer.map( createAgent ) ;
        }

        if( publisher instanceof Array )
        {
            this.publisher = publisher.map( createAgent ) ;
        }

        if( sponsor instanceof Array )
        {
            this.sponsor = sponsor.map( createAgent ) ;
        }

        return this ;
    }

    /**
     * Returns the generic object representation of the item.
     * @return {Object} The generic object representation of the item.
     */
    toObject()
    {
        let {
            about,
            alternativeHeadline,
            audience,
            audio,
            author,
            contentLocation,
            contentRating,
            contributor,
            copyrightHolder,
            copyrightYear,
            dateCreated,
            dateModified,
            datePublished,
            editor,
            encoding,
            encodingFormat,
            expires,
            hasPart,
            headline,
            inLanguage,
            isBasedOn,
            isPartOf,
            keywords,
            license,
            locationCreated,
            mainEntity,
            material,
            mentions,
            offers,
            position,
            producer,
            publisher,
            recordedAt,
            releaseEvent,
            review,
            sourceOrganization,
            sponsor,
            temporal,
            temporalCoverage,
            thumbnailUrl,
            timeRequired,
            translator,
            typicalAgeRange,
            version,
            video
        
        } = this ;
        
        about    = generify( about    ) ;
        hasPart  = generify( hasPart  ) ;
        isPartOf = generify( isPartOf ) ;

        return {
            ...super.toObject() ,
            about,
            alternativeHeadline,
            audience,
            audio,
            author,
            contentLocation,
            contentRating,
            contributor,
            copyrightHolder,
            copyrightYear,
            dateCreated,
            dateModified,
            datePublished,
            editor,
            encoding,
            encodingFormat,
            expires,
            hasPart,
            headline,
            inLanguage,
            isBasedOn,
            isPartOf,
            keywords,
            license,
            locationCreated,
            mainEntity,
            material,
            mentions,
            offers,
            position,
            producer,
            publisher,
            recordedAt,
            releaseEvent,
            review,
            sourceOrganization,
            sponsor,
            temporal,
            temporalCoverage,
            thumbnailUrl,
            timeRequired,
            translator,
            typicalAgeRange,
            version,
            video
        }
    }
}

export default CreativeWork ;