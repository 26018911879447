import activities        from './activities'
import applications      from './applications'
import articles          from './articles'
import conceptualObjects from './conceptualObjects'
import courses           from './courses'
import diseases          from './diseases'
import events            from './events'
import games             from './games'
import livestocks        from './livestocks'
import mediaObjects      from './mediaObjects'
import observations      from './observations'
import organizations     from './organizations'
import people            from './people'
import places            from './places'
import sessions          from './sessions'
import set               from './set'
import stages            from './stages'
import teams             from './teams'
import technicians       from './technicians'
import thesaurus         from './thesaurus'
import users             from './users'
import veterinarians     from './veterinarians'

export default
{
    activities,
    applications,
    articles,
    conceptualObjects,
    courses,
    diseases,
    events,
    games,
    livestocks,
    mediaObjects,
    observations,
    organizations,
    people,
    places,
    sessions,
    set,
    stages,
    teams,
    technicians,
    thesaurus,
    users,
    veterinarians
};
