import React from 'react'

import PropTypes from 'prop-types'

import compose from 'vegas-js-core/src/functors/compose'
import format  from 'vegas-js-core/src/strings/fastformat'

import initApp from '../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { Divider, Grid } from '@material-ui/core'

import EditLocationIcon from '@material-ui/icons/EditLocation'

import LabelItem from '../../components/items/LabelItem'
import Map       from '../maps/Map'

import GeoCoordinatesVO from '../../things/GeoCoordinates'

import ThingContainer from '../containers/ThingContainer'

const styles = () =>
({
    map :
    {
        height : 200
    }
});

class GeoCoordinates extends ThingContainer
{
    editPrepare = item =>
    {
        if( item instanceof GeoCoordinatesVO )
        {
            const { elevation, latitude, longitude } = item ;
            return(
            {
                elevation,
                latitude,
                longitude
            });
        }
        return null ;
    };

    getEntry = init =>
    {
        let empty ;
        if( init instanceof GeoCoordinatesVO )
        {
            const { elevation, latitude, longitude } = init ;
            empty = { elevation, latitude, longitude };
        }
        else if( init )
        {
            empty = { ...init } ;
        }
        return new GeoCoordinatesVO(empty) ;
    };

    getContent = () =>
    {
        let {
            classes,
            config,
            locatable,
            searchable,
            thing,
            zoom
        } = this.props;

        const locale = this.getLocale() ;

        let elevation, latitude, longitude ;

        if( thing instanceof GeoCoordinatesVO )
        {
            const { elevation:ele , latitude:lat , longitude:lon } = thing ;
            elevation = ele ;
            latitude  = lat ;
            longitude = lon ;
        }

        const { labels } = locale ;
        if( elevation === null || isNaN(elevation) )
        {
             elevation = labels.empty ;
        }
        else
        {
            elevation = format(labels.elevationPattern,elevation)
        }

        return (
            <div>
                <Map
                    { ...config.map }
                    className       = { classes.map }
                    fly             = { true }
                    fullscreenMode  = { true }
                    gpsFixed        = { true }
                    locale          = {{ attribution : "Ooopener" }}
                    //onReady         = { () => console.log( this + ' map ready') }
                    //onSelect        = {  }
                    latitude        = { latitude  }
                    locatable       = { locatable }
                    longitude       = { longitude }
                    marker          = { true }
                    markerDraggable = { false }
                    markerMove      = { ( latitude , longitude ) => console.log( latitude , longitude ) }
                    searchable      = { searchable }
                    zoom            = { zoom }
                />
                <Divider light className="my-16"/>

                <Grid container spacing={4}>
                    <Grid item xs={12} sm={4}>
                        <LabelItem title={labels.latitude}>{ isNaN(latitude) ? labels.empty : latitude }</LabelItem>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <LabelItem title={labels.longitude}>{ isNaN(longitude) ? labels.empty : longitude }</LabelItem>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <LabelItem title={labels.elevation}>
                            { elevation }
                        </LabelItem>
                    </Grid>
               </Grid>
            </div>
        );
    };

    getLocale = () => this.props.locale.things.geo ;
}

GeoCoordinates.defaultProps =
{
    ...ThingContainer.defaultProps,
    icon       : <EditLocationIcon/>,
    locatable  : false,
    optionMode : 'edit',
    searchable : false,
    zoom       : 18
};

GeoCoordinates.propTypes =
{
    ...ThingContainer.propTypes,
    locatable  : PropTypes.bool,
    searchable : PropTypes.bool,
    zoom       : PropTypes.number
};

export default compose( withStyles( styles ) , withRouter , initApp )( GeoCoordinates ) ;
