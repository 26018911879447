import React from 'react'

import compose  from 'vegas-js-core/src/functors/compose'
import isString from 'vegas-js-core/src/isString'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import initApp from '../../../contexts/app/initApp'

import { Link , withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import Divider    from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Paper      from '@material-ui/core/Paper'
import Tooltip    from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'

import DeleteIcon from '@material-ui/icons/Delete'
import PagesIcon  from '@material-ui/icons/Pages'

import {
    IoMdLeaf,
    IoIosCube,
    IoIosWater
}
from 'react-icons/io'

import ThingGrid from '../../grids/ThingGrid'

import PropertyValue from '../../../things/PropertyValue'
import Thing         from '../../../things/Thing'
import Word          from '../../../things/Word'
import Workshop      from '../../../things/Workshop'

import api from '../../../configs/api'

const styles = () => ({});

class Workshops extends ThingGrid
{
    addPrepare = item =>
    {
        const {
            breeding,
            name,
            identifier,
            production,
            water
        } = item ;
        return (
        {
            name       : name ,
            breeding   : breeding   instanceof Thing ? String(breeding.id)   : null ,
            identifier : identifier instanceof Thing ? String(identifier.id) : null ,
            production : production instanceof Thing ? String(production.id) : null ,
            water      : water      instanceof Thing ? String(water.id)      : null ,
        });
    }

    createChild = ( item , index ) =>
    {
        if( item )
        {
            const {
                config,
                lang
            } = this.props ;

            const locale = this.getLocale() ;

            let { breeding } = item ;

            let identifier;
            let avatar ;
            let name ;
            let production;
            let water;

            if (breeding instanceof Word)
            {
                const { image } = breeding ;
                if( isString( image) )
                {
                    avatar = (
                        <img
                            alt       = { breeding.getLocaleName(lang) }
                            className = "mr-16"
                            src       = { image + '?w=' + config.avatar.maxWidth }
                            style     = {{ width:26 , height:'auto' }}
                        />
                    );
                }

                breeding = (
                    <div className='flex flex-row w-full items-center my-4'>
                        <IoMdLeaf className="mr-8 self-center" style={{ fontSize:18 }} />
                        <Typography className="mr-4" variant='body2'>{ ucFirst(locale.cell.breeding) }</Typography>
                        <Typography className='mr-4 font-medium' variant='body2'>
                            {ucFirst( breeding.getLocaleName(lang) ) }
                        </Typography>
                    </div>
                )
            }
            else
            {
                breeding = null ;
            }

            const { identifier:ident } = item ;
            if ( ident instanceof PropertyValue)
            {
                let { additionalType } = ident ;

                if( additionalType instanceof Thing )
                {
                    additionalType = ucFirst(additionalType.getLocaleName(lang)) ;
                }
                else
                {
                    additionalType = null  ;
                }

                identifier = (
                    <div className='flex flex-row items-center my-4 w-full'>
                        <Typography variant='body2' >{additionalType}</Typography>
                        <Typography variant='body2' className='mx-8'>›</Typography>
                        <Typography variant='body2' className='font-medium' >{ucFirst(ident.value)}</Typography>
                    </div>
                )
            }

            name = (
                <div className="flex flew-row justify-between items-center w-full">
                    <div className='flex flex-row w-full items-center'>
                        { avatar }
                        <div className="w-full">
                            <Typography
                                className = 'text-blue-800 font-medium w-full'
                                component = { Link  }
                                to        = {{ pathname : item.url.split(api.url)[1] }}
                                variant   = 'subtitle1'
                            >
                                { ucFirst( item.getLocaleName(lang) ) }
                            </Typography>
                            { identifier }
                         </div>
                    </div>
                    <div className="flex flex-row justify-end items-center">
                        <Tooltip placement='left' title={locale.tooltips.remove}>
                            <IconButton onClick={ () => this.openRemoveDialog(item) }>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
            );

            const { production:prod } = item ;
            if ( prod instanceof Word )
            {
                production = (
                    <div className='flex flex-row items-center my-4'>
                        <IoIosCube className="mr-8 self-center" style={{ fontSize:18 }} />
                        <Typography className="mr-4" variant='body2'>{ucFirst(locale.cell.production)}</Typography>
                        <Typography className='mr-4 font-medium' variant='body2'>
                            {ucFirst(prod.getLocaleName(lang))}
                        </Typography>
                    </div>
                )
            }

            const { water:wat } = item ;
            if ( wat instanceof Word) {
                water = (
                    <div className='flex flex-row items-center my-4'>
                        <IoIosWater className="mr-8 self-center" style={{ fontSize:18 }} />
                        <Typography className="mr-4" variant='body2'>{ucFirst(locale.cell.water)}</Typography>
                        <Typography className='mr-4 font-medium' variant='body2'>
                            { ucFirst(wat.getLocaleName(lang)) }
                        </Typography>
                    </div>
                )
            }

            return (
            <Paper
                className = 'p-20'
                key       = { 'item_' + index }
                elevation = {2}
            >
                { name }
                <Divider light className="my-16"/>
                { breeding }
                { production }
                { water }
            </Paper>
            );
        }
        return null ;
    };

    getLocale = () => this.props.locale.things.livestock.workshops ;

    getThingRef = () => this.props.thing ;
}

Workshops.defaultProps =
{
    ...ThingGrid.defaultProps,
    animate : true ,
    badge   : true ,
    clazz   : Workshop,
    icon    : <PagesIcon/>,
    member  : 'workshops'
};

Workshops.propTypes =
{
    ...ThingGrid.propTypes
};

export default compose( withStyles(styles) , withRouter , initApp )( Workshops );
