import React , { Component } from "react"

import clsx from 'clsx'

import PropTypes from "prop-types"

import format  from 'vegas-js-core/src/strings/fastformat'
import ucFirst from 'vegas-js-core/src/strings/ucFirst'

import { withStyles } from '@material-ui/core/styles'

import { Checkbox, Fab, IconButton, Paper, Tooltip, Typography } from '@material-ui/core'

import Adjust          from '@material-ui/icons/Adjust'
import CheckCircle     from '@material-ui/icons/CheckCircle'
import CloseIcon       from '@material-ui/icons/Close'
import DeleteIcon      from '@material-ui/icons/DeleteSweep'
import PanoramaFishEye from '@material-ui/icons/PanoramaFishEye'

import Animate from '../../transitions/Animate'

import withi18n   from '../../contexts/i18n/withi18n'
import withSelect from '../../contexts/select/withSelect'

const styles = () =>
({
    item :
    {
        display: "inline"
    },
    root :
    {
        alignItems     : 'center',
        display        : 'flex',
        flexDirection  : 'horizontal',
        justifyContent : 'space-between',
        padding        : 8,
        width          : '100%'
    }
});

class SelectBar extends Component
{
    getCloseButton = () =>
    {
        const {
            disabled,
            onClose,
            unselectAll
        } = this.props ;

        let button = (
        <IconButton
            key      = 'select-header-option-close'
            disabled = { disabled }
            size     = 'small'
            onClick  = { () =>
            {
                if( onClose instanceof Function )
                {
                    onClose() ;
                }
                else if( unselectAll instanceof Function )
                {
                    unselectAll() ;
                }
            }}
        >
            <CloseIcon/>
        </IconButton>);

        if( !disabled )
        {
            const locale = this.getLocale() ;
            if( locale )
            {
                const { tooltips } = locale ;
                if( tooltips )
                {
                    const { close } = tooltips ;
                    if( close )
                    {
                        const { placement } = this.props ;
                        button = (
                        <Tooltip
                            key       = 'select-header-tooltip-close'
                            placement = { placement }
                            title     = { close }
                        >
                            { button }
                        </Tooltip>)
                    }
                }
            }
        }

        return button ;
    };

    getDeleteButton = () =>
    {
        const {
            deleteButtonClass,
            deleteButtonStyle,
            disabled,
            placement,
            selectable ,
            selectedItems,
            selectRemove
        }
        = this.props ;

        const locale = this.getLocale() ;

        const { tooltips } = locale ;

        let selectMode = selectable
                      && (selectedItems instanceof Array)
                      && (selectedItems.length > 0) ;

        if( selectMode )
        {
            const count = selectedItems.length ;

            const { remove, removeX, select } = tooltips ;

            let title ;
            if( count === 1 )
            {
                title = format(remove,count) ;
            }
            else if( count > 1 )
            {
                title = format(removeX,count) ;
            }
            else
            {
                title = select ;
            }

            let button = (
            <Fab
                key       = 'select-header-option-delete'
                className = { deleteButtonClass }
                disabled  = { disabled }
                onClick   = { () =>
                {
                    if( selectRemove instanceof Function )
                    {
                        selectRemove() ;
                    }
                }}
                size  = 'small'
                style = { deleteButtonStyle }
            >
                <DeleteIcon/>
            </Fab>);

            if( !disabled && title )
            {
                button = (
                <Tooltip
                    key       = 'select-header-tooltip-delete'
                    placement = { placement }
                    title     = { title }
                >
                    { button }
                </Tooltip>);
            }

            return button;
        }

        return null ;
    };

    getLocale = () => this.props.locale.components.headers.select ;

    getOptions()
    {
        let options = [] ;

        let button = this.getDeleteButton() ;
        if( button )
        {
            options.push(button) ;
        }

        options.push(this.getCloseButton()) ;

        return options ;
    }

    render()
    {
        const {
            selectable,
            selectedItems,
            selectableItems,
        } = this.props ;

        let numItems   = selectableItems instanceof Array ? selectableItems.length : 0 ;
        let isSelected = (selectedItems instanceof Array) && (selectedItems.length > 0) ;

        if( selectable && numItems > 0 && isSelected )
        {
            const {
                classes,
                className,
                disabled,
                elevation,
                placement,
                selectAll,
                square,
                style,
                unselectAll
            } = this.props ;

            const locale = this.getLocale() ;

            let checkbox = null ;

            let title ;

            if ( isSelected )
            {
                const len = selectedItems.length;

                title = len > 1 ? format( locale.selectX, len ) : locale.select ;

                checkbox = (
                    <Checkbox
                            color             = "default"
                            disabled          = { disabled }
                            checkedIcon       = { <CheckCircle/> }
                            icon              = { <PanoramaFishEye/> }
                            indeterminateIcon = { <Adjust/> }
                            onClick           = { event => event.stopPropagation() }
                            onChange          = { ( event ) =>
                            {
                                const { target } = event ;
                                return target.checked ? selectAll() : unselectAll()
                            }}
                            checked={ (numItems > 0) && (len === numItems) }
                            indeterminate={ (len !== numItems) && (len > 0) }
                        />
                );

                if( !disabled )
                {
                    checkbox =
                    (
                        <Tooltip
                            placement = { placement }
                            title     = { locale.tooltips.select }
                        >
                            { checkbox }
                        </Tooltip>
                    )
                }

                checkbox = (
                <div className={ classes.item }>
                    { checkbox }
                </div>);
            }
            else
            {
                title = locale.title ;
            }

            if( title )
            {
                title = (
                <Typography variant='subtitle1'>
                    { ucFirst(title) }
                </Typography>);
            }

            return (
            <Animate animation="transition.expandIn" delay={50} duration={100}>
                <Paper
                    className = { clsx(classes.root,className) }
                    elevation = { elevation }
                    square    = { square }
                    style     = { style }
                    tabIndex  = {-1} // fix material UI warning
                >
                    <div className='flex flex-row items-center'>
                        { checkbox }
                        { title }
                    </div>
                    <div>
                        { this.getOptions() }
                    </div>
                </Paper>
            </Animate>
            );
        }

        return null ;
    }
}

SelectBar.defaultProps =
{
    className         : null,
    deleteButtonClass : 'mr-8 bg-red-700 hover:bg-red text-white',
    deleteButtonStyle : null,
    disabled          : false,
    elevation         : 1 ,
    onClose           : null ,
    placement         : 'bottom',
    onSelectAll       : null ,
    onUnSelectAll     : null ,
    selectable        : true ,
    selectedItems     : null ,
    square            : false,
    style             : null,
    title             : '' ,
    total             : 0
};

SelectBar.propTypes =
{
    classes           : PropTypes.object.isRequired ,
    className         : PropTypes.string,
    deleteButtonClass : PropTypes.string,
    deleteButtonStyle : PropTypes.object,
    disabled          : PropTypes.bool,
    elevation         : PropTypes.number,
    pageOffset        : PropTypes.number ,
    placement         : PropTypes.string,
    limit             : PropTypes.number ,
    lang              : PropTypes.string.isRequired ,
    locale            : PropTypes.object.isRequired ,
    offset            : PropTypes.number ,
    onClose           : PropTypes.func,
    onSelectAll       : PropTypes.func,
    onUnSelectAll     : PropTypes.func,
    selectable        : PropTypes.bool,
    selectedItems     : PropTypes.array,
    square            : PropTypes.bool,
    style             : PropTypes.object,
    total             : PropTypes.number
};

export default withStyles(styles)( withSelect( withi18n(SelectBar) ) );
