import React from 'react'

import PropTypes from 'prop-types'

import withi18n from '../../contexts/i18n/withi18n'

import AddressLabel from '../labels/AddressLabel'
import GeoLabel     from '../labels/GeoLabel'
import Thing        from '../../things/Thing'
import Word         from '../../things/Word'
import WordLabel    from '../labels/WordLabel'

import getHorizontalElements from '../getHorizontalElements'

import { ThingCell } from './ThingCell'

class PlaceCell extends ThingCell
{
    getAddress = ( { className = null } = {} ) =>
    {
        const { addressable:visible } = this.props;
        const thing = this.getThing() ;
        if( visible && thing instanceof Thing )
        {
            const { address } = thing ;
            if ( address )
            {
                return <AddressLabel className={className} thing={thing} />;
            }
        }
        return null;
    };

    getContent = () =>
    {
        const { thing } = this.props;
        if ( thing )
        {
            const elements = [ 'status' , 'address' , 'geo' ] ;
            return getHorizontalElements( { elements , factory:this } ) ;
        }
        return null;
    };

    getLocale = () => this.props.locale.display.cells.place;

    getGeo = ({ className = null } = {} ) =>
    {
        const { geo:visible } = this.props;
        const thing = this.getThing();
        if ( visible && thing instanceof Thing )
        {
            const { geo } = thing;
            if ( geo )
            {
                return <GeoLabel className={className} thing={thing} />;
            }
        }
        return null;
    };

    getStatus = ( { className = null } = {}  ) =>
    {
        const { status: visible } = this.props;
        if (!visible)
        {
            return null;
        }
        const thing = this.getThing();
        if ( thing )
        {
            const { status } = thing;
            if ( status instanceof Word )
            {
                const locale = this.getLocale() ;
                const { status : { title } = {} } = locale ;
                return <WordLabel className={className} thing={status} title={title} /> ;
            }
        }
        return null;
    };

}

PlaceCell.defaultProps =
{
    ...ThingCell.defaultProps,
    addressable : true,
    geo         : true,
    status      : true
};

PlaceCell.propTypes =
{
    ...ThingCell.propTypes,
    addressable : PropTypes.bool,
    geo         : PropTypes.bool,
    status      : PropTypes.bool
};

export default withi18n(PlaceCell) ;
