
import redirect from './oauth/redirect'
import secret   from './oauth/secret'

const oauth =
{
    // dependencies

    redirect,
    secret,
    
    // settings
    
    labels :
    {
        api_url       : 'URL de l\'API',
        authorize_url : 'URL d\'autorisation',
        client_id     : 'Client ID',
        copy          : 'Copié dans le presse papier',
        empty         : '----' ,
        token_url     : 'URL token'
    },
    title    : 'OAuth'
};

export default oauth ;