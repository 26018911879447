import React from 'react'

import PropTypes from 'prop-types'

import compose from 'vegas-js-core/src/functors/compose'
import leading from 'vegas-js-core/src/numbers/leading'

import DialogError from '../../../../../../errors/DialogError'

import initApp from '../../../../../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { Fab } from '@material-ui/core'

import red  from '@material-ui/core/colors/red'

import { FaFlask } from 'react-icons/fa'
import { FaNotesMedical } from 'react-icons/fa'

import api from '../../../../../../configs/api'

import ThingCardChildren , { styles } from '../../../../ThingCardChildren'

import Analysis from './Analysis'

import AbortionEvent   from '../../../../../../things/livestock/events/AbortionEvent'
import Disease         from '../../../../../../things/medical/Disease'
import AbortionReview  from '../../../../../../things/livestock/reviews/AbortionReview'
import MedicalAnalysis from '../../../../../../things/medical/MedicalAnalysis'
import MedicalMethod   from '../../../../../../things/medical/MedicalMethod'
import MedicalSampling from '../../../../../../things/medical/MedicalSampling'
import Word            from '../../../../../../things/Word'

const getURI = ( path, props ) =>
{
    const { thing } = props ;
    if( thing )
    {
        const { subjectOf:review } = thing ;
        if( review )
        {
            const { subjectOf:observation } = review ;
            if( observation )
            {
                const { id } = observation ;
                if( id )
                {
                    return api.observations.url + '/' + id ;
                }
            }
        }
    }
    return null ;
};

class Analyses extends ThingCardChildren
{
    addUri = getURI ;

    createChild = child =>
    {
        return (
        <div>
            <Analysis
                className      = 'mt-8'
                containerProps = {{ className : "bg-transparent w-full shadow-none py-8" }}
                subjectOf      = { this.props.subjectOf }
                thing          = { child }
            />
        </div>) ;
    };

    addPrepare = item =>
    {
        if( item instanceof MedicalAnalysis )
        {
            let { disease } = item ;
            if( disease instanceof Disease )
            {
                const { subjectOf:event } = item ;
                if( event instanceof AbortionEvent )
                {
                    let { subjectOf:review } = event ;
                    if( review instanceof AbortionReview )
                    {
                        let { analysis } = event ;

                        let method ;
                        let sampling ;

                        let { analysisMethod, analysisSampling } = disease ;

                        if( analysisMethod instanceof Array && analysisMethod.length > 0 )
                        {
                            analysisMethod = analysisMethod.filter( element =>
                            {
                                if( element instanceof MedicalMethod )
                                {
                                    return !!(element.mandatory) && (element.additionalType instanceof Word) ;
                                }
                                return false ;
                            }) ;

                            if( analysisMethod instanceof Array )
                            {
                                method = analysisMethod.map( element => element.additionalType ) ;
                            }
                        }

                        if( analysisSampling instanceof Array && analysisSampling.length > 0 )
                        {
                            analysisSampling = analysisSampling.filter( element =>
                            {
                                if( element instanceof MedicalSampling )
                                {
                                    return !!(element.mandatory) && (element.additionalType instanceof Word) ;
                                }
                                return false ;
                            }) ;

                            if( analysisSampling instanceof Array )
                            {
                                sampling = analysisSampling.map( element => element.additionalType ) ;
                            }
                        }

                        item.method   = method ;
                        item.sampling = sampling ;

                        review.event.analysis = [ ...analysis , item.toObject() ] ;
                        review = review.toObject(false);

                        //console.log( this + ' addPrepare', review ) ;

                        return { review : JSON.stringify(review) } ;
                    }
                }
            }
            else
            {
                throw new DialogError( { disease : 'disease is required' } ) ;
            }
        }
        return null ;
    };

    editPrepare = item =>
    {
        if( item instanceof MedicalAnalysis )
        {
            let { id, subjectOf:event } = item ;
            if( event instanceof AbortionEvent )
            {
                let { subjectOf:review } = event ;
                if( review instanceof AbortionReview )
                {
                    const { analysis } = event ;
                    if( analysis instanceof Array )
                    {
                        let position = analysis.findIndex( element => element.id === id ) ;
                        if( position > -1 )
                        {
                            let old = analysis[position];

                            const { disease } = item ;
                            if( disease === null || (old && old.disease && old.disease.id !== disease.id ))
                            {
                                item.method   = null ;
                                item.sampling = null ;
                            }

                            analysis[position] = item.toObject() ;
                        }
                    }

                    review = review.toObject(false);
                    return { review : JSON.stringify(review) } ;
                }
            }

        }
        return null ;
    };

    editUri = getURI ;

    getBottom = this.getCustomCardWithAddButton ;

    getCardLabel = ( child, editable, index ) =>
    {
        const locale = this.getLocale() ;
        if( locale )
        {
            const { labels } = locale ;
            if( labels )
            {
                let { card:label } = labels ;
                if( index >= 0 )
                {
                    label = leading(++index) + ' - ' + label ;
                }
                return label ;
            }
        }
        return null ;
    };

    getLocale = () => this.props.locale.things.livestock.reviews.abortion.medical.analyses ;

    onAdd = this.change ;

    onEdit = this.change ;

    onCardClick = item => () => { this.openEditDialog( item ) ; };

    onRemove = this.change ;

    removePrepare = item =>
    {
        if( item instanceof MedicalAnalysis )
        {
            let { id, subjectOf:event } = item ;
            if( event instanceof AbortionEvent )
            {
                let { analysis, subjectOf:review } = event ;
                if( analysis instanceof Array && analysis.length > 0 )
                {
                    analysis = analysis.filter( element => element.id !== id ) ;
                }
                if( review instanceof AbortionReview )
                {
                    review.event.analysis = analysis ;
                    review = review.toObject(false);
                    return { review : JSON.stringify(review) } ;
                }
            }
        }
        return null ;
    };

    removeUri = getURI ;
}

Analyses.defaultProps =
{
    ...ThingCardChildren.defaultProps,
    cardIcon              : <FaNotesMedical size={20}/> ,
    clazz                 : MedicalAnalysis,
    DeleteButtonComponent : Fab,
    DeleteButtonProps     : {
        size  : 'small',
        style : {
            backgroundColor : 'white',
            color           : red[600],
            '&:hover' :
            {
                backgroundColor : red[900],
                color           : 'white'
            }
        }
    },
    icon       : <FaFlask size={20}/>,
    member     : 'analysis',
    optionMode : 'none',
    path       : null,
    useUUID    : true
};

Analyses.propTypes =
{
    ...ThingCardChildren.propTypes,
    path : PropTypes.string
};

export default compose( withStyles( styles ) , withRouter , initApp )( Analyses ) ;
