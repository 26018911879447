import React from 'react'

import PropTypes  from 'prop-types'

import notEmpty from 'vegas-js-core/src/strings/notEmpty'

import { Checkbox, IconButton, Switch, Tooltip } from '@material-ui/core'

import blueGrey from '@material-ui/core/colors/blueGrey'

import Adjust      from '@material-ui/icons/Adjust'
import CircleIcon  from '@material-ui/icons/PanoramaFishEye'
import CheckCircle from '@material-ui/icons/CheckCircle'
import DeleteIcon  from '@material-ui/icons/Delete'

import RequestStatus  from '../../net/RequestStatus'

import AddButton      from '../../components/buttons/AddButton'
import DatasContainer from '../containers/DatasContainer'
import SortButton     from '../../components/buttons/SortButton'

class DatasListContainer extends DatasContainer
{
    add = () => { this.openAddDialog(); }

    getAddButton = ( { className = null } = {} ) =>
    {
        const { addable } = this.props ;
        if( addable && this.isEditable() )
        {
            const { status } = this.state ;
            const locale = this.getLocale() ;
            return (
                <AddButton
                    animate   = { true }
                    disabled  = { status === RequestStatus.PROGRESS }
                    className = { className }
                    key       = "option-add"
                    locale    = { locale }
                    onClick   = { this.add }
                    size      = "medium"
                />
            );
        }
        return null ;
    };

    getActiveSwitch = item =>
    {
        const { activable } = this.props ;
        if( activable && item && this.isEditable() )
        {
            const { active, id } = item ;

            const locale = this.getLocale() ;

            const { actives, activeStatus } = this.state ;

            const { activeSwitchProps } = this.props;

            const disabled = activeStatus === RequestStatus.PROGRESS && (actives.hasOwnProperty(id)) ;

            let button = (
            <Switch
                className = "mx-4"
                checked   = { active }
                disabled  = { disabled }
                onChange  = { this.activeChange }
                size      = 'small'
                value     = { id || '' }
                { ...activeSwitchProps }
            />);

            if( !disabled && locale )
            {
                const { tooltips } = locale ;
                if( tooltips )
                {
                    const { activate, deactivate } = tooltips ;
                    const tooltip = active ? deactivate : activate ;
                    if( notEmpty( tooltip ) )
                    {
                        button = (
                            <Tooltip placement='top' title={ tooltip }>
                                { button }
                            </Tooltip>
                        )
                    }
                }
            }

            return button ;
        }
        return null ;
    };

    /**
     * Returns the checkbox component of the specific item.
     * @param {Thing} item The item to check.
     * @returns {*}
     */
    getCheckbox = item =>
    {
        if( item )
        {
            const {
                avatarSize,
                classes,
                selectable,
                toggleSelected,
                selectedItems
            } = this.props ;

            let size ;
            if( avatarSize === 'small' )
            {
                size = classes.avatarSmall ;
            }
            else if( avatarSize === 'large' )
            {
                size = classes.avatarLarge ;
            }
            else
            {
                size = classes.avatarDefault ;
            }

            const avatar = this.getAvatar( item ) ;

            const { id } = item ;

            const find = (selectedItems instanceof Array)
                      && selectedItems.findIndex( element => (id === element.id) ) > -1 ;

            return (
                <Checkbox
                    className         = { classes.checkbox }
                    color             = 'secondary'
                    checkedIcon       = { <CheckCircle className={ size } style={{ color:blueGrey[300] }}/> }
                    disabled          = { !this.isEditable() || !selectable }
                    icon              = { avatar || <CircleIcon className={ size }/> }
                    indeterminateIcon = { <Adjust className={ size } /> }
                    checked           = { find }
                    onChange          = { () => toggleSelected( item , 'id' ) }
                    onClick           = { event => { event.stopPropagation() ; }}
                />
            ) ;
        }
        return null ;
    };

    getDeleteButton = item =>
    {
        const { deletable } = this.props ;
        if( deletable && item && this.isEditable() )
        {
            const locale = this.getLocale();

            const { deleteIcon, deleteProps = {} } = this.props ;

            let button = (
            <IconButton
                aria-label = 'delete-button'
                size       = 'small'
                onClick    = { event =>
                {
                    event.stopPropagation();
                    this.openRemoveDialog(item)
                }}
                { ...deleteProps }
            >
                { deleteIcon || <DeleteIcon /> }
            </IconButton>);

            const { tooltips } = locale ;
            if( tooltips )
            {
                const { remove } = tooltips ;
                if( notEmpty( remove ) )
                {
                    button = (
                        <Tooltip placement='top' title={remove}>
                            { button }
                        </Tooltip>
                    )
                }
            }
            return button ;
        }
        return null ;
    };

    getFavoriteButton = item =>
    {
        const {
            preferable ,
            isFavorite ,
            toggleFavorite
        } = this.props ;
        if(
            item &&
            preferable &&
            isFavorite instanceof Function &&
            toggleFavorite instanceof Function
        )
        {
            const flag = isFavorite( item ) ;

            const {
                favoriteStatus,
                favoriteIcon,
                favoriteProps = {} ,
                noFavoriteIcon
            } = this.props ;

            let disabled = favoriteStatus === RequestStatus.PROGRESS ;

            let icon = flag ? favoriteIcon : noFavoriteIcon ;

            let button = (
            <IconButton
                aria-label = 'favorite-button'
                color      = { flag ? 'primary' : 'secondary' }
                size       = 'small'
                disabled   = { disabled }
                onClick    = { event =>
                {
                    event.stopPropagation();
                    toggleFavorite( item , this.onToggleFavorite ) ;
                }}
                { ...favoriteProps }
            >
                { icon }
            </IconButton>);

            if( !disabled )
            {
                const { tooltips } = this.getLocale() || {} ;
                if( tooltips )
                {
                    const { favorite , noFavorite } = tooltips ;

                    let tip = flag ? noFavorite : favorite ;

                    if( notEmpty( tip ) )
                    {
                        button = (
                            <Tooltip placement='top' title={ tip }>
                                { button }
                            </Tooltip>
                        )
                    }
                }
            }

            return button ;
        }
        return null ;
    };

    getItem = () => null ;

    getOptions = () =>
    {
        const addButton  = this.getAddButton({ className:'ml-20' }) ;
        const sortButton = this.getSortButton() ;
        if( addButton || sortButton )
        {
            return (
                <div className='flex flex-row-reverse items-center justify-end '>
                    { addButton }
                    { sortButton }
                </div>
            );
        }
        return null ;
    };

    getSortButton = ( { className = null } = {} ) =>
    {
        const { sortable } = this.props ;
        if( sortable )
        {
            const { status } = this.state ;
            const locale = this.getLocale() ;
            return (
                <SortButton
                    animate   = { true }
                    className = { className }
                    disabled  = { status === RequestStatus.PROGRESS }
                    key       = "option-sort"
                    locale    = { locale }
                    onClick   = { this.openSortDialog }
                />
            );
        }
        return null ;
    };

    populate = datas =>
    {
        const { clazz } = this.props ;
        if( datas instanceof Array && clazz instanceof Function)
        {
            return datas.map( item => item instanceof clazz ? item : new clazz(item) ) ;
        }
        return datas ;
    };

    onToggleFavorite = ( { status } = {} ) =>
    {
        switch( status )
        {
            case 'add' :
            {
                const { snacks : { add } = {} } = this.getLocale() ;
                if( notEmpty(add) )
                {
                    this.notify( add ) ;
                }
                break ;
            }

            case 'remove' :
            {
                const { snacks : { remove } = {} } = this.getLocale() ;
                if( notEmpty(remove) )
                {
                    this.notify( remove ) ;
                }
                break ;
            }

            default :
            {
                //
            }
        }
    }
}

DatasListContainer.defaultProps =
{
    ...DatasContainer.defaultProps,
    addable           : true,
    activeSwitchProps : {},
    queries           : { sort : '-modified' },
};

DatasListContainer.propTypes =
{
    ...DatasContainer.propTypes,
    activeSwitchProps : PropTypes.object
};

export default DatasListContainer ;
