const videos =
{
    add :
    {
       agree       : 'Ajouter' ,
       description : 'Sélectionner ou ajouter une vidéo' ,
       disagree    : 'Annuler' ,
       loading     : 'Ajout en cours...' ,
       title       : 'Ajouter une vidéo',
    },
    empty   : 'Aucune vidéo disponible' ,
    loading : 'Enregistrement en cours',
    remove :
    {
        agree        : 'Supprimer' ,
        description  : 'Souhaitez-vous supprimer cette vidéo?' ,
        descriptionX : 'Souhaitez-vous supprimer les {0} vidéos sélectionnées?' ,
        disagree     : 'Annuler' ,
        loading      : 'Suppression en cours...' ,
        title        : 'Supprimer la vidéo ?',
        titleX       : 'Supprimer les vidéos ?'
    },
    removed  : 'Suppression réussie',
    success  : 'Enregistrement réussi',
    title    : 'Vidéos',
    tooltips :
    {
        add : 'Ajouter'
    }
};

export default videos ;