import URI from 'urijs'

export const PATH = 'https://soundcloud.com/oembed' ;

const soundcloud = ( url , format = 'json' ) =>
{
    const uri = new URI( PATH ) ;

    uri.addSearch( 'format' , format ) ;
    uri.addSearch( 'url'    , url    ) ;

    return uri.toString() ;
}

export default soundcloud ;
