import React from 'react'

import PropTypes  from 'prop-types'

import clsx from 'clsx'

import isString from 'vegas-js-core/src/isString'

import { createPortal } from 'react-dom'

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

import DatasListContainer from '../containers/DatasListContainer'

import api           from '../../configs/api'
import PATCH         from '../../net/PATCH'
import RequestStatus from '../../net/RequestStatus'

import Thing from '../../things/Thing'

import TransitionGroup from '../../transitions/TransitionGroup'

class DatasList extends DatasListContainer
{
    constructor( props )
    {
        super( props ) ;
        this.state =
        {
            ...this.state,
            orderStatus : RequestStatus.NEW
        }
    }

    createChild = () => null ;

    getContent = ( loading = false ) =>
    {
        let { things } = this.state ;
        if( things instanceof Array )
        {
            if( things.length > 0 )
            {
                let {
                    droppableId ,
                    isDropDisabled,
                    orderable
                } = this.props;

                things = things.map( this.getItem(loading) ) ;

                if( orderable )
                {
                    return (
                        <DragDropContext
                            onDragEnd    = { this.onDragEnd    }
                            onDragStart  = { this.onDragStart  }
                            onDragUpdate = { this.onDragUpdate }
                        >
                            <Droppable
                                droppableId    = { droppableId }
                                isDropDisabled = { isDropDisabled }
                            >
                                { provided => (
                                    <div
                                        { ...provided.droppableProps }
                                        ref = { provided.innerRef }
                                    >
                                        { things }
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    )
                }
                else
                {
                    const { contentTransition } = this.props ;
                    if( contentTransition )
                    {
                        return (
                        <TransitionGroup
                            enter = {{
                                animation  : 'transition.slideUpBigIn',
                                duration   : 200 ,
                                stagger    : 100
                            }}
                        >
                            { things }
                        </TransitionGroup>
                        );
                    }
                    else
                    {
                        return <div>{ things }</div> ;
                    }
                }
            }
        }

        if( loading )
        {
            return this.getProgress() ;
        }
        if( !loading )
        {
            return <div className='flex flex-1'>{ this.getEmpty() }</div>;
        }

        return null ;
    };

    getItem = loading => ( thing , index ) =>
    {
        if( this.isValid( thing ) )
        {
            const { draggable } = this.state ;

            const {
                isDragDisabled,
                itemClassName,
                orderable,
                usePortal
            } = this.props ;

            let item = (
                <div
                    key       = { 'item-' + index }
                    className = { clsx( "flex my-8 px-8" , itemClassName ) }
                >
                    { this.createChild( thing , loading , index ) }
                </div>
            );

            if( orderable )
            {
                let content = ( provided , snapshot ) =>
                {
                    let view = (
                    <div
                        ref = { provided.innerRef }
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style = { this.getItemStyle(
                        {
                            draggableStyle : provided.draggableProps.style ,
                            isDragging     : snapshot.isDragging
                        } )}
                    >
                        { item }
                    </div>);

                    if( snapshot.isDragging && usePortal )
                    {
                        view = this.portal(view) ;
                    }

                    return view ;
                };

                return (
                    <Draggable
                        key            = { 'item-draggable-' + index }
                        draggableId    = { 'item-draggable-' + index }
                        index          = { index }
                        isDragDisabled = { isDragDisabled && !draggable }
                    >
                        { ( provided , snapshot ) => (
                            <div className='flex flex-col flex-1 w-full'>
                                { content( provided , snapshot ) }
                                { provided.placeholder }
                            </div>
                        )}
                    </Draggable>
                ) ;
            }
            else
            {
                return item ;
            }

        }
        return null ;
    }

    getItemOptions = () => null ;

    getItemStyle = ( { draggableStyle } = {} ) => ({ userSelect:'none', ...draggableStyle });

    getOrderUri = ( { from , target , to , source , ordered } ) =>
    {
        let { orderUri } = this.props;

        let uri = orderUri || this.orderUri ;

        if( uri instanceof Function )
        {
            uri = uri( { from , target , to , source , ordered , props:this.props , state:this.state } ) ;
        }

        if( !isString(uri) )
        {
            uri = this.getPath() ;
        }

        return api.url + uri ;
    };

    isLoading = () => this.state.status === RequestStatus.PROGRESS || this.state.orderStatus === RequestStatus.PROGRESS ;

    onDragEnd = result =>
    {
        this.setState({ dragging:false } , () =>
        {
            const { destination , source } = result || {}  ;
            if( !destination )
            {
                return ; // dropped outside the list
            }

            if( !source )
            {
                return ; // dropped outside the list
            }

            const { things } = this.state ;
            if( things instanceof Array && things.length > 0 )
            {
                const { index:to   } = destination ;
                const { index:from } = source ;

                const ordered = [ ...things ] ;

                const [ target ] = ordered.splice( source.index , 1 ) ;

                ordered.splice( destination.index , 0 , target ) ;

                this.orderChange({ from, target, to , source:things , ordered }) ;
            }
        }) ;
    };

    onDragStart = event =>
    {
        this.setState({ dragging:true , dropIndex:event.source.index }) ;
    };

    onDragUpdate = event =>
    {
        const { destination } = event ;
        if ( destination )
        {
            const { index } = destination;
            this.setState({ dropIndex: index });
        }
    };

    onOrder = null ;

    onSelect = () => null ;

    orderChange = ( { from , target , to , source , ordered } = {} ) =>
    {
        const { orderStatus } = this.state ;
        if( orderStatus !== RequestStatus.PROGRESS )
        {
            this.setState( { things:ordered , orderStatus:RequestStatus.PROGRESS } ) ;

            let datas ;

            let uri = this.getOrderUri( { from , target , to , source , ordered } ) ;

            let { orderPrepare } = this.props  ;

            let { order : { prepare } = {} } = this.getLocale() || {} ;

            prepare = prepare || orderPrepare || this.orderPrepare ;

            if( prepare instanceof Function )
            {
                datas = prepare({
                    props : this.props ,
                    state : this.state ,
                    from,
                    ordered,
                    source,
                    target,
                    to
                }) ;
            }

            const { orderMock:mock } = this.props ;
            if( mock)
            {
                console.log( this + ' orderChange [MOCK]' , uri , datas ) ;
            }
            else
            {
                this.canceler = PATCH(
                    uri ,
                    {
                        datas ,
                        cancel  : this._orderCancel(source),
                        fail    : this._orderFail(source),
                        success : this._orderSuccess(source)
                    }
                ) ;
            }
        }
    };

    orderPrepare = null ;

    orderUri = null ;

    portal = element =>
    {
        let dragElement = document.getElementById('draggable-element');
        if( !dragElement )
        {
            dragElement = document.createElement("div"  );
            dragElement.setAttribute('id','draggable-element')
            document.body.appendChild(dragElement) ;
        }
        return createPortal( element, dragElement );
    };

    _orderCancel = things => () =>
    {
        if( this._mounted )
        {
            this.setState({ things , draggable:true, orderStatus:RequestStatus.NEW });
        }
        const { onCancel } = this.props ;
        if( onCancel instanceof Function )
        {
            onCancel() ;
        }
    };

    _orderFail = things => response =>
    {
        if( response )
        {
            const { data } = response ;
            if( data )
            {
                const { message } = data ;
                switch( message )
                {
                    case 'token revoked' :
                    {
                        console.log( this + " failed, the current token is revoked, status:" + response.status + ", message:" + message );
                        break ;
                    }
                    default :
                    {
                        console.log( this + " failed, status:" + response.status + ", message:" + data.message );
                    }
                }
            }
        }

        if( this._mounted )
        {
            this.setState({ things , draggable:true, orderStatus:RequestStatus.FAIL });
        }
    };

    _orderSuccess = things => response =>
    {
        if( response )
        {
            const { data } = response;
            if( data )
            {
                let { result , position } = data ;
                if( result )
                {
                    if( things instanceof Array && things.length > 0 )
                    {
                        if( !(result instanceof Array) )
                        {
                            result = [ result ] ;
                        }

                        result = this.populate(result) ;

                        // Remove all duplicates things
                        things = things.filter( item =>
                        {
                            if( item )
                            {
                                const { id } = item ;
                                if( id )
                                {
                                    const find = result.find( ( element , index ) =>
                                    {
                                        if( element )
                                        {
                                            const { id:key } = element ;
                                            if( key === id )
                                            {
                                                result[index] = item ;
                                                return true ;
                                            }
                                        }
                                        return false ;
                                    } ) ;
                                    return !find ;
                                }
                            }
                            return false ;
                        });

                        // Inject the new things
                        if( position >= 0 )
                        {
                            things.splice( position , 0 , ...result ) ;
                        }
                        else
                        {
                            things = [ ...things , ...result ] ;
                        }

                        // Adjust all position
                        things = things.map( ( item , index ) =>
                        {
                            if( item instanceof Thing )
                            {
                                item.position = index ;
                            }
                            return item ;
                        }) ;

                        if( this._mounted  )
                        {
                            this.setState({ things , draggable:true, orderStatus:RequestStatus.SUCCESS } , () =>
                            {
                                let { onOrder } = this.props ;
                                onOrder = onOrder || this.onOrder ;
                                if( onOrder instanceof Function )
                                {
                                    onOrder( result ) ;
                                }
                            });
                        }
                        return ;
                    }
                }
            }

        }

        if( this._mounted )
        {
            this.setState({ things , draggable:true, orderStatus:RequestStatus.FAIL });
        }
    };
}

DatasList.defaultProps =
{
    ...DatasListContainer.defaultProps ,
    containerClassName : null ,
    Container          : 'div' ,
    containerProps     : null ,
    containerStyle     : null ,
    contentTransition  : true ,
    droppableId        : 'droppable-list' ,
    isDragDisabled     : false ,
    isDropDisabled     : false ,
    itemClassName      : 'shadow' ,
    orderable          : false ,
    orderMock          : false,
    usePortal          : false
};

DatasList.propTypes =
{
    ...DatasListContainer.propTypes,
    containerClassName : PropTypes.string ,
    Container          : PropTypes.elementType,
    containerProps     : PropTypes.object ,
    containerStyle     : PropTypes.object ,
    contentTransition  : PropTypes.bool ,
    droppableId        : PropTypes.string,
    isDragDisabled     : PropTypes.bool,
    isDropDisabled     : PropTypes.bool,
    itemClassName      : PropTypes.string,
    onOrder            : PropTypes.func,
    orderable          : PropTypes.bool,
    orderPrepare       : PropTypes.func,
    orderMock          : PropTypes.bool,
    orderUri           : PropTypes.oneOfType([PropTypes.func,PropTypes.string]),
    usePortal          : PropTypes.bool
};

export default DatasList ;
