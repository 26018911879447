import generify from './generify'

import Place         from './Place'
import PostalAddress from './PostalAddress'
import Thing         from './Thing'

/**
 * The geographic shape of a place. A GeoShape can be described using several properties whose values are based on latitude/longitude pairs.
 * @memberOf things
 */
class GeoShape extends Thing
{
    /**
     * Creates a new GeoShape instance.
     * @constructor
     * @param object The generic object to initialize the object.
     */
    constructor( object = null )
    {
        super() ;

        /**
         * Physical address of the item.
         * @type {string|PostalAddress}
         */
        this.address = null ;

        /**
         * The country. For example, USA. You can also provide the two-letter ISO 3166-1 alpha-2 country code.
         * @type {string|Place}
         */
        this.addressCountry = null ;

        /**
         * A box is the area enclosed by the rectangle formed by two points. The first point is the lower corner, the second point is the upper corner. A box is expressed as two points separated by a space character.
         * @type {string}
         */
        this.box = null ;

        /**
         * A circle is the circular region of a specified radius centered at a specified latitude and longitude. A circle is expressed as a pair followed by a radius in meters.
         * @type {string}
         */
        this.circle = null ;

        /**
         * The elevation of a location (WGS 84).
         * @type {number|string}
         */
        this.elevation = null ;

        /**
         * A line is a point-to-point path consisting of two or more points. A line is expressed as a series of two or more point objects separated by space.
         * @type {string}
         */
        this.line = null ;

        /**
         * A polygon is the area enclosed by a point-to-point path for which the starting and ending points are the same. A polygon is expressed as a series of four or more space delimited points where the first and final points are identical.
         * @type {string}
         */
        this.polygon = null ;

        /**
         * The postal code. For example, 94043.
         * @type {string}
         */
        this.postalCode = null ;

        this.set( object ) ;
    }


    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        super.clear() ;
        this.address        =
        this.addressCountry =
        this.box            =
        this.circle         =
        this.elevation      =
        this.line           =
        this.polygon        =
        this.postalCode     = null ;
        return this ;
    }

    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new GeoShape( this.toObject() ) ;
    }
    
    /**
     * Populate the object with specific behaviors or types. Launched by default in the constructor and in the set methods.
     * @returns {Thing} The current reference.
     */
    populate()
    {
        const {
            address,
            addressCountry
        } = this ;
        
        if( address )
        {
            if( !(typeof(address) === 'string' || (address instanceof String)) )
            {
                this.address = new PostalAddress(address);
            }
        }
        
        if( addressCountry )
        {
            if( !(typeof(addressCountry) === 'string' || (addressCountry instanceof String)) )
            {
                this.addressCountry = new Place(addressCountry);
            }
        }
        
        return this ;
    }
    
    /**
     * Returns the generic object representation of the item.
     * @return {Object} The generic object representation of the item.
     */
    toObject()
    {
        let {
            address,
            addressCountry,
            box,
            circle,
            elevation,
            line,
            polygon,
            postalCode
        } = this ;

        address        = generify(address);
        addressCountry = generify(addressCountry);

        return {
            ...super.toObject() ,
            address,
            addressCountry,
            box,
            circle,
            elevation,
            line,
            polygon,
            postalCode
        }
    }
}

export default GeoShape ;