import list from './list'

const { tooltips } = list ;

const thesaurus =
{
    ...list,
    helmet :
    {
        title : 'Thésaurus' ,
        metas : []
    },
    empty  : 'Aucun thésaurus trouvé',
    search : 'Rechercher un terme ...' ,
    sorts  :
    [
        { label : 'Date de modification' , value : 'modified'      }  ,
        { label : 'Nom'                  , value : 'fr'            }  ,
        { label : 'identifiant'          , value : 'alternateName' }  ,
        { label : 'Date de création'     , value : 'created'       }
    ],
    title  : 'Thésaurus',
    tooltips :
    {
        ...tooltips,
        back : "Voir les thésaurus"
    },
};

export default thesaurus ;
