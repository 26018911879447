import React from 'react'

import { withRouter } from 'react-router-dom'

import MemoryIcon from '@material-ui/icons/Work'

import Counter from './Counter'

import withi18n from '../../contexts/i18n/withi18n'

export class TechnicianCounter extends Counter 
{
    getLocale = () => this.props.locale.display.counters.technicians;
}
    
TechnicianCounter.defaultProps =
{
    ...Counter.defaultProps,
    avatarSetting : { empty : "bg-gray-500" , filled : "bg-green-500" } ,
    icon          : <MemoryIcon className="mr-8" fontSize='small' />,
    linkable      : true ,
    member        : 'technicians' ,
    path          : '{0}/technicians'
}

TechnicianCounter.propTypes =
{
    ...Counter.propTypes
}

export default withRouter( withi18n( TechnicianCounter ) ) ;