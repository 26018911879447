import React     from 'react'

import PropTypes from 'prop-types'

import { createPortal } from 'react-dom'

import { DragDropContext , Draggable , Droppable } from 'react-beautiful-dnd'

import ThingCell from '../cells/ThingCell'

import ThingChildren from './ThingChildren'

export { styles } from './ThingChildren'

class ThingOrderableChildren extends ThingChildren
{
    createChild = ( child , editable , index ) =>
    {
        return (
            <ThingCell
                draggable = { this.state.draggable }
                editable  = { editable }
                options   = { this.getItemOptions( { child, className:'px-8' , editable , index } ) }
                thing     = { child }
                variant   = 'default'
            />
        );
    };

    getItem = editable => ( child , index ) =>
    {
        if( this.isValid(child) )
        {
            const { id } = child ;

            const { draggable } = this.state ;
            const { itemClassName, member, usePortal } = this.props ;

            let renderItem = (
                <div className={ itemClassName } >
                    { this.createChild( child , editable ) }
                </div>
            );

            let view = ( provided , snapshot ) =>
            {
                let view = (
                    <div
                    ref = { provided.innerRef }
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style = { this.getItemStyle(
                    {
                        draggableStyle : provided.draggableProps.style ,
                        isDragging     : snapshot.isDragging
                    } )}
                >
                    {renderItem}
                </div>);

                if( snapshot.isDragging && usePortal )
                {
                    view = this.portal(view) ;
                }

                return view ;
            };

            return (
                <Draggable
                    key            = { member + '_' + id }
                    draggableId    = { member + '_' + id }
                    index          = { index }
                    isDragDisabled = { !draggable }
                    //renderClone    = { renderItem }
                >
                    { ( provided , snapshot ) => {
                        return (
                            <div>
                                { view( provided , snapshot ) }
                                { provided.placeholder }
                            </div>
                        );
                    }}
                </Draggable>
            ) ;
        }
        return null ;
    };

    portal = element =>
    {
        let dragElement = document.getElementById('draggable');
        if( !dragElement )
        {
            dragElement = document.createElement("div"  );
            dragElement.setAttribute('id','draggable')
            document.body.appendChild(dragElement) ;
        }
        return createPortal( element, dragElement );
    };

    getItemStyle = ( { draggableStyle } ) =>
    ({
        userSelect : "none",
        ...draggableStyle
    });

    getItems = children =>
    {
        if( (children instanceof Array) && (children.length > 0) )
        {
            return children.map( this.getItem( this.isEditable() ) ) ;
        }
        return null ;
    };

    onDragEnd = result =>
    {
        this.setState({ dragging:false } , () =>
        {
            const { destination , source } = result || {}  ;
            if( !destination )
            {
                return ; // dropped outside the list
            }

            if( !source )
            {
                return ; // dropped outside the list
            }

            const { member , thing } = this.props ;
            if( thing && thing.hasOwnProperty(member)  )
            {
                let children = thing[member] ;
                if( children instanceof Array && children.length > 0 )
                {
                    const { index:to   } = destination ;
                    const { index:from } = source ;

                    const ordered = [ ...children ] ;

                    const [ target ] = ordered.splice( source.index , 1 ) ;

                    ordered.splice( destination.index , 0 , target ) ;

                    thing[member] = ordered ;

                    this.orderChange({ from, target, to , source:children , ordered }) ;
                }
            }
        }) ;
    };

    onDragStart = event =>
    {
        this.setState({ dragging:true , dropIndex:event.source.index }) ;
    };

    onDragUpdate = event =>
    {
        const { destination } = event ;
        if ( destination )
        {
            const { index } = destination;
            this.setState({ dropIndex: index });
        }
    };

    renderChildren = children =>
    {
        if( (children instanceof Array) && (children.length > 0) )
        {
            return(
                <DragDropContext
                    onDragEnd    = { this.onDragEnd }
                    onDragStart  = { this.onDragStart }
                    onDragUpdate = { this.onDragUpdate }
                >
                    <Droppable droppableId={ this.props.droppableId } >
                        { provided => (
                            <div
                                { ...provided.droppableProps }
                                ref = { provided.innerRef }
                            >
                                { children }
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            );
        }
        else
        {
            return this.getEmpty() ;
        }
    };

}

ThingOrderableChildren.defaultProps =
{
    ...ThingChildren.defaultProps ,
    droppableId   : 'droppable' ,
    itemClassName : "flex flex-row items-center justify-center bg-white mt-4 pl-8 shadow" ,
    usePortal     : false
};

ThingOrderableChildren.propTypes =
{
    ...ThingChildren.propTypes,
    droppableId   : PropTypes.string,
    itemClassName : PropTypes.string,
    usePortal     : PropTypes.bool
};

export default ThingOrderableChildren ;
