import table from './table'

const teams =
{
    ...table,
    helmet :
    {
        title : 'Équipes' ,
        metas : []
    },
    empty  : 'Aucune équipe trouvée',
    search : 'Rechercher une équipe...' ,
    sorts  :
    [
        { label : 'Date de modification' , value : 'modified'   }  ,
        { label : 'Identifiant'          , value : 'identifier' }  ,
        { label : 'Nom complet'          , value : 'fr'         }  ,
        { label : 'Nom générique'        , value : 'name'       }  ,
        { label : 'Date de création'     , value : 'created'    }
    ],
    title : 'Équipes'
};

export default teams ;
