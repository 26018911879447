const about =
{
    alternateName :
    {
        title    : 'Titre' ,
        tooltips :
        {
            refresh : 'Annuler' ,
            save    : 'Enregister'
        }
    },
    description :
    {
        title    : 'Description' ,
        tooltips :
        {
            refresh : 'Annuler' ,
            save    : 'Enregister'
        }
    },
    notes :
    {
        title    : 'Notes' ,
        tooltips :
        {
            refresh : 'Annuler' ,
            save    : 'Enregister'
        }
    },
    text :
    {
        title    : 'Texte' ,
        tooltips :
        {
            refresh : 'Annuler' ,
            save    : 'Enregister'
        }
    }
};

export default about ;