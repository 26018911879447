import React from 'react'

import moment from 'moment'

import compose  from 'vegas-js-core/src/functors/compose'
import notEmpty from 'vegas-js-core/src/strings/notEmpty'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import initApp from '../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'

import ApplicationIcon from '@material-ui/icons/Map'

import LabelItem from '../../components/items/LabelItem'

import ThingContainer from '../containers/ThingContainer'

import ApplicationVO from '../../vo/Application'
import Thing         from '../../things/Thing'

const styles = () => ({});

class Application extends ThingContainer
{
    getContent = () =>
    {
        let { thing } = this.props;
        if( thing instanceof ApplicationVO )
        {
            let { lang } = this.props;

            const locale = this.getLocale() ;

            const { labels } = locale ;

            let {
                additionalType,
                version
            } = thing ;

            let name = thing.getLocaleName( lang ) ;
            if( additionalType instanceof Thing )
            {
                additionalType = additionalType.getLocaleName( lang ) ;
            }

            additionalType = notEmpty(additionalType) ? ucFirst(additionalType) : labels.empty ;
            name           = notEmpty(name)           ? ucFirst(name)           : labels.empty ;
            version        = notEmpty(version)        ? version                 : labels.empty ;

            return (
            <div className='flex-1 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
                <LabelItem enabled={!!(name)} title={ labels.name }>
                    { name }
                </LabelItem>
                <LabelItem enabled={!!(additionalType)} title={ labels.additionalType }>
                    { additionalType }
                </LabelItem>
                <LabelItem enabled={!!(version)} title={ labels.version }>
                    { version }
                </LabelItem>
            </div>
            );
        }
        return null ;
    };

    getEntry = init =>
    {
        let empty = {} ;
        if( init instanceof Thing )
        {
            empty = init.toObject() ;
        }
        else if( init )
        {
            empty = { ...init } ;
        }
        return new ApplicationVO(empty);
    };

    getLocale = () => this.props.locale.things.application;

    onEdit = item =>
    {
        const { onChange, thing } = this.props ;
        if( thing && item )
        {
            const {
                additionalType,
                name,
                version
            } = item ;

            thing.additionalType = additionalType ;
            thing.modified       = moment(new Date()).toISOString();
            thing.name           = name ;
            thing.version        = version ;

            if( thing instanceof Thing )
            {
                thing.populate();
            }

            if( this._mounted )
            {
                this.forceUpdate( () =>
                {
                    if( onChange instanceof Function )
                    {
                        onChange(thing) ;
                    }
                });
            }
            else if( onChange instanceof Function )
            {
                onChange(thing) ;
            }
        }
    };

    prepare = item =>
    {
        let {
            additionalType,
            name,
            version
        } = item ;

        return ({
            additionalType : additionalType instanceof Thing
                           ? additionalType.id
                           : null ,
            name,
            version
        });
    };
}

Application.defaultProps =
{
    ...ThingContainer.defaultProps ,
    icon       : <ApplicationIcon/> ,
    optionMode : 'edit'
};

Application.propTypes =
{
    ...ThingContainer.propTypes
};

export default compose( withStyles( styles ) , withRouter , initApp )( Application ) ;
