import initDialog from '../initDialog'

import { RemoveDialog } from '../RemoveDialog'

import api from '../../../configs/api'

const styles = () => ({});

class RemoveStepDialog extends RemoveDialog
{
    getLocale = () => this.props.locale.dialogs.application.step.remove ;
}

RemoveStepDialog.defaultProps =
{
    ...RemoveDialog.defaultProps,
    uri    : api.steps.url,
    useUrl : true
};

RemoveStepDialog.propTypes =
{
    ...RemoveDialog.propTypes
};

export default initDialog({ styles })( RemoveStepDialog );
