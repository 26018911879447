import React from 'react'

import withConfig  from '../../../contexts/config/withConfig'
import withDialogs from '../../../contexts/dialogs/withDialogs'
import withi18n    from '../../../contexts/i18n/withi18n'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { Chip } from '@material-ui/core'

import blueGrey from '@material-ui/core/colors/blueGrey'

import LocalActivityIcon from '@material-ui/icons/LocalActivity'

import ThingContainer from '../../containers/ThingContainer'

import Stage   from '../../../things/Stage'
import ThingVO from '../../../things/Thing'
import Word    from '../../../things/Word'

const styles = theme => (
{
    chip :
    {
        borderColor : blueGrey[100],
        margin      : theme.spacing(0.5)
    }
});

class Activities extends ThingContainer
{
    getBadgeLabel = () =>
    {
        const { thing } = this.props ;
        if( thing )
        {
            const { activities } = thing  ;
            if( (activities instanceof Array) )
            {
                return activities.length ;
            }
        }
        return 0 ;
    };

    getContent = () =>
    {
        const { classes, lang, thing } = this.props ;
        if( thing )
        {
            let { activities } = thing ;

            if( activities instanceof Array && activities.length > 0 )
            {
                let css = { paddingLeft:8,marginRight:0, height:24, width:24 } ;
                activities = activities.map( ( item , index ) =>
                {
                    if( item instanceof Word )
                    {
                        const label = item.getLocaleName( lang );
                        const icon  = this.getImage(item,css);
                        return (
                        <Chip
                            className = { classes.chip }
                            clickable = { false }
                            key       = { 'activity_' + index }
                            icon      = { icon }
                            label     = { label }
                            variant   = 'outlined'
                        />);
                    }
                    return null ;
                });

                return (
                    <div className="flex flex-row flex-wrap">
                        { activities }
                    </div>
                )
            }
        }
        return null ;
    };

    getEntry = init =>
    {
        let empty = {} ;
        if( init instanceof ThingVO )
        {
            empty = init.toObject() ;
        }
        else if( init )
        {
            empty = { ...init } ;
        }
        return new Stage(empty) ;
    };

    getIcon = () => <LocalActivityIcon/> ;

    getLocale = () => this.props.locale.things.stage.activities ;
}

Activities.defaultProps =
{
    ...ThingContainer.defaultProps ,
    badge : true
};

Activities.propTypes =
{
    ...ThingContainer.propTypes
};

export default withStyles( styles ) ( withRouter( withConfig( withi18n( withDialogs( Activities ) ) ) ) ) ;
