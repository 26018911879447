
import AbortionEvent    from '../events/AbortionEvent'
import AbortionReview   from './AbortionReview'
import MedicalRisk      from '../../medical/MedicalRisk'
import VaccinationEvent from '../../medical/events/VaccinationEvent'
import Word             from '../../Word'
import Workplace        from '../../Workplace'

import generify from '../../generify'

/**
 * An animal abortion serie review.
 * @memberOf things.livestock.reviews
 * @extends things.livestock.AbortionReview
 */
class AbortionSerieReview extends AbortionReview
{
    /**
     * Creates a new AbortionReview instance.
     * @constructor
     * @param object The generic object to initialize the object.
     */
    constructor( object = null )
    {
        super() ;
        
        /**
         * The abortion events of this review.
         * @type {Array}
         */
        this.aborted = null ;
        
        /**
         * The conclusion of this review.
         * @type {Word}
         */
        this.conclusion = null ;
        
        /**
         * This flag indicates if the review is completed or not.
         * @type {boolean}
         */
        this.completed = false ;
    
        /**
         * The date of the sampling.
         * @type {string}
         */
        this.date = null ;
        
        /**
         * The traveled distance.
         * @type {number}
         */
        this.distance = 0 ;
    
        /**
         * The frequency of this review.
         * @type {Word}
         */
        this.frequency = null ;
        
        /**
         * The reproductive disorders events of this review.
         * @type {Array}
         */
        this.reproductiveDisorders = null ;
        
        /**
         * The risk of this review.
         * @type {MedicalRisk}
         */
        this.risk = null ;
        
        /**
         * The vaccination events of this review.
         * @type {Array}
         */
        this.vaccinations = null ;
        
        this.set( object ) ;
    }

    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        super.clear() ;
        this.completed = false ;
        this.distance = 0 ;
        this.conclusion   =
        this.date         =
        this.aborted      =
        this.frequency    =
        this.risk         =
        this.vaccinations = null ;
        return this ;
    }

    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new AbortionSerieReview( this.toObject() ) ;
    }
    
    /**
     * Populate the object with specific behaviors or types. Launched by default in the constructor and in the set methods.
     * @returns {Thing} The current reference.
     */
    populate()
    {
        super.populate() ;
        
        let {
            aborted,
            conclusion,
            frequency,
            reproductiveDisorders,
            risk,
            subject,
            vaccinations
        } = this ;
        
        if( aborted instanceof Array )
        {
            this.aborted = aborted.map( item =>
            {
                if( item && !(item instanceof AbortionEvent) )
                {
                    item = new AbortionEvent(item) ;
                    item.subjectOf = this ;
                }
                return item ;
            }) ;
        }
        
        if( conclusion && !(conclusion instanceof Word) )
        {
            this.conclusion = new Word( conclusion ) ;
        }
        
        if( frequency && !(frequency instanceof Word) )
        {
            this.frequency = new Word( frequency ) ;
        }
        
        if( reproductiveDisorders instanceof Array )
        {
            this.reproductiveDisorders = reproductiveDisorders.map( item =>
            {
                if( item && !(item instanceof AbortionEvent) )
                {
                    item = new AbortionEvent(item) ;
                    item.subjectOf = this ;
                }
                return item ;
            }) ;
        }
        
        if( risk && !(risk instanceof MedicalRisk))
        {
            this.risk = new MedicalRisk(risk);
        }
        
        if( subject && !(subject instanceof Workplace) )
        {
            this.subject = new Workplace(subject);
        }
        
        if( vaccinations instanceof Array )
        {
            this.vaccinations = vaccinations.map( item =>
            {
                if( item && !(item instanceof VaccinationEvent) )
                {
                    item = new VaccinationEvent(item) ;
                    item.subjectOf = this ;
                }
                return item ;
            }) ;
        }
        
        return this ;
    }
    /**
     * Returns the generic object representation of the Object.
     * @return {Object} The generic object representation of the Object.
     */
    toObject( full = false )
    {
        let {
            about,
            aborted,
            completed,
            conclusion,
            date,
            distance,
            frequency,
            reproductiveDisorders,
            risk,
            subject,
            vaccinations
        } = this ;

        aborted               = generify(aborted,true) ;
        about                 = generify(about) ;
        conclusion            = generify(conclusion,true);
        frequency             = generify(frequency,true);
        reproductiveDisorders = generify(reproductiveDisorders,true) ;
        risk                  = generify(risk,true);
        subject               = generify(subject,true);
        vaccinations          = generify(vaccinations,true);

        return {
            ...super.toObject(full) ,
            aborted,
            about,
            completed,
            conclusion,
            date,
            distance,
            frequency,
            reproductiveDisorders,
            risk,
            subject,
            vaccinations
        };
    }
}

export default AbortionSerieReview ;