import about from './game/about'

const game =
{
    // dependencies
    about,

    // settings
    helmet :
    {
        title : 'Jeux - {0}' ,
        metas : []
    },
    loading  : "Chargement ..." ,
    notFound : "Jeu inconnu" ,
    quest    : {},
    tabs     :
    {
        about : 'À propos' ,
        quest : 'Quête'
    },
    tooltips :
    {
        back : 'Tous les jeux'
    }
};

export default game ;