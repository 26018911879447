import isString from 'vegas-js-core/src/isString'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import Word  from './Word'
import Thing from './Thing'

/**
 * Returns the human readable localized label expression of the object.
 * @param {Thing} thing - The thing reference.
 * @param {string} lang - The current language id.
 * @param {boolean} [upperFirst=true] - Indicates if the first character of the label is uppercase.
 * @returns {null|string} The human readable localized label expression of the object.
 */
const getLocaleThingLabel = ( thing , lang , upperFirst = true ) =>
{
    if( thing instanceof Thing )
    {
        let label ;
        if( thing instanceof Word )
        {
            label = thing.getLocaleName(lang) ;
        }
        else
        {
            label = thing.getLocaleAlternateName(lang) ;
            if( !isString(label) && label !== '' )
            {
                label = thing.getLocaleName(lang) ;
            }
        }

        if( isString(label) && label !== '' )
        {
            return upperFirst ? ucFirst(label) : label ;
        }
    }
    return null ;
};

export default getLocaleThingLabel ;
