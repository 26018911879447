import notEmpty from 'vegas-js-core/src/strings/notEmpty'

import settings from './settings'

/**
 * Returns a function who returns a locale string value of a specific thing's property.
 * @param {string} key - The name of the property.
 * @return {Function} The helper function reference.
 */
const getLocaleProperty = key => ( thing, lang = null , defaultLang = null ) =>
{
    if( thing && thing.hasOwnProperty(key) )
    {
        let value = thing[key] ;
        if( value )
        {
            if( notEmpty(value) )
            {
                return value ;
            }
            else
            {
                lang = lang || settings.lang ;

                if ( value.hasOwnProperty(lang) && notEmpty(value[lang]) )
                {
                    return value[lang] ;
                }

                defaultLang = defaultLang || settings.defaultLang ;
                if ( value.hasOwnProperty(defaultLang) )
                {
                    value = value[defaultLang] ;
                    if( notEmpty(value) )
                    {
                        return value ;
                    }
                }
            }
        }
    }
    return null ;
};

export default getLocaleProperty ;
