import React, { Component } from 'react'

import clsx from 'clsx'

import byteSize from 'byte-size'

import PropTypes from 'prop-types'

import shortid from 'shortid'

import format   from 'vegas-js-core/src/strings/fastformat'
import isString from 'vegas-js-core/src/isString'

import { withStyles } from '@material-ui/core/styles'

import { IconButton } from '@material-ui/core'

import blueGrey from '@material-ui/core/colors/blueGrey'
import grey     from '@material-ui/core/colors/grey'

import UploadIcon from '@material-ui/icons/CloudUpload'

import removeDragData from '../../net/removeDragData'
import UploadStatus   from '../../net/UploadStatus'
import isValidFile    from '../../net/isValidFile'

import UploadButton from './UploadButton'

import FileEntry from './FileEntry'

const styles = () => (
{
    icon :
    {
        fontSize : 48
    },
    iconDisabled :
    {
        color : grey[200]
    },
    iconNormal :
    {
        color : blueGrey[600]
    },
    iconOver :
    {
        color : blueGrey[100]
    },
    disabled :
    {
        backgroundColor : grey[100]
    },
    normal :
    {
        backgroundColor : grey[200]
    },
    over :
    {
        backgroundColor : grey[100]
    },
    root :
    {
        alignItems      : 'center',
        border          : 1,
        borderColor     : grey[400],
        borderRadius    : 12,
        borderStyle     : 'dashed',
        boxShadow       : 'inset 0 2px 6px 0 rgba(0,0,0,0.06)',
        display         : 'flex' ,
        height          : '220px',
        justifyContent  : 'center',
        marginBottom    : 16,
        marginTop       : 16,
        width           : '100%'
    }
});

class Dropper extends Component
{
    constructor( props )
    {
        super( props ) ;
        this.element = window ;
        this.state   =
        {
            level : 0,
            over  : false
        };
    }



    addFile = file =>
    {
        let entry = null ;
        if( file )
        {
            let { rejected, renameFile } = this.props ;

            let accepted = false ;

            this.acceptFile( file , error =>
            {
                if( isString(error) )
                {
                    accepted = false ;
                    if( rejected instanceof Function )
                    {
                        rejected( file, error ) ;
                    }
                }
                else
                {
                    accepted = true ;
                }
            });

            if( accepted )
            {
                let { name } = file ;
                if( renameFile instanceof Function )
                {
                    name = renameFile( file );
                }

                entry = new FileEntry(
                {
                    file,
                    name,
                    id       : shortid.generate(),
                    progress : 0,
                    status   : UploadStatus.PENDING ,
                }) ;
            }
        }

        return entry ;
    };

    /**
     * This function checks the filesize, and if the file.type passes the `acceptedFiles` property.
     * If `done()` is called without argument the file is accepted. If you call it with an error message, the file is rejected.
     * @param {File} file - The file to accept.
     * @param {Function} done - The done callback method to accept or not the file.
     */
    acceptFile = ( file , done ) =>
    {
        let {
            accept,
            acceptedFiles,
            locale,
            maxFilesize,
            maxFilesizeExceeded,
        } = this.props ;

        if( file )
        {
            const { size } = file ;

            if( maxFilesize && size > maxFilesize * 1024 * 1024 )
            {
                maxFilesize = maxFilesize * 1024 * 1024;
                if( size > maxFilesize )
                {
                    if( maxFilesizeExceeded instanceof Function )
                    {
                        maxFilesizeExceeded( file ) ;
                    }
                    return done( format(locale.fileTooBig,byteSize(size).toString(),byteSize(maxFilesize).toString()) ) ;
                }
            }
            else if( !isValidFile(file, acceptedFiles))
            {
                return done( locale.invalidFileType ) ;
            }
        }

        if( accept instanceof Function )
        {
            return accept.call( this, file, done );
        }

        done() ;
    };

    componentDidMount()
    {
        const { id, disabled } = this.props ;
        if( isString( id ) )
        {
            let element = document.getElementById(id);
            if( element )
            {
                this.element = element ;
            }
            else
            {
                this.element = window ;
            }
        }
        if( !disabled )
        {
            this.registerElement() ;
        }
    }

    componentDidUpdate( prevProps )
    {
        const { disabled } = this.props ;
        if( prevProps.disabled !== disabled )
        {
            if( disabled )
            {
                this.unregisterElement();
            }
            else
            {
                this.registerElement();
            }
        }
    }

    componentWillUnmount()
    {
        this.unregisterElement() ;
    }

    registerElement = () =>
    {
        this.unregisterElement() ;
        if( this.element )
        {
            this.element.addEventListener( 'dragenter' , this._dragEnter );
            this.element.addEventListener( 'dragleave' , this._dragLeave );
            this.element.addEventListener( 'dragover'  , this._dragOver  );
            this.element.addEventListener( 'drop'      , this._fileDrop  );
        }
    };

    render = () =>
    {
        let {
            className,
            classes,
            clickable,
            disabled,
            id,
            IconComponent,
            multiple,
            style,
            visible
        } = this.props ;

        const { over } = this.state ;

        const iconClass = clsx(
            classes.icon,
            disabled ? classes.iconDisabled
                     : ( over ? classes.iconOver : classes.iconNormal )
        );

        let icon ;
        if( IconComponent instanceof Function )
        {
            icon = <IconComponent className={ iconClass }/>;
        }
        else
        {
            icon = <UploadIcon className={ iconClass }/>;
        }

        if( clickable )
        {
            icon = (
                <UploadButton
                    disabled   = { disabled }
                    multiple   = { multiple }
                    over       = { over }
                    onFileDrop = { files =>
                    {
                        // console.log( this + ' onFileDrop' , files );
                        if ( files.length > 0 )
                        {
                            files = files.map( item => this.addFile( item ))
                                         .filter( item => item !== null ) ;

                            const { onFileDrop } = this.props ;
                            if( onFileDrop )
                            {
                                onFileDrop( files );
                            }
                        }
                    }}
                >
                    <IconButton>
                        {icon}
                    </IconButton>
                </UploadButton>
            );
        }

        if( visible )
        {
            className = clsx(
                classes.root,
                disabled ? classes.disabled : (over ? classes.over : classes.normal)
            ) ;

            return (
            <div id={id} className={className} style={style}>
                { icon }
            </div>
            );
        }

        return null ;
    }

    unregisterElement = () =>
    {
        if( this.element )
        {
            this.element.removeEventListener( 'dragenter' , this._dragEnter );
            this.element.removeEventListener( 'dragleave' , this._dragLeave );
            this.element.removeEventListener( 'dragover'  , this._dragOver  );
            this.element.removeEventListener( 'drop'      , this._fileDrop  );
        }
    };

    // ---------- PRIVATE

    _dragEnter = event =>
    {
        const { dataTransfer } = event || {} ;
        if( dataTransfer )
        {
            const { types } = dataTransfer;
            if( types )
            {
                if( types.includes('Files') )
                {
                    let { level } = this.state ;

                    level++;

                    this.setState({ level });

                    const { onDragEnter, visible } = this.props ;
                    if ( onDragEnter && !visible )
                    {
                        onDragEnter( event );
                    }
                }
            }
        }
    };

    _dragLeave = event =>
    {

        let { level } = this.state ;

        //console.log( this + ' drag leave');

        level--;

        this.setState( { level , over:false } );

        const { onDragLeave } = this.props ;
        if ( level === 0 && onDragLeave )
        {
            onDragLeave( event );
        }
    };

    _dragOver = event =>
    {
        event.preventDefault();

        //console.log( this + ' drag over');

        this.setState({ over:true });

        const { onDragOver } = this.props ;
        if( onDragOver )
        {
            onDragOver( event );
        }
    };

    _fileDrop = event =>
    {
        event.preventDefault();

        let result = [] ;

        const { dataTransfer } = event ;
        if ( dataTransfer )
        {
            const { items, files } = dataTransfer ;

            if ( items )
            {
                let len = items.length ;
                for ( let i = 0; i < len ; i++)
                {
                    if (items[i].kind === 'file')
                    {
                        result.push( items[i].getAsFile() );
                    }
                }
            }
            else if( files )
            {
                let len = files.length ;
                for( let i = 0 ; i<len ; i++ )
                {
                    result.push( files[i] ) ;
                }
            }
        }

        if ( result.length > 0 )
        {
            result = result.map( item => this.addFile( item ))
                           .filter( item => item !== null ) ;
        }

        removeDragData( event ) ;

        this.setState({ level:0, over:false });

        const { onFileDrop } = this.props ;
        if( onFileDrop )
        {
            onFileDrop( result );
        }
    };
}

Dropper.defaultProps =
{
    accept : null,

    acceptedFiles : [ 'image/*' ],

    /**
     * If true, the dropper element itself will be clickable, if false nothing will be clickable.
     */
    clickable : true ,

    /**
     * Indicates if the element is disabled.
     */
    disabled : false ,

    /**
     * Gets called when the browser is not supported. The default implementation shows the fallback input field and adds a text.
     * @property
     */
    fallback         : null,
    IconComponent    : null,
    id               : null,
    locale           :
    {
        fallback         : 'Your browser does not support drag\'n\'drop file uploads.',
        invalidFileType  : 'You can\'t upload files of this type.',
        fileTooBig       : 'The file is too big ({0}MiB) / Max filesize: {1}MiB.',
        maxFilesExceeded : 'You can not upload any more files.'
    },
    maxFilesExceeded : null,
    maxFiles         : null,
    maxFilesReached  : null,
    /**
     * The maximum file size in MiB.
     * @property
     * @default 256
     */
    maxFilesize         : 256,
    maxFilesizeExceeded : null,
    multiple            : false,
    onFail              : null ,
    onDragEnter         : null,
    onDragLeave         : null,
    onDragOver          : null,
    onFileDrop          : null,
    rejected            : null,
    renameFile          : null,
    visible             : true
};

Dropper.propTypes =
{
    accept              : PropTypes.func,
    acceptedFiles       : PropTypes.oneOfType([PropTypes.string,PropTypes.arrayOf(PropTypes.string)]),
    children            : PropTypes.oneOfType([PropTypes.element,PropTypes.arrayOf(PropTypes.element)]),
    className           : PropTypes.oneOfType([PropTypes.string,PropTypes.arrayOf(PropTypes.string)]),
    classes             : PropTypes.object.isRequired,
    clickable           : PropTypes.bool,
    disabled            : PropTypes.bool,
    fallback            : PropTypes.func,
    IconComponent       : PropTypes.func,
    id                  : PropTypes.string,
    locale              : PropTypes.object,
    maxFiles            : PropTypes.number,
    maxFilesize         : PropTypes.number,
    maxFilesizeExceeded : PropTypes.func,
    multiple            : PropTypes.bool,
    onDragEnter         : PropTypes.func,
    onDragLeave         : PropTypes.func,
    onDragOver          : PropTypes.func,
    onFail              : PropTypes.func,
    onFileDrop          : PropTypes.func,
    rejected            : PropTypes.func,
    renameFile          : PropTypes.func,
    style               : PropTypes.object,
    visible             : PropTypes.bool
};

export default withStyles(styles)( Dropper ) ;
