import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import clsx from 'clsx'

import compose from 'vegas-js-core/src/functors/compose'

import { withRouter } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'

import { Collapse, Icon, IconButton, ListItem, ListItemText } from '@material-ui/core'

import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'

import NavigationBadge from '../NavigationBadge'
import GroupItem       from './GroupItem'
import Item            from './Item'

import AbstractItem from './AbstractItem'
import ItemLink     from './ItemLink'

const styles = theme => (
{
    root :
    {
        padding  : 0,
        '&.open' : { backgroundColor: 'rgba(0,0,0,.08)' }
    },
    item :
    {
        height       : 40,
        width        : 'calc(100% - 16px)',
        borderRadius : '0 20px 20px 0',
        paddingRight : 12,
        color        : theme.palette.text.primary,
        '&.active > .list-item-text > span': { fontWeight: 600 }
    }
});

const needsToBeOpened = props =>
{
    const { item, location } = props ;
    return location && isUrlInChildren( item, location.pathname ) ;
};

const isUrlInChildren = ( parent , url ) =>
{
    const { children } = parent ;

    if ( !children )
    {
        return false;
    }

    const len = children.length ;
    for ( let i = 0 ; i < len ; i++ )
    {
        if ( children[i].children )
        {
            if ( isUrlInChildren(children[i], url) )
            {
                return true;
            }
        }

        if ( children[i].url === url || url.includes(children[i].url) )
        {
            return true;
        }
    }

    return false;
};

class CollapseItem extends AbstractItem
{
    constructor(props)
    {
        super(props);
        this.state = { open: needsToBeOpened(this.props) };
    }

    componentDidUpdate = ( prevProps , prevState ) =>
    {
        if ( needsToBeOpened(this.props) )
        {
            !prevState.open && this.setState({ open:true });
        }
        else
        {
            prevState.open && this.setState({ open:false });
        }
    }

    handleClick = () =>
    {
        this.setState({ open:!this.state.open });
    };

    getChild = item =>
    {
        let node = null ;
        if( item )
        {
            const { active, nestedLevel } = this.props;
            let { id , type } = item ;
            switch( type )
            {
                case 'collapse' :
                {
                    node = <CollapseItem item={item} nestedLevel={nestedLevel+1} active={active}/> ;
                    break ;
                }

                case 'group' :
                {
                    node = <GroupItem item={item} nestedLevel={nestedLevel+1} active={active}/> ;
                    break ;
                }

                case 'item' :
                {
                    node = <Item item={item} nestedLevel={nestedLevel+1} active={active}/> ;
                    break ;
                }

                default :
                {
                    node = null ;
                }

            }
            if( node )
            {
                node = <Fragment key={id}>{node}</Fragment>
            }
        }

        return node ;
    };

    render()
    {
        const {item} = this.props ;

        if( !item )
        {
            return null ;
        }

        const { open } = this.state ;

        const { classes } = this.props;

        let {
            badge,
            children,
            icon,
            title,
            url
        }
        = item ;

        return (
            <ul className={ clsx( classes.root, open && "open" ) }>
                <ListItem
                    component = { url ? ItemLink : 'li' }
                    button    = { true }
                    className = { clsx(classes.item, 'list-item') }
                    style     = {{ paddingLeft : this.getPaddingLeft() }}
                    onClick   = { this.handleClick }
                    role      = "button"
                    to        = { url }
                >
                    { icon &&  <Icon className="list-item-icon text-3xl flex-shrink-0 mr-12" color="action">{ icon }</Icon> }
                    { <ListItemText className="list-item-text" classes={{primary: 'text-2xl'}} primary = {title} /> }
                    { badge && <NavigationBadge className="mr-4" badge={badge} />  }
                    <IconButton
                        aria-label    = "none"
                        className     = "w-40 h-40 -mr-12 p-0 focus:bg-transparent hover:bg-transparent"
                        disableRipple = { true }
                    >
                        {
                            open
                            ? <ExpandLess className="text-2xl arrow-icon" color="inherit"/>
                            : <ExpandMore className="text-2xl arrow-icon" color="inherit"/>
                        }
                    </IconButton>
                </ListItem>
                {
                    children instanceof Array && children.length > 0 &&
                    <Collapse in={ open } className="collapse-children">
                        { children.map( this.getChild ) }
                    </Collapse>
                }
            </ul>
        );
    }
}

CollapseItem.defaultProps = {};

CollapseItem.propTypes =
{
    item : PropTypes.shape(
    {
        id       : PropTypes.string.isRequired,
        title    : PropTypes.string,
        icon     : PropTypes.string,
        children : PropTypes.array
    })
};

export default compose(
    withStyles( styles , { withTheme : true } ) ,
    withRouter
)( CollapseItem );