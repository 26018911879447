import React , { Component } from 'react'

import PropTypes  from 'prop-types'

import { withStyles } from '@material-ui/core/styles'

import SystemUpdateIcon from '@material-ui/icons/SystemUpdate'

import { Button, Snackbar, Typography } from '@material-ui/core'

import grey   from '@material-ui/core/colors/grey'
import yellow from '@material-ui/core/colors/yellow'

import withLocale from '../../contexts/i18n/withLocale'

import reload   from '../../configs/reload'
import reloader from '../../reloader'

import { unregister } from '../../workers/registerServiceWorker'

const styles = () =>
({
    button :
    {
        backgroundColor : grey[700],
        color           : yellow[500],
        '&:hover'       :
        {
            backgroundColor : grey[900]
        }
    },
    root:
    {
        margin    : 20,
        marginTop : 80
    }
});

class Reload extends Component
{
    constructor( props )
    {
        super( props ) ;
        reloader.connect(this.check);
    }

    state =
    {
        open    : false,
        message : ''
    };

    close = ( event , reason ) =>
    {
        if ( reason === 'clickaway' )
        {
            return;
        }
        this.setState( { open: false } );
    };

    check = ( change ) =>
    {
        console.log( this + " check" , change ) ;
        if( !!(change) )
        {
            this.setState({ open: true });
            localStorage.removeItem( reload ) ;
        }
    };

    componentDidMount()
    {
        const change = localStorage.getItem( reload ) ;
        if( change === "true" )
        {
            localStorage.removeItem( reload ) ;
            this.check( true ) ;
        }
    }

    getLocale = () => this.props.locale.components.snackbars.reload ;

    open = () => this.setState({ open: true }) ;

    reload = () =>
    {
        this.setState( { open: false } );
        unregister( true , false ) ;
    };

    render()
    {
        const {
            autoHideDuration,
            classes,
            horizontal,
            vertical
        } = this.props ;

        const locale = this.getLocale() ;

        let { open } = this.state ;

        //open = true ;

        const action = (
            <Button
                key       = "reload"
                className = { classes.button }
                size      = "small"
                onClick   = { this.reload }
                variant   = 'outlined'
            >
                { locale.action }
            </Button>
        ) ;
        const message = (
            <div className='flex flex-row items-center w-full'>
                <SystemUpdateIcon className='mr-8' />
                <Typography
                    color = 'inherit'
                >
                    { locale.text }
                </Typography>
            </div>
        ) ;

        return (
            <Snackbar
                anchorOrigin     = {{ horizontal, vertical }}
                autoHideDuration = { autoHideDuration }
                className        = { classes.root }
                ContentProps     = {{ 'aria-describedby': 'message-id' }}
                open             = { open }
                onClose          = { this.close }
                message          = { message }
                action           = { action }
            />
        );
    }
}

Reload.defaultProps =
{
    autoHideDuration : null ,
    horizontal       : 'right' ,
    vertical         : 'top'
};

Reload.propTypes =
{
    autoHideDuration : PropTypes.number,
    classes          : PropTypes.object.isRequired,
    horizontal       : PropTypes.string,
    locale           : PropTypes.object.isRequired,
    vertical         : PropTypes.string
};

export default withStyles(styles)( withLocale(Reload) ) ;
