import React from "react"

import isString from 'vegas-js-core/src/isString'
import notEmpty from 'vegas-js-core/src/strings/notEmpty'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import AppsIcon     from '@material-ui/icons/Apps'
import MediasIcon   from '@material-ui/icons/PermMedia'
import OAuthIcon    from '@material-ui/icons/VpnKey'
import SettingsIcon from '@material-ui/icons/Settings'

import ThingView , { defaultStyle } from '../../display/views/ThingView'

import About   from './views/About'
import Medias  from '../medias/Medias'
import OAuth   from './views/OAuth'
import Setting from './views/Setting'

import ApplicationVO from '../../vo/Application'

import api      from '../../configs/api'
import paths    from '../../configs/paths'

import initView from '../../display/views/initView'

const styles = defaultStyle();

class Application extends ThingView
{
    getLocale = () => this.props.locale.views.application ;

    getSubtitle = thing =>
    {
        if( thing )
        {
            const { lang } = this.props ;

            let {
                alternativeHeadline,
                headline
            } = thing ;

            if( alternativeHeadline )
            {
                alternativeHeadline = thing.getLocaleAlternativeHeadline(lang) ;
                if( alternativeHeadline && alternativeHeadline !== '' )
                {
                    return ucFirst(alternativeHeadline)  ;
                }
            }

            if( headline )
            {
                return ucFirst(thing.getLocaleName(lang))  ;
            }
        }
        return null ;
    };

    getTitle = thing =>
    {
        if( thing instanceof ApplicationVO )
        {
            const { lang } = this.props ;

            let label = thing.getLocaleHeadline( lang ) ;
            if( notEmpty(label) )
            {

                return ucFirst( label )  ;
            }

            label = thing.getLocaleName(lang) ;
            if( notEmpty(label) )
            {
                return ucFirst( label )  ;
            }

        }
        return null ;
    };

    hasSetting = () =>
    {
        const { thing } = this.state ;
        if ( thing )
        {
            const { additionalType } = thing ;
            if( additionalType )
            {
                const { alternateName } = additionalType ;
                if( isString( alternateName ) )
                {
                    const { config : { application : { settings = {} } = {} } = {} } = this.props ;
                    if( settings[alternateName] )
                    {
                        return true ;
                    }
                }
            }
        }
        return false ;
    };
}

Application.defaultProps =
{
    ...ThingView.defaultProps,
    back       : true ,
    clazz      : ApplicationVO ,
    icon       : <AppsIcon/>,
    path       : api.applications,
    responsive : true ,
    routes     :
    [
        { id : 'about'   , RouteComponent : About                              } ,
        { id : 'setting' , RouteComponent : Setting , validator : 'hasSetting' } ,
        { id : 'medias'  , RouteComponent : Medias                             } ,
        { id : 'oauth'   , RouteComponent : OAuth                              }
    ],
    tabs :
    [
        { id : 'about'   , icon : <AppsIcon     className='pr-8' /> } ,
        { id : 'setting' , icon : <SettingsIcon className='pr-8' /> , validator : 'hasSetting' } ,
        { id : 'medias'  , icon : <MediasIcon   className='pr-8' /> } ,
        { id : 'oauth'   , icon : <OAuthIcon    className='pr-8' /> } ,
    ],
    titleAuto : false
};

Application.propTypes =
{
    ...ThingView.propTypes,
    path : paths.applications
};


export default initView( Application, styles );
