import reload from '../configs/reload'

import reloader from '../reloader'

const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

export default function register()
{
    if ( process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator )
    {
        const publicUrl = new URL( process.env.PUBLIC_URL, window.location ) ;
        
        //console.log( '> register' , publicUrl ) ;
        
        if ( publicUrl.origin !== window.location.origin )
        {
            return ; //  see https://github.com/facebookincubator/create-react-app/issues/2374
        }

        window.addEventListener('load', () =>
        {
            const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
            
            //console.log( '> load' , swUrl ) ;
            
            if ( isLocalhost )
            {
                checkValidServiceWorker(swUrl);
            }
            else
            {
                registerValidSW(swUrl);
            }
        });
    }
}

function registerValidSW( swUrl )
{
    navigator.serviceWorker
        .register(swUrl)
        .then( registration =>
        {
            console.log('Service worker is registered.');
            
            if( window.BroadcastChannel )
            {
                const updateChannel = new BroadcastChannel('precache-updates');
                updateChannel.addEventListener('message', event =>
                {
                    console.log(`Cache updated: ${event.data.payload.updatedUrl}`);
                });
            }
            
            registration.onupdatefound = () =>
            {
                console.log('New service worker update is found.');
                const installingWorker = registration.installing;
                installingWorker.onstatechange = () =>
                {
                    if ( installingWorker.state === 'installed' )
                    {
                        if ( navigator.serviceWorker.controller )
                        {
                            // At this point, the old views will have been purged and
                            // the fresh views will have been added to the cache.
                            // It's the perfect time to display a "New views is
                            // available; please refresh." message in your web app.
                            console.log('New views is available; please refresh.');
                            localStorage.setItem( reload , "true" ) ;
                            reloader.emit( true ) ;
                        }
                        else
                        {
                            // At this point, everything has been precached.
                            // It's the perfect time to display a
                            // "Content is cached for offline use." message.
                            console.log('Content is cached for offline use.');
                        }
                    }
                };
            };
        })
        .catch( error =>
        {
            console.error('Error during service worker registration:', error);
        });
}

function checkValidServiceWorker(swUrl)
{
    //console.log( '> checkValidServiceWorker' , swUrl ) ;
    fetch( swUrl ).then( response =>
    {
        //console.log( '> checkValidServiceWorker then' , response ) ;
        if ( response.status === 404 )
        {
            if( response.headers.get('views-type').indexOf('javascript') === -1 )
            {
                navigator.serviceWorker.ready.then( registration =>
                {
                    registration.unregister().then(() =>
                    {
                        window.location.reload();
                    });
                });
            }
        }
        else
        {
            // Service worker found. Proceed as normal.
            registerValidSW(swUrl);
        }
    })
    .catch( ( error ) => {
        console.log( 'No internet connection found. App is running in offline mode.' , error );
    });
}

export function unregister( reload = false , cleanCache = false )
{
    if ( 'serviceWorker' in navigator )
    {
        navigator.serviceWorker.ready.then( registration =>
        {
            registration.unregister().then(() =>
            {
                if( cleanCache )
                {
                    sessionStorage.clear() ;
                    localStorage.clear() ;
                }
                if( reload )
                {
                    window.location.reload();
                }
            });
        });
    }
}
