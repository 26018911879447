import React from 'react'

import DialogsConsumer from './DialogsConsumer'

export const withDialogs = Component => React.forwardRef( ( props , ref ) =>
(
    <DialogsConsumer>
        { store => <Component {...props} {...store} ref={ref} /> }
    </DialogsConsumer>
));

export default withDialogs ;