import React from 'react';

import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'

import format from 'vegas-js-core/src/strings/fastformat'

import { Grid } from '@material-ui/core'

import indigo from '@material-ui/core/colors/indigo'

import DescriptionIcon  from '@material-ui/icons/Description'
import SubjectIcon      from '@material-ui/icons/Subject'
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes'
import TitleIcon        from '@material-ui/icons/Title'

import AnalysisMethods     from '../../../../../display/things/medical/disease/AnalysisMethods'
import AnalysisSamplings   from '../../../../../display/things/medical/disease/AnalysisSamplings'
import Disease             from '../../../../../display/things/medical/Disease'
import MultiLangEditor     from '../../../../../components/editors/MultiLangEditor'
import Metadatas           from '../../../../../display/things/Metadatas'
import RemoveDiseaseDialog from '../../../../../display/dialogs/remove/RemoveDiseaseDialog'
import ThingSubContainer   from '../../../../../display/containers/ThingSubContainer'
import TransitionGroup     from '../../../../../transitions/TransitionGroup'
import TransmissionMethod  from '../../../../../display/things/medical/disease/TransmissionMethod'

const styles = () =>
({
    header :
    {
        height          : 100,
        backgroundColor : indigo[700] ,
        backgroundSize  : 'cover',
        color           : '#fff'
    },
    tab :
    {
        height: 64
    },
    tabs :
    {
        height: 64,
        width : '100%'
    },
    toolbar :
    {
        padding: 0
    }
});

const full =
{
    xs : 12
};

const half =
{
    md :  6 ,
    sm : 12 ,
    xs : 12
};

class About extends ThingSubContainer
{
    render = () =>
    {
        const {
            locale,
            path,
            thing,
        } = this.props ;

        if( thing )
        {
            const {
                alternateName,
                description,
                text,
                notes,
                id,
            } = thing ;

            const { onRemove } = this.props ;

            return (
            <div className='w-full h-full p-16'>
                <TransitionGroup duration={250} enter={{animation: "transition.slideUpIn"}}>

                    <Metadatas
                        activable             = { true }
                        deletable             = { true }
                        onRemove              = { onRemove }
                        RemoveDialogComponent = { RemoveDiseaseDialog }
                        thing                 = { thing }
                        uri                   = { format(path.disease,id) }
                    />

                    <Grid container spacing={4}>

                        <Grid item {...half}>
                            <Disease
                                editUri  = { format(path.disease,id) }
                                onChange = { this.change }
                                thing    = { thing }
                            />
                        </Grid>

                        <Grid item {...half}>
                            <MultiLangEditor
                                html     = { false }
                                icon     = { <TitleIcon/> }
                                input    = { true }
                                locale   = { locale.alternateName }
                                onChange = { this.changeProperty('alternateName') }
                                text     = { alternateName }
                                uri      = { format(path.alternateName, id ) }
                                verbose  = { true }
                            />
                        </Grid>

                        <Grid item {...full}>
                            <TransmissionMethod
                                editUri    = { format(path.transmissionMethod,id) }
                                onChange   = { this.changeProperty('transmissionMethod') }
                                thing      = { thing }
                            />
                        </Grid>

                        <Grid item {...full}>
                            <AnalysisMethods
                                addUri    = { format(path.analysisMethod,id) }
                                removeUri = { format(path.analysisMethod,id) }
                                onChange  = { this.change }
                                thing     = { thing }
                                uri       = { path.analysisMethod }
                            />
                        </Grid>

                        <Grid item {...full}>
                            <AnalysisSamplings
                                addUri    = { format(path.analysisSampling,id) }
                                removeUri = { format(path.analysisSampling,id) }
                                onChange  = { this.change }
                                thing     = { thing }
                                uri       = { path.analysisSampling }
                            />
                        </Grid>

                        <Grid item {...half}>
                            <MultiLangEditor
                                html     = { false }
                                icon     = { <SubjectIcon/> }
                                locale   = { locale.description }
                                onChange = { this.changeProperty('description') }
                                text     = { description }
                                uri      = { format(path.description, id ) }
                            />
                        </Grid>

                        <Grid item {...half}>
                            <MultiLangEditor
                                icon     = { <SpeakerNotesIcon/> }
                                locale   = { locale.notes }
                                onChange = { this.changeProperty('notes') }
                                text     = { notes }
                                uri      = { format(path.notes, id ) }
                            />
                        </Grid>

                        <Grid item {...full}>
                            <MultiLangEditor
                                icon     = { <DescriptionIcon/> }
                                locale   = { locale.text }
                                onChange = { this.changeProperty('text') }
                                text     = { text }
                                uri      = { format( path.text, id ) }
                            />
                        </Grid>

                    </Grid>
                </TransitionGroup>
            </div>
            );
        }

        return null ;
    }
}

About.defaultProps =
{
    ...ThingSubContainer.defaultProps
};

About.propTypes =
{
    ...ThingSubContainer.propTypes
};

export default withStyles( styles )( withRouter(About) ) ;
