import init from './thing'

const audio =
{
    ...init,
    defaultTime : '00:00' ,
    separator   : ' / '
};

export default audio ;
