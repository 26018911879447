const geo =
{
    copy :
    {
        snack   : 'Coordonnées copiées dans le presse-papiers.' ,
        tooltip : 'Copier les coordonnées'
    },
    label : '{0}, {1}',
    title : 'Coordonnées géographiques'
};

export default geo ;
