import initDialog from '../initDialog'

import { SelectThingDialog , styles } from '../SelectThingDialog'

import api from '../../../configs/api'

import Place from '../../../things/Place'

class SelectPlaceDialog extends SelectThingDialog
{
    getLocale = () => this.props.locale.dialogs.select.place ;
}

SelectPlaceDialog.defaultProps =
{
    ...SelectThingDialog.defaultProps,
    clazz : Place ,
    uri   : api.places.url
};

SelectPlaceDialog.propTypes =
{
    ...SelectThingDialog.propTypes,
};

export default initDialog({ styles , useSearch:false })( SelectPlaceDialog ) ;
