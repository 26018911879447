import React, { Component } from 'react'

import clsx from 'clsx'

import PropTypes from 'prop-types'

import isString from 'vegas-js-core/src/isString'

import { Fab, Tooltip } from '@material-ui/core'

import SortIcon from '@material-ui/icons/Sort'

import Animate from '../../transitions/Animate'

class SortButton extends Component
{
    render = () =>
    {
        const {
            className,
            disabled,
            color,
            locale,
            onClick,
            size
        } = this.props;

        let { icon } = this.props ;
        if( !icon )
        {
            icon = <SortIcon/> ;
        }

        let button = (
        <Fab
            disabled = { disabled }
            color    = { color }
            onClick  = { onClick }
            size     = { size }
        >
            { icon }
        </Fab>);

        if( locale )
        {
            const { tooltips } = locale ;
            if( tooltips )
            {
                const { sort } = tooltips ;
                if( isString(sort) )
                {
                    const { placement, tooltipProps } = this.props ;
                    button = (
                        <Tooltip
                            placement = { placement }
                            { ...tooltipProps }
                            title = {sort}
                        >
                            { button }
                        </Tooltip>
                    );
                }
            }
        }

        button = <div className={clsx('ml-10',className)}>{button}</div> ;

        const { animate } = this.props ;
        if( animate )
        {
            const { animateDelay } = this.props;
            return (
                <Animate enter="transition.expandIn" delay={animateDelay}>
                    { button }
                </Animate>);
        }
        else
        {
            return button ;
        }
    };
}

SortButton.defaultProps =
{
    animate      : false ,
    animateDelay : 1000 ,
    className    : null  ,
    color        : 'inherit' ,
    disabled     : false,
    icon         : <SortIcon/>,
    locale       : null,
    onClick      : null,
    placement    : 'left',
    size         : 'small',
    tooltipProps : null
};

SortButton.propTypes =
{
    animate      : PropTypes.bool,
    animateDelay : PropTypes.number,
    className    : PropTypes.string,
    color        : PropTypes.string,
    disabled     : PropTypes.bool,
    icon         : PropTypes.element,
    locale       : PropTypes.object,
    onClick      : PropTypes.func,
    placement    : PropTypes.oneOf(['bottom-end', 'bottom-start', 'bottom', 'left-end', 'left-start', 'left', 'right-end', 'right-start', 'right', 'top-end', 'top-start', 'top']),
    size         : PropTypes.string,
    tooltipProps : PropTypes.object
};

export default SortButton;
