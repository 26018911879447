import initDialog from '../initDialog'

import moment from 'moment'

import { AddDialog } from '../AddDialog'

import identify from '../../../things/identify'

import api from '../../../configs/api'

const styles = () => ({});

export class AddEventDialog extends AddDialog
{
    getLocale = () => this.props.locale.dialogs.application.event.add ;

    prepare = item =>
    {
        let {
            additionalType,
            endDate,
            location,
            name,
            startDate
        } = item ;

        if( endDate instanceof Date )
        {
            endDate.setSeconds(0) ;
            endDate = moment(endDate).toISOString() ; // must be an ISO8601 date
        }
        else
        {
            endDate = null ;
        }

        if( startDate instanceof Date )
        {
            startDate.setSeconds(0) ;
            startDate = moment(startDate).toISOString() ; // must be an ISO8601 date
        }
        else
        {
            startDate = null ;
        }

        additionalType = identify(additionalType) ;

        const { thingRef } = this.props ;
        if( thingRef )
        {
            location = identify(thingRef) ;
        }
        else
        {
            location = identify(location) ;
        }

        return (
        {
            additionalType,
            location,
            name,
            startDate,
            endDate
        });
    };
}

AddEventDialog.defaultProps =
{
    ...AddDialog.defaultProps,
    mock : false ,
    uri  : api.events.url
};

AddEventDialog.propTypes =
{
    ...AddDialog.propTypes
};

export default initDialog({ styles })( AddEventDialog ) ;
