import initDialog from '../initDialog'

import { AddDialog } from '../AddDialog'

import Thing from "../../../things/Thing"

import api from '../../../configs/api'

const styles = () => ({});

export class AddDiseaseDialog extends AddDialog
{
    getLocale = () => this.props.locale.dialogs.application.disease.add ;

    prepare = item =>
    {
        let {
            additionalType,
            level,
            name,
            transmissionMethod
        } = item ;

        return (
        {
            additionalType     : additionalType     instanceof Thing ? String(additionalType.id)     : null ,
            level              : level              instanceof Thing ? String(level.id)              : null ,
            transmissionMethod : transmissionMethod instanceof Thing ? String(transmissionMethod.id) : null ,
            name
        });
    };
}

AddDiseaseDialog.defaultProps =
{
    ...AddDialog.defaultProps,
    uri : api.diseases.url
};

AddDiseaseDialog.propTypes =
{
    ...AddDialog.propTypes
};

export default initDialog({ styles })( AddDiseaseDialog ) ;
