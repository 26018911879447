import React from 'react'

import PeopleIcon from '@material-ui/icons/People'

import withDatasGrid from './withDatasGrid'
import { ThingsGrid } from './ThingsGrid'

import Person     from '../../things/Person'
import PersonCell from '../cells/PersonCell'

import AddPersonDialog    from '../dialogs/add/AddPersonDialog'
import RemovePersonDialog from '../dialogs/remove/RemovePersonDialog'

class PeopleGrid extends ThingsGrid
{
    getLocale = () => this.props.locale.display.grids.people ;
}

PeopleGrid.defaultProps =
{
    ...ThingsGrid.defaultProps,
    AddDialogComponent    : AddPersonDialog,
    breakPoints           : { xs:12, sm:12, md:6, lg:6 , xl:4} ,
    CellComponent         : PersonCell ,
    clazz                 : Person,
    icon                  : <PeopleIcon />,
    mediaIcon             : <PeopleIcon fontSize='large' />,
    mediaProps            : { className : 'bg-hero-diagonal-stripes-2' },
    preferable            : true ,
    RemoveDialogComponent : RemovePersonDialog
};

PeopleGrid.propTypes =
{
    ...ThingsGrid.propTypes
};

export default withDatasGrid()( PeopleGrid ) ;
