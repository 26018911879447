const about =
{
    alternateName :
    {
        title    : 'Dénomination' ,
        tooltips :
        {
            refresh : 'Annuler' ,
            save    : 'Enregister'
        }
    },
    description :
    {
        title    : 'Description' ,
        tooltips :
        {
            refresh : 'Annuler' ,
            save    : 'Enregister'
        }
    }
};

export default about ;