const navigation =
[
    {
        id    : 'item-main-home',
        title : 'Accueil',
        type  : 'item',
        icon  : 'home',
        url   : '/home'
    },
    {
        id    : 'item-main-favorite',
        title : 'Mes Favoris',
        type  : 'item',
        icon  : 'favorite',
        url   : '/me/favorites'
    },
    {
        id    : 'item-main-events',
        title : 'Événements',
        type  : 'item',
        icon  : 'date_range',
        url   : '/events'
    },
    {
        'id'  : 'divider-1' ,
        'type': 'divider'
    },
    {
        id       : 'nav-medical',
        title    : 'Défense Sanitaire ',
        type     : 'collapse',
        icon     : 'security',
        children :
        [
            {
                id    : 'item-medical-livestocks',
                title : 'Élevages',
                type  : 'item',
                icon  : 'terrain',
                exact : true ,
                url   : '/livestocks'
            },
            {
                id    : 'item-medical-veterinarians',
                title : 'Vétérinaires',
                type  : 'item',
                icon  : 'local_hospital',
                exact : true ,
                url   : '/veterinarians'
            },
            {
                id    : 'item-medical-technicians',
                title : 'Techniciens',
                type  : 'item',
                icon  : 'work',
                exact : true ,
                url   : '/technicians'
            },
            {
                id    : 'item-medical-disease',
                title : 'Maladies',
                type  : 'item',
                icon  : 'healing',
                exact : true ,
                url   : '/diseases'
            }
        ]
    },
    {
        id       : 'nav-manager',
        title    : 'Guides',
        type     : 'collapse',
        icon     : 'explore',
        children :
        [
            {
                id    : 'item-manager-courses',
                title : 'Parcours',
                type  : 'item',
                icon  : 'map',
                exact : true ,
                url   : '/courses'
            },
            {
                id    : 'item-manager-stages',
                title : 'Points d\'intérets',
                type  : 'item',
                icon  : 'stars',
                exact : true ,
                url   : '/stages'
            },
            {
                id    : 'item-tools-games',
                title : 'Jeux',
                type  : 'item',
                icon  : 'casino',
                exact : true ,
                url   : '/games'
            }
        ]
    },
    {
        id       : 'nav-sheets',
        title    : 'Fiches',
        type     : 'collapse',
        icon     : 'library_books',
        children :
        [
            {
                id    : 'item-sheets-articles',
                title : 'Articles',
                type  : 'item',
                icon  : 'chrome_reader_mode',
                exact : true ,
                url   : '/articles'
            },
            {
                id    : 'item-sheets-topics',
                title : 'Sujets',
                type  : 'item',
                icon  : 'collections_bookmark',
                exact : true ,
                url   : '/topics'
            },
            {
                id    : 'item-sheets-places',
                title : 'Lieux',
                type  : 'item',
                icon  : 'place',
                exact : true ,
                url   : '/places'
            },
            {
                id    : 'item-sheets-organizations',
                title : 'Organisations',
                type  : 'item',
                icon  : 'domain',
                exact : true ,
                url   : '/organizations'
            },
            {
                id    : 'item-sheets-artworks',
                title : 'Objets',
                type  : 'item',
                icon  : 'category',
                exact : true ,
                url   : '/conceptualObjects'
            },
            {
                id    : 'item-sheets-people',
                title : 'Personnes',
                type  : 'item',
                icon  : 'people',
                exact : true ,
                url   : '/people'
            }
        ]
    },
    {
        id    : 'item-main-media',
        title : 'Médiathèque',
        type  : 'item',
        icon  : 'perm_media',
        url   : '/mediaObjects'
    },
    {
        'id'  : 'divider-2' ,
        'type': 'divider'
    },
    {
        id       : 'nav-tools',
        title    : 'Paramètres',
        type     : 'collapse',
        icon     : 'settings',
        children :
        [
            {
                id    : 'item-tools-applications',
                title : 'Applications',
                type  : 'item',
                icon  : 'apps',
                exact : true ,
                url   : '/applications'
            },
            {
                id    : 'item-tools-thesaurus',
                title : 'Thésaurus',
                type  : 'item',
                icon  : 'note',
                exact : true ,
                url   : '/thesaurus'
            },
            {
                id    : 'item-tools-settings',
                title : 'Configurations',
                type  : 'item',
                icon  : 'settings_applications',
                exact : true ,
                url   : '/settings'
            }
        ]
    },
    {
        id       : 'nav-platform',
        title    : 'Gestion',
        type     : 'collapse',
        icon     : 'group_work',
        children :
        [
            {
                id    : 'item-tools-teams',
                title : 'Équipes',
                type  : 'item',
                icon  : 'group',
                exact : true ,
                url   : '/teams'
            },
            {
                id    : 'item-tools-users',
                title : 'Utilisateurs',
                type  : 'item',
                icon  : 'account_box',
                exact : true ,
                url   : '/users'
            }
        ]
    },
    {
        id       : 'item-profile',
        title    : 'Profil',
        type     : 'collapse',
        icon     : 'account_circle',
        children :
        [
            {
                id    : 'item-profile-account',
                title : 'Mon profil',
                type  : 'item',
                icon  : 'person' ,
                url   : '/me/profile'
            },
            {
                id    : 'item-profile-activities',
                title : 'Mes activités',
                type  : 'item',
                icon  : 'line_weight' ,
                url   : '/me/activities'
            },
            {
                id    : 'item-profile-sessions',
                title : 'Mes sessions',
                type  : 'item',
                icon  : 'cloud_circle' ,
                url   : '/me/sessions'
            }
        ]
    },
    {
        id    : 'item-support',
        title : 'Support',
        type  : 'item',
        icon  : 'live_help',
        exact : true ,
        url   : '/support'
    }
];

export default navigation ;
