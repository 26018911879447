export default
{
    remove :
    {
        agree        : 'Supprimer' ,
        description  : 'Souhaitez-vous supprimer l\'intérêt :' ,
        descriptionX : 'Souhaitez-vous supprimer les intérêts :' ,
        disagree     : 'Annuler' ,
        loading      : 'Suppression en cours...' ,
        title        : 'Supprimer un intérêt ?',
        titleX       : 'Supprimer des intérêts ?'
    }
};
