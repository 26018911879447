import grid from './grid'

const conceptualObjects =
{
    ...grid,
    helmet :
    {
        title : 'Objets' ,
        metas : []
    },
    empty  : 'Aucun objet trouvé',
    search : 'Rechercher un objet ...' ,
    title  : 'Objets'
};

export default conceptualObjects ;
