import RequestStatus from '../../net/RequestStatus'

const defaultProperties =
{
    favoriteStatus : RequestStatus.NEW,
    user           : null ,

    isFavorite     : () => null ,
    toggleFavorite : () => null ,

    changeUser : () => null ,
    readUser   : () => null ,
    setUser    : () => null ,
    writeUser  : () => null
};

export default defaultProperties;