import React, { Component } from 'react'

import clsx from 'clsx'

import PropTypes from 'prop-types'

import isString from 'vegas-js-core/src/isString'

import { withStyles } from '@material-ui/core/styles'

import { Fab, Tooltip } from '@material-ui/core'

import PrintIcon from '@material-ui/icons/Print'

const styles =
{
    root :
    {
        marginLeft : 10
    }
};

class PrintButton extends Component
{
    render = () =>
    {
        const {
            className,
            classes,
            color,
            disabled,
            locale,
            onClick,
            placement
        } = this.props;

        let { icon } = this.props ;
        if( !icon )
        {
            icon = <PrintIcon/>;
        }

        let button = (
        <Fab
            color    = { color }
            disabled = { disabled }
            onClick  = { onClick }
            size     = 'small'
        >
            { icon }
        </Fab>);

        if( !disabled && locale )
        {
            const { tooltips } = locale ;
            if( tooltips )
            {
                const { print } = tooltips ;
                if( isString(print) )
                {
                   button = (
                    <Tooltip
                        placement = { placement }
                        title     = { print }
                    >
                        { button }
                    </Tooltip>);
                }
            }
        }

        return <div className={clsx(classes.option,className)}>{button}</div> ;
    };
}

PrintButton.defaultProps =
{
    className : null  ,
    color     : 'secondary' ,
    disabled  : false,
    icon      : <PrintIcon/>,
    locale    : null,
    onClick   : null,
    placement : 'left'
};

PrintButton.propTypes =
{
    classes   : PropTypes.object.isRequired ,
    className : PropTypes.string,
    color     : PropTypes.string,
    disabled  : PropTypes.bool,
    icon      : PropTypes.element,
    locale    : PropTypes.object,
    onClick   : PropTypes.func,
    placement : PropTypes.oneOf(['bottom-end', 'bottom-start', 'bottom', 'left-end', 'left-start', 'left', 'right-end', 'right-start', 'right', 'top-end', 'top-start', 'top']),
};

export default withStyles( styles )( PrintButton );
