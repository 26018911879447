import initDialog from '../initDialog'

import { RemoveDialog } from '../RemoveDialog'

import api from '../../../configs/api'

const styles = () => ({});

class RemovePersonDialog extends RemoveDialog
{
    getLocale = () => this.props.locale.dialogs.application.person.remove ;
}

RemovePersonDialog.defaultProps =
{
    ...RemoveDialog.defaultProps,
    uri    : api.people.url,
    useUrl : true
};

RemovePersonDialog.propTypes =
{
    ...RemoveDialog.propTypes
};

export default initDialog({ styles })( RemovePersonDialog );
