
import Review from '../../Review'

/**
 * A water treatment review.
 * @memberOf things.livestock.reviews
 * @extends things.Review
 */
class WaterTreatmentReview extends Review
{
    /**
     * Creates a new WaterTreatmentReview instance.
     * @constructor
     * @param object The generic object to initialize the object.
     */
    constructor( object = null )
    {
        super() ;
        
        /**
         * Indicates whether an annual analysis is applied to the site.
         * @type {boolean}
         */
        this.annualAnalysis = false ;
        
        /**
         * Indicates if the water is confirmed drinking by an annual specific analysis.
         * @type {boolean}
         */
        this.annualPotability = false ;
        
        /**
         * Indicates if a drinking treatment is applied on the site.
         * @type {boolean}
         */
        this.drinkingTreatment = false ;
        
        /**
         * Indicates if the water is filtered on the site.
         * @type {boolean}
         */
        this.filtered = false ;
        
        this.set( object ) ;
    }
    
    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        super.clear() ;
        this.annualAnalysis    =
        this.annualPotability  =
        this.drinkingTreatment =
        this.filtered          = false ;
        return this ;
    }
    
    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new WaterTreatmentReview( this.toObject() ) ;
    }
    
    /**
     * Returns the generic object representation of the Object.
     * @return {Object} The generic object representation of the Object.
     */
    toObject( full = false )
    {
        let {
            annualAnalysis,
            annualPotability,
            drinkingTreatment,
            filtered
        } = this ;

        return {
            ...( full && super.toObject() ) ,
            annualAnalysis,
            annualPotability,
            drinkingTreatment,
            filtered
        };
    }
    
}

export default WaterTreatmentReview ;