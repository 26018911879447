import initDialog from '../initDialog'

import { AddDialog } from '../AddDialog'

import Thing from "../../../things/Thing"

import api from '../../../configs/api'

const styles = () => ({});

export class AddOrganizationDialog extends AddDialog
{
    getLocale = () => this.props.locale.dialogs.application.organization.add ;

    prepare = item =>
    {
        const {
            additionalType,
            name
        } = item ;

        return {
            additionalType : additionalType instanceof Thing ? String(additionalType.id) : null ,
            name           : name
        }
    };
}

AddOrganizationDialog.defaultProps =
{
    ...AddDialog.defaultProps,
    uri : api.organizations.url
};

AddOrganizationDialog.propTypes =
{
    ...AddDialog.propTypes
};

export default initDialog({ styles })( AddOrganizationDialog ) ;
