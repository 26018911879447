import list from './list'

const mediaObjects =
{
    ...list,
    actions :
    {
        embed  : 'Ajouter un média externe',
        sort   : 'Trier les médias' ,
        upload : 'Téléverser un fichier'
    },
    helmet :
    {
        title : 'Médiathèque' ,
        metas : []
    },
    empty  : 'Aucun média trouvé',
    search : 'Rechercher un média ...' ,
    title  : 'Médiathèque'
};

export default mediaObjects ;
