import React from 'react'

import clsx from 'clsx'

import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'

const styles = theme => (
{
    root:
    {
        padding        : '0 7px',
        fontSize       : 11,
        fontWeight     : 600,
        height         : 20,
        minWidth       : 20,
        borderRadius   : 20,
        display        : 'flex',
        alignItems     : 'center',
        backgroundColor: theme.palette.secondary.main,
        color          : theme.palette.secondary.contrastText
    }
});

function NavigationBadge({classes, className, badge})
{

    return (
        <div
            className={clsx(classes.root, className)}
            style={{ backgroundColor: badge.bg, color : badge.fg }}
        >
            {badge.title}
        </div>
    )
}

NavigationBadge.defaultProps =
{

};

NavigationBadge.propTypes =
{
    badge: PropTypes.shape(
    {
        bg    : PropTypes.string,
        fg    : PropTypes.string,
        title : PropTypes.node
    })
};

export default withStyles( styles )( NavigationBadge );
