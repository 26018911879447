import grid from './grid'

const stages =
{
    ...grid,
    helmet :
    {
        title : 'Points d\'intérêt' ,
        metas : []
    },
    empty  : 'Aucun point d\'intérêt trouvé',
    search : 'Rechercher un points d\'intérêt ...' ,
    title  : 'Points d\'intérêt'
};

export default stages ;
