import generify from '../../generify'

import AnimalHealthReview   from './AnimalHealthReview'
import WaterTreatmentReview from './WaterTreatmentReview'
import WorkplaceReview      from './WorkplaceReview'
import Sector               from '../../Sector'
import Workplace            from '../../Workplace'
import Workshop             from '../../Workshop'

/**
 * An annual health review.
 * @memberOf things.livestock.reviews
 * @extends things.livestock.AnimalHealthReview
 */
class AnnualHealthReview extends AnimalHealthReview
{
    /**
     * Creates a new AnnualHealthReview instance.
     * @constructor
     * @param object The generic object to initialize the object.
     */
    constructor( object = null )
    {
        super() ;
        this.waterTreatment = null ;
        this.set( object ) ;
        this.populate() ;
    }
    
    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        super.clear() ;
        this.waterTreatment = null ;
    }
    
    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new AnnualHealthReview( this.toObject() ) ;
    }
    
    /**
     * Populate the object with specific behaviors or types. Launched by default in the constructor and in the set methods.
     * @returns {Thing} The current reference.
     */
    populate()
    {
        super.populate() ;
        
        let {
            about,
            subReview,
            waterTreatment
        } = this ;
        
        if( !(waterTreatment instanceof WaterTreatmentReview) )
        {
            this.waterTreatment = new WaterTreatmentReview(waterTreatment);
        }
        
        if( subReview instanceof Array )
        {
            this.subReview = subReview.map(
                item => item instanceof WorkplaceReview
                      ? item
                      : new WorkplaceReview( { ...item, subjectOf:this } )
            ) ;
        }
        else if( about )
        {
            const { workplaces } = about ;
            if( workplaces instanceof Array && workplaces.length > 0 )
            {
                this.subReview = workplaces.map( item => new WorkplaceReview({ about:item , subjectOf:this }) );
            }
        }
        return this ;
    }
    
    /**
     * Search in the about Workshop reference a specific Sector element with a specific index.
     * @param {number} index - The index of the sector to find.
     * @returns {Sector} The sector reference or null.
     */
    findSectorByIndex = ( index ) =>
    {
        const { about } = this ;
        if( about instanceof Workshop )
        {
            const { workplaces } = about ;
            if( workplaces instanceof Array )
            {
                let len = workplaces.length ;
                for( let i = 0 ; i<len ; i++ )
                {
                    let workplace = workplaces[i] ;
                    if( workplace )
                    {
                        const { sectors } = workplace ;
                        const find = sectors.find( item => (item instanceof Sector) && item.id === index ) ;
                        if( find )
                        {
                            return find ;
                        }
                    }
                }
            }
        }
        return null ;
    };
    
    /**
     * Search in the about Workshop reference a specific Workplace element with a specific index.
     * @param {number} index - The index of the workplace to find.
     * @returns {Workplace} The workplace reference or null.
     */
    findWorkplaceByIndex = ( index ) =>
    {
        const { about } = this ;
        if( about instanceof Workshop )
        {
            const { workplaces } = about ;
            if( workplaces instanceof Array )
            {
                return workplaces.find( item => (item instanceof Workplace) && item.id === index ) ;
            }
        }
        return null ;
    };
    
    /**
     * Returns the generic object representation of the Object.
     * @param {boolean} [full=false] - Indicates if the generic object contains all the properties of only the basic implementation.
     * @return {Object} The generic object representation of the Object.
     */
    toObject( full = false )
    {
        let {
            waterTreatment
        }
        = this ;
        
        waterTreatment = generify(waterTreatment,true) ;

        return {
            ...super.toObject(full) ,
            waterTreatment
        };
    }
}

export default AnnualHealthReview ;