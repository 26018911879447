const photos =
{
    add :
    {
       agree       : 'Ajouter' ,
       description : 'Sélectionner ou ajouter une photo' ,
       disagree    : 'Annuler' ,
       loading     : 'Ajout en cours...' ,
       title       : 'Ajouter une photo',
    },
    empty   : 'Aucune photo disponible' ,
    loading : 'Enregistrement en cours',
    remove :
    {
        agree        : 'Supprimer' ,
        description  : 'Souhaitez-vous supprimer cette photo?' ,
        descriptionX : 'Souhaitez-vous supprimer les {0} photos sélectionnées?' ,
        disagree     : 'Annuler' ,
        loading      : 'Suppression en cours...' ,
        title        : 'Supprimer la photo ?',
        titleX       : 'Supprimer les photos ?'
    },
    removed  : 'Suppression réussie',
    success  : 'Enregistrement réussi',
    title    : 'Photos',
    tooltips :
    {
        add : 'Ajouter'
    }
};

export default photos ;