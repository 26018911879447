import React from 'react'

import PropTypes from 'prop-types'

import clsx from 'clsx'

import { Link as LinkRouter } from 'react-router-dom'

import isString from 'vegas-js-core/src/isString'
import notEmpty from 'vegas-js-core/src/strings/notEmpty'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import { Link, Tooltip, Typography } from '@material-ui/core'

import DragIndicator from '@material-ui/icons/DragIndicator'

import getLocaleHeadline   from '../../things/getLocaleHeadline'
import getLocaleProperty   from '../../things/getLocaleProperty'
import getLocaleThingLabel from '../../things/getLocaleThingLabel'

import CreatedLabel   from '../labels/CreatedLabel'
import ModifiedLabel  from '../labels/ModifiedLabel'
import Person         from '../../things/Person'
import Thing          from '../../things/Thing'
import ThingAvatar    from '../avatars/ThingAvatar'
import ThingComponent from '../things/ThingComponent'
import TypeLabel      from '../labels/TypeLabel'
import withi18n       from '../../contexts/i18n/withi18n'
import WordLabel      from '../labels/WordLabel'
import Word           from '../../things/Word'

import api from '../../configs/api'

import getHorizontalDivider  from '../getHorizontalDivider'
import getHorizontalElements from '../getHorizontalElements'

export const BUTTON = 'button';
export const LABEL  = 'label';
export const LINK   = 'link';
export const NONE   = 'none';

export const modes =
[
    BUTTON, LABEL, LINK, NONE
];

export class ThingCell extends ThingComponent
{
    getAvatar = ( { className = null } = {} ) =>
    {
        const { avatarable:visible } = this.props;
        if ( !visible )
        {
            return null;
        }

        const thing = this.getAvatarThing instanceof Function
                    ? this.getAvatarThing()
                    : this.getThing();

        const { avatar } = this.props;
        if ( avatar )
        {
            if( avatar instanceof Function )
            {
                return avatar(thing) ;
            }
            return avatar ;
        }

        if ( thing instanceof Thing )
        {
            const {
                avatarSize,
                avatarStyle,
                avatarVariant,
            } = this.props ;

            return (
                <ThingAvatar
                    className = { clsx( 'mr-16' , className ) }
                    icon      = { this.getAvatarIcon() }
                    size      = { avatarSize }
                    thing     = { thing }
                    style     = { avatarStyle }
                    variant   = { avatarVariant }
                />
            ) ;
        }
        return null ;
    } ;

    getAvatarIcon = () => this.props.icon ;

    getAvatarThing = null ;

    getBody = () =>
    {
        const { body, variant } = this.props ;
        if( body && variant === 'full' )
        {
            const content = this.getContent() ;
            if ( content )
            {
                const horizontal = getHorizontalDivider();
                return (
                     <div className="flex flex-col w-full">
                        { horizontal }
                        <div className="w-full flex flex-col md:flex-row md:flex-wrap md:items-center">
                            { content }
                        </div>
                    </div>
                );
            }
        }
        return null ;
    };

    getContent = () => null ;

    getCreated = ({ className = 'my-4' } = {}) =>
    {
        const { created:visible, thing } = this.props ;
        if( visible && thing instanceof Thing )
        {
            let { created } = thing ;
            if( created )
            {
                return <CreatedLabel className={ className } thing={ thing }/>;
            }
        }
        return null ;
    };

    getDividers = () =>
    {
        const { divider } = this.props ;
        if( divider )
        {
            const {
                dividerPosition,
                dividerProps
            } = this.props ;

            let bottom ;
            let top ;

            const element = getHorizontalDivider({ props:dividerProps } );
            switch( dividerPosition )
            {
                case 'bottom' :
                {
                    bottom = element ;
                    break ;
                }
                case 'both' :
                {
                    bottom = element ;
                    top    = element ;
                    break ;
                }
                case 'top' :
                {
                    top = element ;
                    break ;
                }
                default :
                {
                    //
                }
            }

            return { bottom , top } ;
        }
        return { } ;
    }

    getDragIndicator = ( { className = null } = {} ) =>
    {
        let {
            draggable: visible ,
            dragIndicator
        } = this.props;
        if ( !visible )
        {
            return null;
        }

        if( dragIndicator )
        {
            const { tooltips : { draggable:tip } = {} } = this.getLocale() || {} ;
            if( notEmpty(tip) )
            {
                dragIndicator = (
                    <Tooltip title={tip} placement='top'>
                        { dragIndicator }
                    </Tooltip>
                );
            }
        }

        return (
            <div className={ clsx( 'mr-4' , className ) } >
                { dragIndicator }
            </div>
        );
    }

    getFooter = () =>
    {
        const { footer } = this.props ;
        if( footer )
        {
            const {
                className,
                metadatas,
                variant
            } = this.props;
            return (
                <div className={ clsx( "flex flex-col w-full" , className ) } >
                    { ( variant === 'full' ) && metadatas && getHorizontalDivider() }
                    <div className="flex flex-row items-center w-full justify-between" >
                        { ( variant === 'full' ) && metadatas && this.getMetaDatas() }
                    </div>
                </div>
            );
        }
        return null ;
    };

    getHeader = () =>
    {
        const { header , thing } = this.props ;
        if ( header && thing instanceof Thing )
        {
            const {
                avatarable,
                draggable,
                optionable,
                titleable,
                typable,
                typeMember,
            } = this.props;

            return (
                <div className="flex flex-row items-center w-full justify-between">
                    <div className="flex flex-row items-center">
                        { draggable && this.getDragIndicator() }
                        { avatarable && this.getAvatar() }
                        <div className='flex flex-col flex-1'>
                            { titleable && this.getTitle({ className:'flex flex-0'}) }
                            { typable   && this.getType({ className:'my-4' , member:typeMember }) }
                        </div>
                    </div>
                    { optionable && <div className='inline-flex'>{ this.getOptions() }</div> }
                </div>
            );
        }
        return null ;
    };

    getLeft = () => null ;

    getLocale = () => this.props.locale.display.cells.thing;

    getLocaleSubtitle = ( { notEqualTo = null } ) =>
    {
        const thing = this.getThing();
        if( thing instanceof Thing )
        {
            const { lang, subtitleVariant } = this.props ;
            switch( subtitleVariant )
            {
                case 'alternateName'       :
                case 'alternativeHeadline' :
                case 'headline'            :
                case 'name'                :
                {
                    const label = thing.getLocaleProperty(subtitleVariant)(lang) ;
                    if( label !== notEqualTo )
                    {
                        return label ;
                    }
                    break ;
                }
                default :
                {
                    //
                }
            }
        }
        return null ;
    };

    /**
     * A custom method used in the getLocaleTitle method to transform the current thing representation and display the good title.
     * @type {Function|null}
     */
    getLocaleThing = null ;

    getLocaleTitle = () =>
    {
        const thing = this.getLocaleThing instanceof Function
                    ? this.getLocaleThing()
                    : this.getThing();

        if( thing instanceof Thing )
        {
            const { lang, titleUpperFirst, titleVariant } = this.props ;
            switch( titleVariant )
            {
                case 'auto' :
                {
                    let title ;
                    if( thing instanceof Person )
                    {
                        title = thing.getFullNameOrUsername( lang ) ;
                        if( notEmpty(title) )
                        {
                            return titleUpperFirst ? ucFirst(title) : title ;
                        }
                    }

                    title = getLocaleHeadline( thing , lang ) ;
                    if( notEmpty(title) )
                    {
                        return titleUpperFirst ? ucFirst(title) : title ;
                    }

                    return getLocaleThingLabel(thing, lang, titleUpperFirst) ;
                }
                case 'default' :
                {
                    return getLocaleThingLabel(thing, lang, titleUpperFirst) ;
                }
                case 'name'     :
                case 'headline' :
                {
                    let text = getLocaleProperty(titleVariant)(thing,lang) ;
                    return titleUpperFirst ? ucFirst(text) : text ;
                }
                default : // none
                {
                    break ;
                }
            }
        }
        return null ;
    };

    getLocaleTitleTip = () => null ;

    getMetaDatas = ({ className = 'py-4 text-gray-500' } = {} ) =>
    {
        const created  = this.getCreated() ;
        const modified = this.getModified() ;
        return getHorizontalElements({ className , elements:[created,modified] }) ;
    };

    getModified = ({ className = null } = {} ) =>
    {
        const { modified:visible, thing } = this.props ;
        if( visible && thing instanceof Thing )
        {
            let { modified } = thing ;
            if( modified )
            {
                return <ModifiedLabel className={ className } thing={ thing }/>;
            }
        }
        return null ;
    };

    getOptions = () => this.props.options ;

    getPath = () =>
    {
        const url = this.getUrl();
        if ( isString(url) )
        {
            return url.split(api.url)[1] ;
        }
    };

    getRight = () => null ;

    getSubtitle = ( { className = null , notEqualTo = null } = {} ) =>
    {
        let text = this.getLocaleSubtitle({ notEqualTo }) ;
        if( notEmpty(text) )
        {
            const { subtitleProps } = this.props;
            return (
                <div className={ clsx("flex flex-row items-center", className ) } >
                    <Typography variant='caption' { ...subtitleProps } >
                        { text }
                    </Typography>
                </div>
            );
        }
        else
        {
            return null ;
        }
    };

    getTitle = ( { className = null } = {} ) =>
    {
        let title ;

        const thing = this.getThing();
        if( thing )
        {
            title = this.getLocaleTitle() ;
        }

        if ( title )
        {
            let {
                mode ,
                onClick,
                titleProps
            } = this.props ;

            onClick = onClick || this.onClick ;

            let TitleComponent = mode === BUTTON ? Link : Typography ;

            const { className:titleClass , ...options } = titleProps || {} ;

            title = (
                <TitleComponent
                    style = {{ textDecoration: 'none' }}

                    className = { clsx (
                        'font-medium no-underline hover:no-underline' ,
                        titleClass ,
                        ( mode === LABEL ) ? 'text-gray-700' : 'text-blue-700 hover:text-blue-800 transition-color duration-300 ease-in-out' ,
                        ( mode === BUTTON ) && onClick && "cursor-pointer"
                    )}
                    { ...( onClick && { onClick } ) }

                    { ...( ( mode === BUTTON ) && { variant:'button' , underline:'none' } ) }

                    { ...options }
                >
                    { title }
                </TitleComponent>
            );

            if( mode === LINK )
            {
                const pathname = this.getPath() ;
                if ( isString( pathname ) )
                {
                    title = (
                        <LinkRouter
                            className = 'font-medium no-underline hover:no-underline hover:text-red-500'
                            style     = {{ color: 'inherit', textDecoration: 'inherit' }}
                            to        = {{ pathname }}
                        >
                            { title }
                        </LinkRouter>
                    ) ;
                }
            }
        }
        else
        {
            const locale = this.getLocale() ;
            title = locale.unknown ;
        }

        const tip = this.getLocaleTitleTip() ;
        if( notEmpty(tip) )
        {
            title = (
                <Tooltip title={tip} placement='top' >
                    { title }
                </Tooltip>
            )
        }

        return (
            <div className={clsx("flex flex-col w-full", className)}>
                { title }
                { this.getSubtitle({ notEqualTo:title }) }
            </div>
        );
    };

    getType = ( { member = 'additionalType' , className = null } = {} ) =>
    {
        const thing = this.getThing() ;
        if( thing && thing[member] )
        {
            const {
                typeClassName ,
                typeUpperFirst
            } = this.props ;
            return (
                <TypeLabel
                    className      = { className }
                    labelClassName = { typeClassName }
                    member         = { member }
                    upperFirst     = { typeUpperFirst }
                    thing          = { thing }
                />
            );
        }
        return null ;
    };

    getUrl = () =>
    {
        const { thing : { url } = {} } = this.props ;
        return url ;
    };

    getWord = ( member , icon = null ) => ( { className = null , key = undefined } = {} ) =>
    {
        const { [member]:visible } = this.props;
        if (!visible)
        {
            return null;
        }

        const thing = this.getThing();
        if ( thing )
        {
            const { [member]:word } = thing;
            if ( word instanceof Word )
            {
                let title ;
                const locale = this.getLocale() ;
                if( locale && locale[member] )
                {
                    const item = locale[member] ;
                    if( item )
                    {
                        const { title:text } = item ;
                        if( notEmpty(text))
                        {
                            title = text ;
                        }
                    }
                }
                return <WordLabel key={key} className={className} thing={word} title={title} { ...( icon && { icon } ) } /> ;
            }
        }
        return null;
    };

    render = () =>
    {
        const { thing } = this.props ;
        if (thing instanceof Thing )
        {
            const {
                className,
                left,
                right,
                style
            } = this.props;

            const { bottom , top } = this.getDividers() ;

            return (
                <div className = { clsx( 'flex-1 flex flex-row' , className ) } >
                    { left && this.getLeft() }
                    <div
                        className = { clsx( 'flex-1 flex flex-col py-8' ) }
                        style     = { style }
                    >
                        { top }
                        { this.getHeader() }
                        { this.getBody() }
                        { this.getFooter() }
                        { bottom }
                    </div>
                    { right && this.getRight() }
                </div>
            );
        }
        return null ;
    };
}

ThingCell.defaultProps =
{
    ...ThingComponent.defaultProps,
    avatarable      : true,
    avatar          : null,
    avatarSize      : 'default',
    avatarStyle     : null,
    avatarVariant   : 'rounded',
    body            : true,
    created         : true,
    draggable       : false,
    dragIndicator   : <DragIndicator/>,
    divider         : false,
    dividerPosition : 'bottom',
    dividerProps    : null,
    editable        : true,
    footer          : true,
    header          : true,
    icon            : null,
    left            : false,
    locale          : {},
    metadatas       : true,
    mode            : LINK,
    modified        : true,
    onClick         : null,
    onSelect        : null,
    options         : null,
    optionable      : true,
    right           : false,
    selectable      : true,
    selected        : false ,
    subtitleProps   : null,
    subtitleVariant : 'none',
    titleable       : true,
    titleProps      : null,
    titleUpperFirst : true,
    titleVariant    : 'auto',
    typable         : true,
    typeClassName   : null,
    typeMember      : 'additionalType',
    typeUpperFirst  : true,
    variant         : 'full'
};

ThingCell.propTypes =
{
    ...ThingComponent.propTypes,
    avatar          : PropTypes.oneOfType([PropTypes.element,PropTypes.func]),
    avatarable      : PropTypes.bool,
    avatarSize      : PropTypes.oneOf(['default','large','small']),
    avatarStyle     : PropTypes.object,
    avatarVariant   : PropTypes.oneOf(['circle','rounded','square']),
    body            : PropTypes.bool,
    created         : PropTypes.bool,
    draggable       : PropTypes.bool,
    divider         : PropTypes.bool,
    dividerProps    : PropTypes.object,
    dividerPosition : PropTypes.oneOf(['bottom','both','none','top']),
    editable        : PropTypes.bool,
    footer          : PropTypes.bool,
    header          : PropTypes.bool,
    icon            : PropTypes.element,
    left            : PropTypes.bool,
    locale          : PropTypes.object.isRequired,
    metadatas       : PropTypes.bool,
    mode            : PropTypes.oneOf(modes),
    modified        : PropTypes.bool,
    onClick         : PropTypes.func,
    onSelect        : PropTypes.func,
    options         : PropTypes.node,
    optionable      : PropTypes.bool,
    right           : PropTypes.bool,
    selected        : PropTypes.bool ,
    selectable      : PropTypes.bool ,
    subtitleProps   : PropTypes.object,
    subtitleVariant : PropTypes.oneOf(['alternateName','alternativeHeadline','none','headline','name']),
    titleVariant    : PropTypes.oneOf(['auto','name','default','headline','none']),
    thing           : PropTypes.instanceOf(Thing),
    titleable       : PropTypes.bool,
    titleProps      : PropTypes.object,
    titleUpperFirst : PropTypes.bool,
    typable         : PropTypes.bool,
    typeClassName   : PropTypes.string,
    typeMember      : PropTypes.string,
    typeUpperFirst  : PropTypes.bool,
    variant         : PropTypes.oneOf(['default','full'])
};

export default withi18n(ThingCell) ;
