import grey from '@material-ui/core/colors/grey'

const styles = () =>
({
    addButton:
    {
        position : 'absolute',
        right    : 12,
        top      : 12,
        zIndex   : 500
    },
    container :
    {
        bottom    : 0,
        left      : 0,
        position  : 'absolute',
        right     : 0,
        top       : 0,
    },
    root :
    {
        backgroundColor : grey[100],
        display         : 'flex',
        position        : 'relative',
        margin          : 0,
        padding         : 0,
        width           : '100%',
        height          : '100%'
    }
});

export default styles ;
