import React from 'react'

import DomainIcon from '@material-ui/icons/Domain'

import withDatasGrid from './withDatasGrid'
import { ThingsGrid } from './ThingsGrid'

import Organization     from '../../things/Organization'
import OrganizationCell from '../cells/OrganizationCell'

import AddOrganizationDialog    from '../dialogs/add/AddOrganizationDialog'
import RemoveOrganizationDialog from '../dialogs/remove/RemoveOrganizationDialog'

class OrganizationsGrid extends ThingsGrid
{
    getLocale = () => this.props.locale.display.grids.organizations ;
}

OrganizationsGrid.defaultProps =
{
    ...ThingsGrid.defaultProps,
    AddDialogComponent    : AddOrganizationDialog,
    CellComponent         : OrganizationCell ,
    clazz                 : Organization,
    icon                  : <DomainIcon />,
    mediaIcon             : <DomainIcon fontSize='large' />,
    mediaProps            : { className : 'bg-hero-graph-paper-2' },
    preferable            : true ,
    RemoveDialogComponent : RemoveOrganizationDialog
};

OrganizationsGrid.propTypes =
{
    ...ThingsGrid.propTypes
};

export default withDatasGrid()( OrganizationsGrid ) ;
