import generify from './generify'

import Event from './Event'
import Person from './Person'

/**
 * An observation event.
 * @memberOf things
 */
class Observation extends Event
{
    /**
     * Creates a new Observation instance.
     * @constructor
     * @param object The generic object to initialize the object.
     */
    constructor( object = null )
    {
        super() ;

        /**
         * Someone acting for this observation.
         * @type {Array|Person}
         */
        this.actor = null  ;

        /**
         * Someone attending for this observation.
         * @type {Array|Person}
         */
        this.attendee = null  ;

        /**
         * An authority of the observation
         * @type {Veterinarian|Technician|Array}
         */
        this.authority = null ;

        /**
         * Information about the first owner of this event.
         * @type {Object|Thing}
         */
        this.firstOwner = null ;

        /**
         * Information about a certain Person or User owned an observation.
         * @type {Object|Thing}
         */
        this.owner = null ;

        this.set( object ) ;
    }

    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        super.clear() ;
        this.actor      =
        this.attendee   =
        this.authority  =
        this.firstOwner =
        this.owner      = null ;
        return this ;
    }

    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new Observation( this.toObject() ) ;
    }

    /**
     * Creates if exist the authority value object.
     * @param thing The object to transform.
     * @returns {*} The authority value object.
     */
    createAuthority( thing ) { return thing }

    /**
     * Creates if exist the owner value object.
     * @param thing The object to transform.
     * @returns {*} The owner value object.
     */
    createOwner( thing ) { return thing }

    /**
     * Populate the object with specific behaviors or types.
     * Launched by default in the constructor and in the set methods.
     * @returns {Thing} The current reference.
     */
    populate()
    {
        super.populate() ;

        const {
            actor,
            attendee,
            authority,
            firstOwner,
            owner
        } = this ;

        if( actor )
        {
            if( actor instanceof Array )
            {
                this.actor = actor.map( item => item instanceof Person ? item : new Person(item) ) ;
            }
            else
            {
                this.actor = new Person( actor ) ;
            }
        }

        if( attendee )
        {
            if( attendee instanceof Array )
            {
                this.attendee = attendee.map( item => item instanceof Person ? item : new Person(item) ) ;
            }
            else
            {
                this.attendee = new Person( attendee ) ;
            }
        }

        this.authority  = this.createAuthority(authority) ;
        this.firstOwner = this.createOwner(firstOwner) ;
        this.owner      = this.createOwner(owner) ;

        return this ;
    }

    /**
     * Returns the generic object representation of the item.
     * @return {Object} The generic object representation of the item.
     */
    toObject()
    {
        let {
            actor,
            attendee,
            authority,
            firstOwner,
            owner
        } = this ;

        actor      = generify(actor);
        attendee   = generify(attendee);
        authority  = generify(authority);
        firstOwner = generify(firstOwner);
        owner      = generify(owner) ;

        return {
            ...super.toObject() ,
            actor,
            attendee,
            authority,
            firstOwner,
            owner
        };
    }
}

export default Observation ;
