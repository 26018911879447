import moment from 'moment'

/**
 * Defines the access token value object and helper to save the access token on the local storage.
 * @memberOf things
 */
class AccessToken
{
    /**
     * Creates a new AccessToken instance.
     * @constructor
     * @param object The generic object to initialize the object the first time.
     * @param insert
     */
    constructor( object = null , insert = false )
    {
        this.access_token  = null ;
        this.expires_in    = 0    ;
        this.id            = null ;
        this.refresh_token = null ;
        this.scope         = null ;
        this.timestamp     = 0    ;
        this.token_type    = null ;

        if( object )
        {
            this.set( object , insert ) ;
        }
    }

    get expired()
    {
        return moment().valueOf() > this.timestamp ;
    }

    /**
     * Clear the object.
     */
    clear()
    {
        this.access_token  = null ;
        this.expires_in    = 0 ;
        this.id            = null ;
        this.refresh_token = null ;
        this.scope         = null ;
        this.timestamp     = 0 ;
        this.token_type    = null ;
        return this ;
    }

    /**
     * Sets the object with a generic object and write it on the server.
     */
    set( object , insert = false )
    {
        for( let prop in object )
        {

            if( prop in this )
            {
                if( object.hasOwnProperty(prop)) {
                    this[prop] = object[prop];
                }
            }
        }

        if( insert )
        {
            if( (this.expires_in instanceof String) || (typeof(this.expires_in) === 'string') )
            {
                this.expires_in *= 1 ;
            }

            let date = moment() ;
            if( this.expires_in > 0 )
            {
                date.add( this.expires_in , 's' ) ;
            }

            this.timestamp = date.valueOf() ;
        }

        return this ;
    }

    /**
     * Returns the generic object representation of the object.
     * @returns {{access_token: *, expire_in: number, refresh_token: *, timestamp: *, token_type: *, scope: *}}
     */
    toObject()
    {
        return {
            access_token  : this.access_token,
            expires_in    : this.expires_in ,
            refresh_token : this.refresh_token,
            timestamp     : this.timestamp ,
            token_type    : this.token_type ,
            scope         : this.scope
        };
    }
}

export default AccessToken ;