
import Review   from '../../Review'
import Workshop from '../../Workshop'

/**
 * An animal health review.
 * @memberOf things.livestock
 * @extends things.Review
 */
class AnimalHealthReview extends Review
{
    /**
     * Creates a new AnimalHealthReview instance.
     * @constructor
     * @param object The generic object to initialize the object.
     */
    constructor( object = null )
    {
        super( object ) ;
    }
    
    /**
     * Populate the object with specific behaviors or types. Launched by default in the constructor and in the set methods.
     * @returns {Thing} The current reference.
     */
    populate( full = false )
    {
        super.populate( full ) ;
        
        let { about } = this ;
        
        if( about && !(about instanceof Workshop) )
        {
            this.about = new Workshop(about);
        }
        
        return this ;
    }
    
    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new AnimalHealthReview( this.toObject() ) ;
    }
}

export default AnimalHealthReview ;