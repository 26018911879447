import React from 'react'

import PropTypes from 'prop-types'


import isString from 'vegas-js-core/src/isString'
import notEmpty from 'vegas-js-core/src/strings/notEmpty'

import PersonIcon from '@material-ui/icons/Person'

import { ThingLabel } from './ThingLabel'

import Person from '../../things/Person'

import withi18n  from '../../contexts/i18n/withi18n'
import withSnack from '../../contexts/snack/withSnack'

export class PersonLabel extends ThingLabel 
{  
    getLocale = () => this.props.locale.display.labels.person;
    
    getLocaleLabel = thing =>
    {
        let label ;
        if( thing instanceof Person )
        {
            label = thing.getFullNameOrUsername( this.props.lang ) ;
        }
        
        if( notEmpty(label) )
        {
            return label ;
        }
        else
        {
             const locale = this.getLocale() ;
             const { unknown } = locale ;
             if( notEmpty(unknown) )
             {
                 return unknown ;
             }
        }
        return null ;
    }
    
    getThing = () =>
    {
        const { thing } = this.props ;
        if ( thing instanceof Person ) 
        {
            return thing ;
        }
        else if( thing )
        {
            const { member } = this.props ;
            if( isString( member ) && (thing[member] instanceof Person) )
            {
                return thing[member] ;
            }
        }
        return null ;
    }
}
    
PersonLabel.defaultProps =
{
    ...ThingLabel.defaultProps,
    icon   : <PersonIcon fontSize='small' /> ,
    member : null ,
    upper  : true 
}

PersonLabel.propTypes =
{
    ...ThingLabel.propTypes,
    member : PropTypes.string ,
    upper  : PropTypes.bool
}

export default withSnack( withi18n( PersonLabel ) );