import React , { forwardRef } from 'react' ;

import LangConsumer from './LangConsumer'

const withLang = Component => forwardRef( ( props , ref ) =>
(
    <LangConsumer>
        { store => <Component {...props} {...store} ref={ref}/> }
    </LangConsumer>
));

export default withLang ;