import sectorHealthEvent from './events/sectorHealthEvent'
import vaccinationEvent  from './events/vaccinationEvent'

import sectorHealthEvents from './reviews/annual/events/sectorHealthEvents'

const events =
{
    sectorHealthEvent,
    vaccinationEvent,
    sectorHealthEvents,
};

export default events ;