const round = ( value , decimals = 0 ) => Number( Math.round( value + 'e' + decimals) + 'e-' + decimals ) ;

const computeSizesColumns = ( { medias, columns, width, spacing, defaultDimension } ) =>
{
    let colWidth = (width - spacing * 2 * columns) / columns;

    let elements = medias.map( media =>
    {
        let { height, width } = media ;

        if( height === null || isNaN(height) || (height <= 0) )
        {
            height = defaultDimension.height  ;
        }

        if( width === null || isNaN(width) || (width <= 0) )
        {
            width = defaultDimension.width  ;
        }

        const newHeight = height / width * colWidth ;

        return {
            media  : media ,
            width  : round(colWidth, 1),
            height : round(newHeight, 1)
        };
    });

    const colLeftPositions    = [];
    const colCurrTopPositions = [];

    for ( let i = 0 ; i < columns; i++ )
    {
        colLeftPositions[i] = round(i * (colWidth + spacing * 2), 1);
        colCurrTopPositions[i] = 0;
    }

    elements = elements.map( element =>
    {
        const smallestCol = colCurrTopPositions.reduce( ( acc , item , i ) => item < colCurrTopPositions[acc] ? i : acc , 0 ) ;

        element.top  = colCurrTopPositions[smallestCol];
        element.left = colLeftPositions[smallestCol];

        colCurrTopPositions[smallestCol] = colCurrTopPositions[smallestCol] + element.height + spacing * 2;

        const tallestCol = colCurrTopPositions.reduce((acc, item, i) => item > colCurrTopPositions[acc] ? i : acc, 0);

        element.containerHeight = colCurrTopPositions[tallestCol];

        return element ;
    });

    return elements ;
};

export default computeSizesColumns ;
