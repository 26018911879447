import initDialog from '../initDialog'

import { AddDialog } from '../AddDialog'

import Team from "../../../vo/Team"

import api from '../../../configs/api'

const styles = () => ({});

export class AddTeamDialog extends AddDialog
{
    getLocale = () => this.props.locale.dialogs.application.team.add ;

    prepare = item =>
    {
        if( item instanceof Team)
        {
            const { name , identifier } = item ;
            return { name , identifier };
        }
        return null ;
    };
}

AddTeamDialog.defaultProps =
{
    ...AddDialog.defaultProps,
    uri : api.teams.url
};

AddTeamDialog.propTypes =
{
    ...AddDialog.propTypes
};

export default initDialog({ styles })( AddTeamDialog ) ;
