import table from './table'

const { tooltips } = table ;

const users =
{
    ...table,
    helmet :
    {
        title : 'Utilisateurs' ,
        metas : []
    },
    empty  : 'Aucun utilisateur trouvé',
    search : 'Rechercher un utilisateur...' ,
    sorts  : 
    [
        { label : 'Date de modification' , value : 'modified'   }  ,
        { label : 'Email'                , value : 'email'      }  ,
        { label : 'Nom de famille'       , value : 'familyName' }  ,
        { label : 'Prénom'               , value : 'givenName'  }  ,
        { label : 'Date de création'     , value : 'created'    }  
    ],
    title    : 'Utilisateurs',
    tooltips :
    {
        ...tooltips,
        send : 'Envoyer une invitation'
    }
};

export default users ;