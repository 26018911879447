import React from 'react'

import SelectConsumer from './SelectConsumer'

export const withSelect = Component => React.forwardRef( ( props , ref ) =>
(
    <SelectConsumer>
        { store => <Component {...props} {...store} ref={ref} /> }
    </SelectConsumer>
));

export default withSelect ;