import department         from './organization/department'
import email              from './email'
import employees          from './organization/employees'
import founder            from './organization/founder'
import legal              from './organization/legal'
import memberOf           from './organization/memberOf'
import members            from './organization/members'
import numbers            from './organization/numbers'
import parentOrganization from './organization/parentOrganization'
import providers          from './organization/providers'
import subOrganizations   from './organization/subOrganizations'
import telephone          from './telephone'
import websites           from './websites'

import Form from '../../../display/forms/FormElements'

import Place from '../../../things/Place'
import Thing from '../../../things/Thing'
import Word  from '../../../things/Word'

import api from '../../../configs/api'

const elements =
[
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'name',
            key          : 'name',
            label        : 'Nom',
            autoFocus    : false,
            fullWidth    : true,
            helperText   : null,
            margin       : 'normal',
            required     : false,
            select       : false,
            autoComplete : 'off' ,
            type         : 'text',
            variant      : 'outlined'
        }
    },
    {
        type : Form.SEARCH_SELECTOR ,
        path : api.thesaurus.organizations_types ,
        init :
        {
            id         : 'additionalType',
            key        : 'additionalType',
            label      : 'Type d\'organisation',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            required   : true,
            type       : 'search',
            variant    : 'outlined'
        },
        inputProps :
        {
            label : 'Type d\'organisation...'
        },
        logics :
        {
            disabledSuggestions : props =>
            {
                const { rel } = props ;
                let suggestions = [] ;
                if( rel instanceof Thing )
                {
                    const { additionalType } = rel ;
                    if( additionalType instanceof Word )
                    {
                        suggestions.push( additionalType ) ;
                    }
                }
                return suggestions ;
            }
        }
    },
    {
        type       : Form.SEARCH_SELECTOR ,
        clazz      : Place,
        path       : api.places.url ,
        init       :
        {
            id         : 'location',
            key        : 'location',
            label      : 'Localisation',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            required   : true,
            type       : 'search',
            variant    : 'outlined'
        },
        inputProps :
        {
            label : 'Lieu...'
        },
        limit  : 0,
        logics :
        {
            disabledSuggestions : props =>
            {
                const { rel } = props ;
                let suggestions = [] ;
                if( rel instanceof Thing )
                {
                    const { organization } = rel ;
                    if( organization instanceof Place )
                    {
                        suggestions.push( organization ) ;
                    }
                }
                return suggestions ;
            }
        }
    }
];


const organization =
{
    // -------- dependencies

    department,
    email,
    employees,
    founder,
    legal,
    memberOf,
    members,
    numbers,
    parentOrganization,
    providers,
    subOrganizations,
    telephone,
    websites,

    // -------- settings

    edit :
    {
        elements,
        agree       : 'Sauver' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour modifier cette organisation.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...' ,
        success     : 'Mise à jour réussie!' ,
        title       : 'Modifier l\'organisation' ,
    },
    labels :
    {
        empty    : '----' ,
        location : 'Localisation',
        name     : 'Nom' ,
        type     : 'Type d\'organisation'
    },
    title    : 'Organisation' ,
    tooltips :
    {
        add    : 'Ajouter',
        edit   : 'Modifier',
        remove : 'Supprimer'
    }
};

export default organization ;
