import Form   from '../../../../display/forms/FormElements'
import Method from '../../../../net/Method'

const elements =
[
    {
        type : Form.I18N_EDITOR ,
        init :
        {
            id    : 'name',
            key   : 'name',
            input : true,
            label : 'Concept'
        }
    },
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'alternateName',
            key          : 'alternateName',
            label        : 'Identifiant',
            autoFocus    : false,
            fullWidth    : true,
            margin       : 'normal',
            required     : false,
            select       : false,
            autoComplete : 'off' ,
            type         : 'text',
            variant      : 'outlined'
        }
    },
    {
        type : Form.COLOR_TEXTFIELD ,
        init :
        {
            id           : 'color',
            key          : 'color',
            label        : 'Couleur',
            autoComplete : 'off' ,
            autoFocus    : false,
            fullWidth    : true,
            margin       : 'normal',
            placeholder  : 'RRGGBB',
            required     : false,
            select       : false,
            type         : 'text',
            variant      : 'outlined'
        }
    },
    {
        type : Form.COLOR_TEXTFIELD ,
        init :
        {
            id           : 'bgcolor',
            key          : 'bgcolor',
            label        : 'Couleur de fond',
            autoComplete : 'off' ,
            autoFocus    : false,
            fullWidth    : true,
            margin       : 'normal',
            placeholder  : 'RRGGBB',
            required     : false,
            select       : false,
            type         : 'text',
            variant      : 'outlined'
        }
    },
    {
        type : Form.I18N_EDITOR ,
        init :
        {
            id    : 'description',
            key   : 'description',
            input : true,
            label : 'Description'
        }
    },
    {
        type : Form.DIVIDER,
        id   : 'divider'
    },
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'format',
            key          : 'format',
            label        : 'Type',
            autoFocus    : false,
            fullWidth    : true,
            margin       : 'normal',
            required     : false,
            select       : false,
            autoComplete : 'off' ,
            type         : 'text',
            variant      : 'outlined'
        }
    },
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'pattern',
            key          : 'pattern',
            label        : 'Masque de filtrage',
            autoFocus    : false,
            fullWidth    : true,
            margin       : 'normal',
            required     : false,
            select       : false,
            autoComplete : 'off' ,
            type         : 'text',
            variant      : 'outlined'
        }
    },
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'validator',
            key          : 'validator',
            label        : 'Validateur(s)',
            autoFocus    : false,
            fullWidth    : true,
            margin       : 'normal',
            required     : false,
            select       : false,
            autoComplete : 'off' ,
            type         : 'text',
            variant      : 'outlined'
        }
    }
];

export default
{
    add :
    {
        agree       : 'Créer' ,
        disagree    : 'Fermer' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour ajouter un nouveau terme ou concept.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...' ,
        success     : 'Ajout du nouveau terme ou concept réussi!' ,
        title       : 'Ajouter un terme',
        elements
    },
    edit :
    {
        agree       : 'Sauver' ,
        disagree    : 'Fermer' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour modifier ce terme.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...'  ,
        success     : 'Mise à jour réussie!' ,
        title       : 'Modifier le terme',
        method      : Method.PUT,
        tooltips    : { upload : 'Ajouter une icône' } ,
        elements
    },
    remove :
    {
        agree        : 'Supprimer' ,
        description  : 'Souhaitez-vous supprimer le terme suivant :' ,
        descriptionX : 'Souhaitez-vous supprimer les termes suivants :' ,
        disagree     : 'Fermer' ,
        loading      : 'Suppression en cours...' ,
        title        : 'Supprimer ce terme ?',
        titleX       : 'Supprimer des termes ?',
        useID        : false
    },
    upload  :
    {
        agree        : 'Envoyer',
        disagree     : 'Fermer',
        filePropName : 'icon' , // important to send the picture with the API
        method       : Method.POST,
        mock         : false ,
        text         : 'Déposer un fichier .PNG avec la taille 512x512px maximum.',
        title        : 'Téléverser une icône'
    }
};
