import React from 'react'

import { MediaObjectsList } from './MediaObjectsList'

import AudioIcon from '@material-ui/icons/QueueMusic'

import withDatasList from './withDatasList'

import api from '../../configs/api'

import AudioObject from '../../things/creativework/media/AudioObject'

class AudiosList extends MediaObjectsList
{
    add = () => this.openSelectMediaDialog() ;

    getLocale = () => this.props.locale.display.lists.audios ;

    getOptions = () => this.getAddButton() ;
}

AudiosList.defaultProps =
{
    ...MediaObjectsList.defaultProps,
    addable          : true ,
    clazz            : AudioObject,
    deletable        : true ,
    icon             : <AudioIcon />,
    member           : 'audios' ,
    optionsVariant   : 'header',
    orderable        : true ,
    orderMock        : false ,
    removeProps      : { mock:false , useUrl:false } ,
    selectMediaProps : ( { props : { config : { acceptedUploadMedias : { audios:acceptedFiles } = {} } = {} } = {} } = {} ) => (
    {
        disableEscapeKeyDown : true ,
        facets               : { encodingFormat : 'audio' } ,
        multiple             : true,
        uploadable           : true,
        uploadMultiple       : true,
        uploadProps          : { acceptedFiles },
        uploadUri            : api.mediaObjects.url
    }),
    selectMediaUri : api.mediaObjects.url ,
    searchPolicy   : 'none' ,
    sortable       : false
};

AudiosList.propTypes =
{
    ...MediaObjectsList.propTypes
};

export default withDatasList()( AudiosList ) ;
