import Thing from './Thing'

import generify from './generify'
import populateImage from './creativework/populateImage';

/**
 * The Answer.
 * @memberOf Thing
 */
class Answer extends Thing
{
    /**
     * Creates a new Answer instance.
     * @constructor
     * @param object The generic object to initialize the object the first time.
     */
    constructor( object = null )
    {
        super() ;

        /**
         * A background color value option.
         * @type {string}
         */
        this.bgcolor = null ;

        /**
         * A color value option.
         * @type {string}
         */
        this.color = null ;

        /**
         * Indicates if the answer is correct.
         * @type {boolean}
         */
        this.correct = false ;

        /**
         * Indicates the score of this answer.
         * @type {number}
         */
        this.score = 0 ;

        this.set( object ) ;
    }

    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        super.clear() ;
        this.correct = false ;
        this.score   = 0 ;
        this.bgcolor =
        this.color   = null ;
        return this ;
    }

    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new Answer( this.toObject( true ) ) ;
    }

    /**
     * Populate the object with specific behaviors or types. Launched by default in the constructor and in the set methods.
     * @returns {Thing} The current reference.
     */
    populate()
    {
        populateImage( this );
    }

    /**
     * Returns the generic object representation of the item.
     * @param full {boolean} [full=false] - Indicates if the generic object contains all properties or a minimalist version of the instance.
     * @return {Object} The generic object representation of the item.
     */
    toObject( full = false )
    {
        let {
            alternateName,
            bgcolor,
            color,
            correct,
            description,
            id,
            image,
            notes,
            text,
            score
        } = this ;

        image = generify(image) ;

        return {
            ...( full && super.toObject() ) ,
            alternateName,
            bgcolor,
            color,
            correct,
            description,
            id,
            image,
            notes,
            text,
            score
        }
    }
}

export default Answer ;