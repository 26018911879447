import CreativeWork from '../CreativeWork'

/**
 * The most generic kind of creative work, including books, movies, photographs, software programs, etc.
 * @memberOf things.creativework
 */
class MediaObject extends CreativeWork
{
    /**
     * Creates a new MediaObject instance.
     * @constructor
     * @param object The generic object to initialize the object the first time.
     */
    constructor( object = null )
    {
        super() ;

        /**
         * The bitrate of the item.
         * @type {number}
         */
        this.bitrate = null  ;

        /**
         * The contentSize of the item.
         * @type {string|number}
         */
        this.contentSize = null  ;

        /**
         * The contentUrl of the item.
         * @type {string}
         */
        this.contentUrl = null  ;

        /**
         * The duration of the item.
         * @type {number}
         */
        this.duration = null  ;

        /**
         * The editor of the item.
         * @type {string}
         */
        this.editor = null  ;

        /**
         * A URL pointing to a player for a specific video.
         * In general, this is the information in the src element of an embed tag and should not be the same as the content of the loc tag.
         * @type {string}
         */
        this.embedUrl = null  ;

        /**
         * The height of the item.
         * @type {number}
         */
        this.height = null  ;

        /**
         * Indicates the primary entity described in some page or other CreativeWork.
         * @type {boolean|Thing}
         */
        this.mainEntity = null  ;

        /**
         * The player type required—for example, Flash or Silverlight.
         * @type {string}
         */
        this.playerType = null ;

        /**
         * The source of the item
         * @type {array}
         */
        this.source = null ;

        /**
         * The width of the item.
         * @type {number}
         */
        this.width = null ;

        this.set( object ) ;
    }

    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        super.clear() ;
        this.bitrate =
        this.contentSize =
        this.contentUrl =
        this.duration =
        this.editor =
        this.embedUrl =
        this.encodingFormat =
        this.mainEntity =
        this.playerType =
        this.source =
        this.width =
        this.height = null ;
        return this ;
    }

    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new MediaObject( this.toObject() ) ;
    }

    /**
     * Returns the generic object representation of the item.
     * @return {Object} The generic object representation of the item.
     */
    toObject()
    {
        return {
            ...super.toObject() ,
            bitrate        : this.bitrate,
            contentSize    : this.contentSize,
            contentUrl     : this.contentUrl,
            duration       : this.duration,
            editor         : this.editor,
            embedUrl       : this.embedUrl,
            encodingFormat : this.encodingFormat,
            height         : this.height,
            mainEntity     : this.mainEntity,
            playerType     : this.playerType,
            source         : this.source,
            width          : this.width
        }
    }
}

export default MediaObject ;
