import CreativeWork from '../CreativeWork'

import DigitalDocumentPermission from './DigitalDocumentPermission'

import generify from '../generify'

/**
 * An electronic file or document.
 * @memberOf things.creativework
 */
class DigitalDocument extends CreativeWork
{
    /**
     * Creates a new DigitalDocument instance.
     * @constructor
     * @param object The generic object to initialize the object the first time.
     */
    constructor( object = null )
    {
        super() ;

        /**
         * A permission related to the access to this document (e.g. permission to read or write an electronic document).
         * For a public document, specify a grantee with an Audience with audienceType equal to "public".
         * @type {DigitalDocumentPermission}
         */
        this.hasDigitalDocumentPermission = null  ;

        this.set( object ) ;
    }

    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        super.clear() ;
        this.hasDigitalDocumentPermission = null ;
        return this ;
    }

    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new DigitalDocument( this.toObject() ) ;
    }

    /**
     * Populate the object with specific behaviors or types. Launched by default in the constructor and in the set methods.
     * @returns {Thing} The current reference.
     */
    populate()
    {
        const { hasDigitalDocumentPermission } = this ;

        if( hasDigitalDocumentPermission && !( hasDigitalDocumentPermission instanceof DigitalDocumentPermission ) )
        {
            this.hasDigitalDocumentPermission = new DigitalDocumentPermission(hasDigitalDocumentPermission);
        }
    }

    /**
     * Returns the generic object representation of the item.
     * @return {Object} The generic object representation of the item.
     */
    toObject()
    {
        let { hasDigitalDocumentPermission } = this ;

        hasDigitalDocumentPermission = generify(hasDigitalDocumentPermission);

        return {
            ...super.toObject() ,
            hasDigitalDocumentPermission
        }
    }
}

export default DigitalDocument ;
