import event     from './event'
import place     from './place'
import step      from './step'
import telephone from './telephone'
import website   from './website'

export default
{
    event,
    place,
    step,
    telephone,
    website
};
