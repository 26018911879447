import React from 'react'

import DomainIcon from '@material-ui/icons/Domain'

import withDatasList from './withDatasList'

import ThingsList from './ThingsList'

import Organization     from '../../things/Organization'
import OrganizationCell from '../cells/OrganizationCell'

import AddOrganizationDialog    from '../dialogs/add/AddOrganizationDialog'
import RemoveOrganizationDialog from '../dialogs/remove/RemoveOrganizationDialog'

class OrganizationsList extends ThingsList
{
    getLocale = () => this.props.locale.display.lists.organizations ;
}

OrganizationsList.defaultProps =
{
    ...ThingsList.defaultProps,
    AddDialogComponent    : AddOrganizationDialog,
    CellComponent         : OrganizationCell ,
    cellProps             : { variant : 'default' },
    clazz                 : Organization,
    icon                  : <DomainIcon />,
    preferable            : true ,
    RemoveDialogComponent : RemoveOrganizationDialog,
    variant               : 'pagination'
};

OrganizationsList.propTypes =
{
    ...ThingsList.propTypes
};

export default withDatasList()( OrganizationsList ) ;
