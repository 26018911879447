import React from 'react'

import withDatasList from './withDatasList'

import ArticleIcon from '@material-ui/icons/ChromeReaderMode'

import ThingsList from './ThingsList'

import Article from '../../things/creativework/Article'

import AddArticleDialog    from '../dialogs/add/AddArticleDialog'
import RemoveArticleDialog from '../dialogs/remove/RemoveArticleDialog'

import ArticleCell from '../cells/ArticleCell'

class ArticlesList extends ThingsList
{
    getLocale = () => this.props.locale.display.lists.articles ;
}

ArticlesList.defaultProps =
{
    ...ThingsList.defaultProps ,
    AddDialogComponent    : AddArticleDialog,
    CellComponent         : ArticleCell,
    cellProps             : { variant : 'default' },
    clazz                 : Article,
    icon                  : <ArticleIcon />,
    preferable            : true ,
    queries               : { sort : '-modified' },
    RemoveDialogComponent : RemoveArticleDialog,
    sortable              : true
};

ArticlesList.propTypes =
{
    ...ThingsList.propTypes
};

export default withDatasList()( ArticlesList ) ;
