import table from './table'

const courses =
{
    ...table,
    helmet :
    {
        title : 'Parcours' ,
        metas : []
    },
    empty  : 'Aucun parcours trouvé',
    search : 'Rechercher un parcours ...' ,
    title  : 'Parcours'
};

export default courses ;
