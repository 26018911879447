import React , { Fragment } from 'react'

import clsx from 'clsx'

import PropTypes from 'prop-types'

import format   from 'vegas-js-core/src/strings/fastformat'
import isString from 'vegas-js-core/src/isString'
import notEmpty from 'vegas-js-core/src/strings/notEmpty'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import { Link } from 'react-router-dom'

import {
    ButtonGroup,
    Button,
    CardMedia,
    DialogTitle,
    Divider,
    IconButton,
    Tooltip,
    Typography,
} from '@material-ui/core'

import green from '@material-ui/core/colors/green'
import grey  from '@material-ui/core/colors/grey'
import red   from '@material-ui/core/colors/red'

import DeleteIcon from '@material-ui/icons/Delete'
import ImageIcon  from "@material-ui/icons/Image"
import NextIcon   from "@material-ui/icons/KeyboardArrowRight"
import PrevIcon   from "@material-ui/icons/KeyboardArrowLeft"

import CoreDialog  from '../../../components/dialogs/CoreDialog'
import StepAvatar  from '../../things/course/StepAvatar'
import ThingAvatar from '../../avatars/ThingAvatar'

import api from "../../../configs/api"

import initDialog from '../initDialog'

import getLocaleAlternativeHeadline from '../../../things/getLocaleAlternativeHeadline'
import getMediaSource               from '../../../things/getMediaSource'
import GeoCoordinates               from '../../../things/GeoCoordinates'
import Place                        from '../../../things/Place'
import Stage                        from '../../../things/Stage'
import Step                         from '../../../things/Step'
import Thing                        from '../../../things/Thing'
import Word                         from '../../../things/Word'

const styles = () => ({});

class StepDialog extends CoreDialog
{
    agree = () =>
    {
        this.close();
        const { item:step } = this.props ;
        if( step instanceof Step )
        {
            const { backPath, history , uri} = this.props ;
            history.push({ pathname:uri  , state: { backPath } }) ;
        }
    };

    getCurrentPath = () => this.props.uri || this.props.location.pathname ;

    getLocale = () => this.props.locale.dialogs.things.step;

    getContent = () =>
    {
        const { item:step } = this.props ;

        let text ;

        if( step instanceof Step )
        {
            const { lang } = this.props ;

            let description = null ;

            text = step.getLocaleDescription( lang ) ;
            if( !isString( text ) )
            {
                const { stage } = step ;
                if( stage instanceof Thing )
                {
                    text = stage.getLocaleDescription( lang ) ;
                    if( !isString( text ) )
                    {
                        const { location } = stage ;
                        if( location instanceof Thing )
                        {
                            text = location.getLocaleDescription( lang ) ;
                        }
                    }
                }
            }

            if( notEmpty(text) )
            {
                description = (
                <Typography
                    align     = 'justify'
                    className = 'w-full font-normal'
                    variant   = 'body2'
                >
                    { ucFirst(text) }
                </Typography>
                )
            }
            else
            {
                description = null  ;
            }

            if( description )
            {
                text = (
                <div className='flex flex-col w-full my-8 text-justify'>
                    { description }
                </div>);
            }
            else
            {
                text = null ;
            }
        }

        return (
            <div className='flex flex-col w-full'>
                { text }
                <ButtonGroup
                    aria-label = "Step options"
                    className  = "my-8"
                    color      = "primary"
                    fullWidth  = { true }
                    size       = "small"
                    variant    = "outlined"
                >
                    { this.getStage( step ) }
                    { this.getLocation( step ) }
                </ButtonGroup>

            </div>
        );
    };

    getImage = child =>
    {
        let item ;

        let {
            config,
            lang
        } = this.props ;

        if( child )
        {
            const { image, stage } = child ;
            if( image )
            {
                item = child ;
            }
            else if ( stage )
            {
                const { image, location } = stage ;
                if( image )
                {
                    item = stage ;
                }
                else if( location )
                {
                    item = location ;
                }
            }
        }

        let img ;
        if( item )
        {
            const { image : media } = item ;

            let sourceElements ;

            if( media )
            {
                const { contentUrl } = media ;
                const { image } = config ;

                const alt = getLocaleAlternativeHeadline( media , lang ) || "" ;

                const resize = '?w=' + image.maxWidth + '&h=' + image.maxHeight ;

                const source = getMediaSource( media ) ;

                if( source )
                {
                    sourceElements = source.map( element => <source key={element.encodingFormat} srcSet={ element.contentUrl + resize } type={ element.encodingFormat } /> ) ;
                }

                const imgElement = (
                    <img
                        key = { contentUrl }
                        alt = { alt }
                        src = { contentUrl + resize }
                        style = {{ width : '100%' , height : '100%' , objectFit : 'cover' }}
                    />
                );

                sourceElements = [ ...sourceElements , imgElement ] ;
            }

            img = (
                <CardMedia
                    className = 'bg-gray-300 mb-8'
                    component = 'picture'
                    children  = { sourceElements }
                    style     = {
                    {
                        height : 150
                    }}
                />
            );
        }
        else
        {
            img = (
                <div
                    className = 'flex items-center justify-center bg-gray-400 mb-8'
                    style     = {{ height : 150 }}
                >
                    <ImageIcon className='text-gray-100' style={{ fontSize : 40 }} />
                </div>
            );
        }

        return img ;
    };

    getOptions = () =>
    {
        const {
            deletable,
            onRemove,
            item
        } = this.props ;

        //console.log( this + ' getOptions' , deletable , this.isEditable() , onRemove ) ;

        if( deletable && this.isEditable() && (onRemove instanceof Function) )
        {
            let deleteButton = (
            <IconButton onClick = { () => { onRemove( item ) ; }} >
                <DeleteIcon style={{ color:red[600] }}/>
            </IconButton>);

            const locale = this.getLocale() ;
            if( locale )
            {
                const { tooltips } = locale ;
                if( tooltips )
                {
                    const { remove } = tooltips ;
                    if( isString(remove) )
                    {
                        deleteButton = (
                            <Tooltip title={remove} placement='top'>
                                { deleteButton }
                            </Tooltip>
                        )
                    }
                }
            }

            return deleteButton ;
        }

        return null ;
    };

    getLocation = ( child , className = null ) =>
    {
        if( child )
        {
            const { stage } = child ;
            if( stage instanceof Stage )
            {
                const { location } = stage ;
                if( location instanceof Place )
                {
                    const { lang } = this.props ;

                    let { geo , url } = location ;

                    const locale = this.getLocale() ;

                    const { labels : { geoExist, geoNone, location:loc } = {} } = locale ;

                    let label ;

                    let tip = format( loc , location.getLocaleName(lang) ) ;

                    let color ;

                    if( geo instanceof GeoCoordinates )
                    {
                        color = green[500];
                        if( isString(geoExist) && geoExist !== '' )
                        {
                            label = geoExist ;
                        }
                    }
                    else
                    {
                        color = red[500];
                        if( isString(geoNone) && geoNone !== '' )
                        {
                            label = geoNone ;
                        }
                    }

                    let chip = (
                        <Button
                            className = { className }
                            color     = "default"
                            component = { Link }
                            size      = "small"
                            startIcon = { <ThingAvatar thing={location} style={{ marginRight:4 }}/> }
                            to        = {{ pathname : url.split(api.url)[1] }}
                            variant   = "outlined"
                        >
                            <span className="flex-1" style={{ color }}>{ label }</span>
                        </Button>
                    );

                    if( isString(tip) )
                    {
                        chip = (
                        <Tooltip placement='top' title={ ucFirst(tip) } >
                            { chip }
                        </Tooltip>);
                    }

                    return chip ;
                }
            }
        }
        return null;
    };

    getStage = ( child , className = null ) =>
    {
        if( child )
        {
            const { stage } = child ;
            if( stage instanceof Stage )
            {
                const { lang } = this.props ;
                let {
                    headline,
                    name,
                    status ,
                    url
                } = stage ;

                let label ;
                let tip ;

                if( headline )
                {
                    tip = ucFirst(stage.getLocaleHeadline(lang)) ;
                }

                let color = grey[400] ;
                if( status instanceof Word )
                {
                    let { color:c } = status ;
                    if( isString( c ) )
                    {
                        color = '#' + c ;
                    }
                    label = ucFirst( status.getLocaleName(lang) ) ;
                }

                if( !tip && name )
                {
                    tip = stage.getLocaleName(lang) ;
                }

                let chip = (
                    <Button
                        className = { className }
                        color     = "default"
                        component = { Link }
                        size      = "small"
                        startIcon = { <ThingAvatar thing={stage} style={{ marginRight:4 }}/> }
                        to        = {{ pathname : url.split(api.url)[1] }}
                        variant   = "outlined"
                    >
                        <span className="flex-1" style={{ color }} >{ label }</span>
                    </Button>
                );

                if( isString(tip) )
                {
                    const locale = this.getLocale() ;
                    const { labels : { poi } = {} } = locale ;
                    tip = format( poi , ucFirst(tip) ) ;
                    chip = <Tooltip placement='top' title={ tip }>{ chip }</Tooltip> ;
                }

                return chip ;
            }
        }
        return null;
    };

    getTitle = () =>
    {
        const {
            index,
            item:step,
            lang,
            options
        } = this.props;

        let subtitle ;
        let title ;

        if( step instanceof Step )
        {
            title    = step.getLocaleTitle( lang ) ;
            subtitle = step.getLocaleSubtitle(lang) ;
        }

        if( !isString(title) || title === '' )
        {
            title = this.getTitleLabel() ;
        }

        if( notEmpty(title) )
        {
            const { uri } = this.props ;
            title = (
            <Typography
                className = { clsx( 'font-medium w-full cursor-pointer no-underline truncate pr-8' ) }
                color     = 'secondary'
                component = { Link }
                onClick   = { this.close }
                to        = {{ pathname : uri }}
                variant   = 'subtitle1'
            >
                { ucFirst(title) }
            </Typography>
            )
        }
        else
        {
            title = null ;
        }

        if( notEmpty(subtitle) )
        {
            subtitle =  (
            <Typography className="font-medium" variant='subtitle2'>
                { ucFirst(subtitle) }
            </Typography>) ;
        }
        else
        {
            subtitle = null ;
        }

        const { divider } = this.props;

        let navigation ;
        const { onNext , onPrevious } = this.props ;
        if( onNext || onPrevious )
        {
            const locale = this.getLocale() || {}  ;

            const {
                labels : {
                    next     : nextLabel = 'Next' ,
                    previous : prevLabel = 'Previous'
                } = {}
            } = locale ;

            let previous ;
            if( onPrevious )
            {
                previous = (
                <Button
                    startIcon = { <PrevIcon /> }
                    onClick   = { () =>
                    {
                        this.close() ;
                        onPrevious( index ) ;
                    }}
                >
                    <Typography variant='caption'>{ prevLabel }</Typography>
                </Button>);
            }

            let next ;
            if( onNext )
            {
                next = (
                <Button
                    endIcon   = { <NextIcon /> }
                    onClick   = { () =>
                    {
                        this.close() ;
                        onNext( index ) ;
                    }}
                >
                    <Typography variant='caption'>{ nextLabel }</Typography>
                </Button>);
            }

            navigation = (
                <ButtonGroup
                    fullWidth = {true}
                    size      = "small"
                    variant   = 'text'
                >
                    { previous }
                    { next }
                </ButtonGroup>
            )
        }

        return (
        <Fragment>
            { this.getImage( step ) }
            <StepAvatar
                className = 'relative shadow'
                index     = { index }
                onSelect  = { this.agree }
                style     = {{ width:50 , height:50 , left:12, top:-36 , zIndex:40 }}
                thing     = { step }
            />
            <DialogTitle
                className = 'w-full flex items-center justify-between bg-grey-600'
                style     = {{ marginTop:-36 }}
            >
                <div className="w-full flex flex-row items-center">
                    <div className='flex flex-col w-full'>
                        { title }
                        { subtitle }
                    </div>
                    { options || this.getOptions() }
                </div>
            </DialogTitle>
            { divider && <Divider light/> }
            { navigation }
            { divider && <Divider light/> }
        </Fragment>
        );
    }
}

StepDialog.defaultProps =
{
    ...CoreDialog.defaultProps,
    deletable  : false ,
    onNext     : null,
    onPrevious : null,
    onRemove   : null
};

StepDialog.propTypes =
{
    ...CoreDialog.propTypes,
    deletable  : PropTypes.bool,
    onNext     : PropTypes.func,
    onPrevious : PropTypes.func,
    onRemove   : PropTypes.func
};

export default initDialog({ styles })(StepDialog) ;
