const answer =
{
    labels :
    {
        bgcolor     : 'Couleur de fond',
        color       : 'Couleur',
        correct     : 'Correct',
        description : 'Description',
        empty       : '----' ,
        no          : 'Non',
        score       : 'Score',
        yes         : 'Oui'
    },
    title : 'Réponse'
};

export default answer ;