import React from 'react'

import moment from "moment"

import compose  from 'vegas-js-core/src/functors/compose'
import isString from 'vegas-js-core/src/isString'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import initApp from '../../../contexts/app/initApp'

import { Link , withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { Grid } from '@material-ui/core'

import AccountBalanceIcon from '@material-ui/icons/AccountBalance'

import LabelItem from '../../../components/items/LabelItem'

import ThingContainer from '../../containers/ThingContainer'

import OrganizationVO from '../../../things/Organization'
import PlaceVO        from '../../../things/Place'
import ThingVO        from '../../../things/Thing'
import api from "../../../configs/api"

const styles = () => ({});

const full =
{
    xs : 12
};

const half =
{
    sm : 6 ,
    xs : 12
};

class Legal extends ThingContainer
{
    editPrepare = item =>
    {
        let {
            ape,
            dissolutionDate,
            foundingDate,
            foundingLocation,
            legalForm,
            legalName,
            name,
            taxID,
            vatID
        } = item ;

        const object = (
        {
            ape              : ape instanceof ThingVO ? ape.id : '' ,
            dissolutionDate  : dissolutionDate && moment( dissolutionDate ).isValid() ? moment( dissolutionDate ).format( 'YYYY-MM-DD' ) : '',
            foundingDate     : foundingDate && moment( foundingDate ).isValid() ? moment( foundingDate ).format( 'YYYY-MM-DD' ) : '',
            foundingLocation : foundingLocation instanceof PlaceVO ? foundingLocation.id : '',
            legalForm        : legalForm instanceof ThingVO ? legalForm.id : '' ,
            legalName,
            name,
            taxID,
            vatID
        });
        return object ;
    };

    getContent = () =>
    {
        let { lang, thing } = this.props;

        const locale = this.getLocale() ;

        const { labels } = locale ;

        let dissolutionDate ;
        let foundingDate ;
        let foundingLocation ;
        let legalForm ;
        let legalName ;
        let ape ;
        let taxID ;
        let vatID ;

        let options ;

        if( thing instanceof OrganizationVO )
        {
            let {
                ape              : a,
                dissolutionDate  : dd ,
                foundingDate     : fd,
                foundingLocation : fl ,
                legalForm        : lf ,
                legalName        : ln,
                taxID            : t,
                vatID            : v

            } = thing ;

            if( a instanceof ThingVO )
            {
                ape = a.getLocaleName( lang ) ;
            }
            else
            {
                ape = null ;
            }

            if( isString( dd ) )
            {
                dissolutionDate = moment( dd ).isValid() ? moment( dd ).format( 'L' ) : false ;
            }

            if( isString( fd ) )
            {
                foundingDate = moment( fd ).isValid() ? moment( fd ).format( 'L' ) : false ;
            }

            if( isString( ln ) )
            {
                legalName = ln ;
            }

            if( isString( t ) )
            {
                taxID = t ;
            }

            if( isString( v ) )
            {
                vatID = v ;
            }

            if( lf instanceof ThingVO )
            {
                legalForm = lf.getLocaleName( lang ) ;
            }
            else
            {
                legalForm = null ;
            }

            if( fl instanceof ThingVO )
            {
                options =
                {
                    component : Link ,
                    to        :
                    {
                        pathname : fl.url.split(api.url)[1]
                    }
                };
                foundingLocation = fl.getLocaleName( lang ) ;
            }
            else
            {
                foundingLocation = null ;
            }
        }

        return (
            <Grid container spacing={4}>
                <Grid item {...full}>
                    <LabelItem enabled={!!(legalName)} title={ labels.legalName }>
                        { isString(legalName) && (legalName !== '') ? ucFirst(legalName) : labels.empty }
                    </LabelItem>
                </Grid>
                <Grid item {...half}>
                    <LabelItem enabled = {!!(legalForm)} title={ labels.legalForm }>
                        { isString(legalForm) && (legalForm !== '') ? ucFirst(legalForm) : labels.empty }
                    </LabelItem>
                </Grid>
                <Grid item {...half}>
                    <LabelItem enabled = {!!(ape)} title={ labels.ape }>
                        { isString(ape) && (ape !== '') ? ucFirst(ape) : labels.empty }
                    </LabelItem>
                </Grid>
                <Grid item {...half}>
                    <LabelItem enabled={!!(taxID)} title={ labels.taxID }>
                        { isString(taxID) && (taxID !== '') ? ucFirst(taxID) : labels.empty }
                    </LabelItem>
                </Grid>
                <Grid item {...half}>
                    <LabelItem enabled={!!(vatID)} title={ labels.vatID }>
                        { isString(vatID) && (vatID !== '') ? ucFirst(vatID) : labels.empty }
                    </LabelItem>
                </Grid>
                <Grid item {...half}>
                    <LabelItem enabled={!!(foundingDate)} title={ labels.foundingDate }>
                        { isString(foundingDate) && (foundingDate !== '') ? foundingDate : labels.empty }
                    </LabelItem>
                </Grid>
                <Grid item {...half}>
                    <LabelItem enabled={!!(dissolutionDate)} title={ labels.dissolutionDate }>
                        { isString(dissolutionDate) && (dissolutionDate !== '') ? dissolutionDate : labels.empty }
                    </LabelItem>
                </Grid>
                <Grid item {...full}>
                    <LabelItem
                        enabled = {!!(foundingLocation)}
                        title   = { labels.foundingLocation }
                        { ...options }
                    >
                        { isString(foundingLocation) && (foundingLocation !== '') ? ucFirst(foundingLocation) : labels.empty }
                    </LabelItem>
                </Grid>
            </Grid>
        );
    };

    getEntry = init =>
    {
        let empty = {} ;
        if( init instanceof ThingVO )
        {
            empty = init.toObject() ;
        }
        else if( init )
        {
            empty = { ...init } ;
        }
        return new OrganizationVO(empty);
    };

    getLocale = () => this.props.locale.things.organization.legal;

    onEdit = item =>
    {
        const {
            onChange,
            thing
        } = this.props ;

        if( thing && item )
        {
            const {
                ape,
                dissolutionDate,
                foundingDate,
                foundingLocation,
                legalForm,
                legalName,
                name,
                taxID,
                vatID
            } = item ;

            thing.ape              = ape ;
            thing.dissolutionDate  = dissolutionDate ;
            thing.foundingDate     = foundingDate ;
            thing.foundingLocation = foundingLocation ;
            thing.legalForm        = legalForm ;
            thing.legalName        = legalName ;
            thing.modified         = moment(new Date()).toISOString();
            thing.name             = name ;
            thing.taxID            = taxID ;
            thing.vatID            = vatID ;

            if( thing instanceof ThingVO )
            {
                thing.populate();
            }

            if( this._mounted )
            {
                this.forceUpdate() ;
            }

            if( onChange instanceof Function )
            {
                onChange(thing) ;
            }
        }
    }
}

Legal.defaultProps =
{
    ...ThingContainer.defaultProps ,
    icon       : <AccountBalanceIcon/> ,
    optionMode : 'edit'
};

Legal.propTypes =
{
    ...ThingContainer.propTypes
};

export default compose( withStyles( styles ) , withRouter , initApp )( Legal ) ;
