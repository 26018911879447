import format from 'vegas-js-core/src/strings/fastformat'

import GeoCoordinates from './GeoCoordinates'

/**
 * Returns the human readable geo coordinates label expression.
 * @param {Thing|PostalAddress} thing - The GeoCoordinates or object with a 'geo' property.
 * @param {Object} options - The optional settings of the function.
 * @param {string} options.pattern - The pattern expression used to format the label. Note latitude={0}, longitude={1}, elevation={2}
 * @param {boolean} options.strict - Indicates if the latitude and longitude must not null.
 * @returns {null|string} The human readable geo coordinates label expression.
 */
const getLocaleGeoCoordinatesLabel = ( thing , { pattern='{0}, {1}' , strict=false} = {} ) =>
{
    let geo ;
    if( thing )
    {
        if ( thing instanceof GeoCoordinates ) 
        {
            geo = thing  ;
        }
        else
        {
            const { geo:coord } = thing ;
            if( coord instanceof GeoCoordinates )
            {
                geo = coord ;
            }
        }
    }
    
    if ( geo instanceof GeoCoordinates ) 
    {
        let {
            elevation,
            latitude,
            longitude
        } = geo;
        
        if( strict && latitude && longitude )
        {
            return format( pattern , latitude, longitude, elevation ) ; 
        }
        else
        {
            return format( pattern , latitude, longitude, elevation ) ; 
        }
        
    }
    
    return null ;
};

export default getLocaleGeoCoordinatesLabel ;
