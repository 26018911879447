import React from "react"

import TeamList from '../../display/lists/TeamList'

import View from '../../display/views/View'

import initView from '../../display/views/initView'

const styles = () => ({}) ;

class Teams extends View
{
    build = () =>
    {
        return (
        <div className='flex flex-col w-full'>
            <TeamList parent = { this.element } />
        </div>);
    }
    
    getLocale = () => this.props.locale.views.teams ;
}

Teams.defaultProps =
{
    ...View.defaultProps
};

Teams.propTypes =
{
    ...View.propTypes
};

export default initView( Teams, styles );