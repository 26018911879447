import React from 'react'

import clsx from 'clsx'

import format from 'vegas-js-core/src/strings/fastformat'

import TransitionGroup from '../../../transitions/TransitionGroup'

import withi18n from '../../../contexts/i18n/withi18n'

import Address               from '../../../display/things/Address'
import Email                 from '../../../display/things/Email'
import LiveStock             from '../../../display/things/LiveStock'
import Metadatas             from '../../../display/things/Metadatas'
import RemoveLivestockDialog from '../../../display/dialogs/remove/RemoveLivestockDialog'
import Telephone             from '../../../display/things/Telephone'
import Websites              from '../../../display/things/Websites'

import ThingSubContainer from '../../../display/containers/ThingSubContainer'

import Organization  from '../../../things/Organization'
import PostalAddress from '../../../things/PostalAddress'

class About extends ThingSubContainer
{
    render = () =>
    {
        const { thing } = this.props ;

        if( thing )
        {
            const {
                animate,
                animateProps,
                className,
                path,
                style
            } = this.props ;

            const {
                id,
                organization
            } = thing ;

            let address ;
            if( organization instanceof Organization )
            {
                if( organization.address && organization.address instanceof PostalAddress )
                {
                    address = organization.address;
                }
            }

            const { onRemove } = this.props ;

            let view = (
                <div className="flex-1 flex flex-col space-y-24">

                    <Metadatas
                        activable             = { true }
                        deletable             = { true }
                        onRemove              = { onRemove }
                        RemoveDialogComponent = { RemoveLivestockDialog }
                        thing                 = { thing }
                        uri                   = { format(path.livestock,id) }
                    />

                    <LiveStock thing={ thing } editable={false} />

                    <Address editable={ false } thing={ address } />

                    <div className='grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-16'>
                        <Telephone editable={ false } thing={ organization } />
                        <Email     editable={ false } thing={ organization } />
                    </div>

                    <Websites editable = { false } thing = { organization } />
                </div>

            )

            if( animate )
            {
                view = (
                    <TransitionGroup
                        duration = { 250 }
                        enter    = {{ animation: "transition.slideUpIn" }}
                        { ...animateProps }
                    >
                        { view }
                    </TransitionGroup>
                )
            }

            return (
            <div
                className = { clsx( 'flex-1 p-12' , className ) }
                style     = { style }
            >
                { view }
            </div>
            );
        }
        return null ;
    }
}

About.defaultProps =
{
    ...ThingSubContainer.defaultProps
};

About.propTypes =
{
    ...ThingSubContainer.propTypes
};

export default withi18n( About ) ;
