import notEmpty from 'vegas-js-core/src/strings/notEmpty'

import generify from './generify'

import settings from './settings'

const metadatas = [ 'created', 'modified' ];

/**
 * The most generic type of item.
 * @memberOf things
 */
class Thing
{
    /**
     * Creates a new Thing instance.
     * @constructor
     * @param object The generic object to initialize the object the first time.
     */
    constructor( object = null )
    {
        /**
         * @private
         */
        this['@type'] = this.constructor.name ;

        /**
         * The callback invoked when the object is changed with the set method.
         * @type {Function}
         */
        this.onChange = null ;

        /**
         * An additional type for the item, typically used for adding more specific types from external vocabularies in microdata syntax.
         * @type {string|Object}
         */
        this.additionalType = null ;

        /**
         * Indicates if the item is active.
         * @type {boolean}
         */
        this.active = true ;

        /**
         * An alias for the item.
         * @type {string}
         */
        this.alternateName = null ;

        /**
         * An audio media of the item. This can be a URL or a fully described AudioObject.
         * @type {string|Object}
         */
        this.audio = null ;

        /**
         * The creation date of the item.
         * @type {string|Date}
         */
        this.created = null ;

        /**
         * A description of the item.
         * @type {string}
         */
        this.description = null ;

        /**
         * A sub property of description. A short description of the item used to disambiguate from other, similar items. Information from other properties (in particular, name) may be necessary for the description to be useful for disambiguation.
         * @type {string}
         */
        this.disambiguatingDescription = null ;

        /**
         * The index of the item.
         * @type {Object}
         */
        this.id = null ;

        /**
         * The identifier of the item.
         * @type {Object}
         */
        this.identifier = null ;

        /**
         * An image of the item. This can be a URL or a fully described ImageObject.
         * @type {string|Object}
         */
        this.image = null ;

        /**
         * The modification date of the item.
         * @type {string|Date}
         */
        this.modified = null ;

        /**
         * The name of the item.
         * @type {string}
         */
        this.name = null ;

        /**
         * A notes of the item.
         * @type {string}
         */
        this.notes = null ;

        /**
         * The position description of this thing.
         * @type {number}
         */
        this.position = null ;

        /**
         * URL of a reference Web page that unambiguously indicates the item's identity.
         * @type {string}
         */
        this.sameAs = null ;

        /**
         * A CreativeWork or Event about this Thing. Inverse property: about.
         * @type {Thing}
         */
        this.subjectOf = null ;

        /**
         * A text of the item.
         * @type {string}
         */
        this.text = null ;

        /**
         * URL of the item.
         * @type {string}
         */
        this.url = null ;

        /**
         * A video media of the item. This can be a URL or a fully described VideoObject.
         * @type {string|Object}
         */
        this.video = null ;

        if( object )
        {
            for ( let prop in object )
            {
                this[prop] = object[prop];
            }
            this.populate() ;
        }
    }

    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        this.active = true ;
        this.additionalType =
        this.alternateName =
        this.audio =
        this.created =
        this.description =
        this.disambiguatingDescription =
        this.id =
        this.identifier =
        this.image =
        this.modified =
        this.name =
        this.notes =
        this.position =
        this.sameAs =
        this.subjectOf =
        this.text =
        this.url =
        this.video = null ;
        return this ;
    }

    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new Thing( this.toObject() ) ;
    }

    /**
     * A utility function for implementing the <code>toString()</code> method in custom classes. Overriding the <code>toString()</code> method is recommended, but not required.
     * @name formatToString
     * @memberof things
     * @instance
     * @function
     * @param {...string} [rest] - rest The properties of the class and the properties that you add in your custom class.
     * @example
     * class CustomThing extends Thing
     * {
     *     constructor( name )
     *     {
     *         this.name = name;
     *     }
     *
     *     toString()
     *     {
     *         return this.formatToString( "name" );
     *     }
     * }
     */
    formatToString = ( ...rest ) =>
    {
        const path = [ this['@type'] ] ;
        const len = rest.length ;
        for( let i = 0 ; i < len ; ++i )
        {
            let prop = rest[i] ;
            if( prop in this || this.hasOwnProperty(prop) )
            {
                path.push( prop + ":" + this[prop] ) ;
            }
        }
        return "[" + path.join(' ') + "]" ;
    };

    /**
     * Returns a function who returns a locale string value of a specific thing's property.
     * @param {string} key - The name of the property.
     * @return {Function} The helper function reference.
     */
    getLocaleProperty = key => ( lang = null , defaultLang = null ) =>
    {
        if( this.hasOwnProperty(key) )
        {
            let value = this[key] ;
            if( value )
            {
                if( notEmpty(value) )
                {
                    return value ;
                }
                else
                {
                    lang = lang || settings.lang ;

                    if ( value.hasOwnProperty(lang) && notEmpty(value[lang]) )
                    {
                        return value[lang] ;
                    }

                    defaultLang = defaultLang || settings.defaultLang ;
                    if ( value.hasOwnProperty(defaultLang) )
                    {
                        value = value[defaultLang] ;
                        if( notEmpty(value) )
                        {
                            return value ;
                        }
                    }
                }
            }
        }
        return null ;
    };

    /**
     * Returns the locale alternateName of the thing.
     * @param {string} [lang=null] the current lang.
     * @param {string} [defaultLang=null] the default lang.
     * @return {String} The locale alternateName of the thing.
     */
    getLocaleAlternateName = this.getLocaleProperty('alternateName') ;

    /**
     * Returns the locale description of the thing.
     * @param {string} [lang=null] the current lang.
     * @param {string} [defaultLang=null] the default lang.
     * @return {String} The locale description of the thing.
     */
    getLocaleDescription = this.getLocaleProperty('description') ;

    /**
     * Returns the locale name of the thing.
     * @param {string} [lang=null] the current lang.
     * @param {string} [defaultLang=null] the default lang.
     * @return {String} The locale name of the thing.
     */
    getLocaleName = this.getLocaleProperty('name') ;

    /**
     * Returns the locale notes of the thing.
     * @param {string} [lang=null] the current lang.
     * @param {string} [defaultLang=null] the default lang.
     * @return {String} The locale notes of the thing.
     */
    getLocaleNotes = this.getLocaleProperty('notes') ;

    /**
     * Returns the locale member value of the thing.
     * @param {string} member - the member name of the property to evaluates.
     * @param {string} [lang=null] - the current lang.
     * @param {string} [defaultLang=null] the default lang.
     * @return {String} The locale name of the thing.
     * @return {String} The locale notes of the thing.
     */
    getLocaleMember( member , lang = null , defaultLang = null )
    {
        return this.getLocaleProperty(member)(lang, defaultLang ) ;
    }

    /**
     * Returns the locale text of the thing.
     * @param {string} [lang=null] the current lang.
     * @param {string} [defaultLang=null] the default lang.
     * @return {String} The locale text of the thing.
     */
    getLocaleText = this.getLocaleProperty('text') ;

    /**
     * Populate the object with specific behaviors or types. Launched by default in the constructor and in the set methods.
     * @returns {Thing} The current reference.
     */
    populate() { return this ; }

    /**
     * Sets the object with a generic object.
     * @param {Object} object the enumerable object used to initialize the instance.
     * @return {Object} The current item reference.
     */
    set = object =>
    {
        if( object )
        {
            for (let prop in object)
            {
                this[prop] = object[prop];
            }

            this.populate();
        }
        return this ;
    };

    toJSON = () => this.toObject() ;

    /**
     * Returns the generic object representation of the item.
     * @return {Object} The generic object representation of the item.
     */
    toObject()
    {
        let {
            additionalType,
            active,
            alternateName,
            audio,
            created,
            description,
            disambiguatingDescription,
            id,
            identifier,
            image,
            modified,
            name,
            notes,
            position,
            sameAs,
            //subjectOf, // not returns by default, override this method to implement it.
            text,
            url,
            video
        } = this ;

        active         = !!(active);
        additionalType = generify(additionalType);
        audio          = generify(audio) ;
        identifier     = generify(identifier) ;
        image          = generify(image) ;
        video          = generify(video) ;

        return {
            additionalType,
            active,
            alternateName,
            audio,
            created,
            description,
            disambiguatingDescription,
            id,
            identifier,
            image,
            modified,
            name,
            notes,
            position,
            sameAs,
            text,
            url,
            video
        };
    }

    toString = () => this.formatToString() ;

    /**
     * Updates the created and modified metadatas of the thing object with the passed-in object.
     * @param object The object to change the metadatas of the thing.
     */
    updateMetadatas = object =>
    {
        if( object )
        {
            metadatas.forEach( prop =>
            {
                if( object.hasOwnProperty(prop) )
                {
                    this[prop] = object[prop] ;
                }
            });
            this.populate() ;
            if( this.onChange instanceof Function )
            {
                this.onChange( this ) ;
            }
        }
        return this ;
    };
}

export default Thing ;