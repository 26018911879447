import initDialog from '../initDialog'

import { AddDialog } from '../AddDialog'

import identify from "../../../things/identify"

import api from '../../../configs/api'

const styles = () => ({});

export class AddConceptualObjectDialog extends AddDialog
{
    getLocale = () => this.props.locale.dialogs.application.conceptualObject.add ;

    prepare = item =>
    {
        let {
            category,
            location,
            name
        } = item ;

        category = identify(category) ;
        
        const { thingRef } = this.props ;
        if( thingRef )
        {
            location = identify(thingRef) ;
        }
        else
        {
            location = identify(location) ;
        }

        return (
        {
            category,
            location,
            name
        });
    };
}

AddConceptualObjectDialog.defaultProps =
{
    ...AddDialog.defaultProps,
    uri : api.conceptualObjects.url
};

AddConceptualObjectDialog.propTypes =
{
    ...AddDialog.propTypes
};

export default initDialog({ styles })( AddConceptualObjectDialog ) ;
