import React from 'react'

import PropTypes from 'prop-types'

import clsx from 'clsx'

import notEmpty from 'vegas-js-core/src/strings/notEmpty'

import { Typography } from '@material-ui/core'

import LevelIcon from '@material-ui/icons/Equalizer'

import withi18n from '../../contexts/i18n/withi18n'

import getHorizontalElements from '../getHorizontalElements'
import getLocaleDescription  from '../../things/getLocaleDescription'
import getVerticalElements   from '../getVerticalElements'
import { ThingCell } from './ThingCell'


class CourseCell extends ThingCell
{
    getContent = () =>
    {
        const { thing } = this.props;
        if ( thing )
        {
            const line1 = getHorizontalElements( { factory:this , elements:[ 'type' , 'status' , 'level' ] } ) ;
            const line2 = getHorizontalElements( { factory:this , elements:[ 'description' ] } ) ;
            return getVerticalElements(
            {
                className : 'flex-1' ,
                elements  :
                [
                    ...(line1 ? [line1] : [] ) ,
                    ...(line2 ? [line2] : [] )
                ]
            }) ;
        }
        return null;
    };

    getDescription = ( { className = null , ...props } = {} ) =>
    {
        const thing = this.getThing() ;
        if( thing )
        {
            const description = getLocaleDescription( thing , this.props.lang ) ;
            if( notEmpty( description ) )
            {
                return (
                    <Typography
                        className = { clsx( 'font-normal text-justify' , className ) }
                        variant   = 'caption'
                        { ...props }
                    >
                        { description }
                    </Typography>
                );
            }
        }
        return null ;
    };

    getLevel = ( { className = null , key = undefined } = {}  ) => this.getWord('level',this.props.levelIcon)( { className , key } ) ;

    getLocale = () => this.props.locale.display.cells.course;

    getStatus = ( { className = null , key=undefined } = {}  ) => this.getWord('status')( { className , key } ) ;
}

CourseCell.defaultProps =
{
    ...ThingCell.defaultProps,
    description     : true,
    level           : true,
    levelIcon       : <LevelIcon fontSize='small' />,
    status          : true,
    subtitleVariant : 'alternativeHeadline' ,
    titleVariant    : 'auto',
    typable         : false
};

CourseCell.propTypes =
{
    ...ThingCell.propTypes,
    description : PropTypes.bool ,
    level       : PropTypes.bool,
    levelIcon   : PropTypes.element,
    status      : PropTypes.bool
};

export default withi18n(CourseCell) ;
