import React from 'react'

import { Redirect } from 'react-router-dom'

import generate from './generate'

const DEFAULT_OPTIONS =
{
    home   : '/home' ,
    routes : null
}

const getRoutes = ( options = null ) =>
{
    const {
        home,
        routes
    } = { ...DEFAULT_OPTIONS , ...options } ;

    return (
    [
        ...generate( routes ) ,
        { path : '/', component : () => <Redirect to={ home } /> }
    ]);
}

export default getRoutes ;