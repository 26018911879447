import React from 'react'

import PropTypes from 'prop-types'

import clsx from 'clsx'

import format   from 'vegas-js-core/src/strings/fastformat'
import notEmpty from 'vegas-js-core/src/strings/notEmpty'

import moment from 'moment'

import TodayIcon from '@material-ui/icons/Today'

import ThingComponent from '../things/ThingComponent'

import withi18n  from '../../contexts/i18n/withi18n'
import withSnack from '../../contexts/snack/withSnack'

export class DateLabel extends ThingComponent 
{  
    formatDate = date =>
    {
        if( moment.isMoment(date) )
        {
            const { formatVariant:variant } = this.props;
            if( variant === 'fromNow' )
            {
                return date.fromNow() ;
            }
            else
            {
                let { pattern } = this.props ;
                if( pattern === null )
                {
                    const locale = this.getLocale() ;
                    const { pattern:p } = locale ;
                    if( notEmpty(p) )
                    {
                        pattern = p ;
                    }
                }
                
                if( notEmpty( pattern ) )
                {
                    return date.format( pattern ) ;
                }
            }
        }
        return null ;
    }
    
    getLocale = () => this.props.locale.display.labels.date;
    
    render = () =>
    {
        let date ;
        
        const { member } = this.props;
        
        const thing = this.getThing();
        
        if( thing && thing[member])
        {
            date = thing[member] ;
            if( notEmpty(date) || date instanceof Date )
            {
                date = moment(date) ;
            }
            else
            {
                date = null ;
            }
        }
        
        let label = this.formatDate(date) ; 
        if ( notEmpty(label) ) 
        {
            const { className } = this.props;
            const locale = this.getLocale() ;
            return (
                <div className={ clsx("flex flex-row items-center", className)} >
                   { this.getIcon() }
                    <div className="flex flex-col pl-8">
                        { this.getTitle() }
                        { this.getLabel( format( locale.label , label ) ) }
                    </div>
                </div>
            );
        }
        return null;
    }
}
    
DateLabel.defaultProps =
{
    ...ThingComponent.defaultProps,
    clickable     : false ,
    copyable      : false ,
    formatVariant : 'default' ,
    iconVariant   : 'icon' ,
    member        : 'date' ,
    icon          : <TodayIcon fontSize='small' />,
    pattern       : null,
    titleable     : false
}

DateLabel.propTypes =
{
    ...ThingComponent.propTypes,
    formatVariant : PropTypes.oneOf(['default','fromNow']),
    member        : PropTypes.string,
    pattern       : PropTypes.string
}

export default withSnack( withi18n( DateLabel ) );