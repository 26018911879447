import generify from '../generify'

import Thing from '../Thing'
import Word  from '../Word'

import MedicalMethod   from './MedicalMethod'
import MedicalSampling from './MedicalSampling'

/**
 * A clinically evident human or animal disease.
 * @memberOf things
 */
class Disease extends Thing
{
    /**
     * Creates a new Place instance.
     * @constructor
     * @param object The generic object to initialize the object the first time.
     */
    constructor( object = null )
    {
        super() ;
    
        /**
         * The collection of all analysis methods of the disease.
         * @type {array}
         */
        this.analysisMethod = null ;
    
        /**
         * The collection of all analysis methods of the disease.
         * @type {array}
         */
        this.analysisSampling = null ;
        
        /**
         * The level of the disease.
         * @type {Word}
         */
        this.level = null ;
    
        /**
         * The collection of all transmission methods of the disease.
         * @type {Array}
         */
        this.transmissionMethod = null ;
        
        this.set( object ) ;
    }


    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        super.clear() ;
        this.analysisMethod =
        this.analysisSampling =
        this.level =
        this.transmissionMethod = null ;
        return this ;
    }

    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new Disease( this.toObject() ) ;
    }

    /**
     * Populate the object with specific behaviors or types. Launched by default in the constructor and in the set methods.
     * @returns {Thing} The current reference.
     */
    populate()
    {
        const {
            additionalType,
            analysisMethod,
            analysisSampling,
            level,
            transmissionMethod
        } = this ;

        if( additionalType && !(additionalType instanceof Word))
        {
            this.additionalType = new Word(additionalType) ;
        }

        if( analysisMethod instanceof Array )
        {
            this.analysisMethod = analysisMethod.map( item => item instanceof MedicalMethod ? item : new MedicalMethod(item) ) ;
        }
        
        if( analysisSampling instanceof Array )
        {
            this.analysisSampling = analysisSampling.map( item => item instanceof MedicalSampling ? item : new MedicalSampling(item) ) ;
        }
        
        if( level && !(level instanceof Word))
        {
            this.level = new Word(level) ;
        }
        
        if( transmissionMethod instanceof Array )
        {
            this.transmissionMethod = transmissionMethod.map( item => item instanceof Word ? item : new Word(item) ) ;
        }

        return this ;
    }

    /**
     * Returns the generic object representation of the item.
     * @return {Object} The generic object representation of the item.
     */
    toObject( full = true )
    {
        if( full )
        {
            let {
                analysisMethod,
                analysisSampling,
                level,
                transmissionMethod
            } = this ;
            
            analysisMethod     = generify(analysisMethod);
            analysisSampling   = generify(analysisSampling);
            level              = generify(level) ;
            transmissionMethod = generify(transmissionMethod) ;
            
            return {
                ...super.toObject() ,
                analysisMethod,
                analysisSampling,
                level,
                transmissionMethod
            };
        }
        else
        {
            let {
                id,
                additionalType,
                alternateName,
                level,
                name,
                url
            } = this ;
            
            additionalType = generify(additionalType);
            level          = generify(level) ;
            
            return {
                id,
                additionalType,
                alternateName,
                level,
                name,
                url
            };
        }
    }
}

export default Disease ;