import React , { Component } from 'react'

import PropTypes from 'prop-types'

import clamp from 'vegas-js-core/src/maths/clamp'
import round from 'vegas-js-core/src/maths/round'

// Doc : https://github.com/s-yadav/react-number-format
import NumberFormat from 'react-number-format'

class NumberInput extends Component
{
    render()
    {
        const {
            floatCount,
            inputRef,
            isNumericString,
            maximum,
            minimum,
            onChange,
            prefix,
            suffix,
            useFormatted,
            type,
            ...other
        } = this.props;
        return (
        <NumberFormat
            {...other}
            getInputRef     = { inputRef }
            isNumericString = { isNumericString }
            prefix          = { prefix }
            suffix          = { suffix }
            type            = { type }
            onValueChange   = { values =>
            {
                let value ;
                const {
                    formattedValue,
                    floatValue
                } = values ;

                if( useFormatted )
                {
                    value = formattedValue ;
                }
                else if( floatValue )
                {
                    value = round(values.floatValue,floatCount);
                    value = clamp(value,minimum,maximum);
                }

                onChange({ target: { value } });
            }}
        />
      );
    }
}

NumberInput.defaultProps =
{
    allowEmptyFormatting : true ,
    allowNegative        : true ,
    decimalSeparator     : '.',
    floatCount           : 100,
    format               : null ,
    isNumericString      : false,
    maximum              : Number.MAX_VALUE,
    minimum              : Number.MIN_VALUE,
    prefix               : '' ,
    suffix               : '' ,
    thousandSeparator    : ' ',
    type                 : 'text' ,
    useFormatted         : false
};

NumberInput.propTypes =
{
    allowEmptyFormatting : PropTypes.bool,
    allowNegative        : PropTypes.bool,
    decimalSeparator     : PropTypes.string,
    floatCount           : PropTypes.number,
    format               : PropTypes.oneOfType([PropTypes.string,PropTypes.func]),
    inputRef             : PropTypes.func.isRequired,
    isNumericString      : PropTypes.bool,
    onChange             : PropTypes.func.isRequired ,
    maximum              : PropTypes.number,
    minimum              : PropTypes.number,
    prefix               : PropTypes.string ,
    suffix               : PropTypes.string ,
    thousandSeparator    : PropTypes.oneOfType([PropTypes.string,PropTypes.bool]),
    type                 : PropTypes.oneOf(['text', 'tel', 'password']),
    useFormatted         : PropTypes.bool
};

export default NumberInput ;