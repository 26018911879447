import compose from 'vegas-js-core/src/functors/compose'

import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'

import withLocale from '../../../contexts/i18n/withLocale'

import ThingTabsSubContainer, { styles } from '../../../display/containers/ThingTabsSubContainer'

import Technicians   from '../../../display/things/Technicians'
import Veterinarians from '../../../display/things/Veterinarians'

import Technician   from '../../../things/Technician'
import Veterinarian from '../../../things/Veterinarian'


import api from '../../../configs/api'

class Authorities extends ThingTabsSubContainer
{
    getLocale = () => this.props.locale.views.livestock.authorities ;
}

Authorities.defaultProps =
{
    ...ThingTabsSubContainer.defaultProps,
    first  : 'veterinarians' ,
    routes :
    [
        {
            id             : 'veterinarians' ,
            clazz          : Veterinarian ,
            RouteComponent : Veterinarians ,
            uri            : api.livestocks.veterinarians
        },
        {
            id             : 'technicians' ,
            clazz          : Technician ,
            RouteComponent : Technicians   ,
            uri            : api.livestocks.technicians
        }
    ],
    tabs :
    [
        { id : 'veterinarians' , badge : 'veterinarians.length' } ,
        { id : 'technicians'   , badge : 'technicians.length'   }
    ]
};

Authorities.propTypes =
{
    ...ThingTabsSubContainer.propTypes
};

export default compose (
    withStyles( styles ),
    withRouter,
    withLocale
)( Authorities );
