import React from 'react'

import format from 'vegas-js-core/src/strings/format'

import Form from '../../../../../../../display/forms/FormElements'

import Method from "../../../../../../../net/Method"

import api from "../../../../../../../configs/api"

import event from '../../../events/sectorHealthEvent'

import AnnualHealthReview    from '../../../../../../../things/livestock/reviews/AnnualHealthReview'
import SectorHealthEvent     from '../../../../../../../things/livestock/events/SectorHealthEvent'
import SectorReview          from '../../../../../../../things/livestock/reviews/SectorReview'
import Thing                 from '../../../../../../../things/Thing'
import Workshop              from '../../../../../../../things/Workshop'
import SectorHealthEventCell from '../../../../../../../display/things/livestock/events/SectorHealthEvent'

const elements =
[
    {
        type        : Form.SEARCH_SELECTOR ,
        iconifiable : false ,
        searchable  : false ,
        title       : 'Sélectionner un taux de morbidité' ,
        init        :
        {
            id         : 'morbidity',
            key        : 'morbidity',
            label      : 'Taux de morbidité',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            required   : false,
            type       : 'search',
            variant    : 'outlined'
        },
        path : props =>
        {
            const { rel } = props ;
            if( rel instanceof SectorHealthEvent )
            {
                const { subjectOf:sectorReview } = rel ;
                if( sectorReview instanceof SectorReview )
                {
                    const { subjectOf:annualReview } = sectorReview ;
                    if( annualReview instanceof AnnualHealthReview )
                    {
                        const { about } = annualReview ;
                        if( about instanceof Workshop )
                        {
                            const { breeding } = about ;
                            if( breeding instanceof Thing )
                            {
                                const { id } = breeding ;
                                if( id )
                                {
                                    return format( api.settings.morbidityRatesTypesByBreedingsTypes, id ) ;
                                }
                            }
                        }
                    }
                }
            }
            return null ;
        }
    },
    {
        type        : Form.SEARCH_SELECTOR ,
        clearLabel  : 'Vider' ,
        closeLabel  : 'Fermer' ,
        iconifiable : false ,
        searchable  : false ,
        title       : 'Sélectionner un taux de mortalité' ,
        init        :
        {
            id         : 'mortality',
            key        : 'mortality',
            label      : 'Taux de mortalité',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            required   : false,
            type       : 'search',
            variant    : 'outlined'
        },
        path : ( props ) =>
        {
            const { rel } = props ;
            if( rel instanceof SectorHealthEvent )
            {
                const { subjectOf:sectorReview } = rel ;
                if( sectorReview instanceof SectorReview )
                {
                    const { subjectOf:annualReview } = sectorReview ;
                    if( annualReview instanceof AnnualHealthReview )
                    {
                        const { about } = annualReview ;
                        if( about instanceof Workshop )
                        {
                            const { breeding } = about ;
                            if( breeding instanceof Thing )
                            {
                                const { id } = breeding ;
                                if( id )
                                {
                                    return format( api.settings.mortalityRatesTypesByBreedingsTypes, id ) ;
                                }
                            }
                        }
                    }
                }
            }
            return null ;
        }
    },
    {
        type : Form.DIVIDER,
        init : { key : 'events-divider-1' , light : true }
    },
    {
        type         : Form.DATE ,
        defaultValue : null ,
        format       : 'L' ,
        init         :
        {
            id            : 'startDate',
            key           : 'startDate',
            autoOk        : true ,
            cancelLabel   : 'Annuler' ,
            clearable     : true ,
            clearLabel    : 'Vider' ,
            invalidLabel  : '-',
            disableFuture : false ,
            disablePast   : false ,
            fullWidth     : true ,
            label         : 'Date de début' ,
            required      : false,
            okLabel       : 'OK',
            variant       : 'outlined'
        }
    },
    {
        type         : Form.DATE ,
        defaultValue : null,
        format       : 'L' ,
        minDate      : 'validFrom' ,
        init :
        {
            id            : 'endDate',
            key           : 'endDate',
            autoOk        : true ,
            cancelLabel   : 'Annuler' ,
            clearable     : true ,
            clearLabel    : 'Vider' ,
            invalidLabel  : '-',
            disableFuture : false ,
            disablePast   : false ,
            label         : 'Date de fin' ,
            fullWidth     : true ,
            okLabel       : 'OK',
            variant       : 'outlined'
        }
    }
];

const sectorHealthEvents =
{
    add :
    {
        agree       : 'Créer' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour créer un événement sanitaire.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...' ,
        method      : Method.PATCH,
        success     : 'Ajout réussi!' ,
        title       : 'Ajouter un événement',
        elements
    },
    edit :
    {
        agree       : 'Sauver' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour modifier cet événement.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...'  ,
        method      : Method.PATCH,
        mock        : false ,
        success     : 'Mise à jour réussie!' ,
        title       : 'Modifier l\'évenement',
        elements
    },
    labels :
    {
        add  : 'Ajouter un événement',
        card : 'Événement'
    },
    remove :
    {
        agree       : 'Supprimer' ,
        description : 'Souhaitez-vous supprimer cet événement sanitaire ?' ,
        disagree    : 'Annuler' ,
        iconifiable : false ,
        item        :
        {
            render : ( item ) =>
            {
                return (
                <SectorHealthEventCell
                    thing   = { item }
                    variant = 'basic'
                />)
            }
        },
        loading : 'Suppression en cours...' ,
        method  : Method.PATCH ,
        mock    : false ,
        title   : 'Supprimer l\'événement',
        useID   : false
    },
    event,
    title    : 'Événements sanitaires' ,
    tooltips :
    {
        add    : "Ajouter" ,
        edit   : "Éditer" ,
        remove : "Supprimer"
    }
};

export default sectorHealthEvents ;
