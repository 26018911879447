import React, { Component } from 'react'

import clsx from 'clsx'

import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'

const styles = () =>
({

    root :
    {

    }
});

const defaultCss = "inline-block uppercase rounded bg-contain text-center font-medium text-white text-lg py-2 px-4 mr-4 " ;

const colors =
{
    DELETE : "bg-red-600" ,
    GET    : "bg-green-600" ,
    PATCH  : "bg-yellow-400" ,
    POST   : "bg-blue-600" ,
    PUT    : "bg-teal-600" ,
    UNKNOW : "bg-purple-500"
};

class MethodBadge extends Component
{
    static DELETE = "DELETE" ;
    static GET    = "GET" ;
    static PATCH  = "PATCH" ;
    static POST   = "POST" ;
    static PUT    = "PUT" ;

    render = () =>
    {
        let { classes , value } = this.props ;

        value = ( (value instanceof String) || typeof(value) === "string" ) ? value.toUpperCase() : "" ;

        let css = defaultCss ;

        switch( value )
        {
            case MethodBadge.DELETE :
            {
                css += colors.DELETE ; break ;
            }
            case MethodBadge.GET :
            {
                css += colors.GET ; break ;
            }
            case MethodBadge.PATCH :
            {
                css += colors.PATCH ; break ;
            }
            case MethodBadge.POST :
            {
                css += colors.POST ; break ;
            }
            case MethodBadge.PUT :
            {
                css += colors.PUT ; break ;
            }
            default :
            {
                css += colors.UNKNOW ;
            }
        }

        if( (value instanceof String) || typeof(value) === "string" )
        {
            return <span className={clsx(css,classes.root)}>{value.toLowerCase()}</span> ;
        }
        else
        {
            return null ;
        }
    }
}

MethodBadge.propTypes =
{
    classes : PropTypes.object.isRequired
};

export default withStyles( styles ) ( MethodBadge ) ;
