import React from 'react'

import clean from 'vegas-js-core/src/objects/clean'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { Grid } from '@material-ui/core'

import OpacityIcon from '@material-ui/icons/Opacity'

import withDialogs from '../../../../../../contexts/dialogs/withDialogs'
import withi18n    from '../../../../../../contexts/i18n/withi18n'

import LabelItem from '../../../../../../components/items/LabelItem'

import ThingContainer from '../../../../../containers/ThingContainer'

import AnnualHealthReviewVO   from '../../../../../../things/livestock/reviews/AnnualHealthReview'
import WaterTreatmentReviewVO from '../../../../../../things/livestock/reviews/WaterTreatmentReview'
import Thing                  from '../../../../../../things/Thing'

const styles = () => ({});

const half =
{
    sm : 6 ,
    xs : 12
};

class WaterTreatmentReview extends ThingContainer
{
    getContent = () =>
    {
        let { thing } = this.props;

        const locale = this.getLocale() ;

        const { labels } = locale ;

        let annualAnalysis;
        let annualPotability;
        let drinkingTreatment;
        let filtered;

        if( thing instanceof WaterTreatmentReviewVO )
        {
            let {
                annualAnalysis    : aa ,
                annualPotability  : ap ,
                drinkingTreatment : dt ,
                filtered          : fi
            } = thing ;

            annualAnalysis    = !!(aa) ? labels.yes : labels.no ;
            annualPotability  = !!(ap) ? labels.yes : labels.no ;
            drinkingTreatment = !!(dt) ? labels.yes : labels.no ;
            filtered          = !!(fi) ? labels.yes : labels.no ;
        }

        return (
            <Grid container spacing={4}>
                <Grid item {...half}>
                    <LabelItem title = { labels.filtered }>
                        { filtered }
                    </LabelItem>
                </Grid>
                <Grid item {...half}>
                    <LabelItem title = { labels.drinkingTreatment }>
                        { drinkingTreatment }
                    </LabelItem>
                </Grid>
                <Grid item {...half}>
                    <LabelItem title = { labels.annualAnalysis }>
                        { annualAnalysis }
                    </LabelItem>
                </Grid>
                <Grid item {...half}>
                    <LabelItem title = { labels.annualPotability }>
                        { annualPotability }
                    </LabelItem>
                </Grid>
            </Grid>
        );
    };

    getEntry = init =>
    {
        let empty = {} ;

        if( init instanceof Thing )
        {
            empty = init.toObject() ;
        }
        else if( init )
        {
            empty = { ...init } ;
        }

        const review = new WaterTreatmentReviewVO(empty);

        const { subjectOf } = this.props ;
        if( subjectOf )
        {
            review.subjectOf = subjectOf ;
        }

        return review ;
    };

    getLocale = () => this.props.locale.things.livestock.reviews.annual.reviews.waterTreatmentReview ;

    onEdit = ( item ) =>
    {
        const { onChange } = this.props ;
        if( onChange )
        {
            onChange( item ) ;
        }
    };

    editPrepare = ( item ) =>
    {
        let {
            subjectOf:review
        } = item ;

        if( item && review instanceof AnnualHealthReviewVO )
        {
            review = review.toObject() ;
            if( item instanceof WaterTreatmentReviewVO )
            {
                review.waterTreatment = clean( item.toObject() ) ;
            }
            else
            {
                review.waterTreatment = null ;
            }

            //console.log( this + ' editPrepare' , review ) ;

            return { review:JSON.stringify(review) };
        }

        return null  ;
    };
}

WaterTreatmentReview.defaultProps =
{
    ...ThingContainer.defaultProps ,
    icon       : <OpacityIcon/> ,
    optionMode : 'edit'
};

WaterTreatmentReview.propTypes =
{
    ...ThingContainer.propTypes
};

export default withStyles( styles )( withRouter( withi18n( withDialogs( WaterTreatmentReview ) ) ) ) ;
