import React from 'react'

import format  from 'vegas-js-core/src/strings/fastformat'
import ucFirst from 'vegas-js-core/src/strings/ucFirst'

import AboutIcon      from '@material-ui/icons/AccountBox'
import ActivitiesIcon from '@material-ui/icons/LineWeight'
import PersonIcon     from '@material-ui/icons/Person'
import SessionsIcon   from '@material-ui/icons/CloudCircle'

import api   from '../../configs/api'
import paths from '../../configs/paths'

import UserVO from '../../vo/User'

import ThingView , { defaultStyle } from '../../display/views/ThingView'

import About from './view/About'

import ActivityTable from '../../display/tables/ActivitiesTable'
import Sessions      from '../../display/things/Sessions'

import initView from '../../display/views/initView'

const styles = defaultStyle();

class User extends ThingView
{
    getLocale = () => this.props.locale.views.user ;

    getRoutePath = path =>
    {
        const { thing } = this.state ;
        if( thing instanceof UserVO )
        {
            return format( path , thing.uuid ) ;
        }
        return path ;
    }

    getSubtitle = thing =>
    {
        if( thing instanceof UserVO )
        {
            const { email } = thing ;
            return email  ;
        }
        return null ;
    };

    getTitle = thing =>
    {
        if( thing instanceof UserVO )
        {
            const { lang } = this.props;
            return ucFirst(thing.getFullNameOrUsername(lang))  ;
        }
        return null ;
    };
}

User.defaultProps =
{
    ...ThingView.defaultProps,
    back       : true ,
    clazz      : UserVO,
    first      : 'about' ,
    icon       : <PersonIcon/>,
    path       : api.users,
    responsive : true ,
    routes     :
    [
        { id : 'about'      , RouteComponent : About         , path : '/users/{0}/about'      } ,
        { id : 'activities' , RouteComponent : ActivityTable , path : '/users/{0}/activities' , routePath : '/users/{0}/activityLogs' } ,
        { id : 'Sessions'   , RouteComponent : Sessions      , path : '/users/{0}/sessions'   } ,
    ],
    tabs :
    [
        { id : 'about'      , icon : <AboutIcon      className='pr-8' /> } ,
        { id : 'activities' , icon : <ActivitiesIcon className='pr-8' /> } ,
        { id : 'sessions'   , icon : <SessionsIcon   className='pr-8' /> }
    ],
    titleAuto : false
};

User.propTypes =
{
    ...ThingView.propTypes,
    path : paths.users
};

export default initView( User, styles );
