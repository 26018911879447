import Form from '../../../../display/forms/FormElements'

import Method from "../../../../net/Method";

const elements =
[
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'taxID',
            key          : 'taxID',
            label        : 'Numéro de Siret',
            autoFocus    : false,
            fullWidth    : true,
            helperText   : null,
            margin       : 'normal',
            required     : false,
            select       : false,
            autoComplete : 'off' ,
            type         : 'text',
            variant      : 'outlined'
        }
    },
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'vatID',
            key          : 'vatID',
            label        : 'N° de TVA',
            autoFocus    : false,
            fullWidth    : true,
            helperText   : null,
            margin       : 'normal',
            required     : false,
            select       : false,
            autoComplete : 'off' ,
            type         : 'text',
            variant      : 'outlined'
        }
    }
];

const prepare = ( item ) =>
{
    let {
        taxID,
        vatID
    } = item ;
    
    const object = (
    {
        taxID,
        vatID
    });
    return object ;
};

const legal =
{
    edit :
    {
        agree       : 'Sauver' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour modifier les informations légales de cet personne.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...' ,
        method      : Method.PATCH ,
        success     : 'Mise à jour réussie!' ,
        title       : 'Modifier' ,
        elements,
        prepare
    },
    labels :
    {
        empty  : '----' ,
        taxID  : 'Numéro de Siret',
        vatID  : 'N° de TVA'
    },
    title    : 'Informations légales' ,
    tooltips :
    {
        add    : 'Ajouter',
        edit   : 'Modifier',
        remove : 'Supprimer'
    }
};

export default legal ;