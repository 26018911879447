import React , { Fragment } from 'react'

import clsx from 'clsx'

import PropTypes from 'prop-types'

import notEmpty from 'vegas-js-core/src/strings/notEmpty'

import format from 'format-duration'

import ReactAudioPlayer from 'react-audio-player'

import FileIcon, { defaultStyles } from 'react-file-icon'

import { Fab, IconButton, Typography } from '@material-ui/core'

import PauseIcon      from '@material-ui/icons/Pause'
import PlayIcon       from '@material-ui/icons/PlayArrow'
import VolumeMuteIcon from '@material-ui/icons/VolumeUp'
import VolumeOffIcon  from '@material-ui/icons/VolumeOff'

import withi18n from '../../contexts/i18n/withi18n'

import AudioSlider from '../../components/audio/AudioSlider'

import AudioObject          from '../../things/creativework/media/AudioObject'
import getLocaleDescription from '../../things/getLocaleDescription'
import getMediaSource       from '../../things/getMediaSource';

import { ThingCell } from './ThingCell'

// thumb : 640x120

export class AudioCell extends ThingCell
{
    constructor( props )
    {
        super( props );
        this.player  = null ;
        this.timeout = null ;
        this.state   =
        {
            ...this.state,
            current  : 0 ,
            duration : 0 ,
            muted    : false,
            playing  : false
        }
    }

    getAvatar = () =>
    {
        const thing = this.getThing();
        if( thing )
        {
            const { encodingFormat } = thing ;
            if( notEmpty( encodingFormat ) )
            {
                let extension = encodingFormat.split('/')[1];

                let styles ;
                if( notEmpty(extension) && defaultStyles.hasOwnProperty(extension) )
                {
                    styles = defaultStyles[extension];
                }

                return (
                    <div className = 'flex items-center justify-center mr-12'>
                        <FileIcon
                            size      = { 26 }
                            extension = { extension }
                            { ...styles }
                        />
                    </div>
                )
            }
        }
        return this.props.icon ;
    }

    getContent = () =>
    {
        const thing = this.getThing();
        if( thing )
        {
            const { contentUrl } = thing ;
            if( notEmpty( contentUrl ) )
            {
                const { className } = this.props ;

                const {
                    current,
                    duration,
                    muted,
                    playing
                } = this.state ;

                const source = getMediaSource( thing ) ;

                let sourceElements ;
                if( source )
                {
                    sourceElements = source.map( element => <source key={element.encodingFormat} src={ element.contentUrl } type={ element.encodingFormat } /> ) ;
                }

                const children = <Fragment>{ sourceElements }</Fragment>

                return (
                <div
                    className = { clsx( "flex flex-col w-full my-16" , className ) }
                >
                    <div className='flex-1 flex flex-row items-center'>

                        <div className='flex-0' >
                            { playing ? this.getPauseButton() : this.getPlayButton() }
                        </div>

                        <AudioSlider
                            className         = 'flex-1'
                            min               = { 0 }
                            max               = { duration }
                            marks             = { false }
                            onChange          = { ( event , current ) =>
                            {
                                clearTimeout( this.timeout ) ;
                                if( this._mounted )
                                {
                                    this.setState({ current }) ;
                                }
                            } }
                            onChangeCommitted = { this.onChangeCommitted }
                            value             = { current }
                            valueLabelDisplay = 'auto'
                            valueLabelFormat  = { value => format(value*1000) }
                        />

                        { this.getTime({ className:'ml-8'}) }
                        { this.getMuteButton({ className:'ml-4' } ) }

                    </div>

                    <ReactAudioPlayer
                        controls         = { false }
                        listenInterval   = { 350 }
                        muted            = { muted }
                        ref              = { ref => this.player = ref }
                        onEnded          = { () => this.setPlaying(false) }
                        onError          = { () => this.setPlaying( false , { current:0 } ) }
                        onListen         = { this.onListen }
                        onLoadedMetadata = { this.onLoadedMetadata }
                        onPause          = { () => this.setPlaying(false) }
                        onPlay           = { () => this.setPlaying(true) }
                        children         = { children }
                    />
                </div>);
            }
        }
        return null ;
    };

    getDescription = ( { className = null , ...props } = {} ) =>
    {
        const { description:visible } = this.props ;
        if( visible )
        {
            const thing = this.getThing() ;
            if( thing )
            {
                const description = getLocaleDescription( thing , this.props.lang ) ;
                if( notEmpty( description ) )
                {
                    return (
                        <Typography
                            className = { clsx( 'font-normal text-justify py-8' , className ) }
                            variant   = 'caption'
                            { ...props }
                        >
                            { description }
                        </Typography>
                    );
                }
            }
        }
        return null ;
    };

    getFooter = () =>
    {
        const { footer } = this.props ;
        if( footer )
        {
            const description =  this.getDescription() ;
            if( description )
            {
                const { className } = this.props;
                return (
                    <div className={ clsx( "flex flex-col flex-1" , className ) } >
                        <div className="flex flex-row items-center w-full justify-between" >
                            { description }
                        </div>
                    </div>
                );
            }
        }
        return null ;
    };

    getLocale = () => this.props.locale.display.cells.audio;

    getLocaleTime = () =>
    {
        const {
            defaultTime = '00:00' ,
            separator
        }
        = this.getLocale() || {} ;

        let d = defaultTime ;
        let t = defaultTime ;

        let { current , duration } = this.state ;
        if( current >= 0 && duration >= 0 )
        {
            if( duration >= 0 )
            {
                d = format(duration*1000);
            }

            if( current >= 0 )
            {
                t = format(current*1000);
            }
        }

        return t + separator + d ;
    };

    getTime = ( { className = null } = {} ) =>
    {
        const time = this.getLocaleTime() ;
        if( notEmpty(time) )
        {
            return (
                <Typography
                    className ={ className }
                    variant   = 'caption'
                >
                    { time }
                </Typography>
            )
        }
        return null ;
    }

    getMuteButton = ( { className = null } = {} ) =>
    {
        const { muted } = this.state ;
        return (
            <IconButton
                className = { className }
                onClick   = { this.toggleMuted }
                size      = 'small'
            >
                { muted ?  <VolumeOffIcon/> : <VolumeMuteIcon/> }
            </IconButton>
        )
    };

    getPauseButton = ( { className = null } = {} ) =>
    (
        <Fab
            aria-label = 'play'
            className  = { clsx( 'mr-16' , className ) }
            color      = 'primary'
            onClick    = { this.pause }
            size       = 'small'
        >
            <PauseIcon />
        </Fab>
    );

    getPlayButton = ( { className = null } = {} ) =>
    (
        <Fab
            aria-label = 'play'
            className  = { clsx( 'mr-16' , className ) }
            color      = 'primary'
            onClick    = { this.play }
            size       = 'small'
        >
            <PlayIcon />
        </Fab>
    );

    getThing = () =>
    {
        const { thing } = this.props ;
        if( thing )
        {
            if( thing instanceof AudioObject )
            {
                return thing ;
            }
            const { member } = this.props ;
            if( notEmpty(member) && thing[member] instanceof AudioObject )
            {
                return thing[member] ;
            }
        }
        return null ;
    }

    onChangeCommitted = ( event , current ) =>
    {
        clearTimeout( this.timeout ) ;
        this.seek( current );
    };

    onListen = current =>
    {
        this.timeout = setTimeout( () =>
        {
            if( this._mounted )
            {
                this.setState({current}) ;
            }
        } , this.props.delay ) ;
    };

    onLoadedMetadata = event =>
    {
        const { target } = event ;
        if( target )
        {
            const { duration } = target ;
            if( this._mounted )
            {
                this.setState({ duration }) ;
            }
        }
    };

    pause = () =>
    {
        if( this.player )
        {
            const { audioEl : { current:audio } = {} } = this.player ;
            if( audio )
            {
                audio.pause() ;
            }
        }
    };

    play = () =>
    {
        if( this.player )
        {
            const { audioEl : { current:audio } = {} } = this.player ;
            if( audio )
            {
                audio.play() ;
            }
        }
    };

    seek = time =>
    {
        if( this.player )
        {
            const { audioEl : { current:audio } = {} } = this.player ;
            if( audio )
            {
                audio.currentTime = time ;
                if( !this.state.playing)
                {
                    audio.play();
                }
            }
        }
    };

    setPlaying = ( playing , options = null ) =>
    {
        const { callback , ...rest } = options || {} ;
        if( this._mounted )
        {
            this.setState( { playing , ...rest } , callback ) ;
        }
    };

    stop = () =>
    {
        if( this.player )
        {
            const { audioEl : { current:audio } = {} } = this.player ;
            if( audio )
            {
                audio.pause() ;
                audio.currentTime = 0 ;
            }
        }
    };

    toggleMuted = () =>
    {
        this.setState({ muted:!this.state.muted } ) ;
    }

    unmount = () =>
    {
        clearTimeout(this.timeout) ;
        if( this.player )
        {
            const { audioEl : { current:audio } = {} } = this.player ;
            if( audio )
            {
                audio.src = null ;
            }
        }
    }
}

AudioCell.defaultProps =
{
    ...ThingCell.defaultProps,
    delay       : 250 ,
    description : true ,
    divider     : false,
    mode        : 'label' ,
    member      : null ,
    player      : true ,
};

AudioCell.propTypes =
{
    ...ThingCell.propTypes,
    delay       : PropTypes.number.isRequired,
    description : PropTypes.bool ,
    member      : PropTypes.string ,
    player      : PropTypes.bool
};

export default withi18n( AudioCell ) ;
