import getElements          from './getElements'
import getHorizontalDivider from './getHorizontalDivider'

const settings = 
{ 
    dividerFactory : getHorizontalDivider 
}  ;

const getVerticalElements = getElements( settings ) ;

export default getVerticalElements ;
