import React from 'react'

import compose  from 'vegas-js-core/src/functors/compose'
import isString from 'vegas-js-core/src/isString'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import initApp from '../../contexts/app/initApp'

import { Link , withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { Grid } from '@material-ui/core'

import MemoryIcon from '@material-ui/icons/Work'

import LabelItem from '../../components/items/LabelItem'

import ThingContainer from '../containers/ThingContainer'

import TechnicianVO from '../../things/Technician'
import ThingVO      from '../../things/Thing'

import api from '../../configs/api'

const styles = () => ({});

const full =
{
    md : 12 ,
    sm : 12 ,
    xs : 12
};

class Technician extends ThingContainer
{
    getContent = () =>
    {
        let { lang, thing } = this.props;

        const locale = this.getLocale() ;

        const { labels } = locale ;

        let options ;
        let person ;

        if( thing instanceof TechnicianVO )
        {
            let { person : p } = thing ;

            if( p instanceof ThingVO )
            {
                options =
                {
                    component : Link ,
                    to        :
                    {
                        pathname : p.url.split(api.url)[1]
                    }
                };
                person = p.getLocaleName( lang ) ;
            }
            else
            {
                person = null ;
            }
        }

        return (
            <Grid container spacing={4}>
                <Grid item {...full}>
                    <LabelItem
                        enabled = {!!(person)}
                        title   = { labels.name }
                        { ...options }
                    >
                        { isString(person) ? ucFirst(person) : labels.empty }
                    </LabelItem>
                </Grid>
            </Grid>
        );
    };

    getEntry = init =>
    {
        let empty = {} ;
        if( init instanceof ThingVO )
        {
            empty = init.toObject() ;
        }
        else if( init )
        {
            empty = { ...init } ;
        }
        return new TechnicianVO(empty);
    };

    getLocale = () => this.props.locale.things.technician;
}

Technician.defaultProps =
{
    ...ThingContainer.defaultProps ,
    icon       : <MemoryIcon/> ,
    optionMode : 'none'
};

Technician.propTypes =
{
    ...ThingContainer.propTypes
};

export default compose( withStyles( styles ) , withRouter , initApp )( Technician ) ;
