import React from 'react'

import clsx from 'clsx'

import isNotNull from 'vegas-js-core/src/isNotNull'
import isString  from 'vegas-js-core/src/isString'

import moment from 'moment/moment'

import PropTypes from 'prop-types'

import ucFirst from 'vegas-js-core/src/strings/ucFirst'

import Divider    from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Paper      from '@material-ui/core/Paper'
import Tooltip    from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'

import DeleteIcon from '@material-ui/icons/Delete'

import TransitionGroup from '../../transitions/TransitionGroup'

import AddButton      from '../../components/buttons/AddButton'
import ThingAvatar    from '../avatars/ThingAvatar'
import ThingContainer from '../containers/ThingContainer'

import Thing from '../../things/Thing'

class ThingGrid extends ThingContainer
{
    createChild = () => null ;

    getAddButton = ( disabled = false ) =>
    {
        if( this.isEditable() )
        {
            const { addIcon, addButtonProps } = this.props ;
            const locale = this.getLocale() ;
            return (
            <Paper
                className = "p-20 flex items-center justify-center bg-transparent"
                elevation = { 2 }
            >
                <AddButton
                    onClick   = { () => this.openAddDialog() }
                    disabled  = { disabled }
                    icon      = { addIcon }
                    locale    = { locale }
                    placement = 'top'
                    { ...addButtonProps }
                />
            </Paper>
            );
        }
        return null ;
    };

    getAvatar = ( thing , className = 'mr-16' ) =>
    (
        <ThingAvatar
            className   = { className }
            defaultIcon = { this.props.avatarIcon }
            thing       = { thing }
        />
    );

    getBadgeLabel = () =>
    {
        const { member, thing } = this.props ;
        if( thing && thing.hasOwnProperty(member) )
        {
            const children = thing[member] ;
            if( children instanceof Array )
            {
                return children.length ;
            }
        }
        return null ;
    };

    /**
     * Returns the main content of the component.
     * @returns {Component|PureComponent|Fragment|Array} the main content of the component.
     */
    getContent = () =>
    {
        const { member, thing } = this.props ;
        if( thing && thing.hasOwnProperty(member) )
        {
            const children = thing[member];
            if( (children instanceof Array) && (children.length > 0) )
            {
                return children.map( ( item , index ) =>
                {
                    if( this.isValid(item) )
                    {
                        return this.createChild( item, index ) ;
                    }
                    return null ;
                }).filter( isNotNull ) ;
            }
        }
        return null ;
    };

    getDeleteButton = ( child, editable = false ) =>
    {
        const { deletable } = this.props ;
        if( editable && deletable )
        {
            const {
                DeleteButtonComponent,
                DeleteButtonIcon,
                DeleteButtonProps
            } = this.props ;

            let button = (
            <DeleteButtonComponent
                onClick = { () => this.openRemoveDialog(child) }
                { ...DeleteButtonProps }
            >
                { DeleteButtonIcon }
            </DeleteButtonComponent>
            );

            const locale = this.getLocale();
            if( locale )
            {
                const { tooltips } = locale ;
                if( tooltips )
                {
                    const { remove } = tooltips ;
                    if( isString( remove ) )
                    {
                        button = (
                           <Tooltip placement='top' title={locale.tooltips.remove}>
                               { button }
                           </Tooltip>
                        )
                    }
                }
            }

            return button ;
        }

        return null ;
    };

    getEntry = ( init = null ) =>
    {
        let { clazz, emptyClazz } = this.props ;

        if( emptyClazz )
        {
            clazz = emptyClazz ;
        }

        if( !clazz )
        {
            clazz = Thing ;
        }

        let generic ;
        if( init instanceof Thing )
        {
            generic = init.toObject() ;
        }
        else if( init )
        {
            generic = { ...init } ;
        }

        const object = new clazz(generic) ;

        object.subjectOf = this.getEntrySubjectOf() ;

        return object ;
    };

    getEntrySubjectOf = () => this.props.thing ;

    isValid = child =>
    {
        if( child )
        {
            const { clazz } = this.props ;
            if( clazz instanceof Array )
            {
                let len = clazz.length ;
                for( let i = 0 ; i<len ; i++ )
                {
                    if( child instanceof clazz[i] )
                    {
                        return true ;
                    }
                }
            }
            else if( clazz instanceof Function )
            {
                return child instanceof clazz ;
            }
        }
        return false ;
    };

    onAdd = item =>
    {
        const {
            member,
            onChange,
            thing
        } = this.props ;

        if( item && thing && thing.hasOwnProperty(member))
        {
            let children = thing[member] ;

            if( !(children instanceof Array))
            {
                children = [] ;
            }

            children.push( item ) ;

            thing[member] = children ;
            thing.modified = moment(new Date()).toISOString();

            if( thing instanceof Thing )
            {
                thing.populate();
            }

            if( this._mounted )
            {
                this.forceUpdate() ;
            }

            if( onChange instanceof Function )
            {
                onChange(thing);
            }
        }
    };

    onEdit = item =>
    {
        // console.log( this + ' onEdit' , item ) ;
        const { member, onChange, thing } = this.props ;
        if( item && thing && thing.hasOwnProperty(member))
        {
            let children = thing[member] ;
            if( !(children instanceof Array))
            {
                children = [] ;
            }

            const { id } = item ;

            children = children.map( element =>
            {
                return ( element.id === id ) ? item : element ;
            });

            thing[member] = children ;
            thing.modified = moment(new Date()).toISOString();

            if( thing instanceof Thing )
            {
                thing.populate();
            }

            if( this._mounted )
            {
                this.forceUpdate() ;
            }

            if( onChange instanceof Function )
            {
                onChange(thing);
            }
        }
    };

    onRemove = id =>
    {
        const { member, onChange, thing } = this.props ;

        if( thing && thing.hasOwnProperty(member))
        {
            let children = thing[member] ;

            if( children instanceof Array )
            {
                children = children.filter( item => item.id !== id ) ;
            }
            else
            {
                children = null ;
            }

            thing[member]  = children ;
            thing.modified = moment(new Date()).toISOString();

            if( thing instanceof Thing )
            {
                thing.populate();
            }

            if( this._mounted )
            {
                this.forceUpdate() ;
            }

            if( onChange instanceof Function )
            {
                onChange(thing);
            }
        }
    };

    render = () =>
    {
        const {
            member,
            thing
        } = this.props ;

        if( thing && thing.hasOwnProperty(member) )
        {
            const { animate, animateProps } = this.props ;

            const locale = this.getLocale() ;

            const {
                className,
                divider,
                style,
                variant
            } = this.props ;

            let grid = variant === 'full' ? 'grid-cols-1' : 'grid-cols-1 sm:grid-cols-2' ;

            let view = (
            <div className="flex-1 flex flex-col space-y-24">
                <div className="flex flex-row items-center justify-between mb-20">
                    <div className="flex flex-row items-center">
                        { this.getIcon() }
                        <Typography
                            className ="ml-12 font-semibold"
                            variant   ="subtitle1"
                        >
                            {ucFirst(locale.title)}
                        </Typography>
                        { this.getBadge() }
                    </div>
                </div>
                { divider && <Divider light className='my-16'/> }
                <div className={ clsx( 'grid gap-16' , grid ) } >
                    { this.getContent() }
                    { this.getAddButton() }
                </div>
            </div>
            ) ;

            if( animate )
            {
                view = (
                    <TransitionGroup
                        duration = { 250 }
                        enter    = {{ animation: "transition.slideUpIn" }}
                        { ...animateProps }
                    >
                        { view }
                    </TransitionGroup>
                )
            }

            return (
            <div
                className = { clsx( 'flex-1 px-8 py-12' , className ) }
                style     = { style }
            >
                { view }
            </div>
            );
        }
        return null ;
    };
}

ThingGrid.defaultProps =
{
    ...ThingContainer.defaultProps,
    avatarIcon            : null ,
    badge                 : true ,
    clazz                 : Thing ,
    deletable             : false ,
    DeleteButtonComponent : IconButton,
    DeleteButtonIcon      : <DeleteIcon/>,
    deleteButtonProps     : null,
    disabled              : false ,
    divider               : true ,
    emptyClazz            : null ,
    icon                  : null ,
    member                : null ,
    variant               : 'default'
};

ThingGrid.propTypes =
{
    ...ThingContainer.propTypes,
    avatarIcon            : PropTypes.element ,
    clazz                 : PropTypes.oneOfType([PropTypes.func,PropTypes.arrayOf(PropTypes.func)]),
    deletable             : PropTypes.bool,
    DeleteButtonComponent : PropTypes.object,
    DeleteButtonIcon      : PropTypes.element,
    DeleteButtonProps     : PropTypes.object,
    disabled              : PropTypes.bool,
    divider               : PropTypes.bool,
    member                : PropTypes.string,
    emptyClazz            : PropTypes.oneOfType([PropTypes.func,PropTypes.arrayOf(PropTypes.func)]),
    variant               : PropTypes.oneOf([ 'default', 'full' ])
};

export default ThingGrid ;
