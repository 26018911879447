const thesauri =
{
    helmet :
    {
        title : 'Thésaurus' ,
        metas : []
    },
    loading : 'Chargement ...' ,
    empty   : 'Aucun thésaurus disponible' ,
    remove  :
    {
        agree       : 'Supprimer' ,
        description : 'Souhaitez-vous supprimer le thésaurus:' ,
        disagree    : 'Annuler' ,
        loading     : 'Suppression en cours...' ,
        title       : 'Supprimer ce thésaurus ?'
    },
    removeAll :
    {
        agree        : 'Supprimer' ,
        description  : 'Souhaitez-vous supprimer le thésaurus:' ,
        descriptionX : 'Souhaitez-vous supprimer les thésaurus :' ,
        disagree     : 'Annuler' ,
        loading      : 'Suppression en cours...' ,
        title        : 'Supprimer un thésaurus ?' ,
        titleX       : 'Supprimer des thésaurus ?'
    },
    removeItem  : "Supprimer un thésaurus" ,
    removeItemX : "Supprimer {0} thésaurus" ,
    tooltips    :
    {
        add    : 'Ajouter' ,
        remove : 'Supprimer'
    },
    search : 'Rechercher un thésaurus ...' ,
    title  : 'Thésaurus'
};

export default thesauri ;
