import React , { Component } from 'react'

import PropTypes from 'prop-types'

import moment from 'moment/moment'

import format   from 'vegas-js-core/src/strings/fastformat'
import isString from 'vegas-js-core/src/isString'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import initApp from '../../../contexts/app/initApp'

import LabelItem from '../../../components/items/LabelItem'

import visibilityMode, { visibilityModes } from '../../../styles/modes/visibilityMode'

class Birth extends Component
{
    getLocale = () => this.props.locale.things.person.birth ;
    
    render()
    {
        const { visibility } = this.props ;
        
        if( visibility === visibilityMode.NONE )
        {
            return null ;
        }
        
        const { icon, person } = this.props ;
        
        const locale = this.getLocale() ;
        
        const {
            date,
            pattern,
            place,
            separator,
            title,
            empty
        } = locale ;
        
        let label = null ;
        
        if( person )
        {
            const { birthDate , birthPlace } = person ;
            
            if( birthDate || birthPlace )
            {
                label = [] ;
                
                if( isString(birthDate) && (birthDate !== '') )
                {
                    label.push( format(date, moment(birthDate).format(pattern)) )  ;
                }
                
                if( isString(birthPlace) && (birthPlace !== '') )
                {
                    label.push( format(place,birthPlace) ) ;
                }
                
                if( label.length > 0 )
                {
                    label = ucFirst( label.join( (separator) )) ;
                }
                else
                {
                    label = null ;
                }
            }
        }
        
        const isEmpty = !(isString(label) && (label !== '')) ;
        
        if( visibility === visibility.AUTO && isEmpty )
        {
            return null ;
        }
        
        if( isEmpty && isString(empty) )
        {
            label = empty ;
        }
        
        return <LabelItem icon={icon} title={title}>{label}</LabelItem> ;
    }
}

Birth.defaultProps =
{
    icon       : null, // <FaBirthdayCake size="1em"/>
    person     : null ,
    style      : null ,
    visibility : visibilityMode.NORMAL
};

Birth.propTypes =
{
    icon       : PropTypes.element,
    locale     : PropTypes.object,
    person     : PropTypes.object,
    style      : PropTypes.oneOfType([PropTypes.object, PropTypes.array]) ,
    visibility : PropTypes.oneOf(visibilityModes)
};

export default initApp(Birth) ;