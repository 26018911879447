const remove =
{
    agree        : 'Supprimer' ,
    description  : 'Souhaitez-vous supprimer cet enregistrement ?' ,
    descriptionX : 'Souhaitez-vous supprimer définitivement ces {0} enregistrements?' ,
    disagree     : 'Annuler' ,
    title        : 'Supprimer cet enregistrement ?',
    titleX       : 'Supprimer ces enregistrements ?'
};

export default remove ;