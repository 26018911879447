import React , { Component } from 'react'

import PropTypes from 'prop-types'

import clsx from 'clsx'

import { Link } from 'react-router-dom'

import compose  from 'vegas-js-core/src/functors/compose'
import notEmpty from 'vegas-js-core/src/strings/notEmpty'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import initApp from '../../../contexts/app/initApp'

import { withStyles } from '@material-ui/core/styles'

import { Typography } from '@material-ui/core'

import ThingAvatar from "../../avatars/ThingAvatar"

import Technician   from '../../../things/Technician'
import Thing        from '../../../things/Thing'
import Veterinarian from '../../../things/Veterinarian'

import getLocaleThingLabel from '../../../things/getLocaleThingLabel'

import api from '../../../configs/api'

export const LABEL = 'label';
export const LINK  = 'link';
export const NONE  = 'none';

export const modes =
[
    LABEL, LINK, NONE
];

const styles = () =>
({
    root :
    {
        alignItems    : 'center' ,
        display       : 'flex' ,
        flexDirection : 'row' ,
        padding       : '20px 0px',
        width         : '100%'
    }
});

class AuthorityLabel extends Component
{
    getLabel = () =>
    {
        let text
        const { thing } = this.props ;
        if( thing )
        {
            const { lang } = this.props ;
            switch( true )
            {
                case thing instanceof Technician :
                {
                    const { person } = thing ;
                    if( person )
                    {
                        text = getLocaleThingLabel( person , lang ) ;
                    }
                    break ;
                }
                case thing instanceof Veterinarian :
                {
                    const { authority } = thing ;
                    if( authority )
                    {
                        text = getLocaleThingLabel( authority , lang ) ;
                    }
                    break ;
                }
                default :
                {
                    text = getLocaleThingLabel( thing , lang ) ;
                }
            }
        }
        return notEmpty(text) ? ucFirst(text) : null ;
    };

    render = () =>
    {
        const {
            className,
            classes,
            mode,
            style,
            thing
        } = this.props ;

        if( thing && mode !== NONE )
        {
            const { avatarStyle, icon } = this.props ;

            let name ;

            name = this.getLabel() ;

            if( name )
            {
                name = (
                <Typography
                    className = 'text-blue-800 font-medium no-underline hover:no-underline'
                    variant   = 'subtitle1'
                >
                    { name }
                </Typography>);

                if( mode === LINK )
                {
                    const { url } = thing ;
                    if( notEmpty(url) )
                    {
                        name = (
                        <Link to={{ pathname : url.split(api.url)[1] }}>
                            { name }
                        </Link>);
                    }
                }
            }

            return (
            <div className={ clsx(classes.root,className) } style={style}>
                <ThingAvatar className='mr-16' icon={icon} thing={thing} style={avatarStyle} />
                <div>
                { name }
                </div>
            </div>
            );
        }
        return null ;
    };
}

AuthorityLabel.defaultProps =
{
    avatarStyle : null,
    className   : null,
    icon        : null,
    mode        : LINK,
    style       : null,
    thing       : null
};

AuthorityLabel.propTypes =
{
    avatarStyle : PropTypes.object,
    className   : PropTypes.string,
    icon        : PropTypes.element,
    mode        : PropTypes.oneOf(modes),
    style       : PropTypes.object,
    thing       : PropTypes.instanceOf(Thing)
};

export default compose( withStyles( styles ) , initApp )( AuthorityLabel ) ;
