import compose from 'vegas-js-core/src/functors/compose'

import initApp from '../../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { styles }  from '../ThingChildren'

import Authorities from '../Authorities'

import PropertyValue from '../../../things/PropertyValue'
import Thing         from '../../../things/Thing'
import Person from '../../../things/Person';
import Organization from '../../../things/Organization';

class Providers extends Authorities
{
    addMapUri = ( item , uri ) =>
    {
        if( item instanceof PropertyValue )
        {
            const { value } = item ;
            if( value instanceof Thing )
            {
                uri += '/' + value.id ;
            }
        }
        return uri ;
    };

    addPrepare = item =>
    {
        let type = null ;
        if( item )
        {
            const { value } = item ;

            if( value instanceof Person )
            {
                type = 'person' ;
            }
            else if ( value instanceof Organization )
            {
                type = 'organization' ;
            }
        }
        return { type } ;
    }

    getLocale = () => this.props.locale.things.organization.providers ;

    removePrepare = item =>
    {
        let type = null ;
        if( item instanceof Person )
        {
            type = 'person' ;
        }
        else if ( item instanceof Organization )
        {
            type = 'organization' ;
        }
        return { type } ;
    }
}

Providers.defaultProps =
{
    ...Authorities.defaultProps ,
    member : 'providers'
};

Providers.propTypes =
{
    ...Authorities.propTypes
};

export default compose( withStyles( styles ) , withRouter , initApp )( Providers );