import api  from '../../../../configs/api'
import Form from '../../../../display/forms/FormElements'

import Person from '../../../../things/Person'
import Thing  from '../../../../things/Thing'

const disabledSuggestions = props =>
{
    const { rel } = props ;
    if( rel instanceof Thing )
    {
        const { subjectOf } = rel ;
        if( subjectOf instanceof Thing )
        {
            const { actor } = subjectOf ;
            if( actor instanceof Array && actor.length > 0 )
            {
                return actor ;
            }
        }
    }
    return null ;
};

const actor =
{
    add :
    {
        agree       : 'Ajouter' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez sélectionner une personne en cliquant ci-dessous.' ,
        elements    :
        [
            {
                type       : Form.SEARCH_SELECTOR ,
                clazz      : Person,
                path       : api.people.url ,
                clearLabel : 'Vider' ,
                closeLabel : 'Fermer' ,
                init       :
                {
                    id        : 'value',
                    key       : 'value',
                    label     : 'Acteur',
                    autoFocus : false,
                    fullWidth : true,
                    margin    : 'normal',
                    required  : true,
                    type      : 'search',
                    variant   : 'outlined'
                },
                inputProps : { label : 'Acteur...' },
                logics     : { disabledSuggestions }
            }
        ],
        fail    : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading : 'Chargement en  cours...' ,
        success : 'Ajout du nouvel acteur réussi!' ,
        title   : 'Ajouter un acteur'
    },
    remove :
    {
        agree       : 'Supprimer' ,
        description : 'Souhaitez-vous supprimer cet acteur:' ,
        disagree    : 'Annuler' ,
        loading     : 'Suppression en cours...' ,
        title       : 'Supprimer l\'acteur ?'
    },
    title : 'acteurs' ,
    tooltips :
    {
        add    : "Ajouter" ,
        edit   : "Éditer" ,
        remove : "Supprimer"
    }
};

export default actor ;
