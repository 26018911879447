import React from 'react'

import isString from 'vegas-js-core/src/isString'

import withConfig  from '../../../contexts/config/withConfig'
import withDialogs from '../../../contexts/dialogs/withDialogs'
import withi18n    from '../../../contexts/i18n/withi18n'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import format from 'vegas-js-core/src/strings/fastformat'
import ucFirst from 'vegas-js-core/src/strings/ucFirst'

import Divider    from '@material-ui/core/Divider'
import Paper      from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import DashboardIcon from '@material-ui/icons/Dashboard'
import StoreIcon     from '@material-ui/icons/StoreMallDirectory'

import NumberValue from '../../../things/NumberValue'
import Thing       from '../../../things/Thing'
import Workplace   from '../../../things/Workplace'
import Workshop    from '../../../things/Workshop'

import ThingGrid from '../../../display/grids/ThingGrid'

import Sectors from './Sectors'

import api from '../../../configs/api'

const prepare = item =>
{
    const { identifier, name } = item ;
    return (
    {
        name       : name ,
        identifier : identifier instanceof Thing ? String(identifier.id) : null
    });
};

const styles = () => ({});

class Workplaces extends ThingGrid
{
    addPrepare = prepare ;

    change = item =>
    {
        const { thing:workshop , onChange } = this.props ;

        // console.log( this + ' change' , workshop , item ) ;

        if( workshop instanceof Workshop )
        {
            if( item instanceof Workplace )
            {
                const { workplaces } = workshop ;

                if( workplaces instanceof Array && workplaces )
                {
                    let position = workplaces.findIndex( entry => entry.id === item.id ) ;
                    if( position > -1 )
                    {
                        workplaces[position] = item ;
                    }

                }
                else
                {
                   workplaces.push( item ) ;
                }

                workshop.workplaces = workplaces ;
            }

        }

        if( onChange instanceof Function )
        {
            onChange( workshop ) ;
        }
    };

    createChild = ( thing , index ) =>
    {
        const { thing:workshop } = this.props ;
        if( workshop instanceof Workshop && thing instanceof Workplace )
        {
            return (
                <Paper
                    className = "p-20 bg-gray-200"
                    key       = { 'item_' + index }
                    elevation = { 1 }
                >
                    { this.getHeader( thing ) }
                    <Divider light className="my-16"/>
                    <Sectors
                        onChange  = { this.change }
                        uri       = { format( api.workplaces.sectors , workshop.id, thing.id ) }
                        subjectOf = { this.props.thing }
                        thing     = { thing }
                    />
                </Paper>
            );
        }
        return null ;
    };

    editPrepare = prepare ;

    getLocale = () => this.props.locale.views.workplaces ;

    getHeader = item =>
    {
        if( item instanceof Workplace )
        {
            const { lang } = this.props ;

            let { identifier, name } = item ;

            let title ;
            let subtitle ;

            if( name )
            {
                name = item.getLocaleName( lang ) ;
                if( isString(name) )
                {
                    title = ucFirst(name) ;
                }
            }

            if ( identifier instanceof NumberValue )
            {
                let { additionalType, value } = identifier ;

                let txt = [] ;

                if( additionalType instanceof Thing )
                {
                    txt.push( ucFirst(additionalType.getLocaleName(lang)) ) ;
                }

                if( value )
                {
                    txt.push( value ) ;
                }

                if( txt.length > 0 )
                {
                    txt = txt.join( ' › ' ) ;
                }
                else
                {
                    txt = null ;
                }

                if( !isString(title) )
                {
                    title = txt ;
                }
                else
                {
                    subtitle = (
                    <Typography
                        className="mr-8 font-medium"
                        variant='subtitle2'
                    >
                        {txt}
                    </Typography>) ;
                }
            }

            if( isString(title) )
            {
                if( this.isEditable() )
                {
                    title = (
                    <Typography variant='subtitle1'>
                        <button className="text-blue-800 font-medium" onClick={ () => this.openEditDialog(item) }>
                            { title }
                        </button>
                    </Typography>);
                }
                else
                {
                    title = (
                    <Typography className = "text-gray-800 font-medium" variant='subtitle1'>
                        { title }
                    </Typography>);
                }
            }

            return (
            <div className="flex flew-row justify-between items-center">
                <div className="flex flex-col">
                    <div className="flex flew-row items-center mb-8">
                        <StoreIcon className="mr-16" />
                        { title }
                    </div>
                    { subtitle }
                </div>
                { this.getDeleteButton( item , this.isEditable() ) }
            </div>
            );
        }
        return null ;
    };
}

Workplaces.defaultProps =
{
    ...ThingGrid.defaultProps,
    addButtonProps : { color : 'primary' } ,
    animate   : false ,
    badge     : true ,
    clazz     : Workplace,
    deletable : true ,
    icon      : <DashboardIcon/>,
    member    : 'workplaces' ,
    variant   : 'full'
};

Workplaces.propTypes =
{
    ...ThingGrid.propTypes
};

export default withStyles( styles )( withRouter( withConfig( withDialogs( withi18n(Workplaces) ) ) ) ) ;
