import initDialog from '../initDialog'

import { RemoveDialog } from '../RemoveDialog'

const styles = () => ({});

class RemoveTelephoneDialog extends RemoveDialog
{
    getLocale = () => this.props.locale.dialogs.things.telephone.remove ;
}

RemoveTelephoneDialog.defaultProps =
{
    ...RemoveDialog.defaultProps
};

RemoveTelephoneDialog.propTypes =
{
    ...RemoveDialog.propTypes
};

export default initDialog({ styles })( RemoveTelephoneDialog );
