import React from 'react'

import Icon from '@material-ui/icons/AccountBox'

import withDatasList from './withDatasList'

import ThingsList from './ThingsList'

import RemoveUserDialog from '../dialogs/remove/RemoveUserDialog'

import UserCell from '../cells/UserCell'
import User     from '../../vo/User'

class UsersList extends ThingsList
{
    getLocale = () => this.props.locale.display.lists.users ;
}

UsersList.defaultProps =
{
    ...ThingsList.defaultProps ,

    CellComponent         : UserCell,
    RemoveDialogComponent : RemoveUserDialog ,

    activable    : false,
    addable      : false,
    addMode      : 'reload',
    clazz        : User,
    icon         : <Icon />,
    queries      : { sort : '-familyName,-givenName,-name,-email' } ,
    selectPolicy : 'none'
};

UsersList.propTypes =
{
    ...ThingsList.propTypes
};

export default withDatasList()( UsersList ) ;
