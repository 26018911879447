export const initFooter  = { display : false } ;
export const initNavbar  = { display : true  } ;
export const initToolbar = { display : true  } ;

export const initTheme =
{
    main   : 'sweetHues',
    navbar : 'dark',
    toolbar: 'dark',
    footer : 'dark'
};

export const getSettings = (
{
    defaultFooter  = null,
    defaultNavbar  = null,
    defaultToolbar = null ,
    defaultTheme   = null
} = {} ) =>
({
    footer  = null ,
    navbar  = null ,
    theme   = null ,
    toolbar = null
} = {} ) =>
{
    defaultFooter  = defaultFooter  || initFooter ;
    defaultNavbar  = defaultNavbar  || initNavbar ;
    defaultToolbar = defaultToolbar || initToolbar ;
    defaultTheme   = defaultTheme   || initTheme ;
    return (
    {
        layout :
        {
            config :
            {
                footer  : { ...defaultFooter  , ...footer  },
                navbar  : { ...defaultNavbar  , ...navbar  },
                toolbar : { ...defaultToolbar , ...toolbar },
            }
        },
        theme : { ...defaultTheme ,...theme }
    })
};

export default getSettings() ;