import React from 'react'

import PropTypes from 'prop-types'

import clsx from 'clsx'

import isString from 'vegas-js-core/src/isString'
import format   from 'vegas-js-core/src/strings/fastformat'
import notEmpty from 'vegas-js-core/src/strings/notEmpty'

import { Avatar, Tooltip } from '@material-ui/core'

import TeamIcon from '@material-ui/icons/Group'

import ThingComponent from '../things/ThingComponent'

import getLocaleThingLabel from '../../things/getLocaleThingLabel'
import Team                from '../../vo/Team'

import withi18n  from '../../contexts/i18n/withi18n'
import withSnack from '../../contexts/snack/withSnack'

export class TeamLabel extends ThingComponent
{
    getIcon = ( { className = null , label = null, team = null , style = null } ) =>
    {
        const { iconifiable } = this.props;
        if ( iconifiable )
        {
            const {
                clickable,
                copiable,
                disabled,
                icon,
                iconProps,
                onClick
            } = this.props;

            const locale = this.getLocale();

            const { color = '666666' } = team || {} ;

            let avatar = (
                <Avatar
                    className = { clsx( 'bg-gray-200 mx-4 w-32 h-32 shadow' , (clickable && !disabled) ? 'cursor-pointer' : '' , className ) }
                    color     = 'secondary'
                    size      = 'small'
                    style     = {{ backgroundColor: "#" + color , color: "#ffffff" , ...style }}
                    onClick   = { () =>
                    {
                        if( clickable && !disabled )
                        {
                            if( notEmpty(label) )
                            {
                                const { copy : { snack } = {} } = locale ;
                                this.copy( label , snack ) ;
                            }
                            if ( onClick instanceof Function )
                            {
                                onClick( this.getThing() , team, label ) ;
                            }
                        }
                    }}
                    { ...iconProps }
                >
                    { icon }
                </Avatar>
            );

            if ( clickable && !disabled && copiable )
            {
                const { copy : { tooltip } = {} } = locale ;
                if( isString(tooltip) )
                {
                    avatar =
                    (
                        <Tooltip placement='top' title={tooltip}>
                            { avatar }
                        </Tooltip>
                    ) ;
                }
            }

            return avatar ;
        }
        return null;
    }

    getLocale = () => this.props.locale.display.labels.team;

    render = () =>
    {
        let team ;
        const thing = this.getThing();
        if( thing )
        {
            const { member } = this.props ;
            if( isString( member ) && (thing[member] instanceof Team) )
            {
                team = thing[member] ;
            }
        }

        if( team instanceof Team )
        {
            const { lang , upperFirst } = this.props;

            const locale = this.getLocale() ;

            const label  = format( locale.label, getLocaleThingLabel(team, lang, upperFirst) )  ;

            if (notEmpty(label) )
            {
                const { className } = this.props;
                return (
                    <div className={ clsx("flex flex-row items-center p-8", className)} >
                        <div className="flex-initial">{ this.getIcon({ label, team }) } </div>
                        <div className="flex flex-col pl-8">
                            { this.getTitle() }
                            { this.getLabel(label) }
                        </div>
                    </div>
                );
            }
        }

        return null;
    }
}

TeamLabel.defaultProps =
{
    ...ThingComponent.defaultProps,
    clickable    : false ,
    copiable     : false,
    icon         : <TeamIcon fontSize='small' />,
    iconProps    : { variant:'rounded' },
    labelProps   : { className : 'text-lg'},
    labelVariant : 'button' ,
    member       : 'team' ,
    upperFirst   : true
}

TeamLabel.propTypes =
{
    ...ThingComponent.propTypes,
    member     : PropTypes.string ,
    upperFirst : PropTypes.bool
}

export default withSnack( withi18n( TeamLabel ) );
