const organization =
{
    copy :
    {
        snack   : 'Organization dans le presse-papiers.' ,
        tooltip : 'Copier l\'organization'
    },
    label : '{0}',
    title : 'Organisation'
};

export default organization ;
