import initDialog from '../initDialog'

import isString  from "vegas-js-core/src/isString"

import { AddDialog } from '../AddDialog'

import api from '../../../configs/api'

const styles = () => ({});

export class AddThesaurusDialog extends AddDialog
{
    getLocale = () => this.props.locale.dialogs.application.thesaurus.add ;

    prepare = item =>
    {
        let {
            alternateName,
            bgcolor,
            color,
            description,
            format,
            name,
            pattern,
            validator
        } = item ;

        const { config : { languages } = {} } = this.props ;
        if( (languages instanceof Array) && languages.length > 0 )
        {
            if( name )
            {
                languages.forEach( lang =>
                {
                    if( !isString(name[lang]) )
                    {
                        name[lang] = '' ;
                    }
                })
            }

            if( description )
            {
                languages.forEach( lang =>
                {
                    if( !isString(description[lang]) )
                    {
                        description[lang] = '' ;
                    }
                })
            }
        }
        else
        {
            console.log( this + ' prepare failed, Impossible to filter the name and description properties. The config.languages collection not must be null or empty.' ) ;
        }

        if( !isString(bgcolor) || bgcolor === '')
        {
            bgcolor = null ;
        }
        else
        {
            bgcolor = bgcolor.toLowerCase();
        }

        if( !isString(color) || color === '')
        {
            color = null ;
        }
        else
        {
            color = color.toLowerCase();
        }

        return (
        {
            alternateName ,
            bgcolor,
            color,
            description,
            format,
            name,
            pattern,
            validator
        });
    };
}

AddThesaurusDialog.defaultProps =
{
    ...AddDialog.defaultProps,
    uri : api.thesaurus.url
};

AddThesaurusDialog.propTypes =
{
    ...AddDialog.propTypes
};

export default initDialog({ styles })( AddThesaurusDialog ) ;
