import React from 'react'

import clsx from 'clsx'

import format   from 'vegas-js-core/src/strings/fastformat'
import isString from 'vegas-js-core/src/isString'
import notEmpty from 'vegas-js-core/src/strings/notEmpty'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import moment from 'moment'

import { FaBirthdayCake } from 'react-icons/fa'

import ThingComponent from '../things/ThingComponent'

import withi18n  from '../../contexts/i18n/withi18n'
import withSnack from '../../contexts/snack/withSnack'

export class BirthLabel extends ThingComponent
{
    getLocale = () => this.props.locale.display.labels.birth ;
    
    render = () =>
    {
        const thing = this.getThing();
        if ( thing ) 
        {
            const { birthDate , birthPlace } = thing;
            if ( birthDate || birthPlace )
            {
                const {
                    date,
                    pattern,
                    place,
                    separator
                } = this.getLocale() || {} ;

                let label = [] ;

                if( isString(birthDate) && (birthDate !== '') )
                {
                    label.push( format(date, moment(birthDate).format(pattern)) )  ;
                }

                if( isString(birthPlace) && (birthPlace !== '') )
                {
                    label.push( format(place,birthPlace) ) ;
                }

                if( label.length > 0 )
                {
                    label = ucFirst( label.join( (separator) )) ;
                }
                else
                {
                    label = null ;
                }

                if ( notEmpty(label) ) 
                {
                    const { className } = this.props;
                    return (
                        <div className={clsx("flex flex-row items-center p-8", className)} >
                            <div className="flex-initial">{ this.getIcon({ label }) } </div>
                            <div className="flex flex-col pl-8">
                                {this.getTitle()}
                                {this.getLabel(label)}
                            </div>
                        </div>
                    );
                }
            }
        }
        return null;
    }
}
    
BirthLabel.defaultProps =
{
    ...ThingComponent.defaultProps,
    icon : <FaBirthdayCake style={{ fontSize:18 }} />
}

BirthLabel.propTypes =
{
    ...ThingComponent.propTypes
}

export default withSnack( withi18n( BirthLabel ) );