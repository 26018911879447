import api from '../../../configs/api'

import Form from '../../../display/forms/FormElements'

import Stage         from '../../../things/Stage'
import PropertyValue from '../../../things/PropertyValue'
import Thing         from '../../../things/Thing'

const stages =
{
    add :
    {
        agree       : 'Ajouter' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez sélectionner un point d\'intérêt en cliquant ci-dessous.' ,
        elements    :
        [
            {
                type       : Form.SEARCH_SELECTOR ,
                path       : api.stages.url ,
                clazz      : Stage ,
                clearLabel : 'Vider' ,
                closeLabel : 'Fermer' ,
                init       :
                {
                    id         : 'value',
                    key        : 'value',
                    label      : 'Point d\'intérêt',
                    autoFocus  : false,
                    fullWidth  : true,
                    margin     : 'normal',
                    required   : true,
                    type       : 'search',
                    variant    : 'outlined'
                },
                inputProps :
                {
                    label : 'Point d\'intérêt...'
                },
                logics :
                {
                    disabledSuggestions : ( props ) =>
                    {
                        const { rel } = props ;
                        if( rel instanceof Thing )
                        {
                            const { subjectOf } = rel ;
                            if( subjectOf instanceof Thing )
                            {
                                const { stages } = subjectOf ;
                                if( stages instanceof Array )
                                {
                                    return stages ;
                                }
                            }
                        }
                        return null ;
                    }
                }
            }
        ],
        fail    : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading : 'Chargement en  cours...' ,
        prepare : ( item ) =>
        {
            return (
            {
                value : item.value
            });
        },
        mapUri : ( item , uri ) =>
        {
            if( item instanceof PropertyValue)
            {
                const { value } = item ;
                if( value instanceof Thing )
                {
                    return uri + '/' + value.id ;
                }
            }
            return uri ;
        },
        success : 'Ajout du point d\'intérêt réussi!' ,
        title   : 'Ajouter un point d\'intérêt'
    },
    remove :
    {
        agree       : 'Supprimer' ,
        description : 'Souhaitez-vous supprimer ce point d\'intérêt:' ,
        disagree    : 'Annuler' ,
        loading     : 'Suppression en cours...' ,
        title       : 'Supprimer le point d\'intérêt ?'
    },
    title : 'Points d\'intérêt' ,
    tooltips :
    {
        add    : "Ajouter" ,
        edit   : "Éditer" ,
        remove : "Supprimer"
    }
};

export default stages ;
