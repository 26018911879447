import React from 'react'

import notEmpty from 'vegas-js-core/src/strings/notEmpty'

import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import PersonIcon         from '@material-ui/icons/Person'

import Form from '../../../../display/forms/FormElements'

import Organization from '../../../../things/Organization'
import Person       from '../../../../things/Person'
import Thing        from '../../../../things/Thing'
import Veterinarian from '../../../../things/Veterinarian'

import api from '../../../../configs/api'

import getExistingSuggestions from '../../../../things/getExistingSuggestions'

const common =
[
    {
        type : Form.TEXTFIELD,
        init :
        {
            id           : 'identifier',
            key          : 'identifier',
            autoComplete : 'off',
            label        : 'N° ordinal',
            autoFocus    : false,
            fullWidth    : true,
            margin       : 'normal',
            required     : false,
            select       : false,
            type         : 'search',
            variant      : 'outlined'
        }
    },
];

const elements =
[
    {
        label : 'Personne' ,
        icon  : <PersonIcon/> ,
        elements :
        [
            {
                type  : Form.SEARCH_SELECTOR,
                path  : api.people.url,
                clazz : Person ,
                disabledSuggestions : getExistingSuggestions({ clazz:Person }) ,
                init  :
                {
                    id         : 'person',
                    key        : 'person',
                    label      : 'Personne',
                    autoFocus  : false,
                    fullWidth  : true,
                    limit      : 0,
                    helperText : 'Obligatoire',
                    margin     : 'normal',
                    required   : true,
                    type       : 'search',
                    variant    : 'outlined'
                },
            },
            ...common
        ]
    },
    {
        label    : 'Organisation' ,
        icon     : <AccountBalanceIcon/> ,
        elements :
        [
            {
                type  : Form.SEARCH_SELECTOR,
                path  : api.organizations.url,
                clazz : Organization ,
                disabledSuggestions : getExistingSuggestions({ clazz:Organization }) ,
                init  :
                {
                    id         : 'organization',
                    key        : 'organization',
                    label      : 'Organisation',
                    autoFocus  : false,
                    fullWidth  : true,
                    limit      : 0,
                    helperText : 'Obligatoire',
                    margin     : 'normal',
                    required   : true,
                    type       : 'search',
                    variant    : 'outlined'
                }
            },
            ...common
        ]
    }
];

const getItem = ( { identifier , unknown = 'unknown' } = {} ) =>
({
    text :
    {
        primary : ( element , props ) =>
        {
            if( element instanceof Veterinarian )
            {
                const { authority } = element ;
                if( authority instanceof Thing )
                {
                    return authority.getLocaleName(props.lang);
                }
            }
            return unknown ;
        },
        secondary : element =>
        {
            if( element instanceof Veterinarian )
            {
                const { identifier:text } = element ;
                if( notEmpty(text) )
                {
                    return identifier + " " + text ;
                }
            }
            return null ;
        }
    }
});

const veterinarian =
{
    add :
    {
        elements,
        agree       : 'Créer' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour enregistrer un nouveau vétérinaire.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en  cours...' ,
        success     : 'Ajout du nouvel enregistrement réussi!' ,
        title       : 'Ajouter un vétérinaire'
    },
    remove     :
    {
        agree        : 'Supprimer' ,
        description  : 'Souhaitez-vous supprimer le vétérinaire suivant :' ,
        descriptionX : 'Souhaitez-vous supprimer les {0} vétérinaires suivants :' ,
        disagree     : 'Annuler' ,
        item         : getItem( { identifier:'N° Ordinal:' , unknown:'Inconnu' } ),
        loading      : 'Suppression en cours...' ,
        title        : 'Supprimer ce vétérinaire ?' ,
        titleX       : 'Supprimer des vétérinaires ?'
    }
};

export default veterinarian ;
