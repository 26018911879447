import React from 'react'

import moment from 'moment/moment'

import compose  from 'vegas-js-core/src/functors/compose'
import isString from 'vegas-js-core/src/isString'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import initApp from '../../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { Grid } from '@material-ui/core'

import IdentityIcon from '@material-ui/icons/AssignmentInd'

import LabelItem from '../../../components/items/LabelItem'

import ThingVO from '../../../things/Thing'
import UserVO  from '../../../vo/User'

import ThingContainer from '../../containers/ThingContainer'

const styles = () => ({});

class User extends ThingContainer
{
    editPrepare = ( item ) =>
    {
        if( item instanceof UserVO )
        {
            const {
                gender,
                givenName,
                familyName,
                team
            } = item ;
            return (
            {
                givenName,
                familyName,
                gender : gender instanceof ThingVO ? String(gender.id) : null ,
                team   : team   instanceof ThingVO ? String(team.id)   : null
            });
        }
        return null ;
    };

    getContent = () =>
    {
        const { thing , lang } = this.props;

        if( thing instanceof UserVO )
        {
            let {
                familyName,
                gender,
                givenName,
                team
            } = thing ;

            if( gender instanceof ThingVO )
            {
                gender = gender.getLocaleName(lang) ;
            }
            else
            {
                gender = null ;
            }

            let teamProps ;

            if( team instanceof ThingVO )
            {
                let {
                    bgcolor:backgroundColor,
                    color,
                } = team ;

                team = team.getLocaleAlternateName(lang) ;
                teamProps =
                {
                    labelProps :
                    {
                        className : 'px-8 rounded text-500 shadow',
                        style     :
                        {
                            color           : isString(color)  ? '#' + color  : '#222222' ,
                            backgroundColor : isString(backgroundColor) ? '#' + backgroundColor : '#FFFFFF'
                        }
                    }
                };
            }
            else
            {
                team = null ;
            }

            const locale = this.getLocale() ;

            const { labels } = locale ;

            return (
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={6}>
                        { this.getLabelItem( givenName , labels.givenName )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        { this.getLabelItem( familyName , labels.familyName )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        { this.getLabelItem( gender , labels.gender )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        { this.getLabelItem( team , labels.team , true, teamProps )}
                    </Grid>
                </Grid>
            );
        }

        return null;
    };

    getEntry = init =>
    {
        const { thing } = this.props ;

        let empty = {} ;
        if( init instanceof ThingVO )
        {
            empty = init.toObject() ;
        }
        else if( init )
        {
            empty = { ...init } ;
        }

        const value = new UserVO(empty) ;
        value.subjectOf = thing ;
        return value ;
    };

    getLabelItem = ( label, title, upperFirst = true , props = null ) =>
    {
        const locale = this.getLocale();
        if( locale )
        {
            const { empty } = locale ;
            const flag = isString(label) ;
            if( upperFirst )
            {
                label = ucFirst(label) ;
                if( isString( title ) )
                {
                    title = ucFirst(title);
                }
            }

            return (
            <LabelItem
                enabled = { flag}
                key     = { 'item_' + title }
                title   = { title }
                { ...props }
            >
                { flag ? label : empty }
            </LabelItem>);
        }

        return null ;
    };

    getLocale = () => this.props.locale.things.person.user;

    onEdit = ( item ) =>
    {
        const { onChange, thing } = this.props ;

        if( thing && item )
        {
            const {
                gender,
                givenName,
                familyName,
                team
            } = item ;

            thing.gender     = gender;
            thing.givenName  = givenName;
            thing.familyName = familyName;
            thing.modified   = moment(new Date()).toISOString();
            thing.team       = team;

            if( thing instanceof ThingVO )
            {
                thing.populate() ;
            }

            if( this._mounted )
            {
                this.forceUpdate();
            }

            if( onChange )
            {
                onChange( thing ) ;
            }
        }
    };

}

User.defaultProps =
{
    ...ThingContainer.defaultProps,
    icon       : <IdentityIcon/>,
    optionMode : 'edit'
};

User.propTypes =
{
    ...ThingContainer.propTypes
};

export default compose( withStyles( styles ) , withRouter , initApp )( User ) ;
