import { createBrowserHistory } from 'history'

const memory = createBrowserHistory();

// memory.listen( (location, action) =>
// {
//     trace( `[HISTORY] - ${location.pathname}${location.search}${location.hash} - ${action}` , true ) ;
// });

export default memory ;

