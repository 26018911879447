import React from 'react'

import clsx from 'clsx'

import PropTypes  from 'prop-types'

import URI from 'urijs'

import camelCase from 'vegas-js-core/src/strings/camelCase'
import debounce  from 'vegas-js-core/src/functors/debounce'
import isNumber  from 'vegas-js-core/src/isNumber'
import isString  from 'vegas-js-core/src/isString'
import notEmpty  from 'vegas-js-core/src/strings/notEmpty'

import { Redirect } from 'react-router-dom'

import { CircularProgress, Typography } from '@material-ui/core'

import BugReportIcon  from '@material-ui/icons/BugReport'
import DeleteIcon     from '@material-ui/icons/Delete'
import DoneIcon       from '@material-ui/icons/DoneAll'
import FavoriteIcon   from '@material-ui/icons/Favorite'
import FindInPageIcon from '@material-ui/icons/FindInPage'
import MoreIcon       from '@material-ui/icons/MoreHoriz'
import NoFavoriteIcon from '@material-ui/icons/FavoriteBorder'

import Pagination       from '@material-ui/lab/Pagination'

import blueGrey from '@material-ui/core/colors/blueGrey'
import green    from '@material-ui/core/colors/green'

import { Pulse as Preloader } from 'react-preloading-component'

import LIST       from '../../net/LIST'
import PATCH         from '../../net/PATCH'
import RequestStatus from '../../net/RequestStatus'

import DialogContainer from '../../components/containers/DialogContainer'
import ScrollContainer from '../../components/containers/ScrollContainer'

import SimpleProgressHeader from '../../components/headers/SimpleProgressHeader'
import ThingAvatar          from '../../display/avatars/ThingAvatar'

import Thing from '../../things/Thing'

import api  from '../../configs/api'
import i18n from '../../locale/fr'

import TransitionGroup from '../../transitions/TransitionGroup'

import getLocaleName from '../../things/getLocaleName'

const defaultValues =
{
    count   : 0,
    first   : true,
    hasMore : false,
    object  : null,
    offset  : 0,
    status  : RequestStatus.NEW,
    things  : null ,
    total   : 0
};

const init = ( values = null ) => ({ ...defaultValues , ...values }) ;

class DatasContainer extends DialogContainer
{
    /**
     * Creates a new DatasContainer instance
     * @param props The properties send to the component to initialize it.
     */
    constructor( props )
    {
        super( props ) ;

        this.onScroll    = debounce( this.onScroll    , 250 ) ;
        this.onScrollTop = debounce( this.onScrollTop , 260 ) ;

        this.canceler  = null ;
        this.nextTimer = null ;
        this.scroller  = null ;
        this.timeout   = null ;

        const options = init() ;

        this.state =
        {
            ...this.state ,
            actives      : {} ,
            activeStatus : RequestStatus.NEW ,
            dialHidden   : false,
            dialOpen     : false,
            status       : RequestStatus.NEW ,
            ...options
        }
    }

    autoload = () =>
    {
        const { autoload } = this.props ;
        if( autoload )
        {
            this.timeout = setTimeout( this.load , this.props.autoloadDelay , this.state.offset ) ;
        }
    };

    /**
     * Build the final display rendering of the component.
     * @param title The title to display in the header of this data container.
     * @param loading Indicates if the view is in the loading mode.
     * @param subtitle The subtitle to display in the header of this data container.
     * @return The view of the component.
     */
    build = ( title = '' , loading = false , subtitle = null ) =>
    {
        const {
            classes,
            className,
            containerClassName,
            optionsVariant,
            scrollable,
            style,
            variant
        } = this.props ;

        let { bottom , top } = this.getPaginations( loading ) ;

        let options ;
        if( optionsVariant === 'default' )
        {
            options = this.getOptions( loading ) ;
        }

        return (
            <div
                className = { clsx(classes.root, className) }
                style     = { style }
            >
                <ScrollContainer
                    className      = { clsx( classes.container , containerClassName ) }
                    onScroll       = { this.onScroll }
                    onScrollBottom = { this.next }
                    onScrollFinish = { this.onScrollFinish }
                    onScrollStart  = { this.onScrollStart }
                    onScrollTop    = { this.onScrollTop }
                    scrollable     = { scrollable && variant === 'infinite' }
                    ref            = { ref => this.scroller = ref }

                >
                    { this.getHeader( title , loading , subtitle ) }
                    { this.getBeforeContent( loading ) }
                    { top }
                    { this.getContent( loading ) }
                    { bottom }
                    { this.getAfterContent( loading ) }
                    { this.getFooter() }
                </ScrollContainer>
                { options }
            </div>
        );
    };

    componentDidUpdate( prevProps , prevState, snapshot )
    {
        const {
            facets,
            limit,
            page,
            pagination,
            search,
            sort
        } = this.props ;

        let load ;

        let { first, offset } = this.state ;

        if( pagination && this.props.variant === 'pagination' && prevState.offset !== offset )
        {
            load = true ;
        }

        if( pagination && this.props.variant === 'pagination' && prevProps.page !== page )
        {
            // console.log( this + ' componentDidUpdate change page' , prevProps.page , '->' , page, 'first:', first ) ;
            if( isNumber(page) )
            {
                offset = (page - 1) * limit ;
            }
            else
            {
                offset = 0 ;
            }
            load = true ;
        }

        if ( prevProps.facets !== facets )
        {
            load = true ;
        }

        if( prevProps.limit !== limit )
        {
            load = true ;
        }

        if( prevProps.search !== search )
        {
            load = true ;
        }

        if( prevProps.sort !== sort )
        {
            load = true ;
        }

        if( load && !first )
        {
            const { variant } = this.props ;
            if( variant === 'pagination' )
            {
                this.load( offset );
            }
            else
            {
                this.reload() ;
            }
        }
        else if( this.update instanceof Function )
        {
            this.update( prevProps , prevState , snapshot ) ;
        }
    }

    getAfterContent = () => null ;

    getAvatar = ( thing , style = undefined ) =>
    {
        if( thing )
        {
            const {
                avatarSize,
                avatarVariant,
                defaultIcon,
            } = this.props ;

            return (
                <ThingAvatar
                    className   = 'mr-0'
                    defaultIcon = { defaultIcon }
                    thing       = { thing }
                    size        = { avatarSize }
                    style       = { style }
                    variant     = { avatarVariant }
                />
            );
        }
        return null ;
    };

    getBadgeColor = () => 'success' ;

    getBeforeContent = () => null ;

    getContent = () => null ;

    /**
     * Returns the current location pathname of the container page.
     * @return the current location pathname of the container page.
     */
    getCurrentPath = ( path = null ) =>
    {
        let uri = this.props.uri || this.props.location.pathname;
        if ( isString(uri) && isString(path) )
        {
            uri += path;
        }
        return uri;
    };

    getEmpty = () =>
    {
        const {
            emptyClassName ,
            emptyIcon:icon ,
            emptyProps ,
            emptyTransition:transition,
            emptyVariant
        } = this.props ;

        let label = this.getEmptyLabel() ;
        if( notEmpty(label) )
        {
            label = (
                <Typography
                    className = { icon ? clsx( 'mt-16' , emptyClassName ) : emptyClassName }
                    color     = 'textPrimary'
                    variant   = { emptyVariant }
                    { ...emptyProps }
                >
                    {label}
                </Typography>
            );
        }
        else
        {
            label = null ;
        }

        if( icon || label )
        {
            let view ;
            if( transition )
            {
                view = (
                    <TransitionGroup
                        className  = 'flex flex-col items-center justify-center'
                        enter      = {{
                            animation  : 'transition.slideUpBigIn',
                            delay      : 500 ,
                            duration   : 200 ,
                            stagger    : 100
                        }}
                    >
                        { icon }
                        { label }
                    </TransitionGroup>
                );
            }
            else
            {
                view = (
                    <div className  = 'flex flex-col items-center justify-center'>
                        { icon }
                        { label }
                    </div>
                );
            }

            const { className , style } = this.props ;
            return (
                <div
                    className = { clsx( 'flex flex-col flex-auto justify-center items-center', className ) }
                    style     = { style }
                >
                    { view }
                </div>
            );
        }

        return null ;
    };

    getEmptyLabel = () =>
    {
        const { empty } = this.getLocale() || {} ;
        if( notEmpty(empty) )
        {
            return empty ;
        }
        return null ;
    };

    /**
     * Returns an empty value object to inject in the add dialog component when is open.
     * @returns {Thing} A new empty value object.
     */
    getEntry = ( init = null ) =>
    {
        const { clazz } = this.props ;
        if( clazz instanceof Function )
        {
            return new clazz( init ) ;
        }
        return null ;
    };

    getFail = () =>
    {
        const {
            className,
            failClassName,
            failIcon:icon,
            failTransition:transition,
            failProps,
            failVariant,
            style
        } = this.props;


        let label = this.getFailLabel() ;
        if( notEmpty(label) )
        {
            label = (
                <Typography
                    className = { icon ? clsx( 'mt-16' , failClassName ) : failClassName }
                    color     = 'textPrimary'
                    variant   = { failVariant }
                    { ...failProps }
                >
                    { label }
                </Typography>
            );
        }
        else
        {
            label = null ;
        }

        if( icon || label )
        {
            let view ;
            if( transition )
            {
                view = (
                    <TransitionGroup
                        className  = 'flex flex-col items-center justify-center'
                        enter      = {{
                            animation  : 'transition.slideUpBigIn',
                            delay      : 500 ,
                            duration   : 200 ,
                            stagger    : 100
                        }}
                    >
                        { icon }
                        { label }
                    </TransitionGroup>
                );
            }
            else
            {
                view = (
                    <div className='flex flex-col items-center justify-center'>
                        { icon }
                        { label }
                    </div>
                );
            }

            return (
                <div
                    className = { clsx( 'flex flex-col flex-auto justify-center items-center', className ) }
                    style     = { style }
                >
                    { view }
                </div>
            );
        }
        return null ;
    };

    getFailLabel = () =>
    {
        const { failed } = this.getLocale() || {} ;
        if( notEmpty(failed) )
        {
            return failed ;
        }
        return null ;
    };

    /**
     * Returns the header component to inject at the top of the container.
     * @param title The title expression to display in the header.
     * @param loading Indicates if the component is loading something.
     * @param subtitle The subtitle expression to display in the header.
     * @returns {React.Component} The header component reference.
     */
    getHeader = ( title = '' , loading = false , subtitle = null ) =>
    {
        const { headerPolicy } = this.props;

        if( headerPolicy !== 'normal' )
        {
            return null ;
        }

        let {
            back ,
            backTooltip ,
            iconButton ,
            iconClick ,
            iconTooltip ,
            onBack,
            optionsVariant
        } = this.props ;

        if( back && !backTooltip )
        {
            const locale = this.getLocale();
            if( locale )
            {
                const { tooltips } = locale ;
                if( tooltips )
                {
                    const { back } = tooltips ;
                    if( back )
                    {
                        backTooltip = back ;
                    }
                }
            }
        }

        let options ;
        if( optionsVariant === 'header' )
        {
            options = this.getOptions( loading ) ;
        }

        const { total } = this.state;

        return (
        <SimpleProgressHeader
            back        = { back }
            backTooltip = { backTooltip }
            onBack      = { onBack }
            badgeColor  = { this.getBadgeColor( loading ) }
            icon        = { this.getIcon( loading ) }
            iconButton  = { iconButton }
            iconClick   = { iconClick }
            iconTooltip = { iconTooltip }
            loading     = { loading }
            options     = { options }
            subtitle    = { subtitle }
            title       = { title }
            total       = { total }
        />);
    };

    getIcon = () => this.props.icon ;

    getOptions = () => null ;

    getPagination = ( loading = false ) =>
    {
        const { things } = this.state;
        if ( things )
        {
            let pageCount = 0;

            let { total } = this.state ;
            if ( total <= 0 )
            {
                total = 0;
            }

            let { limit, page } = this.props;

            let { offset } = this.state;

            if( isNumber(page) )
            {
                offset = (page - 1) * limit ;
            }

            if ( limit > 0 )
            {
                pageCount = Math.ceil(total / limit) ;
                if( !isNumber(page) )
                {
                    page = Math.floor( offset / limit ) + 1 ;
                }
            }

            // console.log('# getPagination total', total, 'pageCount', pageCount, 'page', page, 'limit', limit, 'offset', offset);

            if ( pageCount > 1 )
            {
                const {
                    paginationSize,
                    paginationVariant,
                    showFirstButton,
                    showLastButton
                } = this.props;

                return (
                    <Pagination
                        color           = 'primary'
                        className       = 'my-12'
                        count           = { pageCount }
                        disabled        = { loading }
                        page            = { page }
                        size            = { paginationSize }
                        showFirstButton = { showFirstButton }
                        showLastButton  = { showLastButton }
                        variant         = { paginationVariant }
                        onChange        = { ( event , page ) =>
                        {
                            const {
                                history ,
                                location : { search } = null ,
                                paginationPolicy
                            } = this.props ;

                            if( paginationPolicy === 'history' )
                            {
                                let uri = new URI( search ) ;

                                if( uri.hasSearch('page') )
                                {
                                    uri.removeSearch('page') ;
                                }

                                uri.addSearch( { page } ) ;

                                // console.log( this +  " paginationchange" , page , uri.toString() ) ;

                                history.push( uri.toString() ) ;
                            }
                            else if( paginationPolicy === 'normal' )
                            {
                                if( isNumber(page) && page >= 0 )
                                {
                                    offset = (page - 1) * limit ;
                                    this.setState({ offset } ) ;
                                }
                            }
                        }}
                    />
                );
            }
        }

        return null ;
    };

    getPaginations = loading =>
    {
        let bottom, top ;

        const {
            pagination,
            paginationEdge:edge ,
            variant
        } = this.props ;

        if( pagination && variant === 'pagination' && edge !== false )
        {
            const view = this.getPagination( loading ) ;

            if( edge === 'both' || edge === 'start' )
            {
                top = view ;
            }

            if( edge === 'both' || edge === 'end' )
            {
                bottom = view ;
            }
        }

        return { bottom , top } ;
    };

    getFooter = () =>
    {
        const { variant } = this.props ;
        if( variant === 'infinite' )
        {
            let footer ;
            const { count , hasMore } = this.state ;
            if( hasMore )
            {
                const { status } = this.state ;
                if( status === RequestStatus.PROGRESS )
                {
                    footer = this.props.preloader || <Preloader color={ blueGrey[500]} /> ;
                }
            }
            else if( count > 0 )
            {
                footer = this.getDone() ;
            }

            if( footer )
            {
                return (
                    <div className='flex items-center justify-center w-full py-20'>
                        { footer }
                    </div>
                );
            }
        }
        return null ;
    }

    getDone = () => <DoneIcon fontSize='large' color='disabled' />

    getMore = () => <MoreIcon fontSize='large' color='disabled' />

    getProgress = () =>
    (
        <div className='flex-1 w-full flex justify-center items-center'>
            <CircularProgress style={{ color: green[600] }} size={43} thickness={4} />
        </div>
    );

    getQueries = () => this.props.queries ;

    getSubtitle = () => null ;

    getTitle = () =>
    {
        let { title } = this.getLocale() || {} ;
        const { object } = this.state ;
        if( object )
        {
            title = getLocaleName( object , this.props.lang );
            if( notEmpty( title ) )
            {
                return title;
            }
        }
        return title ;
    };

    goto = offset =>
    {
        this.unselectAll() ;
        this.timeout = setTimeout( this.load , 250 , offset ) ;
    };

    gotoUrl = url =>
    {
        if( isString( url ) )
        {
            const { history } = this.props ;
            if( history )
            {
                history.push( { pathname:url.split(api.url)[1] } );
            }
        }
    };

    hasNext = () =>
    {
        const { hasMore , status } = this.state ;
        return hasMore && status === RequestStatus.SUCCESS ;
    };

    init = () =>
    {
        const {
            initSearch,
            selectPolicy,
            setSelect
        } = this.props ;

        if( setSelect instanceof Function )
        {
            const { selectRemove } = this ;
            const selectable = (selectPolicy !== 'none') && this.isEditable() ;
            setSelect({ selectable , selectableItems:[] , selectRemove });
        }

        if( initSearch )
        {
            const { searchPolicy } = this.props ;

            let searchLabel;
            const locale = this.getLocale() ;
            if( locale )
            {
                const { search } = locale ;
                searchLabel = search ;
            }
            initSearch( { searchable: (searchPolicy === 'normal') , searchLabel , callback : this.autoload } ) ;
        }
        else
        {
            this.autoload() ;
        }
    };

    isLoading = () => this.state.status === RequestStatus.PROGRESS ;

    isValid = child =>
    {
        if( child )
        {
            const { clazz } = this.props ;
            if( clazz instanceof Array )
            {
                let len = clazz.length ;
                for( let i = 0 ; i<len ; i++ )
                {
                    if( child instanceof clazz[i] )
                    {
                        return true ;
                    }
                }
            }
            else if( clazz instanceof Function )
            {
                return child instanceof clazz ;
            }
        }
        return false ;
    };

    load = ( offset = 0 , options = null , path = null ) =>
    {
        clearTimeout( this.timeout ) ;

        const { status } = this.state ;
        if( status !== RequestStatus.PROGRESS )
        {
            const { pagination , parent , variant } = this.props ;
            if( parent && pagination && variant === 'pagination' )
            {
                parent.scrollIntoView() ;
            }

            const { setLoading } = this.props ;
            if( setLoading instanceof Function )
            {
                setLoading( true ) ;
            }

            this.setState({ status:RequestStatus.PROGRESS , offset , ...options } , () =>
            {
                const {
                    apiUrl,
                    facets,
                    getQueries,
                    limit,
                    page,
                    search,
                    sort
                }
                = this.props;

                if( isNumber(page) )
                {
                    offset = (page - 1) * limit ;
                }

                let queries ;
                if( getQueries instanceof Function )
                {
                    queries = getQueries( this ) ;
                }
                else
                {
                    queries = this.getQueries() ;
                }

                const uri = apiUrl + this.getCurrentPath(path) ;

                this.canceler = LIST( uri ,
                {
                    facets,
                    limit,
                    offset,
                    queries,
                    search,
                    sort,
                    cancel  : this._dataCancel ,
                    fail    : this._dataFail ,
                    success : this._dataSuccess
                });
            }) ;

        }
    };

    next = () =>
    {
        if( this.props.variant === 'infinite' )
        {
            this.nextTimer = setTimeout( () =>
            {
                const { count , hasMore } = this.state ;
                if( hasMore )
                {
                    this.load( count );
                }
            } , 200 ) ;
        }
    };

    /**
     * Invoked by the AddDialog panel when a new item is created.
     * @param {*} item - The new item created by the AddDialog component.
     */
    onAdd = item =>
    {
        const { addMode } = this.props ;
        if( addMode === 'redirect')
        {
            if( item && item.hasOwnProperty('url') )
            {
                const { url } = item ;
                this.gotoUrl(url) ;
            }
        }
        else
        {
            this.reload() ;
        }
    };

    onRemove = element =>
    {
        // console.log( this + ' onRemove' , element ) ;

        this.unselectAll() ;

        const { pagination , variant } = this.props ;

        if( pagination && variant === 'pagination' )
        {
            this.load( this.state.offset ) ;
        }
        else
        {
            if( isNumber( element ) )
            {
                element = [ element ] ;
            }
            else if( isString( element ) )
            {
                element = element.split(',') ;
            }

            if( element instanceof Array && element.length > 0 )
            {
                let {
                    count,
                    things,
                    total
                } = this.state ;

                if( things instanceof Array && things.length > 0 )
                {
                    things = things.filter( item =>
                    {
                        if( item )
                        {
                            const { id } = item ;
                            if( id)
                            {
                                return !element.find( key => parseInt(key) === id ) ;
                            }
                        }
                        return false ;
                    }) ;

                    if( variant === 'infinite' )
                    {
                        things = things.map( ( item , index ) =>
                        {
                            if( item instanceof Thing )
                            {
                                item.position = index ;
                            }
                            return item ;
                        }) ;
                    }

                    count = things.length  ;
                }

                if( element instanceof Array )
                {
                    total -= element.length ;
                }
                else if ( element )
                {
                    total-- ;
                }

                const hasMore = count < total ;

                this.reset(
                {
                    count,
                    hasMore,
                    things,
                    total
                } ,
                () =>
                {
                    let { thing , member } = this.props ;
                    if( thing && notEmpty(member) )
                    {
                        member = camelCase('num-' + member) ;
                        if( thing.hasOwnProperty(member) )
                        {
                            thing[member] = count ;
                            const { onChange } = this.props ;
                            if( onChange instanceof Function )
                            {
                                onChange( thing , () =>
                                {
                                    this._refresh( things ) ;
                                }) ;
                            }
                        }
                    }
                    else
                    {
                        this._refresh( things ) ;
                    }
                });
            }
        }
    };

    onScroll = () =>
    {
        if( !this.state.dialHidden )
        {
            this.setState({ dialHidden:true });
        }
    };

    onScrollFinish = () =>
    {
        clearTimeout( this.scrollFinish ) ;
        if( this.state.dialHidden )
        {
            this.scrollFinish = setTimeout( () =>
            {
                if ( this._mounted )
                {
                    this.setState( { dialHidden:false } );
                }
            }, 500 );
        }
    };

    onScrollStart = () =>
    {
        clearTimeout( this.scrollFinish ) ;
        this.setState({ dialHidden:true });
    };

    onScrollTop = () =>
    {
        //this.setState( { enforceDial:true } ) ;
    };

    populate = things => things ;

    reload = () => { this.load( 0 , init() ) ; };

    render = () =>
    {
        if( this.mounted === false )
        {
            return null ;
        }

        const isLoading = this.isLoading() ;

        let { status } = this.state ;
        switch( status )
        {
            case RequestStatus.FAIL :
            {
                return this.getFail() ;
            }

            case RequestStatus.REVOKED :
            {
                const { location } = this.props;
                return <Redirect to={{ pathname: "/welcome" , state: { referrer:location } }} />;
            }

            case RequestStatus.PROGRESS :
            {
                const { loading } = this.getLocale() || {} ;
                return this.build( loading , isLoading ) ;
            }

            case RequestStatus.SUCCESS :
            {
                return this.build( this.getTitle() , isLoading , this.getSubtitle() ) ;
            }

            default :
            {
                return this.build( '' , isLoading ) ;
            }
        }
    };

    reset = ( options = null , callback = null ) =>
    {
        if( this._mounted )
        {
            options = init( options ) ;
            this.setState( { ...options } , callback ) ;
        }
        else if( callback instanceof Function )
        {
            callback() ;
        }
    };

    setFacets = facets =>
    {
        const { setSearch } = this.props ;
        if( setSearch instanceof Function )
        {
            setSearch( { facets } );
        }
    };

    setLimit = limit =>
    {
        const { setSearch } = this.props ;
        if( setSearch instanceof Function )
        {
            setSearch( { limit } );
        }
    }

    setPage = page =>
    {
        const { setSearch} = this.props ;
        if( setSearch instanceof Function )
        {
            setSearch( { page } );
        }
    };

    setSort = sort =>
    {
        const { setSearch } = this.props ;
        if( setSearch instanceof Function )
        {
            setSearch( { sort } );
        }
    };

    unmount = () =>
    {
        clearTimeout( this.timeout ) ;
        clearTimeout( this.nextTimer ) ;
        clearTimeout( this.scrollFinish ) ;

        const { status } = this.state ;
        if( status === RequestStatus.PROGRESS && !!(this.canceler) )
        {
            this.canceler.cancel( this + ' cancel' );
        }

        const { selectReset, resetSearch } = this.props ;

        if( resetSearch instanceof Function )
        {
            resetSearch() ;
        }

        if( selectReset instanceof Function )
        {
            selectReset();
        }
    };

    // -------- SpeedDial

    closeSpeedDial = () => { this.setState({ dialOpen:false }); };
    openSpeedDial  = () => { this.setState({ dialOpen:true  }); };

    // -------- PRIVATE

    activeChange = event =>
    {
        const { actives, activeStatus } = this.state ;
        if( activeStatus !== RequestStatus.PROGRESS )
        {
            const { target } = event ;
            if( target )
            {
                const { activateUri , apiUrl } = this.props ;

                const { checked, value } = target ;

                actives[ String(value) ] = !!(checked) ;

                this.setState( { actives , activeStatus:RequestStatus.PROGRESS } ) ;

                let path = isString(activateUri) ? activateUri : this.getCurrentPath() ;

                this.canceler = PATCH(
                    apiUrl + path + '/' + target.value + '/active/' + ( !!(checked) ? 'true' : 'false' ) ,
                    {
                        cancel  : this._activeCancel(value),
                        fail    : this._activeFail(value),
                        success : this._activeSuccess(value)
                    }
                ) ;
            }
        }
    };

    _activeCancel = id => () =>
    {
        const { actives } = this.state ;
        if( actives.hasOwnProperty(id) )
        {
            delete actives[id] ;
        }
        this.setState( { actives , activeStatus : RequestStatus.FAIL } ) ;
    };

    _activeFail = id => response =>
    {
        if( response )
        {
            const { data } = response ;
            if( data )
            {
                const { message } = data ;
                if( message === 'token revoked')
                {
                    if( this.mounted )
                    {
                        this.setState( { status:RequestStatus.REVOKED } );
                    }
                }
                else
                {
                    console.log( this + " failed, status:" + response.status + ", message:" + message );
                }
            }
        }
        if( this.mounted )
        {
            const { actives } = this.state ;
            if( actives.hasOwnProperty(String(id)) )
            {
                delete actives[String(id)] ;
            }
            this.setState( { actives : actives , activeStatus : RequestStatus.FAIL } ) ;
        }
    };

    _activeSuccess = id => response =>
    {
        if( this.mounted )
        {
            if( response )
            {
                const { data } = response ;
                if( data )
                {
                    let { result } = data ;
                    if( result )
                    {
                        const current = result ;
                        let { actives , things } = this.state ;
                        if( things instanceof Array )
                        {
                            things = things.map( element =>
                            {
                                if( String(element.id) === String(current.id) )
                                {
                                    element.active = Boolean(current.active) ;
                                }
                                return element ;
                            }) ;

                            if( actives.hasOwnProperty(String(id)) )
                            {
                                delete actives[String(id)] ;
                            }

                            this.setState( { actives, activeStatus:RequestStatus.SUCCESS , things } ) ;

                            return ;
                        }
                    }
                }
            }

            this.setState( { activeStatus:RequestStatus.FAIL } ) ;
        }
    };

    _dataCancel = () =>
    {
        const { setLoading } = this.props ;
        if( setLoading instanceof Function )
        {
            setLoading( false ) ;
        }
    };

    _dataFail = response =>
    {
        const { setLoading } = this.props ;
        if( setLoading instanceof Function )
        {
            setLoading( false ) ;
        }

        if( response )
        {
            const {
                data,
                message,
                status
            }
            = response ;

            if( data )
            {
                switch( message )
                {
                    case 'token revoked' :
                    {
                        if( this.mounted )
                        {
                            this.setState({ status:RequestStatus.REVOKED });
                        }
                        break ;
                    }
                    default :
                    {
                        console.log( this + " _dataFail, status:" + status + ", message:" + message );
                    }
                }
            }
        }
        if( this.mounted )
        {
            this.setState({ status:RequestStatus.FAIL });
        }
    };

    _dataSuccess = response =>
    {
        const { setLoading } = this.props ;
        if( setLoading instanceof Function )
        {
            setLoading( false ) ;
        }

        if( response && this.mounted )
        {
            const { data } = response ;
            if( data )
            {
                try
                {
                    const status = RequestStatus.SUCCESS ;

                    let { object , result , total } = data ;

                    let { populate , variant } = this.props ;

                    const isInfinite = variant === 'infinite' ;

                    populate = populate || this.populate ;

                    let { things } = this.state ;

                    let count = things instanceof Array ? things.length : 0 ;

                    if( result instanceof Array && result.length > 0 )
                    {
                        count += result.length ;
                        result = populate( result , this.props , this.state ) ;
                    }
                    else
                    {
                        result = null ;
                    }

                    if( isInfinite && things instanceof Array )
                    {
                        things = [ ...things , ...result ] ;
                    }
                    else
                    {
                        things = result ;
                    }

                    const hasMore = count < total ;

                    this._refresh( things ) ;

                    this.setState( {
                        count ,
                        first:false,
                        hasMore ,
                        object ,
                        status ,
                        things ,
                        total
                    } , () =>
                    {
                        if( isInfinite && this.scroller )
                        {
                            const { element : { clientHeight, scrollHeight } = {} } = this.scroller ;
                            if( this.hasNext() && (clientHeight === scrollHeight) )
                            {
                                this.next() ;
                            }
                        }
                    }) ;

                    return ;
                }
                catch ( error )
                {
                    console.log( this + ' data succeed with an error' , error ) ;
                }
            }

            this.setState({ status:RequestStatus.FAIL });
        }
    };

    _refresh = things =>
    {
        const {
            selectPolicy,
            setSelect
        } = this.props ;

        if( setSelect instanceof Function )
        {
            const selectable = (selectPolicy !== 'none') && this.isEditable() ;
            setSelect({
                selectable ,
                selectableItems : things instanceof Array ? things : [] }
            );
        }
    };
}

DatasContainer.defaultProps =
{
    ...DialogContainer.defaultProps ,
    apiUrl             : api.url,
    activable          : true,
    activateUri        : null,
    addable            : false,
    addMode            : RequestStatus.RELOAD,
    autoload           : true,
    autoloadDelay      : 250,
    avatarSize         : 'default',
    avatarVariant      : 'rounded',
    back               : false ,
    backTooltip        : null ,
    clazz              : Thing,
    containerClassName : null ,
    defaultIcon        : null ,
    deletable          : true,
    deleteIcon         : <DeleteIcon />,
    deleteProps        : {},
    emptyClassName     : null ,
    emptyIcon          : <FindInPageIcon color='action' fontSize='large' />,
    emptyProps         : null,
    emptyVariant       : 'button',
    emptyTransition    : true,
    failClassName      : null ,
    failIcon           : <BugReportIcon color='action' fontSize='large'  />,
    failProps          : null,
    failVariant        : 'button',
    failTransition     : true,
    favoriteIcon       : <FavoriteIcon />,
    favoriteProps      : {},
    headerPolicy       : 'normal',
    icon               : null ,
    iconButton         : false ,
    iconClick          : null ,
    iconTooltip        : null ,
    limit              : 12,
    locale             : i18n.components.containers.datas,
    noFavoriteIcon     : <NoFavoriteIcon />,
    onBack             : null ,
    optionsVariant     : 'header',
    pagination         : true,
    paginationEdge     : 'both' ,
    paginationPolicy   : 'history' ,
    paginationSize     : 'small',
    paginationVariant  : 'text',
    parent             : null ,
    preferable         : false ,
    preloader          : null ,
    queries            : null ,
    reload             : false ,
    resizable          : false ,
    scrollable         : true ,
    searchPolicy       : 'normal',
    selectPolicy       : 'normal',
    search             : null ,
    showFirstButton    : true ,
    showLastButton     : true ,
    sortable           : false,
    variant            : 'pagination'
};

DatasContainer.propTypes =
{
    ...DialogContainer.propTypes ,
    addable            : PropTypes.bool,
    apiUrl             : PropTypes.string.isRequired,
    activable          : PropTypes.bool,
    activateUri        : PropTypes.string,
    avatarSize         : PropTypes.oneOf(['default','large','small']),
    avatarVariant      : PropTypes.oneOf(['circle','rounded','square']),
    addMode            : PropTypes.oneOf([RequestStatus.REDIRECT,RequestStatus.RELOAD]),
    autoload           : PropTypes.bool,
    autoloadDelay      : PropTypes.number.isRequired,
    back               : PropTypes.bool,
    backTooltip        : PropTypes.string ,
    classes            : PropTypes.object.isRequired,
    clazz              : PropTypes.func,
    containerClassName : PropTypes.string,
    defaultIcon        : PropTypes.element,
    deletable          : PropTypes.bool,
    deleteIcon         : PropTypes.element,
    deleteProps        : PropTypes.object,
    emptyClassName     : PropTypes.string,
    emptyIcon          : PropTypes.element,
    emptyProps         : PropTypes.object,
    emptyTransition    : PropTypes.bool,
    emptyVariant       : PropTypes.string,
    failIcon           : PropTypes.element,
    failProps          : PropTypes.object,
    failTransition     : PropTypes.bool,
    failVariant        : PropTypes.string,
    favoriteIcon       : PropTypes.element,
    favoriteProps      : PropTypes.object,
    getQueries         : PropTypes.func,
    headerPolicy       : PropTypes.oneOf(['none','normal']),
    icon               : PropTypes.element,
    iconButton         : PropTypes.bool ,
    iconClick          : PropTypes.func ,
    iconTooltip        : PropTypes.string ,
    limit              : PropTypes.number ,
    noFavoriteIcon     : PropTypes.element,
    onBack             : PropTypes.func,
    optionsVariant     : PropTypes.oneOf(['default', 'header', false]),
    pagination         : PropTypes.bool,
    paginationEdge     : PropTypes.oneOf(['both', 'end', 'start', false]),
    paginationPolicy   : PropTypes.oneOf(['history', 'normal']),
    paginationSize     : PropTypes.oneOf(['small','medium','large']),
    paginationVariant  : PropTypes.oneOf(['text', 'outlined']),
    parent             : PropTypes.object,
    preferable         : PropTypes.bool,
    populate           : PropTypes.func,
    preloader          : PropTypes.element,
    queries            : PropTypes.object,
    reload             : PropTypes.bool ,
    resizable          : PropTypes.bool ,
    scrollable         : PropTypes.bool ,
    search             : PropTypes.string,
    searchPolicy       : PropTypes.oneOf(['none','normal']),
    selectPolicy       : PropTypes.oneOf(['none','normal']),
    setSearch          : PropTypes.func,
    showFirstButton    : PropTypes.bool,
    showLastButton     : PropTypes.bool,
    sortable           : PropTypes.bool,
    variant            : PropTypes.oneOf(['infinite','pagination'])
};

export default DatasContainer ;
