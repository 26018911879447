/**
 * Indicates if a value is an HTMLElement object in the DOM.
 * @name isTouchDevice
 * @memberof core.dom
 * @function
 * @instance
 * @return The value <code>true</code> if the device accept touch events.
 */
const isTouchDevice = () =>
(
    !!( typeof window !== 'undefined'
        && ( 'ontouchstart' in window || ( window.DocumentTouch && typeof document !== 'undefined' && document instanceof window.DocumentTouch ) ) )
        || !!( typeof navigator !== 'undefined' && ( navigator.maxTouchPoints || navigator.msMaxTouchPoints) )
);

export default isTouchDevice ;