import React from 'react'

import clsx from 'clsx'

import notEmpty from 'vegas-js-core/src/strings/notEmpty'

import { GiEarthAfricaEurope } from 'react-icons/gi'

import ThingComponent from '../things/ThingComponent'

import withi18n  from '../../contexts/i18n/withi18n'
import withSnack from '../../contexts/snack/withSnack'

export class NationalityLabel extends ThingComponent
{
    getLocale = () => this.props.locale.display.labels.nationality ;
    
    render = () =>
    {
        const thing = this.getThing();
        if ( thing ) 
        {
            const { nationality:label } = thing;
            if ( notEmpty(label) )
            {
                const { className } = this.props;
                return (
                    <div className={clsx("flex flex-row items-center p-8", className)} >
                        <div className="flex-initial">{ this.getIcon({ label }) } </div>
                        <div className="flex flex-col pl-8">
                            {this.getTitle()}
                            {this.getLabel(label)}
                        </div>
                    </div>
                );
            }
        }
        return null;
    }
}
    
NationalityLabel.defaultProps =
{
    ...ThingComponent.defaultProps,
    icon : <GiEarthAfricaEurope style={{ fontSize:18 }} />
}

NationalityLabel.propTypes =
{
    ...ThingComponent.propTypes
}

export default withSnack( withi18n( NationalityLabel ) );