import React , { Fragment } from 'react'

import moment from 'moment/moment'

import PropTypes from 'prop-types'

import clean        from 'vegas-js-core/src/objects/clean'
import compose      from 'vegas-js-core/src/functors/compose'
import format       from 'vegas-js-core/src/strings/format'
import generateUUID from 'vegas-js-core/src/random/generateUUID'
import leading      from 'vegas-js-core/src/numbers/leading'
import isString     from 'vegas-js-core/src/isString'
import ucFirst      from 'vegas-js-core/src/strings/ucFirst'

import { Lines as Preloader } from 'react-preloading-component'

import withDialogs from '../../../../../../contexts/dialogs/withDialogs'
import withi18n    from '../../../../../../contexts/i18n/withi18n'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import blueGrey from '@material-ui/core/colors/blueGrey'
import red from '@material-ui/core/colors/red'

import { Button, Fab, IconButton, Tooltip, Typography } from '@material-ui/core'

import AddIcon        from '@material-ui/icons/AddBox'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import grey from '@material-ui/core/colors/grey'

import { FaClipboard     } from 'react-icons/fa'
import { FaCalendarCheck } from 'react-icons/fa'

import ThingCardChildren , { styles } from '../../../../ThingCardChildren'

import AbortionEventCell from './AbortionEvent'

import RequestStatus from '../../../../../../net/RequestStatus'

import Analyses from '../medical/Analyses'

import api   from '../../../../../../configs/api'
import GET   from '../../../../../../net/GET'
import PATCH from '../../../../../../net/PATCH'

import SnackVariant        from '../../../../../../components/snackbars/SnackVariant'
import withSnack           from '../../../../../../contexts/snack/withSnack'

import AbortionEventVO     from '../../../../../../things/livestock/events/AbortionEvent'
import Disease             from '../../../../../../things/medical/Disease'
import MedicalAnalysis          from '../../../../../../things/medical/MedicalAnalysis'
import AbortionSerieReview      from '../../../../../../things/livestock/reviews/AbortionSerieReview'
import MedicalMethod            from '../../../../../../things/medical/MedicalMethod'
import MedicalSampling          from '../../../../../../things/medical/MedicalSampling'
import Word                     from '../../../../../../things/Word'

import AddAbortionEventDialog from "../dialogs/AddAbortionEventDialog";

class AbortedEvents extends ThingCardChildren
{
    constructor( props )
    {
        super( props ) ;
        this.canceler = null ;
        this.timeout = null ;
        this.state = {
            ...this.state ,
            added    : [],
            status   : RequestStatus.NEW
        }
    }

    createChild = ( child , editable = false ) =>
    {
        if( child instanceof AbortionEventVO )
        {
            const { member } = this.props ;

            const { id } = child ;

            let isNew = false ;

            const { added } = this.state ;
            if( added instanceof Array )
            {
                isNew = added.indexOf( id ) > - 1;
            }

            let analyses ;

            if( isNew )
            {
                analyses = (
                    <div className='w-full min-h-80 flex items-center justify-center'>
                        <Preloader color={blueGrey[500]} size={16} />
                    </div>
                );
            }
            else
            {
                analyses = (
                <Analyses
                    className      = 'mt-20'
                    containerProps = {{ className : "bg-transparent w-full shadow-none py-8" }}
                    grid           = {{ xs:12 , sm:6 , lg:4 }}
                    gridSpacing    = { 8 }
                    memberOf       = { member }
                    onChange       = { this.change }
                    subjectOf      = { child }
                    thing          = { child }
                />
                );
            }

            return (
            <Fragment>
                <AbortionEventCell
                    editable = { editable }
                    thing    = { child }
                    onClick  = { child => this.openEditDialog(child) }
                />
                { analyses }
            </Fragment>);
        }
        return null ;
    };

    editPrepare = item => this.prepare( item ) ;

    getAddButton = ( disabled = false ) =>
    {
        if( this.isEditable() )
        {
            const {
                addIcon,
                addButtonProps
            }
            = this.props ;

            const locale = this.getLocale() ;

            let button = (
            <Button
                disabled = { disabled }
                onClick  = { () => this.openAddDialog() }
                color    = 'primary'
                variant  = 'contained'
                { ...addButtonProps }
            >
                { addIcon }
                { locale.labels.add }
            </Button>);

            if( !disabled )
            {
                const { tooltips } = locale ;
                if( tooltips )
                {
                    const { add } = tooltips ;
                    if( isString( add ) )
                    {
                        button = (
                        <Tooltip
                            title     = { add }
                            placement = 'top'
                        >
                            { button }
                        </Tooltip>);
                    }
                }
            }

            return button ;
        }
        return null ;
    };

    getBeforeContent = () =>
    {
        const locale = this.getLocale() ;
        if( locale )
        {
            const { labels } = locale ;
            if( labels )
            {
                const { caption } = labels ;
                if( isString(caption) && caption !== '' )
                {
                    return (
                    <Typography
                        className = 'w-full px-16 mb-16 italic'
                        style     = {{ color : grey[600] }}
                        variant   = 'caption'
                    >
                        { ucFirst(caption) }
                    </Typography>);
                }
            }
        }
    };

    getBottom = this.getCustomCardWithAddButton ;

    getCardLabel = ( child, editable, index ) =>
    {
        const locale = this.getLocale() ;
        if( locale )
        {
            const { labels } = locale ;
            if( labels )
            {
                let { card:label } = labels ;
                if( index >= 0 )
                {
                    label = leading(++index) + ' - ' + label ;
                }
                return label ;
            }
        }
        return null ;
    };

    getCardSubLabel = child =>
    {
        if( child instanceof AbortionEventVO )
        {
            let { about } = child ;
            if( about instanceof Word )
            {
                const { lang } = this.props ;
                about = about instanceof Word ? about.getLocaleName(lang) : null ;
                if( isString(about) )
                {
                    return (
                    <Typography
                        className = 'w-full font-medium'
                        variant   = 'body2'
                    >
                        { ucFirst(about) }
                    </Typography>);
                }
            }
        }
        return this.props.cardSubLabel ;
    };

    getCustomOptions = () => (
        <IconButton onClick={ () => this.collapse() } >
            { this.state.expanded ? <ExpandLessIcon/> : <ExpandMoreIcon/> }
        </IconButton>
    ) ;

    onAdd = item =>
    {
        const { onChange, thing:prevReview  } = this.props ;

        if( prevReview && item )
        {
            const { review } = item ;
            if( review )
            {
                let aborted1 ;
                let aborted2 ;

                const { member } = this.props ;

                if( prevReview.hasOwnProperty(member) )
                {
                    aborted1 = prevReview[member]
                }

                if( review.hasOwnProperty(member) )
                {
                    aborted2 = review[member]
                }

                let events ;

                if( aborted1 instanceof Array && aborted1.length > 0 )
                {
                    if( aborted2 instanceof Array && aborted2.length > 0 )
                    {
                        aborted1 = [ ...aborted1 ] ;
                        aborted2 = [ ...aborted2 ] ;
                        events = aborted2.filter( elmt => !aborted1.find( entry => elmt.id === entry.id ) ) ;
                    }
                }
                else if( aborted2 instanceof Array && aborted2.length > 0 )
                {
                    events = [ ...aborted2 ] ;
                }

                if( events instanceof Array && events.length > 0 )
                {
                    events = events.map( item => item.id ) ;
                    if( events.length > 0 )
                    {
                        let { added } = this.state ;

                        added = [ ...added , ...events ] ;

                        // console.log( this + ' onAdd initialize added' , added ) ;

                        this.setState( { added } )  ;

                        this.timeout = setTimeout( this._initialize , 250 , added ) ;
                    }
                }
            }
        }

        if( onChange )
        {
            onChange( item ) ;
        }
    };


    onEdit = this.change ;

    onCardClick = ( item ) => () => this.openEditDialog( item ) ;

    onRemove = this.change ;

    openAddDialog = () =>
    {
        const { addDialog } = this.props;
        if ( addDialog )
        {
            const { addUri:uri, member } = this.props ;
            addDialog(
                AddAbortionEventDialog ,
                {
                    member ,
                    uri,
                    item  : this.getEntry() ,
                    onAdd : this.onAdd
                }
            ) ;
        }
    };

    prepare = item =>
    {
        if( item instanceof AbortionEventVO )
        {
            let {
                id,
                identifier,
                date,
                period,
                subjectOf:review
            } = item ;

            if( review instanceof AbortionSerieReview )
            {
                const { member } = this.props ;

                review = review.toObject() ;

                let events ;

                if( review.hasOwnProperty(member) && review[member] instanceof Array )
                {
                    events = review[ member ];
                }
                else
                {
                    events = []
                }

                item.date       = date instanceof Date ? moment(date).toISOString() : null ;
                item.identifier = isString(identifier) ? identifier : null ;
                item.period     = period ;

                item = clean(item.toObject()) ;

                let position = -1 ;

                if( events.length > 0 )
                {
                    position = events.findIndex( item => item.id === id ) ;
                }

                if( position === -1 )
                {
                    events = [ ...events , item ] ;
                }
                else
                {
                    let event = events[position] ;
                    event.identifier = item.identifier;
                    event.date       = item.date ;
                    event.period     = item.period ;

                    events[position] = event ;
                }

                review[ member ] = events ;

                return { review:JSON.stringify(review) } ;
            }
        }
        return null ;
    };

    removePrepare = ( item ) =>
    {
        if( item instanceof AbortionEventVO )
        {
            let { subjectOf:review } = item ;
            if( review instanceof AbortionSerieReview )
            {
                review = review.toObject(false);

                let { id } = item ;

                const { member } = this.props ;

                let events ;
                if( review.hasOwnProperty(member) && review[member] instanceof Array )
                {
                    events = review[ member ];
                }
                else
                {
                    events = []
                }

                if( events instanceof Array && events.length > 0 )
                {
                    events = events.filter( item => item.id !== id ) ;
                }

                review[member] = events ;

                return { review : JSON.stringify(review) } ;
            }
        }
        return null ;
    };

    unmount = () =>
    {
        if( this.timeout )
        {
            clearTimeout( this.timeout ) ;
            this.timeout = null ;
        }

        const { status } = this.state ;
        if( status === RequestStatus.PROGRESS && !!(this.canceler) )
        {
            this.canceler.cancel(this + ' cancel') ;
        }
    };

    // -------------

    notifyFail = () =>
    {
        const locale = this.getLocale() ;
        if( locale )
        {
            const { snack } = locale ;
            if( snack )
            {
                const { fail } = snack ;
                if( fail )
                {
                    this.notify(fail, SnackVariant.WARNING);
                }
            }
        }
    };

    notify = ( message , variant=SnackVariant.DEFAULT ) =>
    {
        const { notifySnack } = this.props ;
        if( notifySnack )
        {
            notifySnack(message, variant);
        }
    };

    // notifySuccess = () =>
    // {
    //     const locale = this.getLocale() ;
    //     if( locale )
    //     {
    //         const { snack } = locale ;
    //         if( snack )
    //         {
    //             const { success } = snack ;
    //             if( success )
    //             {
    //                 this.notify(success, SnackVariant.SUCCESS);
    //             }
    //         }
    //     }
    // };

    // -------------

    _initialize = ( elements ) =>
    {
        if( this.timeout )
        {
            setTimeout(this.timeout) ;
            this.timeout = null ;
        }

        if( (elements instanceof Array) && (elements.length > 0) )
        {
            const { thing } = this.props ;
            if( thing )
            {
                const { subjectOf:observation } = thing ;
                if( observation )
                {
                    const { additionalType } = observation ;
                    if( additionalType )
                    {
                        const { id } = additionalType ;
                        if( id )
                        {
                            let uri = api.settings.diseasesMandatoryByObservationsTypes + '?skin=full' ; // fixme in props ?
                            this.setState({ status:RequestStatus.PROGRESS }) ;
                            this.canceler = GET(
                                api.url + format( uri , id ) ,
                                {
                                    cancel  : this._cancel,
                                    fail    : this._fail,
                                    queries : { active : false } ,
                                    random  : true ,
                                    success : this._success( elements )
                                }
                            );
                        }
                    }
                }
            }
        }
    };

    _cancel = () =>
    {
        // console.log( this + ' cancel : ' + message ) ;
        this.setState({ added:[] }) ;
        // FIXME a specific message + behavior
    };

    _fail = ( response ) =>
    {
        if( response )
        {
            console.log( this + ' fail' , response ) ;
            const { data } = response ;
            if( data )
            {
                const { message } = data ;
                switch( message )
                {
                    case 'token revoked' :
                    {
                        console.log( this + " failed, status:" + response.status + ", message:" + data.message );
                        this.setState({  status:RequestStatus.REVOKED });
                        break ;
                    }
                    default :
                    {
                        console.log( this + " failed, status:" + response.status + ", message:" + data.message );
                    }
                }
            }
        }

        if( this.mounted )
        {
            this.notifyFail() ;
            this.setState({ added:[] , status:RequestStatus.FAIL }) ;
        }
    };

    _success = elements => data =>
    {
        if( elements instanceof Array && elements.length > 0 )
        {
            let analyses = null ;

            if( data )
            {
                const { result } = data ;
                if( result instanceof Array && result.length > 0 )
                {
                    analyses = result.map( item =>
                    {
                        let disease  = new Disease(item) ;
                        let analysis = new MedicalAnalysis(
                        {
                            id : generateUUID() ,
                            disease
                        }) ;

                        let method ;
                        let sampling ;

                        let { analysisMethod, analysisSampling } = disease ;

                        if( analysisMethod instanceof Array && analysisMethod.length > 0 )
                        {
                            analysisMethod = analysisMethod.filter( item =>
                            {
                                if( item instanceof MedicalMethod )
                                {
                                    return !!(item.mandatory) && (item.additionalType instanceof Word) ;
                                }
                                return false ;
                            }) ;

                            if( analysisMethod instanceof Array )
                            {
                                method = analysisMethod.map( item => item.additionalType ) ;
                            }
                        }

                        if( analysisSampling instanceof Array && analysisSampling.length > 0 )
                        {
                            analysisSampling = analysisSampling.filter( item =>
                            {
                                if( item instanceof MedicalSampling )
                                {
                                    return !!(item.mandatory) && (item.additionalType instanceof Word) ;
                                }
                                return false ;
                            }) ;

                            if( analysisSampling instanceof Array )
                            {
                                sampling = analysisSampling.map( item => item.additionalType ) ;
                            }
                        }

                        analysis.method   = method ;
                        analysis.sampling = sampling ;

                        return analysis ;
                    });
                }
            }

            if( analyses instanceof Array && analyses.length > 0 )
            {
                let { member, thing:review } = this.props ;
                if( review instanceof AbortionSerieReview )
                {
                    const { subjectOf:observation  } = review ;

                    let events ;

                    if( review.hasOwnProperty(member) && review[member] instanceof Array )
                    {
                        events = review[member] ;
                    }

                    if( events instanceof Array && events.length > 0 )
                    {
                        let len = events.length ;
                        for( let i = 0 ; i<len ; i++ )
                        {
                            let event = events[i];
                            if( event )
                            {
                                const { id } = event ;
                                if( elements.indexOf(id) > -1 )
                                {
                                    event.analysis = analyses ;
                                }
                            }
                        }
                    }

                    review[member] = events ;

                    review = review.toObject() ;

                    if( observation )
                    {
                        const { id } = observation ;
                        const uri = api.url + api.observations.url + '/' + id ;
                        this.canceler = PATCH(
                            uri,
                            {
                                success : this._save( elements ),
                                fail    : this._fail,
                                cancel  : this._cancel,
                                datas   : { review:JSON.stringify(review) }
                            }
                        ) ;
                    }
                }
            }
        }
    };

    _save = elements => response =>
    {
        let { added } = this.state ;

        if( (elements instanceof Array) && (elements.length > 0) )
        {
            if( (added instanceof Array) && (added.length > 0) )
            {
                added = added.filter( id => elements.indexOf(id) === -1 ) ;
            }
        }

        this.setState({ added, status:RequestStatus.SUCCESS }) ;

        if( response )
        {
            const { data } = response ;
            if( data )
            {
                const { result:observation } = data ;
                if ( observation )
                {
                    const { onChange } = this.props ;
                    if( onChange instanceof Function )
                    {
                        onChange( observation ) ;
                    }
                }
            }
        }

    };
}

AbortedEvents.defaultProps =
{
    ...ThingCardChildren.defaultProps,
    addIcon               : <AddIcon className='mr-8' />,
    apiUrl                : api.url,
    cardIcon              : <FaCalendarCheck size='1.5em'/> ,
    clazz                 : AbortionEventVO,
    emptyIcon             : null,
    DeleteButtonComponent : Fab,
    DeleteButtonProps     :
    {
        size  : 'small',
        style :
        {
            backgroundColor : 'white',
            color           : red[600],
            '&:hover'       :
            {
                backgroundColor : red[900],
                color           : 'white'
            }
        }
    },
    grid        : { xs:12 },
    gridSpacing : 8,
    icon        : <FaClipboard size='1.5em' />,
    localePath  : 'things.livestock.reviews.abortionSerie.events.abortionEvents',
    optionMode  : 'custom' ,
    member      : 'aborted',
    path        : null,
    useUUID     : true
};

AbortedEvents.propTypes =
{
    ...ThingCardChildren.propTypes,
    path : PropTypes.string
};

export default compose(
    withDialogs,
    withStyles( styles ),
    withRouter,
    withi18n,
    withSnack
)(AbortedEvents);
