import React from 'react'

import format   from 'vegas-js-core/src/strings/fastformat'
import isNumber from 'vegas-js-core/src/isNumber'
import isString from 'vegas-js-core/src/isString'

import PropTypes from 'prop-types'

import clsx from 'clsx'

import filesize from 'filesize'

import FileIcon, { defaultStyles } from 'react-file-icon'

import { withStyles } from '@material-ui/core/styles'

import { Divider, Fab, IconButton, LinearProgress, Typography } from '@material-ui/core'

import AspectRatioIcon from '@material-ui/icons/AspectRatio'
import AbortedIcon     from '@material-ui/icons/CloudOff'
import CancelIcon      from '@material-ui/icons/Cancel'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import DoneIcon        from '@material-ui/icons/CloudDone'
import EditIcon        from '@material-ui/icons/Edit'

import grey from '@material-ui/core/colors/grey'

import isImageFile     from '../../media/isImageFile'
import resizeImageFile from '../../media/resizeImageFile'

import Method        from '../../net/Method'
import RequestStatus from '../../net/RequestStatus'
import UPLOAD        from '../../net/UPLOAD'
import UploadStatus  from '../../net/UploadStatus'

import withLocale from '../../contexts/i18n/withLocale'

import Container from '../../components/containers/Container'

import api                 from '../../configs/api'
import defaultImageOptions from '../../configs/imageOptions'
// import verbose             from '../../verbose'

const styles = () => (
{
    buttonUploading :
    {
        backgroundColor : 'white'
    },
    barColorPrimary : { backgroundColor:grey[200] } ,
    colorPrimary    : { backgroundColor:grey[300] } ,
    progress :
    {
        marginBottom : 8,
        marginTop    : 8,
        width        : '100%'
    },
    root :
    {
        alignItems    : 'justify-between',
        display       : 'flex',
        flexDirection : 'column',
        marginBottom  : 8,
        marginTop     : 8,
        width         : '100%'
    }
});

class FileUploader extends Container
{
    constructor( props )
    {
        super(props);
        this.canceler = null ;
        this.state =
        {
            ...this.state,
            file     : props.file ,
            height   : NaN,
            progress : 0 ,
            resized  : false,
            status   : UploadStatus.PENDING ,
            width    : NaN
        };
    }

    cancel = () =>
    {
        // console.log( this + ' cancel' );
        const { status } = this.state ;
        if ( status === UploadStatus.UPLOADING )
        {
            // cancel here
        }
        this._cancel() ;
    };

    componentDidUpdate( prevProps )
    {
        const { file , upload } = this.props ;

        if( prevProps.file !== file )
        {
            this.prepare(file) ;
        }

        if( prevProps.upload !== upload )
        {
            const { status } = this.state ;
            if( status === UploadStatus.UPLOADING )
            {
                if( upload === false )
                {
                    // TODO cancel
                }
            }
            else if( status === UploadStatus.READY && upload === true )
            {
                this.upload() ;
            }
        }
    }

    edit = () => { console.log( this + ' edit' ); };

    getLocale = () => this.props.locale.components.upload.file ;

    getIcon = () =>
    {
        const { file } = this.state ;
        if( file )
        {
            const { name } = file ;

            let extension ;
            let styles ;

            if( isString(name) )
            {
                extension = name.split('.').pop();
            }

            if( defaultStyles.hasOwnProperty(extension) )
            {
                styles = defaultStyles[extension];
            }

            return (
            <div className='mr-8'>
                <FileIcon
                    size      = { 46 }
                    extension = { extension }
                    { ...styles }
                />
            </div>
            );
        }
        return null ;
    };

    init = () =>
    {
        const { file } = this.props ;
        if( file )
        {
            this.prepare(file) ;
        }

        const { upload } = this.props ;
        if( upload === true )
        {
            this.upload() ;
        }
    }

    getProgress = () =>
    {
        const { classes } = this.props ;
        const { progress, status } = this.state ;
        if( status === UploadStatus.UPLOADING )
        {
            return (
                <LinearProgress
                    className = { classes.progress }
                    value     = { progress }
                    variant   = { ( progress >= 0 ) && ( progress < 100 ) ? 'determinate' : 'indeterminate' }
                />
            );
        }
        else if( status === UploadStatus.RESIZING )
        {
            return (
                <LinearProgress
                    className = { classes.progress }
                    color     = 'secondary'
                    variant   = 'query'
                />
            );
        }
        else
        {
            return (
                <LinearProgress
                    className   = { classes.progress }
                    classes     = {{ barColorPrimary : classes.barColorPrimary , colorPrimary : classes.colorPrimary }}
                    value       = { 0 }
                    variant     = 'determinate'
                />
            );
        }
    };

    getOption = () =>
    {
        let options ;

        let { abortedIcon, cancelIcon, classes } = this.props ;

        if( !abortedIcon )
        {
            abortedIcon = <AbortedIcon color="error" fontSize="small" />;
        }

        if( !cancelIcon )
        {
            cancelIcon = <CancelIcon color="action" fontSize="small" />;
        }

        let { status } = this.state ;

        switch( status )
        {
            case UploadStatus.ABORTED :
            {
                options = (
                <IconButton
                    className = 'ml-4'
                    disabled  = { true }
                >
                    { abortedIcon }
                </IconButton>) ;
                break ;
            }

            case UploadStatus.FAILED :
            {

                options = (
                <IconButton
                    className = 'ml-4'
                    disabled  = { true }
                >
                    { abortedIcon }
                </IconButton>) ;
                break ;
            }

            case UploadStatus.PENDING :
            {
                const {
                    cancelable,
                    editable,
                    startable
                } = this.props ;

                let cancel ;
                if( cancelable )
                {

                    cancel = (
                    <IconButton
                        className = 'ml-4'
                        onClick   = { this.cancel }
                    >
                        { cancelIcon }
                    </IconButton>
                    );
                }

                let edit ;
                if( editable )
                {
                    let { editIcon } = this.props ;
                    if( !editIcon )
                    {
                        editIcon = <EditIcon fontSize="small"/> ;
                    }
                    edit = (
                    <IconButton
                        className = 'ml-4'
                        onClick   = { this.edit }
                    >
                        { editIcon }
                    </IconButton>
                    );
                }

                let start ;
                if( startable )
                {
                    let { uploadIcon } = this.props ;
                    if( !uploadIcon )
                    {
                        uploadIcon = <CloudUploadIcon color="action" fontSize="small" />;
                    }
                    start = (
                    <IconButton
                        className = 'ml-4'
                        onClick   = { this.upload }
                    >
                        { uploadIcon }
                    </IconButton>
                    );
                }

                options = (
                <div className="flex flex-row-reverse items-center">
                    { cancel }
                    { edit }
                    { start }
                </div>
                );
                break ;
            }

            case UploadStatus.RESIZING :
            {
                break ;
            }

            case UploadStatus.UPLOADING :
            {
                options = (
                <div className="flex items-center">
                    <Fab
                        className = { classes.buttonUploading }
                        onClick   = { this.cancel }
                        size      = 'small'
                    >
                        { cancelIcon }
                    </Fab>
                </div>
                );
                break ;
            }

            case UploadStatus.UPLOADED :
            {
                let { doneIcon } = this.props ;
                if( !doneIcon )
                {
                    doneIcon = <DoneIcon color="primary" fontSize="small" />;
                }

                options = (
                <IconButton
                    className = 'ml-4'
                    disabled  = { true }
                >
                    { doneIcon }
                </IconButton>) ;
                break ;
            }

            default :
            {
                //
            }
        }

        return options ;
    };

    getText = () =>
    {
        const locale = this.getLocale() ;

        let { name:primary } = this.props

        let secondary ;

        let { file } = this.state ;
        if( file )
        {
            let { name, size, type } = file ;

            if( isString(name) && !isString(primary))
            {
                primary = name ;
            }

            secondary = [] ;

            if( isNumber(size) )
            {
                secondary.push( filesize(size) );
            }

            if( isString( type ) )
            {
                secondary.push( type ) ;
            }

            if( secondary.length > 0 )
            {
                secondary = (
                <Typography variant='subtitle2'>
                    { secondary.join( locale.separator || ' ' ) }
                </Typography>);
            }
            else
            {
                secondary = null ;
            }
        }

        if( !isString(primary) )
        {
            primary = locale.unknown;
        }

        if( primary )
        {
            primary = (
                <Typography className='w-full' variant='subtitle1'>
                    { primary }
                </Typography>
            );
        }

        let infos ;

        const { resized } = this.state ;
        if( resized )
        {
            const { width , height } = this.state ;
            if( width >= 0 && height >= 0 )
            {
                infos = (
                    <Typography color='textSecondary' className='mr-8' variant='caption'>
                        { format( locale.dimension, width, height ) }
                    </Typography>
                ) ;
            }
            infos = (
                <div className='flex flex-row items-center w-full'>
                    <div className='flex flex-row items-center'>
                        <AspectRatioIcon color='disabled' className='mr-8' fontSize="small" />
                        { infos }
                    </div>
                </div>
            )
        }

        return (
            <div className = 'px-8 w-full' >
                { primary }
                { this.getProgress() }
                { secondary }
                { infos }
            </div>
        );
    };

    render()
    {
        let {
            classes,
            className,
            divider,
            style
        }
        = this.props ;

        return (
        <div className={ clsx(classes.root, className) } style={ style }>
            <div className="flex flex-row items-center justify-between w-full px-8 py-12">

                <div className='flex flex-row items-center w-full'>
                    { this.getIcon() }
                    { this.getText() }
                </div>

                <div className="flex flex-row-reverse items-center">
                    { this.getOption() }
                </div>
            </div>
            { divider && <Divider light className="w-full"/> }
        </div>
        ) ;
    }

    prepare = file =>
    {
        if ( isImageFile(file) )
        {
            let { imageOptions : { resize , ...options } = {} } = this.props;
            if ( resize )
            {
                options = { ...defaultImageOptions, options };

                const { maxHeight , maxWidth } = options ;

                this.setState( { resized:false , status:UploadStatus.RESIZING } ) ;

                const { onResize } = this.props ;
                if( onResize instanceof Function )
                {
                    onResize( file , this.props.id , this.props ) ;
                }

                const helper = resizeImageFile(file, {maxHeight, maxWidth});

                helper
                .then( ( { file , resized , ...infos} ) =>
                {
                    if ( resized )
                    {
                        const { width, height , originalWidth, originalHeight } = infos || {};
                        // if ( verbose )
                        // {
                        //     console.log(this + ` resizeImageFile succeed from ${originalWidth}x${originalHeight} to ${width}x${height}`, file);
                        // }
                        this.ready( file , { resized , width , height , originalWidth, originalHeight } );
                    }
                    else
                    {
                        this.ready( file , { resized } );
                    }
                })
                .catch( error =>
                {
                    console.log(this + ' resizeImageFile failed, ', error);
                    this.ready(file) ;
                });
                return ;
            }
        }

        this.ready(file);
    }

    ready = ( file , options = null ) =>
    {
        if( this._mounted )
        {
            this.setState( { file , status:UploadStatus.READY , ...options } );
        }

        const { id, onReady } = this.props ;
        if( onReady )
        {
            onReady( file , id , this.props ) ;
        }
    }

    unmount = () =>
    {
        const { status } = this.state ;
        if( status === RequestStatus.PROGRESS && !!(this.canceler) )
        {
            this.canceler.cancel(this + ' cancel') ;
        }
    }

    upload = () =>
    {
        const { status } = this.state ;
        if( status === UploadStatus.READY )
        {
            const { file } = this.state ;
            if( file )
            {
                let {
                    filePropName,
                    method,
                    mock,
                    name,
                    uri
                } = this.props ;

                if( !isString(name) )
                {
                    name = file.name ;
                }

                if( mock )
                {
                    console.log(
                        this + " upload" ,
                        'method:' + method ,
                        'uri:'    + api.url + uri ,
                        'name:'   + name ,
                        'size:'   + file.size,
                        'prop:'   + filePropName
                    ) ;
                }
                else
                {
                    this.setState( { progress:0 , status:UploadStatus.UPLOADING } );
                    this.canceler = UPLOAD(api.url + uri , file ,
                    {
                        filePropName ,
                        method,
                        datas    : { name },
                        cancel   : this._cancel,
                        fail     : this._fail,
                        progress : this._progress,
                        success  : this._success
                    });
                }
            }
        }
    };

    _cancel = () =>
    {
        this.setState({ status:UploadStatus.ABORTED }) ;
        const { onCancel } = this.props ;
        if( onCancel instanceof Function )
        {
            onCancel(this.props) ;
        }
    };

    _fail = response =>
    {
        const { onFail } = this.props ;
        if( response )
        {
            const { data } = response ;
            if( data )
            {
                console.log( this + ' fail' , data ) ;

                const { message, status } = data ;

                if( status === 'error' )
                {
                    this.setState({ errors:message , status:UploadStatus.FAILED }) ;
                    if( onFail instanceof Function )
                    {
                        onFail( response, this.props ) ;
                    }
                    return ;
                }

                switch( message )
                {
                    case 'token revoked' :
                    {
                        console.log( this + " failed with a token revoked, status:" + status + ", message:" + message );
                        break ;
                    }
                    default :
                    {
                        console.log( this + " failed, status:" + status + ", message:" + message );
                    }
                }
            }
        }

        if( onFail instanceof Function )
        {
            onFail( response, this.props ) ;
        }
    };

    _progress = ( loaded, total ) =>
    {
        let progress = Math.round( loaded * 100 / total );
        this.setState({ progress });
        const { onProgress } = this.props ;
        if( onProgress instanceof Function )
        {
            onProgress( loaded, total, this.props);
        }
    };

    _success = response =>
    {
        if( response )
        {
            const { data } = response ;
            if( data )
            {
                this.setState( { status:UploadStatus.UPLOADED } );
                const { result } = data ;
                if( result )
                {
                    const { onUpload } = this.props ;
                    if( onUpload instanceof Function )
                    {
                        onUpload( result, this.props ) ;
                    }
                }
            }
        }
    }
}

FileUploader.defaultProps =
{
    ...Container.defaultProps,
    abortedIcon  : <AbortedIcon color="action" fontSize="small" />,
    cancelable   : true,
    cancelIcon   : null,
    disabled     : false ,
    divider      : true ,
    doneIcon     : null,
    editable     : false,
    editIcon     : null,
    failIcon     : null,
    file         : null ,
    filePropName : 'file',
    id           : null,
    imageOptions : defaultImageOptions,
    locale       :
    {
        separator : ' - ',
        unknown   : 'Inconnu'
    },
    method        : Method.POST,
    mock          : false,
    name          : null,
    onCancel      : null,
    onFail        : null,
    onProgress    : null,
    onReady       : null ,
    onResize      : null,
    onUpload      : null,
    startable     : false,
    upload        : false,
    uploadIcon    : null,
    uri           : null
};

FileUploader.propTypes =
{
    ...Container.propTypes,
    cancelable    : PropTypes.bool,
    cancelIcon    : PropTypes.element,
    disabled      : PropTypes.bool,
    divider       : PropTypes.bool,
    doneIcon      : PropTypes.element,
    editable      : PropTypes.bool,
    failIcon      : PropTypes.element,
    file          : PropTypes.object,
    filePropName  : PropTypes.string,
    id            : PropTypes.string,
    imageOptions  : PropTypes.object,
    method        : PropTypes.oneOf([Method.POST,Method.PATCH,Method.PUT]),
    mock          : PropTypes.bool,
    name          : PropTypes.string,
    onCancel      : PropTypes.func,
    onFail        : PropTypes.func,
    onProgress    : PropTypes.func,
    onReady       : PropTypes.func,
    onResize      : PropTypes.func,
    onUpload      : PropTypes.func,
    startable     : PropTypes.bool,
    upload        : PropTypes.bool,
    uploadIcon    : PropTypes.element,
    uri           : PropTypes.string
};

export default withStyles(styles)( withLocale( FileUploader ) ) ;
