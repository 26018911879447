import initDialog from '../initDialog'

import { AddDialog } from '../AddDialog'

import Thing from '../../../things/Thing'

import api from '../../../configs/api'

const styles = () => ({});

export class AddTechnicianDialog extends AddDialog
{
    getLocale = () => this.props.locale.dialogs.application.technician.add ;

    prepare = item =>
    {
        const {
            person
        } = item ;

        return {
            person : person instanceof Thing ? String(person.id) : null ,
        };
    };
}

AddTechnicianDialog.defaultProps =
{
    ...AddDialog.defaultProps,
    uri : api.technicians.url
};

AddTechnicianDialog.propTypes =
{
    ...AddDialog.propTypes
};

export default initDialog({ styles })( AddTechnicianDialog ) ;
