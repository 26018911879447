import Form from '../../../../display/forms/FormElements'

import Livestock from '../../../../things/Livestock'
import Thing     from '../../../../things/Thing'

import api from '../../../../configs/api'

import Organization  from "../../../../things/Organization"

import getExistingSuggestions from '../../../../things/getExistingSuggestions'
import getLocaleThingLabel    from '../../../../things/getLocaleThingLabel'

const primary = ( unknown = 'Inconnu' ) => ( element , props ) =>
{
    if( element instanceof Livestock )
    {
        const { lang } = props ;
        const { organization } = element ;
        if( organization instanceof Thing )
        {
            return getLocaleThingLabel(organization, lang);
        }
    }
    return unknown;
};

const getItem = ( unknown = 'Inconnu' ) => ({ text : { primary : primary(unknown) } }) ;

const elements =
[
    {
        type  : Form.SEARCH_SELECTOR ,
        path  : api.organizations.url ,
        clazz : Organization ,
        disabledSuggestions : getExistingSuggestions({ clazz:Organization }) ,
        init  :
        {
            id         : 'organization',
            key        : 'organization',
            label      : 'Organisation',
            autoFocus  : false,
            fullWidth  : true,
            limit      : 0,
            helperText : 'Obligatoire',
            margin     : 'normal',
            required   : true,
            type       : 'search',
            variant    : 'outlined'
        },
        selector : { i18n :
        {
            empty       : 'Aucune organisation disponible.' ,
            title       : 'Sélectionner une organisation',
            description : 'Veuillez sélectionner une organisation ci-dessous.' ,
        }}
    }
];

const livestock =
{
    add :
    {
        elements,
        agree       : 'Ajouter' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez créer un nouvel élévage à l\'aide en sélectionnant une organisation ci-dessous.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en  cours...' ,
        success     : 'Ajout du nouvel élevage réussi!' ,
        title       : 'Ajouter un élevage',
    },
    remove :
    {
        agree       : 'Supprimer' ,
        description : 'Souhaitez-vous supprimer cet élevage:' ,
        disagree    : 'Annuler' ,
        item        : getItem('Inconnu'),
        loading     : 'Suppression en cours...' ,
        title       : 'Supprimer l\'élevage ?'
    }
};

export default livestock ;
