import dark from '../styles/colors/dark'

import lightBlue  from '@material-ui/core/colors/lightBlue'
import lightGreen from '@material-ui/core/colors/lightGreen'
import red        from '@material-ui/core/colors/red'

export const defaults =
{
    default :
    {
        palette:
        {
            type    : 'light',
            error   : red ,
            primary :
            {
                light        : dark[400],
                main         : dark[600],
                dark         : dark[700],
                contrastText : '#fff'
            },
            secondary :
            {
                light        : lightBlue[400],
                main         : lightBlue[600],
                dark         : lightBlue[700],
                contrastText : '#fff'
            }
        },
        status : { danger : 'orange' }
    },
    defaultDark:
    {
        palette:
        {
            type       : 'dark',
            background :
            {
                paper   : '#fff',
                default : '#fafafa'
            },
            error   : red ,
            primary :
            {
                light        : dark[400],
                main         : dark[600],
                dark         : dark[700],
                contrastText : '#fff'
            },
            secondary:
            {
                light: lightBlue[400],
                main : lightBlue[600],
                dark : lightBlue[700],
                contrastText : '#fff'
            },
            text :
            {
                primary   : "rgba(255, 255, 255, 0.87)",
                secondary : "rgba(255, 255, 255, 0.54)",
                disabled  : "rgba(255, 255, 255, 0.38)",
                hint      : "rgba(255, 255, 255, 0.38)"
            }
        },
        status : { danger : 'orange' }
    }
};

const themes =
{
    ...defaults,

    sunset :
    {
        palette:
        {
            type     : 'light',
            primary  :
            {
                light : '#c76a1d',
                main  : '#ff994c',
                dark  : '#ffca7b',
                contrastText : '#fff'
            },
            secondary:
            {
                light        : '#ff908b',
                main         : '#d0605e',
                dark         : '#9b3134',
                contrastText : '#fff'
            },
            error : red
        },
        status :
        {
            danger: 'orange'
        }
    },
    greeny :
    {
        palette:
        {
            type     : 'light',
            primary  :
            {
                light: '#6cabd4',
                main : '#387ca3',
                dark : '#005074'
            },
            secondary:
            {
                light       : '#ABC13D',
                main        : '#819b24',
                dark        : '#617B3F',
                contrastText: '#fff'
            },
            error : red
        },
        status :
        {
            danger : 'orange'
        }
    },
    beach :
    {
        palette:
        {
            type     : 'light',
            primary  :
            {
                light        : '#c4d8dd',
                main         : '#93a7ab',
                dark         : '#65787c',
                contrastText : '#fff'
            },
            secondary:
            {
                light        : '#ffb281',
                main         : '#f18153',
                dark         : '#ba5228',
                contrastText : '#fff'
            }
        }
    },
    tech :
    {
        palette:
        {
            type    : 'light',
            primary :
            {
                light       : lightBlue[400],
                main        : lightBlue[500],
                dark        : lightBlue[700],
                contrastText: '#fff'
            },
            secondary:
            {
                light: lightGreen[300],
                main : lightGreen[500],
                dark : lightGreen[700],
                contrastText : '#fff'
            }
        }
    },
    sweetHues  :
    {
        palette:
        {
            type     : 'light',
            primary  :
            {
                light        : '#d5c1eb',
                main         : '#a391b9',
                dark         : '#746389',
                contrastText : '#fff'
            },
            secondary:
            {
                light        : '#90afd4',
                main         : '#6080a3',
                dark         : '#325474',
                contrastText : '#fff'
            }
        }
    },
    deepOcean  :
    {
        palette :
        {
            type     : 'dark',
            primary  :
            {
                light : '#8f53e7',
                main  : '#5a24b4',
                dark  : '#1e0083',
                contrastText : '#fff'
            },
            secondary:
            {
                light       : '#ff61ff',
                main        : '#fe00e9',
                dark        : '#c600b6',
                contrastText: '#fff'
            }
        }
    },
    slate :
    {
        palette:
        {
            type     : 'dark',
            primary  :
            {
                light: '#86fff7',
                main : '#4ecdc4',
                dark : '#009b94',
                contrastText : '#fff'
            },
            secondary:
            {
                light       : '#ff9d99',
                main        : '#ff6b6b',
                dark        : '#c73840',
                contrastText: '#fff'
            }
        }
    }
};

export default themes ;
