import React from "react"

import ucFirst from 'vegas-js-core/src/strings/ucFirst'

import TeamIcon from '@material-ui/icons/Group'

import ThingView , { defaultStyle } from '../../display/views/ThingView'

import About from './views/About'

import api   from '../../configs/api'
import paths from '../../configs/paths'

import TeamVO from '../../vo/Team'

import initView from '../../display/views/initView'

const styles = defaultStyle();

class Team extends ThingView
{
    getLocale = () => this.props.locale.views.team ;

    getSubtitle = thing =>
    {
        if( thing )
        {
            const { lang } = this.props ;

            let {
                alternateName,
                name
            } = thing ;

            if( name && alternateName )
            {
                name = thing.getLocaleName(lang) ;
                if( name && name !== '' )
                {
                    return ucFirst(name)  ;
                }
            }
        }
        return null ;
    };

    getTitle = thing =>
    {
        if( thing instanceof TeamVO )
        {
            const { lang } = this.props ;

            let { alternateName } = thing ;

            if( alternateName )
            {
                alternateName = thing.getLocaleAlternateName( lang ) ;
                return ucFirst(alternateName)  ;
            }

            return ucFirst(thing.getLocaleName(lang))  ;
        }
        return null ;
    };
}

Team.defaultProps =
{
    ...ThingView.defaultProps,
    back       : true,
    clazz      : TeamVO,
    icon       : <TeamIcon/>,
    path       : api.teams,
    responsive : true ,
    routes     :
    [
        { id : 'about'  , RouteComponent : About }
    ],
    tabs :
    [
        { id : 'about'  , icon : <TeamIcon className='pr-8' /> } ,
    ],
    titleAuto : false // Important to use always the getTitle() method
};

Team.propTypes =
{
    ...ThingView.propTypes,
    path : paths.teams
};

export default initView( Team, styles );
