import React from 'react'

import ucFirst from 'vegas-js-core/src/strings/ucFirst'

import {
    Avatar,
    Card,
    CardHeader,
    Collapse,
    Divider,
    Grid,
    IconButton,
    Typography
} from '@material-ui/core'

import color from '@material-ui/core/colors/brown'

import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import SettingsIcon   from '@material-ui/icons/SettingsApplications'

import DatasContainer from '../containers/DatasContainer'

import RequestStatus  from '../../net/RequestStatus'

import withDatasGrid from './withDatasGrid'

const styles = () => (
{
    avatar :
    {
        backgroundColor: color[200]
    },
    container :
    {
        flex      : 'auto',
        width     : '100%',
        height    : '100%',
        bottom    : 0,
        left      : 0,
        overflowY : 'scroll',
        padding   : 12,
        position  : 'absolute',
        right     : 0,
        top       : 0,
    },
    label :
    {
        color : color[900]
    },
    root :
    {
        flex     : 'auto',
        overflow : 'hidden',
        position : 'relative'
    }
});

const columns =
{
    xl : 3,
    lg : 4,
    sm : 6,
    xs : 12
};

class SettingsGrid extends DatasContainer
{
    constructor( props )
    {
        super( props ) ;
        this.state = { cards : {} , collapse : {}  } ;
    }

    getContent = ( loading = false ) =>
    {
        const {
            classes ,
            history ,
            lang ,
            location
        }
        = this.props ;

        const {
            collapse ,
            things ,
            status
        } = this.state;

        if( loading )
        {
            return this.getProgress() ;
        }
        else if( status === RequestStatus.NEW )
        {
            return ;
        }
        else if ( (things instanceof Array) && things.length > 0 )
        {
            const map = {} ;

            let categories = [] ;

            things.forEach( element =>
            {
               const { category } = element ;
               if( category )
               {
                   if( categories.indexOf( category ) < 0 )
                   {
                       categories.push( category );
                       map[category] = [] ;
                   }

                   let collection = map[category] ;

                   collection.push( element ) ;
               }
            });

            // console.log( this + ' getContent' , categories ) ;
            if( categories.length > 0 )
            {
                categories = categories.map( category =>
                (
                    <div key={ 'settings-item-' + category }>
                        <div className="flex flex-row items-center justify-between py-10">
                            <Typography className="mb-16 mt-8" variant="h6">{ucFirst(category)}</Typography>
                            <IconButton onClick={ () =>
                            {
                                if( collapse )
                                {
                                    if( collapse.hasOwnProperty(category) )
                                    {
                                        collapse[category] = !(collapse[category]) ;
                                    }
                                    else
                                    {
                                        collapse[category] = true ;
                                    }
                                }
                                this.setState({ collapse }) ;
                            }}>
                                { this.isCollapsed(category) ? <ExpandMoreIcon/> : <ExpandLessIcon/> }
                            </IconButton>
                        </div>

                        <Collapse className="py-10" in={ !this.isCollapsed(category) } >
                            <Grid container spacing={4} className="py-10" >
                            {
                                map[category].map( item => {

                                    let name = item.name[lang] ;
                                    let current = this.getCurrentPath() ;

                                    let shadow = this.state.cards.hasOwnProperty(item.id) ;

                                    let path = item.url ;
                                    path = path.split( current )[1] ;

                                    let to = { pathname : current + path , state : { thing : item , referrer: location } } ;

                                    return(
                                    <Grid key={ 'item-' + category + '-' + item.id } item {...columns}>
                                        <Card
                                            className   = "cursor-pointer"
                                            onClick     = { () => history.push( to ) }
                                            onMouseOver = { this._mouseOver(item.id) }
                                            onMouseOut  = { this._mouseOut(item.id)  }
                                            elevation   = { shadow ? 5 : 2 }
                                        >
                                            <CardHeader
                                                className = "flex flex-row items-center hover:shadow"
                                                avatar    = { <Avatar variant="rounded" className={classes.avatar}>{ name ? name.charAt(0).toUpperCase() : null }</Avatar> }
                                                title     = { <Typography variant='subtitle1' className={classes.label}>{ucFirst(name)}</Typography> }
                                                subheader = { <Typography variant="subtitle2">{ucFirst(category)}</Typography> }
                                            />
                                        </Card>
                                    </Grid>);
                                })
                            }
                            </Grid>
                        </Collapse>
                        <Divider className="mt-20 mb-12"/>
                    </div>
                ));

                return (
                    <div className='flex-1'>
                        { categories }
                    </div>
                );
            }
        }
        return this.getEmpty() ;
    };

    getIcon = () => <SettingsIcon style={{ color : color[700] }} className="w-auto h-32 mr-0"/> ;

    getLocale = () => this.props.locale.components.grids.settings ;

    isCollapsed = category =>
    {
        const { collapse } = this.state ;
        if( collapse && collapse.hasOwnProperty(category) )
        {
            return collapse[category] === true ;
        }
        return false ;
    };

    _mouseOut = id => () =>
    {
        let cards = this.state.cards ;
        if( cards.hasOwnProperty(id))
        {
            delete cards[id] ;
        }
        this.setState( { cards } ) ;
    };

    _mouseOver = id => () =>
    {
        let cards = this.state.cards ;
        cards[id] = true ;
        this.setState( { cards } ) ;
    };
}

SettingsGrid.defaultProps =
{
    ...DatasContainer.defaultProps ,
    pagination : false ,
    queries    : { limit : 1000 , sort : 'category,name' }
};

SettingsGrid.propTypes =
{
    ...DatasContainer.propTypes
};

export default withDatasGrid({ styles })( SettingsGrid ) ;
