const list =
{
    empty      : 'Aucun enregistrement trouvé',
    failed     : 'Problème de connexion',
    labels     : { unknown : 'Inconnu' },
    loading    : 'Chargement ...' ,
    noDataText : 'Aucun résultat' ,
    remove     :
    {
        agree        : 'Supprimer' ,
        description  : 'Souhaitez-vous supprimer l\'enregistrement:' ,
        descriptionX : 'Souhaitez-vous supprimer les {0} enregistrements:' ,
        disagree     : 'Annuler' ,
        loading      : 'Suppression en cours...' ,
        title        : 'Supprimer cet enregistrement ?',
        titleX       : 'Supprimer des enregistrements ?'
    },
    removeItem  : 'Supprimer un enregistrement' ,
    removeItemX : 'Supprimer {0} enregistrements' ,
    tooltips    :
    {
        add         : 'Ajouter' ,
        activate    : 'Activer' ,
        deactivate  : 'Désactiver' ,
        favorite    : 'Ajouter aux favoris' ,
        noFavorite  : 'Supprimer des favoris' ,
        remove      : 'Supprimer',
        sort        : 'Trier',
        removeItem  : "Supprimer un enregistrement" ,
        removeItemX : "Supprimer {0} enregistrements"
    },
    search : 'Rechercher ...' ,
    snacks :
    {
        add    : 'Une ressource est ajoutée dans vos favoris',
        remove : 'Une ressource est supprimée des favoris',
    },
    sorts  : 
    [
        { label : 'Date de modification' , value : 'modified' }  ,
        { label : 'Nom'                  , value : 'name'     }  ,
        { label : 'Date de création'     , value : 'created'  }  
    ],
    title : null
};

export default list ;
