import compose  from 'vegas-js-core/src/functors/compose'

import initApp from '../../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { styles } from '../ThingChildren'

import MedicalAuthorities from '../MedicalAuthorities'

import Technician    from '../../../things/Technician'
import Veterinarian  from '../../../things/Veterinarian'
import Thing         from '../../../things/Thing'
import PropertyValue from '../../../things/PropertyValue'

class Authority extends MedicalAuthorities
{
    addMapUri = ( item , uri ) =>
    {
        if( item instanceof PropertyValue )
        {
            const { value } = item ;
            if( value instanceof Thing )
            {
                const { id } = value ;
                uri += '/' + id ;
            }
        }
        return uri ;
    }

    addPrepare = item =>
    {
        let type = null ;
        if( item )
        {
            const { value } = item ;
            if( value instanceof Veterinarian )
            {
                type = 'veterinarian' ;
            }
            else if ( value instanceof Technician )
            {
                type = 'technician' ;
            }
        }
        return { type };
    };
    
    getLocale = () => this.props.locale.things.observation.authority ;
    
    removePrepare = item =>
    {
        let type = null ;
        if( item instanceof Veterinarian )
        {
            type = 'veterinarian' ;
        }
        else if ( item instanceof Technician )
        {
            type = 'technician' ;
        }
        return { type } ;
    };
}

Authority.defaultProps =
{
    ...MedicalAuthorities.defaultProps
};

Authority.propTypes =
{
    ...MedicalAuthorities.propTypes
};

export default compose( withStyles( styles ) , withRouter , initApp )( Authority ) ;