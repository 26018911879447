import Form from '../../../display/forms/FormElements'

import Thing from '../../../things/Thing'
import Word  from '../../../things/Word'

import api from '../../../configs/api'

import elevation       from './course/elevation'
import steps           from './course/steps'
import transportations from './course/transportations'

const elements =
[
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'name',
            key          : 'name',
            label        : 'Nom',
            autoFocus    : false,
            fullWidth    : true,
            helperText   : null,
            margin       : 'normal',
            required     : true,
            select       : false,
            autoComplete : 'off' ,
            type         : 'text',
            variant      : 'outlined'
        }
    },
    {
        type       : Form.SEARCH_SELECTOR ,
        path       : api.thesaurus.courses_types ,
        clearLabel : 'Vider' ,
        closeLabel : 'Fermer' ,
        init       :
        {
            id        : 'additionalType',
            key       : 'additionalType',
            label     : 'Type de parcours',
            autoFocus : false,
            fullWidth : true,
            margin    : 'normal',
            required  : true,
            type      : 'search',
            variant   : 'outlined'
        },
        inputProps :
        {
            label : 'Type de parcours...'
        },
        limit  : 0,
        logics :
        {
            disabledSuggestions : ( props ) =>
            {
                const { rel } = props ;
                let suggestions = [] ;
                if( rel instanceof Thing )
                {
                    const { additionalType } = rel ;
                    if( additionalType instanceof Word )
                    {
                        suggestions.push( additionalType ) ;
                    }
                }
                return suggestions ;
            }
        }
    },
    {
        type       : Form.SEARCH_SELECTOR ,
        path       : api.thesaurus.courses_levels,
        clearLabel : 'Vider' ,
        closeLabel : 'Fermer' ,
        init       :
        {
            id         : 'level',
            key        : 'level',
            label      : 'Niveau du parcours',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            type       : 'search',
            variant    : 'outlined'
        },
        inputProps :
        {
            label : 'Niveau...'
        },
        limit  : 0,
        logics :
        {
            disabledSuggestions : ( props ) =>
            {
                const { rel } = props ;
                let suggestions = [] ;
                if( rel instanceof Thing )
                {
                    const { additionalType } = rel ;
                    if( additionalType instanceof Word )
                    {
                        suggestions.push( additionalType ) ;
                    }
                }
                return suggestions ;
            }
        }
    },
    {
        type       : Form.SEARCH_SELECTOR ,
        path       : api.thesaurus.courses_paths,
        clearLabel : 'Vider' ,
        closeLabel : 'Fermer' ,
        init       :
        {
            id         : 'path',
            key        : 'path',
            label      : 'Trajet du parcours',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            type       : 'search',
            variant    : 'outlined'
        },
        inputProps :
        {
            label : 'Trajet...'
        },
        limit  : 0,
        logics :
        {
            disabledSuggestions : ( props ) =>
            {
                const { rel } = props ;
                let suggestions = [] ;
                if( rel instanceof Thing )
                {
                    const { additionalType } = rel ;
                    if( additionalType instanceof Word )
                    {
                        suggestions.push( additionalType ) ;
                    }
                }
                return suggestions ;
            }
        }
    },
    {
        type       : Form.SEARCH_SELECTOR ,
        path       : api.thesaurus.courses_status,
        clearLabel : 'Vider' ,
        closeLabel : 'Fermer' ,
        init       :
        {
            id         : 'status',
            key        : 'status',
            label      : 'État du parcours',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            type       : 'search',
            variant    : 'outlined'
        },
        inputProps :
        {
            label : 'Statut...'
        },
        limit  : 0,
        logics :
        {
            disabledSuggestions : ( props ) =>
            {
                const { rel } = props ;
                let suggestions = [] ;
                if( rel instanceof Thing )
                {
                    const { additionalType } = rel ;
                    if( additionalType instanceof Word )
                    {
                        suggestions.push( additionalType ) ;
                    }
                }
                return suggestions ;
            }
        }
    },
    {
        type       : Form.SEARCH_SELECTOR ,
        path       : api.thesaurus.courses_audiences,
        clearLabel : 'Vider' ,
        closeLabel : 'Fermer' ,
        init       :
        {
            id         : 'audience',
            key        : 'audience',
            label      : 'Cible du parcours',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            type       : 'search',
            variant    : 'outlined'
        },
        inputProps :
        {
            label : 'Cible...'
        },
        limit  : 0,
        logics :
        {
            disabledSuggestions : ( props ) =>
            {
                const { rel } = props ;
                let suggestions = [] ;
                if( rel instanceof Thing )
                {
                    const { additionalType } = rel ;
                    if( additionalType instanceof Word )
                    {
                        suggestions.push( additionalType ) ;
                    }
                }
                return suggestions ;
            }
        }
    },
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'distance',
            key          : 'distance',
            label        : 'Distance (en mètres)',
            autoFocus    : false,
            fullWidth    : true,
            /*InputProps   : {
                inputComponent : ( input ) =>
                (
                    <NumberInput
                        allowNegative = {false}
                        {...input}
                    />
                )
            },*/
            margin       : 'normal',
            select       : false,
            autoComplete : 'off' ,
            type         : 'text',
            variant      : 'outlined'
        }
    }
];


const course =
{
    // dependencies

    elevation,
    steps,
    transportations,

    // settings

    edit :
    {
        elements,
        agree       : 'Sauver' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour modifier ce parcours.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...' ,
        success     : 'Mise à jour réussie!' ,
        title       : 'Modifier le parcours'
    },
    labels :
    {
        audience : 'Cible du parcours',
        distance : 'Distance du parcours (en mètres)',
        empty    : '----' ,
        gain     : 'Dénivelé positif du parcours (en mètres)',
        level    : 'Niveau du parcours',
        loss     : 'Dénivelé négatif du parcours (en mètres)',
        maxValue : 'Elévation maximum du parcours (en mètres)',
        minValue : 'Elévation minimum du parcours (en mètres)',
        path     : 'Trajet du parcours',
        status   : 'État du parcours' ,
        name     : 'Nom' ,
        type     : 'Type de parcours'
    },
    title    : 'Parcours' ,
    tooltips :
    {
        add    : 'Ajouter',
        edit   : 'Modifier',
        remove : 'Supprimer'
    }
};

export default course ;
