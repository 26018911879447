import React from "react"

import GridIcon from '@material-ui/icons/Apps'
import ListIcon from '@material-ui/icons/List'

import Stage      from '../stage/Stage'
import StagesGrid from '../../display/grids/StagesGrid'
import StagesList from '../../display/lists/StagesList'

import View from '../../display/views/TabsView'

import initView from '../../display/views/initView'

import api from '../../configs/api'

const styles = () => ({}) ;

const path = api.stages ;

const { url:uri } = path ;

class Stages extends View
{
    getLocale = () => this.props.locale.views.stages ;
}

Stages.defaultProps =
{
    ...View.defaultProps,
    path ,
    first  : 'grid' ,
    routes :
    [
        { id : 'grid'  , Component : StagesGrid , uri , backPath:'/grid' } ,
        { id : 'list'  , Component : StagesList , uri , backPath:'/list' } ,
        { id : 'stage' , component : Stage      , path : uri + '/:id'    }
    ],
    tabs :
    [
        { id : 'grid' , icon : <GridIcon /> } ,
        { id : 'list' , icon : <ListIcon /> }
    ]
};

Stages.propTypes =
{
    ...View.propTypes
};

export default initView( Stages, styles );
