import React from 'react'

import clsx from 'clsx'

import format from 'vegas-js-core/src/strings/fastformat'

import { withStyles } from '@material-ui/core/styles'

import { Grid } from '@material-ui/core'

import withUser from '../../../contexts/user/withUser'

import Address           from '../../../display/things/Address'
import Metadatas         from '../../../display/things/Metadatas'
import ThingSubContainer from '../../../display/containers/ThingSubContainer'
import TransitionGroup   from '../../../transitions/TransitionGroup'
import User              from '../../../display/things/person/User'

const half =
{
    md :  6 ,
    sm : 12 ,
    xs : 12
};

const styles = () => ({});

class About extends ThingSubContainer
{
    change = thing =>
    {
        if( thing )
        {
            const { writeUser } = this.props ;
            if( writeUser instanceof Function )
            {
                writeUser( thing ) ;
            }
        }

        if( this._mounted )
        {
            this.setState( { thing } , () =>
            {
                const { onChange } = this.props ;
                if( onChange instanceof Function )
                {
                    onChange( thing ) ;
                }
            }) ;
        }
    };

    render = () =>
    {
        const { user } = this.props ;
        if( user )
        {
            let { address, id } = user ;

            const {
                className ,
                path ,
                style
            } = this.props ;

            return (
            <div
                className = { clsx( "flex-0 p-12" , className ) }
                style     = { style }
            >
                <TransitionGroup duration={250} enter={{animation: "transition.slideUpIn"}}>
                    <Metadatas
                        activable = { false }
                        deletable = { false }
                        thing     = { user }
                    />
                    <Grid container spacing={3}>
                        <Grid item {...half}>
                            <User
                                editUri  = { path.url }
                                onChange = { this.change }
                                thing    = { user } //new UserVO(thing)
                            />
                        </Grid>
                        <Grid item {...half}>
                            <Address
                                editUri  = { format(path.address,id) }
                                onChange = { this.changeProperty('address',true) }
                                thing    = { address }
                                uri      = { !!(path && path.address) ? path.address : null }
                            />
                        </Grid>
                    </Grid>
                </TransitionGroup>
            </div>
            );
        }
        return null ;
    }
}

About.defaultProps =
{
    ...ThingSubContainer.defaultProps
};

About.propTypes =
{
    ...ThingSubContainer.propTypes
};

export default withStyles( styles ) ( withUser( About ) ) ;
