import React from 'react'

import clsx from 'clsx'

import PropTypes from 'prop-types'

import notEmpty from 'vegas-js-core/src/strings/notEmpty'

import { Lines as Preloader } from 'react-preloading-component'

import { Typography } from '@material-ui/core'

import YouTubeIcon from '@material-ui/icons/YouTube'

import withi18n from '../../contexts/i18n/withi18n'

import VideoObject from '../../things/creativework/media/VideoObject'

import getLocaleDescription from '../../things/getLocaleDescription'
import { YOUTUBE } from '../../things/creativework/mimetypes'

import { ThingCell } from './ThingCell'

import FileIcon, { defaultStyles } from 'react-file-icon'


export class VideoCell extends ThingCell
{
    constructor( props )
    {
        super( props );
        this.state   =
        {
            ...this.state,
            preload : true
        }
    }

    getAvatar = () =>
    {
        const thing = this.getThing();
        if( thing )
        {
            const { encodingFormat } = thing ;
            if( notEmpty( encodingFormat ) )
            {
                let icon ;

                switch( encodingFormat )
                {
                    case YOUTUBE :
                    {
                        icon = <YouTubeIcon color='action' /> ;
                        break ;
                    }

                    default :
                    {
                        let extension = 'mpeg';

                        let styles ;
                        if( notEmpty(extension) && defaultStyles.hasOwnProperty(extension) )
                        {
                            styles = defaultStyles[extension];
                        }

                        icon = (
                            <FileIcon
                                size      = { 26 }
                                extension = { extension }
                                { ...styles }
                            />
                        );
                    }
                }

                if( icon )
                {
                    return (
                        <div className = 'flex items-center justify-center mr-12'>
                            { icon }
                        </div>
                    )
                }
            }
        }
        return this.props.icon ;
    }

    getContent = () =>
    {
        const thing = this.getThing();
        if( thing )
        {
            const { encodingFormat } = thing;
            switch ( encodingFormat )
            {
                case YOUTUBE :
                {
                    const { embedUrl } = thing ;
                    if( notEmpty( embedUrl ) )
                    {
                        const height = 320 ;
                        const { preload } = this.state ;
                        return (
                        <div
                            className = { clsx( "flex items-center justify-center w-full my-8" , this.props.className ) }
                            style     = {{ height }}
                        >
                            <iframe
                                id              = 'youtube-player'
                                allow           = "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen = { true }
                                frameBorder     = '0'
                                onLoad          = { () => { if( this._mounted ) this.setState( { preload:false } ) ; } }
                                title           = 'youtube-preview'
                                width           = '100%'
                                height          = { height }
                                src             = { embedUrl }
                                style           = {{ display: preload ? 'none' : null }}
                                type            = 'text/html'
                            >
                            </iframe>
                            { preload && <Preloader color='#333333' size={16} /> }
                        </div>);
                    }
                    break;
                }
                default :
                {
                    //
                }
            }
        }
        return null ;
    };

    getDescription = ( { className = null , ...props } = {} ) =>
    {
        const { description:visible } = this.props ;
        if( visible )
        {
            const thing = this.getThing() ;
            if( thing )
            {
                const description = getLocaleDescription( thing , this.props.lang ) ;
                if( notEmpty( description ) )
                {
                    return (
                        <Typography
                            className = { clsx( 'font-normal text-justify py-8' , className ) }
                            variant   = 'caption'
                            { ...props }
                        >
                            { description }
                        </Typography>
                    );
                }
            }
        }
        return null ;
    };

    getFooter = () =>
    {
        const { footer } = this.props ;
        if( footer )
        {
            const description =  this.getDescription() ;
            if( description )
            {
                const { className } = this.props;
                return (
                    <div className={ clsx( "flex flex-col flex-1" , className ) } >
                        <div className="flex flex-row items-center w-full justify-between" >
                            { description }
                        </div>
                    </div>
                );
            }
        }
        return null ;
    };

    getLocale = () => this.props.locale.display.cells.video;

    getThing = () =>
    {
        const { thing } = this.props ;
        if( thing )
        {
            if( thing instanceof VideoObject )
            {
                return thing ;
            }
            const { member } = this.props ;
            if( notEmpty(member) && thing[member] instanceof VideoObject )
            {
                return thing[member] ;
            }
        }
        return null ;
    }
}

VideoCell.defaultProps =
{
    ...ThingCell.defaultProps,
    delay       : 250 ,
    description : true ,
    mode        : 'label' ,
    member      : null ,
    player      : true ,
};

VideoCell.propTypes =
{
    ...ThingCell.propTypes,
    delay       : PropTypes.number.isRequired,
    description : PropTypes.bool ,
    member      : PropTypes.string ,
    player      : PropTypes.bool
};

export default withi18n( VideoCell ) ;
