import React from 'react'

import clsx from 'clsx'

import PropTypes from 'prop-types'

import { TableCell, TableRow } from '@material-ui/core'

import grey from '@material-ui/core/colors/grey'

import withDataTable from './withDataTable'

import DatasTable from './DatasTable'

import RequestStatus from '../../net/RequestStatus'

import ThingCell , { modes, LINK } from '../cells/ThingCell'

export class ThingTable extends DatasTable
{
    getRow = ( item , index ) =>
    {
        if( item )
        {
            let {
                backgroundColors,
                clazz,
                cellClassName,
                CellComponent,
                cellPadding,
                cellMode,
                cellProps,
                cellSize,
                selectable,
                toggleSelected,
                selectedItems
            }
            = this.props;

            const { id } = item ;

            const find = (selectedItems instanceof Array)
                      && selectedItems.findIndex( element => (id === element.id) ) > -1 ;

            selectable = this.isEditable() && selectable ;

            const len = backgroundColors.length ;

            return (
                <TableRow
                    className = 'm-8'
                    key       = { "row-" + index }
                    style     = {{ background : backgroundColors[index % len] }}
                >
                    <TableCell
                        className = { clsx('w-full' , cellClassName ) }
                        component = 'td'
                        padding   = { cellPadding }
                        scope     = 'row'
                        size      = { cellSize }
                    >
                        {
                            ( item instanceof clazz ) &&
                            <CellComponent
                                avatar     = { this.getCheckbox }
                                mode       = { cellMode }
                                editable   = { this.isEditable() }
                                onClick    = { () => this.onSelect(item) }
                                onSelect   = { () => toggleSelected( item , 'id' ) }
                                selectable = { selectable }
                                selected   = { !!(find) }
                                options    = { this.getRowOptions( item , index ) }
                                thing      = { item }
                                { ...cellProps}
                            />
                        }
                    </TableCell>
                </TableRow>
            );
        }
        return null ;
    };

    getRowOptions = thing =>
    {
        if( thing )
        {
            if( this.isEditable() )
            {
                const {
                    activable,
                    preferable,
                    deletable
                } = this.props;
                return (
                    <div className="flex justify-end items-center">
                        { activable  && this.getActiveSwitch(thing) }
                        { preferable && this.getFavoriteButton(thing) }
                        { deletable  && this.getDeleteButton(thing) }
                    </div>
                );
            }
        }
        return null ;
    };

    onSelect = () => null ;
}

ThingTable.defaultProps =
{
    ...DatasTable.defaultProps ,
    activeSwitchProps : { size:'small' },
    addMode           : RequestStatus.REDIRECT,
    backgroundColors  : [ grey[50], grey[100] ],
    cellClassName     : null ,
    CellComponent     : ThingCell,
    cellPadding       : 'none' ,
    cellMode          : LINK,
    cellSize          : 'small',
    deleteProps       : { size:'small' },
    sortable          : true
};

ThingTable.propTypes =
{
    ...DatasTable.propTypes,
    backgroundColors   : PropTypes.arrayOf(PropTypes.string),
    CellComponent      : PropTypes.elementType,
    cellClassName      : PropTypes.string,
    cellMode           : PropTypes.oneOf(modes),
    cellPadding        : PropTypes.oneOf(['checkbox','default','none']),
    cellProps          : PropTypes.object , // ex: { variant:'simple' }
    cellSize           : PropTypes.oneOf(['medium','small']),
};

export default withDataTable()( ThingTable ) ;
