const me =
{
    helmet :
    {
        title : 'Chargement du profil utilisateur' ,
        metas : []
    },
    loading : 'Chargement du profil utilisateur ...' ,
    title   : process.env.REACT_APP_TITLE
};

export default me ;