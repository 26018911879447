import React from 'react'

import PropTypes from 'prop-types'

import clsx from 'clsx'

import notEmpty from 'vegas-js-core/src/strings/notEmpty'

import LocationCityIcon from '@material-ui/icons/LocationCity'

import ThingComponent from '../things/ThingComponent'

import getLocaleAddressLabel from '../../things/getLocaleAddressLabel'

import withi18n  from '../../contexts/i18n/withi18n'
import withSnack from '../../contexts/snack/withSnack'

export class AddressLabel extends ThingComponent 
{
    getLocale = () => this.props.locale.display.labels.address ;
    
    render = () =>
    {
        const thing = this.getThing();
        if ( thing ) 
        {
            const { address } = thing;
            if ( address ) 
            {
                const { separator1, separator2 } = this.props;
                const label = getLocaleAddressLabel( address , { separator1, separator2 } ) ;
                if ( notEmpty(label) ) 
                {
                    const { className } = this.props;
                    return (
                        <div className={clsx("flex flex-row items-center p-8", className)} >
                            <div className="flex-initial">{ this.getIcon({ label }) } </div>
                            <div className="flex flex-col pl-8">
                                { this.getTitle() }
                                { this.getLabel(label) }
                            </div>
                        </div>
                    );
                }
            }
        }
        return null;
    }
}
    
AddressLabel.defaultProps =
{
    ...ThingComponent.defaultProps,
    icon       : <LocationCityIcon fontSize='small' /> ,
    separator1 : '-' ,
    separator2 : '\n\r' 
}

AddressLabel.propTypes =
{
    ...ThingComponent.propTypes,
    separator1 : PropTypes.string,
    separator2 : PropTypes.string
}

export default withSnack( withi18n( AddressLabel ) );