const places =
{
    helmet :
    {
        title : 'Lieux' ,
        metas : []
    },
    title : 'Lieux'
};

export default places ;