const death =
{
    copy :
    {
        snack   : 'Date de décès copiée dans le presse-papiers.' ,
        tooltip : 'Copier la date de décès'
    },
    date      : '{0}' ,
    pattern   : 'DD MMMM YYYY' ,
    place     : '{0}' ,
    separator : ' - ' ,
    title     : 'Décès'
};

export default death ;
