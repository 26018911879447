import api           from '../../../../configs/api'
import Form          from '../../../../display/forms/FormElements'
import PropertyValue from '../../../../things/PropertyValue'
import Thing         from '../../../../things/Thing'

const workFeatured =
{
    add :
    {
        agree       : 'Ajouter' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez sélectionner un objet en cliquant ci-dessous.' ,
        elements    :
        [
            {
                type       : Form.SEARCH_SELECTOR ,
                path       : api.conceptualObjects.url ,
                clearLabel : 'Vider' ,
                closeLabel : 'Fermer' ,
                init       :
                {
                    id        : 'value',
                    key       : 'value',
                    label     : 'Objet',
                    autoFocus : false,
                    fullWidth : true,
                    margin    : 'normal',
                    required  : true,
                    type      : 'search',
                    variant   : 'outlined'
                },
                inputProps :
                {
                    label : 'Objet...'
                },
                logics :
                {
                    disabledSuggestions : ( props ) =>
                    {
                        const { rel } = props ;
                        if( rel instanceof Thing )
                        {
                            const { subjectOf } = rel ;
                            if( subjectOf instanceof Thing )
                            {
                                const { workFeatured } = subjectOf ;
                                if( workFeatured instanceof Array )
                                {
                                    return workFeatured ;
                                }
                            }
                        }
                        return null ;
                    }
                }
            }
        ],
        fail    : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading : 'Chargement en  cours...' ,
        prepare : ( item ) =>
        {
            return (
            {
                value : item.value
            });
        },
        mapUri : ( item , uri ) =>
        {
            if( item instanceof PropertyValue)
            {
                const { value } = item ;
                if( value instanceof Thing )
                {
                    return uri + '/' + value.id ;
                }
            }
            return uri ;
        },
        success : 'Ajout du nouvel objet réussi!' ,
        title   : 'Ajouter un objet'
    },
    remove :
    {
        agree       : 'Supprimer' ,
        description : 'Souhaitez-vous supprimer cet objet:' ,
        disagree    : 'Annuler' ,
        loading     : 'Suppression en cours...' ,
        title       : 'Supprimer l\'objet ?'
    },
    title : 'Objets associés' ,
    tooltips :
    {
        add    : "Ajouter" ,
        edit   : "Éditer" ,
        remove : "Supprimer"
    }
};

export default workFeatured ;
