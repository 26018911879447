import initDialog from '../initDialog'

import { RemoveDialog } from '../RemoveDialog'

const styles = () => ({});

class RemoveDiscoverDialog extends RemoveDialog
{
    prepare = item =>
    {
        const { url } = item ;
        return { url } ;
    };

    getLocale = () => this.props.locale.dialogs.application.discover.remove ;
}

RemoveDiscoverDialog.defaultProps =
{
    ...RemoveDialog.defaultProps,
    mock  : false ,
    useID : false
};

RemoveDiscoverDialog.propTypes =
{
    ...RemoveDialog.propTypes
};

export default initDialog({ styles })( RemoveDiscoverDialog );
