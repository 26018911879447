export default
[
    { id:'Mo' , name:"Lundi"      , short:'Lun' } ,
    { id:'Tu' , name:"Mardi"      , short:'Mar' } ,
    { id:'We' , name:"Mercredi"   , short:'Mer' } ,
    { id:'Th' , name:"Jeudi"      , short:'Jeu' } ,
    { id:'Fr' , name:"Vendredi"   , short:'Ven' } ,
    { id:'Sa' , name:"Samedi"     , short:'Sam' } ,
    { id:'Su' , name:"Dimanche"   , short:'Dim' } ,
    { id:'Ph' , name:"Jour férié" , short:'Fér' } ,
    { id:'Sh' , name:"Vacances"   , short:'Vac' }
];