import application from './application'
import color       from './color'
import embed       from './embed'
import media       from './media'
import select      from './select'
import things      from './things'
import upload      from './upload'

export default
{
    application,
    color,
    embed,
    media,
    select,
    things,
    upload
};
