import React from 'react'

import CreatedIcon from '@material-ui/icons/EventAvailableOutlined'

import { DateLabel } from './DateLabel'

import withi18n  from '../../contexts/i18n/withi18n'
import withSnack from '../../contexts/snack/withSnack'

export class CreatedLabel extends DateLabel 
{  
    getLocale = () => this.props.locale.display.labels.created;
}
    
CreatedLabel.defaultProps =
{
    ...DateLabel.defaultProps,
    formatVariant : 'fromNow' ,
    icon          : <CreatedIcon className='text-gray-600' fontSize='small' />,
    labelProps    : { className:'text-gray-600' },
    member        : 'created' 
}

CreatedLabel.propTypes =
{
    ...DateLabel.propTypes
}

export default withSnack( withi18n( CreatedLabel ) );