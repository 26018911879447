import React , { Component, Fragment } from 'react'

import clsx from 'clsx'

import PropTypes from 'prop-types'

import withLang from '../../../contexts/i18n/withLang'

import { withStyles } from '@material-ui/core/styles'

import { Divider, Typography } from '@material-ui/core'

import isString from 'vegas-js-core/src/isString'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import StoreIcon from '@material-ui/icons/Store'

import NumberValue from '../../../things/NumberValue'
import Thing       from '../../../things/Thing'
import Workplace   from '../../../things/Workplace'

const styles = () => (
{
    root :
    {
        alignItems     : 'center',
        display        : 'flex' ,
        flexDirection  : 'column',
        justifyContent : 'space-between' ,
        width          : '100%'
    }
});

export class WorkplaceHeader extends Component
{
    render()
    {
        const { thing } = this.props ;
        if( thing instanceof Workplace )
        {
            const {
                classes,
                className,
                divider,
                icon,
                lang,
                style
            } = this.props ;

            let { identifier, name } = thing ;

            let title ;
            let subtitle ;

            if( name )
            {
                name = thing.getLocaleName( lang ) ;
                if( isString(name) )
                {
                    title = ucFirst(name) ;
                }
            }

            if ( identifier instanceof NumberValue )
            {
                let { additionalType, value } = identifier ;

                let txt = [] ;

                if( additionalType instanceof Thing )
                {
                    txt.push( ucFirst(additionalType.getLocaleName(lang)) ) ;
                }

                if( value )
                {
                    txt.push( value ) ;
                }

                if( txt.length > 0 )
                {
                    txt = txt.join( ' › ' ) ;
                }
                else
                {
                    txt = null ;
                }

                if( !isString(title) )
                {
                    title = txt ;
                }
                else
                {
                    subtitle = (
                    <Typography
                        className="mr-8 font-medium"
                        variant='subtitle2'
                    >
                        {txt}
                    </Typography>) ;
                }
            }

            if( isString(title) )
            {
                title = (
                <Typography
                    className = "text-gray-800 font-medium"
                    variant   ='subtitle1'
                >
                    { title }
                </Typography>);
            }

            return (
            <Fragment>
                <div
                    className = { clsx(classes.root, className) }
                    style     = { style }
                >
                    <div className="flex flex-col w-full">
                        <div className="flex flew-row items-center mb-8 w-full">
                            { icon }
                            { title }
                        </div>
                        { subtitle }
                    </div>
                </div>
                { divider }
            </Fragment>
            );
        }
        return null ;
    }
}

WorkplaceHeader.defaultProps =
{
    className : null,
    divider   : <Divider light={true} variant='fullWidth' className='my-16' />,
    icon      : <StoreIcon className="mr-16" />,
    style     : null,
    thing     : null
};

WorkplaceHeader.propTypes =
{
    className : PropTypes.string,
    icon      : PropTypes.element,
    style     : PropTypes.object,
    thing     : PropTypes.object.isRequired
};

export default withStyles( styles )( withLang(WorkplaceHeader) ) ;
