import React, { Component } from 'react'

import clsx from 'clsx'

import PropTypes from 'prop-types'

import isString from 'vegas-js-core/src/isString'

import { withStyles } from '@material-ui/core/styles'

import { Fab, Tooltip } from '@material-ui/core'

import AddIcon from '@material-ui/icons/Add'

const styles =
{
    option :
    {
        marginLeft : 10
    }
};

class SelectMediaButton extends Component
{
    render = () =>
    {
        const {
            className,
            classes,
            disabled,
            color,
            locale,
            onClick,
            size
        } = this.props;

        let { icon } = this.props ;
        if( !icon )
        {
            icon = <AddIcon/> ;
        }

        let button = (
        <Fab
            disabled = { disabled }
            color    = { color }
            onClick  = { onClick }
            size     = { size }
        >
            { icon }
        </Fab>);

        if( !disabled && locale )
        {
            const { tooltips } = locale ;
            if( tooltips )
            {
                const { selectMedia } = tooltips ;
                if( isString( selectMedia ) )
                {
                   button = (
                    <Tooltip placement='left' title={ selectMedia }>
                        { button }
                    </Tooltip>
                    )
                }
            }
        }

        return <div className={clsx(classes.option,className)}>{button}</div> ;
    };
}

SelectMediaButton.defaultProps =
{
    className : null  ,
    color     : 'primary' ,
    disabled  : false,
    icon      : <AddIcon/>,
    locale    : null,
    onClick   : null,
    size      : 'small'
};

SelectMediaButton.propTypes =
{
    classes   : PropTypes.object.isRequired ,
    className : PropTypes.string,
    disabled  : PropTypes.bool,
    color     : PropTypes.string,
    icon      : PropTypes.element,
    locale    : PropTypes.object,
    onClick   : PropTypes.func,
    size      : PropTypes.string
};

export default withStyles( styles )( SelectMediaButton );
