import isString from 'vegas-js-core/src/isString'

import generify from './generify'

import Place   from './Place'
import Thing   from './Thing'
import Website from './Website'
import Word    from './Word'

import populateDiscover from './populateDiscover'
import populateMedias   from './creativework/populateMedias'

/**
 * Creates a new Stage definition.
 * @memberOf things
 */
class Stage extends Thing
{
    /**
     * Creates a new Stage instance.
     * @constructor
     * @param object The generic object to initialize the object the first time.
     */
    constructor( object = null )
    {
        super() ;

        /**
         * The activities of this stage.
         * @type {Array}
         */
        this.activities = null;

        /**
         * The alternativeHeadline of this stage.
         * @type {String}
         */
        this.alternativeHeadline = null;

        /**
         * The audios medias of this stage.
         * @type {Array}
         */
        this.audios = null;

        /**
         * The discover objects of this stage.
         * @type {Array}
         */
        this.discover = null;

        /**
         * The headline of this stage.
         * @type {String}
         */
        this.headline = null ;

        /**
         * The location of this stage.
         * @type {Place}
         */
        this.location = null ;

        /**
         * Indicates the number of audios medias of this thing.
         * @type {Array}
         */
        this.numAudios = null;

        /**
         * Indicates the number of videos medias of this thing.
         * @type {Array}
         */
        this.numPhotos = null;

        /**
         * Indicates the number of videos medias of this thing.
         * @type {Array}
         */
        this.numVideos = null;

        /**
         * The photos medias associated with this stage.
         * @type {Array}
         */
        this.photos = null ;

        /**
         * The status of this stage.
         * @type {Object}
         */
        this.status = null;

        /**
         * The videos medias associated with this stage.
         * @type {Array}
         */
        this.videos = null ;

        /**
         * The websites of this stage.
         * @type {Array}
         */
        this.websites = null;

        this.set( object ) ;
    }

    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        super.clear() ;
        this.activities =
        this.audios =
        this.alternativeHeadline =
        this.discover =
        this.headline =
        this.location =
        this.numAudios =
        this.numPhotos =
        this.numVideos =
        this.photos =
        this.status =
        this.videos =
        this.websites = null ;
        return this ;
    }

    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new Stage( this.toObject() ) ;
    }

    /**
     * Returns the locale alternativeHeadline expression of the thing.
     * @return {String} The locale headline of the thing.
     */
    getLocaleAlternativeHeadline = this.getLocaleProperty('alternativeHeadline') ;

    /**
     * Returns the locale headline expression of the thing.
     * @return {String} The locale headline of the thing.
     */
    getLocaleHeadline = this.getLocaleProperty('headline') ;

    getLocaleTitle = ( lang = null , defaultLang = null ) =>
    {
        let title = this.getLocaleHeadline( lang , defaultLang ) ;
        if( !isString(title) || title === "" )
        {
            const { location } = this ;
            if( location instanceof Place )
            {
                title = location.getLocaleAlternateName( lang, defaultLang  ) ;
            }
        }

        if( !isString(title) || title === "" )
        {
            title = this.getLocaleName( lang ) ;
        }

        return title ;
    }

    /**
     * Populate the object with specific behaviors or types. Launched by default in the constructor and in the set methods.
     * @returns {Thing} The current reference.
     */
    populate()
    {
        const {
            activities,
            location,
            status,
            websites
        } = this ;

        populateMedias( this ) ;

        if( activities instanceof Array )
        {
            this.activities = activities.map( item => item instanceof Word ? item : new Word(item) ) ;
        }

        if( location && !(location instanceof Place))
        {
            this.location = new Place(location) ;
        }

        if( status && !(status instanceof Word))
        {
            this.status = new Word(status) ;
        }

        if( websites )
        {
            if( websites instanceof Array )
            {
                this.websites = websites.map( item => item instanceof Website ? item : new Website(item) ) ;
            }
        }

        populateDiscover( this ) ;

        return this ;
    }

    /**
     * Returns the generic object representation of the item.
     * @return {Object} The generic object representation of the item.
     */
    toObject()
    {
        let {
            activities,
            alternativeHeadline,
            audios,
            discover,
            headline ,
            location ,
            numAudios,
            numPhotos,
            numVideos,
            photos,
            status,
            videos,
            websites
        } = this ;

        activities        = generify(activities) ;
        audios            = generify(audios) ;
        discover          = generify(discover) ;
        location          = generify(location);
        photos            = generify(photos) ;
        status            = generify(status) ;
        websites          = generify(websites);
        videos            = generify(videos) ;

        return {
            ...super.toObject() ,
            activities,
            alternativeHeadline,
            audios,
            discover,
            headline ,
            location ,
            numAudios,
            numPhotos,
            numVideos,
            photos,
            status,
            videos,
            websites
        }
    }
}

export default Stage ;
