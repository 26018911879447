import clean from 'vegas-js-core/src/objects/clean'

import Form from '../../../../../../../display/forms/FormElements'

import Method from '../../../../../../../net/Method'

import AnnualHealthReview from '../../../../../../../things/livestock/reviews/AnnualHealthReview'
import SectorHealthEvent  from '../../../../../../../things/livestock/events/SectorHealthEvent'

import generify from '../../../../../../../things/generify'

const elements =
[
    {
        type : Form.I18N_EDITOR ,
        init :
        {
            id    : 'description',
            key   : 'description',
            input : false,
            label : 'Description de l\'hypothèse'
        }
    }
];

const prepare = ( datas ) =>
{
    if( datas )
    {
        let {
            about     : event,
            subjectOf : review,
            causes,
            description,
            diseases

        } = datas ;

        if( causes )
        {
            causes = generify(causes,true);
        }

        if( diseases )
        {
            diseases = generify(diseases,true);
        }

        if( (event instanceof SectorHealthEvent) && (review instanceof AnnualHealthReview) )
        {
            const { id : index } = event ;

            review = review.toObject() ;

            let { subReview } = review ;
            if( subReview instanceof Array )
            {
                let len1 = subReview.length ;
                for( let i = 0 ; i<len1 ; i++ )
                {
                    let workplace = subReview[i] ;
                    if( workplace )
                    {
                        let { subReview } = workplace ;
                        if( subReview instanceof Array )
                        {
                            let len2 = subReview.length ;
                            for( let j = 0 ; j<len2 ; j++  )
                            {
                                let sectorReview = subReview[j] ;
                                if( sectorReview )
                                {
                                    let { events } = sectorReview ;
                                    if( events instanceof Array )
                                    {
                                        let len3 = events.length ;
                                        for( let k = 0 ; k<len3 ; k++ )
                                        {
                                            let event = events[k] ;
                                            if( event && event.id )
                                            {
                                                if( index === event.id )
                                                {
                                                    event.hypothesis = clean(
                                                    {
                                                        causes,
                                                        description,
                                                        diseases
                                                    });
                                                    review = JSON.stringify(review) ;

                                                    return { review } ;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    return null ;
};

const hypothesis =
{
    edit :
    {
        agree       : 'Sauver' ,
        disagree    : 'Annuler' ,
        fail        : 'Veuillez corriger les erreurs' ,
        loading     : 'Chargement en cours...' ,
        method      : Method.PATCH,
        mock        : false ,
        success     : 'Mise à jour réussie!' ,
        title       : 'Hypothèse' ,
        elements,
        prepare
    },
    labels:
    {
        description : 'Description'
    },
    title    : 'Hypothèses diagnostiques' ,
    tooltips :
    {
        edit : 'Modifier'
    }
};

export default hypothesis ;
