import about    from './place/about'

const article =
{
    about,

    helmet :
    {
        title : 'Article - {0}' ,
        metas : []
    },
    loading  : "Chargement ..." ,
    notFound : "Article inconnu" ,
    tabs     :
    {
        about  : 'À propos',
        medias : 'Médias' ,
        more   : 'Plus'
    },
    tooltips :
    {
        back : 'Tous les articles'
    }
};

export default article ;