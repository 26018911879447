import api  from "../../configs/api"
import auth from "../../configs/auth"

/**
 * Logout the application.
 */
export default function()
{
    sessionStorage.clear() ;
    localStorage.clear() ;

    // add redirect_uri and client_id in order to revoke access_token
    window.location = api.url + api.logout.url +
        "?redirect_uri=" + auth.homepage + "&client_id=" + auth.apis.api.client_id ;
}
