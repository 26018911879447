import React from 'react'

import GridIcon from '@material-ui/icons/Apps'
import ListIcon from '@material-ui/icons/List'

import Article from '../article/Article'

import ArticlesGrid from '../../display/grids/ArticlesGrid'
import ArticlesList from '../../display/lists/ArticlesList'

import TabsView from '../../display/views/TabsView'

import initView from '../../display/views/initView'

import api from '../../configs/api'

const styles = () => ({}) ;

const path = api.articles ;

const { url:uri } = path ;

class Articles extends TabsView
{
    getLocale = () => this.props.locale.views.articles ;
}

Articles.defaultProps =
{
    ...TabsView.defaultProps,
    first  : 'grid' ,
    path,
    routes :
    [
        { id : 'grid'    , Component : ArticlesGrid , uri , backPath:'/grid' } ,
        { id : 'list'    , Component : ArticlesList , uri , backPath:'/list' } ,
        { id : 'article' , Component : Article      , path : uri + '/:id'    }
    ],
    tabs     :
    [
        { id : 'grid' , icon : <GridIcon /> } ,
        { id : 'list' , icon : <ListIcon /> }
    ]
};

Articles.propTypes =
{
    ...TabsView.propTypes
};

export default initView( Articles , styles );