const authorities =
{
    tabs :
    {
        technicians   : 'Techniciens' ,
        veterinarians : 'Vetérinaires'
    }
};

export default authorities ;
