import React from 'react'

import compose from 'vegas-js-core/src/functors/compose'

import initApp from '../../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { Typography } from '@material-ui/core'

import isString from 'vegas-js-core/src/isString'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import TollIcon from '@material-ui/icons/Toll'

import NumberValue from '../../../things/NumberValue'
import Thing       from "../../../things/Thing"

import ThingChildren, { styles } from '../ThingChildren'

class Numbers extends ThingChildren
{
    createChild = ( child , editable = false ) =>
    {
        if( child )
        {
            const { lang } = this.props ;

            let { additionalType, value} = child ;

            let title = ucFirst(child.getLocaleName(lang)) ;

            if( editable )
            {
                title = (
                <Typography variant='subtitle1'>
                    <button className="text-blue-800 font-medium" onClick={ () => this.openEditDialog(child) }>
                        { title }
                    </button>
                </Typography>) ;
            }
            else
            {
                title = (
                    <Typography className = "text-gray-800 font-medium" variant='subtitle1'>
                        { title }
                    </Typography>
                );
            }

            if( isString(value) )
            {
                value = <Typography className="mr-12">{ value }</Typography> ;
            }
            else
            {
                value = null ;
            }

            let type ;
            if ( additionalType instanceof Thing )
            {
                type = ucFirst(additionalType.getLocaleName(lang));
            }

            let subtitle = (
                <div className="flex flex-row items-center">
                    { type }
                    { type && value && <Typography className="mx-8" variant='body2'>›</Typography> }
                    { value }
                </div>
            );

            return (
            <div className="py-8">
                { title }
                { subtitle }
            </div>
            );
        }

        return null ;
    };

    getLocale = () => this.props.locale.things.conceptualObject.numbers ;

    prepare = item =>
    {
        if( item instanceof NumberValue )
        {
            const { additionalType , name, value } = item ;
            return (
            {
                name ,
                additionalType : additionalType instanceof Thing ? String(additionalType.id) : null ,
                value
            });
        }
        return null ;
    };
}

Numbers.defaultProps =
{
    ...ThingChildren.defaultProps ,
    clazz     : NumberValue,
    badge     : true ,
    emptyIcon : null,
    icon      : <TollIcon/>,
    member    : 'numbers'
};

Numbers.propTypes =
{
    ...ThingChildren.propTypes
};

export default compose( withStyles( styles ) , withRouter , initApp )( Numbers ) ;
