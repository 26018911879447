import React from 'react'

import clsx from 'clsx'

import moment from 'moment/moment'

import ucFirst  from 'vegas-js-core/src/strings/ucFirst'
import isString from 'vegas-js-core/src/isString'

import PropTypes  from 'prop-types'

import withi18n from '../../../../../../contexts/i18n/withi18n'

import { withStyles } from '@material-ui/core/styles'

import { Grid } from '@material-ui/core'

import ScheduleIcon from '@material-ui/icons/Schedule'
import TodayIcon    from '@material-ui/icons/CalendarToday'

import { FaBarcode } from 'react-icons/fa'

import Container from '../../../../../../components/containers/Container'
import LabelItem from '../../../../../../components/items/LabelItem'

import AbortionEventVO from '../../../../../../things/livestock/events/AbortionEvent'
import Word            from '../../../../../../things/Word'

const styles = () => (
{
    root :
    {
        padding : 12,
        width   : '100%'
    }
});

class AbortionEvent extends Container
{
    render = () =>
    {
        const { thing } = this.props ;
        if( thing instanceof AbortionEventVO )
        {
            const locale = this.getLocale() ;
            if( locale )
            {
                const {
                    datePattern,
                    labels
                } = locale ;

                const {
                    className,
                    gridProps,
                    identifierIcon,
                    lang,
                    periodIcon,
                    periodVisible,
                    scheduleIcon,
                    style
                }
                = this.props  ;

                let {
                    identifier,
                    date,
                    period
                }
                = thing ;

                if( isString( date ) )
                {
                    date = moment(date).format(datePattern) ;
                }

                if( period instanceof Word )
                {
                    period = period.getLocaleName( lang ) ;
                }

                return(
                <div
                    className = { clsx( 'w-full p-8' , className ) }
                    style     = { style }
                >
                    <Grid container spacing={4}>

                        <Grid item {...gridProps}>
                            <LabelItem
                                divider   = { false }
                                enabled   = {!!(identifier)}
                                labelIcon = { identifierIcon }
                                title     = { labels.identifier }
                            >
                                { isString(identifier) && identifier !== '' ? identifier : labels.empty }
                            </LabelItem>
                        </Grid>

                        <Grid item {...gridProps}>
                            <LabelItem
                                divider   = { false }
                                enabled   = {!!(date)}
                                labelIcon = { scheduleIcon }
                                title     = { labels.date }
                            >
                                { isString(date) && date !== '' ? ucFirst(date) : labels.empty }
                            </LabelItem>
                        </Grid>

                        { periodVisible &&
                        <Grid item {...gridProps}>
                            <LabelItem
                                divider   = { false }
                                enabled   = {!!(period)}
                                labelIcon = { periodIcon }
                                title     = { labels.period }
                            >
                                { isString(period) && period !== '' ? ucFirst(period) : labels.empty }
                            </LabelItem>
                        </Grid>}
                    </Grid>

                </div>);
            }
        }

        return null ;
    };

    getLocale = () => this.props.locale.things.livestock.reviews.abortionSerie.events.abortionEvent ;
}

AbortionEvent.defaultProps =
{
    ...Container.defaultProps,
    editable       : false ,
    gridProps      : { md:4 , sm:6 , xs:12 },
    identifierIcon : <FaBarcode className="mr-8 self-center" style={{ fontSize:18 }}/>,
    onClick        : null ,
    periodIcon     : <TodayIcon className="mr-8 self-center" style={{ fontSize:18 }}/>,
    periodVisible  : true ,
    scheduleIcon   : <ScheduleIcon className="mr-8 self-center" style={{ fontSize:18 }}/>,
    thing          : null
};

AbortionEvent.propTypes =
{
    ...Container.propTypes,
    editable       : PropTypes.bool,
    gridProps      : PropTypes.object,
    identifierIcon : PropTypes.element,
    onClick        : PropTypes.func,
    periodVisible  : PropTypes.bool,
    scheduleIcon   : PropTypes.element,
    thing          : PropTypes.object
};

export default withStyles( styles )( withi18n( AbortionEvent ) ) ;
