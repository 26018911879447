import AudioIcon   from '@material-ui/icons/MusicNote'
import FileIcon    from '@material-ui/icons/InsertDriveFile'
import ImageIcon   from '@material-ui/icons/Image'
import PdfIcon     from '@material-ui/icons/PictureAsPdf'

//import { IoLogoYoutube as YoutubeIcon } from 'react-icons/io'

import YoutubeIcon from '@material-ui/icons/YouTube'

import {
    JPEG,
    MP3,
    MPEG,
    PDF,
    PNG,
    YOUTUBE
} from '../things/creativework/mimetypes'

/**
 * The mimetypes icons enumeration accepted by the application.
 */
const icons =
{
    [ MP3     ] : AudioIcon,
    [ MPEG    ] : AudioIcon,
    [ PDF     ] : PdfIcon,
    [ JPEG    ] : ImageIcon,
    [ PNG     ] : ImageIcon ,
    [ YOUTUBE ] : YoutubeIcon
};

export default icons ;

export const DEFAULT_ICON = FileIcon ;