import React from 'react'

import clsx from 'clsx'

import PropTypes from 'prop-types'

import format    from 'vegas-js-core/src/strings/fastformat'
import isBoolean from 'vegas-js-core/src/isBoolean'
import isString  from 'vegas-js-core/src/isString'
import notEmpty  from 'vegas-js-core/src/strings/notEmpty'
import ucFirst   from 'vegas-js-core/src/strings/ucFirst'

import { List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core'

import DeleteIcon from '@material-ui/icons/Delete'

import DialogError from '../../errors/DialogError'

import api from '../../configs/api'

import CoreDialog  from '../../components/dialogs/CoreDialog'
import ThingAvatar from '../../display/avatars/ThingAvatar'

import DELETE from '../../net/DELETE'
import PATCH  from '../../net/PATCH'

import Method        from '../../net/Method'
import RequestStatus from '../../net/RequestStatus'

import Person from '../../things/Person'
import Thing  from '../../things/Thing'

import initDialog from './initDialog'

import getLocaleHeadline   from '../../things/getLocaleHeadline'
import getLocaleThingLabel from '../../things/getLocaleThingLabel'

const styles = () => ({});

export class RemoveDialog extends CoreDialog
{
    constructor( props )
    {
        super( props );
        this.canceler = null ;
        this.prepare  = null ;
        this.state    =
        {
            ...this.state,
            status : RequestStatus.NEW
        };
    }

    get multiple()
    {
        const { item } = this.props ;
        return (item instanceof Array) && (item.length > 0) ;
    }

    agree = () =>
    {
        let {
            item,
            mapUri,
            method,
            mock = false,
            prepare,
            uri,
            useID,
            useUrl
        } = this.props ;

        prepare = prepare || this.prepare ;

        const locale = this.getLocale() ;

        let datas = {} ;
        let path  = api.url + uri ;

        if( locale )
        {
            const {
                mapUri  : map,
                method  : met,
                mock    : flag,
                prepare : prep,
                useID   : uid,
                useUrl  : uurl
            } = locale ;

            if( map instanceof Function )
            {
                mapUri = map ;
            }

            if( isBoolean(flag) )
            {
                mock = flag ;
            }

            if( isBoolean(uid) )
            {
                useID = uid ;
            }

            if( isBoolean(uurl) )
            {
                useUrl = uurl ;
            }

            if( met === Method.PATCH || met === Method.DELETE )
            {
                method = met ;
            }

            if( prep instanceof Function )
            {
                prepare = prep ;
            }
        }

        if( mapUri instanceof Function )
        {
            path = api.url + mapUri( item , uri ) ;
        }
        else if( item instanceof Thing )
        {
            if( useUrl )
            {
                path = item.url ;
            }
            else if( useID )
            {
                path += '/' + item.id;
            }
        }

        if( prepare instanceof Function )
        {
            try
            {
                datas = prepare( item , this.props ) ;
            }
            catch( er )
            {
                if( er instanceof DialogError)
                {
                    this.setState( { errors:er.errors , status:RequestStatus.FAIL } ) ;
                }
                return null ;
            }
        }

        if( method === Method.PATCH )
        {
            if( mock )
            {
                console.log( this + ' agree' , method , path , datas ) ;
            }
            else
            {
                this.canceler = PATCH(
                    path ,
                    {
                        datas,
                        success : this._success,
                        fail    : this._fail,
                        cancel  : this._cancel
                    }
                ) ;
            }
        }
        else
        {
            if( item instanceof Array && (item.length > 0) )
            {
                datas =
                {
                    list:item.map( element => element.id ).join(',') ,
                    ...datas
                } ;

                if( mock )
                {
                    console.log( this + ' agree' , method , path , datas ) ;
                }
                else
                {
                    this.canceler = DELETE(
                        path ,
                        {
                            datas,
                            cancel  : this._cancel,
                            fail    : this._fail,
                            success : this._success
                        }
                    ) ;
                }
            }
            else if( item instanceof Thing )
            {
                if( mock )
                {
                    console.log( this + ' agree' , method,  path , datas ) ;
                }
                else
                {
                    this.canceler = DELETE(
                        path ,
                        {
                            datas,
                            cancel  : this._cancel,
                            fail    : this._fail,
                            success : this._success
                        }
                    ) ;
                }
            }
            else
            {
                console.log( this + " delete failed, the item reference must be a valid deletable object.") ;
            }
        }
    };

    getAvatar = thing =>
    {
        if( !this.props.iconifiable )
        {
            return null ;
        }

        const {
            avatarSize,
            avatarVariant,
            defaultIcon,
        } = this.props ;

        return (
            <ThingAvatar
                className   = 'mr-16'
                defaultIcon = { defaultIcon }
                thing       = { thing }
                size        = { avatarSize }
                variant     = { avatarVariant }
            />
        );
    };

    getContent = () =>
    {
        const {
            iconifiable,
            item,
        } = this.props ;

        const locale = this.getLocale() ;

        const { status } = this.state ;

        const multiple = this.multiple ;
        const progress = status === RequestStatus.PROGRESS ;

        const { iconifiable:icon = true } = locale ;

        let showIcon = iconifiable && icon ;

        if( !progress )
        {
            if( multiple && item instanceof Array && item.length > 0 )
            {
                return <List>{ item.map( element => this.getElement(element,true,showIcon) ) }</List> ;
            }
            else if( item )
            {
                return <List>{ this.getElement(item,false,showIcon) }</List> ;
            }
        }

        return null ;
    };

    getDescription = () =>
    {
        const { item, lang } = this.props ;

        const { status } = this.state ;

        const multiple = this.multiple ;
        const progress = status === RequestStatus.PROGRESS ;

        const {
            description ,
            descriptionX ,
            loading
        } = this.getLocale() ;

        let text ;

        if( progress )
        {
            text = loading ;
        }
        else
        {
            if( multiple && (item instanceof Array) )
            {
                const count = item.length ;
                text = (count > 1) ? format( descriptionX , count ) : description ;
            }
            else
            {
                text = format( description, (item instanceof Thing) ? item.getLocaleName(lang) : '' );
            }
        }

        if( notEmpty(text) )
        {
            const {
                descriptionClassName,
                descriptionProps,
                descriptionVariant
            } = this.props ;
            return (
                <Typography
                    className = { clsx('py-12' , descriptionClassName ) }
                    variant   ={ descriptionVariant }
                    { ...descriptionProps }
                >
                    { text }
                </Typography>
            );
        }

        return null ;
    };

    getElement( element , divider = false , iconifiable = true )
    {
        if( element )
        {
            let content ;

            let primary   = this.getElementPrimary ;
            let secondary = this.getElementSecondary ;

            const { item } = this.getLocale() || {} ;
            if( item )
            {
                const { render, text } = item ;
                if( render instanceof Function )
                {
                    content = render( element , this.props , this.state ) ;
                }
                else if( text )
                {
                    const { primary:pri , secondary:sec } = text ;

                    if( pri )
                    {
                        primary = pri ;
                    }

                    if( sec )
                    {
                        secondary = sec ;
                    }
                }
            }

            if( primary instanceof Function )
            {
                primary = primary( element , this.props , this.state ) ;
            }

            if( secondary instanceof Function )
            {
                secondary = secondary( element , this.props , this.state ) ;
            }

            if( notEmpty(primary) )
            {
                primary = (
                    <Typography
                        color     = 'textPrimary'
                        className = 'truncate'
                    >
                        { primary }
                    </Typography>
                );
            }
            else
            {
                primary = null ;
            }

            if( notEmpty(secondary) )
            {
                secondary = (
                    <Typography
                        color   = 'textSecondary'
                        variant = 'caption'
                    >
                        { secondary }
                    </Typography>
                ) ;
            }
            else
            {
                secondary = null ;
            }

            if( !content && ( primary || secondary ) )
            {
                content = (
                    <ListItemText
                        primary   = { primary   }
                        secondary = { secondary }
                    />
                );
            }

            if( !content )
            {
                if( element instanceof Thing )
                {
                    const { lang } = this.props ;

                    let label ;

                    if( element instanceof Person )
                    {
                        label = element.getFullNameOrUsername( lang ) ;
                    }
                    else
                    {
                        const { headline } = element ;
                        if( headline )
                        {
                            label = getLocaleHeadline( element , lang ) ;
                        }
                    }

                    if( !isString( label ) || label === '' )
                    {
                        label = getLocaleThingLabel( element , lang );
                    }

                    if( notEmpty( label ) )
                    {
                        primary = (
                            <Typography
                                className = 'truncate'
                                color     = 'textPrimary'
                                variant   = 'body1'
                            >
                                { ucFirst(label) }
                            </Typography>
                        ) ;
                        content = <ListItemText primary={ primary } /> ;
                    }
                }
            }

            return (
                <ListItem
                    divider        = { divider }
                    dense          = { false }
                    key            = { 'item' + element.id }
                    disableGutters = { false }
                >
                    { iconifiable && <ListItemIcon>{this.getAvatar(element)}</ListItemIcon> }
                    { content }
                </ListItem>
            ) ;

        }
        return null ;
    }

    getElementPrimary = null ;

    getElementSecondary = null ;

    unmount = () =>
    {
        const { status } = this.state ;
        if( status === RequestStatus.PROGRESS && !!(this.canceler) )
        {
            this.canceler.cancel(this + ' cancel') ;
        }
    };

    // ------- PRIVATE

    _cancel = () =>
    {
        this.setState( { errors:null , status : RequestStatus.NEW  } ) ;
    };

    _fail = response =>
    {
        if( response )
        {
            const { data, status } = response ;
            if( data )
            {
                const { message } = data ;
                switch( message )
                {
                    case 'token revoked' :
                    {
                        break ;
                    }
                    default :
                    {
                        console.log( this + " failed, status:" + status + ", message:" , message );
                    }
                }
            }
        }
        this.setState({ status:RequestStatus.FAIL }) ;
    };

    _success = response =>
    {
        try
        {
            const {
                closeOnSuccess,
                item,
                onRemove
            }
            = this.props ;

            let value = null ;
            if( response )
            {
                const { data } = response ;
                if( data )
                {
                    const { result } = data ;
                    value = result ;
                }
            }

            this.setState( { status:RequestStatus.SUCCESS } ) ;

            if( onRemove instanceof Function )
            {
                onRemove( value , item ) ;
            }

            if( closeOnSuccess )
            {
                this.close() ;
            }

        }
        catch( error )
        {
            console.log( this + " _success failed with an unknow error, " , error );
        }
    };
}

RemoveDialog.defaultProps =
{
    ...CoreDialog.defaultProps,
    closeOnSuccess       : true ,
    defaultIcon          : <DeleteIcon/> ,
    descriptionClassName : null ,
    descriptionProps     : null ,
    descriptionVariant   : 'body2' ,
    iconifiable          : true ,
    item                 : null ,
    mapUri               : null,
    method               : Method.DELETE,
    onCancel             : null ,
    onRemove             : null ,
    open                 : false ,
    mock                 : false,
    prepare              : null,
    uri                  : null,
    useID                : true,
    useUrl               : false
};

RemoveDialog.propTypes =
{
    ...CoreDialog.propTypes,
    closeOnSuccess       : PropTypes.bool ,
    defaultIcon          : PropTypes.element,
    descriptionClassName : PropTypes.string ,
    descriptionProps     : PropTypes.object ,
    descriptionVariant   : PropTypes.string ,
    iconifiable          : PropTypes.bool ,
    item                 : PropTypes.oneOfType([PropTypes.array,PropTypes.object]) ,
    mapUri               : PropTypes.func ,
    method               : PropTypes.oneOf([Method.PATCH,Method.DELETE]),
    mock                 : PropTypes.bool,
    onRemove             : PropTypes.func ,
    open                 : PropTypes.bool,
    prepare              : PropTypes.func,
    uri                  : PropTypes.string,
    useID                : PropTypes.bool,
    useUrl               : PropTypes.bool
};

export default initDialog({ useLocale:false, styles, withTheme:true })(RemoveDialog);
