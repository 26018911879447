import organizer    from './event/organizer'
import period       from './event/period'
import subEvent     from './event/subEvent'
import superEvent   from './event/superEvent'
import workFeatured from './event/workFeatured'

import Form from '../../../display/forms/FormElements'

import Place from '../../../things/Place'
import Thing from '../../../things/Thing'
import Word  from '../../../things/Word'

import api from '../../../configs/api'

const elements =
[
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'name',
            key          : 'name',
            label        : 'Nom',
            autoFocus    : false,
            fullWidth    : true,
            helperText   : null,
            margin       : 'normal',
            required     : false,
            select       : false,
            autoComplete : 'off' ,
            type         : 'text',
            variant      : 'outlined'
        }
    },
    {
        type : Form.SEARCH_SELECTOR ,
        path : api.thesaurus.events_types ,
        init :
        {
            id         : 'additionalType',
            key        : 'additionalType',
            label      : 'Type d\'événement',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            required   : true,
            type       : 'search',
            variant    : 'outlined'
        },
        inputProps :
        {
            label : 'Type d\'événement...'
        },
        limit  : 0,
        logics :
        {
            disabledSuggestions : ( props ) =>
            {
                const { rel } = props ;
                let suggestions = [] ;
                if( rel instanceof Thing )
                {
                    const { additionalType } = rel ;
                    if( additionalType instanceof Word )
                    {
                        suggestions.push( additionalType ) ;
                    }
                }
                return suggestions ;
            }
        }
    },
    {
        type         : Form.DATETIME ,
        defaultValue : 'today' ,
        format       : 'L - LT' ,
        init         :
        {
            id              : 'startDate',
            key             : 'startDate',
            ampm            : true,
            autoOk          : true ,
            cancelLabel     : 'Annuler' ,
            clearLabel      : 'Fermer' ,
            disablePast     : false ,
            disableFuture   : false ,
            invalidLabel    : '-',
            fullWidth       : true ,
            label           : 'Date de début' ,
            okLabel         : 'OK',
            required        : true,
            showTodayButton : true ,
            todayLabel      : 'Maintenant',
            variant         : 'outlined'
        }
    }
    ,
    {
        type         : Form.DATETIME ,
        defaultValue : null,
        format       : 'L - LT' ,
        minDate      : 'startDate' ,
        init :
        {
            id            : 'endDate',
            key           : 'endDate',
            autoOk        : true ,
            cancelLabel   : 'Annuler' ,
            clearable     : true ,
            clearLabel    : 'Vider' ,
            invalidLabel  : '-',
            disableFuture : false ,
            disablePast   : false ,
            label         : 'Date de fin' ,
            fullWidth     : true ,
            okLabel       : 'OK',
            variant       : 'outlined'
        }
    },
    {
        type       : Form.SEARCH_SELECTOR ,
        path       : api.thesaurus.events_status,
        clearLabel : 'Vider' ,
        closeLabel : 'Fermer' ,
        init       :
        {
            id         : 'eventStatus',
            key        : 'eventStatus',
            label      : 'Statut de l\'événement',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            required   : true,
            type       : 'search',
            variant    : 'outlined'
        },
        inputProps :
        {
            label : 'Statut...'
        },
        limit  : 0,
        logics :
        {
            disabledSuggestions : ( props ) =>
            {
                const { rel } = props ;
                let suggestions = [] ;
                if( rel instanceof Thing )
                {
                    const { additionalType } = rel ;
                    if( additionalType instanceof Word )
                    {
                        suggestions.push( additionalType ) ;
                    }
                }
                return suggestions ;
            }
        }
    },
    {
        type       : Form.SEARCH_SELECTOR ,
        clazz      : Place,
        path       : api.places.url ,
        clearLabel : 'Vider' ,
        closeLabel : 'Fermer' ,
        init       :
        {
            id         : 'location',
            key        : 'location',
            label      : 'Lieu de l\'événement',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            required   : true,
            type       : 'search',
            variant    : 'outlined'
        },
        inputProps :
        {
            label : 'Lieu...'
        },
        limit  : 0,
        logics :
        {
            disabledSuggestions : ( props ) =>
            {
                const { rel } = props ;
                let suggestions = [] ;
                if( rel instanceof Thing )
                {
                    const { organization } = rel ;
                    if( organization instanceof Place )
                    {
                        suggestions.push( organization ) ;
                    }
                }
                return suggestions ;
            }
        }
    }
];



const event =
{
    // -------- dependencies

    organizer,
    period,
    subEvent,
    superEvent,
    workFeatured,

    // -------- settings

    datePattern : 'L [à] LTS',
    edit :
    {
        elements,
        agree       : 'Sauver' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour modifier cet événement.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...' ,
        success     : 'Mise à jour réussie!' ,
        title       : 'Modifier l\'événement' ,
    },
    labels :
    {
        endDate     : 'Date de fin' ,
        empty       : '----' ,
        eventStatus : 'Statut de l\'événement' ,
        location    : 'Lieu de l\'événement',
        name        : 'Nom' ,
        startDate   : 'Date de début' ,
        type        : 'Type de l\'événement'
    },
    title    : 'Événement' ,
    tooltips :
    {
        add    : 'Ajouter',
        edit   : 'Modifier',
        remove : 'Supprimer'
    }
};

export default event ;
