import React from 'react'

import clsx from 'clsx'

import PropTypes from 'prop-types'

import isString from 'vegas-js-core/src/isString'
import notEmpty from 'vegas-js-core/src/strings/notEmpty'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import { Link } from 'react-router-dom'

import { IconButton, Tooltip, Typography } from '@material-ui/core'

import red from '@material-ui/core/colors/red'

import DeleteIcon from '@material-ui/icons/Delete'

import PlaceContainer from '../../things/Place'
import ThingAvatar    from '../../avatars/ThingAvatar'

import Place from '../../../things/Place'
import Word  from '../../../things/Word'

import getImageSource      from '../../../things/getImageSource'
import getLocaleThingLabel from '../../../things/getLocaleThingLabel'

import CoreDialog from '../../../components/dialogs/CoreDialog'

import initDialog from '../initDialog'

import api from '../../../configs/api'

const styles = () => (
{
    content :
    {
        padding : 16
    }
});

class PlaceDialog extends CoreDialog
{
    getCurrentPath = () => this.props.uri || this.props.location.pathname ;

    getLocale = () => this.props.locale.dialogs.things.place;

    agree = () =>
    {
        this.close() ;
        const { item:place } = this.props ;
        if( place instanceof Place )
        {
            const { backPath, history } = this.props ;
            const { url } = place ;
            if( notEmpty( url ) )
            {
                 history.push({ pathname : url.split(api.url)[1] , state: { backPath } } ) ;
            }
        }
    };

    getContent = () =>
    {
        const { item } = this.props ;
        if( item instanceof Place )
        {
            return (
            <PlaceContainer
                containerProps = {{ className : "bg-transparent w-full shadow-none p-8" }}
                header         = { false }
                thing          = { item }
            />);
        }
        return null ;
    };

    getIcon = () =>
    {
        const { item } = this.props;
        if( item instanceof Place )
        {
            return (
                <ThingAvatar
                    thing     = { item }
                    className = 'border-3 border-white w-60 h-60 mr-16'
                />
            );
        }
        let { icon, useIcon } = this.props ;
        if( useIcon )
        {
            if( icon instanceof Function )
            {
                icon = icon( this.props ) ;
            }
            return icon ;
        }
        return null ;
    };

    getOptions = () =>
    {
        const {
            deletable,
            onRemove,
            item
        } = this.props ;

        const locale = this.getLocale() ;

        if( deletable && this.isEditable() && (onRemove instanceof Function) )
        {
            let deleteButton = (
            <IconButton
                onClick = { () => { onRemove( item ) ; }}
            >
                <DeleteIcon style={{ color:red[600] }}/>
            </IconButton>);

            if( locale )
            {
                const { tooltips } = locale ;
                if( tooltips )
                {
                    const { remove } = tooltips ;
                    if( isString(remove) )
                    {
                        deleteButton = (
                            <Tooltip title={remove} placement='top'>
                                { deleteButton }
                            </Tooltip>
                        )
                    }
                }
            }

            return deleteButton ;
        }

        return null ;
    };

    getTitleContent = () =>
    {
        const {
            item:place,
            lang,
        } = this.props;

        let pathname ;
        let subtitle ;
        let title ;
        let titleStyle ;

        if( place instanceof Place )
        {
            const {
                additionalType,
                url
            } = place ;

            title = getLocaleThingLabel(place,lang) ;

            if( additionalType instanceof Word  )
            {
                subtitle = additionalType.getLocaleName(lang) ;
            }

            if( notEmpty( url ) )
            {
                 pathname = url.split(api.url)[1] ;
            }
        }

        if( !isString(title) || title === '' )
        {
            title = this.getTitleLabel() ;
        }

        if( isString(title) && title !== '' )
        {
            title = (
                <Typography
                    className = { clsx( 'font-medium w-full cursor-pointer no-underline truncate pr-8' , titleStyle && 'text-shadow-lg text-white' ) }
                    color     = 'secondary'
                    component = { Link }
                    onClick   = { this.disagree }
                    to        = {{ pathname }}
                    variant   = 'subtitle1'
                >
                    { ucFirst(title) }
                </Typography>
            )
        }
        else
        {
            title = null ;
        }

        if( isString(subtitle) && subtitle !== '' )
        {
            subtitle = (
                <Typography
                    className = { clsx( 'font-normal w-full no-underline' , titleStyle && 'text-shadow-lg text-white' ) }
                    variant   = 'caption'
                >
                    { ucFirst(subtitle) }
                </Typography>
            )
        }
        else
        {
            subtitle = null ;
        }

        return (
            <div className='flex flex-col flex-1'>
                { title }
                { subtitle }
            </div>
        );
    };

    getTitleStyle = () =>
    {
        const { item } = this.props;
        if( item instanceof Place )
        {
            const src = getImageSource(item) ;
            if ( notEmpty(src) )
            {
                return ({
                    backgroundSize   : 'cover' ,
                    backgroundImage  : `url(${src})` ,
                    backgroundRepeat : 'no-repeat' ,
                    padding          : 24,
                    minHeight        : 100
                });
            }
        }
        return null ;
    };
}

PlaceDialog.defaultProps =
{
    ...CoreDialog.defaultProps,
    deletable : false ,
    maxWidth  : 'md' ,
    onRemove  : null
};

PlaceDialog.propTypes =
{
    ...CoreDialog.propTypes,
    deletable : PropTypes.bool,
    onRemove  : PropTypes.func
};

export default initDialog({ styles })(PlaceDialog) ;
