import React from 'react'

import format from 'vegas-js-core/src/strings/fastformat'

import { Typography } from '@material-ui/core'

import api from '../../../../configs/api'

import Form from '../../../../display/forms/FormElements'

import Livestock   from '../../../../things/Livestock'
import NumberValue from '../../../../things/NumberValue'
import Workshop    from '../../../../things/Workshop'
import Thing       from '../../../../things/Thing'

import ucFirst from 'vegas-js-core/src/strings/ucFirst'

const elements =
[
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'name',
            key          : 'name',
            label        : 'Nom de l\'atelier',
            autoFocus    : true,
            fullWidth    : true,
            helperText   : 'Obligatoire',
            margin       : 'normal',
            required     : true,
            select       : false,
            autoComplete : 'off' ,
            type         : 'search',
            variant      : 'outlined'
        }
    },
    {
        type       : Form.SEARCH_SELECTOR ,
        clazz      : NumberValue,
        clearLabel : 'Vider' ,
        closeLabel : 'Fermer' ,
        init       :
        {
            id        : 'identifier',
            key       : 'identifier',
            label     : 'Identifiant de l\'élevage',
            autoFocus : false,
            fullWidth : true,
            margin    : 'normal',
            required  : true,
            type      : 'search',
            variant   : 'outlined'
        },
        inputProps :
        {
            label : 'Identifiant de l\'atelier...'
        },
        path : props =>
        {
            const { rel } = props ;
            if( rel instanceof Workshop )
            {
                if( rel.subjectOf instanceof Livestock)
                {
                    return format( api.livestocks.numbers , rel.subjectOf.id ) ;
                }
            }
            return null ;
        },
        suggestionLabel : ( suggestion , props ) =>
        {
            const { lang } = props ;
            const name = (suggestion instanceof Thing ? ucFirst(suggestion.getLocaleName(lang)) : "") ;
            if( suggestion instanceof NumberValue )
            {
                const { additionalType, value } = suggestion ;

                let type ;
                if( additionalType instanceof Thing )
                {
                    type = <Typography className="mr-8" variant='caption'>{additionalType.getLocaleName(lang)}</Typography> ;
                }

                return (
                    <div className='flex flex-col py-12'>
                        <div className='flex flex-row items-center'>
                            <Typography className="mr-8" variant='body1'>{ name }</Typography>
                        </div>
                        <div className='flex flex-row items-center'>
                            { type }
                            <Typography className="mr-8" variant='caption'>›</Typography>
                            <Typography className="mr-8" variant='caption'>{value}</Typography>
                        </div>
                    </div>
                ) ;
            }
            return name ;
        },
        valueLabel : ( thing , props ) =>
        {
            const { lang } = props ;
            let name = (thing instanceof Thing ? ucFirst(thing.getLocaleName(lang)) : "") ;
            if( thing instanceof NumberValue )
            {
                if( name !== "" )
                {
                    name += ' › ';
                }
                if( thing.additionalType && thing.additionalType instanceof Thing )
                {
                    name += thing.additionalType.getLocaleName(lang) ;
                    name += ' › ' ;
                }
                name += thing.value ;
            }
            return name ;
        }
    },
    {
        type       : Form.SEARCH_SELECTOR ,
        clearLabel : 'Vider' ,
        closeLabel : 'Fermer' ,
        init       :
        {
            id        : 'breeding',
            key       : 'breeding',
            label     : 'Type de l\'élevage',
            autoFocus : false,
            fullWidth : true,
            margin    : 'normal',
            required  : true,
            type      : 'search',
            variant   : 'outlined'
        },
        inputProps :
        {
            label : 'Type de l\'élevage de l\'atelier...'
        },
        logics :
        {
            change : ( props /*, state */ ) =>
            {
                const { rel } = props ;
                if( rel instanceof Workshop )
                {
                    rel.production = null ;
                }
            }
        },
        path : api.thesaurus.breedings_types
    },
    {
        type       : Form.SEARCH_SELECTOR ,
        clearLabel : 'Vider' ,
        logics     :
        {
            clearable : ( props , state ) =>
            {
                const { rel } = props ;
                if( rel instanceof Workshop )
                {
                    const { item } = state ;
                    return item && ((!rel.breeding) || (!rel.production))  ;
                }
                return false ;
            },
            disable : ( props /*, state */ ) =>
            {
                const { rel } = props ;
                if( rel instanceof Workshop )
                {
                    if( rel.breeding instanceof Thing)
                    {
                        return false ;
                    }
                }
                return true ;
            }
        },
        closeLabel      : 'Fermer' ,
        dropSuggestions : true ,
        init            :
        {
            id        : 'production',
            key       : 'production',
            label     : 'Type de production',
            autoFocus : false,
            fullWidth : true,
            margin    : 'normal',
            required  : true,
            type      : 'search',
            variant   : 'outlined'
        },
        inputProps :
        {
            label : 'Type de production de l\'atelier...'
        },
        path : props =>
        {
            const { rel } = props ;
            console.log( 'workshops -> element::production rel:' , rel ) ;
            if( rel instanceof Workshop )
            {
                const { breeding } = rel ;
                if( breeding instanceof Thing)
                {
                    return format( api.settings.productionsTypesByBreedingsTypes, breeding.id ) ;
                }
            }
            else
            {
                console.log( 'The workshops \'production\' form definition failed with a null or undefined \'rel\' reference.' ) ;
            }
            return null ;
        }
    },
    {
        type       : Form.SEARCH_SELECTOR ,
        path       : api.thesaurus.water_origins ,
        clearLabel : 'Vider' ,
        closeLabel : 'Fermer' ,
        init  :
        {
            id        : 'water',
            key       : 'water',
            label     : 'Origine de l\'eau',
            autoFocus : false,
            fullWidth : true,
            margin    : 'normal',
            required  : false,
            type      : 'search',
            variant   : 'outlined'
        },
        inputProps :
        {
            label : 'Origine de l\'eau de l\'atelier...'
        }
    }
];

const workshops =
{
    add :
    {
        elements ,
        agree       : 'Ajouter' ,
        disagree    : 'Annuler' ,
        title       : 'Ajouter un atelier' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour créer un atelier.' ,
        fail        : 'Veuillez corriger les erreurs !' ,
        loading     : 'Chargement en  cours...' ,
        success     : 'Ajout du nouvel atelier réussi!' ,
    },
    cell :
    {
        breeding   : 'Élevage :' ,
        production : 'Production :',
        water      : 'Origine de l\'eau :'
    } ,
    fail       : 'Données non trouvées...' ,
    noDataText : 'Aucun résultat' ,
    remove :
    {
        agree        : 'Supprimer' ,
        description  : 'Souhaitez-vous supprimer cet atelier:' ,
        descriptionX : 'Souhaitez-vous supprimer les {0} ateliers:' ,
        disagree     : 'Annuler' ,
        loading      : 'Suppression en cours...' ,
        title        : 'Supprimer l\'atelier ?',
        titleX       : 'Supprimer des ateliers ?'
    },
    title    : 'Ateliers' ,
    tooltips :
    {
        add    : "Ajouter" ,
        remove : "Supprimer"
    }
};

export default workshops ;
