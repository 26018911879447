import BadgeItem    from './game/BadgeItem'
import CreativeWork from './CreativeWork'
import Word         from './Word'

import generify from './generify'

class Game extends CreativeWork
{
    /**
     * Creates a new Game instance.
     * @constructor
     * @param object The generic object to initialize the object the first time.
     */
    constructor( object = null )
    {
        super();

        this.characterAttribute = null ;

        this.item = null ;

        this.location = null ;

        this.numberOfPlayers = null ;

        this.quest = null ;

        this.set( object ) ;
    }

    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        super.clear() ;
        this.characterAttribute =
        this.item               =
        this.location           =
        this.numberOfPlayers    =
        this.quest              = null ;
        return this ;
    }

    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new Game( this.toObject() ) ;
    }

    /**
     * Populate the object with specific behaviors or types. Launched by default in the constructor and in the set methods.
     * @returns {Thing} The current reference.
     */
    populate()
    {
        const {
            additionalType,
            item
        } = this ;

        if( additionalType && !(additionalType instanceof Word))
        {
            this.additionalType = new Word(additionalType) ;
        }

        if( item && !(item instanceof BadgeItem))
        {
            this.item = new BadgeItem(item) ;
        }

        return this ;
    }

    /**
     * Returns the generic object representation of the item.
     * @return {Object} The generic object representation of the item.
     */
    toObject()
    {
        let {
            about,
            additionalType,
            characterAttribute,
            item,
            location,
            numberOfPlayers,
            quest
        } = this ;

        additionalType = generify(additionalType) ;
        item           = generify(item) ;

        return { ...super.toObject() , ...{
            about,
            additionalType,
            characterAttribute,
            item,
            location,
            numberOfPlayers,
            quest
        }}
    }
}

export default Game ;