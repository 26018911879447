import React from 'react'

import compose from 'vegas-js-core/src/functors/compose'

import initApp from '../../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import CategoryIcon from '@material-ui/icons/Category'

import PropertyValue    from '../../../things/PropertyValue'
import ConceptualObject from '../../../things/ConceptualObject'

import ThingChildren, { styles } from '../ThingChildren'

class WorkFeatured extends ThingChildren
{
    getLocale = () => this.props.locale.things.event.workFeatured ;
}

WorkFeatured.defaultProps =
{
    ...ThingChildren.defaultProps ,
    clazz      : ConceptualObject,
    emptyClazz : PropertyValue,
    emptyIcon  : null ,
    icon       : <CategoryIcon/> ,
    member     : 'workFeatured'
};

WorkFeatured.propTypes =
{
    ...ThingChildren.propTypes
};

export default compose( withStyles( styles ) , withRouter , initApp )( WorkFeatured ) ;
