import React from 'react'

import PropTypes from 'prop-types'

import clsx from 'clsx'

import isString from 'vegas-js-core/src/isString'
import format   from 'vegas-js-core/src/strings/fastformat'
import notEmpty from 'vegas-js-core/src/strings/notEmpty'

import { Avatar, Tooltip } from '@material-ui/core'

import WordIcon from '@material-ui/icons/FiberManualRecord'

import ThingComponent from '../things/ThingComponent'

import getLocaleThingLabel from '../../things/getLocaleThingLabel'
import Word                from '../../things/Word'

import withi18n  from '../../contexts/i18n/withi18n'
import withSnack from '../../contexts/snack/withSnack'

const defaultBackgroundColor = 'ffffff' ;
const defaultColor           = '666666' ;

export class WordLabel extends ThingComponent
{
    getIcon = ( { className = null , label = null, word = null , style = null } ) =>
    {
        const { iconifiable } = this.props;
        if ( iconifiable )
        {
            const {
                clickable,
                copiable,
                disabled,
                icon,
                iconProps,
                imageable,
                onClick
            } = this.props;

            const locale = this.getLocale();

            let { image } = word ;
            if( imageable && isString(image) )
            {
                const { color = defaultColor } = word || {} ;
                image = image + "?color=" + color + "w=32&h=32" ;
                image = (
                    <img
                        width     = { 16 }
                        height    = { 16 }
                        alt       = { label || '' }
                        loading   = 'lazy'
                        src       = { image }
                    />
                );
            }
            else
            {
                image = null ;
            }

            let { bgcolor , color } = word || {} ;

            if( !isString(bgcolor) || bgcolor === "" )
            {
                bgcolor = defaultBackgroundColor ;
            }

            if( !isString(color) || color === "" )
            {
                color = defaultColor ;
            }

            let avatar = (
                <Avatar
                    className = { clsx( 'bg-gray-200 mx-4 w-32 h-32 shadow' , (clickable && !disabled) ? 'cursor-pointer' : '' , className ) }
                    color     = 'secondary'
                    imgProps  = {{ objectFit : "none" , width:32 , height:32 , loading:'lazy' }}
                    size      = 'small'
                    style     = {{ backgroundColor: "#" + bgcolor , color: "#" + color , ...style }}
                    onClick   = { () =>
                    {
                        if( clickable && !disabled )
                        {
                            if( notEmpty(label) )
                            {
                                const { copy : { snack } = {} } = locale ;
                                this.copy( label , snack ) ;
                            }
                            if ( onClick instanceof Function )
                            {
                                onClick( this.getThing() , word, label ) ;
                            }
                        }
                    }}
                    { ...iconProps }
                >
                    { image || icon }
                </Avatar>
            );

            if ( clickable && !disabled && copiable )
            {
                const { copy : { tooltip } = {} } = locale ;
                if( isString(tooltip) )
                {
                    avatar =
                    (
                        <Tooltip placement='top' title={tooltip}>
                            { avatar }
                        </Tooltip>
                    ) ;
                }
            }

            return avatar ;
        }
        return null;
    }

    getLocale = () => this.props.locale.display.labels.word;

    render = () =>
    {
        let word ;
        const thing = this.getThing();
        if ( thing instanceof Word )
        {
            word = thing ;
        }
        else if( thing )
        {
            const { member } = this.props ;
            if( isString( member ) && (thing[member] instanceof Word) )
            {
                word = thing[member] ;
            }
        }

        if( word instanceof Word )
        {
            const { lang , upperFirst } = this.props;
            const locale = this.getLocale() ;

            const label  = format( locale.label, getLocaleThingLabel(word, lang, upperFirst) )  ;
            if (notEmpty(label) )
            {
                const { className } = this.props;
                return (
                    <div className={ clsx("flex flex-row items-center p-8", className)} >
                        <div className="flex-initial">{ this.getIcon({ label, word }) } </div>
                        <div className="flex flex-col pl-8">
                            { this.getTitle() }
                            { this.getLabel(label) }
                        </div>
                    </div>
                );
            }
        }

        return null;
    }
}

WordLabel.defaultProps =
{
    ...ThingComponent.defaultProps,
    clickable  : false ,
    copiable   : false,
    icon       : <WordIcon fontSize='small' />,
    iconProps  : { variant:'rounded' },
    imageable  : false,
    member     : null ,
    upperFirst : true
}

WordLabel.propTypes =
{
    ...ThingComponent.propTypes,
    imageable  : PropTypes.bool,
    member     : PropTypes.string ,
    upperFirst : PropTypes.bool
}

export default withSnack( withi18n( WordLabel ) );
