import application       from './application'
import applications      from './applications'
import article           from './article'
import articles          from './articles'
import conceptualObject  from './conceptualObject'
import conceptualObjects from './conceptualObjects'
import auth              from './auth'
import course            from './course'
import courses           from './courses'
import event             from './event'
import events            from './events'
import game              from './game'
import games             from './games'
import home              from './home'
import livestock         from './livestock'
import livestocks        from './livestocks'
import me                from './account'
import medias            from './medias'
import mediaObjects      from './mediaObjects'
import medical           from './medical'
import observation       from './observation'
import organization      from './organization'
import organizations     from './organizations'
import people            from './people'
import person            from './person'
import place             from './place'
import places            from './places'
import sectors           from './sectors'
import set               from './set'
import stage             from './stage'
import stages            from './stages'
import step              from './step'
import support           from './support'
import team              from './team'
import teams             from './teams'
import technician        from './technician'
import technicians       from './technicians'
import thesaurus         from './thesaurus'
import topics            from './topics'
import veterinarian      from './veterinarian'
import veterinarians     from './veterinarians'
import user              from './user'
import users             from './users'
import welcome           from './welcome'
import workplaces        from './workplaces'
import workshop          from './workshop'

const views =
{
    application       ,
    applications      ,
    article           ,
    articles          ,
    conceptualObject  ,
    conceptualObjects ,
    auth              ,
    course            ,
    courses           ,
    game              ,
    games             ,
    event             ,
    events            ,
    home              ,
    livestock         ,
    livestocks        ,
    me                ,
    medias            ,
    mediaObjects      ,
    medical           ,
    observation       ,
    organization      ,
    organizations     ,
    people            ,
    person            ,
    place             ,
    places            ,
    sectors           ,
    set               ,
    stage             ,
    stages            ,
    step              ,
    support           ,
    team              ,
    teams             ,
    technician        ,
    technicians       ,
    thesaurus         ,
    topics            ,
    user              ,
    users             ,
    veterinarian      ,
    veterinarians     ,
    welcome           ,
    workplaces        ,
    workshop
};

export default views ;
