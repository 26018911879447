import React from 'react'

import clsx from 'clsx'

import PropTypes from 'prop-types'

import notEmpty from 'vegas-js-core/src/strings/notEmpty'

import { withRouter } from 'react-router-dom'

import LinkIcon from '@material-ui/icons/Link'

import withi18n from '../../contexts/i18n/withi18n'

import getLocaleThingLabel from '../../things/getLocaleThingLabel'

import ThingComponent from '../things/ThingComponent'

export class ThingLabel extends ThingComponent
{     
    getLocale = () => this.props.locale.display.labels.thing;
    
    getLocaleLabel = thing =>
    {
        if( thing )
        {
            const { lang } = this.props ;
            return getLocaleThingLabel( thing , lang ) ;
        }
        return null ;
    }
    
    onClick = () => 
    { 
        const url = this.getUrl()  ;
        this.gotoUrl( url ) ; 
    }
    
    render = () =>
    {
        const thing = this.getThing();
        if ( thing ) 
        {
            const label = this.getLocaleLabel( thing ) ;
            if ( notEmpty(label) ) 
            {
                const { className } = this.props;
                return (
                    <div className={clsx("flex flex-row items-center p-8", className)} >
                        <div className="flex-initial">{ this.getIcon({ label }) } </div>
                        <div className="flex flex-col pl-8">
                            { this.getTitle() }
                            { this.getLabel(label) }
                        </div>
                    </div>
                );
            }
        }
        return null;
    }
}

ThingLabel.defaultProps =
{
    ...ThingComponent.defaultProps,
    clickable     : true ,
    copiable      : false ,
    icon          : <LinkIcon size='small'/>,
    iconLinkable  : true ,
    labelLinkable : true ,
    upperFirst    : true
};

ThingLabel.propTypes =
{
    ...ThingComponent.propTypes,
    upperFirst : PropTypes.bool
};

export default withRouter(withi18n(ThingLabel)) ;
