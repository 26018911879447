import AmbuloSetting  from '../display/things/application/setting/AmbuloSetting'
import SupportSetting from '../display/things/application/setting/SupportSetting'

const application =
{
    settings :
    {
        ambulo  : AmbuloSetting,
        support : SupportSetting
    }
};

export default application ;
