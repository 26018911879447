import moment from 'moment';
import React  from 'react'

import compose   from 'vegas-js-core/src/functors/compose'
import isString  from 'vegas-js-core/src/isString'

import initApp from '../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import LocalOfferIcon from '@material-ui/icons/LocalOffer'

import Thing from '../../things/Thing'

import Offer from './Offer'
import ThingChildren , { styles } from './ThingChildren'

import OfferVO from '../../things/Offer'

class Offers extends ThingChildren
{
    createChild = ( child , editable = false ) =>
    {
        return (
        <Offer
            editable = { editable }
            thing    = { child }
            onClick  = { () => this.openEditDialog(child) }
        />
        );
    };

    getLocale = () => this.props.locale.things.offers ;

    prepare = item =>
    {
        let {
            alternateName,
            category,
            description,
            price,
            validFrom,
            validThrough
        } = item ;

        const { config } = this.props ;
        if( config )
        {
            const { languages } = config ;
            if( languages instanceof Array && languages.length > 0 )
            {
                if( alternateName )
                {
                    languages.forEach( lang =>
                    {
                        if( !isString(alternateName[lang]) )
                        {
                            alternateName[lang] = '' ;
                        }
                    });
                }

                if( description )
                {
                    languages.forEach( lang =>
                    {
                        if( !isString(description[lang]) )
                        {
                            description[lang] = '' ;
                        }
                    });
                }
            }
        }

        if( price !== null )
        {
            price += '' ;
        }

        return (
        {
            alternateName ,
            category : category instanceof Thing ? String(category.id) : null ,
            description ,
            price,
            validFrom    : validFrom instanceof Date ? moment(validFrom).format( 'YYYY-MM-DD' ) : null ,
            validThrough : validThrough instanceof Date ? moment(validThrough).format( 'YYYY-MM-DD' ) : null
        });
    };
}

Offers.defaultProps =
{
    ...ThingChildren.defaultProps,
    clazz     : OfferVO,
    emptyIcon : null ,
    icon      : <LocalOfferIcon/>,
    member    : 'offers'
};

Offers.propTypes =
{
    ...ThingChildren.propTypes
};

export default compose( withStyles( styles ) , withRouter , initApp )( Offers ) ;
