import React from "react"

import notEmpty from 'vegas-js-core/src/strings/notEmpty'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import DiscoverIcon from '@material-ui/icons/Book'
import InfoIcon     from '@material-ui/icons/Info'
import MediasIcon   from '@material-ui/icons/PermMedia'
import StageIcon    from '@material-ui/icons/Stars'

import ThingView , { defaultStyle } from '../../display/views/ThingView'

import About    from './views/About'
import Discover from './views/Discover'
import Medias   from '../medias/Medias'
import More     from './views/More'

import StageVO  from '../../things/Stage'

import api   from '../../configs/api'
import paths from '../../configs/paths'

import initView from '../../display/views/initView'

const styles = defaultStyle();

class Stage extends ThingView
{
    getLocale = () => this.props.locale.views.stage ;

    getSubtitle = thing =>
    {
        if( thing instanceof StageVO )
        {
            let alternativeHeadline = thing.getLocaleAlternativeHeadline( this.props.lang ) ;
            if( notEmpty( alternativeHeadline ) )
            {
                return ucFirst( alternativeHeadline )  ;
            }
        }
        return null ;
    };

    getTitle = thing =>
    {
        if( thing instanceof StageVO )
        {
            const title = thing.getLocaleTitle( this.props.lang ) ;
            if( notEmpty( title ) )
            {
                return ucFirst( title )  ;
            }
        }
        return null ;
    };
}

Stage.defaultProps =
{
    ...ThingView.defaultProps,
    back       : true ,
    clazz      : StageVO ,
    icon       : <StageIcon/>,
    path       : api.stages ,
    responsive : true ,
    preferable : true ,
    routes     :
    [
        { id : 'about'    , RouteComponent : About    } ,
        { id : 'info'     , RouteComponent : More     } ,
        { id : 'discover' , RouteComponent : Discover } ,
        { id : 'medias'   , RouteComponent : Medias   }
    ],
    tabs :
    [
        { id : 'about'    , icon : <StageIcon    className='pr-8' /> } ,
        { id : 'info'     , icon : <InfoIcon     className='pr-8' /> } ,
        { id : 'discover' , icon : <DiscoverIcon className='pr-8' /> } ,
        { id : 'medias'   , icon : <MediasIcon   className='pr-8' /> }
    ],
    titleAuto : false
};

Stage.propTypes =
{
    ...ThingView.propTypes,
    path : paths.stages
};

export default initView( Stage, styles );
