import notEmpty from 'vegas-js-core/src/strings/notEmpty'

import Thing from '../things/Thing'

import clazzFactory from './clazzFactory'

const createByUrl = ( item , defaultClazz = null ) =>
{
    if( item )
    {
        const { url } = item ;
        if( notEmpty(url) )
        {
            for( let uri in clazzFactory )
            {
                if( url.indexOf( uri ) === 0 )
                {
                    const clazz = clazzFactory[uri] ;
                    if( clazz instanceof Function )
                    {
                        return new clazz(item) ;
                    }
                }
            }
        }
        return defaultClazz instanceof Function ? new defaultClazz(item) : new Thing(item) ;
    }
    return null ;
};

export default createByUrl ;