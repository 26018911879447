import initDialog from '../initDialog'

import { RemoveDialog } from '../RemoveDialog'

import api from '../../../configs/api'

const styles = () => ({});

class RemoveDiseaseDialog extends RemoveDialog
{
    getLocale = () => this.props.locale.dialogs.application.disease.remove ;
}

RemoveDiseaseDialog.defaultProps =
{
    ...RemoveDialog.defaultProps,
    uri    : api.diseases.url,
    useUrl : true
};

RemoveDiseaseDialog.propTypes =
{
    ...RemoveDialog.propTypes
};

export default initDialog({ styles })( RemoveDiseaseDialog );
