import React , { Fragment } from 'react'

import PropTypes from 'prop-types'

import isString from 'vegas-js-core/src/isString'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import { Link } from 'react-router-dom'

import { DialogTitle, Divider, IconButton, Tooltip, Typography } from '@material-ui/core'

import grey from '@material-ui/core/colors/grey'
import red  from '@material-ui/core/colors/red'

import DeleteIcon from '@material-ui/icons/Delete'

import EventContainer from '../../things/Event'
import ThingAvatar    from '../../avatars/ThingAvatar'

import Event from '../../../things/Event'

import CoreDialog from '../../../components/dialogs/CoreDialog'

import initDialog from '../initDialog'

import api from '../../../configs/api'

const styles = () => (
{
    content :
    {
        padding : 16
    }
});

class EventDialog extends CoreDialog
{
    getLocale = () => this.props.locale.dialogs.things.event;

    agree = () =>
    {
        this.close() ;
        const { item:event } = this.props ;
        if( event instanceof Event )
        {
            const { url } = event ;
            const { history } = this.props ;
            history.push( url.split(api.url)[1] ) ;
        }
    };

    getContent = () =>
    {
        const { lang, item:event } = this.props ;
        if( event instanceof Event )
        {
            let { description } = event ;
            if( description )
            {
                description = event.getLocaleDescription(lang) ;
                if( isString( description ) && description !== '' )
                {
                    description = (
                        <Typography
                            className = 'w-full mb-8 p-8'
                            style     = {{ color:grey[600] }}
                            variant   = 'body2'
                        >
                            { description }
                        </Typography>
                    );
                }
                else
                {
                    description = null ;
                }
            }
            return (
            <Fragment>
                { description }
                <EventContainer
                    containerProps = {{ className : "bg-transparent w-full shadow-none p-8" }}
                    header         = { false }
                    thing          = { event }
                />
            </Fragment>);
        }
        return null ;
    };

    getOptions = () =>
    {
        const {
            deletable,
            onRemove,
            item
        } = this.props ;

        const locale = this.getLocale() ;

        if( deletable && this.isEditable() && (onRemove instanceof Function) )
        {
            let deleteButton = (
            <IconButton
                onClick = { () => { onRemove( item ) ; }}
            >
                <DeleteIcon style={{ color:red[600] }}/>
            </IconButton>);

            if( locale )
            {
                const { tooltips } = locale ;
                if( tooltips )
                {
                    const { remove } = tooltips ;
                    if( isString(remove) )
                    {
                        deleteButton = (
                            <Tooltip title={remove} placement='top'>
                                { deleteButton }
                            </Tooltip>
                        )
                    }
                }
            }

            return deleteButton ;
        }

        return null ;
    };

    getTitle = () =>
    {
        const {
            divider,
            item:event,
            lang,
            options
        } = this.props;

        let pathname ;
        let icon ;
        let name ;
        let title ;
        let subtitle ;

        if( event instanceof Event )
        {
            const { url } = event ;
            name     = event.getLocaleName(lang) ;
            title    = event.getLocaleHeadline(lang) ;
            subtitle = event.getLocaleAlternativeHeadline(lang) ;

            icon = <ThingAvatar thing={event} className="mr-12" />;

            pathname = url.split(api.url)[1];
        }

        if( !icon )
        {
            icon = this.getIcon() ;
        }

        if( !isString(title) || title === '' )
        {
            title = name ;
        }

        if( !isString(title) || title === '' )
        {
            title = this.getTitleLabel() ;
        }

        if( isString(title) && title !== '' )
        {
            title = (
            <Typography
                className = 'font-medium w-full cursor-pointer no-underline truncate'
                color     = 'secondary'
                component = { Link }
                onClick   = { this.disagree }
                to        = {{ pathname }}
                variant   = 'subtitle1'
            >
                { ucFirst(title) }
            </Typography>
            )
        }
        else
        {
            title = null ;
        }

        if( isString(subtitle) && subtitle !== '' )
        {
            subtitle = (
            <Typography
                className = 'font-normal w-full no-underline'
                variant   = 'caption'
            >
                { ucFirst(subtitle) }
            </Typography>
            )
        }
        else
        {
            subtitle = null ;
        }

        return (
        <Fragment>
            <DialogTitle className='w-full items-center justify-between'>
                <div className="w-full flex flex-row items-center">
                    { icon }
                    <div className='flex flex-col w-full'>
                        { title }
                        { subtitle }
                    </div>
                    { options || this.getOptions() }
                </div>
            </DialogTitle>
            { divider && <Divider light/> }
        </Fragment>
        );
    }
}

EventDialog.defaultProps =
{
    ...CoreDialog.defaultProps,
    deletable : true ,
    maxWidth  : 'md' ,
    onRemove  : null
};

EventDialog.propTypes =
{
    ...CoreDialog.propTypes,
    deletable : PropTypes.bool,
    onRemove  : PropTypes.func
};

export default initDialog({ styles , withTheme:true })(EventDialog) ;
