const simpleView =
{
    snacks :
    {
        favorite :
        {
            add    : 'Cette ressource est ajoutée dans vos favoris',
            remove : 'Cette ressource est supprimée de vos favoris'
        }
    },
    tooltips :
    {
        favorite   : 'Ajouter aux favoris' ,
        noFavorite : 'Supprimer des favoris'
    }
};

export default simpleView ;
