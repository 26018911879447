import React from 'react'

import withDatasList from './withDatasList'

import HealingIcon from '@material-ui/icons/Healing'

import ThingsList from './ThingsList'

import Disease from '../../things/medical/Disease'

import AddDiseaseDialog    from '../dialogs/add/AddDiseaseDialog'
import RemoveDiseaseDialog from '../dialogs/remove/RemoveDiseaseDialog'

import DiseaseCell from '../cells/DiseaseCell'

class DiseasesList extends ThingsList
{
    getLocale = () => this.props.locale.display.lists.diseases ;
}

DiseasesList.defaultProps =
{
    ...ThingsList.defaultProps ,
    AddDialogComponent    : AddDiseaseDialog,
    CellComponent         : DiseaseCell,
    cellProps             : { variant : 'default' },
    clazz                 : Disease,
    contentTransition     : false,
    defaultIcon           : <HealingIcon/>,
    icon                  : <HealingIcon/>,
    preferable            : false ,
    queries               : { sort : '-modified' },
    RemoveDialogComponent : RemoveDiseaseDialog,
    sortable              : true
};

DiseasesList.propTypes =
{
    ...ThingsList.propTypes
};

export default withDatasList()( DiseasesList ) ;
