import axios from 'axios/index'

import tokensManager from "../../vo/TokensManager"
import auth        from "../../configs/auth"

import { v4 as uuid } from 'uuid'

import connect from './connect'

import GrantType from './GrantType'

const timeout = process.env.REACT_APP_UPLOAD_TIMEOUT ;

const DEFAULT_OPTIONS =
{
    accessToken : undefined ,
    api         : null ,
    cancel      : null ,
    canceler    : null ,
    code        : null ,
    fail        : null ,
    success     : null ,
    timeout     : timeout
};

/**
 * Connect the application with api.
 * @param {Null|object} [options=null] - The configuration object to set the request.
 * @param {Object} [options.accessToken=null] - The optional accessToken object.
 * @param {Object} [options.api=null] - The optional api object.
 * @param {Function} [options.cancel=null] - A callback method invoked when the service is canceled.
 * @param {CancelTokenSource} [options.canceler=null] - The canceler of the request.
 * @param {Object} [options.datas=null] - The optional object to send datas to the API method.
 * @param {Function} [options.fail=null] - A callback function invoked when the request failed.
 * @param {Function} [options.success] - A callback function invoked when the request succeed.
 * @param {int} [options.timeout=18000] - Indicates the delay in ms to timeout the request.
 */
export default function getAccessToken( options )
{
    const params = { ...DEFAULT_OPTIONS , ...( !!(options) && options ) } ;

    let {
        accessToken : { refresh_token = null } = {} ,
        api,
        cancel,
        canceler,
        code,
        fail,
        success,
        timeout
    }
    = params ;

    if( canceler === null )
    {
        canceler = axios.CancelToken.source() ;
    }

    if( api === null )
    {
        api = auth.apis['api'] ;
        if( !api )
        {
            if( fail instanceof Function)
            {
                fail( 'getAccessToken() failed, the api definition is not found!' );
            }
            return canceler ;
        }
    }

    const state = uuid() ;

    let variables =
    {
        client_id     : api.client_id ,
        client_secret : api.client_secret,
        state         : state
    };

    if( api.grant_type === GrantType.AUTHORIZATION_CODE )
    {

        if( code === null )
        {
            if( refresh_token === null )
            {
                console.log('getAccessToken no refresh token, reconnect');
                connect( api.name ) ;
            }
            else
            {
                // from refresh token
                console.log('getAccessToken from refresh token');
                variables = {
                    ...variables ,
                    grant_type : GrantType.REFRESH_TOKEN ,
                    refresh_token
                } ;
            }
        }
        else
        {
            // from code
            console.log('getAccessToken from code');
            variables = {
                ...variables ,
                code       : code ,
                grant_type : GrantType.AUTHORIZATION_CODE
            } ;
        }
    }
    else if( api.grant_type === GrantType.CLIENT_CREDENTIALS )
    {
        variables = {
            ...variables ,
            grant_type : GrantType.CLIENT_CREDENTIALS
        } ;
    }
    else
    {
        if( fail instanceof Function)
        {
            fail( 'getAccessToken() failed, the api.grant_type undefined' );
        }
        return canceler ;
    }

    const request = axios.create(
    {
        cancelToken : canceler.token ,
        headers : { 'Accept' : 'application/json' },
        timeout
    });

    request
    .post( api.token_url , variables )
    .then( response =>
    {
        const { data, status } = response ;

        console.log( 'getAccessToken status ' + status ) ;

        if( status === 200 )
        {
            if( data && !data.state && (data.state !== state))
            {
                fail( { data : { code : 401 , message : 'Invalid state token' } } );
                return ;
            }

            tokensManager.set( api.name , data ) ;

            if( success instanceof Function)
            {
                success( response );
            }
        }
        else
        {
            if( fail instanceof Function)
            {
                fail( response );
            }
        }
    })
    .catch( error =>
    {
        console.log( 'getAccessToken error' , error ) ;
        if ( axios.isCancel( error ) )
        {
            if( cancel instanceof Function )
            {
                cancel( error ) ;
            }
        }
        else
        {
            const response = error.response ;
            if( response )
            {
                if( response.data )
                {
                    let data = response.data ;
                    switch( data.message )
                    {
                        case 'Invalid authorization code' :
                        case 'token revoked' :
                        case 'Invalid refresh token' :
                        {
                            tokensManager.dispose( api.name ) ;
                            if( api.grant_type === GrantType.AUTHORIZATION_CODE )
                            {
                                connect( api.name ) ;
                            }
                            return ;
                        }
                        default :
                        {
                            console.log( this + " failed, status:" + response.status + ", message:" + data.message , true );
                        }
                    }
                }
            }

            if( fail instanceof Function)
            {
                fail( error.response );
            }
        }
    });

    return canceler ;
}
