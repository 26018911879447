import React, { Component } from 'react'

import clsx from 'clsx'

import PropTypes from 'prop-types'

import isString from 'vegas-js-core/src/isString'

import { withStyles } from '@material-ui/core/styles'

import { Fab, Tooltip } from '@material-ui/core'

import AddIcon from '@material-ui/icons/Add'

import Animate from '../../transitions/Animate'

const styles =
{
    option :
    {
        marginLeft : 10
    }
};

class AddButton extends Component
{
    render = () =>
    {
        const {
            className,
            classes,
            disabled,
            color,
            locale,
            onClick,
            size
        } = this.props;

        let { icon } = this.props ;
        if( !icon )
        {
            icon = <AddIcon/> ;
        }

        let button = (
        <Fab
            disabled = { disabled }
            color    = { color }
            onClick  = { onClick }
            size     = { size }
        >
            { icon }
        </Fab>);

        if( !disabled && locale )
        {
            const { tooltips } = locale ;

            if( tooltips )
            {
                const { placement, tooltipProps } = this.props ;
                const { add } = tooltips ;
                if( isString(add) )
                {
                   button = (
                    <Tooltip
                        placement = { placement }
                        { ...tooltipProps }
                        title = {add}
                    >
                        { button }
                    </Tooltip>
                    );
                }
            }
        }

        button = <div className={clsx(classes.option,className)}>{button}</div> ;

        const { animate } = this.props ;
        if( animate )
        {
            const { animateDelay } = this.props;
            return (
                <Animate enter="transition.expandIn" delay={animateDelay}>
                    { button }
                </Animate>);
        }
        else
        {
            return button ;
        }
    };
}

AddButton.defaultProps =
{
    animate      : false ,
    animateDelay : 1000 ,
    className    : null  ,
    color        : 'primary' ,
    disabled     : false,
    icon         : <AddIcon/>,
    locale       : null,
    onClick      : null,
    placement    : 'left',
    size         : 'small',
    tooltipProps : null
};

AddButton.propTypes =
{
    animate      : PropTypes.bool,
    animateDelay : PropTypes.number,
    classes      : PropTypes.object.isRequired ,
    className    : PropTypes.string,
    disabled     : PropTypes.bool,
    color        : PropTypes.string,
    icon         : PropTypes.element,
    locale       : PropTypes.object,
    onClick      : PropTypes.func,
    placement    : PropTypes.oneOf(['bottom-end', 'bottom-start', 'bottom', 'left-end', 'left-start', 'left', 'right-end', 'right-start', 'right', 'top-end', 'top-start', 'top']),
    size         : PropTypes.string,
    tooltipProps : PropTypes.object
};

export default withStyles( styles )( AddButton );
