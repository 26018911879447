import React from 'react'

import isString from 'vegas-js-core/src/isString'
import format   from 'vegas-js-core/src/strings/format'

import api from '../../../../../configs/api'

import Form         from '../../../../../display/forms/FormElements'
import Method       from '../../../../../net/Method'
import NumberInput  from '../../../../../components/inputs/NumberInput'
import Thing        from '../../../../../things/Thing'
import Veterinarian from '../../../../../things/Veterinarian'
import Word         from '../../../../../things/Word'

import getLocaleThingLabel from '../../../../../things/getLocaleThingLabel'

import dialogs   from './abortionSerie/dialogs'
import events    from './abortionSerie/events'
import medical   from './abortionSerie/medical'
import workplace from './abortion/workplace'

const elements =
[
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'distance',
            key          : 'distance',
            label        : 'Distance A/R (Km)',
            autoFocus    : false,
            fullWidth    : true,
            InputProps   : {
                inputComponent : ( input ) => (
                    <NumberInput
                        allowNegative = { false }
                        floatCount    = { 3 }
                        {...input}
                    />
                )
            } ,
            margin       : 'normal',
            select       : false,
            autoComplete : 'off' ,
            type         : 'text',
            variant      : 'outlined'
        }
    },
    {
        type            : Form.SEARCH_SELECTOR ,
        clearLabel      : 'Vider' ,
        closeLabel      : 'Fermer' ,
        dropSuggestions : true ,
        iconifiable     : false ,
        init            :
        {
            id         : 'frequency',
            key        : 'frequency',
            label      : 'Fréquence',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            type       : 'search',
            variant    : 'outlined'
        },
        logics :
        {
            disabledSuggestions : ( props ) =>
            {
                const { rel } = props ;
                if( rel )
                {
                    const { method } = rel ;
                    if( method instanceof Word)
                    {
                        return [ method ] ;
                    }
                }
                return null ;
            }
        },
        path : ( props ) =>
        {
            const { rel } = props ;
            if( rel instanceof Thing )
            {
                const { subjectOf } = rel ;
                if( subjectOf instanceof Thing )
                {
                    const { additionalType } = subjectOf ;
                    if( additionalType instanceof Thing )
                    {
                        const { id } = additionalType;
                        if( id )
                        {
                            return format(api.settings.medicalFrequenciesByObservationsTypes,id) ;
                        }
                    }
                }
            }
            return null;
        },
        searchable  : false,
        title       : 'Sélectionner une fréquence'
    },
    {
        type         : Form.DATE ,
        defaultValue : null ,
        format       : 'L' ,
        init         :
        {
            id            : 'date',
            key           : 'date',
            autoOk        : true ,
            cancelLabel   : 'Annuler' ,
            clearable     : true ,
            clearLabel    : 'Vider' ,
            invalidLabel  : '-',
            inputVariant  : 'outlined' ,
            disableFuture : false ,
            disablePast   : false ,
            fullWidth     : true ,
            label         : 'Date de prélèvement' ,
            required      : false,
            okLabel       : 'OK',
            variant       : 'dialog'
        }
    },
    {
        type : Form.SWITCH ,
        init :
        {
            id    : 'completed',
            key   : 'completed',
            label : 'Protocole complet ?'
        }
    },
    {
        type            : Form.SEARCH_SELECTOR ,
        clearLabel      : 'Vider' ,
        closeLabel      : 'Fermer' ,
        dropSuggestions : true ,
        iconifiable     : false ,
        init            :
        {
            id         : 'conclusion',
            key        : 'conclusion',
            label      : 'Conclusion',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            type       : 'search',
            variant    : 'outlined'
        },
        logics :
        {
            disabledSuggestions : ( props ) =>
            {
                const { rel } = props ;
                if( rel )
                {
                    const { method } = rel ;
                    if( method instanceof Word)
                    {
                        return [ method ] ;
                    }
                }
                return null ;
            }
        },
        path : ( props ) =>
        {
            const { rel } = props ;
            if( rel instanceof Thing )
            {
                const { subjectOf } = rel ;
                if( subjectOf instanceof Thing )
                {
                    const { additionalType } = subjectOf ;
                    if( additionalType instanceof Thing )
                    {
                        const { id } = additionalType;
                        if( id )
                        {
                            return format(api.settings.medicalConclusionsByObservationsTypes,id) ;
                        }
                    }
                }
            }
            return null;
        },
        searchable  : false,
        title       : 'Sélectionner une conclusion...'
    }
];

const sort = lang => ( a , b ) =>
{
    if( a instanceof Veterinarian && b instanceof Veterinarian )
    {
        const { authority:a1 } = a ;
        const { authority:a2 } = b ;
        if( a1 && a2 )
        {
            let label1 = getLocaleThingLabel( a1 , lang ) ;
            let label2 = getLocaleThingLabel( a2 , lang ) ;
            if( isString(label1) && isString(label2) )
            {
                return label1.localeCompare(label2, lang) ;
            }
        }
    }
    return 0 ;
};

const thingRef = thing =>
{
    if( thing instanceof Veterinarian )
    {
        const { authority } = thing ;
        if( authority )
        {
            return authority;
        }
    }
    return thing ;
};

const abortionSerie =
{
    // dependencies

    dialogs,
    events,
    medical,
    workplace,

    // settings

    datePattern : 'L',
    edit :
    {
        agree       : 'Sauver' ,
        disagree    : 'Annuler' ,
        fail        : 'Veuillez corriger les erreurs' ,
        loading     : 'Chargement en cours...' ,
        method      : Method.PATCH,
        mock        : false ,
        success     : 'Mise à jour réussie!' ,
        title       : 'Série d\'avortements' ,
        elements
    },
    items :
    {
        conclusion : 'Conclusion du protocole',
        completed  : 'Protocole complet ?' ,
        date       : 'Date de prélèvement',
        distance   :
        {
            label : '{0} km',
            title : 'Nombre de kilomètres parcourus (A/R)'
        },
        empty     : '----',
        frequency : 'Fréquence',
        no        : 'Non',
        yes       : 'Oui'
    },
    suggestions :
    {
        sort,
        thingRef,
        disagree : 'Fermer' ,
        title    : 'Sélectionner un vétérinaire'
    },
    title    : 'Protocole OSCAR' ,
    tooltips :
    {
        edit  : 'Modifier',
        print : 'Imprimer'
    }
};

export default abortionSerie ;
