import React from 'react'

import { MediaObjectsList } from './MediaObjectsList'

import VideoIcon from '@material-ui/icons/VideoLibrary'

import withDatasList from './withDatasList'

import api from '../../configs/api'

import VideoObject from '../../things/creativework/media/VideoObject'

class VideosList extends MediaObjectsList
{
    add = () => this.openSelectMediaDialog() ;

    getLocale = () => this.props.locale.display.lists.videos ;

    getOptions = () => this.getAddButton() ;
}

VideosList.defaultProps =
{
    ...MediaObjectsList.defaultProps,
    addable          : true ,
    clazz            : VideoObject,
    deletable        : true ,
    icon             : <VideoIcon />,
    member           : 'videos' ,
    optionsVariant   : 'header',
    orderable        : true ,
    orderMock        : false ,
    removeProps      : { mock:false , useUrl:false } ,
    selectMediaProps :
    {
        disableEscapeKeyDown : true ,
        facets               : { encodingFormat : 'video' } ,
        multiple             : true,
        uploadable           : false
    },
    selectMediaUri : api.mediaObjects.url ,
    searchPolicy   : 'none' ,
    sortable       : false
};

VideosList.propTypes =
{
    ...MediaObjectsList.propTypes
};

export default withDatasList()( VideosList ) ;