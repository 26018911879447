import React from "react"

import PropTypes  from 'prop-types'

import { CircularProgress, Fab, Tooltip } from '@material-ui/core'

import AddIcon from '@material-ui/icons/Add'

import DatasContainer from '../containers/DatasContainer'
import PlaceDialog    from '../dialogs/things/PlaceDialog'
import Map            from './Map'

import withMap from './withMap'

import RequestStatus from '../../net/RequestStatus'

import AddPlaceDialog    from '../dialogs/add/AddPlaceDialog'
import RemovePlaceDialog from "../dialogs/remove/RemovePlaceDialog"

import Place from '../../things/Place'
import Thing from '../../things/Thing'

class PlacesMap extends DatasContainer
{
    getContent = ( loading = false ) =>
    {
        const { classes, config } = this.props ;

        if( loading )
        {
            return (
                <div className='w-full h-full flex items-center justify-center'>
                    <CircularProgress className={classes.progress} size={43} thickness={6} />
                </div>
            );
        }

        const { status } = this.state ;
        if( status === RequestStatus.SUCCESS )
        {
            const locale = this.getLocale() ;
            const { things } = this.state ;
            return (
            <Map
                { ...config.map }
                ref             = { ref => this.map = ref }
                className       = 'w-full h-full'
                fly             = { true }
                fullscreenMode  = { true }
                gpsFixed        = { true }
                locale          = { locale.map }
                onSelect        = { place => this.openPlaceDialog(place) }
                marker          = { false }
                markerDraggable = { false }
                places          = { things }
            />);
        }

        return null ;
    };

    getAfterContent = () =>
    {
        if( this.isEditable() )
        {
            const { classes } = this.props ;

            let button = (
                <Fab
                    color      = "secondary"
                    aria-label ="add"
                    className  = { classes.addButton }
                    onClick    = { this.openAddDialog }
                >
                    <AddIcon />
                </Fab>
            );

            const locale = this.getLocale() ;
            if( locale )
            {
                const { tooltips } = locale ;
                if( tooltips )
                {
                    const { add } = tooltips ;
                    if( add )
                    {
                        button = (
                        <Tooltip title={add} placement='left'>
                            { button }
                        </Tooltip>);
                    }
                }
            }

            return button ;
        }

        return null ;
    };

    getEntry = ( init = null ) =>
    {
        let { clazz, emptyClazz } = this.props ;

        if( emptyClazz )
        {
            clazz = emptyClazz ;
        }

        if( !clazz )
        {
            clazz = Thing ;
        }

        let generic ;
        if( init instanceof Thing )
        {
            generic = init.toObject() ;
        }
        else if( init )
        {
            generic = { ...init } ;
        }

        const object = new clazz(generic) ;

        object.subjectOf = this.getEntrySubjectOf() ;

        return object ;
    };

    getEntrySubjectOf = () => this.props.thing ;

    getLocale = () => this.props.locale.components.maps.places;

    onRemove = id =>
    {
        const { clearDialog } = this.props ;
        if( clearDialog instanceof Function )
        {
            clearDialog() ;
        }
        if( id )
        {
            let { datas } = this.state ;
            if( datas )
            {
                let { result } = datas ;
                if( result instanceof Array )
                {
                    result = result.filter( item => item.id !== id )
                }
                datas.result = result ;
                this.setState({ datas }) ;
            }
        }
    };

    openAddDialog = () =>
    {
        const { addDialog } = this.props;
        if ( addDialog )
        {
            let init ;
            if( this.map )
            {
                const center = this.map.getCenter() ;
                const { lat , lng } = center ;
                init = { geo:{ latitude:lat, longitude:lng } } ;
            }
            addDialog(
                AddPlaceDialog ,
                {
                    item  : this.getEntry( init ) ,
                    onAdd : this.onAdd
                }
            ) ;
        }
    };

    openPlaceDialog = item =>
    {
        const { addDialog } = this.props;
        if ( addDialog )
        {
            const { backPath } = this.props ;
            addDialog(
                PlaceDialog ,
                {
                    deletable : true ,
                    onRemove : this.openRemoveDialog,
                    backPath,
                    item
                }
            ) ;
        }
    };

    populate = datas =>
    {
        const { clazz } = this.props ;
        if( datas instanceof Array && clazz instanceof Function)
        {
            return datas.map( item => item instanceof clazz ? item : new clazz(item) ) ;
        }
        return datas ;
    };
}

PlacesMap.defaultProps =
{
    ...DatasContainer.defaultProps,
    AddDialogComponent    : AddPlaceDialog,
    addMode               : RequestStatus.RELOAD,
    clazz                 : Place ,
    emptyClazz            : null ,
    headerPolicy          : 'none',
    pagination            : false ,
    queries               : { limit:1000 } ,
    RemoveDialogComponent : RemovePlaceDialog ,
    selectable            : true
};

PlacesMap.propTypes =
{
    ...DatasContainer.propTypes,
    clazz       : PropTypes.func,
    emptyClazz  : PropTypes.func,
    selectable  : PropTypes.bool
};

export default withMap()( PlacesMap );
