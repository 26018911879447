export const APPLICATION = 'application' ;
export const AUDIO       = 'audio' ;
export const IMAGE       = 'image' ;
export const MEDIA       = 'media' ;
export const VIDEO       = 'video' ;

// ------- applications

export const PDF = 'application/pdf' ;

// ------- audios

export const FLAC   = 'audio/flac' ;
export const XFLAC  = 'audio/x-flac' ;
export const MP3    = 'audio/mp3' ;
export const MPEG   = 'audio/mpeg' ;
export const XMPEG  = 'audio/x-mpeg-3' ;
export const OGG    = 'audio/ogg' ;
export const VORBIS = 'audio/vorbis' ;
export const WEBMA  = 'audio/webm' ;
export const WAV    = 'audio/wav' ;
export const XWAV   = 'audio/x-wav' ;

// ------- images

export const JPEG = 'image/jpeg' ;
export const PNG  = 'image/png' ;
export const WEBP = 'image/webp' ;

// ------- vendors

export const SOUNDCLOUD = 'audio/vnd.soundcloud' ;
export const YOUTUBE    = 'video/vnd.youtube' ;

export const defaultMimetypes =
{
    // standards

    [ PDF  ] : MEDIA,

    [ FLAC  ]  : AUDIO,
    [ XFLAC ]  : AUDIO,
    [ MP3  ]   : AUDIO,
    [ MPEG ]   : AUDIO,
    [ XMPEG ]  : AUDIO ,
    [ OGG ]    : AUDIO ,
    [ VORBIS ] : AUDIO ,
    [ WEBMA ]  : AUDIO ,
    [ WAV ]    : AUDIO ,
    [ XWAV ]   : AUDIO ,

    [ JPEG ] : IMAGE,
    [ PNG  ] : IMAGE ,
    [ WEBP ] : IMAGE ,

    // vendors

    [ SOUNDCLOUD ] : AUDIO ,
    [ YOUTUBE ]    : VIDEO
};

/**
 * The mimetypes enumeration accepted by the application.
 */
export let mimetypes = { ...defaultMimetypes } ;

export const initialize = init =>
{
    if( init )
    {
        mimetypes = {} ;
        for( let type in init )
        {
            if( isValid(type) )
            {
                mimetypes[type] = init[type] ;
            }
        }
    }
    else
    {
        mimetypes = { ...defaultMimetypes } ;
    }

    return mimetypes ;
};

export const reset = () =>
{
    mimetypes = { ...defaultMimetypes } ;
    return mimetypes ;
};

export const isValid = type =>
{
    return defaultMimetypes.hasOwnProperty(type) ;
};

export default mimetypes ;
