import React from 'react'

import moment  from 'moment/moment'

import compose  from 'vegas-js-core/src/functors/compose'
import isInt    from 'vegas-js-core/src/isInt'
import isString from 'vegas-js-core/src/isString'

import initApp from '../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { Grid } from '@material-ui/core'

import TeamIcon from '@material-ui/icons/Group'

import LabelItem from '../../components/items/LabelItem'

import ThingContainer from '../containers/ThingContainer'

import TeamVO  from '../../vo/Team'
import ThingVO from '../../things/Thing'

const styles = () => ({});


const size =
{
    lg : 4 ,
    md : 4 ,
    sm : 6 ,
    xs : 12
};

class Team extends ThingContainer
{
    getContent = () =>
    {
        let { thing } = this.props;

        const locale = this.getLocale() ;

        const { labels } = locale ;

        let color ;
        let identifier ;
        let name ;

        if( thing instanceof TeamVO )
        {
            let {
                color : c,
                identifier : i,
                name : n
            } = thing ;

            if( isString( c ) )
            {
                color = c ;
            }

            if( isInt( i ) )
            {
                identifier = i ;
            }

            if( isString( n ) )
            {
                name = n ;
            }
        }

        return (
            <Grid container spacing={4}>
                <Grid item {...size}>
                    <LabelItem enabled={!!(name)} title={ labels.name }>
                        { isString(name) ? name : labels.empty }
                    </LabelItem>
                </Grid>
                <Grid item {...size}>
                    <LabelItem enabled={!!(identifier)} title={ labels.identifier }>
                        { isInt(identifier) ? identifier : labels.empty }
                    </LabelItem>
                </Grid>
                <Grid item {...size}>
                    <LabelItem enabled={!!(color)} title={ labels.color }>
                        { isString(color) ? color : labels.empty }
                    </LabelItem>
                </Grid>
            </Grid>
        );
    };

    getEntry = init =>
    {
        let empty = {} ;
        if( init instanceof ThingVO )
        {
            empty = init.toObject() ;
        }
        else if( init )
        {
            empty = { ...init } ;
        }
        return new TeamVO(empty);
    };

    getLocale = () => this.props.locale.things.team;

    onEdit = item =>
    {
        const { onChange, thing } = this.props ;
        if( thing && item )
        {
            const {
                color,
                identifier,
            } = item ;

            thing.color      = color ;
            thing.identifier = identifier ;
            thing.modified   = moment(new Date()).toISOString();

            if( thing instanceof ThingVO )
            {
                thing.populate();
            }

            if( this._mounted )
            {
                this.forceUpdate();
            }

            if( onChange instanceof Function )
            {
                onChange(thing) ;
            }
        }
    }

    prepare = item =>
    {
        let {
            color,
            identifier
        } = item ;

        return (
        {
            color,
            identifier
        });
    };
}

Team.defaultProps =
{
    ...ThingContainer.defaultProps ,
    icon       : <TeamIcon/> ,
    optionMode : 'edit'
};

Team.propTypes =
{
    ...ThingContainer.propTypes
};

export default compose( withStyles( styles ) , withRouter , initApp )( Team ) ;
