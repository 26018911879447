import MediaObject from '../MediaObject'

/**
 * An image file.
 */
class ImageObject extends MediaObject
{
    /**
     * Creates a new ImageObject instance.
     * @constructor
     * @param object The generic object to initialize the object the first time.
     */
    constructor( object = null )
    {
        super() ;

        /**
         * The caption for this object.
         * @type {string}
         */
        this.caption = null ;

        /**
         * exif data for this object.
         * @type {string|Object}
         */
        this.exifData = null ;

        /**
         * Indicates whether this image is representative of the content of the page.
         * @type {boolean}
         */
        this.representativeOfPage = null ;

        /**
         * Thumbnail image for an image or video.
         * @type {string|ImageObject}
         */
        this.thumbnail = null ;

        this.set( object ) ;
    }


    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        super.clear() ;
        this.caption   =
        this.exifData  =
        this.thumbnail = null ;
        this.representativeOfPage = false ;
        return this ;
    }

    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new ImageObject( this.toObject() ) ;
    }

    /**
     * Returns the generic object representation of the item.
     * @return {Object} The generic object representation of the item.
     */
    toObject()
    {
        let {
            caption,
            exifData,
            representativeOfPage,
            thumbnail
        } = this ;
        
        return {
            ...super.toObject() ,
            caption,
            exifData,
            representativeOfPage,
            thumbnail
        }
    }
}

export default ImageObject ;