import notEmpty from 'vegas-js-core/src/strings/notEmpty'

import generify from '../things/generify'

import populateFavorite from '../things/populateFavorite'

import Roles from '../net/auth/Roles'

import Person        from '../things/Person'
import PostalAddress from '../things/PostalAddress'
import PropertyValue from '../things/PropertyValue'
import Team          from './Team'
import Thing         from '../things/Thing'
import Word          from '../things/Word'

/**
 * Defines the user of the application to save in the session storage of the application.
 * @memberOf things
 */
class User extends Person
{
    /**
     * Creates a new User instance.
     * @constructor
     * @param {Object} [object=null] The generic object to initialize the object the first time.
     */
    constructor( object = null )
    {
        super() ;

        /**
         * The favorites collection of the user.
         * @type {Array}
         */
        this.favorites = null ;

        /**
         * The number of favorites of the user.
         * @type {null}
         */
        this.numFavorites = null ;

        /**
         * The person reference of this user.
         * @type {Person}
         */
        this.person = null ;

        /**
         * The provider of the user.
         */
        this.provider = null ;

        /**
         * The provider user identifier value.
         */
        this.provider_uid = null ;

        /**
         * The scope settings of the user.
         */
        this.scope = null ;
    
        /**
         * The storage identifier value.
         * @type {string}
         */
        this.storageID = 'application_user' ;
        
        /**
         * The team of the user.
         */
        this.team = null ;

        /**
         * The unique user identifier of the user.
         */
        this.uuid = null ;
        
        this.set( object ) ;
    }

    /**
     * Clear the object.
     */
    clear()
    {
        super.clear(true) ;
        this.about =
        this.address =
        this.birthDate =
        this.birthPlace =
        this.deathDate =
        this.deathPlace =
        this.email =
        this.familyName =
        this.favorites =
        this.gender =
        this.givenName =
        this.job =
        this.nationality =
        this.numFavorites =
        this.telephone =
        this.websites =
        this.person =
        this.provider =
        this.provider_uid =
        this.scope =
        this.team =
        this.uuid = null ;
        return this ;
    }

    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new User( this.toObject() ) ;
    }

    /**
     * Returns the current scope of the user with the specific path.
     * @param path The path to check
     * @returns {string} the current scope of the user with the specific path.
     */
    getScopeByPath( path )
    {
        if( notEmpty( path ) )
        {
            if( path.indexOf('/') === 0 )
            {
                path = path.slice(1) ;
            }
            path = path.split('/')[0] ;
            return ( this.scope && this.scope.hasOwnProperty(path)) ? this.scope[path] : null ;
        }
        return null ;
    }

    getFullName()
    {
        const { givenName, familyName } = this ;
        if( !givenName || !familyName )
        {
            return null ;
        }
        return givenName + " " + familyName ;
    }

    isAdmin = path => this.getScopeByPath( path ) === Roles.ADMIN ;
    
    isGuest = path => this.getScopeByPath( path ) === Roles.GUEST ;
    
    isReader = path => this.getScopeByPath( path ) === Roles.READ ;
    
    isSuperAdmin = path => this.getScopeByPath( path ) === Roles.ADMIN && this.team && (this.team.name === 'superadmin') ;
    
    isWriter = path => this.getScopeByPath( path ) === Roles.WRITE ;

    populate()
    {
        const {
            address,
            email,
            favorites,
            gender,
            person,
            team
        } = this ;
    
        if( address && !(address instanceof PostalAddress))
        {
            this.address = new PostalAddress(address) ;
        }
        
        if( email instanceof Array )
        {
            this.email = email.map( item => item instanceof PropertyValue ? item : new PropertyValue(item) ) ;
        }

        if( favorites instanceof Array )
        {
            this.favorites = favorites.map( item => item instanceof Thing ? item : populateFavorite(item) ) ;
        }

        if( gender && !(gender instanceof Word))
        {
            this.gender = new Word(gender) ;
        }

        if( person && !(person instanceof Person))
        {
            this.person = new Person(person) ;
        }

        if( team && !(team instanceof Team))
        {
            this.team = new Team(team) ;
        }
        
        return this ;
    }

    /**
     * Returns the generic object representation of the object.
     * @returns {{access_token: *, expire_in: number, refresh_token: *, timestamp: *, token_type: *, scope: *, state: *}}
     */
    toObject()
    {
        let {
            address,
            email,
            familyName,
            favorites,
            gender,
            givenName,
            numFavorites,
            person,
            provider,
            provider_uid,
            scope,
            team,
            uuid
        } = this ;
        
        address   = generify(address);
        email     = generify(email);
        favorites = generify(favorites) ;
        gender    = generify(gender);
        person    = generify(person);
        team      = generify(team);

        return {
            ...super.toObject() ,
            address,
            email,
            familyName,
            favorites,
            gender,
            givenName,
            numFavorites,
            person,
            provider,
            provider_uid,
            scope,
            team,
            uuid
        };
    }
}

export default User ;