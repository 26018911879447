const media =
{
    failed   : 'Impossible d\'ajouter un média',
    remove :
    {
        agree       : 'Supprimer' ,
        description : 'Souhaitez-vous supprimer ce média ?' ,
        disagree    : 'Annuler' ,
        loading     : 'Suppression en cours...' ,
        title       : 'Supprimer le média ?',
        useID       : false
    },
    removed  : 'Média supprimé',
    success  : 'Média ajouté avec succès',
    title    : 'Média' ,
    tooltips :
    {
        add         : 'Ajouter',
        remove      : 'Supprimer',
        selectMedia : 'Sélectionner'
    }
};

export default media ;