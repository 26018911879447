import list from './list'

const activities =
{
    ...list,
    helmet :
    {
        title : 'Activités' ,
        metas : []
    },
    empty  : 'Aucune activité trouvée',
    title  : 'Activités'
};

export default activities ;
