import React from 'react'

import { Redirect } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import format from "vegas-js-core/src/strings/fastformat"

import AuthStatus from '../../net/auth/AuthStatus'

import Reload            from '../../components/snackbars/Reload'
import RoleableContainer from '../../components/containers/RoleableContainer'

import checkScopeByPath from '../../vo/checkScopeByPath'

import user from '../../contexts/user/user'

class View extends RoleableContainer
{
    build = () => null ;
    
    getHelmet( title = null )
    {
        const { config } = this.props ;

        const { helmet } = config ;

        const locale = this.getLocale() ;

        let { metas } = helmet ;

        const current = locale && locale.helmet ? locale.helmet : null ;
        if( current && current.metas )
        {
            metas = current.metas ;
        }

        let fullTitle = null ;
        if( current && current.title )
        {
            if( title === null )
            {
                fullTitle = current.title ;
            }
            else
            {
                fullTitle = format( current.title , title ) ;
            }
        }

        return (
        <Helmet { ...helmet } >
            { fullTitle && <title>{ fullTitle }</title> }
            { metas instanceof Array && metas.map( ( meta , index ) => (<meta key={'meta-' + index} { ...meta } />) ) }
            <meta name="description" content="Helmet application" />
            <link rel="canonical" href={ config.homepage } />
        </Helmet>)
    }
    
    getLocale = () => this.props.locale ;

    init = () =>
    {
        const { location } = this.props ;
        if( location )
        {
            const { state } = location ;
            if( state )
            {
                const { backOptions, backPath } = state ;
                const options = {
                    ...(backOptions !== null) && { backOptions },
                    ...(backPath    !== null) && { backPath    }
                } ;
                this.setState( { ...options } /*, () => console.log( this + " init OK" , this.state , state ) */) ;
            }
        }
    };

    render()
    {
        const { location } = this.props;
        switch( AuthStatus.check() )
        {
            case AuthStatus.NEED_ACCESS_TOKEN :
            {
                return <Redirect to={{ pathname: "/welcome" , state: { referrer: location } }} />
            }
            case AuthStatus.NEED_USER :
            {
                return <Redirect to={{ pathname: "/register" , state: { referrer: location } }} />
            }
            case AuthStatus.CONNECTED :
            {
                const { permissions } = this.props ;
                return checkScopeByPath( user, location.pathname , permissions )
                     ? <div className='flex flex-auto w-full'>
                           { this.getHelmet( ) }
                           { this.build() }
                           <Reload />
                       </div>
                     : <Redirect to={{ pathname: "/welcome" }} /> ;
            }
            default :
            {
                return this.unknown() ;
            }
        }
    }

    unknown()
    {
        console.log(this + " unknown check:" + AuthStatus.check()) ;
        return null ;
    }
}

View.defaultProps =
{
    ...RoleableContainer.defaultProps
};

View.propTypes =
{
    ...RoleableContainer.propTypes
};

export default View ;
