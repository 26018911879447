import api from "../../../../configs/api"

import Form from '../../../../display/forms/FormElements'

import addHours   from 'vegas-js-core/src/date/addHours'
import addMinutes from 'vegas-js-core/src/date/addMinutes'

import Place from '../../../../things/Place'

import getExistingSuggestions from '../../../../things/getExistingSuggestions'

const showLocation = ( { props : { thingRef } = {} } = {} ) => !( thingRef instanceof Place ) ;

const elements =
[
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'name',
            key          : 'name',
            label        : 'Titre',
            autoFocus    : true,
            fullWidth    : true,
            helperText   : 'Obligatoire',
            margin       : 'normal',
            required     : true,
            select       : false,
            autoComplete : 'off' ,
            type         : 'search',
            variant      : 'outlined'
        }
    },
    {
        type : Form.SEARCH_SELECTOR ,
        path : api.thesaurus.events_types ,
        init :
        {
            id         : 'additionalType',
            key        : 'additionalType',
            label      : 'Type d\'événement',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            required   : true,
            type       : 'search',
            variant    : 'outlined'
        },
        disabledSuggestions : getExistingSuggestions({ ref:'additionalType' , include:true }) ,
        selector : { i18n :
        {
            empty       : 'Aucun type disponible.' ,
            title       : 'Sélectionner un type',
            description : 'Veuillez sélectionner un type d\'événement ci-dessous.' ,
        }}
    },
    {
        type         : Form.DATETIME ,
        defaultValue : () => new Date(),
        format       : 'L - LT' ,
        init         :
        {
            id              : 'startDate',
            key             : 'startDate',
            autoOk          : true ,
            cancelLabel     : 'Annuler' ,
            clearLabel      : 'Fermer' ,
            disableFuture   : false ,
            disablePast     : false ,
            invalidLabel    : '-',
            fullWidth       : true ,
            inputVariant    : 'outlined',
            label           : 'Date de début' ,
            required        : true,
            okLabel         : 'OK',
            showTodayButton : false ,
            variant         : 'dialog'
        },
        onChange : ( thing, newDate ) =>
        {
            if ( thing )
            {
                if ( newDate )
                {
                    thing.startDate = newDate;
                    let { endDate } = thing;
                    if( !endDate || newDate.valueOf() >= endDate.valueOf() )
                    {
                        thing.endDate = addMinutes(newDate, 1) ;
                    }
                }
            }
        }
    }
    ,
    {
        type         : Form.DATETIME ,
        defaultValue : () => addHours(new Date(), 1),
        format       : 'L - LT' ,
        minDate      :  'startDate' ,
        init         :
        {
            id              : 'endDate',
            key             : 'endDate',
            autoOk          : true ,
            disableFuture   : false ,
            disablePast     : false ,
            cancelLabel     : 'Annuler' ,
            clearable       : false ,
            clearLabel      : 'Vider' ,
            fullWidth       : true ,
            inputVariant    : 'outlined',
            label           : 'Date de fin' ,
            invalidLabel    : '-',
            okLabel         : 'OK',
            showTodayButton : false,
            variant         : 'variant'
        },
        onChange : ( thing , newDate ) =>
        {
            if ( thing )
            {
                if ( newDate )
                {
                    thing.endDate = newDate ;
                    let { startDate } = thing;
                    if( !startDate )
                    {
                        thing.startDate = new Date() ;
                        thing.endDate   = addMinutes(thing.startDate, 1);
                    }
                    else if ( newDate.valueOf() <= startDate.valueOf())
                    {
                        thing.endDate = addMinutes(startDate, 1);
                    }
                }
            }
        }
    },
    {
        type    : Form.SEARCH_SELECTOR ,
        visible : showLocation ,
        path    : api.places.url ,
        clazz   : Place ,
        init    :
        {
            id        : 'location',
            key       : 'location',
            label     : 'Lieu de l\'événement',
            autoFocus : false,
            fullWidth : true,
            margin    : 'normal',
            type      : 'search',
            variant   : 'outlined'
        },
        selector : { i18n :
        {
            empty       : 'Aucun lieu disponible.' ,
            title       : 'Sélectionner un lieu',
            description : 'Veuillez sélectionner un lieu ci-dessous.' ,
        }}
    }
];

export default
{
    add :
    {
        elements,
        agree       : 'Créer' ,
        disagree    : 'Annuler' ,
        title       : 'Ajouter un événement' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour créer un nouveau événement.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en  cours...' ,
        success     : 'Ajout du nouvel enregistrement réussi!'
    },
    remove :
    {
        agree        : 'Supprimer' ,
        description  : 'Souhaitez-vous supprimer l\'événement:' ,
        descriptionX : 'Souhaitez-vous supprimer les {0} événements:' ,
        disagree     : 'Annuler' ,
        loading      : 'Suppression en cours...' ,
        title        : 'Supprimer cet événément ?',
        titleX       : 'Supprimer des événements ?'
    }
};
