import React from 'react'

import moment from 'moment'

import compose  from 'vegas-js-core/src/functors/compose'
import isString from 'vegas-js-core/src/isString'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import initApp from '../../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { Grid } from '@material-ui/core'

import HealingIcon from '@material-ui/icons/Healing'

import LabelItem from '../../../components/items/LabelItem'

import ThingContainer from '../../containers/ThingContainer'

import DiseaseVO from '../../../things/medical/Disease'
import Thing     from '../../../things/Thing'


const styles = () => ({});

const half =
{
    md :  6 ,
    sm : 12 ,
    xs : 12
};

class Disease extends ThingContainer
{
    editPrepare = ( item ) =>
    {
        let {
            additionalType,
            level,
            name
        } = item ;

        return (
        {
            additionalType : additionalType instanceof Thing ? String(additionalType.id) : '' ,
            level          : level instanceof Thing ? String(level.id) : '' ,
            name
        });
    };

    getContent = () =>
    {
        let { lang, thing } = this.props;

        if( thing instanceof DiseaseVO )
        {
            const locale = this.getLocale() ;

            let {
                additionalType,
                level
            } = thing ;

            let name = thing.getLocaleName(lang);

            if( additionalType instanceof Thing )
            {
                additionalType = additionalType.getLocaleName(lang)
            }
            else
            {
                additionalType = null ;
            }

            if( level instanceof Thing )
            {
                level = level.getLocaleName(lang)
            }
            else
            {
                level = null ;
            }

            const { labels } = locale ;

            return (
                <Grid container spacing={4}>
                    <Grid item {...half}>
                        <LabelItem enabled={!!(name)} title={ labels.name }>
                            { isString(name) ? name : labels.empty }
                        </LabelItem>
                    </Grid>
                    <Grid item {...half}>
                        <LabelItem enabled={!!(additionalType)} title={ labels.type } >
                            { isString(additionalType) ? ucFirst(additionalType) : labels.empty }
                        </LabelItem>
                    </Grid>
                    <Grid item {...half}>
                        <LabelItem enabled={!!(level)} title={ labels.level } >
                            { isString(level) ? ucFirst(level) : labels.empty }
                        </LabelItem>
                    </Grid>
                </Grid>
            );
        }

        return null ;
    };

    getEntry = init =>
    {
        let empty = {} ;
        if( init instanceof Thing )
        {
            empty = init.toObject() ;
        }
        else if( init )
        {
            empty = { ...init } ;
        }
        return new DiseaseVO(empty);
    };

    getLocale = () => this.props.locale.things.medical.disease;

    onEdit = ( item ) =>
    {
        const {
            onChange,
            thing
        } = this.props ;

        if( thing && item )
        {
            const {
                additionalType,
                level,
                name
            } = item ;

            thing.additionalType = additionalType ;
            thing.modified       = moment(new Date()).toISOString();
            thing.level          = level ;
            thing.name           = name ;

            if( thing instanceof Thing )
            {
                thing.populate();
            }

            if( this._mounted )
            {
                this.forceUpdate();
            }

            if( onChange instanceof Function )
            {
                onChange(thing) ;
            }
        }
    }
}

Disease.defaultProps =
{
    ...ThingContainer.defaultProps ,
    icon       : <HealingIcon/> ,
    optionMode : 'edit'
};

Disease.propTypes =
{
    ...ThingContainer.propTypes
};

export default compose( withStyles( styles ) , withRouter , initApp )(Disease)  ;
