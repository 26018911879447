import React from 'react'

import compose from 'vegas-js-core/src/functors/compose'

import initApp from '../../../../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import TopicIcon from '@material-ui/icons/Map'

import PropertyValue from '../../../../../things/PropertyValue'
import CreativeWork  from '../../../../../things/CreativeWork'

import ThingSortableChildren, { styles } from '../../../ThingSortableChildren'

class Topics extends ThingSortableChildren
{
    getLocale = () => this.props.locale.things.application.settings.support.topics ;

    onAdd    = this.change ;
    onRemove = this.change ;
}

Topics.defaultProps =
{
    ...ThingSortableChildren.defaultProps ,
    clazz      : CreativeWork,
    emptyClazz : PropertyValue,
    icon       : <TopicIcon/> ,
    member     : 'topics'
};

Topics.propTypes =
{
    ...ThingSortableChildren.propTypes
};

export default compose( withStyles( styles ) , withRouter , initApp )( Topics ) ;
