import initDialog from '../initDialog'

import { SelectThingDialog , styles } from '../SelectThingDialog'

import api from '../../../configs/api'

import Course from '../../../things/Course'

class SelectCourseDialog extends SelectThingDialog
{
    getLocale = () => this.props.locale.dialogs.select.course ;
}

SelectCourseDialog.defaultProps =
{
    ...SelectThingDialog.defaultProps,
    clazz : Course ,
    uri   : api.courses.url
};

SelectCourseDialog.propTypes =
{
    ...SelectThingDialog.propTypes,
};

export default initDialog({ styles , useSearch:false })( SelectCourseDialog ) ;
