import notEmpty from 'vegas-js-core/src/strings/notEmpty'

import createMediaObject from '../../things/creativework/createMediaObject'

import getOembed from '../oembed/getOembed'
import iframeSrc from '../strings/iframeSrc'

import defaultValidators , { DEFAULT , OEMBED } from './defaultValidators'

const defaultOptions = {
    delay      : 200 ,
    validators : defaultValidators
} ;

let timer ;

const createEmbedMediaObject = ( url , options = null ) =>
{
    options = { ...defaultOptions , ...options } ;

    clearTimeout( timer ) ;

    return new Promise(( resolve , reject ) =>
    {
        const { delay } = options;
        timer = setTimeout
        (
            () =>
            {
                const { validators } = options;
                if ( notEmpty(url) )
                {
                    if ( validators instanceof Array && validators.length > 0 )
                    {
                        const len = validators.length;
                        for ( let i = 0; i < len; i++ )
                        {
                            let setting = validators[i];
                            let { encodingFormat , type } = setting ;
                            switch( type )
                            {
                                case DEFAULT :
                                {
                                    let { thumbify, validate } = validators[i];
                                    if ( validate instanceof Function )
                                    {
                                        let embedUrl = validate(url);
                                        if ( notEmpty(embedUrl) )
                                        {
                                            let thumbnailUrl = thumbify(embedUrl);
                                            resolve( createMediaObject({encodingFormat, embedUrl, thumbnailUrl } ) ) ;
                                        }
                                    }
                                    return ;
                                }

                                case OEMBED :
                                {
                                    getOembed( url , encodingFormat )
                                    .then( data =>
                                    {
                                        const { html } = data ;
                                        if( notEmpty(html) )
                                        {
                                            const embedUrl = iframeSrc( html ) ;
                                            if( notEmpty( embedUrl ) )
                                            {
                                                const {
                                                    author_name   : author ,
                                                    provider_name : editor,
                                                    title         : name,
                                                    thumbnail_url : thumbnailUrl
                                                }
                                                = data ;

                                                const media = {
                                                    author,
                                                    encodingFormat,
                                                    editor,
                                                    embedUrl,
                                                    name,
                                                    thumbnailUrl
                                                };

                                                resolve( createMediaObject( media ) ) ;
                                                return ;
                                            }
                                        }
                                        reject( 'Invalid data definition' ) ;

                                    })
                                    .catch( error =>
                                    {
                                        reject( error ) ;
                                    })
                                    return ;
                                }

                                default :
                                {
                                    //
                                }
                            }
                        }

                        reject( 'The url is not a valid external media.' ) ;
                    }
                    else
                    {
                        reject( 'The validators collection not must be null or empty.' ) ;
                    }
                }
                else
                {
                    reject( 'The url not must be null or empty.' ) ;
                }
            },
            delay
        );
    }) ;
};

export default createEmbedMediaObject ;