import React from 'react'

import isNotNull from 'vegas-js-core/src/isNotNull'

import Icon from '@material-ui/icons/LocalHospital'

import withDatasList from './withDatasList'

import ThingsList from './ThingsList'

import Veterinarian   from '../../things/Veterinarian'
import VeterinaryCell from '../cells/VeterinaryCell'

import AddVeterinarianDialog    from '../dialogs/add/AddVeterinarianDialog'
import RemoveVeterinarianDialog from '../dialogs/remove/RemoveVeterinarianDialog'

class VeterinariansList extends ThingsList
{
    getEntry = () =>
    {
        let thing ;

        const { clazz } = this.props ;

        if( clazz )
        {
            thing = new clazz() ;
        }

        let { things } = this.state ;
        if( things instanceof Array && things.length > 0 )
        {
            things = things.map( item => item instanceof Veterinarian ? item.authority : null ).filter( isNotNull ) ;
            if( things.length > 0 )
            {
                thing.subjectOf = things ;
            }
        }

        return thing ;
    };

    getLocale = () => this.props.locale.display.lists.veterinarians ;
}

VeterinariansList.defaultProps =
{
    ...ThingsList.defaultProps ,
    AddDialogComponent    : AddVeterinarianDialog,
    CellComponent         : VeterinaryCell,
    clazz                 : Veterinarian,
    icon                  : <Icon /> ,
    preferable            : true ,
    RemoveDialogComponent : RemoveVeterinarianDialog
};

VeterinariansList.propTypes =
{
    ...ThingsList.propTypes
};

export default withDatasList()( VeterinariansList ) ;
