import React from 'react'

import UserConsumer from './UserConsumer'

export const withUser = Component => props =>
(
    <UserConsumer>
        { store => <Component {...props} {...store} /> }
    </UserConsumer>
);

export default withUser ;