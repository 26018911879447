import React from 'react'

import SelectContext from './SelectContext'

import defaultProperties from './defaultProperties'

import RoleableContainer from '../../components/containers/RoleableContainer'

class SelectProvider extends RoleableContainer
{
    constructor( props )
    {
        super(props);
        this.state =
        {
            ...defaultProperties,
            numSelected     : this.size,
            selectAll       : this.all,
            selectReset     : this.reset,
            setSelect       : this.set,
            setSelectRemove : this.remove,
            toggleSelected  : this.toggle,
            unselectAll     : this.close
        };
    }

    all = () =>
    {
        const {
            selectable,
            selectableItems
        }
        = this.state ;
        if( selectable && (selectableItems instanceof Array) )
        {
            this.setState({ selectedItems: [ ...selectableItems ] });
        }
    };

    close = () =>
    {
        const { selectedItems } = this.state ;
        if( selectedItems instanceof Array && selectedItems.length > 0 )
        {
            this.setState({ selectedItems:[] } );
        }
    };

    remove = method =>
    {
       this.setState(
       {
            selectRemove : method instanceof Function ? method : null
        });
    };

    render()
    {
        const { children } = this.props;
        return (
            <SelectContext.Provider value={ this.state }>
                {children}
            </SelectContext.Provider>
        )
    }

    reset = () =>
    {
        this.setState(
        {
            selectable      : false,
            selectableItems : [],
            selectedItems   : [],
            selectLocale    : null,
            selectRemove    : null
        });
    };

    set = options => { this.setState({ ...options }); };

    size = () =>
    {
        const { selectedItems } = this.state ;
        return selectedItems instanceof Array ? selectedItems.length : 0 ;
    };

    toggle = ( item, key = null ) =>
    {
        const { selectable } = this.state ;
        if( selectable )
        {
            let { selectedItems } = this.state;

            if ( !(selectedItems instanceof Array) )
            {
                selectedItems = [] ;
            }

            let index = -1;

            if (key === null)
            {
                index = selectedItems.indexOf(item);
            }
            else
            {
                index = selectedItems.findIndex(element => (item[key] === element[key]));
            }

            if (index > -1)
            {
                selectedItems.splice(index, 1);
            }
            else
            {
                selectedItems.push(item) ;
            }

            // console.log( this + ' toggle' , selectedItems ) ;

            this.setState({ selectedItems }) ;
        }
    };
}

SelectProvider.defaultProps =
{
    ...RoleableContainer.defaultProps
};

SelectProvider.propTypes =
{
    ...RoleableContainer.propTypes
};

export default SelectProvider ;
