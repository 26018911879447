import Method from '../../../../net/Method'

const addMedia =
{
    agree        : 'Sélectionner' ,
    agreeX       : 'Sélectionner ({0})',
    description  : 'Ajouter un nouvel enregistrement.' ,
    descriptionX : 'Ajouter un ou plusieurs enregistrements.' ,
    empty        : 'Aucun média sélectionné !',
    outOfBounds  : 'Nombre maximum de média atteint',
    disagree     : 'Fermer' ,
    title        : 'Sélectionner un média',
    titleX       : 'Sélectionner des médias',
    upload       :
    {
        agree        : 'Envoyer',
        disagree     : 'Fermer',
        filePropName : 'file' ,
        method       : Method.POST,
        mock         : false ,
        text         : 'Déposer un fichier dans la médiathèque.',
        title        : 'Téléverser un média'
    }
};

export default addMedia ;
