import { Component } from "react"

// ------------

import moment from 'moment/moment'
import Moment from 'react-moment'

import 'moment/locale/fr'

// ------------

import Version from 'vegas-js-core/src/version'

// ------------

import { initialize as initMimetypes } from './things/creativework/mimetypes'

import env       from './env'
import mimetypes from './configs/mimetypes'
import settings  from './things/settings'

// ------------

const initialize = version =>
{
    Component.prototype.toString = function()
    {
        return '[' + this.constructor.name + ']';
    };

    initMimetypes( mimetypes ) ;

    // ------------

    settings.defaultLang = process.env.REACT_APP_DEFAULT_LANG ;
    settings.lang        = process.env.REACT_APP_LANG ;

    // console.log( 'settings' , settings ) ;

    // ------------

    Moment.globalLocale = process.env.REACT_APP_LANG ;
    moment.locale( settings.lang );

    // ------------

    let name = process.env.REACT_APP_NAME ;
    let key  = name + '_version' ;

    let current = Version.fromString( version ) ;

    let local = localStorage.getItem( key ) ;

    if( local === null )
    {
        console.log( "---- version initialize:" + current.toString(3) ) ;
        localStorage.setItem( key , current.toString(3) )
    }

    local = Version.fromString( localStorage.getItem( key ) ) ;

    if( current.valueOf() !== local.valueOf() )
    {
        console.log( "---- clear cache, current:" + current.toString(3) + " local:" + local.toString(3) ) ;
        localStorage.clear() ;
        sessionStorage.clear() ;
        localStorage.setItem( key , current.toString(3) )
    }

    console.log( '---- ' + name + ' version : ' + localStorage.getItem( key ) + ' - ' + env) ;
};

export default initialize ;
