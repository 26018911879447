// see https://github.com/alexplumb/material-ui-phone-number

import isString from 'vegas-js-core/src/isString'

let exclude   = process.env.REACT_APP_PHONE_EXCLUDE;
let only      = process.env.REACT_APP_PHONE_ONLY ;
let preferred = process.env.REACT_APP_PHONE_PREFERRED ;
let regions   = process.env.REACT_APP_PHONE_REGIONS ; // 'america', 'europe', 'asia', 'oceania', 'africa'

export default
{
    default   : process.env.REACT_APP_PHONE_DEFAULT,
    exclude   : ( isString(exclude) && exclude !== '' ) ? exclude.split(',') : [] ,
    only      : ( isString(only) && only !== '' ) ? only.split(',') : [] ,
    preferred : ( isString(preferred) && preferred !== '' ) ? preferred.split(',') : [] ,
    regions   : ( isString(regions) && regions !== '' ) ? regions.split(',') : []
};
