import React from 'react'

import PropTypes from 'prop-types'

import clsx from 'clsx'

import isNotNull from 'vegas-js-core/src/isNotNull'
import notEmpty  from 'vegas-js-core/src/strings/notEmpty'

import { Chip } from '@material-ui/core'

import blueGrey from '@material-ui/core/colors/blueGrey'

import withi18n from '../../contexts/i18n/withi18n'

import getLocaleThingLabel from '../../things/getLocaleThingLabel'

import getHorizontalElements from '../getHorizontalElements'

import Thing from '../../things/Thing'

import { ThingCell } from './ThingCell'

export class ConnectionCell extends ThingCell
{
    getContent = () =>
    {
        const { thing } = this.props;
        if ( thing )
        {
            const elements = [ 'from' ] ;
            return getHorizontalElements( { elements , factory:this } ) ;
        }
        return null;
    };

    getFrom = ( { className = null } = {}  ) =>
    {
        let { thing : { from } = {} } = this.props ;
        if( from instanceof Array && from.length > 0 )
        {
            from = from.map( ( element , index ) =>
            {
                if( element instanceof Thing )
                {
                    let { bgcolor:backgroundColor, color } = element ;

                    backgroundColor = notEmpty(backgroundColor) ? '#' + backgroundColor : blueGrey[50] ;
                    color           = notEmpty(color) && color !== '' ? '#' + color : blueGrey[800] ;

                    return (
                    <Chip
                        className = "mr-12 my-8"
                        style     = {{ backgroundColor , color }}
                        key       = { 'item-chip-' + index }
                        label     = { getLocaleThingLabel( element ) }
                    />);
                }
                return null ;
            }).filter( isNotNull )  ;

            if( from.length > 0 )
            {
                return (
                    <div className={ clsx( 'flex flex-row flex-wrap' , className ) } >
                        { from }
                    </div>
                )
            }
        }
        return null ;
    }

    getLocale = () => this.props.locale.display.cells.connection;

    getThing = () =>
    {
        const { thing : { to } = {} } = this.props ;
        return to ;
    }
}

ConnectionCell.defaultProps =
{
    ...ThingCell.defaultProps,
    from    : true,
    footer  : false,
    typable : false
};

ConnectionCell.propTypes =
{
    ...ThingCell.propTypes,
    from : PropTypes.bool
};

export default withi18n( ConnectionCell ) ;
