import grid from './grid'

const courses =
{
    ...grid,
    helmet :
    {
        title : 'Parcours' ,
        metas : []
    },
    empty  : 'Aucun parcours trouvé',
    search : 'Rechercher un parcours ...' ,
    title  : 'Parcours'
};

export default courses ;
