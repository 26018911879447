import initDialog from '../initDialog'

import { SelectThingDialog , styles } from '../SelectThingDialog'

import api from '../../../configs/api'

import Organization from '../../../things/Organization'

class SelectOrganizationDialog extends SelectThingDialog
{
    getLocale = () => this.props.locale.dialogs.select.organization ;
}

SelectOrganizationDialog.defaultProps =
{
    ...SelectThingDialog.defaultProps,
    clazz : Organization ,
    uri   : api.organizations.url
};

SelectOrganizationDialog.propTypes =
{
    ...SelectThingDialog.propTypes,
};

export default initDialog({ styles , useSearch:false })( SelectOrganizationDialog ) ;
