const FormElements =
{
    COLOR_TEXTFIELD     : 'color_textfield' ,
    DATE                : 'date' ,
    DATETIME            : 'datetime' ,
    HOURS               : 'hours',
    I18N_EDITOR         : 'i18n_editor' ,
    IMAGE_THING         : 'image' ,
    MAP_GEO             : 'map_geo' ,
    PHONE_NUMBER        : 'phone_number' ,
    SEARCH_SELECTOR     : 'search_selector' ,
    SELECTOR            : 'selector' ,
    SWITCH              : 'switch',
    TEXTFIELD           : 'textfield' ,
    TEXTFIELD_VALIDATOR : 'textfield_validator' , // https://www.npmjs.com/package/react-material-ui-form-validator
    TIME                : 'time' ,
    THESAURUS_SELECTOR  : 'thesaurus_selector'
} ;

export default FormElements  ;
