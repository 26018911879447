import React from 'react'

import PlaceIcon from '@material-ui/icons/Place'

import withDatasGrid from './withDatasGrid'
import { ThingsGrid } from './ThingsGrid'

import Place     from '../../things/Place'
import PlaceCell from '../cells/PlaceCell'

import AddPlaceDialog    from '../dialogs/add/AddPlaceDialog'
import RemovePlaceDialog from '../dialogs/remove/RemovePlaceDialog'

class PlacesGrid extends ThingsGrid
{
    getLocale = () => this.props.locale.display.grids.places ;
}

PlacesGrid.defaultProps =
{
    ...ThingsGrid.defaultProps,
    AddDialogComponent    : AddPlaceDialog,
    CellComponent         : PlaceCell ,
    clazz                 : Place,
    icon                  : <PlaceIcon />,
    mediaIcon             : <PlaceIcon fontSize='large' />,
    mediaProps            : { className : 'bg-hero-topography-2' },
    preferable            : true ,
    queries               : { sort : '-modified' },
    RemoveDialogComponent : RemovePlaceDialog
};

PlacesGrid.propTypes =
{
    ...ThingsGrid.propTypes
};

export default withDatasGrid()( PlacesGrid ) ;
