import React from 'react'

import moment from 'moment/moment'

import compose  from 'vegas-js-core/src/functors/compose'
import isString from 'vegas-js-core/src/isString'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import initApp from '../../contexts/app/initApp'

import { Link , withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { Grid } from '@material-ui/core'

import LocalHospitalIcon from '@material-ui/icons/LocalHospital'

import LabelItem from '../../components/items/LabelItem'

import ThingContainer from '../containers/ThingContainer'

import api from '../../configs/api'

import ThingVO        from '../../things/Thing'
import VeterinarianVO from '../../things/Veterinarian'

const styles = () => ({});

const half =
{
    md :  6 ,
    sm : 12 ,
    xs : 12
};

class Veterinarian extends ThingContainer
{
    getContent = () =>
    {
        let { lang, thing } = this.props;

        const locale = this.getLocale() ;

        const { labels } = locale ;

        let authority ;
        let identifier ;
        let options ;

        if( thing instanceof VeterinarianVO )
        {
            let {
                authority  : a,
                identifier : i
            } = thing ;

            if( isString( i ) )
            {
                identifier = i ;
            }

            if( a instanceof ThingVO )
            {
                options =
                {
                    component : Link ,
                    to        :
                    {
                        pathname : a.url.split(api.url)[1]
                    }
                };
                authority = a.getLocaleName( lang ) ;
            }
            else
            {
                authority = null ;
            }
        }

        return (
            <Grid container spacing={4}>
                <Grid item {...half}>
                    <LabelItem
                        enabled = {!!(authority)}
                        title   = { labels.name }
                        { ...options }
                    >
                        { isString(authority) ? ucFirst(authority) : labels.empty }
                    </LabelItem>
                </Grid>
                <Grid item {...half}>
                    <LabelItem enabled={!!(identifier)} title={ labels.identifier }>
                        { isString(identifier) && identifier !== '' ? identifier : labels.empty }
                    </LabelItem>
                </Grid>
            </Grid>
        );
    };

    getEntry = init =>
    {
        let empty = {} ;
        if( init instanceof ThingVO )
        {
            empty = init.toObject() ;
        }
        else if( init )
        {
            empty = { ...init } ;
        }
        return new VeterinarianVO(empty);
    };

    getLocale = () => this.props.locale.things.veterinarian;

    onEdit = ( item ) =>
    {
        const { onChange, thing } = this.props ;
        if( thing && item )
        {
            const {
                name,
                identifier,
            } = item ;

            thing.identifier = identifier ;
            thing.modified   = moment(new Date()).toISOString();
            thing.name       = name ;

            if( thing instanceof ThingVO )
            {
                thing.populate();
            }

            if( this._mounted )
            {
                this.forceUpdate() ;
            }

            if( onChange instanceof Function )
            {
                onChange(thing) ;
            }
        }
    }
}

Veterinarian.defaultProps =
{
    ...ThingContainer.defaultProps ,
    icon       : <LocalHospitalIcon/> ,
    optionMode : 'edit'
};

Veterinarian.propTypes =
{
    ...ThingContainer.propTypes
};

export default compose( withStyles( styles ) , withRouter , initApp )( Veterinarian ) ;
