
import Form from '../../../display/forms/FormElements'

import Method from '../../../net/Method'

const elements =
[
    {
        type : Form.COLOR_TEXTFIELD ,
        init :
        {
            id           : 'color',
            key          : 'color',
            label        : 'Couleur',
            autoComplete : 'off' ,
            autoFocus    : false,
            fullWidth    : true,
            margin       : 'normal',
            placeholder  : 'RRGGBB',
            required     : true,
            select       : false,
            type         : 'text',
            variant      : 'outlined'
        }
    },
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'identifier',
            key          : 'identifier',
            label        : 'Identifiant',
            autoFocus    : false,
            fullWidth    : true,
            margin       : 'normal',
            required     : true,
            select       : false,
            autoComplete : 'off' ,
            type         : 'text',
            variant      : 'outlined'
        }
    }
];

const team =
{
    // -------- settings

    edit  :
    {
        elements,
        agree       : 'Sauver' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour modifier cette équipe.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...' ,
        method      : Method.PATCH,
        mock        : false,
        success     : 'Mise à jour réussie!' ,
        title       : 'Modifier l\'équipe' ,
    },
    labels :
    {
        empty      : '----' ,
        color      : 'Couleur' ,
        identifier : 'ID',
        name       : 'Nom'
    },
    title    : 'Équipe' ,
    tooltips :
    {
        add    : 'Ajouter',
        edit   : 'Modifier',
        remove : 'Supprimer'
    }
};

export default team ;
