import React from 'react'

import PropTypes from 'prop-types'

import clsx from 'clsx'

import notEmpty from 'vegas-js-core/src/strings/notEmpty'

import { Link, Typography } from '@material-ui/core'

import api    from '../../configs/api'
import user   from '../../contexts/user/user'
import stored from '../../net/stored'

import withi18n from '../../contexts/i18n/withi18n'

import Team      from '../../vo/Team'
import TeamLabel from '../labels/TeamLabel'
import User      from '../../vo/User'

import { ThingCell } from './ThingCell'

import getHorizontalElements from '../getHorizontalElements'

class UserCell extends ThingCell
{
    getBadgeContent = () => ' ' || this.props.badge ;

    getContent = () =>
    {
        const { thing } = this.props;
        if ( thing )
        {
            const elements = [ 'team' ] ;
            return getHorizontalElements( { elements , factory:this } ) ;
        }
        return null ;
    }

    getLocale = () => this.props.locale.display.cells.user;

    getLocaleSubtitle = () =>
    {
        const thing = this.getThing();
        if( thing instanceof User )
        {
            const { email } = thing ;
            if ( notEmpty(email) )
            {
                return email ;
            }
        }
        return null ;
    }

    getSubtitle = ( { className = null } = {} ) =>
    {
        let text = this.getLocaleSubtitle() ;
        if( notEmpty(text) )
        {
            let me ;
            const thing = this.getThing();
            if( thing instanceof User )
            {
                me = ( stored(user.storageID) && (user.uuid != null) && (user.uuid === thing.uuid) ) ;
                if( me )
                {
                    const { meClassName, meProps, meStyle } = this.props ;
                    const { me:label } = this.getLocale() ;
                    me = (
                        <Typography
                            className = { clsx( 'mr-8 rounded px-8 py-2 text-white text-lg' , meClassName ) }
                            style     = { meStyle }
                            variant   = 'caption'
                            { ...meProps }
                        >
                            { label }
                        </Typography>
                    );
                }
                else
                {
                    me = null ;
                }
            }

            return (
                <div className={ clsx("flex flex-row items-center", className) } >
                    { me }
                    <Link
                        color     = 'secondary'
                        href      = { 'mailto:' + text }
                        underline = 'none'
                        variant   = 'caption'
                    >
                        { text }
                    </Link>
                </div>
            );
        }
        else
        {
            return null ;
        }
    };

    getLocaleTitle = () =>
    {
        const thing = this.getThing();
        if( thing instanceof User )
        {
            return thing.getFullNameOrUsername( this.props.lang ) ;
        }
        return null ;
    };

    getPath = () =>
    {
        const thing = this.getThing() ;
        if( thing instanceof User )
        {
            const { uuid } = thing ;
            if( stored(user.storageID) && (user.uuid != null) && (user.uuid === uuid) )
            {
                return this.props.me ;
            }
            else
            {
                const url = this.getUrl();
                if ( notEmpty(url) )
                {
                    return url.split(api.url)[1] ;
                }
            }
        }
        return null ;
    }

    getTeam = ( { className = null } = {} ) =>
    {
        const { team:visible } = this.props ;
        if( visible )
        {
            const user = this.getThing() ;
            if( user instanceof User )
            {
                const { team } = user ;
                if( team instanceof Team )
                {
                    return <TeamLabel className={className} thing={user} />
                }
            }
        }
        return null ;
    }
}

UserCell.defaultProps =
{
    ...ThingCell.defaultProps,
    me          : '/me/profile' ,
    meClassName : "bg-red-800" ,
    meProps     : null ,
    meStyle     : null ,
    team        : true
};

UserCell.propTypes =
{
    ...ThingCell.propTypes,
    me          : PropTypes.string ,
    meClassName : PropTypes.string ,
    meProps     : PropTypes.object ,
    meStyle     : PropTypes.object ,
    team        : PropTypes.bool
};

export default withi18n(UserCell) ;
