import React from 'react'

import clsx from 'clsx'

import format   from 'vegas-js-core/src/strings/fastformat'
import notEmpty from 'vegas-js-core/src/strings/notEmpty'
import truncate from 'vegas-js-core/src/strings/truncate'

import DescriptionIcon from '@material-ui/icons/Notes'
import NoteIcon        from '@material-ui/icons/SpeakerNotes'
import SubtitleIcon    from '@material-ui/icons/ShortText'
import TextIcon        from '@material-ui/icons/Subject'
import TitleIcon       from '@material-ui/icons/Title'

import sanitizeHtml from 'sanitize-html'

import withConfig from '../../../contexts/config/withConfig'
import withi18n   from '../../../contexts/i18n/withi18n'

import MultiLangEditor from '../../../components/editors/MultiLangEditor'

import Audio             from '../../../display/things/medias/Audio'
import Image             from '../../../display/things/medias/Image'
import Metadatas         from '../../../display/things/Metadatas'
import Step              from '../../../display/things/Step'
import ThingSubContainer from '../../../display/containers/ThingSubContainer'
import Video             from '../../../display/things/medias/Video'

const sanitizeAll = { allowedTags: [], allowedAttributes: {} };

const getLocaleResume = ( element , lang , length = -1 , prune = "..." )=>
{
    if( element && notEmpty(element[lang]) )
    {
        const text = sanitizeHtml( element[lang], sanitizeAll )
        if( notEmpty( text ))
        {
            return ( length >= 0 ) ? truncate( text , length , prune ) : text ;
        }
    }
    return null ;
}

class About extends ThingSubContainer
{
    getLocale = () => this.props.locale.views.step.about ;

    getPlaceHolders = ( thing , placeHolders =
    {
        alternativeHeadline : null,
        description         : null, 
        headline            : null, 
        notes               : null , 
        text                : null
    }) =>
    {
        if( thing )
        {
            const { lang } = this.props ;
            let {
                alternativeHeadline,
                description, 
                headline, 
                notes , 
                text 
            }
            = thing ;
            
            alternativeHeadline = getLocaleResume( alternativeHeadline , lang ,  90 ) ;
            headline            = getLocaleResume( headline            , lang ,  90 ) ;
            description         = getLocaleResume( description         , lang , 140 ) ; 
            notes               = getLocaleResume( notes               , lang , 240 ) ;
            text                = getLocaleResume( text                , lang , 340 ) ;
            
            placeHolders =
            { 
                ...placeHolders,
                ...( alternativeHeadline && { alternativeHeadline } ), 
                ...( headline            && { headline } ), 
                ...( description         && { description } ), 
                ...( notes               && { notes } ), 
                ...( text                && { text } ), 
            } ;
        }
        
        return placeHolders ;
    };
    
    render = () =>
    {
        const { thing } = this.props ;
        if( thing )
        {
            const {
                className,
                path,
                style,
            } = this.props ;

            const {
                alternativeHeadline,
                description,
                headline,
                id,
                notes,
                stage,
                text
            } = thing ;

            const locale = this.getLocale() ;

            let placeholders = this.getPlaceHolders( stage ) ;
            
            const { onRemove } = this.props ;

            const content = (
                <div
                    className = { clsx( 'flex-1 p-12' , className ) }
                    style     = { style }
                >

                    <div className="flex-1 flex flex-col space-y-24">

                        <Metadatas
                            activable = { true }
                            deletable = { true }
                            onRemove  = { onRemove }
                            thing     = { thing }
                            uri       = { format(path.step,id) }
                        />

                        <Step
                            editUri  = { format(path.step,id) }
                            onChange = { this.change }
                            thing    = { thing }
                        />

                        <div className='grid grid-cols-1 sm:grid-cols-2 gap-16'>
                            <MultiLangEditor
                                html        = { false }
                                icon        = { <TitleIcon/> }
                                input       = { true }
                                locale      = { locale.headline }
                                onChange    = { this.changeProperty('headline') }
                                placeholder = { placeholders.headline }
                                text        = { headline }
                                uri         = { format( path.headline, id ) }
                            />
                            <MultiLangEditor
                                html        = { false }
                                icon        = { <SubtitleIcon/> }
                                input       = { true }
                                locale      = { locale.alternativeHeadline }
                                onChange    = { this.changeProperty('alternativeHeadline') }
                                placeholder = { placeholders.alternativeHeadline }
                                text        = { alternativeHeadline }
                                uri         = { format( path.alternativeHeadline, id ) }
                            />
                            <MultiLangEditor
                                html        = { false }
                                icon        = { <DescriptionIcon/> }
                                locale      = { locale.description }
                                onChange    = { this.changeProperty('description') }
                                placeholder = { placeholders.description }
                                text        = { description }
                                uri         = { format( path.description, id ) }
                            />
                            <Image
                                onChange = { this.change }
                                thing    = { thing }
                                uri      = { path.image }
                            />
                        </div>

                        <MultiLangEditor
                            icon        = { <TextIcon/> }
                            locale      = { locale.text }
                            onChange    = { this.changeProperty('text') }
                            placeholder = { placeholders.text }
                            text        = { text }
                            uri         = { format( path.text, id ) }
                        />

                        <MultiLangEditor
                            icon        = { <NoteIcon/> }
                            locale      = { locale.notes }
                            onChange    = { this.changeProperty('notes') }
                            placeholder = { placeholders.notes }
                            text        = { notes }
                            uri         = { format( path.notes, id ) }
                        />

                        <div className='grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-16'>
                            <div>
                                <Audio
                                    onChange = { this.change }
                                    thing    = { thing }
                                    uri      = { path.audio }
                                />
                            </div>
                            <div>
                                <Video
                                    onChange = { this.change }
                                    thing    = { thing }
                                    uri      = { path.video }
                                />
                            </div>
                        </div>

                    </div>
                </div>
            );
            
            return this.renderWithTransition( content ) ;
        }

        return null;
    }
}

About.defaultProps =
{
    ...ThingSubContainer.defaultProps
};

About.propTypes =
{
    ...ThingSubContainer.propTypes
};

export default withConfig( withi18n( About ) ) ;
