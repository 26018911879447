import list from './list'

const games =
{
    ...list,
    helmet :
    {
        title : 'Jeux' ,
        metas : []
    },
    empty  : 'Aucun jeu trouvé',
    search : 'Rechercher un jeu ...' ,
    title  : 'Jeux'
};

export default games ;
