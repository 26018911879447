import React from 'react'

import PropTypes from 'prop-types'

import compose from "vegas-js-core/src/functors/compose"

import { withStyles } from '@material-ui/core/styles'

import { Typography } from '@material-ui/core'

import isString from 'vegas-js-core/src/isString'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import Container from '../../../components/containers/Container'

import withConfig from '../../../contexts/config/withConfig'
import withi18n   from '../../../contexts/i18n/withi18n'

import MaterialVO from '../../../things/Material'
import WordVO     from '../../../things/Word'

const styles = () => (
{
    root :
    {
        padding : '16px 0px'
    }
});

class Material extends Container
{
    getLocale = () => this.props.locale.things.conceptualObject.material ;

    render = () =>
    {
        const { thing } = this.props ;
        if( thing instanceof MaterialVO )
        {
            const {
                classes,
                editable,
                lang,
                onClick,
                style
            } = this.props ;

            const locale = this.getLocale() ;

            let {
                material,
                technique
            } = thing ;

            let title ;
            let subtitle ;

            let name        = thing.getLocaleAlternateName( lang ) ;
            let description = thing.getLocaleDescription( lang );

            if( isString( description ) && description !== '' )
            {
                description = ucFirst(description) ;
                description = (
                <Typography
                    className = 'text-gray-800'
                    variant   = 'body2'
                >
                    {description}
                </Typography>
                ) ;
            }
            else
            {
                description = null ;
            }

            if( isString( name ) && name !== '' )
            {
                title = ucFirst(name) ;
            }
            else
            {
                title = null ;
            }

            let elements = [] ;

            if( material instanceof WordVO )
            {
                let str = material.getLocaleName(lang) ;
                if( isString( str ) && str !== '' )
                {
                    elements.push(ucFirst(str)) ;
                }
            }

            if( technique instanceof WordVO )
            {
                let str = technique.getLocaleName( lang ) ;
                if( isString( str ) && str !== '' )
                {
                    elements.push(ucFirst(str)) ;
                }
            }

            if( elements.length > 0 )
            {
                elements = elements.join( locale.separator ) ;
            }
            else
            {
                elements = null ;
            }

            if( isString( elements ) )
            {
                if( title === null )
                {
                    title = elements ;
                }
                else
                {
                    subtitle = (
                    <Typography className="text-grey-800" variant='subtitle2'>
                        { elements }
                    </Typography>
                    ) ;
                }
            }

            if( title === null )
            {
                title = locale.unknown ;
            }

            if( editable )
            {
                title = (
                <button onClick = { onClick }>
                    <Typography className="cursor-pointer text-blue-800 font-medium" variant='subtitle1'>
                        { title }
                    </Typography>
                </button>
                );
            }
            else
            {
                title = (
                <Typography className='text-gray-800' variant='subtitle1'>
                    { title }
                </Typography>);
            }


            return (
            <div className={ classes.root } style={style}>
                { title }
                { subtitle }
                { description }
            </div>
            );
        }

        return null ;
    };
}

Material.defaultProps =
{
    ...Container.defaultProps,
    editable : false ,
    onClick  : null,
    thing    : null
};

Material.propTypes =
{
    ...Container.propTypes,
    editable : PropTypes.bool,
    onClick  : PropTypes.func,
    thing    : PropTypes.object
};

export default compose(
    withStyles( styles ),
    withConfig,
    withi18n
)( Material );
