const technician =
{
    labels :
    {
        empty      : '----' ,
        name       : 'Nom'
    },
    title    : 'Technicien' ,
    tooltips :
    {
        add    : 'Ajouter',
        edit   : 'Modifier',
        remove : 'Supprimer'
    }
};

export default technician ;