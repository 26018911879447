import Thing from "../things/Thing";

class Team extends Thing
{
    /**
     * Creates a new Team instance.
     * @constructor
     * @param object The generic object to initialize the object the first time.
     */
    constructor( object = null )
    {
        super() ;

        this.color = null ;

        this.permissions = null ;

        this.set( object ) ;
    }

    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        super.clear();
        this.color =
        this.permissions = null ;
        return this ;
    }

    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new Team( this.toObject() ) ;
    }

    populate()
    {
        return this ;
    }

    /**
     * Returns the generic object representation of the object.
     * @returns {Object}
     */
    toObject()
    {
        let {
            color,
            permissions
        } = this ;

        return {
            ...super.toObject() ,
            color,
            permissions
        }
    }
}

export default Team ;