import React from 'react'

import PropTypes from 'prop-types'

import isBoolean from 'vegas-js-core/src/isBoolean'
import isString  from 'vegas-js-core/src/isString'
import notEmpty  from 'vegas-js-core/src/strings/notEmpty'

import { Typography } from '@material-ui/core'

import shortid from 'shortid'

import Method       from '../../net/Method'
import UploadStatus from '../../net/UploadStatus'

import Dropper      from '../../components/upload/Dropper'
import FileUploader from '../../components/upload/FileUploader'

import initDialog from './initDialog'
import CoreDialog from '../../components/dialogs/CoreDialog'
import defaultImageOptions from '../../configs/imageOptions'

const styles = () => ({}) ;

export class UploadDialog extends CoreDialog
{
    constructor( props )
    {
        super( props );
        this.queue = [] ;
        this.state =
        {
            ...this.state ,
            current : null ,
            entries : [] ,
            chain   : [] ,
            status  : UploadStatus.PENDING
        };
    }

    agree = () =>
    {
        if( this.isReady() )
        {
            this.queue = [] ;
            let { entries } = this.state ;
            if( entries instanceof Array && entries.length > 0 )
            {
                entries = entries.map( entry =>
                {
                    const { status } = entry ;
                    if( status === UploadStatus.READY )
                    {
                        entry.status = UploadStatus.UPLOADING;
                        this.queue.push( entry ) ;
                    }
                    return entry ;
                });
                this.setState( {
                    entries ,
                    current : null,
                    status  : UploadStatus.UPLOADING
                } , () =>
                {
                    this.next() ;
                });
            }
        }
    };

    clear = () => { this.setState({ entries:[] }); };

    hasNext = () => this.queue instanceof Array && this.queue.length > 0 ;

    init = () =>
    {
        this.dropperID = 'upload_dialog_dropper_' + shortid.generate();
    };

    isReady = () =>
    {
        let { entries } = this.state ;
        if( entries instanceof Array && entries.length > 0 )
        {
            return entries.every( entry => entry.status === UploadStatus.READY ) ;
        }
        return false ;
    };

    getContent = () =>
    {
        let {
            acceptedFiles,
            filePropName,
            imageOptions,
            method,
            mock,
            multiple,
            uri,
        }
        = this.props ;

        mock         = mock         || this.mock ;
        imageOptions = imageOptions || this.imageOptions ;

        const locale = this.getLocale() ;
        if( locale )
        {
            let {
                filePropName  : propName,
                method        : met,
                mock          : flag,
                imageOptions  : io
            } = locale ;

            if( isString(propName) )
            {
                filePropName = propName ;
            }

            if( isString(met) )
            {
                method = met ;
            }

            if( isBoolean(flag) )
            {
                mock = flag ;
            }

            if( io )
            {
                imageOptions = io ;
            }
        }

        imageOptions = { ...defaultImageOptions , imageOptions } ;

        let content ;

        let { entries, status } = this.state ;
        if( entries instanceof Array && entries.length > 0 )
        {
            const { current } = this.state ;

            content = entries.map( entry =>
            {
                if( entry )
                {
                    const {
                        file,
                        id,
                        name,
                        status
                    }
                    = entry;

                    return (
                    <FileUploader
                        divider       = { multiple }
                        id            = { id }
                        file          = { file }
                        filePropName  = { filePropName }
                        imageOptions  = { imageOptions }
                        key           = { id }
                        method        = { method }
                        mock          = { mock }
                        name          = { name }
                        upload        = { status === UploadStatus.UPLOADING && current === entry }
                        onCancel      = { this._fileUploadCanceled }
                        onFail        = { this._fileUploadFailed   }
                        onReady       = { this._fileUploadReady    }
                        onResize      = { this._fileUploadResize   }
                        onUpload      = { this._fileUploaded       }
                        uri           = { uri }
                    />);
                }
                return null ;
            });

            content = (
                <div className='p-12'>
                    { content }
                </div>
            );
        }
        else
        {
            if( acceptedFiles instanceof Function )
            {
                acceptedFiles = acceptedFiles( this ) ;
            }
            content = (
            <Dropper
                acceptedFiles = { acceptedFiles }
                id            = { this.dropperID }
                disabled      = { status === UploadStatus.UPLOADING }
                multiple      = { multiple }
                rejected      = { this._fileRejected }
                onFileDrop    = { this._fileDrop }
            />);
        }

        return content ;
    };

    getDescription = () =>
    {
        const locale = this.getLocale() ;
        if( locale )
        {
            let text ;

            const { multiple } = this.props ;
            const { status   } = this.state ;

            switch( status )
            {
                case UploadStatus.PENDING :
                {
                    let { pending , pendingX } = locale ;
                    text = multiple ? pendingX : pending ;
                    break ;
                }

                case UploadStatus.READY :
                {
                    let { ready , readyX } = locale ;
                    text = multiple ? readyX : ready ;
                    break ;
                }

                case UploadStatus.UPLOADING :
                {
                    let { uploading } = locale ;
                    text = uploading ;
                    break ;
                }

                case UploadStatus.UPLOADED :
                {
                    let { done } = locale ;
                    text = done ;
                    break ;
                }

                default :
                {
                    // do nothing ;
                }
            }

            if( notEmpty(text) )
            {
                return <Typography className='my-8' variant='body1'>{ text }</Typography> ;
            }
        }
        return null ;
    };

    getLocale = () => this.props.locale.dialogs.upload ;

    getTitleLabel = () =>
    {
        const locale = this.getLocale() ;
        if( locale )
        {
            let { title , titleX } = locale ;
            title = this.props.multiple ? titleX : title ;
            if( notEmpty( title ) )
            {
                return title ;
            }
        }
        return null ;
    };

    showAgreeButton = () => this.props.showAgreeButton && this.isReady()  ;

    _cancel = () =>
    {
        this.setState({ errors:null, status:UploadStatus.ABORTED }) ;
        const { onCancel } = this.props ;
        if( onCancel instanceof Function )
        {
            onCancel() ;
        }
    };

    _changeFileStatus = ( key , status , options ) =>
    {
        let { entries } = this.state ;
        if( entries instanceof Array && entries.length > 0 )
        {
            entries.forEach( entry =>
            {
                const { id } = entry ;
                if( key === id )
                {
                    entry.status = status ;
                    if( options )
                    {
                        for( let prop in options )
                        {
                            if( entry.hasOwnProperty(prop) )
                            {
                                entry[prop] = options[prop];
                            }
                        }
                    }
                }
            });

            this.setState( { entries } , () =>
            {
                if( this.isReady() )
                {
                    this.setState({ status:UploadStatus.READY }) ;
                }
            });
        }
    };

    next = () =>
    {
        if( this.hasNext() )
        {
            let current = this.queue.shift() ;
            this.setState({ current });
        }
    };

    _checkFinished = () =>
    {
        if( this.hasNext() )
        {
            this.next() ;
        }
        else
        {
            let pending = 0 ;

            let { entries } = this.state ;
            if( entries instanceof Array && entries.length > 0 )
            {
                entries.forEach( entry =>
                {
                    if(
                        entry.status === UploadStatus.PENDING   ||
                        entry.status === UploadStatus.RESIZING  ||
                        entry.status === UploadStatus.UPLOADING
                    )
                    {
                        pending++ ;
                    }
                });
            }

            if( pending === 0 )
            {
                this.setState( { status:UploadStatus.UPLOADED });
                this.close() ;
                const { onUpload } = this.props ;
                if( onUpload instanceof Function )
                {
                    onUpload( entries ) ;
                }
            }
        }
    };

    // ------ dropper

    _fileDrop = items =>
    {
        if( items instanceof Array && items.length > 0 )
        {
            let entries = [] ;
            const { multiple } = this.props ;
            if( multiple )
            {
                entries = [ ...items ] ;
            }
            else
            {
                entries = [ items[0] ] ;
            }
            this.setState( { entries } );
        }
    };

    _fileRejected = ( file , message ) =>
    {
        console.log( this + ' reject', message, file );
        // FIXME with an i18n snap message
    };

    // ------ file uploader

    _fileUploadCanceled = props =>
    {
        const { id } = props ;
        this._changeFileStatus( id , UploadStatus.ABORTED );
        this._checkFinished() ;
    };

    _fileUploadFailed = ( response, props ) =>
    {
        const { id } = props ;
        // console.log( this + ' _fileUploadFailed' , id);
        this._changeFileStatus( id , UploadStatus.FAILED );
        this._checkFinished() ;
    };

    _fileUploadReady = ( file , id ) =>
    {
        this._changeFileStatus( id , UploadStatus.READY ) ;
    }

    _fileUploadResize = ( file , id ) =>
    {
        this._changeFileStatus( id , UploadStatus.RESIZING ) ;
    }

    _fileUploaded = ( result , props  ) =>
    {
        const { id } = props ;
        this._changeFileStatus( id , UploadStatus.UPLOADED , { result } );
        this._checkFinished() ;
    };
}

UploadDialog.defaultProps =
{
    ...CoreDialog.defaultProps,
    acceptedFiles        : ( { props : { config : { acceptedUploadMedias : { all:acceptedFiles } = {} } = {} } = {} } = {} ) => acceptedFiles ,
    disableBackdropClick : false,
    filePropName         : 'file' ,
    imageOptions         : defaultImageOptions ,
    maxWidth             : 'md',
    method               : Method.POST,
    mock                 : false,
    multiple             : false,
    onUpload             : null
};

UploadDialog.propTypes =
{
    ...CoreDialog.propTypes,
    acceptedFiles : PropTypes.oneOfType([PropTypes.func,PropTypes.string,PropTypes.arrayOf(PropTypes.string)]),
    filePropName  : PropTypes.string,
    imageOptions  : PropTypes.object ,
    method        : PropTypes.oneOf([Method.POST,Method.PATCH,Method.PUT]),
    multiple      : PropTypes.bool,
    onUpload      : PropTypes.func ,
};


export default initDialog( { styles } )(UploadDialog);
