import React, { Component } from 'react'

import clsx from 'clsx'

import isNumber from 'vegas-js-core/src/isNumber'

import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'

import { CircularProgress, Typography } from '@material-ui/core'

import blueGrey from '@material-ui/core/colors/blueGrey'
import red      from '@material-ui/core/colors/red'

import AudioIcon         from '@material-ui/icons/MusicNote'
import FailIcon          from '@material-ui/icons/MusicOff'

import getLocaleHeadline from '../../things/getLocaleHeadline'
import getLocaleName     from '../../things/getLocaleName'
import getMediaSource    from '../../things/getMediaSource'

const styles = () =>
({
    root :
    {
        alignItems      : 'center',
        backgroundColor : blueGrey[50],
        display         : 'flex',
        flexDirection   : 'column',
        height          : 160,
        justifyContent  : 'center',
        width           : '100%'
    }
});

class AudioPlayer extends Component
{
    constructor( props )
    {
        super(props) ;
        this.element = null ;
        this.timer   = null ;
        this.state   =
        {
            status : AudioPlayer.NEW
        };
    }

    // ------- status

    static FAILED   = 'failed' ;
    static NEW      = 'new';
    static LOADED   = 'loaded' ;
    static PROGRESS = 'progress';

    // -------

    componentDidMount = () =>
    {
        const { volume } = this.props ;

        this.updateVolume(volume);

        const audio = this.element;
        if( audio )
        {
            audio.addEventListener( 'abort'          , this.abort  );
            audio.addEventListener( 'canplay'        , this.canplay );
            audio.addEventListener( 'canplaythrough' , this.canplaythrough );
            audio.addEventListener( 'durationchange' , this.durationchange );
            audio.addEventListener( 'ended'          , this.ended );
            audio.addEventListener( 'error'          , this.error );
            audio.addEventListener( 'loadedmetadata' , this.loadedmetadata );
            audio.addEventListener( 'loadstart'      , this.loadstart );
            audio.addEventListener( 'pause'          , this.pause );
            audio.addEventListener( 'play'           , this.play );
            audio.addEventListener( 'progress'       , this.progress );
            audio.addEventListener( 'seeked'         , this.seeked );
            audio.addEventListener( 'timeupdate'     , this.timeupdate );
            audio.addEventListener( 'volumechange'   , this.volumechange );
        }
    };

    componentDidUpdate = ( prevProps ) =>
    {
        const { volume } = this.props ;
        if( volume !== prevProps.volume )
        {
            this.updateVolume(volume);
        }
    };

    componentWillUnmount = () =>
    {
        this.stopLoading();
        const audio = this.element;
        if( audio )
        {
            audio.removeEventListener( 'abort'          , this.abort  );
            audio.removeEventListener( 'canplay'        , this.canplay );
            audio.removeEventListener( 'canplaythrough' , this.canplaythrough );
            audio.removeEventListener( 'durationchange' , this.durationchange );
            audio.removeEventListener( 'ended'          , this.ended );
            audio.removeEventListener( 'error'          , this.error );
            audio.removeEventListener( 'loadedmetadata' , this.loadedmetadata );
            audio.removeEventListener( 'loadstart'      , this.loadstart );
            audio.removeEventListener( 'pause'          , this.pause );
            audio.removeEventListener( 'play'           , this.play );
            audio.removeEventListener( 'progress'       , this.progress );
            audio.removeEventListener( 'seeked'         , this.seeked );
            audio.removeEventListener( 'timeupdate'     , this.timeupdate );
            audio.removeEventListener( 'volumechange'   , this.volumechange );
        }
    };

    abort = event =>
    {
        this.stopLoading();
        const { onAbort } = this.props ;
        if( onAbort )
        {
            onAbort( this.element, event );
        }
        this.setState( { status:AudioPlayer.NEW })
    };

    canplay = event =>
    {
        this.setState( { status:AudioPlayer.LOADED });
        const { onCanPlay } = this.props ;
        if( onCanPlay )
        {
            onCanPlay( event ) ;
        }
    };

    canplaythrough = event =>
    {
        this.setState( { status:AudioPlayer.LOADED });
        const { onCanPlayThrough } = this.props ;
        if( onCanPlayThrough )
        {
            onCanPlayThrough( event ) ;
        }
    };

    durationchange = event =>
    {
        const { onDurationChange } = this.props ;
        if( onDurationChange )
        {
            onDurationChange( this.element, event );
        }
    };

    ended = event =>
    {
        this.stopLoading();
        const { onEnded } = this.props ;
        if( onEnded )
        {
            onEnded( this.element, event );
        }
    };

    error = event =>
    {
        this.setState( { status:AudioPlayer.FAILED });
        const { onError } = this.props ;
        if( onError )
        {
            onError( this.element, event );
        }
    };

    load = () =>
    {
        if( this.element )
        {
            const { onLoad } = this.props ;
            if( onLoad )
            {
                const { currentTime, duration } = this.element ;
                onLoad( currentTime, duration, this.element ) ;
            }
        }
    };

    loadedmetadata = event =>
    {
        this.stopLoading();
        this.setState( { status:AudioPlayer.LOADED });
        const { onLoadedMetadata } = this.props ;
        if( onLoadedMetadata )
        {
            onLoadedMetadata( this.element, event );
        }
    };

    loadstart = event =>
    {
        this.setState( { status:AudioPlayer.PROGRESS });
        const { onLoadStart } = this.props ;
        if( onLoadStart )
        {
            onLoadStart( this.element, event );
        }
    };

    pause = event =>
    {
        this.stopLoading();
        const { onPause } = this.props ;
        if( onPause )
        {
            onPause( this.element, event );
        }
    };

    play = event =>
    {
        if( this.element )
        {
            this.startLoading();
            const { onPlay } = this.props ;
            if( onPlay )
            {
                onPlay( this.element, event );
            }
        }
    };

    progress = event =>
    {
        const { onProgress } = this.props ;
        if( onProgress )
        {
            onProgress( event );
        }
    };

    seeked = event =>
    {
        const { onSeeked } = this.props ;
        if( onSeeked )
        {
            onSeeked( this.element, event );
        }
    };

    /**
     * Start the loading process.
     */
    startLoading()
    {
        if ( !this.timer && this.element )
        {
            const { delay } = this.props ;
            this.timer = setInterval( this.load, delay );
        }
    }

    /**
     * Stop the loading process.
     */
    stopLoading()
    {
        if( this.timer )
        {
            clearInterval( this.timer );
            this.timer = null;
        }
    }

    timeupdate = event =>
    {
        const { onTimeUpdate } = this.props ;
        if( onTimeUpdate )
        {
            onTimeUpdate( this.element, event );
        }
    };

    /**
     * Set the volume on the audio element from props
     * @param {Number} volume
     */
    updateVolume = volume =>
    {
        const { element } = this ;
        if ( element && isNumber(volume) && volume !== element.volume )
        {
            element.volume = volume;
        }
    };

    volumechange = event =>
    {
        const { onVolumeChanged } = this.props ;
        if( onVolumeChanged )
        {
            onVolumeChanged( this.element, event );
        }
    };

    render()
    {
        const {
            autoPlay,
            classes,
            className,
            controlsList,
            children,
            controls,
            crossOrigin,
            failIcon,
            icon,
            id,
            lang,
            locale,
            loop,
            media,
            muted,
            onPlay,
            preload,
            style
        } = this.props ;

        if( media )
        {
            const { invalid } = locale ;

            let message = children || (
                <Typography variant='caption'>
                    { invalid }
                </Typography>
            );

            let { status } = this.state ;

            const label = getLocaleHeadline( media , lang ) || getLocaleName( media ) || '' ;

            let content ;

            switch( status )
            {
                case AudioPlayer.FAILED :
                {
                    content = failIcon ;
                    break ;
                }

                case AudioPlayer.LOADED :
                {
                    break ;
                }

                case AudioPlayer.PROGRESS :
                {
                    content = <div>
                        <CircularProgress color="primary" />
                    </div>;
                    break ;
                }

                default :
                {
                    content = icon ;
                    break ;
                }
            }

            const source = getMediaSource( media ) ;

            let sourceElements ;
            if( source )
            {
                sourceElements = source.map( element => <source key={element.encodingFormat} src={ element.contentUrl } type={ element.encodingFormat } /> ) ;
            }

            return (
                <div
                    className = { clsx(classes.root,className) }
                    style     = { style }
                >
                    { content }
                    <audio
                        autoPlay     = { autoPlay }
                        className    = { classes.audio }
                        controls     = { controls }
                        controlsList = { controlsList }
                        crossOrigin  = { crossOrigin }
                        id           = { id }
                        loop         = { loop }
                        muted        = { muted }
                        onPlay       = { onPlay }
                        preload      = { preload }
                        ref          = { (ref) => { this.element = ref ; }}
                        style        = {{
                            ...style ,
                            display :(status === AudioPlayer.LOADED) ? 'initial' : 'none'
                        }}
                        title = { label }
                    >
                        { sourceElements }
                        { message }
                    </audio>
                </div>
            );
        }

        return null ;
  }
}

AudioPlayer.defaultProps =
{
    autoPlay         : false,
    children         : null,
    className        : '',
    controls         : false,
    controlsList     : '',
    crossOrigin      : null,
    delay            : 500,
    failIcon         : <FailIcon style={{ color:red[600], fontSize:56 }} />,
    icon             : <AudioIcon style={{ color:blueGrey[200], fontSize:56 }} />,
    id               : '',
    locale           :
    {
        invalid : 'Your browser does not support the audio element.'
    },
    loop             : false,
    media            : null,
    muted            : false,
    onAbort          : null,
    onCanPlay        : null,
    onCanPlayThrough : null,
    onDurationChange : null,
    onEnded          : null,
    onError          : null,
    onLoadedMetadata : null,
    onLoad           : null,
    onLoadStart      : null,
    onPause          : null,
    onProgress       : null,
    onPlay           : null,
    onSeeked         : null,
    onVolumeChanged  : null,
    preload          : 'metadata',
    style            : null ,
    title            : '',
    volume           : 1
};

AudioPlayer.propTypes =
{
    autoPlay  : PropTypes.bool,
    children  : PropTypes.element,
    className : PropTypes.string,
    classes   : PropTypes.shape(
    {
        audio : PropTypes.string
    }),
    controls         : PropTypes.bool,
    controlsList     : PropTypes.string,
    crossOrigin      : PropTypes.string,
    icon             : PropTypes.element,
    id               : PropTypes.string,
    locale           : PropTypes.object,
    delay            : PropTypes.number,
    loop             : PropTypes.bool,
    media            : PropTypes.object.isRequired,
    muted            : PropTypes.bool,
    onAbort          : PropTypes.func,
    onCanPlay        : PropTypes.func,
    onCanPlayThrough : PropTypes.func,
    onDurationChange : PropTypes.func,
    onEnded          : PropTypes.func,
    onError          : PropTypes.func,
    onLoadedMetadata : PropTypes.func,
    onLoadStart      : PropTypes.func,
    onProgress       : PropTypes.func,
    onPause          : PropTypes.func,
    onPlay           : PropTypes.func,
    onSeeked         : PropTypes.func,
    onTimeUpdate     : PropTypes.func,
    onVolumeChanged  : PropTypes.func,
    preload          : PropTypes.oneOf(['', 'none', 'metadata', 'auto']),
    style            : PropTypes.objectOf(PropTypes.string),
    title            : PropTypes.string,
    volume           : PropTypes.number
};

export default withStyles( styles )(AudioPlayer);
