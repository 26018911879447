import React from 'react'

import Icon from '@material-ui/icons/DateRange'

import withDatasList from './withDatasList'

import ThingsList from './ThingsList'

import Event     from '../../things/Event'
import EventCell from '../cells/EventCell'

import AddEventDialog    from '../dialogs/add/AddEventDialog'
import RemoveEventDialog from '../dialogs/remove/RemoveEventDialog'

class EventsList extends ThingsList
{
    getLocale = () => this.props.locale.display.lists.events ;

    getThingRef = () => this.props.thing ;
}

EventsList.defaultProps =
{
    ...ThingsList.defaultProps ,
    AddDialogComponent    : AddEventDialog,
    CellComponent         : EventCell,
    cellProps             : { variant : 'default' },
    clazz                 : Event,
    icon                  : <Icon />,
    preferable            : true ,
    RemoveDialogComponent : RemoveEventDialog
};

EventsList.propTypes =
{
    ...ThingsList.propTypes
};

export default withDatasList()( EventsList ) ;
