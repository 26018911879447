import React from "react"

import PropTypes from 'prop-types'

import clsx from "clsx"

import leading   from "vegas-js-core/src/numbers/leading"

import { Avatar } from '@material-ui/core'

import Container from '../../../components/containers/Container'

const defaultStyle = { width:40, height:40, textDecoration:'none' } ;

export const bgColors    = [ 'bg-blue-800'       , 'bg-blue-700' ] ;
export const colors      = [ 'text-white'        , 'text-white' ] ;
export const hoverColors = [ 'hover:bg-blue-900' , 'hover:bg-blue-800' ] ;

class StepAvatar extends Container
{
    getBackgroundColor = index =>
    {
        const len = bgColors.length ;
        return bgColors[index%len] ;
    };

    getBackgroundHoverColor = index =>
    {
        const len = hoverColors.length ;
        return hoverColors[index%len] ;
    };

    getColor = index =>
    {
        const len = colors.length ;
        return colors[index%len] ;
    };

    render = () =>
    {
        const {
            className ,
            index,
            onSelect,
            thing,
            style,
            variant
        } = this.props ;

        return (
            <Avatar
                className = { clsx(
                    "cursor-pointer" ,
                    this.getBackgroundColor(index) ,
                    this.getBackgroundHoverColor(index) ,
                    className
                )}
                onClick = { onSelect ? () => onSelect( thing , index ) : null }
                style   = {{ ...defaultStyle , ...style }}
                variant = { variant }
            >
               <span
                   className={ clsx( "text-white font-medium text-shadow hover:no-underline no-underline focus:no-underline" , this.getColor(index) ) }
               >
                   { leading(index+1) }
               </span>
           </Avatar>)
    };
}

StepAvatar.defaultProps =
{
    ...Container.defaultProps,
    className : null,
    index     : 0,
    onSelect  : null,
    style     : null,
    thing     : null,
    variant   : 'rounded'
};

StepAvatar.propTypes =
{
    ...Container.propTypes ,
    className : PropTypes.string,
    index     : PropTypes.number,
    onSelect  : PropTypes.func,
    style     : PropTypes.object,
    thing     : PropTypes.object,
    variant   : PropTypes.oneOf(['circle','rounded','square'])
};

export default StepAvatar ;
