import grid from './grid'

const places =
{
    ...grid,
    helmet :
    {
        title : 'Lieux' ,
        metas : []
    },
    empty  : 'Aucun lieu trouvé',
    search : 'Rechercher un lieu ...' ,
    title  : 'Lieux'
};

export default places ;
