import removeImage from './removeImage'
import uploadImage from './uploadImage'

const buttons =
{
    removeImage,
    uploadImage
};

export default buttons ;
