
import getElements        from './getElements'
import getVerticalDivider from './getVerticalDivider'

const settings = 
{ 
    dividerFactory   : getVerticalDivider  , 
    defaultClassName : "w-full flex flex-col md:flex-row md:flex-wrap md:items-center px-8"
}  ;

const getHorizontalElements = getElements(settings) ;

export default getHorizontalElements ;
