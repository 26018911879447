const nationality =
{
    copy :
    {
        snack   : 'Nationalité copiée dans le presse-papiers.' ,
        tooltip : 'Copier la nationalité'
    },
    title : 'Nationalité'
};

export default nationality ;
