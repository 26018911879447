import analysisMethod     from './analysisMethod'
import analysisMethods    from './analysisMethods'
import analysisSampling   from './analysisSampling'
import analysisSamplings  from './analysisSamplings'
import disease            from './disease'
import observationType    from './observationType'
import transmissionMethod from './transmissionMethod'

const medical =
{
    analysisMethod,
    analysisMethods,
    analysisSampling,
    analysisSamplings,
    disease,
    observationType,
    transmissionMethod
};

export default medical ;
