import React from 'react'

import PropTypes from 'prop-types'

import withi18n from '../../contexts/i18n/withi18n'

import AddressLabel from '../labels/AddressLabel'
import PlaceLabel   from '../labels/PlaceLabel'

import Place from '../../things/Place'
import Thing from '../../things/Thing'

import getHorizontalElements from '../getHorizontalElements'

import { ThingCell } from './ThingCell'

class OrganizationCell extends ThingCell
{
    getAddress = ( { className = null } = {} ) =>
    {
        const { addressable:visible } = this.props;
        const thing = this.getThing() ;
        if( visible && thing )
        {
            const { address, location } = thing ;           
            if ( address )
            {
                return <AddressLabel className={className} thing={thing} />;    
            }
            else if( location )
            {
                let { address } = location ;
                if ( address )
                {
                    return <AddressLabel className={className} thing={location} />;    
                }
            }
        }
        return null;
    }
    
    getContent = () => 
    {
        const { thing } = this.props;
        if ( thing ) 
        {
            const elements = [ 'location' , 'address' ] ;
            return getHorizontalElements( { elements , factory:this } ) ;
        }
        return null;
    };
    
    getLocale = () => this.props.locale.display.cells.organization;
            
    getLocation= ( { className = null } = {} ) => 
    {
        const { location:visible } = this.props;
        const thing = this.getThing();
        if ( visible && thing instanceof Thing ) 
        {
            const { location } = thing;
            if ( location instanceof Place ) 
            {
                return <PlaceLabel className={className} thing={location} />;
            }
        }
        return null;
    }
}

OrganizationCell.defaultProps =
{
    ...ThingCell.defaultProps,
    addressable : true,
    location    : true
};

OrganizationCell.propTypes =
{
    ...ThingCell.propTypes,
    addressable : PropTypes.bool, 
    location    : PropTypes.bool
};

export default withi18n(OrganizationCell) ;
