import React from 'react'

import MapIcon from '@material-ui/icons/Map'

import { withRouter } from 'react-router-dom'

import withi18n from '../../contexts/i18n/withi18n'

import { ThingLabel } from './ThingLabel'

export class PlaceLabel extends ThingLabel
{     
    getLocale = () => this.props.locale.display.labels.place;
}

PlaceLabel.defaultProps =
{
    ...ThingLabel.defaultProps,
    icon : <MapIcon size='small'/>
};

PlaceLabel.propTypes =
{
    ...ThingLabel.propTypes
};

export default withRouter(withi18n(PlaceLabel)) ;
