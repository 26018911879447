import dialogType from '../../../display/dialogs/dialogType'

import api from '../../../configs/api'

const activities =
{
    title : 'Activités' ,
    edit  :
    {
        agree       : 'Enregistrer' ,
        description : 'Sélectionner des activités dans la liste ci-dessous.',
        disagree    : 'Annuler' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...',
        refresh     : 'Charger',
        success     : 'Mise à jour réussie!' ,
        title       : 'Activités' ,
        uri         : api.thesaurus.activities,
        type        : dialogType.THESAURUS_CHECKER
    },
    tooltips :
    {
        add    : "Ajouter" ,
        edit   : "Modifier" ,
        remove : "Supprimer"
    }
};

export default activities ;
