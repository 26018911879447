import React from 'react'

import compose  from 'vegas-js-core/src/functors/compose'
import isString from 'vegas-js-core/src/isString'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import withConfig from '../../contexts/config/withConfig'

import { Link, withRouter } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'

import { Grid } from '@material-ui/core'

import TerrainIcon from '@material-ui/icons/Terrain'

import withDialogs from '../../contexts/dialogs/withDialogs'
import withi18n    from '../../contexts/i18n/withi18n'

import LabelItem from '../../components/items/LabelItem'

import ThingContainer from '../containers/ThingContainer'

import LivestockVO from '../../things/Livestock'
import ThingVO from '../../things/Thing'

import api  from '../../configs/api'

const styles = () =>
({

});

const full =
{
    xs : 12
};

class LiveStock extends ThingContainer
{
    getContent = () =>
    {
        let { lang, thing } = this.props;

        const locale = this.getLocale() ;

        const { labels } = locale ;

        let organization ;
        let options ;

        if( thing instanceof LivestockVO )
        {
            let {
                organization : o,
            } = thing ;

            if( o instanceof ThingVO )
            {
                options =
                {
                    component : Link ,
                    to        :
                    {
                        pathname  : o.url.split(api.url)[1]
                    }
                };
                organization = o.getLocaleName( lang ) ;
            }
            else
            {
                organization = null ;
            }
        }

        return (
            <Grid container spacing={4}>
                <Grid item {...full}>
                    <LabelItem
                        enabled   = {!!(organization)}
                        title     = { labels.name }
                        { ...options }
                    >
                        { isString(organization) ? ucFirst(organization) : labels.empty }
                    </LabelItem>
                </Grid>
            </Grid>
        );
    };

    getEntry = init =>
    {
        let empty = {} ;
        if( init instanceof ThingVO )
        {
            empty = init.toObject() ;
        }
        else if( init )
        {
            empty = { ...init } ;
        }
        return new LivestockVO(empty);
    };

    getLocale = () => this.props.locale.things.livestock;
}

LiveStock.defaultProps =
{
    ...ThingContainer.defaultProps ,
    icon       : <TerrainIcon/> ,
    optionMode : 'edit'
};

LiveStock.propTypes =
{
    ...ThingContainer.propTypes
};

export default compose(
    withStyles( styles ),
    withRouter,
    withConfig,
    withi18n,
    withDialogs
)( LiveStock );
