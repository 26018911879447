import dialogType from '../../../display/dialogs/dialogType'

import Word  from '../../../things/Word'

import api from '../../../configs/api'

const services =
{
    title : 'Services' ,
    edit  :
    {
        defaultValue : item =>
        {
            if( item )
            {
                const { services } = item ;
                if( services instanceof Array )
                {
                    return services.map( element =>
                    {
                        if( element instanceof Word )
                        {
                            return element.clone() ;
                        }
                        return null ;
                    })
                }
            }
            return null ;
        },
        prepare : ( datas ) =>
        {
            if( datas instanceof Array )
            {
                datas = datas.map( item => item instanceof Word ? item.id : null ) ;
                let object = datas.join(',');
                object = { services : object  } ;
                //console.log( 'prepare' , object );
                return object;
            }
            return null ;
        },
        agree       : 'Enregistrer' ,
        description : 'Sélectionner des services dans la liste ci-dessous.',
        disagree    : 'Annuler' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...',
        refresh     : 'Charger',
        success     : 'Mise à jour réussie!' ,
        title       : 'Services' ,
        uri         : api.thesaurus.services,
        type        : dialogType.THESAURUS_CHECKER
    },
    tooltips :
    {
        add    : "Ajouter" ,
        edit   : "Modifier" ,
        remove : "Supprimer"
    }
};

export default services ;
