import generify from './generify'

import Thing from './Thing'
import Word  from './Word'

class Offer extends Thing
{
    /**
     * Creates a new Offer instance.
     * @constructor
     * @param object The generic object to initialize the object.
     */
    constructor( object = null )
    {
        super() ;
    
        /**
         * A category for the item. Greater signs or slashes can be used to informally indicate a category hierarchy.
         * @type {Word}
         */
        this.category = null ;
        
        /**
         * The offer price of a product.
         * @type {number|string}
         */
        this.price = 0  ;
    
        /**
         * The currency of the price. Use standard formats: ISO 4217 currency format e.g. "USD".
         * @type {string}
         */
        this.priceCurrency = null ;
    
        /**
         * The date when the item becomes valid.
         * @type {string}
         */
        this.validFrom = null ;
    
        /**
         * The date after when the item is not valid. For example the end of an offer, salary period, or a period of opening hours.
         * @type {string}
         */
        this.validThrough = null ;
        
        this.set( object ) ;
    }
    
    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        super.clear() ;
        this.category      = null ;
        this.price         = 0 ;
        this.priceCurrency = null ;
        this.validFrom     = null ;
        this.validThrough  = null ;
    }

    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new Offer( this.toObject() ) ;
    }

    /**
     * Populate the object with specific behaviors or types. Launched by default in the constructor and in the set methods.
     * @returns {Thing} The current reference.
     */
    populate()
    {
        const { category } = this ;
        
        if( category )
        {
            this.category = category instanceof Word ? category : new Word(category);
        }
        
        return this ;
    }
    
    /**
     * Returns the generic object representation of the item.
     * @return {Object} The generic object representation of the item.
     */
    toObject()
    {
        let {
            category,
            price,
            priceCurrency,
            validFrom,
            validThrough
        } = this ;
        
        category = generify(category) ;
        
        return {
            ...super.toObject() ,
            category,
            price,
            priceCurrency,
            validFrom,
            validThrough
        };
    }
}

export default Offer ;