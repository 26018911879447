import React      from 'react'

import PropTypes from 'prop-types'

import isString from 'vegas-js-core/src/isString'
import notEmpty from 'vegas-js-core/src/strings/notEmpty'

import { Pulse as Preloader } from 'react-preloading-component'

import { Typography, CircularProgress, Chip, List, TextField } from '@material-ui/core'

import CoreDialog      from '../../components/dialogs/CoreDialog'
import ScrollContainer from '../../components/containers/ScrollContainer'
import Suggestion      from '../things/Suggestion'

import api           from '../../configs/api'
import LIST       from '../../net/LIST'
import RequestStatus from '../../net/RequestStatus'

import slide from '../../transitions/slide'

import Thing from '../../things/Thing'

import initDialog from './initDialog'

export const styles = theme =>
({
    content :
    {
        display       : 'flex',
        flex          : 1,
        flexDirection : 'column',
        overflow      : 'hidden' ,
        paddingBottom : 0,
        paddingTop    : 0
    },
    dialogPaper:
    {
        minHeight: '80vh',
        maxHeight: '80vh',
    },
    icon :
    {
        marginRight : theme.spacing(2)
    },
    progress :
    {
        color  : theme.palette.primary.main,
        margin : theme.spacing(2)
    },
    root :
    {
        overflow  : 'hidden' ,
        minHeight : '100% !important' ,
        height    : '100% !important' ,
        width     : '100%'
    }
});


const defaultValues =
{
    count         : 0,
    first         : true,
    hasMore       : false,
    object        : null,
    offset        : 0,
    search        : '' ,
    selectedItems : [] ,
    status        : RequestStatus.NEW,
    suggestions   : [] ,
    total         : 0
};

const init = ( values = null ) => ({ ...defaultValues , ...values }) ;

export class SelectThingDialog extends CoreDialog
{
    constructor( props )
    {
        super( props ) ;

        this.canceler  = null ;
        this.input     = null ;
        this.nextTimer = null ;
        this.scroller  = null ;
        this.timeout   = null ;

        const options = init() ;

        this.state =
        {
            ...this.state,
            ...options
        }
    }

    autoload = () =>
    {
        const { autoload } = this.props ;
        if( autoload )
        {
            const { offset } = this.state ;
            this.timeout = setTimeout( this.load , this.props.autoloadDelay , { offset } ) ;
        }
    };

    componentDidUpdate = ( prevProps , prevState ) =>
    {
        let state = {} ;

        let changed ;

        const { expanded , searchable } = this.props ;
        if( prevState.expanded !== expanded )
        {
            changed = true ;
            state = { ...state , expanded } ;
        }

        if( prevProps.searchable !== searchable )
        {
            const { search } = this.state ;
            if( !searchable && notEmpty(search) )
            {
                changed = true ;
                state = { ...state , search:'' } ;
            }
        }

        if( changed )
        {
            this.setState( state ) ;
        }
    };

    disabledSuggestions = null ;

    getContent = () =>
    {
        const { classes } = this.props ;
        const { first , status }  = this.state ;

        const content = ( first && status === RequestStatus.PROGRESS )
                ? this.getProgress()
                : this.getSuggestions() ;

        return (
            <div className={ classes.content } >
                { content }
            </div>
        );
    };

    getDescription = () =>
    {
        const {
            description,
            empty,
            fail,
            loading,
            search:label
        } = this.getLocale() || {} ;

        let input ;

        const { searchable } = this.props ;

        if( searchable )
        {
            const { inputProps } = this.props ;
            const { search, status } = this.state ;
            input = (
                <TextField
                    key         = 'select-thing-search-input'
                    className   = 'mt-16 mb-8'
                    label       = { label }
                    autoFocus   = { false }
                    fullWidth   = { true }
                    inputProps  = {{ ...inputProps }}
                    disabled    = { status === RequestStatus.PROGRESS }
                    onChange    = { this._changeInput }
                    ref         = { ref => { this.input = ref ; }}
                    value       = { isString(search) ? search : '' }
                    variant     = 'outlined'
                />
            )
        }

        let text = '' ;

        const { status } = this.state ;
        switch( status )
        {
            case RequestStatus.FAIL :
            {
                text = fail ;
                break ;
            }
            case RequestStatus.PROGRESS :
            {
                text = loading ;
                break ;
            }
            default :
            {
                const { total } = this.state ;
                text = total > 0 ? description : empty ;
            }
        }

        if( notEmpty(text) )
        {
            text = <Typography className='py-16' variant='body2'>{ text }</Typography>;
        }
        else
        {
            text = null ;
        }

        return (
            <div className='flex-0'>
                { input }
                { text }
            </div>
        );
    };

    getDialogProps = () => !this.isFullScreen() && ({ classes :
    {
        paper : this.props.classes.dialogPaper
    }}) ;

    getLocale = () =>
    {
        let locale = this.props.locale.dialogs.select.thing ;
        const { i18n } = this.props ;
        if( i18n )
        {
            return { ...locale, ...i18n } ;
        }
        return locale ;
    }

    getProgress = () =>
    (
        <div className="flex flex-1 items-center justify-center">
            <CircularProgress className={this.props.classes.progress}/>
        </div>
    );

    getSuggestions = () =>
    {
        let { suggestions } = this.state ;
        if( suggestions instanceof Array && (suggestions.length > 0) )
        {
            // console.log( this + " getSuggestions" , suggestions ) ;
            let {
                avatarMode,
                disabledSuggestions ,
                scrollable,
                selectable,
                suggestionLabel,
                suggestionRef,
                variant
            } = this.props ;

            disabledSuggestions = disabledSuggestions || this.disabledSuggestions ;

            const {
                item,
                selectedItems,
            } = this.state ;

            let blackList ;
            if( disabledSuggestions instanceof Array )
            {
                blackList = [ ...disabledSuggestions ] ;
            }
            else if( disabledSuggestions instanceof Function )
            {
                blackList = disabledSuggestions( this.props , this.state ) ;
            }

            suggestions = suggestions.map( ( suggestion , index ) =>
            {
                let disabled = false ;
                if( (blackList instanceof Array) && (blackList.length > 0) )
                {
                    const find = ( element ) => element instanceof Thing
                                             && suggestion instanceof Thing
                                             && (element.url === suggestion.url) ;
                    disabled = !!(blackList.find( find )) ;
                }

                let find ;

                if( selectable )
                {
                    find = selectedItems.findIndex( element => element === suggestion ) > -1 ;
                }

                return (
                    <Suggestion
                        key        = { 'suggestion_' + index }
                        avatarMode = { avatarMode }
                        checkable  = { selectable }
                        checked    = { find }
                        label      = { suggestionLabel }
                        disabled   = { disabled }
                        highlight  = { item && (item.id === suggestion.id) }
                        onSelect   = { this._select }
                        thing      = { suggestion }
                        thingRef   = { suggestionRef }
                    />);
            })

            return (
                <ScrollContainer
                    className      = 'flex flex-1 overflow-y-scroll'
                    onScroll       = { this.onScroll }
                    onScrollBottom = { this.next }
                    onScrollFinish = { this.onScrollFinish }
                    onScrollStart  = { this.onScrollStart }
                    onScrollTop    = { this.onScrollTop }
                    scrollable     = { scrollable && variant === 'infinite' }
                    ref            = { ref => this.scroller = ref }
                >
                    <List className='flex-1'>
                        { suggestions }
                    </List>
                </ScrollContainer>
            ) ;
        }
        return null ;
    };

    getTitleInfo = () =>
    {
        const { status , total } = this.state ;
        if( status === RequestStatus.PROGRESS )
        {
            const { theme : { palette : { primary : { main } = {} } = {}  } = {} } = this.props ;
            return <div className='mx-4'><Preloader color={ main } size={6}/></div> ;
        }
        else
        {
            const { count } = this.state ;

            let color = 'default' ;
            if( total > 0 )
            {
                color = count === total ? 'secondary' : 'primary' ;
            }

            return (
                <Chip
                    className = 'mx-4'
                    color     = { color }
                    label     = { total }
                    size      = 'small'
                />
            );
        }
    };

    hasNext = () =>
    {
        const { hasMore , status } = this.state ;
        return hasMore && status !== RequestStatus.PROGRESS ;
    };

    init = () =>
    {
        this.autoload() ;
    };

    load = ( { offset = 0 , search = null , ...options } = {} ) =>
    {
        clearTimeout( this.timeout ) ;
        const { status } = this.state ;
        if( status !== RequestStatus.PROGRESS )
        {
            let {
                apiUrl,
                limit ,
                queries,
                uri
            } = this.props ;

            this.setState( { status:RequestStatus.PROGRESS , offset , search , ...options } , () =>
            {
                const path = apiUrl + ( uri instanceof Function ? uri(this.props) : uri ) ;
                this.canceler = LIST( path ,
                {
                    limit ,
                    offset,
                    queries : { 'active' : true , ...queries } ,
                    search  : notEmpty(search) ? search : null,
                    cancel  : this._dataCancel,
                    fail    : this._dataFail ,
                    success : this._dataSuccess
                }) ;
            }) ;
        }
    };

    next = () =>
    {
        const { variant } = this.props ;
        const { status  } = this.state ;
        if( status !== RequestStatus.PROGRESS && variant === 'infinite' )
        {
            this.nextTimer = setTimeout( () =>
            {
                const { hasMore } = this.state ;
                if( hasMore )
                {
                    const { count:offset } = this.state ;
                    this.load( { offset } );
                }
            } , 200 ) ;
        }
    };

    onScroll       = null ;
    onScrollFinish = null ;
    onScrollStart  = null ;
    onScrollTop    = null ;

    populate = datas =>
    {
        let { clazz, sortable } = this.props ;
        datas = (clazz && datas instanceof Array) ? datas.map( item => new clazz(item) ) : datas ;
        if( sortable )
        {
            let sort = this.props.sort || this.sort ;
            if( sort instanceof Function )
            {
                sort( datas , this.props ) ;
            }
        }
        return datas ;
    };

    selectAll = items =>
    {
        const { selectable } = this.props ;
        if( selectable )
        {
            this.setState({ selectedItems: [...items] });
        }
    };

    toggleSelected = ( item , key = null ) =>
    {
        const { selectable } = this.props ;
        if( selectable )
        {
            let { selectedItems } = this.state;

            if ( !(selectedItems instanceof Array) )
            {
                selectedItems = [] ;
            }

            let index = -1;

            if (key === null)
            {
                index = selectedItems.indexOf(item);
            }
            else
            {
                index = selectedItems.findIndex(element => (item[key] === element[key]));
            }

            if (index > -1)
            {
                selectedItems.splice(index, 1);
            }
            else
            {
                selectedItems.push(item);
            }

            this.setState({ selectedItems }) ;
        }
    };

    unmount = () =>
    {
        const { status } = this.state ;
        if( status === RequestStatus.PROGRESS && !!(this.canceler) )
        {
            this.canceler.cancel( this + ' cancel' ) ;
        }
    };

    unselectAll = () =>
    {
        const { selectable } = this.props ;
        if( selectable )
        {
            this.setState({ selectedItems : [] }) ;
        }
    };

    // ------------ Listeners

    _changeInput = event =>
    {
        clearTimeout( this.timeout ) ;
        if( event )
        {
            const { target } = event ;
            if( target )
            {
                this.input = target ;

                let { value:search } = target ;

                if( !notEmpty(search) )
                {
                    search = '' ;
                }

                this.setState( { search } , () =>
                {
                    this.timeout = setTimeout( () =>
                    {
                        target.focus() ;
                        this.load({ offset:0 , search , suggestions:[] }) ;
                    } , this.props.delay ) ;
                });
            }
        }
    };

    _dataCancel = () =>
    {
        this.setState({ errors:null , status : RequestStatus.NEW }) ;
        const { onCancel } = this.props ;
        if( onCancel instanceof Function )
        {
            onCancel() ;
        }
    };

    _dataFail = response =>
    {
        if( response )
        {
            const { data } = response ;
            if( data )
            {
                const { message, status } = data ;

                if( status === 'error' )
                {
                    this.setState( { errors:message , status:RequestStatus.FAIL } ) ;
                    return ;
                }

                if( message !== 'token revoked' )
                {
                    console.log( this + " failed, status:" + response.status + ", message:" + message , true );
                }
            }
        }
    };

    _dataSuccess = response =>
    {
        try
        {
            let { suggestions } = this.state ;

            let {
                data :
                {
                    object,
                    result,
                    total
                } = {}
            } = response || {} ;

            const isInfinite = this.props.variant === 'infinite' ;

            let count = suggestions instanceof Array ? suggestions.length : 0 ;

            if( result instanceof Array && result.length > 0 )
            {
                count += result.length ;
                result = this.populate( result ) ;
            }
            else
            {
                result = null ;
            }

            if( isInfinite && suggestions instanceof Array && ( result instanceof Array ) )
            {
                suggestions = [ ...suggestions , ...result ] ;
            }
            else
            {
                suggestions = result ;
            }

            const hasMore = count < total ;

            this.setState({
                count,
                first:false,
                hasMore,
                object,
                status : RequestStatus.NEW ,
                suggestions,
                total
            } , () =>
            {
                if( this.input )
                {
                    this.timeout = setTimeout( () =>
                    {
                        if( this.input )
                        {
                            this.input.focus() ;
                        }
                    } , this.props.delay ) ;
                }

                if( isInfinite && this.scroller && this.hasNext() )
                {
                    const { element } = this.scroller ;
                    if( element )
                    {
                        const {
                            offsetHeight,
                            scrollHeight,
                            scrollTop
                        }
                        = element ;
                        const isBottom = scrollTop >= (scrollHeight - offsetHeight - 12) ;
                        if( isBottom )
                        {
                            this.next() ;
                        }
                    }
                }
            });
            return ;

        }
        catch ( er )
        {
            console.log( this + ` success failed, error: ${er.message}` , er ) ;
        }

        this.setState( { status : RequestStatus.FAIL } ) ;
    };

    _select = suggestion =>
    {
        if( suggestion )
        {
            const { selectable } = this.props ;
            if( selectable )
            {
                this.toggleSelected( suggestion );
            }
            else
            {
                const { suggestions } = this.state ;
                const thing = (suggestions instanceof Array)
                    ? suggestions.find( item => { return item.id === suggestion.id } )
                    : null;

                this.close() ;

                const { onSelect } = this.props ;
                if( onSelect instanceof Function )
                {
                    onSelect( thing ) ;
                }
            }
        }
    };
}

SelectThingDialog.defaultProps =
{
    ...CoreDialog.defaultProps,
    apiUrl              : api.url,
    autoload            : true,
    autoloadDelay       : 250,
    avatarMode          : 'auto',
    clazz               : Thing ,
    delay               : 500 ,
    inputProps          : null ,
    limit               : 20 ,
    maxWidth            : 'md' ,
    onCancel            : null ,
    onSelect            : null ,
    queries             : null ,
    searchable          : true ,
    scrollable          : true,
    selectable          : false ,
    showAgreeButton     : false ,
    sort                : null ,
    sortable            : false ,
    suggestionLabel     : null ,
    suggestionRef       : null ,
    TransitionComponent : slide(
    {
        direction : 'down',
        timeout   : { appear : 500 , enter : 600 , exit : 500 }
    }),
    uri     : null ,
    variant : 'infinite'
};

SelectThingDialog.propTypes =
{
    ...CoreDialog.propTypes,
    apiUrl              : PropTypes.string.isRequired,
    autoload            : PropTypes.bool,
    autoloadDelay       : PropTypes.number.isRequired,
    avatarMode          : PropTypes.oneOf(['auto', 'icon', 'cover']),
    clazz               : PropTypes.func ,
    delay               : PropTypes.number ,
    disabledSuggestions : PropTypes.oneOfType([ PropTypes.func , PropTypes.array ] ),
    i18n                : PropTypes.object,
    inputProps          : PropTypes.object,
    limit               : PropTypes.number,
    onCancel            : PropTypes.func,
    onSelect            : PropTypes.func,
    queries             : PropTypes.object ,
    searchable          : PropTypes.bool ,
    scrollable          : PropTypes.bool,
    selectable          : PropTypes.bool,
    sort                : PropTypes.func,
    sortable            : PropTypes.bool,
    suggestionLabel     : PropTypes.oneOfType([PropTypes.string,PropTypes.func]) ,
    suggestionRef       : PropTypes.func , // SPECIAL TO OVERRIDE THE LABEL and the ICON of the Suggestion elements with a custom object (example the authority property of a veterinarian occurence)
    uri                 : PropTypes.oneOfType([PropTypes.string,PropTypes.func]) ,
    variant             : PropTypes.oneOf(['infinite','default']) ,
};

export default initDialog({ styles , useSearch:false })( SelectThingDialog ) ;
