import Form from '../../../../display/forms/FormElements'

import api from '../../../../configs/api'

const elements =
[
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'name',
            label        : 'Nom de l\'organisation',
            autoFocus    : true,
            fullWidth    : true,
            helperText   : 'Obligatoire',
            margin       : 'normal',
            required     : true,
            select       : false,
            autoComplete : 'off' ,
            type         : 'text',
            variant      : 'outlined'
        }
    },
    {
        type : Form.SEARCH_SELECTOR ,
        path : api.thesaurus.organizations_types ,
        init :
        {
            id         : 'additionalType',
            key        : 'additionalType',
            label      : 'Type de l\'organisation',
            autoFocus  : false,
            fullWidth  : true,
            limit      : 0 ,
            helperText : 'Obligatoire',
            margin     : 'normal',
            required   : true,
            type       : 'search',
            variant    : 'outlined'
        },
        selector : { i18n :
        {
            empty       : 'Aucun type disponible.' ,
            title       : 'Sélectionner un type',
            description : 'Veuillez sélectionner un type d\'organisation ci-dessous.' ,
        }}
    }
];

export default
{
    add :
    {
        elements,
        agree       : 'Créer' ,
        disagree    : 'Annuler' ,
        title       : 'Ajouter une organisation' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour créer une nouvelle fiche.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en  cours...' ,
        success     : 'Ajout du nouvel enregistrement réussi!'
    },
    remove :
    {
        agree        : 'Supprimer' ,
        description  : 'Souhaitez-vous supprimer l\'organisation suivante :' ,
        descriptionX : 'Souhaitez-vous supprimer les {0} organisations suivantes :' ,
        disagree     : 'Annuler' ,
        loading      : 'Suppression en cours...' ,
        title        : 'Supprimer cette organisation ?',
        titleX       : 'Supprimer des organisations ?'
    }
};
