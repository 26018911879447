import generify from './generify'

import CreativeWork from './CreativeWork'

/**
 * A review of an item.
 * @memberOf things
 */
class Review extends CreativeWork
{
    /**
     * Creates a new Observation instance.
     * @constructor
     * @param object The generic object to initialize the object.
     */
    constructor( object = null )
    {
        super() ;
        
        /**
         * The specific subject matter of the event.
         * @type {Object|Thing}
         */
        this.subject = null ;
        
        /**
         * A Review that is part of this event.
         * For example, a annual review includes many monthly review, each of which is a subReview of the review.
         * @type {Array|Review}
         */
        this.subReview = null ;
        
        this.set( object ) ;
    }

    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        super.clear() ;
        this.subject   =
        this.subReview = null ;
        return this ;
    }

    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new Review( this.toObject(true) ) ;
    }
    
    /**
     * Returns the generic object representation of the Object.
     * @param {boolean} [full=false] - Indicates if the generic object contains all the properties of only the basic implementation.
     * @return {Object} The generic object representation of the Object.
     */
    toObject( full = false )
    {
        let {
            about,
            subject,
            subReview
        } = this ;
        
        about     = generify( about, true ) ;
        subject   = generify( subject , true ) ;
        subReview = generify( subReview , true ) ;
        
        return {
            ...( full && super.toObject() ) ,
            ...( full && subject ) ,
            about,
            subReview
        };
    }
}

export default Review ;