import React from 'react'

import clsx from 'clsx'

import filesize from 'filesize'

import moment from 'moment'

import PropTypes  from 'prop-types'

import format   from 'vegas-js-core/src/strings/fastformat'
import isString from 'vegas-js-core/src/isString'
import notEmpty from 'vegas-js-core/src/strings/notEmpty'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import {Lines as Preloader} from "react-preloading-component"

import {
    CircularProgress,
    Fab,
    IconButton,
    InputAdornment,
    TextField,
    Tooltip,
    Typography,
} from '@material-ui/core'

import BrokenImageIcon   from '@material-ui/icons/BrokenImage'
import DeleteIcon        from '@material-ui/icons/Delete'
import LinkIcon          from '@material-ui/icons/Link'
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser'

import green from "@material-ui/core/colors/green"

import Form from '../forms/Form'

import PATCH from '../../net/PATCH'

import AudioPlayer    from '../../components/audio/AudioPlayer'
import getMediaAvatar from '../medias/getMediaAvatar'

import RequestStatus from '../../net/RequestStatus'

import CoreDialog from '../../components/dialogs/CoreDialog'

import AudioObject  from '../../things/creativework/media/AudioObject'
import CreativeWork from '../../things/CreativeWork'
import ImageObject  from '../../things/creativework/media/ImageObject'
import MediaObject  from '../../things/creativework/MediaObject'
import VideoObject  from '../../things/creativework/media/VideoObject'

import { PDF , YOUTUBE } from '../../things/creativework/mimetypes'

import texture from '../../assets/textures/project-paper.png'

import Picture from '../things/Picture'

import initDialog from './initDialog'

import api   from '../../configs/api'
import icons from '../../configs/mimeicons'


//import { Document, Page } from 'react-pdf'

const styles = () => (
{
    grid :
    {
        backgroundImage : `url(${texture})`,
        backgroundRepeat : 'repeat'
    }
});

export class MediaDialog extends CoreDialog
{
    constructor( props )
    {
        super( props );

        this.canceler = null ;
        this.state =
        {
            ...this.state,
            enabled : true ,
            errors  : null ,
            image   : null ,
            preload : true ,
            status  : RequestStatus.NEW
        };
    }

    agree = () =>
    {
        let { basePath, item, prepare } = this.props ;

        if( !isString(basePath) )
        {
            basePath = api.url ;
        }

        let datas = item ;

        let path = format( api.mediaObjects.medium , item.id ) ;

        prepare = prepare || this.prepare ;

        const locale = this.getLocale() ;
        if( locale )
        {
            const { prepare:pre } = locale ;
            if( pre instanceof Function )
            {
                prepare = pre ;
            }
        }

        if( prepare instanceof Function )
        {
            datas = prepare(datas) ;
        }

        this.setState( { errors:null , status : RequestStatus.PROGRESS } ) ;

        this.canceler = PATCH(
            basePath + path,
            {
                datas,
                fail    : this._fail,
                cancel  : this._cancel,
                success : this._success
            }
        ) ;
    };

    getCaption = ( value , label = null ) =>
    {
        if( notEmpty(value) )
        {
            if( notEmpty(label) )
            {
                label = <Typography className='font-medium' variant='caption'>{label}</Typography>
            }
            else
            {
                label = null ;
            }

            value = <Typography className='mx-8' variant='caption'>{value}</Typography>

            return (
            <div className="flex flex-row items-center px-8 py-4 bg-gray-100">
                { label }
                { value }
            </div>);
        }
        return null ;
    };

    getContent = () =>
    {
        const {
            errors ,
            image ,
            status
        } = this.state ;

        const {
            classes ,
            item ,
            theme
        } = this.props ;

        const locale = this.getLocale() ;

        const progress = status === RequestStatus.PROGRESS ;

        let preview ;
        let elements ;

        if( item )
        {
            switch( true )
            {
                case item instanceof AudioObject :
                {
                    elements = locale.audio ;

                    const { contentUrl } = item ;

                    preview = (
                        <AudioPlayer
                            className = { clsx( classes.grid , "bg-gray-300 rounded" ) }
                            controls  = { true }
                            media     = { item }
                            src       = { contentUrl }
                            // onAbort          = { () => { console.log( this + ' audio abort' ) }}
                            // onCanPlay        = { () => { console.log( this + ' audio can play' ) }}
                            // onCanPlayThrough = { () => { console.log( this + ' audio can play through' ) }}
                            // onEnded          = { () => { console.log( this + ' audio ended' ) }}
                            // onError          = { () => { console.log( this + ' audio error' ) }}
                            // onLoad           = { () => { console.log( this + ' audio load' ) }}
                            // onLoadStart      = { () => { console.log( this + ' audio load start' ) }}
                            // onPause          = { () => { console.log( this + ' audio pause' ) }}
                            // onProgress       = { () => { console.log( this + ' audio progress' ) }}
                            // onPlay           = { () => { console.log( this + ' audio play' ) }}
                            // onSeeked         = { () => { console.log( this + ' audio seeked' ) }}
                            // onVolumeChanged  = { () => { console.log( this + ' audio volume changed' ) }}
                            // onLoadedMetadata = { () => { console.log( this + ' audio metadata loaded' ) }}
                        />
                    );
                    break ;
                }

                case item instanceof ImageObject :
                {
                    elements = locale.image ;

                    const {
                        contentUrl,
                    } = item ;

                    if( notEmpty(contentUrl) )
                    {
                        preview = (
                            <div className={ clsx( classes.grid , "flex bg-gray-300 justify-center items-center w-full p-4 min-h-96" ) } >
                                <Picture
                                    //className = "w-full"
                                    media     = { item }
                                    style     = {{ objectFit : 'cover' , display: image ? null : 'none' }}
                                    onLoad    = { ( event ) =>
                                    {
                                        const { height , width } = event.target ;
                                        this.setState({ image : { width , height } } ) ;
                                    }}
                                />
                                { !Boolean(image) &&
                                <div className="flex justify-center items-center w-full min-h-100">
                                    <CircularProgress style={{ color: green[600] }} size={43} thickness={4} />
                                </div>}
                            </div>
                        );
                    }
                    else
                    {
                        preview = (
                            <div className={ clsx( classes.grid , "flex bg-gray-400 justify-center items-center w-full h-160 rounded" ) } >
                                <BrokenImageIcon style={{ color:theme.palette.primary.contrastText }} fontSize='large'/>
                            </div>
                        );
                    }
                    break ;
                }

                case item instanceof VideoObject :
                {
                    elements = locale.video ;

                    const { encodingFormat } = item ;

                    if( encodingFormat === YOUTUBE )
                    {
                        const { embedUrl } = item ;
                        if( notEmpty( embedUrl ) )
                        {
                            const height = 320 ;
                            const { preload } = this.state ;
                            preview = (
                                <div
                                    className={ clsx( classes.grid , "bg-gray-400 flex items-center justify-center w-full rounded" ) }
                                    style     = {{ height }}
                                >
                                    <iframe
                                        id              = 'youtube-player'
                                        allow           = "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen = { true }
                                        frameBorder     = '0'
                                        onLoad          = { () => { this.setState( { preload:false } ) ; } }
                                        title           = 'youtube-preview'
                                        width           = '100%'
                                        height          = { height }
                                        src             = { embedUrl }
                                        style           = {{ display: preload ? 'none' : null }}
                                        type            = 'text/html'
                                    >
                                    </iframe>
                                    { preload && <Preloader color='#ffffff' size={16} /> }
                                </div>
                            )
                        }
                    }
                    break ;
                }

                case item instanceof MediaObject :
                {
                    // console.log( this + ' getContent' , item ) ;
                    const { encodingFormat } = item ;
                    if( encodingFormat === PDF )
                    {
                        elements = locale.pdf ;
                        const Icon = icons[encodingFormat];
                        if( Icon )
                        {
                            preview =
                            (
                                <div className={ clsx( classes.grid ,  "flex items-center bg-gray-400 justify-center w-full h-160 rounded" ) } >
                                    <Icon style={{ color:theme.palette.primary.contrastText }} fontSize='large' />
                                </div>
                            );
                        }
                    }
                    break ;
                }

                default :
                {
                    //
                }
            }

            let form ;
            if( elements )
            {
                form = (
                    <Form
                        name     = 'media_form'
                        disabled = { progress }
                        elements = { elements }
                        errors   = { errors }
                        thing    = { item }
                    />
                );
            }

            const { created, modified } = item ;

            return (
                <div className='flex-1 grid grid-cols-1 gap-y-4'>

                    <div className="overflow-hidden p-10" >
                        { preview }
                    </div>

                    <div className="flex flex-1 mt-8 mb-8 w-full" >
                        { this.getLink( item , locale.labels.link ) }
                    </div>

                    <div className="flex flex-1 grid grid-cols-1 sm:grid-cols-2 gap-4 my-10">
                        { this.getDate      ( modified , locale.labels.modified  )}
                        { this.getDate      ( created  , locale.labels.created   )}
                        { this.getType      ( item     , locale.labels.type      )}
                        { this.getDimension ( item     , locale.labels.dimension )}
                        { this.getFileSize  ( item     , locale.labels.filesize  )}
                    </div>

                    { form }

                    { this.getKeywords( item ) }
                </div>
            );
        }
        else
        {
            return null ;
        }
    };

    getDate = ( date , label = null ) =>
    {
        if( notEmpty(date) || date instanceof Date )
        {
            return this.getCaption( moment(date).format('L') , label ) ;
        }
        return null ;
    };

    getDimension = ( item , label = null ) =>
    {
        if( item instanceof ImageObject )
        {
            const { width , height } = item ;
            if( height >= 0 && width >= 0 )
            {
                let value = item.width + ' x ' + item.height + ' px' ;
                return this.getCaption( value , label ) ;
            }
        }
        return null ;
    };

    getFileSize = ( item , label = null ) =>
    {
        if( item )
        {
            let { contentSize } = item ;
            if( contentSize > 0 )
            {
                return this.getCaption( filesize(contentSize) , label ) ;
            }
        }
        return null ;
    };

    getIcon = () => getMediaAvatar( this.props.item ) ;

    getKeywords = item =>
    {
        if( item )
        {
            let { keywords } = item ;
            if( keywords instanceof Array && keywords.length > 0 )
            {
                keywords = keywords.map( keyword =>
                (
                    <Typography variant='caption' key={ 'keyword-' + keyword } className="inline-block bg-pink-800 rounded-full px-8 py-4 mr-8 text-xs font-semibold text-white">{ "#" + keyword }</Typography>
                )) ;
                return <div className="my-20">{keywords}</div> ;
            }

        }
        return null ;
    };

    getLink = ( item , label = null ) =>
    {
        if( item )
        {
            let { contentUrl , embedUrl } = item ;

            if( notEmpty( embedUrl ) )
            {
                contentUrl = embedUrl ;
            }

            if( notEmpty(contentUrl) )
            {
                const locale = this.getLocale() ;

                let copyButton = (
                    <IconButton
                        color   = 'primary'
                        edge    = 'start'
                        onClick = { () =>
                        {
                            const { snacks : { copy:snack } = {} } = locale ;
                            this.copy( contentUrl , snack ) ;
                        }}
                    >
                        <LinkIcon />
                    </IconButton>
                ) ;

                const { tooltips : { copy:tip } = {} } = locale ;
                if( notEmpty(tip) )
                {
                    copyButton = (
                        <Tooltip title={tip} placement='top'>
                            { copyButton }
                        </Tooltip>
                    );
                }

                return (
                    <form className="flex flex-1">
                        <TextField
                            className  = 'border-blue-200 text-grey-800'
                            fullWidth  = { true }
                            InputProps = {{ startAdornment : <InputAdornment position="start">{ copyButton }</InputAdornment> }}
                            label      = { label }
                            margin     = 'dense'
                            readOnly   = { true }
                            type       = 'text'
                            value      = { contentUrl }
                            variant    = 'outlined'
                        />
                    </form>
                );
            }
        }
    };

    getLocale = () => this.props.locale.dialogs.media ;

    getOpenButton = () =>
    {
        let openButton = null ;

        const { item } = this.props ;
        if( item )
        {


            let { contentUrl, embedUrl } = item;
            if ( notEmpty( embedUrl ) )
            {
                contentUrl = embedUrl;
            }

            if ( notEmpty( contentUrl ) )
            {
                openButton = (
                    <Fab
                        className = "ml-8 shadow-none active:shadow-none focus:shadow-none bg-white"
                        variant   = "round"
                        size      = "small"
                        onClick   = { ( event ) =>
                        {
                            event.stopPropagation();
                            window.open( contentUrl );
                        }}
                    >
                        <OpenInBrowserIcon/>
                    </Fab>
                );

                const { open:tip } = this.getLocale() || {} ;
                if( notEmpty(tip) )
                {
                    openButton = (
                        <Tooltip placement='bottom' title={ tip } >
                            { openButton }
                        </Tooltip>
                    );
                }
            }
        }

        return openButton ;
    }

    getOptions = () =>
    {
        const { item } = this.props ;
        if( item )
        {
            let openButton   = this.getOpenButton() ;
            let removeButton = this.getRemoveButton() ;
            if( openButton || removeButton )
            {
                return (
                <div className="flex flex-row item-center ml-auto">
                    { removeButton }
                    { openButton }
                </div>
                );
            }
        }
        return null ;
    };

    getRemoveButton = () =>
    {
        let removeButton = null ;

        let { editable, removable } = this.props ;
        if( removable && editable )
        {
            let {
                item,
                removeIcon,
                onRemove
            } = this.props ;

            removeIcon = removeIcon || <DeleteIcon color='error' fontSize='small'/> ;

            removeButton = (
                <Fab
                    className = "ml-8 shadow-none active:shadow-none focus:shadow-none bg-white"
                    variant   = "round"
                    size      = "small"
                    onClick   = { () =>
                    {
                        if ( onRemove instanceof Function )
                        {
                            onRemove( item )
                        }
                    }}
                >
                    { removeIcon }
                </Fab>
            );

            const { remove:tip } = this.getLocale() || {}
            if( notEmpty( tip ) )
            {
                removeButton = (
                    <Tooltip placement='bottom' title={ tip }>
                        { removeButton }
                    </Tooltip>
                )
            }
        }

        return removeButton ;
    }

    getTitleLabel = () =>
    {
        let title ;
        const { lang, item } = this.props ;

        if( item instanceof MediaObject )
        {
            title = item.getLocaleHeadline(lang);
            if( !isString(title) || title === '' )
            {
                title = item.getLocaleName(lang);
            }
        }

        if( !isString(title) || title === '' )
        {
            const { unknown } = this.getLocale() || {} ;
            if( notEmpty(unknown) )
            {
                title = unknown ;
            }
        }

        return notEmpty(title) ? ucFirst(title) : null ;
    };

    getType = ( item , label = null ) =>
    {
        if( item )
        {
            const { encodingFormat } = item ;
            if( notEmpty(encodingFormat) )
            {
                return this.getCaption( encodingFormat , label ) ;
            }
        }
        return null ;
    };

    prepare = item =>
    {
        let {
            author,
            alternativeHeadline,
            caption,
            description,
            editor,
            headline,
            license,
            mentions,
            publisher,
            review,
            text,
            transcript
        } = item ;

        return (
        {
            author,
            alternativeHeadline,
            caption,
            description,
            editor,
            headline,
            license,
            mentions,
            publisher,
            review,
            text,
            transcript
        });
    };

    unmount = () =>
    {
        const { status } = this.state ;
        if( status === RequestStatus.PROGRESS && !!(this.canceler) )
        {
            this.canceler.cancel( this + ' cancel' ) ;
        }
    };

    _cancel = () =>
    {
        this.setState( { errors:null , status : RequestStatus.NEW  } ) ;
        const { onCancel } = this.props ;
        if( onCancel instanceof Function )
        {
            onCancel() ;
        }
    };

    _fail = response =>
    {
        if( response )
        {
            const { data } = response ;
            if( data )
            {
                console.log( this + ' fail' , data ) ;

                const { status , message } = data ;

                if( status === 'error' )
                {
                    this.setState( { errors:message , status : RequestStatus.FAIL } ) ;
                }
                else if ( message !== 'token revoked' )
                {
                    console.log( this + " failed, status:" + response.status + ", message:" + message , true );
                }
            }
        }
    };

    _success = response =>
    {
        if( response )
        {
            const { data } = response ;
            if( data )
            {
                const { result } = data ;
                if ( result )
                {
                    const { onChange } = this.props ;
                    this.setState( { status:RequestStatus.SUCCESS } ) ;
                    if( onChange instanceof Function )
                    {
                        onChange( result ) ;
                    }
                }
            }
        }
    };
}

MediaDialog.defaultProps =
{
    ...CoreDialog.defaultProps,
    maxWidth   : 'md',
    editable   : false ,
    onRemove   : null ,
    removable  : true ,
    removeIcon : null ,
};

MediaDialog.propTypes =
{
    ...CoreDialog.propTypes,
    editable   : PropTypes.bool ,
    item       : PropTypes.instanceOf( CreativeWork ) ,
    onRemove   : PropTypes.func ,
    removable  : PropTypes.bool
};

export default initDialog({ styles , useSnack:true })( MediaDialog ) ;
