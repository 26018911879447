import React from 'react'

import PropTypes from 'prop-types'

import compose  from 'vegas-js-core/src/functors/compose'
import format   from 'vegas-js-core/src/strings/fastformat'
import isString from 'vegas-js-core/src/isString'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import initApp from '../../../../../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

// import { FaCheckSquare } from 'react-icons/fa'
// import { FaMinusSquare } from 'react-icons/fa'
import { FaPenSquare   } from 'react-icons/fa'

import { Grid, Typography } from '@material-ui/core'

import ThingContainer from '../../../../../containers/ThingContainer'
import LabelItem      from '../../../../../../components/items/LabelItem'

import api from '../../../../../../configs/api'

import Method   from './Method'
import Sampling from './Sampling'

import Disease         from '../../../../../../things/medical/Disease'
import MedicalAnalysis from '../../../../../../things/medical/MedicalAnalysis'

const half =
{
    md : 6 ,
    sm : 6 ,
    xs : 12
};

const full =
{
    sm : 12 ,
    xs : 12
};

const styles = () => ({});

class Analysis extends ThingContainer
{
    getContent = () =>
    {
        let {
            lang,
            memberOf,
            subjectOf,
            thing:analysis
        } = this.props;

        let label ;
        let text ;
        let disease ;

        let hasMethod   = false ;
        let hasSampling = false ;

        let methodUri   = null ;
        let samplingUri = null ;

        if( analysis instanceof MedicalAnalysis )
        {
            let {
                confirmed,
                description,
                disease:dis,
            } = analysis ;

            const locale = this.getLocale() ;

            const { labels } = locale ;

            if( dis instanceof Disease )
            {
                const { id } = dis ;

                dis = dis.getLocaleAlternateName(lang) ;
                if( !isString(dis) || dis === '' )
                {
                    dis = dis.getLocaleName(lang) ;
                }

                if( id )
                {
                    methodUri = format( api.diseases.analysisMethod , id );
                    hasMethod = true ;

                    samplingUri = format( api.diseases.analysisSampling , id );
                    hasSampling = true ;
                }
            }
            else
            {
                dis = null ;
            }

            disease = (
            <div className='flex flex-row items-center my-8 ml-16'>
                {/*<FaStethoscope size='1.5em' className='mr-16' />*/}
                <LabelItem title={labels.disease}>
                    { isString(dis) && dis !== '' ? ucFirst(dis) : labels.empty }
                </LabelItem>
            </div>);

            label = (
            <div className='flex flex-row items-center my-8 ml-16'>
                {/*{ confirmed*/}
                    {/*? <FaCheckSquare size='1.5em' className='mr-16' />*/}
                    {/*: <FaMinusSquare size='1.5em' className='mr-16' />*/}
                {/*}*/}
                <LabelItem title={labels.confirmed}>
                    { confirmed ? labels.yes : labels.no }
                </LabelItem>
            </div>);

            if( description )
            {
                description = analysis.getLocaleDescription(lang) ;

                if( isString(description) && description !== '' )
                {
                    text = (
                    <div className='flex flex-row items-center my-8 ml-16'>
                        <FaPenSquare size='1.5em' className='mr-16' />
                        <Typography
                            className = 'w-full pr-8'
                            variant   = 'body2'
                        >
                            { ucFirst(description) }
                        </Typography>
                    </div>);
                }
            }
        }

        return (
            <Grid container spacing={2}>

                <Grid item {...half}>
                    { disease }
                </Grid>

                <Grid item {...half}>
                    { label }
                </Grid>

                <Grid item {...full}>
                    { text }
                </Grid>

                { hasMethod &&
                <Grid item {...full}>
                    <Method
                        containerProps = {{ className : "bg-transparent w-full shadow-none" }}
                        editSearchUri  = { methodUri }
                        memberOf       = { memberOf }
                        onChange       = { this.change }
                        subjectOf      = { subjectOf }
                        thing          = { analysis }
                    />
                </Grid>}

                { hasSampling &&
                <Grid item {...full}>
                    <Sampling
                        containerProps = {{ className : "bg-transparent w-full shadow-none" }}
                        editSearchUri  = { samplingUri }
                        memberOf       = { memberOf }
                        onChange       = { this.change }
                        subjectOf      = { subjectOf }
                        thing          = { analysis }
                    />
                </Grid>}
            </Grid>
        );
    };

    getLocale = () => this.props.locale.things.livestock.reviews.abortion.medical.analysis;
}

Analysis.defaultProps =
{
    ...ThingContainer.defaultProps ,
    header   : false,
    memberOf : 'aborted'
};

Analysis.propTypes =
{
    ...ThingContainer.propTypes,
    memberOf : PropTypes.string
};

export default compose( withStyles( styles ) , withRouter , initApp )( Analysis ) ;
