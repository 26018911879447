import Livestock from './Livestock'
import Person    from './Person'
import Thing     from './Thing'
import Word      from './Word'

import generify from './generify'

/**
 * Defines a technician person.
 * @memberOf things
 */
class Technician extends Thing
{
    /**
     * Creates a new Technician instance.
     * @constructor
     * @param object The generic object to initialize the object the first time.
     */
    constructor( object = null )
    {
        super() ;
    
        /**
         * The person of this technician.
         * @type {Person}
         */
        this.person = null ;
    
        /**
         * The ordinal identifier of this technician.
         * @type {string|number}
         */
        this.identifier = null ;

        /**
         * The livestocks of this technician.
         * @type {Livestock|Array}
         */
        this.livestocks = null ;

        /**
         * The enumeration of medical specialties of this technician.
         * @type {Word|Array}
         */
        this.medicalSpecialties = null ;

        this.set(object) ;
    }

    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        super.clear() ;
        this.person             =
        this.identifier         =
        this.livestocks         =
        this.medicalSpecialties = null ;
        return this ;
    }

    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new Technician( this.toObject() ) ;
    }

    /**
     * Populate the object with specific behaviors or types.
     * Launched by default in the constructor and in the set methods.
     * @returns {Thing} The current reference.
     */
    populate()
    {
        const {
            livestocks,
            medicalSpecialties,
            person
        } = this ;

        if( person && !(person instanceof Person) )
        {
            this.person = new Person( person ) ;
        }

        if( livestocks )
        {
            if( livestocks instanceof Array )
            {
                this.livestocks = livestocks.map( item => item instanceof Livestock ? item : new Livestock(item) ) ;
            }
            else
            {
                this.livestocks = new Livestock( livestocks ) ;
            }
        }

        if( medicalSpecialties )
        {
            if( medicalSpecialties instanceof Array )
            {
                this.medicalSpecialties = medicalSpecialties.map( item => item instanceof Word ? item : new Word(item) ) ;
            }
            else
            {
                this.medicalSpecialties = new Word( medicalSpecialties ) ;
            }
        }

        return this ;
    }

    /**
     * Returns the generic object representation of the item.
     * @return {Object} The generic object representation of the item.
     */
    toObject()
    {
        let {
            identifier,
            livestocks,
            medicalSpecialties,
            person
        } = this ;

        livestocks         = generify(livestocks) ;
        medicalSpecialties = generify(medicalSpecialties) ;
        person             = generify(person) ;

        return {
            ...super.toObject() ,
            identifier,
            livestocks,
            medicalSpecialties,
            person
        }
    }
}

export default Technician ;