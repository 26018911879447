import about    from './course/about'
import discover from './course/discover'
import steps    from './course/steps'

const course =
{
    // dependencies

    about,
    discover,
    steps,

    // settings

    helmet :
    {
        title : 'Parcours - {0}' ,
        metas : []
    },
    loading  : "Chargement ..." ,
    notFound : "Parcours inconnu" ,
    tabs     :
    {
        about    : 'À propos',
        discover : 'À Découvrir',
        info     : 'Infos',
        medias   : 'Médias',
        steps    : 'Étapes'
    },
    tooltips :
    {
        back : 'Tous les parcours'
    }
};

export default course ;