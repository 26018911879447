import PropTypes from 'prop-types'

export default
{
    applications : PropTypes.shape(
    {
        alternativeHeadline : PropTypes.string ,
        application         : PropTypes.string ,
        description         : PropTypes.string ,
        headline            : PropTypes.string ,
        audio               : PropTypes.string ,
        image               : PropTypes.string ,
        video               : PropTypes.string ,
        notes               : PropTypes.string ,
        oAuth               : PropTypes.string ,
        producer            : PropTypes.string ,
        publisher           : PropTypes.string ,
        secret              : PropTypes.string ,
        setting             : PropTypes.string ,
        slogan              : PropTypes.string ,
        sponsor             : PropTypes.string ,
        text                : PropTypes.string ,
        url                 : PropTypes.string ,
        websites            : PropTypes.string
    }),
    articles : PropTypes.shape(
    {
        alternativeHeadline : PropTypes.string ,
        article             : PropTypes.string ,
        audio               : PropTypes.string ,
        audios              : PropTypes.string ,
        description         : PropTypes.string ,
        hasPart             : PropTypes.string ,
        headline            : PropTypes.string ,
        image               : PropTypes.string ,
        isPartOf            : PropTypes.string ,
        isRelatedTo         : PropTypes.string ,
        isSimilarTo         : PropTypes.string ,
        notes               : PropTypes.string ,
        photos              : PropTypes.string ,
        text                : PropTypes.string ,
        url                 : PropTypes.string ,
        video               : PropTypes.string ,
        videos              : PropTypes.string ,
        websites            : PropTypes.string ,
    }),
    conceptualObjects : PropTypes.shape(
    {
        url              : PropTypes.string ,
        conceptualObject : PropTypes.string ,
        description      : PropTypes.string ,
        alternateName    : PropTypes.string ,
        audio            : PropTypes.string ,
        image            : PropTypes.string ,
        video            : PropTypes.string ,
        location         : PropTypes.string ,
        marks            : PropTypes.string ,
        materials        : PropTypes.string ,
        measurements     : PropTypes.string ,
        movement         : PropTypes.string ,
        numbers          : PropTypes.string ,
        production       : PropTypes.string ,
        notes            : PropTypes.string ,
        text             : PropTypes.string ,
        websites         : PropTypes.string
    }),
    courses : PropTypes.shape(
    {
        alternativeHeadline       : PropTypes.string ,
        url                       : PropTypes.string ,
        notes                     : PropTypes.string ,
        course                    : PropTypes.string ,
        description               : PropTypes.string ,
        discover                  : PropTypes.string ,
        headline                  : PropTypes.string ,
        audio                     : PropTypes.string ,
        image                     : PropTypes.string ,
        video                     : PropTypes.string ,
        openingHoursSpecification : PropTypes.string ,
        photos                    : PropTypes.string ,
        steps                     : PropTypes.string ,
        text                      : PropTypes.string ,
        transportations           : PropTypes.string
    }),
    diseases : PropTypes.shape(
    {
        url                : PropTypes.string ,
        alternateName      : PropTypes.string ,
        analysisMethod     : PropTypes.string ,
        analysisSampling   : PropTypes.string ,
        description        : PropTypes.string ,
        disease            : PropTypes.string ,
        notes              : PropTypes.string ,
        text               : PropTypes.string ,
        transmissionMethod : PropTypes.string
    }),
    events : PropTypes.shape(
    {
        url                 : PropTypes.string ,
        from                : PropTypes.string ,
        alternateName       : PropTypes.string ,
        alternativeHeadline : PropTypes.string ,
        description         : PropTypes.string ,
        event               : PropTypes.string ,
        headline            : PropTypes.string ,
        audio               : PropTypes.string ,
        image               : PropTypes.string ,
        video               : PropTypes.string ,
        offers              : PropTypes.string ,
        organizer           : PropTypes.string ,
        notes               : PropTypes.string ,
        subEvent            : PropTypes.string ,
        superEvent          : PropTypes.string ,
        text                : PropTypes.string ,
        workFeatured        : PropTypes.string
    }),
    games : PropTypes.shape(
    {
        url                 : PropTypes.string ,
        alternateName       : PropTypes.string ,
        description         : PropTypes.string ,
        game                : PropTypes.string ,
        item                : PropTypes.string ,
        notes               : PropTypes.string ,
        quest               : PropTypes.string ,
        text                : PropTypes.string ,

        // specific games

        courses : PropTypes.shape(
        {
            url                 : PropTypes.string ,
            alternateName       : PropTypes.string ,
            description         : PropTypes.string ,
            game                : PropTypes.string ,
            item                : PropTypes.string ,
            notes               : PropTypes.string ,
            quest               : PropTypes.string ,
            text                : PropTypes.string
        }),
        questions : PropTypes.shape(
        {
            url                 : PropTypes.string ,
            alternateName       : PropTypes.string ,
            description         : PropTypes.string ,
            game                : PropTypes.string ,
            item                : PropTypes.string ,
            notes               : PropTypes.string ,
            quest               : PropTypes.string ,
            text                : PropTypes.string
        })
    }),
    livestocks : PropTypes.shape(
    {
        url           : PropTypes.string ,
        livestock     : PropTypes.string ,
        numbers       : PropTypes.string ,
        observations  : PropTypes.string ,
        veterinarians : PropTypes.string ,
        workshops     : PropTypes.string
    }),
    me : PropTypes.shape(
    {
        url        : PropTypes.string ,
        activities : PropTypes.string ,
        address    : PropTypes.string ,
        favorites  : PropTypes.string ,
        sessions   : PropTypes.string
    }),
    observations : PropTypes.shape(
    {
        url           : PropTypes.string ,
        actor         : PropTypes.string ,
        alternateName : PropTypes.string ,
        attendee      : PropTypes.string ,
        authority     : PropTypes.string ,
        description   : PropTypes.string ,
        observation   : PropTypes.string ,
        notes         : PropTypes.string
    }),
    organizations : PropTypes.shape(
    {
        description        : PropTypes.string ,
        notes              : PropTypes.string ,
        address            : PropTypes.string ,
        alternateName      : PropTypes.string ,
        department         : PropTypes.string ,
        text               : PropTypes.string ,
        employees          : PropTypes.string ,
        email              : PropTypes.string ,
        founder            : PropTypes.string ,
        audio               : PropTypes.string ,
        image               : PropTypes.string ,
        video               : PropTypes.string ,
        organization       : PropTypes.string ,
        owns               : PropTypes.string ,
        memberOf           : PropTypes.string ,
        members            : PropTypes.string ,
        numbers            : PropTypes.string ,
        parentOrganization : PropTypes.string ,
        providers          : PropTypes.string ,
        slogan             : PropTypes.string ,
        status             : PropTypes.string ,
        subOrganization    : PropTypes.string ,
        telephone          : PropTypes.string ,
        url                : PropTypes.string ,
        websites           : PropTypes.string
    }),
    people : PropTypes.shape(
    {
        text        : PropTypes.string ,
        address     : PropTypes.string ,
        description : PropTypes.string ,
        civilStatus : PropTypes.string ,
        email       : PropTypes.string ,
        audio       : PropTypes.string ,
        image       : PropTypes.string ,
        video       : PropTypes.string ,
        job         : PropTypes.string ,
        person      : PropTypes.string ,
        photos      : PropTypes.string ,
        notes       : PropTypes.string ,
        telephone   : PropTypes.string ,
        url         : PropTypes.string ,
        websites    : PropTypes.string ,
    }),
    places : PropTypes.shape(
    {
        url                       : PropTypes.string ,
        description               : PropTypes.string ,
        activities                : PropTypes.string ,
        address                   : PropTypes.string ,
        alternateName             : PropTypes.string ,
        notes                     : PropTypes.string ,
        conceptualObjects         : PropTypes.string ,
        containedInPlace          : PropTypes.string ,
        containsPlace             : PropTypes.string ,
        text                      : PropTypes.string ,
        email                     : PropTypes.string ,
        events                    : PropTypes.string ,
        geo                       : PropTypes.string ,
        audio                     : PropTypes.string ,
        image                     : PropTypes.string ,
        video                     : PropTypes.string ,
        keywords                  : PropTypes.string ,
        museumType                : PropTypes.string ,
        offers                    : PropTypes.string ,
        openingHoursSpecification : PropTypes.string ,
        permits                   : PropTypes.string ,
        place                     : PropTypes.string ,
        photos                    : PropTypes.string ,
        prohibitions              : PropTypes.string ,
        services                  : PropTypes.string ,
        slogan                    : PropTypes.string ,
        status                    : PropTypes.string ,
        telephone                 : PropTypes.string ,
        websites                  : PropTypes.string
    }),
    settings : PropTypes.shape(
    {
        url : PropTypes.string
    }),
    stages : PropTypes.shape(
    {
        activities          : PropTypes.string ,
        alternativeHeadline : PropTypes.string ,
        conceptualObjects   : PropTypes.string ,
        courses             : PropTypes.string ,
        discover            : PropTypes.string ,
        events              : PropTypes.string ,
        headline            : PropTypes.string ,
        audio               : PropTypes.string ,
        image               : PropTypes.string ,
        video               : PropTypes.string ,
        location            : PropTypes.string ,
        media               : PropTypes.string ,
        organizations       : PropTypes.string ,
        people              : PropTypes.string ,
        photos              : PropTypes.string ,
        places              : PropTypes.string ,
        stages              : PropTypes.string ,
        status              : PropTypes.string ,
        text                : PropTypes.string ,
        websites            : PropTypes.string ,
        url                 : PropTypes.string

    }),
    steps : PropTypes.shape(
    {
        url                 : PropTypes.string ,
        alternativeHeadline : PropTypes.string ,
        description         : PropTypes.string ,
        headline            : PropTypes.string ,
        audio               : PropTypes.string ,
        image               : PropTypes.string ,
        video               : PropTypes.string ,
        notes               : PropTypes.string ,
        photos              : PropTypes.string ,
        step                : PropTypes.string ,
        text                : PropTypes.string ,

    }),
    teams : PropTypes.shape(
    {
        url           : PropTypes.string ,
        alternateName : PropTypes.string ,
        description   : PropTypes.string ,
        team          : PropTypes.string
    }),
    technicians : PropTypes.shape(
    {
        url                : PropTypes.string ,
        livestocks         : PropTypes.string ,
        medicalSpecialties : PropTypes.string ,
        technician         : PropTypes.string
    }),
    thesaurus : PropTypes.shape(
    {
        url                            : PropTypes.string,
        thesaurus                      : PropTypes.string,
        breedings_types                : PropTypes.string,
        business_entity_types          : PropTypes.string,
        emails_types                   : PropTypes.string,
        events_status                  : PropTypes.string,
        events_types                   : PropTypes.string,
        genders                        : PropTypes.string,
        honorificPrefix                : PropTypes.string,
        livestocks_numbers_types       : PropTypes.string,
        medical_specialties            : PropTypes.string,
        movement                       : PropTypes.string,
        conceptualObject_categories    : PropTypes.string,
        conceptualObject_numbers_types : PropTypes.string,
        observations_status            : PropTypes.string,
        observations_types             : PropTypes.string,
        offers_categories              : PropTypes.string,
        organizations_naf              : PropTypes.string,
        organizations_numbers_types    : PropTypes.string,
        organizations_types            : PropTypes.string,
        people_jobs                    : PropTypes.string,
        phoneNumbers_types             : PropTypes.string,
        places_types                   : PropTypes.string,
        productions_types              : PropTypes.string,
        regulations                    : PropTypes.string,
        sector_types                   : PropTypes.string,
        temporal                       : PropTypes.string,
        water_origins                  : PropTypes.string,
        websites_types                 : PropTypes.string
    }),
    topics : PropTypes.shape(
    {
        alternativeHeadline : PropTypes.string ,
        description         : PropTypes.string ,
        headline            : PropTypes.string ,
        image               : PropTypes.string ,
        notes               : PropTypes.string ,
        text                : PropTypes.string ,
        topic               : PropTypes.string ,
        url                 : PropTypes.string
    }),
    users : PropTypes.shape(
    {
        url        : PropTypes.string ,
        activities : PropTypes.string ,
        address    : PropTypes.string ,
        sessions   : PropTypes.string ,
        user       : PropTypes.string
    }),
    veterinarians : PropTypes.shape(
    {
        url                : PropTypes.string ,
        livestocks         : PropTypes.string ,
        medicalSpecialties : PropTypes.string ,
        veterinarian       : PropTypes.string
    }),
    workshops : PropTypes.shape(
    {
        url          : PropTypes.string ,
        observations : PropTypes.string ,
        sectors      : PropTypes.string ,
        workplaces   : PropTypes.string ,
        workshop     : PropTypes.string
    })
} ;
