import generify from './generify'

import Organization from './Organization'
import NumberValue  from './NumberValue'
import Thing        from './Thing'
import Technician   from './Technician'
import Veterinarian from './Veterinarian'
import Workshop     from './Workshop'

import populateMedias from './creativework/populateMedias'

/**
 * Defines an livestock .
 * @memberOf things
 */
class Livestock extends Thing
{
    /**
     * Creates a new Livestock instance.
     * @constructor
     * @param object The generic object to initialize the object the first time.
     */
    constructor( object = null )
    {
        super() ;

        /**
         * The audios medias of this livestock.
         * @type {Array}
         */
        this.audios = null;

        /**
         * Indicates the number of audios medias of this thing.
         * @type {Array}
         */
        this.numAudios = null;

        /**
         * Indicates the number of videos medias of this thing.
         * @type {Array}
         */
        this.numPhotos = null;

        /**
         * Indicates the number of videos medias of this thing.
         * @type {Array}
         */
        this.numVideos = null;

        /**
         * The collection of numbers and identifiers of the livestock.
         * @type {Array}
         */
        this.numbers = null ;

        /**
         * The organization owner of the livestock.
         * @type {Organization}
         */
        this.organization = null  ;

        /**
         * The photos medias of the livestock.
         * @type {Array}
         */
        this.photos = null;

        /**
         * The list of all technicians of the livestock.
         */
        this.technicians = null  ;

        /**
         * The list of all veterinarians of the livestock.
         */
        this.veterinarians = null  ;

        /**
         * The videos medias of the livestock.
         * @type {Array}
         */
        this.videos = null;

        /**
         * The collection of all workshops of the livestock.
         * @type {Array}
         */
        this.workshops = null  ;

        this.set( object ) ;
    }

    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        super.clear() ;
        this.audios =
        this.numAudios =
        this.numPhotos =
        this.numVideos =
        this.numbers =
        this.organization =
        this.photos =
        this.technicians =
        this.veterinarians =
        this.videos =
        this.workshops = null ;
        return this ;
    }

    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new Livestock( this.toObject() ) ;
    }

    populate()
    {
        const {
            numbers,
            organization,
            technicians,
            veterinarians,
            workshops
        }
        = this ;

        populateMedias( this ) ;

        if( numbers instanceof Array )
        {
            this.numbers = numbers.map( item => item instanceof NumberValue ? item : new NumberValue(item) ) ;
        }
        
        if( organization && !(organization instanceof Organization) )
        {
            this.organization = new Organization( organization ) ;
        }
        
        if( technicians instanceof Array )
        {
            this.technicians = technicians.map( item => item instanceof Technician ? item : new Technician(item) ) ;
        }
        
        if( veterinarians instanceof Array )
        {
            this.veterinarians = veterinarians.map( item => item instanceof Veterinarian ? item : new Veterinarian(item) ) ;
        }
        
        if( workshops instanceof Array )
        {
            this.workshops = workshops.map( item => item instanceof Workshop ? item : new Workshop(item) ) ;
        }

        return this ;
    }

    /**
     * Returns the generic object representation of the item.
     * @return {Object} The generic object representation of the item.
     */
    toObject()
    {
        let {
            audios,
            numAudios,
            numPhotos,
            numVideos,
            numbers,
            organization,
            photos,
            technicians,
            veterinarians,
            videos,
            workshops
        } = this ;

        audios        = generify(audios);
        numbers       = generify(numbers);
        organization  = generify(organization);
        photos        = generify(photos);
        technicians   = generify(technicians);
        videos        = generify(videos);
        veterinarians = generify(veterinarians);
        workshops     = generify(workshops);
        
        return {
             ...super.toObject() ,
            audios,
            numAudios,
            numPhotos,
            numVideos,
            numbers,
            organization,
            photos,
            technicians,
            videos,
            veterinarians,
            workshops
        }
    }
}

export default Livestock ;