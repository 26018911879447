import React from 'react'

import clsx   from 'clsx'
import moment from 'moment'

import notEmpty from 'vegas-js-core/src/strings/notEmpty'

import DateRangeIcon from '@material-ui/icons/DateRange'

import ThingComponent from '../things/ThingComponent'

import getLocalePeriodLabel from '../../things/getLocalePeriodLabel'

import withConfig from '../../contexts/config/withConfig'
import withi18n   from '../../contexts/i18n/withi18n'
import withSnack  from '../../contexts/snack/withSnack'

export class PeriodLabel extends ThingComponent 
{  
    getLocale = () => this.props.locale.display.labels.period;
    
    render = () =>
    {
        const locale = this.getLocale() ;
        let period = getLocalePeriodLabel( this.getThing() , moment , locale.patterns );
        if ( notEmpty( period ) )
        {
            const { className } = this.props;
            return (
                <div className={ clsx("flex flex-row items-center p-8", className)} >
                    <div className="flex-initial">{ this.getIcon({ label:period }) } </div>
                    <div className="flex flex-col pl-8">
                        { this.getTitle() }
                        { this.getLabel( period ) }
                    </div>
                </div>
            );
        }
        return null;
    }
}
    
PeriodLabel.defaultProps =
{
    ...ThingComponent.defaultProps,
    html : true,
    icon : <DateRangeIcon fontSize='small' />
}

PeriodLabel.propTypes =
{
    ...ThingComponent.propTypes
}

export default withConfig( withSnack( withi18n( PeriodLabel ) ) ) ;