import format from 'vegas-js-core/src/strings/fastformat'

import api from "../../../../configs/api"

import Form from '../../../../display/forms/FormElements'

import AnimalHealthObservation from '../../../../things/livestock/observations/AnimalHealthObservation'
import Thing                   from '../../../../things/Thing'
import Workshop                from '../../../../things/Workshop'
import Word                    from '../../../../things/Word'

const elements =
[
    {
        type       : Form.SEARCH_SELECTOR ,
        clearLabel : 'Vider' ,
        closeLabel : 'Fermer' ,
        init       :
        {
            id         : 'additionalType',
            key        : 'additionalType',
            label      : 'Type de l\'observation',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            required   : true,
            type       : 'search',
            variant    : 'outlined'
        },
        logics :
        {
            disabledSuggestions : props =>
            {
                const { rel } = props ;
                let suggestions = [] ;
                if( rel instanceof Thing )
                {
                    const { additionalType } = rel ;
                    if( additionalType instanceof Word )
                    {
                        suggestions.push( additionalType ) ;
                    }
                }
                return suggestions ;
            }
        },
        path : props =>
        {
            const { rel } = props ;
            if( rel instanceof AnimalHealthObservation )
            {
                const { subjectOf } = rel ;
                if( subjectOf instanceof Workshop )
                {
                    const { breeding } = subjectOf ;
                    if( breeding instanceof Thing )
                    {
                        const { id } = breeding ;
                        if( id )
                        {
                            return format( api.settings.observationsTypesByBreedingsTypes, id ) ;
                        }
                    }
                }
            }
            return api.thesaurus.observations_types ;
        }
    },
    {
        type : Form.I18N_EDITOR ,
        init :
        {
            id    : 'alternateName',
            key   : 'alternateName',
            input : true,
            label : 'Titre'
        }
    }
];

const observation =
{
    add :
    {
        elements,
        agree       : 'Créer' ,
        disagree    : 'Annuler' ,
        title       : 'Ajouter une observation' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour créer une nouvelle observation.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en  cours...' ,
        success     : 'Ajout de la nouvelle observation réussi!'
    },
    remove :
    {
        agree        : 'Supprimer' ,
        description  : 'Souhaitez-vous supprimer l\'observation suivante :' ,
        descriptionX : 'Souhaitez-vous supprimer les {0} observations suivantes :' ,
        disagree     : 'Annuler' ,
        loading      : 'Suppression en cours...' ,
        mock         : false ,
        title        : 'Supprimer cette observation ?',
        titleX       : 'Supprimer des observations ?'
    }
};

export default observation ;
