const mark =
{
    additionalType : 'Type' ,
    language       : 'Language',
    name           : 'Text',
    position       : 'Position',
    separator      : ' / ',
    technique      : 'Technique',
    text           : 'Traduction',
    unknown        : 'Inconnu'
};

export default mark ;