import React from 'react'

import PropTypes from "prop-types"

import compose  from 'vegas-js-core/src/functors/compose'
import isInt    from 'vegas-js-core/src/isInt'
import isString from 'vegas-js-core/src/isString'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import initApp from '../../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { Avatar, Link, Typography } from '@material-ui/core'

import moment from 'moment/moment'

import TransportIcon from '@material-ui/icons/DirectionsWalk'

import ThingChildren, { styles } from './../ThingChildren'

import Thing            from '../../../things/Thing'
import TransportationVO from '../../../things/Transportation'
import Word             from '../../../things/Word'

const labels =
{
    day       : 'day',
    days      : 'days',
    hour      : 'hour',
    hours     : 'hours',
    minute    : 'minute',
    minutes   : 'minutes',
    month     : 'month',
    months    : 'months',
    separator : ', ',
    week      : 'week',
    weeks     : 'weeks',
    year      : 'year',
    years     : 'years'
};

const prepare = ( item ) =>
{
    let { additionalType, duration } = item ;
    return (
    {
        additionalType : additionalType instanceof Thing ? String(additionalType.id) : null ,
        duration
    });
};

class Transportations extends ThingChildren
{
    addPrepare = prepare ;

    createChild = ( child , editable = false ) =>
    {
        if( child  )
        {
            const { lang, LinkOptions } = this.props ;
            let {
                additionalType,
                duration
            } = child ;

            let icon ;
            let time ;
            let type ;

            if( isInt( duration ) )
            {
                time = this.humanize( duration , 'minutes' ) ;
                time = <Typography className={ 'font-semibold' } variant={'body2'}>{time}</Typography> ;
            }
            else
            {
                time = null ;
            }

            if( additionalType instanceof Word )
            {
                const { image } = additionalType ;
                type = additionalType.getLocaleName(lang) ;

                if( isString(image) )
                {
                    icon = <img className="h-auto" src={ image + '?w=20' } alt={type} /> ;
                }

                if( isString(type) )
                {
                    type = ucFirst(type) ;
                    if( editable )
                    {
                        type = (
                        <Link
                            className = "cursor-pointer text-blue-600 font-medium"
                            variant   = "subtitle1"
                            underline = 'none'
                            href      = { null }
                            onClick   = { () => this.openEditDialog(child) }
                            { ...LinkOptions }
                        >
                            { type }
                        </Link>) ;
                    }
                    type = (
                        <div className='flex flex-row items-center'>
                            <Typography className={ 'font-medium text-blue-800' } variant={'body1'}>{type}</Typography>
                        </div>
                    );
                }
                else
                {
                    type = null ;
                }
            }

            return (
                <div className="py-16 flex flex-row w-full items-center">
                    <div>
                        <Avatar variant="rounded" className='bg-grey-lightest mr-16'>
                            { icon }
                        </Avatar>
                    </div>
                    <div>
                        { type }
                        { time }
                    </div>
                </div>
            );
        }

        return null ;
    };

    editPrepare = prepare ;

    humanize = ( duration , unit) =>
    {
        if( duration )
        {
            duration = moment.duration(duration, unit);

            const locale = this.getLocale() ;

            if( locale )
            {
                let { labels:localeLabels } = locale ;

                const strings = { ...labels, ...localeLabels } ;

                if( labels )
                {
                    let result = [];

                    let y = duration.years() ;
                    if ( y > 0 )
                    {
                        result.push( y + ' ' + (y === 1 ? strings.year : strings.years) ) ;
                        duration.subtract( y , 'years' );
                    }

                    let m = duration.months() ;
                    if (duration.months() > 0)
                    {
                        result.push( m + ' ' + (m === 1 ? strings.month : strings.months) ) ;
                        duration.subtract( m, 'months' );
                    }

                    let w = duration.weeks() ;
                    if (w > 0)
                    {
                        result.push(w + ' ' + (w === 1 ? strings.week : strings.weeks) );
                        duration.subtract( w, 'weeks' );
                    }

                    let d = duration.days() ;
                    if (duration.days() > 0)
                    {
                        result.push(d + ' ' + (d === 1 ? strings.day : strings.days) );
                        duration.subtract(d, 'days');
                    }

                    let h = duration.hours() ;
                    if (duration.hours() > 0)
                    {
                        result.push( h + ' ' + (h === 1 ? strings.hour : strings.hours) );
                        duration.subtract( h , 'hours' );
                    }

                    let mn = duration.minutes() ;
                    if ( mn > 0)
                    {
                        result.push( mn + ' ' + (mn === 1 ? strings.minute : strings.minutes) );
                    }

                    return result.length === 1 ? result[0] : result.join( strings.separator ) ;
                }

            }

            return duration.humanize() ;
        }
        return null ;
    };

    getLocale = () => this.props.locale.things.course.transportations ;

    sort = ( a , b ) =>
    {
        if( a instanceof TransportationVO && b instanceof TransportationVO )
        {
            let { additionalType:type1 } = a ;
            let { additionalType:type2 } = b ;
            if( type1 instanceof Word && type2 instanceof Word )
            {
                return type1.localeCompare(type2,this.props.lang);
            }
        }
        return 0;
    };
}

Transportations.defaultProps =
{
    ...ThingChildren.defaultProps ,
    clazz       : TransportationVO,
    emptyIcon   : null,
    icon        : <TransportIcon/>,
    LinkOptions : null,
    member      : 'transportations'
};

Transportations.propTypes =
{
    ...ThingChildren.propTypes,
    LinkOptions : PropTypes.object,
};

export default compose( withStyles( styles ) , withRouter , initApp )( Transportations ) ;
