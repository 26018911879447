import React from 'react'

import clsx from 'clsx'

import withi18n from '../../../contexts/i18n/withi18n'

import ThingSubContainer from '../../../display/containers/ThingSubContainer'

import AbortionReview      from '../../../display/things/livestock/reviews/AbortionReview'
import AbortionSerieReview from '../../../display/things/livestock/reviews/AbortionSerieReview'
import AnnualHealthReview  from '../../../display/things/livestock/reviews/AnnualHealthReview'
import SuspicionReview     from '../../../display/things/livestock/reviews/SuspicionReview'

import AbortionReviewVO      from '../../../things/livestock/reviews/AbortionReview'
import AbortionSerieReviewVO from '../../../things/livestock/reviews/AbortionSerieReview'
import AnnualHealthReviewVO  from '../../../things/livestock/reviews/AnnualHealthReview'
import SuspicionReviewVO     from '../../../things/livestock/reviews/SuspicionReview'

class Review extends ThingSubContainer
{
    render()
    {
        const {
            className,
            path,
            style,
            thing
        } = this.props ;

        let AnimalHealthReview ;
        let content ;

        if( thing )
        {
            const { review } = thing ;

            switch( true )
            {
                case (review instanceof AbortionSerieReviewVO) :
                {
                    AnimalHealthReview = AbortionSerieReview ;
                    break ;
                }

                case (review instanceof AbortionReviewVO) :
                {
                    AnimalHealthReview = AbortionReview ;
                    break ;
                }

                case (review instanceof AnnualHealthReviewVO) :
                {
                    AnimalHealthReview = AnnualHealthReview ;
                    break ;
                }

                case (review instanceof SuspicionReviewVO) :
                {
                    AnimalHealthReview = SuspicionReview ;
                    break ;
                }

                default :
                {
                    content = null ;
                }
            }

        }

        if( AnimalHealthReview )
        {
            content = (
                <AnimalHealthReview
                    onChange = { this.change }
                    path     = { path }
                    thing    = { thing }
                />
            );
        }

        return (
        <div
            className = { clsx( 'flex-1 p-12' , className ) }
            style     = { style }
        >
            { content }
        </div>) ;
    }
}

Review.defaultProps =
{
    ...ThingSubContainer.defaultProps
};

Review.propTypes =
{
    ...ThingSubContainer.propTypes
};

export default withi18n( Review );
