import media from './media'

const audio =
{
    ...media,
    remove :
    {
        agree       : 'Supprimer' ,
        description : 'Souhaitez-vous supprimer ce fichier audio ?' ,
        disagree    : 'Annuler' ,
        loading     : 'Suppression en cours...' ,
        title       : 'Supprimer l\'audio ?',
        useID       : false
    },
    snacks   :
    {
        failed  : 'Impossible d\'ajouter un fichier audio',
        removed : 'Audio supprimé',
        success : 'Audio ajouté avec succès',
    },
    title : 'Audio'
};

export default audio ;