import React from 'react'

import compose   from 'vegas-js-core/src/functors/compose'
import isBoolean from 'vegas-js-core/src/isBoolean'

import initApp from '../../../../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import EventIcon from '@material-ui/icons/DateRange'

import identify      from '../../../../../things/identify'
import PropertyValue from '../../../../../things/PropertyValue'
import Event         from '../../../../../things/Event'

import ThingOrderableChildren, { styles } from '../../../ThingOrderableChildren'

const prepare = ( action = null ) => item =>
{
    const {
        subjectOf,
        value
    } = item ;

    let agenda ;
    let courses ;
    let events ;
    let game ;
    let version ;

    if( subjectOf )
    {
        const {
            agenda  : a,
            courses : c,
            events  : e,
            game    : g ,
            version : v
        } = subjectOf ;

        if( isBoolean(a) )
        {
            agenda = a ;
        }

        if( c )
        {
            courses = c ;
        }

        if( e )
        {
            events = e ;
        }

        if( g )
        {
            game = g ;
        }

        if( v )
        {
            version = v ;
        }
    }

    courses = identify( courses ) ;
    events  = identify( events ) ;
    game    = identify( game ) ;

    switch( action )
    {
        case 'add' :
        {
            events.unshift( value.id.toString() ) ;
            break ;
        }
        case 'remove' :
        {
            events = events.filter( id => id !== item.id.toString() ) ;
            break ;
        }
        default :
        {
            //
        }
    }

    const setting =
    {
        agenda,
        courses,
        events,
        game,
        version
    };

    return ({ setting : JSON.stringify( setting ) });
};

class Events extends ThingOrderableChildren
{
    getLocale = () => this.props.locale.things.application.settings.ambulo.events ;

    onAdd    = this.change ;
    onRemove = this.change ;

    addPrepare    = prepare('add') ;
    removePrepare = prepare('remove') ;

    orderPrepare  = ( { ordered } = {} ) =>
    {
        const { thing } = this.props ;
        if( thing )
        {
            let {
                agenda,
                courses,
                events,
                game,
                version
            } = thing ;

            if( ordered )
            {
                events = ordered ;
            }

            courses = identify( courses ) ;
            events  = identify( events ) ;
            game    = identify( game ) ;

            let setting =
            {
                agenda,
                courses,
                events,
                game,
                version
            };

            return { setting : JSON.stringify( setting ) } ;
        }
    };
}

Events.defaultProps =
{
    ...ThingOrderableChildren.defaultProps ,
    clazz      : Event,
    emptyClazz : PropertyValue,
    icon       : <EventIcon/> ,
    member     : 'events'
};

Events.propTypes =
{
    ...ThingOrderableChildren.propTypes
};

export default compose( withStyles( styles ) , withRouter , initApp )( Events ) ;
