import React from 'react'

import StageIcon from '@material-ui/icons/Stars'

import api from "../../../../configs/api"

import Form from '../../../../display/forms/FormElements'

import Stage from "../../../../things/Stage"
import Thing from "../../../../things/Thing"

const elements =
[
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'name',
            key          : 'name',
            label        : 'Nom du l\'étape',
            autoComplete : 'off' ,
            autoFocus    : true,
            fullWidth    : true,
            helperText   : 'Obligatoire',
            margin       : 'normal',
            required     : true,
            select       : false,
            type         : 'text',
            variant      : 'outlined'
        }
    },
    {
        type  : Form.SEARCH_SELECTOR ,
        path  : api.stages.url ,
        clazz : Stage ,
        clearLabel : 'Vider' ,
        closeLabel : 'Fermer' ,
        init  :
        {
            id         : 'stage',
            key        : 'stage',
            label      : 'Point d\'intéret',
            autoFocus  : false,
            fullWidth  : true,
            helperText : 'Obligatoire',
            margin     : 'normal',
            required   : true,
            type       : 'search',
            variant    : 'outlined'
        },
        inputProps :
        {
            label : 'Sélectionner un point d\'intéret'
        },
        searchIcon : <StageIcon/>
    }
];

const prepare = ( item ) =>
{
    let {
        name,
        stage
    } = item ;

    if( stage instanceof Thing )
    {
        stage = String(stage.id)
    }
    else
    {
        stage = null ;
    }
    
    return (
    {
        name,
        stage
    });
};

const steps =
{
    add :
    {
        agree       : 'Créer' ,
        disagree    : 'Annuler' ,
        title       : 'Ajouter une étape' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour créer une nouvelle fiche.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en  cours...' ,
        success     : 'Ajout du nouvel enregistrement réussi!' ,
        elements,
        prepare,
    },
    columns :
    {
        created           : 'Création',
        modified          : 'Modification',
        stage             : 'Point d\'intéret'
    },
    loading    : "Chargement ..." ,
    noDataText : 'Aucun résultat' ,
    remove     :
    {
        agree        : 'Supprimer' ,
        description  : 'Souhaitez-vous supprimer l\'enregistrement:' ,
        descriptionX : 'Souhaitez-vous supprimer les {0} enregistrements:' ,
        disagree     : 'Annuler' ,
        loading      : 'Suppression en cours...' ,
        title        : 'Supprimer cette étape ?',
        titleX       : 'Supprimer des étapes ?'
    },
    removeItem  : "Supprimer une étape" ,
    removeItemX : "Supprimer {0} étapes" ,
    search      : 'Rechercher une étape ...' ,
    tooltips    :
    {
        add        : 'Ajouter' ,
        activate   : 'Activer' ,
        deactivate : 'Désactiver' ,
        remove     : 'Supprimer'
    },
    title  : 'Étapes'
};

export default steps ;