import React from 'react'

import compose  from 'vegas-js-core/src/functors/compose'
import isString from 'vegas-js-core/src/isString'

import initApp from '../../../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { Chip } from '@material-ui/core'

import ThingContainer from '../../../containers/ThingContainer'

import { FaExternalLinkSquareAlt } from 'react-icons/fa'

import OAuthVO from '../../../../vo/OAuth'
import ThingVO from '../../../../things/Thing'

const styles = theme => (
{
    chip :
    {
        margin : theme.spacing(1)
    }
});

class Redirect extends ThingContainer
{
    editPrepare = item =>
    {
        let {
            url_redirect
        } = item ;

        return ({
            url_redirect
        });
    };

    getContent = () =>
    {
        let {
            classes,
            thing
        } = this.props;

        let redirects ;

        if( thing instanceof OAuthVO )
        {
            let { url_redirect } = thing ;

            if( isString( url_redirect ) && url_redirect !== '' )
            {
                redirects = url_redirect.split(',') ;
            }
        }

        if( redirects instanceof Array && redirects.length > 0 )
        {
            redirects = redirects.map( ( item , index ) =>
            {
                return (
                <Chip
                    className = { classes.chip }
                    clickable = { false }
                    color     = 'secondary'
                    key       = { 'link_' + index }
                    label     = { item }
                />) ;
            });

            return (
            <div className='flex flex-row flex-wrap'>
                { redirects }
            </div>);
        }

        return null ;
    };

    getEntry = init =>
    {
        let empty = {} ;
        if( init instanceof ThingVO )
        {
            empty = init.toObject() ;
        }
        else if( init )
        {
            empty = { ...init } ;
        }
        return new OAuthVO(empty);
    };

    getLocale = () => this.props.locale.things.application.oauth.redirect;
}

Redirect.defaultProps =
{
    ...ThingContainer.defaultProps ,
    containerProps :
    {
        className : 'w-full',
        elevation : 0
    },
    icon       : <FaExternalLinkSquareAlt fontSize={20}/>,
    optionMode : 'edit',
};

Redirect.propTypes =
{
    ...ThingContainer.propTypes
};

export default compose( withStyles( styles ) , withRouter , initApp )( Redirect );
