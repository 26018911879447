import Article          from './creativework/Article'
import ConceptualObject from './ConceptualObject'
import Course           from './Course'
import Disease          from './medical/Disease'
import Event            from './Event'
import Livestock        from './Livestock'
import Organization     from './Organization'
import Person           from './Person'
import Place            from './Place'
import Stage            from './Stage'
import Technician       from './Technician'
import Thing            from './Thing'
import Veterinarian     from './Veterinarian'

import FavoriteType    from './FavoriteType'
import getFavoriteType from './getFavoriteType'

const types =
{
    [ FavoriteType.article          ] : Article,
    [ FavoriteType.conceptualObject ] : ConceptualObject ,
    [ FavoriteType.course           ] : Course ,
    [ FavoriteType.disease          ] : Disease ,
    [ FavoriteType.event            ] : Event ,
    [ FavoriteType.livestock        ] : Livestock ,
    [ FavoriteType.organization     ] : Organization ,
    [ FavoriteType.person           ] : Person ,
    [ FavoriteType.place            ] : Place ,
    [ FavoriteType.technician       ] : Technician ,
    [ FavoriteType.stage            ] : Stage ,
    [ FavoriteType.unknown          ] : Thing ,
    [ FavoriteType.veterinarian     ] : Veterinarian ,
}

/**
 * Populates the favorite collection of the specific thing reference.
 * @param {*} thing - The thing reference to populate.
 * @param {Function} clazz - The default Thing class to apply.
 * @returns {*} The thing reference.
 */
const populateFavorite = ( thing , clazz = Thing )  =>
{
    if( thing )
    {
        const type = getFavoriteType( thing , { prefix : '' } ) ;

        if( types[ type ] )
        {
            clazz = types[type];
        }

        if( clazz instanceof Function )
        {
            return new clazz(thing) ;
        }
    }
    return thing ;
};

export default populateFavorite ;