const discover =
{
    discover :
    {
        title    : 'À découvrir' ,
        tooltips :
        {
            refresh : 'Annuler' ,
            save    : 'Enregister'
        }
    }
};

export default discover ;