import React , { Component } from 'react'

import PropTypes from 'prop-types'

import { findDOMNode } from "react-dom"

class Printer extends Component
{
    linkTotal    = null ;
    linksLoaded  = null ;
    linksErrored = null ;
    printer      = null ;
    
    print = () =>
    {
        let {
            bodyClass = "" ,
            content,
            copyStyles,
            pageStyle,
        } = this.props ;
    
        const contentEl = content() ;
        
        if( contentEl === undefined )
        {
            console.error( this + ' print failed, the content element not must be null.' );
            return;
        }
    
        const contentNodes = findDOMNode(contentEl);
        
        const linkNodes = document.querySelectorAll("link[rel='stylesheet']");
    
        this.linkTotal    = linkNodes.length || 0 ;
        this.linksLoaded  = [] ;
        this.linksErrored = [] ;
    
        const markLoaded = (linkNode, loaded ) =>
        {
            if( loaded )
            {
                this.linksLoaded.push(linkNode);
            }
            else
            {
                console.error( this + ` was unable to load a link. It may be invalid. Continue attempting to print the page. The link the errored was:` , linkNode );
                this.linksErrored.push(linkNode);
            }
        
            if ( (this.linksLoaded.length + this.linksErrored.length) === this.linkTotal )
            {
                this.triggerPrint(frame);
            }
        };
    
        const frame = document.createElement('iframe');
    
        frame.style.position = "absolute";
        frame.style.top      = "0px";
        frame.style.left     = "0px";
    
        frame.onload = () =>
        {
            if( window.navigator && window.navigator.userAgent.indexOf('Trident/7.0') > -1 )
            {
                frame.onload = null;
            }
        
            const domDoc       = frame.contentDocument || frame.contentWindow.document;
            const srcCanvasEls = contentNodes.querySelectorAll('canvas') ;
        
            domDoc.open() ;
            domDoc.write( contentNodes.outerHTML );
            domDoc.close() ;
        
            // remove date/time from top
            const defaultPageStyle = pageStyle === undefined
                ? "@page { size: auto;  margin: 0mm; } @media print { body { -webkit-print-color-adjust: exact; } }"
                : pageStyle ;
        
            const styleEl = domDoc.createElement('style') ;
            styleEl.appendChild( domDoc.createTextNode(defaultPageStyle) );
            domDoc.head.appendChild(styleEl);
        
            if ( bodyClass && (bodyClass.length > 1) )
            {
                domDoc.body.classList.add(bodyClass);
            }
            
        
            const canvasEls = domDoc.querySelectorAll('canvas');
            for ( let index = 0, l = canvasEls.length; index < l; ++index)
            {
                const node = canvasEls[index];
                node.getContext("2d").drawImage(srcCanvasEls[index], 0, 0);
            }
        
            if ( copyStyles !== false )
            {
                const headEls = document.querySelectorAll("style, link[rel='stylesheet']");
            
                for ( let index = 0, l = headEls.length; index < l ; ++index )
                {
                    const node = headEls[index];
                    if (node.tagName === "STYLE")
                    {
                        const newHeadEl = domDoc.createElement(node.tagName);
                        const sheet     = node.sheet ;
                    
                        if ( sheet )
                        {
                            let styleCSS = "";
                            for (let i = 0; i < sheet.cssRules.length; i++)
                            {
                                if (typeof sheet.cssRules[i].cssText === "string")
                                {
                                    styleCSS += `${sheet.cssRules[i].cssText}\r\n`;
                                }
                            }
                            newHeadEl.setAttribute( "id", `print-${index}` );
                            newHeadEl.appendChild( domDoc.createTextNode(styleCSS) );
                            domDoc.head.appendChild( newHeadEl );
                        }
                    }
                    else
                    {
                        // Many browsers will do all sorts of weird things if they encounter an empty `href`
                        // tag (which is invalid HTML). Some will attempt to load the current page. Some will
                        // attempt to load the page"s parent directory. These problems can cause
                        // `react-to-print` to stop  without any error being thrown. To avoid such problems we
                        // simply do not attempt to load these links.
                        if ( node.hasAttribute("href") && !!node.getAttribute("href") )
                        {
                            const newHeadEl = domDoc.createElement(node.tagName);
                            
                            for ( let i = 0, l = node.attributes.length; i < l ; ++i )
                            {
                                const attr = node.attributes[i];
                                newHeadEl.setAttribute(attr.nodeName, attr.nodeValue);
                            }
                        
                            newHeadEl.onload  = markLoaded.bind(null,newHeadEl,true);
                            newHeadEl.onerror = markLoaded.bind(null,newHeadEl,false);
                            
                            domDoc.head.appendChild(newHeadEl);
                        }
                        else
                        {
                            markLoaded(node, true) ; // `true` because we"ve already shown a warning for this
                        }
                    }
                }
            }
        
            if (this.linkTotal === 0 || copyStyles === false)
            {
                this.triggerPrint(frame);
            }
        };
    
        document.body.appendChild(frame);
    };
    
    render()
    {
        const { children } = this.props;
        
        if( children )
        {
            return React.cloneElement( children ,
            {
                onClick : this.print,
                ref     : ref => this.printer = ref
            });
        }
        
        return null ;
    }
    
    removeWindow = target =>
    {
        setTimeout( () => { target.parentNode.removeChild(target); } , 0 );
    };
    
    triggerPrint = target =>
    {
        const {
            onAfterPrint,
            onBeforePrint
        }
        = this.props;
        
        if( onBeforePrint instanceof Function )
        {
            onBeforePrint();
        }
        
        setTimeout(() =>
        {
            target.contentWindow.focus();
            target.contentWindow.print();
            
            this.removeWindow(target);
            
            if (onAfterPrint instanceof Function )
            {
                onAfterPrint();
            }
        }, 0 );
    };
}

Printer.defaultProps =
{
    bodyClass     : null,
    children      : null,
    content       : null,
    copyStyles    : true,
    onAfterPrint  : null,
    onBeforePrint : null,
    pageStyle     : null
};

Printer.propTypes =
{
    bodyClass     : PropTypes.string,
    children      : PropTypes.element,
    content       : PropTypes.func.isRequired,
    copyStyles    : PropTypes.bool,
    onAfterPrint  : PropTypes.func,
    onBeforePrint : PropTypes.func,
    pageStyle     : PropTypes.string
};

export default Printer ;