const step =
{
    labels :
    {
        add      : 'Ajouter',
        geoExist : 'GPS OK',
        geoNone  : 'GPS introuvable',
        location : 'Lieu: {0}',
        poi      : 'POI: {0}'
    },
    unknown  : 'Inconnu'  ,
    tooltips :
    {
        active     : 'Activer',
        add        : 'Ajouter une étape' ,
        deactivate : 'Désactiver' ,
        down       : 'Descendre',
        edit       : 'Éditer' ,
        remove     : 'Supprimer' ,
        view       : 'Visualiser' ,
        up         : 'Remonter'
    }
};

export default step ;
