import React , { Component, Fragment } from 'react'

import moment from 'moment/moment'

import ceil     from 'vegas-js-core/src/maths/ceil'
import isString from 'vegas-js-core/src/isString'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'

import { Divider, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'

import grey from '@material-ui/core/colors/grey'

import withi18n from '../../../../contexts/i18n/withi18n'

import LabelItem from '../../../../components/items/LabelItem'

import AnimalHealthObservation from '../../../../things/livestock/observations/AnimalHealthObservation'

import AbortionEvent   from '../../../../things/livestock/events/AbortionEvent'
import AbortionReview  from '../../../../things/livestock/reviews/AbortionReview'
import Disease         from '../../../../things/medical/Disease'
import Livestock       from '../../../../things/Livestock'
import MedicalAnalysis from '../../../../things/medical/MedicalAnalysis'
import Organization    from '../../../../things/Organization'
import Person          from '../../../../things/Person'
import PropertyValue   from '../../../../things/PropertyValue'
import Thing           from '../../../../things/Thing'
import Veterinarian    from '../../../../things/Veterinarian'
import Word            from '../../../../things/Word'
import Workshop        from '../../../../things/Workshop'

import getLocaleThingLabel from '../../../../things/getLocaleThingLabel'

const styles = () => (
{
    cell :
    {
        whiteSpace : "normal",
        wordWrap   : "break-word"
    },
    content :
    {
        overflowX: 'hidden',
        '@media print'  :
        {
            width           : '1190px',
            transform       : 'scale(0.9)',
            transformOrigin : 'top'
        },
        '& table' :
        {
            '& th:first-child, & td:first-child':
            {
                paddingLeft: 0 + '!important'
            },
            '& th:last-child, & td:last-child'  :
            {
                paddingRight: 0 + '!important'
            }
        }
    },
    head :
    {
        backgroundColor : grey[50],
        color           : grey[500]
    },
    row :
    {
        '&:nth-of-type(odd)':
        {
            backgroundColor : grey[200]
        }
    }
});

class AbortionDeclaration extends Component
{
    getAuthority = ( authority ) =>
    {
        if( authority instanceof Organization || authority instanceof Person )
        {
            const locale = this.getLocale() ;

            let city ;
            let name ;
            let street ;

            const { lang } = this.props ;

            name = getLocaleThingLabel( authority , lang , true ) ;
            if( isString(name) && (name !== '') )
            {
                name = (
                    <Typography
                        className = 'w-full font-semibold'
                        color     = 'textPrimary'
                    >
                        { name }
                    </Typography>
                );
            }
            else
            {
                name = null ;
            }

            const { address } = authority ;
            if( address )
            {
                let {
                    addressLocality,
                    postalCode,
                    streetAddress
                }
                = address ;

                city = [] ;

                if( isString(streetAddress) && (streetAddress !== '') )
                {
                    street = (
                    <Typography
                        className = 'w-full'
                        color     = 'textPrimary'
                    >
                        { streetAddress }
                    </Typography>);
                }
                else
                {
                    street = null ;
                }

                if( isString(postalCode) && (postalCode !== '') )
                {
                    city = [ postalCode ];
                }

                if( isString(addressLocality) && (addressLocality !== '') )
                {
                    city = [ addressLocality ];
                }

                if( city.length > 0 )
                {
                    city = city.join( locale.separator ) ;
                    city = (
                        <Typography
                            className = 'w-full'
                            color     = 'textPrimary'
                        >
                            { city }
                        </Typography>
                    );
                }
            }

            return (
            <Fragment>
                { name }
                { street }
                { city }
            </Fragment>);
        }
        return null ;
    };

    getEvent = () =>
    {
        const locale = this.getLocale() ;

        let content = null ;

        let title = locale.event.title ;
        if( isString(title) && title !== '' )
        {
            title = (
            <Fragment>
                <Typography
                    className = 'w-full font-semibold'
                    color     = 'textPrimary'
                    variant   = 'body2'
                >
                    { title }
                </Typography>
                <Divider light className='my-8'/>
            </Fragment>);
        }
        else
        {
            title = null ;
        }

        const { thing } = this.props  ;
        if( thing instanceof AnimalHealthObservation )
        {
            const { review } = thing ;
            if( review instanceof AbortionReview )
            {
                const { event } = review ;

                if( event instanceof AbortionEvent )
                {
                    const { lang } = this.props ;

                    let {
                        analysis,
                        identifier,
                        date,
                        period
                    }
                    = event ;

                    if( isString( date ) )
                    {
                        date = moment(date).format(locale.datePattern) ;
                    }

                    if( period instanceof Word )
                    {
                        period = period.getLocaleName( lang ) ;
                    }

                    if( analysis instanceof Array && analysis.length > 0 )
                    {
                        const { classes } = this.props ;

                        analysis = (
                        <Table
                            className = 'mt-16'
                        >
                            <TableHead>
                                <TableRow className={ classes.head }>
                                    <TableCell align='left' className='w-auto'>
                                        <Typography
                                            className = 'px-16 font-semibold'
                                            variant   = 'caption'
                                        >
                                            { locale.event.analysis.disease  }
                                        </Typography>
                                    </TableCell>
                                    <TableCell align='center' className='w-auto'>
                                        <Typography
                                            className = 'px-16 font-semibold'
                                            variant   = 'caption'
                                        >
                                            { locale.event.analysis.method }
                                        </Typography>
                                    </TableCell>
                                    <TableCell align='center' className='w-auto'>
                                        <Typography
                                            className = 'px-16 font-semibold'
                                            variant   = 'caption'
                                        >
                                            { locale.event.analysis.sampling }
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {
                                analysis.map( ( item , index ) =>
                                {
                                    let disease ;
                                    let methods ;
                                    let samplings ;

                                    if( item instanceof MedicalAnalysis )
                                    {
                                        let {
                                            disease:dis,
                                            method,
                                            sampling
                                        }
                                        = item ;
                                        if( dis instanceof Disease )
                                        {
                                            disease = dis.getLocaleAlternateName(lang) ;
                                            if( isString(disease) && dis !== '' )
                                            {
                                                disease = ucFirst(disease) ;
                                            }
                                            else
                                            {
                                                disease = dis.getLocaleName(lang) ;
                                            }
                                        }

                                        if( method instanceof Array && method.length > 0 )
                                        {
                                            methods = method.map( item =>
                                            {
                                                if( item instanceof Word )
                                                {
                                                    return ucFirst(item.getLocaleName(lang)) ;
                                                }
                                                return null ;
                                            } ).filter( item => item !== null ) ;

                                            methods = methods.join( ' / ' ) ;
                                        }

                                        if( sampling instanceof Array && sampling.length > 0 )
                                        {
                                            samplings = sampling.map( item =>
                                            {
                                                if( item instanceof Word )
                                                {
                                                    return ucFirst(item.getLocaleName(lang)) ;
                                                }
                                                return null ;
                                            } ).filter( item => item !== null ) ;

                                            samplings = samplings.join( ' / ' ) ;
                                        }
                                    }

                                    if( !isString(disease) || disease === '' )
                                    {
                                        disease = locale.empty ;
                                    }

                                    if( !isString(methods) || methods === '' )
                                    {
                                        methods = locale.empty ;
                                    }

                                    if( !isString(samplings) || samplings === '' )
                                    {
                                        samplings = locale.empty ;
                                    }

                                    return (
                                    <TableRow
                                        className = { classes.row }
                                        key       = {'row_' + index }
                                    >
                                        <TableCell align='left' className='w-auto' >
                                            <Typography
                                                className = 'px-16 break-words whitespace-normal font-medium'
                                                variant   = 'caption'
                                            >
                                                { disease }
                                            </Typography>
                                        </TableCell>
                                        <TableCell align='center'>
                                            <Typography
                                                className = 'px-16 break-words whitespace-normal'
                                                variant   = 'caption'
                                            >
                                                { methods }
                                            </Typography>
                                        </TableCell>
                                        <TableCell align='center'>
                                            <Typography
                                                className = 'px-16 break-words whitespace-normal'
                                                variant   = 'caption'
                                            >
                                                { samplings }
                                            </Typography>
                                        </TableCell>
                                    </TableRow>) ;
                                })
                            }
                            </TableBody>
                        </Table>);
                    }

                    content = (
                    <div className='w-full'>
                        <div className='flex w-full'>
                            <div className='w-1/3 px-16'>
                                <LabelItem
                                    divider      = { false }
                                    enabled      = {!!(identifier)}
                                    labelVariant = 'caption'
                                    title        = { locale.event.identifier }
                                    titleVariant = 'caption'
                                >
                                    { isString(identifier) && identifier !== '' ? identifier : locale.empty }
                                </LabelItem>
                            </div>

                            <div className='w-1/3 px-16'>
                                <LabelItem
                                    divider      = { false }
                                    enabled      = {!!(date)}
                                    labelVariant = 'caption'
                                    title        = { locale.event.date }
                                    titleVariant = 'caption'
                                >
                                    { isString(date) && date !== '' ? date : locale.empty }
                                </LabelItem>
                            </div>

                            <div className='w-1/3 px-16'>
                                <LabelItem
                                    divider      = { false }
                                    enabled      = {!!(period)}
                                    labelVariant = 'caption'
                                    title        = { locale.event.period }
                                    titleVariant = 'caption'
                                >
                                    { isString(period) && period !== '' ? ucFirst(period) : locale.empty }
                                </LabelItem>
                            </div>
                        </div>
                        <div className='w-full'>
                            { analysis }
                        </div>
                    </div>);
                }
            }
        }

        return (
        <div className='w-full border border-grey border-dashed p-16 rounded'>
            { title }
            { content }
        </div>) ;
    };

    getLivestock = () =>
    {
        // ----------

        let authority ;

        const locale = this.getLocale();

        // ----------

        let title = locale.livestock ;
        if( isString(title) && title !== '' )
        {
            title = (
            <Fragment>
                <Typography
                    className = 'w-full font-semibold'
                    color     = 'textSecondary'
                    variant   = 'body2'
                >
                    { title }
                </Typography>
                <Divider light className='w-2/3 my-8'/>
            </Fragment>);
        }
        else
        {
            title = null ;
        }

        // ----------

        const { thing } = this.props ;
        if( thing )
        {
            const { about:livestock } = thing ;
            if( livestock instanceof Livestock )
            {
                let { organization } = livestock ;
                if( organization instanceof Organization )
                {
                    authority = this.getAuthority( organization ) ;
                }
                else
                {
                    authority = null ;
                }
            }
        }

        // ----------

        return (
        <div className='w-full px-16'>
            { title }
            { authority }
        </div>) ;
    };

    getReview = () =>
    {
        const locale = this.getLocale() ;

        let content = null ;

        let title = locale.review.title ;
        if( isString(title) && title !== '' )
        {
            title = (
            <Fragment>
                <Typography
                    className = 'w-full font-semibold'
                    color     = 'textSecondary'
                    variant   = 'body2'
                >
                    { title }
                </Typography>
                <Divider light className='w-2/3 my-8'/>
            </Fragment>);
        }
        else
        {
            title = null ;
        }

        const { thing } = this.props ;
        if( thing )
        {
            const { review } = thing ;
            if( review instanceof AbortionReview )
            {
                let {
                    date,
                    distance
                }
                = review ;

                if ( isString(date) && date !== '' )
                {
                    date = moment(date).format(locale.datePattern);
                }
                else
                {
                    date = locale.empty ;
                }

                if ( distance >= 0 )
                {
                    distance = ceil(distance) + ' ' + locale.review.distanceUnity;
                }
                else
                {
                    distance = locale.empty ;
                }

                date = (
                <div className='flex flex-row items-center'>
                    <Typography variant='body2' className="mr-8">
                        { locale.review.date }
                    </Typography>
                    <Typography variant='body2' className='mr-8 font-medium' >
                        { date }
                    </Typography>
                </div>);

                distance = (
                <div className='flex flex-row items-center'>
                    <Typography variant='body2' className="mr-8">
                        { locale.review.distance }
                    </Typography>
                    <Typography variant='body2' className='mr-8 font-medium' >
                        { distance }
                    </Typography>
                </div>);

                content = (
                    <Fragment>
                        { date }
                        { distance }
                    </Fragment>
                );
            }
        }

        return (
        <div className='w-full px-16'>
            { title }
            { content }
        </div>) ;
    };

    getVeterinarian = () =>
    {
        // ----------

        let content ;

        const locale = this.getLocale();

        // ----------

        let title = locale.veterinarian ;
        if( isString(title) && title !== '' )
        {
            title = (
            <Fragment>
                <Typography
                    className = 'w-full font-semibold'
                    color     = 'textSecondary'
                    variant   = 'body2'
                >
                    { title }
                </Typography>
                <Divider light className='w-2/3 my-8'/>
            </Fragment>);
        }
        else
        {
            title = null ;
        }

        // ----------

        let { veterinarian } = this.props ;
        if( veterinarian instanceof Veterinarian )
        {
            let authority = null ;
            let order     = null ;

            const {
                authority:auth,
                identifier
            } = veterinarian ;

            if( auth )
            {
                authority = this.getAuthority(auth) ;
            }

            if( isString(identifier) && identifier !== '' )
            {
                order = (
                <div className='flex flex-row w-full items-center'>
                    <Typography
                        className = 'font-medium mr-8'
                        color     = 'textPrimary'
                    >
                        { locale.order }
                    </Typography>
                    <Typography
                        color = 'textPrimary'
                    >
                        { identifier }
                    </Typography>
                </div>);
            }

            content = (
                <Fragment>
                    { order }
                    { authority }
                </Fragment>
            );
        }

        // ----------

        return (
        <div className='w-full px-16 mt-16'>
            { title }
            { content }
        </div>) ;
    };


    getWorkshop = () =>
    {
        // ----------

        let content = null ;

        const locale = this.getLocale();

        // ----------

        let title = locale.workshop ;
        if( isString(title) && title !== '' )
        {
            title = (
            <Fragment>
                <Typography
                    className = 'w-full font-semibold'
                    color     = 'textSecondary'
                    variant   = 'body2'
                >
                    { title }
                </Typography>
                <Divider light className='w-2/3 my-8'/>
            </Fragment>);
        }
        else
        {
            title = null ;
        }

        // ----------

        const { thing } = this.props ;
        if( thing )
        {
            const { subject:workshop } = thing ;
            if( workshop instanceof Workshop )
            {
                const { lang } = this.props ;

                let {
                    identifier,
                    production
                } = workshop ;

                if ( identifier instanceof PropertyValue)
                {
                    let { additionalType, value } = identifier ;

                    if( additionalType instanceof Thing )
                    {
                        additionalType = (
                            <Typography
                                className = 'mr-8'
                                variant   = 'body2'
                            >
                                { additionalType.getLocaleName(lang) }
                            </Typography>
                        ) ;
                    }
                    else
                    {
                        additionalType = null ;
                    }

                    if( isString(value) )
                    {
                        value = (
                        <Typography variant='body2' className='mr-8 font-medium'>
                            {value}
                        </Typography>);
                    }
                    else
                    {
                        value = null ;
                    }

                    identifier = (
                    <div className='flex flex-row item-center'>
                        { additionalType }
                        { additionalType && <Typography className="mr-8" variant='body2'>›</Typography> }
                        { value }
                    </div>);
                }
                else
                {
                    identifier = null ;
                }

                if (production instanceof Word)
                {
                    production = (
                    <div className='flex flex-row items-center'>
                        <Typography variant='body2' className="mr-8">
                            { locale.production }
                        </Typography>
                        <Typography variant='body2' className='mr-8 font-medium' >
                            { ucFirst(production.getLocaleName(lang)) }
                        </Typography>
                    </div>);
                }

                content = (
                    <Fragment>
                        { identifier }
                        { production }
                    </Fragment>
                );
            }
        }

        // ----------

        return (
        <div className='w-full px-16 mt-16'>
            { title }
            { content }
        </div>) ;
    };

    getHeader = () =>
    {
        const locale = this.getLocale() ;
        const {
            alternativeHeadline,
            headline
        }
        = locale ;
        return (
        <div
            className = 'w-full mt-16 mb-56 text-center'
        >
            <Typography
                className = 'w-full'
                color     = 'textPrimary'
                variant   = 'h5'
            >
                { headline }
            </Typography>

            <Typography
                className = 'w-full'
                color     = 'textSecondary'
                variant   = 'subtitle2'
            >
                 { alternativeHeadline }
            </Typography>
        </div>
        );
    };

    render()
    {
        const { thing } = this.props ;
        if( thing instanceof AnimalHealthObservation )
        {
            const locale = this.getLocale() ;

            return (
             <div className='w-full'>

                 { this.getHeader() }

                 <div className="flex flex-row w-full p-16">

                     <div className='w-1/2 p-16'>
                         { this.getReview()   }
                         { this.getVeterinarian() }
                     </div>

                     <div
                        className = 'w-1/2 p-16 bg-grey-300 border border-grey border-dashed rounded'
                     >
                         { this.getLivestock() }
                         { this.getWorkshop()  }
                     </div>

                 </div>

                 <div className='flex flex-col w-full px-16 pb-16'>
                     <div className='w-full p-16 h-92 border border-grey border-dashed rounded'>
                         <Typography>
                             { locale.sign }
                         </Typography>
                     </div>
                 </div>

                 <div className='flex px-16 pb-16 w-full'>
                    { this.getEvent() }
                 </div>

                 {/*<Typography*/}
                 {/*color     = 'textSecondary'*/}
                 {/*className = 'w-full font-light mt-8'*/}
                 {/*variant   = 'caption'*/}
                 {/*>*/}
                 {/*{ locale.notes }*/}
                 {/*</Typography>*/}
             </div>
            );
        }

        return null ;
    }

    getLocale = () => this.props.locale.print.livestock.reviews.abortion.abortionDeclaration ;
}

AbortionDeclaration.defaultProps =
{
    thing        : null,
    veterinarian : null
};

AbortionDeclaration.propTypes =
{
    thing        : PropTypes.object,
    veterinarian : PropTypes.object
};

export default withStyles( styles )( withi18n( AbortionDeclaration ) ) ;
