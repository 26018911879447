const select =
{
    select   : '1 élément sélectionné' ,
    selectX  : '{0} éléments sélectionnés' ,
    title    : 'Sélectionner',
    tooltips :
    {
        close   : 'Fermer',
        remove  : 'Supprimer (1)' ,
        removeX : 'Supprimer ({0})' ,
        select  : 'Tout sélectionner'
    }
};

export default select ;