import React from 'react'

import PropTypes from 'prop-types'

import compose  from 'vegas-js-core/src/functors/compose'
import isString from 'vegas-js-core/src/isString'
import leading  from 'vegas-js-core/src/numbers/leading'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import initApp from '../../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { Divider, Grid } from '@material-ui/core'

import LabelItem from '../../../components/items/LabelItem'

import ThingContainer from '../../containers/ThingContainer'

import Answers       from './Answers'
import QuestionImage from "./QuestionImage"

import QuestionVO from '../../../things/Question'
import Word       from '../../../things/Word'

import {
    GiSelect,
    GiCog,
    GiLaurelsTrophy,
    GiRingingAlarm,
    GiRolledCloth,
    GiSherlockHolmes,
    GiTrophy
}
from 'react-icons/gi'

const styles = () => ({});

const defaultItemPolicy =
{
    description : true ,
    fail        : true ,
    image       : true ,
    multiple    : true ,
    score       : true ,
    success     : true ,
    tip         : true ,
    type        : true
};

const column =
{
    md : 6,
    sm : 6,
    xs : 12
};

const full =
{
    xs : 12
};

class Question extends ThingContainer
{
    getContent = () =>
    {
        const {
            thing,
            uri
        } = this.props ;

        return (
            <div className = 'w-full'>

                { this.getInformations() }

                <Divider light className="my-16"/>

                <div className='w-full flex flex-wrap pt-16'>
                    { this.getMessages('w-full md:w-1/2 lg:w-1/2 xl:2/3') }
                    { this.getImages('w-full md:w-1/2 lg:w-1/2 xl:1/3') }
                </div>

                <Divider light className="my-16"/>

                <Answers
                    addUri         = { uri }
                    containerProps = {
                    {
                        className : 'bg-transparent w-full shadow-none pb-16',
                        elevation : 0,
                        square    : true
                    }}
                    editUri   = { uri }
                    onChange  = { this.change }
                    removeUri = { uri }
                    subjectOf = { thing }
                    thing     = { thing }
                    uri       = { uri }
                />

            </div>
        );
    };

    getImages = ( className = 'w-full' ) =>
    {
        const {
            thing,
            uri
        } = this.props ;
        return (
        <QuestionImage
            className = { className }
            onChange  = { this.change }
            thing     = { thing }
            removeUri = { uri }
            uri       = { uri }
        />);
    };

    getInformations = () =>
    {
        let { itemPolicy, lang, thing } = this.props;

        itemPolicy = { ...defaultItemPolicy , ...itemPolicy } ;

        const locale = this.getLocale() ;

        const { labels } = locale ;

        let items = [] ;

        if( thing instanceof QuestionVO )
        {
            let {
                additionalType,
                multiple,
                score
            } = thing;

            if( itemPolicy.score )
            {
                items.push(
                    <Grid key={'item_score'} item {...column}>
                        <LabelItem
                            icon={<GiTrophy size='1em'/>}
                            title={labels.score}
                        >
                            {isNaN(score) || score < 0 ? labels.empty : leading(score)}
                        </LabelItem>
                    </Grid>);
            }

            if( itemPolicy.multiple )
            {
                items.push(
                <Grid key={'item_multiple'} item {...column}>
                    <LabelItem
                        icon  = { <GiSelect size='1em' /> }
                        title = { labels.multiple }
                    >
                        { !!(multiple) ? labels.yes : labels.no }
                    </LabelItem>
                </Grid>);
            }

            if( itemPolicy.type )
            {
                if (additionalType instanceof Word)
                {
                    additionalType = additionalType.getLocaleName(lang);
                }
                items.push(
                    <Grid key={'item_type'} item {...column}>
                        <LabelItem
                            icon={<GiCog size='1em'/>}
                            title={labels.additionalType}
                        >
                            {isString(additionalType) && (additionalType !== '') ? ucFirst(additionalType) : labels.empty}
                        </LabelItem>
                    </Grid>);
            }
        }

        return (
            <Grid
                container = { true }
                spacing   = { 2 }
                className = 'w-full'
            >
                { items }
            </Grid>
        );

    };

    getLocale = () => this.props.locale.things.game.question;

    getMessages = ( className = "w-full" ) =>
    {
        let { lang, itemPolicy, thing } = this.props;

        itemPolicy = { ...defaultItemPolicy , ...itemPolicy } ;

        const locale = this.getLocale() ;

        const { labels } = locale ;

        let items = [] ;

        if( thing instanceof QuestionVO )
        {
            if( itemPolicy.description === true )
            {
                let description = thing.getLocaleDescription(lang);
                items.push(
                    <Grid key={'item_description'} item {...full}>
                        <LabelItem
                            icon  = { <GiRolledCloth/> }
                            title = { labels.description }
                        >
                            { isString(description) && (description !== '') ? ucFirst(description) : labels.empty }
                        </LabelItem>
                    </Grid>);
            }

            if( itemPolicy.success === true )
            {
                let success = thing.getLocaleSuccess(lang);
                items.push(
                    <Grid key={'item_success'} item {...full}>
                        <LabelItem
                            icon={<GiLaurelsTrophy/>}
                            title={labels.success}
                        >
                            {isString(success) && (success !== '') ? ucFirst(success) : labels.empty}
                        </LabelItem>
                    </Grid>);
            }

            if( itemPolicy.fail === true )
            {
                let fail = thing.getLocaleFail(lang);
                items.push(
                    <Grid key={'item_fail'} item {...full}>
                        <LabelItem
                            icon={<GiRingingAlarm/>}
                            title={labels.fail}
                        >
                            {isString(fail) && (fail !== '') ? ucFirst(fail) : labels.empty}
                        </LabelItem>
                    </Grid>);
            }

            if( itemPolicy.tip === true )
            {
                let tip = thing.getLocaleTip(lang);
                items.push(
                    <Grid key={'item_tip'} item {...full}>
                        <LabelItem
                            icon  = { <GiSherlockHolmes /> }
                            title = { labels.tip }
                        >
                            { isString(tip) && (tip !== '') ? ucFirst(tip) : labels.empty }
                        </LabelItem>
                    </Grid>);
            }
        }

        return (
            <Grid
                container = { true }
                spacing   = { 2 }
                className = { className }
            >
                { items }
            </Grid>
        );
    };
}

Question.defaultProps =
{
    ...ThingContainer.defaultProps ,
    containerProps :
    {
        className : "bg-transparent w-full shadow-none p-12 mb-16",
        elevation : 0,
        square    : true
    },
    itemPolicy   : null ,
    titleVisible : false
};

Question.propTypes =
{
    ...ThingContainer.propTypes,
    itemPolicy : PropTypes.object
};

export default compose( withStyles( styles ) , withRouter , initApp )( Question ) ;
