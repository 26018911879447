import React from 'react'

import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import PersonIcon         from '@material-ui/icons/Person'

import Form from '../../../../display/forms/FormElements'

import Organization  from '../../../../things/Organization'
import Person        from '../../../../things/Person'
import PropertyValue from '../../../../things/PropertyValue'
import Thing         from '../../../../things/Thing'

import dialogType from '../../../../display/dialogs/dialogType'

import api from '../../../../configs/api'

const elements =
[
    {
        label : 'Personne' ,
        icon  : <PersonIcon/> ,
        elements :
        [
            {
                type       : Form.SEARCH_SELECTOR ,
                clazz      : Person,
                path       : api.people.url ,
                clearLabel : 'Vider' ,
                closeLabel : 'Fermer' ,
                init       :
                {
                    id        : 'value',
                    key       : 'value',
                    label     : 'Personne',
                    autoFocus : false,
                    fullWidth : true,
                    margin    : 'normal',
                    required  : true,
                    type      : 'search',
                    variant   : 'outlined'
                },
                inputProps : { label : 'Personne...' },
                logics :
                {
                    disabledSuggestions : ( props ) =>
                    {
                        const { rel } = props ;
                        if( rel instanceof Thing )
                        {
                            const { subjectOf } = rel ;
                            if( subjectOf instanceof Thing )
                            {
                                const { members } = subjectOf ;
                                if( members instanceof Array )
                                {
                                    return members.map( item => item instanceof Person ? item : null ) ;
                                }
                            }
                        }
                        return null ;
                    }
                }
            }
        ]
    },
    {
        label : 'Organisation' ,
        icon  : <AccountBalanceIcon/> ,
        elements :
        [
            {
                type       : Form.SEARCH_SELECTOR ,
                path       : api.organizations.url ,
                clazz      : Organization,
                clearLabel : 'Vider' ,
                closeLabel : 'Fermer' ,
                init       :
                {
                    id        : 'value',
                    key       : 'value',
                    label     : 'Organisation',
                    autoFocus : false,
                    fullWidth : true,
                    margin    : 'normal',
                    required  : true,
                    type      : 'search',
                    variant   : 'outlined'
                },
                inputProps : { label : 'Organisation...' },
                logics :
                {
                    disabledSuggestions : ( props ) =>
                    {
                        const { rel } = props ;
                        if( rel instanceof Thing )
                        {
                            const { subjectOf } = rel ;
                            if( subjectOf instanceof Thing )
                            {
                                const { members } = subjectOf ;
                                if( members instanceof Array )
                                {
                                    return members.map( item => item instanceof Organization ? item : null ) ;
                                }
                            }
                        }
                        return null ;
                    }
                }
            }
        ]
    }
];

const prepare =
{
    add : ( item ) =>
    {
        let type = null ;
        if( item )
        {
            const { value } = item ;

            if( value instanceof Person )
            {
                type = 'person' ;
            }
            else if ( value instanceof Organization )
            {
                type = 'organization' ;
            }
        }
        return { type };
    },
    remove : ( item ) =>
    {
        let type = null ;

        if( item instanceof Person )
        {
            type = 'person' ;
        }
        else if ( item instanceof Organization )
        {
            type = 'organization' ;
        }
        return { type } ;
    }
};

const mapUri = ( item , uri ) =>
{
    if( item instanceof PropertyValue)
    {
        const { value } = item ;
        if( value instanceof Thing )
        {
            return uri + '/' + value.id ;
        }
    }
    return uri ;
};

const producer =
{
    add :
    {
        agree       : 'Ajouter' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez sélectionner une personne en cliquant ci-dessous.' ,
        elements,
        fail    : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading : 'Chargement en  cours...' ,
        mapUri,
        prepare : prepare.add,
        success : 'Ajout du nouveau producteur réussi!' ,
        title   : 'Ajouter un producteur',
        type    : dialogType.SWIPEABLE
    },
    remove :
    {
        agree       : 'Supprimer' ,
        description : 'Souhaitez-vous supprimer ce producteur:' ,
        disagree    : 'Annuler' ,
        loading     : 'Suppression en cours...' ,
        prepare     : prepare.remove,
        title       : 'Supprimer le producteur ?'
    },
    title : 'Producteurs' ,
    tooltips :
    {
        add    : "Ajouter" ,
        edit   : "Éditer" ,
        remove : "Supprimer"
    }
};

export default producer ;
