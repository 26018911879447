import initDialog from '../initDialog'

import { AddDialog } from '../AddDialog'

import Thing from "../../../things/Thing"

import api from '../../../configs/api'

const styles = () => ({});

export class AddApplicationDialog extends AddDialog
{
    getLocale = () => this.props.locale.dialogs.application.application.add ;

    prepare = item =>
    {
        const { additionalType, name } = item ;
        return (
        {
            additionalType : additionalType instanceof Thing ? additionalType.id : null ,
            name
        });
    };
}

AddApplicationDialog.defaultProps =
{
    ...AddDialog.defaultProps,
    uri : api.applications.url
};

AddApplicationDialog.propTypes =
{
    ...AddDialog.propTypes
};

export default initDialog({ styles })( AddApplicationDialog ) ;
