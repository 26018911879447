import React from "react"

import notEmpty from 'vegas-js-core/src/strings/notEmpty'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import AboutIcon  from '@material-ui/icons/ChromeReaderMode'
import MediasIcon from '@material-ui/icons/PermMedia'
import MoreIcon   from '@material-ui/icons/Assignment'

import ThingView , { defaultStyle } from '../../display/views/ThingView'

import About from './views/About'
import More  from './views/More'

import api   from '../../configs/api'
import paths from '../../configs/paths'

import getLocaleHeadline from '../../things/getLocaleHeadline'

import ArticleVO from '../../things/creativework/Article'

import Medias from '../medias/Medias'

import initView from '../../display/views/initView'

const styles = defaultStyle();

class Article extends ThingView
{
    getLocale = () => this.props.locale.views.article ;

    getTitle = thing =>
    {
        if( thing instanceof ArticleVO )
        {
            const { lang } = this.props ;

            let label = getLocaleHeadline( thing , lang ) ;
            if( notEmpty(label) )
            {
                return ucFirst(label) ;
            }

            label = thing.getLocaleAlternateName( lang ) ;
            if( notEmpty(label) )
            {
                return ucFirst(label)  ;
            }

            label = thing.getLocaleName(lang) ;
            if( notEmpty(label) )
            {
                return ucFirst(label)  ;
            }
        }
        return null ;
    };
}

Article.defaultProps =
{
    ...ThingView.defaultProps,
    back       : true ,
    clazz      : ArticleVO ,
    icon       : <AboutIcon/>,
    path       : api.articles,
    preferable : true ,
    responsive : true ,
    routes     :
    [
        { id : 'about'  , RouteComponent : About  } ,
        { id : 'medias' , RouteComponent : Medias } ,
        { id : 'more'   , RouteComponent : More   } ,
    ],
    tabs :
    [
        { id : 'about'  , icon : <AboutIcon  className='pr-8' /> } ,
        { id : 'medias' , icon : <MediasIcon className='pr-8' /> } ,
        { id : 'more'   , icon : <MoreIcon   className='pr-8' /> } ,
    ],
    titleAuto  : false // Important to use always the getTitle() method
};

Article.propTypes =
{
    ...ThingView.propTypes,
    path : paths.articles
};

export default initView( Article, styles );
