import React from 'react'

import clean from 'vegas-js-core/src/objects/clean'

import clsx from 'clsx'

import PropTypes from 'prop-types'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { Button, Paper, Typography } from '@material-ui/core'

import AddIcon   from '@material-ui/icons/AddCircle'
import BoardIcon from '@material-ui/icons/DeveloperBoard'

import withDialogs from '../../../../../contexts/dialogs/withDialogs'
import withi18n    from '../../../../../contexts/i18n/withi18n'

import api from '../../../../../configs/api'

import generify from '../../../../../things/generify'

import ThingContainer  from '../../../../containers/ThingContainer'
import TitleHeader     from '../../../../../components/headers/TitleHeader'
import WorkplaceHeader from '../../../workplace/WorkplaceHeader'

import Abortion      from '../../../../../things/livestock/reviews/AbortionReview'
import PropertyValue from '../../../../../things/PropertyValue'
import Workplace     from '../../../../../things/Workplace'
import Workshop      from '../../../../../things/Workshop'

const styles = () => (
{
    root :
    {
        width : '100%'
    }
});

class AbortionWorkplace extends ThingContainer
{
    editPrepare = item =>
    {
        if( item )
        {
            let {
                subjectOf:observation,
                value
            } = item ;

            if( observation )
            {
                let { review } = observation ;
                if( review )
                {
                    if( value instanceof Workplace )
                    {
                        value = generify(value,true) ;

                        const {
                            id,
                            name,
                            url,
                            identifier
                        }
                        = value ;

                        value =
                        {
                            id,
                            url,
                            name,
                            identifier
                        } ;

                        review.subject = value ;
                    }
                    else
                    {
                        review.subject = null ;
                    }

                    review = clean(review.toObject()) ;

                    return { review:JSON.stringify(review) } ;
                }
            }
        }
        return null ;
    };

    getEditButtonThing = () =>
    {
        const { thing } = this.props ;
        if( thing )
        {
            const { review } = thing ;
            if( review instanceof Abortion )
            {
                return review ;
            }
        }
        return null ;
    };

    getEntry = () =>
    {
        const { thing } = this.props ;
        let about;
        let value;
        if( thing )
        {
            const { review } = thing ;
            if( review instanceof Abortion )
            {
                const { about:workshop , subject:workplace } = review ;
                about = workshop ;
                value = workplace ;
            }
        }
        return new PropertyValue( { about, subjectOf:thing , value } ) ;
    };

    getLocale = () => this.props.locale.things.livestock.reviews.abortion.workplace ;

    onEdit = this.change;

    render = () =>
    {
        const {
            classes,
            className,
            containerClassName,
            ContainerComponent,
            containerProps,
            style,
            titleMode,
            thing
        } = this.props ;

        if( thing )
        {
            const { review } = thing ;
            if( review )
            {
                let content ;

                const locale = this.getLocale() ;

                const { subject } = review ;

                //console.log( this + ' render', review , thing ) ;

                if( subject instanceof Workplace )
                {
                    content = (
                    <div className='w-full'>
                        <TitleHeader
                            className = 'w-full pt-8'
                            editable  = { titleMode === 'edit' && this.isEditable() }
                            icon      = { <BoardIcon/> }
                            label     = { locale.labels.workplace }
                            onClick   = { () =>
                            {
                                if( titleMode === 'edit' )
                                {
                                    this.openEditDialog(this.getEditButtonThing()) ;
                                }
                            }}
                            options = { this.getOptions() }
                        />
                        <WorkplaceHeader
                            className = 'mt-16'
                            divider   = { null }
                            thing     = { subject }
                        />
                    </div>);
                }
                else
                {
                    const { about } = review ;
                    if( about instanceof Workshop )
                    {
                        const { workplaces } = about ;
                        if( workplaces instanceof Array && workplaces.length > 0 )
                        {
                            const { addIcon, addButtonProps } = this.props ;
                            if( this.isEditable() )
                            {
                                content = (
                                <div className='flex flex-col items-center justify-center p-16'>
                                    <Button
                                        className = 'px-16'
                                        color     = 'secondary'
                                        variant   = 'contained'
                                        { ...addButtonProps }
                                        onClick = { () => this.openEditDialog(review) }
                                    >
                                        { addIcon }
                                        { locale.labels.select }
                                    </Button>
                                </div>);
                            }
                            else
                            {
                                content = (
                                    <Typography
                                        className = 'w-full px-16 text-center'
                                        variant   = 'subtitle2'
                                    >
                                        { locale.labels.unselect }
                                    </Typography>
                                );
                            }
                        }
                        else
                        {
                            content = (
                                <Typography
                                    className = 'w-full px-16 text-center'
                                    variant   = 'subtitle2'
                                >
                                    { locale.labels.empty }
                                </Typography>
                            );
                        }
                    }
                    else
                    {
                      content = (
                        <Typography
                            color     = 'error'
                            className = 'w-full px-16 text-center'
                            variant   = 'subtitle2'
                        >
                            { locale.labels.emptyWorkshop }
                        </Typography>
                        );
                    }

                }

                let defaultContainerProps ;

                const Container = ContainerComponent || Paper ;

                if( Container === Paper )
                {
                    defaultContainerProps = { elevation : 2 } ;
                }

                return (
                <div
                    className = { clsx(classes.root, className) }
                    style     = { style }
                >
                    <Paper
                        className = { clsx( "bg-transparent p-20 mb-8 flex items-center justify-center" , containerClassName ) }
                        { ...defaultContainerProps }
                        { ...containerProps }
                    >
                        { content }
                    </Paper>
                </div>);
            }
            return null ;
        }

        return null  ;
    }
}

AbortionWorkplace.defaultProps =
{
    ...ThingContainer.defaultProps,
    addButtonProps : null ,
    addIcon        : <AddIcon className='mr-16 self-center'/>,
    editUri        : ( path, props ) =>
    {
        const { thing } = props ;
        if( thing )
        {
            const { id } = thing ;
            if( id )
            {
                return api.observations.url + '/' + id ;
            }
        }
        return null ;
    },
    optionMode : 'none' ,
    titleMode  : 'edit'
};

AbortionWorkplace.propTypes =
{
    ...ThingContainer.propTypes,
    addButtonProps : PropTypes.object ,
    addIcon        : PropTypes.element
};

export default withStyles( styles )( withRouter( withi18n( withDialogs( AbortionWorkplace ) ) ) ) ;
