const thing =
{
    created  : "Créé {0}",
    modified : "Modifié {0}",
    unknown  : 'Inconnu' ,
    tooltips :
    {
        draggable : 'Déplacer'
    }
};

export default thing ;
