import React from 'react'

import PropTypes from 'prop-types'

import withi18n from '../../contexts/i18n/withi18n'

import PeriodLabel from '../labels/PeriodLabel'

import getHorizontalElements from '../getHorizontalElements'

import { ThingCell } from './ThingCell'

export class EventCell extends ThingCell
{
    getContent = () => 
    {
        const { thing } = this.props ;
        if( thing )
        {
            const elements = [ 'period' , 'eventStatus' ] ;
            return getHorizontalElements( { elements , factory:this } ) ;
        }
        return null ;
    };
    
    getEventStatus = ( { className = null , key=undefined } = {}  ) => this.getWord('eventStatus')( { className , key } ) ;
    
    getLocale = () => this.props.locale.display.cells.event;

    getPeriod = ( { className = null , key = undefined } = {} ) =>
    {
        const { period:visible } = this.props;
        if (!visible) 
        {
            return null;
        }
        const thing = this.getThing() ;
        if( thing )
        {
            let { endDate, startDate } = thing ;
            if( endDate || startDate )
            {
                return <PeriodLabel key={key} className={className} thing={thing} />;   
            }
        }
        return null ;
    };
}

EventCell.defaultProps =
{
    ...ThingCell.defaultProps,
    eventStatus : true ,
    period      : true,
    sanitize    : true, // sanitize the copy text
};

EventCell.propTypes =
{
    ...ThingCell.propTypes,
    eventStatus : PropTypes.bool,
    period      : PropTypes.bool
};

export default withi18n(EventCell) ;
