import steps         from './steps'
import technicians   from './technicians'
import veterinarians from './veterinarians'
import workshops     from './workshops'

const labels =
{
    steps,
    technicians,
    veterinarians,
    workshops
};

export default labels ;
