import React from 'react'

import PropTypes from 'prop-types'

import clsx from 'clsx'

import compose from 'vegas-js-core/src/functors/compose'

import { withRouter } from 'react-router-dom'

import withLocale from '../../../contexts/i18n/withLocale'

import ThingSubContainer from '../../../display/containers/ThingSubContainer'

import DiscoverList from '../../../display/things/Discover'

import api from '../../../configs/api'

class Discover extends ThingSubContainer
{
    getLocale = () => this.props.locale.views.course.discover ;

    render = () =>
    {
        const { thing } = this.props ;
        if( thing )
        {
            const {
                className,
                style
            } = this.props;
            let content = (
                <div
                    className = { clsx( 'flex-1 p-12' , className ) }
                    style     = { style }
                >
                    <DiscoverList
                        className = 'flex flex-1'
                        onChange = { this.change }
                        thing    = { thing }
                        uri      = { this.props.uri }
                    />
                </div>
            );
            return this.renderWithTransition( content ) ;
        }
        return null ;
    }
}

Discover.defaultProps =
{
    ...ThingSubContainer.defaultProps,
    uri : api.courses.discover
};

Discover.propTypes =
{
    ...ThingSubContainer.propTypes,
    uri : PropTypes.string.isRequired
};

export default compose(
    withRouter,
    withLocale
)
( Discover );
