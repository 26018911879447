
import Form from '../../../../../display/forms/FormElements'

import Method from '../../../../../net/Method'

const elements =
[
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'url_redirect',
            key          : 'url_redirect',
            label        : 'Redirection(s) URL',
            autoFocus    : false,
            fullWidth    : true,
            helperText   : 'URL séparés d\'une virgule',
            margin       : 'normal',
            required     : true,
            select       : false,
            autoComplete : 'off' ,
            type         : 'text',
            variant      : 'outlined'
        }
    }
];

const redirect =
{
    edit :
    {
        agree       : 'Sauver' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour modifier les redirections.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...' ,
        method      : Method.PATCH ,
        success     : 'Mise à jour réussie!' ,
        title       : 'Modifier les redirections' ,
        elements
    },
    labels :
    {
        copy          : 'Lien copié : {0}',
        empty         : '----' ,
        url_redirect  : 'Redirection(s) URL'
    },
    title    : 'Redirection(s) URL' ,
    tooltips :
    {
        add    : 'Ajouter',
        edit   : 'Modifier',
        remove : 'Supprimer'
    }
};

export default redirect ;