const navbarWidth = 256;

const styles = theme => (
{
    content :
    {
        display                      : 'flex',
        overflow                     : 'auto',
        flex                         : '1 1 auto',
        flexDirection                : 'column',
        width                        : '100%',
        '-webkit-overflow-scrolling' : 'touch'
    },
    contentWrapper :
    {
        display      : 'flex',
        flexDirection: 'column',
        zIndex       : 3,
        overflow     : 'auto',
        flex         : '1 1 auto'
    },
    drawer:
    {
        [theme.breakpoints.up('sm')] :
        {
            width: navbarWidth,
            flexShrink: 0
        }
    },
    footer :
    {
        display: 'flex',
        flex   : '1 0 auto'
    },
    footerWrapper :
    {
        position : 'relative',
        zIndex   : 5
    },
    layout :
    {
        backgroundColor : theme.palette.background.default,
        color           : theme.palette.text.primary,
        display         : 'flex',
        flexDirection   : 'column',
        height          : '100%',
        overflow        : 'auto',
        width           : '100%',
        
        '&.boxed' :
        {
            maxWidth : 1280,
            margin   : '0 auto',
            boxShadow: theme.shadows[3]
        },
        '& table.simple tbody tr td':
        {
            borderColor: theme.palette.divider
        },
        '& table.simple thead tr th':
        {
            borderColor: theme.palette.divider
        },
        '& a:not([role=button])' :
        {
            color         : theme.palette.secondary.main,
            textDecoration: 'none',
            '&:hover'     : {
                textDecoration: 'underline'
            }
        },
        '& [class^="border-"]' :
        {
            borderColor: theme.palette.divider
        },
        '& [class*="border-"]' :
        {
            borderColor: theme.palette.divider
        },
        '&.scroll-body' :
        {
            '& $wrapper'       : {
                height  : 'auto',
                flex    : '0 0 auto',
                overflow: 'auto'
            },
            '& $contentWrapper': {},
            '& $content'       : {}
        },
        '&.scroll-content' :
        {
            '& $wrapper'       : {},
            '& $contentWrapper': {},
            '& $content'       : {}
        }
    },
    navbar :
    {
        display       : 'flex',
        overflow      : 'hidden',
        flexDirection : 'column',
        width         : navbarWidth,
        minWidth      : navbarWidth,
        height        : '100%',
        zIndex        : 4,
        transition    : theme.transitions.create( ['width', 'min-width'] ,
        {
            easing   : theme.transitions.easing.sharp,
            duration : theme.transitions.duration.shorter
        })
    },
    navbarButton :
    {
        '&.left' :
        {
            borderRight: '1px solid ' + theme.palette.divider
        },
        '&.right':
        {
            borderLeft: '1px solid ' + theme.palette.divider
        }
    },
    navbarContent :
    {
        overflowX                    : 'hidden',
        overflowY                    : 'auto',
        '-webkit-overflow-scrolling' : 'touch'
    },
    navbarFolded :
    {
        position : 'absolute',
        width    : 64,
        minWidth : 64,
        top      : 0,
        bottom   : 0
    },
    navbarFoldedOpen :
    {
        width    : navbarWidth,
        minWidth : navbarWidth
    },
    navbarFoldedClose :
    {
        '& $navbarHeader' :
        {
            padding         : '0 8px 0 13px' ,
            '& .logo-text'  : { opacity: 0 } ,
            '& .react-badge': { opacity: 0 }
        },
        '& .list-item-text, & .arrow-icon' :
        {
            opacity: 0
        },
        '& .list-subheader .list-subheader-text':
        {
            opacity: 0
        },
        '& .list-subheader:before' :
        {
            content  : '""',
            display  : 'block',
            position : 'absolute',
            minWidth : 16,
            borderTop: '2px solid',
            opacity  : .2
        },
        '& .collapse-children' :
        {
            display: 'none'
        }
    },
    navbarHeader :
    {
        display: 'flex',
        flex   : '1 1 auto',
        padding: '0 8px 0 16px'
    },
    navbarHeaderWrapper :
    {
        alignItems      : 'center',
        backgroundColor : 'rgba(255, 255, 255, .05)',
        boxShadow       : theme.shadows[1],
        display         : 'flex',
        flex            : '0 1 auto',
        ...theme.mixins.toolbar
    },
    navbarLeft :
    {
        left : 0
    },
    navbarPaperWrapper : {} ,
    navbarWrapper      :
    {
        boxShadow : theme.shadows[3],
        zIndex    : 4
    },
    navbarRight :
    {
        right: 0
    },
    root :
    {
        backgroundColor : theme.palette.background.default,
        color           : theme.palette.text.primary,
        '& table.simple tbody tr td':
        {
            borderColor: theme.palette.divider
        },
        '& table.simple thead tr th':
        {
            borderColor: theme.palette.divider
        },
        '& a:not([role=button])' :
        {
            color         : theme.palette.secondary.main,
            textDecoration: 'none',
            '&:hover'     : {
                textDecoration: 'underline'
            }
        },
        '& [class^="border-"]' :
        {
            borderColor: theme.palette.divider
        },
        '& [class*="border-"]' :
        {
            borderColor: theme.palette.divider
        },
        width  : '100%',
        height : '100%'
    },
    toolbar :
    {
        display : 'flex',
        flex    : '1 0 auto'
    },
    toolbarWrapper :
    {
        display  : 'flex',
        position : 'relative',
        zIndex   : 10000
    },
    wrapper :
    {
        display : 'flex',
        position: 'relative',
        width   : '100%',
        height  : '100%'
    }
}) ;

export default styles ;