import React from "react"

import lime from '@material-ui/core/colors/lime'

import ThesauriGrid from '../../display/grids/ThesauriGrid'

import Pagination from '../../components/paginations/Pagination'
import SearchView from '../../display/views/SearchView'

import initView from '../../display/views/initView'

const styles = theme =>
({
    header :
    {
        height          : 100,
        minHeight       : 100,
        display         : 'flex',
        backgroundColor : lime[700] ,
        backgroundImage : `url(${theme.assets.header})`,
        backgroundSize  : 'cover' ,
        color           : '#fff'
    }
});

class Thesauri extends SearchView
{
    getContent = () =>
    {
        const { search } = this.state ;
        return (
        <ThesauriGrid
            limit            = { 0 }
            parent           = { this.element }
            search           = { search }
            selectVisibility = { Pagination.NONE }
        />);
    };
}

Thesauri.propTypes =
{
    ...SearchView.propTypes
};

export default initView( Thesauri, styles );
