import React from 'react'

import clsx from 'clsx'

import compose  from 'vegas-js-core/src/functors/compose'

import { Link , withRouter } from 'react-router-dom'

import isString from 'vegas-js-core/src/isString'
import format   from 'vegas-js-core/src/strings/fastformat'
import notEmpty from 'vegas-js-core/src/strings/notEmpty'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import { withStyles } from '@material-ui/core/styles'

import {
    Button,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    Collapse,
    ButtonGroup,
    Tooltip,
    Typography,
} from '@material-ui/core'

import blue     from '@material-ui/core/colors/blue'
import blueGrey from '@material-ui/core/colors/blueGrey'
import grey     from '@material-ui/core/colors/grey'
import green    from '@material-ui/core/colors/green'
import red      from '@material-ui/core/colors/red'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ImageIcon      from '@material-ui/icons/Image'

import api from '../../configs/api'

import initApp        from '../../contexts/app/initApp'
import GeoCoordinates from '../../things/GeoCoordinates'
import Place          from '../../things/Place'

import StepAvatar  from '../things/course/StepAvatar'
import ThingAvatar from '../avatars/ThingAvatar'

import Stage from '../../things/Stage'
import Step  from '../../things/Step'
import Thing from '../../things/Thing'
import Word  from '../../things/Word'

import getLocaleAlternativeHeadline from '../../things/getLocaleAlternativeHeadline'
import getLocaleThingLabel          from '../../things/getLocaleThingLabel'
import getMediaSource               from '../../things/getMediaSource'

import ThingCard, { styles } from './ThingCard'

export class StepCard extends ThingCard
{
    getBody = () =>
    {
        const { thing } = this.props ;
        if( thing instanceof Step )
        {
            const { lang } = this.props ;
            let text = thing.getLocaleDescription( lang ) ;
            if( !isString( text ) )
            {
                const { stage } = thing ;
                if( stage instanceof Thing )
                {
                    text = stage.getLocaleDescription( lang ) ;
                    if( !isString( text ) )
                    {
                        const { location } = stage ;
                        if( location instanceof Thing )
                        {
                            text = location.getLocaleDescription( lang ) ;
                        }
                    }
                }
            }

            if( notEmpty( text ) )
            {
                const { active } = thing ;
                return (
                    <Typography
                        align     = 'justify'
                        className = { clsx('w-full font-normal', active ? 'text-gray-800' : 'text-gray-500' ) }
                        variant   = 'caption'
                        variantMapping = {{ caption:'p' }}
                    >
                        { ucFirst( text ) }
                    </Typography>
                )
            }
        }
        return null ;
    };

    getFooter = () =>
    {
        const { thing } = this.props ;
        if( thing )
        {
            const { expanded } = this.state ;
            let {
                classes,
                disabled,
                editable,
                footerOptions
            } = this.props ;

            footerOptions = footerOptions || this.footerOptions ;

            if( footerOptions instanceof Function )
            {
                footerOptions = footerOptions( thing , editable ) ;
            }

            return (
                <div>
                    <CardActions>
                        <ButtonGroup
                            aria-label = "Step options"
                            fullWidth  = { true }
                            size       = "small"
                            variant    = "text"
                        >
                            { this.getStage() }
                            { this.getLocation() }
                            {
                                footerOptions &&
                                <Button
                                    aria-label = "show options"
                                    className  = "w-48"
                                    disabled   = {disabled}
                                    onClick    = { this.collapse }
                                >
                                    <ExpandMoreIcon
                                        className={clsx( classes.expandClose , { [classes.expandOpen] : expanded } )}/>
                                </Button>
                            }
                        </ButtonGroup>
                    </CardActions>
                    {
                        footerOptions &&
                        (
                            <Collapse in={ expanded } timeout="auto" unmountOnExit>
                                <CardContent>
                                    <div className='w-full flex flex-row items-center' >
                                        { footerOptions }
                                    </div>
                                </CardContent>
                            </Collapse>
                        )
                    }
                </div>
            );
        }
        return null ;
    };

    getLabel = () =>
    {
        let label ;
        const { thing , lang } = this.props  ;

        if( thing instanceof Step )
        {
            label = thing.getLocaleTitle(lang) ;

        }
        else if( thing instanceof Thing )
        {
            label = getLocaleThingLabel( thing , lang ) ;
        }

        if( !isString(label) || label === '' )
        {
            const { unknown } = this.getLocale() || {} ;
            if( notEmpty )
            {
                return unknown ;
            }
        }
        return notEmpty(label) ? ucFirst(label) : this.props.label ;
    };

    getLinkOptions = () =>
    {
        const { thing } = this.props ;
        if( thing instanceof Step )
        {
            const { active } = thing ;
            return {
                component : Link ,
                style     : { color: active ? blue[600] : blueGrey[200] } ,
                to        : { pathname : this.getPath() }
            } ;
        }
        return null ;
    };

    getLocale = () => this.props.locale.display.cards.step ;

    getLocation = ( { className } = {} ) =>
    {
        const { thing } = this.props ;
        if( thing )
        {
            const { stage } = thing ;
            if( stage instanceof Stage )
            {
                const { location } = stage ;
                if( location instanceof Place )
                {
                    const { disabled , lang } = this.props ;

                    let { geo , url } = location ;

                    const locale = this.getLocale() ;

                    const { labels : { geoExist, geoNone, location:loc } = {} } = locale ;

                    let label ;
                    let color ;

                    if( geo instanceof GeoCoordinates )
                    {
                        color = green[500];
                        if( isString(geoExist) && geoExist !== '' )
                        {
                            label = geoExist ;
                        }
                    }
                    else
                    {
                        color = red[500];
                        if( isString(geoNone) && geoNone !== '' )
                        {
                            label = geoNone ;
                        }
                    }

                    let chip = (
                        <Button
                            className = { clsx( 'shadow-medium' , className ) }
                            component = { Link }
                            disabled  = { disabled }
                            size      = "small"
                            startIcon = { <ThingAvatar thing={location} className="mr-8"/> }
                            to        = {{ pathname : url.split(api.url)[1] }}
                        >
                            <span className="flex-1" style={{ color }}>{ label }</span>
                        </Button>
                    );

                    if( !disabled )
                    {
                        let tip = format( loc , location.getLocaleName(lang) ) ;
                        if( notEmpty(tip)  )
                        {
                            chip = (
                                <Tooltip placement='top' title={ ucFirst(tip) } >
                                    { chip }
                                </Tooltip>);
                        }
                    }

                    return chip ;
                }
            }
        }
        return null;
    };

    getMedia = () =>
    {
        let item ;

        let {
            config,
            disabled ,
            lang,
            onSelect ,
            thing
        } = this.props ;

        let position = 0 ;

        if( thing )
        {
            const {
                active,
                image,
                position:pos,
                stage
            } = thing ;

            disabled = active !== true ;
            position = pos ;

            if( image )
            {
                item = thing ;
            }
            else if ( stage )
            {
                const { image, location } = stage ;
                if( image )
                {
                    item = stage ;
                }
                else if( location )
                {
                    item = location ;
                }
            }
        }

        let img ;
        if( item )
        {
            const { image : media } = item ;

            let sourceElements = [] ;

            if( media )
            {
                const { contentUrl } = media ;
                const { image } = config ;

                const alt = getLocaleAlternativeHeadline( media , lang ) ;

                const resize = '?w=' + image.maxWidth + '&h=' + image.maxHeight ;

                const source = getMediaSource( media ) ;

                if( source )
                {
                    sourceElements = source.map( element => <source key={element.encodingFormat} srcSet={ element.contentUrl + resize } type={ element.encodingFormat } /> ) ;
                }

                const imgElement = (
                    <img
                        key = { contentUrl }
                        alt = { alt }
                        src = { contentUrl + resize }
                        style = {{ width : '100%' , height : '100%' , objectFit : 'cover' }}
                    />
                );

                sourceElements = [ ...sourceElements , imgElement ] ;
            }

            img = (
                <CardMedia
                    className = 'bg-gray-300 mb-8'
                    component = 'picture'
                    children  = { sourceElements }
                    style     = {
                    {
                        height : 150 ,
                        ...disabled && { filter : 'grayscale(1)' , opacity:0.5 }
                    }}
                />
            );

        }
        else
        {
            img = (
                <div
                    className = { clsx(
                        'flex items-center justify-center mb-8' ,
                        disabled ? 'bg-gray-200' : 'bg-blue-900' )
                    }
                    style     = {{ height : 150 }}
                >
                    <ImageIcon
                        className = { clsx( disabled ? 'text-white' : 'text-white') }
                        style     = {{ fontSize:40 }}
                    />
                </div>
            );
        }

        let select ;
        if( onSelect instanceof Function )
        {
            select = () =>
            {
                onSelect( thing , position ) ;
            }
        }

        return (
            <div style={{ marginBottom : -30 }}>
                <CardActionArea
                    onClick ={ select }
                >
                    { img }
                </CardActionArea>
                <StepAvatar
                    className = 'relative shadow'
                    index     = { position }
                    onSelect  = { select }
                    style     = {{ width:40 , height:40 , left:12, top:-26 , zIndex:40 }}
                    thing     = { thing }
                />
            </div>
        ) ;
    };

    getOptions = () => null ;

    getStage = ( { className } = {} ) =>
    {
        const { thing } = this.props ;
        if( thing )
        {
            const { stage } = thing ;
            if( stage instanceof Stage )
            {
                const { lang } = this.props ;
                let {
                    headline,
                    name,
                    status ,
                    url
                } = stage ;

                let label ;
                let tip ;

                if( headline )
                {
                    tip = stage.getLocaleHeadline(lang) ;
                }

                let backgroundColor = grey[100] ;
                let color           = grey[500] ;

                if( status instanceof Word )
                {
                    let { bgcolor:b, color:c } = status ;
                    if( isString( b ) )
                    {
                        backgroundColor = '#' + b ;
                    }

                    if( isString( c ) )
                    {
                        color = '#' + c ;
                    }
                    label = ucFirst( status.getLocaleName(lang) ) ;
                }

                if( !tip && name )
                {
                    tip = stage.getLocaleName(lang) ;
                }

                let chip = (
                    <Button
                        className = { className }
                        disabled  = { this.props.disabled }
                        component = { Link }
                        size      = "small"
                        startIcon = { <ThingAvatar thing={stage} className='mr-8' /> }
                        style     = {{ backgroundColor }}
                        to        = {{ pathname : url.split(api.url)[1] }}
                    >
                        <span className="flex-1" style={{ color }} >{ label }</span>
                    </Button>
                );

                if( isString(tip) )
                {
                    const locale = this.getLocale() ;
                    const { labels : { poi } = {} } = locale ;
                    tip = format( poi , ucFirst(tip) ) ;
                    chip = <Tooltip placement='top' title={ tip }>{ chip }</Tooltip> ;
                }

                return chip ;
            }
        }
        return null;
    };

    getSubLabel = () =>
    {
        const { thing } = this.props ;
        if( thing instanceof Step )
        {
            const { lang } = this.props ;
            let text = thing.getLocaleSubtitle( lang ) ;
            if( notEmpty(text) )
            {
                const { active } = thing ;
                return (
                    <Typography
                        className = { clsx('w-full font-medium', active ? 'text-gray-800' : 'text-gray-500' ) }
                        variant   = 'subtitle2'
                    >
                        { ucFirst(text) }
                    </Typography>
                );
            }
        }
        return null ;
    };

    onClick = () => () => null ;
}

StepCard.defaultProps =
{
    ...ThingCard.defaultProps,
    expanded : false
};

ThingCard.propTypes =
{
    ...ThingCard.propTypes
};

export default compose(
    initApp,
    withRouter,
    withStyles( styles )
)( StepCard ) ;
