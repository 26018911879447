import isString from 'vegas-js-core/src/isString'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import Form from '../../../../display/forms/FormElements'

import MedicalSampling from '../../../../things/medical/MedicalSampling'
import Thing           from '../../../../things/Thing'
import Word            from '../../../../things/Word'

import api from "../../../../configs/api"

import analysisSampling from './analysisSampling'

const elements =
[
    {
        type       : Form.SEARCH_SELECTOR ,
        path       : api.thesaurus.medical_samplings ,
        clearLabel : 'Vider' ,
        closeLabel : 'Fermer' ,
        init       :
        {
            id         : 'additionalType',
            key        : 'additionalType',
            label      : 'Type de prélèvement',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            required   : true,
            type       : 'search',
            variant    : 'outlined'
        },
        inputProps :
        {
            label : 'Sélectionner une type de prélèvement...'
        },
        logics :
        {
            disabledSuggestions : ( props ) =>
            {
                const { rel } = props ;
                let suggestions = [] ;
                if( rel instanceof Thing )
                {
                    const { additionalType } = rel ;
                    if( additionalType instanceof Word )
                    {
                        suggestions.push( additionalType ) ;
                    }
                }
                return suggestions ;
            }
        }
    },
    {
        type : Form.SWITCH ,
        init :
        {
            id    : 'mandatory',
            key   : 'mandatory',
            label : 'Obligatoire ?'
        }
    }
];

const analysisSamplings =
{
    // dependencies

    analysisSampling,

    // settings

    add :
    {
        elements,
        agree       : 'Créer' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour ajouter un type de prélèvement.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...' ,
        mock        : false,
        success     : 'Ajout réussi!' ,
        title       : 'Ajouter une méthode de prélèvement'
    },
    edit :
    {
        elements,
        agree       : 'Sauver' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour modifier ce type de prélèvement.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...'  ,
        success     : 'Mise à jour réussie!' ,
        title       : 'Modifier le type de prélèvement'
    },
    remove :
    {
        agree       : 'Supprimer' ,
        description : 'Souhaitez-vous supprimer ce type de prélèvement:' ,
        disagree    : 'Annuler' ,
        item        :
        {
            text :
            {
                primary : ( thing , props ) =>
                {
                    let label = null ;
                    if( thing instanceof MedicalSampling )
                    {
                        const { additionalType } = thing ;

                        if( additionalType instanceof Thing )
                        {
                            label = additionalType.getLocaleName( props.lang ) ;
                        }
                    }

                    if( !isString(label) || label === '' )
                    {
                        label = analysisSamplings.unknown
                    }

                    return ucFirst(label) ;
                }
            }
        },
        loading : 'Suppression en cours...' ,
        title   : 'Supprimer le type de prélèvement ?'
    },
    title    : 'Types de prélèvement' ,
    unknown  : 'Inconnu',
    tooltips :
    {
        add    : "Ajouter" ,
        edit   : "Éditer" ,
        remove : "Supprimer"
    }
};

export default analysisSamplings ;
