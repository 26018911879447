import api   from '../../../../../../configs/api'
import Form  from '../../../../../../display/forms/FormElements'
import Thing from '../../../../../../things/Thing'

import Method from '../../../../../../net/Method'

const elements =
[
    {
        type       : Form.SEARCH_SELECTOR ,
        path       : api.courses.url ,
        clearLabel : 'Vider' ,
        closeLabel : 'Fermer' ,
        init       :
        {
            id        : 'value',
            key       : 'value',
            label     : 'Parcours',
            autoFocus : false,
            fullWidth : true,
            margin    : 'normal',
            required  : true,
            type      : 'search',
            variant   : 'outlined'
        },
        inputProps : { label : 'Parcours...' },
        logics :
        {
            disabledSuggestions : ( props ) =>
            {
                const { rel } = props ;
                if( rel instanceof Thing )
                {
                    const { subjectOf } = rel ;
                    if( subjectOf )
                    {
                        const { courses } = subjectOf ;
                        if( courses instanceof Array )
                        {
                            return courses ;
                        }
                    }
                }
                return null ;
            }
        }
    }
];

const courses =
{
    add :
    {
        elements ,
        agree       : 'Ajouter' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez sélectionner un parcours en cliquant ci-dessous.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en  cours...' ,
        method      : Method.PATCH ,
        mock        : false,
        success     : 'Ajout du nouveau parcours réussi!' ,
        title       : 'Ajouter un parcours'
    },
    remove :
    {
        agree       : 'Supprimer' ,
        description : 'Souhaitez-vous supprimer ce parcours:' ,
        disagree    : 'Annuler' ,
        loading     : 'Suppression en cours...' ,
        method      : Method.PATCH  ,
        mapUri      : ( item , uri ) => uri ,
        mock        : false ,
        title       : 'Supprimer le parcours ?'
    },
    title : 'Parcours mis en avant' ,
    tooltips :
    {
        add    : "Ajouter" ,
        edit   : "Éditer" ,
        remove : "Supprimer"
    }
};

export default courses ;
