import PropTypes from 'prop-types'

import withi18n from '../../contexts/i18n/withi18n'

import getHorizontalElements from '../getHorizontalElements'

import { ThingCell } from './ThingCell'

class DiseaseCell extends ThingCell
{   
    getContent = () => 
    {
        const { thing } = this.props;
        if ( thing ) 
        {
            const elements = [ 'level' , 'type' ] ;
            return getHorizontalElements( { elements , factory:this } ) ;
        }
        return null;
    };
    
    getLevel = () => this.getType({ member:'level' }) ;
    
    getLocale = () => this.props.locale.display.cells.disease;
}

DiseaseCell.defaultProps =
{
    ...ThingCell.defaultProps,
    level           : true ,
    subtitleVariant : 'name' ,
    typable         : false,
};

DiseaseCell.propTypes =
{
    ...ThingCell.propTypes,
    level : PropTypes.bool
};

export default withi18n(DiseaseCell) ;
