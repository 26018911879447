import React from 'react'

import GridIcon from '@material-ui/icons/Apps'
import ListIcon from '@material-ui/icons/List'

import initView from '../../display/views/initView'
import View     from '../../display/views/TabsView'

import Organization      from '../organization/Organization'
import OrganizationsList from '../../display/lists/OrganizationsList'
import OrganizationsGrid from '../../display/grids/OrganizationsGrid'

import api from '../../configs/api'

const styles = () => ({}) ;

const path = api.organizations ;

const { url:uri } = path ;

class Organizations extends View
{
    getLocale = () => this.props.locale.views.organizations ;
}

Organizations.defaultProps =
{
    ...View.defaultProps ,
    first  : 'grid' ,
    path,
    routes :
    [
        { id : 'grid'         , Component : OrganizationsGrid , uri , backPath:'/grid' } ,
        { id : 'list'         , Component : OrganizationsList , uri , backPath:'/list' } ,
        { id : 'organization' , component : Organization      , path : uri + '/:id' }
    ],
    tabs     :
    [
        { id : 'grid' , icon : <GridIcon /> } ,
        { id : 'list' , icon : <ListIcon /> }
    ]
};

Organizations.propTypes =
{
    ...View.propTypes
};

export default initView( Organizations , styles );
