import PropTypes from 'prop-types'

import sanitizeHtml from 'sanitize-html'

import isString from 'vegas-js-core/src/isString'

import copy from 'copy-to-clipboard'

import RoleableContainer from './RoleableContainer'

import api from '../../configs/api'

export class XtraContainer extends RoleableContainer
{
    componentDidUpdate = ( prevProps , prevState ) =>
    {
        const { expanded } = this.props ;
        if( prevState.expanded !== expanded )
        {
            this.setState({ expanded }) ;
        }
    };

    copy = ( text , notification = null ) =>
    {
        const { copiable } = this.props ;
        if( copiable )
        {
            if ( isString(text) )
            {
                const {
                    config     : { sanitizeAll = {} }= {} ,
                    copyFormat : format,
                    sanitize
                }
                = this.props ;

                if( sanitize )
                {
                    text = sanitizeHtml(text, sanitizeAll);
                }

                copy( text, { format }) ;

                if ( isString(notification) )
                {
                    this.notify(notification);
                }
            }
            else
            {
                console.log( this + ' copy to the clipboard failed, impossible to copy an invalid text.');
            }
        }
    };

    gotoUrl = url =>
    {
        if( isString( url ) )
        {
            const { history } = this.props ;
            if( history )
            {
                history.push( { pathname:url.split(api.url)[1] } );
            }
        }
    };

    render = () => null ;
}

XtraContainer.defaultProps =
{
    ...RoleableContainer.defaultProps,
    copiable      : true,
    copyFormat    : 'text/plain',
    disabled      : false,
    sanitize      : true
};

XtraContainer.propTypes =
{
    ...RoleableContainer.propTypes,
    copiable      : PropTypes.bool,
    copyFormat    : PropTypes.oneOf(['text/plain','text/html']),
    disabled      : PropTypes.bool,
    notifiable    : PropTypes.bool,
    sanitize      : PropTypes.bool
};

export default XtraContainer ;
