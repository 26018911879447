import React, { Component } from 'react'

import PropTypes from 'prop-types'

import withLang from './withLang'

import LocaleContext from './LocaleContext'

const Provider = LocaleContext.Provider ;

class LocaleProvider extends Component
{
    constructor( props )
    {
        super( props );

        let locale = {} ;

        const { defaultLang, i18n } = props ;

        if( defaultLang && i18n )
        {
            locale = i18n.hasOwnProperty(defaultLang) ? i18n[defaultLang] : {} ;
        }

        this.state =
        {
            locale,
            setLocale : this.setLocale
        };
    }

    componentDidUpdate( prevProps )
    {
        const { lang } = this.props ;
        if( prevProps.lang !== lang )
        {
            this.setLocale(lang);
        }
    }

    setLocale = lang =>
    {
        const { i18n } = this.props ;
        const locale = i18n.hasOwnProperty(lang) ? i18n[lang] : {} ;
        this.setState({ locale });
    };

    render()
    {
        const { children } = this.props;
        return (
            <Provider value={ this.state }>
                { children }
            </Provider>
        );
    }
}

LocaleProvider.propTypes =
{
    defaultLang : PropTypes.string.isRequired,
    i18n        : PropTypes.object.isRequired
};

LocaleProvider = withLang(LocaleProvider) ;

export default LocaleProvider ;