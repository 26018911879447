import days from './days'

export default
{
    strings  :
    {
        days,
        all      : 'Tous les jours' ,
        none     : '' ,
        specials : '{0} et {1}' ,
        week     : 'En semaine' ,
        weekend  : 'Le week-end'
    }
};