import compose  from 'vegas-js-core/src/functors/compose'

import initApp   from '../../../contexts/app/initApp'
import withSnack from '../../../contexts/snack/withSnack'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { Image } from '../medias/Image'

import api from '../../../configs/api'

import PATCH         from '../../../net/PATCH'
import RequestStatus from '../../../net/RequestStatus'

import ImageObject  from '../../../things/creativework/media/ImageObject'
import Question     from "../../../things/Question"
import QuestionGame from '../../../things/game/QuestionGame'

import generify from '../../../things/generify'

const styles = () => ({});

class AnswerImage extends Image
{
    getLocale = () => this.props.locale.things.game.questionImage;

    onRemove = this.change ;

    removePrepare = item =>
    {
        let { thing:question } = this.props ;
        if( (question instanceof Question) && (item instanceof Question) )
        {
            let { subjectOf:game } = question ;
            if( game instanceof QuestionGame )
            {
                const { id } = item ;

                game = game.toObject() ;

                let { quest } = game ;

                if( quest instanceof Array && quest.length > 0 )
                {
                    let position = quest.findIndex( element => element.id === id ) ;
                    if( position > -1 )
                    {
                        quest[position].image = null ;
                    }
                }
                quest = JSON.stringify(quest) ;
                return { quest };
            }
        }
        return null ;
    };

    save = media =>
    {
        if( media instanceof ImageObject )
        {
            let { uri, thing } = this.props ;
            if( uri && thing )
            {
                let {
                    id,
                    subjectOf:game
                }
                = thing ;

                if( game instanceof QuestionGame  )
                {
                    game = game.toObject() ;

                    let { quest } = game ;

                    if( quest instanceof Array && quest.length > 0 )
                    {
                        let find = quest.find( item => item.id === id ) ;
                        if( find )
                        {
                            find.image = generify(media, true) ;
                        }
                    }


                    quest = JSON.stringify(quest) ;

                    this.setState({ status:RequestStatus.PROGRESS });

                    this.canceler = PATCH(
                        api.url + uri ,
                        {
                            success : this._success,
                            fail    : this._fail,
                            cancel  : this._cancel,
                            datas   : { quest }
                        }
                    );
                }
            }
        }
    };

    _success = response =>
    {
        const { notifySnack, thing } = this.props ;
        if( thing && response )
        {
            const { data } = response ;
            if( data )
            {
                const { result } = data ;
                if( result )
                {
                    this.setState( { status:RequestStatus.NEW } ) ;

                    const locale = this.getLocale() ;
                    if( locale )
                    {
                        const { success } = locale ;
                        if( success && notifySnack )
                        {
                            notifySnack( success , 'success' )
                        }
                    }

                    const { onChange } = this.props ;
                    if( onChange instanceof Function )
                    {
                        onChange( result ) ;
                    }
                }
            }
        }
    };
}

AnswerImage.defaultProps =
{
    ...Image.defaultProps ,
    containerProps :
    {
        className : "bg-transparent w-full px-16",
        elevation : 0,
        square    : true
    }
};

AnswerImage.propTypes =
{
    ...Image.propTypes
};

export default compose( withStyles( styles ) , withRouter , initApp, withSnack )( AnswerImage ) ;
