import React from 'react'

import compose  from 'vegas-js-core/src/functors/compose'
import isString from 'vegas-js-core/src/isString'

import moment from 'moment'

import initApp from '../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import DayOfWeek from 'vegas-js-calendar/src/DayOfWeek'

import AccessTimeIcon from '@material-ui/icons/AccessTime'

import HourSpecification     from './hours/HourSpecification'
import HourSpecificationIcon from './hours/HourSpecificationIcon'

import ThingChildren , { styles } from './ThingChildren'

import OpeningHoursSpecificationVO from '../../things/OpeningHoursSpecification'

class OpeningHoursSpecification extends ThingChildren
{
    createChild = ( child , editable = false ) =>
    {
        return (
        <div className="flex flex-row items-center justify-start py-12">
            <HourSpecificationIcon
                avatar = {true}
                thing  = {child}
            />
            <HourSpecification
                editable = { editable }
                helper   = { new DayOfWeek( this.props.locale.things.dayOfWeek ) }
                onClick  = { () => this.openEditDialog(child) }
                thing    = { child }
            />
        </div>
        );
    };
    
    getLocale = () => this.props.locale.things.openingHoursSpecification ;

    prepare = item =>
    {
        let {
            alternateName,
            dayOfWeek,
            closes,
            description,
            opens,
            validFrom,
            validThrough
        } = item ;

        const { config : { languages = [] } = {} } = this.props ;

        if( alternateName )
        {
            languages.forEach( lang => {
                if( !isString(alternateName[lang]) )
                {
                    alternateName[lang] = '' ;
                }
            })
        }

        if( description )
        {
            languages.forEach( lang => {
                if( !isString(description[lang]) )
                {
                    description[lang] = '' ;
                }
            })
        }

        if( dayOfWeek instanceof Array )
        {
            dayOfWeek = dayOfWeek.join(',');
        }
        else
        {
            dayOfWeek = null ;
        }

        validFrom    = validFrom instanceof Date ? moment(validFrom).format( 'YYYY-MM-DD' ) : null ;
        validThrough = validThrough instanceof Date ? moment(validThrough).format( 'YYYY-MM-DD' ) : null ;

        return (
        {
            alternateName,
            closes,
            description,
            dayOfWeek,
            opens,
            validFrom,
            validThrough
        });
    };
}

OpeningHoursSpecification.defaultProps =
{
    ...ThingChildren.defaultProps ,
    clazz     : OpeningHoursSpecificationVO,
    emptyIcon : null ,
    icon      : <AccessTimeIcon/>,
    member    : 'openingHoursSpecification'
};

OpeningHoursSpecification.propTypes =
{
    ...ThingChildren.propTypes
};

export default compose( withStyles( styles ) , withRouter , initApp )( OpeningHoursSpecification ) ;