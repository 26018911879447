import React from 'react'

import PropTypes from 'prop-types'

import compose  from 'vegas-js-core/src/functors/compose'
import isString from 'vegas-js-core/src/isString'
import leading  from 'vegas-js-core/src/numbers/leading'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import initApp from '../../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { Grid, Tooltip } from '@material-ui/core'

import EmptyColorIcon from '@material-ui/icons/Texture'
import ColorIcon      from '@material-ui/icons/Image'

import LabelItem from '../../../components/items/LabelItem'

import ThingContainer from '../../containers/ThingContainer'

import AnswerVO from '../../../things/Answer'

import {
    GiPalette,
    GiLaurelsTrophy,
    GiRolledCloth,
    GiTrophy
}
from 'react-icons/gi'

import grey from "@material-ui/core/colors/grey"

const defaultItemPolicy =
{
    bgcolor     : true ,
    color       : true ,
    correct     : true ,
    description : true ,
    image       : true ,
    score       : true
};

const styles = () => ({});

const half =
{
    xl : 4,
    lg : 4,
    md : 4,
    sm : 4,
    xs : 12
};

const full =
{
    xs : 12
};

class Answer extends ThingContainer
{
    getContent = () =>
    {
        const locale = this.getLocale() ;

        const { labels } = locale ;

        let items = [] ;

        const { lang, thing } = this.props ;
        if( thing instanceof AnswerVO )
        {
            let { itemPolicy, emptyColorIcon } = this.props ;

            itemPolicy = { ...defaultItemPolicy , ...itemPolicy } ;

            let {
                bgcolor,
                color,
                correct,
                score
            }
            = thing;

            // ------- score

            if( itemPolicy.score )
            {
                items.push(
                <Grid key={'item_score'} item {...half}>
                    <LabelItem
                        icon  = { <GiTrophy size='1em' /> }
                        title = { labels.score }
                    >
                        { isNaN(score) || score < 0 ? labels.empty : leading(score) }
                    </LabelItem>
                </Grid>);
            }

            // ------- correct

            if( itemPolicy.correct )
            {
                items.push(
                    <Grid key={'item_correct'} item {...half}>
                        <LabelItem
                            icon={<GiLaurelsTrophy size='1em'/>}
                            title={labels.correct}
                        >
                            {!!(correct) ? labels.yes : labels.no}
                        </LabelItem>
                    </Grid>);
            }

            // ------- icon

            if( itemPolicy.color )
            {
                let c ;
                let cIcon ;
                if( isString(color) && (color !== '') )
                {
                    c     = "#" + color ;
                    cIcon = (<Tooltip placement='top' title={ c }>
                        <ColorIcon className='mr-8' style={{ color : c }}/>
                    </Tooltip>);
                }
                else
                {
                    c     = null ;
                    cIcon = emptyColorIcon ;
                }

                items.push(
                    <Grid key={'item_color'} item {...half}>
                        <LabelItem
                            enabled   = {!!(c)}
                            icon      = { <GiPalette size='1em'/> }
                            labelIcon = { cIcon }
                            title     = { labels.color }
                        >
                            { isString(c) ? c : labels.empty }
                        </LabelItem>
                    </Grid>) ;
            }
            // ------- bg icon

            if( itemPolicy.bgcolor )
            {
                let bgc;
                let bgcIcon;
                if (isString(bgcolor) && (bgcolor !== ''))
                {
                    bgc = "#" + bgcolor;
                    bgcIcon = (<Tooltip placement='top' title={bgc}>
                        <ColorIcon className='mr-8' style={{color: bgc}}/>
                    </Tooltip>);
                }
                else
                {
                    bgc = null;
                    bgcIcon = emptyColorIcon;
                }

                items.push(
                    <Grid key={'item_bgcolor'} item {...half}>
                        <LabelItem
                            enabled={!!(bgc)}
                            icon={<GiPalette size='1em'/>}
                            labelIcon={bgcIcon}
                            title={labels.bgcolor}
                        >
                            {isString(bgc) ? bgc : labels.empty}
                        </LabelItem>
                    </Grid>);
            }

            // ------- description

            if( itemPolicy.description )
            {
                let description = thing.getLocaleDescription(lang);
                items.push(
                    <Grid key={'item_description'} item {...full}>
                        <LabelItem
                            icon  = { <GiRolledCloth size='1em'/> }
                            title = { labels.description }
                        >
                            {isString(description) && (description !== '') ? ucFirst(description) : labels.empty}
                        </LabelItem>
                    </Grid>);
            }
        }

        return (
        <div className='w-full'>
            <Grid
                container = { true }
                spacing   = { 4 }
                className = 'w-full'
            >
                { items }
            </Grid>
        </div>);
    };

    getLocale = () => this.props.locale.things.game.answer;
}

Answer.defaultProps =
{
    ...ThingContainer.defaultProps ,
    containerProps :
    {
        className : "bg-transparent w-full shadow-none pl-20 py-12 mb-16",
        elevation : 0,
        square    : true
    },
    emptyColorIcon : <EmptyColorIcon className='mr-8' style={{ color:grey[400] }} /> ,
    itemPolicy     : null ,
    titleVisible   : false
};

Answer.propTypes =
{
    ...ThingContainer.propTypes,
    emptyColorIcon : PropTypes.element,
    itemPolicy     : PropTypes.object
};

export default compose( withStyles( styles ) , withRouter , initApp )( Answer ) ;
