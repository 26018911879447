import React from 'react'

import isNumber from 'vegas-js-core/src/isNumber'
import isString from 'vegas-js-core/src/isString'

import Form       from '../../../display/forms/FormElements'
import PriceInput from '../../../components/inputs/PriceInput'

import Offer from '../../../things/Offer'
import Thing from '../../../things/Thing'

import api from "../../../configs/api"

import Method from '../../../net/Method'

import offer from './offer'

const elements =
[
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'price',
            key          : 'price',
            label        : 'Tarif',
            autoFocus    : false,
            fullWidth    : true,
            InputProps   : { inputComponent : ( input ) => <PriceInput {...input} /> } ,
            margin       : 'normal',
            placeholder  : 'Votre tarif, ex: 15.5 €',
            required     : true,
            select       : false,
            autoComplete : 'off' ,
            type         : 'text',
            variant      : 'outlined'
        }
    },
    {
        type       : Form.SEARCH_SELECTOR ,
        path       : api.thesaurus.offers_categories ,
        clearLabel : 'Vider' ,
        closeLabel : 'Fermer' ,
        init       :
        {
            id         : 'category',
            key        : 'category',
            label      : 'Catégorie',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            required   : true,
            type       : 'search',
            variant    : 'outlined'
        },
        inputProps :
        {
            label : 'Sélectionner une catégorie...'
        }
    },
    {
        type : Form.DIVIDER,
        init : { key : 'events-divider-1' , light : true }
    },
    {
        type : Form.I18N_EDITOR ,
        init :
        {
            id    : 'alternateName',
            key   : 'alternateName',
            input : true,
            label : 'Titre'
        }
    },
    {
        type         : Form.DATE ,
        defaultValue : null ,
        format       : 'L' ,
        init         :
        {
            id            : 'validFrom',
            key           : 'validFrom',
            autoOk        : true ,
            cancelLabel   : 'Annuler' ,
            clearable     : true ,
            clearLabel    : 'Vider' ,
            invalidLabel  : '-',
            inputVariant  : 'outlined' ,
            disableFuture : false ,
            disablePast   : false ,
            fullWidth     : true ,
            label         : 'Date de début' ,
            required      : false,
            okLabel       : 'OK',
            variant       : 'dialog'
        }
    },
    {
        type         : Form.DATE ,
        defaultValue : null,
        format       : 'L' ,
        minDate      : 'validFrom' ,
        init :
        {
            id            : 'validThrough',
            key           : 'validThrough',
            autoOk        : true ,
            cancelLabel   : 'Annuler' ,
            clearable     : true ,
            clearLabel    : 'Vider' ,
            invalidLabel  : '-',
            inputVariant  : 'outlined' ,
            disableFuture : false ,
            disablePast   : false ,
            label         : 'Date de fin' ,
            fullWidth     : true ,
            okLabel       : 'OK',
            variant       : 'dialog'
        }
    },
    {
        type : Form.I18N_EDITOR ,
        init :
        {
            id    : 'description',
            key   : 'description',
            input : true,
            label : 'Notes'
        }
    }
];




const offers =
{
    add :
    {
        elements ,
        agree       : 'Créer' ,
        disagree    : 'Fermer' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour créer un tarif.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...' ,
        success     : 'Ajout du nouveau tarif réussi!' ,
        title       : 'Ajouter un tarif'
    },
    edit :
    {
        elements,
        agree       : 'Sauver' ,
        disagree    : 'Fermer' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour modifier ce tarif.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...'  ,
        mock        : false ,
        method      : Method.PUT,
        success     : 'Mise à jour réussie!' ,
        title       : 'Modifier le tarif'
    },
    remove :
    {
        agree       : 'Supprimer' ,
        description : 'Souhaitez-vous supprimer ce tarif:' ,
        disagree    : 'Fermer' ,
        item        :
        {
            text :
            {
                primary : ( thing , props ) =>
                {
                    let label = null ;
                    if( thing instanceof Offer )
                    {
                        label = thing.getLocaleAlternateName( props.lang ) ;
                        if( !isString(label) )
                        {
                            const { title } = offer ;
                            if ( isString( title ) && title !== '' )
                            {
                                label = title ;
                            }
                        }
                    }
                    return label ;
                },
                secondary : ( thing , props ) =>
                {
                    let label = null ;
                    if( thing instanceof Offer )
                    {
                        let infos = [] ;

                        let { category, price , priceCurrency } = thing ;

                        if( isNumber(price) || isString(price) )
                        {
                            price = price + '' ;
                            if( isString( priceCurrency ) )
                            {
                                price += ' ' + priceCurrency ;
                            }
                            infos.push( price ) ;
                        }

                        if( category instanceof Thing )
                        {
                            category = category.getLocaleName( props.lang ) ;
                            if( isString(category) && category.length > 0 )
                            {
                                infos.push( category ) ;
                            }
                        }

                        if( infos.length > 0 )
                        {
                            label = infos.join( ' - ' );
                        }
                    }
                    return label ;
                }
            }
        },
        loading : 'Suppression en cours...' ,
        title   : 'Supprimer le tarif ?'
    },
    offer,
    title    : 'Tarifs' ,
    tooltips :
    {
        add    : "Ajouter" ,
        edit   : "Éditer" ,
        remove : "Supprimer"
    }
};

export default offers ;
