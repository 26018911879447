
import Form from '../../../../display/forms/FormElements'

import Method from '../../../../net/Method'

import api from '../../../../configs/api'

const elements =
[
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'name',
            key          : 'name',
            label        : 'Nom',
            autoFocus    : false,
            fullWidth    : true,
            helperText   : null,
            margin       : 'normal',
            required     : false,
            select       : false,
            autoComplete : 'off' ,
            type         : 'text',
            variant      : 'outlined'
        }
    },
    {
        type  : Form.SEARCH_SELECTOR ,
        path  : api.thesaurus.badgeItems_types,
        clearLabel : 'Vider' ,
        closeLabel : 'Fermer' ,
        init  :
        {
            id         : 'additionalType',
            key        : 'additionalType',
            label      : 'Type de badge',
            autoFocus  : false,
            fullWidth  : true,
            helperText : 'Obligatoire',
            margin     : 'normal',
            required   : true,
            type       : 'search',
            variant    : 'outlined'
        }
    },
    {
        type : Form.COLOR_TEXTFIELD ,
        init :
        {
            id           : 'color',
            key          : 'color',
            label        : 'Couleur',
            autoComplete : 'off' ,
            autoFocus    : false,
            fullWidth    : true,
            margin       : 'normal',
            placeholder  : 'RRGGBB',
            required     : false,
            select       : false,
            type         : 'text',
            variant      : 'outlined'
        }
    },
    {
        type : Form.COLOR_TEXTFIELD ,
        init :
        {
            id           : 'bgcolor',
            key          : 'bgcolor',
            label        : 'Couleur de fond',
            autoComplete : 'off' ,
            autoFocus    : false,
            fullWidth    : true,
            margin       : 'normal',
            placeholder  : 'RRGGBB',
            required     : false,
            select       : false,
            type         : 'text',
            variant      : 'outlined'
        }
    },
    {
        type : Form.I18N_EDITOR ,
        init :
        {
            id    : 'alternateName',
            key   : 'alternateName',
            input : true,
            label : 'Titre'
        }
    },
    {
        type : Form.I18N_EDITOR ,
        init :
        {
            id    : 'description',
            key   : 'description',
            input : true,
            label : 'Description'
        }
    }
];

const badge =
{
    edit :
    {
        elements,
        agree       : 'Sauver' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour modifier le badge.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...' ,
        method      : Method.PATCH ,
        success     : 'Mise à jour réussie!' ,
        title       : 'Modifier le badge' ,
    },
    labels :
    {
        bgcolor  : 'Couleur de fond',
        color    : 'Couleur',
        empty    : '----' ,
        name     : 'Nom' ,
        type     : 'Type de badge'
    },
    title    : 'Badge' ,
    tooltips :
    {
        add    : 'Ajouter',
        edit   : 'Modifier',
        remove : 'Supprimer'
    }
};

export default badge ;