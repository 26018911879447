import React from 'react'

import clsx from 'clsx'

import PropTypes from 'prop-types'

import Flag from "react-flags"

import compose   from 'vegas-js-core/src/functors/compose'
import isNotNull from 'vegas-js-core/src/isNotNull'
import notEmpty  from 'vegas-js-core/src/strings/notEmpty'
import ucFirst   from 'vegas-js-core/src/strings/ucFirst'

import { Tooltip } from '@material-ui/core'

import withConfig from '../../contexts/config/withConfig'
import withi18n   from '../../contexts/i18n/withi18n'

import Container from '../../components/containers/Container'

const flagSettings =
{
    basePath : './assets/flags' ,
    format   : 'png' ,
    pngSize  : 32 ,
    width    : 26
};

class Flags extends Container
{
    getFlag = target => ( code = 'fr' , { className = null, key="flag-" } = {} ) =>
    {
        let flag ;

        if( notEmpty( code ) && notEmpty(target[code]) )
        {
            let title = ucFirst(target[code]) ;
            flag = (
                <Tooltip key={ key + code } placement='top' title={title} >
                    <div className='flex'>
                        <Flag
                            className = { clsx( 'mr-12' , className ) }
                            name      = { ( (code === 'en') ? 'gb' : code ).toUpperCase() }
                            {...flagSettings}
                        />
                    </div>
                </Tooltip>
            ) ;
        }

        return flag ;
    };

    render = () =>
    {
        const { thing } = this.props ;
        if( thing )
        {
            let i18n = thing ;

            const { member } = this.props;
            if( notEmpty( member ) )
            {
                i18n = thing[member] ;
            }

            if( i18n )
            {
                const { config : { languages } = {} } = this.props ;

                if( languages instanceof Array && languages.length > 0 )
                {
                    const flags = languages.map( lang => this.getFlag(i18n)( lang ) )
                                           .filter( isNotNull ) ;
                    if( flags.length > 0 )
                    {
                        return (
                        <div className={ clsx( 'flex flex-auto flex-row items-center' , this.props.className ) } >
                            { flags }
                        </div>);
                    }
                }
            }
        }
        return null ;
    };
}

Flags.defaultProps =
{
    ...Container.defaultProps,
    className : null ,
    member    : null ,
    thing     : null
};

Flags.propTypes =
{
    ...Container.propTypes,
    className : PropTypes.string ,
    member    : PropTypes.string ,
    thing     : PropTypes.object
};

export default compose( withConfig , withi18n )( Flags ) ;
