import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import Form from '../../../../display/forms/FormElements'

import Thing          from '../../../../things/Thing'
import Transportation from '../../../../things/Transportation'
import Word           from '../../../../things/Word'

import api       from '../../../../configs/api'

const elements =
[
    {
        type       : Form.SEARCH_SELECTOR ,
        path       : api.thesaurus.transportations ,
        clearLabel : 'Vider' ,
        closeLabel : 'Fermer' ,
        init       :
        {
            id         : 'additionalType',
            key        : 'additionalType',
            label      : 'Type de moyen de transport',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            required   : true,
            type       : 'search',
            variant    : 'outlined'
        },
        inputProps :
        {
            label : 'Sélectionner un type...'
        },
        limit  : 0,
        logics :
        {
            disabledSuggestions : ( props ) =>
            {
                const { rel } = props ;
                let suggestions = [] ;
                if( rel instanceof Thing )
                {
                    const { additionalType } = rel ;
                    if( additionalType instanceof Word )
                    {
                        suggestions.push( additionalType ) ;
                    }
                }
                return suggestions ;
            }
        }
    },
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'duration',
            key          : 'duration',
            label        : 'Durée en minutes',
            autoFocus    : false,
            fullWidth    : true,
            margin       : 'normal',
            required     : true,
            select       : false,
            autoComplete : 'off' ,
            type         : 'text' ,
            variant      : 'outlined'
        }
    }
];

const transportations =
{
    add :
    {
        agree       : 'Créer' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour ajouter un moyen de transport.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...' ,
        success     : 'Ajout du nouveau lien réussi!' ,
        title       : 'Ajouter un moyen de transport',
        elements
    },
    edit :
    {
        agree       : 'Sauver' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour modifier ce moyen.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...'  ,
        success     : 'Mise à jour réussie!' ,
        title       : 'Modifier le moyen de transport',
        elements
    },
    labels :
    {
        day       : 'jour',
        days      : 'jours',
        hour      : 'h',
        hours     : 'h',
        minute    : 'mn',
        minutes   : 'mn',
        month     : 'mois',
        months    : 'mois',
        separator : ' ',
        week      : 'semaine',
        weeks     : 'semaines',
        year      : 'an',
        years     : 'ans'
    },
    remove :
    {
        agree       : 'Supprimer' ,
        description : 'Souhaitez-vous supprimer ce moyen de transport:' ,
        disagree    : 'Annuler' ,
        item :
        {
           text :
           {
               primary : ( item ) =>
               {
                    if( item instanceof Transportation )
                    {
                        return item.duration ;
                    }
                    return null ;
               },
               secondary : ( item , props ) =>
               {
                   if( item instanceof Transportation )
                   {
                        const { additionalType} = item ;
                        if( additionalType instanceof Thing )
                        {
                            let name = additionalType.getLocaleName(props.lang) ;
                            if( name && name !== '')
                            {
                                return ucFirst(name) ;
                            }
                        }
                   }
                   return null ;
               }
           }
        },
        loading : 'Suppression en cours...' ,
        title   : 'Supprimer le transport ?'
    },
    title    : 'Moyens de déplacement' ,
    tooltips :
    {
        add    : "Ajouter" ,
        edit   : "Éditer" ,
        remove : "Supprimer"
    }
};

export default transportations ;