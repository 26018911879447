import React from 'react'

import SettingsConsumer from './SettingsConsumer'

export const withSettings = Component => React.forwardRef( ( props , ref ) =>
(
    <SettingsConsumer>
        { store => <Component {...props} {...store} ref={ref} /> }
    </SettingsConsumer>
));

export default withSettings ;