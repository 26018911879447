import React from 'react'

import format     from 'vegas-js-core/src/strings/fastformat'
import isNotEmpty from 'vegas-js-core/src/isNotEmpty'
import isString   from 'vegas-js-core/src/isString'

import TerrainIcon from '@material-ui/icons/Terrain'

import ThingView , { defaultStyle } from '../../display/views/ThingView'

import ObservationList from '../../display/lists/ObservationList'

import About from './views/About'

import Observation from '../../things/Observation'
import Thing       from '../../things/Thing'
import WorkshopVO  from '../../things/Workshop'

import api   from '../../configs/api'
import paths from '../../configs/paths'

import initView from '../../display/views/initView'

const styles = defaultStyle();

const iconDimension = { width:30 , height:30 } ;

class Workshop extends ThingView
{
    editPrepare = item =>
    {
        const {
            breeding,
            name,
            identifier,
            production,
            water
        } = item ;
        return (
        {
            name       : name ,
            breeding   : breeding   instanceof Thing ? String(breeding.id)   : null ,
            identifier : identifier instanceof Thing ? String(identifier.id) : null ,
            production : production instanceof Thing ? String(production.id) : null ,
            water      : water      instanceof Thing ? String(water.id)      : null ,
        });
    };

    getIcon = () =>
    {
        const { config, icon, lang } = this.props ;
        const { thing } = this.state ;
        if( thing )
        {
            const { breeding } = thing ;
            if( breeding )
            {
                let { image } = breeding ;
                if( isString( image ) )
                {
                    return (
                    <img
                        { ...iconDimension }
                        alt = { breeding.getLocaleName(lang) }
                        src = { image + "?w=" + config.avatar.maxWidth }
                    />) ;
                }
            }
        }
        return icon ;
    };

    getLocale = () => this.props.locale.views.workshop ;

    getRoutePath = path =>
    {
        if( isNotEmpty(path) )
        {
            const { thing } = this.state ;
            if( thing instanceof Thing )
            {
                return format( path , thing.id ) ;
            }
        }
        return null ;
    }

    getHeaderThing = () =>
    {
        const { thing } = this.state ;
        if( thing )
        {
            const { subjectOf } = thing ;
            if( subjectOf instanceof Thing )
            {
                const { organization } = subjectOf ;
                if( organization instanceof Thing )
                {
                    return organization ;
                }
            }
        }
        return null ;
    };

    getSubtitle = thing =>
    {
        if( thing instanceof Thing  )
        {
            return thing.getLocaleName( this.props.lang ) ;
        }
        return null ;
    };

    onBack = () =>
    {
        let url ;
        const { thing } = this.state ;
        if( thing )
        {
            const { subjectOf } = thing ;
            if( subjectOf instanceof Thing )
            {
                const { url:uri } = subjectOf ;
                if( isString(uri) )
                {
                    url = uri.split(api.url)[1] + '/workshops'
                }
            }
        }

        const { history } = this.props ;
        if( history && isString(url) )
        {
            history.push( url )
        }
    };
}

Workshop.defaultProps =
{
    ...ThingView.defaultProps,
    back       : true ,
    clazz      : WorkshopVO,
    icon       : <TerrainIcon/>,
    path       : api.workshops,
    preferable : false ,
    responsive : true ,
    routes     :
    [
        {
            id             : 'about' ,
            RouteComponent : About
        },
        {
            id             : 'observations' ,
            activable      : false ,
            backOnRemove   : false ,
            removeUri      : api.observations.url ,
            RouteComponent : ObservationList ,
            routeClazz     : Observation,
            routePath      : api.workshops.observations ,
            routePaths     :
            {
                addUri : api.workshops.observations
            }
        }
    ],
    tabs :
    [
        { id : 'about'        } ,
        { id : 'observations' }
    ]
};

Workshop.propTypes =
{
    ...ThingView.propTypes,
    path : paths.workshops
};

export default initView( Workshop, styles );
