import React, { Component, Fragment } from 'react'

import clsx from 'clsx'

import PropTypes from 'prop-types'

import CollapseItem from './items/CollapseItem'
import GroupItem    from './items/GroupItem'
import Item         from './items/Item'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { Divider, List } from '@material-ui/core'

const styles = () => (
{
    navigation :
    {
        '&.dense' :
        {
            '& .list-item':
            {
                paddingTop    : 0,
                paddingBottom : 0,
                height        : 32
            }
        }
    },
    '@global' :
    {
        '.popper-navigation-list':
        {
            '& .list-item' :
            {
                padding            : '8px 12px 8px 12px',
                height             : 40,
                minHeight          : 40,
                '& .list-item-text': { padding: '0 0 0 8px' }
            },
            '&.dense' :
            {
                '& .list-item':
                {
                    minHeight          : 32,
                    height             : 32,
                    '& .list-item-text': { padding: '0 0 0 8px' }
                }
            }
        }
    }
});

class Navigation extends Component
{
    createItem = ( item ) =>
    {
        const { active } = this.props ;

        const { id, type } = item ;

        let node ;

        switch( type )
        {
            case 'collapse' :
            {
                node = (
                <CollapseItem
                    active      = { active }
                    key         = { id }
                    item        = { item }
                    nestedLevel = { 0 }
                />) ;
                break ;
            }

            case 'divider' :
            {
                node = <Divider className="my-16"/>;
                break ;
            }

            case 'group' :
            {
                node = <GroupItem item={item} nestedLevel={0} active={active}/> ;
                break ;
            }

            case 'item' :
            {
                node = <Item item={item} nestedLevel={0} active={active}/> ;
                break ;
            }

            default :
            {
                node = null ;
            }
        }

        if( node )
        {
            node = <Fragment key={id}>{node}</Fragment> ;
        }

        return node ;
    };

    render()
    {
        let { active , classes, navigation } = this.props;
        if ( (navigation instanceof Array) && navigation.length > 0 )
        {
            return (
            <List className={ clsx("whitespace-no-wrap", classes.navigation , `active-${active}-list` ) } >
                { navigation.map( this.createItem ) }
            </List>) ;
        }
        return null ;
    }
}

Navigation.defaultProps =
{
    navigation : []
};

Navigation.propTypespropTypes =
{
    navigation : PropTypes.array.isRequired
};

export default withStyles( styles )( withRouter(Navigation) ) ;
