import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import Form from '../../../../display/forms/FormElements'

import Thing   from '../../../../things/Thing'
import Website from '../../../../things/Website'

import api from '../../../../configs/api'
import getExistingSuggestions from '../../../../things/getExistingSuggestions';

const item =
({
    text :
    {
        primary : element =>
        {
            if( element instanceof Website )
            {
                return element.href ;
            }
            return null ;
        },
        secondary : ( element , props ) =>
        {
            if( element instanceof Website )
            {
                const { additionalType } = element ;
                if( additionalType instanceof Thing )
                {
                    let name = additionalType.getLocaleName(props.lang) ;
                    if( name && name !== '')
                    {
                        return ucFirst(name) ;
                    }
                }
            }
            return null ;
        }
    }
});

const elements =
[
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'href',
            key          : 'href',
            label        : 'Lien',
            autoFocus    : false,
            fullWidth    : true,
            margin       : 'normal',
            required     : true,
            select       : false,
            autoComplete : 'off' ,
            type         : 'text' ,
            variant      : 'outlined'
        }
    },
    {
        type : Form.SEARCH_SELECTOR ,
        path : api.thesaurus.websites_types ,
        init :
        {
            id         : 'additionalType',
            key        : 'additionalType',
            label      : 'Type de site internet',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            required   : true,
            type       : 'search',
            variant    : 'outlined'
        },
        disabledSuggestions : getExistingSuggestions({ ref:'additionalType' , include:true }) ,
        selector : { i18n :
        {
            empty       : 'Aucun type de numéro de site internet disponible.' ,
            title       : 'Sélectionner un type',
            description : 'Veuillez sélectionner un type de site internet ci-dessous.' ,
        }}
    },
    {
        type : Form.I18N_EDITOR ,
        init :
        {
            id    : 'alternateName',
            key   : 'alternateName',
            input : true,
            label : 'Titre'
        }
    }
];

const website =
{
    add :
    {
        elements,
        agree       : 'Créer' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour ajouter un lien internet.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...' ,
        success     : 'Ajout du nouveau lien réussi!' ,
        title       : 'Ajouter un lien internet',
    },
    edit :
    {
        elements,
        agree       : 'Sauver' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour modifier ce lien.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...'  ,
        success     : 'Mise à jour réussie!' ,
        title       : 'Modifier le lien internet',
    },
    remove :
    {
        item,
        agree       : 'Supprimer' ,
        description : 'Souhaitez-vous supprimer ce lien internet:' ,
        disagree    : 'Annuler' ,
        loading     : 'Suppression en cours...' ,
        title       : 'Supprimer le lien ?'
    }
};

export default website ;
