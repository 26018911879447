import React from 'react'

import PropTypes from 'prop-types'

import compose  from 'vegas-js-core/src/functors/compose'
import notEmpty from 'vegas-js-core/src/strings/notEmpty'

import { withStyles } from '@material-ui/core/styles'

import {
    withWidth,
    IconButton,
    InputAdornment,
    TextField
} from '@material-ui/core'

import withDialogs from '../../contexts/dialogs/withDialogs'
import withLang    from '../../contexts/i18n/withLang'

import CloseIcon  from '@material-ui/icons/HighlightOff'
import SearchIcon from '@material-ui/icons/Search'

import Container from '../../components/containers/Container'

import api from '../../configs/api'

import Person from '../../things/Person'
import Thing  from '../../things/Thing'
import Word   from '../../things/Word'

import getLocaleHeadline   from '../../things/getLocaleHeadline'
import getLocaleThingLabel from '../../things/getLocaleThingLabel'

import SelectThingDialog from '../dialogs/SelectThingDialog'

const styles = theme =>
({
    content :
    {
        display       : 'flex',
        minHeight     : 200,
        paddingBottom : 0,
        paddingTop    : 0,
        width         : '100%' ,
        height        : 'auto'
    },
    icon :
    {
        //alignSelf   : 'center',
        marginRight : theme.spacing(2)
    },
    progress:
    {
        color  : theme.palette.primary.main,
        margin : theme.spacing(2)
    },
    root :
    {
        flexGrow : 1,
        width    : '100%' ,
        height   : 'auto'
    }
});

class SearchSelector extends Container
{
    constructor( props )
    {
        super( props ) ;
        this._cleared = false ;
        this._timeout = 0 ;
        this.canceler = null ;
        this.state =
        {
            ...this.state,
            value         : '' ,
            item          : null ,
            selectedItems : [],
            suggestions   : null
        };
    }

    clear = event =>
    {
        if( event )
        {
            event.stopPropagation();
            event.preventDefault();
        }
        this.setState({
            item   : null ,
            value  : ''
        } , () =>
        {
            this._change( null ) ;
        }) ;
    };

    componentDidUpdate( prevProps )
    {
        const { defaultValue } = this.props ;
        if( prevProps.defaultValue !== defaultValue )
        {
            this.setState({ value:this.getValue( defaultValue )} ) ;
        }
        if( this._cleared )
        {
            this._cleared = false ;
            this._change( null ) ;
        }
    }

    init = () =>
    {
        this.first = true ;
        const { defaultValue } = this.props ;
        if( defaultValue )
        {
            this.setState({ value:this.getValue( defaultValue )} ) ;
        }
    }

    getDisabledSuggestions = () =>
    {
        let list ;
        let { disabledSuggestions , logics } = this.props ;
        if( logics )
        {
            const { disabledSuggestions:d } = logics ;
            if( d )
            {
                disabledSuggestions = d ;
            }
        }
        if( disabledSuggestions instanceof Array )
        {
            list = disabledSuggestions ;
        }
        else if( disabledSuggestions instanceof Function )
        {
            list = disabledSuggestions.call( this , this.props , this.state ) ;
        }

        if( list instanceof Array && list.length > 0 )
        {
            return list.filter( element => element instanceof Thing ) ;
        }

        return null ;
    };

    getIcon = () =>
    {
        const { iconifiable , iconVisibility, width } = this.props ;
        if( iconifiable && iconVisibility.indexOf( width ) > -1 )
        {
            const {
                classes,
                defaultSearchIcon ,
                searchIcon
            } = this.props ;
            return <div className={classes.icon}>{ searchIcon || defaultSearchIcon }</div> ;
        }
        return null ;
    };

    open = () =>
    {
        const { addDialog } = this.props;
        if( addDialog )
        {
            let { DialogComponent } = this.props ;

            DialogComponent = DialogComponent || SelectThingDialog ;

            if( DialogComponent )
            {
                let {
                    apiUrl,
                    avatarMode,
                    clazz,
                    limit ,
                    queries,
                    searchable,
                    selectable,
                    selector,
                    sort,
                    sortable,
                    suggestionLabel,
                    suggestionRef,
                    uri
                } = this.props ;

                if( uri instanceof Function )
                {
                    uri = uri( this.props ) ;
                }

                // console.log( this + ' open' , selector ) ;

                addDialog(
                    DialogComponent ,
                    {
                        disabledSuggestions : this.getDisabledSuggestions() ,
                        onSelect            : this._select ,
                        apiUrl,
                        avatarMode,
                        clazz,
                        limit,
                        queries,
                        searchable,
                        selectable,
                        sort,
                        sortable,
                        suggestionLabel,
                        suggestionRef,
                        uri,
                        ...selector
                    }
                );
            }
        }
    };

    render()
    {
        const {
            classes,
            clearable,
            disabled,
            error,
            init,
            logics,
        } = this.props;

        const { value } = this.state ;

        let lock = false ;

        if( logics )
        {
            if( logics.clearable instanceof Function )
            {
                if( logics.clearable.bind(this)(this.props, this.state) )
                {
                    this._cleared = true ;
                    this.state = { item:null , value:'' }
                }
            }

            if( logics.disable instanceof Function )
            {
                lock = logics.disable.bind(this)( this.props , this.state ) ;
            }
        }

        let endAdornment ;
        if( clearable && notEmpty(value) )
        {
            endAdornment = (
                <InputAdornment
                    disablePointerEvents = { false }
                    position             = 'end'
                >
                    <IconButton
                      aria-label  = "clear element"
                      onClick     = { this.clear }
                      onMouseDown = { this.clear }
                      size        = 'small'
                    >
                        <CloseIcon />
                    </IconButton>
              </InputAdornment>
            );
        }

        return (
        <div className={classes.root}>
            <TextField
                { ...init }
                value        = { lock ? '' : (value || '') }
                select       = { false }
                disabled     = { lock || disabled }
                InputProps   = {{ endAdornment }}
                error        = { error }
                onChange     = { this.open }
                onClick      = { lock ? null : this.open }
                variant      = 'outlined'
            />
        </div>
        );
    }

    getValue = thing =>
    {
        const { lang, valueLabel } = this.props ;
        if( valueLabel )
        {
            if( valueLabel instanceof Function )
            {
                return valueLabel( thing , this.props ) ;
            }
            else if( valueLabel instanceof String || typeof(valueLabel) === 'string' )
            {
                return valueLabel ;
            }
        }

        if( thing )
        {
            if( thing instanceof Person )
            {
                return thing.getFullNameOrUsername( lang ) || '' ;
            }
            const { headline } = thing ;
            if( headline )
            {
                let label = getLocaleHeadline( thing , lang ) ;
                if( notEmpty(label) )
                {
                    return label ;
                }
            }
            return getLocaleThingLabel(thing, lang) || '' ;
        }

        return '' ;
    };

    _change = thing =>
    {
        const { logics , onChange } = this.props;

        if( logics )
        {
            const { change } = logics ;
            if( change instanceof Function )
            {
                change.bind(this)( this.props , this.state ) ;
            }
        }

        if( onChange )
        {
            onChange( { target : { value : thing } } ) ;
        }
    };

    _select = thing =>
    {
        if( thing )
        {
            this.setState({
                value : this.getValue( thing ) ,
                item  : thing ,
            },
            () =>
            {
                this._change( thing ) ;
            }) ;
        }
    };

}

SearchSelector.defaultProps =
{
    apiUrl              : api.url,
    avatarMode          : 'auto',
    clazz               : Word ,
    clearable           : true ,
    delay               : 500 ,
    defaultSearchIcon   : <SearchIcon /> ,
    defaultValue        : null ,
    DialogComponent     : SelectThingDialog,
    disabled            : false ,
    disabledSuggestions : null ,
    dropSuggestions     : false ,
    error               : false ,
    keepMounted         : true ,
    iconifiable         : false ,
    iconVisibility      : [ 'md' , 'lg' , 'xl' ],
    init                : null ,
    limit               : 20 ,
    logics              : null ,
    onCancel            : null ,
    onChange            : null ,
    queries             : null ,
    rel                 : null , // optional relating object
    searchable          : true ,
    searchIcon          : null ,
    selectable          : false ,
    selector            : null,
    sort                : null ,
    sortable            : true ,
    suggestionLabel     : null ,
    suggestionRef       : null ,
    title               : null ,
    uri                 : null ,
    valueLabel          : null
};

const breakpoints = [ 'xs' , 'sm' , 'md' , 'lg' , 'xl' ] ;

SearchSelector.propTypes =
{
    apiUrl              : PropTypes.string.isRequired,
    avatarMode          : PropTypes.oneOf(['auto', 'icon', 'cover']),
    classes             : PropTypes.object.isRequired,
    clazz               : PropTypes.func ,
    clearable           : PropTypes.bool ,
    clearLabel          : PropTypes.string ,
    delay               : PropTypes.number ,
    defaultSearchIcon   : PropTypes.element ,
    defaultValue        : PropTypes.object ,
    DialogComponent     : PropTypes.elementType,
    disabled            : PropTypes.bool ,
    disabledSuggestions : PropTypes.oneOfType([ PropTypes.array , PropTypes.func ]) ,
    dropSuggestions     : PropTypes.bool ,
    error               : PropTypes.bool ,
    fullScreen          : PropTypes.bool ,
    iconVisibility      : PropTypes.arrayOf(( propValue , key) => breakpoints.indexOf(propValue[key]) > -1 ),
    init                : PropTypes.object ,
    limit               : PropTypes.number ,
    logics              : PropTypes.object ,
    onCancel            : PropTypes.func ,
    onChange            : PropTypes.func ,
    queries             : PropTypes.object ,
    rel                 : PropTypes.object ,
    searchable          : PropTypes.bool,
    searchIcon          : PropTypes.element ,
    selectable          : PropTypes.bool,
    selector            : PropTypes.object,
    sort                : PropTypes.func,
    sortable            : PropTypes.bool,
    suggestionLabel     : PropTypes.oneOfType([PropTypes.string,PropTypes.func]) ,
    suggestionRef       : PropTypes.func , // SPECIAL TO OVERRIDE THE LABEL and the ICON of the Suggestion elements with a custom object (example the authority property of a veterinarian occurence)
    title               : PropTypes.string,
    uri                 : PropTypes.oneOfType([PropTypes.string,PropTypes.func]) ,
    valueLabel          : PropTypes.oneOfType([PropTypes.string,PropTypes.func])
};

export default compose(
    withDialogs,
    withWidth(),
    withStyles( styles ),
    withLang
)
( SearchSelector ) ;
