import Livestock    from './Livestock'
import Organization from './Organization'
import Person       from './Person'
import Thing        from './Thing'
import Word         from './Word'

import generify from './generify'

/**
 * Defines a veterinarian organization or person.
 * @memberOf things
 */
class Veterinarian extends Thing
{
    /**
     * Creates a new Veterinarian instance.
     * @constructor
     * @param object The generic object to initialize the object the first time.
     */
    constructor( object = null )
    {
        super() ;
    
        /**
         * The authority of this veterinarian.
         * @type {Person|Organization}
         */
        this.authority = null ;
    
        /**
         * The ordinal identifier of this veterinarian.
         * @type {string|number}
         */
        this.identifier = null ;

        /**
         * The livestocks of this veterinarian.
         * @type {Livestock|Array}
         */
        this.livestocks = null ;

        /**
         * The enumeration of medical specialties of this veterinarian.
         * @type {Word|Array}
         */
        this.medicalSpecialties = null ;

        this.set(object) ;
    }

    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        super.clear() ;
        this.authority          =
        this.identifier         =
        this.livestocks         =
        this.medicalSpecialties = null ;
        return this ;
    }

    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new Veterinarian( this.toObject() ) ;
    }

    /**
     * Populate the object with specific behaviors or types. Launched by default in the constructor and in the set methods.
     * @returns {Thing} The current reference.
     */
    populate()
    {
        const {
            authority,
            livestocks,
            medicalSpecialties
        } = this ;

        if( authority && !((authority instanceof Person) || (authority instanceof Organization)) )
        {
            const { id, url } = authority ;
            if( url.indexOf('people/'+id) > -1 )
            {
                this.authority = new Person( authority ) ;
            }
            else if( url.indexOf('organizations/'+id) > -1 )
            {
                this.authority = new Organization( authority ) ;
            }
        }

        if( livestocks )
        {
            if( livestocks instanceof Array )
            {
                this.livestocks = livestocks.map( item => item instanceof Livestock ? item : new Livestock(item) ) ;
            }
            else
            {
                this.livestocks = new Livestock( livestocks ) ;
            }
        }

        if( medicalSpecialties )
        {
            if( medicalSpecialties instanceof Array )
            {
                this.medicalSpecialties = medicalSpecialties.map( item => item instanceof Word ? item : new Word(item) ) ;
            }
            else
            {
                this.medicalSpecialties = new Word( medicalSpecialties ) ;
            }
        }

        return this ;
    }

    /**
     * Returns the generic object representation of the item.
     * @return {Object} The generic object representation of the item.
     */
    toObject()
    {
        let {
            authority,
            identifier,
            livestocks,
            medicalSpecialties
        } = this ;

        authority          = generify(authority) ;
        livestocks         = generify(livestocks) ;
        medicalSpecialties = generify(medicalSpecialties) ;

        return {
            ...super.toObject() ,
            authority,
            identifier,
            livestocks,
            medicalSpecialties
        }
    }
}

export default Veterinarian ;