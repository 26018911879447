import React from 'react'

import PropTypes from 'prop-types'

import clsx from 'clsx'

import compose  from 'vegas-js-core/src/functors/compose'
import isString from 'vegas-js-core/src/isString'
import notEmpty from 'vegas-js-core/src/strings/notEmpty'

// https://nel-co.github.io/react-preloading-component-demo
import { Grow } from 'react-preloading-component'

import { Link , withRouter } from 'react-router-dom'

import withConfig from '../../contexts/config/withConfig'
import withLocale from '../../contexts/i18n/withLocale'
import withSnack  from '../../contexts/snack/withSnack'
import withUser   from '../../contexts/user/withUser'

import { withStyles } from '@material-ui/core/styles'

import Animate from '../../transitions/Animate'

import { Avatar, CircularProgress, IconButton, Tooltip, Typography } from '@material-ui/core'

import grey from '@material-ui/core/colors/grey'
import red  from '@material-ui/core/colors/red'

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ErrorIcon       from '@material-ui/icons/PriorityHigh'
import FavoriteIcon    from '@material-ui/icons/Favorite'
import NoFavoriteIcon  from '@material-ui/icons/FavoriteBorder'

import Container from '../../components/containers/Container'

import RequestStatus from '../../net/RequestStatus'

import Person from '../../things/Person'
import Thing  from '../../things/Thing'

import getLocaleThingLabel from '../../things/getLocaleThingLabel'

import variants from '../../styles/typography/variants'

import Picture  from '../things/Picture'

const styles = theme =>
({
    avatar :
    {
        color           : grey[800],
        backgroundColor : 'white',
        boxShadow       : '0 15px 30px 0 rgba(0,0,0,0.11), 0 5px 15px 0 rgba(0,0,0,0.08)' ,
        marginLeft      : theme.spacing(1),
        marginRight     : theme.spacing(1),
        width           : 46,
        height          : 46
    },
    backIcon :
    {
        color : 'white'
    },
    error :
    {
        backgroundColor : grey[50],
        color           : red[700],
        marginLeft      : theme.spacing(1) ,
        marginRight     : theme.spacing(1) ,
        padding         : '16px',
        width           : 52,
        height          : 52
    },
    favoriteButton :
    {
        marginLeft  : '8px',
        marginRight : '8px'
    },
    favoriteIcon :
    {
        color : 'white'
    },
    favoritePreloader :
    {
        width       : 32,
        marginLeft  : '8px',
        marginRight : '8px'
    },
    progress :
    {
        color       : 'white' ,
        marginLeft  : theme.spacing(1) ,
        marginRight : theme.spacing(1)
    },
    subtitle :
    {
        color      : 'white',
        textShadow : '1px 2px 3px rgba(0,0,0, 0.5)'
    },
    title :
    {
        color      : 'white',
        textShadow : '1px 2px 3px rgba(0,0,0, 0.5)'
    }
});

class SimpleViewHeader extends Container
{
    getBackButton()
    {
        const {
            back,
            backTo,
            backTooltip,
            classes,
            onBack
        } = this.props;

        let options ;

        if( backTo )
        {
            options = { component:Link , to:backTo } ;
        }

        let button ;
        if( back )
        {
            button = (
                <IconButton
                    className = "mr-4"
                    size      = 'small'
                    onClick   = { onBack }
                    {...options}
                >
                     <ChevronLeftIcon className={ classes.backIcon } fontSize="small"/>
                </IconButton>
            );

            if( backTooltip )
            {
                button = (
                    <Tooltip placement='right' title={backTooltip}>
                        {button}
                    </Tooltip>
                );
            }
        }

        return button ;
    }

    getLocale = () => this.props.locale.display.headers.simpleView ;

    getFavoriteButton = () =>
    {
        let {
            isFavorite,
            preferable ,
            preferableThing ,
            thing
        } = this.props ;

        thing = preferableThing || thing ;

        if( preferable && thing && isFavorite instanceof Function )
        {
            const flag = isFavorite( thing ) ;

            const {
                classes,
                disabled,
                favoriteStatus,
                FavoriteIconComponent,
                NoFavoriteIconComponent,
                toggleFavorite
            } = this.props ;

            if( favoriteStatus === RequestStatus.PROGRESS )
            {
                const { favoritePreloaderProps } = this.props ;
                return (
                    <div className = { classes.favoritePreloader } >
                        <Grow
                            color = '#ffffff'
                            size  = { 16 }
                            { ...favoritePreloaderProps }
                        />
                    </div>
                );
            }
            else
            {
                const IconComponent = flag ? FavoriteIconComponent : NoFavoriteIconComponent;

                let button = (
                    <IconButton
                        className = { classes.favoriteButton }
                        disabled  = { disabled }
                        onClick   = { () =>
                        {
                            if( toggleFavorite instanceof Function )
                            {
                                toggleFavorite( thing , this.onToggleFavorite ) ;
                            }
                        } }
                        size = 'small'
                    >
                         <IconComponent className={ classes.favoriteIcon } fontSize="small"/>
                    </IconButton>
                 );

                if( !disabled )
                {
                    const { tooltips } = this.getLocale() || {} ;
                    if( tooltips )
                    {
                        const { favorite , noFavorite } = tooltips ;

                        let tip = flag ? noFavorite : favorite ;

                        if( notEmpty( tip ) )
                        {
                            button = (
                                <Tooltip placement='top' title={ tip }>
                                    { button }
                                </Tooltip>
                            )
                        }
                    }
                }

                return button ;
            }
        }
        return null ;
    };

    getSubtitle()
    {
        let { classes, subtitle, subtitleVariant } = this.props;
        if( notEmpty(subtitle) )
        {
            return (
            <Typography
                key       = 'subtitle'
                className = { classes.subtitle }
                variant   = { subtitleVariant }
            >
                {subtitle}
            </Typography>
            ) ;
        }
        return null ;
    }

    getTitle()
    {
        let {
            classes,
            lang,
            title,
            titleAuto,
            titleVariant,
            thing
        } = this.props;

        if( titleAuto )
        {
            if( thing instanceof Person )
            {
                title = thing.getFullNameOrUsername(lang) ;
            }
            else if( thing instanceof Thing )
            {
                title = getLocaleThingLabel(thing , lang) ;
            }
        }

        if( notEmpty( title ) )
        {
            return (
            <Typography
                className = { classes.title }
                key       = 'title'
                variant   = { titleVariant }
            >
                {title}
            </Typography>) ;
        }
        else
        {
            return null ;
        }
    }

    getIcon()
    {
        const {
            classes ,
            defaultImage ,
            error ,
            icon ,
            image ,
            lang,
            thing ,
            progress
        } = this.props;

        if( error )
        {
            return <Avatar variant="rounded" className={classes.error}><ErrorIcon /></Avatar> ;
        }
        else if( progress )
        {
            return <CircularProgress className={classes.progress} size={43} thickness={6} /> ;
        }
        else if( thing )
        {
            if( thing instanceof Thing )
            {
                const { image : thingImage , logo } = thing ;

                let media ;
                if( thingImage )
                {
                    media = thingImage ;
                }
                else if( logo )
                {
                    media = logo ;
                }

                if( media instanceof Object )
                {
                    return (
                        <Avatar
                            variant   = "rounded"
                            className = { classes.avatar }
                        >
                            <Picture
                                media   = { media }
                                variant = { 'avatar' }
                            />
                        </Avatar>
                    );
                }
                else if( isString( media ) )
                {
                    return (
                        <Avatar
                            className = { classes.avatar }
                            variant   = "rounded"
                        >
                            <img
                                alt       = { thing.getLocaleName(lang) }
                                className = { classes.image }
                                style     = {{ width:'100%',height:'100%' ,objectFit:'cover' }}
                                //loading   = 'lazy'
                                src       = { media }
                            />
                        </Avatar>
                    );
                }
            }
            else
            {
                return <Avatar variant="rounded" className={classes.avatar} src={ defaultImage } /> ;
            }
        }

        if( image )
        {
            return <Avatar variant="rounded" className={classes.avatar} src={ image } /> ;
        }
        else if( icon )
        {
            return <Avatar variant="rounded" className={classes.avatar}>{icon}</Avatar> ;
        }

        return null ;
    }

    onToggleFavorite = ( { status } = {} ) =>
    {
        switch( status )
        {
            case 'add' :
            case 'remove' :
            {
                const { snacks : { favorite : { [ status ]:message } = {} } = {} } = this.getLocale() ;
                if( notEmpty(message) )
                {
                    this.notify( message ) ;
                }
                break ;
            }

            default :
            {
                //
            }
        }
    }

    render()
    {
        const {
            className,
            error,
            progress,
            style
        } = this.props ;

        let favorite = this.getFavoriteButton() ;
        let subtitle = this.getSubtitle() ;
        let title    = this.getTitle() ;

        let content ;

        if( subtitle || title )
        {
            content = (
            <div className="flex flex-col ml-8 h-full" >
                { title  }
                { subtitle }
            </div>
            );
        }

        content = (
        <div
            className = { clsx( 'flex flex-row flex-1 items-center p-12 justify-between' , className ) }
            style     = { style }
        >
            <div className='flex flex-1 flex-row items-center' >
                { this.getBackButton() }
                { this.getIcon()       }
                { content }
            </div>
            { favorite }
        </div>
        );

        if( !error && !progress )
        {
            content = (
                <Animate animation="transition.expandIn" delay={300}>
                    {content}
                </Animate>
            )
        }

        return content ;
    }
}

SimpleViewHeader.defaultProps =
{
    ...Container.defaultProps,
    back                    : false ,
    backTo                  : null ,
    backTooltip             : null ,
    className               : null ,
    defaultImage            : null ,
    defaultTitle            : null ,
    disabled                : false ,
    FavoriteIconComponent   : FavoriteIcon ,
    error                   : false ,
    icon                    : null ,
    lang                    : null,
    image                   : null ,
    onBack                  : null ,
    NoFavoriteIconComponent : NoFavoriteIcon ,
    preferable              : true ,
    preferableThing         : null ,
    progress                : false ,
    style                   : null ,
    subtitle                : null ,
    subtitleVariant         : 'body2',
    title                   : null ,
    titleAuto               : true ,
    titleVariant            : 'body1',
    thing                   : null
};

SimpleViewHeader.propTypes =
{
    ...Container.propTypes,
    back                    : PropTypes.bool,
    backTo                  : PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]) ,
    backTooltip             : PropTypes.string ,
    className               : PropTypes.string ,
    defaultImage            : PropTypes.string ,
    defaultTitle            : PropTypes.string ,
    error                   : PropTypes.bool ,
    classes                 : PropTypes.object.isRequired ,
    disabled                : PropTypes.bool ,
    FavoriteIconComponent   : PropTypes.elementType ,
    favoritePreloaderProps  : PropTypes.object,
    icon                    : PropTypes.element ,
    image                   : PropTypes.string ,
    lang                    : PropTypes.string ,
    NoFavoriteIconComponent : PropTypes.elementType ,
    onBack                  : PropTypes.func ,
    preferable              : PropTypes.bool ,
    preferableThing         : PropTypes.object ,
    progress                : PropTypes.bool ,
    style                   : PropTypes.object ,
    subtitle                : PropTypes.node ,
    subtitleVariant         : PropTypes.oneOf(variants) ,
    title                   : PropTypes.node ,
    titleAuto               : PropTypes.bool ,
    titleVariant            : PropTypes.oneOf(variants) ,
    thing                   : PropTypes.object
};

export default compose(
    withStyles( styles ),
    withRouter,
    withConfig,
    withLocale,
    withSnack,
    withUser
)
( SimpleViewHeader ) ;
