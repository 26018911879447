const home =
{
    title       : 'Bienvenue sur votre plateforme.' ,
    description : 'Administrer vos données et vos contenus dans cet espace sécurisé.' ,
    helmet      :
    {
        title : 'Bienvenue sur votre plateforme' ,
        metas :
        [
            { charSet : 'utf-8'} ,
            { name    : 'description' ,  content : process.env.REACT_APP_DESCRIPTION } ,
            { name    : 'keywords'    ,  content : process.env.REACT_APP_KEYWORDS    }
        ]
    },
    version : 'Version {0}'
};

export default home  ;