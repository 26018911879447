const abortionEvent =
{
    datePattern : 'L',
    labels :
    {
        date       : 'Date de l\'événement' ,
        empty      : '----',
        identifier : 'Identifiant de l\'animal',
        period     : 'Période de gestation'
    }
};

export default abortionEvent ;