import React from 'react'

import { MenuItem } from '@material-ui/core'

import Selector from '../../components/selectors/Selector'

import withLang from '../../contexts/i18n/withLang'

import Word from '../../things/Word'

/**
 * Creates a thesaurus selector component who load some elements in a specific theasurus and display all words in the select list.
 */
class ThesaurusSelector extends Selector
{
    getChildren( items )
    {
        if( items instanceof Array && items.length > 0 )
        {
            const { lang } = this.props ;
            return items.map( item => {
                const { id } = item ;
                const key = 'select-item-' + id ;
                return this.getSelectItem( key , id , item.getLocaleName(lang) )
            } ) ;
        }
        return null ;
    }

    getSelectItem( key , value , label = null )
    {
        return <MenuItem key={key} className="capitalize" value={value}>{ label || value }</MenuItem> ;
    }

    populate( datas )
    {
        return (datas instanceof Array) ? datas.map( item => new Word(item) ) : datas ;
    }
}

ThesaurusSelector.defaultProps =
{
    ...Selector.defaultProps
};

ThesaurusSelector.propTypes =
{
    ...Selector.propTypes
};

export default withLang( ThesaurusSelector ) ;
