import React from 'react'

import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'

import { Button, CircularProgress, Grid } from '@material-ui/core'

import RefreshIcon from '@material-ui/icons/Refresh';

import withLang from '../../contexts/i18n/withLang'

import api     from '../../configs/api'
import LIST from '../../net/LIST'

import Container     from '../../components/containers/Container'
import RequestStatus from '../../net/RequestStatus'

import Suggestion from '../../display/things/Suggestion'

import createByUrl from '../../vo/createByUrl'

import Thing from '../../things/Thing'
import Word  from '../../things/Word'

const styles = theme =>
({
    button :
    {
        margin: theme.spacing(1),
    },
    leftIcon :
    {
        marginRight : theme.spacing(1)
    },
    progress:
    {
        color  : theme.palette.primary.main ,
        margin : theme.spacing(2)
    },
    root:
    {
        display       : 'flex',
        minHeight     : 200,
        paddingBottom : 12,
        paddingTop    : 12,
        width         : '100%' ,
        height        : 'auto'
    }
});

const half =
{
    md :  6 ,
    sm :  6 ,
    xs : 12
};

class ThesaurusChecker extends Container
{
    constructor(props)
    {
        super(props);

        this.canceler = null ;
    }

    state =
    {
        datas         : null,
        status        : RequestStatus.NEW ,
        selectedItems : []
    };

    componentDidMount()
    {
        this.load() ;
    }

    getItem = ( item , index ) =>
    {
        if( item instanceof Thing )
        {
            const { disabled, selectable } = this.props ;

            const { selectedItems } = this.state ;

            let find = false  ;

            if( selectable )
            {
                if( (selectedItems instanceof Array) )
                {
                    find = selectedItems.findIndex( element => element === item ) > -1 ;
                }
            }

            return (
            <Grid key={'item_'+index} item {...half}>
                <Suggestion
                    checkable  = { selectable }
                    checked    = { find }
                    disabled   = { disabled }
                    highlight  = { false }
                    onSelect   = { this._select }
                    thing      = { item }
                />
            </Grid>
            );
        }
        return null ;
    };

    load = () =>
    {
        const { status } = this.state ;
        if( status !== RequestStatus.PROGRESS )
        {
            const { apiUrl , limit, uri } = this.props ;

            this.setState({
                datas         : null,
                selectedItems : [],
                status        : RequestStatus.PROGRESS
            }) ;

            const url = uri instanceof Function ? uri(this.props) : uri ;

            this.canceler = LIST( apiUrl + url ,
            {
                limit ,
                cancel  : this._cancel,
                fail    : this._fail ,
                queries : { 'active' : true } ,
                success : this._success
            }) ;
        }
    };

    populate = ( datas ) =>
    {
        const { clazz, locale } = this.props ;

        if( locale )
        {
            const { populate } = locale ;
            if( populate instanceof Function )
            {
                return populate( datas ) ;
            }
        }

        return datas instanceof Array
             ? datas.map( item => createByUrl(item, clazz) )
             : datas ;
    };

    render = () =>
    {
        const { classes, locale } = this.props ;

        let { status } = this.state ;

        let content ;
        switch( status )
        {
            case RequestStatus.PROGRESS :
            {
                content = (
                    <div className="flex flex-1 items-center justify-center">
                        <CircularProgress className={classes.progress}/>
                    </div>
                );
                break ;
            }

            case RequestStatus.SUCCESS :
            {
                let { datas } = this.state ;
                if( datas instanceof Array && datas.length > 0 )
                {
                    let { sort, sortable } = this.props ;

                    if( sortable )
                    {
                        if( !sort )
                        {
                            sort = this.sort ;
                        }
                        datas = sort( datas , this.props ) ;
                    }

                    content = (
                        <Grid container spacing={0}>
                            { datas.map( this.getItem )}
                        </Grid>
                    );
                }
                break ;
            }

            case RequestStatus.FAIL :
            default :
            {
                const { refresh } = locale ;
                content = (
                    <div className="flex flex-1 items-center justify-center">
                        <Button
                            className = { classes.button }
                            color     = "primary"
                            onClick   = { this.load }
                            variant   = "contained"
                        >
                            <RefreshIcon className={classes.leftIcon}/>
                            { refresh }
                        </Button>
                    </div>
                );
                break ;
            }
        }

        return (
        <div className={classes.root}>
            { content }
        </div>
        );
    };

    selectAll = ( items ) =>
    {
        const { selectable } = this.props ;
        if( selectable )
        {
            items = [ ...items ] ;
            const { onChange } = this.props ;
            if( onChange )
            {
                onChange( items ) ;
            }
            this.setState({ selectedItems:items });
        }
    };

    selectItem = ( item , key = null , update = false ) =>
    {
        const { selectable } = this.props ;
        if( selectable )
        {
            let { selectedItems } = this.state;

            if ( !(selectedItems instanceof Array) )
            {
                selectedItems = [] ;
            }

            let index = -1;

            if (key === null)
            {
                index = selectedItems.indexOf(item);
            }
            else
            {
                index = selectedItems.findIndex(element => (item[key] === element[key]));
            }

            if( index === -1 )
            {
                selectedItems.push(item)
            }

            if( update )
            {
                this.setState({ selectedItems }) ;
            }

            const { onChange } = this.props ;
            if( onChange )
            {
                onChange( selectedItems ) ;
            }
        }
    };

    sort = ( elements ) =>
    {
        const { lang } = this.props ;
        if( elements instanceof Array && lang )
        {
            elements = [ ...elements ] ;
            elements.sort( ( a , b ) =>
            {
                a = a.getLocaleName( lang ) ;
                b = b.getLocaleName( lang ) ;
                return a.localeCompare(b) ;
            })
        }
        return elements ;
    };

    toggleSelected = ( item , key = null ) =>
    {
        const { selectable } = this.props ;
        if( selectable )
        {
            let { selectedItems } = this.state;

            if ( !(selectedItems instanceof Array) )
            {
                selectedItems = [] ;
            }

            let index = -1;

            if (key === null)
            {
                index = selectedItems.indexOf(item);
            }
            else
            {
                index = selectedItems.findIndex(element => (item[key] === element[key]));
            }

            if (index > -1)
            {
                selectedItems.splice(index, 1);
            }
            else
            {
                selectedItems.push(item)
            }

            const { onChange } = this.props ;
            if( onChange )
            {
                onChange( selectedItems ) ;
            }

            this.setState( { selectedItems } ) ;
        }
    };

    unmount = () =>
    {
        const { status } = this.state ;
        if( status === RequestStatus.PROGRESS && !!(this.canceler) )
        {
            this.canceler.cancel(this + ' cancel') ;
        }
    };

    unselectAll = () =>
    {
        const { selectable } = this.props ;
        if( selectable )
        {
            const items = []  ;
            this.setState( { selectedItems:items } ) ;
            const { onChange } = this.props ;
            if( onChange )
            {
                onChange( items ) ;
            }
        }
    };

    // -------

    _cancel = () =>
    {
        this.setState( { errors:null , status:RequestStatus.NEW , datas:null } ) ;
        const { onCancel } = this.props ;
        if( onCancel instanceof Function )
        {
            onCancel() ;
        }
    };

    _fail = () =>
    {
        this.setState( { status:RequestStatus.FAIL } ) ;
    };

    _select = ( item ) =>
    {
        if( item )
        {
            const { selectable } = this.props ;
            if( selectable )
            {
                this.toggleSelected( item );
            }
            else
            {
                const { onChange } = this.props ;
                if( onChange )
                {
                    onChange( item ) ;
                }
            }
        }
    };

    _success = response =>
    {
        if( response )
        {
            const { data } = response ;
            if( data )
            {
                const { result } = data ;
                if( result )
                {
                    const datas = this.populate(result) ;

                    let selectedItems = [] ;

                    let { defaultValue } = this.props ;

                    if( (datas instanceof Array) && (datas.length > 0) && (defaultValue instanceof Array) )
                    {
                        defaultValue = defaultValue.map( item => item.id ) ;

                        datas.forEach( item =>
                        {
                            if( item instanceof Thing )
                            {
                                const { id } = item ;
                                if( defaultValue.indexOf(id) > -1 )
                                {
                                    selectedItems.push( item );
                                }
                            }
                        });

                        // console.log( this + ' _success' , defaultValue , selectedItems ) ;
                    }

                    if( selectedItems.length > 0 )
                    {
                        const { onChange } = this.props ;
                        if( onChange instanceof Function )
                        {
                            onChange( selectedItems );
                        }
                    }

                    this.setState({ datas, selectedItems, status:RequestStatus.SUCCESS });
                    return ;
                }
            }
        }
        this.setState( { status:RequestStatus.FAIL } ) ;
    };
}

ThesaurusChecker.defaultProps =
{
    apiUrl       : api.url,
    clazz        : Word ,
    defaultValue : null ,
    disabled     : false,
    limit        : 0,
    locale       : null ,
    onCancel     : null ,
    onChange     : null ,
    searchUri    : null,
    selectable   : true ,
    sort         : null ,
    sortable     : true ,
    uri          : null
};

ThesaurusChecker.propTypes =
{
    apiUrl       : PropTypes.string.isRequired,
    classes      : PropTypes.object.isRequired,
    clazz        : PropTypes.func ,
    defaultValue : PropTypes.array ,
    disabled     : PropTypes.bool,
    limit        : PropTypes.number,
    locale       : PropTypes.object,
    onCancel     : PropTypes.func,
    onChange     : PropTypes.func ,
    searchUri    : PropTypes.string,
    selectable   : PropTypes.bool,
    sort         : PropTypes.func,
    sortable     : PropTypes.bool,
    uri          : PropTypes.oneOfType([PropTypes.string,PropTypes.func])
};

export default withStyles( styles )( withLang(ThesaurusChecker) ) ;
