import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import compose  from 'vegas-js-core/src/functors/compose'
import isString from 'vegas-js-core/src/isString'
import trim     from 'vegas-js-core/src/strings/trim'

import withConfig from '../../contexts/config/withConfig'
import withLang   from '../../contexts/i18n/withLang'

import { withRouter } from "react-router-dom"
import { withStyles } from '@material-ui/core/styles'

import {
    CircularProgress,
    Divider,
    Fab,
    Paper,
    Tooltip,
    Typography,
    Zoom
} from '@material-ui/core'

import sanitizeHtml from 'sanitize-html'

import TitleIcon   from '@material-ui/icons/Title'
import RefreshIcon from '@material-ui/icons/Refresh'
import SaveIcon    from '@material-ui/icons/Save'

import ToggleButton      from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'

import blueGrey from '@material-ui/core/colors/blueGrey'
import grey     from '@material-ui/core/colors/grey'
import red      from '@material-ui/core/colors/red'

import { convertToRaw, ContentState, EditorState } from 'draft-js'

import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'

import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import RoleableContainer from '../containers/RoleableContainer'

import editors from '../../locale/fr/components/editors'

import PATCH         from '../../net/PATCH'
import RequestStatus from '../../net/RequestStatus'

import api from '../../configs/api'

const styles = theme => (
{
    editor :
    {
        backgroundColor : grey[100],
        borderWidth     : 0.5,
        borderColor     : grey[300],
        padding         : 8,
        minHeight       : 240 ,
        marginTop       : 8
    },
    editorChanged :
    {
        backgroundColor : grey[100],
        borderWidth     : 0.5,
        borderColor     : blueGrey[400],
        padding         : 8,
        minHeight       : 240 ,
        marginTop       : 8
    },
    editorFail :
    {
        backgroundColor : grey[100],
        borderWidth     : 0.5,
        borderColor     : red[400],
        padding         : 8,
        minHeight       : 240 ,
        marginTop       : 8
    },
    input :
    {
        backgroundColor : grey[100],
        borderWidth     : 0.5,
        borderColor     : grey[300],
        display         : 'inline-block',
        paddingLeft     : 8,
        paddingRight    : 8,
        minHeight       : 'auto' ,
        width           : '100%' ,
        marginTop       : 8 ,
        whiteSpace      : 'nowrap'
    },
    inputChanged :
    {
        backgroundColor : grey[100],
        borderWidth     : 0.5,
        borderColor     : blueGrey[400],
        display         : 'inline-block',
        paddingLeft     : 8,
        paddingRight    : 8,
        minHeight       : 'auto' ,
        width           : '100%' ,
        marginTop       : 8 ,
        whiteSpace      : 'nowrap'
    },
    inputFail :
    {
        backgroundColor : grey[100],
        borderWidth     : 0.5,
        borderColor     : red[400],
        display         : 'inline-block',
        paddingLeft     : 8,
        paddingRight    : 8,
        minHeight       : 'auto' ,
        width           : '100%' ,
        marginTop       : 8 ,
        whiteSpace      : 'nowrap'
    },
    option :
    {
        marginLeft : 12
    },
    wrapper :
    {
        marginTop : 8
    },
    root:
    {
        flexGrow     : 1,
        marginBottom : 8 ,
        marginTop    : 20 ,
        width        : '100%' ,
        height       : 'auto'
    },
    tab :
    {
        alignItems     : 'center',
        border         : 0,
        justifyContent : 'flex-start',
        marginRight    : 10
    },
    tabs :
    {
        alignItems     : 'center',
        background     : theme.palette.background.default,
        boxShadow      : 'none',
        display        : 'flex',
        height         : 56 ,
        justifyContent : 'flex-start',
        // margin         : `${theme.spacing(1)}px 0`,
        // padding        : `${theme.spacing(1)}px ${theme.spacing(2)}px`
    }
});

class MultiLangEditor extends RoleableContainer
{
    constructor( props )
    {
        super( props ) ;
        this.canceler = null ;
        this.change   = false ;
        this.editor   = null ;
        this.interval = 0 ;
    }

    state =
    {
        editorState : EditorState.createEmpty() ,
        errors      : null ,
        currentLang : 'fr' ,
        item        : {} ,
        status      : RequestStatus.NEW ,
        text        : {}
    };

    focus = () =>
    {
        if( this.editor )
        {
            this.editor.focusEditor();
        }
    };

    getCaption = () =>
    {
        const { locale } = this.props ;
        if( locale )
        {
            const style = { color : grey[400] } ;
            const { caption } = locale ;
            if( isString(caption) )
            {
                return (
                    <div className='flex flex-row w-full mt-4 items-center'>
                        <div
                            className = "fas fa-sticky-note mr-8"
                            style     = { style }
                        />
                        <Typography
                            className = 'w-full italic'
                            style     = { style }
                            variant   = 'caption'
                        >
                            { caption }
                        </Typography>
                    </div>
                )
            }
        }
        return null ;
    };

    getContent = ( changed = false , editable = false ) =>
    {
        const {
            classes,
            disabled,
            html,
            input,
            lang,
            placeholder,
            toolbar,
            toolbarOnFocus,
        } = this.props;

        const { editorState, currentLang, status } = this.state ;

        const failed   = status === RequestStatus.FAIL;
        const progress = status === RequestStatus.PROGRESS;

        let css ;
        if( failed )
        {
            css = input ? classes.inputFail : classes.editorFail ;
        }
        else if( changed )
        {
            css = input ? classes.inputChanged : classes.editorChanged ;
        }
        else
        {
            css = input ? classes.input : classes.editor ;
        }

        return (
            <Fragment>
                { this.getTabs(changed) }
                <Editor
                    editorClassName     = { css }
                    editorState         = { editorState }
                    onEditorStateChange = { this.changeEditor(currentLang) }
                    handlePastedText    = { this.paste }
                    handleReturn        = { () => input }
                    localization        = { { locale : lang } } // en, fr, zh, ru, pt, ko, it, nl, de, da, zh_tw, pl, es
                    placeholder         = { placeholder}
                    readOnly            = { disabled || progress || !(editable)  }
                    ref                 = { ref => this.editor = ref }
                    stripPastedStyles   = { html === false }
                    toolbar             = { toolbar }
                    toolbarHidden       = { !(editable && html) }
                    toolbarOnFocus      = { toolbarOnFocus }
                    wrapperClassName    = { classes.wrapper }
                />
            </Fragment>
        ) ;
    };

    getOptions = ( changed = false , editable = false ) =>
    {
        const {
            classes,
            disabled,
            locale,
            progressColor,
            theme
        } = this.props;

        const { status } = this.state ;

        const transitionDuration =
        {
            enter : theme.transitions.duration.enteringScreen,
            exit  : theme.transitions.duration.leavingScreen,
        };

        const progress = status === RequestStatus.PROGRESS ;

        let options ;

        if( progress )
        {
            options = (
                <CircularProgress
                    style     = {{ color: progressColor , marginRight : 12 }}
                    size      = {24}
                    thickness = {4}
                />
            );
        }
        else if( editable && changed )
        {
            const { tooltips } = locale ;
            options =
            [
                (<Tooltip key="button_save" placement='left' title={tooltips.save}>
                    <Zoom
                        className = { classes.option }
                        in        = { changed }
                        timeout   = { transitionDuration }
                        style     = {{ transitionDelay: `${changed ? transitionDuration.exit : 0}ms` }}
                        unmountOnExit
                    >
                        <Fab
                            disabled  = { disabled || progress }
                            size      = "small"
                            color     = "secondary"
                            onClick   = { this.send }
                        >
                            <SaveIcon/>
                        </Fab>
                    </Zoom>
                </Tooltip>),
                (<Tooltip key="button_refresh" placement='left' title={tooltips.refresh}>
                    <Zoom
                        className = { classes.option }
                        in      = {changed}
                        timeout = {transitionDuration}
                        style   = {{ transitionDelay: `${changed ? transitionDuration.exit : 0}ms` }}
                        unmountOnExit
                    >
                        <Fab
                            disabled  = { disabled || progress }
                            size      = "small"
                            color     = "primary"
                            onClick   = { this.refresh }
                         >
                            <RefreshIcon/>
                        </Fab>
                    </Zoom>
                </Tooltip>)
            ];
        }

        return (
            <div className="flex flew-row justify-end items-center">
                { options }
            </div>
        );
    };

    isChanged( currentLang )
    {
        const { item, text } = this.state ;
        if( item && text )
        {
            if( (text[currentLang] || item[currentLang]) && (text[currentLang] !== item[currentLang]) )
            {
                return true ;
            }
        }
        return false ;
    }

    getTabs = () =>
    {
        const { currentLang } = this.state ;
        const { languages } = this.props ;
        if( languages instanceof Array && languages.length > 0 )
        {
            const { classes } = this.props;
            return(
            <ToggleButtonGroup
                className  = { classes.tabs }
                value      = { currentLang }
                exclusive  = { true }
                onChange   = { this.changeLang }
            >
                { languages.map( ( element , index ) => (
                    <ToggleButton
                        key       = { 'tab_' + index }
                        className = { classes.tab }
                        value     = { element.id }
                    >
                        { element.icon }
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
            );
        }
        return null ;
    };

    send = () =>
    {
        const { uri }  = this.props ;
        const { item } = this.state ;

        this.setState( { errors:null , status : RequestStatus.PROGRESS } ) ;

        this.canceler = PATCH( api.url + uri , { success : this._success , fail : this._fail, cancel : this._cancel , datas : item } ) ;
    };

    refresh = () =>
    {
        let { currentLang } = this.state ;
        let { text } = this.props ;
        this.setLang( currentLang , text ) ;
    };

    render = () =>
    {
        const { icon, locale } = this.props;
        const { currentLang } = this.state ;

        const changed  = this.isChanged(currentLang) ;
        const editable = this.isEditable() ;

        return (
        <div className="flex flex-col">
            <Paper elevation={2} className="w-full p-20 mb-16">
                <div className="flex flew-row justify-between items-center">
                    <div className="flex flex-row items-center">
                        { icon }
                        <Typography className="ml-12 font-semibold" variant="subtitle1">{locale.title}</Typography>
                    </div>
                    { this.getOptions(changed, editable) }
                </div>
                <Divider light className="mt-16"/>
                { this.getContent(changed, editable) }
                { this.getCaption() }
            </Paper>
        </div>
        );
    };

    // ------- Protected

    changeEditor = lang => editorState =>
    {
        const { config : { languages } = {} } = this.props ;
        if( languages instanceof Array && languages.indexOf(lang) > -1 )
        {
            const { html, input, onChangeContent } = this.props ;

            let text ;
            if( html )
            {
                text = draftToHtml( convertToRaw( editorState.getCurrentContent() ) ) ;
                text = this.sanitize(text);
            }
            else
            {
                text = editorState.getCurrentContent().getPlainText();
                if( input )
                {
                    text = text.replace(/(\r\n\t|\n|\r\t)/gm,"").trim() ;
                }
            }

            const { item } = this.state ;
            if( item )
            {
                item[lang] = text ;
            }

            this.setState( { editorState , status:RequestStatus.NEW });

            if( onChangeContent instanceof Function )
            {
                onChangeContent( item ) ;
            }
        }
    };

    changeLang = ( event , value ) =>
    {
        const { currentLang } = this.state ;
        if( !value || (currentLang === value) )
        {
            return ;
        }
        this.setLang( value ) ;
    };

    componentDidMount()
    {
        const { text } = this.props ;
        const { currentLang } = this.state ;
        this.setLang( currentLang , text ) ;
    }

    componentDidUpdate = ( prevProps ) =>
    {
        if( prevProps.text !== this.props.text )
        {
            this.setLang( this.state.currentLang , this.props.text ) ;
        }
        if( this.change )
        {
            this.change = false ;
            this.interval = setTimeout( this.refresh, 500 ) ;
        }
    };

    paste = ( text , html ) =>
    {
        if( isString(html) )
        {
            return this.sanitize( html ) ;
        }
        else if ( isString(text ) )
        {
            return this.sanitize( text ) ;
        }
    };

    sanitize = ( text ) =>
    {
        const {
            config ,
            html,
            input
        } = this.props ;

        if( isString(text) )
        {
            text = trim(text) ;
            if( html )
            {
                text = sanitizeHtml(text, config.sanitizeOptions ) ;
                if( text === '<p></p>')
                {
                    text = '' ;
                }
            }
            else
            {
                text = sanitizeHtml(text, config.sanitizeAll ) ;
            }
        }

        if( input )
        {
            text = text.replace(/(\r\n\t|\n|\r\t)/gm,"").trim() ;
            text = text.replace(/[^\x20-\x7E]/gmi,'');
        }

        return text ;
    };

    setLang = ( lang , initialize = null , status = RequestStatus.SUCCESS ) =>
    {
        let { item , text } = this.state ;
        if( item )
        {
            const { config : { languages } = {} } = this.props ;

            if( initialize )
            {
                if( languages )
                {
                    for( let prop in initialize )
                    {
                        if( (languages.indexOf(prop) > -1) && isString(initialize[prop]) )
                        {
                            item[prop] = initialize[prop] ;
                        }
                    }
                }
                text = initialize ;
            }

            if( text === null )
            {
                text = {} ;
                languages.forEach( lang => text[lang] = '' );
            }

            if( item.hasOwnProperty(lang) )
            {
                if( isString(item[lang]) )
                {
                    const { autoFocus , html } = this.props ;

                    let contentState ;

                    if( html )
                    {
                        const draft = htmlToDraft( item[lang] ) ;
                        const { contentBlocks, entityMap } = draft ;
                        contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
                    }
                    else
                    {
                        contentState = ContentState.createFromText( item[lang] , '\u000A' );
                    }

                    if( autoFocus && this.editor )
                    {
                        this.editor.focusEditor();
                    }

                    this.setState({
                        currentLang:lang,
                        status,
                        editorState:EditorState.createWithContent(contentState),
                        text
                    }) ;
                    return ;
                }
            }
        }

        this.setState( { item, currentLang:lang, editorState:null, text } ) ;
    };

    unmount = () =>
    {
        clearTimeout( this.interval ) ;

        const { status } = this.state ;
        if( status === RequestStatus.PROGRESS && !!(this.canceler) )
        {
            this.canceler.cancel(this + ' cancel') ;
        }
    };

    // ------- Private

    _cancel = () =>
    {
        this.setState( { errors:null , status : RequestStatus.NEW  } ) ;
        const { onCancel } = this.props ;
        if( onCancel instanceof Function )
        {
            onCancel() ;
        }
    };

    _fail = ( response ) =>
    {
        if( response )
        {
            const { data } = response ;
            if( data )
            {
                console.log( this + ' fail' , response ) ;
                const { message, status } = data ;
                if( status === 'error' )
                {
                    this.setState( { errors:data.message , status : RequestStatus.FAIL } ) ;
                    return ;
                }

                switch( message )
                {
                    case 'token revoked' :
                    {
                        break ;
                    }
                    default :
                    {
                        console.log( this + " failed, status:" + response.status + ", message:" + data.message , true );
                    }
                }
            }
        }

        this.setState( { status:RequestStatus.FAIL } ) ;
    };

    _success = ( response ) =>
    {
        if( response )
        {
            const { data } = response ;
            if( data )
            {
                const { result } = data ;
                if( result )
                {
                    const { onChange } = this.props ;

                    const { currentLang } = this.state ;

                    this.setLang( currentLang, result, RequestStatus.SUCCESS ) ;

                    this.change = true ;

                    if( onChange instanceof Function )
                    {
                        onChange( result ) ;
                    }
                    return null  ;
                }
            }

            this.setState( { status:RequestStatus.FAIL } ) ;
        }
    }
}

MultiLangEditor.defaultProps =
{
    ...RoleableContainer.defaultProps ,
    autoFocus       : false ,
    defaultLang     : 'fr' ,
    disabled        : false,
    html            : true ,
    icon            : <TitleIcon/>,
    input           : false ,
    languages       : editors.languages ,
    locale          : editors.multilang ,
    onCancel        : null ,
    onChange        : null ,
    onChangeContent : null ,
    placeholder     : null ,
    progressColor   : blueGrey[900],
    text            : null ,
    toolbar         :
    {
        inline:
        {
            inDropdown : false,
            options    : // 'monospace', 'superscript', 'subscript'
            [
                'bold',
                'italic',
                'underline',
                'strikethrough'
            ]
        },
        options :
        [
            // 'fontFamily',
            // 'fontSize',
            // 'list',
            // 'textAlign',
            // 'colorPicker',
            // 'embedded',
            // 'emoji',
            // 'image'

            'history',
            'inline',
            'blockType',
            'link',
            'remove'
        ]
    },
    toolbarOnFocus : false,
    uri            : null
};

MultiLangEditor.propTypes =
{
    ...RoleableContainer.propTypes ,
    autoFocus        : PropTypes.bool,
    classes          : PropTypes.object.isRequired ,
    defaultLang      : PropTypes.string,
    disabled         : PropTypes.bool,
    html             : PropTypes.bool,
    icon             : PropTypes.element,
    input            : PropTypes.bool,
    languages        : PropTypes.arrayOf(PropTypes.shape(
    {
        icon : PropTypes.element,
        id   : PropTypes.string,
        name : PropTypes.string
    })),
    locale           : PropTypes.object,
    onCancel         : PropTypes.func,
    onChange         : PropTypes.func,
    onChangeContent  : PropTypes.func,
    placeholder      : PropTypes.string,
    progressColor    : PropTypes.string,
    text             : PropTypes.object,
    toolbar          : PropTypes.object,
    toolbarOnFocus   : PropTypes.bool,
    uri              : PropTypes.string
};

export default compose(
    withStyles(styles,{ withTheme : true }),
    withRouter,
    withConfig,
    withLang
)(MultiLangEditor) ;
