const events =
{
    ariaLabels :
    {
        tabs : 'Menu principal'
    },
    helmet :
    {
        title : 'Événements' ,
        metas : []
    },
    title : 'Événements' ,
    // tabs :
    // {
    //     calendar : 'Agenda' ,
    //     grid     : 'Grille' ,
    //     list     : 'Liste' ,
    // }
};

export default events ;