import courses from './ambulo/courses'
import events  from './ambulo/events'
import info    from './ambulo/info'
import game    from './ambulo/game'

const ambulo =
{
    courses,
    events,
    info,
    game
};

export default ambulo ;
