import init from './thing'

const technician =
{
    ...init,
    person : 
    {
        title : 'Autorité' 
    }
};

export default technician ;
