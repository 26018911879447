import about from './conceptualObject/about'

const conceptualObject =
{
    about,

    loading  : "Chargement ..." ,
    notFound : "Objet inconnu" ,
    tabs     :
    {
        about  : 'À propos',
        info   : 'Infos',
        medias : 'Médias'
    },
    tooltips :
    {
        back : 'Tous les objets'
    }
};

export default conceptualObject ;
