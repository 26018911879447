export default
{
    description : 'Veuillez sélectionner un parcours ci-dessous.' ,
    disagree    : 'Fermer' ,
    empty       : 'Aucun parcours disponible.' ,
    fail        : 'Impossible de charger les parcours, veuillez relancer un peu plus tard.',
    loading     : 'Chargement en cours...' ,
    search      : 'Rechercher un parcours ...' ,
    title       : 'Sélectionner un parcours'
};
