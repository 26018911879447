import Livestock   from './Livestock'
import NumberValue from './NumberValue'
import Thing       from './Thing'
import Word        from './Word'
import Workplace   from './Workplace'

import generify from './generify'

/**
 * Creates a new breeding workshop.
 * @memberOf things
 */
class Workshop extends Thing
{
    /**
     * Creates a new Workshop instance.
     * @constructor
     * @param object The generic object to initialize the object the first time.
     */
    constructor( object = null )
    {
        super() ;

        /**
         * The type of breeding.
         * @type {Object}
         */
        this.breeding = null ;
    
        /**
         * The identifier of the workshop.
         * @type {null}
         */
        this.identifier = null ;

        /**
         * The production type of the workshop.
         * @type {Object}
         */
        this.production = null ;

        /**
         * Origin of the water in the workshop.
         * @type {Object}
         */
        this.water = null ;

        /**
         * The collection of workplaces.
         * @type {Array}
         */
        this.workplaces = null ;

        this.set( object ) ;
    }

    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        super.clear() ;
        this.breeding   =
        this.identifier =
        this.production =
        this.subjectOf  =
        this.water      =
        this.workplaces = null ;
        return this ;
    }

    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new Workshop( this.toObject() ) ;
    }

    /**
     * Populate the object with specific behaviors or types. Launched by default in the constructor and in the set methods.
     * @returns {Thing} The current reference.
     */
    populate()
    {
        const {
            breeding,
            identifier,
            production,
            subjectOf,
            water,
            workplaces
        } = this ;
        
        if( breeding && !(breeding instanceof Word))
        {
            this.breeding = new Word(breeding) ;
        }
        
        if( identifier && !(identifier instanceof NumberValue))
        {
            this.identifier = new NumberValue(identifier) ;
        }
        
        if( production && !(production instanceof Word))
        {
            this.production = new Word(production) ;
        }
        
        if( subjectOf && !(subjectOf instanceof Livestock ) )
        {
            this.subjectOf = new Livestock(subjectOf);
        }
        
        if( water && !(water instanceof Word))
        {
            this.water = new Word(water) ;
        }
        
        if( workplaces instanceof Array )
        {
            this.workplaces = workplaces.map( item =>
            {
                item = item instanceof Workplace ? item : new Workplace(item) ;
                //item.subjectOf = this ;
                return item ;
            } ) ;
        }
        
        return this ;
    }

    /**
     * Returns the generic object representation of the item.
     * @return {Object} The generic object representation of the item.
     */
    toObject()
    {
        let {
            breeding,
            identifier,
            production,
            subjectOf,
            water,
            workplaces
        } = this ;
        
        breeding   = generify( breeding );
        identifier = generify( identifier );
        production = generify( production );
        subjectOf  = generify( subjectOf );
        water      = generify( water );
        workplaces = generify( workplaces );
        
        return {
            ...super.toObject() ,
            breeding,
            identifier,
            production,
            subjectOf,
            water,
            workplaces
        };
    }
}

export default Workshop ;