import Technician from '../../../things/Technician'
import Thing      from '../../../things/Thing'

const technician =
{
    helmet :
    {
        title : 'Technicien - {0}' ,
        metas : []
    },
    about :
    {
        title : "À propos"
    },
    loading  : "Chargement ..." ,
    notFound : "Technicien inconnu" ,
    remove     :
    {
        agree        : 'Supprimer' ,
        description  : 'Souhaitez-vous supprimer la fiche du technicien:' ,
        disagree     : 'Annuler' ,
        item         :
        {
            text :
            {
                primary : ( element , props ) =>
                {
                    if( element instanceof Technician )
                    {
                        const { person } = element ;
                        if( person instanceof Thing )
                        {
                            return person.getLocaleName(props.lang);
                        }
                    }
                    return 'Inconnu' ;
                }
            }
        },
        loading : 'Suppression en cours...' ,
        title   : 'Supprimer ce technicien ?' ,
        useID   : false
    },
    subtitle : 'Technicien',
    tabs     :
    {
        about      : 'À propos' ,
        livestocks : 'Élevages'
    },
    tooltips :
    {
        back : "Tous les techniciens"
    }
};

export default technician ;