import React from 'react'

import PropTypes from 'prop-types'

import clsx from 'clsx'

import { Link } from 'react-router-dom'

import htmlParser   from 'react-html-parser'

import isString from 'vegas-js-core/src/isString'
import notEmpty from 'vegas-js-core/src/strings/notEmpty'

import { IconButton, Tooltip, Typography } from '@material-ui/core'

import XtraContainer from '../../components/containers/XtraContainer'

import api from '../../configs/api'

export class ThingComponent extends XtraContainer
{
    getIcon = ( { className = null , label = null , style = null } = {} ) =>
    {
        const { iconifiable } = this.props;
        if ( iconifiable )
        {
            let {
                clickable,
                copiable,
                disabled,
                icon,
                iconClassName,
                iconLinkable,
                iconProps,
                iconVariant,
                onClick
            } = this.props;

            if( icon instanceof Function )
            {
                icon = icon( this.props , this.state ) ;
            }

            if( iconVariant === 'icon' )
            {
                return icon ;
            }

            onClick = onClick || this.onClick ;

            const locale = this.getLocale();

            let button = (
                <IconButton
                    className = { clsx(
                        'bg-gray-100 mx-4 w-32 h-32 shadow-md' ,
                        iconClassName ,
                        className
                    )}
                    color     = 'secondary'
                    disabled  = { !clickable || disabled }
                    size      = 'small'
                    style     = { style }

                    component = { (clickable && iconLinkable) ? Link : 'button' }
                    { ...( (clickable && iconLinkable) && { to : this.getPath() } ) }

                    onClick = { () =>
                    {
                        if( label )
                        {
                            const { copy : { snack } = {} } = locale ;
                            this.copy( label , snack ) ;
                        }
                        if ( onClick instanceof Function )
                        {
                            onClick( this.getThing() , label ) ;
                        }
                    }}

                    { ...iconProps }
                >
                    { icon }
                </IconButton>
            );

            if ( clickable && copiable )
            {
                const { copy : { tooltip } = {} } = locale ;
                if( isString(tooltip) )
                {
                    button =
                    (
                        <Tooltip placement='top' title={tooltip}>
                            { button }
                        </Tooltip>
                    ) ;
                }
            }

            return button ;
        }
        return null;
    };

    getLabel = label =>
    {
        const { labelable } = this.props;
        if (labelable)
        {
            const {
                html,
                clickable,
                labelLinkable,
                labelProps,
                labelVariant
            } = this.props;

            const { className , ...rest } = labelProps || {} ;

            return (
                <Typography
                    color     = 'textSecondary'
                    className = { clsx('whitespace-pre-line leading-tight', className) }
                    variant   = { labelVariant }
                    component = { (clickable && labelLinkable) ? Link : 'div' }
                    { ...( (clickable && labelLinkable) && { to : this.getPath() } ) }
                    { ...rest }
                >
                    { html ? htmlParser(label) : label }
                </Typography>
            );
        }
        return null;
    };

    getLocaleTitle = () =>
    {
        const locale = this.getLocale();
        const { title } = locale;
        return title ;
    };

    getPath = ( thingRef = {} ) =>
    {
        const { thing : { url } = thingRef } = this.props ;
        if( notEmpty( url ) )
        {
            return url.split(api.url)[1];
        }
        return "" ;
    };

    getThing = () => this.props.thing ;

    getTitle = ( { className = null , text = null } = {} ) =>
    {
        const { titleable } = this.props;
        if ( titleable )
        {
            if( !isString(text) )
            {
                const { title } = this.props ;
                text = notEmpty(title) ? title : this.getLocaleTitle() ;
            }
            if ( notEmpty(text) )
            {
                const { titleLinkable, titleProps } = this.props;
                return (
                    <Typography
                        className = { clsx('font-500 leading-relaxed', className) }
                        color     = 'textPrimary'

                        component = { titleLinkable ? Link : 'div' }
                        { ...( titleLinkable && { to : this.getPath() } ) }

                        variant   = 'caption'
                        { ...titleProps }
                    >
                        { text }
                    </Typography>
                );
            }
        }
        return null;
    };

    getUrl = ( thingRef = {} ) =>
    {
        const { thing : { url } = thingRef } = this.props ;
        return url ;
    };

    gotoUrl = url =>
    {
        if( isString( url ) )
        {
            const { history } = this.props ;
            if( history )
            {
                history.push( { pathname:url.split(api.url)[1] } );
            }
        }
    };
}

ThingComponent.defaultProps =
{
    ...XtraContainer.defaultProps,
    clickable      : true,
    html           : false,
    icon           : null,
    iconifiable    : true,
    iconLinkable   : false,
    iconProps      : null,
    iconVariant    : 'button',
    labelable      : true,
    labelClassName : null ,
    labelLinkable  : false,
    labelProps     : null,
    labelVariant   : 'caption',
    onClick        : null,
    title          : null,
    titleable      : true,
    titleLinkable  : false,
    titleProps     : null,
    thing          : null
};

ThingComponent.propTypes =
{
    ...XtraContainer.propTypes,
    clickable      : PropTypes.bool,
    html           : PropTypes.bool,
    icon           : PropTypes.oneOfType([PropTypes.element,PropTypes.func]) ,
    iconClassName  : PropTypes.string,
    iconLinkable   : PropTypes.bool,
    iconProps      : PropTypes.object,
    iconifiable    : PropTypes.bool,
    iconVariant    : PropTypes.oneOf(['icon','button']),
    labelable      : PropTypes.bool,
    labelClassName : PropTypes.string,
    labelLinkable  : PropTypes.bool,
    labelProps     : PropTypes.object,
    labelVariant   : PropTypes.oneOf(['caption','button']),
    onClick        : PropTypes.func,
    title          : PropTypes.string,
    titleable      : PropTypes.bool,
    titleLinkable  : PropTypes.bool,
    titleProps     : PropTypes.object,
    thing          : PropTypes.object,
};

export default ThingComponent ;
