const audios =
[
    // 'audio/aac',
    // 'audio/mp3',
    // 'audio/mp4',
    // 'audio/x-m4a',
    // 'audio/mpeg',
    // 'audio/x-mpeg-3',
    // 'audio/flac',
    // 'audio/x-flac',
    // 'audio/ogg',
    // 'audio/vorbis',
    // 'audio/webm',
    // 'audio/wav',
    // 'audio/x-wav',

    'audio/aac',
    'audio/flac',
    'audio/x-flac',
    'audio/mp3',
    'audio/mp4',
    'audio/x-m4a',
    'audio/mpeg',
    'audio/x-mpeg-3',
    'audio/wav',
    'audio/x-wav'
];

const documents =
[
    'application/pdf',
];

const images =
[
    'image/png' ,
    'image/jpeg' ,
    'image/webp'
];

const videos =
[
    //
];

const acceptedUploadMedias =
{
    all :
    [
        ...audios,
        ...documents,
        ...images,
        ...videos,
    ],
    audios,
    documents,
    images,
    videos
};

export default acceptedUploadMedias ;
