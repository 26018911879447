import React from 'react'

import clsx from 'clsx'

import format from 'vegas-js-core/src/strings/fastformat'

import { Grid } from '@material-ui/core'

import DescriptionIcon from '@material-ui/icons/Notes'
import NoteIcon        from '@material-ui/icons/SpeakerNotes'
import TextIcon        from '@material-ui/icons/Subject'
import TitleIcon       from '@material-ui/icons/Title'

import withLocale from '../../../contexts/i18n/withLocale'

import TransitionGroup from '../../../transitions/TransitionGroup'

import Badge             from '../../../display/things/game/Badge'
import Metadatas         from '../../../display/things/Metadatas'
import Game              from '../../../display/things/Game'
import ThingSubContainer from '../../../display/containers/ThingSubContainer'

import MultiLangEditor from '../../../components/editors/MultiLangEditor'

const full =
{
    xs : 12
};

const half =
{
    md :  6 ,
    sm : 12 ,
    xs : 12
};

class About extends ThingSubContainer
{
    getLocale = () => this.props.locale.views.game.about ;

    render = () =>
    {
        const { thing } = this.props ;
        if( thing )
        {
            const {
                className,
                path,
                style,
            } = this.props ;

            const locale = this.getLocale() ;

            const {
                text,
                notes,
                alternateName,
                description,
                id,
                item
            } = thing ;

            return (
                <div
                    className = { clsx( 'flex-1 p-12' , className ) }
                    style     = { style }
                >
                    <TransitionGroup duration={250} enter={{animation: "transition.slideUpIn"}}>

                        <Metadatas
                            activable={false}
                            thing={thing}
                            uri={format(path.game,id)}
                        />

                        <Grid container spacing={4}>
                            <Grid item {...half}>
                                <Game
                                    editUri  = { format(path.game,id) }
                                    onChange = { this.change }
                                    thing    = { thing }
                                />
                                <MultiLangEditor
                                    html     = { false }
                                    icon     = { <TitleIcon/> }
                                    input    = { true }
                                    locale   = { locale.alternateName }
                                    onChange = { this.changeProperty('alternateName') }
                                    text     = { alternateName }
                                    uri      = { format(path.alternateName, id ) }
                                    verbose  = { true }
                                />
                            </Grid>

                            <Grid item {...half}>
                                <Badge
                                    editUri  = { format(path.item,id) }
                                    onChange = { this.changeProperty('item' , true) }
                                    thing    = { item }
                                />
                            </Grid>

                            <Grid item {...half}>
                                <MultiLangEditor
                                    html     = { false }
                                    icon     = { <DescriptionIcon/> }
                                    locale   = { locale.description }
                                    onChange = { this.changeProperty('description') }
                                    text     = { description }
                                    uri      = { format(path.description, id ) }
                                />
                            </Grid>

                            <Grid item {...half}>
                                <MultiLangEditor
                                    icon     = { <NoteIcon/> }
                                    locale   = { locale.notes }
                                    onChange = { this.changeProperty('notes') }
                                    text     = { notes }
                                    uri      = { format(path.notes, id ) }
                                />
                            </Grid>

                            <Grid item {...full}>
                                <MultiLangEditor
                                    icon     = { <TextIcon/> }
                                    locale   = { locale.text }
                                    onChange = { this.changeProperty('text') }
                                    text     = { text }
                                    uri      = { format(path.text, id ) }
                                />
                            </Grid>

                        </Grid>
                    </TransitionGroup>
                </div>
            );
        }
        return null ;
    }
}

About.defaultProps =
{
    ...ThingSubContainer.defaultProps
};

About.propTypes =
{
    ...ThingSubContainer.propTypes
};

export default withLocale( About ) ;
