import Form   from '../../../../../../../display/forms/FormElements'
import Method from '../../../../../../../net/Method'

const elements =
[
    {
        type : Form.SWITCH ,
        init :
        {
            id    : 'confirmed',
            key   : 'confirmed',
            label : 'Confirmation de l\'hypothèse ?'
        }
    },
    {
        type : Form.I18N_EDITOR ,
        init :
        {
            id    : 'description',
            key   : 'description',
            input : false,
            label : 'Description'
        }
    }
];

const analysis =
{
    edit :
    {
        agree       : 'Sauver' ,
        disagree    : 'Annuler' ,
        fail        : 'Veuillez corriger les erreurs' ,
        loading     : 'Chargement en cours...' ,
        method      : Method.PATCH,
        mock        : false ,
        success     : 'Mise à jour réussie!' ,
        title       : 'Description de l\'analyse' ,
        elements
    },
    labels:
    {
        confirmed   : 'Confirmation de laboratoire de cette hypothèse?',
        description : 'Information sur l\'analyse' ,
        no          : 'Non',
        yes         : 'Oui'
    },
    title    : 'Analyses' ,
    tooltips :
    {
        edit : 'Modifier'
    }
};

export default analysis ;