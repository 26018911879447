import React from 'react'

import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import LocalHospitalIcon from '@material-ui/icons/LocalHospital'
import MemoryIcon        from '@material-ui/icons/Work'

import Form from '../../../../display/forms/FormElements'

import Technician    from '../../../../things/Technician'
import Veterinarian  from '../../../../things/Veterinarian'
import Thing         from '../../../../things/Thing'

import AuthorityLabel from '../../../../display/things/observation/AuthorityLabel'
import dialogType     from '../../../../display/dialogs/dialogType'

import api       from '../../../../configs/api'

import Livestock from '../../../../things/Livestock'

const elements =
[
    {
        label : 'Vétérinaire' ,
        icon  : <LocalHospitalIcon/> ,
        elements :
        [
            {
                type       : Form.SEARCH_SELECTOR ,
                clazz      : Veterinarian,
                clearLabel : 'Vider' ,
                closeLabel : 'Fermer' ,
                init       :
                {
                    id        : 'value',
                    key       : 'value',
                    label     : 'Vétérinaire',
                    autoFocus : false,
                    fullWidth : true,
                    margin    : 'normal',
                    required  : true,
                    type      : 'search',
                    variant   : 'outlined'
                },
                inputProps : { label : 'Vétérinaire...' },
                logics     :
                {
                    disabledSuggestions : props =>
                    {
                        const { rel } = props ;
                        if( rel instanceof Thing )
                        {
                            const { subjectOf } = rel ;
                            if( subjectOf instanceof Thing )
                            {
                                const { authority } = subjectOf ;
                                if( authority instanceof Array )
                                {
                                    return authority.map( item => (item instanceof Veterinarian || item instanceof Technician) ? item : null ) ;
                                }
                            }
                        }
                        return null ;
                    }
                },
                path : props =>
                {
                    const { rel } = props ;
                    if( rel )
                    {
                        const { subjectOf } = rel ;
                        if( subjectOf )
                        {
                            const { about } = subjectOf ;
                            if( about instanceof Livestock)
                            {
                                const { url } = about ;
                                if( url )
                                {
                                    return url.split(api.url)[1] + '/veterinarians' ;
                                }
                            }
                        }
                    }
                    return null ;
                },
                suggestionRef : item =>
                {
                    if( item instanceof Technician )
                    {
                        const { person } = item ;
                        if( person instanceof Thing )
                        {
                            return person ;
                        }
                    }
                    else if( item instanceof Veterinarian )
                    {
                        const { authority } = item ;
                        if( authority instanceof Thing )
                        {
                            return authority ;
                        }
                    }
                    return item ;
                },
                valueLabel : ( item , props ) =>
                {
                    const { lang } = props ;
                    if( item instanceof Technician )
                    {
                        const { person } = item ;
                        if( person instanceof Thing )
                        {
                            return ucFirst(person.getLocaleName(lang)) ;
                        }
                    }
                    else if( item instanceof Veterinarian )
                    {
                        const { authority } = item ;
                        if( authority instanceof Thing )
                        {
                            return ucFirst(authority.getLocaleName(lang)) ;
                        }
                    }
                    return item ;
                }
            }
        ]
    },
    {
        label : 'Technicien' ,
        icon  : <MemoryIcon/> ,
        elements :
        [
            {
                type       : Form.SEARCH_SELECTOR ,
                clazz      : Technician,
                clearLabel : 'Vider' ,
                closeLabel : 'Fermer' ,
                init       :
                {
                    id        : 'value',
                    key       : 'value',
                    label     : 'Technicien',
                    autoFocus : false,
                    fullWidth : true,
                    margin    : 'normal',
                    required  : true,
                    type      : 'search',
                    variant   : 'outlined'
                },
                inputProps : { label : 'Technicien...' },
                logics :
                {
                    disabledSuggestions : ( props ) =>
                    {
                        const { rel } = props ;
                        if( rel instanceof Thing )
                        {
                            const { subjectOf } = rel ;
                            if( subjectOf instanceof Thing )
                            {
                                const { authority } = subjectOf ;
                                if( authority instanceof Array )
                                {
                                    return authority.map( item => (item instanceof Veterinarian || item instanceof Technician) ? item : null ) ;
                                }
                            }
                        }
                        return null ;
                    }
                },
                path : ( props ) =>
                {
                    const { rel } = props ;
                    if( rel )
                    {
                        const { subjectOf } = rel ;
                        if( subjectOf )
                        {
                            const { about } = subjectOf ;
                            if( about instanceof Livestock)
                            {
                                const { url } = about ;
                                if( url )
                                {
                                    return url.split(api.url)[1] + '/technicians' ;
                                }
                            }
                        }
                    }
                    return null ;
                },
                suggestionRef : ( item ) =>
                {
                    if( item instanceof Technician )
                    {
                        const { person } = item ;
                        if( person instanceof Thing )
                        {
                            return person ;
                        }
                    }
                    else if( item instanceof Veterinarian )
                    {
                        const { authority } = item ;
                        if( authority instanceof Thing )
                        {
                            return authority ;
                        }
                    }
                    return item ;
                },
                valueLabel : ( item , props ) =>
                {
                    const { lang } = props ;
                    if( item instanceof Technician )
                    {
                        const { person } = item ;
                        if( person instanceof Thing )
                        {
                            return ucFirst(person.getLocaleName(lang)) ;
                        }
                    }
                    else if( item instanceof Veterinarian )
                    {
                        const { authority } = item ;
                        if( authority instanceof Thing )
                        {
                            return ucFirst(authority.getLocaleName(lang)) ;
                        }
                    }
                    return item ;
                }
            }
        ]
    }
];

const authority =
{
    add :
    {
        elements,
        agree       : 'Ajouter' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez sélectionner un vétérinaire en cliquant ci-dessous.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en  cours...' ,
        success     : 'Ajout d\'une nouvelle autorité réussi!' ,
        title       : 'Ajouter une autorité',
        type        : dialogType.SWIPEABLE
    },
    remove :
    {
        agree       : 'Supprimer' ,
        description : 'Souhaitez-vous supprimer cette autorité:' ,
        disagree    : 'Annuler' ,
        item        :
        {
            render : element => <AuthorityLabel className='px-16' mode='label' thing={element}/>
        },
        loading : 'Suppression en cours...' ,
        title   : 'Supprimer l\'autorité ?'
    },
    title : 'Autorités' ,
    tooltips :
    {
        add    : "Ajouter" ,
        edit   : "Éditer" ,
        remove : "Supprimer"
    }
};

export default authority ;