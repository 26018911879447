import React from 'react'

import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import PersonIcon         from '@material-ui/icons/Person'

import Form from '../../../../display/forms/FormElements'

import Organization  from '../../../../things/Organization'
import Person        from '../../../../things/Person'
import Thing         from '../../../../things/Thing'

import dialogType from '../../../../display/dialogs/dialogType'

import api from '../../../../configs/api'

const elements =
[
    {
        label : 'Personne' ,
        icon  : <PersonIcon/> ,
        elements :
        [
            {
                type       : Form.SEARCH_SELECTOR ,
                clazz      : Person,
                path       : api.people.url ,
                clearLabel : 'Vider' ,
                closeLabel : 'Fermer' ,
                init       :
                {
                    id        : 'value',
                    key       : 'value',
                    label     : 'Personne',
                    autoFocus : false,
                    fullWidth : true,
                    margin    : 'normal',
                    required  : true,
                    type      : 'search',
                    variant   : 'outlined'
                },
                inputProps : { label : 'Personne...' },
                logics :
                {
                    disabledSuggestions : ( props ) =>
                    {
                        const { rel } = props ;
                        if( rel instanceof Thing )
                        {
                            const { subjectOf } = rel ;
                            if( subjectOf instanceof Thing )
                            {
                                const { members } = subjectOf ;
                                if( members instanceof Array )
                                {
                                    return members.map( item => item instanceof Person ? item : null ) ;
                                }
                            }
                        }
                        return null ;
                    }
                }
            }
        ]
    },
    {
        label : 'Organisation' ,
        icon  : <AccountBalanceIcon/> ,
        elements :
        [
            {
                type       : Form.SEARCH_SELECTOR ,
                path       : api.organizations.url ,
                clazz      : Organization,
                clearLabel : 'Vider' ,
                closeLabel : 'Fermer' ,
                init       :
                {
                    id        : 'value',
                    key       : 'value',
                    label     : 'Organisation',
                    autoFocus : false,
                    fullWidth : true,
                    margin    : 'normal',
                    required  : true,
                    type      : 'search',
                    variant   : 'outlined'
                },
                inputProps : { label : 'Organisation...' },
                logics :
                {
                    disabledSuggestions : ( props ) =>
                    {
                        const { rel } = props ;
                        if( rel instanceof Thing )
                        {
                            const { subjectOf } = rel ;
                            if( subjectOf instanceof Thing )
                            {
                                const { members } = subjectOf ;
                                if( members instanceof Array )
                                {
                                    return members.map( item => item instanceof Organization ? item : null ) ;
                                }
                            }
                        }
                        return null ;
                    }
                }
            }
        ]
    }
];


const productions =
{
    add :
    {
        elements,
        agree       : 'Ajouter' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez sélectionner une production ci-dessous.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en  cours...' ,
        success     : 'Ajout de la nouvelle production réussi!' ,
        title       : 'Ajouter une production',
        type        : dialogType.SWIPEABLE
    },
    remove :
    {
        agree       : 'Supprimer' ,
        description : 'Souhaitez-vous supprimer ce producteur:' ,
        disagree    : 'Annuler' ,
        loading     : 'Suppression en cours...' ,
        title       : 'Supprimer le producteur ?'
    },
    title    : 'Productions' ,
    tooltips :
    {
        add    : "Ajouter" ,
        edit   : "Éditer" ,
        remove : "Supprimer"
    }
};

export default productions ;
