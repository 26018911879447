import React from "react"

import notEmpty from 'vegas-js-core/src/strings/notEmpty'

import MemoryIcon from '@material-ui/icons/Work'

import ThingView , { defaultStyle } from '../../display/views/ThingView'

import About      from './view/about/About'
import Livestocks from '../../display/things/Livestocks'

import Livestock    from '../../things/Livestock'
import Person       from '../../things/Person'
import TechnicianVO from '../../things/Technician'

import api   from '../../configs/api'
import paths from '../../configs/paths'

import initView from '../../display/views/initView'
import Thing from '../../things/Thing';
import getLocaleThingLabel from '../../things/getLocaleThingLabel';

const styles = defaultStyle();

class Technician extends ThingView
{
    getHeaderThing = () =>
    {
        const { thing } = this.state ;
        if( thing )
        {
            const { person } = thing ;
            if( person instanceof Person )
            {
                return person ;
            }
        }
    }

    getLocale = () => this.props.locale.views.technician ;

    getSubtitle = () =>
    {
        const locale = this.getLocale() ;
        if( locale )
        {
            let { subtitle } = locale ;
            if( notEmpty( subtitle ) )
            {
                return subtitle ;
            }
        }
        return null ;
    };

    getTitle = thing =>
    {
        if( thing instanceof Thing )
        {
            const { person } = thing ;
            if( person )
            {
                return getLocaleThingLabel( person , this.props.lang ) ;
            }
        }
        return null ;
    };

    onRemoveLivestock = ( { item } = {} ) =>
    {
        const { thing } = this.state ;
        if( thing instanceof TechnicianVO )
        {
            if( item instanceof Livestock )
            {
                let { livestocks } = thing ;
                if( livestocks instanceof Array && livestocks.length > 0 )
                {
                    thing.livestocks = livestocks.filter( element => element.url !== item.url ) ;
                    this.change( thing ) ;
                }
            }
        }
    }
}

Technician.defaultProps =
{
    ...ThingView.defaultProps,
    back       : true ,
    clazz      : TechnicianVO,
    icon       : <MemoryIcon/>,
    path       : api.technicians,
    preferable : true ,
    responsive : true ,
    routes     :
    [
        {
            id             : 'about' ,
            RouteComponent : About
        },
        {
            id             : 'livestocks' ,
            routeClazz     : Livestock ,
            RouteComponent : Livestocks ,
            uri            : api.technicians.livestocks,
            onRemove       : 'onRemoveLivestock'
        }
    ],
    tabs :
    [
        { id : 'about'      },
        { id : 'livestocks' }
    ]
};

Technician.propTypes =
{
    ...ThingView.propTypes,
    path : paths.technicians
};

export default initView( Technician, styles );
