import generify from './generify'

import Thing from './Thing'
import Place         from "./Place"
import PostalAddress from "./PostalAddress"

/**
 * The geographic coordinates of a place or event.
 * @memberOf things
 */
class GeoCoordinates extends Thing
{
    /**
     * Creates a new GeoCoordinates instance.
     * @constructor
     * @param object The generic object to initialize the object.
     */
    constructor( object = null )
    {
        super() ;

        /**
         * Physical address of the item.
         * @type {string|PostalAddress}
         */
        this.address = null ;

        /**
         * The country. For example, USA. You can also provide the two-letter ISO 3166-1 alpha-2 country code.
         * @type {string|Place}
         */
        this.addressCountry = null ;

        /**
         * The elevation of a location (WGS 84).
         * @type {number|string}
         */
        this.elevation = null ;

        /**
         * The latitude of a location. For example 37.42242 (WGS 84).
         * @type {number|string}
         */
        this.latitude = null ;

        /**
         * The longitude of a location. For example -122.08585 (WGS 84).
         * @type {number|string}
         */
        this.longitude = null ;

        /**
         * The postal code. For example, 94043.
         * @type {string}
         */
        this.postalCode = null ;

        this.set( object ) ;
    }


    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        super.clear() ;
        this.address        =
        this.addressCountry =
        this.elevation      =
        this.latitude       =
        this.longitude      =
        this.postalCode     = null ;
        return this ;
    }

    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new GeoCoordinates( this.toObject() ) ;
    }
    
    /**
     * Populate the object with specific behaviors or types. Launched by default in the constructor and in the set methods.
     * @returns {Thing} The current reference.
     */
    populate()
    {
        const {
            address,
            addressCountry
        } = this ;
        
        if( address )
        {
            if( !(typeof(address) === 'string' || (address instanceof String)) )
            {
                this.address = new PostalAddress(address);
            }
        }
        
        if( addressCountry )
        {
            if( !(typeof(addressCountry) === 'string' || (addressCountry instanceof String)) )
            {
                this.addressCountry = new Place(addressCountry);
            }
        }
        
        return this ;
    }
    
    /**
     * Returns the generic object representation of the item.
     * @return {Object} The generic object representation of the item.
     */
    toObject()
    {
        let {
            address,
            addressCountry,
            elevation,
            latitude,
            longitude,
            postalCode
        } = this ;

        address        = generify(address);
        addressCountry = generify(addressCountry);

        return { ...super.toObject() ,
            address,
            addressCountry,
            elevation,
            latitude,
            longitude,
            postalCode
        }
    }
}

export default GeoCoordinates ;