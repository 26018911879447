import React  from 'react'

import clsx from 'clsx'

import fastformat from 'vegas-js-core/src/strings/fastformat'

import { withStyles } from '@material-ui/core/styles'

import withConfig from '../../../contexts/config/withConfig'

import Metadatas         from '../../../display/things/Metadatas'
import ThingSubContainer from '../../../display/containers/ThingSubContainer'

const styles = () => ({});

class Setting extends ThingSubContainer
{
    render = () =>
    {
        const {
            className,
            path,
            style,
            thing
        } = this.props ;

        let content ;

        if( thing )
        {
            const { additionalType } = thing ;
            if( additionalType )
            {
                const { config : { application : { settings = {} } = {} } = {} } = this.props ;
                const { alternateName } = additionalType ;
                if( alternateName )
                {
                    let Setting = settings[alternateName] ;
                    if( Setting )
                    {
                        content = (
                            <Setting
                                addUri    = { fastformat( path.quest , thing.id ) }
                                onChange  = { this.change }
                                path      = { path }
                                thing     = { thing }
                            />
                        );
                    }
                }
            }
        }

        return (
        <div
            className = { clsx( 'flex-1', className ) }
            style     = { style }
        >
            <Metadatas activable={false} thing={thing} />
            { content }
        </div>) ;
    }
}

Setting.defaultProps =
{
    ...ThingSubContainer.defaultProps
};

Setting.propTypes =
{
    ...ThingSubContainer.propTypes
};

export default withStyles( styles )( withConfig( Setting ) );
