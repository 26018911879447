import React , { Fragment } from 'react'

import ucFirst from 'vegas-js-core/src/strings/ucFirst'

import {
    Avatar,
    Card,
    Collapse,
    CardHeader,
    Divider,
    Grid,
    IconButton,
    Typography
} from '@material-ui/core'

import lime from '@material-ui/core/colors/lime'

import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import NoteIcon       from '@material-ui/icons/Note'

import RequestStatus from '../../net/RequestStatus'

import DatasContainer from '../containers/DatasContainer'

import withDatasGrid from './withDatasGrid'

const styles = () => (
{
    avatar :
    {
        backgroundColor : lime[300]
    },
    container :
    {
        flex      : 'auto',
        width     : '100%',
        height    : '100%',
        bottom    : 0,
        left      : 0,
        overflowY : 'scroll',
        padding   : 12,
        position  : 'absolute',
        right     : 0,
        top       : 0,
    },
    label :
    {
        color : lime[900]
    },
    root :
    {
        flex     : 'auto',
        overflow : 'hidden',
        position : 'relative'
    }
});

const columns =
{
    xl : 3,
    lg : 4,
    sm : 6,
    xs : 12
};

class ThesauriGrid extends DatasContainer
{
    constructor( props )
    {
        super( props ) ;
        this.state = {
            ...this.state ,
            cards : {} ,
            collapse : {}
        } ;
    }

    getContent = ( loading = false) =>
    {
        const {
            classes ,
            history ,
            lang ,
            location
        } = this.props ;

        let {
            collapse ,
            status,
            things
        } = this.state;

        if( loading )
        {
            return this.getProgress() ;
        }
        else if( status === RequestStatus.NEW )
        {
            return null ;
        }
        else if ( (things instanceof Array) && things.length > 0 )
        {
            const map = {} ;

            let categories = [] ;

            things.forEach( (element) =>
            {
               const { category } = element ;
               if( category )
               {
                   if( categories.indexOf( category ) < 0 )
                   {
                       categories.push( category );
                       map[category] = [] ;
                   }

                   let collection = map[category] ;

                   collection.push( element ) ;
               }
            });

            categories = categories.map( category => (
                <Fragment key={ 'item-' + category }>
                    <div className="flex flex-row items-center justify-between py-10">
                        <Typography className="mb-16 mt-8" variant="h6">{ucFirst(category)}</Typography>
                        <IconButton onClick={ () =>
                        {
                            if( collapse )
                            {
                                if( collapse.hasOwnProperty(category) )
                                {
                                    collapse[category] = !(collapse[category]) ;
                                }
                                else
                                {
                                    collapse[category] = true ;
                                }
                            }
                            this.setState( { collapse }) ;
                        }}>
                            { this.isCollapsed(category) ? <ExpandMoreIcon/> : <ExpandLessIcon/> }
                        </IconButton>
                    </div>
                    <Collapse className="py-10" in={ !this.isCollapsed(category) } >
                        <Grid container spacing={4} className="py-10" >
                        {
                            map[category].map( item =>
                            {
                                const { id } = item ;

                                let name = item.name[lang] ;
                                let current = this.getCurrentPath() ;

                                let shadow = this.state.cards.hasOwnProperty(item.id) ;

                                let path = item.url ;
                                path = path.split( current )[1] ;

                                let to = {
                                    pathname : current + path ,
                                    state    : { thesaurus:item , referrer:location }
                                } ;

                                return(
                                <Grid key={ 'item-' + category + '-' + id } item {...columns}>
                                    <Card
                                        className   = "cursor-pointer"
                                        onClick     = { () => history.push( to ) }
                                        onMouseOver = { this._mouseOver(id) }
                                        onMouseOut  = { this._mouseOut(id)  }
                                        elevation   = { shadow ? 5 : 2 }
                                    >
                                        <CardHeader
                                            className = "flex flex-row items-center hover:shadow"
                                            avatar    = { <Avatar variant="rounded" className={classes.avatar}>{ name ? name.charAt(0).toUpperCase() : null }</Avatar> }
                                            title     = { <Typography variant='subtitle1' className={classes.label}>{ucFirst(name)}</Typography> }
                                            subheader = { <Typography variant="subtitle2">{ucFirst(category)}</Typography> }
                                        />
                                    </Card>
                                </Grid>);
                            })
                        }
                        </Grid>
                    </Collapse>
                    <Divider className="mt-20 mb-12"/>
                </Fragment>
            ));

            return (
                <div className='flex-1'>
                    { categories }
                </div>
            );
        }
        else
        {
            return this.getEmpty() ;
        }
    };

    getIcon = () => <NoteIcon style={{ color:lime[800] }} className="w-auto h-32 mr-0"/> ;

    getLocale = () => this.props.locale.components.grids.thesauri ;

    isCollapsed = category =>
    {
        const { collapse } = this.state ;
        if( collapse && collapse.hasOwnProperty(category) )
        {
            return collapse[category] === true ;
        }
        return false ;
    };

    _mouseOut = id => () =>
    {
        const { cards } = this.state ;
        if( cards && cards.hasOwnProperty(id))
        {
            delete cards[id] ;
        }
        this.setState( { cards } ) ;
    };

    _mouseOver = id => () =>
    {
        const { cards } = this.state ;
        cards[id] = true ;
        this.setState( { cards } ) ;
    };
}

ThesauriGrid.defaultProps =
{
    ...DatasContainer.defaultProps ,
    pagination : false ,
    queries    : { limit : 1000 , sort : 'category,name' }
};

ThesauriGrid.propTypes =
{
    ...DatasContainer.propTypes
};

export default withDatasGrid({ styles })( ThesauriGrid ) ;
