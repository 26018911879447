import React from 'react'

import Icon from '@material-ui/icons/DateRange'

import withDatasList from './withDatasList'

import ThingsList from './ThingsList'

import AddObservationDialog    from '../dialogs/add/AddObservationDialog';
import RemoveObservationDialog from '../dialogs/remove/RemoveObservationDialog';

import Observation     from '../../things/Observation';
import ObservationCell from '../cells/ObservationCell';

class ObservationList extends ThingsList
{
    getLocale = () => this.props.locale.display.lists.observations ;

    getThingRef = () => this.props.thing ;
}

ObservationList.defaultProps =
{
    ...ThingsList.defaultProps ,
    AddDialogComponent    : AddObservationDialog,
    CellComponent         : ObservationCell,
    cellProps             : { variant : 'default' },
    clazz                 : Observation,
    icon                  : <Icon />,
    preferable            : false ,
    RemoveDialogComponent : RemoveObservationDialog
};

ObservationList.propTypes =
{
    ...ThingsList.propTypes
};

export default withDatasList()( ObservationList ) ;
