const conceptualObjects =
{
    helmet :
    {
        title : 'Objets' ,
        metas : []
    }
};

export default conceptualObjects ;
