import about from './organization/about'

const organization =
{
    // dependencies

    about,

    // settings

    helmet :
    {
        title : 'Organisation - {0}' ,
        metas : []
    },
    loading  : "Chargement ..." ,
    notFound : "Organization inconnue" ,
    tabs     :
    {
        about       : 'À propos' ,
        authorities : 'Autorités' ,
        info        : 'Infos' ,
        medias      : 'Médias'
    },
    tooltips :
    {
        back : 'Toutes les organisations'
    }
};

export default organization ;
