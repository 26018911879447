import React, { Component } from 'react'

import PropTypes from 'prop-types'

import isString from 'vegas-js-core/src/isString'

import initApp from '../../../contexts/app/initApp'

import LabelItem from '../../../components/items/LabelItem'

class Nationality extends Component
{
    getLocale = () => this.props.locale.things.person.nationality ;

    render()
    {
        const {
            lang,
            language,
            thing
        } = this.props ;

        const locale = this.getLocale() ;
        if( thing )
        {
            const { nationality } = thing;

            if ( nationality )
            {
                let label;
                let icon;
                if (isString(nationality) && nationality !== '')
                {
                    label = nationality;
                }
                else if ( language && language.id && nationality.hasOwnProperty(language.id))
                {
                    label = nationality[language.id];
                    icon  = language[language.id].icon;
                }
                else if (nationality.hasOwnProperty(lang))
                {
                    label = nationality[lang];
                    icon  = language[lang].icon
                }

                if (isString(label) && (label !== ''))
                {
                    return (
                        <LabelItem title={locale.title}>
                            {label}
                            {icon}
                        </LabelItem>
                    );
                }
            }
            else
            {
                return (
                    <LabelItem title={locale.title} enable={true}>{locale.empty}</LabelItem>
                );
            }
        }
        return null ;
    }


}

Nationality.defaultProps =
{
    language : null ,
    thing    : null
};

Nationality.propTypes =
{
    language : PropTypes.array,
    locale   : PropTypes.object,
    thing    : PropTypes.object
};

export default initApp( Nationality ) ;
