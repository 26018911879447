const word =
{
    copy :
    {
        snack   : 'Mot-clé copié dans le presse-papiers.' ,
        tooltip : 'Copier le mot clé'
    },
    label : '{0}',
    title : null
};

export default word ;
