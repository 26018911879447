import React from 'react'

import Flag from "react-flags"

const flag =
{
    basePath : './assets/flags' ,
    format   : "png" ,
    pngSize  : 32
};

export const languages =
[
    { id : 'fr' , name : "Français" , icon : <Flag name="FR" {...flag} alt="Français" /> } ,
    { id : 'en' , name : "Anglais"  , icon : <Flag name='GB' {...flag} alt="Anglais"  /> } ,
    { id : 'it' , name : "Italien"  , icon : <Flag name='IT' {...flag} alt="Italien"  /> } ,
    { id : 'es' , name : "Espagnol" , icon : <Flag name='ES' {...flag} alt="Espagnol" /> } ,
    { id : 'de' , name : "Allemand" , icon : <Flag name='DE' {...flag} alt="Allemand" /> }
];

const editor =
{
    languages,
    title    : 'Texte' ,
    tooltips :
    {
        refresh : 'Annuler' ,
        save    : 'Enregister'
    }
};

export default editor ;