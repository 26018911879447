import React, {Component} from 'react'

import clsx from 'clsx'
import PropTypes  from 'prop-types'

import withConfig from '../../contexts/config/withConfig'
import withi18n   from '../../contexts/i18n/withi18n'

import { withStyles } from '@material-ui/core/styles'

import isString from 'vegas-js-core/src/isString'
import format   from 'vegas-js-core/src/strings/fastformat'
import Signal   from 'vegas-js-signals/src/Signal'

import grey from '@material-ui/core/colors/grey'

import { FiMap as MapIcon } from 'react-icons/fi'

import GeoCoordinates from '../../things/GeoCoordinates'
import Place          from '../../things/Place'

import providers from '../../configs/providers'

import markerIcon from '../../assets/map/marker.svg'

// --------------- LEAFLET

import L from 'leaflet'

import 'leaflet-center-cross'
import 'leaflet-control-geocoder'
import 'leaflet-easybutton'
import 'leaflet.fullscreen'
import 'leaflet.locatecontrol'
import 'leaflet.markercluster'
import 'leaflet-providers'
import 'leaflet.visualclick'

import marker       from 'leaflet/dist/images/marker-icon.png'
import marker2x     from 'leaflet/dist/images/marker-icon-2x.png'
import markerShadow from 'leaflet/dist/images/marker-shadow.png'

const { Icon } = L ;

delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
    iconRetinaUrl : marker2x,
    iconUrl       : marker,
    shadowUrl     : markerShadow
});

// ---------------

const defaultLocateOptions =
{
    position  : 'bottomright',
    showPopup : false
};

const defaultSearchOptions =
{
    defaultMarkGeocode : true ,
    position           : 'bottomright' ,
    showResultIcons    : false
};

// ---------------

const styles = () =>
({
    root :
    {
        alignItems      : 'center',
        backgroundColor : grey[300] ,
        display         : 'flex',
        height          : 400 ,
        justifyContent  : 'center',
        width           : '100%' ,
        overflow        : 'hidden'
    }
});

/**
 * A Map container based on the Leaflet API.
 * @example
 * <Map
 *     className       = { classes.map }
 *     fly             = { true }
 *     fullscreenMode  = { true }
 *     gpsFixed        = { true }
 *     locale          = { locale.map }
 *     onReady         = { () => console.log( this + ' map ready') }
 *     onSelect        = { ( place ) => { history.push( this.getCurrentPath() + '/' + place.id  ) }}
 *     marker          = { false }
 *     markerDraggable = { false }
 *     markerMove      = { ( latitude , longitude ) => console.log( latitude , longitude ) }
 *     places          = { datas.result }
 * />
 */
class Map extends Component
{
    constructor( props )
    {
        super( props ) ;

        /**
         * The cluster reference.
         */
        this.cluster = null ;

        /**
         * The map reference.
         */
        this.map = null ;

        /**
         * @private
         */
        this.initInterval = 0 ;

        /**
         * @private
         */
        this.interval = 0 ;

        /**
         * Indicates if the map is initialized.
         * @type {boolean}
         */
        this.initialized = false ;

        /**
         * The marker reference.
         * @type {L.marker}
         */
        this.marker = null ;

        this.markerMove = new Signal() ;

        this.state =
        {
            ...this.state ,
            id        : this.props.id ,
            latitude  : this.props.latitude ,
            longitude : this.props.longitude ,
            zoom      : this.props.zoom
        } ;
    }

    addMarker = ( latitude , longitude ) =>
    {
        if( isNaN(latitude) || isNaN(longitude) )
        {
            return this ;
        }

        const { marker } = this.props ;
        if( marker )
        {
            if( this.map )
            {
                const { icon , marker } = L ;

                const {
                    markerAnchor,
                    markerDraggable,
                    markerSize,
                    markerUrl
                } = this.props ;

                if( this.marker )
                {
                    this.map.removeLayer( this.marker ) ;
                    this.marker = null ;
                }

                this.marker = marker(
                    [ latitude , longitude ] , {
                        draggable : markerDraggable ,
                        icon      : icon(
                        {
                            iconUrl    : markerUrl ,
                            iconSize   : markerSize ,
                            iconAnchor : markerAnchor
                        })
                    }
                ) ;

                if( markerDraggable )
                {
                    this.marker.on( 'drag' , this._moveMarker ) ;
                }

                this.marker.addTo( this.map );
            }
        }

        return this ;
    };

    addSpot = ( thing , index ) =>
    {
        const {
            getPlace,
            getSpotDivIcon,
            getTooltip,
            lang,
            tooltips
        } = this.props ;

        let place = (getPlace instanceof Function) ? getPlace(thing) : thing ;

        try
        {
            if( place instanceof Place )
            {
                const { geo } = place ;
                if( geo instanceof GeoCoordinates )
                {
                    const { latitude, longitude } = geo ;

                    if( !isNaN(latitude) && !isNaN(longitude) )
                    {
                        const {
                            additionalType,
                            name,
                            id
                        } = place ;

                        const { icon , marker } = L ;

                        let spotIcon ;

                        if( getSpotDivIcon instanceof Function )
                        {
                            const { divIcon } = L ;
                            spotIcon = getSpotDivIcon(thing, index);
                            if( spotIcon )
                            {
                                spotIcon = divIcon(spotIcon) ;
                            }
                        }

                        if( !spotIcon )
                        {
                            let {
                                spotAnchor,
                                spotOptions,
                                spotSize,
                                spotUrl
                            } = this.props ;

                            if( additionalType )
                            {
                                const { image, color, bgcolor } = additionalType ;
                                if( isString(image) )
                                {
                                    spotAnchor = [ 14 , 28 ] ;
                                    spotUrl    = format( spotOptions , image , color , bgcolor ) ;
                                }
                            }

                            spotIcon = icon({
                                iconUrl       : spotUrl ,
                                iconSize      : spotSize ,
                                iconAnchor    : spotAnchor,
                                tooltipAnchor : [ 14 , -14 ]
                            })
                        }

                        const spot = marker([ latitude, longitude ] , { icon : spotIcon } );

                        if( tooltips )
                        {
                            let tip ;

                            if( getTooltip instanceof Function )
                            {
                                tip = getTooltip( thing , index ) ;
                            }
                            else if( name )
                            {
                                tip = place.getLocaleName( lang ) ;
                            }

                            if( tip )
                            {
                                spot.bindTooltip( tip ).openTooltip() ;
                            }
                        }

                        spot.id = id ;
                        spot.on( 'click' , this.selectSpot( thing , index ) ) ;

                        return spot ;
                    }
                }
            }
        }
        catch( error )
        {
            console.warn( this + " addSpot failed, " + error.message ) ;
        }

        return null ;
    };

    dispose = () =>
    {
        clearTimeout( this.initInterval ) ;
        clearTimeout( this.interval     ) ;

        if( this.marker )
        {
            this.removeMarker() ;
            this.marker = null ;
        }

        if( this.cluster )
        {
            this.cluster.clearLayers();
            if( this.map )
            {
                this.map.removeLayer( this.cluster );
            }
        }

        if( this.map )
        {
            this.map = null ;
        }

        this.initialized = false ;

        return this ;
    };

    /**
     * Fly to the specific coordinates of the map (geographical center and zoom) with the given animation options.
     * @param center The position to center the map.
     * @param zoom The zoom value to apply on the map.
     * @param options The zoom pan options.
     */
    flyTo = ( center , zoom , options = null ) =>
    {
        if( this.map )
        {
            this.map.flyTo( center , zoom , options ) ;
        }
        return this ;
    };

    /**
     * Returns the geographical center of the map view.
     * @returns {LatLng} the geographical center of the map view.
     */
    getCenter = () =>
    {
        return this.map ? this.map.getCenter() : null ;
    };

    getLocale = () => this.props.locale.components.maps.map ;

    removeMarker = () =>
    {
        if( this.map && this.marker )
        {
            this.map.removeLayer( this.marker ) ;
            this.marker = null ;
        }
        return this ;
    };

    render()
    {
        const {
            classes,
            className,
            id,
            style
        } = this.props ;

        return (
            <div
                id        = { id }
                className = { clsx( classes.root , className ) }
                style     = { style }
            >
                { !this.initialized && <MapIcon style={{ color:grey[500], fontSize:48 }}/> }
            </div>
        ) ;
    }

    selectSpot = ( thing , index ) => () =>
    {
        const { onSelect } = this.props ;
        if( onSelect instanceof Function )
        {
            onSelect( thing , index ) ;
        }
    };

    /**
     * Sets the view of the map (geographical center and zoom) with the given animation options.
     * @param center The position to center the map.
     * @param zoom The zoom value to apply on the map.
     * @param options The zoom pan options.
     */
    setView = ( center , zoom , options = null ) =>
    {
        if( this.map )
        {
            this.map.setView( center , zoom , options ) ;
        }
        return this ;
    };

    /**
     * Sets the zoom of the map.
     * @param zoom The zoom value to apply on the map.
     * @param options The zoom pan options.
     */
    setZoom = ( zoom , options = null ) =>
    {
        if( this.map )
        {
            this.map.setZoom( zoom , options ) ;
        }
        return this ;
    };

    // ----- protected

    componentDidMount()
    {
        this.initInterval = setTimeout( this.initialize , 150 ) ;
    }

    componentDidCatch(error, errorInfo)
    {
        console.log( this + ' componentDidCatch' , error, errorInfo );
    }

    compare = ( item1 , item2 ) => item1 !== item2  ;

    componentDidUpdate( prevProps )
    {
        let {
            compare,
            latitude,
            longitude,
            places,
            timeout,
            zoom
        } = this.props ;

        compare = compare || this.compare ;
        if( compare( places , prevProps.places ) )
        {
            this.populate() ;
        }
        else if( (prevProps.latitude !== latitude) || (prevProps.longitude !== longitude) )
        {
            clearTimeout( this.interval ) ;

            if( isNaN(latitude) || isNaN(longitude) )
            {
                this.removeMarker();
                return ;
            }

            this.setState( { latitude, longitude});

            const position = [ latitude , longitude ] ;
            if( this.marker )
            {
                this.marker.setLatLng( position ) ;
                this.interval = setTimeout( this.flyTo, timeout, position, zoom );
            }
            else
            {
                this.addMarker(latitude,longitude);
                this.flyTo( position, zoom );
            }
        }
    }

    componentWillUnmount()
    {
        this.dispose() ;
    }

    initialize = () =>
    {
        if( this.initialized )
        {
            return ;
        }

        const {
            latitude ,
            longitude ,
            zoom
        } = this.state ;

        const {
            fullscreenControl,
            fullscreenMode,
            gpsFixed,
            id,
            locatable,
            locateOptions,
            marker,
            maxZoom,
            minZoom,
            onInit,
            onReady,
            provider,
            scrollWheelZoom ,
            searchable,
            searchOptions,
            zoomControl
        } = this.props ;

        const locale = this.getLocale() ;

        const {
            control,
            Control,
            easyButton ,
            map ,
            tileLayer
        } = L ;

        this.map = map( id ,
        {
            center            : [ latitude , longitude ] ,
            fullscreenControl : fullscreenMode ? fullscreenControl : null ,
            visualClickEvents : 'click contextmenu' ,
            zoom              : zoom,
            zoomControl       : zoomControl
        } ) ;

        const center = control.centerCross();

        this.map.addControl(center);

        switch( scrollWheelZoom )
        {
            case 'always' :
            {
                this.map.scrollWheelZoom.enabled() ;
                break ;
            }

            case 'auto' :
            {
                this.map.scrollWheelZoom.disable() ;
                this.map.on( 'click' , () =>
                {
                    if (this.map.scrollWheelZoom.enabled())
                    {
                        this.map.scrollWheelZoom.disable();
                    }
                    else
                    {
                        this.map.scrollWheelZoom.enable();
                    }
                });
                break ;
            }

            default :
            {
                this.map.scrollWheelZoom.disable() ;
            }
        }

        if( onReady )
        {
            this.map.whenReady(onReady);
        }

        this.map.setMaxZoom( maxZoom ) ;
        this.map.setMinZoom( minZoom ) ;

        if( provider )
        {
            tileLayer.provider(provider).addTo(this.map) ;
        }

        if( searchable && Control )
        {
            const { search:searchLocale } = locale ;

            const options = {
                ...searchLocale,
                ...defaultSearchOptions,
                ...searchOptions
            };

            const { defaultMarkGeocode = false } = options ;

            Control
            .geocoder( options )
            .on( 'markgeocode' , event =>
            {
                if( event )
                {
                    const { geocode } = event ;
                    if( geocode )
                    {
                        const { bbox, center } = geocode ;
                        if( bbox && center )
                        {
                            const { lat:latitude, lng:longitude } = center ;

                            if( !defaultMarkGeocode )
                            {
                                this.map.fitBounds(bbox);
                            }

                            const { markerMove } = this.props ;
                            if( markerMove instanceof Function )
                            {
                                markerMove( latitude , longitude , this.map , this ) ;
                            }
                        }
                    }
                }
            })
            .addTo( this.map ) ;
        }

        if( locatable && Control )
        {
            const { locate:locateLocale } = locale ;

            const options = {
                 ...defaultLocateOptions,
                 ...locateLocale,
                 ...locateOptions
            };

            control.locate( options ).addTo( this.map );

            this.map.on( 'locationfound', event =>
            {
                if( event )
                {
                    const { latlng : { lat:latitude, lng:longitude } = {} } = event || {} ;
                    const { markerMove } = this.props ;
                    if( markerMove instanceof Function )
                    {
                        markerMove( latitude , longitude , this.map , this ) ;
                    }
                }
            });
        }

        if( gpsFixed )
        {
            easyButton( 'fa-redo-alt' , this._fixPosition ).addTo( this.map );
        }

        if( marker )
        {
            const geo = this.getCenter() ;
            this.addMarker( geo.lat , geo.lng );
        }

        this.map.attributionControl._attributions = {};
        this.map.attributionControl.setPrefix( false ) ;
        this.map.attributionControl.addAttribution( locale.attribution ) ;
        this.map.attributionControl.addAttribution( provider ) ;

        this.populate() ;

        this.initialized = true ;

        if( onInit )
        {
            onInit( this.map , this ) ;
        }
    };

    populate = () =>
    {
        if( this.map )
        {
            let {
                clusterSettings ,
                places
            } = this.props;

            if( this.cluster )
            {
                this.cluster.clearLayers();
                this.map.removeLayer( this.cluster ) ;
                this.cluster = null ;
            }

            if ( (places instanceof Array) && (places.length > 0) )
            {
                const { MarkerClusterGroup } = L ;

                this.cluster = new MarkerClusterGroup( clusterSettings );

                places.forEach( ( place , index ) =>
                {
                    const spot = this.addSpot( place, index ) ;
                    if( spot )
                    {
                        this.cluster.addLayer( spot )
                    }
                }) ;

                // check if there is at least one layer in cluster
                if( this.cluster.getLayers().length > 0 )
                {
                    this.map.addLayer( this.cluster ) ;
                    this.map.fitBounds( this.cluster.getBounds().pad( 0.1 ) ) ;

                    const {
                        lat:latitude,
                        lng:longitude
                    }
                    = this.cluster.getBounds().getCenter() ;

                    const zoom = this.map.getBoundsZoom( this.cluster.getBounds().pad(0.1) ) ;

                    this.setState({ latitude, longitude, zoom });

                    this.addMarker(latitude,longitude) ;

                    if( this.props.fly )
                    {
                        this.flyTo( [ latitude , longitude ] , zoom ) ;
                    }
                    else
                    {
                        this.setView( [ latitude , longitude ] , zoom ) ;
                    }
                }

            }
        }
    };

    // ----- private

    _fixPosition = () =>
    {
        const { latitude , longitude , zoom } = this.state ;
        if( this.props.fly )
        {
            this.flyTo( [ latitude , longitude ] , zoom ) ;
        }
        else
        {
            this.setView( [ latitude , longitude ] , zoom ) ;
        }
        this.addMarker(latitude,longitude) ;
    };

    _moveMarker = () =>
    {
        if( this.marker )
        {
            const { markerMove } = this.props ;

            const geo       = this.marker.getLatLng();
            const latitude  = geo.lat ;
            const longitude = geo.lng ;

            if( markerMove instanceof Function )
            {
                markerMove( latitude , longitude , this.map , this ) ;
            }

            this.setState( { latitude , longitude } ) ;
            this.markerMove.emit( latitude , longitude , this.map , this ) ;
        }
    };
}

Map.defaultProps =
{
    className       : null,
    clusterSettings :
    {
        maxClusterRadius           : 50,
        disableClusteringAtZoom    : 20,
        spiderfyDistanceMultiplier : 2
    },
    compare           : null ,
    fly               : false ,
    fullscreenControl :
    {
        position : 'topright' , pseudoFullscreen: true
    } ,
    fullscreenMode    : true ,
    getPlace          : null ,
    getSpotDivIcon    : null ,
    getTooltip        : null ,
    gpsFixed          : true ,
    id                : 'map' ,
    latitude          : -21.115141,
    locatable         : true ,
    locateOptions     : null,
    longitude         : 55.536384,
    onInit            : null ,
    onReady           : null ,
    onSelect          : null ,
    places            : null ,
    marker            : true,
    markerAnchor      : [ 16 , 38 ] ,
    markerDraggable   : false ,
    makerMove         : null ,
    markerSize        : [ 32 , 38 ] ,
    markerUrl         : markerIcon ,
    maxZoom           : 19 ,
    minZoom           : 1,
    provider          : 'OpenStreetMap',
    searchable        : true ,
    searchOptions     : { ...defaultSearchOptions },
    scrollWheelZoom   : 'auto' ,
    spotAnchor        : [ 16 , 38 ] ,
    spotOptions       : "{0}?render=map&color={1}&bgcolor={2}&margin=5&shadow=true" ,
    spotSize          : [ 32 , 38 ] ,
    spotUrl           : markerIcon ,
    style             : null ,
    timeout           : 200,
    tooltips          : true,
    zoom              : 12,
    zoomControl       : true
};

Map.propTypes =
{
    className         : PropTypes.string,
    classes           : PropTypes.object.isRequired ,
    clusterSettings   : PropTypes.object ,
    compare           : PropTypes.func,
    fly               : PropTypes.bool,
    fullscreenControl : PropTypes.object ,
    fullscreenMode    : PropTypes.bool ,
    getPlace          : PropTypes.func,
    getSpotDivIcon    : PropTypes.func,
    getTooltip        : PropTypes.func,
    gpsFixed          : PropTypes.bool,
    id                : PropTypes.string ,
    locale            : PropTypes.object ,
    locatable         : PropTypes.bool ,
    latitude          : PropTypes.number ,
    longitude         : PropTypes.number ,
    onInit            : PropTypes.func ,
    onReady           : PropTypes.func ,
    onSelect          : PropTypes.func ,
    places            : PropTypes.array,
    provider          : PropTypes.oneOf( providers ) ,
    marker            : PropTypes.bool,
    markerAnchor      : PropTypes.array ,
    markerDraggable   : PropTypes.bool,
    markerMove        : PropTypes.func,
    markerSize        : PropTypes.array ,
    markerUrl         : PropTypes.string,
    maxZoom           : PropTypes.number ,
    minZoom           : PropTypes.number ,
    searchable        : PropTypes.bool ,
    searchOptions     : PropTypes.object,
    scrollWheelZoom   : PropTypes.oneOf(['auto','always','none']),
    spotAnchor        : PropTypes.arrayOf(PropTypes.number),
    spotOptions       : PropTypes.string ,
    spotSize          : PropTypes.arrayOf(PropTypes.number),
    spotUrl           : PropTypes.string ,
    style             : PropTypes.object,
    timeout           : PropTypes.number,
    tooltips          : PropTypes.bool,
    zoom              : PropTypes.number ,
    zoomControl       : PropTypes.bool
};

export default withStyles( styles )( withConfig( withi18n(Map) ) ) ;
