const about =
{
    alternateName :
    {
        caption  : 'Titre principal du lieu.',
        title    : 'Titre' ,
        tooltips :
        {
            refresh : 'Annuler' ,
            save    : 'Enregister'
        }
    },
    alternativeHeadline :
    {
        caption  : 'Le sous-titre de cet article.',
        title    : 'Sous-titre' ,
        tooltips :
        {
            refresh : 'Annuler' ,
            save    : 'Enregister'
        }
    },
    description :
    {
        caption  : 'Une description courte de ce lieu.',
        title    : 'Description' ,
        tooltips :
        {
            refresh : 'Annuler' ,
            save    : 'Enregister'
        }
    },
    headline :
    {
        caption  : 'Le titre de cet article.',
        title    : 'Titre' ,
        tooltips :
        {
            refresh : 'Annuler' ,
            save    : 'Enregister'
        }
    },
    notes :
    {
        caption  : 'Anectdotes, précautions, remarques, etc. ',
        title    : 'Notes' ,
        tooltips :
        {
            refresh : 'Annuler' ,
            save    : 'Enregister'
        }
    },
    slogan :
    {
        caption  : 'Slogan du lieu.',
        title    : 'Slogan' ,
        tooltips :
        {
            refresh : 'Annuler' ,
            save    : 'Enregister'
        }
    },
    text :
    {
        caption  : 'Texte principal de ce lieu.',
        title    : 'Texte' ,
        tooltips :
        {
            refresh : 'Annuler' ,
            save    : 'Enregister'
        }
    }
};

export default about ;
