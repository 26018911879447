import React , { Component } from 'react'

import LoadingContext from './LoadingContext'

import defaultProperties from './defaultProperties'

class LoadingProvider extends Component
{
    constructor( props )
    {
        super(props);
        this.state =
        {
            ...defaultProperties,
            setLoading : this.set
        };
    }
    
    render()
    {
        const { children } = this.props;
        return (
            <LoadingContext.Provider value={ this.state }>
                {children}
            </LoadingContext.Provider>
        )
    }
    
    set = ( loading = false ) => { this.setState({ loading }); };
}

export default LoadingProvider ;