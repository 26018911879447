import React from 'react'

import { Typography } from '@material-ui/core'

import Form from '../../../display/forms/FormElements'

import format  from 'vegas-js-core/src/strings/fastformat'
import ucFirst from 'vegas-js-core/src/strings/ucFirst'

import api from '../../../configs/api'

import NumberValue from '../../../things/NumberValue'
import Thing       from '../../../things/Thing'
import Workplace   from '../../../things/Workplace'

import sectors from './sectors'

const elements =
[
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'name',
            key          : 'name',
            label        : 'Nom de l\'espace de travail',
            autoFocus    : true,
            fullWidth    : true,
            helperText   : 'Obligatoire',
            margin       : 'normal',
            required     : true,
            select       : false,
            autoComplete : 'off' ,
            type         : 'search',
            variant      : 'outlined'
        }
    },
    {
        type       : Form.SEARCH_SELECTOR ,
        clazz      : NumberValue,
        clearLabel : 'Vider' ,
        closeLabel : 'Fermer' ,
        init       :
        {
            id         : 'identifier',
            key        : 'identifier',
            label      : 'Identifiant de l\'espace de travail',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            required   : true,
            type       : 'search'
        },
        inputProps :
        {
            label : 'Identifiant...'
        },
        logics :
        {
            disabledSuggestions : ( props ) =>
            {
                const { rel } = props ;
                if( rel instanceof Workplace )
                {
                    const { identifier } = rel ;
                    if( identifier instanceof NumberValue )
                    {
                        return [ identifier ] ;
                    }
                }
                return null ;
            }
        },
        path : ( props ) =>
        {
            const { rel } = props ;
            if( rel instanceof Workplace )
            {
                let { subjectOf:workshop } = rel ;
                if( workshop instanceof Thing )
                {
                    let { subjectOf:livestock } = workshop ;
                    if( livestock instanceof Thing )
                    {
                        return format( api.livestocks.numbers , livestock.id ) ;
                    }
                }
            }
            return null ;
        },
        suggestionLabel : ( suggestion , props ) =>
        {
            const { lang } = props ;
            const name = (suggestion instanceof Thing ? ucFirst(suggestion.getLocaleName(lang)) : "") ;
            if( suggestion instanceof NumberValue )
            {
                const { additionalType, value } = suggestion ;

                let type ;
                if( additionalType instanceof Thing )
                {
                    type = additionalType.getLocaleName(lang) ;
                }

                return (
                    <div className='flex flex-row items-center my-4'>
                        <div className='flex flex-row items-center'>
                            <Typography className="mr-8" variant='body1'>{ name }</Typography>
                        </div>
                        <div className='flex flex-row items-center'>
                            { type && <Typography className="mr-8" variant='caption'>{type}</Typography> }
                            <Typography className="mr-8" variant='caption'>›</Typography>
                            <Typography className="mr-8" variant='caption'>{value}</Typography>
                        </div>
                    </div>) ;
            }
            return name ;
        },
        valueLabel : ( thing , props ) =>
        {
            const { lang } = props ;
            let name = (thing instanceof Thing ? ucFirst(thing.getLocaleName(lang)) : "") ;
            if( thing instanceof NumberValue )
            {
                if( name !== "" )
                {
                    name += ' › ';
                }
                if( thing.additionalType && thing.additionalType instanceof Thing )
                {
                    name += thing.additionalType.getLocaleName(lang) ;
                    name += ' › ' ;
                }
                name += thing.value ;
            }
            return name ;
        }
    }
];

const workplaces =
{
    // dependencies

    sectors,

    // setting

    add :
    {
        elements,
        agree       : 'Créer' ,
        disagree    : 'Annuler' ,
        title       : 'Ajouter un espace de travail' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour créer un espace.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en  cours...' ,
        success     : 'Ajout du nouvel espace réussi!'
    },
    edit  :
    {
        elements,
        agree       : 'Modifier' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour modifier cet espace.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...' ,
        success     : 'Mise à jour réussie!' ,
        title       : 'Modifier l\'espace de travail' ,
        useUrl      : true
    },
    remove :
    {
        agree       : 'Supprimer' ,
        description : 'Souhaitez-vous supprimer cet espace de travail:' ,
        disagree    : 'Annuler' ,
        loading     : 'Suppression en cours...' ,
        title       : 'Supprimer l\'espace de travail ?',
        useUrl      : true
    },
    title : 'Espaces de travail' ,
    tooltips :
    {
        add    : "Ajouter un espace" ,
        edit   : "Modifier" ,
        remove : "Supprimer"
    }
};

export default workplaces ;
