const mediaObjects =
{
    helmet :
    {
        title : 'Médiathèque' ,
        metas :
        [
            { charSet : 'utf-8'} ,
            { name    : 'description' , content : 'Bibliothèque de médias de la plateforme.' } ,
            { name    : 'keywords'    , content : 'gallery,medias,photos,videos,images'      }
        ]
    },
    title : 'Médiathèque',
};

export default mediaObjects ;
