import React from 'react'

import isString from 'vegas-js-core/src/isString'

import events    from './abortion/events'
import medical   from './abortion/medical'
import workplace from './abortion/workplace'

import Form         from '../../../../../display/forms/FormElements'
import Method       from '../../../../../net/Method'
import NumberInput  from '../../../../../components/inputs/NumberInput'
import Veterinarian from '../../../../../things/Veterinarian'

import getLocaleThingLabel from '../../../../../things/getLocaleThingLabel'

const elements =
[
    {
        type         : Form.DATE ,
        defaultValue : null ,
        format       : 'L' ,
        init         :
        {
            id            : 'date',
            key           : 'date',
            autoOk        : true ,
            cancelLabel   : 'Annuler' ,
            clearable     : true ,
            clearLabel    : 'Vider' ,
            invalidLabel  : '-',
            inputVariant  : 'outlined' ,
            disableFuture : false ,
            disablePast   : false ,
            fullWidth     : true ,
            label         : 'Date de prélèvement' ,
            required      : false,
            okLabel       : 'OK',
            variant       : 'dialog'
        }
    },
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'distance',
            key          : 'distance',
            label        : 'Distance A/R (Km)',
            autoFocus    : false,
            fullWidth    : true,
            InputProps   : {
                inputComponent : ( input ) => (
                    <NumberInput
                        allowNegative = { false }
                        floatCount    = { 3 }
                        {...input}
                    />
                )
            } ,
            margin       : 'normal',
            select       : false,
            autoComplete : 'off' ,
            type         : 'text',
            variant      : 'outlined'
        }
    }
];

const sort = ( lang ) => ( a , b ) =>
{
    if( a instanceof Veterinarian && b instanceof Veterinarian )
    {
        const { authority:a1 } = a ;
        const { authority:a2 } = b ;
        if( a1 && a2 )
        {
            let label1 = getLocaleThingLabel( a1 , lang ) ;
            let label2 = getLocaleThingLabel( a2 , lang ) ;
            if( isString(label1) && isString(label2) )
            {
                return label1.localeCompare(label2, lang) ;
            }
        }
    }
    return 0 ;
};

const thingRef = ( thing ) =>
{
    if( thing instanceof Veterinarian )
    {
        const { authority } = thing ;
        if( authority )
        {
            return authority;
        }
    }
    return thing ;
};

const abortion =
{
    // dependencies

    events,
    medical,
    workplace,

    // settings

    datePattern : 'L',
    edit :
    {
        agree       : 'Sauver' ,
        disagree    : 'Annuler' ,
        fail        : 'Veuillez corriger les erreurs' ,
        loading     : 'Chargement en cours...' ,
        method      : Method.PATCH,
        mock        : false ,
        success     : 'Mise à jour réussie!' ,
        title       : 'Rapport d\'avortement' ,
        elements
    },
    items :
    {
        date     : 'Date de prélèvement',
        distance :
        {
            label : '{0} km',
            title : 'Nombre de kilomètres parcourus (A/R)'
        },
        empty : '----'
    },
    suggestions :
    {
        sort,
        thingRef,
        agree    : 'Sélectionner' ,
        disagree : 'Annuler' ,
        title    : 'Sélectionner un vétérinaire'
    },
    title    : 'Déclaration avortement simple' ,
    tooltips :
    {
        edit  : 'Modifier',
        print : 'Imprimer'
    }
};

export default abortion ;
