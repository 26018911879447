import React from 'react'

import compose  from 'vegas-js-core/src/functors/compose'
import isString from 'vegas-js-core/src/isString'

import initApp from '../../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import GestureIcon from '@material-ui/icons/Gesture'

import ThingChildren , { styles } from '../ThingChildren'

import Mark from './Mark'

import MarkVO from '../../../things/Mark'
import Thing  from '../../../things/Thing'

class Marks extends ThingChildren
{
    createChild = ( child , editable = false ) =>
    {
        if( child )
        {
            const { lang, locale } = this.props ;
            return (
            <Mark
                editable = { editable }
                lang     = { lang }
                locale   = { locale }
                onClick  = { () => this.openEditDialog(child) }
                thing    = { child }
            />)
        }
        return null ;
    };

    getLocale = () => this.props.locale.things.conceptualObject.marks ;

    prepare = item =>
    {
        let {
            additionalType,
            name,
            description,
            language,
            position,
            technique,
            text
        } = item ;

        const { config : { languages } = {} } = this.props ;

        if( additionalType instanceof Thing )
        {
            additionalType = String(additionalType.id)
        }
        else
        {
            additionalType = "" ;
        }

        if( position )
        {
            languages.forEach( lang => {
                if( !isString(position[lang]) )
                {
                    position[lang] = '' ;
                }
            })
        }

        if( description )
        {
            languages.forEach( lang => {
                if( !isString(description[lang]) )
                {
                    description[lang] = '' ;
                }
            })
        }

        if( language instanceof Thing )
        {
            language = String(language.id);
        }
        else
        {
            language = "" ;
        }

        if( technique instanceof Thing )
        {
            technique = String(technique.id);
        }
        else
        {
            technique = "" ;
        }

        if( text )
        {
            languages.forEach( lang => {
                if( !isString(text[lang]) )
                {
                    text[lang] = '' ;
                }
            })
        }

        return (
        {
            name ,
            additionalType,
            description ,
            language,
            position,
            technique,
            text
        });
    };
}

Marks.defaultProps =
{
    ...ThingChildren.defaultProps ,
    clazz     : MarkVO,
    emptyIcon : null,
    icon      : <GestureIcon/>,
    member    : 'marks'
};

Marks.propTypes =
{
    ...ThingChildren.propTypes
};

export default compose( withStyles( styles ) , withRouter , initApp )( Marks ) ;
