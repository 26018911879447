import auth from "../../configs/auth"

import getForm from "../getForm"

import { v4 as uuid } from 'uuid'

const name = process.env.REACT_APP_NAME ;

/**
 * Connect the application with the api.
 * @param apiName {Object}
 * @param method {string}
 */
export default function connect( apiName = 'api' , method = 'post' )
{
    const api = auth.apis[apiName] ;

    if( !api )
    {
        console.log( 'api definition not found for this url!' );
        return  ;
    }

    const state = uuid() ;

    localStorage.setItem( name + '_' + api.name +  '_csrf' , state ) ;

    const values =
    {
        client_id     : api.client_id,
        redirect_uri  : auth.redirect_uri ,
        response_type : 'code' ,
        scope         : api.scope ,
        state         : state
    };

    const form = getForm( api.auth_url , values , method , '_self' ) ;

    document.body.appendChild(form);

    form.submit();
    form.parentNode.removeChild(form);
}
