import React from 'react'

import View from '../../display/views/View'

import UsersList from '../../display/lists/UsersList'

import initView from '../../display/views/initView'

const styles = () => ({}) ;

class Users extends View
{
    build = () =>
    {
        return (
        <div className='flex flex-col w-full'>
            <UsersList parent = { this.element } />
        </div>);
    }
    
    getLocale = () => this.props.locale.views.users ;
}

Users.defaultProps =
{
    ...View.defaultProps
};

Users.propTypes =
{
    ...View.propTypes
};

export default initView( Users, styles );
