import React from "react"

import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import PersonIcon from '@material-ui/icons/Person'
import InfoIcon from '@material-ui/icons/Info'
import MediasIcon from '@material-ui/icons/PermMedia'

import ThingView , { defaultStyle } from '../../display/views/ThingView'

import About  from './views/About'
import Info   from './views/Info'
import Medias from '../medias/Medias'

import api   from '../../configs/api'
import paths from '../../configs/paths'

import PersonVO from '../../things/Person'
import Thing    from '../../things/Thing'

import initView from '../../display/views/initView'

const styles = defaultStyle();

class Person extends ThingView
{
    getLocale = () => this.props.locale.views.person ;

    getTitle = thing =>
    {
        const { lang } = this.props ;
        if( thing instanceof PersonVO )
        {
            return ucFirst(thing.getFullNameOrUsername(lang))  ;
        }
        else if( thing instanceof Thing )
        {
            return ucFirst(thing.getLocaleName(lang))  ;
        }
        return null ;
    };
}

Person.defaultProps =
{
    ...ThingView.defaultProps,
    back       : true,
    clazz      : PersonVO,
    icon       : <PersonIcon/>,
    path       : api.people,
    responsive : true ,
    preferable : true ,
    routes     :
    [
        { id : 'about'  , RouteComponent : About  } ,
        { id : 'info'   , RouteComponent : Info   } ,
        { id : 'medias' , RouteComponent : Medias }
    ],
    tabs :
    [
        { id : 'about'  , icon : <PersonIcon className='pr-8' /> } ,
        { id : 'info'   , icon : <InfoIcon   className='pr-8' /> } ,
        { id : 'medias' , icon : <MediasIcon className='pr-8' /> }
    ],
    titleAuto : false
};

Person.propTypes =
{
    ...ThingView.propTypes,
    path : paths.people
};

export default initView( Person, styles );
