import React from 'react'

import CallToActionIcon from '@material-ui/icons/CallToAction'
import LineWeightIcon   from '@material-ui/icons/LineWeight'

import withDataTable  from './withDataTable'

import { ThingTable } from './ThingTable'

import Activity     from '../../vo/Activity'
import ActivityCell from '../cells/ActivityCell'

import styles from './styles'

class ActivitiesTable extends ThingTable
{
    getLocale = () => this.props.locale.display.tables.activities ;
}

ActivitiesTable.defaultProps =
{
    ...ThingTable.defaultProps,
    addable       : false ,
    activable     : false,
    CellComponent : ActivityCell ,
    clazz         : Activity,
    defaultIcon   : <CallToActionIcon fontSize='small'/>,
    deletable     : false,
    icon          : <LineWeightIcon />,
    queries       : { sort : '-created' },
    removable     : false ,
    searchPolicy  : 'none' ,
    selectPolicy  : 'none' ,
    sortable      : false
};

ActivitiesTable.propTypes =
{
    ...ThingTable.propTypes
};

export default withDataTable({ styles })( ActivitiesTable ) ;