import React from "react"

import GameIcon  from '@material-ui/icons/Casino'
import QuestIcon from '@material-ui/icons/PlaylistPlay'

import ThingView , { defaultStyle } from '../../display/views/ThingView'

import About from './views/About'
import Quest from './views/Quest'

import api   from '../../configs/api'
import paths from '../../configs/paths'

import ApplicationGame from '../../things/game/ApplicationGame'
import CourseGame      from '../../things/game/CourseGame'
import QuestionGame    from '../../things/game/QuestionGame'

import GameVO from '../../things/Game'

import initView from '../../display/views/initView'

const styles = defaultStyle();

class Game extends ThingView
{
    getLocale = () => this.props.locale.views.game ;

    getPath = () =>
    {
        const { match } = this.props ;

        if( match.path.includes( 'courses' ) )
        {
            return api.games.courses ;
        }
        else if( match.path.includes( 'questions' )  )
        {
            return api.games.questions ;
        }
        else
        {
            return api.games ;
        }
    };

    onBack = () =>
    {
        const { subjectOf } = this.state ;
        const { history } = this.props ;

        if( history )
        {
            if( subjectOf )
            {
                const { url } = subjectOf ;
                if( url )
                {
                    history.push( url.split(api.url)[1] + "/quest" ) ;
                    return ;
                }
            }

            const { url } = this.getPath() ;
            if( url )
            {
                const { backPath } = this.props ;
                history.push( url + (backPath || '') )
            }
        }
    };

    populateGame = ( item , subjectOf = null ) =>
    {
        const { clazz } = this.props ;
        if( clazz && item && !(item instanceof clazz ) )
        {
            let CLAZZ ;
            const { additionalType } = item ;
            if( additionalType )
            {
                const { alternateName } = additionalType ;
                switch( alternateName )
                {
                    case 'application' :
                    {
                        CLAZZ = ApplicationGame ;
                        break ;
                    }
                    case 'course':
                    {
                        CLAZZ = CourseGame ;
                        break ;
                    }
                    case 'question' :
                    {
                        CLAZZ = QuestionGame ;
                        break ;
                    }
                    default :
                    {
                        console.log( this + ' populate game failed, the type is not valid.' ) ;
                        return null ;
                    }
                }
            }

            if( CLAZZ instanceof Function )
            {
                let game = new CLAZZ( item ) ;
                if( subjectOf )
                {
                    game.subjectOf = subjectOf ;
                }
                return game ;
            }

        }

        return item ;
    };

    populate = data =>
    {
        if( data )
        {
            const { clazz } = this.props ;
            const { result } = data ;
            if( clazz && result && !(result instanceof clazz ) )
            {
                const { object } = data ;
                data.result = this.populateGame( result , object ) ;
            }
        }
        return data ;
    };
}

Game.defaultProps =
{
    ...ThingView.defaultProps,
    back       : true ,
    clazz      : GameVO ,
    icon       : <GameIcon/>,
    path       : api.games,
    responsive : true ,
    routes     :
    [
        { id : 'about' , RouteComponent : About },
        { id : 'quest' , RouteComponent : Quest }
    ],
    tabs :
    [
        { id : 'about' , icon : <GameIcon className='pr-8'  /> } ,
        { id : 'quest' , icon : <QuestIcon className='pr-8' /> }
    ],
    titleAuto : false
};

Game.propTypes =
{
    ...ThingView.propTypes,
    path : paths.games
};

export default initView( Game, styles );
