import list from './list'

const videos =
{
    ...list,
    actions :
    {
        embed  : 'Ajouter un fichier vidéo externe',
        sort   : 'Trier les fichiers vidéos' ,
        upload : 'Téléverser un fichier vidéo'
    },
    helmet :
    {
        title : 'Vidéos' ,
        metas : []
    },
    empty  : 'Aucun fichier vidéo trouvé',
    search : 'Rechercher un fichier vidéo ...' ,
    title  : 'Vidéos'
};

export default videos ;
