const map =
{
    attribution : process.env.REACT_APP_MAP_ATTRIBUTION ,
    locate      :
    {
        strings :
        {
            feetUnit            : 'feet',
            metersUnit          : 'mètres',
            outsideMapBoundsMsg : 'Vous semblez être situé en dehors des limites de la carte.',
            popup               : 'Vous êtes à moins de  {distance} {unit} de ce point.',
            title               : 'Ma Position'
        }
    },
    search :
    {
        placeholder  : 'Rechercher',
        errorMessage : 'Aucun résultat'
    }
};

export default map ;