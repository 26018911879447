import table from './table'

const set =
{
    ...table,
    helmet :
    {
        title : 'Configurations' ,
        metas : []
    },
    edit  :
    {
        agree       : 'Enregistrer' ,
        description : 'Sélectionner des éléments dans la liste ci-dessous.',
        disagree    : 'Annuler' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...',
        mock        : false ,
        refresh     : 'Charger',
        success     : 'Mise à jour réussie!' ,
        title       : 'Sélectionner' ,
        type        : 'thesaurus_checker'
    },
    empty    : 'Aucune configutation trouvée',
    tooltips :
    {
        back : "Toutes les configurations" ,
        edit : "Modifier"
    },
    title : 'Configuration'
};

export default set ;
