import Thing from './Thing'
import Word  from './Word'

/**
 * A property-value pair, e.g. representing a feature of a product or place.
 * Use the 'name' property for the name of the property. If there is an additional human-readable version of the value, put that into the 'description' property.
 * @memberOf things
 */
class PropertyValue extends Thing
{
    /**
     * Creates a new PropertyValue instance.
     * @constructor
     * @param object The generic object to initialize the object the first time.
     */
    constructor( object = null )
    {
        super() ;

        /**
         * The upper value of some characteristic or property.
         * @type {number}
         */
        this.maxValue = NaN ;

        /**
         * A technique or technology used in a Dataset (or DataDownload, DataCatalog), corresponding to the method used for measuring the corresponding variable(s).
         * @type {string}
         */
        this.measurementTechnique = null ;

        /**
         * The lower value of some characteristic or property.
         * @type {number}
         */
        this.minValue = NaN ;

        /**
         * A commonly used identifier for the characteristic represented by the property, e.g. a manufacturer or a standard code for a property.
         * @type {string}
         */
        this.propertyID = null  ;

        /**
         * The unit of measurement given using the UN/CEFACT Common Code (3 characters) or a URL. Other codes than the UN/CEFACT Common Code may be used with a prefix followed by a colon.
         * @type {string}
         */
        this.unitCode = null  ;

        /**
         * A string or text indicating the unit of measurement. Useful if you cannot provide a standard unit code for unitCode.
         * @type {string}
         */
        this.unitText = null  ;

        /**
         * The value of the number.
         * @type {string|number|Object}
         */
        this.value = null  ;

        /**
         * A pointer to a secondary value that provides additional information on the original value, e.g. a reference temperature.
         * @type {Thing}
         */
        this.valueReference = null ;

        this.set( object ) ;
    }

    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        super.clear() ;
        this.maxValue = NaN ;
        this.measurementTechnique = null ;
        this.minValue = NaN ;
        this.propertyID = null ;
        this.unitCode = null ;
        this.unitText= null ;
        this.value = null ;
        this.valueReference = null ;
        return this ;
    }

    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new PropertyValue( this.toObject() ) ;
    }

    /**
     * Populate the object with specific behaviors or types. Launched by default in the constructor and in the set methods.
     * @returns {Thing} The current reference.
     */
    populate()
    {
        if( this.additionalType && !(this.additionalType instanceof Word))
        {
            this.additionalType = new Word(this.additionalType) ;
        }
        return this ;
    }

    /**
     * Returns the generic object representation of the item.
     * @return {Object} The generic object representation of the item.
     */
    toObject()
    {
        let {
            maxValue,
            measurementTechnique,
            minValue,
            propertyID,
            unitCode,
            unitText,
            value,
            valueReference
        } = this ;
        
        return {
            ...super.toObject() ,
            maxValue,
            measurementTechnique,
            minValue,
            propertyID,
            unitCode,
            unitText,
            value,
            valueReference
        }
    }
}

export default PropertyValue ;