const defaultProperties =
{
    addDialog    : null ,
    clearDialog  : null ,
    numDialogs   : null ,
    removeDialog : null ,
    dialogs      : null
};

export default defaultProperties;
