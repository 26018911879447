import { Component } from 'react'

class AbstractItem extends Component
{
    getPaddingLeft = ( { offset = 16 , spacingOffset = 2 } = {} ) =>
    {
        const { nestedLevel , theme } = this.props ;

        if( theme && theme.spacing instanceof Function )
        {
            offset = theme.spacing( spacingOffset ) ;
        }

        let paddingLeft = offset ;
        if( nestedLevel > 0 )
        {
            paddingLeft += nestedLevel * offset ;
        }

        return paddingLeft ;
    }
}

export default AbstractItem;
