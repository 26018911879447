import isString from 'vegas-js-core/src/isString'

import Thing from './Thing'

/**
 * Generates a function to sort two Things by name.
 * @param {object} options
 * @param {boolean} options.invert - Indicates if the sort is descending (true) or ascending (false).
 * @param {string} options.lang - The current lang to get the name of the thing.
 * @param {string} options.defaultLang - The default lang to get the name of the thing.
 * @returns {Function} The string content url defines by the image property of a specific thing.
 */
const sortByName = ( { invert = false , lang = null , defaultLang = null } = {} ) => ( thing1 , thing2 ) =>
{
    if( thing1 instanceof Thing && thing2 instanceof Thing )
    {
        thing1 = thing1.getLocaleName(lang,defaultLang) ;
        thing2 = thing2.getLocaleName(lang,defaultLang) ;
        if( isString(thing1) && isString(thing2) )
        {
            return thing1.localeCompare(thing2,lang) * (invert?-1:1);
        }
    }
    return 0 ;
};

export default sortByName ;
