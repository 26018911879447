
import amber      from '@material-ui/core/colors/amber'
import blue       from '@material-ui/core/colors/blue'
import blueGrey   from '@material-ui/core/colors/blueGrey'
import brown      from '@material-ui/core/colors/brown'
import cyan       from '@material-ui/core/colors/cyan'
import deepOrange from '@material-ui/core/colors/deepOrange'
import deepPurple from '@material-ui/core/colors/deepPurple'
import green      from '@material-ui/core/colors/green'
import grey       from '@material-ui/core/colors/grey'
import indigo     from '@material-ui/core/colors/indigo'
import lightBlue  from '@material-ui/core/colors/lightBlue'
import lightGreen from '@material-ui/core/colors/lightGreen'
import lime       from '@material-ui/core/colors/lime'
import orange     from '@material-ui/core/colors/orange'
import pink       from '@material-ui/core/colors/pink'
import purple     from '@material-ui/core/colors/purple'
import red        from '@material-ui/core/colors/red'
import teal       from '@material-ui/core/colors/teal'
import yellow     from '@material-ui/core/colors/yellow'

const levels = [50,100,200,300,400,500,600,700,800,900];

const palette =
[
    ...levels.map( level => grey[level]).reverse(),
    ...levels.map( level => blueGrey[level]),
    ...levels.map( level => red[level]).reverse(),
    ...levels.map( level => pink[level]),
    ...levels.map( level => purple[level]).reverse(),
    ...levels.map( level => deepPurple[level]),
    ...levels.map( level => indigo[level]).reverse(),
    ...levels.map( level => blue[level]),
    ...levels.map( level => lightBlue[level]).reverse(),
    ...levels.map( level => cyan[level]),
    ...levels.map( level => teal[level]).reverse(),
    ...levels.map( level => green[level]),
    ...levels.map( level => lightGreen[level]).reverse(),
    ...levels.map( level => lime[level]),
    ...levels.map( level => yellow[level]).reverse(),
    ...levels.map( level => amber[level]),
    ...levels.map( level => orange[level]).reverse(),
    ...levels.map( level => deepOrange[level]),
    ...levels.map( level => brown[level]).reverse()
];

export default palette ;