import isNotNull from 'vegas-js-core/src/isNotNull'

import Thing from './Thing'

/**
 * Returns the id value of the specific thing or an array representation if the passed-in argument is an array of Things.
 * @param {(Thing|Thing[])} thing - The thing reference.
 * @returns {string|string[]|null} the id value of the specific thing or an array representation if the passed-in argument is an array of Things.
 */
const identify = thing =>
{
    if( thing instanceof Array && ( thing.length > 0 ) )
    {
        return thing.map( item => item instanceof Thing ? String(item.id) : null ).filter( isNotNull ) ;
    }
    else if( thing instanceof Thing )
    {
        return String(thing.id) ;
    }
    return null ;
};

export default identify ;