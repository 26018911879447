import lightBlue from '@material-ui/core/colors/lightBlue'
import pink      from '@material-ui/core/colors/pink'

import init from './thing'

const session =
{
    ...init,
    current : 'Votre session' ,
    type    :
    {
        'access_token' :
        {
            backgroundColor : lightBlue[800] ,
            color           : '#ffffff' ,
            text            : 'APP'
        } ,
        'id_token' :
        {
            backgroundColor : pink[700] ,
            color           : '#ffffff' ,
            text            : 'API'
        }
    }
};

export default session ;
