const events =
{
    calendar :
    {
        culture : 'fr' ,
        messages :
        {
            agenda          : 'Agenda',
            date            : 'Date',
            event           : 'Événement"',
            allDay          : 'Toute la journée',
            week            : 'Semaine',
            work_week       : 'Jour de la semaine',
            day             : 'Jour',
            month           : 'Mois',
            previous        : 'Précédent',
            next            : 'Suivant',
            noEventsInRange : 'Aucun événement pour cette période.',
            time            : 'Horaire',
            today           : 'Aujourd\'hui',
            tomorrow        : 'Demain',
            yesterday       : 'Hier',
            showMore        : count => '+' + count + ' plus'
        }
    } ,
    helmet :
    {
        title : 'Calendrier des événements' ,
        metas : []
    },
    loading    : "Chargement ..." ,
    noDataText : 'Aucun résultat' ,
    period :
    {
        day      : `Le {0}` ,
        interval : `Du {0} au {1}`
    },
    remove :
    {
        agree        : 'Supprimer' ,
        description  : 'Souhaitez-vous supprimer l\'événement:' ,
        descriptionX : 'Souhaitez-vous supprimer les {0} événements:' ,
        disagree     : 'Annuler' ,
        loading      : 'Suppression en cours...' ,
        title        : 'Supprimer cet événément ?',
        titleX       : 'Supprimer des événements ?'
    },
    search : 'Rechercher un événement ...' ,
    tooltips :
    {
        add         : 'Ajouter' ,
        activate    : 'Activer' ,
        deactivate  : 'Désactiver' ,
        remove      : 'Supprimer' ,
        removeItem  : "Supprimer un événement" ,
        removeItemX : "Supprimer {0} événements"
    },
    title : 'Calendrier des événements'
};

export default events ;