import Form   from '../../../../../../../display/forms/FormElements'
import Method from '../../../../../../../net/Method'

const elements =
[
    {
        type : Form.I18N_EDITOR ,
        init :
        {
            id    : 'description',
            key   : 'description',
            input : false,
            label : 'Description de l\'hypothèse'
        }
    }
];

const hypothesis =
{
    edit :
    {
        agree       : 'Sauver' ,
        disagree    : 'Annuler' ,
        fail        : 'Veuillez corriger les erreurs' ,
        loading     : 'Chargement en cours...' ,
        method      : Method.PATCH,
        mock        : false ,
        success     : 'Mise à jour réussie!' ,
        title       : 'Hypothèse' ,
        elements
    },
    labels:
    {
        description : 'Description'
    },
    title    : 'Hypothèses diagnostiques' ,
    tooltips :
    {
        edit : 'Modifier'
    }
};

export default hypothesis ;
