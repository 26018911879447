import React from 'react'

import PropTypes from 'prop-types'

import moment from 'moment/moment'

import compose  from 'vegas-js-core/src/functors/compose'
import isString from 'vegas-js-core/src/isString'
import leading  from 'vegas-js-core/src/numbers/leading'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import red  from '@material-ui/core/colors/red'

import { Fab, Typography } from '@material-ui/core'

import { FaSyringe   } from 'react-icons/fa'
import { FaCalendarCheck } from 'react-icons/fa'

import withDialogs from "../../../../../../contexts/dialogs/withDialogs"
import withi18n    from '../../../../../../contexts/i18n/withi18n'

import DialogError from '../../../../../../errors/DialogError'

import ThingCardChildren , { styles } from '../../../../ThingCardChildren'
import VaccinationEvent from '../../../events/VaccinationEvent'

import AddVaccinationEventDialog from "../dialogs/AddVaccinationEventDialog"

import AbortionSerieReview from '../../../../../../things/livestock/reviews/AbortionSerieReview'
import VaccinationEventVO  from '../../../../../../things/medical/events/VaccinationEvent'
import Word                from '../../../../../../things/Word'

const prepare = item =>
{
    let {
        id = null ,
        about,
        date,
        subjectOf:review
    }
    = item ;

    if( !(about instanceof Word) )
    {
        throw new DialogError({ about:'vaccine is required' })
    }

    if( review instanceof AbortionSerieReview )
    {
        review = review.toObject(false);

        let { vaccinations } = review ;

        let position = -1 ;

        if( vaccinations instanceof Array )
        {
            position = vaccinations.findIndex( element => element.id === id ) ;
        }
        else
        {
            vaccinations = [] ;
        }

        const event =
        {
            id ,
            about,
            date : date instanceof Date ? moment(date).format( 'YYYY-MM-DD' ) : null ,
        };

        if( position > -1 )
        {
            vaccinations[position] = event ;
        }
        else
        {
            vaccinations = [ ...vaccinations , event ] ;
        }

        review.vaccinations = vaccinations ;

        return { review : JSON.stringify(review) } ;

    }
    return null ;
};

class VaccinationEvents extends ThingCardChildren
{
    editPrepare = prepare ;

    createChild = ( child , editable = false ) =>
    {
        if( child instanceof VaccinationEventVO )
        {
            return (
            <VaccinationEvent
                aboutVisible = { false }
                editable     = { editable }
                gridProps    = {{ xs : 12 }}
                inline       = { true }
                thing        = { child }
                onClick      = { child => this.openEditDialog(child) }
            />);
        }
        return null ;
    };

    getBottom = this.getCustomCardWithAddButton ;

    getCardLabel = ( child, editable, index ) =>
    {
        const locale = this.getLocale() ;
        if( locale )
        {
            const { labels } = locale ;
            if( labels )
            {
                let { card:label } = labels ;
                if( index >= 0 )
                {
                    label = leading(++index) + ' - ' + label ;
                }
                return label ;
            }
        }
        return null ;
    };

    getCardSubLabel = child =>
    {
        if( child instanceof VaccinationEventVO )
        {
            let { about } = child ;
            if( about instanceof Word )
            {
                const { lang } = this.props ;
                about = about instanceof Word ? about.getLocaleName(lang) : null ;
                if( isString(about) )
                {
                    return (
                    <Typography
                        className = 'w-full font-medium'
                        variant   = 'body2'
                    >
                        { ucFirst(about) }
                    </Typography>
                    );
                }
            }
        }
        return this.props.cardSubLabel ;
    };

    getLocale = () => this.props.locale.things.livestock.reviews.abortionSerie.events.vaccinationEvents ;

    onAdd = ( thing ) =>
    {
        if( thing )
        {
            const { onChange } = this.props ;
            if( onChange )
            {
                onChange( thing ) ;
            }
        }
    };

    onEdit = ( thing ) =>
    {
        if( thing )
        {
            const { onChange } = this.props ;
            if( onChange )
            {
                onChange( thing ) ;
            }
        }
    };

    onCardClick = ( item ) => () =>
    {
        this.openEditDialog( item ) ;
    };

    onRemove = ( thing ) =>
    {
        if( thing )
        {
            const { onChange } = this.props ;
            if( onChange )
            {
                onChange( thing ) ;
            }
        }
    };

    openAddDialog = () =>
    {
        const { addDialog } = this.props;
        if ( addDialog )
        {
            const { addUri:uri, member } = this.props ;
            addDialog(
                AddVaccinationEventDialog ,
                {
                    member ,
                    uri,
                    item  : this.getEntry() ,
                    onAdd : this.onAdd
                }
            ) ;
        }
    };

    removePrepare = ( item ) =>
    {
        if( item instanceof VaccinationEventVO )
        {
            let { subjectOf:review } = item ;
            if( review instanceof AbortionSerieReview )
            {
                review = review.toObject(false);
                let { id } = item ;
                let { vaccinations } = review ;
                if( vaccinations instanceof Array && vaccinations.length > 0 )
                {
                    review.vaccinations = vaccinations.filter( element => element.id !== id ) ;
                }
                return { review : JSON.stringify(review) } ;
            }
        }
        return null ;
    };

    sort = ( a , b ) =>
    {
        if( (a instanceof VaccinationEventVO) && (b instanceof VaccinationEventVO) )
        {
            let { date:date1 } = a ;
            let { date:date2 } = b ;
            if( !isString(date1) && !isString(date2) )
            {
                return 0 ;
            }
            else if( isString(date1) && !isString(date2) )
            {
                return -1 ;
            }
            else if( !isString(date1) && isString(date2) )
            {
                return 1 ;
            }
            else if( isString(date1) && isString(date2) )
            {
                date1 = moment(date1).valueOf() ;
                date2 = moment(date2).valueOf() ;
                if( date1 === date2 )
                {
                    return 0 ;
                }
                else if( date1 < date2 )
                {
                    return -1 ;
                }
                else
                {
                    return 1 ;
                }
            }
        }

        return 0 ;
    };
}

VaccinationEvents.defaultProps =
{
    ...ThingCardChildren.defaultProps,
    cardIcon              : <FaCalendarCheck size={20}/> ,
    clazz                 : VaccinationEventVO,
    DeleteButtonComponent : Fab,
    DeleteButtonProps     :
    {
        size  : 'small',
        style :
        {
            backgroundColor : 'white',
            color           : red[600],
            '&:hover'       :
            {
                backgroundColor : red[900],
                color           : 'white'
            }
        }
    },
    grid       : { md:4 , sm:6 , xs:12 },
    icon       : <FaSyringe size={20}/>,
    member     : 'vaccinations',
    optionMode : 'none' ,
    path       : null,
    useUUID    : true
};

VaccinationEvents.propTypes =
{
    ...ThingCardChildren.propTypes,
    path : PropTypes.string
};

export default compose(
    withDialogs,
    withStyles( styles ),
    withRouter,
    withi18n
)(VaccinationEvents)
