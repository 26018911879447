import Livestock    from './Livestock'
import Technician   from './Technician'
import Veterinarian from './Veterinarian'

const getLocaleFavorite = thing =>
{
    switch( true )
    {
        case thing instanceof Livestock :
        {
            return thing.organization ;
        }

        case thing instanceof Technician :
        {
            return thing.person ;
        }

        case thing instanceof Veterinarian :
        {
            return thing.authority ;
        }

        default :
        {
            return thing ;
        }
    }
}

export default getLocaleFavorite ;
