import add    from '../dialogs/add'
import edit   from '../dialogs/edit'
import remove from '../dialogs/remove'

const datas =
{
    // dialogs
    
    add,
    edit,
    remove,
    
    // labels
    
    fail       : 'Contenu non trouvé...' ,
    loading    : 'Chargement...' ,
    noDataText : 'Aucun enregistrement trouvé' ,
    title      : 'Titre'
};

export default datas ;