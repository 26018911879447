const session =
{
    revoke :
    {
        agree       : 'Révoquer' ,
        disagree    : 'Fermer' ,
        loading     : 'Révocation en cours...' ,
        separator   : ' - ' ,
        success     : 'Révocation réussie...' ,
        title       : 'Révoquer une session' ,
        description : 'Souhaitez-vous révoquer la session : '
    },
    revokeAll :
    {
        agree       : 'Déconnecter' ,
        disagree    : 'Fermer' ,
        loading     : 'Révocation en cours...' ,
        success     : 'Déconnection réussie...' ,
        title       : 'Déconnecter toutes les sessions' ,
        description : 'Attention, cette action déconnecte toutes les sessions actives et force la déconnection du service sur toutes les applications et tous les terminaux possédant une session pour ce compte.'
    }
};

export default session ;
