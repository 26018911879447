import React from 'react'

import clsx from 'clsx'

import { Redirect } from 'react-router-dom'

import { Button, Typography } from '@material-ui/core'

import Animate from '../../transitions/Animate'

import connect    from '../../net/auth/connect'
import AuthStatus from "../../net/auth/AuthStatus"

import View from '../../display/views/View'

import Flash  from '../../components/snackbars/Flash'
import Reload from '../../components/snackbars/Reload'

import initView from '../../display/views/initView'

const styles = theme =>
{
    return {
        button:
        {
            marginTop: 40
        },
        logo:
        {
            marginBottom : 32,
            height       : 156
        },
        root:
        {
            backgroundColor : process.env.REACT_APP_THEME_COLOR ,
            background      : `url(${theme.assets.background}) no-repeat center center` ,
            backgroundSize  : 'cover' ,
            width           : '100%',
            height          : '100%'
        }
    }
};

class Welcome extends View
{
    render()
    {
        const {
            config,
            classes,
            location
        } = this.props;

        const locale = this.getLocale() ;

        let path = '/' ;
        if( location )
        {
            const { state } = location ;
            if( state )
            {
                const { referrer } = state ;
                if( referrer )
                {
                    path = referrer ;
                }
            }
        }

        switch( AuthStatus.check() )
        {
            case AuthStatus.NEED_ACCESS_TOKEN :
            {
                return (
                <div className={clsx(classes.root, "flex flex-col w-full h-full p-24")}>

                    <div className={ clsx(classes.intro, "flex flex-col items-start p-16 text-left md:p-128 text-left")}>

                        <Animate animation="transition.expandIn">
                            <img className={ clsx( classes.logo ) } src={ config.assets.logo } alt="logo"/>
                        </Animate>

                        <Animate animation="transition.slideUpIn" delay={300}>
                            <Typography variant="h3" className="font-light" >
                                { locale.title }
                            </Typography>
                        </Animate>

                        <Animate delay={500}>
                            <Typography variant="subtitle1" className="mt-16" >
                                { locale.description }
                            </Typography>
                        </Animate>

                        <Animate delay={500}>
                            <Button
                                className = { classes.button }
                                color     = 'primary'
                                onClick   = { () => { connect() ; }}
                                variant   = 'contained'
                            >
                                { locale.connect }
                            </Button>
                        </Animate>
                    </div>
                    <Flash />
                    <Reload/>
                </div>
                )
            }
            default :
            {
                return <Redirect to={path} />
            }
        }
    }

    getLocale = () => this.props.locale.views.welcome ;
}

Welcome.defaultProps =
{
    ...View.defaultProps
};

Welcome.propTypes =
{
    ...View.propTypes
};

export default initView( Welcome, styles );
