import ucFirst from 'vegas-js-core/src/strings/ucFirst'

import Form from '../../../display/forms/FormElements'

import Thing         from '../../../things/Thing'
import Veterinarian  from '../../../things/Veterinarian'

import getLocaleName from '../../../things/getLocaleName'

import api from '../../../configs/api'

const elements =
[
    {
        type       : Form.SEARCH_SELECTOR ,
        path       : api.veterinarians.url ,
        clazz      : Veterinarian ,
        clearLabel : 'Vider' ,
        closeLabel : 'Fermer' ,
        init       :
        {
            id         : 'value',
            key        : 'value',
            label      : 'Vétérinaire',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            required   : true,
            type       : 'search',
            variant    : 'outlined'
        },
        inputProps :
        {
            label : 'Vétérinaire...'
        },
        logics :
        {
            disabledSuggestions : props =>
            {
                const { rel } = props ;
                let suggestions = [] ;
                if( rel instanceof Thing )
                {
                    const { subjectOf } = rel ;
                    if( subjectOf instanceof Thing )
                    {
                        if( subjectOf instanceof Veterinarian )
                        {
                            suggestions.push( subjectOf ) ;
                        }
                        const { veterinarians } = subjectOf ;
                        if( veterinarians instanceof Array )
                        {
                            return veterinarians ;
                        }
                    }
                }
                return suggestions ;
            }
        },
        suggestionRef : ( item ) =>
        {
            if( item instanceof Veterinarian )
            {
                const { authority } = item ;
                if( authority instanceof Thing )
                {
                    return authority;
                }
            }
            return item ;
        },
        valueLabel : ( item , props ) =>
        {
            if( item instanceof Veterinarian )
            {
                const { authority } = item ;
                if( authority instanceof Thing )
                {
                    return ucFirst( getLocaleName( authority, props.lang ) ) ;
                }
            }
            return item ;
        }
    }
];

const veterinarians =
{
    add :
    {
        elements,
        agree       : 'Ajouter' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez sélectionner un vétérinaire en cliquant ci-dessous.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en  cours...' ,
        success     : 'Ajout du nouveau vétérinaire réussi!' ,
        title       : 'Ajouter un vétérinaire',
    },
    remove :
    {
        agree       : 'Supprimer' ,
        description : 'Souhaitez-vous supprimer ce vétérinaire de cette liste ?' ,
        disagree    : 'Annuler' ,
        item        :
        {
            text :
            {
                primary : ( element , props ) =>
                {
                    if( element instanceof Veterinarian )
                    {
                        const { lang } = props ;
                        const { authority } = element ;
                        if( authority instanceof Thing )
                        {
                            return getLocaleName( authority , lang );
                        }
                    }
                    return 'Inconnu';
                }
            }
        },
        loading     : 'Suppression en cours...' ,
        title       : 'Supprimer le vétérinaire ?'
    },
    title : 'Vétérinaires' ,
    tooltips :
    {
        add    : "Ajouter" ,
        edit   : "Éditer" ,
        remove : "Supprimer"
    }
};

export default veterinarians ;
