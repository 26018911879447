import React from 'react'

import PropTypes  from 'prop-types'

import clsx from 'clsx'

import { Paper } from '@material-ui/core'

import DatasListContainer from '../containers/DatasListContainer'

import TransitionGroup from '../../transitions/TransitionGroup'

class DatasGrid extends DatasListContainer
{
    getBody = things =>
    {
        if( things instanceof Array && (things.length > 0) )
        {
            return things.map( ( item , index ) =>
            (
                <div key={ 'item-' + index } >
                    { this.getItem( item , index ) }
                </div>
            )) ;
        }
        return null ;
    };

    getContent = ( loading = false ) =>
    {
        const { things } = this.state ;
        if( things instanceof Array && things.length > 0 )
        {
            const {
                contentTransition,
                GridClassName = 'py-8' ,
                GridProps
            } = this.props;

            const className = clsx (
                'grid gap-16 grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3' ,
                loading ? 'opacity-50 pointer-events-none' : 'opacity-100 pointer-events-auto' ,
                GridClassName
            );

            const body = this.getBody( things ) ;

            if( contentTransition )
            {
                return (
                <TransitionGroup
                    className = { className }
                    enter     = {
                    {
                        animation : 'transition.flipYIn',
                        delay     : 100,
                        duration  : 300 ,
                        stagger   : 100
                    }}
                >
                    { body }
                </TransitionGroup>
                );
            }
            else
            {
                return (
                    <div
                        className = { className }
                        { ...GridProps }
                    >
                        { body }
                    </div>
                )
            }
        }

        if( loading )
        {
            return this.getProgress() ;
        }

        if( !loading )
        {
            return <div className='flex flex-1'>{ this.getEmpty() }</div>;
        }

        return null ;
    };

    getItem = () => null ;
}

DatasGrid.defaultProps =
{
    ...DatasListContainer.defaultProps,
    contentTransition : true,
    ItemClassName     : null,
    ItemComponent     : Paper,
    ItemProps         : null ,
    GridProps         : null
};

DatasGrid.propTypes =
{
    ...DatasListContainer.propTypes,
    contentTransition : PropTypes.bool ,
    ItemClassName     : PropTypes.string,
    ItemComponent     : PropTypes.elementType.isRequired,
    ItemProps         : PropTypes.object ,
    GridClassName     : PropTypes.string,
    GridProps         : PropTypes.object
};

export default DatasGrid ;
