import React from 'react'

import clsx from 'clsx'

import format from 'vegas-js-core/src/strings/fastformat'

import TransitionGroup from '../../../transitions/TransitionGroup'

import Metadatas         from '../../../display/things/Metadatas'
import Offers            from '../../../display/things/Offers'
import Organizer         from '../../../display/things/event/Organizer'
import RemoveEventDialog from '../../../display/dialogs/remove/RemoveEventDialog'
import SubEvent          from '../../../display/things/event/SubEvent'
import SuperEvent        from '../../../display/things/event/SuperEvent'
import ThingSubContainer from '../../../display/containers/ThingSubContainer'
import Websites          from '../../../display/things/Websites'
import WorkFeatured      from '../../../display/things/event/WorkFeatured'

class Info extends ThingSubContainer
{
    render = () =>
    {
        const { thing } = this.props ;

        if( thing )
        {
            const {
                className,
                onRemove,
                path,
                style,
            } = this.props ;

            const { id } = thing ;

            return (
            <div
                className = { clsx( 'flex-1 p-12' , className ) }
                style     = { style }
            >
                <TransitionGroup duration={250} enter={{animation: "transition.slideUpIn"}}>

                    <div className="flex-1 flex flex-col space-y-24">

                        <Metadatas
                            activable             = { true }
                            deletable             = { true }
                            RemoveDialogComponent = { RemoveEventDialog }
                            onRemove              = { onRemove }
                            thing                 = { thing }
                            uri                   = { format(path.event,id) }
                        />

                        <div className='grid grid-cols-1 sm:grid-cols-2 gap-16'>
                            <Offers
                                onChange  = { this.change }
                                thing     = { thing }
                                uri       = { path.offers }
                            />
                            <Websites
                                onChange = { this.change  }
                                thing    = { thing }
                                uri      = { path.websites }
                            />
                            <SubEvent
                                onChange  = { this.change }
                                thing     = { thing }
                                uri       = { path.subEvent }
                            />
                            <SuperEvent
                                onChange   = { this.change }
                                thing      = { thing }
                                uri        = { path.subEvent }
                            />
                            <Organizer
                                onChange = { this.change }
                                thing    = { thing }
                                uri      = { path.organizer }
                            />
                            <WorkFeatured
                                onChange = { this.change }
                                thing    = { thing }
                                uri      = { path.workFeatured }
                            />
                        </div>

                    </div>

                </TransitionGroup>

            </div>);
        }
        return null ;
    }
}

Info.defaultProps =
{
    ...ThingSubContainer.defaultProps
};

Info.propTypes =
{
    ...ThingSubContainer.propTypes
};

export default Info ;
