const upload =
{
    agree     : 'Envoyer',
    done      : 'Téléversement terminé.',
    disagree  : 'Fermer',
    pending   : 'Déposer un fichier dans la zone ci-dessous.',
    pendingX  : 'Déposer des fichiers dans la zone ci-dessous.',
    ready     : 'Le fichier est prêt à être téléversé sur le serveur',
    readyX    : 'Les fichiers sont prêts à être téléversés sur le serveur',
    uploading : 'Téléversement en cours, veuillez patienter...',
    title     : 'Téléverser un fichier',
    titleX    : 'Téléverser des fichiers'
};

export default upload ;
