import React from 'react'

import EmptyIcon    from '@material-ui/icons/ThumbDown'
import FavoriteIcon from '@material-ui/icons/Favorite'

import withDatasList from './withDatasList'

import ThingsList from './ThingsList'

import Thing from '../../things/Thing'
import notEmpty from 'vegas-js-core/src/strings/notEmpty'

import FavoriteCell from '../cells/FavoriteCell'
import populateFavorite from '../../things/populateFavorite';

class FavoritesList extends ThingsList
{
    getLocale = () => this.props.locale.display.lists.favorites ;

    onToggleFavorite = ( { status, thing } = {} ) =>
    {
        if( status === 'remove')
        {
            const { snacks : { remove } = {} } = this.getLocale() ;
            let { things } = this.state ;
            if( things instanceof Array && things.length > 0 )
            {
                things = things.filter( element => element.id !== thing.id ) ;
                this.setState( { things } , () =>
                {
                    if( notEmpty(remove) )
                    {
                        this.notify( remove ) ;
                    }
                }) ;
            }
            else
            {
                if( notEmpty(remove) )
                {
                    this.notify( remove ) ;
                }
            }
        }
    };

    populate = datas =>
    {
        if( datas instanceof Array && datas.length > 0 )
        {
            datas = datas.map( item => populateFavorite(item) ) ;
        }
        return datas ;
    };
}

FavoritesList.defaultProps =
{
    ...ThingsList.defaultProps,
    activable     : false ,
    addable       : false ,
    CellComponent : FavoriteCell,
    cellProps     : { variant : 'full' },
    clazz         : Thing,
    deletable     : false ,
    emptyIcon     : <EmptyIcon color='action' fontSize='large' />,
    icon          : <FavoriteIcon />,
    preferable    : true ,
    queries       : { },
    selectPolicy  : 'none' ,
    searchPolicy  : 'none' ,
    sortable      : false ,
    variant       : 'pagination'
};

FavoritesList.propTypes =
{
    ...ThingsList.propTypes
};

export default withDatasList()( FavoritesList ) ;
