export default
{
    description : 'Veuillez sélectionner un objet ci-dessous.' ,
    disagree    : 'Fermer' ,
    empty       : 'Aucun objet disponible.' ,
    fail        : 'Impossible de charger les objets, veuillez relancer un peu plus tard.',
    loading     : 'Chargement en cours...' ,
    search      : 'Rechercher un objet ...' ,
    title       : 'Sélectionner un objet'
};
