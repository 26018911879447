import {
    article,
    conceptualObject,
    course ,
    disease ,
    event,
    livestock,
    organization,
    person,
    place,
    stage,
    technician,
    veterinarian
}
from '../../../../things/FavoriteType'

import init from './thing'

import blueGrey   from '@material-ui/core/colors/blueGrey'
import deepOrange from '@material-ui/core/colors/deepOrange'
import green      from '@material-ui/core/colors/green'
import pink       from '@material-ui/core/colors/pink'

const favorite =
{
    ...init,
    type    :
    {
        // -------------------------------------- Micro Adventure

        [ course ] :
        {
            backgroundColor : green[800] ,
            color           : '#ffffff' ,
            text            : 'Parcours'
        },
        [ stage ] :
        {
            backgroundColor : green[600] ,
            color           : '#ffffff' ,
            text            : 'Point d\'intérêt'
        },

        // -------------------------------------- Calendar

        [ event ] :
        {
            backgroundColor : deepOrange[600] ,
            color           : '#ffffff' ,
            text            : 'Événement'
        },

        // -------------------------------------- Sheets

        [ article ] :
        {
            backgroundColor : blueGrey[900] ,
            color           : '#ffffff' ,
            text            : 'Article'
        },
        [ conceptualObject ] :
        {
            backgroundColor : blueGrey[800] ,
            color           : '#ffffff' ,
            text            : 'Objet'
        },
        [ organization ] :
        {
            backgroundColor : blueGrey[700] ,
            color           : '#ffffff' ,
            text            : 'Organisation'
        },
        [ person ] :
        {
            backgroundColor : blueGrey[600] ,
            color           : '#ffffff' ,
            text            : 'Personne'
        },
        [ place ] :
        {
            backgroundColor : blueGrey[400] ,
            color           : '#ffffff' ,
            text            : 'Lieu'
        },

        // -------------------------------------- animal health

        [ disease ] :
        {
            backgroundColor : pink[900] ,
            color           : '#ffffff' ,
            text            : 'Maladie'
        },
        [ livestock ] :
        {
            backgroundColor : pink[700] ,
            color           : '#ffffff' ,
            text            : 'Élevage'
        },
        [ technician ] :
        {
            backgroundColor : pink[500] ,
            color           : '#ffffff' ,
            text            : 'Technicien'
        },
        [ veterinarian ] :
        {
            backgroundColor : pink[300] ,
            color           : '#ffffff' ,
            text            : 'Vétérinaire'
        }
    }
};

export default favorite ;
