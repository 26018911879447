import React  from 'react'

import notEmpty   from 'vegas-js-core/src/strings/notEmpty'

import UploadIcon from '@material-ui/icons/AddPhotoAlternate'

import lightBlue from '@material-ui/core/colors/lightBlue'

import ThingButton from './ThingButton'

import api         from '../../configs/api'
import initDialog  from '../dialogs/initDialog'

const styles =
{
    button :
    {
        backgroundColor : 'white',
        color           : lightBlue[600],
        '&:hover' :
        {
            backgroundColor : lightBlue[900],
            color           : 'white'
        }
    },
    root :
    {
        //
    }
};

export class UploadImageButton extends ThingButton
{
    click = () => { this.openUploadDialog() ; };

    getLocale = () => this.props.locale.display.buttons.uploadImage ;

    uploadUri = () =>
    {
        const { thing } = this.props ;
        if( thing )
        {
            const { url } = thing ;
            if( notEmpty(url) )
            {
                return url.split(api.url)[1] + '/image' ;
            }
        }
        return null ;
    }
}

UploadImageButton.defaultProps =
{
    ...ThingButton.defaultProps,
    icon : <UploadIcon /> ,
    size :'small'
};

UploadImageButton.propTypes =
{
    ...ThingButton.propTypes,
};

export default initDialog({ styles })( UploadImageButton ) ;
