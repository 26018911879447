import FormElements from '../../../../display/forms/FormElements'

import formatDate from 'vegas-js-core/src/strings/fastformatDate'
import isString   from 'vegas-js-core/src/isString'

import Person from '../../../../things/Person'

const elements =
[
    {
        type : FormElements.TEXTFIELD ,
        init :
        {
            id           : 'nationality',
            key          : 'nationality',
            label        : 'Nationalité',
            autoFocus    : true,
            fullWidth    : true,
            helperText   : null,
            margin       : 'normal',
            required     : false,
            select       : false,
            autoComplete : 'off' ,
            type         : 'search',
            variant      : 'outlined'
        }
    },
    {
        type   : FormElements.DATE ,
        format : 'L' ,
        init   :
        {
            id            : 'birthDate',
            key           : 'birthDate',
            autoOk        : true ,
            cancelLabel   : 'Annuler' ,
            clearable     : true ,
            clearLabel    : 'Effacer' ,
            invalidLabel  : '-',
            disableFuture : false ,
            disablePast   : false ,
            fullWidth     : true ,
            label         : 'Date de naissance' ,
            required      : true,
            okLabel       : 'OK',
            variant       : 'outlined'
        }
    },
    {
        type : FormElements.TEXTFIELD ,
        init :
        {
            id           : 'birthPlace',
            key          : 'birthPlace',
            label        : 'Lieu de naissance',
            autoFocus    : true,
            fullWidth    : true,
            helperText   : null,
            margin       : 'normal',
            required     : false,
            select       : false,
            autoComplete : 'off' ,
            type         : 'search',
            variant      : 'outlined'
        }
    },
    {
        type   : FormElements.DATE ,
        format : 'L' ,
        init   :
        {
            id            : 'deathDate',
            key           : 'deathDate',
            autoOk        : true ,
            cancelLabel   : 'Annuler' ,
            clearable     : true ,
            clearLabel    : 'Effacer' ,
            emptyLabel    : '-' ,
            invalidLabel  : '-',
            disableFuture : false ,
            disablePast   : false ,
            fullWidth     : true ,
            label         : 'Date de décès' ,
            required      : true,
            okLabel       : 'OK',
            variant       : 'outlined'
        }
    },
    {
        type : FormElements.TEXTFIELD ,
        init :
        {
            id           : 'deathPlace',
            key          : 'deathPlace',
            label        : 'Lieu de décès',
            autoFocus    : true,
            fullWidth    : true,
            helperText   : null,
            margin       : 'normal',
            required     : false,
            select       : false,
            autoComplete : 'off' ,
            type         : 'search',
            variant      : 'outlined'
        }
    }
];

const prepare = ( item ) =>
{
    let datas ;
    if( item instanceof Person )
    {
        let {
            birthDate,
            birthPlace,
            deathDate,
            deathPlace,
            name,
            nationality
        } = item ;
        
        if( birthDate instanceof Date )
        {
            birthDate = formatDate(birthDate) ;
        }
        else if( !isString(birthDate) )
        {
            birthDate = ''
        }
        
        if( deathDate instanceof Date )
        {
            deathDate = formatDate(deathDate) ;
        }
        else if( !isString(deathDate) )
        {
            deathDate = ''
        }
        
        datas =
        {
            birthDate,
            birthPlace,
            deathDate,
            deathPlace,
            name,
            nationality
        };
    }
    return datas ;
};


const civilStatus =
{
    title : 'État civil',
    edit  :
    {
        agree       : 'Sauver' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour modifier l\'état civil.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...' ,
        success     : 'Mise à jour réussie!' ,
        title       : 'Modifier l\'état civil' ,
        elements ,
        prepare
    },
    labels :
    {
        empty : '-----'
    },
    tooltips :
    {
        add    : "Ajouter" ,
        edit   : "Éditer" ,
        remove : "Supprimer"
    }
};

export default civilStatus ;