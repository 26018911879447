export default
{
    add :
    {
        agree       : 'Créer' ,
        disagree    : 'Annuler' ,
        title       : 'Ajouter un média' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour créer une nouveau média' ,
        elements :
        [
            {
                id         : 'headline' ,
                key        : 'headline' ,
                label      : 'Titre' ,
                autoFocus  : true ,
                fullWidth  : true ,
                helperText : null ,
                margin     : 'dense' ,
                required   : false ,
                select     : false ,
                type       : 'text'
            }
        ],
        fail    : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading : 'Chargement en  cours...' ,
        success : 'Ajout du nouveau média réussi!' ,
    },
    remove :
    {
        agree        : 'Supprimer' ,
        description  : 'Souhaitez-vous supprimer le média :' ,
        descriptionX : 'Souhaitez-vous supprimer les {0} médias ci-dessous :' ,
        disagree     : 'Annuler' ,
        loading      : 'Suppression en cours...' ,
        title        : 'Supprimer ce média ?',
        titleX       : 'Supprimer des médias ?'
    },
    upload :
    {
        agree    : 'Envoyer',
        disagree : 'Fermer',
        text     : 'Déposer des fichiers à charger.',
        title    : 'Téléverser des fichiers'
    }
};
