import React from 'react'

import PropTypes  from 'prop-types'

import clsx from 'clsx'

import { Paper, Table, TableBody, TableContainer } from '@material-ui/core'

import DatasListContainer from '../containers/DatasListContainer'

class DatasTable extends DatasListContainer
{
    getContent = ( loading = false ) =>
    {
        const { things } = this.state ;
        if( things instanceof Array )
        {
            if( things.length > 0 )
            {
                const {
                    tableClassName,
                    TableContainerComponent,
                    tableStyle
                } = this.props;
                return (
                    <TableContainer
                        className = { clsx( loading ? 'opacity-50 pointer-events-none' : 'opacity-100 pointer-events-auto' ) }
                        component = { TableContainerComponent || 'div' }
                    >
                        <Table
                            className  = { tableClassName }
                            size       = 'small'
                            style      = { tableStyle }
                        >
                            <TableBody>
                                { things.map( this.getRow ) }
                            </TableBody>
                        </Table>
                    </TableContainer>
                )
            }
            else if( !loading )
            {
                return <div className='flex-1 flex'>{ this.getEmpty() }</div>;
            }
        }
        return null ;
    };

    getRow = () => null ;
}

DatasTable.defaultProps =
{
    ...DatasListContainer.defaultProps,
    tableClassName          : null,
    TableContainerComponent : Paper,
    tableStyle              : null
};

DatasTable.propTypes =
{
    ...DatasListContainer.propTypes,
    tableClassName          : PropTypes.string,
    TableContainerComponent : PropTypes.elementType,
    tableStyle              : PropTypes.object
};

export default DatasTable ;
