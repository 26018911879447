import React from 'react'

import PropTypes from 'prop-types'

import withi18n from '../../contexts/i18n/withi18n'

import AddressLabel from '../labels/AddressLabel'
import GeoLabel     from '../labels/GeoLabel'
import PlaceLabel   from '../labels/PlaceLabel'

import Place from '../../things/Place'
import Thing from '../../things/Thing'

import getHorizontalElements from '../getHorizontalElements'
import getVerticalElements   from '../getVerticalElements'

import { ThingCell } from './ThingCell'

class StageCell extends ThingCell
{
    getContent = () =>
    {
        const { thing } = this.props;
        if ( thing )
        {
            const line1 = getHorizontalElements({ factory:this , elements:[ 'status' , 'location' ] }) ;
            const line2 = getHorizontalElements({ factory:this , elements:[ 'address' , 'geo' ] }) ;
            return getVerticalElements(
            {
                className : 'flex-1' ,
                elements  :
                [
                    ...(line1 ? [line1] : [] ) ,
                    ...(line2 ? [line2] : [] )
                ]
            }) ;
        }
        return null;
    };

    getAddress = ( { className = null } = {} ) =>
    {
        const { addressable:visible } = this.props;
        const thing = this.getThing() ;
        if( visible && thing instanceof Thing )
        {
            const { location } = thing ;
            if( location )
            {
                const { address } = location ;
                if ( address )
                {
                    return <AddressLabel className={className} thing={location} />;
                }
            }
        }
        return null;
    };

    getGeo = ({ className = null } = {} ) =>
    {
        const { geo:visible } = this.props;
        const thing = this.getThing();
        if ( visible && thing instanceof Thing )
        {
            const { location } = thing ;
            if( location instanceof Place )
            {
                const { geo } = location;
                if( geo )
                {
                    return <GeoLabel className={className} thing={geo}/>;
                }
            }
        }
        return null;
    };

    getLocale = () => this.props.locale.display.cells.stage;

    getLocation= ( { className = null } = {} ) =>
    {
        const { location:visible } = this.props;
        const thing = this.getThing();
        if ( visible && thing instanceof Thing )
        {
            const { location } = thing;
            if ( location instanceof Place )
            {
                return <PlaceLabel className={className} thing={location} />;
            }
        }
        return null;
    };

    getStatus = ( { className = null , key=undefined } = {}  ) => this.getWord('status')( { className , key } ) ;
}

StageCell.defaultProps =
{
    ...ThingCell.defaultProps,
    addressable     : true,
    geo             : true,
    location        : true,
    status          : true,
    subtitleVariant : 'alternativeHeadline' ,
    titleVariant    : 'auto'
};

StageCell.propTypes =
{
    ...ThingCell.propTypes,
    addressable : PropTypes.bool,
    geo         : PropTypes.bool,
    location    : PropTypes.bool,
    status      : PropTypes.bool
};

export default withi18n(StageCell);
