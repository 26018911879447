const period =
{
    endPattern   : 'ddd DD MMM YYYY' ,
    from         : 'Le {0}' ,
    full         : 'Du {0} au {1}' ,
    title        : 'Période' ,
    to           : 'Jusqu\'au {0}' ,
    startPattern : 'ddd DD MMM YYYY'
};

export default period ;