import React from 'react'

import PropTypes from 'prop-types'

import isNotNull  from 'vegas-js-core/src/isNotNull'
import notEmpty   from 'vegas-js-core/src/strings/notEmpty'
import startsWith from 'vegas-js-core/src/strings/startsWith'
import trimStart  from 'vegas-js-core/src/strings/trimStart'

import {
    Typography,
    Checkbox,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Tooltip,
} from '@material-ui/core'

import EmptyIcon from '@material-ui/icons/ErrorOutline'

import {
    AiOutlineSortAscending ,
    AiOutlineSortDescending
}
from 'react-icons/ai'

import initDialog from './initDialog'

import CoreDialog from '../../components/dialogs/CoreDialog'

class SortDialog extends CoreDialog
{
    constructor( props )
    {
        super( props ) ;
        this.state =
        {
            ...this.state,
            ascendants : [] ,
            checked    : []
        };
    }

    agree = () =>
    {
        const sort = this.sortStringify() ;
        const { setSearch } = this.props ;
        if( setSearch )
        {
            setSearch({ sort , page:null }) ;
        }
        const { onCancel } = this.props ;
        if( onCancel instanceof Function )
        {
            onCancel() ;
        }
    };

    sortStringify = () =>
    {
        const { checked } = this.state ;
        let sort = [ ...checked ] ;
        if( sort.length > 0 )
        {
            const { ascendants } = this.state ;
            return sort.map( element => ( ascendants.indexOf(element) > -1 ) ? '-' + element : element ).join(',') ;
        }
        return null ;
    };

    componentDidUpdate( prevProps )
    {
        const { sort } = this.props ;
        if( prevProps.sort !== sort )
        {
            this.setSort( this.props.sort ) ;
        }
    }

    getContent = () =>
    {
        let { elements } = this.props ;
        if( elements instanceof Array && elements.length > 0)
        {
            elements = elements.map( this.getItem( this.getLocale()) ).filter( isNotNull ) ;
            if( elements.length > 0 )
            {
                return <List className="">{ elements }</List> ;
            }
        }
        return this.getEmpty() ;
    };

    getEmpty = () =>
    {
        const locale = this.getLocale() ;
        const { emptyIcon } = this.props ;
        return (
            <div className='flex flex-col items-center justify-center w-full h-160'>
                { emptyIcon }
                <Typography color='textSecondary' variant='caption'>{ locale.empty }</Typography>
            </div>
        );
    };

    getItem = ( locale ) => ( element , index ) =>
    {
        if( element )
        {
            const {
                label = locale.unknown ,
                value
            }
            = element ;

            if( notEmpty(value) && notEmpty( label ) )
            {
                const { ascIcon , descIcon } = this.props;
                const { checked, ascendants }   = this.state ;

                const check = checked.indexOf(value) !== -1 ;
                const order = ascendants.indexOf(value) !== -1 ;

                const id = `checkbox-list-label-${label}`;

                let options ;

                if( check )
                {
                    options = (
                        <Checkbox
                            edge          = 'end'
                            checked       = { order }
                            checkedIcon   = { descIcon }
                            disableRipple = { false }
                            icon          = { ascIcon }
                            onChange      = { this.switchAscendant( value ) }
                            tabIndex      = { -1 }
                        />
                    );

                    const { tooltips : { order:text } = {} } = locale ;
                    if( notEmpty(text) )
                    {
                        options = (
                            <Tooltip placement='top' title={text} arrow={true} >
                                { options }
                            </Tooltip>
                        );
                    }

                    options = (
                        <ListItemSecondaryAction>
                            { options }
                        </ListItemSecondaryAction>
                    );
                }

                return (
                    <ListItem
                        key     = {'item-'+index}
                        role    = { undefined }
                        dense   = { true }
                        button  = { true }
                        onClick = { this.switchSort( value ) }
                    >
                        <ListItemIcon>
                            <Checkbox
                                edge          = 'start'
                                checked       = { check }
                                disableRipple = { false }
                                inputProps    = { { 'aria-labelledby' : id } }
                            />
                        </ListItemIcon>
                        <ListItemText
                            id                       = { id }
                            className                = 'flex flex-col'
                            disableTypography        = { false }
                            primary                  = { label }
                            secondary                = { check ? (order ? locale.subtitle.asc : locale.subtitle.desc) : null }
                            secondaryTypographyProps = {{ variant:'caption' }}
                        />
                        { options }
                    </ListItem>
                )  ;
            }
        }
        return null ;
    };

    getLocale = () => this.props.locale.components.dialogs.sort;

    init = () => { this.setSort( this.props.sort ) ; };

    setSort = sort =>
    {
        if( notEmpty( sort ) )
        {
            let { ascendants, checked } = this.state ;

            sort = sort.split(',');

            sort.forEach( element =>
            {
                if( startsWith(element,'-') )
                {
                    element = trimStart( element , ['-'] );
                    ascendants = [ ...ascendants, element ] ;
                }
                checked = [ ...checked, element ] ;
            })

            this.setState({ ascendants, checked }) ;
        }
    };

    switchAscendant = value => () =>
    {
        let { ascendants } = this.state ;
        if ( ascendants )
        {
            const index = ascendants.indexOf(value) ;
            ascendants = [ ...ascendants ];
            if ( index === -1 )
            {
                ascendants.push( value );
            }
            else
            {
                ascendants.splice(index, 1);
            }
            this.setState({ ascendants } );
        }
    };

    switchSort = value => () =>
    {
        let { checked, ascendants } = this.state ;
        if ( checked && ascendants )
        {
            let index = checked.indexOf(value) ;
            checked = [ ...checked ];
            if ( index === -1 )
            {
                checked.push( value );
            }
            else
            {
                checked.splice(index, 1);
                let pos = ascendants.indexOf(value) ;
                if ( pos > -1 )
                {
                    ascendants.splice( pos, 1);
                }
            }
            this.setState({ checked , ascendants } );
        }
    };
}

SortDialog.defaultProps =
{
    ...CoreDialog.defaultProps,
    ascIcon   : <AiOutlineSortAscending style={{ verticalAlign: 'middle' }} />,
    descIcon  : <AiOutlineSortDescending style={{ verticalAlign: 'middle' }} />,
    elements  : null,
    emptyIcon : <EmptyIcon color='disabled' className='p-12 w-60 h-60' />,
    maxWidth  : 'sm'
};

SortDialog.propTypes =
{
    ...CoreDialog.propTypes,
    ascIcon   : PropTypes.element,
    descIcon  : PropTypes.element,
    elements  : PropTypes.array,
    emptyIcon : PropTypes.element,
};

export default initDialog()( SortDialog ) ;
