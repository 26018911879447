
import React from 'react'

import Form from '../../../../display/forms/FormElements'

import Material from '../../../../display/things/conceptualObject/Material'

import api from '../../../../configs/api'

const elements =
[
    {
        type : Form.I18N_EDITOR ,
        init :
        {
            id    : 'alternateName',
            key   : 'alternateName',
            input : true,
            label : 'Titre'
        }
    },
    {
        type       : Form.SEARCH_SELECTOR ,
        path       : api.thesaurus.materials ,
        clearLabel : 'Vider' ,
        closeLabel : 'Fermer' ,
        init       :
        {
            id         : 'material',
            key        : 'material',
            label      : 'Matériel',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            required   : false,
            type       : 'search',
            variant    : 'outlined'
        },
        inputProps :
        {
            label : 'Sélectionner une matière...'
        }
    },
    {
        type       : Form.SEARCH_SELECTOR ,
        path       : api.thesaurus.conceptualObject_technic ,
        clearLabel : 'Vider' ,
        closeLabel : 'Fermer' ,
        init       :
        {
            id         : 'technique',
            key        : 'technique',
            label      : 'Technique',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            required   : false,
            type       : 'search',
            variant    : 'outlined'
        },
        inputProps :
        {
            label : 'Sélectionner une technique...'
        }
    },
    {
        type : Form.I18N_EDITOR ,
        init :
        {
            id    : 'description',
            key   : 'description',
            input : true,
            label : 'Description'
        }
    }
];

const materials =
{
    add :
    {
        elements ,
        agree       : 'Créer' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour ajouter un matériau et une technique employés pour la réalisation de cet objet.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...' ,
        success     : 'Ajout de cet élément réussi!' ,
        title       : 'Ajouter un matériau et une technique',
    },
    edit :
    {
        elements,
        agree       : 'Sauver' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour modifier cet enregistrement.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...'  ,
        success     : 'Mise à jour réussie!' ,
        title       : 'Modifier',
    },
    remove :
    {
        agree       : 'Supprimer' ,
        description : 'Souhaitez-vous supprimer cette entrée :' ,
        disagree    : 'Annuler' ,
        iconifiable : false,
        item        : { render : item => <Material thing={item} editable={false} style={{ marginLeft:24 }} /> },
        loading     : 'Suppression en cours...' ,
        title       : 'Supprimer cet enregistrement ?',
        titleX      : 'Supprimer ces enregistrements ?'
    },
    title    : 'Matériaux et techniques' ,
    tooltips :
    {
        add    : "Ajouter" ,
        edit   : "Éditer" ,
        remove : "Supprimer"
    }
};

export default materials ;
