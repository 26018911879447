import React from 'react'

import compose from 'vegas-js-core/src/functors/compose'

import initApp from '../../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import PeopleIcon from '@material-ui/icons/People'

import PropertyValue from '../../../things/PropertyValue'
import Person        from '../../../things/Person'
import Thing         from '../../../things/Thing'

import ThingChildren, { styles } from '../ThingChildren'

class Employees extends ThingChildren
{
    addMapUri = ( item , uri ) =>
    {
        if( item instanceof PropertyValue)
        {
            const { value } = item ;
            if( value instanceof Thing )
            {
                uri += '/' + value.id ;
            }
        }
        return uri ;
    };

    addPrepare = item => ( { value : item.value } );

    getLocale = () => this.props.locale.things.organization.employees ;
}

Employees.defaultProps =
{
    ...ThingChildren.defaultProps ,
    clazz      : Person ,
    emptyClazz : PropertyValue ,
    emptyIcon  : null ,
    icon       : <PeopleIcon/> ,
    member     : 'employees'
};

Employees.propTypes =
{
    ...ThingChildren.propTypes
};

export default compose( withStyles( styles ) , withRouter , initApp )( Employees );