import table from './table'

const organizations =
{
    ...table,
    helmet :
    {
        title : 'Organisations' ,
        metas : []
    },
    empty  : 'Aucune organisation trouvée',
    search : 'Rechercher une organisation ...' ,
    title  : 'Organisations'
};

export default organizations ;
