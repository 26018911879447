import dialogType from '../../../../../../../display/dialogs/dialogType'
import Method     from '../../../../../../../net/Method'
import Word       from '../../../../../../../things/Word'

const sampling =
{
    title : 'Matrice(s) prélevée(s)' ,
    edit  :
    {
        agree       : 'Enregistrer' ,
        description : 'Sélectionner des prélèvements dans la liste ci-dessous.',
        disagree    : 'Annuler' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...',
        method      : Method.PATCH,
        mock        : false ,
        populate    : ( item ) =>
        {
            if( item )
            {
                if( item instanceof Array )
                {
                    return item.map( element =>
                    {
                        if( element )
                        {
                            const { additionalType } = element ;
                            if( additionalType )
                            {
                                return new Word(additionalType) ;
                            }
                        }
                        return null ;
                    }).filter( element => element !== null ) ;
                }

                return new Word(item) ;
            }
            return item ;
        },
        refresh : 'Charger',
        success : 'Mise à jour réussie!' ,
        title   : 'Matrice(s) prélevée(s)' ,
        type    : dialogType.THESAURUS_CHECKER
    },
    tooltips :
    {
        add    : "Ajouter" ,
        edit   : "Modifier" ,
        remove : "Supprimer"
    }
};

export default sampling ;
