import Form  from '../../../../display/forms/FormElements'
import Stage from '../../../../things/Stage'
import Step  from '../../../../things/Step'

import api from '../../../../configs/api'

import getLocaleThingLabel from '../../../../things/getLocaleThingLabel'

const elements =
[
    {
        type  : Form.SEARCH_SELECTOR ,
        path  : api.stages.url ,
        clazz : Stage ,
        init  :
        {
            id         : 'stage',
            key        : 'stage',
            label      : 'Point d\'intéret',
            autoFocus  : false,
            fullWidth  : true,
            helperText : 'Obligatoire',
            margin     : 'normal',
            required   : true,
            type       : 'search',
            variant    : 'outlined'
        },
        populate : ( thing , { props : { lang } = {} } = {} ) =>
        {
            if( thing )
            {
                const { stage } = thing ;
                if( stage )
                {
                    thing.name = stage.getLocaleTitle( lang ) ;
                }
            }
        },
        selector : { i18n :
        {
            empty       : 'Aucun point d\'intérêt disponible.' ,
            title       : 'Sélectionner un point d\'intérêt',
            description : 'Veuillez sélectionner un point d\'intérêt ci-dessous.' ,
        }},
        valueLabel : ( thing , { lang } = {} ) =>
        {
            if( thing instanceof Stage )
            {
                return thing.getLocaleTitle( lang ) || '' ;
            }
            return getLocaleThingLabel(thing, lang) || '' ;
        }
    },
    {
        type : Form.I18N_EDITOR ,
        init :
        {
            id       : 'headline',
            key      : 'headline',
            input    : true,
            label    : 'Titre de l\'étape'
        }
    }
];

const getItem = ( { unknown = null } ) =>
({
    text :
    {
        primary : ( element , props ) =>
        {
            if( element instanceof Step )
            {
                const { lang } = props;
                return element.getLocaleTitle( lang );
            }
            return unknown ;
        },
        secondary : ( element , props ) =>
        {
            if( element instanceof Step )
            {
                const { lang } = props;
                return element.getLocaleSubtitle( lang );
            }
            return null ;
        }
    }
});


const step =
{
    add :
    {
        agree       : 'Créer' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour créer une nouvelle étape.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...' ,
        success     : 'Ajout de la nouvelle étape réussie!' ,
        title       : 'Ajouter une étape',
        elements
    },
    remove :
    {
        agree        : 'Supprimer' ,
        description  : 'Souhaitez-vous supprimer l\'étape suivante :' ,
        descriptionX : 'Souhaitez-vous supprimer les étapes suivantes :' ,
        disagree     : 'Annuler' ,
        item         : getItem({ unknown:'Inconnu'}),
        loading      : 'Suppression en cours...' ,
        title        : 'Supprimer l\'étape ?',
        titleX       : 'Supprimer les étapes ?'
    }
};

export default step ;
