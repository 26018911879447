import notEmpty from 'vegas-js-core/src/strings/notEmpty'

import { default as notEmptyArray } from 'vegas-js-core/src/arrays/notEmpty'

import config from '../configs/config'

import sortSource from './sortSource'

const getMediaSource = media =>
{
    if( media )
    {
        const {
            bitrate ,
            contentUrl ,
            duration ,
            encodingFormat ,
            source
        } = media ;

        if( notEmpty(encodingFormat) && notEmpty( contentUrl ) )
        {
            let preferredOrder ;
            let sourcesObj = [] ;

            const type = encodingFormat.split('/') ;
            switch( type[0] )
            {
                case 'audio' :
                {
                    const {
                        audioCodec ,
                        bitsPerSample ,
                        channels ,
                        sampleRate
                    } = media ;

                    sourcesObj =
                    [{
                        audioCodec ,
                        bitrate ,
                        bitsPerSample ,
                        channels ,
                        contentUrl ,
                        duration ,
                        encodingFormat ,
                        sampleRate
                    }] ;
                    preferredOrder = config.preferredMedia.audio ;
                    break ;
                }
                case 'image' :
                {
                    const { height , width } = media ;
                    sourcesObj =
                    [{
                        contentUrl ,
                        encodingFormat ,
                        height ,
                        width
                    }] ;
                    preferredOrder = config.preferredMedia.image ;
                    break ;
                }
                case 'video' :
                {
                    sourcesObj =
                    [{
                        contentUrl ,
                        encodingFormat
                    }] ;
                    preferredOrder = config.preferredMedia.video ;
                    break ;
                }
                default :
                {
                    break ;
                }
            }

            if( notEmptyArray( source ) )
            {
                sourcesObj = [ ...sourcesObj , ...source ] ;
            }

            return sourcesObj.sort( sortSource( preferredOrder ) ).reverse() ;
        }
    }

    return null ;
}

export default getMediaSource ;
