import React from 'react'

import notEmpty from 'vegas-js-core/src/strings/notEmpty'

import Icon from '@material-ui/icons/Note'

import withDatasList from './withDatasList'

import ThingsList from './ThingsList'

import Word from '../../things/Word'

import AddThesaurusDialog    from '../dialogs/add/AddThesaurusDialog'
import EditThesaurusDialog   from '../dialogs/edit/EditThesaurusDialog'
import RemoveThesaurusDialog from '../dialogs/remove/RemoveThesaurusDialog'

import WordCell from '../cells/WordCell'
import Thing from '../../things/Thing'

class ThesaurusList extends ThingsList
{
    getUri = () =>
    {
        const { location } = this.props ;
        if( location )
        {
            const { pathname } = location ;
            return notEmpty( pathname ) ? pathname : null ;
        }
        return null ;
    };

    addUri = this.getUri ;

    removeUri = this.getUri ;

    getEntry = init =>
    {
        let empty = {} ;
        if( init instanceof Thing )
        {
            empty = init.toObject() ;
        }
        else if( init )
        {
            empty = { ...init } ;
        }
        empty = new Word(empty) ;
        return empty ;
    };

    getLocale = () => this.props.locale.display.lists.thesaurus ;

    getQueries = () =>
    {
        let { lang, queries } = this.props ;
        if( lang )
        {
            queries = { ...queries , sort : lang } ;
        }
        return queries ;
    };

    getSubtitle = () =>
    {
        const { object } = this.state ;
        // console.log( this + ' getSubtitle' , object ) ;
        if( object )
        {
            const { category } = object ;
            if( category )
            {
                return category ;
            }
        }
        return null ;
    };

    onEdit = item =>
    {
        if( item instanceof Thing )
        {
            item = item.toObject() ;
        }

        let { things } = this.state ;

        if( things instanceof Array && things.length > 0)
        {
            things = things.map( element =>
            {
                if( element.id === item.id )
                {
                    if( element instanceof Word )
                    {
                        element = new Word( item ) ;
                    }
                }
                return element ;
            });

            if( this._mounted )
            {
                this.setState( { things } );
            }
        }
    };

    onSelect = item => this.openEditDialog(item) ;
}

ThesaurusList.defaultProps =
{
    ...ThingsList.defaultProps ,

    AddDialogComponent    : AddThesaurusDialog,
    EditDialogComponent   : EditThesaurusDialog,
    RemoveDialogComponent : RemoveThesaurusDialog ,

    addMode : 'reload',
    clazz   : Word ,
    icon    : <Icon />,

    CellComponent : WordCell,
    cellMode      : 'button',
    cellProps     : { titleProps : { className:'cursor-pointer' , underline:'none' }  } ,
};

ThesaurusList.propTypes =
{
    ...ThingsList.propTypes
};

export default withDatasList()( ThesaurusList ) ;
