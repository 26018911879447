import React from 'react'

import { withRouter } from 'react-router-dom'

import isNumber from 'vegas-js-core/src/isNumber'
import notEmpty from 'vegas-js-core/src/strings/notEmpty'

import URLSearchParams   from '@ungap/url-search-params'

import SearchContext from './SearchContext'

import RoleableContainer from '../../components/containers/RoleableContainer'

import defaultProperties from './defaultProperties'

class SearchProvider extends RoleableContainer
{
    constructor( props )
    {
        super(props);
        this.state =
        {
            ...defaultProperties,
            closeSearch : this.close,
            initSearch  : this.initialize,
            openSearch  : this.open,
            resetSearch : this.reset,
            setSearch   : this.set
        };
    }

    close = ( callback = null ) =>
    {
        this.setState({ page:null, searchBar:false, search:null }, callback );
    };

    componentDidUpdate( prevProps, prevState )
    {
        const { location : { search:query } = null } = this.props ;

        if( prevProps.location.search !== query )
        {
            // console.log( this + ' componentDidUpdate, query change',  prevProps.location.search , '->' , query )

            const params = new URLSearchParams(query) ;

            let {
                facets,
                limit,
                page,
                search,
                sort
            } = this.state ;

            let init, value;

            value = params.get('facets') ;
            if( facets !== value )
            {
                // console.log( this + ' componentDidUpdate, search.facets change' , facets , '->' , value ) ;
                init = { ...init , facets : value } ;
            }

            value = params.has('limit') ? parseInt( params.get('limit') ) : defaultProperties.limit ;
            if( limit !== value )
            {
                // console.log( this + ' componentDidUpdate, search.limit change' , limit , '->' , value ) ;
                init = { ...init , limit : value } ;
            }

            value = params.has('page') ? parseInt( params.get('page') ) : defaultProperties.page ;
            if( page !== value )
            {
                // console.log( this + ' componentDidUpdate, search.page change' , page, '->' , value ) ;
                init = { ...init , page : value } ;
            }

            value = params.get('search') ;
            if( search !== value )
            {
                // console.log( this + ' componentDidUpdate, search.search param change' , search, '->' , value ) ;
                init = { ...init , search : value } ;
            }

            value = params.get('sort') ;
            if( sort !== value )
            {
                // console.log( this + ' componentDidUpdate, search.sort param change' , sort, '->' , value ) ;
                init = { ...init , sort : value } ;
            }

            if( init )
            {
                this.setState({ ...init } ) ;
            }
        }
        else
        {
            const {
                facets,
                limit,
                page,
                search,
                sort
            } = this.state ;

            if(
                facets !== prevState.facets ||
                limit  !== prevState.limit  ||
                page   !== prevState.page   ||
                search !== prevState.search ||
                sort   !== prevState.sort
            )
            {
                const params = new URLSearchParams(query) ;

                if( facets !== prevState.facets )
                {
                    // console.log( this + ' componentDidUpdate #facets' , prevState.facets , "->" , facets ) ;
                    params.delete('facets') ;
                    if( notEmpty(facets) )
                    {
                        params.set( 'facets' , facets ) ;
                    }
                }

                if( limit !== prevState.limit )
                {
                    // console.log( this + ' componentDidUpdate #limit' , prevState.limit , "->" , limit ) ;
                    params.delete('limit') ;
                    if( isNumber(limit) )
                    {
                        params.set( 'limit' , String(limit) ) ;
                    }
                }

                if( page !== prevState.page )
                {
                    // console.log( this + ' componentDidUpdate #page' , prevState.page ,  "->" , page ) ;
                    params.delete('page') ;
                    if( isNumber(page) )
                    {
                        params.set( 'page' , String(page) ) ;
                    }
                }

                if( search !== prevState.search )
                {
                    // console.log( this + ' componentDidUpdate #search' , prevState.search , "->" , search ) ;
                    params.delete('search') ;
                    if( notEmpty( search ) )
                    {
                        params.set( 'search' , search ) ;
                    }
                }

                if( sort !== prevState.sort )
                {
                    // console.log( this + ' componentDidUpdate #sort' , prevState.sort , "->" , sort ) ;
                    params.delete('sort') ;
                    if( notEmpty(sort) )
                    {
                        params.set( 'sort' , sort ) ;
                    }
                }

                // console.log( this + ' componentDidUpdate replace query:' ,  params.toString() ) ;

                const {  history } = this.props ;
                if( history )
                {
                    history.replace({ search:params.toString() }) ;
                }
            }
        }
    }

    initialize = ( { searchLabel = null , searchable = true , callback = null } ) =>
    {
        const { location : { search:query } = null } = this.props ;

        let {
            facets,
            limit,
            page,
            search,
            sort
        }
        = defaultProperties ;

        let init ;

        if( query )
        {
            const params = new URLSearchParams(query) ;

            // console.log( this + ' initialize uri:' , query ) ;

            if( params.has('facets') )
            {
                facets = params.get('facets') ;
                if( facets !== this.state.facets )
                {
                    // console.log( this + ' initialize #facets' , this.state.facets , "->" , facets ) ;
                    init = { ...init , facets } ;
                }
            }

            if( params.has('limit') )
            {
                limit = parseInt(params.get('limit')) ;
                if( isNaN(limit) )
                {
                    limit = defaultProperties.limit ;
                }
                else if( limit < 0 )
                {
                    limit = 0 ;
                }
                if( limit !== this.state.limit )
                {
                    // console.log( this + ' initialize #limit' , this.state.limit , "->" , limit ) ;
                    init = { ...init , limit } ;
                }
            }

            if( params.has('page'))
            {
                page = parseInt(params.get('page')) ;
                if( isNaN(page) )
                {
                    page = defaultProperties.page ;
                }
                else if( page < 0 )
                {
                    page = 0 ;
                }
                if( page !== this.state.page )
                {
                    // console.log( this + ' initialize #page' , this.state.page , "->" , page ) ;
                    init = { ...init , page } ;
                }
            }

            if( params.has('search'))
            {
                search = params.get('search') ;
                if( search !== this.state.search )
                {
                    // console.log( this + ' initialize #search' , this.state.search , "->" , search ) ;
                    init = { ...init , search } ;
                }
            }

            if( params.has('sort'))
            {
                // console.log( this + ' initialize #sort' , this.state.sort , "->" , sort ) ;
                sort = params.get('sort') ;
                if( sort !== this.state.sort )
                {
                    init = { ...init , sort } ;
                }
            }
        }

        this.setState(
        {
            ...init,
            searchable,
            searchLabel,
            searchBar : !!(search)
        } ,
        callback) ;
    };

    open = () => { this.setState({ searchBar:true }); };

    render()
    {
        const { children } = this.props;
        return (
            <SearchContext.Provider value={ this.state }>
                { children }
            </SearchContext.Provider>
        )
    }

    reset = ( callback = null ) =>
    {
        this.setState(
            {
                facets      : null,
                limit       : parseInt(process.env.REACT_APP_DEFAULT_LIMIT),
                page        : null,
                searchable  : false,
                searchBar   : false,
                searchLabel : null,
                search      : null,
                sort        : null
            },
            callback
        );
    };

    set = options =>
    {
        this.setState({ ...options });
    };
}

export default withRouter(SearchProvider) ;
