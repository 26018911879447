import activities        from './activities'
import applications      from './applications'
import articles          from './articles'
import audios            from './audios'
import conceptualObjects from './conceptualObjects'
import courses           from './courses'
import diseases          from './diseases'
import events            from './events'
import favorites         from './favorites'
import games             from './games'
import list              from './list'
import livestocks        from './livestocks'
import mediaObjects      from './mediaObjects'
import observations      from './observations'
import organizations     from './organizations'
import people            from './people'
import photos            from './photos'
import places            from './places'
import stages            from './stages'
import teams             from './teams'
import technicians       from './technicians'
import thesaurus         from './thesaurus'
import users             from './users'
import veterinarians     from './veterinarians'
import videos            from './videos'

export default
{
    activities,
    applications,
    articles,
    audios,
    conceptualObjects,
    courses,
    diseases,
    events,
    favorites,
    games,
    list,
    livestocks,
    mediaObjects,
    observations,
    organizations,
    people,
    photos,
    places,
    stages,
    teams,
    technicians,
    thesaurus,
    users,
    veterinarians,
    videos
};
