import React from 'react'

import CategoryIcon from '@material-ui/icons/Category'

import withDatasList from './withDatasList'

import ThingsList from './ThingsList'

import AddConceptualObjectDialog    from '../dialogs/add/AddConceptualObjectDialog'
import ConceptualObject             from '../../things/ConceptualObject'
import ConceptualObjectCell         from '../cells/ConceptualObjectCell'
import RemoveConceptualObjectDialog from '../dialogs/remove/RemoveConceptualObjectDialog'

class ConceptualObjectsList extends ThingsList
{
    getLocale = () => this.props.locale.display.lists.conceptualObjects ;

    getThingRef = () => this.props.thing ;
}

ConceptualObjectsList.defaultProps =
{
    ...ThingsList.defaultProps ,
    AddDialogComponent    : AddConceptualObjectDialog,
    CellComponent         : ConceptualObjectCell ,
    cellProps             : { variant : 'default' },
    clazz                 : ConceptualObject,
    defaultIcon           : <CategoryIcon/>,
    icon                  : <CategoryIcon/>,
    preferable            : true ,
    RemoveDialogComponent : RemoveConceptualObjectDialog
};

ConceptualObjectsList.propTypes =
{
    ...ThingsList.propTypes
};

export default withDatasList()( ConceptualObjectsList ) ;
