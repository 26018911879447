import React from "react"

import notEmpty from 'vegas-js-core/src/strings/notEmpty'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import DomainIcon from '@material-ui/icons/Domain'
import InfoIcon   from '@material-ui/icons/Info'
import PeopleIcon from '@material-ui/icons/People'
import MediasIcon from '@material-ui/icons/PermMedia'

import ThingView , { defaultStyle } from '../../display/views/ThingView'

import About       from './view/About'
import Authorities from './view/Authorities'
import Info        from './view/Info'
import Medias      from '../medias/Medias'

import api   from '../../configs/api'
import paths from '../../configs/paths'

import OrganizationVO from '../../things/Organization'
import Thing          from '../../things/Thing'

import initView from '../../display/views/initView'

const styles = defaultStyle();

class Organization extends ThingView
{
    getLocale = () => this.props.locale.views.organization ;

    getTitle = thing =>
    {
        if( thing instanceof Thing )
        {
            const { lang } = this.props ;

            let label ;

            label = thing.getLocaleAlternateName( lang ) ;
            if( notEmpty(label) )
            {
                return ucFirst(label)  ;
            }

            label = thing.getLocaleName(lang) ;
            if( notEmpty(label) )
            {
                return ucFirst(label)  ;
            }
        }
        return null ;
    };
}

Organization.defaultProps =
{
    ...ThingView.defaultProps,
    back       : true ,
    clazz      : OrganizationVO ,
    icon       : <DomainIcon/>,
    path       : api.organizations,
    preferable : true ,
    responsive : true ,
    routes     :
    [
        { id : 'about'       , RouteComponent : About       } ,
        { id : 'info'        , RouteComponent : Info        } ,
        { id : 'authorities' , RouteComponent : Authorities } ,
        { id : 'medias'      , RouteComponent : Medias      }
    ],
    tabs :
    [
        { id : 'about'       , icon : <DomainIcon className='pr-8' /> } ,
        { id : 'info'        , icon : <InfoIcon   className='pr-8' /> } ,
        { id : 'authorities' , icon : <PeopleIcon className='pr-8' /> } ,
        { id : 'medias'      , icon : <MediasIcon className='pr-8' /> }
    ],
    titleAuto : false // Important to use always the getTitle() method
};

Organization.propTypes =
{
    ...ThingView.propTypes,
    path : paths.organizations
};

export default initView( Organization, styles );
