import initDialog from '../initDialog'

import { AddDialog } from '../AddDialog'

import Thing from "../../../things/Thing"

import api from '../../../configs/api'

const styles = () => ({});

export class AddPlaceDialog extends AddDialog
{
    getLocale = () => this.props.locale.dialogs.application.place.add ;

    prepare = item =>
    {
        let {
            additionalType,
            geo ,
            name
        } = item || {} ;

        if( !geo )
        {
            geo = {} ;
        }

        const {
            elevation,
            latitude,
            longitude
        } = geo ;

        return (
        {
            additionalType : additionalType instanceof Thing ? String(additionalType.id) : null ,
            name,
            elevation,
            latitude,
            longitude
        });
    };
}

AddPlaceDialog.defaultProps =
{
    ...AddDialog.defaultProps,
    uri : api.places.url
};

AddPlaceDialog.propTypes =
{
    ...AddDialog.propTypes
};

export default initDialog({ styles })( AddPlaceDialog ) ;
