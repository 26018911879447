import React, { Component } from 'react';

import clsx from 'clsx'

import PropTypes from 'prop-types'

import initApp from '../../contexts/app/initApp'

import { withStyles } from '@material-ui/core/styles'

import { Typography } from '@material-ui/core'

const styles = theme =>
({
    root :
    {
        '& .logo-icon' :
        {
            marginLeft  : 4 ,
            width       : 30,
            height      : 30,
            transition  : theme.transitions.create(['width', 'height'],
            {
                duration: theme.transitions.duration.shortest,
                easing  : theme.transitions.easing.easeInOut
            })
        },
    }
});

class NavbarHeader extends Component
{
    render()
    {
        const {
            classes,
            config,
            locale,
        } = this.props ;

        let label ;

        if( locale )
        {
            const { title } = locale ;
            if( title )
            {
                label = (
                <Typography color='textPrimary' className="text-2xl ml-12 font-normal font-light logo-text" >
                    { title }
                </Typography>
                );
            }
        }

        return (
            <div className={ clsx(classes.root , "flex items-center" )}>
                <img className="logo-icon" src={ config.assets.icon } alt='logo' />
                { label }
            </div>
        );
    }
}

NavbarHeader.defaultProps =
{
};

NavbarHeader.propTypes =
{
    classes : PropTypes.object.isRequired
};

export default withStyles( styles )( initApp(NavbarHeader) );
