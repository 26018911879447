import media from './media'

const video =
{
    ...media,
    failed   : 'Impossible d\'ajouter une vidéo',
    remove :
    {
        agree       : 'Supprimer' ,
        description : 'Souhaitez-vous supprimer cette vidéo ?' ,
        disagree    : 'Annuler' ,
        loading     : 'Suppression en cours...' ,
        title       : 'Supprimer la vidéo ?',
        useID       : false
    },
    removed  : 'Vidéo supprimée',
    success  : 'Vidéo ajoutée avec succès',
    title    : 'Vidéo'
};

export default video ;