import React from 'react'

import { Link } from 'react-router-dom'

import CloudCircleIcon from '@material-ui/icons/CloudCircle'
import ExitToAppIcon   from '@material-ui/icons/ExitToApp'
import FavoriteIcon    from '@material-ui/icons/Favorite'
import LineWeightIcon  from '@material-ui/icons/LineWeight'
import PersonIcon      from '@material-ui/icons/Person'

import logout from '../../../../net/auth/logout'

const account =
{
    menu :
    [
        {
            label  : 'Mon Profil',
            icon   : <PersonIcon className='mr-16' fontSize='small' />,
            action : null,
            options :
            {
                component : Link,
                to        : '/me/profile'
            }
        },
        {
            label  : 'Mes Favoris',
            icon   : <FavoriteIcon className='mr-16' fontSize='small' />,
            action : null,
            options :
            {
                component : Link,
                to        : '/me/favorites'
            }
        },
        {
            label  : 'Mes Activités',
            icon   : <LineWeightIcon className='mr-16' fontSize='small' />,
            action : null,
            options :
            {
                component : Link,
                to        : '/me/activities'
            }
        },
        {
            label  : 'Mes Sessions',
            icon   : <CloudCircleIcon className='mr-16' fontSize='small' />,
            action : null,
            options :
            {
                component : Link,
                to        : '/me/sessions'
            }
        },
        {
            label  : 'Déconnexion',
            icon   : <ExitToAppIcon className='mr-16' fontSize='small' />,
            action : logout
        }
        
    ]
    
};

export default account ;