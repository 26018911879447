import React from 'react'

import format from 'vegas-js-core/src/strings/fastformat'

import { Grid } from '@material-ui/core'

import initApp from '../../../../contexts/app/initApp'

import Topics from './support/Topics'

import ThingSubContainer from '../../../containers/ThingSubContainer'

const half =
{
    md :  6 ,
    sm : 12 ,
    xs : 12
};

class SupportSetting extends ThingSubContainer
{
    render = () =>
    {
        const {
            path,
            thing
        } = this.props ;

        if( thing )
        {
            const {
                id,
                setting
            } = thing ;

            return (
                <div className='w-full h-full p-16'>

                    <Grid container spacing={4}>

                        <Grid item {...half}>
                            <Topics
                                onChange = { this.changeProperty('setting' , true) }
                                thing    = { setting }
                                uri      = { format(path.setting,id) }
                            />
                        </Grid>

                    </Grid>

                </div>
            );
        }

        return null;
    }
}

SupportSetting.defaultProps =
{
    ...ThingSubContainer.defaultProps
};

SupportSetting.propTypes =
{
    ...ThingSubContainer.propTypes
};

export default initApp(SupportSetting) ;
