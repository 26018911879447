import list from './list'

const favorites =
{
    ...list,
    helmet :
    {
        title : 'Mes Favoris' ,
        metas : []
    },
    empty  : 'Aucun favori trouvé',
    search : 'Rechercher un favori ...' ,
    title  : 'Mes Favoris'
};

export default favorites ;
