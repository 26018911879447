import React from 'react'

import compose from 'vegas-js-core/src/functors/compose'

import initApp from '../../../../../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import blueGrey from '@material-ui/core/colors/blueGrey'

import { FaPrescription } from 'react-icons/fa'

import ChipsContainer, { styles } from '../../../../../containers/ChipsContainer'

import AnnualHealthReview from '../../../../../../things/livestock/reviews/AnnualHealthReview'
import SectorReview       from '../../../../../../things/livestock/reviews/SectorReview'
import Sector             from '../../../../../../things/Sector'
import Thing              from '../../../../../../things/Thing'
import Word               from '../../../../../../things/Word'

const css = () => (
{
    ...styles,
    chip :
    {
        backgroundColor : blueGrey[100],
        margin          : 8
    }
});

class Preventions extends ChipsContainer
{
    getEntry = init =>
    {
        const { subjectOf } = this.props ;

        let empty = {} ;

        if( init instanceof Thing )
        {
            empty = init.toObject() ;
        }
        else if( init )
        {
            empty = { ...init } ;
        }

        return new SectorReview( { ...empty , subjectOf } ) ;
    };

    getLocale = () => this.props.locale.things.livestock.reviews.annual.medical.preventions ;

    editInit = ( item ) =>
    {
        if( item )
        {
            const { preventions } = item ;
            if( preventions instanceof Array )
            {
                return preventions.map( element =>
                {
                    if( element instanceof Word )
                    {
                        return element.clone() ;
                    }
                    return null ;
                })
            }
        }
        return null ;
    };

    onEdit = ( item ) =>
    {
        const { onChange } = this.props ;
        if( onChange )
        {
            onChange( item ) ;
        }
    };

    editPrepare = ( datas , props ) =>
    {
        const { item } = props ;
        if( item )
        {
            let { subjectOf:review } = item ;
            if( review instanceof AnnualHealthReview )
            {
                const { about } = item ;
                if( about instanceof Sector )
                {
                    const { id } = about ;
                    if( id )
                    {
                        let { subReview } = review ;
                        if( subReview instanceof Array )
                        {
                            let len1 = subReview.length ;
                            for( let i = 0 ; i<len1 ; i++ )
                            {
                                let workplace = subReview[i] ;
                                if( workplace )
                                {
                                    let { subReview } = workplace ;
                                    if( subReview instanceof Array )
                                    {
                                        let len2 = subReview.length ;
                                        for( let j = 0 ; j<len2 ; j++  )
                                        {
                                            let sector = subReview[j] ;
                                            if( sector )
                                            {
                                                let { about } = sector ;
                                                if( about )
                                                {
                                                    let { id:index } = about ;
                                                    if( index === id )
                                                    {
                                                        sector.preventions = datas ;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                review = review.toObject(false) ;
            }

            review = JSON.stringify(review) ;

            return { review } ;
        }

        return null ;
    };
}

Preventions.defaultProps =
{
    ...ChipsContainer.defaultProps ,
    chipVariant : 'default' ,
    icon        : <FaPrescription size={20}/>,
    member      : 'preventions'
};

Preventions.propTypes =
{
    ...ChipsContainer.propTypes
};

export default compose( withStyles( css ) , withRouter , initApp )( Preventions ) ;
