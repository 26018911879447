import React , { Fragment } from 'react'

import clsx from 'clsx'

import format from 'vegas-js-core/src/strings/fastformat'

import DescriptionIcon  from '@material-ui/icons/Notes'
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes'
import TextIcon         from '@material-ui/icons/Subject'
import TitleIcon        from '@material-ui/icons/Title'

import withi18n from '../../../contexts/i18n/withi18n'

import TransitionGroup from '../../../transitions/TransitionGroup'

import MultiLangEditor   from '../../../components/editors/MultiLangEditor'
import ThingSubContainer from '../../../display/containers/ThingSubContainer'

import Actor          from '../../../display/things/observation/Actor'
import Address        from '../../../display/things/Address'
import Attendee       from '../../../display/things/observation/Attendee'
import Authority      from '../../../display/things/observation/Authority'
import GeoCoordinates from '../../../display/things/GeoCoordinates'
import Metadatas      from '../../../display/things/Metadatas'
import Observation    from '../../../display/things/Observation'

class About extends ThingSubContainer
{
    getLocale = () => this.props.locale.views.observation.about ;

    render = () =>
    {
        const { thing } = this.props ;

        if( thing )
        {
            const {
                animate,
                animateProps,
                className,
                onChange,
                path,
                style
            } = this.props ;

            const locale = this.getLocale() ;

            const {
                alternateName,
                description,
                location,
                notes,
                text,
                id
            } = thing ;

            let address ;
            let geo ;

            if( location )
            {
                const { address:a , geo:g } = location ;
                address = a ;
                geo     = g ;
            }

            let view = (
                <div className="flex-1 flex flex-col space-y-24">

                    <Metadatas
                        activable = { false }
                        thing     = { thing }
                        uri       = { format(path.observation,id) }
                    />

                    <Observation
                        editUri  = { format(path.observation, id) }
                        onChange = { onChange }
                        thing    = { thing }
                    />

                    <MultiLangEditor
                        html     = { false }
                        icon     = { <TitleIcon/> }
                        input    = { true }
                        locale   = { locale.alternateName }
                        onChange = { this.changeProperty('alternateName') }
                        text     = { alternateName }
                        uri      = { format(path.alternateName, id ) }
                        verbose  = { true }
                    />

                    <div className='grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-16'>

                        <Authority
                            onChange = { this.change }
                            thing    = { thing }
                            uri      = { path.authority }
                        />

                        <MultiLangEditor
                            html     = { false }
                            icon     = { <DescriptionIcon/> }
                            locale   = { locale.description }
                            onChange = { this.changeProperty('description') }
                            text     = { description }
                            uri      = { format(path.description, id ) }
                        />

                        {
                            location &&
                            <Fragment>
                                <Address
                                    editable = { false }
                                    thing    = { address }
                                />
                                <GeoCoordinates
                                    editable = { false }
                                    thing    = { geo }
                                />
                            </Fragment>
                        }

                        <Actor
                            onChange = { this.change }
                            thing    = { thing }
                            uri      = { path.actor }
                        />

                        <Attendee
                            onChange = { this.change }
                            thing    = { thing }
                            uri      = { path.attendee }
                        />

                    </div>

                    <MultiLangEditor
                        icon     = { <TextIcon/> }
                        locale   = { locale.text }
                        onChange = { this.changeProperty('text') }
                        text     = { text }
                        uri      = { format( path.text, id ) }
                    />

                    <MultiLangEditor
                        icon     = { <SpeakerNotesIcon/> }
                        locale   = { locale.notes }
                        onChange = { this.changeProperty('notes') }
                        text     = { notes }
                        uri      = { format(path.notes, id ) }
                    />

                </div>
            );

            if( animate )
            {
                view = (
                    <TransitionGroup
                        duration = { 250 }
                        enter    = {{ animation: "transition.slideUpIn" }}
                        { ...animateProps }
                    >
                        { view }
                    </TransitionGroup>
                )
            }

            return (
            <div
                className = { clsx( 'flex-1 p-12' , className ) }
                style     = { style }
            >
                { view }
            </div>
            );
        }

        return null ;
    }
}

About.defaultProps =
{
    ...ThingSubContainer.defaultProps
};

About.propTypes =
{
    ...ThingSubContainer.propTypes
};

export default withi18n(About) ;
