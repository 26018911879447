//import VersionInput from '../../../components/inputs/VersionInput'

import Form from '../../../display/forms/FormElements'

import Method from '../../../net/Method'

import oauth     from './application/oauth'
import producer  from './application/producer'
import publisher from './application/publisher'
import settings  from './application/settings'
import sponsor   from './application/sponsor'

import Thing from '../../../things/Thing'
import Word  from '../../../things/Word'

import api from '../../../configs/api'

const elements =
[
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'name',
            key          : 'name',
            label        : 'Nom',
            autoFocus    : false,
            fullWidth    : true,
            helperText   : null,
            margin       : 'normal',
            required     : true,
            select       : false,
            autoComplete : 'off' ,
            type         : 'text',
            variant      : 'outlined'
        }
    },
    {
        type       : Form.SEARCH_SELECTOR ,
        path       : api.thesaurus.applications_types ,
        clearLabel : 'Vider' ,
        closeLabel : 'Fermer' ,
        init       :
        {
            id         : 'additionalType',
            key        : 'additionalType',
            label      : 'Type d\'application',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            required   : true,
            type       : 'search',
            variant    : 'outlined'
        },
        inputProps :
        {
            label : 'Type d\'application...'
        },
        limit  : 0,
        logics :
        {
            disabledSuggestions : props =>
            {
                let suggestions = [] ;
                const { rel } = props ;
                if( rel instanceof Thing )
                {
                    const { additionalType } = rel ;
                    if( additionalType instanceof Word )
                    {
                        suggestions.push( additionalType ) ;
                    }
                }
                return suggestions ;
            }
        }
    },
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id         : 'version',
            key        : 'version',
            label      : 'Version',
            autoFocus  : false,
            fullWidth  : true,
            helperText : null,
            // InputProps :
            // {
            //     inputComponent : VersionInput
            // },
            margin       : 'normal',
            required     : false,
            select       : false,
            autoComplete : 'off' ,
            type         : 'text',
            variant      : 'outlined'
        }
    },
];

const application =
{
    // dependencies

    oauth,
    producer,
    publisher,
    settings,
    sponsor,

    // settings
    
    edit :
    {
        elements,
        agree       : 'Sauver' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour modifier cette application.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...' ,
        method      : Method.PATCH ,
        success     : 'Mise à jour réussie!' ,
        title       : 'Modifier l\'application'
    },
    labels :
    {
        additionalType : 'Type d\'application' ,
        empty          : '----' ,
        name           : 'Nom',
        version        : 'Version'
    },
    title    : 'Application' ,
    tooltips :
    {
        add    : 'Ajouter',
        edit   : 'Modifier',
        remove : 'Supprimer'
    }
};

export default application ;