import table from './table'

const games =
{
    ...table,
    empty  : 'Aucun jeu trouvé',
    search : 'Rechercher un jeu ...' ,
    title  : 'Jeux'
};

export default games ;
