import React from 'react'

import initDialog from '../initDialog'

import { RemoveDialog } from '../RemoveDialog'

import MediaObjectCell from "../../cells/MediaObjectCell"

import RequestStatus from '../../../net/RequestStatus'

import api from '../../../configs/api'

import getMediaAvatar from '../../medias/getMediaAvatar'

import MediaObject from '../../../things/creativework/MediaObject'

const styles = () => ({});

class RemoveMediaObjectDialog extends RemoveDialog
{
    getContent = () =>
    {
        const { status } = this.state ;
        const progress = status === RequestStatus.PROGRESS ;
        if( !progress )
        {
            const { item } = this.props;
            if( item )
            {
                const multiple = this.multiple ;
                if( multiple && item instanceof Array && item.length > 0 )
                {
                    return (
                        <div className='flex flex-col w-full'>
                        {
                            item.map( (element , index ) => (
                                <MediaObjectCell
                                    avatarable   = { false }
                                    className    = 'py-8'
                                    key          = { index }
                                    left         = { false }
                                    disabled     = { false }
                                    divider      = { true }
                                    dividerProps = {{ className : 'mt-8'}}
                                    footer       = { false }
                                    mode         = 'label'
                                    thing        = { element }
                                    variant      = 'default'
                                />
                            ))
                        }
                        </div>
                    )
                }
                else
                {
                    return (
                        <MediaObjectCell
                            avatarable = { false }
                            disabled   = { false }
                            left       = { false }
                            mode       = 'label'
                            thing      = { item }
                            variant    = 'default'
                        />
                    )
                }
            }

        }
        return null ;
    }

    getIcon = () =>
    {
        let { icon, useIcon } = this.props ;
        if( useIcon )
        {
            const { item } = this.props;
            if( item instanceof MediaObject )
            {
                icon = getMediaAvatar( item , { className:'ml-0 mr-12'} ) ;
            }
            if( icon instanceof Function )
            {
                icon = icon( this.props ) ;
            }
            return icon ;
        }
        return null ;
    };

    getLocale = () => this.props.locale.dialogs.application.mediaObject.remove ;
}

RemoveMediaObjectDialog.defaultProps =
{
    ...RemoveDialog.defaultProps,
    iconifiable : false ,
    mock        : false ,
    uri         : api.mediaObjects.url,
    useIcon     : true,
    useUrl      : true
};

RemoveMediaObjectDialog.propTypes =
{
    ...RemoveDialog.propTypes
};

export default initDialog({ styles })( RemoveMediaObjectDialog );
