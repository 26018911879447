const sort =
{
    agree    : 'Trier' ,
    disagree : 'Annuler' ,
    empty    : 'Aucun élément',
    title    : 'Trier' ,
    subtitle : 
    {
        asc  : 'Ordre ascendant' ,
        desc : 'Ordre descendant' 
    },
    tooltips :
    {
        order  : 'Changer l\'ordre' 
    },
    unknown : 'Inconnu' 
};

export default sort ;