import React from 'react'

import PropTypes from 'prop-types'

import clsx from 'clsx'

import notEmpty from 'vegas-js-core/src/strings/notEmpty'

import { Card, CardContent, Divider } from '@material-ui/core'

import grey from '@material-ui/core/colors/grey'

import ThingComponent from '../things/ThingComponent'
import TitleHeader    from '../../components/headers/TitleHeader'

export const styles = theme =>
({
    disabled :
    {
        //opacity : 0.6
    },
    expandClose :
    {
        duration   : theme.transitions.duration.shortest,
        marginLeft : 'auto',
        transform  : 'rotate(0deg)',
        transition : theme.transitions.create('transform', {})
    },
    expandOpen :
    {
        transform : 'rotate(180deg)',
    },
    even :
    {
        backgroundColor: grey[50]
    },
    odd :
    {
        backgroundColor : 'white'
    },
    root :
    {
        flex         : 1 ,
        marginBottom : 4 ,
        marginTop    : 4
    }
});

export class ThingCard extends ThingComponent
{
    footerOptions = null ;

    getBody = () => null ;

    getFooter = () => null ;

    getHeader = () =>
    {
        const { header } = this.props ;
        if( header )
        {
            let {
                divider,
                editable,
                onClick
            } = this.props ;

            onClick = onClick || this.onClick ;

            if( notEmpty(divider) && (divider !== 'none') )
            {
                divider = (
                    <Divider
                        light     = { true }
                        className = 'my-12'
                        variant   = { divider }
                    />) ;
            }
            else
            {
                divider = null ;
            }


            return (
                <TitleHeader
                    divider     = { divider }
                    editable    = { editable }
                    icon        = { this.getIcon() }
                    label       = { this.getLabel() }
                    LinkOptions = { this.getLinkOptions() }
                    onClick     = { onClick }
                    subLabel    = { this.getSubLabel() }
                    options     = { this.getOptions() }
                />
            );
        }
        return null ;
    };

    getIcon = () => null ;

    getLabel = () => this.props.label ;

    getLinkOptions = () => null ;

    getMedia = () => this.props.media ;

    getOptions = () => this.props.options ;

    getSubLabel = () => this.props.subLabel ;

    onClick = () => () => null ;

    render = () =>
    {
        const {
            classes,
            className,
            disabled,
            index,
            PaperProps
        } = this.props ;

        const body   = this.getBody() ;
        const footer = this.getFooter() ;
        const header = this.getHeader() ;
        const media  = this.getMedia() ;

        let content ;
        if( header || body )
        {
            content = (
                <CardContent>
                    { this.getHeader() }
                    { this.getBody() }
                </CardContent>
            )
        }

        return(
            <Card
                className = { clsx(
                    classes.root ,
                    index%2 === 0 ? classes.odd : classes.even,
                    disabled ? classes.disabled : null ,
                    className
                )}
                { ...PaperProps }
            >
                { media }
                { content }
                { footer }
            </Card>
        );
    }
}

ThingCard.defaultProps =
{
    ...ThingComponent.defaultProps,
    activable     : true,
    deletable     : true,
    disabled      : false,
    divider       : 'fullWidth',
    editable      : true ,
    index         : 0,
    footer        : true,
    footerOptions : null,
    header        : true,
    label         : null,
    media         : null,
    onClick       : null,
    onSelect      : null,
    options       : null,
    PaperProps    : null,
    subLabel      : null
};

ThingCard.propTypes =
{
    ...ThingComponent.propTypes,
    activable     : PropTypes.bool,
    deletable     : PropTypes.bool,
    disabled      : PropTypes.bool,
    divider       : PropTypes.oneOf(['fullWidth','inset','middle','none',null]),
    editable      : PropTypes.bool,
    footer        : PropTypes.bool,
    footerOptions : PropTypes.oneOfType([PropTypes.element,PropTypes.func]) ,
    index         : PropTypes.number,
    header        : PropTypes.bool,
    label         : PropTypes.string,
    media         : PropTypes.element,
    onClick       : PropTypes.func,
    onSelect      : PropTypes.func,
    options       : PropTypes.element,
    PaperProps    : PropTypes.object,
    subLabel      : PropTypes.element
};

export default ThingCard ;
