import media from '../components/dialogs/media'

const audios =
{
    add :
    {
       agree       : 'Ajouter' ,
       description : 'Sélectionner ou ajouter un fichier audio (format MP3).' ,
       disagree    : 'Annuler' ,
       loading     : 'Ajout en cours...' ,
       title       : 'Ajouter un fichier audio',
    },
    empty   : 'Aucun fichier audio disponible' ,
    loading : 'Enregistrement en cours',
    media,
    remove :
    {
        agree        : 'Supprimer' ,
        description  : 'Souhaitez-vous supprimer ce fichier audio?' ,
        descriptionX : 'Souhaitez-vous supprimer les {0} fichiers audios sélectionnées?' ,
        disagree     : 'Annuler' ,
        loading      : 'Suppression en cours...' ,
        title        : 'Supprimer le fichier audio ?',
        titleX       : 'Supprimer les fichiers audios ?'
    },
    removed  : 'Suppression réussie',
    success  : 'Enregistrement réussi',
    title    : 'Audios',
    tooltips :
    {
        add : 'Ajouter'
    }
};

export default audios ;