import React from 'react'

import format from 'vegas-js-core/src/strings/fastformat'

import Form from '../../../../../../../display/forms/FormElements'

import Method from "../../../../../../../net/Method"

import api from "../../../../../../../configs/api"

import event from '../../../events/sectorHealthEvent'

import SectorEventCell from '../../../../../../../display/things/livestock/events/SectorHealthEvent'

import SectorHealthEvent from '../../../../../../../things/livestock/events/SectorHealthEvent'
import Sector            from '../../../../../../../things/Sector';
import SuspicionReview   from '../../../../../../../things/livestock/reviews/SuspicionReview'
import Thing             from '../../../../../../../things/Thing'
import Workshop          from '../../../../../../../things/Workshop'

const getRatesTypesByBreedingsTypes = path => props =>
{
    const { rel } = props ;
    if( rel instanceof SectorHealthEvent )
    {
        const { subjectOf } = rel ;
        if( subjectOf instanceof SuspicionReview )
        {
            const { about } = subjectOf ;
            if( about instanceof Workshop )
            {
                const { breeding } = about ;
                if( breeding instanceof Thing )
                {
                    const { id } = breeding ;
                    if( id )
                    {
                        return format( path , id ) ;
                    }
                }
            }
        }
    }
    return null ;
}

const getSectorCell = thing => <SectorEventCell thing={ thing } variant = 'basic' /> ;

const getSectors = props =>
{
    const { rel } = props ;
    if( rel instanceof SectorHealthEvent )
    {
        const { subjectOf } = rel ;
        if( subjectOf instanceof SuspicionReview )
        {
            const { about , subject } = subjectOf ;
            if( subject instanceof Thing && about instanceof Thing )
            {
                return format( api.workplaces.sectors , about.id , subject.id ) ;
            }
        }
    }
    return null;
}

const elements =
[
    {
        type       : Form.SEARCH_SELECTOR ,
        clazz      : Sector,
        clearLabel : 'Vider' ,
        closeLabel : 'Fermer' ,
        init       :
        {
            id         : 'about',
            key        : 'about',
            label      : 'Secteur',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            required   : true,
            type       : 'search',
            variant    : 'outlined'
        },
        iconifiable : false ,
        path        : getSectors ,
        searchable  : false,
        title       : 'Sélectionner un espace de travail'
    },
    {
        type        : Form.SEARCH_SELECTOR ,
        clearLabel  : 'Vider' ,
        closeLabel  : 'Fermer' ,
        iconifiable : false ,
        init        :
        {
            id         : 'morbidity',
            key        : 'morbidity',
            label      : 'Taux de morbidité',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            required   : false,
            type       : 'search',
            variant    : 'outlined'
        },
        path        : getRatesTypesByBreedingsTypes( api.settings.morbidityRatesTypesByBreedingsTypes ) ,
        searchable  : false ,
        title       : 'Sélectionner un taux de morbidité'
    },
    {
        type        : Form.SEARCH_SELECTOR ,
        clearLabel  : 'Vider' ,
        closeLabel  : 'Fermer' ,
        iconifiable : false ,
        init        :
        {
            id         : 'mortality',
            key        : 'mortality',
            label      : 'Taux de mortalité',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            required   : false,
            type       : 'search',
            variant    : 'outlined'
        },
        path        : getRatesTypesByBreedingsTypes( api.settings.mortalityRatesTypesByBreedingsTypes ) ,
        searchable  : false ,
        title       : 'Sélectionner un taux de mortalité'
    },
    {
        type : Form.DIVIDER,
        init : { key : 'events-divider-1' , light : true }
    },
    {
        type         : Form.DATE ,
        defaultValue : null ,
        format       : 'L' ,
        init         :
        {
            id            : 'startDate',
            key           : 'startDate',
            autoOk        : true ,
            cancelLabel   : 'Annuler' ,
            clearable     : true ,
            clearLabel    : 'Vider' ,
            inputVariant  : 'outlined' ,
            invalidLabel  : '-',
            disableFuture : false ,
            disablePast   : false ,
            fullWidth     : true ,
            label         : 'Date de début' ,
            required      : false,
            okLabel       : 'OK',
            variant       : 'dialog'
        }
    },
    {
        type         : Form.DATE ,
        defaultValue : null,
        format       : 'L' ,
        minDate      : 'validFrom' ,
        init :
        {
            id            : 'endDate',
            key           : 'endDate',
            autoOk        : true ,
            cancelLabel   : 'Annuler' ,
            clearable     : true ,
            clearLabel    : 'Vider' ,
            inputVariant  : 'outlined' ,
            invalidLabel  : '-',
            disableFuture : false ,
            disablePast   : false ,
            label         : 'Date de fin' ,
            fullWidth     : true ,
            okLabel       : 'OK',
            variant       : 'dialog'
        }
    }
];

const sectorHealthEvents =
{
    add :
    {
        agree       : 'Créer' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour créer un événement sanitaire.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...' ,
        method      : Method.PATCH,
        mock        : false,
        success     : 'Ajout réussi!' ,
        title       : 'Ajouter un événement',
        elements
    },
    edit :
    {
        agree       : 'Sauver' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour modifier cet événement.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...'  ,
        method      : Method.PATCH,
        mock        : false ,
        success     : 'Mise à jour réussie!' ,
        title       : 'Modifier l\'évenement',
        elements
    },
    event,
    labels :
    {
        add  : 'Ajouter un événement',
        card : 'Événement'
    },
    remove :
    {
        agree       : 'Supprimer' ,
        description : 'Souhaitez-vous supprimer cet événement sanitaire ?' ,
        disagree    : 'Annuler' ,
        iconifiable : false ,
        item        : { render : getSectorCell },
        loading     : 'Suppression en cours...' ,
        method      : Method.PATCH ,
        mock        : false ,
        title       : 'Supprimer l\'événement',
        useID       : false
    },
    rows :
    {
        empty       : '?' ,
        numAttendee : 'Effectif' ,
        capacity    : 'Capacité'
    },
    title     : 'Événements sanitaires' ,
    tooltips  :
    {
        add    : "Ajouter" ,
        edit   : "Éditer" ,
        remove : "Supprimer"
    }
};

export default sectorHealthEvents ;