import generify from '../../generify'

import Event             from '../../Event'
import MedicalAnalysis   from '../../medical/MedicalAnalysis'
import MedicalHypothesis from '../../medical/MedicalHypothesis'
import Sector            from '../../Sector'
import Word              from '../../Word'

/**
 * A livestock sector health event.
 * @memberOf things.livestock.events
 * @extends Event
 */
class SectorHealthEvent extends Event
{
    /**
     * Creates a new SectorHealthEvent instance.
     * @constructor
     * @param object The generic object to initialize the object.
     */
    constructor( object = null )
    {
        super() ;

        /**
         * Someone acting for this observation.
         * @type {Word}
         */
        this.ageRange = null  ;
        
        /**
         * The medical analysis informations.
         * @type {MedicalAnalysis}
         */
        this.analysis = null  ;
    
        /**
         * The hypothesis informations.
         * @type {MedicalHypothesis}
         */
        this.hypothesis = null ;
    
        /**
         * The morbidity rate.
         * @type {number|Word}
         */
        this.morbidity = null ;
    
        /**
         * The mortality rate.
         * @type {number|Word}
         */
        this.mortality = null ;
        
        /**
         * The symptoms collection.
         * @type {Word|Array}
         */
        this.symptoms = null ;
        
        this.set( object ) ;
    }

    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        super.clear() ;
        this.ageRange   =
        this.analysis   =
        this.hypothesis =
        this.morbidity  =
        this.mortality  =
        this.symptoms   = null ;
        return this ;
    }

    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new SectorHealthEvent( this.toObject() ) ;
    }

    /**
     * Populate the object with specific behaviors or types.
     * Launched by default in the constructor and in the set methods.
     * @returns {Thing} The current reference.
     */
    populate()
    {
        super.populate() ;

        const {
            about,
            ageRange,
            analysis,
            hypothesis,
            morbidity,
            mortality,
            symptoms
        } = this ;

        if( about && !(about instanceof Sector) )
        {
            this.about = new Sector(about);
        }

        if( ageRange instanceof Array )
        {
            this.ageRange = ageRange.map( item => item instanceof Word ? item : new Word(item) ) ;
        }
        else
        {
            this.ageRange = null ;
        }
        
        if( !(analysis instanceof MedicalAnalysis))
        {
            this.analysis = new MedicalAnalysis(analysis);
        }
        
        if( !(hypothesis instanceof MedicalHypothesis) )
        {
            this.hypothesis = new MedicalHypothesis(hypothesis);
        }
        
        if( morbidity && !(morbidity instanceof Word))
        {
            this.morbidity = new Word(morbidity);
        }
        
        if( mortality && !(mortality instanceof Word))
        {
            this.mortality = new Word(mortality);
        }
        
        if( symptoms )
        {
            if( symptoms instanceof Array )
            {
                this.symptoms = symptoms.map( item => item instanceof Word ? item : new Word(item) ) ;
            }
            else
            {
                this.symptoms = new Word(symptoms);
            }
        }

        return this ;
    }

    /**
     * Returns the generic object representation of the item.
     * @return {Object} The generic object representation of the item.
     */
    toObject()
    {
        let {
            about,
            ageRange,
            analysis,
            endDate,
            hypothesis,
            id,
            morbidity,
            mortality,
            startDate,
            symptoms
        } = this ;

        if( about )
        {
            let {
                id ,
                name,
                url,
                additionalType
            }
            = about ;
            if( additionalType )
            {
                additionalType = generify(additionalType,true) ;
            }
            about = { id , name, url, additionalType } ;
        }
        else
        {
            about = null ;
        }
        
        ageRange   = generify(ageRange,true);
        analysis   = generify(analysis);
        hypothesis = generify(hypothesis);
        morbidity  = generify(morbidity);
        mortality  = generify(mortality);
        symptoms   = generify(symptoms);

        return {
            about,
            ageRange,
            analysis,
            endDate,
            id,
            hypothesis,
            morbidity,
            mortality,
            startDate,
            symptoms
        };
    }
}

export default SectorHealthEvent ;