const removeDragData = event =>
{
    if( event )
    {
        const { dataTransfer } = event ;
        if( dataTransfer )
        {
            const { items } = dataTransfer ;
            if( items )
            {
                items.clear() ;
            }
            else
            {
                dataTransfer.clearData();
            }
        }
    }
};

export default removeDragData ;