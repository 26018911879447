import generify from './generify'

import Thing from './Thing'
//import Word  from './Word'

/**
 * Defines a connection between one object and others.
 * @memberOf things
 */
class Connection extends Thing
{
    /**
     * Creates a new Connection instance.
     * @constructor
     * @param object The generic object to initialize the object the first time.
     */
    constructor( object = null )
    {
        super() ;

        /**
         * An object or a collection of object to connect.
         * @type {Array|Object}
         */
        this.from = null ;
        
        /**
         * The target object to connect.
         * @type {string}
         */
        this.to = null ;

        this.set( object ) ;
    }

    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        super.clear() ;
        this.from = null ;
        this.to   = null ;
        return this ;
    }

    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new Connection( this.toObject() ) ;
    }

    // /**
    //  * Populate the object with specific behaviors or types. Launched by default in the constructor and in the set methods.
    //  * @returns {Thing} The current reference.
    //  */
    // populate()
    // {
    //     let {
    //         from,
    //         to
    //      } = this ;
    //
    //     if( !(to instanceof Word))
    //     {
    //         this.to = new Word( to ) ;
    //     }
    //
    //     if( from instanceof Array )
    //     {
    //         this.from = from.map( item => (item instanceof Word) ? item : new Word(item)) ;
    //     }
    //
    //     return this ;
    // }

    /**
     * Returns the generic object representation of the item.
     * @return {Object} The generic object representation of the item.
     */
    toObject()
    {
        let { from, to } = this ;
        
        from = generify(from) ;
        to   = generify(to) ;
        
        return {
            ...super.toObject() ,
            from ,
            to
        };
    }
}

export default Connection ;