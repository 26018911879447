const profile =
{
    empty      : '----' ,
    title      : 'Informations' ,
    created    : 'Création' ,
    modified   : 'Modification' ,
    email      : 'Email' ,
    givenName  : 'Prénom' ,
    familyName : 'Nom de famille' ,
    gender     : 'Genre' ,
    team       : 'Équipe'
};

export default profile ;