import React from "react"

import isString from 'vegas-js-core/src/isString'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import CategoryIcon from '@material-ui/icons/Category'
import InfoIcon     from '@material-ui/icons/Info'
import MediasIcon   from '@material-ui/icons/PermMedia'

import api from '../../configs/api'

import ThingView , { defaultStyle } from '../../display/views/ThingView'

import About  from './views/About'
import Info   from './views/Info'
import Medias from '../medias/Medias'

import ConceptualObjectVO from '../../things/ConceptualObject'
import Thing              from '../../things/Thing'

import initView from '../../display/views/initView'

const styles = defaultStyle();

class ConceptualObject extends ThingView
{
    getLocale = () => this.props.locale.views.conceptualObject ;

    getSubtitle = thing =>
    {
        if( thing )
        {
            const { category } = thing ;
            if( category instanceof Thing )
            {
                const { lang } = this.props ;
                return ucFirst(category.getLocaleName(lang))  ;
            }
        }
        return null ;
    };

    getTitle = thing =>
    {
        if( thing instanceof Thing )
        {
            const { lang } = this.props ;

            let { alternateName } = thing ;

            if( alternateName )
            {
                alternateName = thing.getLocaleAlternateName( lang ) ;
                if( isString(alternateName) && alternateName !== '' )
                {
                    return ucFirst(alternateName)  ;
                }
            }

            return ucFirst(thing.getLocaleName(lang))  ;
        }
        return null ;
    };
}


ConceptualObject.defaultProps =
{
    ...ThingView.defaultProps,
    back       : true ,
    clazz      : ConceptualObjectVO ,
    icon       : <CategoryIcon/>,
    path       : api.conceptualObjects,
    preferable : true ,
    responsive : true ,
    routes     :
    [
        { id : 'about'  , RouteComponent : About  } ,
        { id : 'info'   , RouteComponent : Info   } ,
        { id : 'medias' , RouteComponent : Medias }
    ],
    tabs :
    [
        { id : 'about'  , icon : <CategoryIcon className='pr-8' /> } ,
        { id : 'info'   , icon : <InfoIcon     className='pr-8' /> } ,
        { id : 'medias' , icon : <MediasIcon   className='pr-8' /> }
    ],
    titleAuto : false // Important to use always the getTitle() method
};

ConceptualObject.propTypes =
{
    ...ThingView.propTypes
};

export default initView( ConceptualObject, styles );
