import ageRanges   from './medical/ageRanges'
import analysis    from './medical/analysis'
import causes      from './medical/causes'
import diseases    from './medical/diseases'
import hypothesis  from './medical/hypothesis'
import preventions from './medical/preventions'
import symptoms    from './medical/symptoms'

const medical =
{
    ageRanges,
    analysis,
    causes,
    diseases,
    hypothesis,
    preventions,
    symptoms
};

export default medical ;