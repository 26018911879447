
import Method from '../../../../../net/Method'

const prepare = () => null ;

const secret =
{
    edit :
    {
        agree       : 'Sauver' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez vous renouveler la clé? ATTENTION cela va révoquer toutes les clés (tokens)!' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...' ,
        method      : Method.PATCH ,
        success     : 'Mise à jour réussie!' ,
        title       : 'Renouvelez la clé' ,
        prepare
    },
    labels :
    {
        client_secret : 'Client Secret',
        copy          : 'Copié dans le presse papier',
        empty         : '----'
    },
    title    : 'Client Secret' ,
    tooltips :
    {
        add    : 'Ajouter',
        edit   : 'Renouveler',
        remove : 'Supprimer'
    }
};

export default secret ;