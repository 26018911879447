import React from 'react'

import MediaObject from '../../things/creativework/MediaObject'

import mimeicons , { DEFAULT_ICON } from '../../configs/mimeicons'

const getMediaIcon = ( media , options = null ) =>
{
    if( media instanceof MediaObject )
    {
        const  { encodingFormat } = media ;

        let IconComponent ;

        if( encodingFormat && mimeicons[encodingFormat])
        {
            IconComponent = mimeicons[encodingFormat];
        }
        else
        {
            IconComponent = DEFAULT_ICON ;
        }

        return <IconComponent { ...options }/> ;
    }
    return null ;
};

export default getMediaIcon ;
