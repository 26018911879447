import React from 'react'

import clsx from "clsx"

import PropTypes from 'prop-types'

import compose from 'vegas-js-core/src/functors/compose'

import Flag from 'react-flags'

import { withStyles } from '@material-ui/core/styles'

import { Typography, Tooltip } from '@material-ui/core'

//import LocaleConsumer from '../../../contexts/i18n/LocaleConsumer'

import isString from 'vegas-js-core/src/isString'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import Container from '../../../components/containers/Container'

import withConfig from '../../../contexts/config/withConfig'
import withi18n   from '../../../contexts/i18n/withi18n'

import MarkVO from '../../../things/Mark'
import WordVO from '../../../things/Word'

const flagSettings =
{
    basePath : './assets/flags' ,
    format   : 'png' ,
    pngSize  : 32 ,
    width    : 26
};

const styles = () => (
{
    root :
    {
        padding : '16px 0px'
    }
});

class Mark extends Container
{
    getFlag = ( code = 'FR' , tooltip = null , key = "flag-" ) =>
    {
        if( isString(code) )
        {
            code = code.toUpperCase();
        }
        const flag = <div key={ key + code } style={{ marginRight: 10 }}>
                        <Flag name={code} {...flagSettings} />
                     </div> ;
        return tooltip ? <Tooltip key={ key + code } placement='top' title={ tooltip }>{flag}</Tooltip>: flag ;
    };

    getLocale = () => this.props.locale.things.conceptualObject.mark ;

    render = () =>
    {
        const { thing } = this.props ;
        if( thing instanceof MarkVO )
        {
            const {
                classes,
                className,
                editable,
                lang,
                onClick,
                style
            } = this.props ;

            const locale = this.getLocale() ;

            let {
                additionalType,
                language,
                technique,
                text
            } = thing ;

            let name = thing.getLocaleName( lang ) ;
            if( isString( name ) && name !== '' )
            {
                name = ucFirst(name) ;
            }
            else
            {
                name = null ;
            }

            if( name === null )
            {
                name = locale.unknown ;
            }

            if( editable )
            {
                name = (
                <button onClick = { onClick }>
                    <Typography className="cursor-pointer text-blue-800 font-medium text-left" variant='subtitle1'>
                        { name }
                    </Typography>
                </button>
                );
            }
            else
            {
                name = (
                <Typography className='text-gray-800 text-left' variant='subtitle1'>
                    { name }
                </Typography>);
            }

            // ------- description

            let description = thing.getLocaleDescription( lang );
            if( isString( description ) && description !== '' )
            {
                description = ucFirst(description) ;
                description = (
                <Typography
                    className = 'text-gray-900'
                    variant   = 'body2'
                >
                    {description}
                </Typography>
                ) ;
            }
            else
            {
                description = null ;
            }

            // ------- position

            let position = thing.getLocalePosition( lang ) ;
            if( isString( position ) && position !== '' )
            {
                position = ucFirst(position) ;
                position = (
                <Typography
                    className = 'text-gray-600'
                    variant   = 'caption'
                >
                    {position}
                </Typography>
                ) ;
            }
            else
            {
                position = null ;
            }

            // ------- type

            let informations = [] ;

            if( additionalType instanceof WordVO )
            {
                additionalType = additionalType.getLocaleName(lang) ;
                if( isString(additionalType) && additionalType !== '' )
                {
                    informations.push( ucFirst(additionalType) ) ;
                }
            }

            // ------- language

            if( language instanceof WordVO )
            {
                language = language.getLocaleName(lang) ;
                if( isString(language) && language !== '' )
                {
                    informations.push( ucFirst(language) ) ;
                }
            }

            // ------- technique

            if( technique instanceof WordVO )
            {
                technique = technique.getLocaleName(lang) ;
                if( isString(technique) && technique !== '' )
                {
                    informations.push( ucFirst(technique) ) ;
                }
            }

            // ------- informations

            if( informations.length > 0 )
            {
                informations = informations.join(' / ') ;
                informations = (
                <Typography className="cursor-pointer text-grey-800 font-normal" variant='caption'>
                    { informations }
                </Typography>) ;
            }
            else
            {
                informations = null ;
            }

            // ------- text

            let translations ;
            if( text )
            {
                const { config : { languages } = {} } = this.props ;
                translations = languages.map( lang =>
                {
                    if( text.hasOwnProperty(lang) && isString(text[lang]) && text[lang] !== '' )
                    {
                        return this.getFlag( lang === 'en' ? 'gb' : lang, ucFirst(text[lang]) ) ;
                    }
                    else
                    {
                        return null ;
                    }
                }).filter( item => item !== null );

                if( translations.length > 0 )
                {
                    translations = (
                        <div className="flex flex-row flex-wrap">
                            { translations }
                        </div>
                    );
                }
                else
                {
                    translations = null ;
                }
            }
            return (
            <div
                className = { clsx( classes.root , "flex flex-col justify-start" , className ) }
                style     = { style }
            >
                { name }
                { translations }
                { description }
                { informations }
                { position }
            </div>
            );
        }

        return null ;
    };
}

Mark.defaultProps =
{
    ...Container.defaultProps,
    editable : false ,
    onClick  : null,
    thing    : null
};

Mark.propTypes =
{
    ...Container.propTypes,
    editable : PropTypes.bool,
    onClick  : PropTypes.func,
    thing    : PropTypes.object
};

export default compose(
    withStyles( styles ),
    withConfig,
    withi18n
)( Mark );
