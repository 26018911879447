import React from 'react'

import withDatasList from './withDatasList'

import CallToActionIcon from '@material-ui/icons/CallToAction'
import LineWeightIcon   from '@material-ui/icons/LineWeight'

import ThingsList from './ThingsList'

import Activity     from '../../vo/Activity'
import ActivityCell from '../cells/ActivityCell'

class ActivityList extends ThingsList
{
    getLocale = () => this.props.locale.display.lists.activities ;
}

ActivityList.defaultProps =
{
    ...ThingsList.defaultProps ,
    addable       : false ,
    activable     : false,
    CellComponent : ActivityCell,
    clazz         : Activity,
    defaultIcon   : <CallToActionIcon fontSize='small'/>,
    deletable     : false,
    icon          : <LineWeightIcon />,
    queries       : { sort : '-created' },
    removable     : false ,
    searchPolicy  : 'none' ,
    selectPolicy  : 'none' ,
    sortable      : false
};

ActivityList.propTypes =
{
    ...ThingsList.propTypes
};

export default withDatasList()( ActivityList ) ;
