import React from 'react'

import isString from "vegas-js-core/src/isString"
import ucFirst from "vegas-js-core/src/strings/ucFirst"

import Form        from '../../../../display/forms/FormElements'
import NumberInput from '../../../../components/inputs/NumberInput'

import { Typography } from '@material-ui/core'

import Method from "../../../../net/Method"

import Answer from "../../../../things/Answer"

const elements =
[
    {
        type : Form.I18N_EDITOR ,
        init :
        {
            id       : 'alternateName',
            key      : 'alternateName',
            input    : true,
            label    : 'Réponse'
        }
    },
    {
        type : Form.I18N_EDITOR ,
        init :
        {
            id    : 'description',
            key   : 'description',
            input : true,
            label : 'Description'
        }
    },
    {
        type : Form.DIVIDER,
        init :
        {
            key     : 'divider_1',
            light   : false ,
            variant : 'fullWidth'
        }
    },
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'score',
            key          : 'score',
            label        : 'Score',
            autoFocus    : false,
            fullWidth    : true,
            InputProps   : {
                inputComponent : ( input ) => (
                    <NumberInput
                        allowNegative = { false }
                        useFormatted  = { true }
                        //floatCount    = { 0 }
                        {...input}
                    />
                )
            },
            margin       : 'normal',
            select       : false,
            autoComplete : 'off' ,
            type         : 'text',
            variant      : 'outlined'
        }
    },
    {
        type : Form.SWITCH ,
        init :
        {
            id    : 'correct',
            key   : 'correct',
            label : 'Choix correct'
        }
    },
    {
        type : Form.DIVIDER,
        init :
        {
            key     : 'divider_2',
            light   : false ,
            variant : 'fullWidth'
        }
    },
    {
        type : Form.COLOR_TEXTFIELD ,
        init :
        {
            id           : 'color',
            key          : 'color',
            label        : 'Couleur',
            autoComplete : 'off' ,
            autoFocus    : false,
            fullWidth    : true,
            margin       : 'normal',
            placeholder  : 'RRGGBB',
            required     : false,
            select       : false,
            type         : 'text',
            variant      : 'outlined'
        }
    },
    {
        type : Form.COLOR_TEXTFIELD ,
        init :
        {
            id           : 'bgcolor',
            key          : 'bgcolor',
            label        : 'Couleur de fond',
            autoComplete : 'off' ,
            autoFocus    : false,
            fullWidth    : true,
            margin       : 'normal',
            placeholder  : 'RRGGBB',
            required     : false,
            select       : false,
            type         : 'text',
            variant      : 'outlined'
        }
    }
];

const answers =
{
    add :
    {
        elements,
        agree       : 'Créer' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour créer une nouvelle réponse.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...' ,
        method      : Method.PATCH,
        mock        : false ,
        success     : 'Ajout réussi de la nouvelle réponse !' ,
        title       : 'Ajouter une réponse'
    },
    edit :
    {
        elements,
        agree       : 'Sauver' ,
        disagree    : 'Annuler' ,
        description : null ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en  cours...' ,
        method      : Method.PATCH,
        mock        : false ,
        success     : 'Mise à jour réussie!' ,
        title       : 'Modifier la réponse'
    },
    labels :
    {
        add : 'Ajouter une réponse'
    },
    remove :
    {
        agree       : 'Supprimer' ,
        description : 'Souhaitez-vous supprimer la réponse :' ,
        disagree    : 'Annuler' ,
        item        : {
            render : ( item , props ) =>
            {
                let label ;

                if( item instanceof Answer )
                {
                    const { lang } = props ;
                    label = item.getLocaleAlternateName(lang) ;
                }

                if( !isString(label) || label === '' )
                {
                    label = answers.unknown ;
                }

                if( isString(label) )
                {
                    label = ucFirst(label) ;
                }

                return <Typography className='px-16'>{label}</Typography> ;

            }
        },
        loading : 'Suppression en cours...' ,
        method  : Method.PATCH ,
        title   : 'Supprimer la réponse ?',
        useID   : false
    },
    unknown  : 'Inconnu' ,
    title    : 'Réponses' ,
    tooltips :
    {
        add    : 'Ajouter' ,
        edit   : 'Éditer' ,
        remove : 'Supprimer'
    }
};

export default answers ;
