import notEmpty from 'vegas-js-core/src/strings/notEmpty'

import PostalAddress from './PostalAddress'

/**
 * Returns the human readable postal address label expression.
 * @param {Thing|PostalAddress} thing - The PostalAddress or object with an 'address' property.
 * @param {Object} options - The optional settings of the function.
 * @param {string} options.separator1 - The primary separator character.
 * @param {string} options.separator1 - The secondary separator character.
 * @returns {null|string} The human readable postal address label expression.
 */
const getLocaleAddressLabel = ( thing , { separator1='' , separator2='\n\r' } = {} ) =>
{
    let address ;
    if( thing )
    {
        if ( thing instanceof PostalAddress ) 
        {
            address = thing  ;
        }
        else
        {
            const { address:addr } = thing ;
            if( addr instanceof PostalAddress )
            {
                address = addr ;
            }
        }
    }
    
    if ( address instanceof PostalAddress ) 
    {
        let {
            additionalAddress,
            addressLocality,
            postalCode,
            postOfficeBoxNumber,
            streetAddress
        } = address;
        
        let label = [];
        
        // --------- street

        let street;

        if (notEmpty(streetAddress)) 
        {
            street = streetAddress;
            if (notEmpty(additionalAddress)) 
            {
                street += separator2 + additionalAddress;
            }

        }
        else if (notEmpty(additionalAddress)) 
        {
            street = additionalAddress;
            if (notEmpty(postOfficeBoxNumber)) 
            {
                street += separator1 + postOfficeBoxNumber;
            }
        }
        
        if (notEmpty(street)) 
        {
            label.push(street);
        }

        // --------- locality

        let locality;

        if ( notEmpty(postalCode) ) 
        {
            locality = postalCode;
            if ( notEmpty( addressLocality ) ) 
            {
                locality += separator1 + addressLocality;
            }
            if (notEmpty(postOfficeBoxNumber)) 
            {
                locality += separator1 + postOfficeBoxNumber;
            }
        }
        else if ( notEmpty(addressLocality) ) 
        {
            locality = addressLocality;
            if (notEmpty(postOfficeBoxNumber)) 
            {
                locality += separator1 + postOfficeBoxNumber;
            }
        }
        else if (notEmpty(postOfficeBoxNumber)) 
        {
            locality = postOfficeBoxNumber;
        }

        if ( notEmpty(locality) ) 
        {
            if( label.length > 0 )
            {
                label.push( separator2 );
            }
            label.push(locality);
        }

        return label.length > 0 ? label.join('') : null;
    }
    return null ;
};

export default getLocaleAddressLabel ;
