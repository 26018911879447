import React from 'react'

import clsx from 'clsx'

import PropTypes from 'prop-types'

import { Card, CardContent, Collapse, Divider, Grid } from '@material-ui/core'

import grey  from '@material-ui/core/colors/grey'

import TitleHeader from '../../components/headers/TitleHeader'

import ThingChildren , { styles as defaultStyles } from './ThingChildren'

export const styles = theme =>
({
    ...( defaultStyles(theme) ) ,
    card :
    {
        margin    : '0px 0px',
        minHeight : 80,
        width     : '100%'
    },
    cardEven :
    {
        backgroundColor : grey[50]
    },
    cardOdd :
    {
        backgroundColor : 'white'
    },
    cardDisabled :
    {
        opacity : 0.6
    }
});

class ThingCardChildren extends ThingChildren
{
    getCardFooter = ( /* child , editable , index */ ) => null ;

    getCardHeader = ( child , editable , index ) =>
    {
        const { cardHeader } = this.props ;
        if( cardHeader )
        {
            const { cardDivider } = this.props ;

            let divider = null ;
            if( cardDivider && (cardDivider !== 'none') )
            {
                divider = (
                <Divider
                    light     = { true }
                    className = 'mt-16'
                    variant   = { cardDivider }
                />) ;
            }
            return (
            <TitleHeader
                divider     = { divider }
                editable    = { editable }
                icon        = { this.getCardIcon( child, editable, index ) }
                label       = { this.getCardLabel( child, editable, index ) }
                LinkOptions = { this.getCardLinkOptions( child , editable , index ) }
                onClick     = { this.onCardClick( child , index ) }
                subLabel    = { this.getCardSubLabel( child, editable, index ) }
                options     = { this.getItemOptions( { child, editable, index } ) }
            />
            );
        }
        return null ;
    };

    getCardIcon = () => this.props.cardIcon ;

    getCardLabel = () => this.props.cardLabel ;

    getCardLinkOptions = () => null ;

    getCardMedia = () => null ;

    getCardSubLabel = () => this.props.cardSubLabel ;

    getCustomCard = element =>
    {
        if( element )
        {
            let {
                classes,
                cardCustomPaperProps,
                cardPaperProps
            }
            = this.props ;

            cardCustomPaperProps = cardCustomPaperProps || cardPaperProps ;

            return (
                <Card
                    className = { clsx( classes.card , 'flex-1 self-center flex items-center justify-center p-12 m-12' ) }
                    { ...cardCustomPaperProps }
                >
                    { element }
                </Card>
            );
        }
        return null ;
    };

    getCustomCardWithAddButton = () => this.getCustomCard( this.getAddButton(this.isLocked()) ) ;

    getItem = editable => ( child , index ) =>
    {
        if( this.isValid(child) )
        {
            const { id } = child ;
            const {
                cardPaperProps,
                classes,
                grid,
                member
            } = this.props ;

            return (
                <Grid
                    key = { member + '_' + id }
                    item
                    {...grid}
                >
                    <Card
                        className = { clsx(
                            classes.card ,
                            index%2 === 0 ? classes.cardOdd : classes.cardEven,
                            this.isLocked() ? classes.cardDisabled : null
                        )}
                        { ...cardPaperProps }
                    >
                        { this.getCardMedia( child, editable, index ) }
                        <CardContent>
                            { this.getCardHeader( child , editable , index ) }
                            { this.createChild( child , editable , index ) }
                        </CardContent>
                        { this.getCardFooter( child, editable, index ) }
                    </Card>
                </Grid>
            );
        }
        return null ;
    };

    getItems = children =>
    {
        if( (children instanceof Array) && (children.length > 0) )
        {
            const editable = this.isEditable() ;
            return children.map( this.getItem(editable) ) ;
        }
        return null ;
    };

    onCardClick = ( /*child , index*/ ) => () => null ;

    renderChildren = children =>
    {
         const { grid, gridSpacing } = this.props;
         const { expanded } = this.state ;

         let bottom = this.getBottom() ;
         if( bottom )
         {
             bottom = <Grid item {...grid} >{ bottom }</Grid> ;
         }

         let top = this.getTop() ;
         if( top )
         {
             top = <Grid item {...grid} >{ top }</Grid> ;
         }

         return (
            <Collapse in={ expanded } timeout="auto" unmountOnExit >
                <Grid container spacing={ gridSpacing } >
                    { top }
                    { children || this.getEmpty() }
                    { bottom }
                </Grid>
            </Collapse>
         );
    };
}

ThingCardChildren.defaultProps =
{
    ...ThingChildren.defaultProps,
    cardCustomPaperProps  : null,
    cardDivider           : 'fullWidth',
    cardHeader            : true,
    cardIcon              : null,
    cardLabel             : null,
    cardPaperProps        : null,
    cardSubLabel          : null,
};

ThingCardChildren.propTypes =
{
    ...ThingChildren.propTypes,
    cardCustomPaperProps  : PropTypes.object,
    cardDivider           : PropTypes.oneOf(['fullWidth','inset','middle','none',null]),
    cardHeader            : PropTypes.bool,
    cardIcon              : PropTypes.element,
    cardLabel             : PropTypes.string,
    cardPaperProps        : PropTypes.object,
    cardSubLabel          : PropTypes.element
};

export default ThingCardChildren ;
