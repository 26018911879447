import React from 'react'

import CourseIcon from '@material-ui/icons/Map'

import Form   from '../../../../../display/forms/FormElements'
import Course from '../../../../../things/Course'

import api from '../../../../../configs/api'

const elements =
[
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'name',
            key          : 'name',
            label        : 'Nom du jeu',
            autoComplete : 'off' ,
            autoFocus    : true,
            fullWidth    : true,
            helperText   : 'Obligatoire',
            margin       : 'normal',
            required     : true,
            select       : false,
            type         : 'text',
            variant      : 'outlined'
        }
    },
    {
        type       : Form.SEARCH_SELECTOR ,
        path       : api.courses.url ,
        clazz      : Course ,
        clearLabel : 'Vider' ,
        closeLabel : 'Fermer' ,
        init  :
        {
            id         : 'about',
            key        : 'about',
            label      : 'Parcours',
            autoFocus  : false,
            fullWidth  : true,
            helperText : 'Obligatoire',
            margin     : 'normal',
            required   : true,
            type       : 'search',
            variant    : 'outlined'
        },
        logics     :
        {
            disabledSuggestions : ( props ) =>
            {
                const { rel } = props ;
                let suggestions = [] ;
                if( rel )
                {
                    const { subjectOf } = rel ;
                    if( subjectOf )
                    {
                        let { quest } = subjectOf ;
                        if( quest instanceof Array )
                        {
                            quest = quest.map( item =>
                            {
                                if( item )
                                {
                                    const { about } = item ;
                                    if( about instanceof Course )
                                    {
                                        return about ;
                                    }
                                }
                                return null ;
                            }).filter( item => item !== null );

                            if( quest.length > 0 )
                            {
                                suggestions = quest ;
                            }
                        }
                    }
                }
                return suggestions ;
            }
        },
        inputProps :
        {
            label : 'Sélectionner un parcours'
        },
        searchIcon : <CourseIcon/>
    }
];

const application =
{
    add :
    {
        elements,
        agree       : 'Créer' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour créer un nouveau jeu.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...' ,
        success     : 'Ajout du nouveau jeu sur le parcours réussie!' ,
        title       : 'Ajouter un jeu sur un parcours',
    },
    remove :
    {
        agree       : 'Supprimer' ,
        description : 'Souhaitez-vous supprimer ce jeu sur le parcours:' ,
        disagree    : 'Annuler' ,
        loading     : 'Suppression en cours...' ,
        title       : 'Supprimer le jeu sur le parcours ?'
    },
    unknown  : 'Inconnu' ,
    title    : 'Jeux sur les parcours' ,
    tooltips :
    {
        active     : 'Activer',
        add        : 'Ajouter' ,
        deactivate : 'Désactiver' ,
        edit       : 'Éditer' ,
        remove     : 'Supprimer'
    }
};

export default application ;
