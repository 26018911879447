import React from 'react'

import clsx from 'clsx'

import PropTypes from 'prop-types'

import notEmpty from 'vegas-js-core/src/strings/notEmpty'

import { Typography } from '@material-ui/core'

import withi18n from '../../contexts/i18n/withi18n'

import getHorizontalElements from '../getHorizontalElements'

import getLocaleDescription         from '../../things/getLocaleDescription'
import getLocaleAlternativeHeadline from '../../things/getLocaleAlternativeHeadline'

import { ThingCell } from './ThingCell'

export class ArticleCell extends ThingCell
{
    getAlternativeHeadline = ( { className = null , ...props } = {} ) =>
    {
        const thing = this.getThing() ;
        if( thing )
        {
            const headline = getLocaleAlternativeHeadline( thing , this.props.lang ) ;
            if( notEmpty( headline ) )
            {
                return (
                    <Typography
                        className = { clsx( 'font-medium' , className ) }
                        variant   = 'body2'
                        { ...props }
                    >
                        { headline }
                    </Typography>
                );
            }
        }
        return null ;
    };
    getContent = () =>
    {
        const { thing } = this.props;
        if (thing)
        {
            const elements = [ 'alternativeHeadline' , 'description' ] ;
            return getHorizontalElements( { elements , factory:this } ) ;
        }
        return null;
    };

    getDescription = ( { className = null , ...props } = {} ) =>
    {
        const thing = this.getThing() ;
        if( thing )
        {
            const description = getLocaleDescription( thing , this.props.lang ) ;
            if( notEmpty( description ) )
            {
                return (
                    <Typography
                        className = { clsx( 'font-normal text-justify' , className ) }
                        variant   = 'caption'
                        { ...props }
                    >
                        { description }
                    </Typography>
                );
            }
        }
        return null ;
    };

    getLocale = () => this.props.locale.display.cells.word;
}

ArticleCell.defaultProps =
{
    ...ThingCell.defaultProps,
    alternativeHeadline : true ,
    description         : true
};

ArticleCell.propTypes =
{
    ...ThingCell.propTypes,
    alternativeHeadline : PropTypes.bool ,
    description         : PropTypes.bool
};

export default withi18n( ArticleCell ) ;
