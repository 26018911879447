export default class RequestStatus
{
    static CANCEL   = 'cancel';
    static DONE     = 'done';
    static FAIL     = 'fail';
    static NEW      = 'new';
    static PROGRESS = 'progress';
    static READY    = 'ready';
    static RELOAD   = 'reload';
    static REDIRECT = 'redirect';
    static REVOKED  = 'revoked';
    static SUCCESS  = 'success';
}