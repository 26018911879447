import React from 'react'

import { ListItem } from '@material-ui/core'

import CloudIcon from '@material-ui/icons/CloudOff'

import initDialog from '../initDialog'

import { RemoveDialog } from '../RemoveDialog'

import Method from '../../../net/Method'

import SessionCell from '../../cells/SessionCell'

const styles = () => ({});

class RevokeSessionDialog extends RemoveDialog
{
    getElement( element , divider = false )
    {
        if( element )
        {
            return (
                <ListItem
                    dense          = { false }
                    divider        = { divider }
                    key            = { 'item-' + element.id }
                    disableGutters = { true }
                >
                    <SessionCell thing={element} />
                </ListItem>
            ) ;
        }
        return null ;
    }

    getLocale = () => this.props.locale.dialogs.application.session.revoke ;
}

RevokeSessionDialog.defaultProps =
{
    ...RemoveDialog.defaultProps ,
    icon    : <CloudIcon className='mr-12' color='action' />,
    method  : Method.PATCH,
    mock    : false,
    useIcon : true
};

RevokeSessionDialog.propTypes =
{
    ...RemoveDialog.propTypes
};

export default initDialog({ styles })( RevokeSessionDialog );
