import React from 'react'

import clsx from 'clsx'

import { Lines as Preloader } from "react-preloading-component"

import compose  from 'vegas-js-core/src/functors/compose'
import notEmpty from 'vegas-js-core/src/strings/notEmpty'

import { withStyles } from '@material-ui/core/styles'
import {
    withWidth,
    ButtonBase,
    IconButton
} from '@material-ui/core'

import { isWidthUp } from '@material-ui/core/withWidth'

import CheckCircle     from '@material-ui/icons/CheckCircle'
import PanoramaFishEye from '@material-ui/icons/PanoramaFishEyeTwoTone'

import withConfig from '../../contexts/config/withConfig'
import withi18n   from '../../contexts/i18n/withi18n'

import { ThingCell } from './ThingCell'

import getMediaAvatar from '../medias/getMediaAvatar'
import getMediaIcon   from '../medias/getMediaIcon'

import { getMediaObjectIconClassName } from '../../configs/mediaColors'

import ImageObject from '../../things/creativework/media/ImageObject'
import VideoObject from '../../things/creativework/media/VideoObject'

import loadImage from '../../media/loadImage'

const styles = theme =>
({
    container :
    {
        cursor    : "pointer",
        '&:hover' :
        {
            filter     : 'brightness(50%)' ,
            transition : '0.3s',
            transitionTimingFunction : 'cubic-bezier(0.175,0.885,0.32,1.275)'
        },
        [theme.breakpoints.down('md')]:
        {
            width : 100,
        },
        [theme.breakpoints.up('md')]:
        {
            width : 180
        }
    },
    containerDisabled :
    {
        cursor : "default"
    },
    containerSelected :
    {
        filter     : 'brightness(35%)' ,
        transition : '.2s ease-out'
    },
    left :
    {
        position : 'relative' ,
        [ theme.breakpoints.down('md') ] : { width : 100 },
        [ theme.breakpoints.up('md')   ] : { width : 180 }
    },
    selectButton :
    {
        position : 'absolute' ,
        color    : '#fff' ,
        margin   : 8 ,
        padding  : 0
    }
});

class MediaObjectCell extends ThingCell
{
    constructor( props )
    {
        super( props) ;
        this.state =
        {
            ...this.state,
            backgroundImage : null,
            preload         : false ,
            selected        : props.selected
        }
    }

    componentDidUpdate = ( prevProps , prevState ) =>
    {
        const { expanded } = this.props ;
        if( prevState.expanded !== expanded )
        {
            this.setState({ expanded }) ;
        }
    };

    UNSAFE_componentWillReceiveProps = nextProps =>
    {
        let change ;

        if( nextProps.selected !== this.props.selected )
        {
            change = { selected:nextProps.selected } ;
        }

        if( nextProps.thing !== this.props.thing )
        {
            change = { ...change , backgroundImage:null } ;
        }

        if( change )
        {
            this.setState({ ...change }) ;
        }
    }

    getAvatar = ( { className = "ml-8 mr-12" } = {} ) =>
    {
        let {
            avatarable:visible ,
            width
        } = this.props;
        visible = visible && !isWidthUp( 'sm' , width , true ) ;
        if( visible )
        {
            const thing = this.getThing();

            const { avatar } = this.props;
            if ( avatar )
            {
                if( avatar instanceof Function )
                {
                    return avatar(thing) ;
                }
                return avatar ;
            }

            if( thing )
            {
                const {
                    avatarStyle   : style ,
                    avatarVariant : variant
                } = this.props ;

                return getMediaAvatar( thing , { className , style , variant }) ;
            }
        }
        return null ;
    }

    getLeft = ( { className = null } = {} ) =>
    {
        const { width } = this.props ;
        if( isWidthUp( 'sm' , width , true ) )
        {
            let { preload } = this.state ;

            const { backgroundImage } = this.state ;

            let image = this.getThumbSource() ;

            if( notEmpty(image) && backgroundImage === null )
            {
                if( !this.image )
                {
                    if( this.props.randomize )
                    {
                        image += '?random=' + Date.now() ;
                    }

                    this.image = loadImage(
                    {
                        // loading : 'lazy' ,
                        // height  : 0 ,
                        // width   : 0 ,
                        src     : image ,
                        start   : () => { this.setState({ preload:true }) }
                    }) ;

                    this.image
                    .then( () =>
                    {
                        this.image = null ;
                        if( this._mounted)
                        {
                            this.setState( { backgroundImage : `url(${image})` , preload:false }) ;
                        }
                    })
                    .catch( () =>
                    {

                    });
                }
            }
            else
            {
                preload = false ;
            }

            let icon ;

            const thing = this.getThing() ;
            if( thing )
            {
                className = getMediaObjectIconClassName( thing , className ) ;
                if( !preload )
                {
                    icon = getMediaIcon( thing, { className: 'absolute l-auto t-auto text-white text-shadow-lg', fontSize: 'large' } );
                }
            }

            if( preload )
            {
                icon = <Preloader color='#ffffff' size={16} /> ;
            }

            let style ;

            if( backgroundImage )
            {
                style = { backgroundImage , backgroundSize:'cover' } ;
            }

            let {
                classes ,
                disabled,
                onClick
            } = this.props ;

            onClick = onClick || this.onClick ;

            return (
                <div
                    className = { clsx(
                        classes.left ,
                        disabled ? 'opacity-75' : 'opacity-100' ,
                        'flex rounded-bl-lg rounded-tl-lg overflow-hidden mr-12 bg-hero-diagonal-stripes-1' ,
                        className
                    ) }
                >
                    <ButtonBase
                        className = { clsx( classes.container , 'flex items-center justify-center' ) }
                        disabled  = { disabled }
                        style     = { style }
                        { ...( onClick && { onClick } ) }
                    >
                        { icon }
                    </ButtonBase>
                    { this.getSelectButton() }
                </div>
            )
        }
        return null ;
    }

    getLocale = () => this.props.locale.display.cells.mediaObject;

    getSelectButton = () =>
    {
        const { selectable } = this.props ;
        if( selectable )
        {
            const { classes , disabled } = this.props ;
            return (
                <IconButton
                    className = { classes.selectButton }
                    disabled  = { disabled }
                    onClick   = { this.select }>
                    { this.state.selected ? <CheckCircle /> : <PanoramaFishEye/> }
                </IconButton>
            )
        }
        return null ;
    }

    getThumbSource = () =>
    {
        const thing = this.getThing();
        if( thing instanceof ImageObject )
        {
            let {
                contentUrl,
                thumbnailUrl
            }
            = thing ;

            if( notEmpty(thumbnailUrl) )
            {
               return thumbnailUrl ;
            }

            if( notEmpty(contentUrl) )
            {
               return contentUrl ;
            }
        }
        else if( thing instanceof VideoObject )
        {
            let { thumbnailUrl } = thing ;
            if( notEmpty(thumbnailUrl) )
            {
               return thumbnailUrl ;
            }
        }
        return null;
    };

    select = () =>
    {
        const { media , onSelect } = this.props ;
        const selected = !(this.state.selected) ;
        this.setState({ selected }) ;
        if( onSelect instanceof Function )
        {
            onSelect( selected, media )
        }
    };

    unmount = () =>
    {
        if( this.image )
        {
            this.image.src = null ;
            this.image = null ;
        }
    }
}

MediaObjectCell.defaultProps =
{
    ...ThingCell.defaultProps,
    className      : null ,
    left           : true ,
    randomize      : false,
    typeClassName  : 'font-medium' ,
    typeMember     : 'encodingFormat' ,
    typeUpperFirst : false ,
};

MediaObjectCell.propTypes =
{
    ...ThingCell.propTypes
};

export default compose(
    withStyles( styles ),
    withWidth(),
    withi18n,
    withConfig
)
( MediaObjectCell ) ;
