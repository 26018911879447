import React from 'react'

import compose  from 'vegas-js-core/src/functors/compose'
import isString from 'vegas-js-core/src/isString'
import notEmpty from 'vegas-js-core/src/strings/notEmpty'

import initApp from '../../../../../contexts/app/initApp'

import {Link, withRouter} from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'

import { Typography } from '@material-ui/core'

import GameIcon from '@material-ui/icons/Casino'

import ThingAvatar    from "../../../../avatars/ThingAvatar"
import ThingContainer from '../../../../containers/ThingContainer'

import api from "../../../../../configs/api"

import BadgeItem from '../../../../../things/game/BadgeItem'
import ThingVO   from '../../../../../things/Thing'
import GameVO    from '../../../../../things/Game'

import identify      from '../../../../../things/identify'
import getImageOrLogoSource from '../../../../../things/getImageOrLogoSource'
import getLocaleHeadline    from '../../../../../things/getLocaleHeadline'
import getLocaleThingLabel  from '../../../../../things/getLocaleThingLabel'

import AmbuloSetting from '../../../../../vo/application/settings/AmbuloSetting'

const styles = () => ({});

class Game extends ThingContainer
{
    getContent = () =>
    {
        let { thing } = this.props;

        let game ;
        let name ;

        if( thing instanceof AmbuloSetting )
        {
            let { game:g } = thing ;

            if( g instanceof GameVO )
            {
                game = g ;
            }

            if( game )
            {
                const { lang } = this.props ;

                name = getLocaleHeadline( game , lang ) ;
                if( !isString(name) || name === '')
                {
                    name = getLocaleThingLabel( game , lang ) ;
                }

                const { url } = game ;
                if( notEmpty( name ) )
                {
                    name = (
                    <Link to={{ pathname:url.split(api.url)[1] }}>
                        <Typography
                            className = 'text-blue-700 font-medium no-underline hover:no-underline'
                            variant   = 'subtitle1'
                        >
                            { name }
                        </Typography>
                    </Link>);
                }
            }
        }

        return (
            <div className="flex flex-row items-center justify-center bg-white mt-4 pl-8 shadow">
                <div className="flex flex-row items-center py-20 w-full">
                    <ThingAvatar
                        className = 'mr-16'
                        getImage  = { this.getImage }
                        getWord   = { this.getWord  }
                        style     = {{ padding:4 }}
                        thing     = { game }
                    />
                    { name }
                </div>
            </div>
        );
    };

    getEntry = init =>
    {
        let empty = {} ;
        if( init instanceof ThingVO )
        {
            empty = init.toObject() ;
        }
        else if( init )
        {
            empty = { ...init } ;
        }
        return new AmbuloSetting(empty);
    };

    getImage = thing =>
    {
        if( thing instanceof GameVO )
        {
            const { item } = thing ;
            if( item instanceof BadgeItem )
            {
                const { additionalType } = item ;
                if( additionalType )
                {
                    return getImageOrLogoSource( additionalType ) ;
                }
            }
        }
        return null ;
    };

    getLocale = () => this.props.locale.things.application.settings.ambulo.game;

    getWord = thing =>
    {
        if( thing instanceof Game )
        {
            const { item } = thing;
            if( item instanceof BadgeItem )
            {
                const { additionalType } = item ;
                return additionalType ;
            }
        }
        return null ;
    };

    prepare = item =>
    {
        let {
            agenda,
            courses,
            events,
            game,
            version
        } = item ;

        courses = identify( courses ) ;
        events  = identify( events ) ;
        game    = identify( game ) ;

        const setting =
        {
            agenda,
            courses,
            events,
            game,
            version
        };

        return ({ setting : JSON.stringify( setting ) });
    };

}

Game.defaultProps =
{
    ...ThingContainer.defaultProps ,
    icon       : <GameIcon/> ,
    optionMode : 'edit'
};

Game.propTypes =
{
    ...ThingContainer.propTypes
};

export default compose( withStyles( styles ) , withRouter , initApp )( Game ) ;
