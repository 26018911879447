import React from 'react'

import clsx from 'clsx'

import PropTypes from 'prop-types'

import moment from 'moment/moment'

import compose  from 'vegas-js-core/src/functors/compose'
import isNumber from 'vegas-js-core/src/isNumber'
import isString from 'vegas-js-core/src/isString'
import format   from 'vegas-js-core/src/strings/fastformat'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import initApp from '../../contexts/app/initApp'

import { withStyles } from '@material-ui/core/styles'

import { Link, Typography } from '@material-ui/core'

import Container from '../../components/containers/Container'

import OfferVO from '../../things/Offer'
import ThingVO from '../../things/Thing'

const styles = () => (
{
    root :
    {
        display       : 'flex' ,
        flexDirection : 'column' ,
        paddingBottom : 10,
        paddingTop    : 10
    }
});

class Offer extends Container
{
    render = () =>
    {
        const { classes, className, thing } = this.props ;
        if( thing instanceof OfferVO )
        {
            const {
                editable,
                lang,
                LinkOptions,
                onClick
            } = this.props ;

            const locale = this.getLocale() ;

            let {
                category,
                description ,
                price ,
                priceCurrency,
                validFrom,
                validThrough
            } = thing ;

            let finish ;
            let info ;
            let period ;
            let start ;
            let label ;

            if( locale )
            {
                const {
                    endPattern,
                    from,
                    full,
                    startPattern,
                    title,
                    to
                } = locale ;

                // -------- Title

                label = thing.getLocaleAlternateName( lang ) ;

                if( !isString(label) )
                {
                    if ( isString( title ) && title !== '' )
                    {
                        label = title ;
                    }
                }

                if( isString( label ) && label !== '' )
                {
                    label = ucFirst(label) ;
                    if( editable )
                    {
                        label = (
                        <Link
                            className = "cursor-pointer text-blue-500 font-medium"
                            variant   = "subtitle1"
                            underline = 'none'
                            href      = { null }
                            onClick   = { onClick }
                            { ...LinkOptions }
                        >
                            { label }
                        </Link>) ;
                    }

                    label =
                    <Typography className='font-semibold text-gray-800' variant='body2'>
                        {label}
                    </Typography> ;
                }
                else
                {
                    label = null ;
                }

                // --------- Description

                if( description )
                {
                    description = thing.getLocaleDescription( lang ) ;
                    if( isString(description) && description.length > 0 )
                    {
                        description = <Typography variant='caption'>{ucFirst(description)}</Typography> ;
                    }
                    else
                    {
                        description = null ;
                    }
                }


                // -------- Period

                if( isString(validFrom) )
                {
                    if( isString(startPattern) )
                    {
                        start = moment(validFrom).format( startPattern ) ;
                    }
                    else
                    {
                        start = validFrom ;
                    }
                }

                if( isString(validThrough) )
                {
                    if( isString(endPattern) )
                    {
                        finish = moment(validThrough).format( endPattern ) ;
                    }
                    else
                    {
                        finish = validThrough ;
                    }
                }

                if( finish )
                {
                    if( start )
                    {
                        if( start === finish )
                        {
                            period = format( from , start ) ;
                        }
                        else
                        {
                            period = format( full , start , finish ) ;
                        }
                    }
                    else
                    {
                        period = format( to , finish ) ;
                    }
                }
                else if ( start )
                {
                    period = format( from , start ) ;
                }

                if( isString(period) && period !== '' )
                {
                    period = <Typography variant="caption">{ period }</Typography> ;
                }
                else
                {
                    period = null ;
                }

                // -------- infos

                let infos = [] ;

                if( isNumber(price) || isString(price) )
                {
                    price = price + '' ;
                    if( isString( priceCurrency ) )
                    {
                        price += ' ' + priceCurrency ;
                    }
                    infos.push( price ) ;
                }

                if( category instanceof ThingVO )
                {
                    category = category.getLocaleName( lang ) ;
                    if( isString(category) && category.length > 0 )
                    {
                        infos.push( category ) ;
                    }
                }

                if( infos.length > 0 )
                {
                    info = <Typography variant='caption' className="font-bold">{ infos.join( ' - ' ) }</Typography>
                }
            }

            return (
            <div className={ clsx(classes.root,className) }>
                { label }
                { info }
                { period }
                { description }
            </div>
            );
        }

        return null ;
    };

    getLocale = () => this.props.locale.things.offer ;
}

Offer.defaultProps =
{
    ...Container.defaultProps,
    editable    : false ,
    LinkOptions : null ,
    onClick     : null ,
    thing       : null
};

Offer.propTypes =
{
    ...Container.propTypes,
    editable    : PropTypes.bool,
    LinkOptions : PropTypes.object,
    onClick     : PropTypes.func,
    thing       : PropTypes.object
};

export default compose( withStyles( styles ) , initApp )( Offer ) ;
