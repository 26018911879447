import initDialog from '../initDialog'

import { RemoveDialog } from '../RemoveDialog'

import api from '../../../configs/api'

const styles = () => ({});

class RemoveGameDialog extends RemoveDialog
{
    getLocale = () => this.props.locale.dialogs.application.game.remove ;
}

RemoveGameDialog.defaultProps =
{
    ...RemoveDialog.defaultProps,
    uri    : api.games.url,
    useUrl : true
};

RemoveGameDialog.propTypes =
{
    ...RemoveDialog.propTypes
};

export default initDialog({ styles })( RemoveGameDialog );
