import React from 'react'

import clsx from 'clsx'

import PropTypes from 'prop-types'

import format   from 'vegas-js-core/src/strings/fastformat'
import isString from 'vegas-js-core/src/isString'

import variants   from '../../contexts/snack/variants'

import { Redirect } from 'react-router-dom'

import { Pulse as Preloader } from 'react-preloading-component'

import { DialogTitle, IconButton, TextField, Typography, Zoom } from '@material-ui/core'

import CloseIcon  from '@material-ui/icons/Close'
import SearchIcon from '@material-ui/icons/Search'

import blueGrey from '@material-ui/core/colors/blueGrey'

import CoreDialog   from '../../components/dialogs/CoreDialog'
import UploadButton from '../../components/buttons/UploadButton'
import UploadDialog from './UploadDialog'

import api from '../../configs/api'

import LIST       from '../../net/LIST'
import RequestStatus from '../../net/RequestStatus'

import createMediaObject from '../../things/creativework/createMediaObject'

import Gallery      from '../gallery/Gallery'
import UploadStatus from '../../net/UploadStatus'

import initDialog from './initDialog'

const defaultValues =
{
    count   : 0,
    hasMore : false,
    medias  : [],
    offset  : 0,
    total   : 0
};

const init = values =>
{
    return { ...defaultValues , ...values }
};

const styles = () => (
{
    closeButton :
    {
        marginLeft : 8
    },
    container :
    {
        width     : '100%',
        height    : '100%',
        overflowY : 'scroll',
        padding   : '8px 8px'
    },
    dialogPaper :
    {
        minHeight: '80vh',
        maxHeight: '80vh'
    },
    iconButton :
    {
        marginRight  : 8,
        "&:disabled" :
        {
             color : blueGrey[900]
        }
    },
    root :
    {
        height   : '100%',
        width    : '100%',
        padding  : '8px 0px'
    }
});

class SelectMediaDialog extends CoreDialog
{
    constructor( props )
    {
        super( props );

        this.scroller = null ;
        this._timer   = 0 ;

        const options = init() ;

        this.state =
        {
            fail          : null,
            medias        : [],
            search        : null,
            selectedItems : [],
            status        : RequestStatus.NEW,
            ...options
        };
    }

    agree = () =>
    {
        const { selectedItems } = this.state ;
        if( selectedItems && selectedItems.length > 0 )
        {
            const { onSelect } = this.props ;
            if( onSelect )
            {
                if( this.props.closeOnSelect === true )
                {
                    this.close() ;
                }
                onSelect( [ ...selectedItems ] ) ;
            }
        }
        else
        {
            const locale = this.getLocale() ;
            if( locale )
            {
                const { empty } = locale ;
                if( empty )
                {
                    this.notify( empty , variants.WARNING ) ;
                }
            }
        }
    };

    cancelSearch = () =>
    {
        if( this.state.search !== null )
        {
            this._changeInput( { target:{ value:null } } );
        }
    };

    closeUploadDialog = () =>
    {
        this.setState( { upload:false } ) ;
    };

    init = () =>
    {
        const { offset } = this.state ;
        this.load( offset ) ;
    };

    getAgreeLabel = () =>
    {
        const locale = this.getLocale() ;
        if( locale )
        {
            const { selectedItems } = this.state ;
            const { agree, agreeX } = locale ;

            if( selectedItems instanceof Array && agreeX )
            {
                return format( agreeX , selectedItems.length );
            }
            else if( isString( agree ) )
            {
                return agree ;
            }
        }
        return '' ;
    };

    getCloseButton = () =>
    {
        const {
            classes,
            defaultCloseIcon,
            closeIcon
        } = this.props ;

        const { search } = this.state ;

        const visible = !!(search) ;

        const icon = closeIcon || defaultCloseIcon ;
        return visible ? (
            <Zoom in={visible}>
                <IconButton
                    className = { classes.closeButton }
                    onClick   = { this.cancelSearch }
                >
                    { icon }
                </IconButton>
            </Zoom>
        ) : null ;
    };

    getContent = () =>
    {
        const { status } = this.state ;

        if( status === RequestStatus.REVOKED )
        {
            const { location:referrer } = this.props ;
            return <Redirect to={{ pathname: "/welcome" , state: { referrer } }} />;
        }

        const {
            className,
            classes,
            selectable
        } = this.props ;

        const { medias, selectedItems } = this.state ;

        return(
            <div className = { clsx(classes.root, className) }>
                <Gallery
                    medias        = { medias }
                    selectedItems = { selectedItems }
                    selectable    = { selectable && this.isEditable() }
                    selectOnClick = { true }
                    onClick       = { this.selectMedia }
                    onSelect      = { ( selected , media ) => this.selectMedia( media ) }
                />
                { this.getPreloader() }
            </div>
        )
    };

    getHeader = () => null ;

    getIcon = () =>
    {
        const {
            classes,
            defaultSearchIcon ,
            searchIcon,
            useIcon,
            width
        } = this.props ;
        if( useIcon && ( width !== 'xs' ) )
        {
            const icon = searchIcon || defaultSearchIcon ;
            return (
                <IconButton
                    className = { classes.iconButton }
                    disabled  = { true }
                >
                    { icon }
                </IconButton>
            );
        }
        return null ;
    };

    getLocale = () => this.props.locale.components.dialogs.addMedia ;

    getOptions = () =>
    {
        const { uploadable } = this.props ;
        if( uploadable )
        {
            return (
                <UploadButton
                    onClick = { this.openUploadDialog }
                    size    = 'medium'
                />
            );
        }
    } ;

    getPaperProps = () =>
    {
        const { fullScreen, PaperProps } = this.props ;
        return { ...PaperProps , ...( !fullScreen && { style:{minHeight: '90vh', maxHeight: '90vh'}}) }
    };

    getPreloader = () =>
    {
        const { status } = this.state ;
        let { preloader } = this.props ;
        if( preloader && status === RequestStatus.PROGRESS )
        {
            preloader = (
                <div className='flex items-center justify-center w-full py-20'>
                    { preloader }
                </div>
            );
        }
        else
        {
            preloader = null ;
        }
        return preloader ;
    };

    getTitle = () =>
    {
        const locale = this.getLocale() ;

        let title ;
        if( locale )
        {
            const { multiple } = this.props ;
            const { title:text , titleX } = locale ;
            title = multiple ? titleX : text ;
        }

        let content ;

        const { options, searchable } = this.props ;

        if( searchable )
        {
            const { inputProps } = this.props ;
            const { search, status } = this.state ;

            content = (
                <div className='flex flex-grow flex-row items-center w-full'>
                    { this.getIcon() }
                    <TextField
                        className   = 'pl-8 pr-16'
                        variant     = 'outlined'
                        { ...inputProps }
                        autoFocus   = { true }
                        disabled    = { status === RequestStatus.PROGRESS }
                        fullWidth   = { true }
                        onChange    = { this._changeInput }
                        onKeyDown   = { event =>
                        {
                            if (event.key === 'Escape')
                            {
                                event.preventDefault() ;
                                this.cancelSearch() ;
                            }
                        }}
                        placeholder = { title }
                        value       = { search || '' }
                    />
                    { this.getCloseButton() }
                </div>
            );
        }
        else
        {
            content = (
                <Typography
                        className = 'font-medium w-full'
                        variant   = "subtitle1"
                    >
                        {title}
                </Typography>
            );
        }

        return (
            <DialogTitle disableTypography>
                <div className="w-full flex flex-row items-center">
                    { content }
                    { options || this.getOptions() }
                </div>
            </DialogTitle>
        );
    };


    getUri = () => this.props.uri ;

    hasNext = () =>
    {
         const { hasMore, status } = this.state ;
         return hasMore && status === RequestStatus.SUCCESS ;
    };

    load = ( offset = 0 , options = null ) =>
    {
        const { status } = this.state ;
        if( status !== RequestStatus.PROGRESS )
        {
            clearTimeout(this._timer) ;

            let {
                apiUrl,
                facets,
                limit,
                queries
            }
            = this.props ;

            const { search } = this.state ;

            this.setState({
                offset,
                status:RequestStatus.PROGRESS,
                ...options
            }) ;

            const uri = apiUrl + this.getUri() ;

            if( !!(facets) && !isString(facets) )
            {
                facets = JSON.stringify(facets);
            }

            this.canceler = LIST( uri ,
            {
                facets,
                limit,
                offset,
                queries,
                search,
                random  : true,
                cancel  : this._cancel,
                fail    : this._fail,
                success : this._success
            } );
        }
    };

    next = () =>
    {
        const { count, hasMore } = this.state ;
        if( hasMore )
        {
            this.load( count );
        }
    };

    openUploadDialog = () =>
    {
        const { addDialog, UploadDialogComponent, uploadable } = this.props;
        if ( uploadable && addDialog && UploadDialogComponent )
        {
            const {
                uploadMultiple : multiple,
                uploadProps    : props,
                uploadUri      : uri
            } = this.props;

            const { upload : locale } = this.getLocale() || {} ;

            addDialog(
                UploadDialogComponent ,
                {
                    ...props,
                    locale ,
                    multiple,
                    uri,
                    onUpload : this._upload
                }
            );
        }
    };

    onScrollContent = ( event ) =>
    {
        const { target } = event ;

        const {
            //clientHeight,
            offsetHeight,
            scrollHeight,
            scrollTop
        }
        = target ;

        //if ( scrollTop + clientHeight >= scrollHeight )
        if (  Math.ceil(offsetHeight + scrollTop) === scrollHeight )
        {
            this.next() ; // bottom
        }
    };

    populate = ( datas ) =>
    {
        if( datas instanceof Array )
        {
            return datas.map( createMediaObject ) ;
        }
        return datas ;
    };

    reload = () =>
    {
        this.load( 0, init() ) ;
    };

    selectAll = ( items ) =>
    {
        const { selectable } = this.props ;
        if( selectable )
        {
            this.setState( {selectedItems: [...items] } );
        }
    };

    selectMedia = ( media ) =>
    {
        const { maxItems, multiple } = this.props ;
        const { selectedItems } = this.state ;
        if(
            multiple
            && ( maxItems > 0 )
            && (selectedItems instanceof Array)
            && (selectedItems.length > 0)
            && (selectedItems.indexOf( media ) === -1)
            && (selectedItems.length >= maxItems)
        )
        {
            const locale = this.getLocale() ;
            if( locale )
            {
                const { outOfBounds } = locale ;
                if( outOfBounds )
                {
                    this.notify( outOfBounds , variants.ERROR ) ;
                }
            }
        }
        else
        {
            this.toggleSelected( media , 'id' ) ;
        }
    };


    toggleSelected = ( item , key = null ) =>
    {
        const { multiple, selectable } = this.props ;
        if( selectable )
        {
            let { selectedItems } = this.state;

            if ( !(selectedItems instanceof Array) )
            {
                selectedItems = [] ;
            }

            let index = -1;

            if (key === null)
            {
                index = selectedItems.indexOf(item);
            }
            else
            {
                index = selectedItems.findIndex(element => (item[key] === element[key]));
            }

            if (index > -1)
            {
                if( multiple )
                {
                    selectedItems.splice(index, 1);
                }
                else
                {
                    selectedItems = [] ;
                }
            }
            else
            {
                if( multiple )
                {
                    selectedItems.push(item) ;
                }
                else
                {
                    selectedItems = [ item ] ;
                }
            }

            this.setState( { selectedItems } ) ;
        }
    };

    unmount = () =>
    {
        clearTimeout(this._timer) ;
        clearTimeout(this._timeout) ;

        const { status } = this.state ;
        if( status === RequestStatus.PROGRESS && !!(this.canceler) )
        {
            this.canceler.cancel(this + ' cancel') ;
        }

        const { closeSnack } = this.props ;
        if( closeSnack instanceof Function )
        {
            closeSnack() ;
        }
    };

    unselectAll = () =>
    {
        const { selectable } = this.props ;
        if( selectable )
        {
            this.setState({ selectedItems : [] }) ;
        }
    };

    // --------- PRIVATE

    _cancel = () =>
    {
        // console.log( this + ' cancel : ' + message , true ) ;
    };

    _changeInput = ( event ) =>
    {
        clearTimeout( this._timeout ) ;
        if( event )
        {
            const { target } = event ;
            if( target )
            {
                const { value:search } = target ;
                if( search !== this.state.search )
                {
                    this.setState({ search }) ;
                    this._timeout = setTimeout( this.reload , this.props.delay ) ;
                }
            }
        }
    };

    _fail = response =>
    {
        if( response )
        {
            const { data } = response ;
            if( data )
            {
                const { message } = data ;
                switch( message )
                {
                    case 'token revoked' :
                    {
                        if( this.mounted )
                        {
                            this.setState( { status:RequestStatus.REVOKED } ) ;
                        }
                        break ;
                    }
                    default :
                    {
                        console.log( this + " failed, status:" + response.status + ", message:" + data.message );
                    }
                }
            }
        }

        if( this.mounted )
        {
            this.setState( { status:RequestStatus.FAIL } ) ;
        }
    };

    _success = response =>
    {
        if( response )
        {
            const { data } = response ;
            if( data )
            {
                let { result, total } = data ;

                let { medias } = this.state ;

                let count = medias instanceof Array ? medias.length : 0 ;

                if( result instanceof Array && result.length > 0 )
                {
                    count  += result.length ;
                    result = this.populate(result);
                    medias = [ ...medias, ...result ];
                }

                const hasMore = count < total ;

                this.setState({
                    count, hasMore, medias, total,
                    status:RequestStatus.SUCCESS
                }) ;

                this._timer = setTimeout( this.delayedHasNext , 500 ) ;

                return ;
            }
        }

        this.setState({ status:RequestStatus.FAIL }) ;
    };

    _upload = entries =>
    {
        if( entries instanceof Array && entries.length > 0 )
        {
            const elements = [] ;

            entries.forEach( entry =>
            {
                let { id, name, result, status } = entry ;
                if( status === UploadStatus.UPLOADED )
                {
                    if( result )
                    {
                        elements.push( createMediaObject(result)  );
                    }
                    else
                    {
                        console.log( this + 'onUpload failed with an empty result from the server with the entry ' + name , id );
                    }
                }
            });

            let num = elements.length;
            if( num > 0 )
            {
                let { count, medias, total } = this.state ;

                medias = [ ...elements , ...medias ];

                count += num ;
                total += num ;

                const hasMore = count < total ;

                const options = init({
                    count,
                    hasMore,
                    medias,
                    total
                });

                this.setState({ ...options });
            }
        }
    };

    delayedHasNext = () =>
    {
        if( this.contentRef )
        {
            const { clientHeight, scrollHeight } = this.contentRef ;
            if( this.hasNext() && (clientHeight === scrollHeight) )
            {
                this.next() ;
            }
        }
    }
}

export const defaultInitProps =
{
    maxItems : 10,
    multiple : true ,
    queries  : { sort : '-created,name' }
};

SelectMediaDialog.defaultProps =
{
    ...CoreDialog.defaultProps,
    ...defaultInitProps,
    apiUrl                : api.url,
    closeIcon             : null,
    closeOnSelect         : true,
    defaultCloseIcon      : <CloseIcon fontSize='small'/>,
    defaultSearchIcon     : <SearchIcon fontSize='small'/> ,
    delay                 : 500 ,
    facets                : null,
    inputProps            : null,
    limit                 : 12,
    maxWidth              : 'xl' ,
    name                  : 'add_media_dialog_',
    onSelect              : null,
    preloader             : <Preloader color={ blueGrey[500] }/>,
    searchIcon            : null,
    searchable            : true,
    selectable            : true,
    uploadable            : false,
    UploadDialogComponent : UploadDialog,
    uploadMultiple        : false,
    uploadProps           : null,
    uploadUri             : null,
    useIcon               : false
};

SelectMediaDialog.propTypes =
{
    ...CoreDialog.propTypes,
    apiUrl            : PropTypes.string.isRequired,
    closeIcon         : PropTypes.element,
    closeOnSelect     : PropTypes.bool,
    defaultCloseIcon  : PropTypes.element.isRequired,
    defaultSearchIcon : PropTypes.element.isRequired,
    delay             : PropTypes.number,
    facets            : PropTypes.oneOfType([PropTypes.string,PropTypes.object]),
    inputProps        : PropTypes.object,
    limit             : PropTypes.number,
    maxItems          : PropTypes.number,
    multiple          : PropTypes.bool,
    onSelect          : PropTypes.func,
    preloader         : PropTypes.element,
    queries           : PropTypes.object,
    searchIcon        : PropTypes.element,
    searchable        : PropTypes.bool,
    selectable        : PropTypes.bool,

    uploadable            : PropTypes.bool,
    UploadDialogComponent : PropTypes.elementType,
    uploadMultiple        : PropTypes.bool,
    uploadProps           : PropTypes.object,
    uploadUri             : PropTypes.string,
};

export default initDialog({ useSearch:false, styles })( SelectMediaDialog ) ;
