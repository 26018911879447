
import AnimalHealthReview from './AnimalHealthReview'
import SectorReview       from './SectorReview'
import Workplace          from '../../Workplace'

/**
 * A workplace review.
 * @memberOf things.livestock.reviews
 * @extends things.livestock.AnimalHealthReview
 */
class WorkplaceReview extends AnimalHealthReview
{
    /**
     * Creates a new WorkplaceReview instance.
     * @constructor
     * @param object The generic object to initialize the object.
     */
    constructor( object = null )
    {
        super( object ) ;
    }
    
    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new WorkplaceReview( this.toObject() ) ;
    }
    
    /**
     * Populate the object with specific behaviors or types.
     * Launched by default in the constructor and in the set methods.
     * @returns {Thing} The current reference.
     */
    populate()
    {
        let {
            about,
            subjectOf,
            subReview
        } = this ;
        
        if( about && !(about instanceof Workplace) )
        {
            this.about = new Workplace(about);
        }
        
        if( subReview instanceof Array )
        {
            this.subReview = subReview.map(
                item => item instanceof SectorReview
                      ? item
                      : new SectorReview( { ...item , subjectOf } )
            );
        }
        else if( this.about instanceof Workplace )
        {
            const { sectors } = about ;
            if( sectors instanceof Array && sectors.length > 0 )
            {
                this.subReview = sectors.map( item => new SectorReview({ about:item , subjectOf }) );
            }
        }

        return this ;
    }
    
    /**
     * Returns the generic object representation of the Object.
     * @param {boolean} [full=false] - Indicates if the generic object contains all the properties of only the basic implementation.
     * @return {Object} The generic object representation of the Object.
     */
    toObject( full = false )
    {
        let { about } = this ;
        
        if( !full && about )
        {
            const { id , url } = about ;
            about = { id , url } ;
        }
        else
        {
            about = null ;
        }

        return {
            about,
            ...super.toObject(full)
        };
    }
}

export default WorkplaceReview ;