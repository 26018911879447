import React from 'react'

import PropTypes from 'prop-types'

import isString from 'vegas-js-core/src/isString'

import { Typography } from '@material-ui/core'

import OrdinalIcon from '@material-ui/icons/AccountBalance'

import withi18n from '../../contexts/i18n/withi18n'

import { ThingCell } from './ThingCell'

import AddressLabel      from '../labels/AddressLabel'
import OrganizationLabel from '../labels/OrganizationLabel'
import PersonLabel       from '../labels/PersonLabel'

import Organization from '../../things/Organization'
import Person       from '../../things/Person'
import Thing        from '../../things/Thing'

import getHorizontalElements from '../getHorizontalElements'

class VeterinaryCell extends ThingCell
{
    getAddress = ( { className = null } = {} ) =>
    {
        const { addressable:visible } = this.props;
        const thing = this.getThing() ;
        if( visible && thing )
        {
            const { address } = thing ;
            if ( address )
            {
                return <AddressLabel className={className} thing={thing} />;
            }
        }
        return null;
    }

    getAuthority = ({ className = null } = {} ) =>
    {
        const { authority:visible } = this.props;
        const thing = this.getThing();
        if ( visible && thing instanceof Thing )
        {
            const locale = this.getLocale() ;
            const { authority : { title } = {} } = locale ;
            if ( thing instanceof Person )
            {
                return <PersonLabel className={className} thing={thing} title={title} />;
            }
            else if( thing instanceof Organization )
            {
                return <OrganizationLabel className={className} thing={thing} title={title} />;
            }
        }
        return null;
    }

    getContent = () =>
    {
        const { thing } = this.props;
        if ( thing )
        {
            const elements = [ 'identifier' , 'address' , 'authority' ] ;
            return getHorizontalElements( { elements , factory:this } ) ;
        }
        return null ;
    }

    getIdentifier = () =>
    {
        const { identifiable:visible , thing } = this.props;
        if ( visible && thing )
        {
            const { identifier } = thing ;
            if( isString( identifier ) )
            {
                const locale = this.getLocale() ;
                return (
                    <div className="flex flex-row items-center px-8">
                        <OrdinalIcon fontSize="inherit" />
                        <div className='flex flex-col px-12'>
                            <Typography className='font-500 leading-relaxed' color='textPrimary' variant="caption">{ locale.identifier }</Typography>
                            <span className='px-8 rounded bg-blue-600 text-white text-xl text-center'>{ identifier }</span>
                        </div>
                    </div>
                )
            }
        }
        return null ;
    }

    getLocale = () => this.props.locale.display.cells.veterinary;

    getThing = () =>
    {
        const { thing } = this.props ;
        if( thing )
        {
            const { authority } = thing ;
            if( authority )
            {
                return authority ;
            }
        }
        return null ;
    }
}

VeterinaryCell.defaultProps =
{
    ...ThingCell.defaultProps,
    addressable  : true,
    authority    : true,
    identifiable : true
};

VeterinaryCell.propTypes =
{
    ...ThingCell.propTypes,
    addressable  : PropTypes.bool,
    authority    : PropTypes.bool,
    identifiable : PropTypes.bool
};

export default withi18n(VeterinaryCell);
