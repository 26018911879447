import initDialog from '../initDialog'

import { RemoveDialog } from '../RemoveDialog'

import api from '../../../configs/api'

const styles = () => ({});

class RemovePlaceDialog extends RemoveDialog
{
    getLocale = () => this.props.locale.dialogs.application.place.remove ;
}

RemovePlaceDialog.defaultProps =
{
    ...RemoveDialog.defaultProps,
    uri    : api.places.url,
    useUrl : true
};

RemovePlaceDialog.propTypes =
{
    ...RemoveDialog.propTypes
};

export default initDialog({ styles })( RemovePlaceDialog );
