import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'

import format from 'vegas-js-core/src/strings/fastformat'

import { Grid } from '@material-ui/core'

import indigo from '@material-ui/core/colors/indigo'

import DescriptionIcon  from '@material-ui/icons/Description'
import LabelIcon        from '@material-ui/icons/LabelImportant'

import withLocale from '../../../contexts/i18n/withLocale'

import TransitionGroup from '../../../transitions/TransitionGroup'

import Metadatas from '../../../display/things/Metadatas'
import Team      from '../../../display/things/Team'

import MultiLangEditor   from '../../../components/editors/MultiLangEditor'
import ThingSubContainer from '../../../display/containers/ThingSubContainer'

const styles = () =>
({
    header :
    {
        height          : 100,
        backgroundColor : indigo[700] ,
        backgroundSize  : 'cover',
        color           : '#fff'
    }
});

const full =
{
    xs : 12
};

class About extends ThingSubContainer
{
    getLocale = () => this.props.locale.views.team.about ;

    render = () =>
    {
        const { path, thing } = this.props ;

        if( thing )
        {
            const {
                alternateName,
                description,
                name
            } = thing ;

            const locale = this.getLocale() ;

            return (
            <div className='w-full h-full p-16'>
                <TransitionGroup duration={250} enter={{animation: "transition.slideUpIn"}}>

                    <Metadatas
                        activable = { false }
                        thing     = { thing }
                        uri       = { format(path.team,name) }
                    />

                    <Grid container spacing={4}>

                        <Grid item {...full}>
                            <Team
                                editUri  = { format(path.team,name) }
                                onChange = { this.change }
                                thing    = { thing }
                            />
                        </Grid>

                        <Grid item {...full}>
                           <MultiLangEditor
                                html     = { false }
                                icon     = { <LabelIcon/> }
                                input    = { true }
                                locale   = { locale.alternateName }
                                onChange = { this.changeProperty('alternateName') }
                                text     = { alternateName }
                                uri      = { format( path.alternateName, name ) }
                            />
                        </Grid>

                        <Grid item {...full}>
                            <MultiLangEditor
                                icon     = { <DescriptionIcon/> }
                                locale   = { locale.description }
                                onChange = { this.changeProperty('description') }
                                text     = { description }
                                uri      = { format(path.description, name ) }
                            />
                        </Grid>

                    </Grid>
                </TransitionGroup>
            </div>
            );
        }

        return null  ;
    };
}

About.defaultProps =
{
    ...ThingSubContainer.defaultProps
};

About.propTypes =
{
    ...ThingSubContainer.propTypes
};

export default withStyles( styles )( withRouter( withLocale(About) ) ) ;
