import React from 'react'

import compose from 'vegas-js-core/src/functors/compose'

import initApp from '../../../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { FaSyringe } from 'react-icons/fa'

import AnalysisSampling from './AnalysisSampling'
import ThingChildren , { styles } from '../../ThingChildren'

import AddAnalysisSamplingDialog from './dialogs/AddAnalysisSamplingDialog'

import MedicalSampling from '../../../../things/medical/MedicalSampling'
import Thing           from '../../../../things/Thing'

export const prepare = item =>
{
    let {
        additionalType,
        mandatory
    } = item ;
    return {
        additionalType : additionalType instanceof Thing ? String(additionalType.id) : null ,
        mandatory
    };
};

class AnalysisSamplings extends ThingChildren
{
    createChild = ( child , editable = false ) =>
    {
        return (
        <AnalysisSampling
            editable = { editable }
            thing    = { child }
            onClick  = { () => this.openEditDialog(child) }
        />);
    };

    editPrepare = prepare ;

    getLocale = () => this.props.locale.things.medical.analysisSamplings ;

    openAddDialog = () =>
    {
        const { addDialog } = this.props;
        if ( addDialog )
        {
            const { addUri } = this.props ;
            addDialog(
                AddAnalysisSamplingDialog ,
                {
                    item  : new MedicalSampling() ,
                    onAdd : this.onAdd ,
                    uri   : addUri
                }
            ) ;
        }
    }
}

AnalysisSamplings.defaultProps =
{
    ...ThingChildren.defaultProps,
    clazz     : MedicalSampling,
    emptyIcon : null,
    icon      : <FaSyringe className="self-center" style={{ fontSize:20 }}/>,
    member    : 'analysisSampling'
};

AnalysisSamplings.propTypes =
{
    ...ThingChildren.propTypes
};

export default compose( initApp , withRouter, withStyles( styles ) )( AnalysisSamplings ) ;
