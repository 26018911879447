import React from 'react'

// https://github.com/GIScience/openrouteservice-js
// yarn add openrouteservice-js
// import openrouteservice from 'openrouteservice-js'

import clsx from "clsx"

import PropTypes from "prop-types"

import compose  from 'vegas-js-core/src/functors/compose'
import isNumber from 'vegas-js-core/src/isNumber'
import format   from 'vegas-js-core/src/strings/fastformat'
import leading  from 'vegas-js-core/src/numbers/leading'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { withWidth } from '@material-ui/core'
import { isWidthDown } from '@material-ui/core/withWidth'

import api from '../../../configs/api'

import withConfig  from '../../../contexts/config/withConfig'
import withDialogs from '../../../contexts/dialogs/withDialogs'
import withi18n    from '../../../contexts/i18n/withi18n'

import Map               from '../../../display/maps/Map'
import Steps             from '../../../display/things/course/Steps'
import StepDialog        from "../../../display/dialogs/things/StepDialog"
import ThingSubContainer from '../../../display/containers/ThingSubContainer'

import Place from "../../../things/Place"
import Step  from "../../../things/Step"

const drawerWidth = 480;

const styles = theme =>
({
    drawer:
    {
        backgroundColor : theme.palette.background.paper
    },
    map :
    {
        width        : '100%' ,
        height       : '100%' ,
        zIndex       : 0
    },
    mapMobile :
    {
        marginBottom : 16 ,
        width        : '100%' ,
        height       : 400
    }
});

class Itinerary extends ThingSubContainer
{
    // init = () =>
    // {
    //     let { thing : { steps } = {} } = this.props ;
    //
    //     if( steps instanceof Array && steps.length > 0 )
    //     {
    //         steps = steps.map( this.getPlace ).map( place =>
    //         {
    //             const { geo : { latitude , longitude } = {} } = place || {} ;
    //             if( isNumber(latitude) && isNumber(longitude) )
    //             {
    //                 return [ longitude , latitude ] ;
    //             }
    //             return null ;
    //         }).filter( item => item !== null );
    //
    //         console.log( steps ) ;
    //
    //         let directions = new openrouteservice.Directions({ api_key : '5b3ce3597851110001cf6248d73c3682dde743008fe167410468c63d' }) ;
    //         directions.calculate(
    //         {
    //             coordinates  : steps ,
    //             format       : 'geojson' ,
    //             profile      : 'foot-walking', // driving-car,driving-hgv,cycling-regulat,cycling-road,cycling-mountain,cycling-electric,foot-walking,foot-hiking,wheelchair
    //         })
    //         .then( json =>
    //         {
    //             console.log( this + " init succeed" );
    //             console.log( json );
    //             console.log(JSON.stringify(json));
    //         })
    //         .catch( error =>
    //         {
    //             console.log( this + " init, an error occured" , error );
    //         });
    //     }
    // };

    comparePlace = ( older , latest ) =>
    {
        if( older === null && older === latest )
        {
            return true ;
        }

        if( older instanceof Place && latest instanceof Place )
        {
            if( older.id !== latest.id )
            {
                return false ;
            }

            older  = older.geo ;
            latest = latest.geo ;

            return !(
                   older !== latest
                || older.latitude !== latest.latitude
                || older.longitude !== latest.longitude
            );
        }
        return false ;
    }

    compareStep = ( older , latest ) =>
    {
        if( older === null && older === latest )
        {
            return true ;
        }

        if( older instanceof Step && latest instanceof Step )
        {
            if( older.id !== latest.id )
            {
                return false ;
            }

            older  = this.getPlace( older ) ;
            latest = this.getPlace( latest ) ;

            if( older instanceof Place && latest instanceof Place )
            {
                return this.comparePlace( older , latest ) ;
            }
        }

        return false ;
    }

    compareSteps = ( older , latest ) =>
    {
        if( older === latest )
        {
            return true ;
        }

        if( Array.isArray(older) && Array.isArray(latest) )
        {
            if( older.length === latest.length )
            {
                let len = older.length ;
                for( let i = 0 ; i<len ; i++ )
                {
                    if( !this.compareStep( older[i] , latest[i]) )
                    {
                        return false ;
                    }
                }
                return true ;
            }
        }
        return false ;
    }

    getMap = () =>
    {
        const {
            classes,
            config,
            thing = {}
        } = this.props ;

        const { steps } = thing ;

        return (
            <Map
                { ...config.map }
                className       = { classes.map }
                fly             = { true }
                fullscreenMode  = { true }
                ref             = { ref => this.map = ref }
                getPlace        = { this.getPlace }
                getSpotDivIcon  = { this.getSpotDivIcon }
                getTooltip      = { this.getTooltip }
                gpsFixed        = { true }
                onSelect        = { this.onSelect }
                marker          = { false }
                markerDraggable = { false }
                places          = { steps }
                provider        = { 'OpenStreetMap'}
            />
        );
    };

    getPlace = step =>
    {
        if( step )
        {
            const { stage } = step ;
            if( stage )
            {
                const { location } = stage ;
                return location ;
            }
        }
        return null ;
    };

    getTooltip = step =>
    {
        if( step instanceof Step )
        {
            return '<h6>' + step.getLocaleTitle(this.props.lang) + '</h6>';
        }
        return null ;
    };

    getSpotDivIcon = ( thing , index ) =>
    {
        if( thing )
        {
            let {
                spotClassName ,
                spotClassNames
            } = this.props ;

            if( spotClassNames instanceof Array )
            {
                let modulo = spotClassNames.length ;
                if( modulo > 0 )
                {
                    spotClassName = spotClassNames[index%modulo]
                }
            }

            return {
                className : `flex items-center justify-center font-medium rounded shadow-lg text-2xl ${spotClassName}` ,
                style     : { width:34 , height:34 } ,
                iconSize  : [34, 34],
                html      : `<div style="width:100%; height:100% ; display: flex; align-items: center; justify-content: center;">${leading(index+1)}</div>`
            }
        }
        return null ;
    };

    onSelect = ( step , position ) =>
    {
        if( step instanceof Step )
        {
            const place = this.getPlace( step ) ;
            if( place && this.map )
            {
                const { geo : { latitude , longitude } = {} } = place ;
                if( isNumber(latitude) && isNumber(longitude) && this.map )
                {
                    this.map.flyTo( [ latitude , longitude ] , 19 ) ;
                }
            }
            this.openStepDialog( step , position ) ;
        }
    };

    numSteps = () =>
    {
        const { thing } = this.props ;
        if( thing )
        {
            const { steps } = thing ;
            if( steps instanceof Array )
            {
                return steps.length ;
            }
        }
        return 0 ;
    };

    getSteps = () =>
    {
        const { thing } = this.props ;
        if( thing )
        {
            const { steps } = thing ;
            if( steps instanceof Array )
            {
                return steps ;
            }
        }
        return null ;
    };

    next = index =>
    {
        const { thing } = this.props ;
        if( thing )
        {
            const { steps } = thing ;
            if( steps instanceof Array )
            {
                return steps[index+1] ;
            }
        }
        return null ;
    };

    openStepDialog = ( item , index ) =>
    {
        const { clearDialog } = this.props ;
        if( clearDialog instanceof Function )
        {
            clearDialog( () =>
            {
                const { addDialog } = this.props ;
                if( addDialog instanceof Function )
                {
                    let { url:uri } = item ;
                    const { backPath } = this.props ;

                    uri = uri.split(api.url)[1] ;

                    let onPrevious ;
                    if( index > 0 )
                    {
                        onPrevious = index =>
                        {
                            const prev = this.previous( index ) ;
                            this.onSelect( prev , index-1 ) ;
                        }
                    }

                    let onNext ;
                    if( index < (this.numSteps()-1) )
                    {
                        onNext = index =>
                        {
                            const next = this.next( index ) ;
                            this.onSelect( next , index+1 ) ;
                        }
                    }

                    addDialog(
                        StepDialog ,
                        {
                            index,
                            backPath ,
                            onPrevious ,
                            onNext ,
                            uri,
                            item,
                        }
                    );
                }
            }) ;
        }
    };

    previous = index =>
    {
        const { thing } = this.props ;
        if( thing )
        {
            const { steps } = thing ;
            if( steps instanceof Array )
            {
                return steps[index-1] ;
            }
        }
        return null ;
    };

    render = () =>
    {
        const { thing } = this.props ;
        if( thing )
        {
            const {
                classes,
                mobileWidth,
                path ,
                width
            } = this.props ;

            let map = this.getMap() ;

            let uri = format( path.steps , thing.id ) ;

            let steps =
                <Steps
                    activateUri = { api.steps.url }
                    addUri      = { uri }
                    compare     = { this.compareSteps }
                    onChange    = { this.change }
                    orderUri    = { ( { target : { id } = {} , to } ) => uri + '/' + id + '/position/' + to }
                    onSelect    = { this.onSelect }
                    thing       = { thing }
                /> ;

            if( isWidthDown(mobileWidth,width) )
            {
                return (
                <div className='w-full px-16 py-24'>
                    <div className='mb-16' >
                        { steps }
                    </div>
                    <div className={ classes.mapMobile } >
                        { map }
                    </div>
                </div>
                );
            }
            else
            {
                return (
                    <div className='relative w-full self-stretch overflow-auto'>
                        <div className="h-full" style={{ marginRight:drawerWidth }} >
                            { map }
                        </div>
                        <div
                            className = { clsx( "absolute inset-y-0 right-0 overflow-y-scroll py-16 z-50 shadow" , classes.drawer ) }
                            style     = {{ width:drawerWidth }}
                        >
                            { steps }
                        </div>
                    </div>
                );
            }
        }

        return null;
    }
}

Itinerary.defaultProps =
{
    ...ThingSubContainer.defaultProps,
    mobileWidth    : 'sm' ,
    spotClassName  : 'bg-gray-800 text-white' ,
    spotClassNames :
    [
        'bg-blue-800 text-white' ,
        'bg-blue-700 text-white'
    ]
};

Itinerary.propTypes =
{
    ...ThingSubContainer.propTypes,
    mobileWidth    : PropTypes.oneOf(['xs','sm','md','lg','xl']) ,
    spotClassNames : PropTypes.arrayOf(PropTypes.string)
};

export default compose(
    withConfig,
    withDialogs,
    withi18n,
    withRouter,
    withStyles( styles ),
    withWidth()
)( Itinerary ) ;
