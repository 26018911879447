/**
 * Generates the navigation of the application with the current user scope and the application roles definition.
 * @param {Array} items - The items reference to generate the application navigation elements.
 * @param {User} user - The user reference.
 * @param {Object} permissions - The permissions definitions.
 * @returns {any[]|Array}
 */
const generate = ( items , user , permissions ) =>
{
    if( items instanceof Array && items.length > 0 )
    {
        return items.map( item =>
        {
            const { type } = item ;
            
            switch( type )
            {
                case 'divider' :
                {
                    return item ;
                }
                case 'collapse' :
                case 'group'    :
                {
                    let { children } = item ;
                    if( children instanceof Array && children.length > 0 )
                    {
                        children = generate( children , user , permissions ) ;
                    }
                    
                    if( children instanceof Array && children.length > 0 )
                    {
                        item.children = children ;
                        return item ;
                    }
                    
                    return null ;
                }
                case 'item' :
                {
                    let { url } = item ;
                    
                    if( (typeof(url) === 'string') || (url instanceof String) )
                    {
                        if( url.indexOf('/') === 0 )
                        {
                            url = url.slice(1) ;
                        }

                        url = url.split('/')[0] ;
                        
                        if( user && permissions && permissions.hasOwnProperty(url) )
                        {
                            let role = permissions[url] ;
                            if( role instanceof Array )
                            {
                                let { scope } = user ;
                                if( scope )
                                {
                                    const keys = Object.keys( scope ) ;
                                    if( keys instanceof Array && keys.length > 0 )
                                    {
                                        const len = keys.length ;
                                        for( let i = 0 ; i<len ; i++ )
                                        {
                                            let key = keys[i] ;
                                            if( key.indexOf(url) === 0  ) // test a specific scope, ex: applications/ or applications/xxxxx
                                            {
                                                scope = scope[url] ;
                                                if ( role.indexOf(scope) > -1 )
                                                {
                                                    return item;
                                                }
                                            }
                                        }
                                    }
                                }
                                else if( (role instanceof Array) ? (role.indexOf('guest') > -1) : (role === 'guest') )
                                {
                                    return item ;
                                }
                            }
                            else
                            {
                                return item ;
                            }
                        }
                    }
                    
                    return null ;
                    
                }
                default :
                {
                    return null ;
                }
            }
        }).filter( item => item !== null );
    }
    
    return [] ;
};

export default generate ;