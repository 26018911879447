import React from 'react'

import SearchConsumer from './SearchConsumer'

export const withSearch = Component => React.forwardRef( ( props , ref ) =>
(
    <SearchConsumer>
        { store => <Component {...props} {...store} ref={ref} /> }
    </SearchConsumer>
));

export default withSearch ;