import { YOUTUBE } from '../../../things/creativework/mimetypes'
import Form from "../../../display/forms/FormElements";

export const elements =
[
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'name',
            key          : 'name',
            label        : 'Nom du média',
            autoFocus    : false,
            fullWidth    : true,
            helperText   : 'Obligatoire',
            margin       : 'normal',
            required     : true,
            select       : false,
            autoComplete : 'off' ,
            type         : 'search' ,
            variant      : 'outlined'
        }
    },
    {
        type : Form.I18N_EDITOR ,
        init :
        {
            id    : 'headline' ,
            key   : 'headline' ,
            input : true,
            label : 'Titre'
        }
    },
    {
        type : Form.I18N_EDITOR ,
        init :
        {
            id    : 'alternativeHeadline',
            key   : 'alternativeHeadline',
            input : true,
            label : 'Texte alternatif',
        }
    },
    {
        type : Form.I18N_EDITOR ,
        init :
        {
            id    : 'caption',
            key   : 'caption',
            input : true,
            label : 'Légende'
        }
    },
    {
        type : Form.I18N_EDITOR ,
        init :
        {
            id    : 'description' ,
            key   : 'description' ,
            input : true,
            label : 'Description'
        }
    },
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id         : 'license',
            key        : 'license',
            label      : 'Licence',
            autoFocus  : false ,
            fullWidth  : true ,
            helperText : null ,
            margin     : 'dense' ,
            required   : false ,
            select     : false ,
            type       : 'text',
            variant    : 'outlined'
        }
    },
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id         : 'author',
            key        : 'author',
            label      : 'Auteur',
            autoFocus  : false ,
            fullWidth  : true ,
            helperText : null ,
            margin     : 'dense' ,
            required   : false ,
            select     : false ,
            type       : 'text',
            variant    : 'outlined'
        }
    },
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id         : 'editor',
            key        : 'editor',
            label      : 'Éditeur',
            autoFocus  : false ,
            fullWidth  : true ,
            helperText : null ,
            margin     : 'dense' ,
            required   : false ,
            select     : false ,
            type       : 'text',
            variant    : 'outlined'
        }
    }
];

const embed =
{
    agree    : 'Envoyer',
    disagree : 'Fermer',
    title    : 'Insérer un média externe' ,
    form     :
    {
        elements,
        agree           : 'Enregistrer' ,
        encodingFormats :
        {
            [ YOUTUBE ] :
            {
                label : 'Complétez les informations ci-dessous avant d\'enregistrer ce média Youtube dans la médiathèque. N\'oubliez pas de saisir au minimum le nom du nouveau média dans le formulaire ci-dessous.'
            }
        }
    },
    init :
    {
        agree           : 'Créer' ,
        create          : 'Créer le média externe' ,
        label           : 'Saisir ou copier un lien ...' ,
        caption         : "Entrez l'URL d'un media externe. Actuellement sont pris en charge les liens Youtube." ,
        encodingFormats :
        {
            [ YOUTUBE ] :
            {
                caption : 'Cette ressource Youtube est valide. Appuyez sur le bouton CRÉER ci-dessous pour compléter la procédure en cours.' ,
                label   : 'Ce lien est une url Youtube valide, souhaitez vous créer et enregistrer ce média ?'
            }
        },
        search : 'Recherche d\'un média en cours...'
    }

};

export default embed ;
