import React from 'react'

import ConfigConsumer from './ConfigConsumer'

export const withConfig = Component => React.forwardRef(( props , ref ) =>
(
    <ConfigConsumer>
        { store => <Component {...props} {...store} ref={ref} /> }
    </ConfigConsumer>
));

export default withConfig ;