import Form from '../../../../display/forms/FormElements'

import Place from '../../../../things/Place'

import api from '../../../../configs/api'

import getLocaleThingLabel from '../../../../things/getLocaleThingLabel'

const elements =
[
    {
        type  : Form.SEARCH_SELECTOR,
        path  : api.places.url,
        clazz : Place ,
        init  :
        {
            id         : 'location',
            key        : 'location',
            label      : 'Lieu',
            autoFocus  : false,
            fullWidth  : true,
            limit      : 0,
            helperText : 'Obligatoire',
            margin     : 'normal',
            required   : true,
            type       : 'search',
            variant    : 'outlined'
        },
        populate : ( thing , { props : { lang } = {} } = {} ) =>
        {
            if( thing )
            {
                const { location } = thing ;
                if( location )
                {
                    thing.name = getLocaleThingLabel( location , lang ) ;
                }
            }
        },
        selector : { i18n :
        {
            empty       : 'Aucun lieu disponible.' ,
            title       : 'Sélectionner un lieu',
            description : 'Veuillez sélectionner un lieu ci-dessous.' ,
        }}
    },
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'name',
            key          : 'name',
            label        : 'Nom du Point d\'intérêt',
            autoComplete : 'off' ,
            autoFocus    : true,
            fullWidth    : true,
            helperText   : 'Obligatoire',
            margin       : 'normal',
            required     : true,
            select       : false,
            type         : 'text',
            variant      : 'outlined'
        }
    },
];

const stage =
{
    add :
    {
        elements,
        agree       : 'Créer' ,
        disagree    : 'Annuler' ,
        title       : 'Ajouter un Point d\'intérêt' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour créer un point d\'intérêt.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en  cours...' ,
        success     : 'Ajout du nouvel enregistrement réussi!' ,
    },
    remove :
    {
        agree        : 'Supprimer' ,
        description  : 'Souhaitez-vous supprimer le point d\'intérêt suivant :' ,
        descriptionX : 'Souhaitez-vous supprimer les {0} points d\'intérêt suivants :' ,
        disagree     : 'Annuler' ,
        loading      : 'Suppression en cours...' ,
        title        : 'Supprimer ce Point d\'intérêt ?',
        titleX       : 'Supprimer des Points d\'intérêts ?'
    }
};

export default stage ;
