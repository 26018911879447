import React from "react"

import ApplicationsList from '../../display/lists/ApplicationsList'

import View from '../../display/views/View'

import initView from '../../display/views/initView'

const styles = () => ({}) ;

class Applications extends View
{
    build = () =>
    {
        return (
        <div className='flex flex-col w-full'>
            <ApplicationsList parent = { this.element } />
        </div>);
    }
    
    getLocale = () => this.props.locale.views.applications ;
}

Applications.defaultProps =
{
    ...View.defaultProps
};

Applications.propTypes =
{
    ...View.propTypes
};

export default initView( Applications, styles );