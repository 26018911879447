import isString      from 'vegas-js-core/src/isString'

import Thing         from '../../../things/Thing'
import Veterinarian  from '../../../things/Veterinarian'

const getItem = ( unknown = '' ) =>
({
    text :
    {
        primary : ( element , props ) =>
        {
            if( element instanceof Veterinarian )
            {
                const { authority } = element ;
                if( authority instanceof Thing )
                {
                    return authority.getLocaleName(props.lang);
                }
            }
            return unknown ;
        },
        secondary : ( element ) =>
        {
            if( element instanceof Veterinarian )
            {
                const { identifier } = element ;
                if( isString(identifier) )
                {
                    return veterinarian.labels.identifier + " " + identifier ;
                }
            }
            return null ;
        }
    }
});

const veterinarian =
{
    helmet :
    {
        title : 'Vétérinaire - {0}' ,
        metas : []
    },
    about :
    {
        title : "À propos"
    },
    labels :
    {
        identifier : 'N° ordinal'
    },
    loading  : "Chargement ..." ,
    notFound : "Vétérinaire inconnu" ,
    remove   :
    {
        agree        : 'Supprimer' ,
        description  : 'Souhaitez-vous supprimer la fiche du vétérinaire:' ,
        disagree     : 'Annuler' ,
        item         : getItem( 'Inconnu' ),
        loading      : 'Suppression en cours...' ,
        title        : 'Supprimer ce vétérinaire ?' ,
        useID        : false
    },
    subtitle : 'N° ordinal: {0}',
    tabs     :
    {
        about      : 'À propos' ,
        livestocks : 'Élevages'
    },
    tooltips :
    {
        back : "Tous les vétérinaires"
    }
};

export default veterinarian ;