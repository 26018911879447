import React from 'react'

import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import PersonIcon         from '@material-ui/icons/Person'

import Form from '../../../../display/forms/FormElements'

import Organization from '../../../../things/Organization'
import Person       from '../../../../things/Person'

import dialogType from '../../../../display/dialogs/dialogType'

import api from '../../../../configs/api'

import getExistingSuggestions from '../../../../things/getExistingSuggestions'

const elements =
[
    {
        label : 'Personne' ,
        icon  : <PersonIcon/> ,
        elements :
        [
            {
                type  : Form.SEARCH_SELECTOR ,
                clazz : Person,
                path  : api.people.url ,
                init  :
                {
                    id        : 'value',
                    key       : 'value',
                    label     : 'Personne',
                    autoFocus : false,
                    fullWidth : true,
                    margin    : 'normal',
                    required  : true,
                    type      : 'search',
                    variant   : 'outlined'
                },
                disabledSuggestions : getExistingSuggestions({ member:'members' , clazz:Person }) ,
                selector : { i18n :
                {
                    empty       : 'Aucune personne disponible.' ,
                    title       : 'Sélectionner une personne',
                    description : 'Veuillez sélectionner une personne ci-dessous.' ,
                }}
            }
        ]
    },
    {
        label : 'Organisation' ,
        icon  : <AccountBalanceIcon/> ,
        elements :
        [
            {
                type  : Form.SEARCH_SELECTOR ,
                path  : api.organizations.url ,
                clazz : Organization,
                init  :
                {
                    id        : 'value',
                    key       : 'value',
                    label     : 'Organisation',
                    autoFocus : false,
                    fullWidth : true,
                    margin    : 'normal',
                    required  : true,
                    type      : 'search',
                    variant   : 'outlined'
                },
                disabledSuggestions : getExistingSuggestions({ member:'members' , clazz:Organization , include:true }) ,
                selector : { i18n :
                {
                    empty       : 'Aucune organisation disponible.' ,
                    title       : 'Sélectionner une organisation',
                    description : 'Veuillez sélectionner une organisation ci-dessous.' ,
                }}
            }
        ]
    }
];

const members =
{
    add :
    {
        elements,
        agree       : 'Ajouter' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez sélectionner une personne en cliquant ci-dessous.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en  cours...' ,
        success     : 'Ajout du nouveau membre réussi!' ,
        title       : 'Ajouter un membre',
        type        : dialogType.SWIPEABLE
    },
    remove :
    {
        agree       : 'Supprimer' ,
        description : 'Souhaitez-vous supprimer ce membre:' ,
        disagree    : 'Annuler' ,
        loading     : 'Suppression en cours...' ,
        title       : 'Supprimer le membre ?'
    },
    title : 'Membres' ,
    tooltips :
    {
        add    : "Ajouter" ,
        edit   : "Éditer" ,
        remove : "Supprimer"
    }
};

export default members ;