const place =
{
    copy :
    {
        snack   : 'Lieu dans le presse-papiers.' ,
        tooltip : 'Copier le lieu'
    },
    label : '{0}',
    title : 'Localisation'
};

export default place ;
