import User from '../../../../vo/User'

const getItem = ( unknown = 'unknown' ) =>
({
    text :
    {
        primary : ( thing , props ) =>
        {
            if( props && thing instanceof User )
            {
                const { lang } = props ;
                return thing.getFullNameOrUsername( lang ) || unknown ;
            }
            return unknown ;
        }
    }
});

export default
{
    remove :
    {
        agree        : 'Supprimer' ,
        description  : 'Souhaitez-vous supprimer l\'utilisateur:' ,
        descriptionX : 'Souhaitez-vous supprimer les {0} utilisateurs:' ,
        disagree     : 'Annuler' ,
        item         : getItem('Inconnu') ,
        loading      : 'Suppression en cours...' ,
        title        : 'Supprimer cet utilisateur ?',
        titleX       : 'Supprimer des utilisateurs ?',
        useID        : false
    }
};
