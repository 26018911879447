import React from 'react'

import format   from 'vegas-js-core/src/strings/fastformat'
import isString from 'vegas-js-core/src/isString'

import SettingsIcon from '@material-ui/icons/SettingsApplications'

import { ThingTable } from './ThingTable'

import Connection from '../../things/Connection'
import Thing      from '../../things/Thing'
import Word       from '../../things/Word'

import ConnectionCell from '../cells/ConnectionCell'

import createByUrl from '../../vo/createByUrl'
import sortThing   from '../../things/sortThing'

import api from '../../configs/api'

import withDataTable from './withDataTable'

const populateConnection = ( item , { lang = null , sortable = true } = {} ) =>
{
    let connection ;

    if( !(item instanceof Connection) )
    {
        connection = new Connection(item) ;

        let { from , to } = item ;

        connection.from = populateFrom(from) ;
        connection.to   = populateTo(to) ;
    }
    else
    {
        connection = item ;
    }

    if( sortable && connection )
    {
        const { from } = connection ;
        if( from instanceof Array )
        {
            from.sort( sortThing(lang) );
        }
        connection.from = from ;
    }

    return connection ;
};

const populateFrom = from =>
{
    if( from instanceof Array && from.length > 0 )
    {
        return from.map( item => createByUrl(item) ) ;
    }
    return from ;
};

const populateTo = to => createByUrl( to ) ;

class SetTable extends ThingTable
{
    editInit = item =>
    {
        if( item )
        {
            const { from } = item ;
            if( from instanceof Array )
            {
                return from.map(
                    element => element instanceof Thing
                             ? element.clone()
                             : null
                ) ;
            }
        }
        return null ;
    };

    editPrepare = datas =>
    {
        if( datas instanceof Array )
        {
            datas = datas.map( item => item instanceof Thing ? item.id : null ) ;
            return { list:datas.join(',') } ;
        }
        return null ;
    };

    getEntry = init =>
    {
        let { object } = this.state ;

        let empty = {} ;
        if( init instanceof Thing )
        {
            empty = init.toObject() ;
        }
        else if( init )
        {
            empty = { ...init } ;
        }

        const connection = populateConnection( empty ,{ lang:this.props.lang } ) ;

        connection.subjectOf = object ;

        return connection ;
    };

    getLocale = () => this.props.locale.display.tables.set ;

    getSubtitle = () =>
    {
        const { object } = this.state ;
        if ( object )
        {
            const { category } = object ;
            if( category )
            {
                return category ;
            }
        }
        return null ;
    };

    onEdit = ( item , origin ) =>
    {
        if( origin )
        {
            const { to } = origin ;
            if( to instanceof Thing )
            {
                const { id } = to ;
                let { things } = this.state ;
                if( things instanceof Array )
                {
                    const { lang } = this.props;
                    things = things.map( element =>
                    {
                        if ( element )
                        {
                            const { to } = element;
                            if ( to instanceof Thing )
                            {
                                if ( to.id === id )
                                {
                                    if ( item instanceof Array )
                                    {
                                        item = item.map( element => element instanceof Word ? element : new Word( element ) );
                                        item.sort( sortThing( lang ) );
                                    }
                                    element.from = item;
                                }
                            }
                        }
                        return element;
                    } )
                }
                this.setState({ things });
            }

        }
    };

    getQueries = () =>
    {
        let { lang , queries } = this.props ;
        if( lang )
        {
            return { ...queries, sort : lang } ;
        }
        return queries ;
    };

    onSelect = item => this.openEditDialog( item ) ;
}

SetTable.defaultProps =
{
    ...ThingTable.defaultProps,
    activable     : false ,
    addable       : false,
    CellComponent : ConnectionCell,
    cellMode      : 'button',
    cellProps     : { titleProps : { className:'cursor-pointer' , underline:'none' }  },
    clazz         : Connection ,
    deletable     : false ,
    icon          : <SettingsIcon />,
    populate      : ( things , props ) =>
    {
        if( things instanceof Array )
        {
            const { lang } = props || {} ;
            things = things.map( item => populateConnection( item , { lang }) ) ;
            things.sort( ( a , b ) =>
            {
                let result = 0 ;
                if( (a instanceof Connection) && (b instanceof Connection) )
                {
                    const { to:to1 } = a ;
                    const { to:to2 } = b ;
                    result = sortThing(lang)( to1, to2 ) ;
                }
                return result ;
            });
        }
        return things ;
    },
    selectable    : false,
    sortable      : false,
    editMapUri    : item =>
    {
        if( item )
        {
            const { subjectOf, to } = item ;
            if( subjectOf && to instanceof Word )
            {
                const { id }  = to ;
                let { put } = subjectOf ;
                if( isString(put) )
                {
                    put = put.split(api.url)[1];
                    return format(put,id) ;
                }

            }
        }
        return null ;
    },
    editUri : ( path , props , state ) =>
    {
        const { object } = state ;
        if ( object )
        {
            let { from } = object ;
            if( isString(from) )
            {
                return from.split(api.url)[1];
            }
        }
        return null ;
    }
};

SetTable.propTypes =
{
    ...ThingTable.propTypes
};

export default withDataTable()( SetTable ) ;
