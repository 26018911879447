
import isPlainObject from 'vegas-js-core/src/isPlainObject'
import isString      from 'vegas-js-core/src/isString'

import AudioObject from './media/AudioObject'
import VideoObject from './media/VideoObject'
import ImageObject from './media/ImageObject'

import DigitalDocument from './DigitalDocument'
import MediaObject     from './MediaObject'

import mimetypes from './mimetypes'

/**
 * Creates a MediaObject with a specific thing object.
 * @name createMediaObject
 * @instance
 * @function
 * @memberOf things.CreativeWork
 * @param thing
 * @param withMimetypes
 * @returns {DigitalDocument|AudioObject|null|MediaObject|ImageObject|MediaObject|VideoObject}
 */
const createMediaObject = ( thing , withMimetypes = null ) =>
{
    if( thing )
    {
        if( thing instanceof MediaObject )
        {
            return thing ;
        }

        let { encodingFormat } = thing ;
        if( isString(encodingFormat) && (encodingFormat !== '') )
        {
            let types = withMimetypes !== null && isPlainObject(withMimetypes) ? withMimetypes : mimetypes ;

            if( types[encodingFormat] !== null )
            {
                encodingFormat = types[encodingFormat] ;
            }
            else
            {
                encodingFormat = encodingFormat.split('/')[0] ;
            }

            switch( encodingFormat )
            {
                case 'audio' :
                {
                    return new AudioObject(thing) ;
                }
                case 'document' :
                {
                    return new DigitalDocument(thing) ;
                }
                case 'image' :
                {
                    return new ImageObject(thing) ;
                }
                case 'media' :
                {
                    return new MediaObject(thing) ;
                }
                case 'video' :
                {
                    return new VideoObject(thing) ;
                }
                default :
                {
                    return new MediaObject(thing) ;
                }
            }
        }
        return new MediaObject(thing) ;
    }
    return null ;
};

export default createMediaObject ;
