import article          from './article'
import conceptualObject from './conceptualObject'
import course           from './course'
import event            from './event'
import organization     from './organization'
import person           from './person'
import place            from './place'
import stage            from './stage'
import thing            from './thing'

export default
{
    // dependencies

    article,
    conceptualObject,
    course,
    event,
    organization,
    person,
    place,
    stage,
    thing
};
