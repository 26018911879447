import React from "react"

import isString from 'vegas-js-core/src/isString'

import TerrainIcon from '@material-ui/icons/Terrain'

import FeedbackIcon from '@material-ui/icons/Feedback';
import PagesIcon    from '@material-ui/icons/Pages';
import GroupIcon    from '@material-ui/icons/Group';

import { FaBarcode } from 'react-icons/fa';

import isNotEmpty from 'vegas-js-core/src/isNotEmpty'
import format     from 'vegas-js-core/src/strings/fastformat'

import getLocaleThingLabel from '../../things/getLocaleThingLabel';
import ThingView , { defaultStyle } from '../../display/views/ThingView'

import api from '../../configs/api'

import Numbers         from '../../display/things/Numbers'
import Workshops       from '../../display/things/livestock/Workshops'
import ObservationList from '../../display/lists/ObservationList'

import About       from './views/About'
import Authorities from './views/Authorities'

import LivestockVO from '../../things/Livestock'
import NumberValue from '../../things/NumberValue'
import Thing       from '../../things/Thing'
import Workshop    from '../../things/Workshop'

import paths from '../../configs/paths'

import initView    from '../../display/views/initView'
import Observation from '../../things/Observation'

const styles = defaultStyle(
{
    navLabel :
    {
        paddingBottom : 10 ,
        paddingTop    : 4
    }
});

class Livestock extends ThingView
{
    getHeaderThing = () =>
    {
        const { thing } = this.state ;
        if( thing )
        {
            const { organization } = thing ;
            if( organization instanceof Thing )
            {
                return organization ;
            }
        }
    }

    getLocale = () => this.props.locale.views.livestock ;

    getRoutePath = path =>
    {
        if( isNotEmpty(path) )
        {
            const { thing } = this.state ;
            if( thing instanceof Thing )
            {
                return format( path , thing.id ) ;
            }
        }
        return null ;
    }

    getSubtitle = () =>
    {
        const locale = this.getLocale() ;
        if( locale )
        {
            const { subtitle } = locale ;
            if( isString(subtitle) )
            {
                return subtitle ;
            }
        }
        return null  ;
    };

    getTitle = thing =>
    {
        if( thing instanceof Thing )
        {
            const { organization } = thing ;
            if( organization )
            {
                return getLocaleThingLabel( organization , this.props.lang ) ;
            }
        }
        return null ;
    };

    onRemoveIdentifiers = ( { item } = {} ) =>
    {
        const { thing } = this.state ;
        if( thing instanceof LivestockVO )
        {
            if( item instanceof NumberValue )
            {
                let { numbers } = thing ;
                if( numbers instanceof Array && numbers.length > 0 )
                {
                    thing.numbers = numbers.filter( element => element.id !== item.id ) ;
                    this.change( thing ) ;
                }
            }
        }
    }

    onRemoveWorkshops = ( { item } = {} ) =>
    {
        const { thing } = this.state ;
        if( thing instanceof LivestockVO )
        {
            if( item instanceof Workshop )
            {
                let { workshops } = thing ;
                if( workshops instanceof Array && workshops.length > 0 )
                {
                    thing.workshops = workshops.filter( element => element.id !== item.id ) ;
                    this.change( thing ) ;
                }
            }
        }
    }
}

Livestock.defaultProps =
{
    ...ThingView.defaultProps,
    back       : true,
    clazz      : LivestockVO,
    icon       : <TerrainIcon />,
    path       : api.livestocks,
    preferable : true  ,
    responsive : true  ,
    routes     :
    [
        {
            id             : 'about' ,
            RouteComponent : About
        },
        {
            id             : 'workshops' ,
            onRemove       : 'onRemoveWorkshops' ,
            RouteComponent : Workshops ,
            routeClazz     : Workshop
        } ,
        {
            id             : 'observations' ,
            addable        : false,
            deletable      : false ,
            RouteComponent : ObservationList ,
            routeClazz     : Observation,
            routePath      : api.livestocks.observations
        } ,
        {
            id             : 'authorities' ,
            RouteComponent : Authorities
        } ,
        {
            id             : 'numbers' ,
            onRemove       : 'onRemoveIdentifiers' ,
            routeClazz     : NumberValue ,
            RouteComponent : Numbers
        } ,
    ],
    tabs :
    [
        { id : 'about'        , icon  : <TerrainIcon  className='pr-8' /> },
        { id : 'workshops'    , icon  : <PagesIcon    className='pr-8' /> },
        { id : 'observations' , icon  : <FeedbackIcon className='pr-8' /> },
        { id : 'authorities'  , icon  : <GroupIcon    className='pr-8' /> },
        { id : 'numbers'      , icon  : <FaBarcode    className="mb-4 self-center" style={{ fontSize:18 }}/> }
    ]
};

Livestock.propTypes =
{
    ...ThingView.propTypes,
    path : paths.livestocks
};

export default initView( Livestock, styles );
