import React from 'react';

import PropTypes from 'prop-types'

import clsx from 'clsx'

import compose from 'vegas-js-core/src/functors/compose'

import { withStyles } from '@material-ui/core/styles'

import { Icon, ListItem, ListItemText } from '@material-ui/core'

import { withRouter } from 'react-router-dom'

import NavigationBadge from '../NavigationBadge'

import withSettings from '../../../contexts/settings/withSettings'

import AbstractItem from './AbstractItem'
import ItemLink     from './ItemLink'

const styles = theme => (
{
    item:
    {
        height       : 40,
        width        : 'calc(100% - 16px)',
        borderRadius : '0 20px 20px 0',
        paddingRight : 12,
        '&.active'   :
        {
            backgroundColor             : theme.palette.secondary.main,
            color                       : theme.palette.secondary.contrastText + '!important',
            pointerEvents               : 'none',
            transition                  : 'border-radius .15s cubic-bezier(0.4,0.0,0.2,1)',
            '& .list-item-text-primary' : { color: 'inherit' },
            '& .list-item-icon'         : { color: 'inherit' }
        },
        '& .list-item-icon' : {},
        '& .list-item-text' : {},
        color               : theme.palette.text.primary,
        cursor              : 'pointer',
        textDecoration      : 'none!important'
    }
});

class Item extends AbstractItem
{
    render()
    {
        const { item } = this.props ;
        if( !item )
        {
            return null ;
        }

        const { classes, navbarCloseMobile } = this.props ;

        let {
            badge,
            exact,
            icon,
            title,
            url
        } = item ;

        return (
            <ListItem
                button          = { true }
                activeClassName = 'active'
                className       = { clsx(classes.item , 'list-item' ) }
                style           = {{ paddingLeft : this.getPaddingLeft() }}
                exact           = { exact }
                onClick         = { navbarCloseMobile }
                component       = { ItemLink }
                to              = { url }
            >
                { icon  && <Icon className="list-item-icon text-3xl flex-shrink-0 mr-12" color="action">{ icon }</Icon> }
                { title && <ListItemText className="list-item-text" classes={{ primary:'text-2xl list-item-text-primary' }} primary={title}/> }
                { badge && <NavigationBadge badge={badge}/> }
            </ListItem>
        );
    }
}

Item.defaultProps =
{

};

Item.propTypes =
{
    item : PropTypes.shape(
    {
        id    : PropTypes.string.isRequired,
        title : PropTypes.string,
        icon  : PropTypes.string,
        url   : PropTypes.string
    })
};

export default compose(
    withStyles( styles , { withTheme : true } ) ,
    withRouter,
    withSettings
)( Item );
