import React from 'react'

import EditLocationIcon from '@material-ui/icons/EditLocation'

import api from '../../../../configs/api'

import Form   from '../../../../display/forms/FormElements'
import Method from '../../../../net/Method'

const elements =
[
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'name',
            label        : 'Nom de la maladie',
            autoFocus    : true,
            fullWidth    : true,
            helperText   : 'Obligatoire',
            margin       : 'normal',
            required     : true,
            select       : false,
            autoComplete : 'off' ,
            type         : 'text',
            variant      : 'outlined'
        }
    },
    {
        type       : Form.SEARCH_SELECTOR ,
        path       : api.thesaurus.diseases_types ,
        clearLabel : 'Vider' ,
        closeLabel : 'Fermer' ,
        init  :
        {
            id         : 'additionalType',
            key        : 'additionalType',
            label      : 'Type de la maladie',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            type       : 'search',
            variant    : 'outlined'
        },
        inputProps :
        {
            label : 'Sélectionner un type de maladie...'
        },
        searchIcon : <EditLocationIcon/>
    },
    {
        type       : Form.SEARCH_SELECTOR ,
        path       : api.thesaurus.medical_levels ,
        clearLabel : 'Vider' ,
        closeLabel : 'Fermer' ,
        init  :
        {
            id         : 'level',
            key        : 'level',
            label      : 'Niveau de la maladie',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            type       : 'search',
            variant    : 'outlined'
        },
        inputProps :
        {
            label : 'Sélectionner un niveau...'
        },
        searchIcon : <EditLocationIcon/>
    }
];

const disease =
{
    edit :
    {
        agree       : 'Sauver' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour modifier cette maladie.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...' ,
        method      : Method.PATCH,
        success     : 'Mise à jour réussie!' ,
        title       : 'Modifier la maladie' ,
        elements
    },
    labels :
    {
        empty              : '----' ,
        level              : 'Niveau de la maladie',
        name               : 'Nom' ,
        transmissionMethod : 'Méthode de transmission',
        type               : 'Type de la maladie'
    },
    title    : 'Maladie' ,
    tooltips :
    {
        add    : 'Ajouter',
        edit   : 'Modifier',
        remove : 'Supprimer'
    }
};

export default disease ;