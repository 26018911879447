import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import Form from '../../../display/forms/FormElements'

import PropertyValue from '../../../things/PropertyValue'
import Thing         from '../../../things/Thing'
import Word          from '../../../things/Word'

import api from "../../../configs/api"

const disabledSuggestions = props =>
{
    const { rel } = props ;
    let suggestions = [] ;
    if( rel instanceof Thing )
    {
        const { additionalType } = rel ;
        if( additionalType instanceof Word )
        {
            suggestions.push( additionalType ) ;
        }
    }
    return suggestions ;
};

const primary = item => item instanceof PropertyValue ? item.value : null ;

const secondary = ( item , props ) =>
{
   if( item instanceof PropertyValue )
   {
        const { additionalType} = item ;
        if( additionalType instanceof Thing )
        {
            let name = additionalType.getLocaleName(props.lang) ;
            if( name && name !== '')
            {
                return ucFirst(name) ;
            }
        }
   }
   return null ;
};

const elements =
[
    {
        type : Form.TEXTFIELD_VALIDATOR ,
        init :
        {
            autoComplete  : 'off' ,
            autoFocus     : false,
            errorMessages : [ 'Ce champ est obligatoire.', 'Adresse mail invalide.'] ,
            key           : 'value',
            id            : 'value',
            label         : 'Adresse Mail',
            fullWidth     : true,
            margin        : 'normal',
            required      : true,
            select        : false,
            type          : 'text' ,
            validators    : [ 'required', 'isEmail'],
            variant       : 'outlined'
        }
    },
    {
        type : Form.SEARCH_SELECTOR ,
        path : api.thesaurus.emails_types ,
        init :
        {
            id          : 'additionalType',
            key         : 'additionalType',
            autoFocus   : false,
            fullWidth   : true,
            label       : 'Type d\'adresse mail',
            margin      : 'normal',
            placeholder : 'Selectionner un type',
            required    : true,
            type        : 'search',
            variant     : 'outlined'
        },
        logics     : { disabledSuggestions } ,
        searchable : false,
        selector   : { i18n :
        {
            empty       : 'Aucun type disponible.' ,
            title       : 'Sélectionner un type',
            description : 'Veuillez sélectionner un type ci-dessous.' ,
        }}
    },
    {
        type : Form.I18N_EDITOR ,
        init :
        {
            id    : 'alternateName',
            key   : 'alternateName',
            input : true,
            label : 'Titre'
        }
    }
];

const email =
{
    add :
    {
        elements ,
        agree       : 'Créer' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour ajouter une adresse mail.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...' ,
        success     : 'Ajout d\'une nouvelle adresse réussi!' ,
        title       : 'Ajouter une adresse mail',
    },
    edit :
    {
        elements,
        agree       : 'Sauver' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour modifier cette adresse mail.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...'  ,
        success     : 'Mise à jour réussie!' ,
        title       : 'Modifier l\'adresse mail',
    },
    remove :
    {
        agree       : 'Supprimer' ,
        description : 'Souhaitez-vous supprimer cette adresse mail:' ,
        disagree    : 'Annuler' ,
        item        : { text : { primary, secondary } },
        loading     : 'Suppression en cours...' ,
        title       : 'Supprimer cette adresse ?'
    },
    title    : 'Adresses mails' ,
    tooltips :
    {
        add    : "Ajouter" ,
        edit   : "Éditer" ,
        remove : "Supprimer"
    }
};

export default email ;