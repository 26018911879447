import about from './step/about'
import stage from './step/stage'

const step =
{
    // dependencies

    about,
    stage,

    // settings

    helmet :
    {
        title : 'Étape - {0}' ,
        metas : []
    },
    loading  : "Chargement ..." ,
    notFound : "Étape inconnue" ,
    tabs :
    {
        about  : 'Étape',
        stage  : 'Point d\'intérêt',
        medias : 'Médias',
    },
    tooltips :
    {
        back : 'Retour itinéraire'
    }
};

export default step ;