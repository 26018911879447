import React from 'react'

import format   from 'vegas-js-core/src/strings/format'

import Form from '../../../../../../../display/forms/FormElements'

import Method from "../../../../../../../net/Method"

import api from "../../../../../../../configs/api"

import event from '../../../events/sectorHealthEvent'

import VaccinationEventCell from '../../../../../../../display/things/livestock/events/VaccinationEvent'

import AbortionSerieReview from '../../../../../../../things/livestock/reviews/AbortionSerieReview'
import Thing               from '../../../../../../../things/Thing'
import VaccinationEvent    from '../../../../../../../things/medical/events/VaccinationEvent'
import Workshop            from '../../../../../../../things/Workshop'

const elements =
[
    {
        type       : Form.SEARCH_SELECTOR ,
        clearLabel : 'Vider' ,
        closeLabel : 'Fermer' ,
        init       :
        {
            id         : 'about',
            key        : 'about',
            label      : 'Vaccin',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            required   : true,
            type       : 'search',
            variant    : 'outlined'
        },
        path : ( props ) =>
        {
            const { rel } = props ;
            if( rel instanceof VaccinationEvent )
            {
                const { subjectOf:review } = rel ;
                if( review instanceof AbortionSerieReview )
                {
                    const { about:workshop } = review ;
                    if( workshop instanceof Workshop )
                    {
                        const { breeding } = workshop ;
                        if( breeding instanceof Thing )
                        {
                            const { id } = breeding ;
                            return format(api.settings.vaccinesByBreedingsTypes,id) ;
                        }
                    }
                }
            }
            return null;
        },
        iconifiable : false ,
        searchable  : false,
        title       : 'Sélectionner un vaccin'
    },
    {
        type         : Form.DATE ,
        defaultValue : null ,
        format       : 'L' ,
        init         :
        {
            id            : 'date',
            key           : 'date',
            autoOk        : true ,
            cancelLabel   : 'Annuler' ,
            clearable     : true ,
            clearLabel    : 'Vider' ,
            disableFuture : false ,
            disablePast   : false ,
            fullWidth     : true ,
            inputVariant  : 'outlined',
            invalidLabel  : '-',
            label         : 'Date du vaccin' ,
            required      : false,
            okLabel       : 'OK',
            variant       : 'dialog'
        }
    }
];

const vaccinationEvents =
{
    add :
    {
        agree       : 'Créer' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour créer un événement de vaccination.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...' ,
        method      : Method.PATCH,
        mock        : false,
        success     : 'Ajout réussi!' ,
        title       : 'Ajouter un événement',
        elements
    },
    edit :
    {
        agree       : 'Sauver' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour modifier cet événement de vaccination.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...'  ,
        method      : Method.PATCH,
        mock        : false ,
        success     : 'Mise à jour réussie!' ,
        title       : 'Modifier l\'évenement',
        elements
    },
    event,
    labels :
    {
        card : 'Vaccination'
    },
    remove :
    {
        agree       : 'Supprimer' ,
        description : 'Souhaitez-vous supprimer cette vaccination ?' ,
        disagree    : 'Annuler' ,
        iconifiable : false ,
        item        : { render : ( item ) => <VaccinationEventCell thing = { item } /> },
        loading     : 'Suppression en cours...' ,
        method      : Method.PATCH ,
        mock        : false ,
        title       : 'Supprimer la vaccination',
        useID       : false
    },
    rows :
    {
        empty       : '?' ,
        numAttendee : 'Effectif' ,
        capacity    : 'Capacité'
    },
    title     : 'Vaccinations' ,
    tooltips  :
    {
        add    : "Ajouter" ,
        edit   : "Éditer" ,
        remove : "Supprimer"
    }
};

export default vaccinationEvents ;
