import React from 'react'

import compose from 'vegas-js-core/src/functors/compose'

import initApp from '../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import LocalHospital  from '@material-ui/icons/LocalHospital'

import PropertyValue from '../../things/PropertyValue'
import Word          from '../../things/Word'

import ThingChildren, { styles } from './ThingChildren'

class MedicalSpecialties extends ThingChildren
{
    getLocale = () => this.props.locale.things.medicalSpecialties ;
}

MedicalSpecialties.defaultProps =
{
    ...ThingChildren.defaultProps ,
    clazz      : Word,
    emptyClazz : PropertyValue,
    icon       : <LocalHospital/> ,
    member     : 'medicalSpecialties'
};

MedicalSpecialties.propTypes =
{
    ...ThingChildren.propTypes
};

export default compose( withStyles( styles ) , withRouter , initApp )( MedicalSpecialties ) ;