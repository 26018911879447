import React from 'react'

import { withRouter } from 'react-router-dom'

import PagesIcon from '@material-ui/icons/Pages'

import Counter from './Counter'

import withi18n from '../../contexts/i18n/withi18n'

export class WorkshopCounter extends Counter 
{
    getLocale = () => this.props.locale.display.counters.workshops;
}
    
WorkshopCounter.defaultProps =
{
    ...Counter.defaultProps,
    icon      : <PagesIcon className="mr-8" fontSize='small' />,
    linkable  : true ,
    member    : 'workshops' ,
    path      : '{0}/workshops'
}

WorkshopCounter.propTypes =
{
    ...Counter.propTypes
}

export default withRouter( withi18n( WorkshopCounter ) ) ;