import account from './account'
import embed   from './embed'
import search  from './search'
import select  from './select'
import upload  from './upload'

export default
{
    account,
    embed,
    search,
    select,
    upload
};
