import axios from 'axios'

import moment from 'moment'

import URI from 'urijs'

import auth from "../configs/auth"

import tokensManager from '../vo/TokensManager'

import connect        from './auth/connect'
import getAccessToken from './auth/getAccessToken'
import GrantType      from './auth/GrantType'

const cache   = process.env.REACT_APP_CACHE_GET ;
const name    = process.env.REACT_APP_NAME ;
const timeout = process.env.REACT_APP_TIMEOUT ;

const key = name + cache ;

const DEFAULT_OPTIONS =
{
    accessToken : null ,
    api         : null ,
    cancel      : null ,
    canceler    : null ,
    datas       : null ,
    fail        : null ,
    queries     : {} ,
    random      : false ,
    success     : null ,
    timeout     : timeout
};

const abort          = message => console.log( 'GET failed' , message ) ;
const badRequest     = { data : { code : 400 , message : 'Bad Request' } } ;
const referrerID     = process.env.REACT_APP_REFERRER_ID ;
const validateStatus = status => status === 200 || status === 304 ;

/**
 * GET datas with a specific API.
 * @param uri {string} The uri of the API method.
 * @param {Null|object} [options=null] - The configuration object to set the request.
 * @param {Object} [options.accessToken=null] - The optional accessToken object.
 * @param {Object} [options.api=null] - The optional api object.
 * @param {Function} [options.cancel=null] - A callback method invoked when the service is canceled.
 * @param {CancelTokenSource} [options.canceler=null] - The canceler of the request.
 * @param {Object} [options.datas=null] - The optional object to send datas to the API method.
 * @param {Function} [options.fail=null] - A callback function invoked when the request failed.
 * @param {Object} [options.queries=null] The search queries to inject in the uri.
 * @param {boolean} [options.random=false] - Indicates if a random value is injected in the uri.
 * @param {Function} [options.success] - A callback function invoked when the request succeed.
 * @param {int} [options.timeout=18000] - Indicates the delay in ms to timeout the request.
 */
export default function GET( uri , options )
{
    const params = { ...DEFAULT_OPTIONS , ...( !!(options) && options ) } ;

    let {
        accessToken,
        api,
        cancel,
        canceler,
        datas,
        fail,
        queries,
        random,
        success,
        timeout
    }
    = params ;

    fail = fail instanceof Function ? fail : abort ;

    if( canceler === null )
    {
        canceler = axios.CancelToken.source() ;
    }

    if( api === null )
    {
        for( let prop in auth.apis )
        {
            if( auth.apis.hasOwnProperty( prop ) )
            {
                if( uri.startsWith( auth.apis[prop].url ) )
                {
                    api = auth.apis[prop] ;
                    break ;
                }
            }
        }

        if( !api )
        {
            fail( 'API definition not found with the uri: ' + uri );
            return canceler ;
        }
    }

    if( accessToken === null )
    {
        accessToken = tokensManager.read( api.name ) ; // get stored accessToken
    }

    if( !accessToken || accessToken.expired )
    {
        return getAccessToken(
        {
            accessToken,
            api,
            cancel,
            canceler,
            fail : response => fail(response),
            success : () => GET( uri ,
            {
                accessToken : null,
                api,
                cancel,
                canceler,
                datas,
                fail,
                queries,
                random,
                success,
                timeout
            }),
            timeout
        });
    }

    queries =
    {
        ...( random && { random : Math.floor( Math.random() * moment().valueOf() ) } ),
        ...queries
    };

    uri = new URI(uri) ;

    uri.addSearch(queries) ;

    let cache = JSON.parse( localStorage.getItem( key ) ) ;

    let modified = null ;

    if( !random )
    {
        modified = { 'If-Modified-Since' : ( cache && cache.hasOwnProperty(uri) ) ? cache[uri].modified : 'Sat, 01 Jan 1972 00:00:00 GMT' }
    }

    const request = axios.create(
    {
        ...datas ,
        cancelToken : canceler.token ,
        headers     :
        {
            'Authorization' : accessToken.token_type + ' ' + accessToken.access_token ,
            'Accept'        : 'application/json' ,
            ...modified
        },
        timeout,
        validateStatus
    });

    console.log( 'GET(' + uri + ')' );

    const referrer = window.location.pathname ;
    if( referrer !== '/home' && referrer !== '/register' && referrer !== '/welcome' )
    {
        sessionStorage.setItem( referrerID , JSON.stringify(referrer) ) ;
    }

    request
    .get( uri.toString() )
    .then( response =>
    {
        if( response )
        {
            const { status } = response ;
            console.log( 'GET status ' + status ) ;
            switch( status )
            {
                case 200 :
                {
                    const { data } = response ;
                    if( !response || data === null || (response.data === '') || !(data.result) )
                    {
                        fail( badRequest );
                        return ;
                    }

                    if( success instanceof Function )
                    {
                        if( !random && response.headers )
                        {
                            if( response.headers.hasOwnProperty('last-modified'))
                            {
                                let object = JSON.parse( localStorage.getItem( key ) ) ;
                                if( !object )
                                {
                                    object = {} ;
                                }
                                object[uri] =
                                {
                                    modified : response.headers['last-modified'] ,
                                    data     : response.data
                                };
                                localStorage.setItem( key , JSON.stringify(object) ) ;
                            }
                        }
                        success( response.data );
                    }

                    break ;
                }

                case 304 :
                {
                    if( success instanceof Function )
                    {
                        let object = JSON.parse( localStorage.getItem( key ) ) ;
                        if( object && object.hasOwnProperty(uri))
                        {
                            success(object[uri].data);
                        }
                    }
                    break ;
                }

                default :
                {
                    fail( response );
                    break ;
                }
            }
        }
    })
    .catch( error =>
    {
        const { message, response , request } = error ;
        if ( axios.isCancel( error ) )
        {
            if( cancel instanceof Function )
            {
                cancel( error ) ;
            }
        }
        else if( response )
        {
            const { data } = response ;

            if( data )
            {
                const { message } = data ;

                if( message && ( message === 'token revoked' || message === 'Signature verification failed' ) )
                {
                    if( api.grant_type === GrantType.AUTHORIZATION_CODE )
                    {
                        // remove accessToken
                        tokensManager.dispose( api.name ) ;

                        connect( api.name ) ;
                        return ;
                    }

                    return getAccessToken( {
                        accessToken,
                        api,
                        cancel,
                        fail : response => fail(response),
                        success : () => GET( uri ,
                        {
                            accessToken : null,
                            api,
                            cancel,
                            canceler,
                            datas,
                            fail,
                            queries,
                            random,
                            success,
                            timeout
                        }),
                        timeout
                    });
                }
            }
            fail(response);
        }
        else if( request )
        {
            fail(request);
        }
        else
        {
            fail( message );
        }
    });

    return canceler ;
}
