import Form from '../../../../display/forms/FormElements'

import Person     from '../../../../things/Person'
import Technician from '../../../../things/Technician'
import Thing      from '../../../../things/Thing'

import api from '../../../../configs/api'

import getExistingSuggestions from '../../../../things/getExistingSuggestions'

const elements =
[
    {
        type  : Form.SEARCH_SELECTOR,
        path  : api.people.url,
        clazz : Person ,
        disabledSuggestions : getExistingSuggestions({ clazz:Person }) ,
        init  :
        {
            id         : 'person',
            key        : 'person',
            label      : 'Personne',
            autoFocus  : false,
            fullWidth  : true,
            limit      : 0,
            helperText : 'Obligatoire',
            margin     : 'normal',
            required   : true,
            type       : 'search',
            variant    : 'outlined'
        },
        selector : { i18n :
        {
            empty       : 'Aucune personne disponible.' ,
            title       : 'Sélectionner une personne',
            description : 'Veuillez sélectionner une personne ci-dessous.' ,
        }}
    }
];

const getItem = ( { unknown = 'unknown' } = {} ) =>
({
    text :
    {
        primary : ( element , props ) =>
        {
            if( element instanceof Technician )
            {
                const { person } = element ;
                if( person instanceof Thing )
                {
                    return person.getLocaleName(props.lang);
                }
            }
            return unknown ;
        }
    }
});

export default
{
    add :
    {
        elements,
        agree       : 'Créer' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour enregistrer un nouveau technicien.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en  cours...' ,
        success     : 'Ajout du nouveau technicien réussi!' ,
        title       : 'Ajouter un technicien'
    },
    remove :
    {
        agree        : 'Supprimer' ,
        description  : 'Souhaitez-vous supprimer l\'enregistrement:' ,
        descriptionX : 'Souhaitez-vous supprimer les {0} enregistrements:' ,
        disagree     : 'Annuler' ,
        item         : getItem({ unknown:'Inconnu' }),
        loading      : 'Suppression en cours...' ,
        title        : 'Supprimer ce technicien ?' ,
        titleX       : 'Supprimer des techniciens ?'
    }
};

