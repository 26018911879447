import React , { Fragment } from 'react'

import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'

import { ButtonGroup, Typography } from '@material-ui/core'

import compose  from 'vegas-js-core/src/functors/compose'
import notEmpty from 'vegas-js-core/src/strings/notEmpty'

import { withRouter } from 'react-router-dom'

import withLocale from '../../contexts/i18n/withLocale'
import withSearch from '../../contexts/search/withSearch'
import withSelect from '../../contexts/select/withSelect'
import withUser   from '../../contexts/user/withUser'

import AccountButton from '../../display/buttons/AccountButton'
import Container     from '../containers/Container'
import SearchBar     from './SearchBar'
import SearchButton  from '../buttons/SearchButton'
import SelectBar     from './SelectBar'
import SelectButton  from '../buttons/SelectButton'

import connected from "../../net/connected"

const styles = theme =>
({
    root :
    {
        alignItems     : 'center',
        display        : 'flex',
        flexDirection  : 'row',
        justifyContent : 'space-between',
        width          : '100%'
    },
    searchPaper :
    {
        backgroundColor : theme.palette.primary,
        height          : '100%',
        width           : '100%'
    },
    searchBar :
    {
        position : 'absolute',
        height   : '100%',
        width    : '100%',
        bottom   : 0,
        left     : 0,
        right    : 0,
        top      : 0
    },
    separator :
    {
        width          : 1,
        height         : 64,
        backgroundColor: theme.palette.divider
    },
    title :
    {
         display : 'flex',
         flex    : 1,
         padding : '0px 12px',
         [theme.breakpoints.down('xs')]:
         {
            visibility: 'hidden'
         }
    }
});

class Toolbar extends Container
{
    change = () =>
    {
        if( this.mounted )
        {
            this.forceUpdate();
        }
    };

    getLocale = () => this.props.locale.display.bars.toolbar ;

    getOptions = () =>
    {
        if( connected() )
        {
            const {
                searchable,
                selectable,
                selectableItems,
                openSearch,
                selectAll
            } = this.props ;

            let finder ;
            if( searchable )
            {
               finder =(
                   <SearchButton
                        className = 'mr-8'
                        onClick   = { openSearch }
                        visible   = { searchable }
                   />
               );
            }

            let selector ;
            if( selectable && selectableItems instanceof Array && selectableItems.length > 0 )
            {
                selector = (
                    <SelectButton
                        className = 'mr-8'
                        onClick   = { selectAll }
                    />
                );
            }

            return (
                <ButtonGroup
                    color     = 'primary'
                    className = "flex items-center mr-4"
                    variant   = "outlined"
                >
                    { selector }
                    { finder   }
                    <AccountButton />
                </ButtonGroup>
            );
        }
        else
        {
            return null ;
        }
    };

    getSearchBar = () =>
    {
        const {
            closeSearch,
            search,
            searchable,
            searchBar
        }
        = this.props ;

        // console.log( this + ' getSearchBar searchable' , searchable , 'searchBar' , searchBar ) ;

        if( searchable && searchBar )
        {
            let { classes, searchLabel } = this.props ;

            if( !searchLabel )
            {
                const { search:label } = this.getLocale() ;
                searchLabel = label ;
            }

            return(
                <SearchBar
                    autoComplete   = { true }
                    close          = { true }
                    elevation      = { 0 }
                    className      = { classes.searchBar }
                    delay          = { 500 }
                    label          = { searchLabel }
                    onClose        = { () =>
                    {
                        if( closeSearch instanceof Function)
                        {
                            closeSearch()
                        }
                    }}
                    paperClassName = { classes.searchPaper }
                    search         = { search }
                    square         = { true }
                    style          = {{ height : 64}}
                />
            );
        }
        return null ;
    };

    getSelectBar = () =>
    {
        const { classes, selectable } = this.props ;
        if( selectable )
        {
            return(
                <SelectBar
                    elevation = { 0 }
                    className = { classes.searchBar }
                    square    = { true }
                    style     = {{ height:64 }}
                />
            );
        }
        return null ;
    };

    getTitle = () =>
    {
        const { classes } = this.props ;
        const { title } = this.getLocale() ;
        if( notEmpty( title ) )
        {
            return (
                <div className = { classes.title }>
                    <Typography variant = "body1">{ title }</Typography>
                </div>
            );
        }
        return null ;
    };

    render()
    {
        const { classes } = this.props ;
        return (
            <Fragment>
                <div className={ classes.root }>
                    { this.getTitle() }
                    { this.getOptions() }
                </div>
                { this.getSearchBar() }
                { this.getSelectBar() }
            </Fragment>
        );
    }
}

Toolbar.defaultProps =
{
    ...Container.defaultProps,
};

Toolbar.propTypes =
{
    ...Container.propTypes,
    search          : PropTypes.string,
    searchable      : PropTypes.bool,
    searchBar       : PropTypes.bool,
    searchLabel     : PropTypes.string,
    selectable      : PropTypes.bool,
    selectableItems : PropTypes.array,
    selectBar       : PropTypes.bool,
    selectedItems   : PropTypes.array
};

export default compose (
    withStyles(styles),
    withRouter,
    withLocale,
    withSearch,
    withSelect,
    withUser
)( Toolbar );
