import React from 'react'

import PropTypes from 'prop-types'

import withi18n from '../../contexts/i18n/withi18n'

import { ThingCell } from './ThingCell'

import clsx from 'clsx'

import notEmpty from 'vegas-js-core/src/strings/notEmpty'

import getFavoriteType   from '../../things/getFavoriteType'
import getLocaleFavorite from '../../things/getLocaleFavorite'

export class FavoriteCell extends ThingCell
{
    getAvatarThing = () => getLocaleFavorite( this.getThing() ) ;

    getLocale = () => this.props.locale.display.cells.favorite;

    getLocaleThing = () => getLocaleFavorite( this.getThing() ) ;

    getType = ( { className = null } = {} ) =>
    {
        let type = getFavoriteType( this.getThing() ) ;
        if ( notEmpty(type) )
        {
            const { type : { [type] : { backgroundColor , color , text } = {} } = {} } = this.getLocale() || {} ;
            if( notEmpty(text) )
            {
                type = (
                    <div
                        className = { clsx(
                            'px-8 rounded-full text-lg font-semibold mr-8 uppercase antialiased subpixel-antialiased shadow-md' ,
                            className )
                        }
                        style = {{ backgroundColor , color }}
                    >
                        { text }
                    </div>
                )
            }
            else
            {
                type = null ;
            }
        }
        else
        {
            type = null ;
        }

        if( type )
        {
            return (
                <div className='flex flex-row items-center'>
                    { type }
                </div>
            );
        }

        return null ;
    };
}

FavoriteCell.defaultProps =
{
    ...ThingCell.defaultProps,
    eventStatus : true ,
    period      : true,
    sanitize    : true, // sanitize the copy text
};

FavoriteCell.propTypes =
{
    ...ThingCell.propTypes,
    eventStatus : PropTypes.bool,
    period      : PropTypes.bool
};

export default withi18n( FavoriteCell ) ;
