import React from 'react'

import moment from 'moment/moment'

import compose  from 'vegas-js-core/src/functors/compose'
import isString from 'vegas-js-core/src/isString'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import initApp from '../../contexts/app/initApp'

import { Link , withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { Grid } from '@material-ui/core'

import StepIcon from '@material-ui/icons/PinDrop'

import LabelItem from '../../components/items/LabelItem'

import ThingContainer from '../containers/ThingContainer'

import StageVO from '../../things/Stage'
import StepVO  from '../../things/Step'
import ThingVO from '../../things/Thing'

import api from "../../configs/api"

import ThingAvatar from "../avatars/ThingAvatar"

const styles = () => ({});

const half =
{
    md : 6 ,
    sm : 6 ,
    xs : 12
};

const full =
{
    xs : 12
};

class Step extends ThingContainer
{
    editPrepare = ( item ) =>
    {
        let {
            name,
            stage
        } = item ;

        if( stage instanceof ThingVO )
        {
            stage = String(stage.id)
        }
        else
        {
            stage = null ;
        }

        return (
        {
            name,
            stage
        });
    };

    getContent = () =>
    {
        let { lang, thing } = this.props;

        const locale = this.getLocale() ;

        const { labels } = locale ;

        let interest ;
        let name ;
        let place ;
        let placeIcon ;
        let placeOptions ;
        let stageIcon ;
        let stageOptions ;

        if( thing instanceof StepVO )
        {
            let {
                stage : s ,
                name  : n ,
            } = thing ;

            if( isString( n ) )
            {
                name = n ;
            }

            if( s instanceof StageVO )
            {
                interest     = s.getLocaleTitle( lang ) ;
                stageIcon    = <ThingAvatar thing={s} className="mr-12"/> ;
                stageOptions = { component : Link , to : { pathname : s.url.split(api.url)[1] } };

                const { location:l } = s ;
                if( l )
                {
                    place        = l.getLocaleName( lang ) ;
                    placeIcon    = <ThingAvatar thing={l} className="mr-12"/> ;
                    placeOptions = { component : Link , to : { pathname : l.url.split(api.url)[1] } };
                }
            }
            else
            {
                interest = null ;
            }
        }

        return (
            <Grid container spacing={4}>
                <Grid item {...full}>
                    <LabelItem
                        enabled = {!!(name)}
                        title   = { labels.name }
                    >
                        { isString(name) ? ucFirst(name) : labels.empty }
                    </LabelItem>
                </Grid>
                <Grid item {...half}>
                    <LabelItem
                        enabled  = {!!(interest)}
                        linkIcon = { stageIcon }
                        title    = { labels.interest }
                        { ...stageOptions }
                    >
                        { isString(interest) ? ucFirst(interest) : labels.empty }
                    </LabelItem>
                </Grid>
                {
                    place &&
                    <Grid item {...half}>
                        <LabelItem
                            enabled  = {!!(place)}
                            linkIcon = { placeIcon }
                            title    = { labels.location }
                            {...placeOptions}
                        >
                            {isString(place) ? ucFirst(place) : labels.empty}
                        </LabelItem>
                    </Grid>
                }
            </Grid>
        );
    };

    getEntry = init =>
    {
        let empty = {} ;
        if( init instanceof ThingVO )
        {
            empty = init.toObject() ;
        }
        else if( init )
        {
            empty = { ...init } ;
        }
        return new StepVO(empty);
    };

    getLocale = () => this.props.locale.things.step;

    onEdit = item =>
    {
        const { onChange, thing } = this.props ;
        if( thing && item )
        {
            const {
                name,
                stage
            } = item ;

            thing.name     = name ;
            thing.modified = moment(new Date()).toISOString();
            thing.stage    = stage ;

            if( thing instanceof ThingVO )
            {
                thing.populate();
            }

            if( this._mounted )
            {
                this.forceUpdate();
            }

            if( onChange instanceof Function )
            {
                onChange(thing) ;
            }
        }
    };
}

Step.defaultProps =
{
    ...ThingContainer.defaultProps ,
    icon       : <StepIcon/> ,
    optionMode : 'edit'
};

Step.propTypes =
{
    ...ThingContainer.propTypes
};

export default compose( withStyles( styles ) , withRouter , initApp )( Step ) ;
