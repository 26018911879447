
import generify from '../generify'

import Course from '../Course'
import Game   from '../Game'

import QuestionGame from './QuestionGame'

class CourseGame extends Game
{
    /**
     * Creates a new CourseGame instance.
     * @constructor
     * @param object The generic object to initialize the object the first time.
     */
    constructor( object = null )
    {
        super();

        this.set( object ) ;
    }

    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new CourseGame( this.toObject() ) ;
    }

    /**
     * Populate the object with specific behaviors or types. Launched by default in the constructor and in the set methods.
     * @returns {Thing} The current reference.
     */
    populate()
    {
        super.populate() ;

        const {
            about,
            quest
        } = this ;

        if( about && !(about instanceof Course))
        {
            this.about = new Course(about) ;
        }

        if( quest && quest instanceof Array )
        {
            this.quest = quest.map( item => item instanceof QuestionGame ? item : new QuestionGame( item ) ) ;
        }

        return this ;
    }

    /**
     * Returns the generic object representation of the item.
     * @return {Object} The generic object representation of the item.
     */
    toObject()
    {
        let {
            about,
            quest
        } = this ;

        about = generify(about) ;
        quest = generify(quest) ;

        return { ...super.toObject() , ...{
            about,
            quest
        }}
    }
}

export default CourseGame ;