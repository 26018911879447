import Form   from '../../../display/forms/FormElements'
import Method from '../../../net/Method'

const elements =
[
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'streetAddress',
            key          : 'streetAddress',
            label        : 'Rue',
            autoFocus    : false,
            fullWidth    : true,
            helperText   : null,
            margin       : 'normal',
            required     : false,
            select       : false,
            autoComplete : 'off' ,
            type         : 'text',
            variant      : 'outlined'
        }
    },
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'postalCode',
            key          : 'postalCode',
            label        : 'Code postal',
            autoFocus    : false,
            fullWidth    : true,
            helperText   : null,
            margin       : 'normal',
            required     : false,
            select       : false,
            autoComplete : 'off' ,
            type         : 'text',
            variant      : 'outlined'
        }
    },
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'addressLocality',
            key          : 'addressLocality',
            label        : 'Ville',
            autoFocus    : false,
            fullWidth    : true,
            helperText   : null,
            margin       : 'normal',
            required     : false,
            select       : false,
            autoComplete : 'off' ,
            type         : 'text',
            variant      : 'outlined'
        }
    },
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'additionalAddress',
            key          : 'additionalAddress',
            label        : 'Complément d\'adresse',
            autoFocus    : false,
            fullWidth    : true,
            helperText   : null,
            margin       : 'normal',
            required     : false,
            select       : false,
            autoComplete : 'off' ,
            type         : 'text',
            variant      : 'outlined'
        }
    },
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'postOfficeBoxNumber',
            key          : 'postOfficeBoxNumber',
            label        : 'Boîte postale / Cedex',
            autoFocus    : false,
            fullWidth    : true,
            helperText   : null,
            margin       : 'normal',
            required     : false,
            select       : false,
            autoComplete : 'off' ,
            type         : 'text',
            variant      : 'outlined'
        }
    },
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'addressDepartment',
            key          : 'addressDepartment',
            label        : 'Département',
            autoFocus    : false,
            fullWidth    : true,
            helperText   : null,
            margin       : 'normal',
            required     : false,
            select       : false,
            autoComplete : 'off' ,
            type         : 'text',
            variant      : 'outlined'
        }
    },
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'addressRegion',
            key          : 'addressRegion',
            label        : 'Région',
            autoFocus    : false,
            fullWidth    : true,
            helperText   : null,
            margin       : 'normal',
            required     : false,
            select       : false,
            autoComplete : 'off' ,
            type         : 'text',
            variant      : 'outlined'
        }
    },
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'addressCountry',
            key          : 'addressCountry',
            label        : 'Pays',
            autoFocus    : false,
            fullWidth    : true,
            helperText   : null,
            margin       : 'normal',
            required     : false,
            select       : false,
            autoComplete : 'off' ,
            type         : 'text',
            variant      : 'outlined'
        }
    }
];

const address =
{
    empty             : '----' ,
    additionalAddress : 'Complément d\'adresse',
    addressCountry    : 'Pays' ,
    addressDepartment : 'Département' ,
    addressLocality   : 'Ville' ,
    addressRegion     : 'Région' ,
    edit :
    {
        elements,
        agree       : 'Modifier' ,
        disagree    : 'Fermer' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour modifier cette adresse.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...' ,
        method      : Method.PATCH,
        mock        : false ,
        success     : 'Mise à jour réussie!' ,
        title       : 'Modifier l\'adresse' ,
    },
    faxNumber           : 'Fax' ,
    postalCode          : 'Code postal' ,
    postOfficeBoxNumber : 'Boîte postale / Cedex',
    streetAddress       : 'Rue' ,
    title               : 'Adresse' ,
    telephone           : 'Téléphone' ,
    tooltips            :
    {
        edit : 'Modifier'
    }
};

export default address ;
