import about from './person/about'

const person =
{
    about ,

    helmet :
    {
        title : 'Personne - {0}' ,
        metas : []
    },
    loading  : "Chargement ..." ,
    notFound : "Personne inconnue" ,
    snack :
    {
        success : 'Fiche chargée'
    },
    tabs :
    {
        about  : 'À propos',
        info   : 'Infos',
        medias : 'Médias'
    },
    tooltips :
    {
        back : 'Toutes les personnes'
    }
};

export default person ;
