
const workshop =
{
    loading  : 'Chargement en cours...',
    notFound : "Atelier inconnu" ,
    tabs     :
    {
        about        : 'À propos' ,
        observations : 'Observations'
    },
    title    : 'Atelier' ,
    tooltips :
    {
        back : "Revenir à l'élevage"
    }
};

export default workshop ;