import React from 'react';

import clsx from 'clsx'

import format from 'vegas-js-core/src/strings/fastformat'

import { withRouter } from 'react-router-dom'

import Marks             from '../../../display/things/conceptualObject/Marks'
import Materials         from '../../../display/things/conceptualObject/Materials'
import Measurements      from '../../../display/things/conceptualObject/Measurements'
import Metadatas         from '../../../display/things/Metadatas'
import Numbers           from '../../../display/things/conceptualObject/Numbers'
import Productions       from '../../../display/things/conceptualObject/Productions'
import ThingSubContainer from '../../../display/containers/ThingSubContainer'
import Websites          from '../../../display/things/Websites'

import RemoveConceptualObjectDialog from '../../../display/dialogs/remove/RemoveConceptualObjectDialog'

class Info extends ThingSubContainer
{
    render = () =>
    {
        const {
            className,
            path,
            style,
            thing
        } = this.props ;

        if( thing )
        {
            const { id } = thing ;

            const { onRemove } = this.props ;

            let view = (
            <div className="flex-1 flex flex-col space-y-24">

                <Metadatas
                    activable             = { true }
                    deletable             = { true }
                    onRemove              = { onRemove }
                    RemoveDialogComponent = { RemoveConceptualObjectDialog }
                    thing                 = { thing }
                    uri                   = { format(path.conceptualObjects,id) }
                />

                <div className='grid grid-cols-1 md:grid-cols-2 gap-16'>
                    <Productions
                        onChange = { this.change }
                        thing    = { thing }
                        uri      = { path.productions }
                    />
                    <Websites
                        onChange = { this.change }
                        thing    = { thing }
                        uri      = { path.websites }
                    />
                    <Materials
                        onChange = { this.change }
                        thing    = { thing }
                        uri      = { path.materials }
                    />
                    <Measurements
                        onChange = { this.change }
                        thing    = { thing }
                        uri      = { path.measurements }
                    />
                    <Marks
                        onChange = { this.change }
                        thing    = { thing }
                        uri      = { path.marks }
                    />
                    <Numbers
                        onChange = { this.change }
                        thing    = { thing }
                        uri      = { path.numbers }
                    />
                </div>
            </div>);

            return (
            <div
                className = { clsx( 'flex-1 p-12' , className ) }
                style     = { style }
            >
                { this.renderWithTransition(view) }
            </div>
            );
        }
        return null ;
    }
}

Info.defaultProps =
{
    ...ThingSubContainer.defaultProps
};

Info.propTypes =
{
    ...ThingSubContainer.propTypes
};

export default withRouter(Info) ;
