import React from 'react'

import BusinessIcon from '@material-ui/icons/Business'

import { withRouter } from 'react-router-dom'

import withi18n from '../../contexts/i18n/withi18n'

import { ThingLabel } from './ThingLabel'

export class OrganizationLabel extends ThingLabel
{     
    getLocale = () => this.props.locale.display.labels.organization;
}

OrganizationLabel.defaultProps =
{
    ...ThingLabel.defaultProps,
    icon : <BusinessIcon size='small'/>
};

OrganizationLabel.propTypes =
{
    ...ThingLabel.propTypes
};

export default withRouter(withi18n(OrganizationLabel)) ;
