import React from 'react'

import moment from 'moment/moment'

import compose  from 'vegas-js-core/src/functors/compose'
import isString from 'vegas-js-core/src/isString'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import { Link , withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { Grid } from '@material-ui/core'

import DomainIcon from '@material-ui/icons/Domain'

import api from '../../configs/api'

import LabelItem from '../../components/items/LabelItem'

import ThingContainer from '../containers/ThingContainer'

import identify       from '../../things/identify'
import OrganizationVO from '../../things/Organization'
import Thing          from '../../things/Thing'

import initApp from '../../contexts/app/initApp'

const styles = () => ({});

const half =
{
    sm : 6,
    xs : 12
};

class Organization extends ThingContainer
{
    getContent = () =>
    {
        let { lang, thing } = this.props;

        const locale = this.getLocale() ;

        const { labels } = locale ;

        let location ;
        let name ;
        let options ;
        let type ;

        if( thing instanceof OrganizationVO )
        {
            let {
                additionalType,
                location   : l,
                name       : n
            } = thing ;

            if( isString( n ) )
            {
                name = n ;
            }

            if( additionalType instanceof Thing )
            {
                type = additionalType.getLocaleName( lang ) ;
            }

            if( l instanceof Thing )
            {
                options =
                {
                    component : Link ,
                    to        :
                    {
                        pathname : l.url.split(api.url)[1]
                    }
                };
                location = l.getLocaleName( lang ) ;
            }
            else
            {
                location = null ;
            }
        }

        return (
            <Grid container spacing={4}>
                <Grid item {...half}>
                    <LabelItem enabled={!!(name)} title={ labels.name }>
                        { isString(name) ? ucFirst(name) : labels.empty }
                    </LabelItem>
                </Grid>
                <Grid item {...half}>
                    <LabelItem enabled={!!(type)} title={ labels.type }>
                        { isString(type) ? ucFirst(type) : labels.empty }
                    </LabelItem>
                </Grid>
                <Grid item {...half}>
                    <LabelItem
                        enabled = {!!(location)}
                        title   = { labels.location }
                        { ...options }
                    >
                        { isString(location) ? ucFirst(location) : labels.empty }
                    </LabelItem>
                </Grid>
            </Grid>
        );
    };

    getEntry = init =>
    {
        let empty = {} ;
        if( init instanceof Thing )
        {
            empty = init.toObject() ;
        }
        else if( init )
        {
            empty = { ...init } ;
        }
        return new OrganizationVO(empty);
    };

    getLocale = () => this.props.locale.things.organization;

    onEdit = item =>
    {
        const {
            onChange,
            thing
        } = this.props ;

        if( thing && item )
        {
            const {
                additionalType,
                dissolutionDate,
                foundingDate,
                foundingLocation,
                legalForm,
                legalName,
                location,
                name,
                taxID,
                vatID
            } = item ;

            thing.additionalType   = additionalType ;
            thing.dissolutionDate  = dissolutionDate ;
            thing.foundingDate     = foundingDate ;
            thing.foundingLocation = foundingLocation ;
            thing.legalForm        = legalForm ;
            thing.legalName        = legalName ;
            thing.location         = location ;
            thing.modified         = moment(new Date()).toISOString();
            thing.name             = name ;
            thing.taxID            = taxID ;
            thing.vatID            = vatID ;

            if( thing instanceof Thing )
            {
                thing.populate();
            }

            if( this._mounted )
            {
                this.forceUpdate() ;
            }

            if( onChange instanceof Function )
            {
                onChange(thing) ;
            }
        }
    }

    editPrepare = item =>
    {
        let {
            additionalType,
            location,
            name,
        } = item ;

        additionalType = identify(additionalType) ;
        location       = identify(location) || '';

        const object = (
        {
            additionalType,
            location,
            name
        });

        return object ;
    };

}

Organization.defaultProps =
{
    ...ThingContainer.defaultProps ,
    icon       : <DomainIcon/> ,
    optionMode : 'edit'
};

Organization.propTypes =
{
    ...ThingContainer.propTypes
};

export default compose( withStyles( styles ) , withRouter , initApp )( Organization ) ;
