import React from 'react'

import compose  from 'vegas-js-core/src/functors/compose'

import initApp   from '../../../contexts/app/initApp'
import withSnack from '../../../contexts/snack/withSnack'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { CircularProgress } from '@material-ui/core'

import AddIcon     from '@material-ui/icons/AddPhotoAlternate'
import BrokenIcon  from '@material-ui/icons/BrokenImage'
import PictureIcon from '@material-ui/icons/PictureInPicture'

import green from '@material-ui/core/colors/green'

import api from '../../../configs/api'

import RequestStatus from '../../../net/RequestStatus'

import Media from './Media'
import ImageObject from '../../../things/creativework/media/ImageObject';

const styles = () =>({});

class Logo extends Media
{
    getContent = () =>
    {
        let logo ;

        const {
            logo:logoStatus,
            status
        } = this.state ;

        const { thing }  = this.props ;

        if( status === RequestStatus.PROGRESS )
        {
            logo = <CircularProgress color='secondary' size={26} thickness={6}/> ;
        }
        else if( thing )
        {
            if( thing.logo )
            {
                const failed  = logoStatus === RequestStatus.FAIL ;
                const visible = logoStatus === RequestStatus.SUCCESS ;

                logo = this.getLogo(
                    thing ,
                    {
                        display : visible ? null : 'none' ,
                        height  : 'auto' ,
                        width   : '100%'
                    },
                    {
                        onError : this.onLogoError,
                        onLoad  : this.onLogoLoad
                    }
                ) ;

                logo = (
                <div className='flex items-center justify-center w-full h-full'>
                    { logo }
                    { failed && <BrokenIcon className='text-grey-700' style={{ fontSize : 40 }}/> }
                    { !visible &&!failed &&
                        <CircularProgress style={{ color: green[600] }} size={43} thickness={4} />
                    }
                </div>
                );
            }
        }

        if( !logo )
        {
            logo = <PictureIcon className='text-grey' style={{ fontSize : 40 }}/> ;
        }

        return (
        <div className='flex items-center justify-center w-full min-h-160 bg-grey-200'>
            { logo }
        </div>);
    };

    getLocale = () => this.props.locale.things.media.logo;

    onLogoError = () =>
    {
        if( this._mounted )
        {
            this.setState( { logo: RequestStatus.FAIL } );
        }
    };

    onLogoLoad = () =>
    {
        if( this._mounted )
        {
            this.setState( { logo: RequestStatus.SUCCESS } );
        }
    };
}

Logo.defaultProps =
{
    ...Media.defaultProps ,
    icon             : <PictureIcon/> ,
    member           : 'logo' ,
    memberClazz      : ImageObject,
    selectMediaIcon  : <AddIcon/>,
    selectMediaProps : ( { props : { config : { acceptedUploadMedias : { images:acceptedFiles } = {} } = {} } = {} } = {} ) => (
    {
        disableEscapeKeyDown : true ,
        facets               : { encodingFormat : 'image' } ,
        maxItems             : 1,
        multiple             : false,
        uploadable           : true,
        uploadMultiple       : false,
        uploadProps          : { acceptedFiles },
        uploadUri            : api.mediaObjects.url
    }),
    selectMediaUri : api.mediaObjects.url
};

Logo.propTypes =
{
    ...Media.propTypes
};

export default compose( withStyles( styles ) , withRouter , initApp , withSnack )( Logo ) ;
