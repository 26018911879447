import React      from 'react';
import initDialog from '../dialogs/initDialog'

import red from '@material-ui/core/colors/red'

import ThingButton from './ThingButton'

import { FaEraser } from 'react-icons/fa'

const styles =
{
    button :
    {
        backgroundColor : 'white',
        color           : red[600],
        '&:hover' :
        {
            backgroundColor : red[900],
            color           : 'white'
        }
    },
    root :
    {
        //
    }
};

export class RemoveThingButton extends ThingButton
{
    click = () => { this.openRemoveDialog( this.props.thing ) ; };
}

RemoveThingButton.defaultProps =
{
    ...ThingButton.defaultProps,
    icon : <FaEraser size={20} />
};

RemoveThingButton.propTypes =
{
    ...ThingButton.propTypes,
};

export default initDialog({ styles })( RemoveThingButton ) ;
