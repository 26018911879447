import Thing from '../Thing'

import generify from '../generify'

/**
 * A permission for a particular person or group to access a particular file.
 * @memberOf things.creativework
 */
class DigitalDocumentPermission extends Thing
{
    /**
     * Creates a new DigitalDocumentPermission instance.
     * @constructor
     * @param object The generic object to initialize the object.
     */
    constructor( object = null )
    {
        super() ;

        /**
         * The person, organization, contact point, or audience that has been granted this permission.
         * @type {Organization|Person}
         */
        this.grantee = null ;

        /**
         * The type of permission granted the person, organization, or audience.
         * @type {string|DigitalDocumentPermissionType}
         */
        this.permissionType = null ;

        this.set( object ) ;
    }

    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        super.clear() ;
        this.grantee        =
        this.permissionType = null ;
        return this ;
    }

    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new DigitalDocumentPermission( this.toObject() ) ;
    }

    /**
     * Returns the generic object representation of the item.
     * @return {Object} The generic object representation of the item.
     */
    toObject()
    {
        let {
            grantee,
            permissionType
        } = this ;
        
        grantee = generify( grantee ) ;

        return {
            ...super.toObject() ,
            grantee,
            permissionType
        }
    }
}

export default DigitalDocumentPermission ;