import CreativeWork from '../../../things/CreativeWork'

import Setting  from './Setting'

import generify from '../../../things/generify'

class SupportSetting extends Setting
{
    /**
     * Creates a new SupportSetting instance.
     * @constructor
     * @param object The generic object to initialize the object the first time.
     */
    constructor( object = null )
    {
        super() ;
        this.topics = null ;
        this.set( object ) ;
    }

    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        super.clear() ;
        this.topics = null ;
        return this ;
    }

    /**
     * Returns a shallow copy of the object.
     * @return {SupportSetting} a shallow copy of the object.
     */
    clone()
    {
        return new SupportSetting( this.toObject() ) ;
    }

    /**
     * Populate the object with specific behaviors or types. Launched by default in the constructor and in the set methods.
     * @returns {SupportSetting} The current reference.
     */
    populate()
    {
        const {
            topics
        } = this ;

        if( topics )
        {
            if( topics instanceof Array )
            {
                this.topics = topics.map( item =>
                {
                    if( !(item instanceof CreativeWork) )
                    {
                        item = new CreativeWork(item) ;
                        item.subjectOf = this ;
                    }
                    return item ;
                } ) ;
            }
            else
            {
                this.topics = new CreativeWork( topics ) ;
            }
        }

        return this ;
    }

    /**
     * Returns the generic object representation of the item.
     * @return {Object} The generic object representation of the item.
     */
    toObject()
    {
        let {
            topics
        } = this ;

        topics  = generify(topics,true);

        return {
            ...super.toObject() ,
            topics
        };
    }
}

export default SupportSetting ;