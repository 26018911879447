import React, {Component} from 'react'

import PropTypes from 'prop-types'

import clsx from 'clsx'

import { withStyles } from '@material-ui/core/styles'
import { withWidth }  from '@material-ui/core'

import ResizeObserver from 'resize-observer-polyfill'

import Media from './Media'

import computeSizesColumns from './computeSizesColumns'

const styles = () =>
({
    root :
    {

    }
});

class Gallery extends Component
{
    state =
    {
        containerWidth : 0
    };

    componentDidMount()
    {
        this.observer = new ResizeObserver( entries =>
        {
            const width = entries[0].contentRect.width ;
            if (this.state.containerWidth !== width )
            {
                this.setState( { containerWidth:Math.floor(width) } );
            }
        });

        this.observer.observe(this._gallery) ;
    }

    componentWillUnmount()
    {
        this.observer.disconnect();
        this.observer = null ;
    }

    get columns()
    {
        const {
            columns,
            lg,
            md,
            sm,
            xl,
            xs,
            width
        } = this.props ;
        if ( columns === undefined && width )
        {
            switch( width )
            {
                case 'xs' : return xs ;
                case 'sm' : return sm ;
                case 'md' : return md ;
                case 'lg' : return lg ;
                case 'xl' : return xl ;
                default   : return 1 ;
            }
        }
        return columns ;
    }

    getMedia = ( item , index ) =>
    {
        const {
            left ,
            top ,
            width ,
            height ,
            media
        }
        = item ;

        const {
            disabled,
            info,
            onInfo,
            onSelect,
            selectable,
            selectedItems,
            spacing
        } = this.props ;

        const find = (selectedItems instanceof Array)
                   && selectedItems.findIndex( element => (media.id === element.id) ) > -1 ;

        return (
        <Media
            disabled     = { disabled }
            key          = { 'media_' + index }
            index        = { index }
            margin       = { spacing }
            media        = { media }
            height       = { height }
            info         = { info }
            width        = { width }
            left         = { left }
            top          = { top }
            selectable   = { selectable }
            selected     = { find }
            onClick      = { this.click }
            onInfo       = { onInfo }
            onSelect     = { onSelect }
        /> );
    };

    render()
    {
        const { containerWidth } = this.state;

        const {
            className,
            classes,
            defaultDimension,
            spacing,
            medias ,
            style
        } = this.props ;

        if( !containerWidth || !(medias instanceof Array ) )
        {
            return <div ref={ c => ( this._gallery = c ) } />;
        }

        const columns = this.columns ;

        const width = containerWidth - 1 ;

        let galleryStyle = { position: 'relative' };
        let thumbs       = computeSizesColumns({ width, columns, spacing, medias , defaultDimension } );

        if( thumbs instanceof Array && thumbs.length > 0 )
        {
            galleryStyle.height = thumbs[thumbs.length - 1].containerHeight ;
            thumbs = thumbs.map( this.getMedia ) ;
        }
        else
        {
            thumbs = null  ;
        }

        return (
            <div className={ clsx(classes.root , className ) } style={style}>
                <div ref={c => ( this._gallery = c ) } style={galleryStyle}>
                    { thumbs }
                </div>
            </div>
        );
    }


    click = ( media , index ) =>
    {
        const { onSelect, selectedItems, selectOnClick } = this.props ;
        if( selectOnClick || (selectedItems instanceof Array && selectedItems.length > 0) )
        {
            if( onSelect instanceof Function )
            {
                onSelect( selectedItems.indexOf( media ) === 0, media ) ;
            }
        }
        else
        {
            const { medias, onClick } = this.props ;
            if( onClick instanceof Function )
            {
                onClick( media , index , medias[index - 1] || null , medias[index + 1] || null  ) ;
            }
        }
    }

}

Gallery.defaultProps =
{
    className        : null,
    defaultDimension : { width:320, height:240 },
    disabled         : false,
    info             : false,
    lg               : 4,
    md               : 3,
    medias           : null ,
    onClick          : null ,
    onInfo           : null ,
    onSelect         : null ,
    selectable       : false ,
    selectedItems    : [],
    selectOnClick    : false,
    sm               : 2,
    spacing          : 4,
    xs               : 1,
    xl               : 5
};

Gallery.propTypes =
{
    className        : PropTypes.string,
    columns          : PropTypes.number,
    defaultDimension : PropTypes.shape(
    {
        height : PropTypes.number,
        width  : PropTypes.number
    }),
    disabled      : PropTypes.bool,
    info          : PropTypes.bool,
    lg            : PropTypes.number.isRequired,
    md            : PropTypes.number.isRequired,
    onClick       : PropTypes.func,
    onInfo        : PropTypes.func,
    onSelect      : PropTypes.func,
    medias        : PropTypes.array,
    selectable    : PropTypes.bool,
    selectedItems : PropTypes.array,
    selectOnClick : PropTypes.bool,
    sm            : PropTypes.number.isRequired,
    spacing       : PropTypes.number,
    style         : PropTypes.object,
    xs            : PropTypes.number.isRequired,
    xl            : PropTypes.number.isRequired
};

export default withWidth()( withStyles( styles )( Gallery ) ) ;
