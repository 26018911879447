import React from 'react'

import NoteIcon from '@material-ui/icons/Note'

import Form from '../../../../display/forms/FormElements'

import api from '../../../../configs/api'

import hasPart     from './hasPart'
import isPartOf    from './isPartOf'
import isRelatedTo from './isRelatedTo'
import isSimilarTo from './isSimilarTo'

const elements =
[
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'name',
            key          : 'name',
            label        : 'Nom',
            autoFocus    : false,
            fullWidth    : true,
            helperText   : null,
            margin       : 'normal',
            required     : false,
            select       : false,
            autoComplete : 'off' ,
            type         : 'text',
            variant      : 'outlined'
        }
    },
    {
        type       : Form.SEARCH_SELECTOR ,
        path       : api.thesaurus.articles_types ,
        clearLabel : 'Vider' ,
        closeLabel : 'Fermer' ,
        init  :
        {
            id         : 'additionalType',
            key        : 'additionalType',
            label      : 'Type d\'article',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            required   : true,
            type       : 'text',
            variant    : 'outlined'
        },
        inputProps :
        {
            label : 'Sélectionner un type de lieu...'
        },
        searchIcon : <NoteIcon/>
    }
];

const article =
{
    // dependencies

    hasPart,
    isPartOf,
    isRelatedTo,
    isSimilarTo ,

    // settings

    edit :
    {
        elements,
        agree       : 'Sauver' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour modifier cet article.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...' ,
        success     : 'Mise à jour réussie!' ,
        title       : 'Modifier l\'article' ,
    },
    labels :
    {
        empty : '----' ,
        name  : 'Nom' ,
        type  : 'Type d\'article',
    },
    title    : 'Article' ,
    tooltips :
    {
        add    : 'Ajouter',
        edit   : 'Modifier',
        remove : 'Supprimer'
    }
};

export default article ;