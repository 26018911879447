import notEmpty from 'vegas-js-core/src/strings/notEmpty'

import FavoriteType from './FavoriteType'

export const types =
{
    'articles'          : FavoriteType.article ,
    'conceptualObjects' : FavoriteType.conceptualObject ,
    'courses'           : FavoriteType.course ,
    'diseases'          : FavoriteType.disease ,
    'events'            : FavoriteType.event  ,
    'livestocks'        : FavoriteType.livestock ,
    'organizations'     : FavoriteType.organization ,
    'people'            : FavoriteType.person ,
    'places'            : FavoriteType.place ,
    'stages'            : FavoriteType.stage ,
    'technicians'       : FavoriteType.technician ,
    'veterinarians'     : FavoriteType.veterinarian ,
};

const getFavoriteType = ( thing , { prefix = '' } = {} ) =>
{
    if( thing )
    {
        let { url } = thing ;
        url = prefix + url ;
        if( notEmpty(url) )
        {
            for( let prop in types )
            {
                if ( url.indexOf(prop) > -1 )
                {
                    return types[prop] ;
                }
            }
        }
    }
    return FavoriteType.UNKNOWN ;
};


export default getFavoriteType ;
