import init from './thing'

const veterinary =
{
    ...init,
    authority  : 
    {
        title : 'Autorité' 
    },
    identifier : 'N° Ordinal',
};

export default veterinary ;
