import React from 'react'

import isString from "vegas-js-core/src/isString"
import ucFirst  from "vegas-js-core/src/strings/ucFirst"

import { Typography } from '@material-ui/core'

import Method from '../../../../net/Method'

import Question from '../../../../things/Question'

const questionImage =
{
    failed   : 'Impossible d\'ajouter une image',
    remove :
    {
        agree       : 'Supprimer' ,
        description : 'Souhaitez-vous supprimer cette image ?' ,
        disagree    : 'Annuler' ,
        item        : {
            render : ( item , props ) =>
            {
                let label ;

                if( item instanceof Question )
                {
                    const { lang } = props ;
                    label = item.getLocaleAlternateName(lang) ;
                }

                if( !isString(label) || label === '' )
                {
                    label = questionImage.unknown ;
                }

                if( isString(label) )
                {
                    label = ucFirst(label) ;
                }

                return <Typography className='px-16'>{label}</Typography> ;

            }
        },
        loading : 'Suppression en cours...' ,
        method  : Method.PATCH,
        title   : 'Supprimer l\'image ?',
        useID   : false
    },
    removed  : 'Image supprimée',
    success  : 'Image ajoutée avec succès',
    title    : 'Image' ,
    tooltips :
    {
        add         : 'Ajouter',
        remove      : 'Supprimer',
        selectMedia : 'Sélectionner'
    },
    unknown : 'Inconnu'
};

export default questionImage ;
