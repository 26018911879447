import getLocaleProperty from './getLocaleProperty'

/**
 * Returns the locale alternativeHeadline expression of the thing.
 * @param {Thing} thing - The thing reference.
 * @param {string} lang - The lang of the expression.
 * @param {string} defaultLang - The lang of the expression.
 * @return {string} The locale alternativeHeadline expression of the thing.
 */
const getLocaleAlternativeHeadline = getLocaleProperty('alternativeHeadline' )

export default getLocaleAlternativeHeadline ;
