import Thing  from '../../../things/Thing'

class Setting extends Thing
{
    /**
     * Creates a new AmbuloSetting instance.
     * @constructor
     * @param object The generic object to initialize the object the first time.
     */
    constructor( object = null )
    {
        super() ;

        /**
         * The version of application.
         * @type {string}
         */
        this.version = null ;

        this.set( object ) ;
    }

    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        super.clear() ;
        this.version = null ;
        return this ;
    }

    /**
     * Returns a shallow copy of the object.
     * @return {AmbuloSetting} a shallow copy of the object.
     */
    clone()
    {
        return new Setting( this.toObject() ) ;
    }

    /**
     * Returns the generic object representation of the item.
     * @return {Object} The generic object representation of the item.
     */
    toObject()
    {
        let { version } = this ;
        return { version };
    }
}

export default Setting ;
