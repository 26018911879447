import React from 'react'

import Form        from '../../../../display/forms/FormElements'
import Measurement from '../../../../display/things/conceptualObject/Measurement'
import NumberInput from '../../../../components/inputs/NumberInput'

import api from '../../../../configs/api'

const elements =
[
    {
        type : Form.I18N_EDITOR ,
        init :
        {
            id    : 'alternateName',
            key   : 'alternateName',
            input : true,
            label : 'Titre'
        }
    },
    {
        type       : Form.SEARCH_SELECTOR ,
        path       : api.thesaurus.dimensions ,
        clearLabel : 'Vider' ,
        closeLabel : 'Fermer' ,
        init       :
        {
            id         : 'dimension',
            key        : 'dimension',
            label      : 'Dimension',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            required   : true   ,
            type       : 'search',
            variant    : 'outlined'
        },
        inputProps :
        {
            label : 'Sélectionner un type de dimension...'
        }
    },
    {
        type : Form.TEXTFIELD ,
        init :
            {
                id           : 'value',
                key          : 'value',
                label        : 'Valeur',
                autoFocus    : false,
                fullWidth    : true,
                helperText   : null,
                InputProps   : {
                    inputComponent : ( input ) =>
                        (
                            <NumberInput
                                allowNegative = {true}
                                {...input}
                            />
                        )
                },
                margin       : 'normal',
                required     : true,
                select       : false,
                autoComplete : 'off' ,
                type         : 'text',
                variant      : 'outlined',
                placeholder  : 'votre valeur'
            }
    },
    {
        type       : Form.SEARCH_SELECTOR ,
        path       : api.thesaurus.units ,
        clearLabel : 'Vider' ,
        closeLabel : 'Fermer' ,
        init       :
        {
            id         : 'unit',
            key        : 'unit',
            label      : 'Unité',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            required   : true,
            type       : 'search',
            variant    : 'outlined'
        },
        inputProps :
        {
            label : 'Sélectionner une unité...'
        }
    },
    {
        type : Form.I18N_EDITOR ,
        init :
        {
            id    : 'description',
            key   : 'description',
            input : true,
            label : 'Description'
        }
    }
];

const measurements =
{
    add :
    {
        agree       : 'Créer' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour ajouter une mesure de cet objet.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...' ,
        success     : 'Ajout de cet élément réussi!' ,
        title       : 'Ajouter une mesure',
        elements ,
    },
    edit :
    {
        agree       : 'Sauver' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour modifier cet enregistrement.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...'  ,
        success     : 'Mise à jour réussie!' ,
        title       : 'Modifier',
        elements,
    },
    remove :
    {
        agree       : 'Supprimer' ,
        description : 'Souhaitez-vous supprimer cette entrée :' ,
        disagree    : 'Annuler' ,
        iconifiable : false,
        item        : { render : item => <Measurement thing={item} editable={ false } style={{ marginLeft:24 }} /> },
        loading     : 'Suppression en cours...' ,
        title       : 'Supprimer cet enregistrement ?',
        titleX      : 'Supprimer ces enregistrements ?'
    },
    title    : 'Mesures' ,
    tooltips :
    {
        add    : "Ajouter" ,
        edit   : "Éditer" ,
        remove : "Supprimer"
    }
};

export default measurements ;
