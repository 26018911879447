import React from "react"

import isString from 'vegas-js-core/src/isString'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import { Tab, Tabs } from '@material-ui/core'

import HealingIcon from '@material-ui/icons/Healing'

import { Route, Switch } from "react-router-dom"

import api from '../../../configs/api'

import ThingView , { defaultStyle } from '../../../display/views/ThingView'

import About from './views/about/About'

import RequestStatus from "../../../net/RequestStatus"

import DiseaseVO from '../../../things/medical/Disease'
import Thing     from '../../../things/Thing'

import initView from '../../../display/views/initView'

const styles = defaultStyle();

class Disease extends ThingView
{
    getLocale = () => this.props.locale.views.medical.disease ;

    getRoutes = ( uri , path ) =>
    {
        const { clazz } = this.props ;
        const { status, thing } = this.state ;
        if( status === RequestStatus.SUCCESS && clazz && (thing instanceof clazz) )
        {
            const locale = this.getLocale() ;

            const about = (
                <About
                    locale   = { locale.about }
                    onChange = { this.change }
                    onRemove = { this.onBack }
                    path     = { path }
                    thing    = { thing }
                />
            );

            return (
                <Switch>
                    <Route path={ uri + '/about'  } render={ () => about  } />
                </Switch>
            );
        }
        return null ;
    };

    getSubtitle = thing =>
    {
        if( thing )
        {
            const { additionalType } = thing ;
            if( additionalType instanceof Thing )
            {
                const { lang } = this.props ;
                return ucFirst(additionalType.getLocaleName(lang))  ;
            }
        }
        return null ;
    };

    getTabs = () =>
    {
        const { clazz } = this.props ;
        const { status, thing } = this.state ;
        if( status === RequestStatus.SUCCESS && clazz && (thing instanceof clazz) )
        {
            const locale = this.getLocale() ;

            const {
                classes,
                location,
                match,
                path
            } = this.props ;

            const uri = path.url + '/' + match.params.id ;

            let route = location.pathname.split('/') ;

            route.shift() ;
            route = "/" + route.join("/") ;

            const { wrapper } = classes ;

            return (
                <Tabs
                    className      = 'w-full border-b'
                    indicatorColor = 'primary'
                    onChange       = { this._change }
                    scrollButtons  = 'auto'
                    textColor      = 'secondary'
                    value          = { route }
                    variant        = 'scrollable'
                >
                    <Tab
                        classes = {{ wrapper }}
                        label   = { locale.toolbar.about }
                        value   = { uri + '/about' }
                    />
                </Tabs>
            );
        }

        return null ;
    };

    getTitle = ( thing ) =>
    {
        if( thing instanceof Thing )
        {
            const { lang } = this.props ;

            let { alternateName } = thing ;

            if( alternateName )
            {
                alternateName = thing.getLocaleAlternateName( lang ) ;
                if( isString(alternateName) && alternateName !== '' )
                {
                    return ucFirst(alternateName)  ;
                }
            }

            return ucFirst(thing.getLocaleName(lang))  ;
        }
        return null ;
    };
}

Disease.defaultProps =
{
    ...ThingView.defaultProps,
    back       : true ,
    clazz      : DiseaseVO ,
    icon       : <HealingIcon/>,
    path       : api.diseases,
    preferable : true ,
    titleAuto  : false
};

Disease.propTypes =
{
    ...ThingView.propTypes
};

export default initView( Disease, styles );
