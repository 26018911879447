import React from 'react'

import compose from 'vegas-js-core/src/functors/compose'

import initApp from '../../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import PlaceIcon from '@material-ui/icons/Place'

import PropertyValue from '../../../things/PropertyValue'
import Place         from '../../../things/Place'
import Thing         from '../../../things/Thing'

import ThingChildren, { styles } from '../ThingChildren'

class ContainedInPlace extends ThingChildren
{
    addMapUri = ( item , uri ) =>
    {
        if( item instanceof PropertyValue)
        {
            const { value } = item ;
            if( value instanceof Thing )
            {
                return uri + '/' + value.id ;
            }
        }
        return uri ;
    };

    addPrepare = item => ({ value : item.value });

    getLocale = () => this.props.locale.things.place.containedInPlace ;
}

ContainedInPlace.defaultProps =
{
    ...ThingChildren.defaultProps ,
    clazz      : Place,
    emptyClazz : PropertyValue,
    emptyIcon  : null ,
    icon       : <PlaceIcon/> ,
    member     : 'containedInPlace'
};

ContainedInPlace.propTypes =
{
    ...ThingChildren.propTypes
};

export default compose( withStyles( styles ) , withRouter , initApp )( ContainedInPlace ) ;
