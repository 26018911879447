import notEmpty from 'vegas-js-core/src/strings/notEmpty'

import InterestType from './InterestType'

export const types =
{
    'articles'          : InterestType.ARTICLE ,
    'conceptualObjects' : InterestType.CONCEPTUAL_OBJECT ,
    'courses'           : InterestType.COURSE ,
    'events'            : InterestType.EVENT  ,
    'organizations'     : InterestType.ORGANIZATION ,
    'people'            : InterestType.PERSON ,
    'places'            : InterestType.PLACE ,
    'stages'            : InterestType.STAGE
};

const getInterestType = ( thing , { prefix = '' } = {} ) =>
{
    if( thing )
    {
        let { url } = thing ;
        url = prefix + url ;
        if( notEmpty(url) )
        {
            for( let prop in types )
            {
                if ( url.indexOf(prop) > -1 )
                {
                    return types[prop] ;
                }
            }
        }
    }
    return InterestType.UNKNOWN ;
};


export default getInterestType ;
