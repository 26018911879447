import isString from 'vegas-js-core/src/isString'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import Form from '../../../../display/forms/FormElements'

import MedicalMethod from '../../../../things/medical/MedicalMethod'
import Thing         from '../../../../things/Thing'
import Word          from '../../../../things/Word'

import api from "../../../../configs/api"

import Method from "../../../../net/Method"

import analysisMethod from './analysisMethod'

const elements =
[
    {
        type       : Form.SEARCH_SELECTOR ,
        path       : api.thesaurus.analysis_methods ,
        clearLabel : 'Vider' ,
        closeLabel : 'Fermer' ,
        init       :
        {
            id         : 'additionalType',
            key        : 'additionalType',
            label      : 'Méthode d\'analyse',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            required   : true,
            type       : 'search',
            variant    : 'outlined'
        },
        inputProps :
        {
            label : 'Sélectionner une méthode...'
        },
        logics :
        {
            disabledSuggestions : ( props ) =>
            {
                const { rel } = props ;
                let suggestions = [] ;
                if( rel instanceof Thing )
                {
                    const { additionalType } = rel ;
                    if( additionalType instanceof Word )
                    {
                        suggestions.push( additionalType ) ;
                    }
                }
                return suggestions ;
            }
        }
    },
    {
        type : Form.SWITCH ,
        init :
        {
            id    : 'mandatory',
            key   : 'mandatory',
            label : 'Obligatoire ?'
        }
    }
];

const analysisMethods =
{
    // dependencies
    
    analysisMethod,
    
    // settings
    
    add :
    {
        elements,
        agree       : 'Créer' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour ajouter une méthode d\'analyse.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...' ,
        mock        : false,
        success     : 'Ajout du nouveau tarif réussi!' ,
        title       : 'Ajouter un tarif'
    },
    edit :
    {
        elements,
        agree       : 'Sauver' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour modifier cette méthode d\'analyse.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...',
        method      : Method.PUT,
        mock        : false,
        success     : 'Mise à jour réussie!' ,
        title       : 'Modifier la méthode d\'analyse'
    },
    remove :
    {
        agree       : 'Supprimer' ,
        description : 'Souhaitez-vous supprimer cette méthode d\'analyse:' ,
        disagree    : 'Annuler' ,
        item        :
        {
            text :
            {
                primary : ( thing , props ) =>
                {
                    let label = null ;
                    if( thing instanceof MedicalMethod )
                    {
                        const { additionalType } = thing ;
                        
                        if( additionalType instanceof Thing )
                        {
                            label = additionalType.getLocaleName( props.lang ) ;
                        }
                    }
                    
                    if( !isString(label) || label === '' )
                    {
                        label = analysisMethods.unknown
                    }
                    
                    return ucFirst(label) ;
                }
            }
        },
        loading : 'Suppression en cours...' ,
        title   : 'Supprimer la méthode d\'analyse ?'
    },
    title    : 'Méthodes d\'analyse' ,
    unknown  : 'Inconnu',
    tooltips :
    {
        add    : "Ajouter" ,
        edit   : "Éditer" ,
        remove : "Supprimer"
    }
};

export default analysisMethods ;