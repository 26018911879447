import generify from './generify'

import Thing from './Thing'
import Word  from './Word'

/**
 * A Measurement definition object.
 * @memberOf things
 */
class Measurement extends Thing
{
    /**
     * Creates a new Measurement instance.
     * @constructor
     * @param object The generic object to initialize the object the first time.
     */
    constructor( object = null )
    {
        super() ;

        /**
         * The dimension of this object.
         * @type {object}
         */
        this.dimension = null ;

        /**
         * The units of this object
         * @type {object}
         */
        this.unit = null ;

        /**
         * The value of this object
         * @type {number}
         */
        this.value = 0 ;

        this.set( object ) ;
    }

    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        super.clear() ;
        this.dimension =
        this.unit      = null ;
        this.value     = 0 ;
        return this ;
    }

    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new Measurement( this.toObject() ) ;
    }

    /**
     * Populate the object with specific behaviors or types. Launched by default in the constructor and in the set methods.
     * @returns {Thing} The current reference.
     */
    populate()
    {
        const {
            dimension,
            unit
        } = this;

        if( dimension )
        {
            this.dimension = new Word( dimension ) ;
        }

        if( unit )
        {
            this.unit = new Word( unit ) ;
        }

        return this ;
    }

    /**
     * Returns the generic object representation of the item.
     * @return {Object} The generic object representation of the item.
     */
    toObject()
    {
        let { dimension, unit, value } = this ;

        dimension = generify(dimension) ;
        unit      = generify(unit) ;
        value     = generify(value) ;

        return {
            ...super.toObject() ,
            dimension,
            unit,
            value
        };
    }
}

export default Measurement ;