import React from 'react'

import PropTypes from 'prop-types'

import withi18n from '../../contexts/i18n/withi18n'

import AddressLabel      from '../labels/AddressLabel'
import TechnicianCounter from '../counters/TechnicianCounter'
import VeterinaryCounter from '../counters/VeterinaryCounter'
import WorkshopCounter   from '../counters/WorkshopCounter'

import getHorizontalElements from '../getHorizontalElements'
import getVerticalElements   from '../getVerticalElements'

import Livestock from '../../things/Livestock'

import { ThingCell } from './ThingCell'

class LivestockCell extends ThingCell
{
    getAddress = ( { className = null } = {} ) =>
    {
        const { addressable:visible } = this.props;
        const thing = this.getThing() ;
        if( visible && thing )
        {
            const { address, location } = thing ;
            if ( address )
            {
                return <AddressLabel className={className} thing={thing} />;
            }
            else if( location )
            {
                let { address } = location ;
                if ( address )
                {
                    return <AddressLabel className={className} thing={location} />;
                }
            }
        }
        return null;
    };

    getContent = () =>
    {
        const { thing } = this.props;
        if ( thing )
        {
            const line1 = getHorizontalElements({
                factory   : this ,
                elements  : [ 'workshops' , 'veterinarians' , 'technicians' ]
            }) ;

            const line2 = getHorizontalElements({
                factory  : this ,
                elements : [ 'address' ]
            }) ;

            return getVerticalElements({ elements:
            [
                ...(line1 ? [line1] : [] ) ,
                ...(line2 ? [line2] : [] )
            ]}) ;
        }
        return null ;
    };

    getLocale = () => this.props.locale.display.cells.livestock;

    getThing = () =>
    {
        const { thing } = this.props;
        if( thing )
        {
            const { organization } = thing ;
            if( organization )
            {
                return organization ;
            }
        }
        return thing;
    };

    getTechnicians = ( { className='py-8' } = {} ) =>
    {
        const { thing, technicians:visible } = this.props;
        return ( visible && thing instanceof Livestock ) ? <TechnicianCounter className={className} thing={thing} path='{0}/authorities' /> : null ;
    };

    getVeterinarians = ( { className='py-8' } = {} ) =>
    {
        const { thing, veterinarians:visible } = this.props;
        return ( visible && thing instanceof Livestock ) ? <VeterinaryCounter className={className} thing={thing} path='{0}/authorities' /> : null ;
    };

    getWorkshops = ( { className='py-8' } = {} ) =>
    {
        const { thing, workshops:visible } = this.props;
        return ( visible && thing instanceof Livestock ) ? <WorkshopCounter className={className} thing={thing} /> : null ;
    };
}

LivestockCell.defaultProps =
{
    ...ThingCell.defaultProps,
    addressable   : true,
    technicians   : true ,
    veterinarians : true ,
    workshops     : true
};

LivestockCell.propTypes =
{
    ...ThingCell.propTypes,
    addressable   : PropTypes.bool,
    technicians   : PropTypes.bool ,
    veterinarians : PropTypes.bool ,
    workshops     : PropTypes.bool
};

export default withi18n(LivestockCell) ;
