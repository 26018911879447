import init from './thing'

const course =
{
    ...init,
    level  : { title : 'Niveau' },
    status : { title : 'État' }
};

export default course ;
