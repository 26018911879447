import tokensManager from "../../vo/TokensManager"

import user from "../../contexts/user/user"

import stored from "../../net/stored"

const referrerID = process.env.REACT_APP_REFERRER_ID ;

class AuthStatus
{
    static GUEST             = 'guest' ;
    static NEED_ACCESS_TOKEN = "needAccessToken" ;
    static NEED_USER         = "needUser" ;
    static CONNECTED         = 'connected' ;

    static check()
    {
        const isStored = stored( user.storageID ) ;

        const accessToken = tokensManager.read( 'api' ) ;

        if( !accessToken )
        {
            // console.log( '## The application need an access token ##' ) ;
            const referrer = window.location.pathname ;
            if( referrer !== '/home' && referrer !== '/register' && referrer !== '/welcome' && referrer !== '/redirect' )
            {
                sessionStorage.setItem( referrerID , JSON.stringify(referrer) ) ;
            }
            return AuthStatus.NEED_ACCESS_TOKEN ;
        }
        else if ( !isStored )
        {
            return AuthStatus.NEED_USER ;
        }
        else if( accessToken && isStored )
        {
            return AuthStatus.CONNECTED ;
        }
        else
        {
            return AuthStatus.GUEST ;
        }
    }
}

export default AuthStatus ;