import React from 'react'

import moment from 'moment/moment'

import compose  from 'vegas-js-core/src/functors/compose'
import isString from 'vegas-js-core/src/isString'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import initApp from '../../contexts/app/initApp'

import { Link, withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { Grid } from '@material-ui/core'

import ArtworkIcon from '@material-ui/icons/Category'

import LabelItem from '../../components/items/LabelItem'

import ThingContainer from '../containers/ThingContainer'

import api from '../../configs/api'

import ConceptualObjectVO from '../../things/ConceptualObject'
import ThingVO            from '../../things/Thing'

const styles = () => ({});

const full =
{
    xs : 12
};

const half =
{
    md :  6 ,
    sm : 12 ,
    xs : 12
};

class ConceptualObject extends ThingContainer
{
    getContent = () =>
    {
        let { lang, thing } = this.props;

        if( thing instanceof ConceptualObjectVO )
        {
            const locale = this.getLocale() ;

            let options ;

            let {
                category,
                movement,
                temporal,
                location
            } = thing ;

            let name = thing.getLocaleName(lang);

            if( category instanceof ThingVO )
            {
                category = category.getLocaleName(lang)
            }
            else
            {
                category = null ;
            }

            if( movement instanceof ThingVO )
            {
                movement = movement.getLocaleName(lang)
            }
            else
            {
                movement = null ;
            }

            if( temporal instanceof ThingVO )
            {
                temporal = temporal.getLocaleName(lang)
            }
            else
            {
                temporal = null ;
            }

            if( location instanceof ThingVO )
            {
                const { url } = location ;

                location = location.getLocaleName(lang);

                if( url )
                {
                    options  =
                    {
                        component : Link ,
                        to        : { pathname : url.split(api.url)[1] }
                    };
                }
            }
            else
            {
                location = null ;
            }

            const { labels } = locale ;

            return (
                <Grid container spacing={4}>

                    <Grid item {...half}>
                        <LabelItem enabled={!!(name)} title={ labels.name }>
                            { isString(name) ? ucFirst(name) : labels.empty }
                        </LabelItem>
                    </Grid>
                    <Grid item {...half}>
                        <LabelItem enabled={!!(category)} title={ labels.category } >
                            { isString(category) ? ucFirst(category) : labels.empty }
                        </LabelItem>
                    </Grid>
                    <Grid item {...half}>
                        <LabelItem enabled={!!(movement)} title={ labels.movement } >
                            { isString(movement) ? ucFirst(movement) : labels.empty }
                        </LabelItem>
                    </Grid>
                    <Grid item {...half}>
                        <LabelItem enabled={!!(temporal)} title={ labels.temporal } >
                            { isString(temporal) ? ucFirst(temporal) : labels.empty }
                        </LabelItem>
                    </Grid>
                    <Grid item {...full}>
                        <LabelItem
                            enabled = { !!(location) }
                            title   = { labels.location }
                            { ...options }
                        >
                            { isString(location) ? ucFirst(location) : labels.empty }
                        </LabelItem>
                    </Grid>


                </Grid>
            );
        }

        return null ;
    };

    getEntry = init =>
    {
        let empty = {} ;
        if( init instanceof ThingVO )
        {
            empty = init.toObject() ;
        }
        else if( init )
        {
            empty = { ...init } ;
        }
        return new ConceptualObjectVO(empty);
    };

    getLocale = () => this.props.locale.things.conceptualObject;

    onEdit = ( item ) =>
    {
        const {
            onChange,
            thing
        } = this.props ;

        if( thing && item )
        {
            const {
                additionalType,
                category,
                location,
                movement,
                name,
                temporal
            } = item ;

            thing.additionalType = additionalType ;
            thing.category       = category ;
            thing.location       = location ;
            thing.modified       = moment(new Date()).toISOString();
            thing.movement       = movement ;
            thing.name           = name ;
            thing.temporal       = temporal ;

            if( thing instanceof ThingVO )
            {
                thing.populate();
            }

            if( this._mounted )
            {
                this.forceUpdate();
            }

            if( onChange instanceof Function )
            {
                onChange(thing) ;
            }
        }
    }
}

ConceptualObject.defaultProps =
{
    ...ThingContainer.defaultProps ,
    icon       : <ArtworkIcon/> ,
    optionMode : 'edit'
};

ConceptualObject.propTypes =
{
    ...ThingContainer.propTypes
};

export default compose( withStyles( styles ) , withRouter , initApp )( ConceptualObject ) ;
