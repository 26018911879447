import initDialog from '../initDialog'

import { RemoveDialog } from '../RemoveDialog'

import api from '../../../configs/api'

const styles = () => ({});

class RemoveEventDialog extends RemoveDialog
{
    getLocale = () => this.props.locale.dialogs.application.event.remove ;
}

RemoveEventDialog.defaultProps =
{
    ...RemoveDialog.defaultProps,
    uri    : api.events.url,
    useUrl : true
};

RemoveEventDialog.propTypes =
{
    ...RemoveDialog.propTypes
};

export default initDialog({ styles })( RemoveEventDialog );
