import React from 'react'

import DomainIcon   from '@material-ui/icons/Domain'
import GroupAddIcon from '@material-ui/icons/GroupAdd'
import PersonIcon   from '@material-ui/icons/Person'
import PeopleIcon   from '@material-ui/icons/People'

import Organization  from '../../things/Organization'
import Person        from '../../things/Person'
import PropertyValue from '../../things/PropertyValue'

import ThingChildren from './ThingChildren'

import ThingCell from '../cells/ThingCell'

class Authorities extends ThingChildren
{
    createChild = child =>
    {
        let icon ;
        switch( true )
        {
            case child instanceof Organization :
            {
                icon = <DomainIcon/>;
                break ;
            }
            case child instanceof Person :
            {
                icon = <PersonIcon/>;
                break ;
            }
            default :
            {
                //
            }
        }
        return <ThingCell icon={icon} thing={child} variant='default'/>
    };
}

Authorities.defaultProps =
{
    ...ThingChildren.defaultProps ,
    addIcon    : <GroupAddIcon/>,
    clazz      : [ Person, Organization] ,
    emptyClazz : PropertyValue,
    emptyIcon  : null ,
    icon       : <PeopleIcon/>
};

Authorities.propTypes =
{
    ...ThingChildren.propTypes
};

export default Authorities ;