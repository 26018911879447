import React from 'react'

import compose from 'vegas-js-core/src/functors/compose'
import ucFirst from 'vegas-js-core/src/strings/ucFirst'

import initApp from '../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { Chip } from '@material-ui/core'

import blueGrey from '@material-ui/core/colors/blueGrey'

import LocalActivityIcon from '@material-ui/icons/LocalActivity'

import ThingContainer from '../containers/ThingContainer'

import Place   from '../../things/Place'
import ThingVO from '../../things/Thing'
import Word    from '../../things/Word'

const styles = theme => (
{
    chip :
    {
        borderColor : blueGrey[100],
        margin      : theme.spacing(0.5)
    }
});

class Activities extends ThingContainer
{
    editInit = item =>
    {
        if( item )
        {
            const { activities } = item ;
            if( activities instanceof Array )
            {
                return activities.map( element =>
                {
                    if( element instanceof Word )
                    {
                        return element.clone() ;
                    }
                    return null ;
                })
            }
        }
        return null ;
    };

    editPrepare = datas =>
    {
        if( datas instanceof Array )
        {
            datas = datas.map( item => item instanceof Word ? item.id : null ) ;
            let object = datas.join(',');
            object = { activities : object  } ;
            return object;
        }
        return null ;
    };

    getBadgeLabel = () =>
    {
        const { thing } = this.props ;
        if( thing )
        {
            const { activities } = thing  ;
            if( (activities instanceof Array) )
            {
                return activities.length ;
            }
        }
        return 0 ;
    };

    getContent = () =>
    {
        const { classes, lang, thing } = this.props ;
        if( thing )
        {
            let { activities } = thing ;

            if( activities instanceof Array && activities.length > 0 )
            {
                let css = { paddingLeft:8,marginRight:0, height:24, width:24 } ;

                activities = [ ...activities ] ;

                activities.sort( ( a , b ) =>
                {
                    if( a instanceof Word && b instanceof Word )
                    {
                        return a.getLocaleName(lang).localeCompare(b.getLocaleName(lang));
                    }
                    return 0;
                });

                activities = activities.map( ( item , index ) =>
                {
                    if( item instanceof Word )
                    {
                        const label = ucFirst(item.getLocaleName( lang )) ;
                        const icon  = this.getImage(item,css);
                        return (
                        <Chip
                            className = { classes.chip }
                            clickable = { false }
                            key       = { 'activity_' + index }
                            icon      = { icon }
                            label     = { label }
                            variant   = 'outlined'
                        />);
                    }
                    return null ;
                });

                return (
                    <div className="flex flex-row flex-wrap">
                        { activities }
                    </div>
                )
            }
        }
        return null ;
    };

    getEntry = init =>
    {
        let empty = {} ;
        if( init instanceof ThingVO )
        {
            empty = init.toObject() ;
        }
        else if( init )
        {
            empty = { ...init } ;
        }
        return new Place(empty) ;
    };

    getLocale = () => this.props.locale.things.activities ;
}

Activities.defaultProps =
{
    ...ThingContainer.defaultProps ,
    badge      : true ,
    icon       : <LocalActivityIcon/>,
    optionMode : 'edit'
};

Activities.propTypes =
{
    ...ThingContainer.propTypes
};

export default compose( withStyles( styles ) , withRouter , initApp )( Activities ) ;
