import compose from 'vegas-js-core/src/functors/compose'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { withWidth }  from '@material-ui/core'

import withConfig   from '../contexts/config/withConfig'
import withDialogs  from '../contexts/dialogs/withDialogs'
import withLang     from '../contexts/i18n/withLang'
import withLoading  from '../contexts/loading/withLoading'
import withLocale   from '../contexts/i18n/withLocale'
import withSearch   from '../contexts/search/withSearch'
import withSelect   from '../contexts/select/withSelect'
import withSettings from '../contexts/settings/withSettings'
import withSnack    from '../contexts/snack/withSnack'
import withUser     from '../contexts/user/withUser'

const withDatas = defaultStyles => ( {
    useConfig   = true ,
    useDialogs  = true ,
    useLang     = true ,
    useLoading  = true ,
    useLocale   = true ,
    useSearch   = true ,
    useSelect   = true ,
    useSettings = true ,
    useSnack    = true ,
    useStyles   = true ,
    useUser     = true ,
    useWidth    = true ,
    styles      = defaultStyles ,
    withTheme   = true
} = {} ) => compose(
    withRouter,
    useWidth    && withWidth(),
    useConfig   && withConfig,
    useStyles   && withStyles( styles , { withTheme } ) ,
    useDialogs  && withDialogs,
    useLang     && withLang ,
    useLoading  && withLoading,
    useLocale   && withLocale ,
    useSearch   && withSearch,
    useSettings && withSettings,
    useSelect   && withSelect,
    useSnack    && withSnack,
    useUser     && withUser
) ;

export default withDatas ;
