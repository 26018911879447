import React from 'react'

import PropTypes from "prop-types"

import compose  from 'vegas-js-core/src/functors/compose'
import isString from 'vegas-js-core/src/isString'
import notEmpty from 'vegas-js-core/src/strings/notEmpty'

import initApp from '../../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { withWidth, Button, Grid, IconButton, Tooltip, Typography, Zoom } from '@material-ui/core'

import AddIcon          from '@material-ui/icons/AddCircle'
import AddNextIcon      from '@material-ui/icons/Add'
import PinDropIcon      from '@material-ui/icons/PinDrop'

import api           from '../../../configs/api'
import RequestStatus from '../../../net/RequestStatus'

import Step from '../../../things/Step'

import StepCard from '../../cards/StepCard'

import AddStepDialog    from '../../dialogs/add/AddStepDialog'
import RemoveStepDialog from '../../dialogs/remove/RemoveStepDialog'

import stepsNone from '../../../assets/images/stepsNone'

import ThingOrderableChildren, { styles } from '../ThingOrderableChildren'
import clsx from "clsx";

class Steps extends ThingOrderableChildren
{
    createChild = ( child , editable , index ) =>
    {
        if( child instanceof Step )
        {
            const { orderStatus } = this.state ;
            const { onSelect } = this.props ;
            return (
                <StepCard
                    disabled      = { orderStatus === RequestStatus.PROGRESS }
                    editable      = { editable }
                    footerOptions = { this.getFooterOptions( child , editable ) }
                    index         = { index }
                    key           = { 'step-card-' + index }
                    onSelect      = { onSelect }
                    thing         = { child }
                />
            );
        }
        return null ;
    };

    getAddButton = ( disabled = false , offset = 0 ) =>
    {
        if( this.isEditable() )
        {
            const {
                addIcon,
                addButtonProps
            } = this.props ;

            const locale = this.getLocale() ;

            let label = null ;
            const { labels } = locale ;
            if( labels )
            {
                const { add } = labels ;
                if( add )
                {
                    label = add ;
                }
            }

            let button = (
                <Button
                    className = "my-16"
                    disabled  = { disabled }
                    onClick   = { () => this.openAddDialog( { offset } ) }
                    color     = 'primary'
                    variant   = 'contained'
                    { ...addButtonProps }
                >
                    { addIcon }
                    { label }
                </Button>);

            if( !disabled )
            {
                const { tooltips } = locale ;
                if( tooltips )
                {
                    const { add } = tooltips ;
                    if( isString( add ) )
                    {
                        button = (
                            <Tooltip
                                title     = { add }
                                placement = 'top'
                            >
                                { button }
                            </Tooltip>
                        );
                    }
                }
            }

            return <Zoom timeout={{ appear: 1000, enter: 500, exit: 500 }} in={true} >{button}</Zoom> ;
        }
        return null ;
    };

    getAddAfterButton = ( child , editable = null ) =>
    {
        if( editable )
        {
            const {
                AddAfterButtonComponent,
                AddAfterButtonIcon,
                AddAfterButtonProps
            } = this.props ;

            const disabled = this.isLocked() ;

            let offset = -1 ;
            if( child )
            {
                const { position } = child ;
                if( position >= 0 )
                {
                    offset = position ;
                }
            }

            offset++ ;

            let button = (
                <AddAfterButtonComponent
                    disabled = { disabled }
                    size     = 'small'
                    { ...AddAfterButtonProps }
                    onClick = { () => this.openAddDialog( { offset } ) }
                >
                    { AddAfterButtonIcon }
                </AddAfterButtonComponent>
            );

            if( !disabled )
            {
                const locale = this.getLocale();
                if( locale )
                {
                    const { tooltips } = locale ;
                    if( tooltips )
                    {
                        const { after } = tooltips ;
                        if( button && isString( after ) )
                        {
                            button = (
                                <Tooltip placement='top' title={after}>
                                    { button }
                                </Tooltip>
                            )
                        }
                    }
                }
            }

            return button ;
        }
        return null ;
    };

    getAfterContent = () =>
    {
        const { dragging } = this.state ;
        if( !dragging )
        {
            const { member, thing } = this.props;
            if( thing && thing.hasOwnProperty(member) )
            {
                let children = thing[member];
                if( children instanceof Array && children.length > 2 )
                {
                    return (
                        <div className='flex flex-1 items-center justify-center p-8'>
                            { this.getAddButton( this.isLocked() ) }
                        </div>
                    ) ;
                }
            }
        }
        return null ;
    } ;

    getFooterOptions = ( child, editable ) =>
    {
        const {
            activable,
            deletable,
        } = this.props ;
        if( (activable || deletable) )
        {
            return (
                <div className='flex flex-row items-center justify-center'>
                    { this.getAddAfterButton( child , editable ) }
                    { deletable && this.getDeleteButton( child, editable ) }
                    { activable && this.getActiveSwitch( child, editable ) }
                </div>
            ) ;
        }
        return null ;
    };

    getEmpty = () =>
    {
        const { grid } = this.props ;

        let label = this.getEmptyLabel() ;
        if( notEmpty(label) )
        {
            const {
                emptyClassName ,
                emptyProps ,
                emptyVariant
            } = this.props ;

            label = (
                <Typography
                    className = { clsx( 'w-full text-center italic text-gray-600' , emptyClassName ) }
                    variant   = { emptyVariant }
                    { ...emptyProps }
                >
                    { label }
                </Typography>
            );

        }
        else
        {
            label = null ;
        }

        return (
            <Grid item {...grid} className="flex flex-col items-center justify-center p-12" >
                <Zoom timeout={{ appear: 800, enter: 500, exit: 500 }} in={true} >
                    <img className="w-full h-auto max-w-512" src={stepsNone} alt="none"/>
                </Zoom>
                <Zoom timeout={{ appear: 2000, enter: 500, exit: 500 }} in={true} >
                    { label }
                </Zoom>
            </Grid>
        )
    };

    getLocale = () => this.props.locale.things.course.steps ;

    getOptions = () => this.getAddButton( this.isLocked() , 0 ) ;
}

Steps.defaultProps =
{
    ...ThingOrderableChildren.defaultProps ,

    activable      : true ,
    activateUri    : api.steps.url ,
    addIcon        : <AddIcon className='mr-16 self-center'/>,
    animate        : true ,
    clazz          : Step,
    containerProps :
    {
            className : 'bg-transparent w-full px-16',
            elevation : 0,
            square    : true
    },
    emptyVariant  : 'caption',
    icon          : <PinDropIcon/>,
    itemClassName : "flex flex-row items-center justify-center" ,
    member        : 'steps',
    onSelect      : null ,
    optionable    : false ,
    orderable     : true ,
    usePortal     : true,

    ActiveSwitchProps       :  { color : 'secondary' , className : 'mr-8' },
    AddDialogComponent      : AddStepDialog ,
    AddAfterButtonComponent : IconButton,
    AddAfterButtonIcon      : <AddNextIcon/>,
    AddAfterButtonProps     : { color : 'secondary' , className : 'mr-8' },
    DeleteButtonProps       : { className : 'mr-8' },
    RemoveDialogComponent   : RemoveStepDialog ,

};

Steps.propTypes =
{
    ...ThingOrderableChildren.propTypes ,
    onSelect             : PropTypes.func,
    OrderButtonComponent : PropTypes.object,
    OrderButtonUpIcon    : PropTypes.element,
    OrderButtonProps     : PropTypes.object
};

export default compose(
    initApp,
    withRouter,
    withWidth(),
    withStyles( styles , { withTheme:true } )
)( Steps ) ;
