import Pica from 'pica'

import filterJpeg from './filterJpeg'

const pica = new Pica() ;

const defaultOptions = {
    extensions : [ 'bmp', 'jpg', 'jpeg', 'png' ] ,
    maxWidth   : 2000 ,
    maxHeight  : 2000
} ;

const arrayConcat = list =>
{
    let size = 0;
    let pos = 0;

    for (let i = 0; i < list.length; i++)
    {
        size += list[i].length;
    }

    let result = new Uint8Array(size);

    for ( let i = 0; i < list.length; i++ )
    {
        result.set(list[i], pos);
        pos += list[i].length;
    }

    return result;
}

const resizeImageFile = ( file , options = null ) =>
{
    options = { ...defaultOptions , ...options } ;

    return new Promise(( resolve , reject ) =>
    {
        setTimeout(() =>
        {
            if ( file )
            {
                const { type } = file;

                if ( type.indexOf('image') === 0 )
                {
                    const { name } = file ;

                    let ext   = name.split('.').pop();
                    let slice = file.slice || file['webkitSlice'] || file['mozSlice'] ;

                    const { extensions } = options ;
                    if ( extensions.indexOf(ext) === -1 )
                    {
                        reject( { file , resized:false } ); // Skip resize
                        return;
                    }

                    let jpegHeader ;

                    const image = new Image();

                    image.onerror = () => reject('Is not a valid image file, name:' + name ) ;

                    image.onload  = () =>
                    {
                        let { height , width } = image;

                        const originalHeight = height;
                        const originalWidth  = width;

                        const {
                            maxHeight,
                            maxWidth
                        }
                        = options || {};

                        if ( width > height )
                        {
                            if (width > maxWidth)
                            {
                                height *= maxWidth / width;
                                width = maxWidth;
                            }
                            else
                            {
                                // Skip resize
                                resolve({ file, resized:false, height, width, originalWidth, originalHeight });
                                return;
                            }
                        }
                        else
                        {
                            if (height > maxHeight)
                            {
                                width *= maxHeight / height;
                                height = maxHeight;
                            }
                            else
                            {
                                // Skip resize
                                resolve({ file, resized:false, height, width, originalWidth, originalHeight });
                                return;
                            }
                        }

                        height = Math.round(height);
                        width  = Math.round(width);

                        const source = document.createElement('canvas');
                        const dest   = document.createElement('canvas');

                        source.width  = originalWidth  ;
                        source.height = originalHeight ;

                        dest.width  = width ;
                        dest.height = height ;

                        const context = source.getContext('2d');

                        context.drawImage (image , 0, 0, originalWidth, originalHeight );

                        const alpha = ext === 'png';

                        pica
                        .resize( source , dest , { alpha } )
                        .then( () => pica.toBlob( dest , type , 0.90 ) )
                        .then( blob =>
                        {
                            let jpegBlob, jpegBody ;
                            if ( jpegHeader )
                            {
                                jpegBody = slice.call(blob, 20); // remove JPEG header (2 bytes) and JFIF segment (18 bytes), assuming JFIF is always present and always the same in all images from canvas
                                jpegBlob = new Blob([ jpegHeader, jpegBody ], { type } );
                            }

                            file = jpegBlob || blob;
                            file.name = name;

                            file = new File( [ blob ] , name , { type } )  ;

                            resolve({ file, resized:true, height, width, originalWidth, originalHeight });
                        });
                    };

                    const reader = new FileReader();

                    reader.onloadend = () =>
                    {
                        const { result } = reader ;
                        if( result )
                        {
                            let fileData = new Uint8Array(result);
                            if ( fileData[0] === 0xFF && fileData[1] === 0xD8 )
                            {
                                let filter = filterJpeg(
                                {
                                    removeImage : true,
                                    filter      : true,
                                    removeICC   : true
                                });

                                try
                                {
                                    filter.push(fileData);
                                    filter.end();
                                }
                                catch ( er )
                                {
                                    reject( 'Is not a valid image file with the name : ' + name );
                                    return ;
                                }

                                let tmp = arrayConcat(filter.output);

                                // cut off last 2 bytes (EOI, 0xFFD9), they are always added by filterJpeg on end
                                jpegHeader = tmp.subarray(0, tmp.length - 2);
                            }

                            image.src = window.URL.createObjectURL(file);
                        }
                    }

                    reader.readAsDataURL(file);
                }
                else
                {
                    reject('Is not a valid file with the mime-type: ', type);
                }
            }
            else
            {
                reject('The file reference not must be null or undefined.');
            }
        } , 0 ) ;
    }) ;
};

export default resizeImageFile ;