import React from 'react'

import leading from 'vegas-js-core/src/numbers/leading'
import format  from 'vegas-js-core/src/strings/format'

import { Typography } from '@material-ui/core'

import api from '../../../../../../../configs/api'

import Form from '../../../../../../../display/forms/FormElements'

import Method from "../../../../../../../net/Method"

import Disease from '../../../../../../../things/medical/Disease'
import Thing   from '../../../../../../../things/Thing'
import Word    from '../../../../../../../things/Word'

const common =
[
    {
        type : Form.SWITCH ,
        init :
        {
            id    : 'confirmed',
            key   : 'confirmed',
            label : 'Confirmation de l\'analyse ?'
        }
    },
    {
        type : Form.I18N_EDITOR ,
        init :
        {
            id    : 'description',
            key   : 'description',
            input : false,
            label : 'Description'
        }
    }
];

const elements =
[
    {
        type       : Form.SEARCH_SELECTOR ,
        clazz      : Disease,
        clearLabel : 'Vider' ,
        closeLabel : 'Fermer' ,
        init       :
        {
            id         : 'disease',
            key        : 'disease',
            label      : 'Maladie',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            required   : true,
            type       : 'search',
            variant    : 'outlined'
        },
        path : ( props ) =>
        {
            const { rel } = props ;
            if( rel instanceof Thing )
            {
                const { subjectOf:event } = rel ;
                if( event instanceof Thing )
                {
                    const { subjectOf:review } = event ;
                    if( review )
                    {
                        const { subjectOf:observation } = review ;
                        if( observation )
                        {
                            const { additionalType } = observation ;
                            if( additionalType )
                            {
                                const { id } = additionalType;
                                if( id )
                                {
                                    return format(api.settings.diseasesByObservationsTypes,id) + '?skin=full' ;
                                }
                            }
                        }
                    }
                }
            }
            return null;
        },
        iconifiable : false ,
        logics :
        {
            change : ( props /*, state */ ) =>
            {
                const { rel } = props ;
                if( rel )
                {
                    rel.method   = null ; // FIXME
                    rel.sampling = null ; // FIXME
                }
            },
            disabledSuggestions : ( props ) =>
            {
                const { rel } = props ;
                if( rel )
                {
                    const { disease } = rel ;
                    if( disease instanceof Word)
                    {
                        return [ disease ] ;
                    }
                }
                return null ;
            }
        },
        searchable  : false,
        title       : 'Sélectionner une maladie'
    },
    ...common
];

const analyses =
{
    add :
    {
        elements,
        agree       : 'Créer' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour créer une nouvelle analyse.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...' ,
        method      : Method.PATCH,
        mock        : false,
        success     : 'Ajout réussi!' ,
        title       : 'Ajouter une analyse'
    },
    edit :
    {
        elements,
        agree       : 'Sauver' ,
        disagree    : 'Annuler' ,
        description : 'Modifier cette les informations principales de l\'analyse.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...'  ,
        method      : Method.PATCH,
        mock        : false ,
        success     : 'Mise à jour réussie!' ,
        title       : 'Modifier l\'analyse'
    },
    labels :
    {
        card : 'Analyse'
    },
    remove :
    {
        agree       : 'Supprimer' ,
        description : 'Souhaitez-vous supprimer cette analyse ?' ,
        disagree    : 'Annuler' ,
        iconifiable : false ,
        item        :
        {
            render : ( item , props ) =>
            {
                const { locale } = props ;
                if( locale )
                {
                    const { labels } = locale ;
                    if( labels )
                    {
                        if( item )
                        {
                            const { id, subjectOf:event } = item ;
                            if( event )
                            {
                                const { analysis } = event ;
                                if( analysis instanceof Array )
                                {
                                    let index = analysis.findIndex( element => element.id === id ) ;
                                    if( index > -1 )
                                    {
                                        return (
                                        <Typography
                                            className = 'w-full font-semibold'
                                            variant   = 'body1'
                                        >
                                            { format( labels.analysis , leading( index+1 ) ) }
                                        </Typography>) ;
                                    }
                                }
                            }
                        }
                    }
                }
                return null ;
            }
        },
        labels :
        {
            analysis : 'Analyse médicale : {0}'
        },
        loading : 'Suppression en cours...' ,
        method  : Method.PATCH ,
        mock    : false ,
        title   : 'Supprimer l\'analyse',
        useID   : false
    },
    title     : 'Analyses médicales' ,
    tooltips  :
    {
        add    : "Ajouter" ,
        edit   : "Éditer" ,
        remove : "Supprimer"
    }
};

export default analyses ;
