import initDialog from '../initDialog'

import { RemoveDialog } from '../RemoveDialog'

import api from '../../../configs/api'

const styles = () => ({});

class RemoveLivestockDialog extends RemoveDialog
{
    getLocale = () => this.props.locale.dialogs.application.livestock.remove ;
}

RemoveLivestockDialog.defaultProps =
{
    ...RemoveDialog.defaultProps,
    uri    : api.livestocks.url,
    useUrl : true
};

RemoveLivestockDialog.propTypes =
{
    ...RemoveDialog.propTypes
};

export default initDialog({ styles })( RemoveLivestockDialog );
