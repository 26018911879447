import abortion      from './reviews/abortion'
import abortionSerie from './reviews/abortionSerie'
import annual        from './reviews/annual'
import suspicion     from './reviews/suspicion'

const reviews =
{
    abortion,
    abortionSerie,
    annual,
    suspicion
};

export default reviews ;