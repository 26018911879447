import BusinessIcon from '@material-ui/icons/Business'
import CategoryIcon from '@material-ui/icons/Category'
import CourseIcon   from '@material-ui/icons/Map'
import EventIcon    from '@material-ui/icons/Event'
import NoteIcon     from '@material-ui/icons/Note'
import PersonIcon   from '@material-ui/icons/Person'
import PlaceIcon    from '@material-ui/icons/Place'
import StageIcon    from '@material-ui/icons/Stars'

import React from 'react'

const discover =
{
    actions :
    [
        { id:'course'           , icon: <CourseIcon   /> , title : 'Ajouter un parcours'         },
        { id:'stage'            , icon: <StageIcon    /> , title : 'Ajouter un point d\'intérêt' },
        { id:'event'            , icon: <EventIcon    /> , title : 'Ajouter un Événement'        },
        { id:'conceptualObject' , icon: <CategoryIcon /> , title : 'Ajouter un objet'            },
        { id:'organization'     , icon: <BusinessIcon /> , title : 'Ajouter une organisation'    },
        { id:'person'           , icon: <PersonIcon   /> , title : 'Ajouter une personne'        },
        { id:'article'          , icon: <NoteIcon     /> , title : 'Ajouter un article'          },
        { id:'place'            , icon: <PlaceIcon    /> , title : 'Ajouter un Lieu'             }
    ],
    empty     : 'Aucun élément à découvrir',
    speedDial :
    {
        ariaLabel : "Ajouter un intérêt"
    },
    snacks :
    {
        add    : 'Ajout d\'un nouveau élément réussi' ,
        remove : 'Suppression d\'un élément réussi' ,
    },
    title    : 'À découvrir' ,
    tooltips :
    {
        add    : "Ajouter" ,
        edit   : "Éditer" ,
        remove : "Supprimer"
    }
};

export default discover ;
