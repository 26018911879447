const text =
{
    title    : 'Texte' ,
    tooltips :
    {
        refresh : 'Annuler' ,
        save    : 'Enregister'
    }
};

export default text ;