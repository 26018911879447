
import generify from '../generify'

import Answer   from '../Answer'
import Game     from '../Game'
import Question from '../Question'
import Step     from '../Step'

class QuestionGame extends Game
{
    /**
     * Creates a new QuestionGame instance.
     * @constructor
     * @param object The generic object to initialize the object the first time.
     */
    constructor( object = null )
    {
        super(object);
    }

    get score()
    {
        let result = 0 ;
        const { quest } = this ;
        if( (quest instanceof Array) && quest.length > 0 )
        {
            quest.forEach( item =>
            {
                if( item instanceof Question )
                {
                    let { choices, score = 0 } = item ;
                    result += isNaN( score ) ? 0 : score ;
                    if( choices instanceof Array )
                    {
                        choices.forEach( element =>
                        {
                            if( element instanceof Answer )
                            {
                                let { score = 0 } = element ;
                                result += isNaN( score ) ? 0 : score ;
                            }
                        });
                    }
                }
            });
        }

        return result ;
    }

    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new QuestionGame( this.toObject(true) ) ;
    }

    /**
     * Populate the object with specific behaviors or types. Launched by default in the constructor and in the set methods.
     * @returns {Thing} The current reference.
     */
    populate()
    {
        super.populate() ;

        const {
            about,
            quest
        } = this ;

        if( about && !(about instanceof Step) )
        {
            this.about = new Step(about) ;
        }

        if( quest instanceof Array )
        {
            this.quest = quest.map( item =>
            {
                if( !( item instanceof Question ) )
                {
                    item = new Question( item ) ;
                    item.subjectOf = this ;
                }
                return item ;
            }) ;
        }

        return this ;
    }

    /**
     * Returns the generic object representation of the item.
     * @return {Object} The generic object representation of the item.
     */
    toObject()
    {
        let {
            about,
            quest
        } = this ;

        about = generify(about) ;
        quest = generify(quest,true) ;

        return { ...super.toObject() , ...{
            about,
            quest
        }}
    }
}

export default QuestionGame ;
