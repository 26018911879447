import React from 'react'

import moment from 'moment/moment'

import compose  from 'vegas-js-core/src/functors/compose'
import isString from 'vegas-js-core/src/isString'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import initApp from '../../contexts/app/initApp'

import { Link , withRouter } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'

import { Grid } from '@material-ui/core'

import LabelIcon from '@material-ui/icons/Label'
import PlaceIcon from '@material-ui/icons/Place'
import StageIcon from '@material-ui/icons/Stars'

import LabelItem from '../../components/items/LabelItem'

import ThingContainer from '../containers/ThingContainer'

import Place   from "../../things/Place"
import StageVO from '../../things/Stage'
import ThingVO from '../../things/Thing'
import WordVO  from '../../things/Word'

import api from '../../configs/api'

import ThingAvatar from "../avatars/ThingAvatar"

const styles = () => ({});

const full =
{
    xs : 12
};

const half =
{
    lg : 6,
    md : 6,
    sm : 12,
    xs : 12
};

class Stage extends ThingContainer
{
    editPrepare = item =>
    {
        let {
            location,
            name,
            status
        } = item ;

        return (
        {
            name ,
            location : location instanceof Place ? String(location.id) : null,
            status : status instanceof ThingVO ? String(status.id) : null
        });
    };

    getContent = () =>
    {
        let { lang, thing } = this.props;

        const locale = this.getLocale() ;

        const { labels } = locale ;

        let indicator ;
        let locationIcon = <PlaceIcon className="mr-8"/> ;
        let location ;
        let name ;
        let options ;
        let statusStage ;

        if( thing instanceof StageVO )
        {
            let {
                location : l,
                name     : n,
                status   : s
            } = thing ;

            if( isString( n ) )
            {
                name = n ;
            }

            if( s instanceof WordVO )
            {
                const { color } = s ;
                if( isString( color ) )
                {
                    indicator = (
                        <LabelIcon
                            className = "mr-8 self-center"
                            style     = {{ color : "#" + color }}
                        />);
                }
                statusStage = s.getLocaleName( lang )
            }

            if( l instanceof ThingVO )
            {
                locationIcon = <ThingAvatar thing={l} className="mr-12" /> ;
                options =
                {
                    component : Link ,
                    to        :
                    {
                        pathname : l.url.split(api.url)[1]
                    }
                };
                location = l.getLocaleName( lang ) ;
            }
            else
            {
                location = null ;
            }
        }

        return (
            <Grid container spacing={4}>
                <Grid item {...half}>
                    <LabelItem enabled={!!(name)} title={ labels.name }>
                        { isString(name) ? ucFirst(name) : labels.empty }
                    </LabelItem>
                </Grid>
                <Grid item {...half}>
                    <LabelItem enabled={!!(statusStage)} title={ labels.status } labelIcon={indicator}>
                        { isString(statusStage) ? ucFirst(statusStage) : labels.empty }
                    </LabelItem>
                </Grid>
                <Grid item {...full}>
                    <LabelItem
                        enabled   = {!!(location)}
                        labelIcon = { locationIcon }
                        title     = { labels.location }
                        { ...options }
                    >
                        { isString(location) ? ucFirst(location) : labels.empty }
                    </LabelItem>
                </Grid>
            </Grid>
        );
    };

    getEntry = init =>
    {
        let empty = {} ;
        if( init instanceof ThingVO )
        {
            empty = init.toObject() ;
        }
        else if( init )
        {
            empty = { ...init } ;
        }
        return new StageVO(empty);
    };

    getLocale = () => this.props.locale.things.stage;

    onEdit = ( item ) =>
    {
        const { onChange, thing } = this.props ;
        if( thing && item )
        {
            const {
                status,
                location,
                name
            } = item ;

            thing.location = location ;
            thing.modified = moment(new Date()).toISOString();
            thing.name     = name ;
            thing.status   = status ;

            if( thing instanceof ThingVO )
            {
                thing.populate();
            }

            if( this._mounted )
            {
                this.forceUpdate() ;
            }

            if( onChange instanceof Function )
            {
                onChange(thing) ;
            }

        }
    }
}

Stage.defaultProps =
{
    ...ThingContainer.defaultProps ,
    icon       : <StageIcon/> ,
    optionMode : 'edit'
};

Stage.propTypes =
{
    ...ThingContainer.propTypes
};

export default compose( withStyles( styles ) , withRouter , initApp )( Stage ) ;
