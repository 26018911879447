import React from 'react'

import clsx from 'clsx'

import compose from 'vegas-js-core/src/functors/compose'

import initApp from '../../../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { Grid } from '@material-ui/core'

import GroupWorkIcon from '@material-ui/icons/GroupWork'

import api from '../../../../configs/api'

import ThingContainer from '../../../containers/ThingContainer'

import AbortedEvents     from './abortionSerie/events/AbortedEvents'
import AbortionSerie     from './abortionSerie/AbortionSerie'
import Risk              from './abortionSerie/medical/Risk'
import VaccinationEvents from './abortionSerie/events/VaccinationEvents'

const styles = () => (
{
    root :
    {
        width : '100%'
    }
});

const full =
{
    xs : 12
};

class AbortionSerieReview extends ThingContainer
{
    editUri = ( path, props ) =>
    {
        const { thing } = props ;
        if( thing )
        {
            const { id } = thing ;
            if( id )
            {
                return api.observations.url + '/' + id ;
            }
        }
        return null ;
    };

    getLocale = () => this.props.locale.things.livestock.reviews.abortionSerie ;

    render = () =>
    {
        const {
            classes,
            className,
            style,
            thing
        } = this.props ;

        // console.log( this + " render" , thing ) ;

        let { editUri:uri } = this.props ;

        uri = uri || this.editUri ;

        if( uri instanceof Function )
        {
            uri = uri( null , this.props ) ;
        }

        if( thing )
        {
            const { review } = thing ;

            return (
            <div
                className = { clsx(classes.root, className) }
                style     = { style }
            >
                <Grid container spacing = { 2 } >

                    <Grid item {...full}>
                        <AbortionSerie
                            containerProps = {{ className : "bg-transparent w-full shadow-none p-12 mb-8" }}
                            onChange       = { this.change }
                            thing          = { thing }
                        />
                    </Grid>

                    <Grid item {...full}>
                        <Risk
                            containerProps = {{ className : "bg-transparent w-full shadow-none p-12 mb-8" }}
                            editUri        = { uri }
                            onChange       = { this.change }
                            subjectOf      = { thing  }
                            thing          = { review }
                        />
                    </Grid>

                    <Grid item {...full}>
                        <VaccinationEvents
                            addUri         = { uri }
                            containerProps = {{ className : "bg-transparent w-full shadow-none p-12 mb-16" }}
                            editUri        = { uri }
                            onChange       = { this.change }
                            path           = { uri }
                            removeUri      = { uri }
                            subjectOf      = { thing }
                            thing          = { review }
                        />
                    </Grid>

                    <Grid item {...full}>
                        <AbortedEvents
                            addUri               = { uri }
                            cardBottomPaperProps = {{ className : "bg-transparent w-full p-12 flex items-center justify-center" , elevation:0 }}
                            cardPaperProps       = {{ className : "bg-transparent w-full p-12" }}
                            containerProps       = {{ className : "bg-transparent w-full shadow-none py-8" }}
                            localePath           = 'things.livestock.reviews.abortionSerie.events.abortionEvents'
                            editUri              = { uri }
                            member               = 'aborted'
                            onChange             = { this.change }
                            path                 = { uri }
                            removeUri            = { uri }
                            subjectOf            = { thing }
                            thing                = { review }
                        />
                    </Grid>

                    <Grid item {...full}>
                        <AbortedEvents
                            addUri               = { uri }
                            cardBottomPaperProps = {{ className : "bg-transparent w-full p-12 flex items-center justify-center" , elevation : 0 }}
                            cardPaperProps       = {{ className : "bg-transparent w-full p-12" }}
                            containerProps       = {{ className : "bg-transparent w-full shadow-none py-8" }}
                            editUri              = { uri }
                            icon                 = { <GroupWorkIcon /> }
                            localePath           = 'things.livestock.reviews.abortionSerie.events.reproductiveDisorderEvents'
                            member               = 'reproductiveDisorders'
                            onChange             = { this.change }
                            path                 = { uri }
                            removeUri            = { uri }
                            subjectOf            = { thing }
                            thing                = { review }
                        />
                    </Grid>

                </Grid>

            </div>) ;
        }

        return null  ;
    }
}

AbortionSerieReview.defaultProps =
{
    ...ThingContainer.defaultProps
};

AbortionSerieReview.propTypes =
{
    ...ThingContainer.propTypes
};

export default compose( withStyles( styles ) , withRouter , initApp )( AbortionSerieReview ) ;
