
const defaultOptions = {
    alt     : null ,
    delay   : 0 ,
    finish  : null ,
    loading : 'eager' ,
    src     : null ,
    srcSet  : null ,
    sizes   : null ,
    start   : null ,
}

const loadImage = ( init , decode = false ) =>
{
    init = { ...defaultOptions , ...init } ;
    return new Promise( ( resolve, reject ) =>
    {
        const { delay , ...options } = init ;
        setTimeout( () =>
        {
            const {
                alt,
                finish,
                height,
                loading,
                sizes ,
                src,
                srcSet,
                start ,
                width
            }
            = options ;

            if( start instanceof Function )
            {
                start( src ) ;
            }

            const image = new Image();
            if ( srcSet )
            {
                image.srcset = srcSet;
            }

            if( loading )
            {
                image.loading = loading ;
            }

            if ( alt )
            {
                image.alt = alt;
            }

            if ( sizes )
            {
                image.sizes = sizes;
            }

            if( height >=0 )
            {
                image.height = height ;
            }

            if( width >=0 )
            {
                image.width = width ;
            }

            image.src = src;

            if ( decode && 'decode' in image )
            {
                return image.decode()
                .then( image => resolve( image ) )
                .catch( error => reject( error ) );
            }

            image.onload = () =>
            {
                if( finish instanceof Function )
                {
                    finish( src ) ;
                }
                resolve( image );
            }

            image.onerror = reject;

        } , delay ) ;
    }) ;
}

export default loadImage ;