import React from 'react'

import PropTypes from 'prop-types'

import clsx from 'clsx'

import format   from 'vegas-js-core/src/strings/fastformat'
import isNumber from 'vegas-js-core/src/isNumber'

import { Link } from 'react-router-dom'

import { Avatar } from '@material-ui/core'

import api from '../../configs/api'

import ThingComponent from '../things/ThingComponent'

export class Counter extends ThingComponent
{
    render = () =>
    {
        const thing = this.getThing();
        if ( thing )
        {
            const {
                className,
                clickable,
                disabled,
                member,
                icon,
                linkable,
                onClick,
                path
            } = this.props;

            const locale = this.getLocale();
            const { title, titleX } = locale;

            let { [member]:element = {} , url } = thing ;

            let num = 0 ;
            if( isNumber(element) && element >= 0 )
            {
                num = element ;
            }
            else if( element instanceof Array )
            {
                num = element.length ;
            }

            const text = num > 1 ? titleX : title ;

            const { avatarSetting : { empty = "bg-gray-500" , filled = "bg-blue-600" } = {} } = this.props;

            return (
                <div className={clsx("flex flex-row items-center", className)} >
                    { icon }
                    <div className="pr-8">{ this.getTitle( { text } ) }</div>
                    <Avatar
                        variant   = "rounded"
                        { ...((clickable && onClick) && { onClick }) }
                        className = {
                            clsx('w-24 h-24 rounded text-xl cursor-pointer' ,
                            num > 0 ? filled : empty
                        )}
                        {
                            ...( (linkable && !disabled) && {
                                component : Link ,
                                to        : ( format( path, url.split(api.url)[1] ) )
                            })
                        }
                    >
                        <span className="text-white">{ num }</span>
                    </Avatar>
                </div>
            );
        }
        return null;
    }
}

Counter.defaultProps =
{
    ...ThingComponent.defaultProps,
    clickable : true ,
    disabled  : false ,
    icon      : null,
    linkable  : true ,
    member    : null ,
    onClick   : null ,
    path      : '{0}'
}

Counter.propTypes =
{
    ...ThingComponent.propTypes,
    avatarSetting : PropTypes.shape({ empty:PropTypes.string , filled:PropTypes.string }) ,
    clickable     : PropTypes.bool,
    disabled      : PropTypes.bool,
    linkable      : PropTypes.bool,
    member        : PropTypes.string.isRequired ,
    onClick       : PropTypes.func ,
    path          : PropTypes.string
}

export default Counter ;
