import initDialog from '../initDialog'

import { SelectThingDialog , styles } from '../SelectThingDialog'

import api from '../../../configs/api'

import Event from '../../../things/Event'

class SelectEventDialog extends SelectThingDialog
{
    getLocale = () => this.props.locale.dialogs.select.event ;
}

SelectEventDialog.defaultProps =
{
    ...SelectThingDialog.defaultProps,
    clazz : Event ,
    uri   : api.events.url
};

SelectEventDialog.propTypes =
{
    ...SelectThingDialog.propTypes,
};

export default initDialog({ styles , useSearch:false })( SelectEventDialog ) ;
