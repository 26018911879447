import React from 'react'

import moment from 'moment/moment'

import compose  from 'vegas-js-core/src/functors/compose'
import isString from 'vegas-js-core/src/isString'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import initApp from '../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { Grid } from '@material-ui/core'

import PagesIcon  from '@material-ui/icons/Pages'

import LabelItem from "../../components/items/LabelItem"

import ThingContainer from '../containers/ThingContainer'

import Thing      from '../../things/Thing'
import WorkshopVO from '../../things/Workshop'

const styles = () => ({});

const half =
{
    md : 4,
    sm : 6,
    xs : 12
};

class Workshop extends ThingContainer
{
    editPrepare = ( item ) =>
    {
        const {
            breeding,
            name,
            identifier,
            production,
            water
        } = item ;

        return (
        {
            name       : name ,
            breeding   : breeding   instanceof Thing ? String(breeding.id)   : null ,
            identifier : identifier instanceof Thing ? String(identifier.id) : null ,
            production : production instanceof Thing ? String(production.id) : null ,
            water      : water      instanceof Thing ? String(water.id)      : null ,
        });
    };

    getContent = () =>
    {
        let { lang, thing } = this.props;

        const locale = this.getLocale() ;

        const { labels } = locale ;

        let breeding;
        let identifier;
        let name ;
        let production;
        let water;

        if( thing instanceof WorkshopVO )
        {
            let {
                breeding   : b ,
                identifier : i ,
                name       : n ,
                production : p ,
                water      : w
            } = thing ;

            if( isString( n ) )
            {
                name = n ;
            }

            if( b instanceof Thing )
            {
                breeding = b.getLocaleName( lang ) ;
            }

            if( i instanceof Thing )
            {
                identifier = i.getLocaleName( lang ) ;
            }

            if( p instanceof Thing )
            {
                production = p.getLocaleName( lang ) ;
            }

            if( w instanceof Thing )
            {
                water = w.getLocaleName( lang ) ;
            }
        }

        return (
            <Grid container spacing={4}>
                <Grid item {...half}>
                    <LabelItem enabled={!!(name)} title={ labels.name }>
                        { isString(name) ? ucFirst(name) : labels.empty }
                    </LabelItem>
                </Grid>
                <Grid item {...half}>
                    <LabelItem enabled={!!(identifier)} title={ labels.identifier }>
                        { isString(identifier) ? ucFirst(identifier) : labels.empty }
                    </LabelItem>
                </Grid>
                <Grid item {...half}>
                    <LabelItem enabled={!!(breeding)} title={ labels.breeding }>
                        { isString(breeding) ? ucFirst(breeding) : labels.empty }
                    </LabelItem>
                </Grid>
                <Grid item {...half}>
                    <LabelItem enabled={!!(production)} title={ labels.production }>
                        { isString(production) ? ucFirst(production) : labels.empty }
                    </LabelItem>
                </Grid>
                <Grid item {...half}>
                    <LabelItem enabled={!!(water)} title={ labels.water }>
                        { isString(water) ? ucFirst(water) : labels.empty }
                    </LabelItem>
                </Grid>
            </Grid>
        );
    };

    getEntry = init =>
    {
        let thing ;
        if( init instanceof Thing )
        {
            thing = init.clone() ;
        }
        return thing ;
    };

    getLocale = () => this.props.locale.things.workshop;

    onEdit = item =>
    {
        const { thing } = this.props ;
        if( thing && item )
        {
            const {
                breeding ,
                identifier ,
                name ,
                production ,
                water
            } = item ;

            thing.breeding   = breeding ;
            thing.identifier = identifier ;
            thing.modified   = moment(new Date()).toISOString() ;
            thing.name       = name ;
            thing.production = production ;
            thing.water      = water ;

            if( thing instanceof Thing )
            {
                thing.populate();
            }

            if( this._mounted )
            {
                this.forceUpdate();
            }

            const { onChange } = this.props ;
            if( onChange )
            {
                onChange( thing ) ;
            }
        }
    };
}

Workshop.defaultProps =
{
    ...ThingContainer.defaultProps ,
    icon       : <PagesIcon/> ,
    optionMode : 'edit'
};

Workshop.propTypes =
{
    ...ThingContainer.propTypes
};

export default compose( withStyles( styles ) , withRouter , initApp )( Workshop ) ;
