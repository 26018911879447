import initDialog from '../initDialog'

import { AddDialog } from '../AddDialog'

import Person from "../../../things/Person"

import api from '../../../configs/api'

const styles = () => ({});

export class AddPersonDialog extends AddDialog
{
    getLocale = () => this.props.locale.dialogs.application.person.add ;

    prepare = item =>
    {
        if( item instanceof Person)
        {
            const {
                name ,
            } = item ;

            return (
            {
                name ,
            });
        }
        return null ;
    };
}

AddPersonDialog.defaultProps =
{
    ...AddDialog.defaultProps,
    uri : api.people.url
};

AddPersonDialog.propTypes =
{
    ...AddDialog.propTypes
};

export default initDialog({ styles })( AddPersonDialog ) ;
