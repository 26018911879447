import authorities from './livestock/authorities'

const livestock =
{
    // dependencies

    authorities,

    // settings
    
    helmet :
    {
        title : 'Élevage - {0}' ,
        metas : []
    },
    loading  : "Chargement ..." ,
    notFound : "Élevage inconnu" ,
    subtitle : 'Élevage' ,
    tabs     :
    {
        about        : 'À propos',
        workshops    : 'Ateliers',
        observations : 'Observations',
        authorities  : 'Référents',
        numbers      : 'Indexation',
    },
    tooltips :
    {
        back : "Tous les élevages"
    }
};

export default livestock ;