const about =
{
    alternateName :
    {
        caption  : 'Dénomination commerciale de l\'organisation.',
        title    : 'Titre' ,
        tooltips :
        {
            refresh : 'Annuler' ,
            save    : 'Enregister'
        }
    },
    description :
    {
        caption  : 'Un résumé des activités de l\'organisation.',
        title    : 'Description' ,
        tooltips :
        {
            refresh : 'Annuler' ,
            save    : 'Enregister'
        }
    },
    notes :
    {
        caption  : 'Informations complémentaires et remarques. ',
        title    : 'Notes' ,
        tooltips :
        {
            refresh : 'Annuler' ,
            save    : 'Enregister'
        }
    },
    slogan :
    {
        caption  : 'Slogan de l\'organisation.',
        title    : 'Slogan' ,
        tooltips :
        {
            refresh : 'Annuler' ,
            save    : 'Enregister'
        }
    },
    text :
    {
        caption  : 'Texte principal de l\'organisation.',
        title    : 'Texte' ,
        tooltips :
        {
            refresh : 'Annuler' ,
            save    : 'Enregister'
        }
    }
};

export default about ;
