import React from 'react';

import clsx from 'clsx'

import { withRouter } from 'react-router-dom'

import format from 'vegas-js-core/src/strings/fastformat'

import ThingSubContainer from '../../../display/containers/ThingSubContainer'

import Metadatas   from '../../../display/things/Metadatas'
import HasPart     from '../../../display/things/article/HasPart'
import IsPartOf    from '../../../display/things/article/IsPartOf'
import IsRelatedTo from '../../../display/things/article/IsRelatedTo'
import IsSimilarTo from '../../../display/things/article/IsSimilarTo'

import RemoveArticleDialog from '../../../display/dialogs/remove/RemoveArticleDialog'

class More extends ThingSubContainer
{
    render = () =>
    {
        const { thing } = this.props ;
        if( thing )
        {
            const { id } = thing ;

            const {
                className,
                path,
                onRemove,
                style
            } = this.props ;

            let view = (
            <div className="flex-1 flex flex-col space-y-24">
                <Metadatas
                    activable             = { true }
                    deletable             = { true }
                    RemoveDialogComponent = { RemoveArticleDialog }
                    onRemove              = { onRemove }
                    thing                 = { thing }
                    uri                   = { format(path.article,id) }
                />

                <IsRelatedTo
                    onChange = { this.change }
                    thing    = { thing }
                    uri      = { path.isRelatedTo }
                />

                <IsSimilarTo
                    onChange = { this.change }
                    thing    = { thing }
                    uri      = { path.isSimilarTo }
                />

                <div className='grid grid-cols-1 sm:grid-cols-2 gap-16'>
                    <div>
                        <HasPart
                            onChange = { this.change }
                            thing    = { thing }
                            uri      = { path.hasPart }
                        />
                    </div>
                    <div>
                        <IsPartOf
                            onChange = { this.change }
                            thing    = { thing }
                            uri      = { path.isPartOf }
                        />
                    </div>
                </div>
            </div>);

            return (
            <div
                className = { clsx( 'flex-1 p-12' , className ) }
                style     = { style }
            >
                { this.renderWithTransition(view) }
            </div>
            );
        }
        return null ;
    }
}

More.defaultProps =
{
    ...ThingSubContainer.defaultProps
};

More.propTypes =
{
    ...ThingSubContainer.propTypes
};

export default withRouter( More ) ;
