import React from 'react'

import Signal from 'vegas-js-signals/src/Signal'

import notEmpty from 'vegas-js-core/src/strings/notEmpty'

import PropTypes from 'prop-types'

import UserContext from './UserContext'

import defaultProperties from './defaultProperties'

import readUser from './readUser'

import api from '../../configs/api'

import Container from '../../components/containers/Container'

import DELETE        from '../../net/DELETE'
import RequestStatus from '../../net/RequestStatus'
import POST          from '../../net/POST'

import Thing from '../../things/Thing'
import User  from '../../vo/User'

class UserProvider extends Container
{
    constructor( props )
    {
        super( props );
        const { user } = props ;
        this.canceler  = null ;
        this.change    = new Signal() ;
        this.state     =
        {
            ...this.state,

            ...defaultProperties,
            
            user,

            isFavorite     : this.isFavorite,
            toggleFavorite : this.toggleFavorite ,
            
            changeUser : this.changeUser,
            readUser   : this.read,
            setUser    : this.set,
            writeUser  : this.write
        };
    }
    
    changeUser = () => this.change ;
    
    componentDidUpdate( prevProps )
    {
        const { user } = this.props ;
        if( prevProps.user !== user )
        {
            this.setState({ user });
        }
    }

    dispose = () =>
    {
        const { user } = this.state ;
        if( user && sessionStorage.getItem( user.storageID ) !== null )
        {
            sessionStorage.removeItem( user.storageID ) ;
        }
    };

    getLocale = () => this.props.locale.contexts.user ;

    isFavorite = thing =>
    {
        const { user } = this.state ;
        if( user && thing instanceof Thing )
        {
            const { favorites } = user ;
            if( favorites instanceof Array && favorites.length > 0 )
            {
                const find = favorites.find( item => item.url === thing.url ) ;
                return !!(find) ;
            }
        }
        return false ;
    };

    read = () => readUser( this.state.user ) ;
    
    render = () =>
    {
        const {
            favoriteStatus,
            user,
            isFavorite,
            toggleFavorite,
            changeUser,
            readUser,
            setUser,
            writeUser

        } = this.state;
        return (
            <UserContext.Provider
                value = {{
                    favoriteStatus,
                    user,
                    isFavorite,
                    toggleFavorite,
                    changeUser,
                    readUser,
                    setUser,
                    writeUser
                }}
            >
                { this.props.children }
            </UserContext.Provider>
        );
    }
    
    set = user =>
    {
        this.setState({ user });
    };

    toggleFavorite = ( thing , callback = null ) =>
    {
        if( thing instanceof Thing )
        {
            const { url } = thing ;
            const {
                apiUrl ,
                favoriteUri
            } = this.props ;

            const flag = this.isFavorite( thing ) ;

            const METHOD = flag ? DELETE : POST ;

            let next ;
            if( callback instanceof Function )
            {
                next = () =>
                {
                    callback( { thing , status: flag ? 'remove' : 'add' } );
                };
            }

            this.setState({ favoriteStatus: RequestStatus.PROGRESS }) ;

            this.canceler = METHOD( apiUrl + favoriteUri ,
            {
                datas : { url } ,
                cancel  : this._favoriteCancel ,
                fail    : this._favoriteFail ,
                success : this._favoriteSuccess( next )
            });
        }
    };

    unmount = () =>
    {
        this.dispose() ;

        const { status } = this.state ;
        if( status === RequestStatus.PROGRESS && !!(this.canceler) )
        {
            this.canceler.cancel( this + ' cancel' );
        }
    }

    write = ( init , callback = null ) =>
    {
        let { user } = this.state ;
        if( user )
        {
            if( init )
            {
                if( init instanceof Thing )
                {
                    init = init.toObject() ;
                }
                user.set( init ) ;
            }

            // console.log( this + ' write user:' , user ) ;
            
            const { storageID } = user ;
            
            if( notEmpty(storageID) )
            {
                sessionStorage.setItem( user.storageID , JSON.stringify( user.toObject() ) ) ;
            }
            else
            {
                console.log( this + ' write failed, the user storage identifier must me a string and not must be null.');
            }
            
            this.setState( { user } , () =>
            {
                this.change.emit() ;
                if( callback instanceof Function )
                {
                    callback() ;
                }
            }) ;
        }
        else if( callback instanceof Function )
        {
            callback() ;
        }
    };

    // ----------- favorites

    _favoriteCancel = () =>
    {
        this.setState( { favoriteStatus:RequestStatus.CANCEL } ) ;
    };

    _favoriteFail = response =>
    {
        if( response )
        {
            const {
                data,
                message,
                status
            }
            = response ;

            if( data )
            {
                if( message === 'token revoked' )
                {
                    if( this.mounted )
                    {
                        this.setState({ favoriteStatus:RequestStatus.REVOKED });
                    }
                }
                else
                {
                    console.log( this + " _favoriteFail, status:" + status + ", message:" + message );
                }
            }
        }
        if( this._mounted )
        {
            this.setState({ favoriteStatus:RequestStatus.FAIL });
        }
    };

    _favoriteSuccess = next => response =>
    {
        if( response && this._mounted )
        {
            const { data } = response ;
            if( data )
            {
                const { result:favorites } = data ;

                // console.log( this + ' _favoriteSuccess favorites:' , favorites ) ;

                this.write( { favorites } , () =>
                {
                    this.setState( { favoriteStatus:RequestStatus.SUCCESS } , next );
                })
                return ;
            }
        }
        this.setState({ status:RequestStatus.FAIL });
    };
}

UserProvider.defaultProps =
{
    ...Container.defaultProps,
    apiUrl      : api.url,
    favoriteUri : api.me.favorites ,
    notifiable  : true,
    user        : null
};

UserProvider.propTypes =
{
    ...Container.propTypes,
    apiUrl      : PropTypes.string.isRequired,
    favoriteUri : PropTypes.string.isRequired,
    user        : PropTypes.instanceOf(User)
};

export default UserProvider ;