import about    from './place/about'
import photos   from '../things/photos'

const place =
{
    about,
    photos,

    helmet :
    {
        title : 'Lieu - {0}' ,
        metas : []
    },
    loading  : "Chargement ..." ,
    notFound : "Lieu inconnu" ,
    tabs :
    {
        about             : 'À propos',
        info              : 'Infos',
        audios            : 'Audios' ,
        conceptualObjects : 'Objets',
        events            : 'Événements',
        medias            : 'Médias' ,
        photos            : 'Photos' ,
        videos            : 'Vidéos'
    },
    tooltips :
    {
        back : 'Tous les lieux'
    },
    map :
    {
        attribution : process.env.REACT_APP_MAP_ATTRIBUTION
    }
};

export default place ;
