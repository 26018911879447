import React from 'react'

import clean from 'vegas-js-core/src/objects/clean'

import withDialogs from '../../../../../../contexts/dialogs/withDialogs'
import withi18n    from '../../../../../../contexts/i18n/withi18n'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import blueGrey from '@material-ui/core/colors/blueGrey'

import { FaBriefcaseMedical } from 'react-icons/fa'

import ChipsContainer, { styles } from '../../../../../containers/ChipsContainer'

import generify from '../../../../../../things/generify'

import Thing           from '../../../../../../things/Thing'
import Word            from '../../../../../../things/Word'
import SuspicionReview from '../../../../../../things/livestock/reviews/SuspicionReview'

const css = () => (
{
    ...styles,
    chip :
    {
        backgroundColor : blueGrey[100],
        margin          : 8
    }
});

class Causes extends ChipsContainer
{
    getEntry = init =>
    {
        const { subjectOf } = this.props ;

        let empty = {} ;

        if( init instanceof Thing )
        {
            empty = init.toObject() ;
        }
        else if( init )
        {
            empty = { ...init } ;
        }

        return new SuspicionReview( { ...empty , subjectOf } ) ;
    };

    getLocale = () => this.props.locale.things.livestock.reviews.suspicion.medical.causes ;

    getThing = () =>
    {
        const { thing } = this.props ;
        if( thing )
        {
            const { hypothesis } = thing ;
            return hypothesis ;
        }
        return null ;
    };

    editInit = ( item ) =>
    {
        if( item )
        {
            const { hypothesis } = item ;
            if( hypothesis )
            {
                const { causes } = hypothesis ;
                if( causes instanceof Array )
                {
                    return causes.map( element =>
                    {
                        if( element instanceof Word )
                        {
                            return element.clone() ;
                        }
                        return null ;
                    })
                }
            }
        }
        return null ;
    };

    onEdit = ( item ) =>
    {
        const { onChange } = this.props ;
        if( onChange )
        {
            onChange( item ) ;
        }
    };

    editPrepare = ( datas , props ) =>
    {
        let { item:review } = props ;
        if( review instanceof SuspicionReview )
        {
            review = clean(review.toObject()) ;

            const { hypothesis } = review ;

            if( hypothesis )
            {
                if( datas )
                {
                    if( datas instanceof Array && datas.length === 0 )
                    {
                        datas = null ;
                    }
                    datas = generify(datas,true) ;
                }
                hypothesis.causes = datas ;
            }
            review = JSON.stringify(review) ;
            return { review } ;
        }
        return null ;
    };
}

Causes.defaultProps =
{
    ...ChipsContainer.defaultProps ,
    chipVariant : 'default' ,
    icon        : <FaBriefcaseMedical size={18}/>,
    member      : 'causes'
};

Causes.propTypes =
{
    ...ChipsContainer.propTypes
};

export default withStyles( css ) ( withRouter( withi18n( withDialogs(Causes ) ) ) ) ;
