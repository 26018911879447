//import clean from 'vegas-js-core/src/objects/clean'

import generify from '../../generify'

import AmbienceReview     from './AmbienceReview'
import AnimalHealthReview from './AnimalHealthReview'
import Sector             from '../../Sector'
import SectorHealthEvent  from '../events/SectorHealthEvent'
import Word               from '../../Word'

/**
 * A sector review.
 * @memberOf things.livestock.reviews
 * @extends things.livestock.AnimalHealthReview
 */
class SectorReview extends AnimalHealthReview
{
    /**
     * Creates a new SectorReview instance.
     * @constructor
     * @param object The generic object to initialize the object.
     */
    constructor( object = null )
    {
        super() ;
        
        /**
         * The ambience review of the sector.
         * @type {AmbienceReview}
         */
        this.ambience = null ;
        
        /**
         * The list of all SectorHealthEvent of this sector.
         * @type {Array}
         */
        this.events = null ;
        
        /**
         * The list of all preventions of this sector.
         * @type {Array}
         */
        this.preventions = null ;
        
        this.set( object ) ;
    }

    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        super.clear() ;
        
        this.ambience    =
        this.events      =
        this.preventions = null ;
        
        return this ;
    }

    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new SectorReview( this.toObject() ) ;
    }
    
    /**
     * Populate the object with specific behaviors or types. Launched by default in the constructor and in the set methods.
     * @returns {Thing} The current reference.
     */
    populate()
    {
        let {
            about,
            ambience,
            events,
            preventions,
            subjectOf
        } = this ;
        
        if( about && !(about instanceof Sector) )
        {
            this.about = new Sector(about);
        }
        
        if( !(ambience instanceof AmbienceReview) )
        {
            this.ambience = new AmbienceReview( { ...ambience , about:this } );
        }
        
        if( events instanceof Array )
        {
            this.events = events.map(
                item => item instanceof SectorHealthEvent
                      ? item
                      : new SectorHealthEvent( { ...item , about, subjectOf } )
            ) ;
        }
        
        if( preventions instanceof Array )
        {
            this.preventions = preventions.map(
                item => item instanceof Word
                      ? item
                      : new Word(item)
            ) ;
        }
        
        return this ;
    }
    /**
     * Returns the generic object representation of the Object.
     * @return {Object} The generic object representation of the Object.
     */
    toObject()
    {
        let {
            about,
            ambience,
            events,
            preventions
        } = this ;

        if( about )
        {
            let { id , url, additionalType } = about ;
            if( additionalType )
            {
                additionalType = generify(additionalType) ;
            }
            about = { id , url, additionalType } ;
        }
        else
        {
            about = null ;
        }

        ambience    = generify(ambience) ;
        events      = generify(events) ;
        preventions = generify(preventions) ;

        return {
            about,
            ambience,
            events,
            preventions,
            
        };
    }
}

export default SectorReview ;