import { withStyles } from '@material-ui/core/styles'

import { Slider } from '@material-ui/core'

const styles = () =>
({
    // active : {},
    // rail   :
    // {
    //     height: 8,
    //     borderRadius: 4,
    // },
    // root :
    // {
    //     color  : theme.palette.secondary.main,
    //     height : 8
    // },
    // track:
    // {
    //     height       : 8,
    //     borderRadius : 4
    // },
    // thumb:
    // {
    //     backgroundColor : '#fff',
    //     height          : 24,
    //     width           : 24,
    //     border          : '2px solid currentColor',
    //     marginTop       : -8,
    //     marginLeft      : -12,
    //     '&:focus, &:hover, &$active' :
    //     {
    //         boxShadow : 'inherit',
    //     },
    // },
    // valueLabel :
    // {
    //     left: 'calc(-50% + 4px)',
    // }
})

export default withStyles( styles )( Slider ) ;
