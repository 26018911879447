export const set = config =>
{
    let routes = [...config.routes];

    if ( config.settings || config.auth )
    {
        routes = routes.map( route =>
        {
            let auth = config.auth ? [...config.auth] : [] ;
            auth = route.auth ? [...auth, ...route.auth] : auth;
            return {
                ...route,
                settings: {...config.settings, ...route.settings},
                auth
            };
        });
    }

    return [...routes];
};

const generate = configs =>
{
    let allRoutes = [];
    configs.forEach( (config) =>
    {
        allRoutes = [ ...allRoutes , ...set(config) ]
    });
    return allRoutes;
};

export default generate ;
