import api from '../../configs/api'

import ImageObject from '../../things/creativework/media/ImageObject'

import { MediaObjectsList } from './MediaObjectsList'

import withDatasList from './withDatasList'

class PhotosList extends MediaObjectsList
{
    add = () => this.openSelectMediaDialog() ;

    getLocale = () => this.props.locale.display.lists.photos ;

    getOptions = () => this.getAddButton() ;
}

PhotosList.defaultProps =
{
    ...MediaObjectsList.defaultProps,
    addable          : true ,
    clazz            : ImageObject,
    deletable        : true ,
    member           : 'photos' ,
    optionsVariant   : 'header',
    orderable        : true ,
    orderMock        : false ,
    removeProps      : { mock:false , useUrl:false } ,
    selectMediaProps : ( { props : { config : { acceptedUploadMedias : { images:acceptedFiles } = {} } = {} } = {} } = {} ) => (
    {
        disableEscapeKeyDown : true ,
        facets               : { encodingFormat : 'image' } ,
        multiple             : true,
        uploadable           : true,
        uploadMultiple       : true,
        uploadProps          : { acceptedFiles },
        uploadUri            : api.mediaObjects.url
    }),
    selectMediaUri : api.mediaObjects.url ,
    searchPolicy   : 'none' ,
    sortable       : false
};

PhotosList.propTypes =
{
    ...MediaObjectsList.propTypes
};

export default withDatasList()( PhotosList ) ;