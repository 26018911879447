import React from 'react'

import compose  from 'vegas-js-core/src/functors/compose'

import initApp from '../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { Chip } from '@material-ui/core'

import blueGrey from '@material-ui/core/colors/blueGrey'

import ExtensionIcon from '@material-ui/icons/Extension'

import ThingContainer from '../containers/ThingContainer'

import Place   from '../../things/Place'
import ThingVO from '../../things/Thing'
import Word    from '../../things/Word'

const styles = theme => (
{
    chip :
    {
        borderColor : blueGrey[100],
        margin      : theme.spacing(0.5)
    }
});

class Services extends ThingContainer
{
    getBadgeLabel = () =>
    {
        const { thing } = this.props ;
        if( thing )
        {
            const { services } = thing  ;
            if( (services instanceof Array) )
            {
                return services.length ;
            }
        }
        return 0 ;
    };

    getContent = () =>
    {
        const { classes, lang, thing } = this.props ;
        if( thing )
        {
            let { services } = thing ;

            if( services instanceof Array && services.length > 0 )
            {
                let css = { paddingLeft:8,marginRight:0, height:24, width:24 } ;

                services = services.map( ( item , index ) =>
                {
                    if( item instanceof Word )
                    {
                        const label = item.getLocaleName( lang );
                        const icon  = this.getImage(item,css, { variant : 'avatar' } );
                        return (
                        <Chip
                            className = { classes.chip }
                            clickable = { false }
                            key       = { 'service_' + index }
                            icon      = { icon }
                            label     = { label }
                            variant   = 'outlined'
                        />);
                    }
                    return null ;
                });

                return (
                    <div className="flex flex-row flex-wrap">
                        { services }
                    </div>
                )
            }
        }
        return null ;
    };

    getEntry = init =>
    {
        let empty = {} ;
        if( init instanceof ThingVO )
        {
            empty = init.toObject() ;
        }
        else if( init )
        {
            empty = { ...init } ;
        }
        return new Place(empty) ;
    };

    getLocale = () => this.props.locale.things.services ;
}

Services.defaultProps =
{
    ...ThingContainer.defaultProps ,
    badge      : true ,
    icon       : <ExtensionIcon/>,
    optionMode : 'edit'
};

Services.propTypes =
{
    ...ThingContainer.propTypes
};

export default compose( withStyles( styles ) , withRouter , initApp )( Services ) ;
