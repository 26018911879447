
const metadatas =
{
    labels :
    {
        active    : 'Actif',
        inactive  : 'Inactif'
    },
    pattern : 'L [à] LTS',
    remove :
    {
        agree       : 'Supprimer' ,
        description : 'Souhaitez-vous supprimer cette ressource définitivement ?' ,
        disagree    : 'Annuler' ,
        loading     : 'Suppression en cours...' ,
        title       : 'Supprimer la ressource ?',
        useID       : false
    },
    tooltips  :
    {
        activate   : 'Activer',
        deactivate : 'Désactiver',
        remove     : 'Supprimer'
    }
};

export default metadatas ;