import React from 'react'

import compose  from 'vegas-js-core/src/functors/compose'
import isString from 'vegas-js-core/src/isString'

import initApp from '../../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import NoteIcon from '@material-ui/icons/Note'

import ThingChildren , { styles } from '../ThingChildren'

import Material   from './Material'
import MaterialVO from '../../../things/Material'
import Thing      from '../../../things/Thing'

class Materials extends ThingChildren
{
    createChild = ( child , editable = false ) =>
    {
        if( child )
        {
            const { lang, locale } = this.props ;
            return (
            <Material
                editable = { editable }
                lang     = { lang }
                locale   = { locale }
                onClick  = { () => this.openEditDialog(child) }
                thing    = { child }
            />)
        }
        return null ;
    };

    getLocale = () => this.props.locale.things.conceptualObject.materials ;

    prepare = item =>
    {
        let { alternateName, description, material, technique } = item ;

        const { config : { languages } = {} } = this.props ;

        if( languages )
        {
            if( alternateName )
            {
                languages.forEach( lang => {
                    if( !isString(alternateName[lang]) )
                    {
                        alternateName[lang] = '' ;
                    }
                });
            }

            if( description )
            {
                languages.forEach( lang => {
                    if( !isString(description[lang]) )
                    {
                        description[lang] = '' ;
                    }
                });
            }
        }

        if( material instanceof Thing )
        {
            material = String(material.id)
        }
        else
        {
            material = null ;
        }


        if( technique instanceof Thing )
        {
            technique = String(technique.id)
        }
        else
        {
            technique = null ;
        }

        return (
        {
            alternateName ,
            description ,
            material,
            technique
        });
    };

}

Materials.defaultProps =
{
    ...ThingChildren.defaultProps ,
    clazz     : MaterialVO,
    emptyIcon : null,
    icon      : <NoteIcon/>,
    member    : 'materials'
};

Materials.propTypes =
{
    ...ThingChildren.propTypes
};

export default compose( withStyles( styles ) , withRouter , initApp )( Materials ) ;
