import Article          from './creativework/Article'
import ConceptualObject from './ConceptualObject'
import Course           from './Course'
import Event            from './Event'
import Organization     from './Organization'
import Person           from './Person'
import Place            from './Place'
import Stage            from './Stage'
import Thing            from './Thing'

import getInterestType from './getInterestType'
import InterestType    from './InterestType'

/**
 * Populates the discover collection of the specific thing reference.
 * @param {*} thing - The thing reference to populate.
 * @returns {*} The thing reference.
 */
const populateDiscover = thing =>
{
    if( thing )
    {
        const { discover } = thing ;
        if( discover instanceof Array && discover.length > 0 )
        {
            thing.discover = discover.map( item =>
            {
                if( !(item instanceof Thing) )
                {
                    const type = getInterestType( item , { prefix : '' } ) ;
                    switch( type )
                    {
                        case InterestType.ARTICLE :
                        {
                            return new Article( item ) ;
                        }
                        case InterestType.CONCEPTUAL_OBJECT :
                        {
                            return new ConceptualObject( item ) ;
                        }
                        case InterestType.COURSE :
                        {
                            return new Course( item ) ;
                        }
                        case InterestType.EVENT :
                        {
                            return new Event( item ) ;
                        }
                        case InterestType.ORGANIZATION :
                        {
                            return new Organization( item ) ;
                        }
                        case InterestType.PERSON :
                        {
                            return new Person( item ) ;
                        }
                        case InterestType.PLACE :
                        {
                            return new Place( item ) ;
                        }
                        case InterestType.STAGE :
                        {
                            return new Stage( item ) ;
                        }
                        default :
                        {
                            return item ;
                        }
                    }
                }
                return item ;
            }) ;
        }
    }
    return thing ;
};

export default populateDiscover ;