import React from 'react'

import Form from '../../../../display/forms/FormElements'

import Mark from '../../../../display/things/conceptualObject/Mark'

import Thing from '../../../../things/Thing'
import Word  from '../../../../things/Word'

import api from '../../../../configs/api'

const elements =
[
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'name',
            key          : 'name',
            label        : 'Texte',
            autoComplete : 'off' ,
            autoFocus    : true,
            fullWidth    : true,
            margin       : 'normal',
            required     : true,
            select       : false,
            type         : 'text',
            variant      : 'outlined'
        }
    },
    {
        type       : Form.SEARCH_SELECTOR ,
        path       : api.thesaurus.marks_types ,
        clearLabel : 'Vider' ,
        closeLabel : 'Fermer' ,
        init       :
        {
            id         : 'additionalType',
            key        : 'additionalType',
            label      : 'Type',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            required   : false,
            type       : 'search',
            variant    : 'outlined'
        },
        inputProps :
        {
            label : 'Type d\'inscription ou de marque...'
        },
        limit  : 0,
        logics :
        {
            disabledSuggestions : props =>
            {
                const { rel } = props ;
                let suggestions = [] ;
                if( rel instanceof Thing )
                {
                    const { additionalType } = rel ;
                    if( additionalType instanceof Word )
                    {
                        suggestions.push( additionalType ) ;
                    }
                }
                return suggestions ;
            }
        }
    },
    {
        type       : Form.SEARCH_SELECTOR ,
        path       : api.thesaurus.languages ,
        clearLabel : 'Vider' ,
        closeLabel : 'Fermer' ,
        init       :
        {
            id         : 'language',
            key        : 'language',
            label      : 'Langage',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            required   : false,
            type       : 'search',
            variant    : 'outlined'
        },
        inputProps :
        {
            label : 'Sélectionner un langage...'
        },
        limit  : 0,
        logics :
        {
            disabledSuggestions : props =>
            {
                const { rel } = props ;
                let suggestions = [] ;
                if( rel instanceof Thing )
                {
                    const { language } = rel ;
                    if( language instanceof Word )
                    {
                        suggestions.push( language ) ;
                    }
                }
                return suggestions ;
            }
        }
    },
    {
        type       : Form.SEARCH_SELECTOR ,
        path       : api.thesaurus.conceptualObject_technic ,
        clearLabel : 'Vider' ,
        closeLabel : 'Fermer' ,
        init       :
        {
            id         : 'technique',
            key        : 'technique',
            label      : 'Technique',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            required   : false,
            type       : 'search',
            variant    : 'outlined'
        },
        inputProps :
        {
            label : 'Sélectionner une technique...'
        },
        limit  : 0,
        logics :
        {
            disabledSuggestions : props =>
            {
                const { rel } = props ;
                let suggestions = [] ;
                if( rel instanceof Thing )
                {
                    const { technique } = rel ;
                    if( technique instanceof Word )
                    {
                        suggestions.push( technique ) ;
                    }
                }
                return suggestions ;
            }
        }
    },
    {
        type : Form.I18N_EDITOR ,
        init :
        {
            id    : 'description',
            key   : 'description',
            input : true,
            label : 'Description'
        }
    },
    {
        type : Form.I18N_EDITOR ,
        init :
        {
            id    : 'position',
            key   : 'position',
            input : true,
            label : 'Position'
        }
    },
    {
        type : Form.I18N_EDITOR ,
        init :
        {
            id    : 'text',
            key   : 'text',
            input : true,
            label : 'Traductions'
        }
    }
];

const marks =
{
    add :
    {
        agree       : 'Créer' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour ajouter une marque ou une inscription présente sur cet objet.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...' ,
        success     : 'Ajout de cet élément réussi!' ,
        title       : 'Ajouter une marque ou une inscription',
        elements
    },
    edit :
    {
        agree       : 'Sauver' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour modifier cet enregistrement.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...'  ,
        success     : 'Mise à jour réussie!' ,
        title       : 'Modifier',
        elements
    },
    remove :
    {
        agree       : 'Supprimer' ,
        description : 'Souhaitez-vous supprimer cet enregistrement :' ,
        disagree    : 'Annuler' ,
        iconifiable : false,
        item        : { render : item => <Mark thing={ item } editable={ false } style={{ marginLeft:24 }} /> },
        loading     : 'Suppression en cours...' ,
        title       : 'Supprimer cet enregistrement ?',
        titleX      : 'Supprimer ces enregistrements ?'
    },
    title    : 'Marques et inscriptions' ,
    tooltips :
    {
        add    : "Ajouter" ,
        edit   : "Éditer" ,
        remove : "Supprimer"
    }
};

export default marks ;
