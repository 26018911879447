import PropertyValue from './PropertyValue'
import Word from './Word'

/**
 * A number-value element.
 * @memberOf things
 */
class NumberValue extends PropertyValue
{
    /**
     * Creates a new NumberValue instance.
     * @constructor
     * @param object The generic object to initialize the object the first time.
     */
    constructor( object = null )
    {
        super( object ) ;
    }

    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new NumberValue( this.toObject() ) ;
    }

    /**
     * Populate the object with specific behaviors or types. Launched by default in the constructor and in the set methods.
     * @returns {Thing} The current reference.
     */
    populate()
    {
        if( this.additionalType && !(this.additionalType instanceof Word ))
        {
            this.additionalType = new Word(this.additionalType) ;
        }
        return this ;
    }
}

export default NumberValue ;