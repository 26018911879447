import isString from 'vegas-js-core/src/isString'

import api       from '../../../../../../configs/api'
import Form      from '../../../../../../display/forms/FormElements'
import Method    from '../../../../../../net/Method'
import Thing     from '../../../../../../things/Thing'
import Workplace from '../../../../../../things/Workplace'

const elements =
[
    {
        type       : Form.SEARCH_SELECTOR ,
        clazz      : Workplace,
        clearLabel : 'Vider' ,
        closeLabel : 'Fermer' ,
        init       :
        {
            id         : 'value',
            key        : 'value',
            label      : 'Espace de travail',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            required   : true,
            type       : 'search',
            variant    : 'outlined'
        },
        path : ( props ) =>
        {
            const { rel } = props ;
            if( rel instanceof Thing )
            {
                const { about } = rel ;
                if( about instanceof Thing )
                {
                    const { url } = about ;
                    if( isString(url) )
                    {
                        return url.split(api.url)[1] + '/workplaces'
                    }
                }
            }
            return null;
        },
        iconifiable : false ,
        searchable  : false,
        title       : 'Sélectionner un espace de travail'
    }
];

const workplace =
{
    edit :
    {
        agree       : 'Sauver' ,
        disagree    : 'Annuler' ,
        description : 'Sélectionner un espace de travail' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...'  ,
        method      : Method.PATCH,
        mock        : false ,
        success     : 'Mise à jour réussie!' ,
        title       : 'Espace de travail',
        elements
    },
    labels :
    {
        empty         : 'Aucun espace de travail dans cet élevage',
        emptyWorkshop : 'Aucun atelier dans cet élevage.',
        select        : 'Sélectionner un espace de travail',
        unselect      : 'Aucun espace de travail sélectionné.',
        workplace     : 'Espace de travail'
    },
    tooltips :
    {
        'add'  : 'Sélectionner',
        'edit' : 'Modifier'
    }
};

export default workplace ;