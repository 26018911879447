import React , { Component } from "react"

import PropTypes from "prop-types"

import clsx from "clsx"

import { withStyles } from "@material-ui/core/styles"

import { Button, IconButton, Tooltip } from '@material-ui/core'

import ceil   from 'vegas-js-core/src/maths/ceil'
import format from 'vegas-js-core/src/strings/fastformat'

import {
    MdChevronLeft,
    MdChevronRight ,
    MdFirstPage ,
    MdLastPage
}
from 'react-icons/md'

import
{
    greyColor,
    primaryColor,
    infoColor,
    successColor,
    warningColor,
    dangerColor
}
from '../../configs/colors'

import withLocale from '../../contexts/i18n/withLocale'

const colors = [ "primary" , "info" , "success" , "warning" , "danger" ] ;

const defaultLocale =
{
    first    : 'First' ,
    last     : 'Last' ,
    next     : 'NEXT' ,
    pages    : 'Pages {0}/{1}' ,
    previous : 'PREV'
};

const styles =
{
    pagination :
    {
        display        : 'flex',
        alignItems     : 'center',
        justifyContent : 'start' ,
        paddingLeft    : 0,
        margin         : "8px 0 8px 0",
        borderRadius   : "4px"
    },
    paginationItem :
    {
        display: "inline"
    },
    paginationIcon:
    {
        ":first-of-type"    : { marginleft: "0" } ,
        border              : "0" ,
        borderRadius        : "30px !important",
        transition          : "all .3s",
        padding             : "0px 6px",
        margin              : "0 3px",
        minWidth            : "20px",
        height              : "30px",
        minHeight           : "auto",
        lineHeight          : "30px",
        fontWeight          : "400",
        fontSize            : "22px",
        background          : "transparent",
        position            : "relative",
        float               : "left",
        textDecoration      : "none",
        boxSizing           : "border-box",
        "&,&:hover,&:focus" : { color : greyColor } ,
        "&:hover,&:focus"   : { zIndex : "3" , backgroundColor : "#eee" , borderColor: "#ddd" },
        "&:hover"           : { cursor : "pointer" }
    },
    paginationLink:
    {
        ":first-of-type"    : { marginleft: "0" } ,
        border              : "0" ,
        borderRadius        : "30px !important",
        transition          : "all .3s",
        padding             : "0px 11px",
        margin              : "0 3px",
        minWidth            : "20px",
        height              : "30px",
        minHeight           : "auto",
        lineHeight          : "30px",
        fontWeight          : "400",
        fontSize            : "12px",
        textTransform       : "uppercase",
        background          : "transparent",
        position            : "relative",
        float               : "left",
        textDecoration      : "none",
        boxSizing           : "border-box",
        "&,&:hover,&:focus" : { color: greyColor },
        "&:hover,&:focus"   : { zIndex: "3", backgroundColor: "#eee", borderColor: "#ddd" },
        "&:hover"           : { cursor: "pointer" }
    },
    paginationTitle:
    {
        ":first-of-type"    : { marginleft: "0" } ,
        border              : "0" ,
        borderRadius        : "30px !important",
        color               : '#222' ,
        padding             : "0px 5px 0px 0px",
        margin              : "0 4px 0px 0px",
        minWidth            : "30px",
        height              : "30px",
        minHeight           : "auto",
        lineHeight          : "30px",
        fontWeight          : "bold",
        fontSize            : "12px",
        textTransform       : "uppercase",
        background          : "transparent",
        position            : "relative",
        float               : "left",
        textDecoration      : "none",
        boxSizing           : "border-box"
    },
    primary:
    {
        "&,&:hover,&:focus":
        {
            backgroundColor: primaryColor,
            borderColor: primaryColor,
            color: "#FFFFFF",
            boxShadow:
                "0 4px 5px 0 rgba(156, 39, 176, 0.14), 0 1px 10px 0 rgba(156, 39, 176, 0.12), 0 2px 4px -1px rgba(156, 39, 176, 0.2)"
        },
        "&:hover,&:focus": {
            zIndex: "2",
            cursor: "default"
        }
    },
    info: {
        "&,&:hover,&:focus": {
            backgroundColor: infoColor,
            borderColor: infoColor,
            color: "#FFFFFF",
            boxShadow:
                "0 4px 5px 0 rgba(0, 188, 212, 0.14), 0 1px 10px 0 rgba(0, 188, 212, 0.12), 0 2px 4px -1px rgba(0, 188, 212, 0.2)"
        },
        "&:hover,&:focus": {
            zIndex: "2",
            cursor: "default"
        }
    },
    success :
    {
        "&,&:hover,&:focus":
        {
            backgroundColor : successColor,
            borderColor     : successColor,
            color           : "#FFFFFF",
            boxShadow       : "0 4px 5px 0 rgba(76, 175, 80, 0.14), 0 1px 10px 0 rgba(76, 175, 80, 0.12), 0 2px 4px -1px rgba(76, 175, 80, 0.2)"
        },
        "&:hover,&:focus":
        {
            zIndex : "2",
            cursor : "default"
        }
    },
    warning:
    {
        "&,&:hover,&:focus": {
            backgroundColor: warningColor,
            borderColor: warningColor,
            color: "#FFFFFF",
            boxShadow : "0 4px 5px 0 rgba(255, 152, 0, 0.14), 0 1px 10px 0 rgba(255, 152, 0, 0.12), 0 2px 4px -1px rgba(255, 152, 0, 0.2)"
        },
        "&:hover,&:focus": {
            zIndex: "2",
            cursor: "default"
        }
    },
    danger: {
        "&,&:hover,&:focus": {
            backgroundColor: dangerColor,
            borderColor: dangerColor,
            color: "#FFFFFF",
            boxShadow:
                "0 4px 5px 0 rgba(244, 67, 54, 0.14), 0 1px 10px 0 rgba(244, 67, 54, 0.12), 0 2px 4px -1px rgba(244, 67, 54, 0.2)"
        },
        "&:hover,&:focus": {
            zIndex: "2",
            cursor: "default"
        }
    },
    disabled:
    {
        "&,&:hover,&:focus":
        {
            color           : "#777",
            cursor          : "not-allowed",
            backgroundColor : "#fff",
            borderColor     : "#ddd"
        }
    }
};

class Pagination extends Component
{
    state =
    {
        max       : 0 ,
        min       : 0 ,
        next      : 2 ,
        page      : 1 ,
        pageCount : 0 ,
        previous  : 1
    };

    static getDerivedStateFromProps( props , state )
    {
        const {
            limit,
            offset,
            pageOffset,
            total
        }
        = props ;

        let page = 1 ;
        let pageCount = 0 ;

        if ( limit > 0 )
        {
            pageCount = ceil( total / limit ) ;
            page      = pageCount - ceil((total - offset) / limit) + 1 ;
        }

        let next = offset + limit ;
        if ( next > total )
        {
            next = total;
        }

        let previous = offset - limit ;
        if ( previous < 0)
        {
            previous = 0;
        }

        let max = page + pageOffset;
        if ( max > pageCount)
        {
            max = pageCount ;
        }

        let min = page - pageOffset ;
        if( min < 1 )
        {
            min = 1 ;
        }

        state.max = max ;
        state.min = min ;
        state.next = next ;
        state.page = page ;
        state.pageCount = pageCount ;
        state.previous = previous ;

        return state ;
    }

    getLocale = () =>
    {
        const { locale } = this.props ;
        if( locale )
        {
            const { components } = locale ;
            if( components )
            {
                const { pagination } = components ;
                if( pagination )
                {
                    return pagination ;
                }
            }
        }

        return defaultLocale ;
    };

    render()
    {
        const {
            classes,
            color ,
            count ,
            limit ,
            onChange
        } = this.props ;

        const {
            max,
            min,
            next,
            page,
            pageCount,
            previous
        }
        = this.state ;

        const pagination = this.getLocale() ;

        if( count > 0 )
        {
            let pages = [] ;
            let title = null ;

            if( pageCount > 1 )
            {
                title = format(pagination.pages, page , pageCount ) ;

                if( page > 1 )
                {
                    pages.push( { icon : <MdFirstPage   size='0.8em'/> , tooltip : pagination.first    , onClick : onChange ? () => onChange( 0 , 1 ) : null } ) ;
                    pages.push( { icon : <MdChevronLeft size='0.8em'/> , tooltip : pagination.previous , onClick : onChange ? () => onChange( previous , ((page - 1) < 1) ? 1 : page ) : null } ) ;
                }

                if( min > 1 )
                {
                    pages.push( { text : '1' , onClick : onChange ? () => onChange( 0 , 1 ) : null }) ;
                    pages.push( { text : '...' }) ;
                }

                for( let i = min ; i <= max ; i++ )
                {
                    let active = (page === i) ;
                    pages.push( { active , text:i , onClick: active ? null : ( onChange ? () => onChange( (i - 1) * limit , i ) : null) }) ;
                }

                if( max < pageCount )
                {
                    pages.push( { text : '...' }) ;
                    pages.push( { text : pageCount , onClick : onChange ? () => onChange( (pageCount-1) * limit , pageCount ) : null }) ;
                }

                if( page < pageCount )
                {
                    pages.push( { icon : <MdChevronRight size='0.8em'/> , tooltip : pagination.next, onClick : onChange ? () => onChange( next , (page + 1) > max ? max : (page + 1) ) : null } ) ;
                    pages.push( { icon : <MdLastPage     size='0.8em'/> , tooltip : pagination.last, onClick : onChange ? () => onChange( (pageCount-1) * limit , pageCount ) : null  } ) ;
                }
            }

            return (
                <div className="flex items-center">
                    <ul className={classes.pagination}>

                        {
                            title
                            && (title.length > 0)
                            && <li className={ clsx(classes.paginationItem, classes.paginationTitle) }>{ title }</li>
                        }

                        { pages.map((prop, key) =>
                        {
                            const {
                                active,
                                disabled,
                                icon,
                                onClick,
                                text,
                                tooltip
                            }
                            = prop ;

                            let content = null ;

                            if( icon )
                            {
                                content = (
                                    <IconButton
                                        onClick   = { onClick }
                                        className = { clsx({ [classes.paginationIcon]:true,[classes[color]]:prop.active,[classes.disabled]:prop.disabled })}
                                    >
                                        { icon }
                                    </IconButton>);
                            }
                            else if( text )
                            {
                                content = (
                                <Button
                                    onClick   = { onClick }
                                    className = { clsx({[classes.paginationLink]:true,[classes[color]]:active,[classes.disabled]:disabled}) }
                                >
                                    { text }
                                </Button>);
                            }

                            if( tooltip && tooltip.length > 0 )
                            {
                                content = <Tooltip placement='top' title={tooltip}>{content}</Tooltip>
                            }

                            return <li className={classes.paginationItem} key={key}>{ content }</li>
                        })}
                    </ul>

                </div>

            );
        }

        return null ;
    }
}

Pagination.defaultProps =
{
    color      : 'primary' ,
    count      : 0 ,
    locale     : null,
    pageOffset : 2 ,
    limit      : 12 ,
    offset     : 0 ,
    onChange   : null ,
    title      : '' ,
    total      : 0
};

Pagination.propTypes =
{
    classes    : PropTypes.object.isRequired ,
    color      : PropTypes.oneOf( colors ) ,
    count      : PropTypes.number ,
    pageOffset : PropTypes.number ,
    limit      : PropTypes.number ,
    locale     : PropTypes.object ,
    offset     : PropTypes.number ,
    onChange   : PropTypes.func,
    total      : PropTypes.number
};

export default withStyles(styles)( withLocale(Pagination) );
