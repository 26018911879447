import initDialog from '../initDialog'

import { RemoveDialog } from '../RemoveDialog'

import api from '../../../configs/api'

const styles = () => ({});

class RemoveStageDialog extends RemoveDialog
{
    getLocale = () => this.props.locale.dialogs.application.stage.remove ;
}

RemoveStageDialog.defaultProps =
{
    ...RemoveDialog.defaultProps,
    uri    : api.stages.url,
    useUrl : true
};

RemoveStageDialog.propTypes =
{
    ...RemoveDialog.propTypes
};

export default initDialog({ styles })( RemoveStageDialog );
