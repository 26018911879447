import React from 'react'

import PropTypes from 'prop-types'

import withi18n from '../../contexts/i18n/withi18n'

import getHorizontalElements from '../getHorizontalElements'

import Flags from '../things/Flags'

import { ThingCell } from './ThingCell'

export class WordCell extends ThingCell
{
    getContent = () =>
    {
        const { thing } = this.props;
        if (thing)
        {
            const elements = [ 'names' ] ;
            return getHorizontalElements( { elements , factory:this } ) ;
        }
        return null;
    };

    getNames = ( { className = null } = {}  ) =>
    {
        const thing = this.getThing() ;
        if( thing )
        {
            return <Flags className={className} member='name' thing={thing} />
        }
        return null ;
    };

    getLocale = () => this.props.locale.display.cells.word;
}

WordCell.defaultProps =
{
    ...ThingCell.defaultProps,
    names           : true,
    subtitleVariant : 'alternateName',
    typable         : false
};

WordCell.propTypes =
{
    ...ThingCell.propTypes,
    names : PropTypes.bool
};

export default withi18n(WordCell) ;
