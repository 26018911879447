import Thing from '../Thing'
import Word  from '../Word'

import generify from '../generify'

/**
 * A medical method definition.
 * @memberOf things.medical
 * @extends Thing
 */
class MedicalMethod extends Thing
{
    /**
     * Creates a new MedicalMethod instance.
     * @constructor
     * @param object The generic object to initialize the object.
     */
    constructor( object = null )
    {
        super() ;
    
        /**
         * Indicates if the method is mandatory or not.
         * @type {boolean}
         */
        this.mandatory = false ;
        
        this.set( object ) ;
    }
    
    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        super.clear() ;
        this.mandatory = false ;
        return this ;
    }
    
    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new MedicalMethod( this.toObject() ) ;
    }
    
    /**
     * Populate the object with specific behaviors or types.
     * Launched by default in the constructor and in the set methods.
     * @returns {Thing} The current reference.
     */
    populate()
    {
        super.populate() ;
        const {
            additionalType
        }
        = this ;
        
        if( additionalType && !(additionalType instanceof Word))
        {
            this.additionalType = new Word(additionalType) ;
        }
    }
    
    /**
     * Returns the generic object representation of the Object.
     * @return {Object} The generic object representation of the Object.
     */
    toObject( full = false )
    {
        let {
            additionalType,
            id,
            mandatory
        } = this ;
        
        additionalType = generify(additionalType,true);
        
        return {
            ...( full && super.toObject() ),
            additionalType,
            id,
            mandatory
        };
    }
}

export default MedicalMethod ;