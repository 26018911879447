import React  from 'react'

import notEmpty   from 'vegas-js-core/src/strings/notEmpty'

import { FaEraser } from 'react-icons/fa'

import red from '@material-ui/core/colors/red'

import { RemoveThingButton } from './RemoveThingButton'

import api        from '../../configs/api'
import initDialog from '../dialogs/initDialog'

const styles =
{
    button :
    {
        backgroundColor : 'white',
        color           : red[600],
        '&:hover' :
        {
            backgroundColor : red[900],
            color           : 'white'
        }
    },
    root :
    {
        //
    }
};

export class RemoveImageButton extends RemoveThingButton
{
    getLocale = () => this.props.locale.display.buttons.removeImage ;

    removeUri = () =>
    {
        const { thing } = this.props ;
        if( thing )
        {
            const { url } = thing ;
            if( notEmpty(url) )
            {
                return url.split(api.url)[1] + '/image' ;
            }
        }
        return null ;
    }
}

RemoveImageButton.defaultProps =
{
    ...RemoveThingButton.defaultProps,
    icon : <FaEraser size={20} /> ,
    size :'small'
};

RemoveImageButton.propTypes =
{
    ...RemoveThingButton.propTypes,
};

export default initDialog({ styles })( RemoveImageButton ) ;
