import grey   from '@material-ui/core/colors/grey'
import indigo from '@material-ui/core/colors/indigo'
import green  from '@material-ui/core/colors/green'

const styles = theme =>
({
    avatarDefault :
    {
        width  : 40,
        height : 40
    },
    avatarSmall : 
    {
        width  : theme.spacing(3),
        height : theme.spacing(3)
    },
    avatarLarge : 
    {
        width  : theme.spacing(6),
        height : theme.spacing(6),
    },
    badge :
    {
        fontSize : 10
    },
    checkbox : 
    {
        marginRight : 8
    },
    container :
    {
        display       : 'inline-flex' ,
        flexDirection : 'column' ,
        bottom        : 0 ,
        left          : 0 ,
        right         : 0 ,
        top           : 0 ,
        overflowY     : 'scroll',
        padding       : 12,
        position      : 'absolute'
    },
    map :
    {
        marginBottom : 12 ,
        height       : 520
    },
    progress:
    {
        color : indigo[500]
    },
    root :
    {
        flex     : 'auto',
        overflow : 'hidden',
        position : 'relative'
    },
    switchBase :
    {
        '&$switchChecked':
        {
            color: grey[100],
            '& + $switchBar':
            {
                backgroundColor: green[500]
            }
        },
        transition: theme.transitions.create('transform',
        {
            duration : theme.transitions.duration.shortest,
            easing   : theme.transitions.easing.sharp
        }),
    },
    switchChecked:
    {
        transform: 'translateX(21px)',
        '& + $switchBar': { opacity: 1, border: 'none' },
    },
    switchBar:
    {
        borderRadius    : 10,
        width           : 42,
        height          : 20,
        marginTop       : -10,
        marginLeft      : -18,
        border          : 'solid 1px',
        borderColor     : theme.palette.grey[400],
        backgroundColor : theme.palette.grey[200],
        opacity         : 1,
        transition      : theme.transitions.create(['background-color', 'border'])
    },
    switchIcon:
    {
        width  : 18,
        height : 18
    },
    switchIconChecked:
    {
        boxShadow: theme.shadows[1]
    }
});

export default styles ;
