import Roles from './net/auth/Roles'

export const groups =
{
    admin     : [ Roles.ADMIN ],
    all       : [ Roles.ADMIN, Roles.WRITE , Roles.READ , Roles.GUEST ] ,
    onlyGuest : [ Roles.GUEST ] ,
    reader    : [ Roles.READ ] ,
    user      : [ Roles.ADMIN, Roles.WRITE , Roles.READ ] ,
    writer    : [ Roles.WRITE , Roles.READ ]
};

/**
 * Sets here the roles of all the main views in the application.
 * All the elements in the navigation and the router must be defined here.
 * Note : You can use the null value to display a view without right access.
 */
const permissions =
{
    applications      : [ ...groups.user ] ,
    articles          : [ ...groups.user ] ,
    courses           : [ ...groups.user ] ,
    diseases          : [ ...groups.user ] ,
    events            : [ ...groups.user ] ,
    games             : [ ...groups.user ] ,
    home              : null ,
    livestocks        : [ ...groups.user ] ,
    me                : [ ...groups.user ] ,
    mediaObjects      : [ ...groups.user ] ,
    conceptualObjects : [ ...groups.user] ,
    organizations     : [ ...groups.user ] ,
    people            : [ ...groups.user ] ,
    person            : [ ...groups.user ] ,
    places            : [ ...groups.user ] ,
    settings          : [ ...groups.user ] ,
    stages            : [ ...groups.user ] ,
    teams             : [ ...groups.user ] ,
    technicians       : [ ...groups.user ] ,
    thesaurus         : [ ...groups.user ] ,
    users             : [ ...groups.user ] ,
    veterinarians     : [ ...groups.user ]
};

export default permissions ;
