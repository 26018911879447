import React from 'react'

import DayOfWeek from 'vegas-js-calendar/src/DayOfWeek'

import isString  from 'vegas-js-core/src/isString'

import { Chip, MenuItem } from '@material-ui/core'

import Form from '../../../display/forms/FormElements'

import HourSpecification from '../../../display/things/hours/HourSpecification'

import days      from './days'
import dayOfWeek from './dayOfWeek'

const dayOfWeekHelper = new DayOfWeek( dayOfWeek ) ;

const MenuProps =
{
    PaperProps:
    {
        style:
        {
            maxHeight : 48 * 4.5 + 8,
            width     : 250
        }
    }
};

const daylist = days.map( ( { id , name } ) => <MenuItem key={id} value={id}>{name}</MenuItem> ) ;

const renderDay = selected =>
{
    if( selected instanceof Array )
    {
        selected = days
        .map( item => ( selected.indexOf(item.id) > -1 ) ? item : null )
        .filter( item => item !== null );
        selected = selected.map( item =>
        (
            <Chip

                className = 'mx-8 mb-4'
                color     = 'secondary'
                key       = { 'item_' + item.short }
                label     = { item.name }
            />
        ));

        return <div className="flex flex-wrap">{ selected }</div>;
    }
    return null ;
};

const elements =
[
    {
        type       : Form.TEXTFIELD ,
        checkValue : value => isString(value) ? dayOfWeekHelper.parse( value ) : value ,
        init       :
        {
            id           : 'dayOfWeek',
            key          : 'dayOfWeek',
            autoComplete : 'off' ,
            autoFocus    : false,
            children     : daylist,
            fullWidth    : true,
            label        : 'Jours',
            margin       : 'normal',
            SelectProps  :
            {
                MenuProps,
                multiple    : true,
                renderValue : renderDay
            } ,
            required     : false,
            select       : true,
            type         : 'select',
            variant      : 'outlined'
        }
    },
    {
        type : Form.HOURS ,
        init :
        {
            key             : 'hours',
            TimePickerProps :
            {
                cancelLabel  : 'Annuler' ,
                clearLabel   : 'Fermer' ,
                inputVariant : 'outlined',
                okLabel      : 'OK'
            }
        }
    },
    {
        type : Form.DIVIDER,
        init :
        {
            key     : 'divider',
            light   : false ,
            variant : 'fullWidth'
        }
    },
    {
        type : Form.I18N_EDITOR ,
        init :
        {
            id    : 'alternateName',
            key   : 'alternateName',
            input : true,
            label : 'Titre'
        }
    },
    {
        type         : Form.DATE ,
        defaultValue : null ,
        format       : 'L' ,
        init         :
        {
            id            : 'validFrom',
            key           : 'validFrom',
            autoOk        : true ,
            cancelLabel   : 'Annuler' ,
            clearable     : true ,
            clearLabel    : 'Vider' ,
            disableFuture : false ,
            disablePast   : false ,
            invalidLabel  : '-',
            inputVariant  : 'outlined',
            fullWidth     : true ,
            label         : 'Date de début' ,
            required      : false,
            okLabel       : 'OK',
            variant       : 'dialog'
        }
    },
    {
        type         : Form.DATE ,
        defaultValue : null,
        format       : 'L' ,
        minDate      : 'validFrom' ,
        init :
        {
            id            : 'validThrough',
            key           : 'validThrough',
            autoOk        : true ,
            cancelLabel   : 'Annuler' ,
            clearable     : true ,
            clearLabel    : 'Vider' ,
            invalidLabel  : '-',
            disableFuture : false ,
            disablePast   : false ,
            inputVariant  : 'outlined',
            label         : 'Date de fin' ,
            fullWidth     : true ,
            okLabel       : 'OK',
            variant       : 'dialog'
        }
    },
    {
        type : Form.I18N_EDITOR ,
        init :
        {
            id    : 'description',
            key   : 'description',
            input : true,
            label : 'Notes'
        }
    }
];

const openingHoursSpecification =
{
    add :
    {
        elements ,
        agree       : 'Créer' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour créer un nouvel horaire.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...' ,
        success     : 'Ajout du nouvel horaire réussi!' ,
        title       : 'Ajouter un horaire',
    },
    edit :
    {
        elements,
        agree       : 'Sauver' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour modifier cet horaire.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...'  ,
        success     : 'Mise à jour réussie!' ,
        title       : 'Modifier l\'horaire',
    },
    days   : dayOfWeek,
    remove :
    {
        agree       : 'Supprimer' ,
        description : 'Souhaitez-vous supprimer cet horaire:' ,
        disagree    : 'Annuler' ,
        item        : { render : item => <HourSpecification helper={ dayOfWeekHelper } thing={ item } /> },
        loading     : 'Suppression en cours...' ,
        title       : 'Supprimer cet horaire ?'
    },
    title    : 'Horaires' ,
    tooltips :
    {
        add    : "Ajouter" ,
        edit   : "Éditer" ,
        remove : "Supprimer"
    }
};

export default openingHoursSpecification;
