import api   from '../../../../../../configs/api'
import Form  from '../../../../../../display/forms/FormElements'
import Thing from '../../../../../../things/Thing'

import Method from '../../../../../../net/Method'

const topics =
{
    add :
    {
        agree       : 'Ajouter' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez sélectionner un sujet en cliquant ci-dessous.' ,
        elements    :
        [
            {
                type       : Form.SEARCH_SELECTOR ,
                path       : api.topics.url + "?facets={\"hasPart\":{\"length\":0}}",
                clearLabel : 'Vider' ,
                closeLabel : 'Fermer' ,
                init       :
                {
                    id        : 'value',
                    key       : 'value',
                    label     : 'Sujet',
                    autoFocus : false,
                    fullWidth : true,
                    margin    : 'normal',
                    required  : true,
                    type      : 'search',
                    variant   : 'outlined'
                },
                inputProps :
                {
                    label : 'Sujet...'
                },
                logics :
                {
                    disabledSuggestions : ( props ) =>
                    {
                        const { rel } = props ;
                        if( rel instanceof Thing )
                        {
                            const { subjectOf } = rel ;
                            if( subjectOf )
                            {
                                const { topics } = subjectOf ;
                                if( topics instanceof Array )
                                {
                                    return topics ;
                                }
                            }
                        }
                        return null ;
                    }
                }
            }
        ],
        fail    : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading : 'Chargement en  cours...' ,
        method  : Method.PATCH ,
        prepare : ( item ) =>
        {
            const {
                subjectOf,
                value
            } = item ;

            let topics ;

            if( subjectOf )
            {
                const {
                    topics : c,
                } = subjectOf ;

                if( c )
                {
                    topics = c ;
                }
            }

            let topicsIds = topics ? topics.map( thing => thing.id.toString() ) : [] ;
            topicsIds.push( value.id.toString() ) ;

            const setting = {
                topics : topicsIds ? topicsIds : null
            };

            return ({
                setting : JSON.stringify( setting )
            });
        },
        success : 'Ajout du nouveau topics réussi!' ,
        title   : 'Ajouter un topics'
    },
    remove :
    {
        agree       : 'Supprimer' ,
        description : 'Souhaitez-vous supprimer ce topics:' ,
        disagree    : 'Annuler' ,
        loading     : 'Suppression en cours...' ,
        method      : Method.PATCH  ,
        prepare : ( item ) =>
        {
            const {
                subjectOf
            } = item ;

            let topics ;

            if( subjectOf )
            {
                const {
                    topics : c
                } = subjectOf ;

                if( c )
                {
                    topics = c ;
                }
            }

            let topicsIds = topics.map( element => element.id.toString() ) ;
            topicsIds = topicsIds.filter( id => id !== item.id.toString() ) ;

            const setting = {
                topics : topicsIds ? topicsIds : null
            };

            return ({
                setting : JSON.stringify( setting )
            });
        },
        mapUri : ( item , uri ) =>
        {
            return uri ;
        },
        title       : 'Supprimer le sujet ?'
    },
    title : 'Sujets' ,
    tooltips :
    {
        add    : "Ajouter" ,
        edit   : "Éditer" ,
        remove : "Supprimer"
    },
    sortable :
    {
        prepare : item =>
        {
            let {
                topics
            } = item ;

            let topicsIds = topics.map( element => element.id.toString() ) ;

            const setting = {
                topics : topicsIds ? topicsIds : null
            };

            return ({
                setting : JSON.stringify( setting )
            });
        }
    }
};

export default topics ;
