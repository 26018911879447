import brown    from '@material-ui/core/colors/brown'
import green    from '@material-ui/core/colors/green'
import blue     from '@material-ui/core/colors/blue'
import blueGrey from '@material-ui/core/colors/blueGrey'
import indigo   from '@material-ui/core/colors/indigo'

const support =
{
    title       : 'Comment pouvons-nous vous aider?' ,
    description : 'Support et documentation de la plateforme' ,
    helmet      :
    {
        title : 'Support de la plateforme' ,
        metas :
        [
            { charSet : 'utf-8'} ,
            { name    : 'description' ,  content : process.env.REACT_APP_DESCRIPTION } ,
            { name    : 'keywords'    ,  content : process.env.REACT_APP_KEYWORDS    }
        ]
    },
    labels :
    {
        read : 'Consulter'
    },
    contents :
    [
        {
            id       : 'item-main',
            title    : process.env.REACT_APP_TITLE,
            icon     : 'apps',
            children :
            [
                {
                    id    : 'item-main-events',
                    color : green[600],
                    title : 'Événements',
                    type  : 'item',
                    icon  : 'date_range',
                    url   : '/events'
                },
                {
                    id    : 'item-main-media',
                    color : green[500],
                    title : 'Médias',
                    type  : 'item',
                    icon  : 'collections_bookmark',
                    url   : '/media'
                }
            ]
        },
        {
            id       : 'item-manager',
            title    : 'Gestion',
            icon     : 'work',
            children :
            [
                {
                    id    : 'item-manager-livestocks',
                    color : blue[700],
                    title : 'Élevages',
                    type  : 'item',
                    icon  : 'terrain',
                    exact : true ,
                    url   : '/livestocks'
                },
                {
                    id    : 'item-manager-veterinarians',
                    color : blue[600],
                    title : 'Vétérinaires',
                    type  : 'item',
                    icon  : 'local_hospital',
                    exact : true ,
                    url   : '/veterinarians'
                },
                {
                    id    : 'item-manager-technicians',
                    color : blue[500],
                    title : 'Techniciens',
                    type  : 'item',
                    icon  : 'healing',
                    exact : true ,
                    url   : '/technicians'
                },
                {
                    id    : 'item-manager-courses',
                    color : blue[400],
                    title : 'Parcours',
                    type  : 'item',
                    icon  : 'map',
                    exact : true ,
                    url   : '/courses'
                },
                {
                    id    : 'item-manager-stages',
                    color : blue[300],
                    title : 'Points d\'intérets',
                    type  : 'item',
                    icon  : 'stars',
                    exact : true ,
                    url   : '/stages'
                }
            ]
        },
        {
            id       : 'item-sheets',
            title    : 'Fiches',
            icon     : 'library_books',
            children :
            [
                {
                    id    : 'item-sheets-places',
                    color : indigo[600],
                    title : 'Lieux',
                    type  : 'item',
                    icon  : 'place',
                    exact : true ,
                    url   : '/places'
                },
                {
                    id    : 'item-sheets-organizations',
                    color : indigo[500],
                    title : 'Organisations',
                    type  : 'item',
                    icon  : 'domain',
                    exact : true ,
                    url   : '/organizations'
                },
                {
                    id    : 'item-sheets-artworks',
                    color : indigo[400],
                    title : 'Objets',
                    type  : 'item',
                    icon  : 'category',
                    exact : true ,
                    url   : '/objects'
                },
                {
                    id    : 'item-sheets-people',
                    color : indigo[300],
                    title : 'Personnes',
                    type  : 'item',
                    icon  : 'people',
                    exact : true ,
                    url   : '/people'
                }
            ]
        },
        {
            id       : 'item-tools',
            title    : 'Paramètres',
            icon     : 'settings',
            children :
            [
                {
                    id    : 'item-tools-thesaurus',
                    color : blueGrey[700],
                    title : 'Thésaurus',
                    type  : 'item',
                    icon  : 'note',
                    exact : true ,
                    url   : '/thesaurus'
                },
                {
                    id    : 'item-tools-settings',
                    color : blueGrey[600],
                    title : 'Configurations',
                    type  : 'item',
                    icon  : 'settings_applications',
                    exact : true ,
                    url   : '/settings'
                },
                {
                    id    : 'item-tools-applications',
                    color : blueGrey[500],
                    title : 'Applications',
                    type  : 'item',
                    icon  : 'apps',
                    exact : true ,
                    url   : '/applications'
                },
                {
                    id    : 'item-tools-games',
                    color : blueGrey[400],
                    title : 'Jeux',
                    type  : 'item',
                    icon  : 'casino',
                    exact : true ,
                    url   : '/games'
                },
                {
                    id    : 'item-tools-teams',
                    color : blueGrey[300],
                    title : 'Équipes',
                    type  : 'item',
                    icon  : 'group',
                    exact : true ,
                    url   : '/teams'
                },
                {
                    id    : 'item-tools-users',
                    color : blueGrey[200],
                    title : 'Utilisateurs',
                    type  : 'item',
                    icon  : 'account_box',
                    exact : true ,
                    url   : '/users'
                }
            ]
        },
        {
            id       : 'item-profile',
            title    : 'Profil',
            icon     : 'account_circle',
            children :
            [
                {
                    id    : 'item-profile-account',
                    color : brown[600],
                    title : 'Acompte',
                    type  : 'item',
                    icon  : 'person',
                    exact : true ,
                    url   : '/me/profile'
                },
            {
                id    : 'item-profile-activities',
                color : brown[500],
                title : 'Activités',
                type  : 'item',
                icon  : 'line_weight' ,
                url   : '/me/activities'
            },
            {
                id    : 'item-profile-sessions',
                color : brown[400],
                title : 'Sessions',
                type  : 'item',
                icon  : 'cloud_circle' ,
                url   : '/me/sessions'
            }
            ]
        }
    ]
};

export default support  ;