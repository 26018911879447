import React from 'react'

import CloudIcon from '@material-ui/icons/CloudOff'

import initDialog from '../initDialog'

import { RemoveDialog } from '../RemoveDialog'

import Method from '../../../net/Method'

const styles = () => ({});

class RevokeAllSessionDialog extends RemoveDialog
{
    getLocale = () => this.props.locale.dialogs.application.session.revokeAll ;
}

RevokeAllSessionDialog.defaultProps =
{
    ...RemoveDialog.defaultProps ,
    icon    : <CloudIcon className='mr-12' color='action' />,
    method  : Method.PATCH,
    mock    : false,
    useIcon : true
};

RevokeAllSessionDialog.propTypes =
{
    ...RemoveDialog.propTypes
};

export default initDialog({ styles })( RevokeAllSessionDialog );
