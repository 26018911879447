import { Component } from 'react'

import get from 'vegas-js-core/src/objects/get'

import PropTypes from 'prop-types'

import ReactDom from 'react-dom'

import SnackVariant from '../snackbars/SnackVariant'

class Container extends Component
{
    constructor( props )
    {
        super( props ) ;
        this._element = null ;
        this._mounted = false ;
        this.state =
        {
            element  : this._element ,
            expanded : props.expanded ,
            mounted  : this._mounted
        };
    }

    get element()
    {
        return this._element ;
    }

    get mounted()
    {
        return this._mounted ;
    }

    collapse = () =>
    {
        const { expanded } = this.state ;
        this.setState( { expanded:!expanded } );
    };

    componentDidMount()
    {
        this._element = ReactDom.findDOMNode(this) ;
        this._mounted = true ;

        this.setState({
            element:this._element,
            mounted:this._mounted
        });

        const { route } = this.props ;
        if( route )
        {
            const { settings } = route ;
            if( settings )
            {
                const { setSettings } = this.props ;
                if( setSettings instanceof Function )
                {
                    setSettings( settings ) ;
                }
            }
        }

        this.init() ;
    }

    componentWillUnmount()
    {
        this._element = null ;
        this.__lock__ = false ;
        this._mounted = false ;

        this.setState({
            element:null,
            mounted:false
        }) ;

        const { closeSnack } = this.props ;
        if( closeSnack )
        {
            closeSnack() ;
        }

        const { resetSettings } = this.props ;
        if( resetSettings )
        {
            resetSettings() ;
        }

        this.unmount() ;
    }

    getLocale = () =>
    {
        const { locale } = this.props ;
        if( locale )
        {
            const { localePath } = this.props ;
            if( localePath )
            {
                return get( locale , localePath , locale )
            }
        }
        return this.props.locale || {} ;
    };

    init = () => {} ;

    /**
     * Indicates if the component is locked.
     * @returns {boolean}
     */
    isLocked = () => this.__lock__ ;

    /**
     * Locks the object.
     */
    lock = () =>
    {
        this.__lock__ = true ;
    };

    /**
     * Unlocks the object.
     */
    unlock = () =>
    {
        this.__lock__ = false ;
    };

    unmount = () => {} ;

    update = null ;

    notify = ( message , variant = SnackVariant.DEFAULT ) =>
    {
        const { notifiable, notifySnack } = this.props ;
        if( notifiable && notifySnack instanceof Function )
        {
            notifySnack(message, variant);
        }
    };
}

Container.defaultProps =
{
    backPath   : '' ,
    classes    : {} ,
    className  : null,
    disabled   : false ,
    expanded   : true,
    lang       : 'en',
    locale     : {},
    localePath : null,
    notifiable : true,
    parent     : null,
    style      : null,
    verbose    : false
};

Container.propTypes =
{
    backPath   : PropTypes.string,
    classes    : PropTypes.object.isRequired ,
    className  : PropTypes.string,
    disabled   : PropTypes.bool,
    expanded   : PropTypes.bool,
    lang       : PropTypes.string,
    locale     : PropTypes.object.isRequired ,
    localePath : PropTypes.string,
    notifiable : PropTypes.bool,
    parent     : PropTypes.object,
    style      : PropTypes.object,
    verbose    : PropTypes.bool
};

export default Container ;
