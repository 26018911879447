import React , { Fragment } from 'react'

import format   from 'vegas-js-core/src/strings/fastformat'
import isString from 'vegas-js-core/src/isString'

import withDialogs from '../../../../../../contexts/dialogs/withDialogs'
import withi18n    from '../../../../../../contexts/i18n/withi18n'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { FaPenSquare } from 'react-icons/fa'
import { FaNotesMedical } from 'react-icons/fa'

import { Typography } from '@material-ui/core'

import api from '../../../../../../configs/api'

import Causes         from './Causes'
import Diseases       from './Diseases'
import ThingContainer from '../../../../../containers/ThingContainer'

import AnnualHealthReview from '../../../../../../things/livestock/reviews/AnnualHealthReview'
import MedicalHypothesis  from '../../../../../../things/medical/MedicalHypothesis'
import SectorHealthEvent  from '../../../../../../things/livestock/events/SectorHealthEvent'
import Workshop           from '../../../../../../things/Workshop'
import Thing              from '../../../../../../things/Thing'

const styles = () => ({});

class Hypothesis extends ThingContainer
{
    change = ( thing ) =>
    {
        const { onChange } = this.props ;
        if( onChange instanceof Function )
        {
            onChange( thing ) ;
        }
    };

    getContent = () =>
    {
        let { thing } = this.props;
        if( thing instanceof SectorHealthEvent )
        {
            const { hypothesis } = thing ;

            const {
                lang,
                editUri,
                subjectOf:review
            }
            = this.props ;

            let causesUri = '' ;

            if( review instanceof AnnualHealthReview )
            {
                const { about:workshop } = review ;
                if( workshop instanceof Workshop )
                {
                    const { breeding } = workshop ;
                    if( breeding instanceof Thing )
                    {
                        const { id } = breeding ;
                        causesUri = format(api.settings.causesByBreedingsTypes, id);
                    }
                }
            }

            let text ;
            if( hypothesis instanceof MedicalHypothesis )
            {
                let { description } = hypothesis ;
                if( description )
                {
                    description = hypothesis.getLocaleDescription(lang) ;

                    if( isString(description) && description !== '' )
                    {
                        text = (
                        <div className='flex flex-row items-center px-16 my-24'>
                            <FaPenSquare size='1.5em' className='mr-16' />
                            <Typography
                                className = 'w-full'
                                variant   = 'body2'
                            >
                                { description }
                            </Typography>
                        </div>);
                    }
                }
            }

            return (
                <Fragment>

                    { text }

                    <Causes
                        className      = 'px-16'
                        containerProps = {{ className : "bg-transparent w-full shadow-none py-12 mb-8" }}
                        editSearchUri  = { causesUri }
                        editUri        = { editUri }
                        onChange       = { this.change }
                        subjectOf      = { review }
                        thing          = { thing }
                    />

                    <Diseases
                        className      = 'px-16'
                        containerProps = {{ className : "bg-transparent w-full shadow-none py-12 mb-8" }}
                        editSearchUri  = { api.diseases.url }
                        editUri        = { editUri }
                        onChange       = { this.change }
                        subjectOf      = { review }
                        thing          = { thing }
                    />

                </Fragment>
            )
        }

        return null ;
    };

    getEntry = init =>
    {
        if( init )
        {
            let empty = {} ;

            let { hypothesis } = init ;

            if( hypothesis instanceof Thing )
            {
                empty = hypothesis.toObject() ;
            }
            else if( hypothesis )
            {
                empty = { ...hypothesis } ;
            }

            hypothesis = new MedicalHypothesis(empty);

            hypothesis.about = init ;

            const { subjectOf } = this.props ;
            if( subjectOf )
            {
                hypothesis.subjectOf = subjectOf ;
            }

            return hypothesis ;
        }

        return null ;
    };

    getLocale = () => this.props.locale.things.livestock.reviews.annual.medical.hypothesis;

    onEdit = ( item ) =>
    {
        this.change( item ) ;
    };
}

Hypothesis.defaultProps =
{
    ...ThingContainer.defaultProps ,
    icon       : <FaNotesMedical size={20}/> ,
    optionMode : 'edit'
};

Hypothesis.propTypes =
{
    ...ThingContainer.propTypes
};

export default withStyles( styles )( withRouter( withi18n( withDialogs( Hypothesis ) ) ) ) ;
