import PropTypes from 'prop-types'

import Roles from '../../net/auth/Roles'

import Container from './Container'
import user      from "../../contexts/user/user"

class RoleableContainer extends Container
{
    get scope()
    {
        const { location } = this.props ;
        if( location && location.pathname )
        {
            return user.getScopeByPath( location.pathname ) ;
        }
        return null ;
    }

    isEditable = () =>
    {
        const { editable, roles } = this.props ;
        return editable && ((roles instanceof Array) ? (roles.indexOf(this.scope) > -1) : false) ;
    };
}

RoleableContainer.defaultProps =
{
    ...Container.defaultProps,
    editable : true ,
    roles    : [ Roles.ADMIN , Roles.WRITE ] ,
    scope    : null
};

RoleableContainer.propTypes =
{
    ...Container.propTypes,
    editable : PropTypes.bool,
    roles    : PropTypes.arrayOf(PropTypes.string),
    scope    : PropTypes.string
};

export default RoleableContainer ;
