import React from 'react'

import moment from 'moment/moment'

import compose from 'vegas-js-core/src/functors/compose'

import initApp from '../../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { Divider, Grid } from '@material-ui/core'

import TitleIcon from '@material-ui/icons/HowToReg'

import ThingContainer from '../../containers/ThingContainer'

import Birth from './Birth'
import Death from './Death'

import Nationality from './Nationality'

import PersonVO from '../../../things/Person'
import ThingVO  from '../../../things/Thing'

const styles = () => ({});

class CivilStatus extends ThingContainer
{
    getEntry = init =>
    {
        const { thing } = this.props ;
        let empty = {} ;
        if( init instanceof ThingVO )
        {
            empty = init.toObject() ;
        }
        else if( init )
        {
            empty = { ...init } ;
        }
        const value = new PersonVO(empty) ;
        value.subjectOf = thing ;
        return value ;
    };

    getContent = () =>
    {
        const { thing } = this.props ;
        if( thing )
        {
            return (
                <Grid container spacing={4}>
                    <Divider light className="my-16"/>
                    <Grid item xs={12} sm={6}>
                        <Birth person={thing}/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Death person={thing}/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Nationality thing={thing}/>
                    </Grid>
                </Grid>
            );
        }
        return null ;
    };

    getLocale = () => this.props.locale.things.person.civilStatus ;

    onEdit = ( item ) =>
    {
        const { onChange, thing } = this.props ;
        if( item && thing instanceof ThingVO )
        {
            const {
                birthDate,
                birthPlace,
                deathDate,
                deathPlace,
                name,
                nationality
            } = item ;

            thing.birthDate   = birthDate;
            thing.birthPlace  = birthPlace;
            thing.deathDate   = deathDate;
            thing.deathPlace  = deathPlace;
            thing.modified    = moment(new Date()).toISOString();
            thing.name        = name ;
            thing.nationality = nationality ;

            thing.populate();

            if( this._mounted )
            {
                this.forceUpdate() ;
            }

            if( onChange instanceof Function )
            {
                onChange(thing);
            }
        }
    };
}

CivilStatus.defaultProps =
{
    ...ThingContainer.defaultProps,
    icon       : <TitleIcon/>,
    optionMode : 'edit'
};

CivilStatus.propTypes =
{
    ...ThingContainer.propTypes
};

export default compose( withStyles( styles ) , withRouter , initApp )( CivilStatus ) ;
