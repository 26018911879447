import { createMuiTheme } from '@material-ui/core/styles'

const customMixins = object =>
{
    const theme = createMuiTheme( object );
    return {
        border : ( width = 1 ) => (
        {
            borderWidth : width,
            borderStyle : 'solid',
            borderColor : theme.palette.divider
        }),
        borderLeft : (width = 1) => (
        {
            borderLeftWidth: width,
            borderStyle    : 'solid',
            borderColor    : theme.palette.divider
        }),
        borderRight : (width = 1) => ({
            borderRightWidth: width,
            borderStyle     : 'solid',
            borderColor     : theme.palette.divider
        }),
        borderTop : (width = 1) => ({
            borderTopWidth: width,
            borderStyle   : 'solid',
            borderColor   : theme.palette.divider
        }),
        borderBottom: (width = 1) => ({
            borderBottomWidth: width,
            borderStyle      : 'solid',
            borderColor      : theme.palette.divider
        })
    }
};

export default customMixins ;
