import React from 'react'

import clsx from 'clsx'

import format from 'vegas-js-core/src/strings/fastformat'

import Address                  from '../../../display/things/Address'
import Email                    from '../../../display/things/Email'
import Legal                    from '../../../display/things/organization/Legal'
import Logo                     from '../../../display/things/medias/Logo'
import Metadatas                from '../../../display/things/Metadatas'
import Numbers                  from '../../../display/things/organization/Numbers'
import RemoveOrganizationDialog from '../../../display/dialogs/remove/RemoveOrganizationDialog'
import Telephone                from '../../../display/things/Telephone'
import ThingSubContainer        from '../../../display/containers/ThingSubContainer'
import Websites                 from '../../../display/things/Websites'

class Info extends ThingSubContainer
{
    render = () =>
    {
        const { thing } = this.props ;
        if( thing )
        {
            const { address , id } = thing ;

            const {
                className,
                path,
                onRemove,
                style
            } = this.props ;

            let view = (
            <div className="flex-1 flex flex-col space-y-24">
                <Metadatas
                    activable             = { true }
                    deletable             = { true }
                    RemoveDialogComponent = { RemoveOrganizationDialog }
                    onRemove              = { onRemove }
                    thing                 = { thing }
                    uri                   = { format(path.organization,id) }
                />
                <div className='grid grid-cols-1 sm:grid-cols-2 gap-16'>
                    <Address
                        editUri  = { format(path.address,id) }
                        onChange = { this.changeProperty('address',true) }
                        thing    = { address }
                        uri      = { path.address }
                    />
                    <Legal
                        editUri  = { format(path.organization,id) }
                        onChange = { this.change }
                        thing    = { thing }
                    />
                    <Email
                        onChange = { this.change }
                        thing    = { thing }
                        uri      = { path.email }
                    />
                    <Telephone
                        onChange = { this.change }
                        thing    = { thing }
                        uri      = { path.telephone }
                    />
                    <Websites
                        onChange = { this.change }
                        thing    = { thing }
                        uri      = { path.websites }
                    />
                    <Numbers
                        onChange  = { this.change }
                        thing     = { thing }
                        uri       = { path.numbers }
                    />
                    <Logo
                        onChange = { this.change }
                        thing    = { thing }
                        uri      = { path.logo }
                    />
                </div>
            </div>
            );

            return (
            <div
                className = { clsx( 'flex-1 p-12' , className ) }
                style     = { style }
            >
                { this.renderWithTransition(view) }
            </div>
            );
        }
        return null ;
    }
}

Info.defaultProps =
{
    ...ThingSubContainer.defaultProps
};

Info.propTypes =
{
    ...ThingSubContainer.propTypes
};

export default Info ;
