import React from 'react'

import withConfig  from '../../../contexts/config/withConfig'
import withDialogs from '../../../contexts/dialogs/withDialogs'
import withi18n    from '../../../contexts/i18n/withi18n'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import withWidth from '@material-ui/core/withWidth'

import Avatar     from '@material-ui/core/Avatar'
import Divider    from '@material-ui/core/Divider'
import Paper      from '@material-ui/core/Paper'
import Table      from '@material-ui/core/Table'
import TableBody  from '@material-ui/core/TableBody'
import TableCell  from '@material-ui/core/TableCell'
import TableRow   from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'

import { isWidthUp } from '@material-ui/core/withWidth'

import isNumber from 'vegas-js-core/src/isNumber'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import grey from '@material-ui/core/colors/grey'

import BoardIcon  from '@material-ui/icons/DeveloperBoard'
import SectorIcon from '@material-ui/icons/BorderOuter'

import ThingGrid from '../../../display/grids/ThingGrid'

import Sector from '../../../things/Sector'
import Thing  from '../../../things/Thing'

const styles = theme => (
{
    avatar :
    {
        backgroundColor : '#ffffff',
        color           : grey[800]
    },
    button :
    {
        margin: theme.spacing(1)
    }
});

const prepare = item =>
{
    if( item )
    {
        let {
            additionalType,
            capacity,
            name,
            numAttendee
        } = item ;

        if( additionalType instanceof Thing )
        {
            const { id } = additionalType ;
            additionalType = id ;
        }
        else
        {
            additionalType = null ;
        }

        return {
            additionalType,
            capacity,
            name,
            numAttendee
        };
    }

    return null ;
};

class Sectors extends ThingGrid
{
    createChild = ( thing , index ) =>
    {
        if( thing )
        {
            return (
            <Paper
                className = "p-20 bg-gray-300"
                key       = { 'item_' + index }
                elevation = { 1 }
            >
                { this.getHeader( thing ) }
                <Divider light variant='fullWidth' className='my-8'/>
                { this.getBody( thing ) }
            </Paper>
            );
        }
        return null ;
    };

    addPrepare = prepare ;

    editPrepare = prepare ;

    getBody = thing =>
    {
        if( thing )
        {
            let {
                capacity,
                numAttendee
            } = thing ;

            const locale = this.getLocale() ;

            const { rows } = locale ;

            capacity    = parseInt(capacity) ;
            numAttendee = parseInt(numAttendee) ;

            let color = 'bg-blue-600 text-white' ;
            if( isNumber(capacity) && isNumber(numAttendee) )
            {
                if( numAttendee > capacity )
                {
                    color = 'bg-red-600 text-white' ;
                }
            }

            return (
            <Table>
                <TableBody>
                    { this.getNumericRow( numAttendee , rows.numAttendee , rows.empty , color )  }
                    { this.getNumericRow( capacity    , rows.capacity    , rows.empty , 'bg-green-600 text-white')  }
                </TableBody>
            </Table>);
        }
        return null ;
    };

    getEntrySubjectOf = () => this.props.subjectOf ;

    getHeader = thing =>
    {
        if( thing )
        {
            const { classes, lang, width } = this.props ;

            let { additionalType, name } = thing ;

            let icon ;

            if( additionalType instanceof Thing )
            {
                additionalType = additionalType.getLocaleName(lang);
                if( this.isEditable() )
                {
                    additionalType = (
                    <button
                        className = 'cursor-pointer text-blue-600 font-medium font-bold w-full text-left text-3xl'
                        onClick   = { () => this.openEditDialog(thing) }
                    >
                        { ucFirst(additionalType) }
                    </button>);
                }
                else
                {
                    additionalType = (
                    <Typography className = 'font-medium w-full text-left'>
                        { ucFirst(additionalType) }
                    </Typography>);
                }
            }
            else
            {
                additionalType = null  ;
            }

            if( name )
            {
                name = thing.getLocaleName(lang);
                name = (
                <Typography className="font-normal mt-4" >
                    { ucFirst(name) }
                </Typography>);
            }

            if( isWidthUp( 'sm' , width ) )
            {
               icon = (
               <div className='mr-16'>
                    <Avatar className={classes.avatar} variant="rounded">
                        <SectorIcon />
                    </Avatar>
                </div>);
            }

            return (
            <div className="flex flew-row justify-between items-center w-full">
                { icon }
                <div className="flex flex-col w-full">
                    { additionalType }
                    { name }
                </div>
                <div className="flex flex-row justify-end items-center">
                    { this.getDeleteButton(thing, this.isEditable()) }
                </div>
            </div>
            );
        }
        return null ;
    };

    getNumericRow = ( value, label , empty = '-' , color = 'bg-blue-600 text-white' ) =>
    {
        value = parseInt(value);
        if( isNaN(value) || value < 0 )
        {
            value = empty ;
        }

        color = (value >= 0) ? color : 'bg-grey-300' ;

        const css = `font-medium px-8 rounded-lg ${color}` ;

        value = <span className={ css }>{value}</span>;
        return (
        <TableRow>
            <TableCell className='px-4 font-medium'>{ label }</TableCell>
            <TableCell align='right'>{ value }</TableCell>
        </TableRow>);
    };

    getLocale = () => this.props.locale.views.sectors ;
}

Sectors.defaultProps =
{
    ...ThingGrid.defaultProps,
    addButtonProps : { color : 'secondary' } ,
    animate   : false ,
    badge     : true ,
    clazz     : Sector ,
    deletable : true ,
    divider   : false ,
    icon      : <BoardIcon/>,
    member    : 'sectors' ,
    variant   : 'full'
};

Sectors.propTypes =
{
    ...ThingGrid.propTypes,
};

export default withWidth()( withStyles( styles )( withRouter( withConfig( withi18n( withDialogs( Sectors ) ) ) ) ) ) ;
