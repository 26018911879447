import React, { Fragment } from 'react'

import PropTypes  from 'prop-types'

import clsx from 'clsx'

import isBoolean from 'vegas-js-core/src/isBoolean'
import isString  from 'vegas-js-core/src/isString'
import compose   from 'vegas-js-core/src/functors/compose'
import isNotNull from 'vegas-js-core/src/isNotNull'
import get       from 'vegas-js-core/src/objects/get'
import set       from 'vegas-js-core/src/objects/set'
import ucFirst   from 'vegas-js-core/src/strings/ucFirst'

import moment from 'moment'

import MuiPhoneInput from 'material-ui-phone-number'

import {
    Divider,
    Fab,
    FormControlLabel,
    InputAdornment,
    Switch,
    TextField,
    Typography,
} from '@material-ui/core'

import { withStyles } from '@material-ui/core/styles'

import grey from '@material-ui/core/colors/grey'

import {
    TextValidator,
    ValidatorForm
} from 'react-material-ui-form-validator'

import {
    DatePicker,
    DateTimePicker,
    MuiPickersUtilsProvider
}
from '@material-ui/pickers'

import MomentUtils from '@date-io/moment'

import '../../components/inputs/styles/phone.css'

import withConfig  from '../../contexts/config/withConfig'
import withDialogs from '../../contexts/dialogs/withDialogs'
import withi18n    from '../../contexts/i18n/withi18n'

import ColorDialog       from '../dialogs/ColorDialog'
import ColorInput        from '../../components/inputs/ColorInput'
import Container         from '../../components/containers/Container'
import HoursInput        from '../inputs/HoursInput'
import Map               from '../../display/maps/Map'
import MultiLangInput    from '../../components/inputs/MultiLangInput'
import SearchSelector    from '../selectors/SearchSelector'
import Selector          from '../selectors/Selector'
import ThesaurusSelector from '../selectors/ThesaurusSelector'

import FormElements from './FormElements'

import GeoCoordinates from '../../things/GeoCoordinates'
import Place          from '../../things/Place'
import Thing          from '../../things/Thing'

const styles = theme => (
{
    divider :
    {
        marginBottom : theme.spacing(1),
        marginTop    : theme.spacing(2)
    },
    form :
    {
        display  : 'flex',
        flexWrap : 'wrap'
    },
    switchLabel :
    {
        padding      : '0px 8px',
        marginBottom : theme.spacing(1),
        marginTop    : theme.spacing(1),
        width        : '100%'
    }
});

const colorStyle =
{
    borderRadius    : 6,
    display         : 'flex',
    alignItems      : 'center',
    justifyContent  : 'center',
    backgroundColor : grey[200] ,
    padding         : 4 ,
    width           : 20,
    height          : 20
};

class Form extends Container
{
    changeDate = key => date =>
    {
        let { thing } = this.props ;
        if( thing )
        {
            thing[key] = date ? date.format( 'YYYY-MM-DD' ) : null ;
        }
        if( this._mounted )
        {
            this.forceUpdate();
        }
        const { onChange } = this.props ;
        if( onChange )
        {
            onChange( thing , this.props ) ;
        }
    };

    changeDateTime = key => ( date , update = true )  =>
    {
        let { thing } = this.props ;
        if( thing )
        {
            thing[key] = date ;
        }
        if( update && this._mounted )
        {
            this.forceUpdate();
        }
        const { onChange } = this.props ;
        if( onChange )
        {
            onChange( thing , this.props ) ;
        }
    };

    // -----------

    changeImage = item =>
    {
        console.log( this + ' change image' , item ) ;
        return null ;
    };

    // -----------

    changeInput = ( name , populate = null ) => event =>
    {
        const { target } = event ;
        if( target )
        {
            const { value } = target ;

            // console.log( this + ' changeInput' , name , value ) ;

            const { thing } = this.props ;
            if( thing )
            {
                set( thing , name , value ) ;
            }

            if( populate instanceof Function )
            {
                populate( thing , { name , value , props:this.props } ) ;
            }

            const { onChange } = this.props ;

            if( this._mounted )
            {
                this.forceUpdate( () =>
                {
                    if( onChange )
                    {
                        onChange( thing , this.props ) ;
                    }
                });
            }
            else if( onChange )
            {
                onChange( thing , this.props ) ;
            }
        }
    };

    // -----------

    changeI18nEditor = name => value =>
    {
        let { thing } = this.props ;
        if( thing )
        {
            thing[name] = value ;
        }

        if( this._mounted )
        {
            this.forceUpdate();
        }

        const { onChange } = this.props ;
        if( onChange )
        {
            onChange( thing , this.props ) ;
        }
    };

    // -----------

    changeMapMarker = ( latitude , longitude ) =>
    {
        const { thing } = this.props ;
        if( thing instanceof GeoCoordinates )
        {
            thing.latitude  = latitude ;
            thing.longitude = longitude ;
        }
        else if( thing instanceof Place )
        {
            let { geo } = thing ;
            if( !(geo instanceof GeoCoordinates) )
            {
                geo = new GeoCoordinates() ;
            }
            geo.latitude  = latitude ;
            geo.longitude = longitude ;
            thing.geo = geo ;
        }
        if( this._mounted )
        {
            this.forceUpdate();
        }

        const { onChange } = this.props ;
        if( onChange )
        {
            onChange( thing , this.props ) ;
        }
    };

    // -----------

    changePhoneNumber = name => value =>
    {
        let { thing } = this.props ;
        if( thing )
        {
            thing[name] = value ;
        }
        if( this._mounted )
        {
            this.forceUpdate();
        }
        const { onChange } = this.props ;
        if( onChange )
        {
            onChange( thing , this.props ) ;
        }
    };

    // -----------

    changeSwitch = name => event =>
    {
        const { target } = event ;
        if( target )
        {
            const { checked } = target ;
            const { thing } = this.props ;
            if( thing && thing.hasOwnProperty(name) )
            {
                thing[name] = !!(checked) ;
            }
            if( this._mounted )
            {
                this.forceUpdate();
            }
            const { onChange } = this.props ;
            if( onChange )
            {
                onChange( thing , this.props ) ;
            }
        }
    };

    // -----------

    getColorTextField = element =>
    {
        if( element )
        {
            let {
                defaultValue,
                init
            } = element ;

            const {
                errors,
                disabled,
                thing
            } = this.props;

            const { id } = init ;

            let value = defaultValue ;

            if( thing && thing.hasOwnProperty(id) && (thing[id] !== null) )
            {
                value = get( thing, id ) ;
            }

            const color = (isString(value) && value !== '') ? "#" + value : "#000000";
            const hash  = '#' ;

            const style = { ...colorStyle , color } ;

            return (
                <TextField
                    { ...init }
                    InputProps =
                    {{
                        inputComponent : ColorInput,
                        startAdornment : (
                            <InputAdornment position="start">
                                <Fab
                                    size="small"
                                    style={{ backgroundColor: color }}
                                    onClick={ () => this.openColorDialog(id) }
                                >
                                    <Typography style={style}>
                                        { hash }
                                    </Typography>
                                </Fab>
                            </InputAdornment>
                        )
                    }}
                    key        = { id }
                    name       = { id }
                    disabled   = { disabled }
                    error      = { Boolean(errors && errors[id]) }
                    onChange = { this.changeInput(id) }
                    value    = { value || '' }
                />
            );
        }

        return null ;
    };

    // -----------

    getDate = element =>
    {
        if( element )
        {
            const {
                errors,
                disabled,
                thing
            } = this.props;

            let {
                defaultValue,
                init,
            } = element ;

            // console.log( this + ' getDate' , init ) ;

            const { id , key } = init ;
            if( !(thing[id] instanceof Date) )
            {
                switch( true )
                {
                    case isString(thing[id]) :
                    {
                        thing[id] = new Date(thing[id]) ;
                        break ;
                    }
                    case defaultValue === 'today' :
                    {
                        thing[id] = new Date() ;
                        break ;
                    }
                    case defaultValue instanceof Date :
                    {
                        thing[id] = defaultValue ;
                        break ;
                    }
                    default :
                    {
                        thing[id] = null  ;
                        break ;
                    }
                }
            }

            const { minDate } = element ;
            let minimum = '0000-01-01';
            if( minDate && (minDate !== id) && (thing[minDate] instanceof Date) )
            {
                minimum = thing[minDate] ;
                if( thing[id] instanceof Date )
                {
                    if( thing[id].valueOf() < thing[minDate].valueOf() )
                    {
                         thing[id] = new Date(thing[minDate].valueOf()) ;
                    }
                }
            }

            return (
                <DatePicker
                    { ...init }
                    className = "mt-20"
                    key       = { key }
                    disabled  = { disabled }
                    error     = { Boolean(errors && errors[id]) }
                    labelFunc = { ( date , invalidLabel ) =>
                    {
                        return date ? ucFirst(moment(date).format( element.format || 'L' ))
                                    : ucFirst(invalidLabel);
                    }}
                    minDate   = { minimum }
                    onChange  = { this.changeDate(id) }
                    value     = { thing[id] }
                />
            );
        }
        return null ;
    };

    getDateTime = element =>
    {
        if( element )
        {
            const {
                errors,
                disabled,
                thing
            } = this.props;

            let {
                defaultValue,
                init,
                onChange
            }
            = element ;

            const { id , key } = init ;

            if( !(thing[id] instanceof Date) )
            {
                switch( true )
                {
                    case isString(thing[id]) :
                    {
                        thing[id] = new Date(thing[id]) ;
                        break ;
                    }
                    case defaultValue === 'today' :
                    {
                        thing[id] = new Date() ;
                        break ;
                    }
                    case defaultValue instanceof Date :
                    {
                        thing[id] = defaultValue ;
                        break ;
                    }
                    case defaultValue instanceof Function :
                    {
                        thing[id] = defaultValue( thing ) ;
                        break ;
                    }
                    default :
                    {
                        thing[id] = null ;
                        break ;
                    }
                }
            }

            const { minDate } = element ;

            let minimum = '1900-01-01';
            if( minDate && (minDate !== id) && (thing[minDate] instanceof Date) )
            {
                minimum = thing[minDate] ;
                if( thing[id] instanceof Date )
                {
                    if( thing[id].valueOf() < thing[minDate].valueOf() )
                    {
                        thing[id] = new Date(thing[minDate].valueOf()) ;
                    }
                }
            }

            return (
                <DateTimePicker
                    { ...init }
                    className = "mt-20"
                    key       = { key }
                    ampm      = { false }
                    disabled  = { disabled }
                    error     = { !!(errors && errors[id]) }
                    labelFunc = { ( date , invalidLabel ) =>
                    {
                        return date ? ucFirst(moment(date).format( element.format || 'L - LT' ))
                                    : ucFirst(invalidLabel);
                    }}
                    minDate   = { minimum }
                    value     = { thing[id] }
                    onChange  = { date =>
                    {
                        date = date ? date.toDate() : null ;
                        if ( onChange )
                        {
                            onChange( thing , date ) ;
                            if ( this._mounted )
                            {
                                this.forceUpdate();
                            }
                        }
                        else
                        {
                            this.changeDateTime(id)( date ) ;
                        }
                    }}
                />
            );
        }
        return null ;
    };

    getDivider = ( element , index = 0 ) =>
    {
        if( element )
        {
            const { classes } = this.props ;

            const { init } = element ;

            let key = index ;

            if( init && init.key )
            {
                key = init.key ;
            }

            let options = { className:clsx("w-full",classes.divider), ...init } ;

            return <Divider key={ key } { ...options  } />;
        }
        return null ;
    };

    getElement = ( element , index ) =>
    {
        if( element )
        {
            let { visible } = element ;

            if( visible instanceof Function )
            {
                visible = visible( { element , index , props:this.props } ) ;
            }
            else if( !isBoolean(visible) )
            {
                visible = true ;
            }

            if( visible === true )
            {
                let { type } = element ;
                switch( type )
                {
                    case Form.COLOR_TEXTFIELD     : return this.getColorTextField( element );
                    case Form.DATE                : return this.getDate( element ) ;
                    case Form.DATETIME            : return this.getDateTime( element ) ;
                    case Form.DIVIDER             : return this.getDivider( element , index ) ;
                    case Form.HOURS               : return this.getHours( element ) ;
                    case Form.I18N_EDITOR         : return this.getI18nEditor( element ) ;
                    case Form.IMAGE               : return this.getImage( element ) ;
                    case Form.MAP_GEO             : return this.getMapGeo( element ) ;
                    case Form.PHONE_NUMBER        : return this.getPhoneNumber( element ) ;
                    case Form.SEARCH_SELECTOR     : return this.getSearchSelector( element );
                    case Form.SELECTOR            : return this.getSelector( element );
                    case Form.SWITCH              : return this.getSwitch( element );
                    case Form.TEXTFIELD           : return this.getTextField( element );
                    case Form.TEXTFIELD_VALIDATOR : return this.getTextFieldValidator( element ) ;
                    case Form.THESAURUS_SELECTOR  : return this.getThesaurusSelector( element ) ;
                    default                       : return null ;
                }
            }
        }
        return null ;
    };

    getHours = ( element , index = 0 ) =>
    {
        if( element )
        {
            const {
                disabled,
                thing
            }
            = this.props;

            const { init } = element ;

            let key = index ;

            if( init && init.key )
            {
                key = init.key ;
            }

            return (
            <HoursInput
                { ...init }
                disabled  = { disabled }
                key       = { key }
                value     = { thing }
            />
            );
        }
        return null ;
    };

    getI18nEditor = element =>
    {
        if( element )
        {
            const { thing } = this.props;

            let { init } = element ;

            const { id } = init ;

            return (
               <MultiLangInput
                   { ...init }
                   key             = { id }
                   onChangeContent = { this.changeI18nEditor(id) }
                   value           = { thing[id] }
               />
            );
        }
        return null  ;
    };

    getImage = ( element ) =>
    {
        if (element)
        {
            // const { thing } = this.props;
            //
            // let { init } = element ;
            //
            // const { uri } = init ;

            // return (
            //     <Image
            //         onChange = { this.changeImage }
            //         thing    = { thing }
            //         uri      = { uri }
            //     />
            // );
        }

        return null ;
    };

    getMapGeo = element =>
    {
        if( element )
        {
            const { config, thing } = this.props;

            let { init } = element ;

            let latitude;
            let longitude;

            if( thing instanceof Place )
            {
                const { geo } = thing ;
                if( geo )
                {
                    const { latitude:lat , longitude:long } = geo ;
                    latitude  = lat ;
                    longitude = long ;
                }
            }
            else if( thing instanceof GeoCoordinates )
            {
                latitude  = thing.latitude ;
                longitude = thing.longitude ;
            }

            //console.log( this + " getMapGeo" , latitude , longitude ) ;

            if( !latitude )
            {
                latitude = config.map.latitude;
            }

            if( !longitude )
            {
                longitude = config.map.longitude;
            }

            return(
            <Map
                { ...config.map }
                fly             = { true }
                fullscreenMode  = { false }
                gpsFixed        = { true }
                latitude        = { latitude  }
                longitude       = { longitude }
                marker          = { true }
                markerDraggable = { true }
                markerMove      = { this.changeMapMarker }
                searchOptions   = {{ showResultIcons : false , defaultMarkGeocode : false }}
                { ...init }
            />);
        }
        return null ;
    };

    getPhoneNumber = element =>
    {
        if( element )
        {
            const {
                config,
                thing
            } = this.props;

            const { phone } = config ;

            const { init } = element ;

            const { id } = init ;

            return (
                <MuiPhoneInput
                    className          = 'w-full'
                    key                = { id }
                    defaultCountry     = { phone.default }
                    excludeCountries   = { phone.exclude }
                    onlyCountries      = { phone.only }
                    preferredCountries = { phone.preferred }
                    regions            = { phone.regions }
                    onChange           = { this.changePhoneNumber(id) }
                    value              = { thing[id] }
                    { ...init }
                />
            );
        }
        return null ;
    };

    getSearchSelector = element =>
    {
        if( element )
        {
            const {
                errors,
                disabled,
                thing
            } = this.props;

            let {
                avatarMode = 'auto' ,
                clazz ,
                clearable ,
                clearLabel,
                dropSuggestions ,
                iconifiable = true ,
                init,
                logics ,
                path,
                populate,
                queries,
                searchable = true ,
                searchIcon ,
                selectable,
                selector,
                sort,
                sortable,
                suggestionLabel ,
                title,
                valueLabel,
                ...rest
            }
            = element ;

            const { id , key } = init ;

            let others =
            {
                ...( clazz && { clazz } ),
                ...( searchIcon && { searchIcon } ),
                ...rest
            } ;

            //console.log( this + " getSearchSelector" , selector ) ;

            let value ;
            if( thing )
            {
                value = get( thing , id ) ;
            }

            return (
                <SearchSelector
                    key               = { key }
                    avatarMode        = { avatarMode }
                    clazz             = { clazz }
                    clearable         = { clearable }
                    clearLabel        = { clearLabel }
                    defaultValue      = { value }
                    disabled          = { disabled }
                    dropSuggestions   = { dropSuggestions }
                    error             = { Boolean(errors && errors[id]) }
                    iconifiable       = { iconifiable }
                    init              = { init }
                    logics            = { logics }
                    queries           = { queries }
                    searchable        = { searchable }
                    selectable        = { selectable }
                    selector          = { selector }
                    sort              = { sort }
                    sortable          = { sortable }
                    title             = { title }
                    rel               = { thing }
                    uri               = { path }
                    onChange          = { this.changeInput( id , populate ) }
                    suggestionLabel   = { suggestionLabel }
                    valueLabel        = { valueLabel }
                    { ...others }
                />
            );
        }
        return null ;
    };

    getSelector = element =>
    {
        if( element )
        {
            const {
                errors,
                disabled,
                thing
            } = this.props;

            const {
                init,
                label ,
                options
            }
            = element ;

            const { id , key } = init ;

            let value ;
            if( thing )
            {
                value = get( thing , id ) ;
            }

            return (
                <Selector
                    key      = { key || id }
                    disabled = { disabled }
                    error    = { Boolean(errors && errors[id]) }
                    init     = { init }
                    label    = { label }
                    rel      = { thing }
                    onChange = { this.changeInput(id) }
                    options  = { options }
                    value    = { value }
                />
            );
        }
        return null ;
    };

    getSwitch = element =>
    {
        if( element )
        {
            const {
                classes,
                disabled,
                thing
            } = this.props;

            const { init } = element ;

            if( init )
            {
                const {
                    id,
                    label,
                    key
                } = init ;

                return (
                    <FormControlLabel
                        key       = { key }
                        className = { classes.switchLabel }
                        control   = {
                            <Switch
                                disabled = { disabled }
                                checked  = { (thing && thing.hasOwnProperty(id)) ? !!(thing[id]) : false }
                                onChange = { this.changeSwitch(id) }
                            />
                        }
                        label = { label }
                    />
                );
            }
        }
        return null ;
    };

    getTextField = element =>
    {
        if( element )
        {
            const {
                errors,
                disabled,
                thing
            } = this.props;

            let { checkValue, defaultValue, init } = element ;

            const { id, children, ...options } = init ;

            let multiple = false ;

            const { SelectProps } = init ;
            if( SelectProps && SelectProps.multiple === true )
            {
                multiple = true ;
            }

            let value = defaultValue ;

            if( thing )
            {
                value = get( thing , id ) ;
            }

            if( checkValue instanceof Function )
            {
                value = checkValue( value ) ;
            }

            // console.log( this + " getTextField" , id , value , thing );

            if( value === 0 )
            {
                value = '0';
            }

            if( !value )
            {
                value = multiple ? [] : '' ;
            }

            return (
                <TextField
                    { ...options }
                    key      = { id }
                    name     = { id }
                    disabled = { disabled }
                    error    = { Boolean(errors && errors[id]) }
                    onChange = { this.changeInput(id) }
                    value    = { value }
                >
                    { children }
                </TextField>
            );
        }
        return null  ;
    };

    getTextFieldValidator = element =>
    {
        if( element )
        {
            const {
                disabled,
                thing
            } = this.props;

            let { defaultValue, init } = element ;

            const { id, children, ...options } = init ;

            let value = defaultValue ;

            if( thing && thing.hasOwnProperty(id) && (thing[id] !== null) )
            {
                value = thing[id] ;
            }

            return (
                <TextValidator
                    { ...options }
                    key      = { id }
                    name     = { id }
                    disabled = { disabled }
                    onChange = { this.changeInput(id) }
                    value    = { value || '' }
                >
                    { children }
                </TextValidator>
            );
        }
        return null  ;
    };

    getThesaurusSelector = element =>
    {
        if( element )
        {
            const {
                errors,
                disabled,
                lang,
                thing
            } = this.props;

            let {
                defaultValue,
                init,
                path
            } = element ;

            const { id , key } = init ;

            let value = defaultValue ;

            if( thing && thing.hasOwnProperty(id) && (thing[id] !== null) )
            {
                if( thing[id] instanceof Thing )
                {
                    value = thing[id].getLocaleName(lang)
                }
            }

            return (
                <ThesaurusSelector
                    key      = { key }
                    disabled = { disabled }
                    error    = { Boolean(errors && errors[id]) }
                    init     = { init }
                    uri      = { path }
                    onChange = { this.changeInput(id) }
                    value    = { value || '' }
                />
            );
        }

        return null ;
    };

    openColorDialog = id =>
    {
        const { addDialog } = this.props ;
        if( addDialog instanceof Function )
        {
            let { thing } = this.props;

            let color = null;

            if ( thing && thing[id] && isString( thing[id] ) )
            {
                color = "#" + thing[id];
            }

            addDialog(
                ColorDialog,
                {
                    color, id ,
                    onChange : ( color, id ) =>
                    {
                        const { thing } = this.props;
                        if ( thing )
                        {
                            if ( isString( color ) && color.indexOf( "#" ) === 0 )
                            {
                                color = color.substring( 1 );
                            }
                            thing[id] = color;
                            if ( this._mounted )
                            {
                                this.forceUpdate();
                            }
                        }
                    }
                }
            );
        }
    };

    render()
    {
        let { elements } = this.props;
        if( (elements instanceof Array) && ( elements.length > 0 ) )
        {
            elements = elements
                     .map( this.getElement )
                     .filter( isNotNull );

            const {
                classes,
                lang,
                onError,
                onSubmit
            } = this.props;

            return (
                <Fragment>
                    <MuiPickersUtilsProvider
                        locale = { lang }
                        utils  = { MomentUtils }
                    >
                        <ValidatorForm
                            className = { classes.form }
                            onError   = { onError }
                            onSubmit  = { ( event ) =>
                            {
                                event.preventDefault();
                                if( onSubmit instanceof Function )
                                {
                                    onSubmit() ;
                                }
                            }}
                        >
                            { elements }
                        </ValidatorForm>
                    </MuiPickersUtilsProvider>
                </Fragment>
            )
        }
        return null ;
    }
}

Form.COLOR_TEXTFIELD     = FormElements.COLOR_TEXTFIELD ;
Form.DATE                = FormElements.DATE ;
Form.DATETIME            = FormElements.DATETIME ;
Form.DIVIDER             = FormElements.DIVIDER ;
Form.HOURS               = FormElements.HOURS ;
Form.I18N_EDITOR         = FormElements.I18N_EDITOR;
Form.IMAGE               = FormElements.IMAGE;
Form.MAP_GEO             = FormElements.MAP_GEO ;
Form.PHONE_NUMBER        = FormElements.PHONE_NUMBER ;
Form.SELECTOR            = FormElements.SELECTOR ;
Form.SEARCH_SELECTOR     = FormElements.SEARCH_SELECTOR ;
Form.SWITCH              = FormElements.SWITCH ;
Form.TEXTFIELD           = FormElements.TEXTFIELD ;
Form.TEXTFIELD_VALIDATOR = FormElements.TEXTFIELD_VALIDATOR ; // https://www.npmjs.com/package/react-material-ui-form-validator
Form.TIME                = FormElements.TIME ;
Form.THESAURUS_SELECTOR  = FormElements.THESAURUS_SELECTOR ;

Form.defaultProps =
{
    elements : null ,
    disabled : false ,
    errors   : null ,
    name     : 'form',
    onChange : null,
    onError  : null,
    onSubmit : () => { } ,
    thing    : null
};

Form.propTypes =
{
    elements : PropTypes.oneOfType([PropTypes.array,PropTypes.func]),
    disabled : PropTypes.bool,
    errors   : PropTypes.oneOfType([PropTypes.string,PropTypes.object]),
    onChange : PropTypes.func,
    onError  : PropTypes.func,
    onSubmit : PropTypes.func,
    name     : PropTypes.string,
    thing    : PropTypes.object
};

export default compose(
    withStyles( styles ),
    withDialogs,
    withConfig,
    withi18n
)
( Form )  ;
