export const FORM              = 'form' ;
export const SWIPEABLE         = 'swipeable' ;
export const TEXT              = 'text' ;
export const THESAURUS_CHECKER = 'thesaurus_checker' ;

export const dialogTypes =
[
    FORM,
    SWIPEABLE,
    TEXT,
    THESAURUS_CHECKER
] ;

export default
{
    FORM,
    SWIPEABLE,
    TEXT,
    THESAURUS_CHECKER
}