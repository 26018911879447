const disease =
{
    helmet :
    {
        title : 'Maladie - {0}' ,
        metas : []
    },
    about :
    {
        text :
        {
            title    : 'Texte' ,
            tooltips :
            {
                refresh : 'Annuler' ,
                save    : 'Enregister'
            }
        },
        alternateName :
        {
            title    : 'Titre' ,
            tooltips :
            {
                refresh : 'Annuler' ,
                save    : 'Enregister'
            }
        },
        description :
        {
            title    : 'Description' ,
            tooltips :
            {
                refresh : 'Annuler' ,
                save    : 'Enregister'
            }
        },
        notes :
        {
            title    : 'Notes' ,
            tooltips :
            {
                refresh : 'Annuler' ,
                save    : 'Enregister'
            }
        }
    },
    loading  : "Chargement ..." ,
    notFound : "Maladie inconnu" ,
    toolbar  :
    {
        about : 'À propos'
    },
    tooltips :
    {
        back : 'Toutes les maladies'
    },
    labels :
    {
        empty : '----' ,
        name  : 'Nom' ,
        type  : 'Type de maladie'
    }
};

export default disease ;