const calendar =
{
    agenda :
    {
        title : 'Agenda',
        icon  : 'view_agenda'
    },
    day :
    {
        title : 'Jour',
        icon  : 'view_day'
    },
    month :
    {
        title : 'Mois',
        icon  : 'view_module'
    },
    week :
    {
        title : 'Semaine',
        icon  : 'view_week'
    },
    work_week :
    {
        title : 'Semaine',
        icon  : 'view_array'
    },
    title : 'Agenda',
    today : 'Aujourd\'hui'
};

export default calendar ;