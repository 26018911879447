import compose from 'vegas-js-core/src/functors/compose'

import initApp from '../../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import Authorities from '../Authorities'
import { styles }  from '../ThingChildren'

class Organizer extends Authorities
{
    getLocale = () => this.props.locale.things.event.organizer ;
}

Organizer.defaultProps =
{
    ...Authorities.defaultProps ,
    member : 'organizer'
};

Organizer.propTypes =
{
    ...Authorities.propTypes
};

export default compose( withStyles( styles ) , withRouter , initApp )( Organizer ) ;