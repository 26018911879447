import Thing from './Thing'

import clean from 'vegas-js-core/src/objects/clean'

/**
 * Make generic the passed-in object.
 * @param {boolean} [cleanup=false] - Indicates if generic object representation of the passed-in object is cleaned.
 * @function
 * @param {Thing|Array} thing - the Thing or an Array of Thing objects to generify.
 * @returns {Object} The generic object representation of the passed-in object.
 */
const generify = ( thing , cleanup = false ) =>
{
    if( !!(thing) )
    {
        if ( thing instanceof Thing )
        {
            thing = thing.toObject() ;
            if( cleanup )
            {
                thing = clean(thing) ;
            }
        }
        else if ( thing instanceof Array )
        {
            thing = thing.map( item =>
            {
                item = item instanceof Thing ? item.toObject() : item ;
                if( cleanup )
                {
                    item = clean(item) ;
                }
                return item ;
            }) ;
        }
        
    }
    return thing ;
};

export default generify ;