import clean    from 'vegas-js-core/src/objects/clean'

import Disease from '../medical/Disease'
import Thing   from '../Thing'
import Word    from '../Word'

import generify from '../generify'

/**
 * A medical hypothesis definition.
 * @memberOf things.medical
 * @extends Thing
 */
class MedicalHypothesis extends Thing
{
    /**
     * Creates a new MedicalHypothesis instance.
     * @constructor
     * @param object The generic object to initialize the object.
     */
    constructor( object = null )
    {
        super() ;
    
        /**
         * The hypothetical causes.
         * @type {Array}
         */
        this.causes = null ;
        
        /**
         * The list of diseases
         * @type {Array}
         */
        this.diseases = null ;
        
        this.set( object ) ;
    }
    
    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        super.clear() ;
        this.causes   =
        this.diseases = null ;
        return this ;
    }
    
    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new MedicalHypothesis( this.toObject() ) ;
    }
    
    /**
     * Populate the object with specific behaviors or types. Launched by default in the constructor and in the set methods.
     * @returns {Thing} The current reference.
     */
    populate()
    {
        let {
            causes,
            diseases
        } = this ;
        
        if( causes instanceof Array )
        {
            this.causes = causes.map( item => item instanceof Word ? item : new Word(item) ) ;
        }
        
        if( diseases instanceof Array )
        {
            this.diseases = diseases.map( item => item instanceof Disease ? item : new Disease(item) ) ;
        }
        
        return this ;
    }
    
    /**
     * Returns the generic object representation of the Object.
     * @param {boolean} [full=false] - Indicates if the toObject returns a full generic object or a basic representation.
     * @return {Object} The generic object representation of the Object.
     */
    toObject( full = false )
    {
        let {
            description,
            causes,
            diseases
        } = this ;

        causes = generify( causes   , true ) ;
        
        if ( diseases instanceof Array )
        {
            diseases = diseases.map( item => clean(item instanceof Disease ? item.toObject(false) : item) ) ;
        }

        return {
            ...( full && super.toObject() ),
            description,
            causes,
            diseases
        };
    }
    
}

export default MedicalHypothesis ;