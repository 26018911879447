import React from 'react'

import compose  from 'vegas-js-core/src/functors/compose'
import isString from 'vegas-js-core/src/isString'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import initApp from '../../../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { Chip, Tooltip, Typography } from '@material-ui/core'

import grey from '@material-ui/core/colors/grey'

import LensIcon  from '@material-ui/icons/Lens'
import StepIcon  from '@material-ui/icons/PinDrop'

import ThingCardChildren, { styles } from '../../ThingCardChildren'

import api from '../../../../configs/api'

import Step   from '../../../../things/Step'
import Game   from '../../../../things/Game'
import Thing  from '../../../../things/Thing'
import Word   from '../../../../things/Word'

class CourseQuest extends ThingCardChildren
{
    createChild = child =>
    {
        if( child )
        {
            let { about } = child ;
            return (
            <div className='w-full flex justify-start pt-16'>
                { this.getStep( about ) }
            </div> );
        }
        return null ;
    };

    getCardIcon = child => this.getAvatar( child , { marginRight : 8 } ) ;

    getCardLabel = child =>
    {
        if( child instanceof Thing )
        {
            const { lang } = this.props ;

            let label = child.getLocaleAlternateName(lang) ;

            if( !isString(label) || label === '' )
            {
                label = child.getLocaleName(lang) ;
            }

            if( !isString(label) || label === '' )
            {
                label = this.getLocale().unknown ;
            }

            if( isString(label) )
            {
                if( isString(label) )
                {
                    return ucFirst(label) ;
                }
            }
        }
        return this.props.cardLabel ;
    };

    getCardSubLabel = child =>
    {
        if( child instanceof Thing )
        {
            const { lang } = this.props ;
            let label = child.getLocaleAlternateName(lang) ;
            if( isString(label) && label !== '' )
            {
                label = child.getLocaleName( lang ) ;
                if( isString(label) )
                {
                    if( isString(label) )
                    {
                        return (
                        <Typography variant='caption'>
                            { ucFirst(label) }
                        </Typography>
                        ) ;
                    }
                }
            }
        }
        return this.props.cardSubLabel ;
    };

    getStep = step =>
    {
        if( step instanceof Step )
        {
            const { history, lang } = this.props ;

            let {
                headline,
                name,
                status ,
                url
            } = step ;

            let label ;
            let icon ;

            if( headline )
            {
                label = ucFirst(step.getLocaleHeadline(lang)) ;
            }

            if( status instanceof Word )
            {
                let { color } = status ;
                if( isString( color ) )
                {
                    color = '#' + color ;
                }
                else
                {
                    color = grey[200];
                }

                icon = <LensIcon style={{ color }}/> ;

                let tip = status.getLocaleName(lang) ;
                if( isString(tip) && tip !== '' )
                {
                    icon = (
                    <Tooltip
                        placement = 'top'
                        title     = { ucFirst(tip) }
                    >
                        { icon }
                    </Tooltip>);
                }
            }

            if( !label && name )
            {
                label = ucFirst(step.getLocaleName(lang)) ;
            }

            return (
                <Chip
                    color     = 'default'
                    icon      = { icon }
                    label     = { label }
                    onClick   = { () => history.push ( url.split(api.url)[1] ) }
                />
            )
        }
        return null;
    };

    getLocale = () => this.props.locale.things.game.quests.course ;

    onCardClick = child  => () =>
    {
        if( child instanceof Thing )
        {
            const { url } = child ;
            if( isString(url) )
            {
                const { history } = this.props ;
                history.push( url.split(api.url)[1] );
            }
        }
    };

    prepare = item =>
    {
        let {
            name,
            about
        } = item ;

        if( about instanceof Thing )
        {
            about = String(about.id)
        }
        else
        {
            about = null ;
        }

        return ({ about , name });
    };
}

CourseQuest.defaultProps =
{
    ...ThingCardChildren.defaultProps ,
    activable      : false ,
    animate        : true ,
    clazz          : Game,
    containerProps :
    {
        className : "bg-transparent w-full px-16",
        elevation : 0,
        square    : true
    },
    badge    : true ,
    icon     : <StepIcon/>,
    itemGrid :
    {
        xl : 12 ,
        lg : 12 ,
        md : 12 ,
        sm : 12 ,
        xs : 12
    },
    member    : 'quest',
    removeUri : api.games.questions.url
};

CourseQuest.propTypes =
{
    ...ThingCardChildren.propTypes
};

export default compose( withStyles( styles ) , withRouter , initApp )( CourseQuest ) ;
