import React from 'react';

import clsx from 'clsx'

import { withRouter } from 'react-router-dom'

import format from 'vegas-js-core/src/strings/fastformat'

import Activities        from '../../../display/things/stage/Activities'
import ThingSubContainer from '../../../display/containers/ThingSubContainer'
import Websites          from '../../../display/things/Websites'

class More extends ThingSubContainer
{
    render = () =>
    {
        const { thing } = this.props ;

        if( thing )
        {
            const {
                className,
                path,
                style
            } = this.props;

            const { id } = thing ;

            const content = (
                <div
                    className = { clsx( 'flex-1 p-12 space-y-24' , className ) }
                    style     = { style }
                >
                    <Activities
                        optionMode = 'edit'
                        onChange   = { this.changeProperty('activities') }
                        thing      = { thing }
                        editUri    = { format(path.activities, id ) }
                    />
                    <Websites
                        onChange = { this.change }
                        thing    = { thing }
                        uri      = { format(path.websites, id )  }
                    />
                </div>
            );
            
            return this.renderWithTransition( content ) ;
        }
        return null ;
    }
}

More.defaultProps =
{
    ...ThingSubContainer.defaultProps
};

More.propTypes =
{
    ...ThingSubContainer.propTypes
};

export default withRouter( More ) ;
