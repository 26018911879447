import React from 'react'

import ListIcon from '@material-ui/icons/List'

import DiseasesList from '../../../display/lists/DiseasesList'

import TabsView from '../../../display/views/TabsView'

import initView from '../../../display/views/initView'

import api from '../../../configs/api'
import Disease from '../disease/Disease'

const styles = () => ({}) ;

const path = api.diseases ;

const { url:uri } = path ;

class Diseases extends TabsView
{
    getLocale = () => this.props.locale.views.medical.diseases ;
}

Diseases.defaultProps =
{
    ...TabsView.defaultProps,
    first  : 'list' ,
    path ,
    routes :
    [
        {
            id         : 'list' ,
            Component  : DiseasesList ,
            backPath   :'/list' ,
            preferable : true ,
            uri
        } ,
        {
            id        : 'disease' ,
            Component : Disease ,
            path : uri + '/:id'
        }
    ],
    tabs :
    [
        { id : 'list' , icon : <ListIcon /> }
    ]
};

Diseases.propTypes =
{
    ...TabsView.propTypes
};

export default initView( Diseases , styles ) ;
