import notEmpty from 'vegas-js-core/src/strings/notEmpty'

import getPreferredMediaSource from './getPreferredMediaSource'

import Thing from './Thing'

/**
 * Returns the string content url defines by the image property of a specific thing.
 * @param {Thing} thing - The thing reference.
 * @returns {null|string} The string content url defines by the image property of a specific thing.
 */
const getImageSource = thing =>
{
    if( thing instanceof Thing )
    {
        let src;
        const { image } = thing ;
        if( image )
        {
            const { contentUrl } = image;
            if ( notEmpty( contentUrl )  )
            {
                const source = getPreferredMediaSource( image ) ;
                if( source )
                {
                    const { contentUrl : sourceContentUrl } = source ;
                    src = sourceContentUrl ;
                }
            }
            else if ( notEmpty(image) )
            {
                src = image ;
            }
        }
        if( notEmpty(src) )
        {
            return src ;
        }
    }
    return null ;
};

export default getImageSource ;
