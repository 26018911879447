import React from 'react'

import { withRouter } from 'react-router-dom'

import LocalHospitalIcon from '@material-ui/icons/LocalHospital'

import Counter from './Counter'

import withi18n from '../../contexts/i18n/withi18n'

export class VeterinaryCounter extends Counter 
{
    getLocale = () => this.props.locale.display.counters.veterinarians;
}
    
VeterinaryCounter.defaultProps =
{
    ...Counter.defaultProps,
    avatarSetting : { empty : "bg-gray-500" , filled : "bg-green-600" } ,
    icon          : <LocalHospitalIcon className="mr-8" fontSize='small' />,
    linkable      : true ,
    member        : 'veterinarians' ,
    path          : '{0}/veterinarians'
}

VeterinaryCounter.propTypes =
{
    ...Counter.propTypes
}

export default withRouter( withi18n( VeterinaryCounter ) ) ;