const token =
{
    helmet :
    {
        title : 'Chargement du jeton de connexion' ,
        metas : []
    },
    loading : 'Patientez ...' ,
    title   : process.env.REACT_APP_TITLE
};

export default token ;