import React from 'react'

import PropTypes  from 'prop-types'

import Container from './Container'

import isTouchDevice from '../../dom/isTouchDevice'

class ScrollContainer extends Container
{
    constructor( props )
    {
        super( props ) ;
        this.scrolling = null ;
        this.ticking   = false ;
    }

    init = () =>
    {
        const { capture , passive } = this.props ;
        if( isTouchDevice() )
        {
            window.addEventListener( 'touchmove', this.__scroll , { capture , passive } );
        }
        else
        {
            window.addEventListener( 'scroll', this.__scroll , { capture , passive } );
        }
    };

    render()
    {
        const {
            children,
            className,
            style
        } = this.props ;
        return (
        <div
            className = { className }
            style     = { style }
        >
            { children }
        </div>);
    }

    scroll = event =>
    {
        const { scrollable } = this.props ;
        if( scrollable )
        {
            const {
                onScroll,
                onScrollBottom,
                onScrollTop,
                scrollRef
            }
            = this.props ;

            let element = scrollRef || this._element ;

            const { scrollingElement } = event ;
            if( scrollingElement )
            {
                element = scrollingElement;
            }

            if( element )
            {
                const {
                    //clientHeight,
                    offsetHeight,
                    scrollHeight,
                    scrollTop
                }
                = element ;

                if( onScroll )
                {
                    onScroll( scrollTop, offsetHeight, element, this.props , this.state ) ;
                }

                //const isBottom1 = Math.ceil(offsetHeight + scrollTop) === scrollHeight ;
                //const isBottom2 = ( scrollHeight - scrollTop ) === clientHeight ;

                const isBottom = scrollTop >= (scrollHeight - offsetHeight - 12) ; // use 12px to working on mobile

                //console.log( this + " __scroll element:" , isBottom1 , isBottom2 , isBottom ) ;

                if( scrollTop <= 0 )
                {
                    //console.log( this + ' __scroll top');
                    if( onScrollTop )
                    {
                        onScrollTop( this.props , this.state ) ;
                    }
                }
                else if ( isBottom && onScrollBottom )
                {
                    onScrollBottom( this.props , this.state ) ;
                }
            }
        }
    }

    unmount = () =>
    {
        window.removeEventListener( 'scroll' , this.__scroll , true );
    };

    // -------------------

    __scroll = event =>
    {
        const { onScrollStart } = this.props ;
        if( onScrollStart instanceof Function && this.scrolling === null )
        {
            onScrollStart( this.props , this.state ) ;
        }

        clearTimeout( this.scrolling ) ;

        if( !this.ticking )
        {
            requestAnimationFrame( () =>
            {
                this.ticking = false ;
                this.scroll( event ) ;
            })

            this.ticking = true ;
        }

        this.scrolling = setTimeout(() =>
        {
            this.scrolling = null ;
            const { onScrollFinish } = this.props ;
            if( onScrollFinish )
            {
                onScrollFinish( this.props , this.state ) ;
            }
        } ,200 ) ;
    };
}

ScrollContainer.defaultProps =
{
    capture        : true,
    children       : null,
    className      : null,
    passive        : false,
    scrollable     : true,
    onScroll       : null,
    onScrollBottom : null,
    onScrollFinish : null,
    onScrollStart  : null,
    onScrollTop    : null,
    scrollRef      : null,
    style          : null,
};

ScrollContainer.propTypes =
{
    capture        : PropTypes.bool,
    children       : PropTypes.node,
    className      : PropTypes.string,
    passive        : PropTypes.bool,
    scrollable     : PropTypes.bool,
    onScroll       : PropTypes.func,
    onScrollBottom : PropTypes.func,
    onScrollFinish : PropTypes.func,
    onScrollStart  : PropTypes.func,
    onScrollTop    : PropTypes.func,
    scrollRef      : PropTypes.object,
    style          : PropTypes.object
};

export default ScrollContainer ;