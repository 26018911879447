import React from 'react'

import LivestocksList from '../../display/lists/LivestocksList'

import initView from '../../display/views/initView'
import View     from '../../display/views/View'

const styles = () => ({}) ;

class Livestocks extends View
{
    build = () =>
    (
        <div className='flex flex-col w-full'>
            <LivestocksList parent = { this.element } />
        </div>
    );

    getLocale = () => this.props.locale.views.livestocks ;
}

Livestocks.defaultProps =
{
    ...View.defaultProps
};

Livestocks.propTypes =
{
    ...View.propTypes
};

export default initView( Livestocks , styles );