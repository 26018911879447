import initDialog from '../initDialog'

import { AddDialog } from '../AddDialog'

import Livestock from '../../../things/Livestock'
import Thing     from '../../../things/Thing'

import api from '../../../configs/api'

const styles = () => ({});

export class AddLivestockDialog extends AddDialog
{
    getLocale = () => this.props.locale.dialogs.application.livestock.add ;

    prepare = item =>
    {
        if( item instanceof Livestock )
        {
            const { organization } = item ;
            return (
            {
                organization : organization instanceof Thing ? String(organization.id) : null
            });
        }
        return null ;
    };
}

AddLivestockDialog.defaultProps =
{
    ...AddDialog.defaultProps,
    uri : api.livestocks.url
};

AddLivestockDialog.propTypes =
{
    ...AddDialog.propTypes
};

export default initDialog({ styles })( AddLivestockDialog ) ;
