import React from 'react'

import isString from 'vegas-js-core/src/isString'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import FeedbackIcon from '@material-ui/icons/Feedback'

import ThingView , { defaultStyle } from '../../display/views/ThingView'

import About  from './views/About'
import Review from './views/Review'

import api   from '../../configs/api'
import paths from '../../configs/paths'

import AnimalHealthObservation from '../../things/livestock/observations/AnimalHealthObservation'
import Thing                   from '../../things/Thing'

import initView from '../../display/views/initView'

const styles = defaultStyle();

const iconDimension = { width:30 , height:30 } ;

class Observation extends ThingView
{
    getIcon = () =>
    {
        const {
            config,
            icon,
            lang
        } = this.props ;
        const { thing } = this.state ;
        if( thing )
        {
            const { subject } = thing ;
            if( subject )
            {
                const { breeding } = subject ;
                if( breeding )
                {
                    let { image } = breeding ;
                    if( isString( image ) )
                    {
                        return (
                        <img
                            { ...iconDimension }
                            alt = { breeding.getLocaleName(lang) }
                            src = { image + "?w=" + config.avatar.maxWidth }
                        />) ;
                    }
                }
            }
        }
        return icon ;
    };

    getLocale = () => this.props.locale.views.observation ;

    getSubtitle = thing =>
    {
        if( thing instanceof Thing )
        {
            const { additionalType } = thing ;
            if( additionalType instanceof Thing )
            {
                const { lang } = this.props ;
                return additionalType.getLocaleName(lang) ;
            }
        }
        return null ;
    };

    getTitle = thing =>
    {
        let title = null ;
        if( thing )
        {
            const { lang } = this.props ;
            const { alternateName , name } = thing ;

            if( alternateName )
            {
                title = ucFirst(thing.getLocaleAlternateName(lang))  ;
            }

            if( (!isString(title) || title === '') && name )
            {
                title = ucFirst(thing.getLocaleName(lang))  ;
            }
        }
        return isString(title) && title !== '' ? title : null ;
    };

    onBack = () =>
    {
        let url ;

        const { thing } = this.state ;

        if( thing )
        {
            const { about, subject } = thing ;
            let target ;

            if( subject instanceof Thing )
            {
                target = subject ;
            }
            else if( about instanceof Thing )
            {
                target = about ;
            }

            if( target )
            {
                const { url:uri } = target ;
                if( isString(uri) )
                {
                    url = uri.split(api.url)[1] + '/observations'
                }
            }
        }

        const { history } = this.props ;
        if( history && isString(url) )
        {
            history.push( url )
        }
    };

    populateOnChange = thing =>
    {
        const { clazz } = this.props ;
        if( clazz && !( thing instanceof clazz) )
        {
            thing = new clazz(thing) ;
        }
        return thing ;
    }
}

Observation.defaultProps =
{
    ...ThingView.defaultProps,
    back       : true ,
    clazz      : AnimalHealthObservation,
    icon       : <FeedbackIcon/>,
    path       : api.observations,
    preferable : false ,
    responsive : true  ,
    routes     :
    [
        {
            id             : 'about' ,
            RouteComponent : About
        },
        {
            id             : 'review' ,
            RouteComponent : Review
        }
    ],
    tabs :
    [
        { id : 'about'  } ,
        { id : 'review' }
    ]
};

Observation.propTypes =
{
    ...ThingView.propTypes,
    path : paths.observations
};

export default initView( Observation , styles ) ;
