import React , { Component } from 'react'

import PropTypes  from 'prop-types'

import SnackContext from './SnackContext'

import { withStyles } from '@material-ui/core/styles'

import SnackVariant from '../../components/snackbars/SnackVariant'
import SnackMessage from '../../components/snackbars/SnackMessage'

const styles = () => ({});

class SnackProvider extends Component
{
    constructor(props)
    {
        super(props);
        this.state =
        {
            snack       : null,
            closeSnack  : this.close,
            notifySnack : this.notify
        };
    }
    
    close = () =>
    {
        this.setState({ snack:null });
    };
    
    notify = ( message , variant = SnackVariant.DEFAULT ) =>
    {
        this.setState({ snack:{ message , variant } });
    };
    
    render()
    {
        const {
            autoHideDuration,
            children,
            horizontal,
            vertical
        } = this.props;
        
        const { snack } = this.state ;
        
        let message ;
        let variant ;
        
        if( snack )
        {
            const { message:m, variant:v } = snack ;
            message = m ;
            variant = v ;
        }
        
        return (
        <SnackContext.Provider value={ this.state }>
            { children }
            <SnackMessage
                autoHideDuration = { autoHideDuration }
                horizontal       = { horizontal }
                message          = { message }
                open             = { !!(snack) }
                onClose          = { this.close }
                variant          = { variant }
                vertical         = { vertical }
            />
        </SnackContext.Provider>
        )
    }
}

SnackProvider.defaultProps =
{
    autoHideDuration : 6000 ,
    horizontal       : 'left' ,
    vertical         : 'bottom'
};

SnackProvider.propTypes =
{
    autoHideDuration : PropTypes.number ,
    horizontal       : PropTypes.string,
    vertical         : PropTypes.string
};

export default withStyles(styles)(SnackProvider) ;