import notEmpty       from 'vegas-js-core/src/arrays/notEmpty';
import getMediaSource from './getMediaSource';

const getPreferredMediaSource = media =>
{
    if( media )
    {
        const source = getMediaSource( media ) ;

        if( notEmpty( source ) )
        {
            return source[0] ;
        }
    }

    return null ;
}

export default getPreferredMediaSource ;
