import React from 'react'

import PropTypes from 'prop-types'

import { VelocityComponent } from 'velocity-react'
import 'velocity-animate/velocity.ui'

const Animate = props =>
{
    const children = React.cloneElement( props.children ,
    {
        style: { ...props.children.style , visibility : 'hidden' }
    });
    
    return <VelocityComponent {...props} children={children}/> ;
};

Animate.propTypes =
{
    children: PropTypes.element.isRequired
};

Animate.defaultProps =
{
    animation          : 'transition.fadeIn',
    begin              : null ,
    complete           : null ,
    delay              : 50,
    display            : null,
    duration           : 300,
    interruptBehavior  : 'stop',
    easing             : [0.4, 0.0, 0.2, 1],
    runOnMount         : true,
    targetQuerySelector: null,
    visibility         : 'visible'
};

export default Animate;
