import Form from '../../../../display/forms/FormElements'

const common =
[
    {
        type : Form.I18N_EDITOR ,
        init :
        {
            id    : 'headline' ,
            key   : 'headline' ,
            input : true,
            label : 'Titre'
        }
    },
    {
        type : Form.I18N_EDITOR ,
        init :
        {
            id    : 'alternativeHeadline',
            key   : 'alternativeHeadline',
            input : true,
            label : 'Texte alternatif',
        }
    },
    {
        type : Form.I18N_EDITOR ,
        init :
        {
            id    : 'caption',
            key   : 'caption',
            input : true,
            label : 'Légende'
        }
    },
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id         : 'license',
            key        : 'license',
            label      : 'Licence',
            autoFocus  : false ,
            fullWidth  : true ,
            helperText : null ,
            margin     : 'normal' ,
            required   : false ,
            select     : false ,
            type       : 'text',
            variant    : 'outlined'
        }
    },
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id         : 'author',
            key        : 'author',
            label      : 'Auteur',
            autoFocus  : false ,
            fullWidth  : true ,
            helperText : null ,
            margin     : 'normal' ,
            required   : false ,
            select     : false ,
            type       : 'text',
            variant    : 'outlined'
        }
    },
    {
        type : Form.TEXTFIELD ,
        init :
            {
                id         : 'editor',
                key        : 'editor',
                label      : 'Éditeur',
                autoFocus  : false ,
                fullWidth  : true ,
                helperText : null ,
                margin     : 'normal' ,
                required   : false ,
                select     : false ,
                type       : 'text',
                variant    : 'outlined'
            }
    },
    {
        type : Form.I18N_EDITOR ,
        init :
        {
            id    : 'description' ,
            key   : 'description' ,
            input : true,
            label : 'Description'
        }
    }
];

const audio =
[
    ...common
];

const image =
[
    ...common
];

const pdf =
[
    ...common
];

const prepare = item =>
{
    let {
        author,
        alternativeHeadline,
        caption,
        description,
        editor,
        headline,
        license,
        mentions,
        publisher,
        review,
        text,
        transcript
    } = item ;

    return (
    {
        author,
        alternativeHeadline,
        caption,
        description,
        editor,
        headline,
        license,
        mentions,
        publisher,
        review,
        text,
        transcript
    });
};

const media =
{
    agree      : 'Modifier' ,
    disagree   : 'Fermer' ,
    expandLess : 'Réduire' ,
    expandMore : 'Agrandir' ,
    open       : 'Ouvrir dans une nouvelle page' ,
    remove     : 'Supprimer',
    labels     :
    {
        created   : 'Téléversé le :' ,
        dimension : 'Dimensions :' ,
        filesize  : 'Taille du fichier :' ,
        link      : 'Adresse web du fichier' ,
        modified  : 'Modifié le :' ,
        type      : 'Type du fichier : '
    },
    tooltips   :
    {
        remove : "Supprimer définitivement ?"
    },
    audio,
    image,
    pdf,
    prepare
};

export default media ;
