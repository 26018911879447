import React from 'react'

import initApp from '../../../../../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import blueGrey from '@material-ui/core/colors/blueGrey'

import { FaFileMedicalAlt } from 'react-icons/fa'

import ChipsContainer, { styles } from '../../../../../containers/ChipsContainer'

import generify from '../../../../../../things/generify'

import AnnualHealthReview from '../../../../../../things/livestock/reviews/AnnualHealthReview'
import SectorHealthEvent  from '../../../../../../things/livestock/events/SectorHealthEvent'
import Thing              from '../../../../../../things/Thing'
import Word               from '../../../../../../things/Word'

const css = () => (
{
    ...styles,
    chip :
    {
        backgroundColor : blueGrey[100],
        margin          : 8
    }
});

class Symptoms extends ChipsContainer
{
    getEntry = init =>
    {
        const { subjectOf } = this.props ;

        let empty = {} ;

        if( init instanceof Thing )
        {
            empty = init.toObject() ;
        }
        else if( init )
        {
            empty = { ...init } ;
        }

        return new SectorHealthEvent( { ...empty , subjectOf } ) ;
    };

    getLocale = () => this.props.locale.things.livestock.reviews.annual.medical.symptoms ;

    editInit = ( item ) =>
    {
        if( item )
        {
            const { symptoms } = item ;
            if( symptoms instanceof Array )
            {
                return symptoms.map( element =>
                {
                    if( element instanceof Word )
                    {
                        return element.clone() ;
                    }
                    return null ;
                })
            }
        }
        return null ;
    };

    onEdit = ( item ) =>
    {
        const { onChange } = this.props ;
        if( onChange )
        {
            onChange( item ) ;
        }
    };

    editPrepare = ( datas , props ) =>
    {
        const { item } = props ;
        if( item )
        {
            let { subjectOf:review } = item ;
            if( review instanceof AnnualHealthReview )
            {
                review = review.toObject() ;
                const { about , id:key } = item ;
                if( about )
                {
                    const { id } = about ;
                    if( id )
                    {
                        let { subReview } = review ;
                        if( subReview instanceof Array )
                        {
                            let len1 = subReview.length ;
                            for( let i = 0 ; i<len1 ; i++ )
                            {
                                let workplace = subReview[i] ;
                                if( workplace )
                                {
                                    let { subReview } = workplace ;
                                    if( subReview instanceof Array )
                                    {
                                        let len2 = subReview.length ;
                                        for( let j = 0 ; j<len2 ; j++  )
                                        {
                                            let sectorReview = subReview[j] ;
                                            if( sectorReview )
                                            {
                                                let { about, events } = sectorReview ;
                                                if( about && (events instanceof Array) )
                                                {
                                                    let { id:index } = about ;
                                                    if( index === id )
                                                    {
                                                        let len3 = events.length ;
                                                        for( let k = 0 ; k<len3 ; k++ )
                                                        {
                                                            let event = events[k] ;
                                                            if( event && event.id )
                                                            {
                                                                if( key === event.id )
                                                                {
                                                                    if( datas )
                                                                    {
                                                                        if( datas instanceof Array && datas.length === 0 )
                                                                        {
                                                                            datas = null ;
                                                                        }
                                                                        datas = generify(datas,true) ;
                                                                    }
                                                                    event.symptoms = datas ;
                                                                    review = JSON.stringify(review) ;
                                                                    return { review } ;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        return null ;
    };
}

Symptoms.defaultProps =
{
    ...ChipsContainer.defaultProps ,
    chipVariant : 'default' ,
    icon        : <FaFileMedicalAlt size={20}/>,
    member      : 'symptoms'
};

Symptoms.propTypes =
{
    ...ChipsContainer.propTypes
};

export default withStyles( css ) ( withRouter( initApp( Symptoms ) ) ) ;
