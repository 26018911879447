import api          from '../../../../configs/api'
import Form         from '../../../../display/forms/FormElements'
import Organization from '../../../../things/Organization'

import getExistingSuggestions from '../../../../things/getExistingSuggestions'

const elements =
[
    {
        type  : Form.SEARCH_SELECTOR ,
        path  : api.organizations.url ,
        clazz : Organization,
        disabledSuggestions : getExistingSuggestions({ member:[ 'parentOrganization' , 'subOrganization' ] , include:true , clazz:Organization }) ,
        init  :
        {
            id         : 'value',
            key        : 'value',
            label      : 'Organisation',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            required   : true,
            type       : 'search'
        },
        selector : { i18n :
        {
            empty       : 'Aucune organisation disponible.' ,
            title       : 'Sélectionner une organisation',
            description : 'Veuillez sélectionner une organisation ci-dessous.' ,
        }}
    }
]

const parentOrganization =
{
    add :
    {
        elements,
        agree       : 'Ajouter' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez sélectionner une organisation parente en cliquant ci-dessous.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en  cours...' ,

        success : 'Ajout d\'une nouvelle organisation réussi!' ,
        title   : 'Ajouter une organisation'
    },
    remove :
    {
        agree       : 'Supprimer' ,
        description : 'Souhaitez-vous supprimer cette organisation parente:' ,
        disagree    : 'Annuler' ,
        loading     : 'Suppression en cours...' ,
        title       : 'Supprimer l\'organisation ?'
    },
    title : 'Organisations parentes' ,
    tooltips :
    {
        add    : "Ajouter" ,
        edit   : "Éditer" ,
        remove : "Supprimer"
    }
};

export default parentOrganization ;