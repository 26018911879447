import React     from 'react'

import PropTypes from 'prop-types'

import { DragDropContext , Draggable , Droppable } from 'react-beautiful-dnd'

import api from '../../configs/api'

import PATCH         from '../../net/PATCH'
import RequestStatus from '../../net/RequestStatus'

import ThingChildren from './ThingChildren'

export { styles } from './ThingChildren'

class ThingSortableChildren extends ThingChildren
{
    getItem = editable => ( child , index ) =>
    {
        if( this.isValid(child) )
        {
            const { id } = child ;

            const { itemClassName, member } = this.props ;
            const { draggable } = this.state ;

            let options = this.getItemOptions( { child, editable } ) ;

            return (
                <Draggable
                    key            = { member + '_' + id }
                    draggableId    = { member + '_' + id }
                    index          = { index }
                    isDragDisabled = { !draggable }
                >
                    { provided => (
                        <div
                            ref = { provided.innerRef }
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                        >
                            <div className={itemClassName}>
                                { this.createChild( child , editable ) }
                                { options &&
                                <div style={{ width:64 }}>
                                    { options }
                                </div>}
                            </div>
                            { provided.placeholder }
                        </div>
                    )}
                </Draggable>
            ) ;
        }
        return null ;
    };

    getItems = children =>
    {
        if( (children instanceof Array) && (children.length > 0) )
        {
            return children.map( this.getItem( this.isEditable() ) ) ;
        }
        return null ;
    };

    onDragEnd = result =>
    {
        const { destination , source } = result || {}  ;

        if( !destination )
        {
            return ; // dropped outside the list
        }

        const { member , thing } = this.props ;

        if( thing && thing.hasOwnProperty(member)  )
        {
            let children = thing[member] ;
            if( children instanceof Array && children.length > 0 )
            {
                let datas ;

                const sorted = [ ...children ] ;

                const [ removed ] = sorted.splice( source.index , 1 ) ;

                sorted.splice( destination.index , 0 , removed ) ;

                thing[member] = sorted ;

                let { sortPrepare } = this.props  ;
                let { sortable : { prepare } = {} } = this.getLocale() || {} ;
                prepare = prepare || sortPrepare || this.sortPrepare ;
                if( prepare instanceof Function )
                {
                    datas = prepare(
                        thing ,
                        {
                            from   : source.index ,
                            target : removed ,
                            to     : destination.index ,
                            props  : this.props
                        }
                    ) ;
                }

                this.setState( { draggable:false , sortStatus:RequestStatus.PROGRESS } ) ;

                this.canceler = PATCH(
                    api.url + this.getPath()  ,
                    {
                        datas ,
                        cancel  : this._orderCancel,
                        fail    : this._orderFail,
                        success : this._orderSuccess
                    }
                );
            }
        }
    };

    renderChildren = children =>
    {
         return(
            <DragDropContext onDragEnd={ this.onDragEnd }>
                <Droppable droppableId = 'droppable' >

                    { ( provided ) => (
                        <div
                            { ...provided.droppableProps }
                            ref = { provided.innerRef }
                        >
                            { children }
                        </div>
                    )}

                </Droppable>
            </DragDropContext>
        );
    };

    sortPrepare = null ;
}

ThingSortableChildren.defaultProps =
{
    ...ThingChildren.defaultProps ,
    itemClassName : "flex flex-row items-center justify-center bg-white mt-4 pl-8 shadow"
};

ThingSortableChildren.propTypes =
{
    ...ThingChildren.propTypes,
    itemClassName : PropTypes.string,
    sortPrepare   : PropTypes.func
};

export default ThingSortableChildren ;
