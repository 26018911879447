import React from 'react'

import PropTypes from 'prop-types'

import compose  from 'vegas-js-core/src/functors/compose'
import notEmpty from 'vegas-js-core/src/strings/notEmpty'

import initApp from '../../contexts/app/initApp'
import withSnack from '../../contexts/snack/withSnack'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { withWidth, IconButton, Tooltip } from '@material-ui/core'

import SpeedDial from '@material-ui/lab/SpeedDial'
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon'
import SpeedDialAction from '@material-ui/lab/SpeedDialAction'

import AddIcon from '@material-ui/icons/Add'
import PageviewIcon from '@material-ui/icons/Book'

import POST          from '../../net/POST'
import RequestStatus from '../../net/RequestStatus'

import ThingOrderableChildren, { styles } from './ThingOrderableChildren'

import api from '../../configs/api'

import RemoveDiscoverDialog from '../dialogs/remove/RemoveDiscoverDialog'

import SelectArticleDialog          from '../dialogs/select/SelectArticleDialog'
import SelectConceptualObjectDialog from '../dialogs/select/SelectConceptualObjectDialog'
import SelectCourseDialog           from '../dialogs/select/SelectCourseDialog'
import SelectEventDialog            from '../dialogs/select/SelectEventDialog'
import SelectOrganizationDialog     from '../dialogs/select/SelectOrganizationDialog'
import SelectPersonDialog           from '../dialogs/select/SelectPersonDialog'
import SelectPlaceDialog            from '../dialogs/select/SelectPlaceDialog'
import SelectStageDialog            from '../dialogs/select/SelectStageDialog'

import getThingCellComponent from './getThingCellComponent'

import Thing from '../../things/Thing'

const dialogs =
{
    article          : SelectArticleDialog,
    conceptualObject : SelectConceptualObjectDialog ,
    course           : SelectCourseDialog,
    event            : SelectEventDialog ,
    organization     : SelectOrganizationDialog,
    person           : SelectPersonDialog,
    place            : SelectPlaceDialog,
    stage            : SelectStageDialog,
};

class Discover extends ThingOrderableChildren
{
    createChild = ( child , editable ) =>
    {
        let CellComponent = getThingCellComponent( child ) ;
        if( CellComponent )
        {
            let options;
            const { deletable , optionable } = this.props;
            if( optionable )
            {
                options = (
                    <div className='flex flex-row items-center'>
                        {deletable && this.getDeleteButton( child , editable )}
                    </div>
                );
            }

            return (
                <CellComponent
                    draggable = { this.state.draggable }
                    options   = { options }
                    thing     = { child }
                />
            );
        }

        return null;
    };

    getItemOptions = () => null;

    getLocale = () => this.props.locale.things.discover;

    /**
     * Returns the options elements to display in the title area.
     * @returns {Component|PureComponent|Array} the options elements to display in the title area.
     */
    getOptions = () =>
    {
        const {
            actions ,
            speedDial : { ariaLabel = "" } = {} ,
        } = this.getLocale() || {};
        if( actions instanceof Array && actions.length > 0 )
        {
            const { open } = this.state;

            const { width } = this.props ;
            if( width === 'xs' || width === 'sm' )
            {
                const { classes , speedDial , speedDialAction } = this.props;

                return <SpeedDial
                    ariaLabel = { ariaLabel }
                    className = { classes.speedDial }
                    onClose   = { this._closeActions }
                    onOpen    = { this._openActions }
                    open      = { open }
                    { ...speedDial }
                >
                    {
                        actions.map( ( { id , title , icon } , index ) => (
                            <SpeedDialAction
                                key          = { 'action-' + index }
                                icon         = { icon }
                                tooltipTitle = { title }
                                onClick      = { () =>
                                {
                                    this.setState({ open:false }) ;
                                    this.openSelectDialog( id ) ;
                                }}
                                {...speedDialAction}
                            /> ) )
                    }
                </SpeedDial>

            }
            else
            {
                return (
                    <div className='bg-white shadow-md text-gray-600 flex flex-row-reverse rounded-full items-center px-8'>
                        <AddIcon className='pl-8'/>
                        {
                            actions.map( ( { id , title , icon } , index ) =>
                            {
                                let button = (
                                    <IconButton
                                        key       = { 'button-' + index }
                                        className = 'mx-4'
                                        color     = 'secondary'
                                        size      = 'small'
                                        onClick   = { () =>
                                        {
                                            this.setState({ open:false }) ;
                                            this.openSelectDialog( id ) ;
                                        }}
                                    >
                                        { icon }
                                    </IconButton>
                                );
                                if( notEmpty(title) )
                                {
                                    button = (
                                        <Tooltip key={ 'item-' + index } title={title} placement='top'>
                                            { button }
                                        </Tooltip>
                                    );
                                }
                                return button ;
                            })
                        }
                    </div>
                )
            }

        }
        return null;
    };

    onRemove = item =>
    {
        const { snacks : { remove } = {} } = this.getLocale() || {};
        if( notEmpty( remove ) )
        {
            this.notify( remove , 'info' )
        }
        this.refreshMembers( item );
    };

    getDisabledSuggestions = () =>
    {
        const { thing } = this.props ;
        if( thing )
        {
            let list = [ thing ] ;

            const { member } = this.props ;
            if( notEmpty(member) && thing[member] instanceof Array && thing[member].length > 0 )
            {
                list = [ ...list , ...thing[member] ] ;
            }

            list = list.filter( element => element instanceof Thing ) ;

            return list ;
        }
        return null ;
    };

    openSelectDialog = id =>
    {
        const { addDialog } = this.props;
        if( addDialog )
        {
            const DialogComponent = dialogs[id];
            if( DialogComponent )
            {
                addDialog(
                    DialogComponent ,
                    {
                        disabledSuggestions : this.getDisabledSuggestions() ,
                        onSelect            : thing => { this._addElement( thing ); }
                    }
                );
            }
        }
    };

    orderPrepare = ( { target , to:position } = {} ) =>
    {
        const { url } = target || {} ;
        if( notEmpty( url ) )
        {
            return { url , position };
        }
        return null;
    };

    _addElement = ( thing , position = 0 ) =>
    {
        if( thing )
        {
            const { url } = thing;
            if( notEmpty( url ) )
            {
                const uri = this.getPath();
                if( notEmpty( uri ) )
                {
                    const datas = { url , position };

                    // console.log( this + ' _addElement' , api.url + uri , datas ) ;

                    this.setState( { addStatus:RequestStatus.PROGRESS } );
                    this.canceler = POST(
                        api.url + uri ,
                        {
                            datas ,
                            cancel  : this._addCancel ,
                            fail    : this._addFail ,
                            success : this._addSuccess
                        }
                    );
                }
            }
        }
    };

    _addCancel = () =>
    {
        this.setState({ addStatus : RequestStatus.FAIL });
    };

    _addFail = response =>
    {
        if( response )
        {
            const { data } = response;
            if( data )
            {
                const { message } = data;
                switch( message )
                {
                    case 'token revoked' :
                    {
                        console.log( this + " failed, the current token is revoked, status:" + response.status + ", message:" + message );
                        break;
                    }
                    default :
                    {
                        console.log( this + " failed, status:" + response.status + ", message:" + message );
                    }
                }
            }
        }

        if( this.mounted )
        {
            this.setState({ addStatus : RequestStatus.FAIL });
        }
    };

    _addSuccess = response =>
    {
        if( response )
        {
            const { data } = response;
            if( data )
            {
                let { result } = data;
                if( result )
                {
                    this.refreshMembers( result , { addStatus : RequestStatus.SUCCESS } );
                    const { snacks : { add } = {} } = this.getLocale() || {};
                    if( notEmpty( add ) )
                    {
                        this.notify( add , 'success' )
                    }
                }
            }
        }
    };

    _closeActions = () =>
    {
        this.setState( { open : false } );
    };

    _openActions = () =>
    {
        this.setState( { open : true } );
    };
}

Discover.defaultProps =
{
    ...ThingOrderableChildren.defaultProps ,
    icon                  : <PageviewIcon/> ,
    member                : 'discover' ,
    RemoveDialogComponent : RemoveDiscoverDialog ,
    speedDial             :
    {
        direction : 'up' ,
        icon      : <SpeedDialIcon/>
    },
    speedDialAction :
    {
        tooltipPlacement : 'left'
    }
};

Discover.propTypes =
{
    ...ThingOrderableChildren.propTypes ,
    optionDirection : PropTypes.oneOf(['down','left','right','up']) ,
    speedDialIcon   : PropTypes.element
};

export default compose(
    withWidth(),
    withStyles( styles ) ,
    withRouter,
    withSnack,
    initApp
)( Discover ) ;
