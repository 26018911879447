import React from 'react'

import PropTypes  from 'prop-types'

import clsx from 'clsx'

import moment from 'moment/moment'

import compose  from 'vegas-js-core/src/functors/compose'
import isString from 'vegas-js-core/src/isString'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import initApp from '../../../../contexts/app/initApp'

import { withStyles } from '@material-ui/core/styles'

import { Grid } from '@material-ui/core'

import Container from '../../../../components/containers/Container'
import LabelItem from '../../../../components/items/LabelItem'

import VaccinationEventVO from '../../../../things/medical/events/VaccinationEvent'
import Word               from '../../../../things/Word'

const styles = () => (
{
    root :
    {
        padding : 12,
        width   : '100%'
    }
});

class VaccinationEvent extends Container
{
    render = () =>
    {
        const {
            aboutVisible,
            dateVisible,
            classes,
            className,
            gridProps,
            inline,
            thing
        } = this.props ;

        if( thing instanceof VaccinationEventVO && ( aboutVisible || dateVisible) )
        {
            const { lang } = this.props ;

            const locale = this.getLocale() ;

            let {
                about,
                date
            }
            = thing ;

            about = about instanceof Word ? ucFirst(about.getLocaleName(lang)) : about.empty ;

            if( isString( date ) )
            {
                date = moment(date).format(locale.datePattern)
            }
            else
            {
                date = locale.empty ;
            }

            return (
            <div className={ clsx(classes.root,className) }>

                <Grid container spacing={4}>

                    {
                        aboutVisible &&
                        <Grid item {...gridProps}>
                            <LabelItem inline={inline} title={ locale.about }>
                                { about }
                            </LabelItem>
                        </Grid>
                    }
                    {
                        dateVisible &&
                        <Grid item {...gridProps}>
                            <LabelItem inline={inline} title = { locale.date } >
                                { date }
                            </LabelItem>
                        </Grid>
                    }
                </Grid>

            </div>
            );
        }

        return null ;
    };

    getLocale = () => this.props.locale.things.livestock.events.vaccinationEvent ;
}

VaccinationEvent.defaultProps =
{
    ...Container.defaultProps,
    aboutVisible : true ,
    dateVisible  : true ,
    editable     : false ,
    gridProps    :
    {
        md : 6 ,
        sm : 6 ,
        xs : 12
    },
    inline  : false,
    onClick : null ,
    thing   : null
};

VaccinationEvent.propTypes =
{
    ...Container.propTypes,
    aboutVisible : PropTypes.bool,
    dateVisible  : PropTypes.bool,
    editable     : PropTypes.bool,
    gridProps    : PropTypes.object,
    inline       : PropTypes.bool,
    onClick      : PropTypes.func,
    thing        : PropTypes.object
};

export default compose( withStyles( styles ) , initApp )( VaccinationEvent ) ;
