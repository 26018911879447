import React from 'react'

import GroupAddIcon      from '@material-ui/icons/GroupAdd'
import LocalHospitalIcon from '@material-ui/icons/LocalHospital'
import MemoryIcon        from '@material-ui/icons/Work'
import PeopleIcon        from '@material-ui/icons/People'

import Technician    from '../../things/Technician'
import Veterinarian  from '../../things/Veterinarian'
import PropertyValue from '../../things/PropertyValue'

import ThingChildren from './ThingChildren'

import AuthorityLabel from './observation/AuthorityLabel'

class MedicalAuthorities extends ThingChildren
{
    createChild = child =>
    {
        let icon ;
        switch( true )
        {
            case child instanceof Technician :
            {
                icon = <MemoryIcon/>;
                break ;
            }
            case child instanceof Veterinarian :
            {
                icon = <LocalHospitalIcon/>;
                break ;
            }
            default :
            {
                //
            }
        }
        return <AuthorityLabel icon={icon} thing={child} />
    };
}

MedicalAuthorities.defaultProps =
{
    ...ThingChildren.defaultProps ,
    addIcon    : <GroupAddIcon/>,
    clazz      : [ Veterinarian , Technician ] ,
    emptyClazz : PropertyValue,
    emptyIcon  : null,
    icon       : <PeopleIcon/>,
    member     : 'authority'
};

MedicalAuthorities.propTypes =
{
    ...ThingChildren.propTypes
};

export default MedicalAuthorities ;