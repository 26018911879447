import React from 'react'

import moment from 'moment'

import isInt    from 'vegas-js-core/src/isInt'
import isString from 'vegas-js-core/src/isString'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import withConfig  from '../../contexts/config/withConfig'
import withDialogs from '../../contexts/dialogs/withDialogs'
import withi18n    from '../../contexts/i18n/withi18n'

import { withRouter } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'

import { Grid } from '@material-ui/core'

import CourseIcon from '@material-ui/icons/Map'
import LabelIcon  from '@material-ui/icons/Label'

import LabelItem from '../../components/items/LabelItem'

import ThingContainer from '../containers/ThingContainer'

import CourseVO from '../../things/Course'
import ThingVO  from '../../things/Thing'
import WordVO   from '../../things/Word'

const styles = () => ({});

const half =
{
    md :  6 ,
    sm :  6 ,
    xs : 12
};

class Course extends ThingContainer
{
    editPrepare = item =>
    {
        let {
            additionalType,
            audience,
            distance,
            level,
            path,
            status,
            name
        } = item ;

        try
        {
            return (
            {
                distance ,
                name,
                additionalType : additionalType instanceof ThingVO ? String(additionalType.id) : null ,
                audience       : audience       instanceof ThingVO ? String(audience.id) : null ,
                level          : level          instanceof ThingVO ? String(level.id)    : null ,
                path           : path           instanceof ThingVO ? String(path.id)     : null ,
                status         : status         instanceof ThingVO ? String(status.id)   : null
            });
        }
        catch( error )
        {
            console.log( this + ' editPrepare failed, ' , error ) ;
        }

        return null ;
    };

    getContent = () =>
    {
        let { lang, thing } = this.props;

        const locale = this.getLocale() ;

        const { labels } = locale ;

        let audience ;
        let distance ;
        let indicator ;
        let level ;
        let path ;
        let name ;
        let statusCourse ;
        let type ;

        if( thing instanceof CourseVO )
        {
            let {
                additionalType,
                audience : a ,
                distance : d ,
                level    : l ,
                path     : p ,
                status ,
                name : n,
            } = thing ;

            if( additionalType instanceof ThingVO )
            {
                type = additionalType.getLocaleName( lang ) ;
            }

            if( isString( n ) )
            {
                name = n ;
            }

            if( a instanceof WordVO )
            {
                audience = a.getLocaleName( lang ) ;
            }

            if( isInt( d ) )
            {
                distance = d ;
            }

            if( l instanceof WordVO )
            {
                level = l.getLocaleName( lang ) ;
            }

            if( p instanceof WordVO )
            {
                path = p.getLocaleName( lang ) ;
            }

            if( status instanceof WordVO )
            {
                const { color } = status ;
                if( isString( color ) )
                {
                    indicator = (
                    <LabelIcon
                        className = "mr-8 self-center"
                        style     = {{ color : "#" + color }}
                    />);
                }
                statusCourse = status.getLocaleName( lang )
            }
        }

        return (
        <Grid container spacing={4}>
            <Grid item {...half}>
                <LabelItem enabled={!!(name)} title={ labels.name }>
                    { isString(name) ? ucFirst(name) : labels.empty }
                </LabelItem>
            </Grid>
            <Grid item {...half}>
                <LabelItem enabled={!!(type)} title={ labels.type }>
                    { isString(type) ? ucFirst(type) : labels.empty }
                </LabelItem>
            </Grid>
            <Grid item {...half}>
                <LabelItem enabled={!!(distance)} title={ labels.distance }>
                    { isInt(distance) ? distance : labels.empty }
                </LabelItem>
            </Grid>
            <Grid item {...half}>
                <LabelItem enabled={!!(level)} title={ labels.level } >
                    { isString(level) ? ucFirst(level) : labels.empty }
                </LabelItem>
            </Grid>
            <Grid item {...half}>
                <LabelItem enabled={!!(statusCourse)} title={ labels.status } labelIcon={indicator}>
                    { isString(statusCourse) ? ucFirst(statusCourse) : labels.empty }
                </LabelItem>
            </Grid>
            <Grid item {...half}>
                <LabelItem enabled={!!(path)} title={ labels.path } >
                    { isString(path) ? ucFirst(path) : labels.empty }
                </LabelItem>
            </Grid>
            <Grid item {...half}>
                <LabelItem enabled={!!(audience)} title={ labels.audience } >
                    { isString(audience) ? ucFirst(audience) : labels.empty }
                </LabelItem>
            </Grid>

        </Grid>);
    };

    getEntry = init =>
    {
        let empty = {} ;
        if( init instanceof ThingVO )
        {
            empty = init.toObject() ;
        }
        else if( init )
        {
            empty = { ...init } ;
        }
        return new CourseVO(empty);
    };

    getLocale = () => this.props.locale.things.course;

    onEdit = ( item ) =>
    {
        const { onChange, thing } = this.props ;
        if( thing && item )
        {
            const {
                additionalType,
                audience,
                distance,
                level,
                name,
                path,
                status
            } = item ;

            thing.additionalType = additionalType ;
            thing.audience       = audience ;
            thing.distance       = distance ;
            thing.level          = level ;
            thing.modified       = moment(new Date()).toISOString();
            thing.name           = name ;
            thing.path           = path ;
            thing.status         = status ;

            if( thing instanceof ThingVO )
            {
                thing.populate();
            }

            if( this._mounted )
            {
                this.forceUpdate();
            }

            if( onChange instanceof Function )
            {
                onChange(thing) ;
            }
        }
    };
}

Course.defaultProps =
{
    ...ThingContainer.defaultProps ,
    icon       : <CourseIcon/> ,
    optionMode : 'edit'
};

Course.propTypes =
{
    ...ThingContainer.propTypes
};

export default withStyles( styles )( withRouter( withConfig( withi18n( withDialogs(Course) ) ) ) ) ;
