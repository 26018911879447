import React , { Component } from 'react'

import compose from 'vegas-js-core/src/functors/compose'
import get     from 'vegas-js-core/src/objects/get'
import set     from 'vegas-js-core/src/objects/set'

import clsx from 'clsx'

import PropTypes from 'prop-types'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles/index'

import AppBar     from '@material-ui/core/AppBar/index'
import Drawer     from '@material-ui/core/Drawer/index'
import Hidden     from '@material-ui/core/Hidden/index'
import IconButton from '@material-ui/core/IconButton/index'
import Toolbar    from '@material-ui/core/Toolbar/index'

import MenuIcon from '@material-ui/icons/Menu'

import { ThemeProvider }  from '@material-ui/styles'

import { default as AppToolbar } from '../../components/bars/Toolbar'

import DialogsProvider from '../../contexts/dialogs/DialogsProvider'
import withLocale      from '../../contexts/i18n/withLocale'
import withSettings    from '../../contexts/settings/withSettings'
import withUser        from '../../contexts/user/withUser'

import NavbarHeader from '../navigation/NavbarHeader'
import Navigation   from '../navigation/Navigation'

import styles from './styles/application'

class ApplicationLayout extends Component
{
    componentDidMount()
    {
        const { changeUser } = this.props ;
        if( changeUser instanceof Function )
        {
            const change = changeUser() ;
            change.connect( this.updater ) ;
        }
    }

    componentWillUnmount()
    {
        const { changeUser } = this.props ;
        if( changeUser instanceof Function )
        {
            const change = changeUser() ;
            change.disconnect( this.updater ) ;
        }
    }

    getThemeByType = ( type ) => type === 'light' ? this.props.lightTheme : this.props.darkTheme ;

    getFooter = () =>
    {
        const {
            classes,
            footer,
            settings
        } = this.props ;

        let theme = this.getThemeByType( get(settings,'theme.footer') ) ;

        return (
            <ThemeProvider theme={theme}>
                <AppBar id='footer' className={ clsx(classes.footerWrapper)} color="default">
                    <Toolbar className="p-0">
                        <div className={clsx(classes.footer)}>
                            { footer }
                        </div>
                    </Toolbar>
                </AppBar>
            </ThemeProvider>
        );
    };

    getNavbar = () =>
    {
        const { navigation } = this.props ;

        //console.log( this + ' getNavbar' , navigation ) ;

        let {
            classes,
            navbarFolded,
            navbarMobileOpen,
            navbarOpenFolded  ,
            navbarCloseFolded ,
            navbarCloseMobile ,
            settings
        } = this.props ;

        let navbar = get( settings , 'layout.config.navbar' ) ;

        let {
            display,
            folded
        }
        = navbar ;

        //console.log( this + ' getNavbar display:' , display ) ;

        if( display )
        {
            const header = (
            <div className={ classes.navbarHeaderWrapper }>
                <div className={ classes.navbarHeader }>
                    <NavbarHeader />
                </div>
                <Hidden mdDown>
                    <IconButton
                        className  = 'mr-4'
                        onClick    = { this.onToggleFolded }
                        aria-label = "menu-button"
                    >
                        <MenuIcon/>
                    </IconButton>
                </Hidden>
                <Hidden lgUp>
                    <IconButton
                        onClick    = { navbarCloseMobile }
                        aria-label = "menu-button"
                    >
                        <MenuIcon/>
                    </IconButton>
                </Hidden>
            </div>
            );

            const content = (
            <div className={classes.navbarContent}>
                <Navigation navigation={ navigation }/>
            </div>);

            let theme = this.getThemeByType( get(settings,'theme.navbar') ) ;

            //console.log( this + ' getNavbar', theme ) ;

            return (
            <ThemeProvider theme = { theme }>
                <div
                    id        = 'navbar'
                    className = { classes.navbarWrapper }
                >
                    <Hidden mdDown>
                        <div
                            className = { clsx(
                                classes.navbar,
                                folded && classes.navbarFolded,
                                folded &&  navbarFolded && classes.navbarFoldedOpen,
                                folded && !navbarFolded && classes.navbarFoldedClose
                            )}
                            onMouseEnter = { navbarOpenFolded }
                            onMouseLeave = { navbarCloseFolded }
                            style        = { { backgroundColor : theme.palette.background.default }}
                        >
                            { header }
                            { content }
                        </div>
                    </Hidden>

                    <Hidden lgUp>
                        <Drawer
                            anchor     = 'left'
                            variant    = 'temporary'
                            open       = { navbarMobileOpen }
                            classes    = {{ paper: classes.navbar }}
                            onClose    = { navbarCloseMobile }
                            ModalProps = {{ keepMounted : true }}
                        >
                            { header }
                            { content }
                        </Drawer>
                    </Hidden>
                </div>
            </ThemeProvider>)  ;
        }

        return null ;
    };

    getToolbar = () =>
    {
        const {
            classes,
            navbarOpenMobile,
            settings,
            toolbar
        } = this.props ;

        let navbar = get( settings , 'layout.config.navbar') ;
        let theme  = this.getThemeByType( get(settings,'theme.toolbar') ) ;

        return (
            <ThemeProvider theme={theme}>
                <AppBar
                    id        = 'toolbar'
                    className = { clsx(classes.toolbarWrapper) }
                    color     = 'default'
                >
                    <Toolbar className="p-0">

                        {
                            navbar.position === 'left' && (
                            <Hidden lgUp>
                                <IconButton
                                    className  = { clsx(classes.navbarButton, 'w-64 h-64 rounded-none', navbar.position) }
                                    aria-label = "open drawer"
                                    onClick    = { navbarOpenMobile }
                                >
                                    <MenuIcon/>
                                </IconButton>
                            </Hidden>
                        )}

                        <div className={classes.toolbar} >
                            { toolbar }
                        </div>

                        {
                            navbar.position === 'right' && (
                            <Hidden lgUp>
                                <IconButton
                                    className  = { clsx(classes.navbarButton, 'w-64 h-64 rounded-none', navbar.position) }
                                    aria-label = 'open drawer'
                                    onClick    = { navbarOpenMobile }
                                >
                                    <MenuIcon/>
                                </IconButton>
                            </Hidden>
                        )}
                    </Toolbar>
                </AppBar>
            </ThemeProvider>
        );
    };

    onToggleFolded = () =>
    {
        const { setSettings } = this.props ;
        if( setSettings )
        {
            const { settings } = this.props ;
            const navbar = get( settings , 'layout.config.navbar' ) ;
            setSettings( set( {} , 'layout.config.navbar.folded', !navbar.folded ) );
        }
    };

    render()
    {
        const {
            children,
            className,
            classes,
            settings,
            style
        }
        = this.props;

        let footer  = get( settings , 'layout.config.footer'  ) ;
        let mode    = get( settings , 'layout.config.mode'  ) ;
        let navbar  = get( settings , 'layout.config.navbar'  ) ;
        let scroll  = get( settings , 'layout.config.scroll'  ) ;
        let toolbar = get( settings , 'layout.config.toolbar' ) ;

        switch( scroll )
        {
            case 'body' :
            {
                return null ;
            }

            case 'content' :
            {
                return (
                <div
                    className = { clsx(classes.root,className) }
                    style     = { style }
                >
                    <div id="layout" className={ clsx( classes.layout , mode , 'scroll-' + scroll ) }>

                        { toolbar.display && toolbar.position === 'above' && this.getToolbar() }

                        <div className = { classes.wrapper } >

                            { navbar.display && navbar.position === 'left' && this.getNavbar() }

                            <div className = { clsx(
                                classes.contentWrapper,
                                navbar.display && navbar.folded && navbar.position === 'left' && 'lg:ml-64',
                                navbar.display && navbar.folded && navbar.position === 'right' && 'lg:mr-64'
                            )}>

                                { toolbar.display && toolbar.position === 'below' && toolbar.style === 'fixed' && this.getToolbar() }

                                <div className={ classes.content }>

                                    { toolbar.display && toolbar.position === 'below' && toolbar.style !== 'fixed' && this.getToolbar() }

                                    <DialogsProvider>
                                        { children }
                                    </DialogsProvider>

                                    { footer.display && footer.position === 'below' && footer.style !== 'fixed' && this.getFooter() }

                                </div>

                                { footer.display && footer.position === 'below' && footer.style === 'fixed' && this.getFooter() }

                            </div>

                            { navbar.display && navbar.position === 'right' && this.getNavbar() }

                        </div>
                    </div>

                    { footer.display && footer.position === 'above' && this.getFooter() }

                </div>);
            }

            default :
            {
                //
            }
        }

        return null ;
    }

    updater = () =>
    {
        this.forceUpdate() ;
    }
}

ApplicationLayout.defaultProps =
{
    className : null,
    footer    : null,
    style     : null,
    toolbar   : <AppToolbar/>
};

ApplicationLayout.propTypes =
{
    className : PropTypes.string,
    footer    : PropTypes.node,
    style     : PropTypes.object,
    toolbar   : PropTypes.node
};

export default compose (
    withSettings,
    withStyles( styles ),
    withLocale,
    withRouter,
    withUser
)( ApplicationLayout );
