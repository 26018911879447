import generify from '../../generify'

import AnimalHealthReview from './AnimalHealthReview'
import MedicalAnalysis    from '../../medical/MedicalAnalysis'
import MedicalHypothesis  from '../../medical/MedicalHypothesis'
import SectorHealthEvent  from '../events/SectorHealthEvent'
import Workplace          from '../../Workplace'

/**
 * A suspicion review.
 * @memberOf things.livestock.reviews
 * @extends things.livestock.AnimalHealthReview
 */
class SuspicionReview extends AnimalHealthReview
{
    /**
     * Creates a new SuspicionReview instance.
     * @constructor
     * @param object The generic object to initialize the object.
     */
    constructor( object = null )
    {
        super() ;
        
        /**
         * The medical analysis informations.
         * @type {MedicalAnalysis}
         */
        this.analysis = null  ;
        
        /**
         * The list of all SectorHealthEvent of this suspicion.
         * @type {Array}
         */
        this.events = null ;
        
        /**
         * The hypothesis informations.
         * @type {MedicalHypothesis}
         */
        this.hypothesis = null ;
        
        this.set( object ) ;
        this.populate() ;
    }

    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        super.clear() ;
        
        this.ambience    =
        this.events      =
        this.hypothesis  = null ;
        
        return this ;
    }

    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new SuspicionReview( this.toObject() ) ;
    }
    
    /**
     * Populate the object with specific behaviors or types. Launched by default in the constructor and in the set methods.
     * @returns {Thing} The current reference.
     */
    populate()
    {
        super.populate() ;
        
        let {
            analysis,
            events,
            hypothesis,
            subject
        } = this ;

        if( !(analysis instanceof MedicalAnalysis) )
        {
            this.analysis = new MedicalAnalysis(analysis);
        }

        if( events instanceof Array )
        {
            this.events = events.map( item =>
            {
                if( item && !(item instanceof SectorHealthEvent) )
                {
                    item = new SectorHealthEvent(item);
                    item.subjectOf = this ;
                }
                return item ;
            } ) ;
        }

        if( !(hypothesis instanceof MedicalHypothesis) )
        {
            this.hypothesis = new MedicalHypothesis(hypothesis);
        }

        if( subject && !(subject instanceof Workplace) )
        {
            this.subject = new Workplace(subject);
        }

        return this ;
    }
    /**
     * Returns the generic object representation of the Object.
     * @return {Object} The generic object representation of the Object.
     */
    toObject( full = false )
    {
        let {
            about,
            analysis,
            events,
            hypothesis,
            subject,
        } = this ;

        about      = generify(about) ;
        analysis   = generify(analysis) ;
        events     = generify(events) ;
        hypothesis = generify(hypothesis);
        subject    = generify(subject);

        return {
            ...super.toObject(full) ,
            about,
            analysis,
            events,
            hypothesis,
            subject
        };
    }
}

export default SuspicionReview ;