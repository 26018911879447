import list from './list'

const people =
{
    ...list,
    helmet :
    {
        title : 'Personnes' ,
        metas : []
    },
    empty  : 'Aucune personne trouvée',
    search : 'Rechercher une personne ...' ,
    title  : 'Personnes'
};

export default people ;
