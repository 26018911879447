import address      from './address'
import birth        from './birth'
import created      from './created'
import date         from './date'
import death        from './death'
import geo          from './geo'
import logout       from './logout'
import modified     from './modified'
import nationality  from './nationality'
import organization from './organization'
import period       from './period'
import person       from './person'
import place        from './place'
import team         from './team'
import thing        from './thing'
import word         from './word'

const labels =
{
    address,
    birth,
    created,
    date,
    death,
    geo,
    logout,
    modified,
    nationality,
    organization,
    period,
    person,
    place,
    team,
    thing,
    word
};

export default labels ;
