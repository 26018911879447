import React , { Fragment } from 'react'

import clsx from 'clsx'

import PropTypes from 'prop-types'

import TransitionGroup from '../../transitions/TransitionGroup'

import isString from 'vegas-js-core/src/isString'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import { Divider, Link, Paper, Typography } from '@material-ui/core'

import Badge, { colors } from '../../components/badges/Badge'

import AddButton         from '../../components/buttons/AddButton'
import EditButton        from '../../components/buttons/EditButton'
import RemoveButton      from '../../components/buttons/RemoveButton'
import SelectMediaButton from '../../components/buttons/SelectMediaButton'

import DialogContainer from '../../components/containers/DialogContainer'

import ThingAvatar from '../avatars/ThingAvatar'

import ThingVO from '../../things/Thing'

import Picture from '../things/Picture'

const getPicture = name => ( item , style = null , options = null , variant = 'image' ) =>
{
    if ( item instanceof ThingVO && item.hasOwnProperty(name) )
    {
        const { [name]:media } = item ;
        if( media )
        {
            return (
                <Picture
                    media   = { media }
                    style   = { style }
                    variant = { variant }
                    { ...options }
                />
            );
        }
    }
    return null ;
};

class ThingContainer extends DialogContainer
{
    constructor( props )
    {
        super( props ) ;
        this.scrollView = null ;
    }

    getAddButton = ( disabled = false , offset = undefined ) =>
    {
        if( this.isEditable() )
        {
            const { addIcon, addButtonProps } = this.props ;
            const locale = this.getLocale() ;
            return (
            <AddButton
                disabled = { disabled }
                icon     = { addIcon }
                locale   = { locale }
                { ...addButtonProps }
                onClick = { () => this.openAddDialog( { offset } ) }
            />) ;
        }
        return null ;
    };

    /**
     * Returns the element after the content view.
     * @returns {Component|PureComponent|Fragment|Array} the element after the content view.
     */
    getAfterContent = () => null ;

    getAvatar = ( thing , style = { marginRight:8 } ) => (<ThingAvatar thing={ thing } style={ style } />);

    getBadge = () =>
    {
        const { badge, badgeColor } = this.props ;
        const label = this.getBadgeLabel() ;
        if( badge && (label !== null) )
        {
            return (
                <div className="ml-12 font-thin">
                    <Badge color={Badge.checkColor(this.getBadgeColor(),badgeColor)}>{label}</Badge>
                </div>
            ) ;
        }
        return null ;
    };

    getBadgeColor = () => null ;

    getBadgeLabel = () => null ;

    /**
     * Returns the element before the content view.
     * @returns {Component|PureComponent|Fragment|Array} the element before the content view.
     */
    getBeforeContent = () => null ;

    /**
     * Returns the main content of the component.
     * @returns {Component|PureComponent|Fragment|Array} the main content of the component.
     */
    getContent = () => null ;

    /**
     * Returns a custom option element if the props.optionMode is 'custom'.
     * @returns {Component|PureComponent|Fragment|Array} A custom option element if the props.optionMode is 'custom'.
     */
    getCustomOptions = () => null ;

    getEditButton = ( disabled = false , options = null ) =>
    {
        const editable = this.isEditable() ;
        if( editable )
        {
            const locale = this.getLocale() ;
            const { editIcon } = this.props ;
            return (
            <EditButton
                disabled  = { disabled }
                icon      = { editIcon }
                locale    = { locale }
                { ...options }
                onClick   = { () => this.openEditDialog( this.getEditButtonThing() ) }
            />);
        }
        return null ;
    };

    getEditButtonThing = () => this.props.thing ;

    /**
     * Returns the icon element representation.
     * @returns {Component|PureComponent|Fragment|Array} The icon element representation.
     */
    getIcon = () => this.props.icon ;

    getImage = getPicture('image');

    getLogo = getPicture('logo');

    /**
     * Returns the options elements to display in the title area.
     * @returns {Component|PureComponent|Array} the options elements to display in the title area.
     */
    getOptions = () =>
    {
        const { optionMode } = this.props ;
        const locked = this.isLocked() ;
        switch( optionMode )
        {
            case 'add' :
            {
                return this.getAddButton( locked ) ;
            }
            case 'custom' :
            {
                return this.getCustomOptions( locked ) ;
            }
            case 'edit' :
            {
                return this.getEditButton( locked ) ;
            }
            case 'remove' :
            {
                return this.getRemoveButton( locked ) ;
            }
            case 'selectMedia' :
            {
                return this.getSelectMediaButton( locked ) ;
            }
            default :
            {
                return null ;
            }
        }
    };

    getRemoveButton = ( disabled = false , options = null ) =>
    {
        const locale = this.getLocale() ;
        const editable = this.isEditable() ;
        if( editable )
        {
            const { removeIcon } = this.props ;
            return (
            <RemoveButton
                disabled = { disabled }
                icon     = { removeIcon }
                locale   = { locale }
                { ...options }
                onClick  = { () => this.remove() }
            />);
        }
        return null ;
    };

    getSelectMediaButton = ( disabled = false ) =>
    {
        const locale = this.getLocale() ;
        const editable = this.isEditable() ;
        if( editable )
        {
            const { selectMediaIcon } = this.props ;
            return (
            <SelectMediaButton
                disabled = { disabled }
                icon     = { selectMediaIcon }
                locale   = { locale }
                onClick  = { () => this.openSelectMediaDialog() }
            />) ;
        }
        return null ;
    };

    /**
     * Returns the title component.
     * @param {null} [label=null] - The label to display in the component.
     * @returns {*} The title component reference.
     */
    getTitle = ( label = null ) =>
    {
        const locale = this.getLocale() ;
        let text ;
        if( isString( label ) )
        {
            text = label ;
        }
        else if( locale )
        {
            const { title } = locale ;
            if( isString(title) )
            {
                text = title ;
            }
        }

        if( isString(text) && text !== '' )
        {
            const {
                titleMode,
                titleProps
            }
            = this.props ;

            if( titleMode === 'edit' && this.isEditable() )
            {
                return (
                <Link
                    className = 'ml-12 font-medium text-blue-600'
                    component = 'button'
                    onClick   = { () => this.openEditDialog(this.getEditButtonThing()) }
                    variant   = 'subtitle1'
                    { ...titleProps }
                >
                    { ucFirst(text) }
                </Link>) ;
            }
            else
            {
                return (
                <Typography
                    className = "ml-12 font-medium"
                    variant   = "subtitle1"
                    { ...titleProps }
                >
                    { ucFirst(text) }
                </Typography>) ;
            }
        }
        return null ;
    };

    getTitleHeader = () =>
    {
        const { divider, header } = this.props ;
        if( header )
        {
            return(
            <Fragment>
                <div className="flex flew-row justify-between items-center">
                    <div className="flex flex-row items-center">
                        { this.getIcon() }
                        { this.getTitle() }
                        { this.getBadge() }
                    </div>
                    <div className="flex flex-row">
                        { this.getOptions() }
                    </div>
                </div>
                { divider && <Divider light className="my-16"/> }
            </Fragment>);
        }
        return null ;
    };

    remove = () => { this.openRemoveDialog( this.props.thing ) ; };

    render = () =>
    {
        let {
            animate,
            animateProps,
            className,
            containerClassName,
            ContainerComponent,
            containerProps,
            style,
            titleVisible
        } = this.props ;

        const Container = ContainerComponent || Paper ;

        let view = (
            <Container
                className = { clsx( 'flex flex-col flex-1 p-12' , containerClassName ) }
                { ...containerProps }
            >
                { titleVisible && this.getTitleHeader() }
                { this.getBeforeContent() }
                { this.getContent() }
                { this.getAfterContent() }
            </Container>
        );

        if( animate )
        {
            view = (
                <TransitionGroup
                    duration = {250}
                    enter    = {{ animation : 'transition.slideUpIn' }}
                    { ...animateProps }
                >
                    { view }
                </TransitionGroup>
            )
        }

        return (
            <div
                className = { clsx( 'flex flex-1 flex-col w-full' , className ) }
                ref       = { ref => this.scrollView = ref }
                style     = { style }
            >
                { view }
            </div>
        );
    };
}

ThingContainer.defaultProps =
{
    ...DialogContainer.defaultProps ,
    addButtonProps     : null ,
    addIcon            : null,
    animate            : false,
    animateProps       : null,
    badge              : false,
    containerClassName : null,
    containerProps     : null,
    customOption       : null,
    defaultIcon        : null,
    divider            : true,
    editIcon           : null,
    header             : true,
    icon               : null,
    optionMode         : 'add',
    removeIcon         : null,
    selectMediaIcon    : null,
    titleMode          : 'none',
    titleProps         : null,
    titleVisible       : true
};

ThingContainer.propTypes =
{
    ...DialogContainer.propTypes ,
    addButtonProps     : PropTypes.object ,
    addIcon            : PropTypes.element,
    animate            : PropTypes.bool,
    animateProps       : PropTypes.object,
    badge              : PropTypes.bool,
    badgeColor         : PropTypes.oneOf(colors),
    containerClassName : PropTypes.string,
    ContainerComponent : PropTypes.object,
    containerProps     : PropTypes.object,
    defaultIcon        : PropTypes.element,
    divider            : PropTypes.bool,
    editIcon           : PropTypes.element,
    header             : PropTypes.bool,
    icon               : PropTypes.element ,
    optionMode         : PropTypes.oneOf(['add','custom','edit','none','remove','selectMedia']),
    removeIcon         : PropTypes.element,
    selectMediaIcon    : PropTypes.element,
    titleMode          : PropTypes.oneOf(['edit','none']),
    titleProps         : PropTypes.object,
    titleVisible       : PropTypes.bool
};

export default ThingContainer ;
