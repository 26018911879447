import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import FormElements from '../../../../display/forms/FormElements'

import Team  from '../../../../vo/Team'
import Thing from '../../../../things/Thing'

import api from '../../../../configs/api'

const elements =
[
    {
        type : FormElements.TEXTFIELD ,
        init :
        {
            id           : 'givenName',
            key          : 'givenName',
            label        : 'Prénom',
            autoFocus    : true,
            fullWidth    : true,
            margin       : 'normal',
            required     : false,
            select       : false,
            autoComplete : 'off' ,
            type         : 'search',
            variant      : 'outlined'
        }
    },
    {
        type : FormElements.TEXTFIELD ,
        init :
        {
            id           : 'familyName',
            key          : 'familyName',
            label        : 'Nom de Famille',
            autoFocus    : true,
            fullWidth    : true,
            margin       : 'normal',
            required     : false,
            select       : false,
            autoComplete : 'off' ,
            type         : 'search',
            variant      : 'outlined'
        }
    },
    {
        type       : FormElements.SEARCH_SELECTOR ,
        path       : api.thesaurus.genders ,
        clearLabel : 'Vider' ,
        closeLabel : 'Fermer' ,
        init       :
        {
            id        : 'gender',
            key       : 'genre',
            label     : 'Genre',
            autoFocus : false,
            fullWidth : true,
            margin    : 'normal',
            required  : false,
            type      : 'search',
            variant   : 'outlined'
        },
        limit  : 0,
        inputProps :
        {
            label : 'Genre...'
        }
    },
    {
        type       : FormElements.SEARCH_SELECTOR ,
        clazz      : Team ,
        path       : api.teams.url ,
        clearable  : false ,
        closeLabel : 'Fermer' ,
        init       :
        {
            id           : 'team',
            key          : 'team',
            label        : 'Équipe',
            autoComplete : 'off' ,
            autoFocus    : false,
            fullWidth    : true,
            helperText   : 'Obligatoire',
            margin       : 'normal',
            required     : true,
            select       : false,
            type         : 'search',
            variant      : 'outlined'
        },
        limit  : 0,
        inputProps :
        {
            label : 'Équipe...'
        },
        valueLabel : ( item , props ) =>
        {
            if( item instanceof Thing )
            {
                return ucFirst(item.getLocaleAlternateName(props.lang)) ;
            }
            return item ;
        }
    }
];

const user =
{
    edit :
    {
        elements,
        agree       : 'Sauver' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour modifier cet utilisateur.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...' ,
        mock        : false,
        success     : 'Mise à jour réussie!' ,
        title       : 'Modifier les informations de l\'utilisateur' ,
    },
    labels :
    {
        empty      : '-----',
        givenName  : "Prénom",
        familyName : "Nom de Famille",
        gender     : "Genre",
        team       : "Équipe"
    },
    title         : 'Utilisateur',
    tooltips :
    {
        add    : "Ajouter" ,
        edit   : "Éditer" ,
        remove : "Supprimer"
    }
};

export default user ;