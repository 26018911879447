import React from "react"

import { FaCalendarAlt as EventIcon } from 'react-icons/fa'

import GridIcon from '@material-ui/icons/Apps'
import ListIcon from '@material-ui/icons/List'

import Event          from '../event/Event'
import EventsCalendar from '../../display/calendars/EventsCalendar'
import EventsGrid     from '../../display/grids/EventsGrid'
import EventsList     from '../../display/lists/EventsList'
import View           from '../../display/views/TabsView'

import initView from '../../display/views/initView'

import api from '../../configs/api'

const styles = () => ({}) ;

const path = api.events ;

const { url:uri } = path ;

class Events extends View
{
    getLocale = () => this.props.locale.views.events ;
}

Events.defaultProps =
{
    ...View.defaultProps,
    first  : 'calendar' ,
    path ,
    routes :
    [
        { id : 'calendar' , Component : EventsCalendar , uri , backPath:'/calendar' , className:'' } ,
        { id : 'grid'     , Component : EventsGrid     , uri , backPath:'/grid'     } ,
        { id : 'list'     , Component : EventsList     , uri , backPath:'/list'     } ,
        { id : 'event'    , component : Event          , path : uri + '/:id' }
    ],
    tabs :
    [
        { id : 'calendar' , icon : <EventIcon style={{ fontSize:18 }} /> } ,
        { id : 'grid'     , icon : <GridIcon /> } ,
        { id : 'list'     , icon : <ListIcon /> } ,
    ]
};

Events.propTypes =
{
    ...View.propTypes
};

export default initView( Events , styles );
