import React from "react"

import red from '@material-ui/core/colors/red'

import Pagination from '../../components/paginations/Pagination'

import SearchView from '../../display/views/SearchView'

import SettingsGrid from '../../display/grids/SettingsGrid'

import initView from '../../display/views/initView'

const styles = theme =>
({
    header :
    {
        height          : 100,
        minHeight       : 100,
        display         : 'flex',
        backgroundColor : red[700] ,
        backgroundImage : `url(${theme.assets.header})`,
        backgroundSize  : 'cover' ,
        color           : '#fff'
    }
});

class Settings extends SearchView
{
    getContent = () =>
    {
        const { search } = this.state ;
        return (
        <SettingsGrid
            limit            = { 0 }
            parent           = { this.element }
            search           = { search }
            selectVisibility = { Pagination.NONE }
        />);
    };
}

Settings.propTypes =
{
    ...SearchView.propTypes
};

export default initView( Settings, styles );
