import Thing from './Thing'

class OpeningHoursSpecification extends Thing
{
    /**
     * Creates a new OpeningHoursSpecification instance.
     * @constructor
     * @param object The generic object to initialize the object.
     */
    constructor( object = null )
    {
        super() ;
    
        /**
         * The closing hour of the place or service on the given day(s) of the week.
         * @type {string}
         */
        this.closes = null  ;
        
        /**
         * The day of the week for which these opening hours are valid.
         * @type {string|Array}
         */
        this.dayOfWeek = null ;

        /**
         * The opening hour of the place or service on the given day(s) of the week.
         * @type {string}
         */
        this.opens = null ;
    
        /**
         * The date when the item becomes valid.
         * @type {string}
         */
        this.validFrom = null ;
    
        /**
         * The date after when the item is not valid. For example the end of an offer, salary period, or a period of opening hours
         * @type {string}
         */
        this.validThrough = null ;
        
        this.set( object ) ;
    }
    
    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        super.clear() ;
        this.closes        =
        this.dayOfWeek     =
        this.opens         =
        this.validFrom     =
        this.validThrough  = null ;
    }

    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new OpeningHoursSpecification( this.toObject() ) ;
    }
    
    /**
     * Returns the generic object representation of the item.
     * @return {Object} The generic object representation of the item.
     */
    toObject()
    {
        let {
            closes,
            dayOfWeek,
            opens,
            validFrom,
            validThrough
        } = this ;
        return {
            ...super.toObject() ,
            closes,
            dayOfWeek,
            opens,
            validFrom,
            validThrough
        };
    }
}

export default OpeningHoursSpecification ;