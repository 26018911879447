import React, { PureComponent } from 'react'

import clsx from 'clsx'
import PropTypes  from 'prop-types'

import isString from 'vegas-js-core/src/isString'
import notEmpty from 'vegas-js-core/src/strings/notEmpty'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import Moment from 'react-moment'

// https://nel-co.github.io/react-preloading-component-demo
import { Lines as Preloader } from 'react-preloading-component'

import { withStyles } from '@material-ui/core/styles'

import { ButtonBase, IconButton, Typography } from '@material-ui/core'

import CheckCircle     from '@material-ui/icons/CheckCircle'
import InfoIcon        from '@material-ui/icons/Info'
import PanoramaFishEye from '@material-ui/icons/PanoramaFishEyeTwoTone'

import grey  from '@material-ui/core/colors/grey'
import green from '@material-ui/core/colors/green'

import CreativeWork from '../../things/CreativeWork'
import ImageObject  from '../../things/creativework/media/ImageObject'

import withConfig from '../../contexts/config/withConfig'
import withLang   from '../../contexts/i18n/withLang'

import mimeicons, { DEFAULT_ICON } from '../../configs/mimeicons'

import Picture from '../things/Picture'

const css =
{
    bottom :
    {
        bottom : 0
    },
    container :
    {
        cursor    : "pointer",
        '&:hover' :
        {
            filter     : 'brightness(50%)' ,
            transition : '0.3s',
            transitionTimingFunction : 'cubic-bezier(0.175,0.885,0.32,1.275)'
        }
    },
    containerDisabled :
    {
        cursor : "default"
    },
    containerSelected :
    {
        filter     : 'brightness(35%)' ,
        transition : '.2s ease-out'
    },
    icon :
    {
        color    : '#ffffff',
        fontSize : 48
    },
    iconSelected :
    {
        color    : grey[600],
        fontSize : 48
    },
    image :
    {
        '&:hover':
        {
            transition : '0.3s',
            transform  : 'scale(1.1)'
        }
    },
    infoButton :
    {
        color   : '#fff' ,
        padding : 0
    },
    left :
    {
        left : 0
    },
    right :
    {
        right : 0
    },
    root :
    {
        backgroundColor : 'rgba(0, 0, 0, 0.8)' ,
        borderRadius    : 12,
        boxShadow       : '0 4px 8px 0 rgba(0,0,0,0.12),0 2px 4px 0 rgba(0,0,0,0.08)',
        cursor          : "pointer",
        margin          : '0',
        overflow        : 'hidden',
        position        : "relative"
    },
    rootDisabled :
    {
        cursor  : "default",
        opacity : 0.6
    },
    rootSelected :
    {
        backgroundColor : green[900]
    },
    selectButton :
    {
        color    : '#fff' ,
        margin   : 8 ,
        padding  : 0 ,
    },
    selectIcon :
    {
        color    : '#fff' ,
        fontSize : '30px'
    },
    subtitle:
    {
        lineHeight   : 1,
        textOverflow : 'ellipsis',
        overflow     : 'hidden',
        whiteSpace   : 'nowrap'
    },
    title :
    {
        textOverflow : 'ellipsis',
        overflow     : 'hidden',
        whiteSpace   : 'nowrap'
    },
    titleBar :
    {
        alignItems      : 'center',
        backgroundColor : 'rgba(0, 0, 0, 0.6)' ,
        display         : 'flex',
        flexDirection   : 'row' ,
        justifyContent  : 'space-between',
        minHeight       : '48px' ,
        height          : 'auto' ,
        padding         : '4px 4px 8px 4px;' ,
        width           : '100%'
    },
    titleWrap :
    {
        color       : 'white',
        marginLeft  : 8,
        marginRight : 8,
        overflow    : 'hidden'
    }
};

const styles = () => css ;

class Media extends PureComponent
{
    state =
    {
        preload  : true ,
        selected : false
    };

    UNSAFE_componentWillReceiveProps = nextProps =>
    {
        if( nextProps.selected !== this.props.selected )
        {
            this.setState({ selected:nextProps.selected });
        }
    }

    getIcon = ( media , className = null ) =>
    {
        if( media )
        {
            const { classes } = this.props ;

            const  { encodingFormat } = media ;

            let IconComponent ;

            if( encodingFormat && mimeicons[encodingFormat])
            {
                IconComponent = mimeicons[encodingFormat];
            }
            else
            {
                IconComponent = DEFAULT_ICON ;
            }

            const { selected } = this.state ;

            return (
            <IconComponent className = { clsx( selected ? classes.iconSelected : classes.icon , className ) }/>);
        }
        return null ;
    };

    getImage = media =>
    {
        if( media )
        {
            const { classes, width, height } = this.props ;
            const { preload } = this.state ;

            const onPreload = () => { this.setState( { preload:false } ) ; } ;

            return (
            <div style={{ width, height , overflow:'hidden' , display: preload ? 'none' : null }}>
                <Picture
                    className = { classes.image }
                    height    = { height }
                    media     = { media }
                    onLoad    = { onPreload }
                    style     = {{ display: preload ? 'none' : null }}
                    width     = { width }
                />
            </div>);
        }
    };

    getTitleLabel = () =>
    {
        let title ;
        const { lang, media } = this.props ;
        if( media instanceof CreativeWork )
        {
            title = media.getLocaleHeadline(lang);
            if( !isString(title) || title === '' )
            {
                title = media.getLocaleName(lang);
            }
        }
        return notEmpty(title) ? ucFirst(title) : null ;
    };

    render()
    {
        const {
            classes ,
            disabled ,
            index ,
            info,
            left ,
            height,
            media,
            noWrap,
            onClick,
            onInfo,
            selectable,
            top,
            width
        }
        = this.props ;

        const dimension = { width , height } ;

        const { preload, selected } = this.state ;

        if( media instanceof CreativeWork )
        {
            let {
                contentUrl,
                thumbnailUrl
            }
            = media ;

            let title = this.getTitleLabel() ;

            let root = { position:'absolute', left, top, height, width } ;

            // ---------

            let hasPicture = ( isString( contentUrl ) && media instanceof ImageObject ) || isString(thumbnailUrl) ;

            let content ;

            if( hasPicture )
            {
                content = (
                <div className="flex items-center justify-center" style={dimension}>
                    { this.getImage( media ) }
                    { preload && <Preloader color={green[500]} size={16} /> }
                    { this.getIcon(media, 'absolute') }
                </div>) ;
            }
            else
            {
                content = (
                <div className="flex items-center justify-center" style={dimension}>
                    { this.getIcon(media) }
                </div>) ;
            }

            if( content )
            {
                content = (
                    <div className="flex items-center justify-center border-black" style={dimension}>
                        { content }
                    </div>
                );
            }

            let containerClass ;
            if( disabled )
            {
                containerClass = classes.container.disabled ;
            }
            else if( selected )
            {
                containerClass = classes.containerSelected ;
            }
            else
            {
                containerClass = classes.container ;
            }

            let rootClass ;
            if( disabled )
            {
                rootClass = classes.rootDisabled ;
            }
            else if( selected )
            {
                rootClass = classes.rootSelected
            }

            return (
                <div
                    className = { clsx(classes.root, rootClass ) }
                    style     = {{ ...root }}
                >
                    <ButtonBase
                        className = { clsx( containerClass ) }
                        disabled  = { disabled }
                        style     = { dimension }
                        onClick   = { onClick instanceof Function ? event => onClick( media , index , selected, event ) : null }
                    >
                        { content }
                    </ButtonBase>

                    <div
                        className = "w-full absolute flex justify-end items-end pointer-events-none"
                        style     = {{ bottom:0, width }}
                    >
                        <div className={ classes.titleBar } style={{ width }}>
                            <div
                                className = "flex flex-col w-full"
                            >
                                <Typography
                                    className = { clsx(classes.titleWrap ,classes.title )}
                                    noWrap    = { noWrap }
                                    variant   = 'subtitle1'
                                >
                                    { title }
                                </Typography>
                                <Typography
                                    className = { clsx( classes.titleWrap , classes.subtitle )}
                                    noWrap    = { noWrap }
                                    variant   = 'caption'
                                >
                                    <Moment fromNow element="span" locale="fr">{media.created}</Moment>
                                </Typography>
                            </div>
                        </div>
                    </div>

                    {
                        (info || selectable) && (

                            <div className="absolute flex flex-row" style={{ left:0, top:0 }}>

                                { selectable &&
                                    <IconButton
                                        className = { classes.selectButton }
                                        disabled  = { disabled }
                                        onClick   = { this.select }>
                                        { selected ? <CheckCircle /> : <PanoramaFishEye/> }
                                    </IconButton>
                                }

                                { info &&
                                    <IconButton
                                        className = { classes.infoButton }
                                        disabled  = { disabled }
                                        onClick   = { event =>
                                        {
                                            if( onInfo instanceof Function )
                                            {
                                                onInfo( media , index , selected, event ) ;
                                            }
                                        }}
                                    >
                                        <InfoIcon />
                                    </IconButton>
                                }

                            </div>
                        )
                    }

                </div>
            );
        }
        else
        {
            return null ;
        }
    }

    select = () =>
    {
        const { media , onSelect } = this.props ;
        const selected = !(this.state.selected) ;
        this.setState({ selected }) ;
        if( onSelect instanceof Function )
        {
            onSelect( selected, media )
        }
    };
}

Media.defaultProps =
{
    disabled   : false,
    height     : 480 ,
    index      : 320 ,
    info       : false,
    media      : null ,
    noWrap     : true ,
    onClick    : null ,
    onInfo     : null,
    onSelect   : null,
    selectable : false ,
    selected   : false ,
    width      : 0
};

Media.propTypes =
{
    disabled   : PropTypes.bool,
    height     : PropTypes.number,
    index      : PropTypes.number.isRequired,
    info       : PropTypes.bool,
    left       : PropTypes.number.isRequired,
    onClick    : PropTypes.func,
    media      : PropTypes.object,
    noWrap     : PropTypes.bool,
    onInfo     : PropTypes.func,
    onSelect   : PropTypes.func,
    selectable : PropTypes.bool,
    selected   : PropTypes.bool,
    top        : PropTypes.number.isRequired,
    width      : PropTypes.number
};

export default withStyles( styles )( withConfig( withLang(Media) ) ) ;
