import React from 'react'

import PropTypes  from 'prop-types'

import ColorLensIcon from '@material-ui/icons/ColorLens'

import grey from '@material-ui/core/colors/grey'

import { BlockPicker } from 'react-color'

import CoreDialog from '../../components/dialogs/CoreDialog'

import initDialog from './initDialog'

import material from '../../styles/colors/material'

const defaultColor = grey[500];

const defaultPalette = material ;

const styles = () => (
{
    content :
    {
        padding : 12
    }
});

export class ColorDialog extends CoreDialog
{
    constructor( props )
    {
        super( props );
        this.state =
        {
            ...this.state ,
            color : props.color || defaultColor
        }
    }

    agree = () =>
    {
        this.close() ;
        const { onChange } = this.props ;
        if( onChange instanceof Function )
        {
            onChange( this.state.color , this.props.id ) ;
        }
    }

    change = color =>
    {
        if( color )
        {
            const { hex } = color ;
            if( hex )
            {
                this.setState({ color:hex }) ;
            }
        }
    };

    getContent = () =>
    {
        return (
            <BlockPicker
                color            = { this.state.color }
                colors           = { this.props.colors }
                height           = '100%'
                width            = '100%'
                triangle         = "hide"
                onChangeComplete = { this.change }
            />
        );
    };

    getLocale = () => this.props.locale.dialogs.color ;
}

ColorDialog.defaultProps =
{
    ...CoreDialog.defaultProps,
    color  : defaultColor,
    colors : defaultPalette,
    icon   : <ColorLensIcon/> ,
    id     : null
};

ColorDialog.propTypes =
{
    ...CoreDialog.propTypes,
    color  : PropTypes.string,
    colors : PropTypes.array,
    id     : PropTypes.string
};

export default initDialog({ styles })( ColorDialog ) ;
