import isString from 'vegas-js-core/src/isString'

import Thing from './Thing'
import Word  from './Word'

const sortThing = lang => ( a , b ) =>
{
    if( a instanceof Word && b instanceof Word )
    {
        return a.getLocaleName(lang).localeCompare(b.getLocaleName(lang)); // special with Words
    }
    else if( (a instanceof Thing) && (b instanceof Thing) )
    {
        const { alternateName:an1 , na1 } = a ;
        const { alternateName:an2 , na2 } = b ;
        
        let first ;
        let second ;
        
        if( an1 )
        {
            first = a.getLocaleAlternateName(lang) ;
        }
        else if( na1 )
        {
            first = a.getLocaleName(lang) ;
        }
        
        if( an2 )
        {
            second  = b.getLocaleAlternateName(lang) ;
        }
        else if( na2 )
        {
            second = b.getLocaleName(lang) ;
        }
        
        if( isString(first) && isString(second) )
        {
            return first.localeCompare(second);
        }
    }
    return 0;
};

export default sortThing;