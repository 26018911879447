import api           from '../../../configs/api'
import Form          from '../../../display/forms/FormElements'
import Event         from '../../../things/Event'
import PropertyValue from '../../../things/PropertyValue'
import Method        from '../../../net/Method'
import Thing         from '../../../things/Thing'

const events =
{
    add :
    {
        agree       : 'Ajouter' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez sélectionner un événement en cliquant ci-dessous.' ,
        elements    :
        [
            {
                type       : Form.SEARCH_SELECTOR ,
                path       : api.events.url ,
                clazz      : Event ,
                clearLabel : 'Vider' ,
                closeLabel : 'Fermer' ,
                init       :
                {
                    id         : 'value',
                    key        : 'value',
                    label      : 'Événement',
                    autoFocus  : false,
                    fullWidth  : true,
                    margin     : 'normal',
                    required   : true,
                    type       : 'search',
                    variant    : 'outlined'
                },
                inputProps :
                {
                    label : 'Événement...'
                },
                logics :
                {
                    disabledSuggestions : ( props ) =>
                    {
                        const { rel } = props ;
                        if( rel instanceof Thing )
                        {
                            const { subjectOf } = rel ;
                            if( subjectOf instanceof Thing )
                            {
                                const { events } = subjectOf ;
                                if( events instanceof Array )
                                {
                                    return events ;
                                }
                            }
                        }
                        return null ;
                    }
                }
            }
        ],
        fail    : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading : 'Chargement en  cours...' ,
        method  : Method.POST,
        prepare : ( item ) =>
        {
            return (
            {
                value : item.value
            });
        },
        mapUri : ( item , uri ) =>
        {
            if( item instanceof PropertyValue)
            {
                const { value } = item ;
                if( value instanceof Thing )
                {
                    return uri + '/' + value.id ;
                }
            }
            return uri ;
        },
        success : 'Ajout de l\'événement réussi !' ,
        title   : 'Ajouter un événement'
    },
    empty  : 'Aucun événement disponible' ,
    remove :
    {
        agree       : 'Supprimer' ,
        description : 'Souhaitez-vous supprimer cet événement :' ,
        disagree    : 'Annuler' ,
        loading     : 'Suppression en cours...' ,
        title       : 'Supprimer l\'événement ?'
    },
    title : 'Événements' ,
    tooltips :
    {
        add    : "Ajouter" ,
        edit   : "Éditer" ,
        remove : "Supprimer"
    }
};

export default events ;
