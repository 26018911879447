//import React from 'react'

import clean   from 'vegas-js-core/src/objects/clean'
import compose from 'vegas-js-core/src/functors/compose'

import initApp from '../../../../../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import blueGrey from '@material-ui/core/colors/blueGrey'

//import { FaMortarPestle } from 'react-icons/fa'

import ChipsContainer, { styles } from '../../../../../containers/ChipsContainer'

import api from '../../../../../../configs/api'

import AbortionEvent   from '../../../../../../things/livestock/events/AbortionEvent'
import MedicalAnalysis from '../../../../../../things/medical/MedicalAnalysis'
import Thing           from '../../../../../../things/Thing'

const css = () => (
{
    ...styles,
    chip :
    {
        backgroundColor : blueGrey[100],
        margin          : 8
    }
});

class Method extends ChipsContainer
{
    editInit = ( item ) =>
    {
        if( item )
        {
            const { method } = item ;
            if( method instanceof Array )
            {
                return method.map( element =>
                {
                    if( element instanceof Thing )
                    {
                        return element.clone() ;
                    }
                    return null ;
                })
            }
        }
        return null ;
    };

    editPrepare = ( datas , props ) =>
    {
        let { item:analysis } = props ;
        if( analysis instanceof MedicalAnalysis )
        {
            const { id } = analysis ;
            const { subjectOf:event } = analysis ;
            if( event instanceof AbortionEvent )
            {
                let { subjectOf:review } = event ;
                if( review )
                {
                    const { analysis:analyses } = event ;

                    if( analyses instanceof Array )
                    {
                        let position = analyses.findIndex( item => item.id === id ) ;
                        if( position > -1 )
                        {
                            analyses[position].method = datas ;
                        }
                    }

                    review = clean(review.toObject()) ;

                    //console.log( this + ' editPrepare review ::::' , review ) ;

                    return { review:JSON.stringify(review) } ;
                }
            }

        }
        return null ;
    };

    getEntry = init =>
    {
        const { subjectOf } = this.props ;

        let empty = {} ;

        if( init instanceof Thing )
        {
            empty = init.toObject() ;
        }
        else if( init )
        {
            empty = { ...init } ;
        }

        return new MedicalAnalysis( { ...empty , subjectOf } ) ;
    };

    getLocale = () => this.props.locale.things.livestock.reviews.abortion.medical.method ;

    onEdit = ( item ) =>
    {
        const { onChange } = this.props ;
        if( onChange )
        {
            onChange( item ) ;
        }
    };
}

Method.defaultProps =
{
    ...ChipsContainer.defaultProps ,
    chipVariant : 'default' ,
    editUri     : ( path, props ) =>
    {
        const { subjectOf } = props ;
        if( subjectOf )
        {
            const { subjectOf:review } = subjectOf ;
            if( review )
            {
                const { subjectOf:observation } = review ;
                if( observation )
                {
                    const { id } = observation ;
                    if( id )
                    {
                        return api.observations.url + '/' + id ;
                    }
                }
            }
        }
        return null ;
    },
    icon       : null, // <FaMortarPestle size={18}/>,
    member     : 'method',
    optionMode : 'none' ,
    titleMode  : 'edit'
};

Method.propTypes =
{
    ...ChipsContainer.propTypes
};

export default compose( withStyles( css ) , withRouter , initApp )( Method ) ;
