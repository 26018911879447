import React from 'react'

import clean   from 'vegas-js-core/src/objects/clean'
import compose from 'vegas-js-core/src/functors/compose'

import initApp from '../../../../../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import blueGrey from '@material-ui/core/colors/blueGrey'

import { FaClipboardList } from 'react-icons/fa'

import ChipsContainer, { styles } from '../../../../../containers/ChipsContainer'

import generify from '../../../../../../things/generify'

import AbortionSerieReview from '../../../../../../things/livestock/reviews/AbortionSerieReview'
import Thing               from '../../../../../../things/Thing'
import Word                from '../../../../../../things/Word'

const css = () => (
{
    ...styles,
    chip :
    {
        backgroundColor : blueGrey[100],
        margin          : 8
    }
});

class Factors extends ChipsContainer
{
    editPrepare = ( datas , props ) =>
    {
        let { item:review } = props ;
        if( review instanceof AbortionSerieReview )
        {
            let { risk } = review ;

            if( !risk )
            {
                risk = { factors:null } ;
                review.risk = risk ;
            }

           if( datas )
            {
                if( datas instanceof Array && datas.length === 0 )
                {
                    datas = null ;
                }
                datas = generify(datas,true) ;
            }

            risk.factors = datas ;

            review = clean(review.toObject()) ;

            review = JSON.stringify(review) ;
            return { review } ;
        }
        return null ;
    };

    getEntry = init =>
    {
        const { subjectOf } = this.props ;

        let empty = {} ;

        if( init instanceof Thing )
        {
            empty = init.toObject() ;
        }
        else if( init )
        {
            empty = { ...init } ;
        }

        return new AbortionSerieReview( { ...empty , subjectOf } ) ;
    };

    getLocale = () => this.props.locale.things.livestock.reviews.abortionSerie.medical.factors ;

    getThing = () =>
    {
        const { thing } = this.props ;
        if( thing )
        {
            const { risk } = thing ;
            return risk ;
        }
        return null ;
    };

    editInit = ( item ) =>
    {
        if( item )
        {
            const { risk } = item ;
            if( risk )
            {
                const { factors } = risk ;
                if( factors instanceof Array )
                {
                    return factors.map( element =>
                    {
                        if( element instanceof Word )
                        {
                            return element.clone() ;
                        }
                        return null ;
                    })
                }
            }
        }
        return null ;
    };

    onEdit = ( item ) =>
    {
        const { onChange } = this.props ;
        if( onChange )
        {
            onChange( item ) ;
        }
    };

}

Factors.defaultProps =
{
    ...ChipsContainer.defaultProps ,
    chipVariant : 'default' ,
    icon        : <FaClipboardList size={18}/>,
    member      : 'factors',
    optionMode  : 'none' ,
    titleMode   : 'edit'
};

Factors.propTypes =
{
    ...ChipsContainer.propTypes
};

export default compose( withStyles( css ) , withRouter , initApp )( Factors );
