import React from 'react'

import PropTypes from 'prop-types'

import camelCase from 'vegas-js-core/src/strings/camelCase'
import notEmpty  from 'vegas-js-core/src/strings/notEmpty'

import { MdLibraryAdd as EmbedIcon } from 'react-icons/md'

import CloseIcon      from '@material-ui/icons/Close'
import CollectionIcon from '@material-ui/icons/PermMedia'
import MoreVertIcon   from '@material-ui/icons/MoreVert'
import SortIcon       from '@material-ui/icons/Sort'
import UploadIcon     from '@material-ui/icons/CloudUpload'

import SpeedDial       from '@material-ui/lab/SpeedDial'
import SpeedDialIcon   from '@material-ui/lab/SpeedDialIcon'
import SpeedDialAction from '@material-ui/lab/SpeedDialAction'

import withDatasList from './withDatasList'

import api from '../../configs/api'

import POST          from '../../net/POST'
import RequestStatus from '../../net/RequestStatus'
import UploadStatus  from '../../net/UploadStatus'

import MediaObjectCell         from '../cells/MediaObjectCell'
import RemoveMediaObjectDialog from '../dialogs/remove/RemoveMediaObjectDialog'

import getMediaAvatar from '../medias/getMediaAvatar'

import createMediaObject from '../../things/creativework/createMediaObject'
import CreativeWork      from '../../things/CreativeWork'
import MediaObject       from '../../things/creativework/MediaObject'
import Thing             from '../../things/Thing'

import ThingsList from './ThingsList'

const actions =
[
    {
        id      : 'upload' ,
        action  : ref => { ref.openUploadDialog() ; } ,
        icon    : <UploadIcon /> ,
        visible : props => props.uploadable === true
    },
    {
        id      : 'embed' ,
        action  : ref => { ref.openEmbedDialog() ; } ,
        icon    : <EmbedIcon  /> ,
        visible : props => props.embeddable === true
    },
    {
        id      : 'sort'  ,
        action  : ref => { ref.openSortDialog() ; } ,
        icon    : <SortIcon />  ,
        visible : props => props.sortable === true
    }
];

export class MediaObjectsList extends ThingsList
{
    getAvatar = ( thing , style = undefined ) =>
    {
        if( thing )
        {
            const { avatarVariant:variant } = this.props ;
            return getMediaAvatar( thing , { className:'mr-0' , style , variant }) ;
        }
        return null ;
    };

    getEntry = init => init instanceof CreativeWork ? init.clone() : null ;

    getLocale = () => this.props.locale.display.lists.mediaObjects ;

    getOptions = () =>
    {
        if( this.isEditable() )
        {
            let { actions , classes } = this.props ;

            let {
                actions   : i18n = {}  ,
                speedDial : { ariaLabel = "" } = {} ,
            } = this.getLocale() || {};

            if( actions instanceof Array && actions.length > 0 )
            {
                actions = actions.filter( ( { visible = false } = {} ) =>
                {
                    if( visible instanceof Function )
                    {
                        return visible( this.props ) ;
                    }
                    else
                    {
                        return Boolean( visible ) ;
                    }
                })
            }

            if( actions instanceof Array && actions.length > 0 )
            {
                const { speedDial , speedDialAction } = this.props;

                const { dialOpen , dialHidden } = this.state;

                return (
                    <SpeedDial
                        ariaLabel = { ariaLabel }
                        className = { classes.speedDial }
                        hidden    = { dialHidden }
                        onClose   = { this.closeSpeedDial }
                        onOpen    = { this.openSpeedDial }
                        open      = { dialOpen }
                        { ...speedDial }
                    >
                        {
                            actions.map( ( { action , id , icon /*, title*/ } , index ) =>
                            (
                                <SpeedDialAction
                                    key          = { 'action-' + index }
                                    icon         = { icon }
                                    tooltipTitle = { i18n[id] || null }
                                    onClick      = { () =>
                                    {
                                        if( action instanceof Function )
                                        {
                                           action( this ) ;
                                        }
                                    } }
                                    { ...speedDialAction }
                                /> ) )
                        }
                    </SpeedDial>
                ) ;
            }
        }
    };

    onEmbedMedia = item =>
    {
        const { things } = this.state ;
        if( item && things instanceof Array && things.length > 0)
        {
            item = createMediaObject(item) ;
            if( item )
            {
                things.unshift( item ) ; // insert at 0
                if( this._mounted )
                {
                    this.setState({ things } ) ;
                }
            }
        }
    };

    onMedia = item =>
    {
        const { things } = this.state ;
        if( things instanceof Array && things.length > 0 )
        {
            let find = things.find((element) => element.id === item.id);
            if ( find instanceof CreativeWork )
            {
                find.clear().set( item );
                if (this._mounted)
                {
                    this.setState( { things } ) ;
                }
            }
        }
    };

    onSelect = item => { this.openMediaDialog( item ) ; }

    onSelectMedia = things =>
    {
        if( things instanceof Array && things.length > 0 )
        {
            this.timeout = setTimeout( this.save, 250, things ) ;
        }
    };

    save = ( things , position = 0 ) =>
    {
        if( things instanceof Array && things.length > 0 )
        {
            const { member, thing } = this.props ;
            if( thing && notEmpty(member) )
            {
                let { url } = thing ;
                if( notEmpty(url) )
                {
                    let list = things.map( element => element.id ).join(',') ;
                    this.setState({ status:RequestStatus.PROGRESS }) ;
                    this.canceler = POST( url + '/' + member ,
                    {
                        cancel  : this._saveCancel,
                        datas   : { list , position } ,
                        fail    : this._saveFail,
                        success : this._saveSuccess
                    }) ;
                }
            }
        }
    };

    onUpload = entries =>
    {
        const { unselectAll } = this.props ;
        if( unselectAll instanceof Function )
        {
            unselectAll();
        }

        if( entries instanceof Array && entries.length > 0 )
        {
            const elements = [] ;

            entries.forEach( entry =>
            {
                let { id, name, result, status } = entry ;
                if( status === UploadStatus.UPLOADED )
                {
                    if( result )
                    {
                        elements.push( createMediaObject(result)  );
                    }
                    else
                    {
                        console.log( this + 'onUpload failed with an empty result from the server with the entry ' + name , id );
                    }
                }
            });

            let num = elements.length;
            if( num > 0 )
            {
                let { count, things, total } = this.state ;

                things = [ ...elements , ...things ];

                count += num ;
                total += num ;

                const hasMore = count < total ;

                this._refresh( things ) ;

                this.reset({
                    count,
                    hasMore,
                    things,
                    total
                });

            }
        }
    };

    orderUri = ( { target : { id } = {} , to } ) => this.getPath() + '/' + id + '/position/' + to ;

    populate = things => ( things instanceof Array && things.length > 0 ) ? things.map( createMediaObject ) : things ;

    _saveCancel = () =>
    {
        // console.log( this + " cancel message:" + message ) ;
        this.setState( { status:RequestStatus.NEW } ) ;
    };

    _saveFail = response =>
    {
        if( response )
        {
            const { data } = response ;
            if( data )
            {
                const { message } = data ;
                switch( message )
                {
                    case 'token revoked' :
                    {
                        break ;
                    }
                    default :
                    {
                        console.log( this + " failed, status:" + response.status + ", message:" + data.message , true );
                    }
                }
            }
        }

        this.setState( { status:RequestStatus.NEW } ) ;
    };

    _saveSuccess = response =>
    {
        if( response )
        {
            const { data } = response ;
            if( data )
            {
                let { position , result , total } = data ;
                if( result instanceof Array && result.length > 0 )
                {
                    const { variant } = this.props ;
                    if( variant === 'pagination' )
                    {
                        this.load( this.state.offset ) ;
                    }
                    else
                    {
                        const status = RequestStatus.SUCCESS ;

                        let { things } = this.state ;

                        let count = things instanceof Array ? things.length : 0 ;

                        result = this.populate( result ) ;

                        if( things instanceof Array && things.length > 0 )
                        {
                            // Remove all duplicates things
                            things = things.filter( item =>
                            {
                                if( item )
                                {
                                    const { id } = item ;
                                    if( id )
                                    {
                                        const find = result.find( element =>
                                        {
                                            if( element )
                                            {
                                                return element.id === id ;
                                            }
                                            return false ;
                                        } ) ;
                                        return !find ;
                                    }
                                }
                                return false ;
                            });

                            // Inject the new things
                            if( position >= 0 )
                            {
                                things.splice( position , 0 , ...result ) ;
                            }
                            else
                            {
                                things = [ ...things , ...result ] ;
                            }
                        }
                        else
                        {
                            things = result ;
                        }

                        // Adjust all position
                        things = things.map( ( item , index ) =>
                        {
                            if( item instanceof Thing )
                            {
                                item.position = index ;
                            }
                            return item ;
                        }) ;

                        count = things.length ;

                        const hasMore = count < total ;

                        this.setState(
                        {
                            count ,
                            first:false,
                            hasMore ,
                            status ,
                            things ,
                            total
                        } ,
                        () =>
                        {
                            let { thing , member } = this.props ;
                            if( thing && notEmpty(member) )
                            {
                                member = camelCase('num-' + member) ;
                                if( thing.hasOwnProperty(member) )
                                {
                                    thing[member] = count ;
                                    const { onChange } = this.props ;
                                    if( onChange instanceof Function )
                                    {
                                        onChange( thing , () =>
                                        {
                                            this._refresh( things ) ;
                                        }) ;
                                    }
                                }
                            }
                            else
                            {
                                this._refresh( things ) ;
                            }
                        });

                    }
                }
            }
        }
    }
}

MediaObjectsList.defaultProps =
{
    ...ThingsList.defaultProps ,
    actions               : actions,
    activable             : false,
    addable               : true,
    embeddable            : true ,
    CellComponent         : MediaObjectCell,
    cellMode              : 'button' ,
    cellProps             : { variant : 'full' },
    clazz                 : MediaObject,
    contentTransition     : true,
    icon                  : <CollectionIcon />,
    itemClassName         : 'rounded-bl-lg rounded-tl-lg pr-8 pl-0 shadow' ,
    optionsVariant        : 'default' ,
    queries               : { sort : '-created,name' } ,
    RemoveDialogComponent : RemoveMediaObjectDialog ,
    speedDial             :
    {
        direction : 'left' ,
        icon      : <SpeedDialIcon icon={ <MoreVertIcon /> } openIcon={ <CloseIcon /> }/>
    },
    speedDialAction : { tooltipPlacement : 'left' },
    uploadable      : true,
    uploadMultiple  : true ,
    uploadUri       : api.mediaObjects.url ,
    variant         : 'infinite'
};

MediaObjectsList.propTypes =
{
    ...ThingsList.propTypes ,
    actions       : PropTypes.array,
    embeddable    : PropTypes.bool ,
    speedDial     : PropTypes.shape({ direction:PropTypes.string , icon:PropTypes.element }) ,
    speedDialIcon : PropTypes.element,
};

export default withDatasList()( MediaObjectsList ) ;
