import React from 'react'

import ArticleIcon from '@material-ui/icons/ChromeReaderMode'

import withDatasGrid from './withDatasGrid'
import { ThingsGrid } from './ThingsGrid'

import Article     from '../../things/creativework/Article'
import ArticleCell from '../cells/ArticleCell'

import AddArticleDialog    from '../dialogs/add/AddArticleDialog'
import RemoveArticleDialog from '../dialogs/remove/RemoveArticleDialog'

class ArticlesGrid extends ThingsGrid
{
    getLocale = () => this.props.locale.display.grids.articles ;
}

ArticlesGrid.defaultProps =
{
    ...ThingsGrid.defaultProps,
    AddDialogComponent    : AddArticleDialog,
    CellComponent         : ArticleCell ,
    clazz                 : Article,
    icon                  : <ArticleIcon />,
    mediaIcon             : <ArticleIcon fontSize='large' />,
    mediaProps            : { className : 'bg-hero-graph-paper-2' },
    preferable            : true ,
    queries               : { sort : '-modified' },
    RemoveDialogComponent : RemoveArticleDialog
};

ArticlesGrid.propTypes =
{
    ...ThingsGrid.propTypes
};

export default withDatasGrid()( ArticlesGrid ) ;
