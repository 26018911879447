import abortionEvent              from './abortionEvent'
import abortionEvents             from './abortionEvents'
import reproductiveDisorderEvents from './reproductiveDisorderEvents'
import vaccinationEvents          from './vaccinationEvents'

const events =
{
    abortionEvent,
    abortionEvents,
    reproductiveDisorderEvents,
    vaccinationEvents
};

export default events ;
