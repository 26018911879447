import initDialog from '../initDialog'

import { AddDialog } from '../AddDialog'

import Organization from '../../../things/Organization'
import Person       from '../../../things/Person'

import api from '../../../configs/api'

const styles = () => ({});

export class AddVeterinarianDialog extends AddDialog
{
    getLocale = () => this.props.locale.dialogs.application.veterinarian.add ;

    prepare = item =>
    {
        const {
            identifier,
            organization,
            person
        } = item ;
        return {
            identifier,
            organization : organization instanceof Organization ? String(organization.id) : null ,
            person       : person instanceof Person ? String(person.id) : null
        };
    };
}

AddVeterinarianDialog.defaultProps =
{
    ...AddDialog.defaultProps,
    contentType : 'swipeable' ,
    uri         : api.veterinarians.url
};

AddVeterinarianDialog.propTypes =
{
    ...AddDialog.propTypes
};

export default initDialog({ styles })( AddVeterinarianDialog ) ;
