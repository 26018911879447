import React, { Component } from 'react'

import clsx from 'clsx'

import PropTypes from 'prop-types'

import isString from 'vegas-js-core/src/isString'

import { withStyles } from '@material-ui/core/styles'

import { Fab, Tooltip } from '@material-ui/core'

import red from '@material-ui/core/colors/red'

import RemoveIcon from '@material-ui/icons/Delete'

const styles =
{
    button :
    {
        backgroundColor : 'white',
        color           : red[600],
        '&:hover' :
        {
            backgroundColor : red[900],
            color           : 'white'
        }
    },
    option :
    {
        marginLeft : 10
    }
};

class RemoveButton extends Component
{
    render = () =>
    {
        const {
            className,
            classes,
            disabled,
            color,
            locale,
            onClick,
            size
        } = this.props;

        let { icon } = this.props ;
        if( !icon )
        {
            icon = <RemoveIcon/> ;
        }

        let button = (
        <Fab
            className = { classes.button }
            disabled  = { disabled }
            color     = { color }
            onClick   = { onClick }
            size      = { size }
        >
            { icon }
        </Fab>);

        if( !disabled && locale )
        {
            const { tooltips } = locale ;

            if( tooltips )
            {
                const { remove } = tooltips ;
                if( isString(remove) )
                {
                   button = (
                    <Tooltip placement='left' title={remove}>
                        { button }
                    </Tooltip>
                    );
                }
            }
        }

        return (
        <div className={clsx(classes.option,className)}>
            {button}
        </div>) ;
    };
}

RemoveButton.defaultProps =
{
    className : null  ,
    color     : 'primary' ,
    disabled  : false,
    icon      : <RemoveIcon/>,
    locale    : null,
    onClick   : null,
    size      : 'small'
};

RemoveButton.propTypes =
{
    classes   : PropTypes.object.isRequired ,
    className : PropTypes.string,
    disabled  : PropTypes.bool,
    color     : PropTypes.string,
    icon      : PropTypes.element,
    locale    : PropTypes.object,
    onClick   : PropTypes.func,
    size      : PropTypes.string
};

export default withStyles( styles )( RemoveButton );
