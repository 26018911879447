import React from 'react'

import compose from 'vegas-js-core/src/functors/compose'

import initApp from '../../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import DateIcon   from '@material-ui/icons/DateRange'

import PropertyValue from '../../../things/PropertyValue'
import Event         from '../../../things/Event'

import ThingChildren, { styles } from '../ThingChildren'

class SuperEvent extends ThingChildren
{
    getLocale = () => this.props.locale.things.event.superEvent ;
}

SuperEvent.defaultProps =
{
    ...ThingChildren.defaultProps ,
    clazz      : Event,
    emptyClazz : PropertyValue,
    emptyIcon  : null ,
    icon       : <DateIcon/> ,
    member     : 'superEvent'
};

SuperEvent.propTypes =
{
    ...ThingChildren.propTypes
};

export default compose( withStyles( styles ) , withRouter , initApp )( SuperEvent ) ;