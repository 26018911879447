import React from 'react'

import ReactDOM from 'react-dom'

import { create } from 'jss'

// --------------- LIBRARIES

import jssExtend from 'jss-extend'

import {
    createGenerateClassName ,
    jssPreset ,
    StylesProvider ,
}
from '@material-ui/styles'

// --------------- Fonts

// Raleway
import 'typeface-raleway'

 // Roboto
import 'typeface-roboto'

// Fontawesome
import '@fortawesome/fontawesome-free/js/all'
import '@fortawesome/fontawesome-free/js/fontawesome'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/css/fontawesome.css'

import './styles/MaterialIcons.css'

// --------------- Styles

import './styles/normalize.css'
import './styles/print.css'
import './styles/prism.css'
import './styles/tables.css'

import 'leaflet/dist/leaflet.css'
import 'leaflet-easybutton/src/easy-button.css'
import 'leaflet.fullscreen/Control.FullScreen.css'
import 'leaflet.locatecontrol/dist/L.Control.Locate.css'
import 'leaflet.locatecontrol/dist/L.Control.Locate.mapbox.css'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'
import 'leaflet-control-geocoder/dist/Control.Geocoder.css'
import 'leaflet.visualclick/dist/L.VisualClick.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import './styles/app.css'

// --------------- IMPORTS

import registerServiceWorker from './workers/registerServiceWorker'

import Application from './Application'

import { getConfig } from './configs/config'

import initialize from './initialize'

import routes   from './routes'
import settings from './configs/settings'
import themes   from './configs/themes'

const defaultOptions =
{
    routes,
    settings,
    themes
};

const run = (
{
    id = 'root' ,
    assets  ,
    config  ,
    locale  ,
    style   ,
    version ,
    ...options
} = {} ) =>
{
    // ---------------

    options = { ...defaultOptions , ...options } ;

    // ---------------

    initialize( version ) ;

    // ---------------

    config = getConfig( { assets , version , ...config } ) ;

    // ---------------

    const jss = create({
        ...jssPreset(),
        plugins : [ ...jssPreset().plugins, jssExtend() ]
    });

    jss.options.insertionPoint = document.getElementById('jss-insertion-point') ;

    const generateClassName = createGenerateClassName();

    const main =
    <StylesProvider
        generateClassName = { generateClassName }
        jss               = { jss }
    >
        <Application
            config = { config }
            locale = { locale }
            style  = { style }
            { ...options }
        />
    </StylesProvider>;

    ReactDOM.render( main , document.getElementById( id ) ) ;

    registerServiceWorker();
};

export default run ;

