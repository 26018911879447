import React from 'react'

import PropTypes from 'prop-types'

import compose  from 'vegas-js-core/src/functors/compose'
import isString from 'vegas-js-core/src/isString'

import initApp from '../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { Switch, Tooltip, Typography } from '@material-ui/core'

import green from '@material-ui/core/colors/green'
import grey  from '@material-ui/core/colors/grey'

import CreatedLabel   from '../labels/CreatedLabel'
import ModifiedLabel  from '../labels/ModifiedLabel'

import getHorizontalElements from '../getHorizontalElements'

import DialogContainer from '../../components/containers/DialogContainer'
import RemoveButton    from '../../components/buttons/RemoveButton'

import PATCH           from '../../net/PATCH'
import RequestStatus   from '../../net/RequestStatus'

import Thing from '../../things/Thing'

import api from '../../configs/api'

const styles = theme =>
({
    chip:
    {
        margin : theme.spacing(1)
    },
    switchBase :
    {
        '&$switchChecked':
        {
            color: grey[100],
            '& + $switchBar':
            {
                backgroundColor: green[500]
            }
        },
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
            easing: theme.transitions.easing.sharp
        }),
    },
    switchChecked:
    {
        transform: 'translateX(21px)',
        '& + $switchBar': { opacity: 1, border: 'none' },
    },
    switchBar:
    {
        borderRadius    : 10,
        width           : 42,
        height          : 20,
        marginTop       : -10,
        marginLeft      : -18,
        border          : 'solid 1px',
        borderColor     : grey[400],
        backgroundColor : grey[200],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border'])
    },
    switchIcon:
    {
        width  : 18,
        height : 18
    },
    switchIconChecked:
    {
        boxShadow: theme.shadows[1]
    }
});

class Metadatas extends DialogContainer
{
    constructor(props)
    {
        super(props);
        this.canceler = null ;
    }

    state =
    {
        activeStatus : RequestStatus.NEW
    };

    getLocale = () => this.props.locale.things.metadatas ;

    getPath = () =>
    {
        const { thing } = this.props  ;
        if( thing instanceof Thing )
        {
            const { url } = thing ;
            if( isString(url) )
            {
                return url.split(api.url)[1] ;
            }
        }
        return null ;
    };

    render = () =>
    {
        const {
            activable,
            deletable,
            thing
        } = this.props  ;

        const locale = this.getLocale() ;

        if( thing instanceof Thing )
        {
            let { active, created, modified } = thing ;

            const { activeStatus } = this.state ;

            const { pattern, labels, tooltips } = locale ;

            if( created )
            {
                created = <CreatedLabel pattern={pattern} thing={ thing } formatVariant='default' />;
            }
            else
            {
                created = null ;
            }

            if( modified )
            {
                modified = <ModifiedLabel pattern={pattern} thing={ thing } formatVariant='default' />;
            }
            else
            {
                modified = null ;
            }

            const metas = getHorizontalElements({ className:'' , elements:[created,modified] })

            return (
                <div className='mb-12' >
                    <div className="flex flex-row items-center justify-between">
                        { metas }
                        { ( activable || deletable ) && this.isEditable() &&
                        <div className="flex flex-row items-center">
                            {
                                activable &&
                                <Typography className="font-normal" variant="caption">{ active ? labels.active : labels.inactive}</Typography>
                            }
                            {
                                activable &&
                                <Tooltip placement='top' title={ active ? tooltips.deactivate : tooltips.activate } >
                                    <Switch
                                        disabled = { activeStatus === RequestStatus.PROGRESS }
                                        checked  = { active }
                                        onChange = { this._activeChange }
                                        value    = { active }
                                    />
                                </Tooltip>
                            }
                            {
                                deletable &&
                                <RemoveButton
                                    disabled = { activeStatus === RequestStatus.PROGRESS }
                                    locale   = { locale }
                                    onClick  = { () => this.openRemoveDialog(thing) }
                                    thing    = { thing }
                                />
                            }
                        </div>}
                    </div>
                </div>
            )
        }

        return null ;
    };

    unmount = () =>
    {
        const { status } = this.state ;
        if( status === RequestStatus.PROGRESS && !!(this.canceler) )
        {
            this.canceler.cancel(this + ' cancel') ;
        }
    };

    // ------ activable

    _activeCancel = () =>
    {
        this.setState( { activeStatus : RequestStatus.FAIL } ) ;
    };

    _activeChange = event =>
    {
        const { activeStatus } = this.state ;
        if( activeStatus !== RequestStatus.PROGRESS )
        {
            const { target } = event;
            const { uri } = this.props ;

            this.setState( { activeStatus : RequestStatus.PROGRESS } ) ;

            let path = api.url + uri + '/active/' + ( Boolean(target.checked) ? 'true' : 'false' ) ;

            this.canceler = PATCH( path , { success : this._activeSuccess, fail : this._activeFail, cancel : this._activeCancel } ) ;
        }
    };

    _activeFail = response =>
    {
        if( response )
        {
            const { data } = response ;
            if( data )
            {
                const { message } = data ;
                switch( message )
                {
                    case 'token revoked' :
                    {
                        if( this.mounted )
                        {
                            this.setState({ status:RequestStatus.REVOKED } );
                        }
                        break ;
                    }
                    default :
                    {
                        console.log( this + " failed, status:" + response.status + ", message:" + data.message );
                    }
                }
            }
        }
        if( this.mounted )
        {
            this.setState( { activeStatus : RequestStatus.FAIL } ) ;
        }
    };

    _activeSuccess = ( response ) =>
    {
        if( this.mounted )
        {
            if( response )
            {
                const { data } = response ;
                if( data )
                {
                    const { result } = data ;

                    if( result )
                    {
                        const { thing } = this.props ;

                        if( thing )
                        {
                            thing.modified = result.modified ;
                            thing.active   = !!(result.active) ;
                            this.setState( { activeStatus : RequestStatus.SUCCESS } ) ;
                            const { onChange } = this ;
                            if( onChange instanceof Function )
                            {
                                onChange( result );
                            }
                            return ;
                        }
                    }
                }

            }
            this.setState( { activeStatus : RequestStatus.FAIL } ) ;
        }
    };
}

Metadatas.defaultProps =
{
    ...DialogContainer.defaultProps,
    activable : true ,
    apiUrl    : api.url,
    deletable : false ,
    onChange  : null ,
    onRemove  : null ,
    thing     : null,
    uri       : null
};

Metadatas.propTypes =
{
    ...DialogContainer.propTypes,
    activable : PropTypes.bool,
    apiUrl    : PropTypes.string.isRequired,
    deletable : PropTypes.bool,
    locale    : PropTypes.object ,
    onChange  : PropTypes.func,
    onRemove  : PropTypes.func,
    thing     : PropTypes.object,
    uri       : PropTypes.string

};

export default compose( withStyles( styles ) , withRouter , initApp )( Metadatas ) ;
