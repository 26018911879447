const person =
{
    copy :
    {
        snack   : 'Personne copiée dans le presse-papiers.' ,
        tooltip : 'Copier le représentant'
    },
    label   : '{0}',
    title   : 'Personne',
    unknown : "Inconnu"
};

export default person ;
