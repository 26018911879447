import list from './list'

const observations =
{
    ...list,
    helmet :
    {
        title : 'Observations' ,
        metas : []
    },
    empty  : 'Aucune observation trouvée',
    search : 'Rechercher une observation ...' ,
    title  : 'Observations'
};

export default observations ;
