import React from 'react'

import isString from 'vegas-js-core/src/isString'

import initDialog from '../initDialog'

import { EditDialog } from '../EditDialog'

import RemoveImageButton from '../../buttons/RemoveImageButton'
import UploadImageButton from '../../buttons/UploadImageButton'

import Thing from '../../../things/Thing'

const styles = () => ({});

export class EditThesaurusDialog extends EditDialog
{
    getLocale = () => this.props.locale.dialogs.application.thesaurus.edit ;

    getOptions = () =>
    {
        const { item } = this.props ;
        if( item && this.isEditable() )
        {
            const { image } = item ;
            if( image )
            {
                return (
                <RemoveImageButton
                    onOpenRemoveDialog = { this.onOpenRemoveDialog }
                    onRemove           = { this.onRemoveIcon }
                    thing              = { item }
                />);
            }
            else
            {
                return (
                <UploadImageButton
                    thing              = { item }
                    onOpenUploadDialog = { this.onOpenUploadDialog }
                    onUpload           = { this.onUploadIcon }
                    uploadFilePropName = 'icon'
                />);
            }
        }
        return this.props.options ;
    };

    onRemoveIcon = id =>
    {
        let { item } = this.props ;
        if( item instanceof Thing && item.id === id )
        {
            item.image = null ;
            if( this._mounted )
            {
                this.forceUpdate( () =>
                {
                    if( this.removeIndex >= 0 )
                    {
                        const { removeDialog } = this.props ;
                        if( removeDialog instanceof Function )
                        {
                            removeDialog( this.removeIndex , () =>
                            {
                                const { onChange } = this.props ;
                                if( onChange instanceof Function )
                                {
                                    onChange( item ) ;
                                }
                            }) ;
                        }
                        this.removeIndex = undefined ;
                    }
                }) ;
            }
        }
    };

    onOpenRemoveDialog = ( { position } ) => { this.removeIndex = position ; };

    onOpenUploadDialog = ( { position } ) => { this.uploadIndex = position ; };

    onUploadIcon = entries =>
    {
        if( entries instanceof Array && entries.length > 0 )
        {
            const { item } = this.props ;
            if( item )
            {
                entries.forEach( entry =>
                {
                    if( entry )
                    {
                        const { result } = entry ;
                        if( result )
                        {
                            const { image, id } = result ;
                            if( item.id === id  )
                            {
                                item.image = image + "?random=" + (new Date()).valueOf(); // Update the current Word in the list
                            }
                        }
                    }
                });

                if( this.uploadIndex >= 0 )
                {
                    const { removeDialog } = this.props ;
                    if( removeDialog instanceof Function )
                    {
                        removeDialog( this.uploadIndex , () =>
                        {
                            const { onChange } = this.props ;
                            if( onChange instanceof Function )
                            {
                                onChange( item ) ;
                            }
                        }) ;
                    }
                    this.uploadIndex = undefined ;
                }
            }
        }
    };

    prepare = item =>
    {
        let {
            alternateName,
            bgcolor,
            color,
            description,
            format,
            name,
            pattern,
            validator
        } = item ;

        const { config : { languages } = {} } = this.props ;
        if( (languages instanceof Array) && languages.length > 0 )
        {
            if( name )
            {
                languages.forEach( lang =>
                {
                    if( !isString( name[lang] ) )
                    {
                        name[lang] = '' ;
                    }
                })
            }

            if( description )
            {
                languages.forEach( lang =>
                {
                    if( !isString(description[lang]) )
                    {
                        description[lang] = '' ;
                    }
                })
            }
        }

        if( !isString(bgcolor) || bgcolor === '' )
        {
            bgcolor = null ;
        }
        else
        {
            bgcolor = bgcolor.toLowerCase();
        }

        if( !isString(color) || color === '')
        {
            color = null ;
        }
        else
        {
            color = color.toLowerCase();
        }

        return (
        {
            alternateName ,
            bgcolor,
            color,
            description,
            format,
            name,
            pattern,
            validator
        });
    };
}

EditThesaurusDialog.defaultProps =
{
    ...EditDialog.defaultProps,
    closeOnSuccess : false  ,
    mock           : false
};

EditThesaurusDialog.propTypes =
{
    ...EditDialog.propTypes
};

export default initDialog({ styles })( EditThesaurusDialog ) ;
