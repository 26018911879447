import Thing from '../../../things/Thing'

const back = ( props , state ) =>
{
    const { thing } = state ;
    if( thing )
    {
        const { about, subject } = thing ;
        if( subject instanceof Thing )
        {
            return 'Retour à l\'atelier'
        }
        else if( about instanceof Thing )
        {
            return 'Retour à l\'élevage'
        }
    }
    return "Retour" ;
} ;

const observation =
{
    about :
    {
        description :
        {
            title : 'Description' ,
            tooltips :
            {
                refresh : 'Annuler' ,
                save    : 'Enregister'
            }
        },
        alternateName :
        {
            title    : 'Titre' ,
            tooltips :
            {
                refresh : 'Annuler' ,
                save    : 'Enregister'
            }
        },
        notes :
        {
            title    : 'Notes' ,
            tooltips :
            {
                refresh : 'Annuler' ,
                save    : 'Enregister'
            }
        }
    },
    document :
    {
        button : 'Document',
        dialog :
        {
            agree    : 'Imprimer' ,
            disagree : 'Annuler' ,
            title    : 'Observation - Rapport'
        }
    },
    helmet :
    {
        title : 'Observation - {0}' ,
        metas : []
    },
    loading  : 'Chargement en cours...',
    notFound : "Observation inconnue" ,
    tabs     :
    {
        about  : 'À propos' ,
        review : 'Rapport'
    },
    title    : 'Observation' ,
    tooltips :
    {
        back
    }
};

export default observation ;