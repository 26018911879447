import ArticleCell          from '../cells/ArticleCell'
import ConceptualObjectCell from '../cells/ConceptualObjectCell'
import CourseCell           from '../cells/CourseCell'
import EventCell            from '../cells/EventCell'
import OrganizationCell     from '../cells/OrganizationCell'
import PersonCell           from '../cells/PersonCell'
import PlaceCell            from '../cells/PlaceCell'
import StageCell            from '../cells/StageCell'
import ThingCell            from '../cells/ThingCell'

import Article          from '../../things/creativework/Article'
import ConceptualObject from '../../things/ConceptualObject'
import Course           from '../../things/Course'
import Event            from '../../things/Event'
import Organization     from '../../things/Organization'
import Person           from '../../things/Person'
import Place            from '../../things/Place'
import Stage            from '../../things/Stage'

const getThingCellComponent = thing =>
{
    switch( true )
    {
        case thing instanceof Article :
        {
            return ArticleCell;
        }
        case thing instanceof ConceptualObject :
        {
            return ConceptualObjectCell;
        }
        case thing instanceof Course :
        {
            return CourseCell ;
        }
        case thing instanceof Event :
        {
            return EventCell;
        }
        case thing instanceof Organization :
        {
            return OrganizationCell;
        }
        case thing instanceof Person :
        {
            return PersonCell;
        }
        case thing instanceof Place :
        {
            return PlaceCell;
        }
        case thing instanceof Stage :
        {
            return StageCell;
        }
        default:
        {
            return ThingCell;
        }
    }
};

export default getThingCellComponent ;