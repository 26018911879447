import React from 'react'

import compose  from 'vegas-js-core/src/functors/compose'
import isString from 'vegas-js-core/src/isString'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import moment from 'moment/moment'

import initApp from '../../contexts/app/initApp'

import { Link , withRouter } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'

import { Avatar, Grid } from '@material-ui/core'

import grey from '@material-ui/core/colors/grey'

import FeedbackIcon     from '@material-ui/icons/Feedback'
import NotificationIcon from '@material-ui/icons/Notifications'
import ScheduleIcon     from '@material-ui/icons/Schedule'
import TodayIcon        from '@material-ui/icons/CalendarToday'

import LabelItem from '../../components/items/LabelItem'

import ThingContainer from '../containers/ThingContainer'

import ObservationVO from '../../things/Observation'
import ThingVO       from '../../things/Thing'
import WordVO        from '../../things/Word'

import api  from '../../configs/api'

const styles = () => ({});

const third =
{
    md :  4 ,
    sm :  6 ,
    xs : 12
};

class Observation extends ThingContainer
{
    getContent = () =>
    {
        let { lang, thing } = this.props;

        const locale = this.getLocale() ;

        const { labels } = locale ;

        let indicator ;
        let end ;
        let location ;
        let name ;
        let options ;
        let type ;
        let start ;
        let status ;

        if( thing instanceof ObservationVO )
        {
            let {
                additionalType,
                endDate,
                eventStatus,
                location : l ,
                name : n,
                startDate,
            } = thing ;

            if( isString( n ) )
            {
                name = n ;
            }

            if( additionalType instanceof ThingVO )
            {
                type = additionalType.getLocaleName( lang ) ;
            }

            if( isString( endDate ) )
            {
                end = moment(endDate).format(locale.datePattern)
            }

            if( eventStatus instanceof WordVO )
            {
                let { bgcolor:backgroundColor, color } = eventStatus ;

                if( isString(backgroundColor) && backgroundColor !== '' )
                {
                    backgroundColor = '#' + backgroundColor ;
                }
                else
                {
                    backgroundColor = grey[200] ;
                }

                if( isString(color) && color !== '' )
                {
                    color = '#' + color ;
                }
                else
                {
                    color = grey[500] ;
                }
                if( isString( color ) )
                {
                    indicator = (
                    <Avatar
                        className = 'mr-8 w-24 h-24 self-center'
                        variant   = "rounded"
                        style     = {{ backgroundColor }}
                    >
                        <NotificationIcon
                            style = {{ padding:4, color : "#" + color }}
                        />
                    </Avatar>);
                }
                status = eventStatus.getLocaleName( lang )
            }

            if( l instanceof ThingVO )
            {
                location = l.getLocaleName( lang ) ;
                let { url } = l ;
                if( isString( url ) )
                {
                    options =
                    {
                        component : Link ,
                        to        : { pathname : l.url.split(api.url)[1] }
                    };
                }
            }
            else
            {
                location = null ;
            }

            if( isString( startDate ) )
            {
                start = moment(startDate).format(locale.datePattern)
            }
        }

        return (
            <Grid container spacing={4}>
                <Grid item {...third}>
                    <LabelItem enabled={!!(name)} title={ labels.name }>
                        { isString(name) ? ucFirst(name) : labels.empty }
                    </LabelItem>
                </Grid>
                <Grid item {...third}>
                    <LabelItem
                        enabled   = {!!(type)}
                        labelIcon = {<TodayIcon className="mr-8 self-center" style={{ fontSize:18 }}/>}
                        title     = { labels.type }
                    >
                        { isString(type) ? ucFirst(type) : labels.empty }
                    </LabelItem>
                </Grid>
                <Grid item {...third}>
                    <LabelItem enabled={!!(status)} title={ labels.eventStatus } labelIcon={indicator}>
                        { isString(status) ? ucFirst(status) : labels.empty }
                    </LabelItem>
                </Grid>
                <Grid item {...third}>
                    <LabelItem
                        enabled   = {!!(location)}
                        title     = { labels.location }
                        { ...options }
                    >
                        { isString(location) ? ucFirst(location) : labels.empty }
                    </LabelItem>
                </Grid>
                <Grid item {...third}>
                    <LabelItem
                        enabled   = {!!(start)}
                        labelIcon = {<ScheduleIcon className="mr-8 self-center" style={{ fontSize:18 }}/>}
                        title     = { labels.startDate }
                    >
                        { isString(start) ? ucFirst(start) : labels.empty }
                    </LabelItem>
                </Grid>
                <Grid item {...third}>
                    <LabelItem
                        enabled   = {!!(end)}
                        labelIcon = {<ScheduleIcon className="mr-8 self-center" style={{ fontSize:18 }}/>}
                        title     = { labels.endDate }
                    >
                        { isString(end) ? ucFirst(end) : labels.empty }
                    </LabelItem>
                </Grid>

            </Grid>
        );
    };

    getEntry = init =>
    {
        let empty = {} ;
        if( init instanceof ThingVO )
        {
            empty = init.toObject() ;
        }
        else if( init )
        {
            empty = { ...init } ;
        }
        return new ObservationVO(empty);
    };

    getLocale = () => this.props.locale.things.observation;

    onEdit = ( item ) =>
    {
        const { onChange, thing } = this.props ;
        if( thing && item )
        {
            const {
                additionalType,
                endDate,
                eventStatus,
                location,
                name,
                startDate
            } = item ;

            thing.additionalType = additionalType ;
            thing.endDate        = endDate;
            thing.eventStatus    = eventStatus;
            thing.location       = location;
            thing.modified       = moment(new Date()).toISOString();
            thing.name           = name ;
            thing.startDate      = startDate ;

            if( thing instanceof ThingVO )
            {
                thing.populate();
            }

            if( this._mounted )
            {
                this.forceUpdate() ;
            }

            if( onChange instanceof Function )
            {
                onChange( thing ) ;
            }
        }
    };
}

Observation.defaultProps =
{
    ...ThingContainer.defaultProps ,
    icon       : <FeedbackIcon/> ,
    optionMode : 'edit'
};

Observation.propTypes =
{
    ...ThingContainer.propTypes
};

export default compose( withStyles( styles ) , withRouter , initApp )( Observation ) ;
