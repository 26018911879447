import isString from 'vegas-js-core/src/isString'

import getPreferredMediaSource from './getPreferredMediaSource'

import Thing from './Thing'

const getSource = element =>
{
    if( element )
    {
        const { contentUrl } = element;
        if ( isString(contentUrl) )
        {
            const source = getPreferredMediaSource( element ) ;
            if( source )
            {
                const { contentUrl : sourceContentUrl } = source ;
                return sourceContentUrl ;
            }
        }
        else if ( isString(element) )
        {
            return element ;
        }
    }
    return null ;
};

/**
 * Returns the string content url defines by the image property of a specific thing.
 * @param {Thing} thing - The thing reference.
 * @returns {null|string} The string content url defines by the image property of a specific thing.
 */
const getImageOrLogoSource = thing =>
{
    if( thing instanceof Thing )
    {
        let src;
        const { image , logo } = thing ;
        if( image )
        {
            src = getSource( image ) ;
        }

        if( !isString(src) && logo )
        {
            src = getSource( logo ) ;
        }

        if( isString(src) )
        {
            return src ;
        }
    }
    return null ;
};

export default getImageOrLogoSource ;
