import React , { Component, Fragment } from 'react'

import PropTypes from 'prop-types'

import moment from 'moment/moment'

import DayOfWeek from 'vegas-js-calendar/src/DayOfWeek'

import format   from 'vegas-js-core/src/strings/fastformat'
import notEmpty from 'vegas-js-core/src/strings/notEmpty'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import { Link, Typography } from '@material-ui/core'

import withLang   from '../../../contexts/i18n/withLang'
import withLocale from '../../../contexts/i18n/withLocale'

import OpeningHoursSpecificationVO from '../../../things/OpeningHoursSpecification'

class HourSpecification extends Component
{
    getLocale = () => this.props.locale.things.hourSpecification ;

    render = () =>
    {
        const { helper, thing } = this.props ;
        if( thing instanceof OpeningHoursSpecificationVO )
        {
            const { lang } = this.props ;

            const locale = this.getLocale() ;

            let label ;
            let finish ;
            let period ;
            let start ;

            let {
                alternateName,
                description,
                closes,
                dayOfWeek,
                opens ,
                validFrom,
                validThrough
            } = thing ;

//            console.log( this + " render dayOfWeek" , dayOfWeek ) ;
//            thing.dayOfWeek = 'Mo-Su' ;

            if( locale )
            {
                const { labels } = locale ;

                let {
                    close,
                    defaultTitle,
                    endPattern,
                    period : { from, full, to } = {} ,
                    startPattern,
                    time,
                } = labels ;

                // --------- alternateName

                if( alternateName )
                {
                    let text = thing.getLocaleAlternateName( lang ) ;
                    if ( notEmpty( text ) )
                    {
                        label = text ;
                    }
                }

                // --------- Description

                if( description )
                {
                    description = thing.getLocaleDescription( lang ) ;
                    if( notEmpty(description) )
                    {
                        description = <Typography variant='caption'>{ucFirst(description)}</Typography> ;
                    }
                    else
                    {
                        description = null ;
                    }
                }


                // -------- Period

                if( notEmpty(validFrom) )
                {
                    if( notEmpty(startPattern) )
                    {
                        start = moment(validFrom).format( startPattern ) ;
                    }
                    else
                    {
                        start = validFrom ;
                    }
                }

                if( notEmpty(validThrough) )
                {
                    if( notEmpty(endPattern) )
                    {
                        finish = moment(validThrough).format( endPattern ) ;
                    }
                    else
                    {
                        finish = validThrough ;
                    }
                }

                if( finish )
                {
                    if( start )
                    {
                        if( start === finish )
                        {
                            period = format( from , start ) ;
                        }
                        else
                        {
                            period = format( full , start , finish ) ;
                        }
                    }
                    else
                    {
                        period = format( to , finish ) ;
                    }
                }
                else if ( start )
                {
                    period = format( from , start ) ;
                }
                else
                {
                    period = null ;
                }

                if( notEmpty(period) )
                {
                    period = <Typography variant="caption">{ period }</Typography> ;
                }
                else
                {
                    period = null ;
                }


                if( (dayOfWeek === null) && (opens === '00:00') && (closes === '00:00') )
                {
                    return (
                        <Fragment>
                            { this.getLabel( label || close ) }
                            { description }
                            { period }
                        </Fragment>
                    )
                }
                else
                {
                    let days ;
                    if( dayOfWeek && dayOfWeek !== '' && helper )
                    {
                        days = helper.format( helper.parse( dayOfWeek ) , true ) ;
                    }

                    let title ;
                    if( notEmpty(label) )
                    {
                        title = (
                            <Fragment>
                                { this.getLabel( label ) }
                                { days && <Typography className="font-semibold" variant="body2">{days}</Typography> }
                            </Fragment>
                        );
                    }
                    else if( notEmpty(days) )
                    {
                        title = this.getLabel( days ) ;
                    }
                    else
                    {
                        title = this.getLabel( defaultTitle ) ;
                    }

                    let times ;
                    if( opens && closes )
                    {
                        opens  = opens.split(',') ;
                        closes = closes.split(',') ;
                        const len1 = opens.length ;
                        const len2 = closes.length ;
                        if( len1 > 0 && len1 === len2 )
                        {
                            if( len1 === 1 && (opens[0] === '00:00') && (closes[0] === opens[0]) )
                            {
                                times = (
                                    <Typography
                                        className = 'font-medium'
                                        variant   = 'caption'
                                    >
                                        { close }
                                    </Typography>
                                );
                            }
                            else
                            {
                                times = opens.map( ( item , index ) =>
                                (
                                    <Typography
                                        key       = { 'time_' + index }
                                        className = 'font-medium'
                                        variant   = 'caption'
                                    >
                                        {format(time,item,closes[index])}
                                    </Typography>
                                )) ;
                            }
                        }
                    }

                    return (
                        <div className='flex flex-col w-full'>
                            { title }
                            { period }
                            { times }
                            { description }
                        </div>
                    )
                }
            }
        }

        return null ;
    };

    getLabel = ( label ) =>
    {
        if( notEmpty( label ) )
        {
            const { editable } = this.props ;
            label = ucFirst(label) ;
            if( editable )
            {
                const { LinkOptions, onClick } = this.props ;
                label = (
                <Link
                    className = "cursor-pointer text-blue-600 font-medium"
                    variant   = "subtitle1"
                    underline = 'none'
                    href      = { null }
                    onClick   = { onClick }
                    { ...LinkOptions }
                >
                    { label }
                </Link>) ;
            }

            return (
            <Typography className={ 'font-semibold text-gray-800' } variant={'body1'}>
                {label}
            </Typography>) ;
        }

        return null ;
    };
}

HourSpecification.defaultProps =
{
    helper      : null,
    LinkOptions : null ,
    locale      : null,
    thing       : null
};

HourSpecification.propTypes =
{
    helper      : PropTypes.instanceOf(DayOfWeek),
    LinkOptions : PropTypes.object,
    locale      : PropTypes.object,
    thing       : PropTypes.object
};

export default withLang( withLocale( HourSpecification ) ) ;
