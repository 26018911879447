import React from "react"

import ucFirst from 'vegas-js-core/src/strings/ucFirst'

import AboutIcon      from '@material-ui/icons/AccountBox'
import ActivitiesIcon from '@material-ui/icons/LineWeight'
import FavoriteIcon   from '@material-ui/icons/Bookmark'
import SessionsIcon   from '@material-ui/icons/CloudCircle'

import ThingView , { defaultStyle } from '../../display/views/ThingView'

import About from './views/About'

import ActivityList  from '../../display/lists/ActivityList'
import FavoritesList from '../../display/lists/FavoritesList'
import Sessions      from '../../display/things/Sessions'

import RequestStatus from '../../net/RequestStatus'

import User from '../../vo/User'

import api   from '../../configs/api'
import paths from '../../configs/paths'

import initView from '../../display/views/initView'

import Thing from '../../things/Thing'

const styles = defaultStyle() ;

class Me extends ThingView
{
    constructor( props )
    {
        super(props) ;
        this.state  =
        {
            ...this.state ,
            status : RequestStatus.SUCCESS,
            thing  : props.user
        }
    }

    componentDidUpdate( prevProps , prevState )
    {
        const { user:thing } = this.props ;
        if( prevState.thing !== thing )
        {
            this.setState({ ...this.state , thing })
        }
    }

    getLocale = () => this.props.locale.views.me ;

    getSubtitle = thing =>
    {
        if( thing instanceof User )
        {
            return thing.email  ;
        }
        return null ;
    };

    getTitle = thing =>
    {
        if( thing instanceof User )
        {
            return ucFirst( thing.getFullNameOrUsername( this.props.lang ) )  ;
        }
        return null ;
    };

    getUri = () => api.url + this.props.path.url ;
}

Me.defaultProps =
{
    ...ThingView.defaultProps,
    clazz       : User,
    first       : 'profile' ,
    loadOnMount : false,
    path        : api.me,
    responsive  : true ,
    routes      :
    [
        { id : 'profile'    , RouteComponent : About         } ,
        { id : 'favorites'  , RouteComponent : FavoritesList , uri : api.me.favorites  , routeClazz : Thing } ,
        { id : 'activities' , RouteComponent : ActivityList  , uri : api.me.activities } ,
        { id : 'sessions'   , RouteComponent : Sessions      , uri : api.me.sessions   } ,
    ],
    tabs :
    [
        { id : 'profile'    , icon : <AboutIcon      className='pr-8' /> } ,
        { id : 'favorites'  , icon : <FavoriteIcon   className='pr-8' /> } ,
        { id : 'activities' , icon : <ActivitiesIcon className='pr-8' /> } ,
        { id : 'sessions'   , icon : <SessionsIcon   className='pr-8' /> } ,
    ],
    titleAuto : false
};

Me.propTypes =
{
    ...ThingView.propTypes,
    path : paths.me
};

export default initView( Me, styles );
