import React from 'react'

import PropTypes from 'prop-types'

import Suggestion from '../things/Suggestion'

import CoreDialog from '../../components/dialogs/CoreDialog'

import initDialog from './initDialog'

export class SuggestionDialog extends CoreDialog
{
    getContent = () =>
    {
        let { suggestions } = this.props;
        if( suggestions instanceof Array && suggestions.length > 0 )
        {
            const {
                label,
                lang,
                sort,
                thingRef
            }
            = this.props  ;

            if( sort instanceof Function )
            {
                suggestions = [ ...suggestions ] ;
                suggestions.sort( sort(lang) , this.props ) ;
            }

            suggestions = suggestions.map( ( item , index ) =>
            {
                return (
                <Suggestion
                    key      = { 'suggestion_' + index }
                    label    = { label }
                    onSelect = { this._select }
                    thing    = { item }
                    thingRef = { thingRef }
                />);
            });

            return (
            <div className='px-8 py-8 mt-16'>
                { suggestions }
            </div>);
        }

        return null ;
    };

    _select = suggestion =>
    {
        if( suggestion )
        {
            const { change , onChange } = this.props;
            if( change instanceof Function )
            {
                change.bind(this)( this.props , this.state ) ;
            }
            if( onChange )
            {
                onChange( { target : { value : suggestion } } ) ;
            }
        }
    };
}

SuggestionDialog.defaultProps =
{
    ...CoreDialog.defaultProps,
    change          : null ,
    label           : null ,
    maxWidth        : 'sm',
    showAgreeButton : false,
    sort            : null ,
    suggestions     : null,
    thingRef        : null
};

SuggestionDialog.propTypes =
{
    ...CoreDialog.propTypes,
    change      : PropTypes.func,
    label       : PropTypes.oneOfType([PropTypes.string,PropTypes.func]) ,
    logics      : PropTypes.object,
    sort        : PropTypes.func,
    suggestions : PropTypes.array,
    thingRef    : PropTypes.func
};

export default initDialog({ useLocale:false , useStyles:false })( SuggestionDialog );
