import React , { Component } from 'react'

import clsx from 'clsx' ;

import PropTypes from 'prop-types'

import URI from 'urijs'

import compose  from 'vegas-js-core/src/functors/compose'
import isString from 'vegas-js-core/src/isString'
import notEmpty from 'vegas-js-core/src/strings/notEmpty'

import { withStyles } from '@material-ui/core/styles'

import { Avatar } from '@material-ui/core'

import withConfig from '../../contexts/config/withConfig'
import withLang   from '../../contexts/i18n/withLang'

import getImageOrLogoSource from "../../things/getImageOrLogoSource"
import getLocaleThingLabel  from "../../things/getLocaleThingLabel"

import Thing   from '../../things/Thing'
import Word    from '../../things/Word'

import Picture from '../things/Picture'


const styles = theme => (
{
    image :
    {
        height : 20 ,
        width  : 'auto'
    } ,
    small :
    {
        width  : theme.spacing(3),
        height : theme.spacing(3)
    },
    large :
    {
        width  : theme.spacing(15),
        height : theme.spacing(15),
    }
});

class ThingAvatar extends Component
{
    getClassName = size => clsx( size , this.props.shadow ? 'shadow-md' : null , this.props.className ) ;

    getWord = thing =>
    {
        if( thing instanceof Word )
        {
            return thing ;
        }
        else if( thing instanceof Thing )
        {
            const { additionalType } = thing;
            return additionalType instanceof Word ? additionalType : null;
        }
        return null ;
    };

    render = () =>
    {
        let {
            classes,
            config,
            defaultIcon,
            icon,
            lang,
            queries,
            thing,
            size,
            style,
            variant
        }
        = this.props ;

        if( size === 'small' )
        {
            size = classes.small  ;
        }
        else if( size === 'large' )
        {
             size = classes.large  ;
        }
        else
        {
            size = null ;
        }

        let color = null ;

        style = { ...style } ;

        if( !icon && thing )
        {
            if( thing instanceof Thing )
            {
                const { getImage , getWord } = this.props ;

                let word = getWord instanceof Function ? getWord(thing) : this.getWord(thing);
                if( word )
                {
                    let { bgcolor:bc , color:c } = word ;

                    if( notEmpty(bc) )
                    {
                        style = { ...style , backgroundColor:'#'+bc } ;
                    }

                    if( notEmpty(c) )
                    {
                        color = c ;
                        style = { ...style , color:'#' + c } ;
                    }
                }

                let src = getImage instanceof Function
                        ? getImage(thing)
                        : getImageOrLogoSource( thing ) ;

                if( notEmpty(src) )
                {
                    let uri = new URI(src) ;

                    if( thing instanceof Word )
                    {
                        uri.addSearch( {
                            w : config.icon.maxWidth ,
                            h : config.icon.maxWidth ,
                            ...queries
                        } ) ;

                        if( notEmpty(color) )
                        {
                            uri.addSearch( { color } ) ;
                        }

                        return (
                            <Avatar
                                className = { this.getClassName( size ) }
                                style     = { style }
                                variant   = { variant }
                            >
                                <img
                                    alt       = { thing.getLocaleName(lang) }
                                    className = { classes.image }
                                    //loading   = 'lazy'
                                    src       = { uri.toString() }
                                />
                            </Avatar>
                        );
                    }
                    else
                    {
                        const { image , logo } = thing ;

                        let media ;
                        if( image )
                        {
                            media = image ;
                        }
                        else if( logo )
                        {
                            media = logo ;
                        }

                        if( media instanceof Object )
                        {
                            return (
                                <Avatar
                                    className = { this.getClassName( size ) }
                                    //imgProps  = {{ loading : 'lazy' }}
                                    style     = { style }
                                    variant   = { variant }
                                >
                                    <Picture
                                        media   = { media }
                                        variant = { 'avatar' }
                                    />
                                </Avatar>
                            )
                        }
                        else if( isString( media ) )
                        {
                            return (
                                <Avatar
                                    className = { this.getClassName( size ) }
                                    style     = { style }
                                    variant   = { variant }
                                >
                                    <img
                                        alt       = { thing.getLocaleName(lang) }
                                        className = { classes.image }
                                        style     = {{ width:'100%',height:'100%' ,objectFit:'cover' }}
                                        //loading   = 'lazy'
                                        src       = { media }
                                    />
                                </Avatar>
                            );
                        }
                    }
                }
                else
                {
                    let name = getLocaleThingLabel( thing , lang) ;
                    if( notEmpty(name) )
                    {
                        name = name.charAt(0).toUpperCase() ;
                        return (
                            <Avatar
                                className = { this.getClassName( size ) }
                                style     = { style }
                                variant   = { variant }
                            >
                                { name }
                            </Avatar>
                        );
                    }
                }

            }
            else
            {
                icon = thing ;
            }
        }

        if( !icon )
        {
            icon = defaultIcon ;
        }

        if( icon )
        {
            return (
                <Avatar
                    className = { this.getClassName(size) }
                    style     = { style }
                    variant   = { variant }
                >
                    { icon }
                </Avatar>
            );
        }

        return null ;
    };
}

ThingAvatar.defaultProps =
{
    className   : null,
    defaultIcon : null,
    icon        : null,
    mode        : 'auto',
    queries     : null ,
    size        : 'default',
    shadow      : true,
    style       : null,
    thing       : null,
    variant     : 'rounded'
};

ThingAvatar.propTypes =
{
    className   : PropTypes.string,
    defaultIcon : PropTypes.element,
    getImage    : PropTypes.func,
    getWord     : PropTypes.func,
    icon        : PropTypes.element,
    mode        : PropTypes.oneOf(['auto','icon','cover']),
    queries     : PropTypes.object,
    shadow      : PropTypes.bool,
    size        : PropTypes.oneOf(['default','large','small']),
    style       : PropTypes.object,
    thing       : PropTypes.oneOfType([PropTypes.instanceOf(Thing),PropTypes.element]) ,
    variant     : PropTypes.oneOf(['circle','rounded','square'])
};

export default compose(
    withStyles( styles ) ,
    withConfig,
    withLang
)( ThingAvatar ) ;
