import React from 'react'

import clsx from 'clsx'

import PropTypes from 'prop-types'

import notEmpty from 'vegas-js-core/src/strings/notEmpty'

import { Link, Typography } from '@material-ui/core'

import CallToActionIcon from '@material-ui/icons/CallToAction'

import withConfig from '../../contexts/config/withConfig'
import withi18n   from '../../contexts/i18n/withi18n'

import MethodBadge from '../../components/badges/MethodBadge'

import Activity from '../../vo/Activity'

import { ThingCell } from './ThingCell'

const preventDefault = event => event.preventDefault();

class ActivityCell extends ThingCell
{
    getAgent = () =>
    {
        const { thing : { agent } = {} } = this.props ;
        if( notEmpty(agent) )
        {
            return (
                <Typography
                    className = "font-normal"
                    variant   = 'caption'
                >
                    { agent }
                </Typography>
            );
        }
        return null ;
    };

    getContent = () =>
    {
        const { informations:visible , thing } = this.props;
        if ( visible && thing instanceof Activity )
        {
            const agent  = this.getAgent() ;
            const method = this.getMethod() ;
            const ip     = this.getIp({ className: method ? 'pl-8' : '' }) ;
            if( agent || ip || method )
            {
                return (
                    <div className={ clsx( "flex flex-1 flex-col px-8") } >
                        <div className='flex flex-row flex-1 items-center'>
                            { method }
                            { ip }
                        </div>
                        { agent }
                    </div>
                )
            }
        }
        return null;
    };

    getIp = ( { className = null } = {} ) =>
    {
        const { ip:visible, thing : { ip } = {} } = this.props ;
        if( visible && notEmpty(ip) )
        {
            const { config : { ip_api_url:path = "" } = {} } = this.props ;
            return (
                <Link
                    className = { className }
                    color     = 'inherit'
                    href      = { path + ip }
                    onClick   = { preventDefault }
                    variant   = 'caption'
                >
                    { ip }
                </Link>
            );
        }
        return null ;
    };

    getLocaleTitle = () =>
    {
        const { thing : { resource } = {} } = this.props ;
        if( notEmpty(resource) )
        {
            return resource ;
        }
        return null ;
    };

    getMethod = () =>
    {
        const { thing : { method } = {} } = this.props ;
        if( notEmpty(method) )
        {
            return <div className='flex py-8'><MethodBadge value={method}/></div>
        }
        return null ;
    };

    getPath = () =>
    {
        const { thing : { resource } = {} } = this.props ;
        if ( notEmpty(resource) )
        {
            return '/' + resource ;
        }
        return null ;
    };

    getLocale = () => this.props.locale.display.cells.activity;

}

ActivityCell.defaultProps =
{
    ...ThingCell.defaultProps,
    agent        : true,
    icon         : <CallToActionIcon fontSize='small'/>,
    informations : true ,
    ip           : true,
    method       : true,
    mode         : 'link' ,
    modified     : false ,
    titleProps   : { className:'font-normal' , variant:'body2' }
};

ActivityCell.propTypes =
{
    ...ThingCell.propTypes,
    agent        : PropTypes.bool,
    informations : PropTypes.bool,
    ip           : PropTypes.bool,
    method       : PropTypes.bool
};

export default withConfig( withi18n( ActivityCell ) ) ;
