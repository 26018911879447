import React from 'react'

import compose  from 'vegas-js-core/src/functors/compose'

import initApp from '../../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import PeopleIcon from '@material-ui/icons/People'

import PropertyValue from '../../../things/PropertyValue'
import Person        from '../../../things/Person'
import Thing         from '../../../things/Thing'

import ThingChildren, { styles } from '../ThingChildren'

class Attendee extends ThingChildren
{
    addMapUri = ( item , uri ) =>
    {
        if( item instanceof PropertyValue )
        {
            const { value } = item ;
            if( value instanceof Thing )
            {
                const { id } = value ;
                return uri + '/' + id ;
            }
        }
        return uri ;
    }

    addPrepare = item =>
    {
        const { value } = item ;
        return { value } ;
    }

    getLocale = () => this.props.locale.things.observation.attendee ;
}

Attendee.defaultProps =
{
    ...ThingChildren.defaultProps ,
    clazz      : Person,
    emptyClazz : PropertyValue,
    emptyIcon  : null,
    icon       : <PeopleIcon/> ,
    member     : 'attendee'
};

Attendee.propTypes =
{
    ...ThingChildren.propTypes
};

export default compose(
    withStyles( styles ) ,
    withRouter ,
    initApp
)( Attendee );
