const period =
{
    copy :
    {
        snack   : 'Période copiée dans le presse-papiers.' ,
        tooltip : 'Copier la période'
    },
    label    : '{0}',
    title    : 'Période',
    patterns :
    {
        day       : "Le <span class='text-gray-700'>{0}</span>" ,
        date      : 'ddd ll',
        dateTime  : 'ddd ll à HH:mm',
        interval  : "Du <span class='text-gray-700'>{0}</span> au <span class='text-gray-700'>{1}</span>" ,
        time      : 'HH:mm',
        timeRange : "Le <span class='text-gray-700'>{0}</span> de <span class='text-gray-700'>{1}</span> à <span class='text-gray-700'>{2}</span>" ,
        until     : "Jusqu'au <span class='text-gray-700'>{0}</span>"
    }
};

export default period ;
