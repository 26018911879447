const mediaContainer =
{
    actions :
    {
        embed  : 'Ajouter un média externe',
        sort   : 'Trier les médias' ,
        upload : 'Téléverser un fichier'
    },
    helmet :
    {
        title : 'Médiathèque' ,
        metas :
        [
            { charSet : 'utf-8'} ,
            { name    : 'description' , content : 'Bibliothèque de médias de la plateforme.' } ,
            { name    : 'keywords'    , content : 'gallery,medias,photos,videos,images'      }
        ]
    },
    defaultFilter : 'all',
    failed        : 'Problème de connexion',
    filters       :
    [
        {
            id    : 'all',
            label : 'Tous',
            value : null
        },
        {
            id    : 'image',
            label : 'Images',
            value : '{"encodingFormat":"image"}'
        },
        {
            id    : 'audio',
            label : 'Audios',
            value : '{"encodingFormat":"audio"}'
        },
        {
            id    : 'document',
            label : 'Documents',
            value : '{"encodingFormat":"-audio,-image,-video"}'
        },
        {
            id    : 'video',
            label : 'Vidéos',
            value : '{"encodingFormat":"video"}'
        }
    ],
    loading : 'Chargement ...' ,
    search  : 'Rechercher un média ...' ,
    sorts   :
    [
        { label : 'Date de modification' , value : 'modified' }  ,
        { label : 'Nom'                  , value : 'name'     }  ,
        { label : 'Date de création'     , value : 'created'  }
    ],
    speedDial :
    {
        ariaLabel : "Ajouter un média"
    },
    title : 'Médiathèque'
};

export default mediaContainer ;
