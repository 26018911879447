const articles =
{
    helmet :
    {
        title : 'Articles' ,
        metas : []
    },
    columns :
    {
        type     : 'Type' ,
        created  : 'Création' ,
        modified : 'Modification'
    },
    empty      : 'Aucun article trouvé',
    failed     : 'Problème de connexion',
    loading    : "Chargement ..." ,
    noDataText : 'Aucun résultat' ,
    remove     :
    {
        agree        : 'Supprimer' ,
        description  : 'Souhaitez-vous supprimer l\'enregistrement:' ,
        descriptionX : 'Souhaitez-vous supprimer les {0} enregistrements:' ,
        disagree     : 'Annuler' ,
        loading      : 'Suppression en cours...' ,
        title        : 'Supprimer cet article ?',
        titleX       : 'Supprimer des articles ?'
    },
    tooltips    :
    {
        add        : 'Ajouter' ,
        activate   : 'Activer' ,
        deactivate : 'Désactiver' ,
        remove     : 'Supprimer'
    },
    search : 'Rechercher un article ...' ,
    title  : 'Articles'
};

export default articles ;
