import React from "react"

import notEmpty from 'vegas-js-core/src/strings/notEmpty'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import CourseIcon   from '@material-ui/icons/Map'
import DiscoverIcon from '@material-ui/icons/Book'
import InfoIcon     from '@material-ui/icons/Info'
import MediasIcon   from '@material-ui/icons/PermMedia'
import StepIcon     from '@material-ui/icons/PinDrop'

import ThingView , { defaultStyle } from '../../display/views/ThingView'

import About     from './views/About'
import Discover  from './views/Discover'
import Info      from './views/Info'
import Itinerary from './views/Itinerary'
import Medias    from '../medias/Medias'

import CourseVO from '../../things/Course'

import api   from '../../configs/api'
import paths from '../../configs/paths'

import initView from '../../display/views/initView'

const styles = defaultStyle();

class Course extends ThingView
{
    getLocale = () => this.props.locale.views.course ;

    getSubtitle = thing =>
    {
        if( thing instanceof CourseVO )
        {
            let alternativeHeadline = thing.getLocaleAlternativeHeadline(this.props.lang) ;
            if( notEmpty(alternativeHeadline) )
            {
                return ucFirst(alternativeHeadline)  ;
            }
        }
        return null ;
    };

    getTitle = thing =>
    {
        if( thing instanceof CourseVO )
        {
            const { lang } = this.props ;

            let title = thing.getLocaleHeadline(lang) ;
            if( notEmpty( title ) )
            {
                return ucFirst(title)  ;
            }

            title = thing.getLocaleName(lang) ;
            if( notEmpty( title ) )
            {
                return ucFirst(title)  ;
            }
        }
        return null ;
    };
}

Course.defaultProps =
{
    ...ThingView.defaultProps,
    back       : true ,
    clazz      : CourseVO ,
    icon       : <CourseIcon/>,
    path       : api.courses,
    preferable : true ,
    queries    : { skin : 'compact' } ,
    responsive : true ,
    routes     :
    [
        { id : 'about'    , RouteComponent : About     } ,
        { id : 'info'     , RouteComponent : Info      } ,
        { id : 'steps'    , RouteComponent : Itinerary } ,
        { id : 'discover' , RouteComponent : Discover  } ,
        { id : 'medias'   , RouteComponent : Medias    }
    ],
    tabs :
    [
        { id : 'about'    , icon : <CourseIcon   className='pr-8' /> } ,
        { id : 'info'     , icon : <InfoIcon     className='pr-8' /> } ,
        { id : 'steps'    , icon : <StepIcon     className='pr-8' /> } ,
        { id : 'discover' , icon : <DiscoverIcon className='pr-8' /> } ,
        { id : 'medias'   , icon : <MediasIcon   className='pr-8' /> }
    ],
    titleAuto : false // Important to use always the getTitle() method
};

Course.propTypes =
{
    ...ThingView.propTypes,
    path : paths.courses
};

export default initView( Course, styles );
