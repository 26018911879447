const analysis =
{
    labels:
    {
        confirmed   : 'Confirmation laboratoire',
        description : 'Information sur l\'analyse' ,
        disease     : 'Maladie',
        empty       : '----',
        no          : 'Non',
        yes         : 'Oui'
    }
};

export default analysis ;