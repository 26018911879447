import React from 'react'

import PropTypes from 'prop-types'

import MovementIcon from '@material-ui/icons/Brush'
import TemporalIcon from '@material-ui/icons/Timer'

import withi18n from '../../contexts/i18n/withi18n'

import PlaceLabel  from '../labels/PlaceLabel'

import Place from '../../things/Place'
import Thing from '../../things/Thing'

import getHorizontalElements from '../getHorizontalElements'

import { ThingCell } from './ThingCell'

class ConceptualObjectCell extends ThingCell
{
    getContent = () =>
    {
        const { thing } = this.props;
        if ( thing )
        {
            const elements = [ 'location' , 'movement' , 'temporal' ] ;
            return getHorizontalElements( { elements , factory:this } ) ;
        }
        return null;
    };

    getLocale = () => this.props.locale.display.cells.conceptualObject;

    getLocation= ({ className = null } = {} ) =>
    {
        const { location:visible } = this.props;
        const thing = this.getThing();
        if ( visible && thing instanceof Thing )
        {
            const { location } = thing;
            if ( location instanceof Place )
            {
                return <PlaceLabel className={className} thing={location} />;
            }
        }
        return null;
    };

    getMovement = ( { className = null , key=undefined } = {}  ) => this.getWord('movement',this.props.movementIcon)( { className , key } ) ;

    getTemporal = ( { className = null , key=undefined } = {}  ) => this.getWord('temporal',this.props.temporalIcon)( { className , key } ) ;
}

ConceptualObjectCell.defaultProps =
{
    ...ThingCell.defaultProps,
    location     : true,
    movement     : true,
    movementIcon : <MovementIcon fontSize='small' />,
    temporal     : true,
    temporalIcon : <TemporalIcon fontSize='small' />,
    typeMember   : 'category'
};

ConceptualObjectCell.propTypes =
{
    ...ThingCell.propTypes,
    location     : PropTypes.bool,
    movement     : PropTypes.bool,
    movementIcon : PropTypes.element,
    temporal     : PropTypes.bool,
    temporalIcon : PropTypes.element
};

export default withi18n(ConceptualObjectCell) ;
