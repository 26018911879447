import FormElements from '../../../../display/forms/FormElements'

import Person from '../../../../things/Person'
import Thing  from '../../../../things/Thing'

import api from '../../../../configs/api'

const elements =
[
    {
        type : FormElements.TEXTFIELD ,
        init :
        {
            id           : 'name',
            key          : 'name',
            label        : 'Nom Complet',
            autoFocus    : true,
            fullWidth    : true,
            helperText   : 'Obligatoire',
            margin       : 'normal',
            required     : true,
            select       : false,
            autoComplete : 'off' ,
            type         : 'search',
            variant      : 'outlined'
        }
    },
    {
        type : FormElements.TEXTFIELD ,
        init :
        {
            id           : 'givenName',
            key          : 'givenName',
            label        : 'Prénom',
            autoFocus    : true,
            fullWidth    : true,
            margin       : 'normal',
            required     : false,
            select       : false,
            autoComplete : 'off' ,
            type         : 'search',
            variant      : 'outlined'
        }
    },
    {
        type : FormElements.TEXTFIELD ,
        init :
        {
            id           : 'familyName',
            key          : 'familyName',
            label        : 'Nom de Famille',
            autoFocus    : true,
            fullWidth    : true,
            margin       : 'normal',
            required     : false,
            select       : false,
            autoComplete : 'off' ,
            type         : 'search',
            variant      : 'outlined'
        }
    },
    {
        type : FormElements.TEXTFIELD ,
        init :
        {
            id           : 'alternateName',
            key          : 'alternateName',
            label        : 'Pseudonyme',
            autoFocus    : true,
            fullWidth    : true,
            helperText   : null,
            margin       : 'normal',
            required     : false,
            select       : false,
            autoComplete : 'off' ,
            type         : 'search',
            variant      : 'outlined'
        }
    },
    {
        type       : FormElements.SEARCH_SELECTOR ,
        path       : api.thesaurus.honorificPrefix ,
        clearLabel : 'Vider' ,
        closeLabel : 'Fermer' ,
        init       :
        {
            id        : 'honorificPrefix',
            key       : 'honorificPrefix',
            label     : 'Préfixe',
            autoFocus : false,
            fullWidth : true,
            margin    : 'normal',
            required  : false,
            type      : 'search',
            variant   : 'outlined'
        },
        inputProps :
        {
            label : 'Préfixe...'
        }
    },
    {
        type : FormElements.TEXTFIELD ,
        init :
        {
            id           : 'honorificSuffix',
            key          : 'honorificSuffix',
            label        : 'Suffixe',
            autoFocus    : true,
            fullWidth    : true,
            helperText   : null,
            margin       : 'normal',
            required     : false,
            select       : false,
            autoComplete : 'off' ,
            type         : 'search',
            variant      : 'outlined'
        }
    },
    {
        type       : FormElements.SEARCH_SELECTOR ,
        path       : api.thesaurus.genders ,
        clearLabel : 'Vider' ,
        closeLabel : 'Fermer' ,
        init       :
        {
            id        : 'gender',
            key       : 'genre',
            label     : 'Genre',
            autoFocus : false,
            fullWidth : true,
            margin    : 'normal',
            required  : false,
            type      : 'search',
            variant   : 'outlined'
        },
        inputProps :
        {
            label : 'Genre...'
        }
    }
];

const prepare = ( item ) =>
{
    let datas = null ;
    if( item instanceof Person)
    {
        const {
            name ,
            alternateName,
            gender,
            givenName,
            familyName,
            honorificPrefix,
            honorificSuffix
        } = item ;
        
        datas =
        {
            name ,
            alternateName ,
            givenName,
            familyName,
            gender : gender instanceof Thing ? String(gender.id) : null ,
            honorificPrefix : honorificPrefix instanceof Thing ? String(honorificPrefix.id) : '' ,
            honorificSuffix
        };
    }
    
    return datas ;
};

const identity =
{
    edit :
    {
        agree       : 'Sauver' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour modifier cette personne.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...' ,
        success     : 'Mise à jour réussie!' ,
        title       : 'Modifier les informations de la personne' ,
        elements ,
        prepare
    },
    labels :
    {
        alternateName : "Pseudonyme",
        empty         : '-----',
        givenName     : "Prénom",
        familyName    : "Nom de Famille",
        gender        : "Genre",
        name          : 'Nom Complet',
        prefix        : "Préfixe",
        suffix        : "Suffixe"
    },
    title    : 'Identité',
    tooltips :
    {
        add    : "Ajouter" ,
        edit   : "Éditer" ,
        remove : "Supprimer"
    }
};

export default identity ;