import isString from 'vegas-js-core/src/isString'

import generify from './generify'

import Course from './Course'
import Stage  from './Stage'
import Thing  from './Thing'

import populateMedias from './creativework/populateMedias'

/**
 * Creates a new Step definition.
 * @memberOf things
 */
class Step extends Thing
{
    /**
     * Creates a new Step instance.
     * @constructor
     * @param object The generic object to initialize the object the first time.
     */
    constructor( object = null )
    {
        super() ;

        /**
         * The alternativeHeadline of this step.
         * @type {String}
         */
        this.alternativeHeadline = null;

        /**
         * The audios associated with the step.
         *  @type {Array}
         */
        this.audios = null ;

        /**
         * The headline of this stage.
         * @type {String}
         */
        this.headline = null ;

        /**
         * Indicates the number of audios medias of this thing.
         * @type {Array}
         */
        this.numAudios = null;

        /**
         * Indicates the number of videos medias of this thing.
         * @type {Array}
         */
        this.numPhotos = null;

        /**
         * Indicates the number of videos medias of this thing.
         * @type {Array}
         */
        this.numVideos = null;

        /**
         * The photos associated with the step.
         *  @type {Array}
         */
        this.photos = null ;

        /**
         * The position of the step
         * @type {null}
         */
        this.position = null ;

        /**
         * The stage associated with this step.
         * @type {Stage}
         */
        this.stage = null ;

        /**
         * The videos associated with the step.
         *  @type {Array}
         */
        this.videos = null ;

        this.set( object ) ;
    }

    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        super.clear() ;
        this.alternativeHeadline =
        this.audios =
        this.headline  =
        this.numAudios =
        this.numPhotos =
        this.numVideos =
        this.photos =
        this.position =
        this.stage =
        this.videos =
        this.subjectOf = null ;
        return this ;
    }

    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new Step( this.toObject() ) ;
    }

    /**
     * Returns the locale alternativeHeadline expression of the thing.
     * @return {String} The locale headline of the thing.
     */
    getLocaleAlternativeHeadline = this.getLocaleProperty('alternativeHeadline') ;

    /**
     * Returns the locale headline expression of the thing.
     * @return {String} The locale headline of the thing.
     */
    getLocaleHeadline = this.getLocaleProperty('headline') ;

    getLocaleSubtitle = ( lang = null , defaultLang = null ) =>
    {
        let subtitle = this.getLocaleAlternativeHeadline( lang ) ;
        if( !isString(subtitle) || subtitle === "" )
        {
            const { stage } = this ;
            if( stage )
            {
                subtitle = stage.getLocaleAlternativeHeadline( lang, defaultLang ) ;
            }
        }
        return subtitle ;
    };

    getLocaleTitle = ( lang = null , defaultLang = null ) =>
    {
        let title = this.getLocaleHeadline( lang , defaultLang ) ;
        if( !isString(title) || title === "" )
        {
            const { stage } = this ;
            if( stage instanceof Stage )
            {
                title = stage.getLocaleTitle( lang, defaultLang  ) ;
            }
        }

        if( !isString(title) || title === "" )
        {
            title = this.getLocaleName( lang ) ;
        }

        return title ;
    }

    /**
     * Populate the object with specific behaviors or types. Launched by default in the constructor and in the set methods.
     * @returns {Thing} The current reference.
     */
    populate()
    {
        const { stage, subjectOf } = this ;

        populateMedias( this , { useImage:false } ) ;

        if( stage )
        {
            this.stage = new Stage( stage ) ;
        }

        if( subjectOf && !(subjectOf instanceof Course ) )
        {
            this.subjectOf = new Course(subjectOf);
        }

        return this ;
    }

    /**
     * Returns the generic object representation of the item.
     * @return {Object} The generic object representation of the item.
     */
    toObject()
    {
        let {
            alternativeHeadline,
            audios,
            headline ,
            numAudios,
            numPhotos,
            numVideos,
            photos,
            position,
            stage,
            subjectOf,
            videos
        } = this ;

        audios     = generify(audios) ;
        photos     = generify(photos) ;
        stage      = generify(stage);
        subjectOf  = generify(subjectOf);
        videos     = generify(videos) ;

        return {
            ...super.toObject() ,
            alternativeHeadline,
            audios,
            headline,
            numAudios,
            numPhotos,
            numVideos,
            photos,
            position,
            stage,
            subjectOf,
            videos
        }
    }
}

export default Step ;
