import React from 'react'

import ModifiedIcon from '@material-ui/icons/Update'

import { DateLabel } from './DateLabel'

import withi18n  from '../../contexts/i18n/withi18n'
import withSnack from '../../contexts/snack/withSnack'

export class ModifiedLabel extends DateLabel 
{  
    getLocale = () => this.props.locale.display.labels.modified;
}
    
ModifiedLabel.defaultProps =
{
    ...DateLabel.defaultProps,
    formatVariant : 'fromNow' ,
    icon          : <ModifiedIcon className='text-gray-600' fontSize='small' />,
    labelProps    : { className : 'text-gray-600' },
    member        : 'modified' 
}

ModifiedLabel.propTypes =
{
    ...DateLabel.propTypes
}

export default withSnack( withi18n( ModifiedLabel ) );