import React from 'react'

import clsx from 'clsx'

import PropTypes from 'prop-types'

import clean   from 'vegas-js-core/src/objects/clean'
import compose from 'vegas-js-core/src/functors/compose'

import initApp from '../../../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { Button, Paper, Typography } from '@material-ui/core'

import AddIcon   from '@material-ui/icons/AddCircle'
import BoardIcon from '@material-ui/icons/DeveloperBoard'

import api from '../../../../configs/api'

import ThingContainer  from '../../../containers/ThingContainer'
import TitleHeader     from '../../../../components/headers/TitleHeader'
import WorkplaceHeader from '../../workplace/WorkplaceHeader'

import Analysis           from './suspicion/medical/Analysis'
import Hypothesis         from './suspicion/medical/Hypothesis'
import SectorHealthEvents from './suspicion/events/SectorHealthEvents'

import generify from '../../../../things/generify'

import PropertyValue from '../../../../things/PropertyValue'
import Suspicion     from '../../../../things/livestock/reviews/SuspicionReview'
import Workplace     from '../../../../things/Workplace'
import Workshop      from '../../../../things/Workshop'

const styles = () => (
{
    root :
    {
        width : '100%'
    }
});

class SuspicionReview extends ThingContainer
{
    editPrepare = item =>
    {
        if( item )
        {
            let {
                subjectOf:observation,
                value
            } = item ;

            if( observation )
            {
                let { review } = observation ;
                if( review )
                {
                    if( value instanceof Workplace )
                    {
                        value = generify(value,true) ;

                        const {
                            id,
                            name,
                            url,
                            identifier
                        }
                        = value ;

                        const { subject } = review ;
                        if( subject && subject.id !== id )
                        {
                            review.events = [] ;
                        }

                        value =
                        {
                            id,
                            url,
                            name,
                            identifier
                        } ;

                        review.subject = value ;
                    }
                    else
                    {
                        review.subject = null ;
                    }

                    review.subject = value ;

                    review = clean(review.toObject()) ;

                    return { review:JSON.stringify(review) } ;
                }
            }
        }
        return null ;
    };

    getEditButtonThing = () =>
    {
        const { thing } = this.props ;
        if( thing )
        {
            const { review } = thing ;
            if( review instanceof Suspicion )
            {
                return review ;
            }
        }
        return null ;
    };

    getEntry = () =>
    {
        const { thing } = this.props ;
        let about;
        let value;
        if( thing )
        {
            const { review } = thing ;
            if( review instanceof Suspicion )
            {
                const { about:workshop , subject:workplace } = review ;
                about = workshop ;
                value = workplace ;
            }
        }
        return new PropertyValue( { about, subjectOf:thing , value } ) ;
    };

    getLocale = () => this.props.locale.things.livestock.reviews.suspicion ;

    getWorkplace = review =>
    {
        if( review )
        {
            let content ;

            const locale = this.getLocale() ;
            const { subject } = review ;

            //console.log( this + ' getWorkplace', review , this.props.thing ) ;

            if( subject instanceof Workplace )
            {
                content = (
                <div className='w-full'>
                    <TitleHeader
                        className = 'w-full pt-8'
                        icon      = { <BoardIcon/> }
                        label     = { locale.labels.workplace }
                        options   = { this.getEditButton() }
                    />
                    <WorkplaceHeader
                        className = 'mt-16'
                        divider   = { null }
                        thing     = { subject }
                    />
                </div>);
            }
            else
            {
                const { about } = review ;
                if( about instanceof Workshop )
                {
                    const { workplaces } = about ;
                    if( workplaces instanceof Array && workplaces.length > 0 )
                    {
                        const { addIcon, addButtonProps } = this.props ;
                        if( this.isEditable() )
                        {
                            content = (
                            <div className='flex flex-col items-center justify-center w-full'>
                                <Button
                                    className = 'px-16'
                                    color     = 'secondary'
                                    variant   = 'contained'
                                    { ...addButtonProps }
                                    onClick = { () => this.openEditDialog(review) }
                                >
                                    { addIcon }
                                    { locale.labels.select }
                                </Button>
                            </div>);
                        }
                        else
                        {
                            content = (
                                <Typography
                                    className = 'w-full px-16 text-center'
                                    variant   = 'subtitle2'
                                >
                                    { locale.labels.unselect }
                                </Typography>
                            );
                        }
                    }
                    else
                    {
                        content = (
                            <Typography
                                className = 'w-full px-16 text-center'
                                variant   = 'subtitle2'
                            >
                                { locale.labels.empty }
                            </Typography>
                        );
                    }
                }
                else
                {
                    content = (
                        <Typography
                            color     = 'error'
                            className = 'w-full px-16 text-center'
                            variant   = 'subtitle2'
                        >
                            { locale.labels.emptyWorkshop }
                        </Typography>
                    );
                }

            }
            return (
            <Paper elevation={2} className="p-20 mb-16 flex items-center justify-center bg-transparent">
                { content }
            </Paper>);
        }
        return null ;
    };

    render = () =>
    {
        const {
            classes,
            className,
            style,
            thing
        } = this.props ;

        if( thing )
        {
            const { review } = thing ;
            if( review instanceof Suspicion )
            {
                const {
                    about,
                    subject
                }
                = review  ;

                let hasSectors = false ;

                if( about instanceof Workshop && subject instanceof Workplace )
                {
                    const { workplaces } = about ;
                    if( workplaces instanceof Array && workplaces.length > 0 )
                    {
                        const workplace = workplaces.find( item => item.id === subject.id ) ;
                        if( workplace instanceof Workplace )
                        {
                            const { sectors } = workplace ;
                            if( sectors instanceof Array && sectors.length > 0 )
                            {
                                hasSectors = true ;
                            }
                        }
                    }
                }

                let { editUri } = this.props ;

                if( editUri instanceof Function )
                {
                    editUri = editUri( null , this.props ) ;
                }

                return (
                <div
                    className = { clsx(classes.root, className) }
                    style     = { style }
                >
                    { this.getWorkplace( review ) }

                    { hasSectors &&
                    <SectorHealthEvents
                        addUri               = { editUri }
                        containerProps       = {{ className : "w-full p-16 mb-16" }}
                        editUri              = { editUri }
                        onChange             = { this.change }
                        cardBottomPaperProps = {{ className : "flex items-center justify-center w-full p-16" , elevation:0 }}
                        path                 = { editUri }
                        removeUri            = { editUri }
                        subjectOf            = { thing }
                        thing                = { review }
                    />}

                    <Hypothesis
                        containerProps = {{ className : "w-full p-16 mb-16" }}
                        editUri        = { editUri }
                        onChange       = { this.change }
                        subjectOf      = { thing  }
                        thing          = { review }
                    />

                    <Analysis
                        containerProps = {{ className : "w-full p-16 mb-16" }}
                        editUri        = { editUri }
                        onChange       = { this.change }
                        subjectOf      = { thing  }
                        thing          = { review }
                    />

                </div>) ;

            }
        }

        return null ;
    }
}

SuspicionReview.defaultProps =
{
    ...ThingContainer.defaultProps,
    addButtonProps : null ,
    addIcon        : <AddIcon className='mr-16 self-center'/>,
    editUri        : ( path, props ) =>
    {
        const { thing } = props ;
        if( thing )
        {
            const { id } = thing ;
            if( id )
            {
                return api.observations.url + '/' + id ;
            }
        }
        return null ;
    }
};

SuspicionReview.propTypes =
{
    ...ThingContainer.propTypes,
    addButtonProps : PropTypes.object ,
    addIcon        : PropTypes.element
};

export default compose( withStyles( styles ) , withRouter , initApp )( SuspicionReview ) ;
