import articles          from './articles'
import conceptualObjects from './conceptualObjects'
import courses           from './courses'
import events            from './events'
import organizations     from './organizations'
import people            from './people'
import places            from './places'
import stages            from './stages'

export default
{
    articles,
    conceptualObjects,
    courses,
    events,
    organizations,
    people,
    places,
    stages
};
