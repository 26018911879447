import media from './media'

const logo =
{
    ...media,
    remove :
    {
        agree       : 'Supprimer' ,
        description : 'Souhaitez-vous supprimer ce logo ?' ,
        disagree    : 'Annuler' ,
        loading     : 'Suppression en cours...' ,
        title       : 'Supprimer le logo ?',
        useID       : false
    },
    snacks   :
    {
        failed  : 'Impossible d\'ajouter un logo',
        removed : 'Logo supprimé',
        success : 'Logo ajouté avec succès',
    },
    title : 'Logo'
};

export default logo ;