import React  from 'react'

import clsx from 'clsx'

import format from 'vegas-js-core/src/strings/fastformat'

import { withRouter } from 'react-router-dom'

import TransitionGroup from '../../../transitions/TransitionGroup'

import Elevation                 from '../../../display/things/course/Elevation'
import Metadatas                 from '../../../display/things/Metadatas'
import OpeningHoursSpecification from '../../../display/things/OpeningHoursSpecification'
import RemoveCourseDialog        from '../../../display/dialogs/remove/RemoveCourseDialog'
import Transportations           from '../../../display/things/course/Transportations'
import ThingSubContainer         from '../../../display/containers/ThingSubContainer'

class Info extends ThingSubContainer
{
    render = () =>
    {
        const { thing } = this.props ;

        if( thing )
        {
            const { id } = thing ;

            const {
                className,
                onRemove,
                path,
                style
            } = this.props ;

            return (
                <div
                    className = { clsx( 'flex-1 p-12' , className ) }
                    style     = { style }
                >
                    <TransitionGroup duration={250} enter={{animation: "transition.slideUpIn"}}>

                        <div className="flex-1 flex flex-col space-y-24">

                            <Metadatas
                                activable             = { true }
                                deletable             = { true }
                                onRemove              = { onRemove }
                                RemoveDialogComponent = { RemoveCourseDialog }
                                thing                 = { thing }
                                uri                   = { format(path.course,id) }
                            />

                            <Elevation
                                editUri  = { format(path.course,id) }
                                onChange = { this.change }
                                thing    = { thing }
                            />

                            <OpeningHoursSpecification
                                onChange = { this.change }
                                thing    = { thing }
                                uri      = { path.openingHoursSpecification }
                            />

                            <Transportations
                                onChange = { this.change }
                                thing    = { thing }
                                uri      = { path.transportations }
                            />

                        </div>

                    </TransitionGroup>
                </div>
            );
        }

        return null;
    }
}

Info.defaultProps =
{
    ...ThingSubContainer.defaultProps
};

Info.propTypes =
{
    ...ThingSubContainer.propTypes
};

export default withRouter( Info ) ;
