import React from "react"

import GridIcon from '@material-ui/icons/Apps'
import ListIcon from '@material-ui/icons/List'
import MapIcon  from '@material-ui/icons/Map'

import Place      from '../place/Place'
import PlacesMap  from '../../display/maps/PlacesMap'
import PlacesGrid from '../../display/grids/PlacesGrid'
import PlacesList from '../../display/lists/PlacesList'
import TabsView   from '../../display/views/TabsView'

import initView from '../../display/views/initView'

import api from '../../configs/api'

const styles = () => ({}) ;

const path = api.places ;

const { url:uri } = path ;

class Places extends TabsView
{
    getLocale = () => this.props.locale.views.places ;
}

Places.defaultProps =
{
    ...TabsView.defaultProps,
    first  : 'grid' ,
    path,
    routes :
    [
        { id : 'grid'  , Component : PlacesGrid , uri , backPath:'/grid' , exact:true } ,
        { id : 'list'  , Component : PlacesList , uri , backPath:'/list' , exact:true } ,
        { id : 'map'   , Component : PlacesMap  , uri , backPath:'/map'  , exact:true , className:'p-0' } ,
        { id : 'place' , component : Place      , path : uri + '/:id' }
    ],
    tabs :
    [
        { id : 'grid' , icon : <GridIcon /> } ,
        { id : 'list' , icon : <ListIcon /> } ,
        { id : 'map'  , icon : <MapIcon  /> }
    ]
};

Places.propTypes =
{
    ...TabsView.propTypes
};

export default initView( Places , styles );
