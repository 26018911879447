import initDialog from '../../../../../dialogs/initDialog'

import { AddDialog } from '../../../../../dialogs/AddDialog'

import prepareAbortionEvent from './prepare/prepareAbortionEvent'

const styles = () => ({});

export class AddAbortionEventDialog extends AddDialog
{
    getLocale = () => this.props.locale.things.livestock.reviews.abortionSerie.dialogs.abortionEvent.add ;
}

AddAbortionEventDialog.defaultProps =
{
    ...AddDialog.defaultProps,
    member  : 'aborted',
    prepare : prepareAbortionEvent
};

AddAbortionEventDialog.propTypes =
{
    ...AddDialog.propTypes
};

export default initDialog({ styles })( AddAbortionEventDialog ) ;
