import React , { Fragment } from 'react'

import isString from 'vegas-js-core/src/isString'

import PropTypes from 'prop-types'

import moment from 'moment/moment'

import clean        from 'vegas-js-core/src/objects/clean'
import compose      from 'vegas-js-core/src/functors/compose'
import format       from 'vegas-js-core/src/strings/fastformat'
import generateUUID from 'vegas-js-core/src/random/generateUUID'
import leading      from 'vegas-js-core/src/numbers/leading'
import ucFirst      from 'vegas-js-core/src/strings/ucFirst'

import initApp from '../../../../../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import {
    Avatar,
    Button,
    Fab,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Tooltip,
    Typography,
} from '@material-ui/core'

import grey from '@material-ui/core/colors/blueGrey'
import red  from '@material-ui/core/colors/red'

import AddIcon from '@material-ui/icons/AddBox'

import { FaCalendarAlt   } from 'react-icons/fa'
import { FaCalendarCheck } from 'react-icons/fa'
import { FaVectorSquare }  from 'react-icons/fa'

import AgeRanges from '../medical/AgeRanges'
import Symptoms  from '../medical/Symptoms'

import SectorHealthEvent from '../../../events/SectorHealthEvent'
import ThingCardChildren , { styles } from '../../../../ThingCardChildren'

import api from '../../../../../../configs/api'

import generify from '../../../../../../things/generify'

import SectorEvent     from '../../../../../../things/livestock/events/SectorHealthEvent'
import Sector          from '../../../../../../things/Sector'
import SuspicionReview from '../../../../../../things/livestock/reviews/SuspicionReview'
import Thing           from '../../../../../../things/Thing'

class SectorHealthEvents extends ThingCardChildren
{
    createChild = ( child , editable = false ) =>
    {
        if( child instanceof SectorEvent )
        {
            const { lang, path } = this.props ;

            let {
                about:sector,
                subjectOf
            } = child ;

            let agesUri = '' ;
            let symptomsUri = '' ;

            let icon ;
            let title ;
            let type ;

            if( sector instanceof Sector )
            {
                const { additionalType, name } = sector ;
                if( additionalType )
                {
                    const { id } = additionalType ;
                    if( id )
                    {
                        agesUri     = format(api.settings.ageGroupsBySectorsTypes , id);
                        symptomsUri = format(api.settings.symptomsBySectorsTypes  , id);
                    }
                    type = additionalType.getLocaleName(lang);
                    if( type )
                    {
                        type = (
                        <Typography className="font-medium w-full" variant='body1'>
                            { ucFirst(type) }
                        </Typography>);
                    }
                    else
                    {
                        type = null ;
                    }
                }

                if( name )
                {
                    title = sector.getLocaleName(lang);
                    if( title )
                    {
                        title = (
                        <Typography className="mt-4 w-full" variant='body2'>
                            { ucFirst(title) }
                        </Typography>);
                    }
                    else
                    {
                        title = null ;
                    }
                }
            }

            if( type || title )
            {
               icon = (
               <div className='mr-16'>
                    <Avatar variant="rounded" style={{ backgroundColor:grey[300] }}>
                        <FaVectorSquare size={20} />
                    </Avatar>
                </div>
               );
            }

            return (
            <Fragment>

                { icon &&
                    <div className='px-8 py-16 w-full'>
                        <div className='flex flex-row w-full items-center'>
                            { icon }
                            <div className='flex flex-col w-full'>
                                { type }
                                { title }
                            </div>
                        </div>
                        { this.getInfo( sector ) }
                    </div>
                }

                <SectorHealthEvent
                    editable  = { editable }
                    thing     = { child }
                    onClick   = { child => this.openEditDialog(child) }
                />

                <AgeRanges
                    containerProps = {{ className : "bg-transparent w-full shadow-none py-12 mb-8" }}
                    editSearchUri  = { agesUri }
                    editUri        = { path }
                    onChange       = { this.change }
                    subjectOf      = { subjectOf }
                    thing          = { child }
                />
                <Symptoms
                    containerProps = {{ className : "bg-transparent w-full shadow-none py-12 mb-8" }}
                    editSearchUri  = { symptomsUri }
                    editUri        = { path }
                    onChange       = { this.change }
                    subjectOf      = { subjectOf }
                    thing          = { child }
                />
            </Fragment>);
        }
        return null ;
    };

    getAddButton = ( disabled = false ) =>
    {
        if( this.isEditable() )
        {
            const {
                addIcon,
                addButtonProps
            } = this.props ;
            const locale = this.getLocale() ;
            let button = (
            <Button
                disabled = { disabled }
                onClick  = { () => this.openAddDialog() }
                color    = 'primary'
                variant  = 'contained'
                { ...addButtonProps }
            >
                { addIcon }
                { locale.labels.add }
            </Button>);

            const { tooltips } = locale ;
            if( tooltips )
            {
                const { add } = tooltips ;
                if( isString( add ) )
                {
                    button = (
                    <Tooltip
                        title     = { add }
                        placement = 'top'
                    >
                        { button }
                    </Tooltip>
                    )
                }
            }

            return button ;
        }
        return null ;
    };

    getBottom = this.getCustomCardWithAddButton ;

    getCardLabel = ( child, editable, index ) =>
    {
        const locale = this.getLocale() ;
        if( locale )
        {
            const { labels } = locale ;
            if( labels )
            {
                let { card:label } = labels ;
                if( index >= 0 )
                {
                    label = leading(++index) + ' - ' + label ;
                }
                return label ;
            }
        }
        return null ;
    };

    findSector = item =>
    {
        const { subjectOf:observation } = this.props ;
        if( item && observation )
        {
            const { id } = item ;
            const { subject:workshop } = observation ;
            if( id && workshop )
            {
                const { workplaces } = workshop ;
                if( workplaces instanceof Array && workplaces.length > 0 )
                {
                    let len1 = workplaces.length ;
                    for( let i=0 ; i<len1 ; i++ )
                    {
                        let workplace = workplaces[i] ;
                        if( workplace )
                        {
                            const { sectors } = workplace ;
                            if( sectors instanceof Array && sectors.length > 0 )
                            {
                                let len2 = sectors.length ;
                                for ( let j=0 ; j<len2 ; j++ )
                                {
                                    const sector = sectors[j];
                                    if( sector && sector.id === id )
                                    {
                                        return sector ;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        return item ;
    };

    getInfo = sector =>
    {
        sector = this.findSector( sector ) ;

        if( sector instanceof Sector )
        {
            let { capacity , numAttendee } = sector ;
            if( ( capacity >= 0 ) || ( numAttendee >= 0 )  )
            {
                const locale = this.getLocale() ;

                const { rows } = locale ;

                let color = 'bg-blue text-white' ;
                if( numAttendee > capacity )
                {
                    color = 'bg-red text-white' ;
                }

                return (
                <div className='w-full p-16'>
                    <Table>
                        <TableBody>
                            { this.getNumericRow( numAttendee , rows.numAttendee , color )  }
                            { this.getNumericRow( capacity    , rows.capacity    , 'bg-green-700 text-white')  }
                        </TableBody>
                    </Table>
                </div>);
            }
        }
        return null ;
    };

    getLocale = () => this.props.locale.things.livestock.reviews.suspicion.events.sectorHealthEvents ;

    getNumericRow = ( value, label, color = 'bg-blue-500 text-white' ) =>
    {
        value = parseInt(value);
        if( isNaN(value) || value < 0 )
        {
            return null ;
        }

        color = (value >= 0) ? color : 'bg-grey-400' ;

        const css = `font-medium px-8 rounded-lg ${color}` ;

        value = <span className={ css }>{value}</span>;
        return (
        <TableRow className='py-16'>
            <TableCell className='px-4 font-medium'>{ label }</TableCell>
            <TableCell align='right'>{ value }</TableCell>
        </TableRow>
        )
    };

    onAdd = thing => { this.change(thing); }

    onEdit = thing => { this.change(thing); }

    onCardClick = item => () => this.openEditDialog( item ) ;

    onRemove = thing => { this.change(thing); }

    prepare = item =>
    {
        let {
            id = null ,
            about,
            ageRange,
            morbidity,
            mortality,
            endDate,
            startDate,
            symptoms,
            subjectOf:review
        }
        = item ;

        if( review instanceof SuspicionReview )
        {
            review = review.toObject(false);

            let { events } = review ;

            let position = -1 ;

            if( events instanceof Array )
            {
                position = events.findIndex( element => element.id === id ) ;
            }

            if( about instanceof Sector )
            {
                let { id:key , additionalType, name, url } = about ;

                if( additionalType )
                {
                    additionalType = generify(additionalType,true);
                }

                about = { id:key , additionalType, name, url } ;

                if( position < 0 )
                {
                    id = key + '-' + generateUUID() ; // Create a unique ID : [sectorID-uuid]
                }
                else
                {
                    let old = id.split('-')[0];
                    if( old !== String(key) )
                    {
                        //console.log( this + " prepare, replace the sector with an other" ) ;
                        id = key + '-' + generateUUID() ;
                        ageRange = null ;
                        symptoms = null ;
                    }
                }
            }
            else
            {
                id       = generateUUID() ;
                about    = null ;
                ageRange = null ;
                symptoms = null ;
            }

            if( ageRange instanceof Thing )
            {
                ageRange = generify(ageRange,true) ;
            }

            if( morbidity instanceof Thing )
            {
                morbidity = clean(morbidity.toObject()) ;
            }

            if( mortality instanceof Thing )
            {
                mortality = clean(mortality.toObject()) ;
            }

            if( symptoms instanceof Array )
            {
                symptoms = generify(symptoms,true);
            }

            const event =
            {
                id ,
                about,
                ageRange,
                morbidity,
                mortality,
                endDate   : endDate instanceof Date   ? moment(endDate).format( 'YYYY-MM-DD' )   : null ,
                startDate : startDate instanceof Date ? moment(startDate).format( 'YYYY-MM-DD' ) : null ,
                symptoms
            };

            if( (events instanceof Array) && ( events.length > 0) )
            {
                if( position > -1 )
                {
                    events[position] = event ;
                }
                else
                {
                    events.push( event ) ;
                }
            }
            else
            {
                events = [ event ] ;
            }

            review.events = events ;

            return { review : JSON.stringify(review) } ;

        }
        return null ;
    };

    removePrepare = item =>
    {
        if( item instanceof SectorEvent )
        {
            let { subjectOf:review } = item ;
            if( review instanceof SuspicionReview )
            {
                review = review.toObject(false);
                let { id } = item ;
                let { events } = review ;
                if( events instanceof Array && events.length > 0 )
                {
                    events = events.filter( element => element.id !== id ) ;
                    review.events = events ;
                }
                return { review : JSON.stringify(review) } ;
            }
        }
        return null ;
    };
}

SectorHealthEvents.defaultProps =
{
    ...ThingCardChildren.defaultProps,
    addIcon               : <AddIcon className='mr-8' />,
    cardIcon              : <FaCalendarCheck size={20}/> ,
    clazz                 : SectorEvent,
    DeleteButtonComponent : Fab,
    DeleteButtonProps     :
    {
        size  : 'small',
        style :
        {
            backgroundColor : 'white',
            color           : red[600],
            '&:hover'       :
            {
                backgroundColor : red[900],
                color           : 'white'
            }
        }
    },
    icon       : <FaCalendarAlt size={20}/>,
    member     : 'events',
    optionMode : 'none' ,
    path       : null
};

SectorHealthEvents.propTypes =
{
    ...ThingCardChildren.propTypes,
    path : PropTypes.string
};

export default compose( withStyles( styles ) , withRouter , initApp )( SectorHealthEvents )  ;
