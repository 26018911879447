import ucFirst from 'vegas-js-core/src/strings/ucFirst'

import Form from '../../../display/forms/FormElements'

import Livestock from '../../../things/Livestock'
import Thing     from '../../../things/Thing'

import api from '../../../configs/api'

const elements =
[
    {
        type       : Form.SEARCH_SELECTOR ,
        path       : api.livestocks.url ,
        clazz      : Livestock ,
        clearLabel : 'Vider' ,
        closeLabel : 'Fermer' ,
        init       :
        {
            id         : 'value',
            key        : 'value',
            label      : 'Elevage',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            required   : true,
            type       : 'search',
            variant    : 'outlined'
        },
        inputProps :
        {
            label : 'Élevage...'
        },
        logics :
        {
            disabledSuggestions : ( props ) =>
            {
                const { rel } = props ;
                let suggestions = [] ;
                if( rel instanceof Thing )
                {
                    const { subjectOf } = rel ;
                    if( subjectOf instanceof Thing )
                    {
                        if( subjectOf instanceof Livestock )
                        {
                            suggestions.push( subjectOf ) ;
                        }
                        const { livestocks } = subjectOf ;
                        if( livestocks instanceof Array )
                        {
                            return livestocks ;
                        }
                    }
                }
                return suggestions ;
            }
        },
        suggestionRef : ( item ) =>
        {
            if( item instanceof Livestock )
            {
                const { organization } = item ;
                if( organization instanceof Thing )
                {
                    return organization;
                }
            }
            return item ;
        },
        valueLabel : ( item , props ) =>
        {
            if( item instanceof Livestock )
            {
                const { organization } = item ;
                if( organization instanceof Thing )
                {
                    return ucFirst(organization.getLocaleName(props.lang)) ;
                }
            }
            return item ;
        }
    }
];

const livestocks =
{
    add :
    {
        elements,
        agree       : 'Ajouter' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez sélectionner un élevage en cliquant ci-dessous.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en  cours...' ,
        success     : 'Ajout du nouvel élevage réussi!' ,
        title       : 'Ajouter un élevage',
    },
    remove :
    {
        agree       : 'Supprimer' ,
        description : 'Souhaitez-vous supprimer cet élevage:' ,
        disagree    : 'Annuler' ,
        item        :
        {
            text :
            {
                primary : ( element , props ) =>
                {
                    if( element instanceof Livestock )
                    {
                        const { lang } = props ;
                        const { organization } = element ;
                        if( organization instanceof Thing )
                        {
                            return organization.getLocaleName(lang);
                        }
                    }
                    return 'Inconnu';
                }
            }
        },
        loading     : 'Suppression en cours...' ,
        title       : 'Supprimer l\'élevage ?'
    },
    title : 'Élevages' ,
    tooltips :
    {
        add    : "Ajouter" ,
        edit   : "Éditer" ,
        remove : "Supprimer"
    }
};

export default livestocks ;
