import React from 'react'

import CategoryIcon from '@material-ui/icons/Category'

import withDatasGrid from './withDatasGrid'

import { ThingsGrid } from './ThingsGrid'

import AddConceptualObjectDialog    from '../dialogs/add/AddConceptualObjectDialog'
import ConceptualObject             from '../../things/ConceptualObject'
import ConceptualObjectCell         from '../cells/ConceptualObjectCell'
import RemoveConceptualObjectDialog from '../dialogs/remove/RemoveConceptualObjectDialog'

class ConceptualObjectsGrid extends ThingsGrid
{
    getLocale = () => this.props.locale.display.grids.conceptualObjects ;

    getThingRef = () => this.props.thing ;
}

ConceptualObjectsGrid.defaultProps =
{
    ...ThingsGrid.defaultProps,
    AddDialogComponent    : AddConceptualObjectDialog,
    CellComponent         : ConceptualObjectCell ,
    clazz                 : ConceptualObject,
    icon                  : <CategoryIcon />,
    mediaIcon             : <CategoryIcon fontSize='large' />,
    mediaProps            : { className : 'bg-hero-signal-2' },
    RemoveDialogComponent : RemoveConceptualObjectDialog
};

ConceptualObjectsGrid.propTypes =
{
    ...ThingsGrid.propTypes
};

export default withDatasGrid()( ConceptualObjectsGrid ) ;
