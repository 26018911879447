import components from './components'
import contexts   from './contexts'
import dialogs    from "./dialogs"
import display    from "./display"
import languages  from './languages'
import navigation from './navigation'
import print      from './print'
import things     from './things'
import toolbar    from './display/bars/toolbar'
import views      from './views'

const fr =
{
    // ---- dependencies

    components,
    contexts,
    dialogs,
    display,
    languages,
    navigation,
    print,
    things,
    toolbar,
    views,

    // ---- setting

    locale : 'fr' ,
    title  : process.env.REACT_APP_SHORT_TITLE
};

export default fr;
