import React, { Fragment } from 'react'

import clsx from 'clsx'

import PropTypes from 'prop-types'

import compose from 'vegas-js-core/src/functors/compose'

import CollapseItem from './CollapseItem'
import Item         from './Item'

import { withRouter} from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'

import { ListSubheader } from '@material-ui/core'

import AbstractItem from './AbstractItem'
import ItemLink     from './ItemLink'

const styles = () => (
{
    item:
    {
        height       : 40,
        width        : 'calc(100% - 16px)',
        borderRadius : '0 20px 20px 0',
        paddingRight : 12 ,
        //marginBottom : 4 ,
        '&.active > .list-subheader-text': { fontWeight: 700 }
    }
});

class GroupItem extends AbstractItem
{
    getChild = item =>
    {
        if( item )
        {
            const {
                nestedLevel
            }
            = this.props ;

            const {
                id,
                type
            }
            = item ;

            switch( type )
            {
                case 'collapse' :
                {
                    return <CollapseItem
                                key         = { id }
                                item        = { item }
                                nestedLevel = { nestedLevel }
                           />;
                }

                case 'group' :
                {
                    return <GroupItem
                                key         = { id }
                                item        = { item }
                                nestedLevel = { nestedLevel }
                           />;
                }

                case 'item' :
                {
                    return <Item
                                key         = { id }
                                item        = { item }
                                nestedLevel = { nestedLevel }
                           />;
                }

                default :
                {
                    return null ;
                }
            }

        }
        return null ;
    };

    render()
    {
        const { item } = this.props ;

        if( !item )
        {
            return null ;
        }

        const { classes } = this.props ;

        let { children, title, url } = item ;

        return (
            <Fragment>
                <ListSubheader
                    component     = { url ? ItemLink : 'li'}
                    disableSticky = { true }
                    className     = { clsx(classes.item, "list-subheader flex items-center") }
                    style         = {{ paddingLeft : this.getPaddingLeft() }}
                    role          = "button"
                    to            = {url}
                >
                    <span className="list-subheader-text uppercase text-2xl">{ title }</span>
                </ListSubheader>
                <Fragment>
                    {  children instanceof Array && ( children.length > 0 ) &&  children.map( this.getChild ) }
                </Fragment>
            </Fragment>
        );
    }
}

GroupItem.defaultProps = {} ;

GroupItem.propTypes =
{
    item : PropTypes.shape(
    {
        children : PropTypes.array,
        id       : PropTypes.string.isRequired,
        title    : PropTypes.string
    })
};

export default compose(
    withStyles( styles , { withTheme : true } ) ,
    withRouter
)( GroupItem ) ;
