import React from 'react'

import format from 'vegas-js-core/src/strings/fastformat'

import initApp from '../../../../contexts/app/initApp'

import Courses from './ambulo/Courses'
import Events  from './ambulo/Events'
import Game    from './ambulo/Game'
import Info    from './ambulo/Info'

import ThingSubContainer from '../../../containers/ThingSubContainer'

class AmbuloSetting extends ThingSubContainer
{
    changeSetting = this.changeProperty( 'setting' , true ) ;

    render = () =>
    {
        const {
            path,
            thing
        } = this.props ;
        if( thing )
        {
            const { id, setting } = thing ;

            const uri = format(path.setting,id) ;

            return (
                <div className='flex flex-col flex-1 p-12 space-y-16'>

                    <Info
                        editUri   = { uri }
                        onChange  = { this.changeSetting }
                        thing     = { setting }
                    />

                    <Courses
                        onChange  = { this.changeSetting }
                        onOrder   = { this.changeSetting }
                        thing     = { setting }
                        uri       = { uri }
                    />

                    <Events
                        onChange  = { this.changeSetting }
                        onOrder   = { this.changeSetting }
                        thing     = { setting }
                        uri       = { uri }
                    />

                    <Game
                        editUri   = { uri }
                        onChange  = { this.changeSetting }
                        thing     = { setting }
                    />

                </div>
            );
        }

        return null;
    }
}

AmbuloSetting.defaultProps =
{
    ...ThingSubContainer.defaultProps
};

AmbuloSetting.propTypes =
{
    ...ThingSubContainer.propTypes
};

export default initApp(AmbuloSetting) ;
