import React from 'react'

import compose  from 'vegas-js-core/src/functors/compose'
import isString from 'vegas-js-core/src/isString'
import leading  from 'vegas-js-core/src/numbers/leading'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import initApp from '../../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { Button, Tooltip } from '@material-ui/core'

import AddIcon from '@material-ui/icons/AddCircle'

import { GiCardRandom } from 'react-icons/gi'

import ThingCardChildren, { styles } from '../ThingCardChildren'

import DialogError  from '../../../errors/DialogError'
import LabelItem    from "../../../components/items/LabelItem"
import QuestionView from './Question'

import Question     from '../../../things/Question'
import QuestionGame from '../../../things/game/QuestionGame'
import Word         from '../../../things/Word'

import generify from '../../../things/generify'

class Questions extends ThingCardChildren
{
    createChild = ( child /*, editable = false*/ ) =>
    {
        if( child )
        {
            const { uri } = this.props ;
            return (
            <div className='w-full flex flex-col justify-start py-12'>
                <QuestionView
                    className  = 'w-full'
                    itemPolicy = {{ description:false , multiple:false }}
                    onChange   = { this.change }
                    uri        = { uri }
                    thing      = { child }
                />
            </div> );
        }
        return null ;
    };

    getAddButton = ( disabled = false ) =>
    {
        if( this.isEditable() )
        {
            const {
                addIcon,
                addButtonProps
            } = this.props ;

            const locale = this.getLocale() ;

            let button = (
                <Button
                    disabled = { disabled }
                    onClick  = { () => this.openAddDialog() }
                    color    = 'primary'
                    variant  = 'contained'
                    { ...addButtonProps }
                >
                    { addIcon }
                    { locale.labels.add }
                </Button>
            );

            const { tooltips } = locale ;
            if( tooltips && !disabled )
            {
                const { add } = tooltips ;
                if( isString( add ) )
                {
                    button = (
                        <Tooltip
                            title     = { add }
                            placement = 'top'
                        >
                            { button }
                        </Tooltip>
                    ) ;
                }
            }

            return button ;
        }
        return null ;
    };

    getBottom = () => this.getEmpty() ;

    getCardIcon = child => this.getAvatar( child , { marginRight : 8 } ) ;

    getCardLabel = ( child , editable , index ) =>
    {
        // console.log( this + ' getCardLabel ' , child ) ;
        const unknown = this.getLocale().unknown ;

        if( child instanceof Question )
        {
            const { lang } = this.props ;

            let label = child.getLocaleAlternateName(lang) ;

            if( !isString(label) || label === '' )
            {
                label = unknown ;
            }

            if( isString(label) )
            {
                return leading(index+1) + ' - ' + ucFirst(label) ;
            }
        }

        return this.props.cardLabel || unknown ;
    };

    getCardSubLabel = child =>
    {
        if( child )
        {
            let { lang } = this.props;
            let description = child.getLocaleDescription(lang);
            if( isString(description) && (description !== '') )
            {
                return (
                    <LabelItem>
                        {  ucFirst(description) }
                    </LabelItem>
                );
            }
        }
        return null ;
    };

    getEmpty = () => (
        <div className='flex flex-1 py-12 items-center justify-center'>
            { this.getAddButton(this.isLocked()) }
        </div>
    );

    getLocale = () => this.props.locale.things.game.questions ;

    // ---- change the default behavior with the PATCH mode

    onAdd    = this.change ;
    onEdit   = this.change ;
    onRemove = this.change ;

    // --------

    onCardClick = child => () => this.openEditDialog( child ) ;

    prepare = item =>
    {
        let {
            id = null ,
            alternateName,
            additionalType,
            description,
            choices,
            fail,
            image,
            multiple,
            score,
            subjectOf:game,
            success,
            tip
        } = item ;

        if( !(additionalType instanceof Word) )
        {
            throw new DialogError({ additionalType:'question type is required' })
        }
        else
        {
            additionalType = generify(additionalType,true);
        }

        if( image )
        {
            image = generify(image,true) ;
        }

        const { config : { languages } = {} } = this.props ;

        if( alternateName )
        {
            languages.forEach( lang =>
            {
                if( !isString(alternateName[lang]) )
                {
                    alternateName[lang] = '' ;
                }
            });
        }

        if( description )
        {
            languages.forEach( lang =>
            {
                if( !isString(description[lang]) )
                {
                    description[lang] = '' ;
                }
            });
        }

        if( fail )
        {
            languages.forEach( lang =>
            {
                if( !isString(fail[lang]) )
                {
                    fail[lang] = '' ;
                }
            });
        }

        if( success )
        {
            languages.forEach( lang =>
            {
                if( !isString(success[lang]) )
                {
                    success[lang] = '' ;
                }
            });
        }

        if( tip )
        {
            languages.forEach( lang =>
            {
                if( !isString(tip[lang]) )
                {
                    tip[lang] = '' ;
                }
            });
        }

        if( isString(score) )
        {
            score = parseInt(score);
        }

        if( isNaN(score) || (score < 0) )
        {
            score = 0 ;
        }

        if( choices )
        {
            choices = generify(choices,true);
        }

        if( game instanceof QuestionGame )
        {
            game = game.toObject();

            let { quest } = game ;

            let position = -1 ;

            if( quest instanceof Array )
            {
                position = quest.findIndex( element => element.id === id ) ;
            }
            else
            {
                quest = [] ;
            }

            const question =
            {
                id ,
                additionalType,
                alternateName,
                choices,
                description,
                fail,
                image,
                multiple,
                score,
                success,
                tip
            };

            if( position > -1 )
            {
                quest[position] = question ;
            }
            else
            {
                quest = [ ...quest , question ] ;
            }

            // console.log( 'prepare >> ' , quest ) ;

            quest = JSON.stringify(quest) ;

            return { quest };
        }

        return null ;

    };

    removePrepare = item =>
    {
        let { member, thing:game } = this.props ;
        if( (game instanceof QuestionGame) && (item instanceof Question) )
        {
            const { id } = item ;

            game = game.toObject() ;

            let quest = null ;

            if( game.hasOwnProperty(member) && (game[member] instanceof Array) )
            {
                quest = game[member].filter( element => element.id !== id ) ;
                quest = JSON.stringify(quest) ;
            }

            return { quest };
        }
        return null ;
    };
}

Questions.defaultProps =
{
    ...ThingCardChildren.defaultProps ,
    addIcon    : <AddIcon className='mr-16 self-center'/>,
    cardBottomPaperProps :
    {
        className : "bg-gray-200 w-full p-12 mb-8 flex items-center justify-center",
        elevation : 1
    },
    cardPaperProps :
    {
        className : "bg-gray-200 w-full p-12 mb-8",
        elevation : 1
    },
    clazz      : Question,
    badge      : true ,
    icon       : <GiCardRandom size='2.5em'/>,
    itemGrid   :
    {
        xl : 12 ,
        lg : 12 ,
        md : 12 ,
        sm : 12 ,
        xs : 12
    },
    member     : 'quest',
    optionMode : 'none' ,
    useUUID    : true
};

Questions.propTypes =
{
    ...ThingCardChildren.propTypes
};

export default compose( withStyles( styles ) , withRouter , initApp )( Questions ) ;
