import React from 'react'

import clsx from 'clsx'

import format from 'vegas-js-core/src/strings/fastformat'

import Address           from '../../../display/things/Address'
import CivilStatus       from '../../../display/things/person/CivilStatus'
import Email             from '../../../display/things/Email'
import Jobs              from '../../../display/things/person/Jobs'
import Legal             from '../../../display/things/person/Legal'
import MemberOf          from '../../../display/things/person/MemberOf'
import Metadatas         from '../../../display/things/Metadatas'
import Telephone         from '../../../display/things/Telephone'
import ThingSubContainer from '../../../display/containers/ThingSubContainer'
import Websites          from '../../../display/things/Websites'
import WorksFor          from '../../../display/things/person/WorksFor'

import RemovePersonDialog from '../../../display/dialogs/remove/RemovePersonDialog'

class Info extends ThingSubContainer
{
    render = () =>
    {
        const { thing } = this.props ;
        if( thing )
        {
            const {
                className,
                path,
                style
            } = this.props ;

            const { address , id } = thing ;
            const { onRemove } = this.props ;

            let view = (
            <div className="flex-1 flex flex-col space-y-24">

                <Metadatas
                    activable             = { true }
                    deletable             = { true }
                    RemoveDialogComponent = { RemovePersonDialog }
                    onRemove              = { onRemove }
                    thing                 = { thing }
                    uri                   = { format(path.person,id) }
                />

                <div className='grid grid-cols-1 md:grid-cols-2 gap-16'>
                    <CivilStatus
                        editUri  = { format(path.person,id) }
                        onChange = { this.change }
                        thing    = { thing }
                    />
                    <Legal
                        editUri  = { format(path.person,id) }
                        onChange = { this.change }
                        thing    = { thing }
                    />
                    <Address
                        editUri  = { format(path.address,id) }
                        onChange = { this.changeProperty('address',true) }
                        thing    = { address }
                        uri      = { path.address }
                    />
                    <Telephone
                        onChange = { this.change  }
                        thing    = { thing }
                        uri      = { path.telephone }
                    />
                    <Email
                        onChange = { this.change }
                        thing    = { thing }
                        uri      = { path.email }
                    />
                    <Websites
                        onChange = { this.change }
                        thing    = { thing }
                        uri      = { path.websites }
                    />
                </div>

                <Jobs
                    onChange = { this.change }
                    thing    = { thing }
                    uri      = { path.job }
                />

                <div className='grid grid-cols-1 md:grid-cols-2 gap-16'>
                    <WorksFor
                        onChange = { this.change }
                        thing    = { thing }
                        uri      = { path.worksFor }
                    />
                    <MemberOf
                        onChange = { this.change }
                        thing    = { thing }
                        uri      = { path.memberOf }
                    />
                </div>
            </div>
            );

            return (
            <div
                className = { clsx( 'flex-1 p-12' , className ) }
                style     = { style }
            >
                { this.renderWithTransition(view) }
            </div>
            );
        }
        return null  ;
    };
}

Info.defaultProps =
{
    ...ThingSubContainer.defaultProps
};

Info.propTypes =
{
    ...ThingSubContainer.propTypes
};

export default Info ;
