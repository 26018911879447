import format from 'vegas-js-core/src/strings/fastformat'

import api from '../../../configs/api'

import Form from '../../../display/forms/FormElements'

import Thing    from '../../../things/Thing'
import Workshop from '../../../things/Workshop'
import Word     from '../../../things/Word'

const elements =
[
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'name',
            key          : 'name',
            label        : 'Nom du secteur',
            autoFocus    : false,
            fullWidth    : true,
            helperText   : 'Obligatoire',
            margin       : 'normal',
            required     : true,
            select       : false,
            autoComplete : 'off' ,
            type         : 'search',
            variant      : 'outlined'
        }
    },
    {
        type       : Form.SEARCH_SELECTOR ,
        clearLabel : 'Vider' ,
        closeLabel : 'Fermer' ,
        init       :
        {
            id         : 'additionalType',
            key        : 'additionalType',
            label      : 'Type de secteur',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            required   : true,
            type       : 'search'
        },
        inputProps :
        {
            label : 'Type du secteur...'
        },
        limit  : 0,
        logics :
        {
            disabledSuggestions : props =>
            {
                const { rel } = props ;
                let suggestions = [] ;
                if( rel instanceof Thing )
                {
                    const { additionalType } = rel ;
                    if( additionalType instanceof Word )
                    {
                        suggestions.push( additionalType ) ;
                    }
                }
                return suggestions ;
            }
        },
        path : props =>
        {
            const { rel } = props ;
            if( rel instanceof Thing )
            {
                const { subjectOf:workshop } = rel ;
                if( workshop instanceof Workshop )
                {
                    const { breeding } = workshop ;
                    if( breeding instanceof Thing)
                    {
                        const { id } = breeding ;
                        if( id )
                        {
                            return format( api.settings.sectorsTypesByBreedingsTypes, id ) ;
                        }
                    }
                }
            }
            return api.thesaurus.sector_types ;
        }
    },
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'numAttendee',
            key          : 'numAttendee',
            label        : 'Nombres d\'animaux',
            autoFocus    : false,
            fullWidth    : true,
            margin       : 'normal',
            select       : false,
            autoComplete : 'off' ,
            type         : 'text',
            variant      : 'outlined'
        }
    },
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'capacity',
            key          : 'capacity',
            label        : 'Capacité maximale',
            autoFocus    : false,
            fullWidth    : true,
            margin       : 'normal',
            select       : false,
            autoComplete : 'off' ,
            type         : 'text',
            variant      : 'outlined'
        }
    }
];

const sectors =
{
    add :
    {
        elements,
        agree       : 'Créer' ,
        disagree    : 'Annuler' ,
        title       : 'Ajouter un secteur' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour créer un secteur.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en  cours...' ,
        mock        : false ,
        success     : 'Ajout du nouveau secteur réussi!'
    },
    buttons :
    {
        add : "Ajouter"
    },
    edit :
    {
        elements,
        agree       : 'Sauver' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour modifier ce secteur.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en  cours...' ,
        mock        : false ,
        success     : 'Mise à jour réussie!' ,
        title       : 'Modifier le secteur'
    },
    rows :
    {
        empty       : '?' ,
        numAttendee : 'Effectif' ,
        capacity    : 'Capacité'
    },
    remove :
    {
        agree       : 'Supprimer' ,
        description : 'Souhaitez-vous supprimer ce secteur:' ,
        disagree    : 'Annuler' ,
        loading     : 'Suppression en cours...' ,
        title       : 'Supprimer le secteur ?'
    },
    title : 'Secteurs' ,
    tooltips :
    {
        add    : "Ajouter un secteur" ,
        edit   : "Modifier" ,
        remove : "Supprimer"
    }
};

export default sectors ;