import table from './table'

const observations =
{
    ...table,
    helmet :
    {
        title : 'Observations' ,
        metas : []
    },
    search : 'Rechercher une observation ...' ,
    title  : 'Observations'
};

export default observations ;
