import React, { Component } from 'react'

import clsx from 'clsx'

import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'

import { IconButton, Typography } from '@material-ui/core'

import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CloseIcon       from '@material-ui/icons/Close'
import ErrorIcon       from '@material-ui/icons/Error'
import InfoIcon        from '@material-ui/icons/Info'
import WarningIcon     from '@material-ui/icons/Warning'

import blueGrey from '@material-ui/core/colors/blueGrey'
import green    from '@material-ui/core/colors/green'
import amber    from '@material-ui/core/colors/amber'

import SnackVariant from './SnackVariant'

export const variants = SnackVariant ;

const Icons =
{
    error   : ErrorIcon,
    info    : InfoIcon,
    success : CheckCircleIcon,
    warning : WarningIcon
};

const styles = theme => (
{
    close :
    {
        margin : '0 0 0 8px'
    },
    closeIcon :
    {
        fontSize : 16
    },
    error :
    {
        backgroundColor: theme.palette.error.dark
    },
    icon :
    {
        fontSize: 20
    },
    iconVariant :
    {
        opacity     : 1,
        marginRight : theme.spacing(1)
    },
    info :
    {
        backgroundColor: theme.palette.primary.dark
    },
    message:
    {
        alignItems    : 'center',
        display       : 'flex',
        flexDirection : 'row',
        flexGrow      : 1
    },
    root :
    {
        backgroundColor : blueGrey[900],
        borderRadius    : 3,
        color           : 'white',
        display         : 'flex',
        flexDirection   : 'row',
        flexGrow        : 1,
        margin          : 0,
        padding         : '4px 0px 4px 8px',
        width           : '100%'
    },
    success :
    {
        backgroundColor: green[600]
    },
    warning :
    {
        backgroundColor: amber[700]
    }
});

class SnackContent extends Component
{
    render()
    {
        let {
            classes,
            className,
            icon,
            message,
            onClose,
            variant,
            ...other
        } = this.props;

        if( icon )
        {
            icon = (
                <div style={{
                    height  : 'auto' ,
                    margin  : '4px 16px 4px 8px',
                    padding : '4px 8px',
                    width   : 20
                }}>
                    {icon}
                </div>);
        }
        else
        {
            const Icon = Icons[variant] || null ;
            if( Icon )
            {
                icon = <Icon className={ clsx(classes.icon, classes.iconVariant) } />;
            }
        }

        className = clsx(
            classes.root ,
            variant !== variants.DEFAULT ? classes[variant] : null ,
            className
        );

        if( message )
        {
            message = (
                <Typography
                    color = 'inherit'
                >
                    {message}
                </Typography>
            );
        }
        else
        {
            message = null ;
        }

        return (
        <div
            className = { className }
            {...other}
        >
            <div className={classes.message}>
                { icon }
                { message }
            </div>
            <div className={ classes.close }>
                <IconButton
                    aria-label = 'Close'
                    color      = 'inherit'
                    key        = 'close'
                    onClick    = { onClose }
                >
                    <CloseIcon
                        className={classes.closeIcon}
                    />
                </IconButton>
            </div>
        </div>);
    }
}

SnackContent.defaultProps =
{
    icon    : null,
    onClose : null,
    variant : 'info'
};

SnackContent.propTypes =
{
    classes   : PropTypes.object.isRequired,
    className : PropTypes.string,
    icon      : PropTypes.element,
    message   : PropTypes.node,
    onClose   : PropTypes.func,
    variant   : PropTypes.oneOf(Object.values(variants))
};

export default withStyles(styles)(SnackContent) ;
