import analyses from './analyses'
import factors  from './factors'
import risk     from './risk'

const medical =
{
    analyses,
    factors,
    risk
};

export default medical ;
