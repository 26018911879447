import React from 'react'

import PropTypes from 'prop-types'

import moment from 'moment'

import isString from 'vegas-js-core/src/isString'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import withi18n from '../../contexts/i18n/withi18n'

import { TimePicker } from '@material-ui/pickers'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { Button, IconButton, Typography } from '@material-ui/core'

import AddIcon    from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'

import Badge, { colors } from '../../components/badges/Badge'
import RoleableContainer from '../../components/containers/RoleableContainer'

import OpeningHoursSpecification from '../../things/OpeningHoursSpecification'

const styles = () => (
{
    root:
    {
        flexGrow     : 1,
        marginBottom : 8 ,
        marginTop    : 20 ,
        width        : '100%' ,
        height       : 'auto'
    },
    underline:
    {
        borderBottom : '2px solid white',
        '&:after':
        {
            borderBottom: '2px solid white',
        }
    }
});

class HoursInput extends RoleableContainer
{
    state =
    {
        close : moment('00:00','HH:mm'),
        open  : moment('00:00','HH:mm')
    };

    /**
     * Indicates the number of time periods register in this hours input.
     * @returns {number} The number of time periods register in this hours input.
     */
    get length()
    {
        const {
            separator,
            value
        } = this.props ;
        if( value instanceof OpeningHoursSpecification )
        {
            let { closes, opens } = value ;

            if( isString(closes) && closes !== '')
            {
                closes = closes.split(separator);
            }
            else
            {
                closes = null ;
            }

            if( isString(opens) && opens !== '')
            {
                opens = opens.split(separator);
            }
            else
            {
                opens = null ;
            }

            if( opens instanceof Array && closes instanceof Array )
            {
                return opens.length ;
            }
        }
        return 0 ;
    }

    getAdd = () =>
    {
        const { disabled } = this.props ;
        let { open , close } = this.state ;
        return(
            <div key={'hour_add'} className="flex w-full my-4">
                <Button
                    className = "flex w-full"
                    color     = "primary"
                    disabled  = { disabled }
                    size      = "small"
                    variant   = "outlined"
                    onClick   = { () =>
                    {
                        if( close === null )
                        {
                            close = moment('00:00','HH:mm');
                        }

                        if( open === null )
                        {
                            open = moment('00:00','HH:mm');
                        }

                        const { value } = this.props ;
                        if( value instanceof OpeningHoursSpecification )
                        {
                            let { closes , opens } = value ;

                            close = close.format('HH:mm');
                            if( !isString(closes) || (closes === '') )
                            {
                                closes = close ;
                            }
                            else
                            {
                                closes = closes.split(',');
                                closes.push( close );
                                closes = closes.join(',');
                            }

                            open = open.format('HH:mm');
                            if( !isString(opens) || (opens === '') )
                            {
                                opens = open ;
                            }
                            else
                            {
                                opens = opens.split(',');
                                opens.push( open );
                                opens = opens.join(',');
                            }

                            if( closes === "" )
                            {
                                closes = null ;
                            }

                            if( opens === "" )
                            {
                                opens = null ;
                            }

                            value.closes = closes ;
                            value.opens  = opens ;

                            this.reset() ;
                        }
                    }}
                >
                    <AddIcon fontSize ="small" className="text-blue-700"/>
                </Button>
            </div>
        );
    };

    getBadge = () =>
    {
        const {
            badge,
            badgeColor,
            badgeNoneColor,
        } = this.props ;
        if( badge )
        {
            const count = this.length ;
            return (
                <div className="ml-12 font-10">
                    <Badge color={Badge.checkColor( count>0 ? badgeColor : badgeNoneColor)}>{count}</Badge>
                </div>
            ) ;
        }
        return null ;
    };

    getHours = () =>
    {
        const {
            disabled,
            separator,
            TimePickerProps,
            value
        } = this.props;

        const locale = this.getLocale() ;

        if( value instanceof OpeningHoursSpecification )
        {
            let { closes, opens } = value ;

            if( isString(closes) && closes !== '')
            {
                closes = closes.split(separator);
            }
            else
            {
                closes = null ;
            }

            if( isString(opens) && opens !== '')
            {
                opens = opens.split(separator);
            }
            else
            {
                opens = null ;
            }

            if( (closes instanceof Array) && (opens instanceof Array) )
            {
                const len1 = opens.length ;
                const len2 = closes.length ;
                if( len1 > 0 && len1 === len2 )
                {
                    return opens.map( ( item , index ) =>
                    {
                        return(
                            <div key={'hour_'+index} className="flex flex-row w-full mb-4">
                                <div className="flex flex-1">
                                    <div className="flex-1 p-8">
                                        <TimePicker
                                            ampm      = { false }
                                            fullWidth = { true }
                                            { ...TimePickerProps }
                                            disabled  = { disabled }
                                            value     = { moment(opens[index],'HH:mm') }
                                            onChange  = { ( moment ) =>
                                            {
                                                opens[index] = moment.format('HH:mm');
                                                value.opens = opens.join(',');
                                                if( this._mounted )
                                                {
                                                    this.forceUpdate() ;
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="flex-1 p-8">
                                        <TimePicker
                                            ampm      = { false }
                                            fullWidth = { true }
                                            { ...TimePickerProps }
                                            disabled  = { disabled }
                                            value     = { moment(closes[index],'HH:mm') }
                                            onChange  = { ( moment ) =>
                                            {
                                                closes[index] = moment.format('HH:mm');
                                                value.closes = closes.join(',');
                                                if( this._mounted )
                                                {
                                                    this.forceUpdate() ;
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                <div aria-label="Delete" className="flex-2 p-8">
                                    <IconButton
                                        disabled = { disabled }
                                        onClick  = { () =>
                                        {
                                            closes.splice(index,1);
                                            opens.splice(index,1);
                                            value.closes = closes.join(',');
                                            value.opens  = opens.join(',');
                                            if( this._mounted )
                                            {
                                                this.forceUpdate() ;
                                            }
                                        }}
                                    >
                                        <DeleteIcon fontSize="small" className="text-red-800"/>
                                    </IconButton>
                                </div>
                            </div>
                        )
                    });
                }
            }
        }
        return(
            <div className="w-full p-12 text-center bg-grey-200 rounded-sm my-8">
                <Typography variant="caption">{ locale.label }</Typography>
            </div>
        );
    };

    getLocale = () => this.props.locale.components.inputs.hours ;

    /**
     * Returns the title component.
     * @param {string} label - The label to display in the component.
     * @returns {*} The title component reference.
     */
    getTitle = ( label = null ) =>
    {
        const locale = this.getLocale() ;

        let text ;

        if( isString( label ) )
        {
            text = label ;
        }
        else if( locale )
        {
            const { title } = locale ;
            if( isString(title) )
            {
                text = title ;
            }
        }

        if( isString(text) )
        {
            return (
                <div className="flex flex-row items-center">
                    <Typography className="text-grey-800" variant="caption">{ucFirst(text)}</Typography>
                    { this.getBadge() }
                </div>
            ) ;
        }
        return null ;
    };

    render = () =>
    {
        const { classes } = this.props ;
        return (
            <div className={classes.root}>
                { this.getTitle() }
                <div className="flex flex-col">
                    { this.getHours() }
                    { this.getAdd() }
                </div>
            </div>
        ) ;
    };

    reset = () =>
    {
        this.setState({
            close : moment('00:00','HH:mm'),
            open  : moment('00:00','HH:mm')
         })
    };
}

HoursInput.defaultProps =
{
    ...RoleableContainer.defaultProps ,
    badge          : true,
    badgeColor     : 'info' ,
    badgeNoneColor : 'gray' ,
    disabled       : false,
    onChange        : null ,
    separator       : ',' ,
    TimePickerProps :
    {
        cancelLabel : 'Cancel' ,
        clearLabel  : 'Close' ,
        okLabel     : 'OK'
    },
    value           : null
};

HoursInput.propTypes =
{
    ...RoleableContainer.propTypes ,
    badge           : PropTypes.bool,
    badgeColor      : PropTypes.oneOf(colors),
    badgeNoneColor  : PropTypes.oneOf(colors),
    classes         : PropTypes.object.isRequired ,
    disabled        : PropTypes.bool,
    lang            : PropTypes.string.isRequired,
    locale          : PropTypes.object.isRequired,
    onChange        : PropTypes.func,
    separator       : PropTypes.string,
    TimePickerProps : PropTypes.object,
    value           : PropTypes.object
};

export default withStyles( styles )( withRouter( withi18n(HoursInput) ) ) ;
