import generify from './generify'

import ConceptualObject          from './ConceptualObject'
import Event                     from './Event'
import GeoCoordinates            from './GeoCoordinates'
import GeoShape                  from './GeoShape'
import Offer                     from './Offer'
import OpeningHoursSpecification from './OpeningHoursSpecification'
import PostalAddress             from './PostalAddress'
import PropertyValue             from './PropertyValue'
import Thing                     from './Thing'
import Website                   from './Website'
import Word                      from './Word'

import populateMedias from './creativework/populateMedias'

/**
 * Entities that have a somewhat fixed, physical extension.
 * @memberOf things
 */
class Place extends Thing
{
    /**
     * Creates a new Place instance.
     * @constructor
     * @param object The generic object to initialize the object the first time.
     */
    constructor( object = null )
    {
        super() ;

        /**
         * The subject matter of the content.
         * @type {Object|string}
         */
        this.about = null ;

        /**
         * The activities enumeration of this place.
         * @type {Array}
         */
        this.activities = null ;

        /**
         * A property-value pair representing an additional characteristics of the entitity, e.g. a product feature or another characteristic for which there is no matching property in schema.org.
         * Can be an Array collection of PropertyValue.
         * @type {Array|PropertyValue}
         */
        this.additionalProperty = null ;

        /**
         * Physical address of the item.
         * @type {PostalAddress|string}
         */
        this.address = null ;

        /**
         * The audios medias of this place.
         * @type {Array}
         */
        this.audios = null;

        /**
         * A short textual code (also called "store code") that uniquely identifies a place of business.
         * @type {string}
         */
        this.branchCode = null ;

        /**
         * The conceptual objects on this place.
         * @type {ConceptualObject|array}
         */
        this.conceptualObjects = null ;

        /**
         * The basic containment relation between a place and one that contains it.
         * @type {Place}
         */
        this.containedInPlace = null ;

        /**
         * The basic containment relation between a place and another that it contains.
         * @type {Array|Place}
         */
        this.containsPlace = null ;

        /**
         * If set, the distance to this place
         * @type {number}
         */
        this.distance = null ;

        /**
         * The email enumeration of this place.
         * @type {PropertyValue|array}
         */
        this.email = null ;

        /**
         * Upcoming or past event associated with this place, organization, or action.
         * @type {Event|Array}
         */
        this.events = null ;

        /**
         * The fax number.
         * @type {string}
         */
        this.faxNumber = null ;

        /**
         * The geo coordinates of the place.
         * @type {GeoCoordinates|GeoShape}
         */
        this.geo = null ;

        /**
         * An associated logo.
         * @type {string|ImageObject}
         */
        this.logo = null ;

        /**
         * The museum types
         * @type {Array|Word}
         */
        this.museumType = null ;

        /**
         * Indicates the number of audios medias of this thing.
         * @type {Array}
         */
        this.numAudios = null;

        /**
         * Indicates the number of conceptual object of this thing.
         * @type {Array}
         */
        this.numConceptualObject = null;

        /**
         * Indicates the number of events of this thing.
         * @type {Array}
         */
        this.numEvents = null;

        /**
         * Indicates the number of videos medias of this thing.
         * @type {Array}
         */
        this.numPhotos = null;

        /**
         * Indicates the number of videos medias of this thing.
         * @type {Array}
         */
        this.numVideos = null;

        /**
         * The location of for example where the event is happening, an organization is located, or where an action takes place.
         * @type {Offer|array}
         */
        this.offers = null ;

        /**
         * The opening hours of a certain place.
         * @type {Array|OpeningHoursSpecification}
         */
        this.openingHoursSpecification = null ;

        /**
         * The permits activities enumeration of this place.
         * @type {Array}
         */
        this.permits = null ;

        /**
         * The photos collection of this place.
         * @type {Array}
         */
        this.photos = null ;

        /**
         * The prohibited activities enumeration of this place.
         * @type {Array}
         */
        this.prohibitions = null ;

        /**
         * A flag to signal that the Place is open to public visitors.
         * @type {boolean}
         */
        this.publicAccess = null ;

        /**
         * The services enumeration of this place.
         * @type {Array}
         */
        this.services = null ;

        /**
         * A slogan or motto associated with the item.
         * @type {Object|string}
         */
        this.slogan = null ;

        /**
         * Use this to explicitly override general opening hours brought in scope by openingHoursSpecification.
         * @type {Array|OpeningHoursSpecification}
         */
        this.specialOpeningHoursSpecification = null ;

        /**
         * The status of the item.
         *  @type {Word}
         */
        this.status = null ;

        /**
         * The telephone enumeration of this place.
         * @type {PropertyValue|array}
         */
        this.telephone = null ;

        /**
         * The videos medias of this place.
         * @type {Array}
         */
        this.videos = null;

        /**
         * The websites enumeration of this place.
         * @type {Website|array}
         */
        this.websites = null ;

        this.set( object ) ;
    }


    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        super.clear() ;
        this.about =
        this.activities =
        this.additionalProperty =
        this.address =
        this.audios =
        this.branchCode =
        this.conceptualObjects  =
        this.containedInPlace =
        this.distance =
        this.email =
        this.events =
        this.faxNumber =
        this.geo =
        this.logo =
        this.museumType =
        this.numAudios =
        this.numConceptualObject =
        this.numEvents =
        this.numPhotos =
        this.numVideos =
        this.offers  =
        this.openingHoursSpecification =
        this.permits =
        this.photos =
        this.prohibitions =
        this.publicAccess =
        this.services =
        this.slogan =
        this.specialOpeningHoursSpecification =
        this.status =
        this.telephone =
        this.videos =
        this.websites = null ;
        return this ;
    }

    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new Place( this.toObject() ) ;
    }

    /**
     * Populate the object with specific behaviors or types. Launched by default in the constructor and in the set methods.
     * @returns {Thing} The current reference.
     */
    populate()
    {
        const {
            activities,
            additionalType,
            address ,
            containedInPlace,
            containsPlace,
            conceptualObjects,
            email,
            events,
            geo,
            museumType,
            offers,
            openingHoursSpecification,
            permits,
            prohibitions,
            services,
            specialOpeningHoursSpecification,
            status,
            telephone,
            websites
        } = this ;

        populateMedias( this ) ;

        if( activities instanceof Array )
        {
            this.activities = activities.map( item => item instanceof Word ? item : new Word(item) ) ;
        }

        if( additionalType && !(additionalType instanceof Word))
        {
            this.additionalType = new Word(additionalType) ;
        }

        if( address && !(address instanceof PostalAddress))
        {
            this.address = new PostalAddress(address) ;
        }

        if( containedInPlace )
        {
            if( containedInPlace instanceof Array )
            {
                this.containedInPlace = containedInPlace.map( item => item instanceof Place ? item : new Place(item) ) ;
            }
            else
            {
                this.containedInPlace = new Place( containedInPlace ) ;
            }
        }

        if( containsPlace )
        {
            if( containsPlace instanceof Array )
            {
                this.containsPlace = containsPlace.map( item => item instanceof Place ? item : new Place(item) ) ;
            }
            else
            {
                this.containsPlace = new Place( containsPlace ) ;
            }
        }

        if( email )
        {
            if( email instanceof Array )
            {
                this.email = email.map( item => item instanceof PropertyValue ? item : new PropertyValue(item) ) ;
            }
        }

        if( events )
        {
            if( events instanceof Array )
            {
                this.events = events.map( item => new Event(item) ) ;
            }
            else
            {
                this.events = new Event( events ) ;
            }
        }

        if( geo )
        {
            const {
                box , circle, line ,
                latitude , longitude , elevation
            } = geo ;

            if( elevation || latitude || longitude )
            {
                this.geo = new GeoCoordinates( geo ) ;
            }
            else if( box || circle || line )
            {
                this.geo = new GeoShape( geo ) ;
            }
            else
            {
                this.geo = null ;
            }
        }

        if( museumType instanceof Array )
        {
            this.museumType = museumType.map( item => item instanceof Word ? item : new Word(item) ) ;
        }

        if( conceptualObjects )
        {
            if( conceptualObjects instanceof Array )
            {
                this.conceptualObjects = conceptualObjects.map( item => new ConceptualObject(item) ) ;
            }
            else
            {
                this.conceptualObjects = new ConceptualObject( conceptualObjects ) ;
            }
        }

        if( offers instanceof Array )
        {
            this.offers = offers.map( item => item instanceof Offer ? item : new Offer(item) ) ;
        }

        if( openingHoursSpecification )
        {
            if( openingHoursSpecification instanceof Array )
            {
                this.openingHoursSpecification = openingHoursSpecification.map( item => item instanceof OpeningHoursSpecification ? item : new OpeningHoursSpecification(item) ) ;
            }
            else
            {
                this.openingHoursSpecification = new OpeningHoursSpecification( openingHoursSpecification ) ;
            }
        }

        if( permits instanceof Array )
        {
            this.permits = permits.map( item => item instanceof Word ? item : new Word(item) ) ;
        }

        if( prohibitions instanceof Array )
        {
            this.prohibitions = prohibitions.map( item => item instanceof Word ? item : new Word(item) ) ;
        }

        if( services instanceof Array )
        {
            this.services = services.map( item => item instanceof Word ? item : new Word(item) ) ;
        }

        if( specialOpeningHoursSpecification )
        {
            if( specialOpeningHoursSpecification instanceof Array )
            {
                this.specialOpeningHoursSpecification = specialOpeningHoursSpecification.map( item => item instanceof OpeningHoursSpecification ? item : new OpeningHoursSpecification(item) ) ;
            }
            else
            {
                this.specialOpeningHoursSpecification = new OpeningHoursSpecification( specialOpeningHoursSpecification ) ;
            }
        }

        if( status && !(status instanceof Word))
        {
            this.status = new Word(status) ;
        }

        if( telephone )
        {
            if( telephone instanceof Array )
            {
                this.telephone = telephone.map( item => item instanceof PropertyValue ? item : new PropertyValue(item) ) ;
            }
        }

        if( websites )
        {
            if( websites instanceof Array )
            {
                this.websites = websites.map( item => item instanceof Website ? item : new Website(item) ) ;
            }
        }

        return this ;
    }

    /**
     * Returns the generic object representation of the item.
     * @return {Object} The generic object representation of the item.
     */
    toObject()
    {
        let {
            about,
            activities,
            address,
            additionalProperty,
            audios,
            branchCode,
            conceptualObjects,
            containedInPlace,
            containsPlace,
            distance,
            email,
            events,
            faxNumber,
            geo,
            logo,
            museumType,
            numAudios,
            numConceptualObject,
            numEvents,
            numPhotos,
            numVideos,
            offers,
            openingHoursSpecification,
            permits,
            photos,
            prohibitions,
            publicAccess,
            services,
            slogan,
            specialOpeningHoursSpecification,
            status,
            telephone,
            videos,
            websites
        } = this ;

        activities                       = generify(activities) ;
        address                          = generify(address) ;
        additionalProperty               = generify(additionalProperty) ;
        audios                           = generify(audios) ;
        containedInPlace                 = generify(containedInPlace) ;
        containsPlace                    = generify(containsPlace) ;
        email                            = generify(email) ;
        events                           = generify(events) ;
        geo                              = generify(geo) ;
        logo                             = generify(logo);
        museumType                       = generify(museumType) ;
        conceptualObjects                = generify(conceptualObjects) ;
        offers                           = generify(offers) ;
        openingHoursSpecification        = generify(openingHoursSpecification) ;
        permits                          = generify(permits) ;
        photos                           = generify(photos) ;
        prohibitions                     = generify(prohibitions) ;
        services                         = generify(services) ;
        specialOpeningHoursSpecification = generify(specialOpeningHoursSpecification) ;
        status                           = generify(status) ;
        telephone                        = generify(telephone) ;
        videos                           = generify(videos);
        websites                         = generify(websites);

        return {
            ...super.toObject() ,
            about,
            activities,
            audios,
            additionalProperty,
            address,
            branchCode,
            containedInPlace,
            containsPlace,
            distance,
            email,
            events,
            faxNumber,
            geo,
            logo,
            museumType,
            numAudios,
            numConceptualObject,
            numEvents,
            numPhotos,
            numVideos,
            conceptualObjects,
            offers,
            openingHoursSpecification,
            permits,
            photos,
            prohibitions,
            publicAccess,
            services,
            slogan,
            specialOpeningHoursSpecification,
            status,
            telephone,
            videos,
            websites
        }
    }
}

/**
 * Indicates if the passed-in object is a Place or a compatible generic object with the good Place definition.
 * @param {Object} object The object to validate.
 * @param {boolean} strict Indicates if all elements must be valid or only one.
 * @returns {boolean} If the object is a valid Place definition.
 */
Place.is = ( object , strict = false ) =>
{
    if( object instanceof Place )
    {
        return true ;
    }
    else if( strict )
    {
        return object.hasOwnProperty('address')
            && object.hasOwnProperty('geo') ;
    }
    else
    {
        return object.hasOwnProperty('address')
            || object.hasOwnProperty('geo') ;
    }
};

export default Place ;
