import React from 'react'

import isString from 'vegas-js-core/src/isString'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import clsx from 'clsx'

import PropTypes  from 'prop-types'

import initApp from '../../../../contexts/app/initApp'

import { withStyles } from '@material-ui/core/styles'

import { Typography, Tooltip } from '@material-ui/core'

import Container from '../../../../components/containers/Container'

import blueGrey from '@material-ui/core/colors/blueGrey'
import orange   from '@material-ui/core/colors/orange'

import LabelIcon          from '@material-ui/icons/FiberManualRecord'
import LabelImportantIcon from '@material-ui/icons/LabelImportant'

import MedicalMethod from '../../../../things/medical/MedicalMethod'
import Thing         from '../../../../things/Thing'

const styles = () => (
{
    root :
    {
        alignItems    : 'center',
        display       : 'flex' ,
        flexDirection : 'row' ,
        paddingBottom : 10,
        paddingTop    : 10,
        width         : '100%'
    }
});

class AnalysisMethod extends Container
{
    render = () =>
    {
        const { classes, className, thing } = this.props ;
        if( thing instanceof MedicalMethod )
        {
            const {
                editable,
                lang,
                onClick
            } = this.props ;

            const locale = this.getLocale() ;

            const { unknown = '----' , tooltips } = locale ;

            let {
                additionalType,
                mandatory
            } = thing ;

            let icon  = null ;
            let label = null ;

            if( additionalType instanceof Thing )
            {
                label = additionalType.getLocaleName( lang ) ;
            }

            if( label === null || label === '' )
            {
                label = unknown ;
            }

            if( isString( label ) && label !== '' )
            {
                label = ucFirst(label) ;
                if( editable )
                {
                    label = (
                    <button
                        className = 'cursor-pointer text-blue-700 font-normal'
                        onClick   = { onClick }
                    >
                        { label }
                    </button>) ;
                }

                label =
                <Typography
                    className = 'font-normal text-gray-700'
                    variant   = 'body1'
                >
                    {label}
                </Typography> ;
            }
            else
            {
                label = null ;
            }

            if( mandatory === true )
            {
                icon = <LabelImportantIcon className='mr-16' style={{ color:orange[500] }} /> ;
            }
            else
            {
                icon = <LabelIcon className='mr-16' style={{ color:blueGrey[300] }}/> ;
            }

            if( tooltips && mandatory )
            {
                let { mandatory } = tooltips ;
                icon = (
                <Tooltip title={mandatory} placement='top'>
                    { icon }
                </Tooltip>);
            }

            return (
            <div className={ clsx(classes.root,className) }>
                { icon }
                { label }
            </div>);
        }

        return null ;
    };

    getLocale = () => this.props.locale.things.medical.analysisMethod ;
}

AnalysisMethod.defaultProps =
{
    ...Container.defaultProps,
    editable : false ,
    onClick  : null ,
    thing    : null
};

AnalysisMethod.propTypes =
{
    ...Container.propTypes,
    editable : PropTypes.bool,
    onClick  : PropTypes.func,
    thing    : PropTypes.object
};

export default withStyles( styles )( initApp( AnalysisMethod ) ) ;
