import generify from './generify'

import isString from 'vegas-js-core/src/isString'

import ConceptualObject from './ConceptualObject'
import NumberValue      from './NumberValue'
import Place            from './Place'
import PostalAddress    from './PostalAddress'
import PropertyValue    from './PropertyValue'
import Person           from './Person'
import Thing            from './Thing'
import Word             from './Word'
import Website          from "./Website"

import createAgent from './createAgent'

import populateMedias from './creativework/populateMedias'

/**
 * Defines an organization.
 * @memberOf things
 */
class Organization extends Thing
{
    /**
     * Creates a new Person instance.
     * @constructor
     * @param object The generic object to initialize the object the first time.
     */
    constructor( object = null )
    {
        super() ;

        /**
         * The About reference of the organization.
         * @type {Object|string}
         */
        this.about = null ;

        /**
         * Physical address of the organization.
         * @type {Object|string|PostalAddress}
         */
        this.address = null ;

        /**
         * The ape identifier of this organization.
         * @type {Word}
         */
        this.ape = null ;

        /**
         * The audios medias of this organization.
         * @type {Array}
         */
        this.audios = null;

        /**
         * One or multiple department for this organization.
         * @type {Organization|Array}
         */
        this.department = null ;

        /**
         * The date that this organization was dissolute.
         * @type {string|Date}
         */
        this.dissolutionDate = null ;

        /**
         * Someone working for this organization.
         * @type {Array|Person}
         */
        this.employees = null  ;

        /**
         * Email enumeration for this organization.
         * @type {Array|PropertyValue}
         */
        this.email = null  ;

        /**
         * One or multiple persons who founded this organization.
         * @type {Person|Array}
         */
        this.founder = null ;

        /**
         * The date that this organization was founded.
         * @type {string|Date}
         */
        this.foundingDate = null ;

        /**
         * The place where the Organization was founded.
         * @type {null}
         */
        this.foundingLocation = null ;

        /**
         * The identifier property represents any kind of identifier for any kind of Thing, such as ISBNs, GTIN codes, UUIDs etc.
         * @type {PropertyValue|Object|string}
         */
        this.identifier = null ;

        /**
         * Legal form of the organization.
         * @type {Object|string}
         */
        this.legalForm = null ;

        /**
         * Legal name of the organization.
         * @type {Object|string}
         */
        this.legalName = null ;

        /**
         * Location of the item.
         * @type {Object|string}
         */
        this.location = null ;

        /**
         * An associated logo.
         * @type {string|ImageObject}
         */
        this.logo = null ;

        /**
         * Indicates if the item is member of other items.
         * @type {Array|Organization}
         */
        this.memberOf = null ;

        /**
         * A member of an Organization. Organizations can be members of organizations;
         * @type {Person|Organization|Array}
         */
        this.members = null ;

        /**
         * Indicates the number of audios medias of this thing.
         * @type {Array}
         */
        this.numAudios = null;

        /**
         * Indicates the number of videos medias of this thing.
         * @type {Array}
         */
        this.numPhotos = null;

        /**
         * Indicates the number of videos medias of this thing.
         * @type {Array}
         */
        this.numVideos = null;

        /**
         * The collection of numbers and identifiers of the organization.
         * @type {Array}
         */
        this.numbers = null ;

        /**
         * The object(s) owns by the organization.
         * @type {Array|ConceptualObject}
         */
        this.owns = null ;

        /**
         * The larger organization that this organization is a subOrganization of, if any.
         * @type {Array|Organization}
         */
        this.parentOrganization = null  ;

        /**
         * The photos collection of this organization.
         * @type {Array}
         */
        this.photos = null ;

        /**
         * A provider of an Organization. Organizations can be providers of organizations;
         * @type {Person|Organization|Array}
         */
        this.providers = null ;

        /**
         * A slogan or motto associated with the item.
         * @type {Object|string}
         */
        this.slogan = null ;

        /**
         * A relationship between two organizations where the first includes the second
         * @type {Array|Organization}
         */
        this.subOrganization = null  ;

        /**
         * TThe Tax / Fiscal ID of the organization or person, e.g. the TIN in the US or the CIF/NIF in Spain or the SIRET in France.
         * @type {string}
         */
        this.taxID = null ;

        /**
        * The telephone enumeration of this organization.
        * @type {PropertyValue|array}
        */
        this.telephone = null ;

        /**
         * The Value-added Tax ID of the organization or person.
         * @type {string}
         */
        this.vatID = null ;

        /**
         * The videos medias of this organization.
         * @type {Array}
         */
        this.videos = null;

        /**
         * The websites enumeration of this organization.
         * @type {Website|array}
         */
        this.websites = null ;

        this.set( object ) ;
    }

    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        super.clear() ;
        this.about =
        this.address =
        this.ape =
        this.audios =
        this.employees =
        this.email =
        this.department =
        this.dissolutionDate =
        this.founder =
        this.foundingDate =
        this.foundingLocation =
        this.legalForm =
        this.legalName =
        this.location =
        this.logo =
        this.memberOf =
        this.numAudios =
        this.numPhotos =
        this.numVideos =
        this.numbers =
        this.owns =
        this.parentOrganization =
        this.photos =
        this.providers =
        this.slogan =
        this.subOrganization =
        this.taxID =
        this.telephone =
        this.vatID =
        this.videos =
        this.websites = null  ;
        return this ;
    }

    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new Organization( this.toObject() ) ;
    }

    /**
     * Populate the object with specific behaviors or types. Launched by default in the constructor and in the set methods.
     * @returns {Thing} The current reference.
     */
    populate()
    {
        const {
            additionalType,
            address,
            ape,
            employees,
            email,
            department,
            founder,
            foundingLocation,
            identifier,
            legalForm,
            location,
            members,
            memberOf,
            numbers,
            owns,
            parentOrganization,
            providers,
            subOrganization,
            telephone,
            websites
        } = this ;

        if( additionalType && !(additionalType instanceof Word))
        {
            this.additionalType = new Word(additionalType) ;
        }

        if( address && !(address instanceof PostalAddress))
        {
            this.address = new PostalAddress(address) ;
        }

        if( ape && !(ape instanceof Word))
        {
            this.ape = new Word(ape) ;
        }

        if( department )
        {
            if( department instanceof Array )
            {
                this.department = department.map( item => item instanceof Organization ? item : new Organization(item) ) ;
            }
            else
            {
                this.department = new Organization( department ) ;
            }
        }

        if( email )
        {
            if( email instanceof Array )
            {
                this.email = email.map( item => item instanceof PropertyValue ? item : new PropertyValue(item) ) ;
            }
        }

        if( employees )
        {
            if( employees instanceof Array )
            {
                this.employees = employees.map( item => item instanceof Person ? item : new Person(item) ) ;
            }
            else
            {
                this.employees = new Person( employees ) ;
            }
        }

        if( founder )
        {
            if( founder instanceof Array )
            {
                this.founder = founder.map( item => item instanceof Person ? item : new Person(item) ) ;
            }
            else
            {
                this.founder = new Person( founder ) ;
            }
        }

        if( foundingLocation )
        {
            this.foundingLocation = new Place( foundingLocation ) ;
        }

        if( identifier && !isString(identifier) && identifier.hasOwnProperty('value') )
        {
            this.identifier = new PropertyValue(identifier);
        }

        populateMedias( this , { useLogo:true } ) ;

        if( legalForm && !(legalForm instanceof Word))
        {
            this.legalForm = new Word(legalForm) ;
        }

        if( location && !(location instanceof Place))
        {
            this.location = new Place( location ) ;
        }

        if( memberOf )
        {
            if( memberOf instanceof Array )
            {
                this.memberOf = memberOf.map( item => item instanceof Organization ? item : new Organization(item) ) ;
            }
            else
            {
                this.memberOf = new Organization( memberOf ) ;
            }
        }

        if( numbers instanceof Array )
        {
            this.numbers = numbers.map( item => item instanceof NumberValue ? item : new NumberValue(item) ) ;
        }

        if( parentOrganization )
        {
            if( parentOrganization instanceof Array )
            {
                this.parentOrganization = parentOrganization.map( item => item instanceof Organization ? item : new Organization(item) ) ;
            }
            else
            {
                this.parentOrganization = new Organization( parentOrganization ) ;
            }
        }

        if( members instanceof Array )
        {
            this.members = members.map( createAgent ) ;
        }

        if( owns )
        {
            if( owns instanceof Array )
            {
                this.owns = owns.map( item => item instanceof ConceptualObject ? item : new ConceptualObject(item) ) ;
            }
            else
            {
                this.owns = new ConceptualObject( owns ) ;
            }
        }

        if( providers instanceof Array )
        {
            this.providers = providers.map( createAgent ) ;
        }

        if( subOrganization )
        {
            if( subOrganization instanceof Array )
            {
                this.subOrganization = subOrganization.map( item => item instanceof Organization ? item : new Organization(item) ) ;
            }
            else
            {
                this.subOrganization = new Organization( subOrganization ) ;
            }
        }

        if( telephone )
        {
            if( telephone instanceof Array )
            {
                this.telephone = telephone.map( item => item instanceof PropertyValue ? item : new PropertyValue(item) ) ;
            }
        }

        if( websites )
        {
            if( websites instanceof Array )
            {
                this.websites = websites.map( item => item instanceof Website ? item : new Website(item) ) ;
            }
        }

        return this ;
    }

    /**
     * Returns the generic object representation of the item.
     * @return {Object} The generic object representation of the item.
     */
    toObject()
    {
        let {
            about,
            address,
            ape,
            audios,
            department,
            dissolutionDate,
            email,
            employees,
            founder,
            foundingDate,
            foundingLocation,
            identifier,
            legalForm,
            legalName,
            location,
            logo,
            memberOf,
            members,
            numAudios,
            numPhotos,
            numVideos,
            numbers,
            owns,
            parentOrganization,
            photos,
            providers,
            slogan,
            subOrganization,
            taxID,
            telephone,
            vatID,
            videos,
            websites
        } = this ;

        address            = generify(address);
        audios             = generify(audios);
        department         = generify(department);
        ape                = generify(ape);
        email              = generify(email);
        employees          = generify(employees);
        founder            = generify(founder);
        foundingLocation   = generify(foundingLocation);
        identifier         = generify(identifier);
        location           = generify(location);
        logo               = generify(logo);
        memberOf           = generify(memberOf);
        members            = generify(members);
        numbers            = generify(numbers);
        photos             = generify(photos);
        owns               = generify(owns);
        parentOrganization = generify(parentOrganization);
        providers          = generify(providers);
        subOrganization    = generify(subOrganization);
        telephone          = generify(telephone);
        videos             = generify(videos);
        websites           = generify(websites);

        return {
            ...super.toObject() ,
            about,
            address,
            ape,
            audios,
            department,
            dissolutionDate,
            email,
            employees,
            founder,
            foundingDate,
            foundingLocation,
            identifier,
            legalForm,
            legalName,
            location,
            logo,
            memberOf,
            members,
            numAudios,
            numPhotos,
            numVideos,
            numbers,
            owns,
            parentOrganization,
            photos,
            providers,
            slogan,
            subOrganization,
            taxID,
            telephone,
            vatID,
            videos,
            websites
        }
    }
}

export default Organization ;
