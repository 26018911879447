import React , { Component } from 'react'

import PropTypes from 'prop-types'

import ConfigContext from './ConfigContext'

import defaultProperties from './defaultProperties'

class ConfigProvider extends Component
{
    constructor( props )
    {
        super(props);

        const { config = {} } = this.props ;

        this.state =
        {
            ...defaultProperties,
            config,
            setConfig : this.set
        };
    }
    
    render()
    {
        const { children } = this.props;
        return (
            <ConfigContext.Provider value={ this.state }>
                {children}
            </ConfigContext.Provider>
        )
    }
    
    set = ( config = {} ) => { this.setState({ config }); };
}

ConfigProvider.propTypes =
{
    config : PropTypes.object.isRequired
};

export default ConfigProvider ;