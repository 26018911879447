import initDialog from '../initDialog'

import { RemoveDialog } from '../RemoveDialog'

import api from '../../../configs/api'

const styles = () => ({});

class RemoveApplicationDialog extends RemoveDialog
{
    getLocale = () => this.props.locale.dialogs.application.application.remove ;
}

RemoveApplicationDialog.defaultProps =
{
    ...RemoveDialog.defaultProps,
    uri    : api.applications.url,
    useUrl : true
};

RemoveApplicationDialog.propTypes =
{
    ...RemoveDialog.propTypes
};

export default initDialog({ styles })( RemoveApplicationDialog );
