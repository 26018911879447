import createAgent from './createAgent'
import generify    from './generify'

import Mark         from './Mark'
import Material     from './Material'
import Measurement  from './Measurement'
import NumberValue  from './NumberValue'
import Place        from './Place'
import Thing        from './Thing'
import Word         from './Word'
import Website      from './Website'

import populateMedias from './creativework/populateMedias'

/**
 * Entities that have a somewhat fixed, physical extension.
 * @member things
 */
class ConceptualObject extends Thing
{
    /**
     * Creates a new ConceptualObject instance.
     * @constructor
     * @param object The generic object to initialize the object the first time.
     */
    constructor( object = null )
    {
        super() ;

        /**
         * The audios medias of this object.
         * @type {Array}
         */
        this.audios = null;

        /**
         * The category of this object.
         * @type {Object|string}
         */
        this.category = null ;

        /**
         * The location of this object.
         * @type {Object|place}
         */
        this.location = null ;

        /**
         * Mark and inscription information supports Security, Accountability, Access, and an Historic archive. It enables the retrieval of lost property and the unique identification of otherwise
         * similar objects and can be of particular research significance and all processes, methods, and techniques used in the creation of the object.
         * @type {Array}
         */
        this.marks = null ;

        /**
         * The Materials used in the creation, decoration, and any subsequent adaptations of the object
         * and all processes, methods, and techniques used in the creation of the object.
         * @type {Array}
         */
        this.materials = null ;

        /**
         * The list of measurements setting of this object.
         * @type {Array}
         */
        this.measurements = null ;

        /**
         * The artistic movement of this object
         * @type {Object|string}
         */
        this.movement = null ;

        /**
         * Indicates the number of audios medias of this thing.
         * @type {Array}
         */
        this.numAudios = null;

        /**
         * Indicates the number of videos medias of this thing.
         * @type {Array}
         */
        this.numPhotos = null;

        /**
         * Indicates the number of videos medias of this thing.
         * @type {Array}
         */
        this.numVideos = null;

        /**
         * The collection of numbers and identifiers of this object.
         * @type {Array}
         */
        this.numbers = null ;

        /**
         * The temporal of this object.
         * @type {Object|string}
         */
        this.temporal = null ;

        /**
         * The photos collection of this object.
         * @type {Array}
         */
        this.photos = null ;

        /**
         * The productions enumeration of this object.
         * @type {production|array}
         */
        this.productions = null ;

        /**
         * The videos medias of this object.
         * @type {Array}
         */
        this.videos = null;

        /**
         * The websites enumeration of this object.
         * @type {Website|array}
         */
        this.websites = null ;

        this.set( object ) ;
    }

    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        super.clear() ;
        this.audios =
        this.category =
        this.location =
        this.marks =
        this.materials =
        this.measurements =
        this.movement =
        this.numAudios =
        this.numPhotos =
        this.numVideos =
        this.numbers =
        this.photos =
        this.temporal =
        this.productions =
        this.videos =
        this.websites = null;
        return this ;
    }

    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new ConceptualObject( this.toObject() ) ;
    }

    /**
     * Populate the object with specific behaviors or types. Launched by default in the constructor and in the set methods.
     * @returns {Thing} The current reference.
     */
    populate()
    {
        const {
            category,
            location,
            numbers,
            marks,
            materials,
            measurements,
            movement,
            productions,
            temporal,
            websites
        } = this ;

        if( category && !(category instanceof Word))
        {
            this.category = new Word( category ) ;
        }

        populateMedias( this ) ;

        if( location && !(location instanceof Place))
        {
            this.location = new Place( location ) ;
        }

        if( numbers instanceof  Array )
        {
            this.numbers = numbers.map( item => item instanceof NumberValue ? item : new NumberValue(item) ) ;
        }

        if( marks instanceof Array )
        {
            this.marks = marks.map( item => item instanceof Mark ? item : new Mark(item) ) ;
        }

        if( materials instanceof Array )
        {
            this.materials = materials.map( item => item instanceof Material ? item : new Material(item) ) ;
        }

        if( measurements instanceof Array )
        {
            this.measurements = measurements.map( item => item instanceof Measurement ? item : new Measurement(item) ) ;
        }

        if( movement && !(movement instanceof Word))
        {
            this.movement = new Word( movement ) ;
        }

        if( productions instanceof Array )
        {
            this.productions = productions.map( createAgent ) ;
        }

        if( temporal && !(temporal instanceof Word))
        {
            this.temporal = new Word( temporal ) ;
        }

        if( websites instanceof Array )
        {
            this.websites = websites.map( item => item instanceof Website ? item : new Website(item) ) ;
        }

        return this ;
    }

    /**
     * Returns the generic object representation of the Object.
     * @return {Object} The generic object representation of the Object.
     */
    toObject()
    {
        let {
            audios,
            category,
            location,
            marks,
            materials,
            measurements,
            movement,
            numAudios,
            numPhotos,
            numVideos,
            numbers,
            photos,
            productions,
            temporal,
            videos,
            websites
        } = this ;

        audios       = generify(audios) ;
        category     = generify(category) ;
        location     = generify(location) ;
        marks        = generify(marks) ;
        materials    = generify(materials) ;
        measurements = generify(measurements) ;
        movement     = generify(movement) ;
        numbers      = generify(numbers) ;
        photos       = generify(photos) ;
        temporal     = generify(temporal) ;
        productions  = generify(productions) ;
        videos       = generify(videos) ;
        websites     = generify(websites) ;

        return {
            ...super.toObject() ,
            audios,
            category,
            location,
            marks,
            materials,
            measurements,
            movement,
            numAudios,
            numPhotos,
            numVideos,
            numbers,
            photos,
            productions,
            temporal,
            videos,
            websites
        };
    }
}

/**
 * Indicates if the passed-in object is a Object or a compatible generic object with the good Object definition.
 * @param {Object} conceptualObject The object to validate.
 * @param {boolean} strict Indicates if all elements must be valid or only one.
 * @returns {boolean} If the object is a valid Object definition.
 */
ConceptualObject.is = ( conceptualObject , strict = false ) =>
{
    if( conceptualObject instanceof ConceptualObject )
    {
        return true ;
    }
    else if( strict )
    {
        return conceptualObject.hasOwnProperty('category')
            && conceptualObject.hasOwnProperty('location') ;
    }
    else
    {
        return conceptualObject.hasOwnProperty('category')
            || conceptualObject.hasOwnProperty('location') ;
    }
};

export default ConceptualObject ;
