import Thing from "../things/Thing";

class Activity extends Thing
{
    /**
     * Creates a new Activity instance.
     * @constructor
     * @param object The generic object to initialize the object the first time.
     */
    constructor( object = null )
    {
        super() ;
        
        /**
         * The user agent that is acting on behalf of a user, such as a web browser that retrieves, renders and facilitates end user interaction with Web content.
         * @type {string}
         */   
        this.agent = null ;
        
        /** 
         * The user ip value.
         * @type {string}
         */
        this.ip = null ;
        
        /** 
         * The method attribute specifies how to send form-data.
         * @type {string}
         */
        this.method = null ;
        
        /** 
         * The resource of the current activity.
         * @type {string}
         */
        this.resource = null ;

        this.set( object ) ;
    }

    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        super.clear();
        this.agent    =
        this.ip       =
        this.method   =
        this.resource = null ;
        return this ;
    }

    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new Activity( this.toObject() ) ;
    }

    /**
     * Returns the generic object representation of the object.
     * @returns {Object}
     */
    toObject()
    {
        let {
            agent,
            ip,
            method,
            resource
        } = this ;

        return {
            ...super.toObject() ,
            agent,
            ip,
            method,
            resource
        }
    }
}

export default Activity ;