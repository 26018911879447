import React from 'react'

import clsx from 'clsx'

import { withRouter } from 'react-router-dom'

import format from 'vegas-js-core/src/strings/fastformat'

import DescriptionIcon   from '@material-ui/icons/Notes'
import FormatQuoteIcon   from '@material-ui/icons/FormatQuote'
import NoteIcon          from '@material-ui/icons/SpeakerNotes'
import TextIcon          from '@material-ui/icons/Subject'
import TitleIcon         from '@material-ui/icons/Title'

import withLocale from '../../../contexts/i18n/withLocale'

import MultiLangEditor from '../../../components/editors/MultiLangEditor'

import ThingSubContainer from '../../../display/containers/ThingSubContainer'

import Address        from '../../../display/things/Address'
import Audio          from '../../../display/things/medias/Audio'
import GeoCoordinates from '../../../display/things/GeoCoordinates'
import Image          from '../../../display/things/medias/Image'
import Metadatas      from '../../../display/things/Metadatas'
import Place          from '../../../display/things/Place'
import Video          from '../../../display/things/medias/Video'

import RemovePlaceDialog from '../../../display/dialogs/remove/RemovePlaceDialog'

class About extends ThingSubContainer
{
    getLocale = () => this.props.locale.views.place.about ;

    render = () =>
    {
        const { thing } = this.props ;
        if( thing )
        {
            const {
                className,
                path,
                style,
            } = this.props ;

            const {
                address,
                alternateName,
                description,
                geo,
                id,
                notes,
                slogan,
                text
            } = thing ;

            const locale = this.getLocale() ;

            let view = (
                <div className="flex-1 flex flex-col space-y-24">

                    <Metadatas
                        activable             = { true }
                        deletable             = { true }
                        RemoveDialogComponent = { RemovePlaceDialog }
                        onRemove              = { this.props.onRemove }
                        thing                 = { thing }
                        uri                   = { format(path.place,id) }
                    />

                    <Place
                        editUri  = { format(path.place,id) }
                        onChange = { this.change }
                        onRemove = { this.onBack }
                        thing    = { thing }
                    />

                    <div className='grid grid-cols-1 sm:grid-cols-2 gap-16'>
                        <MultiLangEditor
                            html     = { false }
                            icon     = { <TitleIcon/> }
                            input    = { true }
                            locale   = { locale.alternateName }
                            onChange = { this.changeProperty('alternateName') }
                            text     = { alternateName }
                            uri      = { format( path.alternateName, id ) }
                        />
                        <MultiLangEditor
                            html     = { false }
                            icon     = { <FormatQuoteIcon/> }
                            input    = { true }
                            locale   = { locale.slogan }
                            onChange = { this.changeProperty('slogan') }
                            text     = { slogan }
                            uri      = { format( path.slogan, id ) }
                        />
                        <GeoCoordinates
                            editUri  = { format(path.geo,id) }
                            onChange = { this.changeProperty('geo') }
                            thing    = { geo }
                        />
                        <Address
                            editUri  = { format(path.address,id) }
                            onChange = { this.changeProperty('address',true) }
                            thing    = { address }
                        />
                        <MultiLangEditor
                            html     = { false }
                            icon     = { <DescriptionIcon/> }
                            locale   = { locale.description }
                            onChange = { this.changeProperty('description') }
                            text     = { description }
                            uri      = { format( path.description, id ) }
                        />
                        <div>
                            <Image
                                onChange = { this.change }
                                thing    = { thing }
                                uri      = { path.image }
                            />
                        </div>
                    </div>

                    <MultiLangEditor
                        icon     = { <TextIcon/> }
                        locale   = { locale.text }
                        onChange = { this.changeProperty('text') }
                        text     = { text }
                        uri      = { format( path.text, id ) }
                    />

                    <MultiLangEditor
                        icon     = { <NoteIcon/> }
                        locale   = { locale.notes }
                        onChange = { this.changeProperty('notes') }
                        text     = { notes }
                        uri      = { format( path.notes, id ) }
                    />

                    <div className='grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-16'>
                        <div>
                            <Audio
                                onChange = { this.change }
                                thing    = { thing }
                                uri      = { path.audio }
                            />
                        </div>
                        <div>
                            <Video
                                onChange = { this.change }
                                thing    = { thing }
                                uri      = { path.video }
                            />
                        </div>
                    </div>

                </div>
            )

            return (
            <div
                className = { clsx( 'flex-1 p-12' , className ) }
                style     = { style }
            >
                { this.renderWithTransition(view) }
            </div>
            );
        }
        return null ;
    }
}

About.defaultProps =
{
    ...ThingSubContainer.defaultProps
};

About.propTypes =
{
    ...ThingSubContainer.propTypes
};

export default withLocale( withRouter(About) ) ;
