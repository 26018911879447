import React from 'react'

import clsx from 'clsx'

import PropTypes from 'prop-types'

import notEmpty from 'vegas-js-core/src/strings/notEmpty'

import CloudIcon      from '@material-ui/icons/Cloud'
import CloudOffIcon   from '@material-ui/icons/CloudOff'
import CloudQueueIcon from '@material-ui/icons/CloudQueue'

import withDataTable  from './withDataTable'

import { ThingTable } from './ThingTable'

import RevokeSessionDialog from '../dialogs/remove/RevokeSessionDialog'

import SessionCell from '../cells/SessionCell'
import ThingAvatar from '../avatars/ThingAvatar'

import Session from '../../vo/Session'

class SessionsTable extends ThingTable
{
    componentDidUpdate( prevProps , prevState, snapshot )
    {
        const {
            limit,
            reload,
            reset,
        } = this.props ;

        if( prevProps.reload !== reload )
        {
            if( reload )
            {
                this.reload() ;
                const { onReload } = this.props ;
                if( onReload instanceof Function )
                {
                    onReload() ;
                }
                return ;
            }
        }

        if( prevProps.reset !== reset )
        {
            if( reset )
            {
                this.reload() ;
                const { onReset } = this.props ;
                if( onReset instanceof Function )
                {
                    onReset() ;
                }
                return ;
            }
        }

        let load ;

        let { first, offset } = this.state ;

        if( prevState.offset !== offset )
        {
            load = true ;
        }

        if( prevProps.limit !== limit )
        {
            // console.log( this + ' componentDidUpdate change limit' , prevProps.limit , '->' , limit , 'first:', first ) ;
            load = true ;
        }

        if( load && !first )
        {
            this.load( offset );
        }
        else if( this.update instanceof Function )
        {
            this.update( prevProps , prevState , snapshot ) ;
        }
    }

    getCurrentPath = () =>
    {
        const { active, uri } = this.props ;
        return uri + '/' + ( active ? 'true' : 'false') ;
    };

    getAvatar = ( thing , style = undefined ) =>
    {
        if( thing )
        {
            const {
                avatarSize,
                avatarVariant,
                revoked
            } = thing ;

            const icon = revoked ? <CloudOffIcon /> : <CloudIcon /> ;

            return (
                <ThingAvatar
                    className   = { clsx( revoked ? 'bg-red-600' : 'bg-green-600') }
                    defaultIcon = { icon }
                    thing       = { thing }
                    size        = { avatarSize }
                    style       = { style }
                    variant     = { avatarVariant }
                />
            );
        }
        return null ;
    };

    getEmptyLabel = () =>
    {
        const { active:flag } = this.props ;
        const { empty : { active , inactive } = {} }  = this.getLocale() || {} ;
        let empty = flag ? active : inactive ;
        if( notEmpty(empty) )
        {
            return empty ;
        }
        return null ;
    }

    getIcon = () => this.props.active ? <CloudIcon/> : <CloudQueueIcon/> ;

    getLocale = () => this.props.locale.display.tables.sessions ;

    getRowOptions = item =>
    {
        const { deletable } = this.props;
        if( deletable && item )
        {
             const { revoked } = item ;
             if( !revoked && this.isEditable() )
             {
                 return this.getDeleteButton(item) ;
             }
        }
        return null ;
    };

    getTitle = () =>
    {
        const { active , inactive } = this.getLocale() || {} ;
        return this.props.active ? active : inactive ;
    };

    onRemove = item =>
    {
        this.reload() ;
        const { onRevoke } = this.props ;
        if( onRevoke instanceof Function )
        {
            onRevoke( item ) ;
        }
    };

    onSelect = item =>
    {
        console.log( this + ' onSelect' , item ) ;
        if( item )
        {
            const {ip } = item ;
            if( notEmpty(ip) )
            {
                const { config: { ip_api_url: path = "" } = {}} = this.props;
                const win = window.open( path + ip , '_blank' ) ;
                win.focus() ;
            }
        }
    }
}

SessionsTable.defaultProps =
{
    ...ThingTable.defaultProps,
    active                : true ,
    activable             : false,
    addable               : false ,
    avatarSize            : 'small' ,
    CellComponent         : SessionCell ,
    clazz                 : Session,
    cellMode              : 'button',
    emptyClassName        : 'uppercase font-semibold' ,
    emptyIcon             : <CloudOffIcon color='action' fontSize='large' />,
    emptyVariant          : 'caption' ,
    onReload              : null ,
    onReset               : null ,
    onRevoke              : null ,
    reset                 : false,
    paginationPolicy      : 'normal' ,
    queries               : { sort : '-created' , skin:'full' },
    RemoveDialogComponent : RevokeSessionDialog ,
    searchPolicy          : 'none' ,
    selectPolicy          : 'none' ,
    sortable              : false
};

SessionsTable.propTypes =
{
    ...ThingTable.propTypes ,
    active    : PropTypes.bool ,
    onReload  : PropTypes.func ,
    onReset   : PropTypes.func ,
    onRevoke  : PropTypes.func ,
    reset     : PropTypes.bool,
    revokeAll : PropTypes.bool
};

export default withDataTable({ useSearch:false })(SessionsTable) ;