import React from 'react'

import clsx from 'clsx'

import PropTypes from 'prop-types'

import compose  from 'vegas-js-core/src/functors/compose'
import debounce from 'vegas-js-core/src/functors/debounce'
import isNumber from 'vegas-js-core/src/isNumber'
import isString from 'vegas-js-core/src/isString'

import { Redirect , withRouter } from 'react-router-dom'

import { Pulse as Preloader } from 'react-preloading-component'
// https://nel-co.github.io/react-preloading-component-demo

import blueGrey from '@material-ui/core/colors/blueGrey'

import { MdLibraryAdd as EmbedIcon } from 'react-icons/md'

import BugReportIcon  from '@material-ui/icons/BugReport'
import CloseIcon      from '@material-ui/icons/Close'
import CollectionIcon from '@material-ui/icons/PermMedia'
import MoreVertIcon   from '@material-ui/icons/MoreVert'
import SortIcon       from '@material-ui/icons/Sort'
import UploadIcon     from '@material-ui/icons/CloudUpload'

import SpeedDial       from '@material-ui/lab/SpeedDial'
import SpeedDialIcon   from '@material-ui/lab/SpeedDialIcon'
import SpeedDialAction from '@material-ui/lab/SpeedDialAction'

import { withStyles } from '@material-ui/core/styles'

import { Typography, withWidth } from '@material-ui/core'

import withDialogs from '../../contexts/dialogs/withDialogs'
import withLang    from '../../contexts/i18n/withLang'
import withLocale  from '../../contexts/i18n/withLocale'
import withSearch  from '../../contexts/search/withSearch'
import withSelect  from '../../contexts/select/withSelect'

import ScrollContainer from '../../components/containers/ScrollContainer'

import SimpleProgressHeader from '../../components/headers/SimpleProgressHeader'

import FilterBar from '../../components/bars/FilterBar'

import RemoveMediaObjectDialog from '../dialogs/remove/RemoveMediaObjectDialog'

import LIST       from '../../net/LIST'
import RequestStatus from '../../net/RequestStatus'
import UploadStatus  from '../../net/UploadStatus'

import CreativeWork from '../../things/CreativeWork'
import Thing        from '../../things/Thing'

import DialogContainer from '../../components/containers/DialogContainer'
import Gallery         from '../gallery/Gallery'

import createMediaObject from '../../things/creativework/createMediaObject'

import api from '../../configs/api'

const styles = theme =>
({
    container :
    {
        position  : 'absolute',
        bottom    : 0,
        left      : 0,
        right     : 0,
        top       : 0,
        overflowY : 'scroll',
        padding   : '20px 20px'
    },
    fail :
    {
        alignItems      : 'center',
        display         : 'flex',
        flexDirection   : 'column',
        flex            : 'auto',
        justifyContent  : 'center'
    },
    options :
    {
        position : 'absolute',
        right    : 24,
        zIndex   : 100,
        [ theme.breakpoints.up('md') ]: { top : 16 },
        [ theme.breakpoints.down('sm')] : { bottom : 16 }
    },
    root :
    {
        flex     : 'auto',
        overflow : 'hidden',
        position : 'relative'
    },
    search :
    {
        width : '100%'
    },
    speedDial :
    {
        position : 'absolute' ,
        zIndex   : 100,
        '&.MuiSpeedDial-directionLeft' :
        {
            top   : theme.spacing( 2 ) ,
            right : theme.spacing( 2.5 )
        } ,
        '&.MuiSpeedDial-directionUp' :
        {
            bottom : theme.spacing( 2 ) ,
            right  : theme.spacing( 2.5 )
        } ,
        '&.MuiSpeedDial-directionDown' :
        {
            top   : theme.spacing( 2 ) ,
            right : theme.spacing( 2.5 )
        },
        '&.MuiSpeedDial-directionRight' :
        {
            left  : theme.spacing( 2 ) ,
            right : theme.spacing( 2.5 )
        }
    }
});

const actions =
[
    {
        id      : 'upload' ,
        action  : ref => { ref.openUploadDialog() ; } ,
        icon    : <UploadIcon /> ,
        visible : props => props.uploadable === true
    },
    {
        id      : 'embed' ,
        action  : ref => { ref.openEmbedDialog() ; } ,
        icon    : <EmbedIcon  /> ,
        visible : props => props.embeddable === true
    },
    {
        id      : 'sort'  ,
        action  : ref => { ref.openSortDialog() ; } ,
        icon    : <SortIcon />  ,
        visible : props => props.sortable === true
    }
];

const defaultValues =
{
    count   : 0,
    hasMore : false,
    medias  : [],
    offset  : 0,
    total   : 0
};

const init = values => ({ ...defaultValues , ...values }) ;

class MediaContainer extends DialogContainer
{
    /**
     * Creates a new DatasContainer instance
     * @param props The properties send to the component to initialize it.
     */
    constructor( props )
    {
        super( props ) ;

        this.onScroll    = debounce( this.onScroll    , 250 ) ;
        this.onScrollTop = debounce( this.onScrollTop , 260 ) ;

        this.canceler = null ;
        this.scroller = null ;
        this.timeout  = null ;

        const options = init() ;

        this.state = {
            ...this.state ,
            actives      : {} ,
            activeStatus : RequestStatus.NEW ,
            dialHidden   : false,
            dialOpen     : false,
            status       : RequestStatus.NEW,
            ...options
        }
    }

    init = () =>
    {
        const {
            autoload,
            initSearch,
            setSelect
        }
        = this.props ;

        if( setSelect instanceof Function )
        {
            const { selectRemove } = this ;
            setSelect({ selectable:false , selectRemove });
        }

        if( initSearch )
        {
            let searchLabel ;
            const locale = this.getLocale() ;
            if( locale )
            {
                const { search } = locale ;
                searchLabel = search ;
            }
            initSearch( { searchLabel } ) ;
        }

        if( autoload )
        {
            this.timeout = setTimeout( this.load, 300, this.state.offset ) ;
        }
    };

    componentDidUpdate( prevProps )
    {
        const {
            facets,
            search,
            sort
        } = this.props  ;

        if(
            (prevProps.facets !== facets) ||
            (prevProps.search !== search) ||
            (prevProps.sort   !== sort)
        )
        {
            this.reload();
        }
    }

    getBadgeColor = () => 'success' ;

    /**
     * Returns an empty value object to inject in the add dialog component when is open.
     * @returns {Thing} A new empty value object.
     */
    getEntry = init => init instanceof CreativeWork ? init.clone() : null ;

    /**
     * Returns the header component to inject at the top of the container.
     * @returns {React.Component} The header component reference.
     */
    getHeader = () =>
    {
        const {
            back ,
            backTooltip ,
            iconButton ,
            iconClick ,
            iconTooltip ,
            onBack
        } = this.props ;

        const { total } = this.state;

        return (
        <SimpleProgressHeader
            back        = { back }
            backTooltip = { backTooltip }
            className   = "py-8"
            onBack      = { onBack }
            badgeColor  = { this.getBadgeColor() }
            icon        = { this.getIcon() }
            iconButton  = { iconButton }
            iconClick   = { iconClick }
            iconTooltip = { iconTooltip }
            loading     = { false }
            subtitle    = { this.getSubtitle() }
            title       = { this.getTitle() }
            total       = { total }
        />);
    };

    /**
     * Returns the icon reference.
     */
    getIcon = () => <CollectionIcon /> ;

    getLocale = () => this.props.locale.display.containers.mediaContainer ;

    getOptions = () =>
    {
        if( this.isEditable() )
        {
            let { actions , classes } = this.props ;

            let {
                actions   : i18n = {}  ,
                speedDial : { ariaLabel = "" } = {} ,
            } = this.getLocale() || {};

            if( actions instanceof Array && actions.length > 0 )
            {
                actions = actions.filter( ( { visible = false } = {} ) =>
                {
                    if( visible instanceof Function )
                    {
                        return visible( this.props ) ;
                    }
                    else
                    {
                        return Boolean( visible ) ;
                    }
                })
            }

            if( actions instanceof Array && actions.length > 0 )
            {

                const { speedDial , speedDialAction } = this.props;

                const { dialOpen , dialHidden } = this.state;

                return (
                    <SpeedDial
                        ariaLabel = { ariaLabel }
                        className = { classes.speedDial }
                        hidden    = { dialHidden }
                        onClose   = { this.closeSpeedDial }
                        onOpen    = { this.openSpeedDial }
                        open      = { dialOpen }
                        { ...speedDial }
                    >
                        {
                            actions.map( ( { action , id , icon /*, title*/ } , index ) =>
                            (
                                <SpeedDialAction
                                    key          = { 'action-' + index }
                                    icon         = { icon }
                                    tooltipTitle = { i18n[id] || null }
                                    onClick      = { () =>
                                    {
                                        if( action instanceof Function )
                                        {
                                           action( this ) ;
                                        }
                                    } }
                                    { ...speedDialAction }
                                /> ) )
                        }
                    </SpeedDial>
                ) ;
            }
        }
    };

    /**
     * Returns the current location pathname of the container page.
     * @return the current location pathname of the container page.
     */
    getCurrentPath = ( path = null ) =>
    {
        let uri = this.props.uri || this.props.location.pathname;
        if ( isString(uri) && isString(path) )
        {
            uri += path;
        }
        return uri;
    };

    getFilterBar = () =>
    {
        const locale = this.getLocale();
        if( locale )
        {
            const { facets } = this.props ;
            const { status } = this.state ;
            let { defaultFilter, filters } = locale ;
            if( filters instanceof Array )
            {
                const find = filters.find( item => item.value === facets ) ;
                return (
                    <FilterBar
                        className = 'mb-16'
                        disabled  = { status === RequestStatus.PROGRESS }
                        filters   = { filters }
                        onChange  = { this.onChangeFilter }
                        value     = { find ? find.id : defaultFilter }
                    />
                );
            }
        }
        return null ;
    };

    getPreloader = () =>
    {
        const { status } = this.state ;
        let { preloader } = this.props ;
        if( preloader && status === RequestStatus.PROGRESS )
        {
            preloader = (
                <div className='flex items-center justify-center w-full py-20'>
                    { preloader }
                </div>
            );
        }
        else
        {
            preloader = null ;
        }
        return preloader ;
    };

    getSubtitle = () => null ;

    getTitle = () =>
    {
        const { lang } = this.props ;

        const { medias } = this.state ;

        if( medias )
        {
            const { object } = medias ;
            if( object )
            {
                let thing = new Thing(object);
                return thing.getLocaleName(lang);
            }
        }

        const locale = this.getLocale() ;

        if( locale )
        {
            const { title } = locale ;
            return title || '' ;
        }

        return '' ;
    };

    load = ( offset = 0 , options = null ) =>
    {
        this.unselectAll() ;
        const { status } = this.state ;
        if( status !== RequestStatus.PROGRESS )
        {
            let {
                apiUrl,
                limit,
                facets,
                queries,
                search,
                sort
            } = this.props ;

            if( !(isString(facets)) || facets === '' )
            {
                facets = null ;
            }

            if( !(isString(sort)) || sort === '' )
            {
                sort = null ;
            }

            this.setState({
                offset,
                status : RequestStatus.PROGRESS,
                ...options
            }) ;

            this.canceler = LIST( apiUrl + this.getCurrentPath() ,
            {
                facets,
                limit,
                offset,
                queries,
                search,
                sort,
                cancel  : this._cancel,
                fail    : this._fail,
                random  : true,
                success : this._success
            });
        }
    };

    next = () =>
    {
        this.nextTimer = setTimeout( () =>
        {
            const { count, hasMore } = this.state ;
            if( hasMore )
            {
                this.load( count );
            }
        } , 200 ) ;
    };

    onChangeFilter = filter =>
    {
        if( filter )
        {
            const { value } = filter ;
            const { setSearch } = this.props ;
            if( setSearch instanceof Function )
            {
                setSearch( { facets:value } );
            }
        }
    };

    onEmbedMedia = item =>
    {
        const { medias } = this.state ;
        if( item && medias instanceof Array && medias.length > 0)
        {
            item = createMediaObject(item) ;
            if( item )
            {
                medias.unshift( item ) ; // insert at 0
                if( this._mounted )
                {
                    this.setState({ medias } ) ;
                }
            }
        }
    };

    onMedia = item =>
    {
        const { medias } = this.state ;
        if( medias instanceof Array && medias.length > 0)
        {
            let find = medias.find( ( element ) => element.id === item.id ) ;
            if( find instanceof CreativeWork )
            {
                find.clear()
                    .set(item);
                if( this._mounted )
                {
                    this.forceUpdate();
                }
            }
        }
    };

    onRemove = value =>
    {
        const { clearDialog } = this.props ;
        if( clearDialog instanceof Function )
        {
            clearDialog() ;
        }

        if( isNumber( value ) )
        {
            value = [ value ] ;
        }
        else if( isString(value) )
        {
            value = value.split(',') ;
        }

        if( value instanceof Array && value.length > 0 )
        {
            let { count, medias, total } = this.state ;

            if( medias instanceof Array && medias.length > 0 )
            {
                medias = medias.filter( item =>
                {
                    if( item )
                    {
                        const { id } = item ;
                        if( id)
                        {
                            return !value.find( key => parseInt(key) === id ) ;
                        }
                    }
                    return false ;
                });
                count = medias.length  ;
            }

            if( value instanceof Array )
            {
                total -= value.length ;
            }
            else if ( value )
            {
                total-- ;
            }

            const hasMore = count < total ;

            const options = init({
                count,
                hasMore,
                medias,
                total
            });

            const { unselectAll } = this.props ;
            if( unselectAll instanceof Function )
            {
                unselectAll();
            }

            this._refresh( medias ) ;

            this.setState({ ...options });
        }
    };

    onUpload = entries =>
    {
        if( entries instanceof Array && entries.length > 0 )
        {
            const elements = [] ;

            entries.forEach( entry =>
            {
                let { id, name, result, status } = entry ;
                if( status === UploadStatus.UPLOADED )
                {
                    if( result )
                    {
                        elements.push( createMediaObject(result)  );
                    }
                    else
                    {
                        console.log( this + 'onUpload failed with an empty result from the server with the entry ' + name , id );
                    }
                }
            });

            let num = elements.length;
            if( num > 0 )
            {
                let { count, medias, total } = this.state ;

                medias = [ ...elements , ...medias ];

                count += num ;
                total += num ;

                const hasMore = count < total ;

                const options = init({
                    count,
                    hasMore,
                    medias,
                    total
                });

                const { unselectAll } = this.props ;
                if( unselectAll instanceof Function )
                {
                    unselectAll();
                }
                this._refresh( medias ) ;

                this.setState({ ...options });
            }
        }
    };

    hasNext = () =>
    {
         const { hasMore, status } = this.state ;
         return hasMore && status === RequestStatus.SUCCESS ;
    };

    populate = datas =>
    {
        if( datas instanceof Array && datas.length > 0 )
        {
            return datas.map( createMediaObject ) ;
        }
        return datas ;
    };

    reload = () =>
    {
        this.load( 0, init() ) ;
    };

    render = () =>
    {
        const {
            classes,
            className,
            location,
            selectable,
            selectedItems,
            toggleSelected
        } = this.props;

        let { status } = this.state ;

        // status = RequestStatus.FAIL ;

        if( status === RequestStatus.REVOKED )
        {
            return <Redirect to={{ pathname: "/welcome" , state: { referrer: location } }} />;
        }
        else if ( status === RequestStatus.FAIL )
        {
            const { failed } = this.getLocale() ;
            return (
            <div className={ classes.fail } >
                <BugReportIcon
                    color = 'action'
                    style = {{ fontSize:60 }}
                />
                {
                    isString(failed) &&
                    <Typography
                        color   = 'inherit'
                        variant = 'button'
                    >
                        { failed }
                    </Typography>
                }
            </div>) ;
        }

        const { medias } = this.state ;

        return(
            <div className={ clsx(classes.root, className) } >
                <ScrollContainer
                    className      = { classes.container }
                    onScroll       = { this.onScroll }
                    onScrollBottom = { this.next }
                    onScrollFinish = { this.onScrollFinish }
                    onScrollStart  = { this.onScrollStart }
                    onScrollTop    = { this.onScrollTop }
                    scrollable     = { true }
                    ref            = { ref => this.scroller = ref }
                >
                    { this.getHeader() }
                    { this.getFilterBar() }

                    <Gallery
                        info          = { true }
                        medias        = { medias }
                        selectedItems = { selectedItems }
                        selectable    = { selectable }
                        onClick       = { this.openMediaDialog }
                        onInfo        = { media => this.openMediaDialog( media ) }
                        onSelect      = { ( selected , media ) =>
                        {
                            if( toggleSelected instanceof Function )
                            {
                                toggleSelected( media , 'id' )
                            }
                        }}
                    />
                    { this.getPreloader() }
                </ScrollContainer>
                { this.getOptions() }
            </div>
        )
    };

    selectRemove = () =>
    {
        this.openRemoveDialog( this.props.selectedItems ) ;
    };


    unmount = () =>
    {
        clearTimeout(this.scrollFinish);
        clearTimeout(this.timeout);

        const { status } = this.state ;
        if( status === RequestStatus.PROGRESS && !!(this.canceler) )
        {
            this.canceler.cancel( this + ' cancel' ) ;
        }

        const {
            clearDialog,
            selectReset,
            resetSearch
        }
        = this.props ;

        if( selectReset instanceof Function )
        {
            selectReset();
        }

        if( resetSearch instanceof Function )
        {
            resetSearch() ;
        }

        if( clearDialog instanceof Function )
        {
            clearDialog() ;
        }
    };

    // ---------- scroll

    onScroll = () => { this.setState({ dialHidden:true }); };

    onScrollFinish = () =>
    {
        clearTimeout( this.scrollFinish ) ;
        this.scrollFinish = setTimeout( () =>
        {
            if( this._mounted )
            {
                this.setState({ dialHidden:false });
            }
        },500 ) ;
    };

    onScrollStart = () =>
    {
        clearTimeout( this.scrollFinish ) ;
        this.setState({ dialHidden:true });
    };

    onScrollTop = () =>
    {
        //this.setState( { enforceDial:true } ) ;
    };

    // ---------- speed dial

    closeSpeedDial = () =>
    {
        this.setState({ dialOpen:false });
    };

    openSpeedDial = () =>
    {
        this.setState({ dialOpen:true });
    };

    // ---------- datas

    _cancel = message =>
    {
        console.log( this + ' cancel : ' + message , true ) ;
    };

    _fail = (response ) =>
    {
        if( response )
        {
            const { data } = response ;
            if( data )
            {
                const { message } = data ;
                switch( message )
                {
                    case 'token revoked' :
                    {
                        if( this.mounted )
                        {
                            this.setState( { status:RequestStatus.REVOKED } ) ;
                        }
                        break ;
                    }
                    default :
                    {
                        console.log( this + " failed, status:" + response.status + ", message:" + data.message , true );
                    }
                }
            }
        }

        if( this.mounted )
        {
            this.setState( { status:RequestStatus.FAIL } ) ;
        }
    };

    _refresh = medias =>
    {
        const {
            selectPolicy,
            setSelect
        } = this.props ;

        if( setSelect instanceof Function )
        {
            const selectable = (selectPolicy !== 'none') && this.isEditable() ;
            setSelect({
                selectable ,
                selectableItems : medias instanceof Array ? medias : [] }
            );
        }
    };

    _success = response =>
    {
        if( this.mounted && response )
        {
            const { data } = response ;
            if( data )
            {
                const status = RequestStatus.SUCCESS ;
                let { result, total } = data ;

                let { medias } = this.state ;

                let count = medias instanceof Array ? medias.length : 0 ;

                if( result instanceof Array && result.length > 0 )
                {
                    count  += result.length ;
                    result = this.populate(result);
                    medias = [ ...medias, ...result ];
                }

                const hasMore = count < total ;

                this.setState({ count, hasMore, medias, status, total }) ;

                this._refresh(medias) ;

                if( this.scroller )
                {
                    const {
                        clientHeight,
                        scrollHeight
                    }
                    = this.scroller.element ;

                    //console.log( this + ' success' , clientHeight , scrollHeight) ;

                    if( this.hasNext() && (clientHeight === scrollHeight) )
                    {
                        this.next() ;
                    }
                }
                return ;
            }
        }
        this.setState( { status:RequestStatus.FAIL } ) ;
    };
}

MediaContainer.defaultProps =
{
    ...DialogContainer.defaultProps ,
    actions               : actions,
    apiUrl                : api.url,
    autoload              : true,
    back                  : false ,
    backTooltip           : null ,
    className             : null,
    defaultIcon           : null ,
    embeddable            : true,
    iconButton            : false ,
    iconClick             : null ,
    iconTooltip           : null ,
    limit                 : 20 ,
    onBack                : null ,
    parent                : null ,
    preloader             : <Preloader color={blueGrey[500]}/>,
    queries               : { sort : '-created,name' } ,
    reload                : false ,
    RemoveDialogComponent : RemoveMediaObjectDialog ,
    resizable             : false ,
    selectPolicy          : 'normal',
    speedDial             :
    {
        direction : 'left' ,
        icon      : <SpeedDialIcon icon={ <MoreVertIcon /> } openIcon={ <CloseIcon /> }/>
    },
    speedDialAction : { tooltipPlacement : 'left' },
    sortable        : true,
    uploadable      : true ,
    uploadMultiple  : true ,
    uploadUri       : api.mediaObjects.url,
    uri             : api.mediaObjects.url
};

MediaContainer.propTypes =
{
    ...DialogContainer.propTypes ,
    actions       : PropTypes.array,
    apiUrl        : PropTypes.string.isRequired,
    autoload      : PropTypes.bool,
    back          : PropTypes.bool,
    backTooltip   : PropTypes.string ,
    classes       : PropTypes.object.isRequired,
    className     : PropTypes.oneOfType([PropTypes.string,PropTypes.arrayOf(PropTypes.string)]),
    defaultIcon   : PropTypes.element,
    embeddable    : PropTypes.bool,
    iconButton    : PropTypes.bool ,
    iconClick     : PropTypes.func ,
    iconTooltip   : PropTypes.string ,
    limit         : PropTypes.number ,
    onBack        : PropTypes.func,
    parent        : PropTypes.object,
    preloader     : PropTypes.element,
    queries       : PropTypes.object,
    reload        : PropTypes.bool ,
    resizable     : PropTypes.bool ,
    search        : PropTypes.string,
    selectPolicy  : PropTypes.oneOf(['none','normal']),
    setSearch     : PropTypes.func,
    speedDial     : PropTypes.shape({ direction:PropTypes.string , icon:PropTypes.element }) ,
    speedDialIcon : PropTypes.element,
    sortable      : PropTypes.bool,
    uploadable    : PropTypes.bool
};

export default compose (
    withWidth(),
    withStyles( styles ),
    withRouter,
    withDialogs,
    withLang,
    withLocale,
    withSearch,
    withSelect
)
( MediaContainer );
