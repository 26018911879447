import list from './list'

const organizations =
{
    ...list,
    helmet :
    {
        title : 'Organisations' ,
        metas : []
    },
    empty  : 'Aucune organisation trouvée',
    search : 'Rechercher une organisation ...' ,
    title  : 'Organisations'
};

export default organizations ;
