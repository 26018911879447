import React            from 'react'

import ArticleIcon      from '@material-ui/icons/Notes'

import api              from '../../../../configs/api'

import Form   from '../../../../display/forms/FormElements'
import Method from '../../../../net/Method';

import CreativeWork     from '../../../../things/CreativeWork'

const elements =
[
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'name',
            label        : 'Nom du sujet',
            autoFocus    : true,
            fullWidth    : true,
            helperText   : 'Obligatoire',
            margin       : 'normal',
            required     : true,
            select       : false,
            autoComplete : 'off' ,
            type         : 'text',
            variant      : 'outlined'
        }
    }
];

const elementsChild =
[
    {
        type  : Form.SEARCH_SELECTOR ,
        path  : api.articles.url ,
        clazz : CreativeWork ,
        init  :
        {
            id         : 'child',
            key        : 'child',
            label      : 'Article',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            required   : true,
            type       : 'text',
            variant    : 'outlined'
        },
        searchIcon : <ArticleIcon/>
    }
];

const elementsEdit =
[
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'name',
            label        : 'Nom du sujet',
            autoFocus    : true,
            fullWidth    : true,
            helperText   : 'Obligatoire',
            margin       : 'normal',
            required     : true,
            select       : false,
            autoComplete : 'off' ,
            type         : 'text',
            variant      : 'outlined'
        }
    },
    {
        type : Form.I18N_EDITOR ,
        init :
        {
            id       : 'headline',
            key      : 'headline',
            input    : true,
            label    : 'Titre'
        }
    },
    {
        type : Form.I18N_EDITOR ,
        init :
        {
            id       : 'alternativeHeadline',
            key      : 'alternativeHeadline',
            input    : true,
            label    : 'Sous-titre'
        }
    },
    {
        type : Form.I18N_EDITOR ,
        init :
        {
            id       : 'description',
            key      : 'description',
            input    : true,
            label    : 'Description'
        }
    },
    {
        type : Form.I18N_EDITOR ,
        init :
        {
            id       : 'text',
            key      : 'text',
            html     : true,
            input    : true,
            label    : 'Texte'
        }
    },
    {
        type : Form.I18N_EDITOR ,
        init :
        {
            id       : 'notes',
            key      : 'notes',
            html     : true,
            input    : true,
            label    : 'Note'
        }
    }
];

const topics =
{
    helmet :
    {
        title : 'Sujets' ,
        metas : []
    },
    add :
    {
        elements,
        agree       : 'Créer' ,
        disagree    : 'Annuler' ,
        title       : 'Ajouter un sujet' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour créer une nouvelle fiche.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en  cours...' ,
        success     : 'Ajout du nouvel enregistrement réussi!'
    },
    addChild :
    {
        elements    : elementsChild,
        agree       : 'Ajouter' ,
        disagree    : 'Annuler' ,
        title       : 'Ajouter un article' ,
        description : 'Veuillez selectionner un article.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en  cours...' ,
        success     : 'Ajout du nouvel enregistrement réussi!'
    },
    edit :
    {
        elements    : elementsEdit,
        agree       : 'Sauver' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour modifier ce sujet.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...' ,
        success     : 'Mise à jour réussie!' ,
        title       : 'Modifier le sujet' ,
    },
    failed     : 'Problème de connexion',
    loading    : "Chargement ..." ,
    noDataText : 'Aucun résultat' ,
    remove     :
    {
        agree        : 'Supprimer' ,
        description  : 'Souhaitez-vous supprimer l\'enregistrement:' ,
        descriptionX : 'Souhaitez-vous supprimer les {0} enregistrements:' ,
        disagree     : 'Annuler' ,
        loading      : 'Suppression en cours...' ,
        title        : 'Supprimer cet élément ?',
        titleX       : 'Supprimer des éléments ?',
        useID        : false,
        useUrl       : false
    },
    removeIcon :
    {
        remove :
        {
            agree        : 'Supprimer' ,
            description  : 'Souhaitez-vous supprimer l\'image:' ,
            descriptionX : 'Souhaitez-vous supprimer les images:' ,
            disagree     : 'Annuler' ,
            loading      : 'Suppression en cours...' ,
            mock         : false,
            title        : 'Supprimer cette image ?',
            titleX       : 'Supprimer ces images ?',
            useID        : false
        },
        tooltip : 'Supprimer l\'image'
    },
    removeItem  : "Supprimer un élément" ,
    removeItemX : "Supprimer {0} éléments" ,
    tooltips    :
    {
        add        : 'Ajouter' ,
        activate   : 'Activer' ,
        deactivate : 'Désactiver' ,
        edit       : 'Modifier',
        remove     : 'Supprimer',
        uploadIcon  : 'Ajouter une image'
    },
    title  : 'Sujets',
    upload :
    {
        agree        : 'Envoyer',
        disagree     : 'Fermer',
        filePropName : 'file' , // important to send the picture with the API
        method       : Method.POST,
        mock         : false ,
        text         : 'Déposer un fichier dans la médiathèque.',
        title        : 'Téléverser une image'
    }
};

export default topics ;