import React, { Component } from 'react';

import isString from 'vegas-js-core/src/isString'

import PropTypes  from 'prop-types'

import { withStyles } from '@material-ui/core/styles'

import { Divider, Link, Typography } from '@material-ui/core'

import Badge from '../badges/Badge'

const styles = () => ({});

class TitleHeader extends Component
{
    getBadge = () =>
    {
        const { badge } = this.props ;
        if( badge )
        {
            return (
            <div className='font-light'>
                <Badge color='gray'>{ badge }</Badge>
            </div>
            ) ;
        }
        return null ;
    };

    getIcon = () =>
    {
        const { icon } = this.props ;
        if( icon )
        {
            return <div className='mr-12'>{ icon }</div> ;
        }
        return null ;
    };

    getLabel = () =>
    {
        const {
            editable,
            label,
            LinkOptions
        } = this.props ;
        if( isString(label) )
        {
            if( editable )
            {
                return(
                    <Link
                        className = "font-medium mr-12 cursor-pointer font-blue"
                        variant   = "subtitle1"
                        underline = 'none'
                        href      = { null }
                        onClick   = { this.props.onClick }
                        { ...LinkOptions }
                    >
                        { label }
                    </Link>
                );
            }
            else
            {
                return(
                    <Typography
                        className = "font-medium mr-12"
                        variant   = "subtitle1"
                    >
                        { label }
                    </Typography>
                );
            }
        }
        return null ;
    };

    getOptions = () => this.props.options ;

    getSubLabel = () => this.props.subLabel ;

    render()
    {
        const {
            className,
            divider,
            style
        } = this.props;
        return (
            <div
                className = {className}
                style     = {style}
            >
                <div className="flex flex-row items-center justify-between">
                    <div className="flex flex-row items-center">
                        { this.getIcon()  }
                        <div className="flex flex-col">
                        { this.getLabel() }
                        { this.getSubLabel() }
                        </div>
                        { this.getBadge() }
                    </div>
                    { this.getOptions() }
                </div>
                { divider }
            </div>
        );
    }
}

TitleHeader.defaultProps =
{
    badge       : null ,
    className   : null ,
    divider     : <Divider light className="mt-16" /> ,
    editable    : false ,
    icon        : null ,
    label       : null ,
    LinkOptions : null ,
    onClick     : null ,
    options     : null ,
    subLabel    : null ,
    style       : null
};

TitleHeader.propTypes =
{
    badge       : PropTypes.node,
    classes     : PropTypes.object.isRequired,
    className   : PropTypes.string,
    divider     : PropTypes.element,
    editable    : PropTypes.bool,
    icon        : PropTypes.element,
    label       : PropTypes.string,
    LinkOptions : PropTypes.object,
    onClick     : PropTypes.func,
    options     : PropTypes.element,
    subLabel    : PropTypes.element,
    style       : PropTypes.object
};

export default withStyles( styles ) ( TitleHeader ) ;
