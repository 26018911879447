import React from 'react'

import clsx from 'clsx'

import PropTypes from 'prop-types'

import isNotNull from 'vegas-js-core/src/isNotNull'
import notEmpty  from 'vegas-js-core/src/strings/notEmpty'
import trimEnd   from 'vegas-js-core/src/strings/trimEnd'

import {
    AppBar,
    Badge,
    Paper,
    Tab,
    Tabs,
    Typography
} from '@material-ui/core'

import { isWidthUp } from '@material-ui/core/withWidth'

import {
    Redirect ,
    Route,
    Switch
} from 'react-router-dom'

import RequestStatus from '../../net/RequestStatus'

import View from './View'

class TabsView extends View
{
    constructor( props )
    {
        super( props ) ;
        this.canceler = null ;
        this.state  =
        {
            ...this.state ,
            status : RequestStatus.NEW
        }
    }

    build = () =>
    {
        const {
            location ,
            first ,
            header
        } = this.props ;

        const { status } = this.state ;
        if( status === RequestStatus.REVOKED )
        {
            return <Redirect to={{ pathname: "/welcome" , state: { referrer:location } }} />;
        }

        const path = this.getPath() || {} ;

        let { url:uri } = path ;

        uri = this.populateUri( uri ) ;

        let { pathname } = location || {} ;

        let route ;

        if( notEmpty(pathname))
        {
            route = pathname.split('/') ;
            route.shift() ;
            route = '/' + route.join("/") ;

            pathname = trimEnd( pathname , ['/'] ) ;

            // if( this.props.verbose )
            // {
            //     console.log( this + ' build #1 ------ ', 'uri:' + uri, 'pathname:' + pathname ) ;
            // }

            if( pathname === uri && notEmpty(first) )
            {
                return <Redirect to={ uri + '/' + first } />
            }
        }

        return (
        <div className='flex flex-col w-full'>
            <div className='flex flex-col'>
                { header && this.getHeader() }
                { this.getTabs( uri , route ) }
            </div>
            <div className='flex flex-1'>
                { this.getRoutes( uri , path ) }
            </div>
        </div>);
    };

    getBadgeValue = ( { id , badge } = {} ) =>
    {
        if( badge )
        {
            if( badge instanceof Function )
            {
                badge = badge( { id , ref:this } ) ;
            }
            return badge ;
        }
        return null ;
    }

    getHeader = () => null ;

    getPath = () => this.props.path ;

    getRouteElement = () => null ;

    getRoutePath = path => path ;

    getRoutes = ( uri , path ) =>
    {
        if( this.showContent() )
        {
            let { routes } = this.props ;
            if( routes instanceof Array && routes.length > 0 )
            {
                routes = routes.map( entry =>
                {
                    if( entry )
                    {
                        let {
                            id,
                            component ,
                            Component ,
                            exact = false ,
                            factory ,
                            path : p ,
                            RouteComponent,
                            routePath,
                            routePaths,
                            sensitive = false ,
                            strict    = false ,
                            validator ,
                            ...options
                        } = entry ;

                        // Special mapping of the special properties in the route component
                        // ex: the addUri property must be formatted with the current thing.id value
                        if( routePaths )
                        {
                            for( let key in routePaths )
                            {
                                if( routePaths.hasOwnProperty(key) )
                                {
                                    routePaths[key] = this.getRoutePath(routePaths[key]) ;
                                }
                            }
                        }

                        let url = notEmpty(p) ? p : ( uri + '/' + id ) ;

                        if( this.validate( id , validator ) )
                        {
                            let render ;
                            if( RouteComponent )
                            {
                                render = () => this.getRouteElement(
                                {
                                    RouteComponent ,
                                    id ,
                                    path ,
                                    uri : this.getRoutePath( notEmpty(routePath) ? routePath : url ) ,
                                    ...options
                                }) ;
                            }
                            else if( notEmpty(factory) && this[factory] instanceof Function )
                            {
                                render = () => this[factory]( path , options ) ;
                            }
                            else if( factory instanceof Function )
                            {
                                render = factory( this , path , options ) ;
                            }
                            else if( Component )
                            {
                                render = () => (
                                    <Component
                                        className = 'p-12'
                                        parent    = { this.element }
                                        { ...options }
                                    />
                                );
                            }

                            return (
                                <Route
                                    component = { component }
                                    exact     = { exact }
                                    key       = { id }
                                    path      = { this.getRoutePath( url ) }
                                    render    = { render }
                                    sensitive = { sensitive }
                                    strict    = { strict }
                                />
                            );
                        }
                    }
                    return null ;
                })
                .filter( isNotNull )
            }

            if( routes instanceof Array && routes.length > 0 )
            {
                return <Switch>{ routes }</Switch>
            }
        }
        return null ;
    };

    getTabIcon = ( { icon } = {} ) => icon ;

    getTabItem = uri => ( { badge , icon , id , label , validator } = {} ) =>
    {
        if( this.validate( id , validator ) )
        {
            let options = this.getTabOptions({ id }) ;

            const { breakpoint , responsive , width } = this.props ;
            if( responsive )
            {
                if( isWidthUp( breakpoint , width ) )
                {
                     icon  = null ;
                     label = this.getTabLabel( { badge , id , label } ) ;
                }
                else
                {
                    icon  = this.getTabIcon( { badge , id , icon } ) ;
                    if( !icon )
                    {
                        label = this.getTabLabel( { badge , id , label } ) ;
                    }
                    else
                    {
                        label = null ;
                    }
                }
            }
            else
            {
                icon  = this.getTabIcon( { id , icon } ) ;
                label = this.getTabLabel( { badge , id , label } ) ;
            }

            let {
                tabClassName,
                TabComponent,
                tabProps
            } = this.props ;

            TabComponent = TabComponent || Tab ;

            options = { ...options , ...tabProps } ;

            const value = this.getTabUri( { id , uri } ) ;

            return (
                <TabComponent
                    aria-label = { id }
                    className  = { clsx( 'px-8' , tabClassName ) }
                    key        = { 'tab-' + id }
                    icon       = { icon }
                    label      = { label }
                    value      = { value  }
                    { ...options }
                />
            );
        }
        return null ;
    } ;

    getTabLabel = ( { badge , id , label } = {} ) =>
    {
        if( notEmpty( label ) )
        {
            // do nothing
        }
        else if( id )
        {
            const { tabs:labels = {} } = this.getLocale() || {} ;
            if( labels && labels.hasOwnProperty(id) && notEmpty(labels[id]) )
            {
                label = labels[id] ;
            }
        }
        else
        {
            label = null ;
        }

        if( notEmpty(label) )
        {
            const {
                labelClassName ,
                labelOptions
            } = this.props ;

            label = (
                <Typography
                    className = { clsx( 'font-semibold px-12' , labelClassName ) }
                    variant   = 'caption'
                    { ...labelOptions }
                >
                    { label }
                </Typography>
            )

            badge = this.getBadgeValue( { id , badge } ) ;
            if( badge !== null )
            {
                const { badgeOptions } = this.props ;
                label = (
                    <Badge
                        badgeContent = { badge }
                        classes      = {{ badge:'text-base' }}
                        color        = 'primary'
                        overlap      = 'rectangle'
                        showZero     = { true }
                        { ...badgeOptions }
                    >
                        { label }
                    </Badge>
                )
            }

            return label ;
        }

        return null ;
    };

    getTabOptions = () => ({ classes : { wrapper:'inline-flex flex-row items-center justify-center px-16 w-full' } }) ;

    getTabUri = ( { id , uri } = {} ) => uri + '/' + id ;

    getTabs = ( uri , route ) =>
    {
        if( this.showContent() )
        {
            let { tabs } = this.props ;
            if( tabs instanceof Array && tabs.length > 0 )
            {
                if( notEmpty(uri) && notEmpty(route) )
                {
                    route = route.split( uri + '/' )[1] ;
                }

                const find = tabs.find( element => route.startsWith(element.id) ) ;
                if( find )
                {
                    tabs = tabs.map( this.getTabItem(uri) ).filter( isNotNull ) ;
                    if( tabs instanceof Array && tabs.length > 0 )
                    {
                        let {
                            containerClassName,
                            ContainerComponent,
                            containerProps,
                            location
                        } = this.props ;

                        const Container = ContainerComponent || Paper ;

                        if( Container === Paper )
                        {
                            containerProps = { square:true , ...containerProps } ;
                        }
                        else if( Container === AppBar )
                        {
                            containerProps = {
                                color    : 'transparent' ,
                                position :'static' ,
                                ...containerProps
                            } ;
                        }

                        const { ariaLabels : { tabs:aria = '' } = {} } = this.getLocale() || {} ;

                        let route = location.pathname.split('/') ;

                        route.shift() ;
                        route = "/" + route.join("/") ;

                        return (
                            <Container
                                className = { clsx( 'w-full' , containerClassName ) }
                                { ...containerProps }
                            >
                                <Tabs
                                    aria-label     = { aria }
                                    indicatorColor = 'primary'
                                    onChange       = { this.onChangeTabs }
                                    scrollButtons  = 'desktop'
                                    textColor      = 'primary'
                                    value          = { uri + '/' + find.id }
                                    variant        = 'scrollable'
                                >
                                    { tabs }
                                </Tabs>
                            </Container>
                        );
                    }
                }
            }
        }
        return null  ;
    };

    init = () =>
    {
        let options = null ;
        const { location } = this.props ;
        if( location )
        {
            const { state } = location ;
            if( state )
            {
                const { backOptions, backPath } = state ;
                options =
                {
                    ...options,
                    ...(backOptions !== null) && { backOptions },
                    ...(backPath    !== null) && { backPath    }
                } ;
            }
        }

        if( options )
        {
            this.setState( { ...options } /*, () => console.log( this + " init OK" , this.state , state ) */) ;
        }

    };

    populateUri = uri => uri ;

    onChangeTabs = ( event , value) =>
    {
        this.props.history.push( value ) ;
    };

    showContent = () => true ;

    validate = ( id , validator ) =>
    {
        if( notEmpty(validator) && this.hasOwnProperty(validator) && this[validator] instanceof Function )
        {
            return this[validator]( id ) ;
        }
        else if( validator instanceof Function )
        {
            return validator( id , this ) ;
        }
        else
        {
            return true ;
        }
    }
}

TabsView.defaultProps =
{
    ...View.defaultProps ,
    breakpoint         : 'sm' ,
    ContainerComponent : AppBar ,
    first              : 'grid' ,
    header             : true,
    path               : { url : '' },
    responsive         : false ,
    routes             : [],
    TabComponent       : Tab ,
    tabs               : []
};

TabsView.propTypes =
{
    ...View.propTypes ,

    badgeOptions       : PropTypes.object,
    breakpoint         : PropTypes.oneOf(['xs','sm','md','lg','xl']) ,
    containerClassName : PropTypes.string,
    ContainerComponent : PropTypes.object,
    containerProps     : PropTypes.object,
    first              : PropTypes.string,
    header             : PropTypes.bool,
    labelClassName     : PropTypes.string,
    labelOptions       : PropTypes.object,
    path               : PropTypes.object,
    responsive         : PropTypes.bool,
    routeClassName     : PropTypes.string,
    routes             : PropTypes.arrayOf( PropTypes.shape(
    {
        id        : PropTypes.string,
        member    : PropTypes.elementType ,
        component : PropTypes.elementType ,
        Component : PropTypes.elementType ,
        factory   : PropTypes.oneOfType([PropTypes.func,PropTypes.string]),
        path      : PropTypes.string,
        validator : PropTypes.oneOfType([PropTypes.func,PropTypes.string])
    })) ,
    tabClassName : PropTypes.string,
    TabComponent : PropTypes.elementType,
    tabProps     : PropTypes.object,
    tabs         : PropTypes.arrayOf( PropTypes.shape(
    {
        badge     : PropTypes.oneOfType([PropTypes.func,PropTypes.string,PropTypes.element]),
        icon      : PropTypes.element,
        id        : PropTypes.string,
        label     : PropTypes.string,
        validator : PropTypes.oneOfType([PropTypes.func,PropTypes.string])
    }))
};

export default TabsView;
