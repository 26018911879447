import React from "react"

import AboutIcon   from '@material-ui/icons/Place'
import EventsIcon  from '@material-ui/icons/DateRange'
import InfoIcon    from '@material-ui/icons/Info'
import MediasIcon  from '@material-ui/icons/PermMedia'
import ObjectsIcon from '@material-ui/icons/Category'
import PlaceIcon   from '@material-ui/icons/Place'

import ThingView , { defaultStyle } from '../../display/views/ThingView'

import About                 from './views/About'
import ConceptualObjectsList from '../../display/lists/ConceptualObjectsList'
import EventsList            from '../../display/lists/EventsList'
import Info                  from './views/Info'
import Medias                from '../medias/Medias'

import PlaceVO from '../../things/Place'
import Thing   from '../../things/Thing'

import api   from '../../configs/api'
import paths from '../../configs/paths'

import initView from '../../display/views/initView'

const styles = defaultStyle();

const getQueries = ( { props } = {} ) =>
{
    let { queries, thing } = props ;
    queries = queries || {} ;
    if( thing instanceof Thing )
    {
        let { facets } = queries ;
        if( facets )
        {
            facets = JSON.parse(facets) ;
        }
        facets = { ...facets , location:thing.id } ;
        return { ...queries , facets : JSON.stringify(facets) }  ;
    }
    return queries ;
}

const reload = () =>
{
    window.location.reload();
}

class Place extends ThingView
{
    getLocale = () => this.props.locale.views.place ;
}

Place.defaultProps =
{
    ...ThingView.defaultProps,
    back       : true ,
    clazz      : PlaceVO ,
    icon       : <PlaceIcon/>,
    path       : api.places,
    preferable : true ,
    responsive : true ,
    routes     :
    [
        {
            id             : 'about' ,
            RouteComponent : About
        } ,
        {
            id             : 'info' ,
            RouteComponent : Info
        } ,
        {
            id             : 'medias' ,
            RouteComponent : Medias
        } ,
        {
            id             : 'events' ,
            RouteComponent : EventsList ,
            uri            : api.events.url ,
            onRemove       : reload,
            getQueries
        },
        {
            id             : 'conceptualObjects' ,
            RouteComponent : ConceptualObjectsList,
            uri            : api.conceptualObjects.url ,
            onRemove       : reload ,
            getQueries
        }
    ],
    tabs :
    [
        { id : 'about'             , icon : <AboutIcon   className='pr-8' /> } ,
        { id : 'info'              , icon : <InfoIcon    className='pr-8' /> } ,
        { id : 'medias'            , icon : <MediasIcon  className='pr-8' /> } ,
        { id : 'events'            , icon : <EventsIcon  className='pr-8' /> } ,
        { id : 'conceptualObjects' , icon : <ObjectsIcon className='pr-8' /> }
    ] ,
    titleAuto : false // Important to use always the getTitle() method
};

Place.propTypes =
{
    ...ThingView.propTypes,
    path : paths.places
};

export default initView( Place, styles );
