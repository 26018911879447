import axios from "axios"

import URI from 'urijs'

import auth from '../configs/auth'

import tokensManager from "../vo/TokensManager"

import connect        from './auth/connect'
import getAccessToken from './auth/getAccessToken'
import GrantType      from './auth/GrantType'

const abort   = message => console.log( 'DELETE failed' , message ) ;
const timeout = process.env.REACT_APP_UPLOAD_TIMEOUT ;

const DEFAULT_OPTIONS =
{
    accessToken : null ,
    api         : null ,
    cancel      : null ,
    canceler    : null ,
    datas       : null ,
    fail        : null ,
    success     : null ,
    timeout     : timeout
};

/**
 * Delete a record with a webservice API.
 * @param uri {string} The uri of the API method.
 * @param {Null|object} [options=null] - The configuration object to set the request.
 * @param {Object} [options.accessToken=null] - The optional accessToken object.
 * @param {Object} [options.api=null] - The optional api object.
 * @param {Function} [options.cancel=null] - A callback method invoked when the service is canceled.
 * @param {CancelTokenSource} [options.canceler=null] - The canceler of the request.
 * @param {Object} [options.datas=null] - The optional object to send datas to the API method.
 * @param {Function} [options.fail=null] - A callback function invoked when the request failed.
 * @param {Function} [options.success] - A callback function invoked when the request succeed.
 * @param {int} [options.timeout=18000] - Indicates the delay in ms to timeout the request.
 */
export default function DELETE( uri , options )
{
    const params = { ...DEFAULT_OPTIONS , ...( !!(options) && options ) } ;

    let {
        accessToken,
        api,
        cancel,
        canceler,
        datas,
        fail,
        success,
        timeout
    }
    = params ;

    fail = fail instanceof Function ? fail : abort ;

    if( canceler === null )
    {
        canceler = axios.CancelToken.source() ;
    }

    if( api === null )
    {
        for( let prop in auth.apis )
        {
            if( auth.apis.hasOwnProperty( prop ) )
            {
                if( uri.startsWith( auth.apis[prop].url ) )
                {
                    api = auth.apis[prop] ;
                    break ;
                }
            }
        }

        if( !api )
        {
            fail( 'API definition not found with the uri: ' + uri );
            return canceler ;
        }
    }

    if( accessToken === null )
    {
        accessToken = tokensManager.read( api.name ) ; // get stored accessToken
    }

    if( !accessToken || accessToken.expired )
    {
        return getAccessToken(
        {
            accessToken,
            api,
            cancel,
            fail : response => fail(response), // test if the fail function is not null ?
            success : () => DELETE( uri ,
            {
                accessToken : null,
                api,
                cancel,
                canceler,
                datas,
                fail,
                success,
                timeout
            }),
            timeout
        });
    }

    const request = axios.create(
    {
        cancelToken : canceler.token,
        headers     :
        {
            'Authorization' : accessToken.token_type + ' ' + accessToken.access_token ,
            'Accept'        : 'application/json'
        },
        timeout
    });

    const data =
    {
        ...datas
    };

    uri = new URI(uri) ;

    console.log( 'DELETE(' + uri + ')' );

    request
    .delete( uri, { data } )
    .then( response =>
    {
        const { status } = response ;
        if( status === 200 )
        {
            try
            {
                if( success instanceof Function)
                {
                    success( response );
                }
            }
            catch( er )
            {
                if( fail instanceof Function)
                {
                    fail( response );
                }
            }
        }
        else if( fail instanceof Function)
        {
            fail( response );
        }
    })
    .catch( error =>
    {
        console.log( "DELETE fail" , error ) ;

        const { message, response, request } = error ;

        if ( axios.isCancel( error ) )
        {
            if( cancel instanceof Function )
            {
                cancel( error ) ;
            }
        }
        else if( response )
        {
            const { data } = response ;
            if( data )
            {
                const { message } = data ;
                if( message && ( message === 'token revoked' || message === 'Signature verification failed' ) )
                {
                    if( api.grant_type === GrantType.AUTHORIZATION_CODE )
                    {
                        tokensManager.dispose( api.name ) ; // remove accessToken
                        connect( api.name ) ;
                        return ;
                    }

                    return getAccessToken(
                    {
                        accessToken : accessToken,
                        api         : api,
                        cancel      : cancel,
                        fail        : response => fail(response),
                        success     : () => DELETE( uri ,
                        {
                            accessToken : null,
                            api,
                            cancel,
                            canceler,
                            datas,
                            fail,
                            success,
                            timeout
                        }),
                        timeout
                    });
                }
            }

            if( fail instanceof Function )
            {
                fail( response );
            }
        }
        else if( request )
        {
            if( fail instanceof Function )
            {
                fail( request );
            }
        }
        else if( fail instanceof Function )
        {
            fail( message );
        }
    });

    return canceler ;
}
