import generify from './generify'

import Elevation                 from './Elevation'
import OpeningHoursSpecification from './OpeningHoursSpecification'
import Step                      from './Step'
import Thing                     from './Thing'
import Transportation            from './Transportation'
import Word                      from './Word'

import populateDiscover from './populateDiscover'
import populateMedias   from './creativework/populateMedias'

/**
 * Entities that have a somewhat fixed, physical extension.
 * @memberOf things
 */
class Course extends Thing
{
    /**
     * Creates a new Step instance.
     * @constructor
     * @param object The generic object to initialize the object the first time.
     */
    constructor( object = null )
    {
        super() ;

        /**
         * An alternative headline of the item.
         * @type {String}
         */
        this.alternativeHeadline = null ;

        /**
         * The audience associated with this course
         * @type {Word}
         */
        this.audience = null ;

        /**
         * The audios medias of this course.
         * @type {Array}
         */
        this.audios = null;

        /**
         * The discover objects of this course.
         * @type {Array}
         */
        this.discover = null;

        /**
         * The distance of the course.
         *  @type {String}
         */
        this.distance = null ;

        /**
         * The elevation of the course.
         *  @type {Elevation/Object}
         */
        this.elevation = null ;

        /**
         * An headline of the item.
         *  @type {String}
         */
        this.headline = null ;

        /**
         * The level of the courses.
         *  @type {Word}
         */
        this.level = null ;

        /**
         * Indicates the number of audios medias of this thing.
         * @type {Array}
         */
        this.numAudios = null;

        /**
         * Indicates the number of videos medias of this thing.
         * @type {Array}
         */
        this.numPhotos = null;

        /**
         * Indicates the number of videos medias of this thing.
         * @type {Array}
         */
        this.numVideos = null;

        /**
         * The opening hours of the course.
         *  @type {Array/Object}
         */
        this.openingHoursSpecification = null ;

        /**
         * The path associated with this course
         *  @type {Word}
         */
        this.path = null ;

        /**
         * photos associated with this course.
         *  @type {Array}
         */
        this.photos = null ;

        /**
         * The status of the item.
         *  @type {Word}
         */
        this.status = null ;

        /**
         * steps of this course.
         *  @type {Array/Object}
         */
        this.steps = null ;

        /**
         * The transportations of the course.
         *  @type {Array/Object}
         */
        this.transportations = null ;

        /**
         * The videos medias of this course.
         * @type {Array}
         */
        this.videos = null;

        this.set( object ) ;
    }


    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        super.clear() ;
        this.alternativeHeadline =
        this.audience =
        this.audios =
        this.discover =
        this.distance =
        this.elevation =
        this.headline =
        this.level =
        this.numAudios =
        this.numPhotos =
        this.numVideos =
        this.openingHoursSpecification =
        this.path =
        this.photos =
        this.status =
        this.steps =
        this.transportations =
        this.videos = null ;
        return this ;
    }

    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new Course( this.toObject() ) ;
    }

    /**
     * Returns the locale alternativeHeadline expression of the thing.
     * @return {String} The locale headline of the thing.
     */
    getLocaleAlternativeHeadline = this.getLocaleProperty('alternativeHeadline') ;

    /**
     * Returns the locale headline expression of the thing.
     * @return {String} The locale headline of the thing.
     */
    getLocaleHeadline = this.getLocaleProperty('headline') ;

    /**
     * Populate the object with specific behaviors or types. Launched by default in the constructor and in the set methods.
     * @returns {Thing} The current reference.
     */
    populate()
    {
        const {
            additionalType,
            audience,
            elevation,
            level,
            path,
            openingHoursSpecification,
            status,
            steps,
            transportations
        } = this ;

        populateMedias( this , { useImage:false } ) ;

        if( additionalType && !(additionalType instanceof Word))
        {
            this.additionalType = new Word(additionalType) ;
        }

        if( audience && !(audience instanceof Word))
        {
            this.audience = new Word(audience) ;
        }

        if( elevation && !(elevation instanceof Elevation))
        {
            this.elevation = new Elevation(elevation) ;
        }

        if( level && !(level instanceof Word))
        {
            this.level = new Word(level) ;
        }

        if( path && !(path instanceof Word))
        {
            this.path = new Word(path) ;
        }

        if( status && !(status instanceof Word))
        {
            this.status = new Word(status) ;
        }

        if( openingHoursSpecification )
        {
            if( openingHoursSpecification instanceof Array )
            {
                this.openingHoursSpecification = openingHoursSpecification.map( item => item instanceof OpeningHoursSpecification ? item : new OpeningHoursSpecification(item) ) ;
            }
            else
            {
                this.openingHoursSpecification = new OpeningHoursSpecification( openingHoursSpecification ) ;
            }
        }

        if( steps )
        {
            if( steps instanceof Array )
            {
                this.steps = steps.map( item => item instanceof Step ? item : new Step(item) ) ;
            }
            else
            {
                this.steps = new Step( steps ) ;
            }
        }

        if( transportations )
        {
            if( transportations instanceof Array )
            {
                this.transportations = transportations.map( item => item instanceof Transportation ? item : new Transportation(item) ) ;
            }
            else
            {
                this.transportations = new Transportation( transportations ) ;
            }
        }

        populateDiscover( this ) ;

        return this ;
    }

    /**
     * Returns the generic object representation of the item.
     * @return {Object} The generic object representation of the item.
     */
    toObject()
    {
        let {
            alternativeHeadline,
            audience,
            audios,
            distance,
            discover,
            elevation,
            headline,
            level,
            numAudios,
            numPhotos,
            numVideos,
            openingHoursSpecification,
            path,
            photos,
            status,
            steps,
            transportations,
            videos,
        } = this ;

        audience                  = generify(audience) ;
        audios                    = generify(audios) ;
        discover                  = generify(discover) ;
        elevation                 = generify(elevation) ;
        level                     = generify(level) ;
        openingHoursSpecification = generify(openingHoursSpecification) ;
        path                      = generify(path) ;
        photos                    = generify(photos) ;
        status                    = generify(status) ;
        steps                     = generify(steps);
        transportations           = generify(transportations);
        videos                    = generify(videos);

        return {
            ...super.toObject() ,
            alternativeHeadline,
            audience,
            audios,
            discover,
            distance,
            elevation,
            headline,
            level,
            numAudios,
            numPhotos,
            numVideos,
            openingHoursSpecification,
            path,
            photos,
            status,
            steps,
            transportations,
            videos
        }
    }
}

export default Course ;
