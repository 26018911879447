import React from 'react'

import PropTypes  from 'prop-types'

import DatasList from './DatasList'

import ThingCell, { LINK } from '../cells/ThingCell'
import RequestStatus from '../../net/RequestStatus'

export class ThingsList extends DatasList
{
    createChild = ( thing , loading , index ) =>
    {
        if( thing )
        {
            let {
                CellComponent,
                cellMode,
                cellProps,
                selectable,
                toggleSelected,
                selectedItems
            } = this.props ;

            selectable = this.isEditable() && selectable ;

            CellComponent = CellComponent || ThingCell  ;

            const { id } = thing ;

            const find = (selectedItems instanceof Array)
                      && selectedItems.findIndex( element => (id === element.id) ) > -1 ;

            return (
                <CellComponent
                    avatar     = { this.getCheckbox }
                    mode       = { cellMode }
                    disabled   = { loading }
                    editable   = { this.isEditable() }
                    onClick    = { () => this.onSelect( thing ) }
                    onSelect   = { () => toggleSelected( thing , 'id' ) }
                    selectable = { selectable }
                    selected   = { !!(find) }
                    options    = { this.getItemOptions( thing , index ) }
                    thing      = { thing }
                    { ...cellProps}
                />
            );
        }
        return null ;
    };

    getItemOptions = thing =>
    {
        if( thing )
        {
            if( this.isEditable() )
            {
                const {
                    activable,
                    preferable,
                    deletable
                } = this.props;
                return (
                    <div className="flex justify-end items-center">
                        { activable  && this.getActiveSwitch(thing) }
                        { preferable && this.getFavoriteButton(thing) }
                        { deletable  && this.getDeleteButton(thing) }
                    </div>
                );
            }
        }
        return null ;
    };
}

ThingsList.defaultProps =
{
    ...DatasList.defaultProps ,
    addMode       : RequestStatus.REDIRECT,
    CellComponent : ThingCell ,
    cellMode      : LINK,
    deleteProps   : { size:'small' },
    sortable      : true
};

ThingsList.propTypes =
{
    ...DatasList.propTypes,
    CellComponent : PropTypes.elementType ,
};

export default ThingsList ;
