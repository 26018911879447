import compose from 'vegas-js-core/src/functors/compose'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { withWidth }  from '@material-ui/core'

import withConfig  from '../../contexts/config/withConfig'
import withDialogs from '../../contexts/dialogs/withDialogs'
import withLang    from '../../contexts/i18n/withLang'
import withLocale  from '../../contexts/i18n/withLocale'
import withSearch  from '../../contexts/search/withSearch'
import withSnack   from '../../contexts/snack/withSnack'

const initDialog = ( {
    useConfig = true ,
    useLang   = true ,
    useLocale = true ,
    useSearch = true ,
    useSnack  = false,
    useStyles = true ,
    styles    = null ,
    withTheme = true
} = {} ) => compose(
    withWidth() ,
    withDialogs ,
    withRouter ,
    useConfig && withConfig,
    useLang   && withLang ,
    useLocale && withLocale ,
    useSearch && withSearch,
    useSnack  && withSnack,
    useStyles && withStyles( styles , { withTheme } )
);

export default initDialog ;
