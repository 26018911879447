import list from './list'

const applications =
{
    ...list,
    helmet :
    {
        title : 'Applications' ,
        metas : []
    },
    empty  : 'Aucune application trouvée',
    search : 'Rechercher une application ...' ,
    title  : 'Applications'
};

export default applications ;
