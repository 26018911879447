import init from './thing'

const place =
{
    ...init,
    status :
    {
        title : 'État du lieu'
    }
};

export default place ;
