import React from 'react'

import PropTypes from 'prop-types'

import clsx from 'clsx'

import compose   from 'vegas-js-core/src/functors/compose'
import isNotNull from 'vegas-js-core/src/isNotNull'

import { withStyles } from "@material-ui/core/styles"

import { Tab, Tabs, Typography } from '@material-ui/core'

import grey from '@material-ui/core/colors/grey'
import green from '@material-ui/core/colors/green'

import withLocale from '../../contexts/i18n/withLocale'
import withSearch from '../../contexts/search/withSearch'

// import FilterListIcon  from '@material-ui/icons/FilterList'

import Container from '../../components/containers/Container'

const styles = () =>
({
    displayNone :
    {
        display: "none !important"
    },
    root :
    {
        alignItems     : 'center',
        display        : 'flex',
        flexDirection  : 'row',
        fontSize       : "12px",
        fontWeight     : "600",
        justifyContent : 'space-between',
        padding        : "2px 8px",
        width          : '100%'
    },
    tab :
    {
        backgroundColor : grey[200],
        minHeight    : "unset !important",
        minWidth     : "unset !important",
        width        : "unset !important",
        height       : "unset !important",
        maxWidth     : "unset !important",
        maxHeight    : "unset !important",
        padding      : "4px 8px",
        borderRadius : "3px",
        lineHeight   : "16px",
        border       : "0 !important",
        color        : grey[900],
        marginLeft   : "4px",
        marginRight  : "4px",
        "&:first-child" :
        {
            marginLeft: "0px"
        },
        "&:last-child" :
        {
            marginRight : "0px"
        }
    },
    tabSelected :
    {
        color           : "#ffffff",
        backgroundColor : green[600],
        //boxShadow       : "0 4px 0px 0 rgba(0,0,0,0.50), 0 2px 0px 0 rgba(0,0,0,0.08);",
        transition      : "0.1s background-color 0.05s",
        fontWeight      : "900"
    },
    tabWrapper :
    {
        display   : "inline-block",
        minHeight : "unset !important",
        minWidth  : "unset !important",
        width     : "unset !important",
        height    : "unset !important",
        maxWidth  : "unset !important",
        maxHeight : "unset !important",
        "& > svg" :
        {
            verticalAlign: "middle",
            margin: "-1px 5px 0 0"
        }
    },
    tabs :
    {
        flexWrap  : 'wrap',
        minHeight : 'unset !important',
        padding   : '4px 0px'
    }
});

class FilterBar extends Container
{
    constructor( props )
    {
        super( props ) ;
        this.state =
        {
            ...this.state,
            value : null
        };
    }

    change = ( event , value ) =>
    {
        this.setState( { value } );

        const { filters } = this.props ;

        if( filters instanceof Array )
        {
            const find = filters.find( item => item.id === value ) ;
            if( find )
            {
                const { onChange } = this.props ;
                if( onChange )
                {
                    onChange( find ) ;
                }
            }
        }
    };

    componentDidMount()
    {
        const { value } = this.props ;
        this.setState({ value } ) ;
    }

    componentDidUpdate( prevProps )
    {
        const { value } = this.props ;
        if( prevProps.value !== value )
        {
            this.setState({ value }) ;
        }
    }

    getChild = value => item =>
    {
        if( item )
        {
            const { classes, disabled } = this.props ;
            const { id, label } = item ;
            return (
            <Tab
                classes = {
                {
                    root     : classes.tab,
                    selected : classes.tabSelected,
                    wrapper  : classes.tabWrapper
                }}
                disabled = { disabled }
                key      = { id }
                label    = { label }
                style    = {{ color : value === id ? 'white' : grey[800] }}
                value    = { id }
            />);
        }
        return null ;
    };

    getLocale = () => this.props.locale.components.bars.filter;

    getIcon = () => this.props.icon ;

    getTitle = () =>
    {
        const locale = this.getLocale() ;
        if( locale )
        {
            const { title } = locale ;
            if( title )
            {
                return <Typography className='font-medium mr-16 mt-6' variant='body2'>{title}</Typography>;
            }
        }
        return null ;
    };

    render = () =>
    {
        let {
            classes,
            className,
            filters,
            style
        } = this.props;

        const { value } = this.state ;

        if( filters instanceof Array && filters.length > 0 )
        {
            filters = filters
                    .map( this.getChild(value) )
                    .filter( isNotNull ) ;

            if( filters.length > 0 )
            {
                filters = (
                    <Tabs
                        className = 'mt-6'
                        classes   = {
                        {
                            indicator : classes.displayNone,
                            root      : classes.tabs
                        }}
                        onChange  = { this.change }
                        value     = { !!(value) ? value : false }
                        variant   = 'scrollable'
                    >
                        { filters  }
                    </Tabs>
                );

                return (
                    <div className={ clsx( classes.root, className ) } style={style} >
                        <div className='flex flex-row items-center w-full flex-wrap'>
                            <div className='flex flex-row items-center xs\:invisible'>
                                { this.getIcon() }
                                { this.getTitle() }
                            </div>
                            { filters }
                        </div>
                    </div>
                );
            }
        }
        return null ;
    }
}

FilterBar.defaultProps =
{
    ...Container.defaultProps,
    disabled : false,
    filters  : null,
    icon     : null , // <FilterListIcon color="action" className="mr-12 mt-6"/>,
    onChange : null,
    value    : null
};

FilterBar.propTypes =
{
    ...Container.propTypes,
    disabled : PropTypes.bool,
    filters  : PropTypes.array,
    icon     : PropTypes.element,
    onChange : PropTypes.func,
    value    : PropTypes.string
};

export default compose(
    withStyles(styles),
    withSearch,
    withLocale
)
( FilterBar ) ;
