import Thing from './Thing'

/**
 * Defines a word in a thesaurus.
 * @memberOf things
 */
class Word extends Thing
{
    /**
     * Creates a new Word instance.
     * @constructor
     * @param object The generic object to initialize the object the first time.
     */
    constructor( object = null )
    {
        super() ;

        /**
         * A background color value option.
         * @type {string}
         */
        this.bgcolor = null ;

        /**
         * A color value option.
         * @type {string}
         */
        this.color = null ;

        /**
         * A format value option.
         * @type {string}
         */
        this.format = null ;

        /**
         * The pattern to validate this word expression.
         * @type {string}
         */
        this.pattern = null ;

        this.validator = null ;

        this.set( object ) ;
    }

    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        super.clear() ;
        this.bgcolor   =
        this.color     =
        this.format    =
        this.pattern   =
        this.validator = null ;
        return this ;
    }

    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new Word( this.toObject() ) ;
    }

    /**
     * Compare the word with an other but use the getLocaleName method to compare the two objects.
     * @param {Word} word - The word to compare
     * @param {string} [lang=null] - The locale lang to use to compare the two words.
     * @returns {number} Returns 0 if equals, -1 if lower, 1 if upper.
     */
    localeCompare( word , lang = 'en' )
    {
        if( word instanceof Thing )
        {
            return this.getLocaleName(lang).localeCompare(word.getLocaleName(lang));
        }
        return 0;
    }

    /**
     * Returns the generic object representation of the item.
     * @return {Object} The generic object representation of the item.
     */
    toObject()
    {
        const {
            bgcolor,
            color,
            format,
            pattern,
            validator
        } = this ;

        return {
            ...super.toObject() ,
            bgcolor,
            color,
            format,
            pattern,
            validator
        }
    }
}

export default Word ;
