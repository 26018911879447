import clean from 'vegas-js-core/src/objects/clean'

import Disease from '../medical/Disease'
import Thing   from '../Thing'
import Word    from '../Word'

import generify from '../generify'

/**
 * A medical analysis definition.
 * @memberOf things.medical
 * @extends Thing
 */
class MedicalAnalysis extends Thing
{
    /**
     * Creates a new MedicalHypothesis instance.
     * @constructor
     * @param object The generic object to initialize the object.
     */
    constructor( object = null )
    {
        super() ;

        /**
         * Indicates if the analysis confirm something.
         * @type {boolean}
         */
        this.confirmed = false ;

        /**
         * The disease to analysis.
         * @type {Word}
         */
        this.disease = null ;

        /**
         * All analysis.
         * @type {Array}
         */
        this.method = null  ;

        /**
         * The risk to analysis.
         * @type {Word}
         */
        this.risk = null ;

        /**
         * All samplings.
         * @type {Array}
         */
        this.sampling = null ;

        this.set( object ) ;
    }

    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        super.clear() ;
        this.confirmed = false ;
        this.disease  =
        this.method   =
        this.sampling = null ;
        return this ;
    }

    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new MedicalAnalysis( this.toObject() ) ;
    }

    /**
     * Populate the object with specific behaviors or types.
     * Launched by default in the constructor and in the set methods.
     * @returns {Thing} The current reference.
     */
    populate()
    {
        super.populate() ;
        const {
            disease,
            method,
            sampling
        }
        = this ;

        if( disease && !(disease instanceof Disease))
        {
            this.disease = new Disease(disease);
        }

        if( method instanceof Array )
        {
            this.method = method.map( item => item instanceof Word ? item : new Word(item) ) ;
        }

        if( sampling instanceof Array )
        {
            this.sampling = sampling.map( item => item instanceof Word ? item : new Word(item) ) ;
        }
    }

    /**
     * Returns the generic object representation of the Object.
     * @return {Object} The generic object representation of the Object.
     */
    toObject( full = false )
    {
        let {
            id,
            confirmed,
            description,
            disease,
            method,
            sampling
        } = this ;

        confirmed = !!(confirmed);

        if ( disease instanceof Disease )
        {
            disease = clean(disease.toObject(false)) ;
        }

        method   = generify(method,true);
        sampling = generify(sampling,true);

        return {
            ...( full && super.toObject() ),
            id,
            description,
            disease,
            method,
            sampling,
            confirmed
        };
    }
}

export default MedicalAnalysis ;
