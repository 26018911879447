const account =
{
    helmet :
    {
        title : 'Mon compte' ,
        metas : []
    },
    loading : 'Chargement...' ,
    notFound : "Profil inconnu" ,
    snack :
    {
        success : 'Profil à jour'
    },
    tabs :
    {
        activities : 'Mes activités' ,
        favorites  : 'Mes favoris' ,
        profile    : 'Mon profil' ,
        sessions   : 'Mes sessions'
    }
};

export default account ;