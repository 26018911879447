import moment from 'moment/moment'

import PropTypes from 'prop-types'

import format   from 'vegas-js-core/src/strings/fastformat'
import notEmpty from 'vegas-js-core/src/strings/notEmpty'
import isString from 'vegas-js-core/src/isString'

import api from '../../configs/api'

import{ defaultInitProps } from '../../display/dialogs/SelectMediaDialog'

import AddDialog         from '../../display/dialogs/AddDialog'
import EditDialog        from '../../display/dialogs/EditDialog'
import EmbedMediaDialog  from '../../display/dialogs/EmbedMediaObjectDialog'
import MediaDialog       from '../../display/dialogs/MediaDialog'
import PrintDialog       from '../../display/dialogs/PrintDialog'
import RemoveDialog      from '../../display/dialogs/RemoveDialog'
import SelectMediaDialog from '../../display/dialogs/SelectMediaDialog'
import SortDialog        from '../../display/dialogs/SortDialog'
import SuggestionDialog  from '../../display/dialogs/SuggestionDialog'
import UploadDialog      from '../../display/dialogs/UploadDialog'

import RoleableContainer from './RoleableContainer'

import Thing from '../../things/Thing'
import Word  from '../../things/Word'

class DialogContainer extends RoleableContainer
{
    constructor( props , initState = null )
    {
        super( props ) ;

        this.prepare = null ;

        this.addLocale  = null ;
        this.addMapUri  = null ;
        this.addPrepare = null ;
        this.addUri     = null ;

        this.editClazz   = null ;
        this.editInit    = null ;
        this.editLocale  = null ;
        this.editMapUri  = null ;
        this.editPrepare = null ;

        this.mediaLocale = null ;

        this.removeLocale  = null ;
        this.removeMapUri  = null ;
        this.removePrepare = null ;
        this.removeProps   = null ;

        this.onAdd         = null ;
        this.onEmbedMedia  = null ;
        this.onEdit        = null ;
        this.onMedia       = null ;
        this.onRemove      = null ;
        this.onSelectMedia = null ;
        this.onSuggest     = null ;
        this.onUpload      = null ;

        this.onOpenAddDialog    = null ;
        this.onOpenEditDialog   = null ;
        this.onOpenRemoveDialog = null ;
        this.onOpenUploadDialog = null ;

        this.selectMediaUri = null ;

        this.suggestionLocale = null ;

        this.uploadLocale = null ;
        this.uploadUri    = null ;

        this.state = {
            ...this.state,
            ...initState
        }
    }

    change = thing =>
    {
        if( this._mounted )
        {
            this.forceUpdate( () =>
            {
                const { onChange } = this.props ;
                if( onChange instanceof Function )
                {
                    onChange( thing ) ;
                }
            }) ;
        }
    };

    changeProperty = ( property , isMember = false ) => ( item ) =>
    {
        const { thing } = this.props ;
        if( thing instanceof Thing )
        {
            thing[property] = isMember ? item[property] : item ;
            thing.modified = moment(new Date()).toISOString();
            if( thing instanceof Thing )
            {
                thing.populate();
            }
            if( this._mounted )
            {
                this.forceUpdate(() =>
                {
                    const { onChange } = this.props ;
                    if( onChange instanceof Function )
                    {
                        onChange( thing ) ;
                    }
                });
            }
        }
    };

    getEntry = init => ({ ...init }) ;

    getPath = () =>
    {
        const { thing, uri } = this.props  ;
        if( thing instanceof Thing && isString(uri) )
        {
            const { id } = thing ;
            return format( uri , id ) ;
        }
        return uri ;
    };

    getThingRef = () => null ;

    render = () => {};

    selectRemove = () =>
    {
        this.openRemoveDialog( this.props.selectedItems ) ;
    };

    unselectAll = () =>
    {
        const { unselectAll } = this.props ;
        if( unselectAll instanceof Function )
        {
            unselectAll() ;
        }
    };

    // ----- dialogs

    getEditOptions = () => null  ;

    // ----- behaviors

    openCustomAddDialog = () => null ;

    openAddDialog = ( settings = {} ) =>
    {
        let { init , ...options } = settings ;
        const { addDialog } = this.props;
        if ( addDialog )
        {
            const item = this.getEntry(init) ;
            const path = this.getPath() ;

            let {
                addUri,
                onAdd,
                openAddPolicy,
            } = this.props ;

            addUri = addUri || this.addUri ;
            onAdd  = onAdd  || this.onAdd ;

            let uri = path ;
            if( isString( addUri ) )
            {
                uri = addUri;
            }
            else if( addUri instanceof Function )
            {
                uri = addUri( path , this.props , this.state );
            }

            const { AddDialogComponent } = this.props;
            if( AddDialogComponent )
            {
                if( notEmpty(uri) )
                {
                    options = { uri , ...options } ;
                }

                const position = addDialog
                (
                    AddDialogComponent ,
                    {
                        item ,
                        onAdd,
                        thingRef:this.getThingRef(),
                        ...options
                    }
                );

                let { onOpenAddDialog } = this.props ;
                onOpenAddDialog = onOpenAddDialog || this.onOpenAddDialog ;
                if( onOpenAddDialog instanceof Function )
                {
                    onOpenAddDialog( { position , item } ) ;
                }

                return ;
            }
            else if( openAddPolicy === 'default')
            {
                let {
                    addLocale ,
                    addMapUri ,
                    addPrepare ,
                    addProps
                }
                = this.props;

                addLocale  = addLocale  || this.addLocale;
                addMapUri  = addMapUri  || this.addMapUri;
                addPrepare = addPrepare || this.addPrepare || this.prepare;

                if( !addLocale )
                {
                    const locale = this.getLocale();
                    if( locale )
                    {
                        const { add : al } = locale;
                        if( al )
                        {
                            addLocale = al;
                        }
                    }
                }

                if( addLocale instanceof Function )
                {
                    addLocale = addLocale( this.props , this.state );
                }

                const position = addDialog(
                    AddDialog ,
                    {
                        locale  : addLocale ,
                        ...addProps ,
                        item ,
                        uri ,
                        mapUri   : addMapUri ,
                        prepare  : addPrepare ,
                        thingRef : this.getThingRef(),
                        onAdd    : element =>
                        {
                            let { onChange } = this.props;
                            if( onAdd instanceof Function )
                            {
                                onAdd( element );
                            }
                            else if( onChange instanceof Function )
                            {
                                onChange( element );
                            }
                        }
                    }
                );

                let { onOpenAddDialog } = this.props ;
                onOpenAddDialog = onOpenAddDialog || this.onOpenAddDialog ;
                if( onOpenAddDialog instanceof Function )
                {
                    onOpenAddDialog( { position , item } ) ;
                }

                return ;
            }
        }
        this.openCustomAddDialog() ;
    };

    openEditDialog = item =>
    {
        const edit = this.getEntry( item ) ;
        if( edit )
        {
            const { addDialog } = this.props;
            if ( addDialog )
            {
                let position ;

                const path = this.getPath() ;

                let { editUri, onChange , onEdit } = this.props;

                editUri = editUri || this.editUri ;
                onEdit  = onEdit  || this.onEdit ;

                let uri = path ;
                if( notEmpty(editUri) )
                {
                    uri = editUri ;
                }
                else if( editUri instanceof Function )
                {
                    uri = editUri( path, this.props , this.state ) ;
                }
                else if ( edit instanceof Thing )
                {
                    const { id , url } = edit ;
                    if( notEmpty(url) )
                    {
                        uri = url.split(api.url)[1] ;
                    }
                    else if ( id && notEmpty(path) )
                    {
                        uri = path + '/' + id
                    }
                }

                const { EditDialogComponent } = this.props;
                if( EditDialogComponent )
                {
                    position = addDialog(
                        EditDialogComponent ,
                        {
                            uri ,
                            item     : edit ,
                            options  :  this.getEditOptions( edit ) ,
                            onChange : element =>
                            {
                                if( onEdit instanceof Function )
                                {
                                    onEdit( element , edit ) ;
                                }
                                else if( onChange instanceof Function )
                                {
                                    onChange( element , edit ) ;
                                }
                            }
                        }
                    );
                }
                else
                {
                    let {
                        editClazz,
                        editKeepMounted,
                        editLocale,
                        editMapUri,
                        editPrepare,
                        editProps,
                        editSearchUri,
                        editSortable,
                    } = this.props;

                    let clazz = Word ;
                    if( editClazz instanceof Function )
                    {
                        clazz = editClazz( this.props , this.state ) ;
                    }

                    editLocale  = editLocale  || this.editLocale ;
                    editMapUri  = editMapUri  || this.editMapUri ;
                    editPrepare = editPrepare || this.editPrepare || this.prepare ;

                    if( !editLocale )
                    {
                        const locale = this.getLocale() ;
                        if( locale )
                        {
                            const { edit:el } = locale ;
                            if( el )
                            {
                                editLocale = el ;
                            }
                        }
                    }

                    if( editLocale instanceof Function )
                    {
                        editLocale = editLocale( this.props , this.state ) ;
                    }

                    position = addDialog(
                        EditDialog ,
                        {
                            clazz       : clazz ,
                            keepMounted : editKeepMounted,
                            init        : this.editInit ,
                            locale      : editLocale,
                            mapUri      : editMapUri ,
                            options     :  this.getEditOptions( edit ) ,
                            prepare     : editPrepare,
                            searchUri   : editSearchUri,
                            sortable    : editSortable,
                            ...editProps ,
                            item     : edit ,
                            uri,
                            onChange : element =>
                            {
                                if( onEdit instanceof Function )
                                {
                                    onEdit( element , edit );
                                }
                                else if( onChange instanceof Function )
                                {
                                    onChange( element , edit ) ;
                                }
                            }
                        }
                    );
                }

                let { onOpenEditDialog } = this.props ;
                onOpenEditDialog = onOpenEditDialog || this.onOpenEditDialog ;
                if( onOpenEditDialog instanceof Function )
                {
                    onOpenEditDialog( { position , item:edit } ) ;
                }
            }
        }
    };

    openEmbedDialog = ( options = null ) =>
    {
        const { addDialog , EmbedDialogComponent } = this.props ;
        if( addDialog && EmbedDialogComponent )
        {
            let { onEmbedMedia } = this.props ;
            onEmbedMedia = onEmbedMedia  || this.onEmbedMedia ;
            addDialog(
                EmbedDialogComponent ,
                {
                    onEmbedMedia,
                    ...options
                }
            );
        }
    };

    openMediaDialog = ( media , options = null ) =>
    {
        const { addDialog, MediaDialogComponent } = this.props;
        if ( media && addDialog && MediaDialogComponent )
        {
            media = this.getEntry( media ) ;

            let {
                mediaLocale,
                mediaProps,
                onChange,
                onMedia
            }
            = this.props;

            mediaLocale = mediaLocale || this.mediaLocale ;
            onMedia     = onMedia     || this.onMedia ;

            if( !mediaLocale )
            {
                const locale = this.getLocale() ;
                if( locale )
                {
                    const { media:ml } = locale ;
                    if( ml )
                    {
                        mediaLocale = ml ;
                    }
                }
            }

            if( mediaLocale instanceof Function )
            {
                mediaLocale = mediaLocale( this.props , this.state ) ;
            }

            addDialog(
                MediaDialogComponent ,
                {
                    ...mediaProps,
                    editable : this.isEditable() ,
                    item     : media,
                    locale   : mediaLocale ,
                    onChange : item =>
                    {
                        if( onMedia instanceof Function )
                        {
                            onMedia( item , media ) ;
                        }
                        else if( onChange instanceof Function )
                        {
                            onChange( item , media );
                        }
                    },
                    onRemove : this.openRemoveDialog,
                    ...options
                }
            );
        }
    };

    openPrintDialog = print =>
    {
        const { addDialog, PrintDialogComponent } = this.props;
        if ( addDialog && PrintDialogComponent )
        {
            addDialog( PrintDialogComponent , { print } );
        }
    };

    openRemoveDialog = ( item , settings = {} ) =>
    {
        const { addDialog } = this.props;
        if ( addDialog )
        {
            let position ;
            const path = this.getPath() ;
            const { RemoveDialogComponent } = this.props ;
            if( RemoveDialogComponent )
            {
                let {
                    onRemove,
                    removeProps ,
                    removeUri : uri
                }
                = this.props ;

                onRemove    = onRemove    || this.onRemove ;
                removeProps = removeProps || this.removeProps ;
                uri         = uri         || this.removeUri || path ;

                if( uri instanceof Function )
                {
                    uri = uri( path , this.props , this.state ) ;
                }

                if( notEmpty( uri ) )
                {
                    settings = { uri , ...settings } ;
                }

                position = addDialog(
                    RemoveDialogComponent ,
                    {
                        item ,
                        ...removeProps ,
                        onRemove,
                        ...settings
                    }
                ) ;
            }
            else
            {
                let {
                    removeLocale,
                    removePrepare,
                    removeProps,
                    removeMapUri,
                    removeUri : uri ,
                    onChange,
                    onRemove ,
                    thing
                } = this.props;

                onRemove = onRemove || this.onRemove ;
                uri      = uri || this.removeUri || path ;

                if( uri instanceof Function )
                {
                    uri = uri( path , this.props , this.state ) ;
                }

                removeLocale  = removeLocale  || this.removeLocale ;
                removeMapUri  = removeMapUri  || this.removeMapUri;
                removePrepare = removePrepare || this.removePrepare ;

                if( !removeLocale )
                {
                    const locale = this.getLocale() ;
                    if( locale )
                    {
                        const { remove:rl } = locale ;
                        if( rl )
                        {
                            removeLocale = rl ;
                        }
                    }
                }

                if( removeLocale instanceof Function )
                {
                    removeLocale = removeLocale( this.props , this.state ) ;
                }

                // console.log( this + ' openRemoveDialog' , uri ) ;

                position = addDialog(
                    RemoveDialog ,
                    {
                        locale  : removeLocale ,
                        ...removeProps ,
                        item,
                        uri ,
                        mapUri   : removeMapUri ,
                        prepare  : removePrepare ,
                        onRemove : ( id , element ) =>
                        {
                            if( onRemove instanceof Function )
                            {
                                onRemove( id , element ) ;
                            }
                            else if( onChange instanceof Function )
                            {
                                onChange( thing , id , element );
                            }
                        }
                    }
                );
            }

            let { onOpenRemoveDialog } = this.props ;
            onOpenRemoveDialog = onOpenRemoveDialog || this.onOpenRemoveDialog ;
            if( onOpenRemoveDialog instanceof Function )
            {
                onOpenRemoveDialog( { position , item } ) ;
            }
        }
    };

    openSelectMediaDialog = () =>
    {
        const { addDialog, SelectMediaDialogComponent } = this.props;
        if ( addDialog && SelectMediaDialogComponent )
        {
            let { selectMediaUri:uri } = this.props;

            uri = uri || this.selectMediaUri || this.getPath() ;

            if( uri instanceof Function )
            {
                uri = uri( this.props, this.state ) ;
            }

            let { onSelectMedia , selectMediaProps } = this.props ;

            if( selectMediaProps instanceof Function )
            {
                selectMediaProps = selectMediaProps( this ) ;
            }

            onSelectMedia = onSelectMedia || this.onSelectMedia ;

            const options = {
                ...defaultInitProps ,
                ...selectMediaProps,
                uri,
                onSelect : item =>
                {
                    if( onSelectMedia instanceof Function )
                    {
                        onSelectMedia( item ) ;
                    }
                }
            } ;

            addDialog( SelectMediaDialogComponent , options );
        }
    };

    openSortDialog = () =>
    {
        const { addDialog } = this.props;
        if ( addDialog )
        {
            const locale = this.getLocale() || {} ;
            const { sorts:elements } = locale ;
            if( elements instanceof Array && elements.length > 0 )
            {
                const { SortDialogComponent } = this.props ;
                addDialog( SortDialogComponent , { elements } ) ;
            }
        }
    };

    openSuggestionDialog = suggestions =>
    {
        const { addDialog, SuggestionDialogComponent } = this.props;
        if ( addDialog && SuggestionDialogComponent )
        {
            let options = {};

            if( suggestions )
            {
                if( !( suggestions instanceof Array ) || suggestions.length === 0 )
                {
                    const { items , ...rest } = suggestions;
                    if( items instanceof Array && items.length > 0 )
                    {
                        suggestions = items;
                        options = { ...options , ...rest };
                    }
                    else
                    {
                        suggestions = null;
                    }
                }
            }
            else
            {
                suggestions = null;
            }

            if( suggestions instanceof Array && suggestions.length > 0 )
            {
                let { suggestionLocale } = this.props;

                suggestionLocale = suggestionLocale || this.suggestionLocale ;

                if( !suggestionLocale )
                {
                    const locale = this.getLocale();
                    if( locale )
                    {
                        const { suggestions : loc } = locale;
                        if( loc )
                        {
                            const { label , sort , thingRef , ...rest } = loc;

                            if( label instanceof Function )
                            {
                                options = { ...options , label };
                            }

                            if( sort instanceof Function )
                            {
                                options = { ...options , sort };
                            }

                            if( thingRef instanceof Function )
                            {
                                options = { ...options , thingRef };
                            }

                            suggestionLocale = rest;
                        }
                    }
                }

                let { onSuggest } = this.props ;

                onSuggest = onSuggest || this.onSuggest;

                return (
                    addDialog( SuggestionDialogComponent ,
                    {
                        ...options ,
                        suggestions ,
                        locale   : suggestionLocale  ,
                        onChange : event =>
                        {
                            if( onSuggest instanceof Function )
                            {
                                if( event )
                                {
                                    const { target } = event;
                                    if( target )
                                    {
                                        const { value } = target;
                                        onSuggest( value );
                                    }
                                }
                            }
                        }
                    })
                )
            }
        }
    };

    openUploadDialog = ( settings = {} ) =>
    {
        const { addDialog, UploadDialogComponent } = this.props;
        if ( addDialog && UploadDialogComponent )
        {
            let {
                onUpload,
                onChange,
                UploadDialogComponent,
                uploadFilePropName,
                uploadLocale,
                uploadMultiple,
                uploadProps,
                uploadUri:uri
            }
            = this.props;

            const path = this.getPath() ;

            onUpload = onUpload || this.onUpload ;

            uploadLocale = uploadLocale || this.uploadLocale ;
            if( !uploadLocale )
            {
                const locale = this.getLocale() ;
                if( locale )
                {
                    const { upload:ul } = locale ;
                    if( ul )
                    {
                        uploadLocale = ul ;
                    }
                }
            }

            if( uploadLocale instanceof Function )
            {
                uploadLocale = uploadLocale( this.props , this.state ) ;
            }

            uri = uri || this.uploadUri || path ;
            if( uri instanceof Function )
            {
                const { uri:url } = settings || {} ;
                uri = uri( { path , props:this.props , state:this.state , uri:url } ) ;
            }

            const position = addDialog(
                UploadDialogComponent ,
                {
                    uri,
                    filePropName : uploadFilePropName ,
                    locale       : uploadLocale,
                    multiple     : uploadMultiple ,
                    ...uploadProps,
                    onUpload : item =>
                    {
                        if( onUpload instanceof Function )
                        {
                            onUpload( item );
                        }
                        else if( onChange instanceof Function )
                        {
                            onChange( item );
                        }
                    }
                }
            );

            let { onOpenUploadDialog } = this.props ;
            onOpenUploadDialog = onOpenUploadDialog || this.onOpenUploadDialog ;
            if( onOpenUploadDialog instanceof Function )
            {
                onOpenUploadDialog( { position , uri } ) ;
            }
        }
    };
}

DialogContainer.defaultProps =
{
    ...RoleableContainer.defaultProps ,

    AddDialogComponent         : null,
    EditDialogComponent        : null,
    EmbedDialogComponent       : EmbedMediaDialog,
    MediaDialogComponent       : MediaDialog,
    PrintDialogComponent       : PrintDialog,
    RemoveDialogComponent      : null,
    SelectMediaDialogComponent : SelectMediaDialog ,
    SortDialogComponent        : SortDialog,
    SuggestionDialogComponent  : SuggestionDialog ,
    UploadDialogComponent      : UploadDialog,

    addLocale : null,
    addMapUri : null,
    addUri    : null,
    addProps  : null,

    editKeepMounted : false ,
    editLocale      : null,
    editMapUri      : null,
    editProps       : null,
    editSearchUri   : null,
    editSortable    : true,
    editUri         : null,

    mediaProps : null ,

    onAdd         : null ,
    onChange      : null ,
    onEdit        : null ,
    onRemove      : null ,
    onSelectMedia : null ,
    onSuggest     : null ,
    onUpload      : null ,

    onOpenAddDialog    : null,
    onOpenEditDialog   : null,
    onOpenRemoveDialog : null,
    onOpenUploadDialog : null,

    openAddPolicy : 'default',

    path : null ,

    removeLocale : null,
    removeMapUri : null,
    removeProps  : null,
    removeUri    : null,

    suggestionLocale : null,

    selectable : false ,

    selectMediaUri : null ,

    member    : null ,
    subjectOf : null ,
    thing     : null ,

    uploadable         : false ,
    uploadFilePropName : 'file',
    uploadLocale       : null,
    uploadMultiple     : false,
    uploadProps        : null,
    uploadUri          : null,

    uri : null
};

DialogContainer.propTypes =
{
    ...RoleableContainer.propTypes ,

    AddDialogComponent         : PropTypes.elementType,
    EditDialogComponent        : PropTypes.elementType,
    RemoveDialogComponent      : PropTypes.elementType,
    MediaDialogComponent       : PropTypes.elementType,
    PrintDialogComponent       : PropTypes.elementType,
    SortDialogComponent        : PropTypes.elementType,
    SelectMediaDialogComponent : PropTypes.elementType,
    SuggestionDialogComponent  : PropTypes.elementType,
    UploadDialogComponent      : PropTypes.elementType,

    addLocale        : PropTypes.object,
    addMapUri        : PropTypes.func,
    addPrepare       : PropTypes.func,
    addProps         : PropTypes.object,
    addUri           : PropTypes.oneOfType([PropTypes.func,PropTypes.string]),

    editKeepMounted  : PropTypes.bool,
    editLocale       : PropTypes.object,
    editMapUri       : PropTypes.func,
    editPrepare      : PropTypes.func,
    editProps        : PropTypes.object,
    editSearchUri    : PropTypes.string,
    editSortable     : PropTypes.bool,
    editUri          : PropTypes.oneOfType([PropTypes.func,PropTypes.string]),

    mediaLocale      : PropTypes.object,
    mediaProps       : PropTypes.object,

    onAdd            : PropTypes.func,
    onChange         : PropTypes.func,
    onEdit           : PropTypes.func,
    onEmbedMedia     : PropTypes.func,
    onRemove         : PropTypes.func,
    onSelectMedia    : PropTypes.func,
    onUpload         : PropTypes.func,

    onOpenAddDialog    : PropTypes.func,
    onOpenEditDialog   : PropTypes.func,
    onOpenRemoveDialog : PropTypes.func,
    onOpenUploadDialog : PropTypes.func,

    openAddPolicy    : PropTypes.string,

    path : PropTypes.object,

    removeLocale     : PropTypes.object,
    removeMapUri     : PropTypes.func,
    removePrepare    : PropTypes.func,
    removeProps      : PropTypes.object,
    removeUri        : PropTypes.oneOfType([PropTypes.func,PropTypes.string]),

    suggestionLocale : PropTypes.object ,

    selectable       : PropTypes.bool ,
    selectMediaProps : PropTypes.oneOfType([PropTypes.object,PropTypes.func]),
    selectMediaUri   : PropTypes.oneOfType([PropTypes.func,PropTypes.string]),

    member           : PropTypes.string,
    subjectOf        : PropTypes.object,
    thing            : PropTypes.object,
    uri              : PropTypes.string,

    uploadable         : PropTypes.bool,
    uploadFilePropName : PropTypes.string,
    uploadLocale       : PropTypes.object,
    uploadMultiple     : PropTypes.bool,
    uploadProps        : PropTypes.object,
    uploadUri          : PropTypes.oneOfType([PropTypes.string,PropTypes.func])
};

export default DialogContainer ;
