import React from 'react'

import withDatasList from './withDatasList'

import StageIcon  from '@material-ui/icons/Stars'

import ThingsList from './ThingsList'

import Stage from '../../things/Stage'

import AddStageDialog    from '../dialogs/add/AddStageDialog'
import RemoveStageDialog from '../dialogs/remove/RemoveStageDialog'

import StageCell from '../cells/StageCell'

class StagesList extends ThingsList
{
    getLocale = () => this.props.locale.display.lists.stages ;
}

StagesList.defaultProps =
{
    ...ThingsList.defaultProps ,
    AddDialogComponent    : AddStageDialog,
    CellComponent         : StageCell,
    cellProps             : { variant : 'default' },
    clazz                 : Stage,
    icon                  : <StageIcon />,
    preferable            : true ,
    RemoveDialogComponent : RemoveStageDialog,
    sortable              : true
};

StagesList.propTypes =
{
    ...ThingsList.propTypes
};

export default withDatasList()( StagesList ) ;
