import initDialog from '../initDialog'

import { AddDialog } from '../AddDialog'

import Thing from "../../../things/Thing"

import api from '../../../configs/api'

const styles = () => ({});

export class AddCourseDialog extends AddDialog
{
    getLocale = () => this.props.locale.dialogs.application.course.add ;

    prepare = item =>
    {
        const { additionalType, name } = item ;
        return (
        {
            additionalType : additionalType instanceof Thing ? String(additionalType.id) : null ,
            name
        });
    };
}

AddCourseDialog.defaultProps =
{
    ...AddDialog.defaultProps,
    uri : api.courses.url
};

AddCourseDialog.propTypes =
{
    ...AddDialog.propTypes
};

export default initDialog({ styles })( AddCourseDialog ) ;
