import React     from 'react'

import PropTypes from 'prop-types'

import clsx from 'clsx'

import { Redirect } from 'react-router-dom'

import { CircularProgress, Typography } from '@material-ui/core'

import RequestStatus    from '../../net/RequestStatus'

import Animate from '../../transitions/Animate'

import AuthStatus from '../../net/auth/AuthStatus'

import GET  from "../../net/GET"
import api  from "../../configs/api"

import View from '../../display/views/View'

import initView from '../../display/views/initView'

const styles = theme =>
({
    logo:
    {
        marginBottom : 32,
        height       : 156
    },
    progress:
    {
        color     : '#FFFD44' ,
        marginTop : theme.spacing(4)
    },
    root:
    {
        backgroundColor : process.env.REACT_APP_THEME_COLOR ,
        background      : `url(${theme.assets.background}) no-repeat center center` ,
        backgroundSize  : 'cover' ,
        width           : '100%',
        height          : '100%'
    }
});

class Register extends View
{
    constructor(props)
    {
        super(props);
        this.canceler = null ;
    }

    getContent = ( label = '' , title = '' ) =>
    {
        const {
            classes,
            config
        } = this.props;
        return (
            <div className={clsx(classes.root, "flex flex-col flex-1 flex-shrink-0 p-24 md:flex-row md:p-0")}>
                <div className="flex flex-col flex-grow-0 items-center p-16 text-center md:p-128 md:items-start md:flex-shrink-0 md:flex-1 md:text-left">
                    <Animate animation="transition.expandIn">
                        <img className={ classes.logo } src={ config.assets.logo } alt="logo"/>
                    </Animate>
                    <Animate animation="transition.slideUpIn" delay={200}>
                        <Typography variant="h3" className="font-light">
                            { title }
                        </Typography>
                    </Animate>
                    <Animate delay={500}>
                        <Typography variant="subtitle1" className="max-w-512 mt-16">
                            { label }
                        </Typography>
                    </Animate>
                    <Animate delay={600}>
                        <CircularProgress className={classes.progress} thickness={7} />
                    </Animate>
                </div>
            </div>
        ) ;
    };

    render = () =>
    {
        const check = AuthStatus.check() ;

        switch( check )
        {
            case AuthStatus.NEED_ACCESS_TOKEN :
            {
                return <Redirect to='/welcome' />
            }

            case AuthStatus.NEED_USER :
            {
                const locale = this.getLocale() ;
                if( locale )
                {
                    const { loading , title } = locale ;
                    return this.getContent( loading, title ) ;
                }
                return null ;
            }

            case AuthStatus.CONNECTED :
            {
                const { location } = this.props ;
                if( location )
                {
                    const { state } = location ;
                    if( state )
                    {
                        const { referrer } = state ;
                        if( referrer )
                        {
                            return <Redirect to={ referrer } />
                        }
                    }
                }
                return <Redirect to='/welcome' />
            }
            default:
            {
                return <Redirect to='/welcome' />
            }
        }
    };

    fail = response =>
    {
        if( response )
        {
            const { data, status } = response ;
            if( data )
            {
                const { message } = data ;
                switch( message )
                {
                    case 'Invalid authorization code' :
                    {
                        console.log( this + " failed, status:" + status + ", code:" + data.code + ", message:" + message );
                        break ;
                    }

                    case 'Invalid refresh token' :
                    {
                        console.log( this + " failed, status:" + status + ",  message:" + message );
                        break ;
                    }
                    case 'token revoked' :
                    {
                        console.log( this + " failed, status:" + status + ", message:" + message );
                        if( this._mounted )
                        {
                            this.forceUpdate() ;
                        }
                        break ;
                    }
                    default :
                    {
                        console.log( this + " failed, unknow status:" + status + ", message:" + message );
                    }
                }
            }
        }
    };

    getLocale = () => this.props.locale.views.auth.me ;

    init = () =>
    {
        if( AuthStatus.check() === AuthStatus.NEED_USER )
        {
            this.canceler = GET
            (
                api.url + api.me.url ,
                {
                    fail    : this.fail ,
                    random  : true,
                    success : this.success
                }
            );
        }
    };

    success = data =>
    {
        if( data )
        {
            const { result } = data ;
            if( result )
            {
                const { writeUser } = this.props ;
                if( writeUser instanceof Function )
                {
                    writeUser(result) ;
                }
            }
        }
    };

    unmount = () =>
    {
        const { status } = this.state ;
        if( status === RequestStatus.PROGRESS && !!(this.canceler) )
        {
            this.canceler.cancel( this + ' cancel' ) ;
        }
    };
}

Register.defaultProps =
{
    ...View.defaultProps,
    apiUrl : api.url
};


Register.propTypes =
{
    ...View.propTypes,
    apiUrl : PropTypes.string.isRequired
};

export default initView( Register , styles );
