import table from './table'

const veterinarians =
{
    ...table,
    helmet :
    {
        title : 'Vétérinaires' ,
        metas : []
    },
    empty  : 'Aucun vétérinaire',
    search : 'Rechercher un vétérinaire ...' ,
    title  : 'Vétérinaire'
};

export default veterinarians ;
