import PropTypes from 'prop-types'

import moment from 'moment/moment'

import format   from 'vegas-js-core/src/strings/fastformat'
import notEmpty from 'vegas-js-core/src/strings/notEmpty'

import ThingContainer from '../../containers/ThingContainer'

import api from '../../../configs/api'

import PATCH         from '../../../net/PATCH'
import RequestStatus from '../../../net/RequestStatus'

export class Media extends ThingContainer
{
    constructor( props )
    {
        super( props ) ;
        this.canceler = null ;
        this.state =
        {
            ...this.state,
            status : RequestStatus.NEW
        }
    }

    getCustomOptions = () =>
    {
        const { status } = this.state ;
        if( status === RequestStatus.PROGRESS )
        {
            return null ;
        }
        const { thing } = this.props ;
        if( thing )
        {
            const media = this.getThingMember() ;
            if( media )
            {
                return this.getRemoveButton() ;
            }
            else
            {
                return this.getSelectMediaButton() ;
            }
        }
        return false ;
    };

    getThingMember = () =>
    {
        let { member , thing } = this.props ;
        if( thing && notEmpty( member) && thing[member] )
        {
            member = thing[member] ;
            const { memberClazz } = this.props ;
            if( memberClazz instanceof Function )
            {
                if( member instanceof memberClazz )
                {
                    return member ;
                }
            }
            else
            {
                return member ;
            }
        }
        return null ;
    };

    getUri = media => api.url + this.props.uri + '/' + media ;

    onRemove = () =>
    {
        const { thing , member } = this.props ;
        if( thing && notEmpty(member) )
        {
            thing[member]  = null ;
            thing.modified = moment( new Date() ).toISOString();

            if( this._mounted )
            {
                this.forceUpdate( () =>
                {
                    const { snacks : { removed } = {} } = this.getLocale() || {} ;
                    if( notEmpty( removed ) )
                    {
                        const { notifySnack } = this.props ;
                        if( notifySnack )
                        {
                            notifySnack( removed , 'success' )
                        }
                    }

                    const { onChange } = this.props ;
                    if( onChange instanceof Function )
                    {
                        onChange( thing ) ;
                    }
                });
            }
        }
    };

    onSelectMedia = medias =>
    {
        if( medias instanceof Array && medias.length === 1 )
        {
            this.timeout = setTimeout( this.save, 250, medias[0] ) ;
        }
    };

    save = media =>
    {
        if( media )
        {
            const { id:key } = media ;
            let { thing } = this.props ;
            if( thing )
            {
                const { id } = thing ;
                if( id )
                {
                    this.setState({ status:RequestStatus.PROGRESS });
                    this.canceler = PATCH(
                        format( this.getUri( key ), id ) ,
                        {
                            success : this._success,
                            fail    : this._fail,
                            cancel  : this._cancel
                        }
                    )
                }

            }
        }
    };

    unmount = () =>
    {
        clearTimeout( this.timeout ) ;
        const { status } = this.state ;
        if( status === RequestStatus.PROGRESS && !!(this.canceler) )
        {
            this.canceler.cancel(this + ' cancel') ;
        }
    };

    _cancel = () =>
    {
        // console.log( this + " cancel message:" + message ) ;
        this.setState( { status:RequestStatus.CANCEL } ) ;
    };

    _fail = response =>
    {
        if( response )
        {
            const { data } = response ;
            if( data )
            {
                const { message } = data ;
                switch( message )
                {
                    case 'token revoked' :
                    {
                        break ;
                    }
                    default :
                    {
                        console.log( this + " failed, status:" + response.status + ", message:" + data.message , true );
                    }
                }
            }
        }

        if( this._mounted )
        {
            this.setState( { status:RequestStatus.FAIL }, () =>
            {
                const { snacks: { failed } = {} } = this.getLocale() || {};
                if ( notEmpty( failed ) )
                {
                    const { notifySnack } = this.props;
                    if ( notifySnack )
                    {
                        notifySnack( failed, 'error' )
                    }
                }
            });
        }
    };

    _success = response =>
    {
        const { member , thing } = this.props ;
        if( thing && notEmpty(member) && response )
        {
            const { data } = response ;
            if( data )
            {
                const { result } = data ;
                if( result )
                {
                    thing[member] = result ;
                }
                else
                {
                    thing[member] = null ;
                }

                thing.modified = moment(new Date()).toISOString();
                thing.populate() ;

                if( this._mounted )
                {
                    this.setState( { status:RequestStatus.SUCCESS } , () =>
                    {
                        const { onChange } = this.props ;
                        if( onChange instanceof Function )
                        {
                            onChange( thing ) ;
                        }

                        const { snacks : { success } = {} } = this.getLocale() || {} ;
                        if( notEmpty(success) )
                        {
                            const { notifySnack } = this.props ;
                            if( notifySnack )
                            {
                                notifySnack( success , 'success' )
                            }
                        }
                    }) ;
                }
            }
        }
    };
}

Media.defaultProps =
{
    ...ThingContainer.defaultProps ,
    editMapUri : ( item , uri ) => uri ,
    optionMode : 'custom'
};

Media.propTypes =
{
    ...ThingContainer.propTypes ,
    member      : PropTypes.string,
    memberClazz : PropTypes.func,
};

export default Media ;
