import Form from '../../../../display/forms/FormElements'

const elements =
[
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id         : 'name',
            key        : 'name',
            label      : 'Nom Complet',
            autoFocus  : true,
            fullWidth  : true,
            helperText : 'Obligatoire',
            margin     : 'normal',
            required   : true,
            select     : false,
            type       : 'text',
            variant    : 'outlined'
        }
    }
];

export default
{
    add :
    {
        elements,
        agree       : 'Créer' ,
        disagree    : 'Annuler' ,
        title       : 'Ajouter une personne' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour créer une nouvelle fiche.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en  cours...' ,
        success     : 'Ajout du nouvel enregistrement réussi!'
    },
    remove :
    {
        agree        : 'Supprimer' ,
        description  : 'Souhaitez-vous supprimer la personne suivante : ' ,
        descriptionX : 'Souhaitez-vous supprimer les {0} personnes suivantes : ' ,
        disagree     : 'Annuler' ,
        loading      : 'Suppression en cours...' ,
        title        : 'Supprimer cette personne ?',
        titleX       : 'Supprimer des personnes ?'
    }
};
