import React from 'react'

import moment  from 'moment/moment'

import compose   from "vegas-js-core/src/functors/compose"
import isBoolean from "vegas-js-core/src/isBoolean"
import isString  from 'vegas-js-core/src/isString'
import ucFirst   from 'vegas-js-core/src/strings/ucFirst'

import withConfig  from '../../contexts/config/withConfig'
import withDialogs from '../../contexts/dialogs/withDialogs'
import withi18n    from '../../contexts/i18n/withi18n'

import { withRouter } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'

import PlaceIcon from '@material-ui/icons/Place'

import LabelItem from '../../components/items/LabelItem'

import ThingContainer from '../containers/ThingContainer'

import LabelIcon from '@material-ui/icons/Label'

import PlaceVO   from '../../things/Place'
import ThingVO   from '../../things/Thing'
import WordVO    from '../../things/Word'

const styles = () => ({});

const half =
{
    lg : 6,
    md : 6,
    sm : 12,
    xs : 12
};

const full =
{
    sm : 6,
    xs : 12
};

class Place extends ThingContainer
{
    editPrepare = item =>
    {
        let {
            additionalType,
            name,
            publicAccess,
            status
        } = item ;
        return (
        {
            additionalType : additionalType instanceof ThingVO ? String(additionalType.id) : null ,
            name,
            publicAccess : isBoolean(publicAccess) ? publicAccess : "" ,
            status : status instanceof ThingVO ? String(status.id) : null
        });
    };

    getContent = () =>
    {
        let { lang, thing } = this.props;

        const locale = this.getLocale() ;

        const { labels } = locale ;

        let indicator ;
        let name ;
        let status ;
        let type ;

        let publicAccess = labels.empty ;

        if( thing instanceof PlaceVO )
        {
            let {
                additionalType,
                name : n,
                publicAccess : p ,
                status : s
            } = thing ;

            if( isString( n ) )
            {
                name = n ;
            }

            if( additionalType instanceof ThingVO )
            {
                type = additionalType.getLocaleName( lang ) ;
            }

            if( isBoolean(p) )
            {
                publicAccess = p ? labels.yes : labels.no ;
            }

            if( s instanceof WordVO )
            {
                const { color } = s ;
                if( isString( color ) )
                {
                    indicator = (
                        <LabelIcon
                            className = "mr-8 self-center"
                            style     = {{ color : "#" + color }}
                        />);
                }
                status = s.getLocaleName( lang )
            }
        }

        return (
            <Grid container spacing={4}>
                <Grid item {...full}>
                    <LabelItem enabled={!!(name)} title={ labels.name }>
                        { isString(name) ? ucFirst(name) : labels.empty }
                    </LabelItem>
                </Grid>
                <Grid item {...full}>
                    <LabelItem enabled={!!(type)} title={ labels.type }>
                        { isString(type) ? ucFirst(type) : labels.empty }
                    </LabelItem>
                </Grid>
                <Grid item {...half}>
                    <LabelItem enabled={!!(status)} title={ labels.status } labelIcon={indicator}>
                        { isString(status) ? ucFirst(status) : labels.empty }
                    </LabelItem>
                </Grid>
                <Grid item {...half}>
                    <LabelItem enabled={!!(publicAccess)} title={ labels.publicAccess} labelIcon={indicator}>
                        { isString(publicAccess) ? ucFirst(publicAccess) : labels.empty }
                    </LabelItem>
                </Grid>
            </Grid>
        );
    };

    getEntry = init =>
    {
        let empty = {} ;
        if( init instanceof ThingVO )
        {
            empty = init.toObject() ;
        }
        else if( init )
        {
            empty = { ...init } ;
        }
        return new PlaceVO(empty);
    };

    getLocale = () => this.props.locale.things.place;

    onEdit = item =>
    {
        const { onChange, thing } = this.props ;
        if( thing && item )
        {
            const {
                additionalType,
                name,
                publicAccess,
                status
            } = item ;

            thing.additionalType = additionalType ;
            thing.modified       = moment(new Date()).toISOString();
            thing.name           = name ;
            thing.publicAccess   = publicAccess ;
            thing.status         = status ;

            if( thing instanceof ThingVO )
            {
                thing.populate();
            }

            if( this._mounted )
            {
                this.forceUpdate();
            }

            if( onChange instanceof Function )
            {
                onChange(thing) ;
            }
        }
    }
}

Place.defaultProps =
{
    ...ThingContainer.defaultProps ,
    icon       : <PlaceIcon/> ,
    optionMode : 'edit'
};

Place.propTypes =
{
    ...ThingContainer.propTypes
};

export default compose(
    withStyles( styles ),
    withRouter,
    withConfig,
    withi18n,
    withDialogs
)( Place ) ;
