import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import format    from 'vegas-js-core/src/strings/fastformat'
import isBoolean from 'vegas-js-core/src/isBoolean'
import isString  from 'vegas-js-core/src/isString'

import SwipeableViews from 'react-swipeable-views'

import { Tab, Tabs, Typography } from '@material-ui/core'

import DialogError from '../../errors/DialogError'

import Form from '../forms/Form'

import PATCH from '../../net/PATCH'
import POST  from '../../net/POST'
import PUT   from '../../net/PUT'

import Method        from '../../net/Method'
import RequestStatus from '../../net/RequestStatus'

import Thing from '../../things/Thing'

import api  from '../../configs/api'
import i18n from '../../locale/fr'

import dialogType , { dialogTypes } from './dialogType'

import initDialog from './initDialog'

import CoreDialog from '../../components/dialogs/CoreDialog'

const styles = () => ({});

export class AddDialog extends CoreDialog
{
    constructor( props )
    {
        super( props );
        this.state    =
        {
            ...this.state,
            enabled : true ,
            tab     : 0
        };
    }

    agree = () =>
    {
        let {
            item,
            mapUri,
            method,
            mock,
            prepare,
            uri
        }  = this.props ;

        mock    = isBoolean(mock) ? mock : this.mock ;
        prepare = prepare || this.prepare ;

        let datas ;
        let path = uri ;

        const locale = this.getLocale() ;

        if( locale )
        {
            const {
                mapUri  : map,
                method  : met,
                mock    : flag,
                prepare : prep
            }
            = locale  ;

            if( map instanceof Function )
            {
                mapUri = map ;
            }

            if( met === Method.PATCH || met === Method.PUT || met === Method.POST )
            {
                method = met ;
            }

            if( isBoolean(flag) )
            {
                mock = flag ;
            }

            if( prep instanceof Function )
            {
                prepare = prep ;
            }
        }

        if( mapUri instanceof Function )
        {
            path = mapUri( item , uri ) ;
        }

        if( prepare instanceof Function )
        {
            try
            {
                datas = prepare( item , this.props ) ;
            }
            catch( er )
            {
                if( er instanceof DialogError)
                {
                    this.setState( { errors:er.errors , status : RequestStatus.FAIL } ) ;
                }
                else
                {
                    console.log( this + ' agree failed' , er ) ;
                }
                return null ;
            }
        }

        if( mock )
        {
            console.log( this + ' send' , path , datas ) ;
        }
        else
        {
            this.setState( { errors:null , status : RequestStatus.PROGRESS } ) ;
            switch( method )
            {
                case Method.PATCH :
                {
                    this.canceler = PATCH( api.url + path, { success : this._success, fail : this._fail, cancel : this._cancel, datas } ) ;
                    break ;
                }
                case Method.POST :
                {
                    this.canceler = POST( api.url + path, { success : this._success, fail : this._fail, cancel : this._cancel, datas } ) ;
                    break ;
                }
                default :
                {
                    this.canceler = PUT( api.url + path, { success : this._success, fail : this._fail, cancel : this._cancel, datas } ) ;
                }
            }
        }
    };

    getContent = () =>
    {
        const {
            errors,
            status,
            tab
        }
        = this.state ;

        let {
            item,
            name,
            theme,
            contentType,
            thingRef
        }
        = this.props ;

        const locale = this.getLocale() ;

        const progress = status === RequestStatus.PROGRESS ;

        let content ;

        const { elements, type } = locale ;

        contentType = contentType || type ;

        switch( contentType )
        {
            case dialogType.SWIPEABLE :
            {
                if( elements instanceof Array && elements.length > 0 )
                {
                    let tabs  = [] ;
                    let views = [] ;
                    elements.forEach( ( element , index ) =>
                    {
                        const { label, icon, elements } = element ;

                        tabs.push(
                            <Tab
                                key   = { name + 'tab_' + index }
                                icon  = { icon  }
                                label = { label }
                            />
                        );

                        views.push(
                            <Form
                                disabled = { progress }
                                elements = { elements }
                                errors   = { errors }
                                key      = { name + index }
                                name     = { name + index }
                                thing    = { item }
                                thingRef = { thingRef }
                            />
                        );

                        const { direction } = theme ;

                        content = (
                            <Fragment>
                                <Tabs
                                    disabled       = { progress }
                                    value          = { tab }
                                    onChange       = { this._changeTab }
                                    indicatorColor = "secondary"
                                    textColor      = "secondary"
                                    variant        = 'fullWidth'
                                >
                                    { tabs }
                                </Tabs>
                                <SwipeableViews
                                    axis     = { direction === 'rtl' ? 'x-reverse' : 'x' }
                                    disabled = { progress }
                                    index    = { tab }
                                >
                                    { views }
                                </SwipeableViews>
                            </Fragment>
                        );
                    })

                }
                break ;
            }
            case dialogType.FORM :
            default :
            {
                if( elements instanceof Array && elements.length > 0 )
                {
                    content = (
                    <Form
                        name     = { name }
                        disabled = { progress }
                        elements = { elements }
                        errors   = { errors }
                        thing    = { item }
                        thingRef = { thingRef }
                    />);
                }
            }

        }

        return content ;
    };

    getDescription = () =>
    {
        const { status } = this.state ;

        const locale = this.getLocale() ;

        const { description, fail, loading, success} = locale ;

        let text = '' ;

        switch( status )
        {
            case RequestStatus.FAIL :
            {
                text = fail ;
                break ;
            }
            case RequestStatus.PROGRESS :
            {
                text = loading ;
                break ;
            }
            case RequestStatus.SUCCESS :
            {
                text = success ;
                break ;
            }
            default :
            {
                text = description ;
            }
        }

        if( isString(text) )
        {
            return <Typography className='py-16' variant='body2'>{ text }</Typography>;
        }

        return null ;
    };

    get disabled()
    {
        return this.state.status === RequestStatus.PROGRESS ;
    }

    unmount = () =>
    {
        const { status } = this.state ;
        if( status === RequestStatus.PROGRESS && !!(this.canceler) )
        {
            this.canceler.cancel(this + ' cancel') ;
        }
    };

    _cancel = () =>
    {
        this.setState( { errors:null , status : RequestStatus.NEW  } ) ;
    };

    _changeTab = ( event , tab ) =>
    {
        const { item } = this.props ;
        if( item instanceof Thing )
        {
            const { subjectOf } = item ;
            item.clear() ;
            item.subjectOf = subjectOf ;
        }
        this.setState( { disabled:null , errors:null , item:item , tab:tab } ) ;
    };

    _fail = response =>
    {
        if( response )
        {
            const { data } = response ;
            if( data )
            {
                console.log( this + ' fail' , data ) ;

                const { message , status } = data ;

                if( status === 'error' )
                {
                    this.setState( { errors:message , status : RequestStatus.FAIL } ) ;
                    return ;
                }

                switch( message )
                {
                    case 'token revoked' :
                    {
                        break ;
                    }
                    default :
                    {
                        console.log( this + " failed, status:" + response.status + ", message:" , message );
                    }
                }
            }
        }
    };

    _success = response =>
    {
        const {
            closeOnSuccess,
            onAdd,
            redirect,
            history,
            uri
        } = this.props ;

        let { redirectUri } = this.props ;

        //const item = response && response.data && response.data.result ? response.data.result : null ;

        const { data : { result:item = null } = {} } = response ;

        if( redirect && item && item.hasOwnProperty('id') )
        {
            if( redirectUri )
            {
                redirectUri = format(redirectUri, item.id);
            }
            else
            {
                redirectUri = uri + ( item ? ('/' + item.id) : '' ) ;
            }
            history.push ( redirectUri ) ;
        }
        else
        {
            this.setState( { status:RequestStatus.SUCCESS } ) ;

            if( closeOnSuccess )
            {
                this.close() ;
            }

            if( onAdd instanceof Function )
            {
                onAdd( item ) ;
            }
        }
    };
}

AddDialog.defaultProps =
{
    ...CoreDialog.defaultProps,
    closeOnSuccess : true ,
    locale         : i18n.components.dialogs.add,
    mapUri         : null,
    method         : Method.POST,
    name           : 'add_dialog_' ,
    onAdd          : null ,
    redirect       : false ,
    redirectUri    : null
};

AddDialog.propTypes =
{
    ...CoreDialog.propTypes,
    contentType    : PropTypes.oneOf(dialogTypes),
    closeOnSuccess : PropTypes.bool ,
    mapUri         : PropTypes.func,
    member         : PropTypes.string ,
    method         : PropTypes.oneOf([Method.PATCH,Method.PUT,Method.POST]),
    onAdd          : PropTypes.func ,
    redirect       : PropTypes.bool ,
    redirectUri    : PropTypes.string
};

export default initDialog({ useLocale:false, styles , withTheme:true })( AddDialog ) ;