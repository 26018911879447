import React , { PureComponent } from 'react'

import clsx from 'clsx'

import PropTypes from 'prop-types'

import notEmpty  from 'vegas-js-core/src/strings/notEmpty'

import withConfig from '../../contexts/config/withConfig'
import withLang   from '../../contexts/i18n/withLang'

import getLocaleAlternativeHeadline from '../../things/getLocaleAlternativeHeadline'
import getMediaSource               from '../../things/getMediaSource'
import isString from 'vegas-js-core/src/isString';

const defaultImgStyle =
{
    height    : '100%'  ,
    objectFit : 'cover' ,
    width     : '100%'
} ;

class Picture extends PureComponent
{
    render = () =>
    {
        const { media } = this.props ;

        // console.log( this + ' render media' , media ) ;

        if( media )
        {
            let source ;
            let src ;

            if( isString( media ) )
            {
                src = media ;
            }
            else
            {
                const { contentUrl , thumbnailUrl } = media ;
                if( notEmpty( contentUrl ) )
                {
                    src = contentUrl ;
                    source = getMediaSource( media ) ;
                }
                else if( notEmpty( thumbnailUrl ) )
                {
                    src = thumbnailUrl ;
                }
            }

            if( notEmpty(src) )
            {
                const { className , config , lang , onLoad , style , variant } = this.props ;
                const { avatar , icon , image } = config ;

                let resizeH ;
                let resizeW ;

                switch( variant )
                {
                    case 'avatar' :
                    {
                        resizeH = avatar.maxHeight ;
                        resizeW = avatar.maxWidth ;
                        break ;
                    }
                    case 'icon' :
                    {
                        resizeH = icon.maxHeight ;
                        resizeW = icon.maxWidth ;
                        break ;
                    }
                    case 'image' :
                    default :
                    {
                        resizeH = image.maxHeight ;
                        resizeW = image.maxWidth ;
                        break ;
                    }
                }
                const resize = '?w=' + resizeW + '&h=' + resizeH ;

                const alt = getLocaleAlternativeHeadline( media , lang ) || "" ;

                let sourceElements ;
                if( source )
                {
                    sourceElements = source.map( element => <source key={element.encodingFormat} srcSet={ element.contentUrl + resize } type={ element.encodingFormat } /> ) ;
                }

                const { imgClassName , imgStyle } = this.props ;

                const img = (
                    <img
                        alt       = { alt }
                        className = { imgClassName }
                        src       = { src + resize }
                        style     = {{ ...defaultImgStyle , ...imgStyle }}
                    />
                );

                return(
                    <picture
                        className = { clsx( 'flex h-full w-full' , className ) }
                        onLoad    = { onLoad }
                        style     = { style }
                    >
                        { sourceElements }
                        { img }
                    </picture>
                );
            }
        }

        return null ;
    }
}

Picture.defaultProps =
{
    imgClassName : null ,
    imgStyle     : null ,
    media        : null,
    onLoad       : null,
    variant      : 'image'
};

Picture.propTypes =
{
    className    : PropTypes.string,
    imgClassName : PropTypes.string,
    imgStyle     : PropTypes.object,
    media        : PropTypes.oneOfType([ PropTypes.object , PropTypes.string ]),
    onLoad       : PropTypes.func,
    style        : PropTypes.object,
    variant      : PropTypes.oneOf([ 'avatar' , 'icon' , 'image' ]).isRequired
};

export default withConfig( withLang( Picture ) ) ;
