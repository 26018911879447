import React from 'react'

import clsx from 'clsx'

import PropTypes from 'prop-types'

import isNumber from 'vegas-js-core/src/isNumber'
import notEmpty from 'vegas-js-core/src/strings/notEmpty'

import { Tooltip, Typography } from '@material-ui/core'

import ColorIcon from '@material-ui/icons/Group'
import EmptyIcon from '@material-ui/icons/Texture'

import withi18n from '../../contexts/i18n/withi18n'

import getHorizontalElements from '../getHorizontalElements'

import Flags from '../things/Flags'

import { WordCell } from './WordCell'

class TeamCell extends WordCell
{
    getAlternateNames = ( { className = 'mx-4' } = {}  ) =>
    {
        const thing = this.getThing() ;
        if( thing )
        {
            const { alternateName } = thing ;
            if( alternateName )
            {
                return <Flags className={className} thing={alternateName} />
            }
        }
        return null ;
    }

    getColor = ( { className = 'mx-4' } = {}  ) =>
    {
        const thing = this.getThing() ;
        if( thing )
        {
            let icon ;

            const {
                ColorIconComponent ,
                EmptyIconComponent ,
                empty
            } = this.props ;

            let { color } = thing ;
            if( notEmpty( color) )
            {
                color = '#' + color ;
                icon = (
                    <Tooltip placement='top' title={color}>
                        <ColorIconComponent style={{ color }}/>
                    </Tooltip>
                );
            }
            else if( empty )
            {
                icon = <EmptyIconComponent color='disabled' /> ;
            }

            if( icon )
            {
                return (
                    <div className={ clsx( 'flex flex-row items-center ' , className ) } >
                        { icon }
                    </div>
                )
            }
        }
        return null ;
    }

    getIdentifier = ( { className = 'mx-4' } = {}  ) =>
    {
        const thing = this.getThing() ;
        if( thing )
        {
            const { identifier } = thing ;
            if( isNumber(identifier) || notEmpty(identifier) )
            {
                return (
                    <div className={ clsx( 'flex flex-row items-center' , className)  } >
                        <Typography className='px-8 py-2 bg-gray-500 text-white rounded-full' variant='caption'>{ identifier }</Typography>
                    </div>
                )
            }
        }
        return null ;
    }

    getContent = () =>
    {
        const { thing } = this.props;
        if ( thing )
        {
            const elements = [ 'color' , 'identifier' , 'alternateNames' ] ;
            return getHorizontalElements( { elements , factory:this } ) ;
        }
        return null;
    };


    getLocale = () => this.props.locale.display.cells.team;
}

TeamCell.defaultProps =
{
    ...WordCell.defaultProps,
    alternateNames     : true,
    color              : true,
    ColorIconComponent : ColorIcon ,
    empty              : false,
    EmptyIconComponent : EmptyIcon ,
    identifier         : true,
    subtitleVariant    : 'name',
    typable            : false
};

TeamCell.propTypes =
{
    ...WordCell.propTypes,
    alternateName      : PropTypes.bool,
    color              : PropTypes.bool,
    ColorIconComponent : PropTypes.elementType.isRequired,
    empty              : PropTypes.bool,
    EmptyIconComponent : PropTypes.elementType.isRequired,
    identifier         : PropTypes.bool
};

export default withi18n( TeamCell ) ;
