import languages from './languages'

import fr from './fr'

const locale =
{
    // ----------

    languages,

    // ----------

    fr
};

export default locale ;
