import Thing from './Thing'

/**
 * The elevation.
 * @memberOf things
 */
class Elevation extends Thing
{
    /**
     * Creates a new Elevation instance.
     * @constructor
     * @param object The generic object to initialize the object.
     */
    constructor( object = null )
    {
        super() ;

        /**
         * The elevation gain.
         * @type {number|string}
         */
        this.gain = null ;

        /**
         * The elevation loss.
         * @type {number|string}
         */
        this.loss = null ;

        /**
         * The maximum elevation.
         * @type {number|string}
         */
        this.maxValue = null ;

        /**
         * The minimum elevation.
         * @type {number|string}
         */
        this.minValue = null ;

        this.set( object ) ;
    }


    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        super.clear() ;
        this.gain     =
        this.loss     =
        this.maxValue =
        this.minValue = null ;
        return this ;
    }

    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new Elevation( this.toObject() ) ;
    }
    
    /**
     * Populate the object with specific behaviors or types. Launched by default in the constructor and in the set methods.
     * @returns {Thing} The current reference.
     */
    populate()
    {
        return this ;
    }
    
    /**
     * Returns the generic object representation of the item.
     * @return {Object} The generic object representation of the item.
     */
    toObject()
    {
        let {
            gain,
            loss,
            maxValue,
            minValue
        } = this ;

        return {
            ...super.toObject() ,
            gain,
            loss,
            maxValue,
            minValue
        }
    }
}

export default Elevation ;