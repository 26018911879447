import React, { Component } from 'react'

import PropTypes from 'prop-types'

import clsx from 'clsx'

import { withStyles } from '@material-ui/core/styles'

import { Typography } from '@material-ui/core'

import
{
    primaryColor,
    warningColor,
    dangerColor,
    successColor,
    infoColor,
    roseColor
}
from "../../configs/colors"

export const colors =
[
    "primary",
    "warning",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
];

const styles = () =>
({
    label :
    {
    },
    root:
    {
        alignItems     : 'center',
        color          : 'white',
        float          : 'left',
        fontSize       : 11,
        fontWeight     : '500',
        justifyContent : 'center',
        marginRight    : 3,
        borderRadius   : 12,
        padding        : "4px 12px",
        textAlign      : 'center',
        textTransform  : "uppercase",
        whiteSpace     : 'nowrap'
    },
    primary:
    {
        backgroundColor: primaryColor
    },
    warning:
    {
        backgroundColor: warningColor
    },
    danger:
    {
        backgroundColor: dangerColor
    },
    success:
    {
        backgroundColor: successColor
    },
    info:
    {
        backgroundColor: infoColor
    },
    rose:
    {
        backgroundColor: roseColor
    },
    gray:
    {
        backgroundColor: "#6c757d"
    }
});

class Badge extends Component
{
    render = () =>
    {
        const {
            classes,
            className,
            color,
            children,
            style
        } = this.props;
        return (
            <Typography
                className = { clsx(
                    classes.root,
                    (classes[color] ? classes[color] : classes.grey),
                    className
                )}
                style = { style }
            >
                { children }
            </Typography>
        );
    }
}

Badge.defaultProps =
{
    className : null ,
    color     : "gray" ,
    style     : null
};

Badge.checkColor = ( color , defaultColor = 'gray' ) => (colors.indexOf(color) > -1) ? color : defaultColor ;

Badge.propTypes =
{
    children  : PropTypes.node ,
    classes   : PropTypes.object.isRequired ,
    className : PropTypes.string,
    color     : PropTypes.oneOf(colors),
    style     : PropTypes.object
};

export default withStyles( styles ) ( Badge ) ;
