import React from 'react'

import PropTypes from 'prop-types'

import ucWords from 'vegas-js-core/src/strings/ucWords'

import clsx from 'clsx'

import { withStyles } from '@material-ui/core/styles'

import {
    CircularProgress,
    Icon,
    IconButton,
    Tooltip,
    Typography
} from '@material-ui/core'

import Toolbar from 'react-big-calendar/lib/Toolbar'
import { navigate } from 'react-big-calendar/lib/utils/constants'

import Animate from '../../transitions/Animate'

import withi18n    from '../../contexts/i18n/withi18n'
import withLoading from '../../contexts/loading/withLoading'

import autumn from './assets/calendar/autumn.jpg'
import spring from './assets/calendar/spring.jpg'
import summer from './assets/calendar/summer.jpg'
import winter from './assets/calendar/winter.jpg'

const headerHeight = 200 ;

const styles = () => (
{
    root :
    {
        height            : headerHeight,
        minHeight         : headerHeight,
        display           : 'flex',
        //backgroundImage   : `url(${background})`,
        backgroundColor   : '#222222',
        color             : '#FFFFFF',
        backgroundSize    : 'cover',
        backgroundPosition: '0 50%',
        backgroundRepeat  : 'no-repeat',
        position          : 'relative',
        '&:before'        :
        {
            content   : "''",
            position  : 'absolute',
            top       : 0,
            right     : 0,
            bottom    : 0,
            left      : 0,
            zIndex    : 1,
            background: 'rgba(0, 0, 0, 0.45)'
        },
        '&.jan' :
        {
            backgroundImage   : `url(${winter})`,
            backgroundPosition: '0 85%'
        },
        '&.feb' :
        {
            backgroundImage   : `url(${winter})`,
            backgroundPosition: '0 85%'
        },
        '&.mar' :
        {
            backgroundImage   : `url(${spring})`,
            backgroundPosition: '0 40%'
        },
        '&.apr' :
        {
            backgroundImage   : `url(${spring})`,
            backgroundPosition: '0 40%'
        },
        '&.may' :
        {
            backgroundImage   : `url(${spring})`,
            backgroundPosition: '0 40%'
        },
        '&.jun' :
        {
            backgroundImage   : `url(${summer})`,
            backgroundPosition: '0 80%'
        },
        '&.jul' :
        {
            backgroundImage   : `url(${summer})`,
            backgroundPosition: '0 80%'
        },
        '&.aug' :
        {
            backgroundImage   : `url(${summer})`,
            backgroundPosition: '0 80%'
        },
        '&.sep' :
        {
            backgroundImage   : `url(${autumn})`,
            backgroundPosition: '0 40%'
        },
        '&.oct' :
        {
            backgroundImage   : `url(${autumn})`,
            backgroundPosition: '0 40%'
        },
        '&.nov' :
        {
            backgroundImage   : `url(${autumn})`,
            backgroundPosition: '0 40%'
        },
        '&.dec' :
        {
            backgroundImage   : `url(${winter})`,
            backgroundPosition: '0 85%'
        }
    }
});

const months =
[
    'jan',
    'feb',
    'mar',
    'apr',
    'may',
    'jun',
    'jul',
    'aug',
    'sep',
    'oct',
    'nov',
    'dec'
];

class CalendarHeader extends Toolbar
{
    constructor( props )
    {
        super( props ) ;
        this.next     = this.navigate.bind(null, navigate.NEXT);
        this.previous = this.navigate.bind(null, navigate.PREVIOUS);
    }

    componentDidMount()
    {
        document.addEventListener("keydown", this.keyPress, false);
    }

    componentWillUnmount()
    {
        document.removeEventListener("keydown", this.keyPress, false);
    }

    getLocale = () => this.props.locale.components.headers.calendar;

    getContent = () =>
    {
        const { label } = this.props;

        const locale = this.getLocale() ;

        return (
        <div className="flex flex-1 flex-col p-12 justify-between z-10">
            <div className="flex flex-col items-center justify-between sm:flex-row">

                <div className="flex items-center my-16 sm:mb-0">
                    <Animate enter="transition.expandIn" delay={300}>
                        <Icon className = 'text-32 mx-12' color='inherit'>today</Icon>
                    </Animate>
                    <Animate enter="transition.slideLeftIn" delay={300}>
                        <Typography
                            className = 'capitalize'
                            color     = 'inherit'
                            variant   = 'h6'
                        >
                            { locale.title }
                        </Typography>
                    </Animate>
                </div>

                <div className="flex items-center">
                    <Tooltip title={ locale.today }>
                        <div>
                            <Animate enter="transition.expandIn" delay={500}>
                                <IconButton
                                    aria-label = 'today'
                                    color      = 'inherit'
                                    onClick    = { this.navigate.bind(null, navigate.TODAY) }
                                >
                                    <Icon>today</Icon>
                                </IconButton>
                            </Animate>
                        </div>
                    </Tooltip>
                    { this.getOptions() }
                </div>
            </div>

            <Animate delay={500}>
                <div className="flex items-center justify-center">

                    <Tooltip title="Previous">
                        <IconButton
                            aria-label = "Previous"
                            color      = 'inherit'
                            onClick    = { this.navigate.bind(null, navigate.PREVIOUS) }
                        >
                            <Icon color='inherit'>chevron_left</Icon>
                        </IconButton>
                    </Tooltip>

                    <Typography
                        className = 'text-center font-semibold'
                        color     = 'inherit'
                        variant   = 'subtitle1'
                    >
                        { ucWords(label) }
                    </Typography>

                    <Tooltip title="Next">
                        <IconButton
                            aria-label = "Next"
                            color      = 'inherit'
                            onClick    = { this.navigate.bind(null, navigate.NEXT) }
                        >
                            <Icon color='inherit'>chevron_right</Icon>
                        </IconButton>
                    </Tooltip>
                </div>
            </Animate>
        </div>
        )
    };

    getLoading = () =>
    {
        return (
        <div className="flex flex-1 flex-col p-12 justify-center items-center z-10">
            <CircularProgress
                style     = {{ color: 'white' , marginRight : 12 }}
                size      = { 36 }
                thickness = { 5 }
            />
        </div>);
    };

    getOptions = () =>
    {
        const {
            view,
            views
        }  = this.props ;

        if ( views instanceof Array && views.length > 1 )
        {
            const locale = this.getLocale() ;
            return views.map( name => (
            <Tooltip title={locale[name].title} key={name}>
                <div>
                    <Animate enter="transition.expandIn" delay={500}>
                        <IconButton
                            aria-label = { name }
                            color      = 'inherit'
                            onClick    = { () => this.props.onView(name) }
                            disabled   = { view === name }
                        >
                            <Icon color='inherit'>{locale[name].icon}</Icon>
                        </IconButton>
                    </Animate>
                </div>
            </Tooltip>
            ) );
        }
    };

    keyPress = ( event ) =>
    {
        if( event )
        {
            const { code } = event ;
            switch( code )
            {
                case 'ArrowLeft' :
                {
                    this.previous() ;
                    break ;
                }

                case 'ArrowRight' :
                {
                    this.next() ;
                    break ;
                }

                default :
                {
                    // do nothing
                }
            }
        }
    };

    render()
    {
        const {
            classes,
            date,
            loading
        } = this.props;

        return (
        <div className={clsx( classes.root , months[date.getMonth()] ) }>
            { loading ? this.getLoading() : this.getContent() }
        </div>);
    }
}

CalendarHeader.defaultProps =
{
    classes    : {} ,
    className  : null,
    lang       : 'en',
    loading    : false,
    locale     : {},
    style      : null
};

CalendarHeader.propTypes =
{
    classes    : PropTypes.object.isRequired ,
    className  : PropTypes.string,
    lang       : PropTypes.string,
    loading    : PropTypes.bool,
    locale     : PropTypes.object.isRequired ,
    notifiable : PropTypes.bool,
    style      : PropTypes.object
};

export default withStyles(styles)( withi18n( withLoading(CalendarHeader) ) );
