import React, { Component } from 'react'

import PropTypes from 'prop-types'

import isString from 'vegas-js-core/src/isString'

import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'

import withLocale from '../../contexts/i18n/withLocale'
import withUser   from '../../contexts/user/withUser'

import { withStyles } from '@material-ui/core/styles'

import {
    Avatar,
    Button,
    ClickAwayListener,
    Grow,
    ListItemText,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Typography,
} from '@material-ui/core'

const styles = () =>
({
    avatar :
    {
        width  : 30,
        height : 30
    }
});

class AccountButton extends Component
{
    anchorEl = null ;
    state    = { open : false };

    close = () =>
    {
        this.setState( { open:false } );
    };

    getLocale = () => this.props.locale.components.buttons.account ;

    getPopper = () =>
    {
        const { open } = this.state ;

        const locale = this.getLocale() ;

        let { menu } = locale ;

        if( menu instanceof Array )
        {
            menu = menu.map( ( item , index ) =>
            {
                const { action, icon, label, options } = item ;
                return (
                    <MenuItem
                        key       = { 'menu_item_' + index  }
                        className = "flex items-center hover:no-underline no-underline "
                        { ...options }
                        onClick   = { () =>
                        {
                            this.close();
                            if ( action instanceof Function )
                            {
                                action();
                            }
                        }}
                    >
                        { icon }
                        {
                            label &&
                            <ListItemText
                                primary                = { label }
                                primaryTypographyProps = {{
                                    className : 'text-xl pl-0 hover:no-underline no-underline font-medium' ,
                                    variant   : 'button'
                                }}
                            />
                        }
                    </MenuItem>);
            });

            menu = (
            <ClickAwayListener onClickAway = { this.close }>
                <MenuList>
                    { menu }
                </MenuList>
            </ClickAwayListener>
            );
        }

        return (
            <Popper
                anchorEl      = { this.anchorEl }
                disablePortal = { true }
                onClose       = { this.close }
                open          = { !!(open) }
                transition    = { true }
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        { ...TransitionProps }
                        id    = "menu-list-grow"
                        style = {{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper elevation={2}>
                            { menu }
                        </Paper>
                    </Grow>
                )}
            </Popper>
        );
    };

    componentWillUnmount()
    {
        clearTimeout( this.timeout ) ;
    }

    open = event =>
    {
        clearTimeout( this.timeout ) ;
        if( event )
        {
            const { currentTarget } = event ;
            this.timeout = setTimeout
            (
                () => { this.setState( { open:!!(currentTarget) } ); } ,
                250
            );
        }
    };

    render = () =>
    {
        const { user } = this.props ;

        // console.log( this + ' render' , user ) ;

        if( user )
        {
            let fullname = [] ;
            let initials = [] ;

            let {
                familyName,
                givenName,
                image,
                team
            } = user ;

            if( givenName )
            {
                fullname.push(givenName);
                initials.push(givenName.charAt(0));
            }

            if( user.familyName )
            {
                fullname.push( familyName ) ;
                initials.push( familyName.charAt(0)) ;
            }

            fullname = fullname.join(' ') ;
            initials = initials.join('') ;

            if( team )
            {
                const { name } = team ;
                team = name ;
            }

            return (
                    <Button
                        className = "h-64"
                        buttonRef = { node => { this.anchorEl = node; } }
                        onClick   = { this.open }
                    >
                        {
                            isString(image)
                                ? ( <Avatar variant="rounded" className="mr-12" alt="user photo" src={image}/>  )
                                : ( <Avatar variant="rounded" className="mr-12">{initials}</Avatar> )
                        }

                        <div className="hidden md:flex flex-col mx-8 items-start">
                            <Typography variant='body2' component="span" className="normal-case font-medium flex">
                                {fullname}
                            </Typography>
                            <Typography variant='caption' className="capitalize" color="textSecondary">
                                {team}
                            </Typography>
                        </div>
                        <KeyboardArrowDown
                            className = "text-3xl ml-12 hidden sm:flex"
                            variant   = "action"
                        />
                        { this.getPopper() }
                    </Button>
            );
        }

        return null ;
    }
}

AccountButton.defaultProps =
{
    locale : null
};

AccountButton.propTypes =
{
    locale : PropTypes.object.isRequired
};

export default withStyles(styles)( withLocale( withUser( AccountButton ) ) ) ;
