import React from 'react'

import clsx from 'clsx'

import notEmpty from 'vegas-js-core/src/strings/notEmpty'

import PlaceIcon from '@material-ui/icons/Place'

import ThingComponent from '../things/ThingComponent'

import getLocaleGeoCoordinatesLabel from '../../things/getLocaleGeoCoordinatesLabel'

import GeoCoordinates from '../../things/GeoCoordinates'

import withi18n  from '../../contexts/i18n/withi18n'
import withSnack from '../../contexts/snack/withSnack'

export class GeoLabel extends ThingComponent 
{  
    getLocale = () => this.props.locale.display.labels.geo;
    
    render = () =>
    {
        let geo ;
        
        const thing = this.getThing();
        if( thing instanceof GeoCoordinates )
        {
            geo = thing ;
        }
        else if ( thing ) 
        {
            const { geo:coord } = thing;
            if( coord instanceof GeoCoordinates )
            {
                geo = coord ;
            }
        }
        
        if ( geo instanceof GeoCoordinates ) 
        {
            const { latitude, longitude } = geo;
            if ( latitude && longitude ) 
            {
                const locale = this.getLocale() ;
                const label  = getLocaleGeoCoordinatesLabel( geo, { pattern:locale.label } )  ;
                if ( notEmpty(label) )
                {
                    const { className } = this.props;
                    return (
                        <div className={ clsx("flex flex-row items-center p-8", className)} >
                            <div className="flex-initial">{ this.getIcon({ label }) } </div>
                            <div className="flex flex-col pl-8">
                                { this.getTitle() }
                                { this.getLabel(label) }
                            </div>
                        </div>
                    );
                }
            }
        }
        return null;
    }
}
    
GeoLabel.defaultProps =
{
    ...ThingComponent.defaultProps,
    icon : <PlaceIcon fontSize='small' />
}

GeoLabel.propTypes =
{
    ...ThingComponent.propTypes
}

export default withSnack( withi18n( GeoLabel ) );