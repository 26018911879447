import React from 'react'

import clsx from 'clsx'

import get       from 'vegas-js-core/src/objects/get'
import isNotNull from 'vegas-js-core/src/isNotNull'
import notEmpty  from 'vegas-js-core/src/strings/notEmpty'
import trimEnd   from 'vegas-js-core/src/strings/trimEnd'

import {
    Redirect,
    Route,
    Switch,
} from 'react-router-dom'

import { Badge, Tab, Tabs, Typography } from '@material-ui/core'

import ThingSubContainer from '../../display/containers/ThingSubContainer'

export const styles = theme => (
{
    tab :
    {
        backgroundColor : 'white',
        border          : 'dashed',
        borderColor     : theme.palette.divider,
        borderRadius    : "3px",
        borderWidth     : 'thin',
        boxSizing       : 'border-box',
        flexShrink      : 0,
        lineHeight      : "16px",
        marginLeft      : "4px",
        marginRight     : "4px",
        overflow        : 'visible',
        padding         : "4px 8px"
    },
    tabSelected :
    {
        backgroundColor : theme.palette.primary.contrastText,
        color           : theme.palette.primary.dark,
        fontWeight      : '800' ,
        transition      : '0.1s background-color 0.05s'
    },
    tabWrapper :
    {
        //
    },
    tabs :
    {
        flexWrap  : 'wrap',
        minHeight : 'unset !important',
        padding   : '4px 0px'
    }
}) ;

class ThingTabsSubContainer extends ThingSubContainer
{
    constructor( props )
    {
        super( props );
        this.routeRefs = {} ;
    }

    getBadgeValue = ( { id , badge } = {} ) =>
    {
        if( badge )
        {
            if( notEmpty(badge) )
            {
                const { thing } = this.props ;
                if( thing )
                {
                    return get( thing , badge ) ;
                }
            }
            else if( badge instanceof Function )
            {
                return badge( { id , ref:this } ) ;
            }
        }
        return null ;
    };

    getCurrentPath = ( { id , uri } = {} ) => uri + '/' + id ;

    getPath = () => this.props.path ;

    getRouteElement = ( { RouteComponent, clazz, id, uri, ...options } = {} ) =>
    {
        const { thing } = this.props ;
        if( thing )
        {
            return (
                <RouteComponent
                    clazz    = { clazz || this.getRouteClazz({ id }) }
                    onChange = { this.change }
                    path     = { this.props.path }
                    thing    = { thing }
                    uri      = { this.getRouteUri({ id , uri }) }
                    { ...options }
                />
            )
        }
        return null ;
    };

    getRouteClazz = () => this.props.clazz ;

    getRouteUri = ( { uri } = {} ) => uri ;

    getRoutes = ( uri , path ) =>
    {
        if( this.showContent() )
        {
            let { routes } = this.props ;

            if( routes instanceof Array && routes.length > 0 )
            {
                routes = routes.map( entry =>
                {
                    if( entry )
                    {
                        let {
                            id,
                            RouteComponent,
                            clazz     = null  ,
                            exact     = false ,
                            sensitive = false ,
                            strict    = false ,
                            validator ,
                            ...options
                        } = entry ;

                        if( this.validate( id , validator ) )
                        {
                            let url = uri + '/' + id ;

                            let render = () => this.getRouteElement({
                                RouteComponent ,
                                clazz,
                                id ,
                                path ,
                                uri ,
                                ...options
                            }) ;

                            return (
                                <Route
                                    exact     = { exact }
                                    key       = { id }
                                    path      = { url }
                                    render    = { render }
                                    sensitive = { sensitive }
                                    strict    = { strict }
                                />
                            );
                        }
                    }
                    return null ;
                })
                .filter( isNotNull ) ;
            }

            if( routes instanceof Array && routes.length > 0 )
            {
                return <Switch>{ routes }</Switch> ;
            }
        }
        return null ;
    };

    getTabIcon = ( { icon } = {} ) => icon ;

    getTabItem = ( { badge , icon , id , label , validator } = {} , index = 0 ) =>
    {
        if( this.validate( id , validator ) )
        {
            const { classes , disabled } = this.props ;
            return (
            <Tab
                aria-label = { id }
                classes    =
                {{
                    root      : classes.tab,
                    selected  : clsx(classes.tabSelected,'shadow-xl'),
                    wrapper   : classes.tabWrapper
                }}
                disabled = { disabled }
                key      = { 'tab-' + index }
                icon     = { this.getTabIcon( { id , icon } ) }
                label    = { this.getTabLabel( { badge , id , label } ) }
                value    = { this.getTabUri( id ) }
            />);
        }
        return null ;
    };

    getTabLabel = ( { badge , id , label } = {} ) =>
    {
        if( notEmpty( label ) )
        {
            // do nothing
        }
        else if( id )
        {
            const { tabs:labels = {} } = this.getLocale() || {} ;
            if( labels && labels.hasOwnProperty(id) && notEmpty(labels[id]) )
            {
                label = labels[id] ;
            }
        }
        else
        {
            label = null ;
        }

        if( notEmpty(label) )
        {
            const {
                labelClassName ,
                labelOptions
            } = this.props ;

            label = (
                <Typography
                    className = { clsx( 'font-semibold px-12' , labelClassName ) }
                    variant   = 'caption'
                    { ...labelOptions }
                >
                    { label }
                </Typography>
            )

            badge = this.getBadgeValue( { id , badge } ) ;
            if( badge !== null )
            {
                const { badgeOptions } = this.props ;
                label = (
                    <Badge
                        badgeContent = { badge }
                        classes      = {{ badge:'text-base' }}
                        color        = 'secondary'
                        overlap      = 'rectangle'
                        showZero     = { true }
                        { ...badgeOptions }
                    >
                        { label }
                    </Badge>
                )
            }

            return label ;
        }

        return null ;
    };

    getTabUri = id => this.props.uri + '/' + id ;

    getTabs = () =>
    {
        if( this.showContent() )
        {
            let { tabs } = this.props ;
            if( tabs instanceof Array && tabs.length > 0 )
            {
                tabs = tabs.map( this.getTabItem ).filter( isNotNull ) ;
                if( tabs instanceof Array && tabs.length > 0 )
                {
                    const { classes , location } = this.props ;

                    let route = location.pathname.split('/') ;

                    route.shift() ;
                    route = "/" + route.join("/") ;

                    return (
                        <Tabs
                            classes       = {{ indicator : classes.none , root:classes.tabs }}
                            onChange      = { ( event , value ) => { this.props.history.push( value ) ; }}
                            value         = { route }
                            scrollButtons = 'desktop'
                            variant       = 'fullWidth'
                        >
                            { tabs }
                        </Tabs>
                    );
                }
            }
        }
        return null  ;
    };

    populateUri = uri => uri ;

    render = () =>
    {
        let {
            className ,
            first,
            location,
            style,
            uri
        } = this.props ;

        const path = this.getPath() || {} ;

        uri = this.populateUri( uri ) ;

        let { pathname } = location || {} ;

        if( notEmpty(pathname))
        {
            pathname = trimEnd( pathname , ['/'] ) ;
            if( pathname === uri && notEmpty(first) )
            {
                return <Redirect to={ uri + '/' + first } />
            }
        }

        return (
            <div
                className = { clsx( 'flex flex-col flex-1' , className ) }
                style     = { style }
            >
                <div className='flex flex-col px-8 py-4'>
                    { this.getTabs() }
                </div>
                <div className='flex flex-auto'>
                    { this.getRoutes( uri , path ) }
                </div>
            </div>
        ) ;
    }

    showContent = () => this.props.thing !== null ;

    validate = ( id , validator ) =>
    {
        if( notEmpty(validator) && this.hasOwnProperty(validator) && this[validator] instanceof Function )
        {
            return this[validator]( id ) ;
        }
        else if( validator instanceof Function )
        {
            return validator( id , this ) ;
        }
        else
        {
            return true ;
        }
    }
}

ThingTabsSubContainer.defaultProps =
{
    ...ThingSubContainer.defaultProps,
    first  : null ,
    routes : [] ,
    tabs   : [] ,
};

ThingTabsSubContainer.propTypes =
{
    ...ThingSubContainer.propTypes
};

export default ThingTabsSubContainer ;
