import React from 'react'

import clsx from 'clsx'

import PropTypes from 'prop-types'

import isString from 'vegas-js-core/src/isString'

import { Fab, Tooltip } from '@material-ui/core'

import DialogContainer from '../../components/containers/DialogContainer'

class ThingButton extends DialogContainer
{
    click = () => null ;

    render = () =>
    {
        let {
            className,
            classes,
            disabled,
            color,
            icon,
            onClick,
            size
        } = this.props;

        const locale = this.getLocale() ;

        let button = (
        <Fab
            className = { classes.button }
            disabled  = { disabled }
            color     = { color }
            onClick   = { onClick || this.click }
            size      = { size }
        >
            { icon }
        </Fab>);

        if( !disabled && locale )
        {
            const { tooltip } = locale ;
            if( isString(tooltip) )
            {
                const { toolTipProps } = this.props ;
                button = (
                <Tooltip
                    placement = 'left'
                    title     = { tooltip }
                    { ...toolTipProps }
                >
                    { button }
                </Tooltip>
                );
            }
        }

        return (
        <div className={ clsx( classes.root , className ) }>
            { button }
        </div>) ;
    };
}

ThingButton.defaultProps =
{
    ...DialogContainer.defaultProps,
    color        : 'primary' ,
    disabled     : false,
    icon         : null,
    onClick      : null,
    size         : 'small',
    toolTipProps : null
};

ThingButton.propTypes =
{
    ...DialogContainer.propTypes,
    color        : PropTypes.string,
    disabled     : PropTypes.bool,
    icon         : PropTypes.element,
    onClick      : PropTypes.func,
    size         : PropTypes.string,
    toolTipProps : PropTypes.object
};

export default ThingButton ;
