import React from "react"

import GridIcon from '@material-ui/icons/Apps'
import ListIcon from '@material-ui/icons/List'

import Person      from '../person/Person'
import PeopleGrid  from '../../display/grids/PeopleGrid'
import PeopleList from '../../display/lists/PeopleList'
import View        from '../../display/views/TabsView'

import initView from '../../display/views/initView'

import api from '../../configs/api'

const styles = () => ({}) ;

const path = api.people ;

const { url:uri } = path ;

class People extends View
{
    getLocale = () => this.props.locale.views.people ;
}

People.defaultProps =
{
    ...View.defaultProps,
    first  : 'grid' ,
    path,
    routes :
    [
        { id : 'grid'   , Component : PeopleGrid , uri , backPath:'/grid' } ,
        { id : 'list'   , Component : PeopleList , uri , backPath:'/list' } ,
        { id : 'person' , component : Person     , path : uri + '/:id' }
    ],
    tabs :
    [
        { id : 'grid' , icon : <GridIcon /> } ,
        { id : 'list' , icon : <ListIcon /> }
    ]
};

People.propTypes =
{
    ...View.propTypes
};

export default initView( People , styles );