import React, { Fragment } from 'react'

import clsx from 'clsx'

import isNotNull from 'vegas-js-core/src/isNotNull'
import notEmpty  from 'vegas-js-core/src/strings/notEmpty'
import ucFirst   from 'vegas-js-core/src/strings/ucFirst'

const getElements = ( { dividerFactory = null , defaultClassName = null } ) =>
({ 
    className    = null , 
    elements     = null, 
    divider      = true , 
    dividerProps = { light:true } , 
    factory      = null , 
    prefix       = 'item' 
} = {} ) =>
{
    if( elements instanceof Array )
    {
        let length  = elements.length ;
        if( length > 0 )
        {
            elements = elements.map( element => 
            {
                let item = null ;
                if( notEmpty(element) )
                {
                    let definition ;
                    
                    if ( factory )
                    {
                        if( factory[element] )
                        {
                            definition = factory[element] ;
                        }
                        else
                        {
                            const getKey = 'get' + ucFirst(element) ;
                            if( factory[getKey] )
                            {
                                definition = factory[getKey] ;
                            }
                        }
                    }
                    if( definition instanceof Function )
                    {
                        item = definition() ;    
                    }
                    else
                    {
                        item = definition ;
                    }
                }
                else if( element )
                {
                    item = element ;
                }
                
                return item ;
            })
            .filter( isNotNull ) ;
            
            let length = elements.length ;
            if( length > 0 )
            {
                let cache ; 
                elements = elements.map( ( element , key ) =>
                {
                    let separator ;
                    if( divider && (key < (length-1))  ) 
                    {
                        if( !cache && (dividerFactory instanceof Function))
                        {
                            cache = dividerFactory({ props:dividerProps }) ;
                        }
                        separator = cache ;
                    }
                    
                    return(
                        <Fragment key={ prefix + '-' + key} >
                            { element }
                            { separator }
                        </Fragment>
                    )
                });
                
                if( elements.length > 0 )
                {
                    return <div className={ clsx( defaultClassName, className ) }>{ elements }</div>
                }
            }
        }
    }
    return null ;
}

export default getElements ;
