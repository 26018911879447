export default
{
    description : 'Veuillez sélectionner une organisation ci-dessous.' ,
    disagree    : 'Fermer' ,
    empty       : 'Aucune organisation disponible.' ,
    fail        : 'Impossible de charger les organisations, veuillez relancer un peu plus tard.',
    loading     : 'Chargement en cours...' ,
    search      : 'Rechercher une organisation ...' ,
    title       : 'Sélectionner une organisation'
};
