import React , { Component , Fragment } from 'react'

import clsx from 'clsx'

import PropTypes from 'prop-types'

import initApp from '../../../../../../contexts/app/initApp'

import { withStyles } from '@material-ui/core/styles'

import { Collapse, Grid, IconButton, Paper } from '@material-ui/core'

import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import BoardIcon      from '@material-ui/icons/DeveloperBoard'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import StoreIcon      from '@material-ui/icons/Store'

import TitleHeader     from '../../../../../../components/headers/TitleHeader'
import WorkplaceHeader from '../../../../workplace/WorkplaceHeader'

import AnnualHealthReview from '../../../../../../things/livestock/reviews/AnnualHealthReview'
import SectorReviewVO     from '../../../../../../things/livestock/reviews/SectorReview'
import Workplace          from '../../../../../../things/Workplace'

import SectorReview from './SectorReview'

const styles = () => (
{
    root :
    {
        width : '100%'
    }
});

class WorkplaceReview extends Component
{
    state =
    {
        expanded : true
    } ;

    change = thing =>
    {
        const { onChange } = this.props ;
        if( onChange )
        {
            onChange( thing ) ;
        }
    };

    find = thing =>
    {
        if( thing )
        {
            const { subjectOf } = this.props ;
            if( subjectOf instanceof AnnualHealthReview )
            {
                return subjectOf.findWorkplaceByIndex(this.getIndex(thing)) ;
            }
        }
        return null ;
    };

    getIndex = thing =>
    {
        if( thing )
        {
           const { about } = thing ;
           if( about )
           {
                const { id } = about ;
                return id ;
           }
        }
        return null ;
    };

    getLocale = () => this.props.locale.things.livestock.reviews.annual.reviews.workplaceReview ;

    getHeader = () =>
    {
        const { thing } = this.props ;
        if( thing )
        {
            const workplace = this.find( thing ) ;
            if( workplace instanceof Workplace )
            {
                return <WorkplaceHeader thing={workplace} />;
            }
        }
        return null ;
    };

    getSectorReview = ( sectorReview , index ) =>
    {
        if( sectorReview instanceof SectorReviewVO )
        {
            const { path } = this.props ;
            return (
            <SectorReview
                key       = { 'sector_' + index }
                path      = { path }
                onChange  = { this.change }
                subjectOf = { this.props.subjectOf }
                thing     = { sectorReview }
            />);
        }
        return null ;
    };

    getSubReview = () =>
    {
        const { thing } = this.props ;
        if( thing )
        {
            const { subReview } = thing ;
            if( subReview instanceof Array && subReview.length > 0  )
            {
                const { expanded } = this.state ;
                const locale = this.getLocale() ;
                return (
                <Fragment>
                    <TitleHeader
                        badge     = { subReview.length }
                        className = 'pt-8'
                        divider   = { null }
                        icon      = { <BoardIcon/> }
                        label     = { ucFirst(locale.sectors) }
                        options   = {
                            <IconButton onClick={ () => this.collapse() } >
                                { expanded ? <ExpandLessIcon/> : <ExpandMoreIcon/> }
                            </IconButton>
                        }
                    />
                    <Collapse in={ expanded } timeout="auto" unmountOnExit>
                        <Grid container spacing={4} className="py-16">
                            { subReview.map( this.getSectorReview ) }
                        </Grid>
                    </Collapse>
                </Fragment>);
            }
        }
        return null ;
    };

    render = () =>
    {
        const {
            classes,
            className,
            containerClassName,
            ContainerComponent,
            containerProps,
            grid,
            style
        }
        = this.props ;

        let defaultContainerProps ;

        const Container = ContainerComponent || Paper ;

        if( Container === Paper )
        {
            defaultContainerProps = { elevation : 2 } ;
        }

        return (
        <Grid
            {...grid}
            item      = { true }
            className = { clsx( classes.root, className ) }
            style     = { style }
        >
            <Container
                className = { clsx("p-20 bg-grey-200" , containerClassName ) }
                { ...defaultContainerProps }
                { ...containerProps }
            >
                { this.getHeader() }
                { this.getSubReview() }
            </Container>
        </Grid>
        ) ;
    }

    collapse = () =>
    {
        const { expanded } = this.state ;
        this.setState( { expanded:!expanded } );
    };
}

WorkplaceReview.defaultProps =
{
    className          : null,
    containerClassName : null,
    containerProps     : null,
    grid               : { xs : 12 },
    icon               : <StoreIcon className="mr-16" />,
    locale             : null,
    onChange           : null,
    path               : null,
    style              : null,
    subjectOf          : null ,
    thing              : null
};

WorkplaceReview.propTypes =
{
    className          : PropTypes.string,
    containerClassName : PropTypes.string,
    ContainerComponent : PropTypes.object,
    containerProps     : PropTypes.object,
    grid               : PropTypes.object,
    icon               : PropTypes.element,
    locale             : PropTypes.object,
    onChange           : PropTypes.func,
    path               : PropTypes.oneOfType([PropTypes.object,PropTypes.string]),
    style              : PropTypes.object,
    subjectOf          : PropTypes.object,
    thing              : PropTypes.object.isRequired
};

export default withStyles( styles )( initApp( WorkplaceReview ) ) ;
