import compose from 'vegas-js-core/src/functors/compose'

import initApp from '../../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import ArticleChildren from './ArticleChildren'
import { styles } from '../ThingChildren'

class IsSimilarTo extends ArticleChildren
{
    getLocale = () => this.props.locale.things.article.isSimilarTo ;
}

IsSimilarTo.defaultProps =
{
    ...ArticleChildren.defaultProps ,
    member : 'isSimilarTo'
};

IsSimilarTo.propTypes =
{
    ...ArticleChildren.propTypes
};

export default compose( withStyles( styles ) , withRouter , initApp )( IsSimilarTo );