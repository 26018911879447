import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import Form from '../../../../display/forms/FormElements'

import PropertyValue from '../../../../things/PropertyValue'
import Thing         from '../../../../things/Thing'

import api from "../../../../configs/api"
import getExistingSuggestions from '../../../../things/getExistingSuggestions';

const elements =
[
    {
        type : Form.PHONE_NUMBER ,
        init :
        {
            autoComplete : 'tel' ,
            autoFocus    : false,
            className    : "w-full" ,
            fullWidth    : true,
            id           : 'value',
            key          : 'value',
            label        : 'Numéro de téléphone',
            helperText   : 'Obligatoire',
            margin       : 'normal',
            required     : true,
            select       : false,
            type         : 'tel' ,
            variant      : 'outlined'
        }
    },
    {
        type : Form.SEARCH_SELECTOR ,
        path : api.thesaurus.phoneNumbers_types ,
        init :
        {
            id         : 'additionalType',
            key        : 'additionalType',
            label      : 'Type de numéro de téléphone',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            required   : true,
            type       : 'search'
        },
        disabledSuggestions : getExistingSuggestions({ ref:'additionalType' , include:true }) ,
        selector : { i18n :
        {
            empty       : 'Aucun type de numéro de téléphone disponible.' ,
            title       : 'Sélectionner un type',
            description : 'Veuillez sélectionner un type de numéro de téléphone ci-dessous.' ,
        }}
    },
    {
        type : Form.I18N_EDITOR ,
        init :
        {
            id    : 'alternateName',
            key   : 'alternateName',
            input : true,
            label : 'Titre'
        }
    }
];

const telephone =
{
    add :
    {
        elements,
        agree       : 'Créer' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour ajouter un numéro de téléphone.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...' ,
        success     : 'Ajout du nouveau numéro réussi!' ,
        title       : 'Ajouter un numéro de téléphone',
    },
    edit :
    {
        elements,
        agree       : 'Sauver' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour modifier ce numéro.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...'  ,
        success     : 'Mise à jour réussie!' ,
        title       : 'Modifier le numéro de téléphone',
    },
    remove :
    {
        agree       : 'Supprimer' ,
        description : 'Souhaitez-vous supprimer ce numéro de téléphone:' ,
        disagree    : 'Annuler' ,
        item        :
        {
            text :
            {
                primary : item =>
                {
                    if( item instanceof PropertyValue )
                    {
                        return item.value ;
                    }
                    return null ;
                },
                secondary : ( item , props ) =>
                {
                    if( item instanceof PropertyValue )
                    {
                        const { additionalType} = item ;
                        if( additionalType instanceof Thing )
                        {
                            let name = additionalType.getLocaleName(props.lang) ;
                            if( name && name !== '')
                            {
                                return ucFirst(name) ;
                            }
                        }
                    }
                    return null ;
                }
            }
        },
        loading : 'Suppression en cours...' ,
        title   : 'Supprimer le numéro ?'
    }
};

export default telephone ;
