import list from './list'

const audios =
{
    ...list,
    actions :
    {
        embed  : 'Ajouter un fichier audio externe',
        sort   : 'Trier les fichiers audios' ,
        upload : 'Téléverser un fichier audio'
    },
    helmet :
    {
        title : 'Audios' ,
        metas : []
    },
    empty  : 'Aucun fichier audio trouvé',
    search : 'Rechercher un fichier audio ...' ,
    title  : 'Audios'
};

export default audios ;
