import isString   from 'vegas-js-core/src/isString'

import initDialog from '../initDialog'

import Thing from '../../../things/Thing'

import { AddDialog } from '../AddDialog'

const styles = () => ({});

export class AddWebsiteDialog extends AddDialog
{
    getLocale = () => this.props.locale.dialogs.things.website.add ;

    prepare = item =>
    {
        let { alternateName, additionalType, href, order } = item ;

        if( alternateName )
        {
            const { config } = this.props  ;
            if( config )
            {
                const { languages } = config ;
                if( languages instanceof Array )
                {
                    languages.forEach( lang =>
                    {
                        if( !isString(alternateName[lang]) )
                        {
                            alternateName[lang] = '' ;
                        }
                    })
                }
            }
        }

        return (
        {
            alternateName ,
            additionalType : additionalType instanceof Thing ? String(additionalType.id) : null ,
            href,
            order
        });
    };
}

AddWebsiteDialog.defaultProps =
{
    ...AddDialog.defaultProps
};

AddWebsiteDialog.propTypes =
{
    ...AddDialog.propTypes
};

export default initDialog({ styles })( AddWebsiteDialog ) ;
