import React from "react"

import GridIcon from '@material-ui/icons/Apps'
import ListIcon from '@material-ui/icons/List'

import Course      from '../course/Course'
import CoursesGrid from '../../display/grids/CoursesGrid'
import CoursesList from '../../display/lists/CoursesList'
import Step        from '../step/Step'

import View from '../../display/views/TabsView'

import initView from '../../display/views/initView'

import api from '../../configs/api'

const styles = () => ({}) ;

const path = api.courses ;

const { url:uri } = path ;

class Courses extends View
{
    getLocale = () => this.props.locale.views.courses ;
}

Courses.defaultProps =
{
    ...View.defaultProps,
    first : 'grid' ,
    path,
    routes :
    [
        { id : 'grid'   , Component : CoursesGrid , uri , backPath:'/grid' } ,
        { id : 'list'   , Component : CoursesList , uri , backPath:'/list' } ,
        { id : 'step'   , component : Step        , path : uri + '/steps/:id' } ,
        { id : 'course' , component : Course      , path : uri + '/:id' }
    ],
    tabs :
    [
        { id : 'grid' , icon : <GridIcon /> } ,
        { id : 'list' , icon : <ListIcon /> }
    ]
};

Courses.propTypes =
{
    ...View.propTypes
};

export default initView( Courses, styles );
