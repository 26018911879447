import React from 'react'

import StageIcon from '@material-ui/icons/Stars'

import api from "../../../configs/api"

import Form from '../../../display/forms/FormElements'

import Stage from "../../../things/Stage"

const elements =
[
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'name',
            key          : 'name',
            label        : 'Nom du l\'étape',
            autoComplete : 'off' ,
            autoFocus    : true,
            fullWidth    : true,
            helperText   : 'Obligatoire',
            margin       : 'normal',
            required     : true,
            select       : false,
            type         : 'text',
            variant      : 'outlined'
        }
    },
    {
        type  : Form.SEARCH_SELECTOR ,
        path  : api.stages.url ,
        clazz : Stage ,
        clearLabel : 'Vider' ,
        closeLabel : 'Fermer' ,
        init  :
        {
            id         : 'stage',
            key        : 'stage',
            label      : 'Point d\'intéret',
            autoFocus  : false,
            fullWidth  : true,
            helperText : 'Obligatoire',
            margin     : 'normal',
            required   : true,
            type       : 'search',
            variant    : 'outlined'
        },
        inputProps :
        {
            label : 'Sélectionner un point d\'intéret'
        },
        searchIcon : <StageIcon/>
    }
];


const step =
{
    edit :
    {
        elements,
        agree       : 'Sauver' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour modifier cette étape.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...' ,
        success     : 'Mise à jour réussie!' ,
        title       : 'Modifier l\'étape'
    },
    labels :
    {
        empty    : '----' ,
        name     : 'Nom' ,
        interest : 'Point d\'intérêt' ,
        location : 'Lieu'
    },
    title    : 'Étape' ,
    tooltips :
    {
        add    : 'Ajouter',
        edit   : 'Modifier',
        remove : 'Supprimer'
    }
};

export default step ;
