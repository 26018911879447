import table from './table'

const { tooltips } = table ;

const thesaurus =
{
    ...table,
    empty : 'Aucun thésaurus trouvé',
    tooltips :
    {
        ...tooltips,
        back : "Voir les thésaurus"
    },
    search : 'Rechercher un terme ...' ,
    sorts  :
    [
        { label : 'Date de modification' , value : 'modified'      }  ,
        { label : 'Nom'                  , value : 'fr'            }  ,
        { label : 'identifiant'          , value : 'alternateName' }  ,
        { label : 'Date de création'     , value : 'created'       }
    ],
    title  : 'Thésaurus'
};

export default thesaurus ;
