const isImageFile = file =>
{
    if( file )
    {
        const { type } = file;
        return type.indexOf('image') === 0 ;
    }
    return false ;
};

export default isImageFile ;