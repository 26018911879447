import generify from './generify'

import isString from 'vegas-js-core/src/isString'

import ConceptualObject from './ConceptualObject'
import Organization     from './Organization'
import PostalAddress    from './PostalAddress'
import PropertyValue    from './PropertyValue'
import Thing            from './Thing'
import Word             from './Word'
import Website          from './Website'

import populateMedias from './creativework/populateMedias'

/**
 * Defines a person (alive, dead, undead, or fictional).
 * @memberOf things
 */
class Person extends Thing
{
    /**
     * Creates a new Person instance.
     * @constructor
     * @param object The generic object to initialize the object the first time.
     */
    constructor( object = null )
    {
        super() ;

        /**
         * The subject matter of the content.
         * @type {Object|string}
         */
        this.about = null ;

        /**
         * Physical address of the item.
         * @type {Object|string}
         */
        this.address = null ;

        /**
         * The audios medias of this person.
         * @type {Array}
         */
        this.audios = null;

        /**
         * Date of birth.
         * @type {string|Date|object}
         */
        this.birthDate = null ;

        /**
         * Date of birth.
         * @type {string|Date|Place}
         */
        this.birthPlace = null ;

        /**
         * Date of death.
         * @type {string|Date|object}
         */
        this.deathDate = null ;

        /**
         * Date of death.
         * @type {string|Date|Place}
         */
        this.deathPlace = null ;

        /**
         * Email address.
         * @type {Object|string}
         */
        this.email = null ;

        /**
         * Family name. In the U.S., the last name of an Person. This can be used along with givenName instead of the name property.
         * @type {string}
         */
        this.familyName = null ;

        /**
         * Gender of the person
         * @type {Object|string}
         */
        this.gender = null ;

        /**
         * Given name. In the U.S., the first name of a Person. This can be used along with familyName instead of the name property.
         * @type {string}
         */
        this.givenName = null ;

        /**
         * An honorific prefix preceding a Person's name such as Dr/Mrs/Mr.
         * @type {Object|String}
         */
        this.honorificPrefix = null ;

        /**
         * An honorific suffix preceding a Person's name such as M.D./PhD/MSCSW.
         * @type {Object|String}
         */
        this.honorificSuffix = null ;

        /**
         * The jobs of the person.
         * @type {Array|Object}
         */
        this.job = null ;

        /**
         * Indicates if the person is member of an Organization.
         * @type {Array|Organization}
         */
        this.memberOf = null ;

        /**
         * Nationality of the person.
         * @type {Object|string}
         */
        this.nationality = null ;

        /**
         * Indicates the number of audios medias of this thing.
         * @type {Array}
         */
        this.numAudios = null;

        /**
         * Indicates the number of videos medias of this thing.
         * @type {Array}
         */
        this.numPhotos = null;

        /**
         * Indicates the number of videos medias of this thing.
         * @type {Array}
         */
        this.numVideos = null;

        /**
         * The object(s) owns by this person.
         * @type {Array|ConceptualObject}
         */
        this.owns = null ;

        /**
         * The photos collection of this person.
         * @type {Array}
         */
        this.photos = null ;

        /**
         * TThe Tax / Fiscal ID of the organization or person, e.g. the TIN in the US or the CIF/NIF in Spain or the SIRET in France.
         * @type {string}
         */
        this.taxID = null ;

        /**
         * The telephone of the person.
         * @type {Array|Object|String}
         */
        this.telephone = null  ;

        /**
         * The Value-added Tax ID of the organization or person.
         * @type {string}
         */
        this.vatID = null ;

        /**
         * The videos medias of this person.
         * @type {Array}
         */
        this.videos = null;

        /**
         * The websites enumeration of this person.
         * @type {Website|array}
         */
        this.websites = null ;

        /**
         * The employers of this person.
         * @type {Array|Organization|Person}
         */
        this.worksFor = null ;

        this.set( object ) ;
    }

    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        super.clear() ;
        this.about =
        this.address =
        this.audios =
        this.birthDate =
        this.birthPlace =
        this.deathDate =
        this.deathPlace =
        this.email =
        this.familyName =
        this.gender =
        this.givenName =
        this.honorificPrefix =
        this.honorificSuffix =
        this.job =
        this.memberOf =
        this.nationality =
        this.numAudios =
        this.numPhotos =
        this.numVideos =
        this.owns =
        this.photos =
        this.taxID =
        this.telephone =
        this.vatID =
        this.videos =
        this.websites =
        this.worksFor = null ;
        return this ;
    }

    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new Person( this.toObject() ) ;
    }

    /**
     * Generates the fullname of the person (givenName + familyName) or returns the alternateName or the name value.
     * @returns {string}
     */
    getFullNameOrUsername( lang = null , defaultLang = null )
    {
        const {
            familyName,
            givenName,
            honorificPrefix,
            honorificSuffix
        } = this ;

        let name = [] ;

        if( ( (givenName instanceof String) || typeof(givenName) === 'string') )
        {
            name.push( givenName ) ;
        }

        if( ( (familyName instanceof String) || typeof(familyName) === 'string') )
        {
            name.push( familyName ) ;
        }

        if( name.length === 0 )
        {
            if( this.alternateName )
            {
                name.push( this.getLocaleAlternateName(lang, defaultLang) );
            }
            else if ( this.name )
            {
                name.push( this.getLocaleName(lang, defaultLang) ) ;
            }
        }

        if( name.length > 0 )
        {
            if( honorificPrefix instanceof Thing )
            {
                name.unshift( honorificPrefix.getLocaleName(lang) )
            }
            else if( typeof(honorificPrefix) === 'string' || (honorificPrefix instanceof String) )
            {
                name.unshift( honorificPrefix )
            }

            if( honorificSuffix instanceof Thing )
            {
                name.push( honorificSuffix.getLocaleName(lang) )
            }
            else if( typeof(honorificSuffix) === 'string' || (honorificSuffix instanceof String) )
            {
                name.push( honorificSuffix )
            }
        }

        return name.length > 0 ? name.join(' ') : '' ;
    }

    populate()
    {
        const {
            address,
            birthDate,
            deathDate,
            email,
            gender,
            honorificPrefix,
            job,
            memberOf,
            owns,
            telephone,
            websites,
            worksFor
        } = this ;

        populateMedias( this ) ;

        if( address && !(address instanceof PostalAddress))
        {
            this.address = new PostalAddress(address) ;
        }

        if( isString(birthDate) && birthDate === '')
        {
            this.birthDate = null ;
        }

        if( isString(deathDate) && deathDate === '')
        {
            this.deathDate = null ;
        }

        if( email )
        {
            if( email instanceof Array )
            {
                this.email = email.map( item => item instanceof PropertyValue ? item : new PropertyValue(item) ) ;
            }
        }

        if( gender && !(gender instanceof Word))
        {
            this.gender = new Word(gender) ;
        }

        if( honorificPrefix && !(honorificPrefix instanceof Word))
        {
            this.honorificPrefix = new Word(honorificPrefix) ;
        }

        if( job instanceof Array && job.length > 0 )
        {
            this.job = job.map( item => item instanceof Word ? item : new Word(item) ) ;
        }

        if( memberOf )
        {
            if( memberOf instanceof Array )
            {
                this.memberOf = memberOf.map( item => item instanceof Organization ? item : new Organization(item) ) ;
            }
            else
            {
                this.memberOf = new Organization( memberOf ) ;
            }
        }

        if( owns )
        {
            if( owns instanceof Array )
            {
                this.owns = owns.map( item => item instanceof ConceptualObject ? item : new ConceptualObject(item) ) ;
            }
            else
            {
                this.owns = new ConceptualObject( owns ) ;
            }
        }

        if( telephone instanceof Array && telephone.length > 0 )
        {
            this.telephone = telephone.map( item => item instanceof PropertyValue ? item : new PropertyValue(item) ) ;
        }

        if( websites )
        {
            if( websites instanceof Array )
            {
                this.websites = websites.map( item => item instanceof Website ? item : new Website(item) ) ;
            }
        }

        if( worksFor )
        {
            if( worksFor instanceof Array )
            {
                this.worksFor = worksFor.map( item => item instanceof Organization ? item : new Organization(item) ) ;
            }
            else
            {
                this.worksFor = new Organization( worksFor ) ;
            }
        }

        return this ;
    }

    /**
     * Returns the generic object representation of the item.
     * @return {Object} The generic object representation of the item.
     */
    toObject()
    {
        let {
            about,
            address,
            audios,
            birthDate,
            birthPlace,
            deathDate,
            deathPlace,
            email,
            familyName,
            gender,
            givenName,
            honorificPrefix,
            honorificSuffix,
            job,
            memberOf,
            nationality,
            numAudios,
            numPhotos,
            numVideos,
            owns,
            photos,
            taxID,
            telephone,
            vatID,
            videos,
            websites,
            worksFor
        } = this ;

        address         = generify(address);
        audios          = generify(audios) ;
        email           = generify(email);
        gender          = generify(gender);
        honorificPrefix = generify(honorificPrefix);
        job             = generify(job);
        memberOf        = generify(memberOf);
        owns            = generify(owns);
        photos          = generify(photos) ;
        telephone       = generify(telephone);
        videos          = generify(videos) ;
        websites        = generify(websites);
        worksFor        = generify(worksFor);

        return {
            ...super.toObject() ,
            about,
            audios,
            address,
            birthDate,
            birthPlace,
            deathDate,
            deathPlace,
            email,
            familyName,
            gender,
            givenName,
            honorificPrefix,
            honorificSuffix,
            job,
            memberOf,
            nationality,
            numAudios,
            numPhotos,
            numVideos,
            owns,
            photos,
            taxID,
            telephone,
            vatID,
            videos,
            websites,
            worksFor
        }
    }
}

export default Person ;
