import initDialog from '../initDialog'

import { AddDialog } from '../AddDialog'

import api from '../../../configs/api'

const styles = () => ({});

export class AddGameDialog extends AddDialog
{
    getLocale = () => this.props.locale.dialogs.application.game.add ;

    prepare = item =>
    {
        const { name } = item ;
        return { name }
    };
}

AddGameDialog.defaultProps =
{
    ...AddDialog.defaultProps,
    uri : api.games.url
};

AddGameDialog.propTypes =
{
    ...AddDialog.propTypes
};

export default initDialog({ styles })( AddGameDialog ) ;
