import React from 'react'

import PropTypes from 'prop-types'

import compose  from 'vegas-js-core/src/functors/compose'
import notEmpty from 'vegas-js-core/src/strings/notEmpty'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import initApp from '../../contexts/app/initApp'

import { withStyles } from '@material-ui/core/styles'

import { Checkbox, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'

import green from '@material-ui/core/colors/green'

import CheckCircleIcon    from '@material-ui/icons/CheckCircle'
import KeyboardArrowRight from '@material-ui/icons/ChevronRight'

import Container   from '../../components/containers/Container'
import ThingAvatar from '../../display/avatars/ThingAvatar'

import getLocaleHeadline   from '../../things/getLocaleHeadline'
import getLocaleThingLabel from '../../things/getLocaleThingLabel'

import Person from '../../things/Person'
import Thing  from '../../things/Thing'

import LangConsumer from '../../contexts/i18n/LangConsumer'

const styles = () =>
({
    check  :
    {
        color       : green[600] ,
        height      : 42,
        marginRight : 8,
        width       : 42
    }
});

class Suggestion extends Container
{
    getAvatar = thing =>
    {
        if( thing instanceof Thing )
        {
            const {
                avatarSize,
                avatarMode,
                avatarVariant,
                defaultIcon,
            } = this.props ;

            return (
                <ThingAvatar
                    className   = 'mr-16'
                    defaultIcon = { defaultIcon }
                    mode        = { avatarMode }
                    thing       = { thing }
                    size        = { avatarSize }
                    variant     = { avatarVariant }
                />
            );
        }
        return null ;
    };

    getCheckedIcon = () => <CheckCircleIcon className={ this.props.classes.check} /> ;

    getLabel = ( thing , lang ) =>
    {
        let { label } = this.props ;
        if( label )
        {
            if( label instanceof Function )
            {
                label = label( thing , this.props ) ;
            }
            else if( label instanceof String || typeof(label) === 'string' )
            {
                label = ucFirst(label) ;
            }
            else
            {
                label = null ;
            }
        }
        else if( thing instanceof Person )
        {
            label = thing.getFullNameOrUsername( lang ) ;
        }
        else if( thing instanceof Thing )
        {
            const { headline } = thing ;
            if( headline )
            {
                label = getLocaleHeadline( thing , lang )
                if( notEmpty(label) )
                {
                    return label ;
                }
            }
            return getLocaleThingLabel( thing , lang ) ;
        }
        else
        {
            label = null ;
        }

        return label ;
    };

    render = () =>
    {
        const {
            checkable,
            checked,
            disabled ,
            highlight ,
            onSelect,
            thing,
            thingRef
        } = this.props ;

        let avatar ;

        let ref = thingRef instanceof Function ? thingRef(thing, this.props) : thing ;

        if( checkable )
        {
            avatar = (
            <Checkbox
                checked       = { checked }
                checkedIcon   = { this.getCheckedIcon() }
                disableRipple = { true }
                icon          = { this.getAvatar( ref ) }
                onClick       = { ( event ) =>
                {
                    event.stopPropagation() ;
                    if( onSelect instanceof Function )
                    {
                        onSelect( thing , this ) ;
                    }
                }}
            />);
        }
        else
        {
            avatar = this.getAvatar( ref ) ;
        }

        return (
            <ListItem
                button         = { true }
                disableGutters = { true }
                className      = 'mb-4 px-8 py-12 whitespace-normal'
                component      = 'div'
                disabled       = { disabled }
                key            = { 'suggestion_item_' + thing.id }
                onClick        = { () => { if( onSelect instanceof Function ){ onSelect( thing , this ) ; } }}
                selected       = { highlight }
                value          = { thing }
            >
                <ListItemIcon>{ avatar }</ListItemIcon>
                <LangConsumer>{ ({lang}) => <ListItemText primary={ this.getLabel( ref, lang ) }/> }</LangConsumer>
            </ListItem>
        );
    }
}

Suggestion.defaultProps =
{
    ...Container.defaultProps,
    avatarMode    : 'auto',
    avatarSize    : 'default',
    avatarVariant : 'rounded',
    defaultIcon   : <KeyboardArrowRight/> ,
    checkable     : false ,
    checked       : false ,
    disabled      : false ,
    highlight     : false ,
    label         : null ,
    onSelect      : null ,
    thing         : null ,
    thingRef      : null
};

Suggestion.propTypes =
{
    ...Container.propTypes,
    avatarMode    : PropTypes.oneOf(['auto','icon','cover']),
    avatarSize    : PropTypes.oneOf(['default','large','small']),
    avatarVariant : PropTypes.oneOf(['circle','rounded','square']),
    defaultIcon   : PropTypes.element,
    checkable     : PropTypes.bool,
    checked       : PropTypes.bool,
    disabled      : PropTypes.bool,
    highlight     : PropTypes.bool,
    label         : PropTypes.oneOfType([PropTypes.string,PropTypes.func]) ,
    onSelect      : PropTypes.func,
    thing         : PropTypes.object,
    thingRef      : PropTypes.func // SPECIAL TO OVERRIDE THE LABEL and the ICON with a custom object (example the authority property of a veterinarian occurence)
};

export default compose( withStyles( styles ) , initApp )( Suggestion ) ;
