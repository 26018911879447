import compose from 'vegas-js-core/src/functors/compose'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { withWidth }  from '@material-ui/core'

import withConfig   from '../../contexts/config/withConfig'
import withDialogs  from '../../contexts/dialogs/withDialogs'
import withLang     from '../../contexts/i18n/withLang'
import withLocale   from '../../contexts/i18n/withLocale'
import withSearch   from '../../contexts/search/withSearch'
import withSettings from '../../contexts/settings/withSettings'
import withSnack    from '../../contexts/snack/withSnack'
import withUser     from '../../contexts/user/withUser'


const initView = ( Component , styles , withTheme = true ) =>
compose(
    withConfig,
    withDialogs,
    withLang,
    withLocale,
    withRouter,
    withSearch,
    withUser,
    withSettings,
    withStyles( styles ,{ withTheme } ),
    withSnack,
    withWidth(),
)( Component ) ;

export default initView ;
