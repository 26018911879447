import React from 'react'

import ucFirst from 'vegas-js-core/src/strings/ucFirst'

import initApp from '../../../../../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { Grid } from '@material-ui/core'

import WhatshotIcon from '@material-ui/icons/Whatshot'

import LabelItem from '../../../../../../components/items/LabelItem'

import ThingContainer from '../../../../../containers/ThingContainer'

import AmbienceReviewVO from '../../../../../../things/livestock/reviews/AmbienceReview'
import Thing            from '../../../../../../things/Thing'
import Word             from '../../../../../../things/Word'

const styles = () => ({});

const half =
{
    sm : 6 ,
    xs : 12
};

class AmbienceReview extends ThingContainer
{
    getContent = () =>
    {
        let { thing } = this.props;
        if( thing )
        {
            let { lang } = this.props;

            const locale = this.getLocale() ;

            const { labels } = locale ;

            let comfort,
                couplingHeatingVentilation,
                density,
                heating,
                heatingRegulation,
                regulationToolMastering,
                ventilation;
            const { ambience } = thing ;

            if( ambience instanceof AmbienceReviewVO )
            {
                let {
                    comfort     : cf ,
                    density     : ds ,
                    ventilation : vt ,
                    couplingHeatingVentilation:ch = false ,
                    heating:he                    = false ,
                    heatingRegulation:hr          = false ,
                    regulationToolMastering:rt    = false ,
                } = ambience ;

                comfort     = cf instanceof Word ? ucFirst(cf.getLocaleName(lang)) : labels.empty ;
                density     = ds instanceof Word ? ucFirst(ds.getLocaleName(lang)) : labels.empty ;
                ventilation = vt instanceof Word ? ucFirst(vt.getLocaleName(lang)) : labels.empty ;

                couplingHeatingVentilation = !!(ch) ? labels.yes : labels.no ;
                heating                    = !!(he) ? labels.yes : labels.no ;
                heatingRegulation          = !!(hr) ? labels.yes : labels.no ;
                regulationToolMastering    = !!(rt) ? labels.yes : labels.no ;
            }

            return (
                <Grid container spacing={4}>
                    <Grid item {...half}>
                        <LabelItem title = { labels.density }>
                            { density }
                        </LabelItem>
                    </Grid>
                    <Grid item {...half}>
                        <LabelItem title = { labels.ventilation }>
                            { ventilation }
                        </LabelItem>
                    </Grid>
                    <Grid item {...half}>
                        <LabelItem title = { labels.heating }>
                            { heating }
                        </LabelItem>
                    </Grid>
                    <Grid item {...half}>
                        <LabelItem title = { labels.couplingHeatingVentilation }>
                            { couplingHeatingVentilation }
                        </LabelItem>
                    </Grid>
                    <Grid item {...half}>
                        <LabelItem title = { labels.heatingRegulation }>
                            { heatingRegulation }
                        </LabelItem>
                    </Grid>
                    <Grid item {...half}>
                        <LabelItem title = { labels.regulationToolMastering }>
                            { regulationToolMastering }
                        </LabelItem>
                    </Grid>
                    <Grid item {...half}>
                        <LabelItem title = { labels.comfort }>
                            { comfort }
                        </LabelItem>
                    </Grid>
                </Grid>
            );

        }
    };

    getEntry = init =>
    {
        let empty = {} ;

        let review ;

        if( init )
        {
            const { ambience } = init ;
            if( init instanceof Thing )
            {
                empty = ambience.toObject() ;
            }
            else if( init )
            {
                empty = { ...ambience } ;
            }

            review = new AmbienceReviewVO(empty);

            review.about = init ;

            const { subjectOf } = this.props ;
            if( subjectOf )
            {
                review.subjectOf = subjectOf ;
            }
        }

        return review ;
    };

    getLocale = () => this.props.locale.things.livestock.reviews.annual.reviews.ambienceReview ;

    onEdit = ( item ) =>
    {
        const { onChange } = this.props ;
        if( onChange )
        {
            onChange( item ) ;
        }
    }
}

AmbienceReview.defaultProps =
{
    ...ThingContainer.defaultProps ,
    icon       : <WhatshotIcon/> ,
    optionMode : 'edit'
};

AmbienceReview.propTypes =
{
    ...ThingContainer.propTypes
};

export default withStyles( styles )( withRouter( initApp(AmbienceReview) ) ) ;
