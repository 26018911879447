export default
{
    disagree    : 'Fermer' ,
    empty       : 'Aucun élément disponible.' ,
    fail        : 'Impossible de charger les éléments sur le serveur, veuillez relancer le chargement.',
    title       : 'Sélectionner un élément' ,
    description : 'Veuillez sélectionner un élément ci-dessous.' ,
    loading     : 'Chargement en cours...' ,
    search      : 'Rechercher ...'
};
