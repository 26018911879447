import React from 'react'

import EventIcon from '@material-ui/icons/DateRange'

import withDatasGrid from './withDatasGrid'
import { ThingsGrid } from './ThingsGrid'

import Event     from '../../things/Event'
import EventCell from '../cells/EventCell'

import AddEventDialog    from '../dialogs/add/AddEventDialog'
import RemoveEventDialog from '../dialogs/remove/RemoveEventDialog'

class EventsGrid extends ThingsGrid
{
    getLocale = () => this.props.locale.display.grids.events ;

    getThingRef = () => this.props.thing ;
}

EventsGrid.defaultProps =
{
    ...ThingsGrid.defaultProps,
    AddDialogComponent    : AddEventDialog,
    CellComponent         : EventCell ,
    clazz                 : Event,
    icon                  : <EventIcon />,
    mediaIcon             : <EventIcon fontSize='large' />,
    mediaProps            : { className : 'bg-hero-polka-dots-2' },
    preferable            : true ,
    RemoveDialogComponent : RemoveEventDialog
};

EventsGrid.propTypes =
{
    ...ThingsGrid.propTypes
};

export default withDatasGrid()( EventsGrid ) ;
