import React from 'react'

import URLSearchParams from '@ungap/url-search-params'

import { Redirect } from 'react-router-dom'

import clsx from 'clsx'

import { Typography } from '@material-ui/core'

import auth from '../../configs/auth'

import Animate from '../../transitions/Animate'

import tokensManager from '../../vo/TokensManager'

import getAccessToken from '../../net/auth/getAccessToken'
import RequestStatus  from '../../net/RequestStatus'

import initView from '../../display/views/initView'
import View     from '../../display/views/View'

const styles = theme =>
{
    return {
        logo:
        {
            marginBottom : 32,
            height       : 156
        },
        root:
        {
            backgroundColor : process.env.REACT_APP_THEME_COLOR ,
            background      : `url(${theme.assets.background}) no-repeat center center` ,
            backgroundSize  : 'cover' ,
            width           : '100%',
            height          : '100%'
        }
    }
};

class Token extends View
{
    constructor( props )
    {
        super( props ) ;
        this.canceler = null ;
        this.state =
        {
            ...this.state,
            redirect : false
        };
    }

    fail = response =>
    {
        // FIXME redirect to the 'welcome' page + notify an error
        if( response )
        {
            let data = response.data ;
            if( data )
            {
                switch( data.message )
                {
                    case 'Invalid authorization code' :
                    {
                        console.log( this + " getAccessToken failed, code:" + data.code + " message:" + data.message );
                        break ;
                    }

                    case 'Invalid state token' :
                    {
                        console.log( this + " getAccessToken failed, code:" + data.code + " message:" + data.message );
                        this.setState({ redirect:true }) ;
                        break;
                    }

                    case 'Invalid refresh token' :
                    {
                        console.log( this + " getAccessToken failed, code:" + data.code + " message:" + data.message );
                        break ;
                    }
                    default :
                    {
                        console.log( this + " getAccessToken failed, unknown status:" + response.status );
                    }
                }
            }
        }
    };

    init = () =>
    {
        const api = auth.apis['api'] ;

        if( !api )
        {
            this.fail( { data : { code : 400 , message : 'api definition not found!' } } );
        }

        const accessToken = tokensManager.read( api.name ) ;

        if( accessToken && accessToken.stored )
        {
            this.setState( { redirect : true } ) ;
        }
        else
        {
            const { config, location } = this.props;

            const params = new URLSearchParams(location.search) ;

            const code  = params.get('code') ;
            const state = params.get('state') ;

            const current = localStorage.getItem(config.name + '_' + api.name + '_csrf') ;

            if( !(current) || (state !== current) )
            {
                this.fail( { data : { code : 401 , message : 'Invalid state token' } } ) ;
                return ;
            }

            if( current )
            {
                localStorage.removeItem( config.name + '_' + api.name + '_csrf' );
            }

            this.canceler = getAccessToken( {
                code    : code ,
                fail    : this.fail,
                success : this.success
            }) ;
        }
    };

    render = () =>
    {
        const { config }   = this.props ;
        const { redirect } = this.state ;

        if( redirect )
        {
            let path = sessionStorage.getItem( config.referrerID ) ;

            //console.log( this + ' render referrer ' , path ) ;

            if( path )
            {
                path = JSON.parse(path) ;
            }
            else
            {
                path = config.home;
            }

            //sessionStorage.removeItem( config.referrerID ) ; // clean memory

            //console.log( this + " render redirect:" ) ;
            //console.log( path )

            return <Redirect to={ { pathname : path } }  />
        }
        else
        {
            const { classes } = this.props;
            const locale = this.getLocale() ;
            return (
                <div className={clsx(classes.root, "flex flex-col flex-1 flex-shrink-0 p-24 md:flex-row md:p-0")}>
                    <div
                        className={clsx(classes.intro, "flex flex-col flex-grow-0 items-center p-16 text-center md:p-128 md:items-start md:flex-shrink-0 md:flex-1 md:text-left")}>

                        <Animate animation="transition.expandIn">
                            <img className={ classes.logo } src={config.assets.logo} alt="logo"/>
                        </Animate>

                        <Animate animation="transition.slideUpIn" delay={300}>
                            <Typography variant="h3" className="font-light">
                                { locale.title }
                            </Typography>
                        </Animate>
                        <Animate delay={500}>
                            <Typography variant="subtitle1" className="max-w-512 mt-16">
                                { locale.loading }
                            </Typography>
                        </Animate>
                    </div>
                </div>
            );
        }
    }

    getLocale = () => this.props.locale.views.auth.token ;

    success = () =>
    {
        this.setState({ redirect:true }) ;
    };

    unmount = () => {
        const { status } = this.state ;
        if( status === RequestStatus.PROGRESS && !!(this.canceler) )
        {
            this.canceler.cancel(this + ' cancel') ;
        }
    } ;
}

Token.defaultProps =
{
    ...View.defaultProps
};

Token.propTypes =
{
    ...View.propTypes
};

export default initView( Token , styles );
