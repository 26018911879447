import React from "react"

import GridIcon from '@material-ui/icons/Apps'
import ListIcon from '@material-ui/icons/List'

import ConceptualObject      from '../conceptualObject/ConceptualObject'
import ConceptualObjectsGrid from '../../display/grids/ConceptualObjectsGrid'
import ConceptualObjectsList from '../../display/lists/ConceptualObjectsList'

import initView  from '../../display/views/initView'
import View      from '../../display/views/TabsView'

import api from '../../configs/api'

const styles = () => ({}) ;

const path = api.conceptualObjects ;

const { url:uri } = path ;

class ConceptualObjects extends View
{
    getLocale = () => this.props.locale.views.conceptualObjects ;
}

ConceptualObjects.defaultProps =
{
    ...View.defaultProps,
    path ,
    first  : 'grid' ,
    routes :
    [
        { id : 'grid' , Component:ConceptualObjectsGrid , uri , backPath:'/grid' } ,
        { id : 'list' , Component:ConceptualObjectsList , uri , backPath:'/list' } ,
        { id : 'conceptualObject' , component : ConceptualObject , path : uri + '/:id' } ,
    ],
    tabs :
    [
        { id : 'grid' , icon : <GridIcon /> } ,
        { id : 'list' , icon : <ListIcon /> }
    ]
};

ConceptualObjects.propTypes =
{
    ...View.propTypes
};

export default initView( ConceptualObjects , styles );
