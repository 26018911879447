import initDialog from '../initDialog'

import { SelectThingDialog , styles } from '../SelectThingDialog'

import api from '../../../configs/api'

import ConceptualObject from '../../../things/ConceptualObject'

class SelectConceptualObjectDialog extends SelectThingDialog
{
    getLocale = () => this.props.locale.dialogs.select.conceptualObject ;
}

SelectConceptualObjectDialog.defaultProps =
{
    ...SelectThingDialog.defaultProps,
    clazz : ConceptualObject ,
    uri   : api.conceptualObjects.url
};

SelectConceptualObjectDialog.propTypes =
{
    ...SelectThingDialog.propTypes,
};

export default initDialog({ styles , useSearch:false })( SelectConceptualObjectDialog ) ;
