import generify from './generify'

import createAgent from './createAgent'

import ConceptualObject from './ConceptualObject'
import Offer            from './Offer'
import Person           from './Person'
import Place            from './Place'
import PostalAddress    from './PostalAddress'
import Thing            from './Thing'
import Word             from './Word'
import Website          from './Website'

import populateMedias from './creativework/populateMedias'

/**
 * An event happening at a certain time and location, such as a concert, lecture, or festival.
 * @memberOf things
 */
class Event extends Thing
{
    /**
     * Creates a new Event instance.
     * @constructor
     * @param object The generic object to initialize the object.
     */
    constructor( object = null )
    {
        super() ;

        /**
         * The main subject matter of the content.
         * @type {Object|Thing}
         */
        this.about = null ;

        /**
         * An actor, e.g. in tv, radio, movie, video games etc., or in an event. Actors can be associated with individual items or with a series, episode, clip.
         * @type {Person|Array}
         */
        this.actor = null ;

        /**
         * A secondary title of the Event.
         * @type {string}
         */
        this.alternativeHeadline = null ;

        /**
         * A person or organization attending the event.
         * @type {Person|Organization|Array}
         */
        this.attendee = null ;

        /**
         * The audios medias of this event.
         * @type {Array}
         */
        this.audios = null;

        /**
         * The authority of the event.
         * @type {Object}
         */
        this.authority = null ;

        /**
         * The reason of the event.
         * @type {Thing}
         */
        this.causedBy = null ;

        /**
         * The current date and time of the event (in ISO 8601 date format - https://en.wikipedia.org/wiki/ISO_8601).
         * @type {string}
         */
        this.date = null ;

        /**
         * The duration of the item (movie, audio recording, event, etc.) in ISO 8601 date format or with a number value.
         * @type {string|number}
         */
        this.duration = null ;

        /**
         * The end date and time of the item (in ISO 8601 date format - https://en.wikipedia.org/wiki/ISO_8601).
         * @type {string}
         */
        this.endDate = null ;

        /**
         * An eventStatus of an event represents its status; particularly useful when an event is cancelled or rescheduled.
         * @type {string|Work}
         */
        this.eventStatus = null ;

        /**
         * Headline of the event.
         * @type {string}
         */
        this.headline = null ;

        /**
         * The location of for example where the event is happening, an organization is located, or where an action takes place.
         * @type {PostalAddress|Place|string}
         */
        this.location = null ;

        /**
         * Indicates the number of audios medias of this thing.
         * @type {Array}
         */
        this.numAudios = null;

        /**
         * Indicates the number of videos medias of this thing.
         * @type {Array}
         */
        this.numPhotos = null;

        /**
         * Indicates the number of videos medias of this thing.
         * @type {Array}
         */
        this.numVideos = null;

        /**
         * The location of for example where the event is happening, an organization is located, or where an action takes place.
         * @type {Offer|array}
         */
        this.offers = null ;

        /**
         * The organizers of the event.
         * @type {null}
         */
        this.organizer = null ;

        /**
         * The photos collection of this event.
         * @type {Array}
         */
        this.photos = null ;

        /**
         * Used in conjunction with eventStatus for rescheduled or cancelled events. This property contains the previously scheduled start date. For rescheduled events, the startDate property should be used for the newly scheduled start date. In the (rare) case of an event that has been postponed and rescheduled multiple times, this field may be repeated.
         * @type {Array|string}
         */
        this.previousStartDate = null ;

        /**
         * A review of the item
         * @type {Object}
         */
        this.review = null ;

        /**
         * The start date and time of the item (in ISO 8601 date format).
         * @type {string}
         */
        this.startDate = null ;

        /**
         * An Event that is part of this event. For example, a conference event includes many presentations, each of which is a subEvent of the conference.
         * @type {Event|Array}
         */
        this.subEvent = null ;

        /**
         * The specific subject matter of the event.
         * @type {Object|Thing}
         */
        this.subject = null ;

        /**
         * An event that this event is a part of. For example, a collection of individual music performances might each have a music festival as their superEvent. Inverse property: subEvent.
         * @type {Event|Array}
         */
        this.superEvent = null ;

        /**
         * The typical expected age range, e.g. '7-9', '11-'.
         * @type {Word|String}
         */
        this.typicalAgeRange = null ;

        /**
         * The videos medias of this place.
         * @type {Array}
         */
        this.videos = null;

        /**
         * The websites enumeration of this place.
         * @type {Website|array}
         */
        this.websites = null ;

        /**
         * A work featured in some event, e.g. exhibited in an ExhibitionEvent. Specific subproperties are available for workPerformed (e.g. a play), or a workPresented (a Movie at a ScreeningEvent).
         * @type {Array|Thing}
         */
        this.workFeatured = null ;

        this.set( object ) ;
    }

    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        super.clear() ;
        this.about =
        this.actor =
        this.alternativeHeadline =
        this.attendee =
        this.audios =
        this.authority =
        this.causedBy =
        this.date =
        this.duration =
        this.endDate =
        this.eventStatus =
        this.headline =
        this.location =
        this.numAudios =
        this.numPhotos =
        this.numVideos =
        this.offers =
        this.organizer =
        this.photos =
        this.previousStartDate =
        this.review =
        this.startDate =
        this.subEvent =
        this.subject =
        this.superEvent =
        this.type =
        this.typicalAgeRange =
        this.videos =
        this.websites =
        this.workFeatured = null ;
        return this ;
    }

    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new Event( this.toObject() ) ;
    }

    /**
     * Returns the locale alternative headline of the thing.
     * @param {string} [lang=null] the current lang.
     * @param {string} [defaultLang=null] the default lang.
     * @return {String} The locale headline of the thing.
     */
    getLocaleAlternativeHeadline = this.getLocaleProperty('alternativeHeadline') ;

    /**
     * Returns the locale headline of the thing.
     * @param {string} [lang=null] the current lang.
     * @param {string} [defaultLang=null] the default lang.
     * @return {String} The locale headline of the thing.
     */
    getLocaleHeadline = this.getLocaleProperty('headline') ;

    /**
     * Populate the object with specific behaviors or types. Launched by default in the constructor and in the set methods.
     * @returns {Thing} The current reference.
     */
    populate()
    {
        let {
            additionalType,
            actor ,
            attendee,
            eventStatus ,
            location ,
            offers ,
            organizer ,
            subEvent ,
            superEvent,
            workFeatured,
            typicalAgeRange,
            websites
        } = this ;

        populateMedias( this ) ;

        if( additionalType && !(additionalType instanceof Word))
        {
            this.additionalType = new Word(additionalType) ;
        }

        if( actor )
        {
            if( actor instanceof Array )
            {
                this.actor = actor.map( item => item instanceof Person ? item : new Person(item) ) ;
            }
            else
            {
                this.actor = new Person( actor ) ;
            }
        }

        if( attendee )
        {
            if( attendee instanceof Array )
            {
                this.attendee = attendee.map( createAgent ) ;
            }
            else
            {
                this.attendee = createAgent( attendee ) ;
            }
        }

        if( eventStatus )
        {
            this.eventStatus = eventStatus instanceof Word ? eventStatus : new Word(eventStatus) ;
        }

        if( location )
        {
            if( Place.is( this.location ) )
            {
                this.location = location instanceof Place ? location : new Place(location) ;
            }
            else if( PostalAddress.is( this.location ))
            {
                this.location = location instanceof PostalAddress ? location : new PostalAddress(location) ;
            }
        }

        if( offers )
        {
            if( offers instanceof Array )
            {
                this.offers = offers.map( item => item instanceof Offer ? item : new Offer(item) ) ;
            }
        }

        if( organizer )
        {
            if( organizer instanceof Array )
            {
                this.organizer = organizer.map( createAgent ) ;
            }
            else
            {
                this.organizer = createAgent( organizer ) ;
            }
        }

        if( subEvent )
        {
            if( subEvent instanceof Array )
            {
                this.subEvent = subEvent.map( item => item instanceof Event ? item : new Event(item) ) ;
            }
            else
            {
                this.subEvent = new Event( subEvent ) ;
            }
        }

        if( superEvent )
        {
            if( superEvent instanceof Array )
            {
                this.superEvent = superEvent.map( item => item instanceof Event ? item : new Event(item) ) ;
            }
            else
            {
                this.superEvent = new Event( superEvent ) ;
            }
        }

        if( typicalAgeRange )
        {
            if( typicalAgeRange instanceof Array )
            {
                this.typicalAgeRange = typicalAgeRange.map( item => item instanceof Word ? item : new Word(item) ) ;
            }
            else if (!(typicalAgeRange instanceof Word) )
            {
                this.typicalAgeRange = new Word(typicalAgeRange) ;
            }
        }

        if( workFeatured )
        {
            if( workFeatured instanceof Array )
            {
                this.workFeatured = workFeatured.map( item => item instanceof ConceptualObject ? item : new ConceptualObject(item) ) ;
            }
            else
            {
                this.workFeatured = new ConceptualObject( workFeatured ) ;
            }
        }

        if( websites )
        {
            if( websites instanceof Array )
            {
                this.websites = websites.map( item => item instanceof Website ? item : new Website(item) ) ;
            }
        }

        return this ;
    }

    /**
     * Returns the generic object representation of the item.
     * @return {Object} The generic object representation of the item.
     */
    toObject()
    {
        let {
            about,
            actor,
            alternativeHeadline,
            attendee,
            audios,
            authority,
            causedBy,
            duration,
            endDate,
            eventStatus,
            headline,
            location,
            numAudios,
            numPhotos,
            numVideos,
            offers,
            organizer,
            photos,
            previousStartDate,
            review ,
            startDate ,
            subEvent ,
            subject,
            superEvent,
            typicalAgeRange,
            videos,
            websites,
            workFeatured
        } = this ;

        about           = generify(about) ;
        actor           = generify(actor) ;
        attendee        = generify(attendee) ;
        audios          = generify(audios) ;
        authority       = generify(authority) ;
        causedBy        = generify(causedBy) ;
        eventStatus     = generify(eventStatus);
        location        = generify(location);
        offers          = generify(offers);
        organizer       = generify(organizer);
        photos          = generify(photos) ;
        review          = generify(review) ;
        subEvent        = generify(subEvent);
        subject         = generify(subject);
        superEvent      = generify(superEvent);
        typicalAgeRange = generify(typicalAgeRange);
        videos          = generify(videos);
        websites        = generify(websites);
        workFeatured    = generify(workFeatured);

        if( previousStartDate instanceof Array )
        {
            previousStartDate = [ ...previousStartDate ] ; // clone
        }

        return {
            ...super.toObject() ,
            about,
            actor,
            alternativeHeadline,
            attendee,
            audios,
            authority,
            causedBy,
            duration,
            endDate,
            eventStatus,
            headline,
            location,
            numAudios,
            numPhotos,
            numVideos,
            offers,
            organizer,
            photos,
            previousStartDate,
            review,
            startDate,
            subEvent,
            subject,
            superEvent,
            typicalAgeRange,
            videos,
            websites,
            workFeatured
        };
    }
}

export default Event ;
