import React from 'react'

import Form        from '../../../../display/forms/FormElements'
import NumberInput from "../../../../components/inputs/NumberInput"

import api from '../../../../configs/api'
import map from "../../../../configs/map"

const {
    elevation,
    latitude,
    longitude,
    maxElevation,
    maxLatitude,
    maxLongitude,
    minElevation,
    minLatitude,
    minLongitude,
    zoom
}
= map ;

const elements =
[
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'name',
            key          : 'name',
            label        : 'Nom du lieu',
            autoComplete : 'off' ,
            autoFocus    : true,
            fullWidth    : true,
            helperText   : 'Obligatoire',
            margin       : 'normal',
            required     : true,
            select       : false,
            type         : 'text',
            variant      : 'outlined'
        }
    },
    {
        type : Form.SEARCH_SELECTOR ,
        path : api.thesaurus.places_types ,
        init :
        {
            id         : 'additionalType',
            key        : 'additionalType',
            label      : 'Type de lieu',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            required   : true,
            type       : 'text',
            variant    : 'outlined'
        },
        selector : { i18n :
        {
            empty       : 'Aucun type disponible.' ,
            title       : 'Sélectionner un type de lieu',
            description : 'Veuillez sélectionner un type de lieu ci-dessous.' ,
        }}
    },
    {
        type : Form.DIVIDER,
        init :
        {
            key     : 'divider',
            light   : false ,
            variant : 'fullWidth'
        }
    },
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'geo.latitude',
            key          : 'latitude',
            label        : 'Latitude',
            autoFocus    : false,
            fullWidth    : true,
            InputProps   : {
                inputComponent : input =>
                (
                    <NumberInput
                        allowNegative = { true }
                        floatCount    = { 7 }
                        maximum       = { maxLatitude }
                        minimum       = { minLatitude }
                        {...input}
                    />
                )
            },
            margin       : 'normal',
            placeholder  : `Ex: ${latitude}`,
            required     : false,
            select       : false,
            autoComplete : 'off' ,
            type         : 'text',
            variant      : 'outlined'
        }
    },
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'geo.longitude',
            key          : 'longitude',
            label        : 'Longitude',
            autoFocus    : false,
            fullWidth    : true,
            InputProps   : {
                inputComponent : input =>
                (
                    <NumberInput
                        allowNegative = { true }
                        floatCount    = { 7 }
                        maximum       = {maxLongitude}
                        minimum       = {minLongitude}
                        {...input}
                    />
                )
            },
            margin       : 'normal',
            placeholder  : `Ex: ${longitude}`,
            required     : false,
            select       : false,
            autoComplete : 'off' ,
            type         : 'text',
            variant      : 'outlined'
        }
    },
    {
        type  : Form.MAP_GEO ,
        label : '...',
        init  :
        {
            id             : 'map_geo_editor',
            key            : 'map_geo_editor',
            fullscreenMode : true,
            style          :
            {
                borderRadius : 10,
                height       : 220,
                marginTop    : 8,
                marginBottom : 8
            },
            zoom
        }
    },
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'geo.elevation',
            key          : 'elevation',
            label        : 'Élévation',
            autoFocus    : false,
            fullWidth    : true,
            InputProps   : {
                inputComponent : ( input ) =>
                (
                    <NumberInput
                        allowNegative = {true}
                        maximum       = {maxElevation}
                        minimum       = {minElevation}
                        suffix        = {' m'}
                        {...input}
                    />
                )
            } ,
            margin       : 'normal',
            placeholder  : `Ex: ${elevation} m`,
            required     : false,
            select       : false,
            autoComplete : 'off' ,
            type         : 'text',
            variant      : 'outlined'
        }
    }
];

export default
{
    add :
    {
        agree       : 'Créer' ,
        disagree    : 'Annuler' ,
        title       : 'Ajouter un lieu' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour créer une nouvelle fiche.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en  cours...' ,
        success     : 'Ajout du nouvel enregistrement réussi!' ,
        elements
    },
    remove :
    {
        agree        : 'Supprimer' ,
        description  : 'Souhaitez-vous supprimer le lieu :' ,
        descriptionX : 'Souhaitez-vous supprimer les {0} lieux :' ,
        disagree     : 'Annuler' ,
        loading      : 'Suppression en cours...' ,
        title        : 'Supprimer un lieu ?',
        titleX       : 'Supprimer des lieux ?'
    }
};
