import React from 'react'

import compose from 'vegas-js-core/src/functors/compose'

import initApp from '../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import Terrain from '@material-ui/icons/Terrain'

import PropertyValue from '../../things/PropertyValue'
import Livestock     from '../../things/Livestock'

import ThingChildren, { styles } from './ThingChildren'

import LivestockCell from '../cells/LivestockCell'
import Thing from '../../things/Thing';

class Livestocks extends ThingChildren
{
    addMapUri = ( item , uri ) =>
    {
        if( item instanceof PropertyValue)
        {
            const { value } = item ;
            if( value instanceof Thing )
            {
                const { id } = value ;
                return uri + '/' + id ;
            }
        }
        return uri ;
    };

    addPrepare = item =>
    {
        const { value } = item ;
        return { value } ;
    }

    createChild = child =>
    {
        if( child instanceof Livestock )
        {
            return <LivestockCell thing={child} />
        }
        return null ;
    };
    
    getLocale = () => this.props.locale.things.livestocks ;
}

Livestocks.defaultProps =
{
    ...ThingChildren.defaultProps ,
    animate    : true ,
    clazz      : Livestock ,
    emptyClazz : PropertyValue ,
    emptyIcon  : null ,
    icon       : <Terrain/> ,
    member     : 'livestocks'
};

Livestocks.propTypes =
{
    ...ThingChildren.propTypes
};

export default compose( withStyles( styles ) , withRouter , initApp )( Livestocks ) ;