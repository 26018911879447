import generify from './generify'

import Answer from './Answer'
import Thing  from './Thing'
import Word   from './Word'

import populateImage from "./creativework/populateImage"

/**
 * The Question.
 * @memberOf Thing
 */
class Question extends Thing
{
    /**
     * Creates a new Question instance.
     * @constructor
     * @param object The generic object to initialize the object the first time.
     */
    constructor( object = null )
    {
        super() ;

        /**
         * A background color value option.
         * @type {string}
         */
        this.bgcolor = null ;

        /**
         * The list of answers of this question.
         * @type {Array}
         */
        this.choices = null ;

        /**
         * A color value option.
         * @type {string}
         */
        this.color = null ;

        /**
         * The fail expression of this question.
         * @type {string|Object}
         */
        this.fail = null ;

        /**
         * Indicates if this question accept multiple answers.
         * @type {boolean}
         */
        this.multiple = false ;

        /**
         * The score value of this question.
         * @type {number}
         */
        this.score = 0 ;

        /**
         * The success expression of this question.
         * @type {string|Object}
         */
        this.success = null ;

        /**
         * The tip expression of this question.
         * @type {string|Object}
         */
        this.tip = null ;

        this.set( object ) ;
    }

    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        super.clear() ;
        this.multiple = false ;
        this.score    = 0 ;
        this.bgcolor  =
        this.choices  =
        this.color    =
        this.fail     =
        this.success  =
        this.tip      = null ;
        return this ;
    }

    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new Question( this.toObject(true) ) ;
    }

    /**
     * Returns the locale fail expression of the thing.
     * @param {string} [lang=null] the current lang.
     * @param {string} [defaultLang=null] the default lang.
     * @return {String} The locale fail expression of the thing.
     */
    getLocaleFail = this.getLocaleProperty('fail') ;

    /**
     * Returns the locale success expression of the thing.
     * @param {string} [lang=null] the current lang.
     * @param {string} [defaultLang=null] the default lang.
     * @return {String} The locale success expression of the thing.
     */
    getLocaleSuccess = this.getLocaleProperty('success') ;

    /**
     * Returns the locale tip expression of the thing.
     * @param {string} [lang=null] the current lang.
     * @param {string} [defaultLang=null] the default lang.
     * @return {String} The locale tip expression of the thing.
     */
    getLocaleTip = this.getLocaleProperty('tip') ;

    /**
     * Populate the object with specific behaviors or types. Launched by default in the constructor and in the set methods.
     * @returns {Thing} The current reference.
     */
    populate()
    {
        const {
            additionalType,
            choices
        } = this ;

        populateImage( this ) ;

        if( additionalType && !(additionalType instanceof Word ))
        {
            this.additionalType = new Word(additionalType) ;
        }

        if( choices instanceof Array )
        {
            this.choices = choices.map( item =>
            {
                if( !( item instanceof Answer ) )
                {
                    item = new Answer( item ) ;
                    item.subjectOf = this ;
                }
                return item ;
            }) ;
        }

        return this ;
    }

    /**
     * Returns the generic object representation of the item.
     * @param full {boolean} [full=false] - Indicates if the generic object contains all properties or a minimalist version of the instance.
     * @return {Object} The generic object representation of the item.
     */
    toObject( full = false )
    {
        let {
            additionalType,
            alternateName,
            bgcolor,
            choices ,
            color,
            description,
            fail,
            id,
            image,
            multiple ,
            score,
            success,
            tip
        } = this ;

        let clear = !full ;

        additionalType = generify(additionalType,clear);
        choices        = generify(choices,clear) ;
        image          = generify(image,clear) ;

        return {
            ...( full && super.toObject() ) ,
            additionalType,
            alternateName,
            bgcolor,
            choices ,
            color,
            description,
            fail,
            id,
            image,
            multiple ,
            score,
            success,
            tip
        }
    }
}

export default Question ;