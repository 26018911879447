/**
 * Creates a form to send datas.
 * @param url The url of the page to open
 * @param values The object to populates all input variables
 * @param method The method of the request (default post)
 * @param target The target of the request (default _blank)
 * @returns {HTMLFormElement}
 * @example
 * var form = getForm("http://example.com", "_blank", obj, "post");
 * document.body.appendChild(form);
 * form.submit();
 * form.parentNode.removeChild(form);
 */
export default function getForm( url , values ,  method = "post" , target = "_blank" )
{
    function grabValues(x)
    {
        let path = [];
        let depth = 0;
        let results = [];

        function iterate( x )
        {
            switch (typeof x)
            {
                case 'function':
                case 'undefined':
                case 'null' :
                {
                    break;
                }
                case 'object':
                {
                    if (Array.isArray(x))
                    {
                        for (let i = 0; i < x.length; i++)
                        {
                            path[depth++] = i;
                            iterate(x[i]);
                        }
                    }
                    else
                    {
                        for (let i in x)
                        {
                            path[depth++] = i;
                            iterate(x[i]);
                        }
                    }
                    break;
                }
                default:
                {
                    results.push(
                    {
                        path: path.slice(0),
                        value: x
                    });
                    break;
                }
            }
            path.splice(--depth);
        }

        iterate(x);
        return results;
    }

    let form = document.createElement('form');

    form.method = method;
    form.action = url;
    form.target = target;

    values = grabValues(values);

    let len = values.length ;

    for ( let j = 0 ; j < len ; j++ )
    {
        let input = document.createElement('input');

        input.type  = 'hidden' ;

        input.value = values[j].value;
        input.name  = values[j].path[0];

        let size = values[j].path.length ;

        for( let k = 1; k < size ; k++ )
        {
            input.name += "[" + values[j].path[k] + "]";
        }

        form.appendChild(input);
    }

    return form ;
}