import React from 'react'

import format from 'vegas-js-core/src/strings/format'

import api         from '../../../../../../../configs/api'
import Form        from '../../../../../../../display/forms/FormElements'
import Method      from '../../../../../../../net/Method'
import NumberInput from '../../../../../../../components/inputs/NumberInput'
import Thing       from '../../../../../../../things/Thing'

const elements =
[
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'identifier',
            key          : 'identifier',
            label        : 'Identifiant animal',
            autoFocus    : true,
            fullWidth    : true,
            InputProps   : {
                inputComponent : ( input ) => (
                    <NumberInput
                        allowEmptyFormatting = { true }
                        allowNegative        = { false }
                        format               = 'FR##########'
                        mask                 = '#'
                        useFormatted         = { true }
                        {...input}
                    />
                )
            } ,
            margin       : 'normal',
            select       : false,
            autoComplete : 'off' ,
            type         : 'text',
            variant      : 'outlined'
        }
    },
    {
        type         : Form.DATE ,
        defaultValue : null,
        format       : 'L' ,
        init :
        {
            id            : 'date',
            key           : 'date',
            autoOk        : true ,
            cancelLabel   : 'Annuler' ,
            clearable     : true ,
            clearLabel    : 'Vider' ,
            invalidLabel  : '-',
            inputVariant  : 'outlined' ,
            disableFuture : false ,
            disablePast   : false ,
            label         : 'Date de l\'événement' ,
            fullWidth     : true ,
            okLabel       : 'OK',
            variant       : 'dialog'
        }
    },
    {
        type       : Form.SEARCH_SELECTOR ,
        clearLabel : 'Vider' ,
        closeLabel : 'Fermer' ,
        init       :
        {
            id         : 'period',
            key        : 'period',
            label      : 'Période de gestation',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            type       : 'search',
            variant    : 'outlined'
        },
        path : ( props ) =>
        {
            const { rel } = props ;
            if( rel instanceof Thing )
            {
                const { subjectOf } = rel ;
                if( subjectOf )
                {
                    const { additionalType } = subjectOf ;
                    if( additionalType )
                    {
                        const { id } = additionalType ;
                        if( id )
                        {
                            return format(api.settings.ageGroupsByObservationsTypes, id);
                        }
                    }
                }
            }
            return null;
        },
        iconifiable : false ,
        searchable  : false,
        title       : 'Période de gestation'
    }
];

const event =
{
    datePattern : 'L',
    edit :
    {
        agree       : 'Sauver' ,
        disagree    : 'Annuler' ,
        description : 'Sélectionner un espace de travail' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...'  ,
        method      : Method.PATCH,
        mock        : false ,
        success     : 'Mise à jour réussie!' ,
        title       : 'Espace de travail',
        elements
    },
    labels :
    {
        add        : 'Créer un événement' ,
        date       : 'Date de l\'événement' ,
        empty      : '----',
        emptyEvent : 'Aucun événement d\'avortement dans cette observation.',
        identifier : 'Identifiant de l\'animal',
        period     : 'Période de gestation' ,
        title      : 'Événement'
    },
    snack :
    {
        fail    : 'Impossible de créer l\'événement',
        success : 'Événement créé avec succès'
    },
    tooltips :
    {
        'add'  : 'Ajouter',
        'edit' : 'Modifier'
    }
};

export default event ;
