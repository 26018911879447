import corsAnywhere from '../../corsAnywhere'

import URI from 'urijs'

export const PATH = 'https://www.youtube.com/oembed' ;

const youtube = ( url , format = 'json' ) =>
{
    const uri = new URI( corsAnywhere + PATH ) ;

    uri.addSearch( 'format' , format ) ;
    uri.addSearch( 'url'    , url    ) ;

    return uri.toString() ;
}

export default youtube ;
