import React, { Fragment } from 'react'

import clsx from 'clsx'

import notEmpty from 'vegas-js-core/src/strings/notEmpty'

import ReactDom from 'react-dom'

import PropTypes from 'prop-types'

import { Redirect } from 'react-router-dom'

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Typography
} from '@material-ui/core'

import { isWidthDown } from '@material-ui/core/withWidth'

import RequestStatus from '../../net/RequestStatus'
import Method        from '../../net/Method'
import slideUp       from '../../transitions/slideUp'
import XtraContainer from '../containers/XtraContainer'

class CoreDialog extends XtraContainer
{
    constructor( props )
    {
        super( props ) ;
        this.canceler        = null ;
        this.contentRef      = null ;
        this.onScrollContent = null ;
        this.mock            = false ;
        this.prepare         = null ;
        this.state =
        {
            ...this.state,
            datas  : null,
            errors : null ,
            path   : null ,
            status : RequestStatus.NEW
        };
    }

    get disabled() { return this.props.disabled ; }

    agree = () => null ; // console.log( this + ' agree' ) ;

    close = () =>
    {
        const { position , removeDialog, onCancel } = this.props ;
        // console.log( this + ' close' , position , removeDialog ) ;
        if( position >=0 && removeDialog instanceof Function )
        {
            removeDialog( position ) ;
        }
        if( onCancel instanceof Function )
        {
            onCancel({ position }) ;
        }
    };

    disagree = () => { this.close() ; } ;

    getAgreeButton = () =>
    {
        if( this.showAgreeButton() )
        {
            const {
                agreeClassName,
                agreeProps,
                agreeStyle,
            } = this.props ;

            return (
            <Button
                disabled  = { this.disabled }
                onClick   = { this.agree }
                color     = "secondary"
                variant   = "contained"
                className = { agreeClassName }
                style     = { agreeStyle }
                { ...agreeProps }
            >
                { this.getAgreeIcon() }
                { this.getAgreeLabel() }
            </Button>);
        }

        return null  ;
    };

    getAgreeIcon = () => this.props.agreeIcon ;

    getAgreeLabel = () =>
    {
        const locale = this.getLocale() ;
        if( locale )
        {
            const { agree } = locale ;
            if( notEmpty( agree ) )
            {
                return agree ;
            }
        }
        return '' ;
    };

    getDisagreeButton = () =>
    {
        if( this.showDisagreeButton() )
        {
            const {
                disagreeClassName,
                disagreeProps,
                disagreeStyle
            } = this.props ;
            return (
            <Button
                disabled  = { this.disabled }
                onClick   = { this.disagree }
                color     = "primary"
                variant   = "contained"
                className = { disagreeClassName }
                style     = { disagreeStyle }
                { ...disagreeProps }
            >
                { this.getDisagreeIcon() }
                { this.getDisagreeLabel() }
            </Button>);
        }
        return null ;
    };

    getDisagreeIcon = () => this.props.disagreeIcon ;

    getDisagreeLabel = () =>
    {
        const locale = this.getLocale() ;
        if( locale )
        {
            const { disagree } = locale ;
            if( notEmpty( disagree ) )
            {
                return disagree ;
            }
        }
        return '' ;
    };

    getContent = () => null ;

    getDescription = () => null ;

    getDialogProps = () => this.props.DialogProps;

    getFooterOption = () => this.props.footerOption ;

    getIcon = () =>
    {
        let { icon, useIcon } = this.props ;
        if( useIcon )
        {
            if( icon instanceof Function )
            {
                icon = icon( this.props ) ;
            }
            return icon ;
        }
        return null ;
    };

    getOptions = () => this.props.options ;

    getPaperProps = () => this.props.PaperProps;

    getTitle = () =>
    {
        const { divider } = this.props;
        return (
        <Fragment>
            <DialogTitle
                className = 'w-full items-center justify-between'
                style    = { this.getTitleStyle() }
            >
                <div className="w-full flex flex-row items-center">
                    { this.getIcon() }
                    { this.getTitleContent() }
                    { this.getOptions() }
                </div>
            </DialogTitle>
            { divider && <Divider light/> }
        </Fragment>
        );
    };

    getTitleInfo = () => null ;

    getTitleContent = () =>
    {
        return (
            <div className='flex flex-row flex-wrap flex-1 items-center'>
                <Typography
                    className = 'font-medium pr-8'
                    variant   = 'subtitle1'
                >
                    { this.getTitleLabel() }
                </Typography>
                { this.getTitleInfo() }
            </div>
        )
    };

    getTitleLabel = () =>
    {
        const locale = this.getLocale() ;
        if( locale )
        {
            const { title } = locale ;
            if( notEmpty( title ) )
            {
                return title ;
            }
        }
        return null ;
    };

    getTitleStyle = () => null ;

    isFullScreen = () => ( this.props.fullScreen === true ) || isWidthDown('sm', this.props.width ) ;

    render = () =>
    {
        const { path, status } = this.state ;

        if( status === RequestStatus.REDIRECT )
        {
            return <Redirect to={path} /> ;
        }

        const {
            classes,
            className,
            disableBackdropClick,
            disableEscapeKeyDown,
            fullWidth,
            keepMounted,
            maxWidth ,
            open,
            style,
            titleVisible,
            TransitionComponent
        }
        = this.props ;

        const footerOption = this.getFooterOption() ;
        const dialogProps  = this.getDialogProps() ;

        return (
            <Dialog
                className            = { clsx( classes.root , className ) }
                disableBackdropClick = { disableBackdropClick }
                disableEscapeKeyDown = { disableEscapeKeyDown }
                fullScreen           = { this.isFullScreen() }
                fullWidth            = { fullWidth }
                keepMounted          = { keepMounted }
                maxWidth             = { maxWidth }
                open                 = { open }
                onClose              = { this.close }
                PaperProps           = { this.getPaperProps() }
                style                = { style }
                TransitionComponent  = { TransitionComponent }
                { ...dialogProps }
            >
                { titleVisible && this.getTitle() }
                <DialogContent
                    className = { classes.content }
                    ref       = { ref => this.contentRef = ReactDom.findDOMNode(ref) }
                    onScroll  = { this.onScrollContent }
                >
                    { this.getDescription() }
                    { this.getContent() }
                </DialogContent>
                <DialogActions>
                    {
                        footerOption &&
                        <div className="mr-auto">
                            { footerOption }
                        </div>
                    }
                    { this.getAgreeButton() }
                    { this.getDisagreeButton() }
                </DialogActions>
            </Dialog>
        );
    };

    showAgreeButton = () => this.props.showAgreeButton ;

    showDisagreeButton = () => this.props.showDisagreeButton ;
}

CoreDialog.defaultProps =
{
    ...XtraContainer.defaultProps,
    agreeClassName       : null,
    agreeIcon            : null,
    agreeProps           : null,
    agreeStyle           : null,
    disableBackdropClick : false,
    disableEscapeKeyDown : false,
    disagreeClassName    : null,
    disagreeIcon         : null,
    disagreeProps        : null,
    disagreeStyle        : null,
    divider              : true,
    fullScreen           : undefined,
    fullWidth            : true,
    footerOption         : null ,
    icon                 : null,
    index                : null ,
    item                 : null,
    keepMounted          : false,
    maxWidth             : 'sm',
    method               : Method.GET,
    name                 : 'dialog_',
    open                 : false,
    onCancel             : null,
    onChange             : null ,
    options              : null,
    PaperProps           : {},
    prepare              : null,
    showAgreeButton      : true,
    showDisagreeButton   : true,
    thingRef             : null,
    titleVisible         : true,
    TransitionComponent  : slideUp,
    useIcon              : false,
    uri                  : null
};

CoreDialog.propTypes =
{
    ...XtraContainer.propTypes,
    agreeClassName       : PropTypes.string ,
    agreeIcon            : PropTypes.element,
    agreeStyle           : PropTypes.object ,
    agreeProps           : PropTypes.object,
    classes              : PropTypes.object.isRequired,
    DialogProps          : PropTypes.object,
    disableBackdropClick : PropTypes.bool,
    disableEscapeKeyDown : PropTypes.bool,
    disagreeClassName    : PropTypes.string ,
    disagreeIcon         : PropTypes.element,
    disagreeStyle        : PropTypes.object ,
    divider              : PropTypes.bool ,
    footerOption         : PropTypes.element,
    fullScreen           : PropTypes.bool ,
    fullWidth            : PropTypes.bool ,
    keepMounted          : PropTypes.bool ,
    icon                 : PropTypes.element,
    index                : PropTypes.number,
    item                 : PropTypes.object,
    maxWidth             : PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', false]),
    method               : PropTypes.oneOf([Method.GET]),
    mock                 : PropTypes.bool,
    name                 : PropTypes.string ,
    onCancel             : PropTypes.func,
    onChange             : PropTypes.func ,
    open                 : PropTypes.bool,
    options              : PropTypes.element,
    PaperProps           : PropTypes.object,
    position             : PropTypes.number,
    prepare              : PropTypes.func,
    showAgreeButton      : PropTypes.bool ,
    showDisagreeButton   : PropTypes.bool ,
    thingRef             : PropTypes.object,
    titleVisible         : PropTypes.bool ,
    TransitionComponent  : PropTypes.elementType,
    useIcon              : PropTypes.bool,
    uri                  : PropTypes.string
};

export default CoreDialog ;
