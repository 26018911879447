import React from 'react'

import compose   from 'vegas-js-core/src/functors/compose'
import isString  from 'vegas-js-core/src/isString'
import ucFirst   from 'vegas-js-core/src/strings/ucFirst'

import initApp from '../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { Fab, Typography } from '@material-ui/core'

import LinkIcon from '@material-ui/icons/Link'
import WebIcon  from '@material-ui/icons/Web'

import Thing                from '../../things/Thing';

import ThingCardChildren, { styles } from './ThingCardChildren'

import Website from '../../things/Website'
import Word    from '../../things/Word'

import AddWebsiteDialog    from '../dialogs/add/AddWebsiteDialog'
import RemoveWebsiteDialog from '../dialogs/remove/RemoveWebsiteDialog'

class Websites extends ThingCardChildren
{
    createChild = child =>
    {
        if( child  )
        {
            let { href } = child ;

            const { lang } = this.props ;

            let link ;

            if( isString( href ) )
            {
                let label = child.getLocaleAlternateName( lang ) ;
                if( isString( label ) && label !== '' )
                {
                    label = ucFirst(label) ;
                }
                else
                {
                    label = href ;
                }

                link = (
                <Typography
                    className = 'text-white truncate text-left normal-case px-8'
                    variant   = 'body2'
                >
                    { label }
                </Typography>) ;
            }

            if( isString(href) )
            {
                link = (
                <Fab
                    className = 'justify-start flex-0'
                    component = 'a'
                    color     = 'secondary'
                    href      = { href }
                    size      = 'small'
                    variant   = 'extended'
                >
                    <LinkIcon className='text-white mr-4 ml-4'/>
                    { link }
                </Fab>);
            }

            return (
                <div className="flex justify-start pt-20">
                    { link  }
                </div>
            );
        }

        return null ;
    };

    getCardIcon = child =>
    {
        if( child  )
        {
            const { lang } = this.props ;
            let { additionalType } = child ;
            if( additionalType )
            {
                const { image } = additionalType ;
                const type = additionalType.getLocaleName(lang) ;
                if( isString(image) )
                {
                    return <img loading="lazy" className="mr-4" height={16} width={16} src={ image + "?w=30"} alt={type} /> ;
                }
            }
        }
        return this.props.cardIcon ;
    };

    getCardLabel = child =>
    {
        if( child  )
        {
            const { lang } = this.props ;
            let { additionalType } = child ;
            if( additionalType instanceof Word )
            {
                let type = additionalType.getLocaleName(lang) ;
                if( isString(type) )
                {
                    return ucFirst(type) ;
                }
            }
        }
        return this.props.cardLabel ;
    };

    getLocale = () => this.props.locale.things.websites ;

    onCardClick = child => () => { this.openEditDialog(child) ; };

    prepare = item =>
    {
        let {
            alternateName,
            additionalType,
            href,
            order
        } = item ;

        if( alternateName )
        {
            const { config : { languages } = {} } = this.props ;
            if( languages )
            {
                languages.forEach( lang =>
                {
                    if( !isString(alternateName[lang]) )
                    {
                        alternateName[lang] = '' ;
                    }
                });
            }
        }

        return (
        {
            alternateName ,
            additionalType : additionalType instanceof Thing ? String(additionalType.id) : null ,
            href,
            order
        });
    };
}

Websites.defaultProps =
{
    ...ThingCardChildren.defaultProps ,

    AddDialogComponent    : AddWebsiteDialog ,
    RemoveDialogComponent : RemoveWebsiteDialog ,

    clazz          : Website,
    cardDivider    : 'fullWidth',
    cardPaperProps :
    {
        elevation : 1
    },
    containerProps :
    {
        className : "bg-grey-200 w-full p-20 mb-12",
        elevation : 1,
        square    : false
    },
    emptyIcon : null ,
    icon      : <WebIcon/>,
    member    : 'websites'
};

Websites.propTypes =
{
    ...ThingCardChildren.propTypes
};

export default compose( withStyles( styles ) , withRouter , initApp )( Websites ) ;
