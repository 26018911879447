import about from './team/about'

const team =
{
    about ,

    helmet :
    {
        title : 'Équipe - {0}' ,
        metas : []
    },
    loading  : "Chargement ..." ,
    notFound : "Équipe inconnue" ,
    snack    : { success : 'Fiche chargée' },
    tabs     :
    {
        about : 'À propos'
    } ,
    tooltips :
    {
        back : 'Toutes les équipes'
    }
};

export default team ;