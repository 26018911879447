import User from '../../vo/User'

import readUser from './readUser'

const name = process.env.REACT_APP_NAME ;

const storageID = name + '_user' ;

export let user = new User({ storageID }) ;

readUser( user , storageID ) ;

export default user ;