import isString from 'vegas-js-core/src/isString'
import clean    from 'vegas-js-core/src/objects/clean'

import moment from 'moment'

import AbortionEvent       from '../../../../../../../things/livestock/events/AbortionEvent'
import AbortionSerieReview from '../../../../../../../things/livestock/reviews/AbortionSerieReview'

const prepare = ( item , props ) =>
{
    if( item instanceof AbortionEvent )
    {
        let {
            id,
            identifier,
            date,
            period,
            subjectOf:review
        } = item ;

        if( review instanceof AbortionSerieReview )
        {
            const { member } = props ;

            review = review.toObject() ;

            let events ;

            if( review.hasOwnProperty(member) && review[member] instanceof Array )
            {
                events = review[ member ];
            }
            else
            {
                events = []
            }

            item.date       = date instanceof Date ? moment(date).toISOString() : null ;
            item.identifier = isString(identifier) ? identifier : null ;
            item.period     = period ;

            item = clean(item.toObject()) ;

            let position = -1 ;

            if( events.length > 0 )
            {
                position = events.findIndex( element => element.id === id ) ;
            }

            if( position === -1 )
            {
                events = [ ...events , item ] ;
            }
            else
            {
                let event = events[position] ;
                event.identifier = item.identifier;
                event.date       = item.date ;
                event.period     = item.period ;

                events[position] = event ;
            }

            review[ member ] = events ;

            return { review:JSON.stringify(review) } ;
        }
    }
    return null ;
};

export default prepare ;
