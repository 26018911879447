import React, { Component } from 'react'

import clsx from 'clsx'

import blueGrey from '@material-ui/core/colors/blueGrey'
import grey     from '@material-ui/core/colors/grey'

import ReactDOM from 'react-dom'

import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'

import { IconButton } from '@material-ui/core'

import UploadIcon from '@material-ui/icons/CloudUpload'

const styles = () => (
{
    icon :
    {
        fontSize : 48
    },
    iconDisabled :
    {
        color : grey[200]
    },
    iconNormal :
    {
        color : blueGrey[600]
    },
    iconOver :
    {
        color : blueGrey[100]
    },
    input :
    {
        display : 'none'
    }
});

class UploadButton extends Component
{
    constructor( props )
    {
        super( props ) ;
        this.input = null ;
        this.state =
        {
            over : false
        };
    }

    componentDidMount()
    {
        const { over } = this.props ;
        this.setState({ over });
    }

    componentDidUpdate()
    {
        const { over } = this.props ;
        if( over !== this.state.over  )
        {
            this.setState({ over });
        }
    }

    onChange = event =>
    {
        if( event )
        {
            const { target } = event ;
            if( target )
            {
                const { files } = target ;
                if( files )
                {
                    const result = [] ;

                    const len = files.length ;

                    for( let i = 0 ; i<len ; i++ )
                    {
                        result.push( files[i] ) ;
                    }

                    const { onFileDrop } = this.props ;
                    if( onFileDrop instanceof Function )
                    {
                        onFileDrop( result ) ;
                    }
                }
            }
        }
    };

    onClick = () =>
    {
        if( this.input )
        {
            ReactDOM.findDOMNode( this.input ).click() ;
        }
    };

    render()
    {
        const {
            accept,
            className,
            classes,
            edge,
            disabled,
            multiple,
            size,
            style
        } = this.props ;

        const { over } = this.state ;

        const iconClass = clsx(
            classes.icon,
            disabled ? classes.iconDisabled
                     : ( over ? classes.iconOver : classes.iconNormal )
        );

        let { icon } = this.props ;
        if( icon instanceof Function )
        {
            icon = icon( iconClass, this.props ) ;
        }
        else
        {
            icon = <UploadIcon className={ iconClass } /> ;
        }

        return (
        <div className={ className } style={{ ...style }}>
            <IconButton
                edge     = { edge }
                disabled = { disabled }
                onClick  = { this.onClick }
                size     = { size }

            >
                { icon }
            </IconButton>
            <input
                accept    = { accept }
                className = { classes.input }
                hidden    = { true }
                multiple  = { multiple }
                onChange  = { this.onChange }
                ref       = { (ref) => { this.input = ref; } }
                type      = 'file'
            />
        </div>);
    }

}

UploadButton.defaultProps =
{
    accept     : null,
    base64     : false, // TODO not implemented for the moment
    className  : null,
    disabled   : false,
    edge       : false,
    icon       : null ,
    multiple   : false,
    onFileDrop : null,
    over       : false,
    size       : 'medium' ,
    style      : null
};

UploadButton.propTypes =
{
    accept     : PropTypes.bool,
    base64     : PropTypes.bool,
    className  : PropTypes.string,
    disabled   : PropTypes.bool,
    edge       : PropTypes.oneOf(['start','end',false]),
    icon       : PropTypes.oneOfType([PropTypes.element,PropTypes.func]),
    iconClass  : PropTypes.string,
    multiple   : PropTypes.bool,
    onFileDrop : PropTypes.func,
    over       : PropTypes.bool,
    size       : PropTypes.oneOf(['medium','small']),
    style      : PropTypes.object
};

export default withStyles(styles)( UploadButton ) ;
