import analyses from './medical/analyses'
import analysis from './medical/analysis'
import method   from './medical/method'
import sampling from './medical/sampling'

const medical =
{
    analyses,
    analysis,
    method,
    sampling
};

export default medical ;