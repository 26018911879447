import isString   from 'vegas-js-core/src/isString'

import initDialog from '../initDialog'

import Thing from '../../../things/Thing'

import { AddDialog } from '../AddDialog'

const styles = () => ({});

export class AddTelephoneDialog extends AddDialog
{
    getLocale = () => this.props.locale.dialogs.things.telephone.add ;

    prepare = item =>
    {
        let { alternateName, additionalType, value } = item ;

        if( alternateName )
        {
            const { config } = this.props ;
            if( config )
            {
                const { languages } = config ;
                languages.forEach( lang =>
                {
                    if( !isString(alternateName[lang]) )
                    {
                        alternateName[lang] = '' ;
                    }
                });
            }
        }

        return (
        {
            alternateName ,
            additionalType : additionalType instanceof Thing ? String(additionalType.id) : null ,
            value
        });
    };
}

AddTelephoneDialog.defaultProps =
{
    ...AddDialog.defaultProps
};

AddTelephoneDialog.propTypes =
{
    ...AddDialog.propTypes
};

export default initDialog({ styles })( AddTelephoneDialog ) ;
