import React from 'react'

import compose  from 'vegas-js-core/src/functors/compose'

import { withRouter } from 'react-router-dom'

import AddIcon   from '@material-ui/icons/VideoLibrary'
import VideoIcon from '@material-ui/icons/Movie'

import initApp   from '../../../contexts/app/initApp'
import withSnack from '../../../contexts/snack/withSnack'

import Media from './Media'

import api from '../../../configs/api'

import VideoCell   from '../../cells/VideoCell'
import VideoObject from '../../../things/creativework/media/VideoObject'

export class Video extends Media
{
    getContent = () =>
    {
        let view ;
        let thing = this.getThingMember() ;
        if( thing instanceof VideoObject )
        {
            view = (
            <VideoCell
                mode    = 'button'
                thing   = { thing }
                onClick = { () =>
                {
                    this.openMediaDialog( thing , { removable:false } ) ;
                }}
            />) ;
        }
        else
        {
            view = (
                <div className='flex-1 w-full h-160 flex items-center justify-center'>
                    <VideoIcon
                        color = 'action'
                        style = {{ fontSize : 40 }}
                    />
                </div>
            );
        }

        return (
        <div className='flex items-center justify-center w-full'>
            { view }
        </div>);
    };

    getEntry = media => media instanceof VideoObject ? media : new VideoObject(media) ;

    getLocale = () => this.props.locale.things.media.video;

    onMedia = () =>
    {
        if( this._mounted )
        {
            this.forceUpdate() ;
        }
    }
}

Video.defaultProps =
{
    ...Media.defaultProps ,
    icon             : <VideoIcon/> ,
    member           : 'video' ,
    memberClazz      : VideoObject,
    optionMode       : 'custom',
    selectMediaIcon  : <AddIcon/>,
    selectMediaProps :
    {
        disableEscapeKeyDown : true ,
        facets               : { encodingFormat : 'video' } ,
        maxItems             : 1,
        multiple             : false,
        uploadable           : false,
        uploadMultiple       : false,
        uploadUri            : api.mediaObjects.url
    },
    selectMediaUri : api.mediaObjects.url
};

Video.propTypes =
{
    ...Media.propTypes
};

export default compose( initApp, withRouter , withSnack )( Video ) ;
