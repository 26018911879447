import React from 'react'

import GamesList from '../../display/lists/GamesList'

import View from '../../display/views/View'

import initView from '../../display/views/initView'

const styles = () => ({}) ;

class Games extends View
{
    build = () =>
    {
        return (
        <div className='flex flex-col w-full'>
            <GamesList parent = { this.element } />
        </div>);
    }
    
    getLocale = () => this.props.locale.views.games ;
}

Games.defaultProps =
{
    ...View.defaultProps
};

Games.propTypes =
{
    ...View.propTypes
};

export default initView( Games, styles );
