import initDialog from '../initDialog'

import { RemoveDialog } from '../RemoveDialog'

import api from '../../../configs/api'

const styles = () => ({});

class RemoveArticleDialog extends RemoveDialog
{
    getLocale = () => this.props.locale.dialogs.application.article.remove ;
}

RemoveArticleDialog.defaultProps =
{
    ...RemoveDialog.defaultProps,
    uri    : api.articles.url,
    useUrl : true
};

RemoveArticleDialog.propTypes =
{
    ...RemoveDialog.propTypes
};

export default initDialog({ styles })( RemoveArticleDialog );
