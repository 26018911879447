import React from 'react'

import PropTypes from 'prop-types'

import compose from 'vegas-js-core/src/functors/compose'
import leading from 'vegas-js-core/src/numbers/leading'

import initApp from '../../../../../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import red from '@material-ui/core/colors/red'

import { Fab } from '@material-ui/core'

import { FaFlask } from 'react-icons/fa'
import { FaNotesMedical } from 'react-icons/fa'

import api from '../../../../../../configs/api'

import ThingCardChildren , { styles } from '../../../../ThingCardChildren'

import Analysis from './Analysis'

import DialogError from '../../../../../../errors/DialogError'

import AbortionEventVO     from '../../../../../../things/livestock/events/AbortionEvent'
import AbortionSerieReview from '../../../../../../things/livestock/reviews/AbortionSerieReview';
import Disease             from '../../../../../../things/medical/Disease'
import MedicalAnalysis     from '../../../../../../things/medical/MedicalAnalysis'
import MedicalMethod       from '../../../../../../things/medical/MedicalMethod'
import MedicalSampling     from '../../../../../../things/medical/MedicalSampling'
import Word                from '../../../../../../things/Word'

const getURI = ( path , props ) =>
{
    const { thing } = props ;
    if( thing )
    {
        const { subjectOf:review } = thing ;
        if( review )
        {
            const { subjectOf:observation } = review ;
            if( observation )
            {
                const { id } = observation ;
                if( id )
                {
                    return api.observations.url + '/' + id ;
                }
            }
        }
    }
    return null ;
};

const addPrepare = member => item =>
{
    if( item instanceof MedicalAnalysis )
    {
        let { disease } = item ;
        if( disease instanceof Disease )
        {
            const { subjectOf:event } = item ;
            if( event instanceof AbortionEventVO )
            {
                let { subjectOf:review } = event ;
                if( review instanceof AbortionSerieReview )
                {
                    if( review.hasOwnProperty(member) )
                    {
                        let events = review[member] ;
                        if( events instanceof Array && events.length > 0 )
                        {
                            let position = events.findIndex( element => element.id === event.id ) ;
                            if( position > -1 )
                            {
                                let { analysis } = event ;

                                if( !(analysis instanceof Array) )
                                {
                                    analysis = [] ;
                                }

                                let method ;
                                let sampling ;

                                let { analysisMethod, analysisSampling } = disease ;

                                if( analysisMethod instanceof Array && analysisMethod.length > 0 )
                                {
                                    analysisMethod = analysisMethod.filter( element =>
                                    {
                                        if( element instanceof MedicalMethod )
                                        {
                                            return !!(element.mandatory) && (element.additionalType instanceof Word) ;
                                        }
                                        return false ;
                                    }) ;

                                    if( analysisMethod instanceof Array )
                                    {
                                        method = analysisMethod.map( element => element.additionalType ) ;
                                    }
                                }

                                if( analysisSampling instanceof Array && analysisSampling.length > 0 )
                                {
                                    analysisSampling = analysisSampling.filter( element =>
                                    {
                                        if( element instanceof MedicalSampling )
                                        {
                                            return !!(element.mandatory) && (element.additionalType instanceof Word) ;
                                        }
                                        return false ;
                                    }) ;

                                    if( analysisSampling instanceof Array )
                                    {
                                        sampling = analysisSampling.map( element => element.additionalType ) ;
                                    }
                                }

                                item.method   = method ;
                                item.sampling = sampling ;

                                event.analysis = [ ...analysis , item.toObject() ] ;

                                events[position] = event ;
                                review[member]   = events ;

                                review = review.toObject(false);

                                // console.log( 'addPrepareAnalyses' , review ) ;

                                return { review : JSON.stringify(review) } ;
                            }

                        }
                    }
                }
            }
        }
        else
        {
            throw new DialogError( { disease : 'disease is required' } ) ;
        }
    }
    return null ;
};

const editPrepare = member => item =>
{
    if( item instanceof MedicalAnalysis )
    {
        let { id, subjectOf:event } = item ;
        if( event instanceof AbortionEventVO )
        {
            let { subjectOf:review } = event ;
            if( review instanceof AbortionSerieReview )
            {
                let events = review[member] ;
                if( events instanceof Array && events.length > 0 )
                {
                    let position = events.findIndex( element => element.id === event.id ) ;
                    if( position > -1 )
                    {
                        const { analysis } = event ;
                        if( analysis instanceof Array )
                        {
                            let pos = analysis.findIndex( element => element.id === id ) ;
                            if( pos > -1 )
                            {
                                let old = analysis[pos];
                                const { disease } = item ;
                                if( disease === null || (old && old.disease && old.disease.id !== disease.id ))
                                {
                                    item.method   = null ;
                                    item.sampling = null ;
                                }

                                analysis[pos] = item.toObject() ;
                            }
                        }

                        events[position] = event ;
                        review[member]   = events ;

                        review = review.toObject(false);

                        // console.log( 'editPrepareAnalyses' , review ) ;

                        return { review : JSON.stringify(review) } ;
                    }
                }
            }
        }

    }
    return null ;
};

const removePrepare = member => item =>
{
    if( item instanceof MedicalAnalysis )
    {
        let { id, subjectOf:event } = item ;
        if( event instanceof AbortionEventVO )
        {
            let { subjectOf:review } = event ;
            if( review instanceof AbortionSerieReview )
            {
                let events = review[member] ;
                if( events instanceof Array && events.length > 0 )
                {
                    let position = events.findIndex( element => element.id === event.id ) ;
                    if( position > -1 )
                    {
                        let { analysis } = event ;
                        if( analysis instanceof Array && analysis.length > 0 )
                        {
                            event.analysis = analysis.filter( element => element.id !== id ) ;
                        }

                        events[position] = event ;
                        review[member]   = events ;

                        review = review.toObject(false);

                        return { review : JSON.stringify(review) } ;
                    }
                }
            }
        }
    }
    return null ;
};

class Analyses extends ThingCardChildren
{
    createChild = child =>
    {
        //console.log( this + ' createChild' , child ) ;
        const { memberOf , subjectOf } = this.props ;
        return (
        <div>
            <Analysis
                className      = 'mt-8'
                containerProps = {{ className : "bg-transparent w-full shadow-none py-8" }}
                memberOf       = { memberOf }
                subjectOf      = { subjectOf }
                thing          = { child }
            />
        </div>) ;
    };

    getCardLabel = ( child, editable, index ) =>
    {
        const locale = this.getLocale() ;
        if( locale )
        {
            const { labels } = locale ;
            if( labels )
            {
                let { card:label } = labels ;
                if( index >= 0 )
                {
                    label = leading(++index) + ' - ' + label ;
                }
                return label ;
            }
        }
        return null ;
    };

    getLocale = () => this.props.locale.things.livestock.reviews.abortionSerie.medical.analyses ;

    addUri    = getURI ;
    editUri   = getURI ;
    removeUri = getURI ;

    addPrepare    = item => addPrepare(this.props.memberOf)(item) ;
    editPrepare   = item => editPrepare(this.props.memberOf)(item);
    removePrepare = item => removePrepare(this.props.memberOf)(item);

    getBottom = this.getCustomCardWithAddButton ;

    onCardClick = item => () => this.openEditDialog( item ) ;

    onAdd    = this.change ;
    onEdit   = this.change ;
    onRemove = this.change ;
}

Analyses.defaultProps =
{
    ...ThingCardChildren.defaultProps,
    cardIcon              : <FaNotesMedical size={20}/> ,
    clazz                 : MedicalAnalysis,
    DeleteButtonComponent : Fab,
    DeleteButtonProps     :
    {
        size  : 'small',
        style : {
            backgroundColor : 'white',
            color           : red[600],
            '&:hover' :
            {
                backgroundColor : red[900],
                color           : 'white'
            }
        }
    },
    icon       : <FaFlask size={20}/>,
    member     : 'analysis',
    optionMode : 'none',
    memberOf   : 'aborted' ,
    path       : null,
    useUUID    : true
};

Analyses.propTypes =
{
    ...ThingCardChildren.propTypes,
    memberOf : PropTypes.string ,
    path     : PropTypes.string
};

export default compose( withStyles( styles ) , withRouter , initApp )( Analyses ) ;
