import Thing from './Thing'
import Word  from './Word'

/**
 * A transportation definition object.
 * @memberOf things
 */
class Transportation extends Thing
{
    /**
     * Creates a new Transportation instance.
     * @constructor
     * @param object The generic object to initialize the object the first time.
     */
    constructor( object = null )
    {
        super() ;

        /**
         * The duration of the transportation
         * @type {Integer}
         */
        this.duration = null ;

        this.set( object ) ;
    }

    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        super.clear() ;
        this.duration = null ;
        return this ;
    }

    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new Transportation( this.toObject() ) ;
    }

    /**
     * Populate the object with specific behaviors or types. Launched by default in the constructor and in the set methods.
     * @returns {Thing} The current reference.
     */
    populate()
    {
        if( this.additionalType && !(this.additionalType instanceof Word))
        {
            this.additionalType = new Word(this.additionalType) ;
        }
        return this ;
    }

    /**
     * Returns the generic object representation of the item.
     * @return {Object} The generic object representation of the item.
     */
    toObject()
    {
        const { duration  } = this ;
        return {
            ...super.toObject() ,
            duration
        }
    }
}

export default Transportation ;