import moment from 'moment'

import DialogError         from '../../../../../../../errors/DialogError'

import AbortionSerieReview from '../../../../../../../things/livestock/reviews/AbortionSerieReview'
import Word                from '../../../../../../../things/Word'

const prepare = ( item ) =>
{
    let {
        id = null ,
        about,
        date,
        subjectOf:review
    }
    = item ;

    if( !(about instanceof Word) )
    {
        throw new DialogError({ about:'vaccine is required' })
    }

    if( review instanceof AbortionSerieReview )
    {
        review = review.toObject(false);

        let { vaccinations } = review ;

        let position = -1 ;

        if( vaccinations instanceof Array )
        {
            position = vaccinations.findIndex( element => element.id === id ) ;
        }
        else
        {
            vaccinations = [] ;
        }

        const event =
        {
            id ,
            about,
            date : date instanceof Date ? moment(date).format( 'YYYY-MM-DD' ) : null ,
        };

        if( position > -1 )
        {
            vaccinations[position] = event ;
        }
        else
        {
            vaccinations = [ ...vaccinations , event ] ;
        }

        review.vaccinations = vaccinations ;

        return { review : JSON.stringify(review) } ;
    }
    return null ;
};

export default prepare ;
