import React from 'react'

import clsx from 'clsx'

import PropTypes from 'prop-types'

import notEmpty from 'vegas-js-core/src/strings/notEmpty'
import isString from 'vegas-js-core/src/isString'

import { FaLaptopCode as CodeIcon } from 'react-icons/fa'

import { Typography, Tooltip } from '@material-ui/core'

import CloudIcon   from '@material-ui/icons/Cloud'
import CurrentIcon from '@material-ui/icons/CloudDone'
import DeviceIcon  from '@material-ui/icons/DevicesOther'
import LabelIcon   from '@material-ui/icons/ShortText'

import withConfig from '../../contexts/config/withConfig'
import withi18n   from '../../contexts/i18n/withi18n'

import LogoutLabel from '../labels/LogoutLabel'

import { ThingCell } from './ThingCell'

import getLocaleThingLabel from '../../things/getLocaleThingLabel'
import getLocaleHeadline   from '../../things/getLocaleHeadline'

import Application from '../../vo/Application'
import Session     from '../../vo/Session'

import getHorizontalElements from '../getHorizontalElements'

class SessionCell extends ThingCell
{
    getAgent = () =>
    {
        const { agent:visible } = this.props ;
        if( visible )
        {
            const { thing : { agent } = {} } = this.props ;
            if( notEmpty(agent) )
            {
                return (
                    <div className='flex flex-row items-center py-4'>
                        <DeviceIcon className='mr-8' fontSize='small' />
                        <Typography
                            className = "font-normal"
                            variant   = 'caption'
                        >
                            { agent }
                        </Typography>
                    </div>
                );
            }
        }
        return null ;
    };

    getApplication = () =>
    {
        const { application:visible } = this.props ;
        if( visible )
        {
            const { thing : {app} = {} } = this.props;
            if( app instanceof Application )
            {
                const { lang } = this.props ;
                let label = getLocaleHeadline( app, lang ) ;
                if( !isString(label) )
                {
                    label = getLocaleThingLabel( app, lang ) ;
                }
                if ( notEmpty(label) )
                {
                    return (
                        <div className='flex flex-row items-center py-4'>
                            <LabelIcon className='mr-8' fontSize='small' />
                            <Typography
                                className = 'font-medium'
                                variant   = 'caption'
                            >
                                { label }
                            </Typography>
                        </div>
                    )
                }
            }
        }
        return null ;
    };

    getContent = () =>
    {
        const { informations:visible , thing } = this.props;
        if ( visible && thing instanceof Session )
        {
            const agent       = this.getAgent() ;
            const application = this.getApplication() ;
            const id          = this.getId() ;
            if( agent || application || id )
            {
                return (
                    <div className={ clsx( "flex flex-1 flex-col px-8") } >
                        { application }
                        { agent }
                        { id }
                    </div>
                )
            }
        }
        return null;
    };

    getId = () =>
    {
        const { id:visible } = this.props ;
        if( visible )
        {
            const { thing : { id } = {} } = this.props ;
            if( id )
            {
                return (
                    <div className='flex flex-row items-center py-4'>
                        <CodeIcon className='mr-8' size='1.3em' />
                        <Typography
                            className = "font-normal"
                            variant   = 'caption'
                        >
                            { id }
                        </Typography>
                    </div>
                );
            }
        }
        return null ;
    };

    getLocaleTitle = () =>
    {
        const { thing : { ip } = {} } = this.props ;
        if( notEmpty(ip) )
        {
            return ip ;
        }
        return null ;
    };

    getLocale = () => this.props.locale.display.cells.session;

    getLogout = ({ className = null } = {} ) =>
    {
        const { logout:visible, thing } = this.props ;
        if( visible && thing )
        {
            let { logout } = thing ;
            if( logout )
            {
                return <LogoutLabel className={ className } thing={ thing }/>;
            }
        }
        return null ;
    };

    getMetaDatas = ({ className = 'py-4 text-gray-500' } = {} ) =>
    {
        const created  = this.getCreated() ;
        const modified = this.getModified() ;
        const logout   = this.getLogout() ;
        return getHorizontalElements({
            className ,
            elements : [ created , modified , logout ]
        }) ;
    };

    getPath = () =>
    {
        const { thing : { ip } = {} } = this.props ;
        if( notEmpty(ip) )
        {
            const { config: { ip_api_url: path = "" } = {}} = this.props;
            return path + ip;
        }
        return null ;
    };

    getType = ( { member = 'type' , className = null } = {} ) =>
    {

        let { [member]:type , isCurrent } = this.getThing() || {} ;

        if ( notEmpty(type) )
        {
            const { type : { [type] : { backgroundColor , color , text } = {} } = {} } = this.getLocale() || {} ;
            if( notEmpty(text) )
            {
                type = (
                    <span
                        className = { clsx( 'rounded px-8 text-lg font-semibold mr-8' , className ) }
                        style     = {{ backgroundColor , color }}
                    >
                        { text }
                    </span>
                )
            }
            else
            {
                type = null ;
            }
        }
        else
        {
            type = null ;
        }

        let flag ;
        if( isCurrent )
        {
            flag = <CurrentIcon className='text-green-600' fontSize='small'/> ;
            const { current } = this.getLocale() || {} ;
            if( notEmpty(current) )
            {
                flag = (
                    <Tooltip title={current} placement='top'>
                        { flag }
                    </Tooltip>
                )
            }
        }

        if( flag || type )
        {
            return (
                <div className='flex flex-row items-center'>
                    { type }
                    { flag }
                </div>
            );
        }

        return null ;
    };
}

SessionCell.defaultProps =
{
    ...ThingCell.defaultProps,
    agent        : true,
    application  : true,
    icon         : <CloudIcon fontSize='small'/>,
    id           : true ,
    informations : true ,
    logout       : true ,
    mode         : 'button' ,
    titleProps   : { className:'font-normal' , variant:'body2' } ,
    token        : true ,
    typeMember   : 'type'
};

SessionCell.propTypes =
{
    ...ThingCell.propTypes,
    agent       : PropTypes.bool,
    application : PropTypes.bool,
    id          : PropTypes.bool,
    logout      : PropTypes.bool,
    token       : PropTypes.bool,
    type        : PropTypes.bool
};

export default withConfig( withi18n( SessionCell ) ) ;
