import Form from '../../../display/forms/FormElements'

const elements =
[
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'identifier',
            key          : 'identifier',
            label        : 'N° ordinal',
            autoFocus    : false,
            fullWidth    : true,
            helperText   : null,
            margin       : 'normal',
            required     : false,
            select       : false,
            autoComplete : 'off' ,
            type         : 'text',
            variant      : 'outlined'
        }
    }
];

const prepare = ( item ) =>
{
    let {
        identifier
    } = item ;

    const object = (
    {
        identifier
    });
    return object ;
};

const veterinarian =
{
    // -------- settings

    edit :
    {
        agree       : 'Sauver' ,
        disagree    : 'Annuler' ,
        description : 'Mettre à jour le numéro ordinal du vétérinaire.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...' ,
        success     : 'Mise à jour réussie!' ,
        title       : 'Modifier le numéro ordinal' ,
        elements,
        prepare
    },
    labels :
    {
        empty      : '----' ,
        name       : 'Nom' ,
        identifier : 'N° ordinal'
    },
    title    : 'Vétérinaire' ,
    tooltips :
    {
        add    : 'Ajouter',
        edit   : 'Modifier',
        remove : 'Supprimer'
    }
};

export default veterinarian ;