import about from './event/about'

const event =
{
    about ,
    helmet :
    {
        title : 'Événement - {0}' ,
        metas : []
    },
    loading  : 'Chargement ...' ,
    notFound : 'Événement inconnu' ,
    period :
    {
        day       : "Le {0}" ,
        date      : 'ddd ll',
        dateTime  : 'ddd ll à HH:mm',
        interval  : "Du {0} au {1}" ,
        time      : 'HH:mm',
        timeRange : "Le {0} de {1} à {2}" ,
        until     : "Jusqu'au {0}"
    },
    tabs :
    {
        about  : 'À propos',
        info   : 'Infos',
        medias : 'Médias'
    },
    tooltips :
    {
        back : 'Tous les événements'
    }
};

export default event ;