import React from 'react'

import clsx from 'clsx'

import PropTypes from 'prop-types'

import moment from 'moment/moment'

import generateUUID from 'vegas-js-core/src/random/generateUUID'

import isString from 'vegas-js-core/src/isString'
import notEmpty from 'vegas-js-core/src/strings/notEmpty'

import {
    IconButton,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Tooltip,
    Typography,
} from '@material-ui/core'

import DeleteIcon from '@material-ui/icons/Delete'
import EmptyIcon  from '@material-ui/icons/FilterNone'

import ThingContainer from '../containers/ThingContainer'

import ThingCell from '../cells/ThingCell'

import PATCH         from '../../net/PATCH'
import RequestStatus from '../../net/RequestStatus'

import api from '../../configs/api'

import Thing from '../../things/Thing'

import sortByModified from '../../things/sortByModified'
import sortByName     from '../../things/sortByName'
import sortByPosition from '../../things/sortByPosition'

import TransitionGroup from '../../transitions/TransitionGroup'

export const styles = theme =>
({
    empty :
    {
        display : 'flex',
        flex    : 1
    },
    expandClose :
    {
        duration   : theme.transitions.duration.shortest,
        marginLeft : 'auto',
        transform  : 'rotate(0deg)',
        transition : theme.transitions.create('transform', {})
    },
    expandOpen :
    {
        transform : 'rotate(180deg)',
    },
    row :
    {
        '&:nth-of-type(even)':
        {
            backgroundColor: theme.palette.background.default
        },
        width : '100%'
    },
    speedDial :
    {
        position : 'absolute' ,
        '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft' :
        {
            bottom : theme.spacing( 2 ) ,
            right  : theme.spacing( 2 ) ,
        } ,
        '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight' :
        {
            top  : theme.spacing( 2 ) ,
            left : theme.spacing( 2 ) ,
        }
    }
});

class ThingChildren extends ThingContainer
{
    /**
     * Creates a new ThingChildren instance
     * @param props The properties send to the component to initialize it.
     * @param initState The optional object to populate the state object of the component.
     */
    constructor( props , initState = null )
    {
        super( props ) ;
        this.canceler     = null ;
        this.sort         = null ;
        this.state        =
        {
            ...this.state ,
            actives      : {} ,
            activeStatus : RequestStatus.NEW,
            addStatus    : RequestStatus.NEW ,
            draggable    : true,
            dragging     : false,
            dropIndex    : 0,
            open         : false ,
            orderStatus  : RequestStatus.NEW,
            sortStatus   : RequestStatus.NEW,
            ...initState
        }
    }

    getEmpty = () =>
    {
        const { emptyIcon:icon } = this.props;

        let label = this.getEmptyLabel() ;

        if( notEmpty(label) )
        {
            const {
                emptyClassName ,
                emptyProps ,
                emptyVariant
            } = this.props ;

            label = (
                <Typography
                    className = { icon ? clsx( 'mt-16' , emptyClassName ) : emptyClassName }
                    color     = 'textPrimary'
                    variant   = { emptyVariant }
                    { ...emptyProps }
                >
                    {label}
                </Typography>
            );
        }
        else
        {
            label = null ;
        }

        if( icon || label )
        {
            const { classes } = this.props ;
            return (
                <div className={ classes.empty }>
                    <TransitionGroup
                        className = 'flex flex-col flex-1 justify-center items-center'
                        duration  = { 250 }
                        enter     = {{ animation: "transition.slideUpIn" }}
                    >
                        { icon }
                        { label }
                    </TransitionGroup>
                </div>
            );
        }

        return null ;
    };

    getEmptyLabel = () =>
    {
        const { empty } = this.getLocale() || {} ;
        if( notEmpty(empty) )
        {
            return empty ;
        }
        return null ;
    };

    /**
     * Indicates if the component is locked.
     * @returns {boolean}
     */
    isLocked = () =>
    {
        const { addStatus, activeStatus, orderStatus, sortStatus } = this.state ;
        return this.__lock__
            || addStatus === RequestStatus.PROGRESS
            || activeStatus === RequestStatus.PROGRESS
            || orderStatus === RequestStatus.PROGRESS
            || sortStatus === RequestStatus.PROGRESS ;
    };

    getActiveSwitch = ( thing , editable = false , options = null ) =>
    {
        const { activable } = this.props ;
        if(
            activable
            && editable
            && thing
        )
        {
            const { ActiveSwitchProps } = this.props ;

            const disabled = this.isLocked();

            const {
                active,
                id
            } = thing ;

            let button = (
            <Switch
                checked  = { active }
                disabled = { disabled }
                size     = 'small'
                onChange = { this.activeChange }
                value    = { id }
                { ...ActiveSwitchProps }
                { ...options }
            />);

            const locale = this.getLocale() ;
            if( locale && !disabled )
            {
                const { tooltips } = locale ;
                if( tooltips )
                {
                    const { activate, deactivate } = tooltips ;
                    const tooltip = active ? deactivate : activate ;
                    if( isString( tooltip ) )
                    {
                        button = (
                            <Tooltip placement='top' title={ tooltip }>
                                { button }
                            </Tooltip>
                        )
                    }
                }
            }

            return button ;
        }
        return null ;
    };

    getBadgeLabel = () =>
    {
        const { member, thing } = this.props ;
        if( thing && thing.hasOwnProperty(member) )
        {
            const children = thing[member] ;
            if( children instanceof Array )
            {
                return children.length ;
            }
        }
        return null ;
    };

    createChild = ( child , editable ) => <ThingCell editable={editable} thing={child} variant='default' /> ;

    getBottom = () => null ;

    getContent = () =>
    {
        let children ;
        const { member, thing } = this.props;
        if( thing && thing.hasOwnProperty(member) )
        {
            children = this.getItems( this.sortItems( thing[member] ) ) ;
        }

        if( children instanceof Array && children.length > 0 )
        {
            return this.renderChildren( children ) ;
        }
        else
        {
            return this.getEmpty() ;
        }
    };

    getDeleteButton = ( child, editable = false , options = null ) =>
    {
        const {
            deletable,
            DeleteButtonComponent,
            DeleteButtonIcon,
            DeleteButtonProps
        } = this.props ;

        if( editable && deletable )
        {
            const disabled = this.isLocked();
            let button = (
                <DeleteButtonComponent
                    disabled = { disabled }
                    onClick  = { () => this.openRemoveDialog(child) }
                    size     = 'small'
                    { ...options }
                    { ...DeleteButtonProps }
                >
                    { DeleteButtonIcon }
                </DeleteButtonComponent>
            );

            const locale = this.getLocale();
            if( locale && !disabled )
            {
                const { tooltips } = locale ;
                if( tooltips )
                {
                    const { remove } = tooltips ;
                    if( isString( remove ) )
                    {
                        button = (
                            <Tooltip placement='top' title={locale.tooltips.remove}>
                                { button }
                            </Tooltip>
                        )
                    }
                }
            }

            return button ;
        }

        return null ;

    };

    getEntry = init =>
    {
        let {
            clazz,
            emptyClazz,
            useUUID
        } = this.props ;

        if( emptyClazz )
        {
            clazz = emptyClazz ;
        }

        if( !clazz )
        {
            clazz = Thing ;
        }

        const id = useUUID ? generateUUID() : null ;

        let generic = { id };

        if( init instanceof Thing )
        {
            generic = { ...generic , ...init.toObject() } ;
        }
        else if( init )
        {
            generic = { ...generic , ...init } ;
        }

        const object = new clazz( generic ) ;

        object.subjectOf = this.getEntrySubjectOf() ;

        return object ;
    };

    getEntrySubjectOf = () => this.props.thing ;

    getItem = editable => ( child , index ) =>
    {
        if( this.isValid(child) )
        {
            const { id } = child ;

            const { classes, member } = this.props ;

            const options = this.getItemOptions( { child, editable , index } ) ;

            return (
                <TableRow key={ member + '_' + id } className={clsx('border-none', classes.row )}>
                    <TableCell className="px-12 py-8">
                        { this.createChild( child , editable, index ) }
                    </TableCell>
                    { options &&
                    <TableCell style={{ width:64 }}>
                        { options }
                    </TableCell>}
                </TableRow>
            );
        }
        return null ;
    };

    getItems = children =>
    {
        if( (children instanceof Array) && (children.length > 0) )
        {
            return children.map( this.getItem( this.isEditable() ) ) ;
        }
        return null ;
    };

    getItemOptions = ( { child, className , editable , force = false } = {} ) =>
    {
        const {
            activable,
            deletable,
            optionable
        } = this.props ;
        if( ( force || optionable ) && (activable || deletable) )
        {
            return (
                <div className={ clsx('flex flex-row items-center', className) } >
                    { activable && this.getActiveSwitch( child, editable ) }
                    { deletable && this.getDeleteButton( child, editable ) }
                </div>
            ) ;
        }
        return null ;
    };

    getTop = () => null ;

    isValid = child =>
    {
        if( child )
        {
            const { clazz } = this.props ;
            if( clazz instanceof Array )
            {
                let len = clazz.length ;
                for( let i = 0 ; i<len ; i++ )
                {
                    if( child instanceof clazz[i] )
                    {
                        return true ;
                    }
                }
            }
            else if( clazz instanceof Function )
            {
                return child instanceof clazz ;
            }
        }
        return false ;
    };

    onAdd = item =>
    {
        const {
            member,
            onChange,
            thing
        } = this.props ;

        if( item && thing && thing.hasOwnProperty(member))
        {
            const { position } = item ;

            let children = thing[member] ;

            if( !(children instanceof Array))
            {
                children = [] ;
            }

            if( position >= 0 && children.length > 0 )
            {
                children.splice( position , 0 , item ) ;
            }
            else
            {
                children.push( item ) ;
            }

            children = children.map( ( element , index ) =>
            {
                element.position = index ;
                return element ;
            } ) ;

            thing[member] = children ;
            thing.modified = moment(new Date()).toISOString();

            if( thing instanceof Thing )
            {
                thing.populate();
            }

            if( this._mounted )
            {
                this.forceUpdate(() =>
                {
                    if( onChange instanceof Function )
                    {
                        onChange( thing );
                    }
                }) ;
            }

        }
    };

    onEdit = item =>
    {
        const { member, onChange, thing } = this.props ;
        if( item && thing && thing.hasOwnProperty(member))
        {
            let children = thing[member] ;
            if( !(children instanceof Array))
            {
                children = [] ;
            }

            const { id } = item ;

            children = children.map( element =>
            {
                return ( element.id === id ) ? item : element ;
            });

            thing[member] = children ;
            thing.modified = moment(new Date()).toISOString();

            if( thing instanceof Thing )
            {
                thing.populate();
            }

            if( this._mounted )
            {
                this.forceUpdate() ;
            }

            if( onChange instanceof Function )
            {
                onChange(thing);
            }
        }
    };

    onRemove = id =>
    {
        const { member, thing } = this.props ;

        if( thing && thing.hasOwnProperty(member))
        {
            let children = thing[member] ;

            if( children instanceof Array )
            {
                children = children.filter( item => item.id !== id ) ;
            }
            else
            {
                children = null ;
            }

            if( children instanceof Array && children.length > 0 )
            {
                children = children.map( ( element , index ) =>
                {
                    element.position = index ;
                    return element ;
                } ) ;
            }

            thing[member]  = children ;
            thing.modified = moment(new Date()).toISOString();

            if( thing instanceof Thing )
            {
                thing.populate();
            }

            if( this._mounted )
            {
                this.forceUpdate( () =>
                {
                    const { onChange } = this.props ;
                    if( onChange instanceof Function )
                    {
                        onChange(thing);
                    }
                }) ;
            }

        }
    };

    getOrderUri = ( { from , target , to , source , ordered } ) =>
    {
        let { orderUri } = this.props;

        let uri = orderUri || this.orderUri ;

        if( uri instanceof Function )
        {
            uri = uri( { from , target , to , source , ordered , props:this.props , state:this.state } ) ;
        }

        if( !isString(uri) )
        {
            uri = this.getPath() ;
        }

        return api.url + uri ;
    };

    onOrder = null ;

    orderChange = ( { from , target , to , source , ordered } = {} ) =>
    {
        const { orderStatus } = this.state ;
        if( orderStatus !== RequestStatus.PROGRESS )
        {
            this.setState( { orderStatus:RequestStatus.PROGRESS } ) ;

            let datas ;

            let uri = this.getOrderUri( { from , target , to , source , ordered } ) ;

            let { orderPrepare } = this.props  ;

            let { order : { prepare } = {} } = this.getLocale() || {} ;

            prepare = prepare || orderPrepare || this.orderPrepare ;

            if( prepare instanceof Function )
            {
                datas = prepare
                ({
                    props : this.props ,
                    state : this.state ,
                    from,
                    ordered,
                    source,
                    target,
                    to
                }) ;
            }

            const { orderMock:mock } = this.props ;
            if( mock)
            {
                console.log( this + ' orderChange' , uri , datas ) ;
            }
            else
            {
                this.canceler = PATCH(
                    uri ,
                    {
                        datas ,
                        cancel  : this._orderCancel,
                        fail    : this._orderFail,
                        success : this._orderSuccess
                    }
                ) ;
            }
        }
    };

    orderPrepare = null ;

    orderUri = null ;

    refreshMembers = ( members , options = null ) =>
    {
        const { member , thing } = this.props ;
        if( thing && notEmpty(member) && thing.hasOwnProperty(member) )
        {
            thing[member] = members ;
            if( thing instanceof Thing )
            {
                thing.populate() ;
            }
            if( this._mounted )
            {
                const { onChange } = this.props ;
                if( options )
                {
                    this.setState({ ...options } , () =>
                    {
                        if( onChange instanceof Function )
                        {
                            onChange( thing ) ;
                        }
                    });
                }
                else
                {
                    this.forceUpdate(() =>
                    {
                        if( onChange instanceof Function )
                        {
                            onChange( thing ) ;
                        }
                    });
                }
            }
        }
    };

    renderChildren = children =>
    {
        return (
            <Table className="m-0 p-0" padding='none' size='small'>
                <TableBody className="m-0 px-0" >
                    { children }
                </TableBody>
            </Table>
        );
    };

    sortItems = children =>
    {
        if( (children instanceof Array) && (children.length > 0) )
        {
            let { sort } = this.props ;
            if( !sort )
            {
                const { lang, sortBy } = this.props ;
                switch( sortBy )
                {
                    case 'modified' :
                    {
                        sort = sortByModified() ;
                        break ;
                    }
                    case '-modified' :
                    {
                        sort = sortByModified({invert:true} ) ;
                        break ;
                    }
                    case 'name' :
                    {
                        sort = sortByName({lang}) ;
                        break ;
                    }
                    case '-name' :
                    {
                        sort = sortByName({invert:true,lang}) ;
                        break ;
                    }
                    case 'position' :
                    {
                        sort = sortByPosition() ;
                        break ;
                    }
                    case '-position' :
                    {
                        sort = sortByPosition({invert:true}) ;
                        break ;
                    }
                    default :
                    {
                        sort = this.sort ;
                    }
                }
            }

            if( sort instanceof Function )
            {
                children = [ ...children ] ;
                children.sort( sort ) ;
            }
        }

        return children ;
    };

    update = ( prevProps , prevState ) =>
    {
        const { expanded } = this.props ;
        if( prevState.expanded !== expanded )
        {
            this.setState({ expanded }) ;
        }
    };

    unmount = () =>
    {
        const {
            activeStatus ,
            orderStatus,
            sortStatus
        } = this.state ;

        if( activeStatus === RequestStatus.PROGRESS && !!(this.canceler) )
        {
            this.canceler.cancel(this + ' cancel') ;
        }

        if( orderStatus === RequestStatus.PROGRESS && !!(this.canceler) )
        {
            this.canceler.cancel(this + ' cancel') ;
        }

        if( sortStatus === RequestStatus.PROGRESS && !!(this.canceler) )
        {
            this.canceler.cancel(this + ' cancel') ;
        }
    };

    // -------- Active behaviors

    activeChange = event =>
    {
        const {
            actives,
            activeStatus
        } = this.state ;
        if( activeStatus !== RequestStatus.PROGRESS )
        {
            const { target } = event ;

            if( target )
            {
                const { activateUri } = this.props ;

                const { checked, value:id } = target ;

                actives[ String(id) ] = !!(checked) ;

                this.setState( { actives , activeStatus:RequestStatus.PROGRESS } ) ;

                let path = isString(activateUri) ? activateUri : this.getPath() ;

                this.canceler = PATCH(
                    api.url + path + '/' + id + '/active/' + ( !!(checked) ? 'true' : 'false' ) ,
                    {
                        success : this._activeSuccess(id),
                        fail    : this._activeFail(id) ,
                        cancel  : this._activeCancel(id)
                    }
                ) ;
            }
        }
    };

    _activeCancel = id => () =>
    {
        const { actives } = this.state ;
        if( actives.hasOwnProperty(id) )
        {
            delete actives[id] ;
        }
        this.setState( { actives , activeStatus : RequestStatus.FAIL } ) ;
    };

    _activeFail = id => response =>
    {
        if( response )
        {
            const { data } = response ;
            if( data )
            {
                const { message } = data ;
                switch( message )
                {
                    case 'token revoked' :
                    {
                        console.log( this + " failed, the current token is revoked, status:" + response.status + ", message:" + message );
                        break ;
                    }
                    default :
                    {
                        console.log( this + " failed, status:" + response.status + ", message:" + message );
                    }
                }
            }
        }

        if( this.mounted )
        {
            const { actives } = this.state ;
            if( actives.hasOwnProperty(String(id)) )
            {
                delete actives[String(id)] ;
            }
            this.setState( { actives : actives , activeStatus : RequestStatus.FAIL } ) ;
        }
    };

    _activeSuccess = id => response =>
    {
        if( this.mounted )
        {
            if( response )
            {
                const { data } = response;
                if( data )
                {
                    let { result } = data;
                    if( result )
                    {
                        const current = result;

                        const { actives } = this.state;

                        const { member, onChange, thing } = this.props;

                        if( thing && thing.hasOwnProperty(member))
                        {
                            let children = thing[member] ;
                            if( children instanceof Array && (children.length > 0) )
                            {
                                const child = children.find( element => String( element.id ) === String( current.id ) ) ;

                                if( child )
                                {
                                    child.active = !!( current.active );
                                }

                                if( actives.hasOwnProperty( String( id ) ) )
                                {
                                    delete actives[ String( id ) ];
                                }

                                if( this._mounted )
                                {
                                    this.forceUpdate() ;
                                }

                                // console.log( this + ' _activeSuccess' , thing[member] ) ;

                                if( onChange instanceof Function )
                                {
                                    onChange( thing ) ;
                                }
                            }
                        }
                    }
                }
            }

            this.setState( { activeStatus : RequestStatus.SUCCESS } );

            if( this.load instanceof Function )
            {
                this.load( this.state.offset );
            }
        }
    };

    // -------- Ordering behaviors

    _orderCancel = () =>
    {
        this.setState( { draggable:true, orderStatus:RequestStatus.NEW  } ) ;
        const { onCancel } = this.props ;
        if( onCancel instanceof Function )
        {
            onCancel() ;
        }
    };

    _orderFail = response =>
    {
        if( response )
        {
            const { data } = response ;
            if( data )
            {
                const { message } = data ;
                switch( message )
                {
                    case 'token revoked' :
                    {
                        console.log( this + " failed, the current token is revoked, status:" + response.status + ", message:" + message );
                        break ;
                    }
                    default :
                    {
                        console.log( this + " failed, status:" + response.status + ", message:" + data.message );
                    }
                }
            }
        }

        if( this._mounted )
        {
            this.setState( { draggable:true , orderStatus:RequestStatus.FAIL } ) ;
        }
    };

    _orderSuccess = response =>
    {
        if( response )
        {
            const { data } = response;
            if( data )
            {
                const { result } = data;
                if( result )
                {
                    this.setState({ draggable:true, orderStatus:RequestStatus.SUCCESS } ) ;
                    let { onOrder } = this.props ;
                    onOrder = onOrder || this.onOrder ;
                    if( onOrder instanceof Function )
                    {
                        onOrder( result ) ;
                    }
                    else
                    {
                        this.refreshMembers( result , { draggable:true, orderStatus:RequestStatus.SUCCESS } ) ;
                    }
                }
            }
        }
    };
}

ThingChildren.defaultProps =
{
    ...ThingContainer.defaultProps,
    activable             : false,
    activateUri           : null,
    apiUrl                : api.url,
    badge                 : true,
    clazz                 : Thing ,
    deletable             : true,
    DeleteButtonComponent : IconButton,
    DeleteButtonIcon      : <DeleteIcon/>,
    DeleteButtonProps     : null,
    emptyClassName        : null ,
    emptyClazz            : null,
    emptyIcon             : <EmptyIcon color='action' fontSize='large' />,
    emptyProps            : null,
    emptyVariant          : 'button',
    grid                  : { xs:12 },
    gridSpacing           : 2,
    member                : null,
    optionable            : true,
    orderable             : false,
    orderMock             : false,
    sort                  : null,
    sortBy                : 'default',
    sortable              : false,
    useUUID               : false
};

ThingChildren.propTypes =
{
    ...ThingContainer.propTypes,
    activable             : PropTypes.bool,
    activateUri           : PropTypes.string,
    ActiveSwitchProps     : PropTypes.object,
    apiUrl                : PropTypes.string.isRequired,
    clazz                 : PropTypes.oneOfType([PropTypes.func,PropTypes.arrayOf(PropTypes.func)]),
    deletable             : PropTypes.bool,
    DeleteButtonComponent : PropTypes.object,
    DeleteButtonIcon      : PropTypes.element,
    DeleteButtonProps     : PropTypes.object,
    emptyClassName        : PropTypes.string,
    emptyClazz            : PropTypes.oneOfType([PropTypes.func,PropTypes.arrayOf(PropTypes.func)]),
    emptyIcon             : PropTypes.element,
    emptyProps            : PropTypes.object,
    emptyVariant          : PropTypes.string,
    grid                  : PropTypes.object,
    gridSpacing           : PropTypes.oneOf([0,1,2,3,4,5,6,7,8,9,10]),
    member                : PropTypes.string,
    onOrder               : PropTypes.func,
    optionable            : PropTypes.bool,
    orderable             : PropTypes.bool,
    orderMock             : PropTypes.bool,
    orderPrepare          : PropTypes.func,
    orderUri              : PropTypes.oneOfType([PropTypes.func,PropTypes.string]),
    sort                  : PropTypes.func,
    sortBy                : PropTypes.oneOf(['default','modified','name','-modified','-name','position','-position']),
    sortable              : PropTypes.bool,
    useUUID               : PropTypes.bool
};

export default ThingChildren ;
