import UploadStatus from '../../net/UploadStatus'

class FileEntry
{
    constructor( object = null )
    {
        /**
         * The action to invoke on the file.
         * @type {Object|Function}
         */
        this.action = null ;
        
        /**
         * The data object to send during the upload process of the file.
         * @type {Object}
         */
        this.data = null ;
        
        /**
         * The file blob reference.
         * @type {Blob}
         */
        this.file = null ;
        
        /**
         * The index of the item.
         * @type {Object}
         */
        this.id = null ;
        
        /**
         * The name of the item.
         * @type {string}
         */
        this.name = null ;
    
        /**
         * The progress level of the download or the upload of the file.
         * @type {number}
         */
        this.progress = 0;
    
        /**
         * The result data object.
         * @type {Object|Array}
         */
        this.result = null ;
        
        /**
         * The status of the entry.
         * @type {number}
         */
        this.status = UploadStatus.PENDING;
        
        if( object )
        {
            for (let prop in object)
            {
                this[prop] = object[prop];
            }
        }
    }

    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        this.action   =
        this.data     =
        this.file     =
        this.id       =
        this.name     =
        this.result   = null ;
        this.progress = 0 ;
        this.status   = UploadStatus.PENDING;
        return this ;
    }
    
    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new FileEntry( this.toObject() ) ;
    }
    
    /**
     * A utility function for implementing the <code>toString()</code> method in custom classes. Overriding the <code>toString()</code> method is recommended, but not required.
     * @name formatToString
     * @memberof things
     * @instance
     * @function
     * @param {...string} [rest] - rest The properties of the class and the properties that you add in your custom class.
     * @example
     * class CustomThing extends Thing
     * {
     *     constructor( name )
     *     {
     *         this.name = name;
     *     }
     *
     *     toString()
     *     {
     *         return this.formatToString( "name" );
     *     }
     * }
     */
    formatToString( ...rest )
    {
        if( this._constructorName === null )
        {
            Object.defineProperty( this , '_constructorName' , { value : this.constructor.name } );
        }
        const path = [ this._constructorName ] ;
        const len = rest.length ;
        for( let i = 0; i < len ; ++i )
        {
            if( rest[i] in this )
            {
                path.push( rest[i] + ":" + this[rest[i]] ) ;
            }
        }
        return "[" + path.join(' ') + "]" ;
    }
    
    /**
     * Sets the object with a generic object.
     * @return {Object} The current item reference.
     */
    set = ( object ) =>
    {
        if( object )
        {
            for (let prop in object)
            {
                this[prop] = object[prop];
            }
        }
        return this ;
    };
    
    /**
     * Returns the generic object representation of the item.
     * @return {Object} The generic object representation of the item.
     */
    toObject()
    {
        let {
            action,
            data,
            file,
            id,
            name,
            progress,
            result,
            status
        } = this ;
        
        if( data )
        {
            data = { ...data };
        }
        
        return {
            action,
            data,
            file,
            id,
            name,
            progress,
            result,
            status
        }
    }
    
    toString()
    {
        return this.formatToString('name') ;
    }
}

export default FileEntry ;