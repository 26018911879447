import Form from '../../../../display/forms/FormElements'

const elements =
[
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'name',
            label        : 'Nom de du jeu',
            autoFocus    : true,
            fullWidth    : true,
            helperText   : 'Obligatoire',
            margin       : 'normal',
            required     : true,
            select       : false,
            autoComplete : 'off' ,
            type         : 'text',
            variant      : 'outlined'
        }
    }
];

export default
{
    add :
    {
        agree       : 'Créer' ,
        disagree    : 'Annuler' ,
        title       : 'Ajouter un jeu' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour créer une nouvelle fiche.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en  cours...' ,
        success     : 'Ajout du nouvel enregistrement réussi!' ,
        elements
    },
    remove :
    {
        agree        : 'Supprimer' ,
        description  : 'Souhaitez-vous supprimer le jeu suivant :' ,
        descriptionX : 'Souhaitez-vous supprimer les {0} jeux suivants :' ,
        disagree     : 'Annuler' ,
        loading      : 'Suppression en cours...' ,
        title        : 'Supprimer ce jeu ?',
        titleX       : 'Supprimer des jeux ?'
    }
};
