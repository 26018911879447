const defaultProperties =
{
    darkTheme    : {},
    currentTheme : {},
    lightTheme   : {},
    
    defaultSettings : {},
    permissions     : {},
    settings        : {},
    themes          : {},

    navbarFolded     : false ,
    navbarMobileOpen : false ,
    navigation       : {},
    
    navbarCloseFolded  : () => {},
    navbarCloseMobile  : () => {},
    navbarOpenFolded   : () => {},
    navbarOpenMobile   : () => {},
    navbarToggleFolded : () => {},
    navbarToggleMobile : () => {},

    resetNavigation  : () => {},
    resetPermissions : () => {},
    resetSettings    : () => {},

    setDefaultSettings : () => {},
    setNavigation      : () => {},
    setPermissions     : () => {},
    setSettings        : () => {}
};

export default defaultProperties;