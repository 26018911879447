import React from 'react'

import ucFirst from 'vegas-js-core/src/strings/ucFirst'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { Chip } from '@material-ui/core'

import withConfig  from '../../contexts/config/withConfig'
import withDialogs from '../../contexts/dialogs/withDialogs'
import withi18n    from '../../contexts/i18n/withi18n'

import blueGrey from '@material-ui/core/colors/blueGrey'

import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt'

import ThingContainer from '../containers/ThingContainer'

import Place   from '../../things/Place'
import ThingVO from '../../things/Thing'
import Word    from '../../things/Word'

const styles = theme => (
{
    chip :
    {
        borderColor : blueGrey[100],
        margin      : theme.spacing(0.5)
    }
});

class Permits extends ThingContainer
{
    editInit = ( item ) =>
    {
        if( item )
        {
            const { permits } = item ;
            if( permits instanceof Array )
            {
                return permits.map( element =>
                {
                    if( element instanceof Word )
                    {
                        return element.clone() ;
                    }
                    return null ;
                })
            }
        }
        return null ;
    };

    editPrepare = ( datas ) =>
    {
        if( datas instanceof Array )
        {
            datas = datas.map( item => item instanceof Word ? item.id : null ) ;
            let object = datas.join(',');
            object = { permits : object  } ;
            return object;
        }
        return null ;
    };

    getBadgeLabel = () =>
    {
        const { thing } = this.props ;
        if( thing )
        {
            const { permits } = thing  ;
            if( (permits instanceof Array) )
            {
                return permits.length ;
            }
        }
        return 0 ;
    };

    getContent = () =>
    {
        const { classes, lang, thing } = this.props ;
        if( thing )
        {
            let { permits } = thing ;

            if( permits instanceof Array && permits.length > 0 )
            {
                let css = { paddingLeft:8,marginRight:0, height:24, width:24 } ;

                permits = permits.map( ( item , index ) =>
                {
                    if( item instanceof Word )
                    {
                        const label = ucFirst(item.getLocaleName( lang ));
                        const icon  = this.getImage(item,css);
                        return (
                        <Chip
                            className = { classes.chip }
                            clickable = { false }
                            key       = { 'activity_' + index }
                            icon      = { icon }
                            label     = { label }
                            variant   = 'outlined'
                        />);
                    }
                    return null ;
                });

                return (
                    <div className="flex flex-row flex-wrap">
                        { permits }
                    </div>
                )
            }
        }
        return null ;
    };

    getEntry = init =>
    {
        let empty = {} ;
        if( init instanceof ThingVO )
        {
            empty = init.toObject() ;
        }
        else if( init )
        {
            empty = { ...init } ;
        }
        return new Place(empty) ;
    };

    getLocale = () => this.props.locale.things.permits ;
}

Permits.defaultProps =
{
    ...ThingContainer.defaultProps ,
    badge      : true ,
    icon       : <ThumbUpAltIcon/>,
    optionMode : 'edit'
};

Permits.propTypes =
{
    ...ThingContainer.propTypes
};

export default withStyles( styles ) ( withRouter( withConfig( withi18n( withDialogs( Permits ) ) ) ) ) ;
