import initDialog from '../initDialog'

import { AddDialog } from '../AddDialog'

import api from '../../../configs/api'
import Thing from '../../../things/Thing';

const styles = () => ({});

export class AddArticleDialog extends AddDialog
{
    getLocale = () => this.props.locale.dialogs.application.article.add ;

    prepare = item =>
    {
        const {
            additionalType,
            name
        } = item ;

        return (
        {
            additionalType : additionalType instanceof Thing ? String(additionalType.id) : null ,
            name
        });
    };
}

AddArticleDialog.defaultProps =
{
    ...AddDialog.defaultProps,
    uri  : api.articles.url
};

AddArticleDialog.propTypes =
{
    ...AddDialog.propTypes
};

export default initDialog({ styles })( AddArticleDialog ) ;
