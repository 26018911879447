export const ARTICLE           = 'article' ;
export const CONCEPTUAL_OBJECT = 'conceptualObject' ;
export const COURSE            = 'course' ;
export const EVENT             = 'event' ;
export const ORGANIZATION      = 'organization' ;
export const PERSON            = 'person' ;
export const PLACE             = 'place' ;
export const STAGE             = 'stage' ;
export const UNKNOWN           = 'unknown' ;

const InterestType =
{
    ARTICLE,
    CONCEPTUAL_OBJECT,
    COURSE ,
    EVENT,
    ORGANIZATION,
    PERSON,
    PLACE,
    STAGE,
    UNKNOWN
} ;

export default InterestType ;
