
import Form  from '../../../display/forms/FormElements'
import Thing from '../../../things/Thing'
import Word  from '../../../things/Word'

import api from "../../../configs/api"

const elements =
[
    {
        type : Form.PHONE_NUMBER ,
        init :
        {
            id           : 'value',
            key          : 'value',
            autoFocus    : false,
            className    : "w-full" ,
            label        : 'Numéro de téléphone',
            fullWidth    : true,
            helperText   : 'Obligatoire',
            margin       : 'normal',
            required     : true,
            select       : false,
            autoComplete : 'tel' ,
            type         : 'tel',
            variant      : 'outlined'
        }
    },
    {
        type       : Form.SEARCH_SELECTOR ,
        path       : api.thesaurus.phoneNumbers_types ,
        clearLabel : 'Vider' ,
        init       :
        {
            id         : 'additionalType',
            key        : 'additionalType',
            label      : 'Type de numéro de téléphone',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            required   : true,
            type       : 'search',
            variant    : 'outlined'
        },
        inputProps :
        {
            label : 'Sélectionner un type...'
        },
        limit  : 0,
        logics :
        {
            disabledSuggestions : props =>
            {
                const { rel } = props ;
                let suggestions = [] ;
                if( rel instanceof Thing )
                {
                    const { additionalType } = rel ;
                    if( additionalType instanceof Word )
                    {
                        suggestions.push( additionalType ) ;
                    }
                }
                return suggestions ;
            }
        }
    },
    {
        type : Form.I18N_EDITOR ,
        init :
        {
            id    : 'alternateName',
            key   : 'alternateName',
            input : true,
            label : 'Titre'
        }
    }
];

const telephone =
{
    edit :
    {
        elements,
        agree       : 'Sauver' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour modifier ce numéro.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...'  ,
        success     : 'Mise à jour réussie!' ,
        title       : 'Modifier le numéro de téléphone',
    },
    title    : 'Numéros de téléphone' ,
    tooltips :
    {
        add    : "Ajouter" ,
        edit   : "Éditer" ,
        remove : "Supprimer"
    }
};

export default telephone ;
