import {
    JPEG,
    MP3,
    MPEG,
    PDF,
    PNG,
    YOUTUBE,

    AUDIO,
    IMAGE,
    MEDIA,
    VIDEO

} from '../things/creativework/mimetypes'

/**
 * The mimetypes enumeration accepted by the application.
 */
const mimetypes =
{
    // standards

    [ PDF  ] : MEDIA,
    [ MP3  ] : AUDIO,
    [ MPEG ] : AUDIO,
    [ JPEG ] : IMAGE,
    [ PNG  ] : IMAGE ,

    // vendors

    [ YOUTUBE ] : VIDEO
};

export default mimetypes ;