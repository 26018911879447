import React from "react"

import SetTable from '../../display/tables/SetTable'

import initView from '../../display/views/initView'
import View     from '../../display/views/View'

import api from '../../configs/api'

const styles = () => ({}) ;

class Set extends View
{
    build = () =>
    (
        <SetTable
            back   = { true }
            onBack = { () => this.props.history.push( api.settings.url ) }
            limit  = { 12 }
            parent = { this.element }
        />
    );

    getLocale = () => this.props.locale.views.set ;
}

Set.defaultProps =
{
    ...View.defaultProps
};

Set.propTypes =
{
    ...View.propTypes
};

export default initView( Set , styles ) ;