import React from 'react'

import compose  from 'vegas-js-core/src/functors/compose'
import notEmpty from 'vegas-js-core/src/strings/notEmpty'

import { withRouter} from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'

import Icon from '@material-ui/icons/Settings'

import initApp from '../../../../../contexts/app/initApp'

import LabelItem     from '../../../../../components/items/LabelItem'
import ThingContainer from '../../../../containers/ThingContainer'

import identify from '../../../../../things/identify'
import Thing    from '../../../../../things/Thing'

import AmbuloSetting from '../../../../../vo/application/settings/AmbuloSetting'

const styles = () => ({});

class Info extends ThingContainer
{
    getContent = () =>
    {
        const { labels } = this.getLocale() || {} ;

        let { thing : { agenda , version } = {} } = this.props ;

        agenda  = agenda === true ? labels.yes : labels.no ;
        version = notEmpty(version) ? version : labels.empty ;

        return (
            <div className='flex-1 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4'>
                <LabelItem enabled={!!(agenda)} title={ labels.agenda }>
                    { agenda }
                </LabelItem>
                <LabelItem enabled={!!(version)} title={ labels.version }>
                    { version }
                </LabelItem>
            </div>
        );
    };

    getEntry = init =>
    {
        let empty = {} ;
        if( init instanceof Thing )
        {
            empty = init.toObject() ;
        }
        else if( init )
        {
            empty = { ...init } ;
        }
        return new AmbuloSetting(empty);
    };

    getLocale = () => this.props.locale.things.application.settings.ambulo.info;

    prepare = item =>
    {
        let {
            agenda,
            courses,
            events,
            game,
            version
        } = item ;

        courses = identify( courses ) ;
        events  = identify( events ) ;
        game    = identify( game ) ;

        const setting =
        {
            agenda,
            courses,
            events,
            game,
            version
        };

        return ({ setting : JSON.stringify( setting ) });
    };

}

Info.defaultProps =
{
    ...ThingContainer.defaultProps ,
    icon       : <Icon/> ,
    optionMode : 'edit'
};

Info.propTypes =
{
    ...ThingContainer.propTypes
};

export default compose( withStyles( styles ) , withRouter , initApp )( Info ) ;
