import api    from '../../../../configs/api'
import Form   from '../../../../display/forms/FormElements'
import Person from '../../../../things/Person'

import getExistingSuggestions from '../../../../things/getExistingSuggestions'

const elements =
[
    {
        type  : Form.SEARCH_SELECTOR ,
        path  : api.people.url ,
        clazz : Person,
        init  :
        {
            id        : 'value',
            key       : 'value',
            label     : 'Employé',
            autoFocus : false,
            fullWidth : true,
            margin    : 'normal',
            required  : true,
            type      : 'search'
        },
        disabledSuggestions : getExistingSuggestions({ member:'employees' , clazz:Person }) ,
        selector : { i18n :
        {
            empty       : 'Aucune personne disponible.' ,
            title       : 'Sélectionner une personne',
            description : 'Veuillez sélectionner une personne ci-dessous.' ,
        }}
    }
]

const employees =
{
    add :
    {
        elements,
        agree       : 'Ajouter' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez sélectionner une personne en cliquant ci-dessous.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en  cours...' ,
        success     : 'Ajout du nouvel employé réussi!' ,
        title       : 'Ajouter un employé'
    },
    remove :
    {
        agree       : 'Supprimer' ,
        description : 'Souhaitez-vous supprimer cet employé:' ,
        disagree    : 'Annuler' ,
        loading     : 'Suppression en cours...' ,
        title       : 'Supprimer l\'employé ?'
    },
    title : 'employés' ,
    tooltips :
    {
        add    : "Ajouter" ,
        edit   : "Éditer" ,
        remove : "Supprimer"
    }
};

export default employees ;