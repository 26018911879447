import React from 'react'

import compose from 'vegas-js-core/src/functors/compose'

import initApp   from '../../../contexts/app/initApp'
import withSnack from '../../../contexts/snack/withSnack'

import { withRouter } from 'react-router-dom'

import { CircularProgress } from '@material-ui/core'

import AddIcon    from '@material-ui/icons/AddPhotoAlternate'
import BrokenIcon from '@material-ui/icons/BrokenImage'
import ImageIcon  from '@material-ui/icons/Image'

import green from '@material-ui/core/colors/green'

import api from '../../../configs/api'

import ImageObject   from '../../../things/creativework/media/ImageObject'
import Media         from './Media'
import RequestStatus from '../../../net/RequestStatus'

export class Image extends Media
{
    getContent = () =>
    {
        let image ;

        const {
            image:imageStatus,
            status
        } = this.state ;

        const { thing }  = this.props ;

        if( status === RequestStatus.PROGRESS )
        {
            image = <CircularProgress color='secondary' size={26} thickness={6}/> ;
        }
        else if( thing )
        {
            if( thing.image )
            {
                const failed  = imageStatus === RequestStatus.FAIL ;
                const visible = imageStatus === RequestStatus.SUCCESS ;

                image = this.getImage(
                    thing,
                    {
                        display : visible ? null : 'none' ,
                        height  : 'auto' ,
                        width   : '100%'
                    },
                    {
                        onError : this.onImageError,
                        onLoad  : this.onImageLoad
                    }
                ) ;

                image = (
                <div className='flex items-center justify-center w-full h-full'>
                    { image }
                    { failed && <BrokenIcon className='text-grey-700' style={{ fontSize : 40 }}/> }
                    { !visible &&!failed &&
                        <CircularProgress style={{ color: green[600] }} size={43} thickness={4} />
                    }
                </div>
                );
            }
        }

        if( !image )
        {
            image = <ImageIcon className='text-grey-600' style={{ fontSize : 40 }}/> ;
        }

        return (
        <div className='flex items-center justify-center w-full min-h-160 bg-grey-200'>
            { image }
        </div>);
    };

    getLocale = () => this.props.locale.things.media.image;

    onImageError = () =>
    {
        if( this._mounted )
        {
            this.setState({ image:RequestStatus.FAIL });
        }
    };

    onImageLoad = () =>
    {
        if( this._mounted )
        {
            this.setState( { image:RequestStatus.SUCCESS } );
        }
    };
}

Image.defaultProps =
{
    ...Media.defaultProps ,
    icon             : <ImageIcon/> ,
    member           : 'image' ,
    memberClazz      : ImageObject,
    selectMediaIcon  : <AddIcon/>,
    selectMediaProps : ( { props : { config : { acceptedUploadMedias : { images:acceptedFiles } = {} } = {} } = {} } = {} ) => (
    {
        disableEscapeKeyDown : true ,
        facets               : { encodingFormat : 'image' } ,
        maxItems             : 1,
        multiple             : false,
        uploadable           : true,
        uploadMultiple       : false,
        uploadProps          : { acceptedFiles },
        uploadUri            : api.mediaObjects.url
    }),
    selectMediaUri : api.mediaObjects.url
};

Image.propTypes =
{
    ...Media.propTypes
};

export default compose( initApp, withRouter , withSnack )( Image ) ;
