import Thing from '../Thing'
import Word  from '../Word'

import generify from '../generify'

/**
 * A medical risk definition.
 * @memberOf things.medical
 * @extends Thing
 */
class MedicalRisk extends Thing
{
    /**
     * Creates a new MedicalRisk instance.
     * @constructor
     * @param object The generic object to initialize the object.
     */
    constructor( object = null )
    {
        super() ;
    
        /**
         * Indicates the list of all factors of risk.
         * @type {array}
         */
        this.factors = null ;
        
        this.set( object ) ;
    }
    
    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        super.clear() ;
        this.factors = null ;
        return this ;
    }
    
    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new MedicalRisk( this.toObject() ) ;
    }
    
    /**
     * Populate the object with specific behaviors or types.
     * Launched by default in the constructor and in the set methods.
     * @returns {Thing} The current reference.
     */
    populate()
    {
        super.populate() ;
        const {
            factors
        }
        = this ;
        
        if( factors instanceof Array )
        {
            this.factors = factors.map( item => item instanceof Word ? item : new Word(item) ) ;
        }
    }
    
    /**
     * Returns the generic object representation of the Object.
     * @return {Object} The generic object representation of the Object.
     */
    toObject( full = false )
    {
        let {
            id,
            description,
            factors
        } = this ;
        
        factors = generify(factors,true);
        
        return {
            ...( full && super.toObject() ),
            id,
            description,
            factors
        };
    }
}

export default MedicalRisk ;