import MediaObject from '../MediaObject'

/**
 * An audio file.
 * @memberOf things
 */
class AudioObject extends MediaObject
{
    /**
     * Creates a new AudioObject instance.
     * @constructor
     * @param object The generic object to initialize the object the first time.
     */
    constructor( object = null )
    {
        super() ;

        /**
         * The audio codec
         * @type {string}
         */
        this.audioCodec = null ;

        /**
         * The bits per sample of the audio
         * @type {int}
         */
        this.bitsPerSample = null ;

        /**
         * The channels of the audio
         * @type {string}
         */
        this.channels = null ;

        /**
         * The audio sample rate
         * @type {int}
         */
        this.sampleRate = null ;

        /**
         * Thumbnail image for an image or video.
         * @type {string|ImageObject}
         */
        this.thumbnail = null ;

        /**
         * If this MediaObject is an AudioObject or VideoObject, the transcript of that object.
         * @type {string}
         */
        this.transcript = null ;

        this.set(object) ;
    }

    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        super.clear() ;
        this.audioCodec    =
        this.bitsPerSample =
        this.channels      =
        this.sampleRate    =
        this.thumbnail     =
        this.transcript    = null ;
        return this ;
    }

    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new AudioObject( this.toObject() ) ;
    }

    /**
     * Returns the generic object representation of the item.
     * @return {Object} The generic object representation of the item.
     */
    toObject()
    {
        let {
            audioCodec,
            bitsPerSample,
            channels,
            sampleRate,
            thumbnail ,
            transcript
        } = this ;
        return {
            ...super.toObject() ,
            audioCodec,
            bitsPerSample,
            channels,
            sampleRate,
            thumbnail,
            transcript
        };
    }
}

export default AudioObject ;
