import answer        from './game/answer'
import answerImage   from './game/answerImage'
import answers       from './game/answers'
import badge         from './game/badge'
import question      from './game/question'
import questionImage from './game/questionImage'
import questions     from './game/questions'
import quests        from './game/quests'

import Form from '../../../display/forms/FormElements'

import Method from '../../../net/Method'

const elements =
[
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'name',
            key          : 'name',
            label        : 'Nom',
            autoFocus    : false,
            fullWidth    : true,
            helperText   : null,
            margin       : 'normal',
            required     : false,
            select       : false,
            autoComplete : 'off' ,
            type         : 'text',
            variant      : 'outlined'
        }
    }
];

const prepare = ( item ) =>
{
    let {
        name,
    } = item ;
    
    const object = (
    {
        name
    });
    return object ;
};

const game =
{
    // -------- dependencies

    answer,
    answerImage,
    answers,
    badge,
    question,
    questionImage,
    questions,
    quests,

    // -------- settings
    
    edit :
    {
        agree       : 'Sauver' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour modifier ce jeu.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...' ,
        method      : Method.PATCH ,
        success     : 'Mise à jour réussie!' ,
        title       : 'Modifier le jeu' ,
        elements,
        prepare
    },
    labels :
    {
        empty    : '----' ,
        name     : 'Nom' ,
        type     : 'Type de jeu',

    },
    title    : 'Jeu' ,
    tooltips :
    {
        add    : 'Ajouter',
        edit   : 'Modifier',
        remove : 'Supprimer'
    }
};

export default game ;