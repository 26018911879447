import table from './table'

const diseases =
{
    ...table,
    helmet :
    {
        title : 'Maladies' ,
        metas : []
    },
    empty  : 'Aucune maladie trouvée',
    remove :
    {
        agree        : 'Supprimer' ,
        description  : 'Souhaitez-vous supprimer l\'enregistrement:' ,
        descriptionX : 'Souhaitez-vous supprimer les {0} enregistrements:' ,
        disagree     : 'Annuler' ,
        loading      : 'Suppression en cours...' ,
        title        : 'Supprimer cette maladie ?',
        titleX       : 'Supprimer des maladies ?'
    },
    removeItem  : "Supprimer une maladie" ,
    removeItemX : "Supprimer {0} maladies" ,
    search      : 'Rechercher une maladie ...' ,
    title       : 'Maladies'
};

export default diseases ;
