const places =
{
    helmet :
    {
        title : 'Carte des lieux' ,
        metas : []
    },
    loading : "Chargement ..." ,
    failed  : 'Problème de connexion',
    map     :
    {
        attribution : process.env.REACT_APP_MAP_ATTRIBUTION
    },
    noDataText : 'Aucun résultat' ,
    search     : 'Rechercher un lieu ...' ,
    tooltips   :
    {
        add         : 'Ajouter' ,
        activate    : 'Activer' ,
        deactivate  : 'Désactiver' ,
        remove      : 'Supprimer' ,
        removeItem  : "Supprimer un lieu" ,
        removeItemX : "Supprimer {0} lieux"
    },
    title : 'Carte des lieux'
};

export default places ;
