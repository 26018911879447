import React from 'react'

import clsx from 'clsx'

import fastformat from 'vegas-js-core/src/strings/fastformat'

import { withStyles } from '@material-ui/core/styles'

import ApplicationQuest from '../../../display/things/game/quests/ApplicationQuest'
import CourseQuest      from '../../../display/things/game/quests/CourseQuest'
import QuestionQuest    from '../../../display/things/game/quests/QuestionQuest'

import ThingSubContainer from '../../../display/containers/ThingSubContainer'

const styles = () => (
{
    root :
    {
        padding : 16 ,
        width   : '100%'
    }
});

class Quest extends ThingSubContainer
{
    changeQuest = this.changeProperty('quest' , true ) ;

    render = () =>
    {
        const {
            classes,
            className,
            path,
            style,
            thing
        } = this.props ;

        let Component ;
        let content;

        if( thing )
        {
            const { additionalType } = thing ;
            if( additionalType )
            {
                const { alternateName } = additionalType ;
                switch( alternateName )
                {
                    case 'application' :
                    {
                        Component = ApplicationQuest ;
                        break ;
                    }

                    case 'course' :
                    {
                        Component = CourseQuest ;
                        break ;
                    }

                    case 'question' :
                    {
                        Component = QuestionQuest ;
                        break ;
                    }

                    default :
                    {
                        content = null ;
                    }
                }
            }
        }

        if( Component )
        {
            content = (
                <Component
                    addUri    = { fastformat( path.quest , thing.id ) }
                    onChange  = { this.changeQuest }
                    path      = { path }
                    thing     = { thing }
                />
            );
        }

        return (
        <div
            className = { clsx(classes.root, className) }
            style     = { style }
        >
            { content }
        </div>) ;
    }
}

Quest.defaultProps =
{
    ...ThingSubContainer.defaultProps
};

Quest.propTypes =
{
    ...ThingSubContainer.propTypes
};

export default withStyles( styles )( Quest );
