import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'

import { withRouter } from "react-router-dom"

import sanitizeHtml from 'sanitize-html'

import isString from 'vegas-js-core/src/isString'
import trim     from 'vegas-js-core/src/strings/trim'

import { Typography } from '@material-ui/core'

import ToggleButton      from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'

import grey from '@material-ui/core/colors/grey'
import red  from '@material-ui/core/colors/red'

import {
    convertToRaw,
    ContentState,
    EditorState
} from 'draft-js'

import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'

import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import RoleableContainer from '../containers/RoleableContainer'

import withConfig from '../../contexts/config/withConfig'
import withi18n   from '../../contexts/i18n/withi18n'

const styles = theme => (
{
    editor :
    {
        backgroundColor : grey[100],
        borderWidth     : 0.5,
        borderColor     : grey[300],
        borderRadius    : 8,
        padding         : 8,
        paddingLeft     : 16,
        paddingRight    : 16,
        minHeight       : 240 ,
        marginTop       : 0
    },
    editorChanged :
    {
        backgroundColor : grey[100],
        borderWidth     : 0.5,
        borderColor     : red[300],
        borderRadius    : 8,
        padding         : 8,
        minHeight       : 240 ,
        marginTop       : 0
    },
    input :
    {
        backgroundColor : grey[100],
        borderWidth     : 0.5,
        borderColor     : grey[300],
        borderRadius    : 8,
        display         : 'inline-block',
        paddingLeft     : 8,
        paddingRight    : 8,
        minHeight       : 'auto' ,
        width           : '100%' ,
        marginTop       : 0 ,
        whiteSpace      : 'nowrap'
    },
    inputChanged :
    {
        backgroundColor : grey[100],
        borderWidth     : 0.5,
        borderColor     : red[300],
        borderRadius    : 8,
        display         : 'inline-block',
        paddingLeft     : 8,
        paddingRight    : 8,
        minHeight       : 'auto' ,
        width           : '100%' ,
        marginTop       : 0 ,
        whiteSpace      : 'nowrap'
    },
    wrapper :
    {
        marginTop : 2
    },
    root:
    {
        flexGrow     : 1,
        marginBottom : 8 ,
        marginTop    : 20 ,
        width        : '100%' ,
        height       : 'auto'
    },
    tab :
    {
        alignItems     : 'center',
        border         : 0,
        justifyContent : 'flex-start',
        marginRight    : 10
    },
    tabs :
    {
        alignItems     : 'center',
        background     : theme.palette.background.light,
        boxShadow      : 'none',
        display        : 'flex',
        height         : 56 ,
        justifyContent : 'flex-start',
        //marginTop      : `${theme.spacing(1)}px`,
        //padding        : `${theme.spacing(1)}px ${theme.spacing(2)}px`
    }
});

class MultiLangInput extends RoleableContainer
{
    constructor( props )
    {
        super( props ) ;
        this.change   = false ;
        this.editor   = null ;
        this.interval = 0 ;
    }

    getLocale = () => this.props.locale.components.forms.multilang ;

    state =
    {
        editorState : EditorState.createEmpty() ,
        errors      : null ,
        lang        : 'fr' ,
        item        : {} ,
        value       : null
    };

    getContent = () =>
    {
        const {
            classes,
            disabled,
            editorStyle,
            html,
            input,
            lang:currentLang,
            toolbar,
            toolbarOnFocus,

        } = this.props;

        const { editorState, lang } = this.state ;

        const changed  = this.isChanged(lang) ;
        const editable = this.isEditable() ;

        let css ;
        if( changed )
        {
            css = input ? classes.inputChanged : classes.editorChanged ;
        }
        else
        {
            css = input ? classes.input : classes.editor ;
        }

        return (
            <Fragment>
                { this.getTabs() }
                <Editor
                    editorClassName     = { css }
                    editorState         = { editorState }
                    editorStyle         = { editorStyle }
                    onEditorStateChange = { this.changeEditor(lang) }
                    handlePastedText    = { this.paste }
                    handleReturn        = { () => input }
                    localization        = {{ locale : currentLang }} // en, fr, zh, ru, pt, ko, it, nl, de, da, zh_tw, pl, es
                    readOnly            = { disabled }
                    ref                 = { ref => this.editor = ref }
                    stripPastedStyles   = { html === false }
                    toolbar             = { toolbar }
                    toolbarHidden       = { !(editable && html) }
                    toolbarOnFocus      = { toolbarOnFocus }
                    wrapperClassName    = { classes.wrapper }
                />
            </Fragment>
        ) ;
    };

    getTabs = () =>
    {
        const locale = this.getLocale() ;
        if( locale )
        {
            let { languages } = locale ;
            if( languages instanceof Array && languages.length > 0 )
            {
                const { lang } = this.state ;
                const { classes } = this.props;

                languages = languages.map( ( element , index ) =>
                (
                    <ToggleButton
                        key       = { 'tab_'+index }
                        className = { classes.tab }
                        value     = { element.id }
                    >
                        { element.icon }
                    </ToggleButton>
                ));

                return(
                <ToggleButtonGroup
                    className  = { classes.tabs }
                    value      = { lang }
                    exclusive  = { true }
                    onChange   = { this.changeLang }
                >
                    { languages }
                </ToggleButtonGroup>
                );
            }
        }
        return null ;
    };

    isChanged( lang )
    {
        const { value } = this.state ;
        const { item } = this.state ;

        if( value && item )
        {
            if( value[lang] !== item[lang] )
            {
                return true ;
            }
        }

        return false ;
    }

    render = () =>
    {
        const { classes, label } = this.props;
        const locale = this.getLocale() ;

        let text ;
        if( isString(label) && label !== '')
        {
            text = label ;
        }
        else if( locale )
        {
            const { title } = locale ;
            if( isString(title) && title !== '' )
            {
                text = title ;
            }
        }

        return (
        <div className={classes.root}>
            { text &&
            <div className="flex">
                <Typography className="text-grey-800" variant="body2">{text}</Typography>
            </div> }
            { this.getContent() }
        </div>
        );
    };

    // ------- Protected

    changeEditor = lang => editorState =>
    {
        const { config : { languages } = {} } = this.props ;
        if( languages instanceof Array && languages.indexOf(lang) > -1 )
        {
            const { html, input, onChangeContent } = this.props ;

            let text ;
            if( html )
            {
                text = draftToHtml( convertToRaw( editorState.getCurrentContent() ) ) ;
                text = this.sanitize(text);
            }
            else
            {
                text = editorState.getCurrentContent().getPlainText();
                if( input )
                {
                    text = text.replace(/(\r\n\t|\n|\r\t)/gm,"").trim() ;
                }
            }

            const { item } = this.state ;
            if( item )
            {
                item[lang] = text ;
            }

            this.setState({ editorState });

            if( onChangeContent instanceof Function )
            {
                onChangeContent( item ) ;
            }
        }
    };

    changeLang = ( event , value ) =>
    {
        const { lang } = this.state ;
        if( !value || (lang === value) )
        {
            return ;
        }
        this.setLang( value ) ;
    };

    componentDidMount()
    {
        this.refresh() ;
    }

    componentDidUpdate = () =>
    {
        clearTimeout( this.interval ) ;
    };

    paste = ( text , html ) =>
    {
        if( isString(html) )
        {
            return this.sanitize( html ) ;
        }
        else if ( isString(text ) )
        {
            return this.sanitize( text ) ;
        }
    };

    refresh = () =>
    {
        const { value } = this.props ;
        const { lang } = this.state ;
        if( value !== this.state.value )
        {
            this.setLang( lang , value ) ;
        }
    };

    sanitize = ( text ) =>
    {
        const {
            config,
            html,
            input
        } = this.props ;

        if( isString(text) )
        {
            text = trim(text) ;
            if( html )
            {
                text = sanitizeHtml(text, config.sanitizeOptions ) ;
                if( text === '<p></p>')
                {
                    text = '' ;
                }
            }
            else
            {
                text = sanitizeHtml(text, config.sanitizeAll ) ;
            }
        }

        if( input )
        {
            text = text.replace(/(\r\n\t|\n|\r\t)/gm,"").trim() ;
            text = text.replace(/[^\x20-\x7E]/gmi,'');
        }

        return text ;
    };

    setLang = ( lang , value = null ) =>
    {
        let { item } = this.state ;

        if( value )
        {
            item = {} ;

            const { config : { languages } = {} } = this.props ;
            if( languages instanceof Array )
            {
                languages.forEach( lang =>
                {
                    if( value.hasOwnProperty(lang) && isString(value[lang]) )
                    {
                        item[lang] = value[lang] ;
                    }
                    else
                    {
                        item[lang] = null ;
                    }
                });
            }
        }

        if( item.hasOwnProperty(lang) )
        {
            if( isString(item[lang]) )
            {
                const { autoFocus, html } = this.props ;

                let contentState ;

                if( html )
                {
                    const draft = htmlToDraft( item[lang] ) ;
                    const { contentBlocks, entityMap } = draft ;
                    contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
                }
                else
                {
                    contentState = ContentState.createFromText( item[lang] , '\u000A' );
                }

                if( this.editor && autoFocus )
                {
                    this.editor.focusEditor();
                }

                this.setState( { item, lang, editorState:EditorState.createWithContent(contentState)} ) ;
                return ;
            }
        }

        this.setState( { item, lang, editorState:null , value } ) ;
    };
}

MultiLangInput.defaultProps =
{
    ...RoleableContainer.defaultProps ,
    autoFocus        : false,
    defaultLang      : 'fr' ,
    disabled         : false,
    editorStyle      : null ,
    html             : false ,
    input            : false ,
    label            : null,
    locale           : null ,
    onCancel         : null ,
    onChange         : null ,
    onChangeContent  : null ,
    toolbar          :
    {
        inline:
        {
            inDropdown : false,
            options    : // 'monospace', 'superscript', 'subscript'
            [
                'bold',
                'italic',
                'underline',
                'strikethrough'
            ]
        },
        options : // 'fontFamily', 'fontSize', 'list', 'textAlign', 'colorPicker', 'embedded', 'emoji', 'image'
        [
            'history',
            'inline',
            'blockType',
            'link',
            'remove'
        ]
    },
    toolbarOnFocus : false,
    uri            : null ,
    value          : null
};

MultiLangInput.propTypes =
{
    ...RoleableContainer.propTypes ,
    autoFocus        : PropTypes.bool,
    classes          : PropTypes.object.isRequired ,
    defaultLang      : PropTypes.string,
    disabled         : PropTypes.bool,
    editorStyle      : PropTypes.object,
    html             : PropTypes.bool,
    input            : PropTypes.bool,
    label            : PropTypes.string,
    locale           : PropTypes.object,
    onCancel         : PropTypes.func,
    onChange         : PropTypes.func,
    onChangeContent  : PropTypes.func,
    toolbar          : PropTypes.object,
    toolbarOnFocus   : PropTypes.bool,
    uri              : PropTypes.string,
    value            : PropTypes.object
};

export default withStyles( styles )( withRouter( withConfig( withi18n(MultiLangInput) ) ) ) ;
