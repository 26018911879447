import React from 'react'

import withDatasList from './withDatasList'

import CourseIcon from '@material-ui/icons/Map'

import ThingsList from './ThingsList'

import AddCourseDialog    from '../dialogs/add/AddCourseDialog'
import RemoveCourseDialog from '../dialogs/remove/RemoveCourseDialog'

import Course          from '../../things/Course'
import CourseCell      from '../cells/CourseCell'

class CoursesList extends ThingsList
{
    getLocale = () => this.props.locale.display.lists.stages ;
}

CoursesList.defaultProps =
{
    ...ThingsList.defaultProps ,
    AddDialogComponent    : AddCourseDialog,
    CellComponent         : CourseCell,
    cellProps             : { variant : 'default' },
    clazz                 : Course,
    icon                  : <CourseIcon />,
    preferable            : true ,
    RemoveDialogComponent : RemoveCourseDialog,
    sortable              : true
};

CoursesList.propTypes =
{
    ...ThingsList.propTypes
};

export default withDatasList()( CoursesList ) ;
