import bars       from './bars'
import buttons    from './buttons'
import cards      from './cards'
import cells      from './cells'
import containers from './containers'
import counters   from './counters'
import grids      from './grids'
import headers    from './headers'
import labels     from './labels'
import lists      from './lists'
import tables     from './tables'

const display =
{
    bars,
    buttons,
    cards,
    cells,
    containers,
    counters,
    grids,
    headers,
    labels,
    lists,
    tables
};

export default display ;
