import isString from 'vegas-js-core/src/isString'

/**
 * Validate a file with a serie of mimetypes expressions.
 * More informations about it: https://developer.mozilla.org/en-US/docs/HTML/Element/input#attr-accept.
 * @param {File} file - A file to validate.
 * @param {Array|string} acceptedFiles - A string or array of string expressions.
 * @returns {boolean} True if the file is valid.
 */
const isValidFile = ( file , acceptedFiles ) =>
{
    if( !file )
    {
        return false ;
    }

    if( isString( acceptedFiles ) )
    {
        acceptedFiles = acceptedFiles.split(",") ;
    }
    
    if ( !(acceptedFiles instanceof Array) )
    {
        return true ;
    }
    
    let mimeType     = file.type;
    let baseMimeType = mimeType.replace(/\/.*$/, "");

    for( let validType of acceptedFiles )
    {
        //console.log( 'isValidFile' , validType , '>>>>' , mimeType );
        
        validType = validType.trim() ;
        
        if ( validType.charAt(0) === "." )
        {
            const { name } = file ;
            if ( isString(name) && name.toLowerCase().indexOf(validType.toLowerCase(), name.length - validType.length) !== -1)
            {
                return true;
            }
        }
        else if (/\/\*$/.test(validType))
        {
            if (baseMimeType === validType.replace(/\/.*$/, "")) // This is something like a image/* mime type
            {
                return true;
            }
        }
        else if ( mimeType === validType )
        {
            return true;
        }
    }

    return false;
};

export default isValidFile ;
