import activities                from './activities'
import address                   from './address'
import application               from './application'
import article                   from './article'
import audios                    from './audios'
import conceptualObject          from './conceptualObject'
import conceptualObjects         from './conceptualObjects'
import course                    from './course'
import dayOfWeek                 from './dayOfWeek'
import days                      from './days'
import discover                  from './discover'
import email                     from './email'
import event                     from './event'
import events                    from './events'
import game                      from './game'
import geo                       from './geo'
import hourSpecification         from './hourSpecification'
import label                     from './label'
import livestock                 from './livestock'
import livestocks                from './livestocks'
import media                     from './media'
import medias                    from './media'
import medical                   from './medical'
import medicalSpecialties        from './medicalSpecialties'
import metadatas                 from './metadatas'
import numbers                   from './numbers'
import offer                     from './offer'
import offers                    from './offers'
import observation               from './observation'
import openingHoursSpecification from './openingHoursSpecification'
import organization              from './organization'
import organizations             from './organizations'
import people                    from './people'
import permits                   from './permits'
import person                    from './person'
import photos                    from './photos'
import place                     from './place'
import places                    from './places'
import profile                   from './profile'
import prohibitions              from './prohibitions'
import services                  from './services'
import sessions                  from './sessions'
import stage                     from './stage'
import stages                    from './stages'
import step                      from './step'
import team                      from './team'
import technician                from './technician'
import technicians               from './technicians'
import telephone                 from './telephone'
import text                      from './text'
import veterinarian              from './veterinarian'
import veterinarians             from './veterinarians'
import video                     from './video'
import videos                    from './videos'
import websites                  from './websites'
import workshop                  from './workshop'

const things =
{
    activities,
    address,
    application,
    article,
    audios,
    conceptualObject,
    conceptualObjects,
    course,
    dayOfWeek,
    days,
    discover,
    email,
    event,
    events,
    game,
    geo,
    hourSpecification,
    label,
    livestock,
    livestocks,
    media,
    medias,
    medical,
    medicalSpecialties,
    metadatas,
    numbers,
    observation,
    offer,
    offers,
    openingHoursSpecification,
    organization,
    organizations,
    person,
    people,
    permits,
    photos,
    place,
    places,
    profile,
    prohibitions,
    services,
    sessions,
    stage,
    stages,
    step,
    team,
    technician,
    technicians,
    telephone,
    text,
    veterinarian,
    veterinarians,
    video,
    videos,
    websites,
    workshop,
};

export default things ;
