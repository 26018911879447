import React from 'react'

import clsx from 'clsx'

import PropTypes from 'prop-types'

import isString from 'vegas-js-core/src/isString'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import { Chip } from '@material-ui/core'

import blueGrey from '@material-ui/core/colors/blueGrey'

import ListAltIcon from '@material-ui/icons/ListAlt'

import ThingContainer from './ThingContainer'

import Thing from '../../things/Thing'
import Word  from '../../things/Word'

import sortThing from '../../things/sortThing'

export const styles =
{
    chip :
    {
        borderColor : blueGrey[400],
        margin      : 8
    }
};

class ChipsContainer extends ThingContainer
{
    getBadgeLabel = () =>
    {
        let elements = this.getMembers() ;
        if( elements instanceof Array )
        {
            return elements.length ;
        }
        return 0 ;
    };

    getContent = () =>
    {
        const {
            classes,
            lang,
            member,
            sortable
        } = this.props ;

        let elements = this.getMembers() ;

        //console.log( this + ' getContent' , elements ) ;

        if( elements instanceof Array && elements.length > 0 )
        {
            let css = { paddingLeft:8,marginRight:0, height:24, width:24 } ;

            const {
                clazz,
                chipClassName,
                chipProps,
                chipVariant
            }
            = this.props ;

            if( sortable )
            {
                elements = this.sort( elements ) ;
            }

            elements = elements.map( ( item , index ) =>
            {
                if( (item instanceof Thing) && (item instanceof clazz) )
                {
                    let label ;

                    if( item instanceof Word )
                    {
                        label = item.getLocaleName( lang ) ;
                    }
                    else
                    {
                        label = item.getLocaleAlternateName( lang ) ;
                        if( !isString(label) || (label === '') )
                        {
                            label = item.getLocaleName( lang ) ;
                        }
                    }

                    if( isString(label) && (label !== '') )
                    {
                        label = ucFirst(label);
                    }
                    else
                    {
                        label = null ;
                    }

                    const icon  = this.getImage(item,css);
                    return (
                    <Chip
                        className = { clsx(classes.chip, chipClassName) }
                        clickable = { false }
                        key       = { member + '_' + index }
                        icon      = { icon }
                        label     = { label }
                        variant   = { chipVariant }
                        { ...chipProps }
                    />);
                }
                return null ;
            });

            return (
            <div className="flex flex-row flex-wrap">
                { elements }
            </div>);
        }

        return null ;
    };

    getMembers = () =>
    {
        const { member } = this.props ;
        const thing = this.getThing() ;
        if( thing && isString(member) && thing.hasOwnProperty(member) && (thing[member] instanceof Array) )
        {
            return thing[member] ;
        }
        return null ;
    };

    getThing = () => this.props.thing ;

    sort = ( elements ) =>
    {
        const { lang } = this.props ;
        if( elements instanceof Array && lang )
        {
            elements = [ ...elements ] ;
            elements.sort( sortThing(lang) ) ;
        }
        return elements ;
    };
}

ChipsContainer.defaultProps =
{
    ...ThingContainer.defaultProps ,
    badge         : true ,
    clazz         : Word,
    chipClassName : null ,
    chipProps     : null ,
    chipVariant   : 'outlined' ,
    icon          : <ListAltIcon/>,
    member        : null,
    optionMode    : 'edit' ,
    sortable      : true
};

ChipsContainer.propTypes =
{
    ...ThingContainer.propTypes,
    clazz         : PropTypes.func,
    chipClassName : PropTypes.string ,
    chipProps     : PropTypes.object ,
    chipVariant   : PropTypes.oneOf(['outlined','default']) ,
    member        : PropTypes.string.isRequired,
    sortable      : PropTypes.bool
};

export default ChipsContainer ;
