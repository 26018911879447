import generify from './generify'

import Thing from './Thing'
import Word  from './Word'

/**
 * This class comprises symbols, signs, signatures or short texts applied to instances of Physical Man-Made Thing by arbitrary techniques in order to indicate the creator, owner, dedications, purpose, etc.
 * @memberOf things
 */
class Mark extends Thing
{
    /**
     * Creates a new Mark instance.
     * @constructor
     * @param object The generic object to initialize the object the first time.
     */
    constructor( object = null )
    {
        super() ;

        /**
         * The language of this thing.
         * @type {object}
         */
        this.language = null ;

        /**
         * The position description of this thing.
         * @type {string}
         */
        this.position = null ;

        /**
         * The technique of this thing.
         * @type {object}
         */
        this.technique = null ;

        this.set( object ) ;
    }

    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        super.clear() ;
        this.language  =
        this.position  =
        this.technique = null ;
        return this ;
    }

    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new Mark( this.toObject() ) ;
    }

    /**
     * Returns the locale position description of the thing.
     * @return {String} The locale position description of the thing.
     */
    getLocalePosition( lang = 'en' )
    {
        const { position } = this ;
        if( position instanceof String || typeof(position) === 'string' )
        {
            return position ;
        }
        else if ( position && position.hasOwnProperty(lang) )
        {
            return position[lang];
        }
        else
        {
            return null ;
        }
    }

    /**
     * Populate the object with specific behaviors or types. Launched by default in the constructor and in the set methods.
     * @returns {Thing} The current reference.
     */
    populate()
    {
        const {
            additionalType,
            language,
            technique
        } = this;

        if( additionalType && !(additionalType instanceof Word ))
        {
            this.additionalType = new Word(additionalType) ;
        }

        if( language )
        {
            this.language = new Word( language ) ;
        }

        if( technique )
        {
            this.technique = new Word( technique ) ;
        }

        return this ;
    }

    /**
     * Returns the generic object representation of the item.
     * @return {Object} The generic object representation of the item.
     */
    toObject()
    {
        let {
            language,
            position,
            technique
        } = this ;
        
        language  = generify(language) ;
        technique = generify(technique) ;
        
        return {
            ...super.toObject() ,
            language,
            position,
            technique
        };
    }
}

export default Mark ;