const removeImage =
{
    remove :
    {
        agree        : 'Supprimer' ,
        description  : 'Souhaitez-vous supprimer l\'image:' ,
        descriptionX : 'Souhaitez-vous supprimer les images:' ,
        disagree     : 'Fermer' ,
        loading      : 'Suppression en cours...' ,
        mock         : false,
        title        : 'Supprimer cette image ?',
        titleX       : 'Supprimer ces images ?',
        useID        : false
    },
    tooltip : 'Supprimer l\'image'
};

export default removeImage ;
