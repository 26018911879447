import table from './table'

const sessions =
{
    ...table,
    helmet :
    {
        title : 'Sessions' ,
        metas : []
    },
    empty    :
    {
        active   : 'Aucune session active' ,
        inactive : 'Aucune session inactive'
    },
    active   : 'Sessions actives' ,
    inactive : 'Sessions Inactives' ,
    revokeAll : 'Déconnecter toutes les sessions'
};

export default sessions ;
