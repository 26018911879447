import isBoolean from 'vegas-js-core/src/isBoolean'

import Course from '../../../things/Course'
import Event  from '../../../things/Event'
import Game   from '../../../things/Game'

import Setting  from './Setting'

import generify from '../../../things/generify'

class AmbuloSetting extends Setting
{
    /**
     * Creates a new AmbuloSetting instance.
     * @constructor
     * @param object The generic object to initialize the object the first time.
     */
    constructor( object = null )
    {
        super() ;

        /**
         * The flag to indicates if the agenda is visible or not.
         * @type {boolean}
         */
        this.agenda = true ;

        /**
         * The array of the focused courses.
         * @type {array}
         */
        this.courses = null ;

        /**
         * The array of the focused events.
         * @type {array}
         */
        this.events = null ;

        /**
         * The main game of the application.
         * @type {boolean}
         */
        this.game = null ;

        /**
         * The version information in the setting of the application.
         * @type {boolean}
         */
        this.version = null ;

        this.set( object ) ;
    }

    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        super.clear() ;
        this.agenda  =
        this.courses =
        this.event   =
        this.game    =
        this.version = null ;
        return this ;
    }

    /**
     * Returns a shallow copy of the object.
     * @return {AmbuloSetting} a shallow copy of the object.
     */
    clone()
    {
        return new AmbuloSetting( this.toObject() ) ;
    }

    /**
     * Populate the object with specific behaviors or types. Launched by default in the constructor and in the set methods.
     * @returns {AmbuloSetting} The current reference.
     */
    populate()
    {
        const {
            agenda,
            courses,
            events,
            game
        } = this ;

        if( !isBoolean(agenda) )
        {
            this.agenda = true ;
        }

        if( courses )
        {
            if( courses instanceof Array && courses.length > 0 )
            {
                this.courses = courses.map( item =>
                {
                    if( !(item instanceof Course) )
                    {
                        item = new Course(item) ;
                        item.subjectOf = this ;
                    }
                    return item ;
                } ) ;
            }
            else
            {
                this.courses = new Course( courses ) ;
                this.courses.subjectOf = this ;
            }
        }

        if( events && events.length > 0 )
        {
            if( events instanceof Array )
            {
                this.events = events.map( item =>
                {
                    if( !(item instanceof Event) )
                    {
                        item = new Event(item) ;
                        item.subjectOf = this ;
                    }
                    return item ;
                } ) ;
            }
            else
            {
                this.events = new Event( events ) ;
                this.events.subjectOf = this ;
            }
        }

        if( game )
        {
            this.game = new Game( game ) ;
            this.game.subjectOf = this ;
        }

        return this ;
    }

    /**
     * Returns the generic object representation of the item.
     * @return {Object} The generic object representation of the item.
     */
    toObject()
    {
        let {
            agenda,
            courses,
            events,
            game,
            version
        } = this ;

        courses = generify( courses , true );
        events  = generify( events  , true );
        game    = generify( game );

        return {
            ...super.toObject() ,
            agenda,
            courses,
            events,
            game,
            version
        };
    }
}

export default AmbuloSetting ;
