import React from 'react'

import format from 'vegas-js-core/src/strings/format'

import Method from "../../../../../../../net/Method"

import event from '../../../events/sectorHealthEvent'

import api from '../../../../../../../configs/api'

import AbortionEventCell from '../../../../../../../display/things/livestock/reviews/abortionSerie/events/AbortionEvent'
import Form              from '../../../../../../../display/forms/FormElements'
import NumberInput       from '../../../../../../../components/inputs/NumberInput'
import Thing             from '../../../../../../../things/Thing'

const elements =
[
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'identifier',
            key          : 'identifier',
            label        : 'Identifiant animal',
            autoFocus    : true,
            fullWidth    : true,
            InputProps   : {
                inputComponent : ( input ) => (
                    <NumberInput
                        allowEmptyFormatting = { true }
                        allowNegative        = { false }
                        format               = 'FR##########'
                        mask                 = '#'
                        useFormatted         = { true }
                        {...input}
                    />
                )
            } ,
            margin       : 'normal',
            select       : false,
            autoComplete : 'off' ,
            type         : 'text',
            variant      : 'outlined'
        }
    },
    {
        type         : Form.DATE ,
        defaultValue : null,
        format       : 'L' ,
        init :
        {
            id            : 'date',
            key           : 'date',
            autoOk        : true ,
            cancelLabel   : 'Annuler' ,
            clearable     : true ,
            clearLabel    : 'Vider' ,
            invalidLabel  : '-',
            disableFuture : false ,
            disablePast   : false ,
            label         : 'Date de l\'événement' ,
            fullWidth     : true ,
            okLabel       : 'OK',
            variant       : 'outlined'
        }
    },
    {
        type       : Form.SEARCH_SELECTOR ,
        clearLabel : 'Vider' ,
        closeLabel : 'Fermer' ,
        init       :
        {
            id         : 'period',
            key        : 'period',
            label      : 'Période de gestation',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            type       : 'search',
            variant    : 'outlined'
        },
        path : ( props ) =>
        {
            const { rel } = props ;
            if( rel instanceof Thing )
            {
                const { subjectOf:review } = rel ;
                if( review )
                {
                    const { subjectOf:observation } = review ;
                    if( observation )
                    {
                        const { additionalType } = observation ;
                        if( additionalType )
                        {
                            const { id } = additionalType ;
                            if( id )
                            {
                                return format(api.settings.ageGroupsByObservationsTypes, id);
                            }
                        }
                    }
                }
            }
            return null;
        },
        iconifiable : false ,
        searchable  : false,
        title       : 'Période de gestation'
    }
];

const reproductiveDisorderEvents =
{
    add :
    {
        agree       : 'Créer' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour créer un problème de reproduction.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...' ,
        method      : Method.PATCH,
        mock        : false,
        success     : 'Ajout réussi!' ,
        title       : 'Ajouter un problème de reproduction',
        elements
    },
    edit :
    {
        agree       : 'Sauver' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour modifier ce problème de reproduction.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...'  ,
        method      : Method.PATCH,
        mock        : false ,
        success     : 'Mise à jour réussie!' ,
        title       : 'Modifier ce problème de reproduction',
        elements
    },
    event,
    labels :
    {
        add     : 'Ajouter un nouvel individu',
        card    : 'Événement',
        caption : '6 minimum : comprend les vaches avortées depuis plus de 8 jours, les vaches à problème de reproduction (non délivrance, métrite, retour en chaleur), et les individus du même lot'
                + ' ne présentant pas de troubles de la reproduction.'
    },
    remove :
    {
        agree       : 'Supprimer' ,
        description : 'Souhaitez-vous supprimer cet événement ?' ,
        disagree    : 'Annuler' ,
        iconifiable : false ,
        item        : {
            render : ( item ) => (
                <AbortionEventCell
                    gridProps     = {{ sm : 6 , xs : 12 }}
                    periodVisible = { false }
                    thing         = { item }
                />)
            },
        loading : 'Suppression en cours...' ,
        method  : Method.PATCH ,
        mock    : false ,
        title   : 'Supprimer l\'événement',
        useID   : false
    },
    rows :
    {
        empty       : '?' ,
        numAttendee : 'Effectif' ,
        capacity    : 'Capacité'
    },
    title     : 'Autres individus du lot' ,
    tooltips  :
    {
        add    : "Ajouter" ,
        edit   : "Éditer" ,
        remove : "Supprimer"
    }
};

export default reproductiveDisorderEvents ;