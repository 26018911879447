import React, { Component} from 'react'

import PropTypes  from 'prop-types'

import format from 'vegas-js-core/src/strings/fastformat'

import { Grid } from '@material-ui/core'

import TransitionGroup from '../../../../transitions/TransitionGroup'

import withi18n from '../../../../contexts/i18n/withi18n'

import Address                from '../../../../display/things/Address'
import Email                  from '../../../../display/things/Email'
import MedicalSpecialties     from '../../../../display/things/MedicalSpecialties'
import Metadatas              from '../../../../display/things/Metadatas'
import RemoveTechnicianDialog from '../../../../display/dialogs/remove/RemoveTechnicianDialog'
import Telephone              from '../../../../display/things/Telephone'
import Technician             from '../../../../display/things/Technician'
import Websites               from '../../../../display/things/Websites'

import paths from '../../../../configs/paths'

import PostalAddress from '../../../../things/PostalAddress'

const half =
{
    md :  6 ,
    sm : 12 ,
    xs : 12
};

class About extends Component
{
    render()
    {
        const { thing } = this.props ;
        if( thing )
        {
            const { path } = this.props ;

            const {
                person,
                id
            } = thing ;

            let address ;

            if( person )
            {
                const { address:addr } = person ;
                if( addr instanceof PostalAddress )
                {
                    address = addr ;
                }
            }

            const { onRemove } = this.props ;

            return (
                <div className='w-full h-full p-16'>
                <TransitionGroup duration={250} enter={{animation: "transition.slideUpIn"}}>

                    <Metadatas
                        activable             = { true }
                        deletable             = { true }
                        onRemove              = { onRemove }
                        RemoveDialogComponent = { RemoveTechnicianDialog }
                        thing                 = { thing }
                        uri                   = { format(path.technician,id) }
                    />

                    <Grid container spacing={4}>
                        <Grid item {...half}>
                            <Technician
                                thing = { thing }
                            />
                            <MedicalSpecialties
                                onChange = { this.change }
                                thing    = { thing }
                                uri      = { path.medicalSpecialties }
                            />
                        </Grid>
                        <Grid item {...half}>
                            <Address
                                thing={ address }
                                optionMode='none'
                            />
                        </Grid>
                        <Grid item {...half}>
                            <Email
                                editable   = { false }
                                thing      = { person }
                                optionMode = 'none'
                            />
                            <Websites
                                editable   = { false }
                                thing      = { person }
                                optionMode = 'none'
                            />
                        </Grid>
                        <Grid item {...half}>
                            <Telephone
                                editable   = { false }
                                thing      = { person }
                                optionMode = 'none'
                            />

                        </Grid>
                    </Grid>
                </TransitionGroup>
                </div>
            );
        }
        return null ;
    }
}

About.defaultProps =
{
    locale   : null,
    onChange : null ,
    path     : null,
    thing    : null
};


About.propTypes =
{
    locale   : PropTypes.object,
    onChange : PropTypes.func,
    path     : paths.organizations,
    thing    : PropTypes.object.isRequired
};

export default withi18n(About) ;
