export default
{
    description : 'Veuillez sélectionner un événement ci-dessous.' ,
    disagree    : 'Fermer' ,
    empty       : 'Aucun événement disponible.' ,
    fail        : 'Impossible de charger les événements, veuillez relancer un peu plus tard.',
    loading     : 'Chargement en cours...' ,
    search      : 'Rechercher un événement ...' ,
    title       : 'Sélectionner un événement'
};
