import isString from 'vegas-js-core/src/isString'

import Thing from '../../things/Thing'

const readUser = user =>
{
    if( user )
    {
        const { storageID } = user ;
        if( isString( storageID ) )
        {
            let object = sessionStorage.getItem( storageID )  ;
            if( isString( object ) )
            {
                object = JSON.parse( object ) ;
                for( let prop in object )
                {
                    if( object.hasOwnProperty(prop) && user.hasOwnProperty(prop) )
                    {
                        user[prop] = object[prop] ;
                    }
                }
                
                if( user instanceof Thing )
                {
                    user.populate() ;
                }
            }
        }
        else
        {
            console.log( this + ' readUser failed, the user storage identifier not must be null.' );
        }
    }
    else
    {
        console.log( this + ' readUser failed, the user not must be null.' );
    }

    // console.log( 'readUser' , user ) ;

    return user ;
};

export default readUser ;