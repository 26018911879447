import React from 'react'

import compose from 'vegas-js-core/src/functors/compose'
import isInt   from 'vegas-js-core/src/isInt'

import initApp from '../../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { Grid } from '@material-ui/core'

import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory'

import LabelItem from '../../../components/items/LabelItem'

import ThingContainer from '../../containers/ThingContainer'

import ElevationVO from '../../../things/Elevation'
import ThingVO     from '../../../things/Thing'

const styles = () => ({});

const half =
{
    md :  6 ,
    sm :  6 ,
    xs : 12
};

class Elevation extends ThingContainer
{
    getContent = () =>
    {
        const locale = this.getLocale() ;

        const { labels } = locale ;

        let gain ;
        let loss ;
        let maxValue ;
        let minValue ;

        let { thing } = this.props;

        if( thing )
        {
            const { elevation } = thing ;

            if( elevation instanceof ElevationVO )
            {
                gain     = isInt( elevation.gain )     ? elevation.gain     : null ;
                loss     = isInt( elevation.loss )     ? elevation.loss     : null ;
                maxValue = isInt( elevation.maxValue ) ? elevation.maxValue : null ;
                minValue = isInt( elevation.minValue ) ? elevation.minValue : null ;
            }
        }

        return (
            <Grid container spacing={4}>
                <Grid item {...half}>
                    <LabelItem enabled={!!(minValue)} title={ labels.minValue } >
                        { isInt(minValue) ? minValue : labels.empty }
                    </LabelItem>
                    <LabelItem enabled={!!(gain)} title={ labels.gain } >
                        { isInt(gain) ? gain : labels.empty }
                    </LabelItem>
                </Grid>
                <Grid item {...half}>
                    <LabelItem enabled={!!(maxValue)} title={ labels.maxValue } >
                        { isInt(maxValue) ? maxValue : labels.empty }
                    </LabelItem>
                    <LabelItem enabled={!!(loss)} title={ labels.loss } >
                        { isInt(loss) ? loss : labels.empty }
                    </LabelItem>
                </Grid>
            </Grid>
        );
    };

    getEntry = init =>
    {
        let empty = {} ;
        if( init )
        {
            const { elevation } = init ;
            if( elevation instanceof ThingVO )
            {
                empty = elevation.toObject() ;
            }
            else if( elevation )
            {
                empty = { ...elevation } ;
            }
        }
        empty.subjectOf = init ;
        return new ElevationVO(empty);
    };

    getLocale = () => this.props.locale.things.course.elevation;

    onEdit = ( item ) =>
    {
        const { onChange, thing } = this.props ;
        if( thing && item )
        {
            const { elevation } = item ;

            thing.elevation = elevation ;

            if( thing instanceof ThingVO )
            {
                thing.populate();
            }

            if( this._mounted )
            {
                this.forceUpdate() ;
            }

            if( onChange instanceof Function )
            {
                onChange( thing ) ;
            }
        }
    };
}

Elevation.defaultProps =
{
    ...ThingContainer.defaultProps ,
    icon       : <ChangeHistoryIcon/> ,
    optionMode : 'edit'
};

Elevation.propTypes =
{
    ...ThingContainer.propTypes
};

export default compose( withStyles( styles ) , withRouter , initApp )( Elevation ) ;
