import React , { Component } from 'react'

import DialogsContext from './DialogsContext'

import defaultProperties from './defaultProperties'

class DialogsProvider extends Component
{
    constructor( props )
    {
        super( props );
        this.state =
        {
            ...defaultProperties,
            addDialog    : this.add ,
            clearDialog  : this.clear ,
            numDialogs   : this.length ,
            removeDialog : this.remove ,
            dialogs      : []
        };
    }

    add = ( Component , options = null ) =>
    {
        const { callback , ...settings } = options ;
        if( Component )
        {
            const { dialogs } = this.state ;
            const position = dialogs.length ;
            // console.log( this + ' add position' , position ) ;
            dialogs.push( { Component , position , settings } ) ;
            this.setState({ dialogs } , callback ) ;
            return position ;
        }
        return null ;
    };

    clear = ( callback = null ) =>
    {
        const { dialogs } = this.state ;
        if( dialogs.length > 0 )
        {
            dialogs.length = 0 ;
            this.setState({ dialogs } , callback ) ;
        }
        else if( callback instanceof Function )
        {
            callback() ;
        }
    };

    componentDidMount()
    {
        window.addEventListener('popstate', this.popState );
    }

    componentWillUnmount()
    {
        window.removeEventListener('popstate', this.popState );
    }

    length = () => this.state.dialogs.length ;

    render()
    {
        const { children } = this.props ;
        let { dialogs }  = this.state;
        if( dialogs.length > 0 )
        {
            dialogs = dialogs.map( item =>
            {
                const { Component, position, settings } = item ;
                const component = (
                    <Component
                        { ...settings }
                        key      = { 'dialog-item-' + position }
                        open     = { true }
                        position = { position }
                    />
                ) ;
                return component  ;
            });
        }
        else
        {
            dialogs = null ;
        }

        return (
            <DialogsContext.Provider value={ this.state }>
                { children }
                { dialogs  }
            </DialogsContext.Provider>
        )
    }

    popState = () =>
    {
        const { dialogs } = this.state ;
        if( dialogs.length > 0 )
        {
            this.clear() ;
        }
    };

    remove = ( position , callback = null ) =>
    {
        const { dialogs } = this.state ;
        if( dialogs instanceof Array && dialogs.length > 0 )
        {
            // console.log( this + ' remove position' , position ) ;
            let element = dialogs.splice(position, 1) ;
            if( element )
            {
                this.setState({ dialogs } , callback ) ;
            }
        }
    };
}

export default DialogsProvider ;
