import list from './list'

const articles =
{
    ...list,
    helmet :
    {
        title : 'Articles' ,
        metas : []
    },
    empty  : 'Aucun article trouvé',
    search : 'Rechercher un article ...' ,
    title  : 'Articles'
};

export default articles ;
