import React from 'react'

import PropTypes from 'prop-types'

import compose  from 'vegas-js-core/src/functors/compose'
import isString from 'vegas-js-core/src/isString'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import initApp from '../../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { Grid, Tooltip, CircularProgress } from '@material-ui/core'

import BadgeIcon       from '@material-ui/icons/VerifiedUser'
import BrokenIcon      from '@material-ui/icons/BrokenImage'
import ColorIcon       from '@material-ui/icons/Image'
import EmptyColorIcon  from '@material-ui/icons/Texture'
import ImageIcon       from '@material-ui/icons/Image'

import green from '@material-ui/core/colors/green'
import grey  from '@material-ui/core/colors/grey'

import LabelItem from '../../../components/items/LabelItem'

import ThingContainer from '../../containers/ThingContainer'

import BadgeItem from '../../../things/game/BadgeItem'
import Thing     from '../../../things/Thing'
import Word      from '../../../things/Word'

import RequestStatus from '../../../net/RequestStatus'

const styles = () => ({});

const half =
{
    sm : 6,
    xs : 12
};

const grid_1_3 =
{
    xs : 12
};

const grid_2_3 =
{
    xs : 12
};

class Badge extends ThingContainer
{
    constructor( props )
    {
        super( props ) ;
        this.state =
        {
            ...this.state,
            image  : RequestStatus.NEW,
            status : RequestStatus.NEW
        }
    }

    editPrepare = item =>
    {
        let {
            additionalType,
            alternateName,
            bgcolor,
            color,
            description,
            name,
        } = item ;

        const { config : { languages } = {} } = this.props ;

        if( alternateName )
        {
            languages.forEach( lang =>
            {
                if( !isString(alternateName[lang]) )
                {
                    alternateName[lang] = '' ;
                }
            })
        }

        if( description )
        {
            languages.forEach( lang =>
            {
                if( !isString(description[lang]) )
                {
                    description[lang] = '' ;
                }
            })
        }

        if( !isString(bgcolor) || bgcolor === '')
        {
            bgcolor = '' ;
        }
        else
        {
            bgcolor = bgcolor.toLowerCase();
        }

        if( !isString(color) || color === '')
        {
            color = '' ;
        }
        else
        {
            color = color.toLowerCase();
        }



        const object = (
        {
            additionalType : additionalType instanceof Thing
                ? additionalType.id
                : null ,
            alternateName ,
            bgcolor,
            color,
            description ,
            name
        });
        return object ;
    };

    getBadge = ( item , style = null , defaultWidth = null, options = null ) =>
    {
        if ( item instanceof Thing )
        {
            const {
                additionalType,
                bgcolor,
                color
            } = item ;

            if( additionalType )
            {
                const { config } = this.props ;
                if( defaultWidth === null )
                {
                    defaultWidth = config.icon.maxWidth ;
                }

                const {
                    bgcolor : bg,
                    color   : c,
                    image
                } = additionalType ;

                if( image )
                {
                    let src ;

                    if( isString(image) )
                    {
                        src = image ;
                    }

                    let params = '';

                    if( isString(bgcolor) )
                    {
                        params += '&bgcolor=' + bgcolor ;
                    }
                    else if( isString(bg) )
                    {
                        params += '&bgcolor=' + bg ;
                    }

                    if( isString(color) )
                    {
                        params += '&color=' + color ;
                    }
                    else if( isString(c) )
                    {
                        params += '&color=' + c ;
                    }

                    if( isString(src) )
                    {
                        const { lang } = this.props ;
                        return (
                        <img
                            alt   = { item.getLocaleName(lang) }
                            src   = { src + "?w=" + defaultWidth + '&h=' + defaultWidth + params }
                            style = { style }
                            { ...options }
                        />
                        );
                    }
                }
            }

        }
        return null ;
    };

    getContent = () =>
    {
        let image ;

        const {
            image:imageStatus,
            status
        } = this.state ;

        let { lang, thing } = this.props;

        const locale = this.getLocale() ;

        const { labels } = locale ;

        let c;
        let cIcon ;
        let bgc;
        let bgcIcon;
        let bgStyle;
        let name ;
        let type ;

        if( thing instanceof BadgeItem )
        {
            const { emptyColorIcon } = this.props ;
            let {
                additionalType,
                bgcolor,
                color,
                name : n
            } = thing ;

            if( isString( n ) )
            {
                name = n ;
            }

            if( additionalType instanceof Word )
            {
                type = additionalType.getLocaleName( lang ) ;
            }

            if( isString(bgcolor) && (bgcolor !== '') )
            {
                bgc     = "#" + bgcolor ;
                bgcIcon = (<Tooltip placement='top' title={ bgc }>
                    <ColorIcon className='mr-8' style={{ color:bgc }}/>
                </Tooltip>);

                bgStyle = { backgroundColor : bgc } ;
            }
            else
            {
                bgc     = null ;
                bgcIcon = emptyColorIcon ;
            }

            if( isString(color) && (color !== '') )
            {
                c     = "#" + color ;
                cIcon = (<Tooltip placement='top' title={ c }>
                    <ColorIcon className='mr-8' style={{ color : c }}/>
                </Tooltip>);
            }
            else
            {
                c     = null ;
                cIcon = emptyColorIcon ;
            }

            if( status === RequestStatus.PROGRESS )
            {
                image = <CircularProgress color='secondary' size={26} thickness={6}/> ;
            }
            else if( additionalType )
            {
                if( additionalType.image )
                {
                    const failed  = imageStatus === RequestStatus.FAIL ;
                    const visible = imageStatus === RequestStatus.SUCCESS ;

                    image = this.getBadge(
                        thing,
                        {
                            display : visible ? null : 'none' ,
                            height  : '100%' ,
                            width   : 'auto'
                        },
                        128,
                        {
                            onError : this.onImageError,
                            onLoad  : this.onImageLoad
                        }
                    ) ;

                    image = (
                    <div className='flex items-center justify-center w-full h-full'>
                        { image }
                        { failed && <BrokenIcon className='text-grey-700' style={{ fontSize : 40 }}/> }
                        { !visible &&!failed && <CircularProgress style={{ color: green[600] }} size={43} thickness={4} /> }
                    </div>);
                }
            }
        }

        if( !image )
        {
            image = <ImageIcon className='text-grey' style={{ fontSize : 40 }}/> ;
        }

        return (
            <Grid container spacing={4} className='p-8'>

                <Grid
                    className = 'flex items-center justify-center w-full min-h-160 bg-grey-200 rounded-lg mb-8'
                    item      = { true }
                    style     = { bgStyle }
                    {...grid_1_3}
                >
                    { image }
                </Grid>

                <Grid item {...grid_2_3} >
                    <Grid container spacing={4}>
                        <Grid item {...half}>
                            <div className='px-8'>
                                <LabelItem enabled={!!(name)} title={ labels.name }>
                                    { isString(name) ? ucFirst(name) : labels.empty }
                                </LabelItem>
                            </div>
                        </Grid>
                        <Grid item {...half}>
                            <div className='px-8'>
                                <LabelItem enabled={!!(type)} title={ labels.type }>
                                    { isString(type) ? ucFirst(type) : labels.empty }
                                </LabelItem>
                            </div>
                        </Grid>
                        <Grid item {...half}>
                            <div className='px-8'>
                                <LabelItem
                                    enabled   = {!!(c)}
                                    labelIcon = { cIcon }
                                    title     = { labels.color }
                                >
                                    { isString(c) ? c : labels.empty }
                                </LabelItem>
                            </div>
                        </Grid>
                        <Grid item {...half}>
                            <div className='px-8'>
                                <LabelItem
                                    enabled   = { !!(bgc) }
                                    labelIcon = { bgcIcon }
                                    title     = { labels.bgcolor }
                                >
                                    { isString(bgc) ? bgc : labels.empty }
                                </LabelItem>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        );
    };

    getEntry = init =>
    {
        let empty = {} ;
        if( init instanceof Thing )
        {
            empty = init.toObject() ;
        }
        else if( init )
        {
            empty = { ...init } ;
        }
        return new BadgeItem(empty);
    };

    getLocale = () => this.props.locale.things.game.badge;

    onImageError = () =>
    {
        this.setState({ image:RequestStatus.FAIL });
    };

    onImageLoad = () =>
    {
        this.setState({ image:RequestStatus.SUCCESS });
    };
}

Badge.defaultProps =
{
    ...ThingContainer.defaultProps ,
    emptyColorIcon : <EmptyColorIcon className='mr-8' style={{ color : grey[400] }} /> ,
    icon           : <BadgeIcon/> ,
    optionMode     : 'edit'
};

Badge.propTypes =
{
    ...ThingContainer.propTypes,
    emptyColorIcon : PropTypes.element
};

export default compose( withStyles( styles ) , withRouter , initApp )( Badge ) ;
