const defaultProperties =
{
    numSelected     : () => {} ,
    selectAll       : () => {} ,
    selectRemove    : null ,
    selectReset     : () => {} ,
    setSelect       : () => {} ,
    setSelectRemove : () => {} ,
    toggleSelected  : () => {} ,
    unselectAll     : () => {} ,
    
    selectableItems : [],
    selectable      : false,
    selectLocale    : null,
    selectedItems   : []
};

export default defaultProperties;