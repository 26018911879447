
import isString from 'vegas-js-core/src/isString'

import ImageObject from './media/ImageObject'

/**
 * Populates the image attribute of the specific thing reference.
 * @param {*} thing - The thing reference to populate.
 * @returns {*} The thing reference.
 */
const populateImage = thing =>
{
    if( thing )
    {
        const { image } = thing ;
        if( image && !isString(image) && !( image instanceof ImageObject) )
        {
            thing.image = new ImageObject(image);
        }
    }
    return thing ;
};

export default populateImage ;