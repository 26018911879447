const abortionSerieDeclaration =
{
    aborted             : 'Individu(s) avorté(s)',
    authority           : 'Vétérinaire',
    alternativeHeadline : 'Document d’accompagnement de prélèvements',
    datePattern         : 'L',
    empty               : '---' ,
    event               :
    {
        analysis   :
        {
            disease  : 'Maladie' ,
            method   : 'Méthodes d\'analyse',
            sampling : 'Matrices prélevées'
        },
        date       : 'Date de l\'avortement ' ,
        identifier : 'Identifiant individu ',
        period     : 'Stade de gestation ',
        title      : 'Événement d\'avortement'
    },
    
    headline   : 'Déclaration d\'avortements en série de ruminants',
    
    livestock  : 'Propriétaire',
    notes      : 'Penser à coller les étiquettes sur les prélèvements effectués.',
    order      : 'N° ordre : ' ,
    production : 'Production : ',
    
    reproductiveDisorders : 'Individu(s) du lot touché(s) par les avortements' ,
    reproductiveCaption   : 'Comprend les vaches avortées (6 minimum) depuis plus de 8 jours, les vaches à problème de reproduction (non délivrance, métrite, retour en chaleur), et les individus du'
                          + ' même lot ne présentant pas de troubles de la reproduction.' ,
    review                :
    {
        date          : 'Date : ' ,
        distance      : 'Distance parcourue : ' ,
        distanceUnity : 'Km' ,
        title         : 'Visite et prélèvements'
    },
    separator    : ' - ',
    sign         : 'Nom et signature du vétérinaire :',
    veterinarian : 'Vétérinaire',
    workshop     : 'Atelier'
};

export default abortionSerieDeclaration ;