import React from 'react'

import format from 'vegas-js-core/src/strings/fastformat'
import notEmpty from 'vegas-js-core/src/strings/notEmpty'

import LocalHospitalIcon from '@material-ui/icons/LocalHospital'

import ThingView , { defaultStyle } from '../../display/views/ThingView'

import getLocaleThingLabel from '../../things/getLocaleThingLabel'

import About      from './view/About'
import Livestocks from '../../display/things/Livestocks'

import Livestock  from '../../things/Livestock'
import Thing      from '../../things/Thing'
import Veterinary from '../../things/Veterinarian'

import api   from '../../configs/api'
import paths from '../../configs/paths'

import initView from '../../display/views/initView'

const styles = defaultStyle();

class Veterinarian extends ThingView
{
    getHeaderThing = () =>
    {
        const { thing } = this.state ;
        if( thing )
        {
            const { authority } = thing ;
            if( authority instanceof Thing )
            {
                return authority ;
            }
        }
    }

    getLocale = () => this.props.locale.views.veterinarian ;

    getRoutePath = path =>
    {
        const { thing } = this.state ;
        if( thing instanceof Thing )
        {
            return format( path , thing.id ) ;
        }
        return path ;
    }

    getSubtitle = thing =>
    {
        if( thing )
        {
            const { identifier } = thing ;
            if( notEmpty(identifier) )
            {
                const locale = this.getLocale() ;
                if( locale )
                {
                    let { subtitle } = locale ;
                    if( notEmpty( subtitle ) )
                    {
                        return format( subtitle , identifier ) ;
                    }
                }
                return identifier ;
            }
        }
        return null ;
    };

    getTitle = thing =>
    {
        if( thing instanceof Thing )
        {
            const { authority } = thing ;
            if( authority )
            {
                return getLocaleThingLabel( authority , this.props.lang ) ;
            }
        }
        return null ;
    };

    onRemoveLivestock = ( { item } = {} ) =>
    {
        const { thing } = this.state ;
        if( thing instanceof Veterinary )
        {
            if( item instanceof Livestock )
            {
                let { livestocks } = thing ;
                if( livestocks instanceof Array && livestocks.length > 0 )
                {
                    thing.livestocks = livestocks.filter( element => element.url !== item.url ) ;
                    this.change( thing ) ;
                }
            }
        }
    }
}

Veterinarian.defaultProps =
{
    ...ThingView.defaultProps,
    back       : true ,
    clazz      : Veterinary,
    icon       : <LocalHospitalIcon/>,
    path       : api.veterinarians,
    preferable : true ,
    responsive : true ,
    routes     :
    [
        {
            id             : 'about' ,
            RouteComponent : About
        },
        {
            id             : 'livestocks' ,
            routeClazz     : Livestock ,
            RouteComponent : Livestocks ,
            uri            : api.veterinarians.livestocks ,
            onRemove       : 'onRemoveLivestock'
        }
    ],
    tabs :
    [
        { id : 'about'      },
        { id : 'livestocks' }
    ]
};

Veterinarian.propTypes =
{
    ...ThingView.propTypes,
    path : paths.veterinarians
};

export default initView( Veterinarian, styles );
