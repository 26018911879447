import React, {Component} from 'react'

import ucFirst from 'vegas-js-core/src/strings/ucFirst'

import PropTypes  from 'prop-types'

import clsx from 'clsx'

import { withStyles } from '@material-ui/core/styles'

import {
    Avatar,
    CircularProgress,
    Divider,
    IconButton,
    Typography,
    Tooltip
} from '@material-ui/core'

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'

import red from '@material-ui/core/colors/red'

import Badge from '../badges/Badge'

const styles = () =>
({
    badge :
    {
        fontSize : '13px'
    },
    progress:
    {
        color : red[500] ,
    },
    root :
    {
        padding: '8px 0px'
    }
});

class SimpleProgressHeader extends Component
{
    render()
    {
        let {
            back,
            badgeColor,
            backTooltip,
            classes,
            className,
            icon,
            iconButton,
            iconClick,
            iconTooltip,
            loading,
            onBack,
            options,
            style,
            subtitle,
            title,
            total
        } = this.props;

        let backButton ;
        if( back )
        {
            backButton = (
            <IconButton
                className = 'mr-8'
                onClick   = { onBack }
                size      = 'small'
            >
                <ChevronLeftIcon fontSize='small'/>
            </IconButton>
            ) ;
            if( backTooltip )
            {
                backButton = (
                    <Tooltip placement='right' title={backTooltip}>
                        {backButton}
                    </Tooltip>
                );
            }
        }

        let left = null ;

        if( loading )
        {
            left = (
                <Avatar variant="rounded" className="bg-white text-gray-800 shadow">
                    <CircularProgress className={classes.progress} size={28} thickness={7} />
                </Avatar>
            );
        }
        else if ( icon )
        {
            left = iconButton ? <IconButton onClick={iconClick}>{icon}</IconButton> : icon ;

            left = (
                <Avatar variant="rounded" className="bg-white text-gray-800 shadow">{ left }</Avatar>
            );

            if( iconTooltip )
            {
                left = (
                    <Tooltip placement='bottom' title={iconTooltip}>
                        {left}
                    </Tooltip>
                );
            }
        }

        let badge ;
        if( !loading && ( total > 0 ) )
        {
            badge = (
            <div className='ml-16'>
                <Badge classes={{ label:classes.badge }} color={badgeColor}>{ total }</Badge>
            </div>
            );
        }

        let option ;
        if( options )
        {
            option = <div>{ options }</div> ;
        }

        if ( subtitle )
        {
            subtitle = <Typography variant='caption'>{ucFirst(subtitle)}</Typography> ;
        }
        else
        {
            subtitle = null ;
        }

        if( title )
        {
            title = <Typography variant='subtitle1' className="font-medium">{ ucFirst(title) }</Typography> ;
        }
        else
        {
            title = null ;
        }

        return (
        <div className={clsx(classes.root,className)} style={style}>
            <div className="flex flex-row items-center justify-between">
                <div className="flex flex-row items-center">
                { backButton }
                { left }
                <div className="flex flex-row ml-20 items-center">
                    <div className="flex flex-col">
                        <div className="flex flex-row pr-20 items-center">
                            { title }
                            { badge }
                        </div>
                        { subtitle }
                    </div>
                </div>
                </div>
                { option }
            </div>
            <Divider light className="my-20 mb-8"/>
        </div>
        );
    }
}

SimpleProgressHeader.defaultProps =
{
    back        : false ,
    backTooltip : null ,
    badgeColor  : 'success' ,
    className   : null,
    icon        : null ,
    iconButton  : false ,
    iconClick   : null ,
    iconTooltip : null ,
    loading     : false,
    onBack      : null ,
    options     : null ,
    style       : null ,
    subtitle    : null ,
    title       : null ,
    total       : 0
};

SimpleProgressHeader.propTypes =
{
    badgeColor : PropTypes.oneOf([
        "primary",
        "warning",
        "danger",
        "success",
        "info",
        "rose",
        "gray"
    ]),
    back        : PropTypes.bool,
    backTooltip : PropTypes.string ,
    className   : PropTypes.string,
    icon        : PropTypes.element,
    iconButton  : PropTypes.bool,
    iconClick   : PropTypes.func ,
    iconTooltip : PropTypes.string ,
    loading     : PropTypes.bool,
    classes     : PropTypes.object.isRequired,
    onBack      : PropTypes.func,
    options     : PropTypes.element,
    style       : PropTypes.object,
    subtitle    : PropTypes.string,
    title       : PropTypes.string,
    total       : PropTypes.number
};

export default withStyles( styles ) ( SimpleProgressHeader ) ;
