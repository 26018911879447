import React from 'react'

import CourseIcon from '@material-ui/icons/Map'

import withDatasGrid from './withDatasGrid'
import { ThingsGrid } from './ThingsGrid'

import Course     from '../../things/Course'
import CourseCell from '../cells/CourseCell'

import AddCourseDialog    from '../dialogs/add/AddCourseDialog'
import RemoveCourseDialog from '../dialogs/remove/RemoveCourseDialog'

class CoursesGrid extends ThingsGrid
{
    getLocale = () => this.props.locale.display.grids.courses ;
}

CoursesGrid.defaultProps =
{
    ...ThingsGrid.defaultProps,
    AddDialogComponent    : AddCourseDialog,
    CellComponent         : CourseCell ,
    clazz                 : Course,
    icon                  : <CourseIcon />,
    mediaIcon             : <CourseIcon fontSize='large' />,
    mediaProps            : { className : 'bg-hero-topography-2' },
    preferable            : true ,
    RemoveDialogComponent : RemoveCourseDialog
};

CoursesGrid.propTypes =
{
    ...ThingsGrid.propTypes
};

export default withDatasGrid()( CoursesGrid ) ;
