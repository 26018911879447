import React from 'react'

import PeopleIcon from '@material-ui/icons/People'

import withDatasList from './withDatasList'
import ThingsList from './ThingsList'

import Person     from '../../things/Person'
import PersonCell from '../cells/PersonCell'

import AddPersonDialog    from '../dialogs/add/AddPersonDialog'
import RemovePersonDialog from '../dialogs/remove/RemovePersonDialog'

class PeopleList extends ThingsList
{
    getLocale = () => this.props.locale.display.lists.people ;
}

PeopleList.defaultProps =
{
    ...ThingsList.defaultProps,
    AddDialogComponent    : AddPersonDialog,
    CellComponent         : PersonCell ,
    cellProps             : { variant : 'default' },
    clazz                 : Person,
    icon                  : <PeopleIcon />,
    preferable            : true ,
    queries               : { sort : '-modified' },
    RemoveDialogComponent : RemovePersonDialog
};

PeopleList.propTypes =
{
    ...ThingsList.propTypes
};

export default withDatasList()( PeopleList ) ;
