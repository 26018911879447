import React, { Component } from 'react'

import PropTypes from 'prop-types'

import isString from 'vegas-js-core/src/isString'

import withLocale from '../../contexts/i18n/withLocale'

import { Fab, Tooltip } from '@material-ui/core'

import CloudUploadIcon from '@material-ui/icons/CloudUpload'

import Animate from '../../transitions/Animate'

class UploadButton extends Component
{
    getLocale = () => this.props.locale.components.buttons.upload ;

    render = () =>
    {
        const { visible } = this.props;
        if( !visible )
        {
            return null ;
        }

        let {
            className,
            color,
            onClick,
            disabled,
            placement,
            size,
            style,
            tip
        } = this.props;

        let { icon } = this.props ;
        if( !icon )
        {
            icon = <CloudUploadIcon/> ;
        }

        let button = (
        <Fab
            color    = { color }
            onClick  = { onClick }
            disabled = { disabled }
            size     = { size }
        >
            { icon }
        </Fab>);

        if( !disabled )
        {
            if( !isString(tip) )
            {
                const locale = this.getLocale() ;
                const { tooltip } = locale ;
                if( tooltip )
                {
                    tip = tooltip ;
                }
            }
            if( isString(tip) )
            {
               button = (
                <Tooltip placement={placement} title={tip}>
                    { button }
                </Tooltip>);
            }
        }

        button = (
        <div
            className = { className }
            style     = { style }
        >
            {button}
        </div>) ;

        const { animate } = this.props;
        if (animate)
        {
            const { animateDelay } = this.props;
            return (
                <Animate enter="transition.expandIn" delay={animateDelay}>
                    {button}
                </Animate>
            );
        }
        else
        {
            return button;
        }
    };
}

UploadButton.defaultProps =
{
    animate      : false,
    animateDelay : 1000,
    className    : null  ,
    color        : 'primary' ,
    disabled     : false ,
    icon         : <CloudUploadIcon/>,
    locale       : null,
    onClick      : null,
    placement    : 'left',
    size         : 'large',
    style        : null,
    tip          : null,
    visible      : true
};

UploadButton.propTypes =
{
    animate      : PropTypes.bool,
    animateDelay : PropTypes.number,
    className    : PropTypes.string,
    color        : PropTypes.oneOf(['default','inherit','primary','secondary']),
    disabled     : PropTypes.bool,
    icon         : PropTypes.element,
    locale       : PropTypes.object,
    onClick      : PropTypes.func,
    placement    : PropTypes.oneOf(['bottom-end', 'bottom-start', 'bottom', 'left-end', 'left-start', 'left', 'right-end', 'right-start', 'right', 'top-end', 'top-start', 'top']),
    size         : PropTypes.oneOf(['small','medium','large']),
    style        : PropTypes.object,
    tip          : PropTypes.string,
    visible      : PropTypes.bool
};

export default withLocale(UploadButton) ;
