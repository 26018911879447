import generify from './generify'

import Place from './Place'
import Thing from './Thing'

/**
 * The mailing address.
 * @memberOf things
 */
class PostalAddress extends Thing
{
    /**
     * Creates a new PostalAddress instance.
     * @constructor
     * @param object The generic object to initialize the object.
     */
    constructor( object = null )
    {
        super() ;

        /**
         * The additional address information : house name, building number, neighborhood, etc.
         * @type {string}
         */
        this.additionalAddress = null ;

        /**
         * The country. For example, USA. You can also provide the two-letter ISO 3166-1 alpha-2 country code.
         * @type {Place|string}
         */
        this.addressCountry = null ;

        /**
         * The department.
         * @type {string}
         */
        this.addressDepartment = null ;

        /**
         * The locality. For example, Mountain View.
         * @type {string}
         */
        this.addressLocality = null ;

        /**
         * The region. For example, CA.
         * @type {string}
         */
        this.addressRegion = null ;

        /**
         * The postal code. For example, 94043.
         * @type {string}
         */
        this.postOfficeBoxNumber = null ;

        /**
         * The post office box number for PO box addresses.
         * @type {string}
         */
        this.postalCode = null ;

        /**
         * The street address. For example, 1600 Amphitheatre Pkwy.
         * @type {string}
         */
        this.streetAddress = null ;

        this.set( object ) ;
    }


    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        super.clear() ;
        this.additionalAddress   =
        this.addressCountry      =
        this.addressDepartment   =
        this.addressLocality     =
        this.addressRegion       =
        this.postOfficeBoxNumber =
        this.postalCode          =
        this.streetAddress       = null ;
        return this ;
    }

    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new PostalAddress( this.toObject() ) ;
    }

    /**
     * Returns the generic object representation of the item.
     * @return {Object} The generic object representation of the item.
     */
    toObject()
    {
        let {
            additionalAddress,
            addressCountry,
            addressDepartment,
            addressLocality,
            addressRegion,
            postOfficeBoxNumber,
            postalCode,
            streetAddress
        } = this ;

        addressCountry = generify(addressCountry) ;

        return {
            ...super.toObject() ,
            additionalAddress,
            addressCountry,
            addressDepartment,
            addressLocality,
            addressRegion,
            postOfficeBoxNumber,
            postalCode,
            streetAddress
        }
    }
}

/**
 * Indicates if the passed-in object is a Place or a compatible generic object with the good Place definition.
 * @param {Object} object The object to validate.
 * @param {boolean} strict Indicates if all elements must be valid or only one.
 * @returns {boolean} If the object is a valid Place definition.
 */
PostalAddress.is = ( object , strict = false ) =>
{
    if( object instanceof Place )
    {
        return true ;
    }
    else if( strict )
    {
        return object.hasOwnProperty('addressCountry')
            && object.hasOwnProperty('additionalAddress')
            && object.hasOwnProperty('addressDepartment')
            && object.hasOwnProperty('addressLocality')
            && object.hasOwnProperty('addressRegion')
            && object.hasOwnProperty('postOfficeBoxNumber')
            && object.hasOwnProperty('postalCode')
            && object.hasOwnProperty('streetAddress')
            ;

    }
    else
    {
        return object.hasOwnProperty('addressCountry')
            || object.hasOwnProperty('additionalAddress')
            || object.hasOwnProperty('addressDepartment')
            || object.hasOwnProperty('addressLocality')
            || object.hasOwnProperty('addressRegion')
            || object.hasOwnProperty('postOfficeBoxNumber')
            || object.hasOwnProperty('postalCode')
            || object.hasOwnProperty('streetAddress')
            ;
    }
};

export default PostalAddress ;
