
import generify from '../generify'

import Game from '../Game'

import CourseGame from './CourseGame'

class ApplicationGame extends Game
{
    /**
     * Creates a new ApplicationGame instance.
     * @constructor
     * @param object The generic object to initialize the object the first time.
     */
    constructor( object = null )
    {
        super();

        this.set( object ) ;
    }

    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new ApplicationGame( this.toObject() ) ;
    }

    /**
     * Populate the object with specific behaviors or types. Launched by default in the constructor and in the set methods.
     * @returns {Thing} The current reference.
     */
    populate()
    {
        super.populate() ;

        const {
            quest
        } = this ;

        if( quest && quest instanceof Array )
        {
            this.quest = quest.map( item => item instanceof CourseGame ? item : new CourseGame( item ) ) ;
        }

        return this ;
    }

    /**
     * Returns the generic object representation of the item.
     * @return {Object} The generic object representation of the item.
     */
    toObject()
    {
        let {
            quest
        } = this ;

        quest = generify(quest) ;

        return { ...super.toObject() , ...{
            quest
        }}
    }
}

export default ApplicationGame ;