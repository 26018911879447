const address =
{
    copy :
    {
        snack   : 'Adresse copiée dans le presse-papiers.' ,
        tooltip : 'Copier l\'adresse' 
    },
    title : 'Adresse'
};

export default address ;
