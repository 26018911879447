import Form from '../../../../display/forms/FormElements'

import Method from "../../../../net/Method";

import Place from "../../../../things/Place";

import api from "../../../../configs/api";

const elements =
[
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'legalName',
            key          : 'legalName',
            label        : 'Dénomination légale',
            autoFocus    : false,
            fullWidth    : true,
            helperText   : null,
            margin       : 'normal',
            required     : false,
            select       : false,
            autoComplete : 'off' ,
            type         : 'text',
            variant      : 'outlined'
        }
    },
    {
        type : Form.SEARCH_SELECTOR ,
        path : api.thesaurus.business_entity_types ,
        init :
        {
            id         : 'legalForm',
            key        : 'legalForm',
            label      : 'Forme légale',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            required   : false,
            type       : 'search',
            variant    : 'outlined'
        },
        inputProps :
        {
            label : 'Forme légal...'
        },
        limit  : 0
    },
    {
        type : Form.SEARCH_SELECTOR ,
        path : api.thesaurus.organizations_naf ,
        init :
        {
            id         : 'ape',
            key        : 'ape',
            label      : 'Activité principale (APE)',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            required   : false,
            type       : 'search',
            variant    : 'outlined'
        },
        inputProps :
        {
            label : 'Activité principale...'
        },
        limit  : 0
    },
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'taxID',
            key          : 'taxID',
            label        : 'Numéro de Siret',
            autoFocus    : false,
            fullWidth    : true,
            helperText   : null,
            margin       : 'normal',
            required     : false,
            select       : false,
            autoComplete : 'off' ,
            type         : 'text',
            variant      : 'outlined'
        }
    },
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'vatID',
            key          : 'vatID',
            label        : 'N° de TVA',
            autoFocus    : false,
            fullWidth    : true,
            helperText   : null,
            margin       : 'normal',
            required     : false,
            select       : false,
            autoComplete : 'off' ,
            type         : 'text',
            variant      : 'outlined'
        }
    },
    {
        type  : Form.SEARCH_SELECTOR ,
        clazz : Place,
        path  : api.places.url ,
        init  :
        {
            id         : 'foundingLocation',
            key        : 'foundingLocation',
            label      : 'Lieu de création',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            required   : false,
            type       : 'search',
            variant    : 'outlined'
        },
        selector : { i18n :
        {
            empty       : 'Aucun lieu disponible.' ,
            title       : 'Sélectionner un lieu',
            description : 'Veuillez sélectionner un lieu ci-dessous.' ,
        }}
    },
    {
        type   : Form.DATE ,
        format : 'L' ,
        init   :
        {
            id            : 'foundingDate',
            key           : 'foundingDate',
            autoOk        : true ,
            cancelLabel   : 'Annuler' ,
            clearable     : true ,
            clearLabel    : 'Effacer' ,
            invalidLabel  : '-',
            disableFuture : false ,
            disablePast   : false ,
            fullWidth     : true ,
            label         : 'Date de création' ,
            required      : true,
            okLabel       : 'OK',
            variant       : 'outlined'
        }
    },
    {
        type   : Form.DATE ,
        format : 'L' ,
        init   :
        {
            id            : 'dissolutionDate',
            key           : 'dissolutionDate',
            autoOk        : true ,
            cancelLabel   : 'Annuler' ,
            clearable     : true ,
            clearLabel    : 'Effacer' ,
            invalidLabel  : '-',
            disableFuture : false ,
            disablePast   : false ,
            fullWidth     : true ,
            label         : 'Date de fermeture' ,
            required      : true,
            okLabel       : 'OK',
            variant       : 'outlined'
        }
    }
];

const legal =
{
    edit :
    {
        agree       : 'Sauver' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour modifier les informations légales de cette organisation.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...' ,
        method      : Method.PATCH ,
        success     : 'Mise à jour réussie!' ,
        title       : 'Modifier' ,
        elements
    },
    labels :
    {
        empty            : '----' ,
        ape              : 'Activité principale (APE)',
        dissolutionDate  : 'Date de fermeture' ,
        foundingDate     : 'Date de création' ,
        foundingLocation : 'Lieu de création',
        legalForm        : 'Forme légale',
        legalName        : 'Dénomination légale',
        taxID            : 'Numéro de Siret',
        vatID            : 'N° de TVA'
    },
    title    : 'Informations légales' ,
    tooltips :
    {
        add    : 'Ajouter',
        edit   : 'Modifier',
        remove : 'Supprimer'
    }
};

export default legal ;
