import AudioObject from '../things/creativework/media/AudioObject'
import ImageObject from '../things/creativework/media/ImageObject'
import MediaObject from '../things/creativework/MediaObject'
import VideoObject from '../things/creativework/media/VideoObject'
import clsx from 'clsx';

const colors =
{
    audio : 'bg-blue-800 text-white' ,
    file  : 'bg-blue-800 text-white' ,
    image : 'bg-green-800 text-white' ,
    video : 'bg-red-800 text-white'
};

export const getMediaObjectIconClassName = ( media , className = null ) =>
{
    if( media instanceof MediaObject )
    {
        switch ( true )
        {
            case media instanceof AudioObject :
            {
                return clsx( colors.audio, className );
            }
            case media instanceof ImageObject :
            {
                return clsx( colors.image, className );
            }
            case media instanceof VideoObject :
            {
                return clsx( colors.video, className );
            }
            default :
            {
                return clsx( colors.file, className );
            }
        }
    }
    return className ;
}

export default colors ;