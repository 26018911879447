
import Form from '../../../../../../display/forms/FormElements'

import Method from '../../../../../../net/Method'

import api from '../../../../../../configs/api'

const elements =
[
    {
        type       : Form.SEARCH_SELECTOR ,
        path       : api.games.url ,
        clearLabel : 'Vider' ,
        closeLabel : 'Fermer' ,
        init       :
        {
            id         : 'game',
            key        : 'game',
            label      : 'Jeu',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            required   : true,
            type       : 'search',
            variant    : 'outlined'
        },
        inputProps :
        {
            label : 'Jeu...'
        },
        limit  : 0
    }
];


const game =
{
    edit :
    {
        elements,
        agree       : 'Sauver' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour modifier le jeu.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...' ,
        method      : Method.PATCH ,
        success     : 'Mise à jour réussie!' ,
        title       : 'Modifier le jeu' ,
    },
    labels :
    {
        empty : '----'
    },
    title    : 'Jeu' ,
    tooltips :
    {
        add    : 'Ajouter',
        edit   : 'Modifier',
        remove : 'Supprimer'
    }
};

export default game ;