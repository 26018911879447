const defaultOptions =
{
    overrides:
    {
        // DateTimePicker

        MuiPickersToolbarText :
        {
            toolbarTxt :
            {
                fontWeight : 500 ,
                fontSize   : 28
            },
            toolbarBtnSelected:
            {
                fontWeight : 500 ,
                fontSize   : 28
            }
        }
    },
    typography:
    {
        fontFamily :
        [
            //'oswald',
            //'Lato',
            //'Raleway',
            //'Muli',
            'Roboto',
            'Helvetica',
            'Arial',
            'sans-serif'
        ].join(',') ,
        fontWeightLight             : 300,
        fontWeightRegular           : 400,
        fontWeightMedium            : 600,
        htmlFontSize                : 9,
        //useNextVariants             : true,
        suppressDeprecationWarnings : true
    }
};

export default defaultOptions ;
