import React , { Fragment } from 'react'

import PropTypes from 'prop-types'

import moment from 'moment/moment'

import clean        from 'vegas-js-core/src/objects/clean'
import compose      from 'vegas-js-core/src/functors/compose'
import format       from 'vegas-js-core/src/strings/fastformat'
import generateUUID from 'vegas-js-core/src/random/generateUUID'
import isString     from "vegas-js-core/src/isString"
import leading      from 'vegas-js-core/src/numbers/leading'

import initApp from '../../../../../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import red from '@material-ui/core/colors/red'

import { Button, Fab, Tooltip } from '@material-ui/core'

import { FaCalendarAlt   } from 'react-icons/fa'
import { FaCalendarCheck } from 'react-icons/fa'

import AgeRanges  from '../medical/AgeRanges'
import Analysis   from '../medical/Analysis'
import Hypothesis from '../medical/Hypothesis'
import Symptoms   from '../medical/Symptoms'

import SectorHealthEvent from '../../../events/SectorHealthEvent'
import ThingCardChildren , { styles } from '../../../../ThingCardChildren'

import api from '../../../../../../configs/api'

import generify from '../../../../../../things/generify'

import AnnualHealthReview from '../../../../../../things/livestock/reviews/AnnualHealthReview'
import SectorEvent        from '../../../../../../things/livestock/events/SectorHealthEvent'
import Sector             from '../../../../../../things/Sector'
import SectorReview       from '../../../../../../things/livestock/reviews/SectorReview'
import Thing              from '../../../../../../things/Thing'

class SectorHealthEvents extends ThingCardChildren
{
    constructor( props )
    {
        super( props ) ;
        this.addPrepare  = this.prepare(false) ;
        this.editPrepare = this.prepare(true) ;
    }

    createChild = ( child , editable = false ) =>
    {
        if( child instanceof SectorEvent )
        {
            const {
                path,
                subjectOf
             } = this.props ;

            let { about:sector } = child ;

            let agesUri = '' ;
            let symptomsUri = '' ;

            if( sector instanceof Sector )
            {
                const { additionalType } = sector ;
                if( additionalType )
                {
                    const { id } = additionalType ;
                    if( id )
                    {
                        agesUri     = format(api.settings.ageGroupsBySectorsTypes , id);
                        symptomsUri = format(api.settings.symptomsBySectorsTypes  , id);
                    }
                }
            }

            return (
            <Fragment>
                <SectorHealthEvent
                    editable = { editable }
                    thing    = { child }
                    onClick  = { () => this.openEditDialog(child) }
                />
                <AgeRanges
                    containerProps = {{ className : "bg-transparent w-full shadow-none py-12 mb-8" }}
                    editSearchUri  = { agesUri }
                    editUri        = { path }
                    onChange       = { this.change }
                    subjectOf      = { subjectOf }
                    thing          = { child }
                />
                <Symptoms
                    containerProps = {{ className : "bg-transparent w-full shadow-none py-12 mb-8" }}
                    editSearchUri  = { symptomsUri }
                    editUri        = { path }
                    onChange       = { this.change }
                    subjectOf      = { subjectOf }
                    thing          = { child }
                />
                <Hypothesis
                    containerProps = {{ className : "bg-transparent w-full shadow-none py-12 mb-8" }}
                    editUri        = { path }
                    onChange       = { this.change }
                    subjectOf      = { subjectOf }
                    thing          = { child }
                />
                <Analysis
                    containerProps = {{ className : "bg-transparent w-full shadow-none py-12 mb-8" }}
                    editUri        = { path }
                    onChange       = { this.change }
                    subjectOf      = { subjectOf }
                    thing          = { child }
                />
            </Fragment>);

            }
        return null ;
    };

    getAddButton = ( disabled = false ) =>
    {
        if( this.isEditable() )
        {
            const {
                addIcon,
                addButtonProps
            } = this.props ;
            const locale = this.getLocale() ;
            let button = (
            <Button
                disabled = { disabled }
                onClick  = { () => this.openAddDialog() }
                color    = 'primary'
                variant  = 'contained'
                { ...addButtonProps }
            >
                { addIcon }
                { locale.labels.add }
            </Button>);

            const { tooltips } = locale ;
            if( tooltips )
            {
                const { add } = tooltips ;
                if( isString( add ) )
                {
                    button = (
                    <Tooltip
                        title     = { add }
                        placement = 'top'
                    >
                        { button }
                    </Tooltip>
                    )
                }
            }

            return button ;
        }
        return null ;
    };

    getBottom = this.getCustomCardWithAddButton ;

    getCardLabel = ( child, editable, index ) =>
    {
        let { cardLabel:label } = this.props ;
        if( label )
        {
            if( index >= 0 )
            {
                label = leading(++index) + ' - ' + label ;
            }
            return label ;
        }
        return null ;
    };

    getLocale = () => this.props.locale.things.livestock.reviews.annual.events.sectorHealthEvents ;

    onAdd    = this.change ;
    onEdit   = this.change ;
    onRemove = this.change ;

    onCardClick = item => () => this.openEditDialog( item ) ;

    prepare = ( edit = false ) => item =>
    {
        let {
            id = null ,
            ageRange,
            morbidity,
            mortality,
            endDate,
            startDate,
            symptoms,
            subjectOf:sectorReview
        }
        = item ;

        let index ;
        let review ;
        let about = null ;

        if( sectorReview instanceof SectorReview )
        {
            const {
                about:sector ,
                subjectOf:annualReview
            }
            = sectorReview ;

            if( sector instanceof Sector )
            {
                const { id:key , url } = sector ;

                index = key ;

                about = { id:key , url } ;

                if( id === null )
                {
                    id = key + '-' + generateUUID() ; // Create a unique ID : [sectorID-uuid]
                }
            }

            if( annualReview instanceof AnnualHealthReview )
            {
                review = annualReview ;
            }
        }

        if( ageRange instanceof Thing )
        {
            ageRange = generify(ageRange,true) ;
        }

        if( morbidity instanceof Thing )
        {
            morbidity = clean(morbidity.toObject()) ;
        }

        if( mortality instanceof Thing )
        {
            mortality = clean(mortality.toObject()) ;
        }

        if( symptoms instanceof Array )
        {
            symptoms = generify(symptoms,true);
        }

        const datas =
        {
            id ,
            about,
            ageRange,
            morbidity,
            mortality,
            endDate   : endDate instanceof Date   ? moment(endDate).format( 'YYYY-MM-DD' )   : null ,
            startDate : startDate instanceof Date ? moment(startDate).format( 'YYYY-MM-DD' ) : null ,
            symptoms
        };

        if( review instanceof AnnualHealthReview )
        {
            review = review.toObject(false);
            if( review )
            {
                const { subReview : workplaces } = review ;
                if( workplaces )
                {
                    let len1 = workplaces.length ;
                    for( let i = 0 ; i<len1 ; i++ )
                    {
                        let workplace = workplaces[i] ;
                        if( workplace )
                        {
                            const { subReview : sectors } = workplace ;
                            if( sectors instanceof Array )
                            {
                                let len2 = sectors.length ;

                                for( let j = 0 ; j<len2 ; j++ )
                                {
                                    const sector = sectors[j];
                                    if( sector )
                                    {
                                        let { about , events } = sector ;
                                        if( about )
                                        {
                                            const { id } = about ;
                                            if( index === id )
                                            {
                                                if( !(events instanceof Array) )
                                                {
                                                    events = [] ;
                                                }

                                                if( edit )
                                                {
                                                    let { id } = datas ;
                                                    let position = events.findIndex( element => element.id === id ) ;
                                                    if( position > -1 )
                                                    {
                                                        events[position] = datas ;
                                                    }
                                                }
                                                else
                                                {
                                                    events.push( datas ) ;
                                                }

                                                sector.events = events ;

                                                return { review : JSON.stringify(review) } ;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        return null ;
    };

    removePrepare = item =>
    {
        if( item instanceof SectorEvent )
        {
            let { id, subjectOf:review } = item ;
            if( review instanceof AnnualHealthReview )
            {
                review = review.toObject(false);
                if( review )
                {
                    const { subReview : workplaces } = review ;
                    if( workplaces )
                    {
                        let len1 = workplaces.length ;
                        for( let i = 0 ; i<len1 ; i++ )
                        {
                            let workplace = workplaces[i] ;
                            if( workplace )
                            {
                                const { subReview : sectors } = workplace ;
                                if( sectors instanceof Array )
                                {
                                    let len2 = sectors.length ;
                                    for( let j = 0 ; j<len2 ; j++ )
                                    {
                                        const sector = sectors[j];
                                        if( sector )
                                        {
                                            let { events } = sector ;
                                            if( events instanceof Array )
                                            {
                                                events = events.filter( element => element.id !== id ) ;
                                                sector.events = events ;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                return { review : JSON.stringify(review) } ;
            }
        }
        return null ;
    };
}

SectorHealthEvents.defaultProps =
{
    ...ThingCardChildren.defaultProps,
    cardIcon              : <FaCalendarCheck size={20}/> ,
    cardLabel             : 'Événement' ,
    clazz                 : SectorEvent,
    DeleteButtonComponent : Fab,
    DeleteButtonProps     :
    {
        size  : 'small',
        style :
        {
            backgroundColor : 'white',
            color           : red[600],
            '&:hover'       :
            {
                backgroundColor : red[900],
                color           : 'white'
            }
        }
    },
    icon       : <FaCalendarAlt size={20}/>,
    member     : 'events',
    optionMode : 'none' ,
    path       : null
};

SectorHealthEvents.propTypes =
{
    ...ThingCardChildren.propTypes,
    path : PropTypes.string
};

export default compose( withStyles( styles ) , withRouter , initApp )( SectorHealthEvents ) ;
