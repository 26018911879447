const question =
{
    labels :
    {
        additionalType : 'Type',
        description    : 'Description',
        empty          : '----' ,
        fail           : 'Message d\'erreur',
        multiple       : 'Choix multiple',
        no             : 'Non',
        score          : 'Score' ,
        success        : 'Message de réussite',
        tip            : 'Indice',
        yes            : 'Oui'
    },
    title : 'Question'
};

export default question ;