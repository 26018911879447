import React  from 'react'

import clsx from 'clsx'

import format from 'vegas-js-core/src/strings/fastformat'

import DescriptionIcon  from '@material-ui/icons/Description'
import FormatQuoteIcon  from '@material-ui/icons/FormatQuote'
import NoteIcon         from '@material-ui/icons/SpeakerNotes'
import ShortTextIcon    from '@material-ui/icons/ShortText'
import TextIcon         from '@material-ui/icons/Subject'
import TitleIcon        from '@material-ui/icons/Title'

import withLocale from '../../../contexts/i18n/withLocale'

import TransitionGroup from '../../../transitions/TransitionGroup'

import MultiLangEditor from '../../../components/editors/MultiLangEditor'
import Websites        from '../../../display/things/Websites'

import Application       from '../../../display/things/Application'
import Audio             from '../../../display/things/medias/Audio'
import Image             from '../../../display/things/medias/Image'
import Metadatas         from '../../../display/things/Metadatas'
import Producer          from '../../../display/things/application/Producer'
import Publisher         from '../../../display/things/application/Publisher'
import Sponsor           from '../../../display/things/application/Sponsor'
import ThingSubContainer from '../../../display/containers/ThingSubContainer'
import Video             from '../../../display/things/medias/Video'

class About extends ThingSubContainer
{
    getLocale = () => this.props.locale.views.application.about ;

    render = () =>
    {
        const { thing } = this.props ;
        if( thing )
        {
            const {
                className ,
                path,
                style,
            } = this.props ;

            const locale = this.getLocale() ;

            const {
                alternativeHeadline,
                description,
                notes,
                headline,
                slogan,
                text,
                id
            } = thing ;

            return (
                <div
                    className = { clsx( 'flex-1 p-12' , className ) }
                    style     = { style }
                >
                    <TransitionGroup
                        duration = { 250 }
                        enter    = {{ animation: "transition.slideUpIn" }}
                    >

                        <div className="flex-1 flex flex-col space-y-24">

                            <Metadatas
                                activable = { false }
                                thing     = { thing }
                                uri       = { format(path.application, id) }
                            />

                            <Application
                                editUri  = { format( path.application ,id ) }
                                onChange = { this.change }
                                thing    = { thing }
                            />

                            <div className='grid grid-cols-1 sm:grid-cols-2 gap-16'>
                                <MultiLangEditor
                                    html     = { false }
                                    icon     = { <TitleIcon/> }
                                    input    = { true }
                                    locale   = { locale.headline }
                                    onChange = { this.changeProperty('headline') }
                                    text     = { headline }
                                    uri      = { format(path.headline, id ) }
                                    verbose  = { true }
                                />
                                <MultiLangEditor
                                    html     = { false }
                                    icon     = { <ShortTextIcon/> }
                                    input    = { true }
                                    locale   = { locale.alternativeHeadline }
                                    onChange = { this.changeProperty('alternativeHeadline') }
                                    text     = { alternativeHeadline }
                                    uri      = { format(path.alternativeHeadline, id ) }
                                    verbose  = { true }
                                />
                                <MultiLangEditor
                                    html     = { false }
                                    icon     = { <FormatQuoteIcon/> }
                                    input    = { true }
                                    locale   = { locale.slogan }
                                    onChange = { this.changeProperty('slogan') }
                                    text     = { slogan }
                                    uri      = { format( path.slogan, id ) }
                                />
                            </div>

                            <Websites
                                onChange = { this.change }
                                thing    = { thing }
                                uri      = { path.websites }
                            />

                            <div className='grid grid-cols-1 sm:grid-cols-2 gap-16'>
                                <MultiLangEditor
                                    html     = { false }
                                    icon     = { <DescriptionIcon/> }
                                    locale   = { locale.description }
                                    onChange = { this.changeProperty('description') }
                                    text     = { description }
                                    uri      = { format( path.description, id ) }
                                />
                                <MultiLangEditor
                                    icon     = { <NoteIcon/> }
                                    locale   = { locale.notes }
                                    onChange = { this.changeProperty('notes') }
                                    text     = { notes }
                                    uri      = { format( path.notes, id ) }
                                />
                            </div>

                            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-16'>
                                <div>
                                    <Image
                                        onChange = { this.change }
                                        thing    = { thing }
                                        uri      = { path.image }
                                    />
                                </div>
                                <div>
                                    <Audio
                                        onChange = { this.change }
                                        thing    = { thing }
                                        uri      = { path.audio }
                                    />
                                </div>
                                <div>
                                    <Video
                                        onChange = { this.change }
                                        thing    = { thing }
                                        uri      = { path.video }
                                    />
                                </div>
                            </div>

                            <MultiLangEditor
                                icon     = { <TextIcon/> }
                                locale   = { locale.text }
                                onChange = { this.changeProperty('text') }
                                text     = { text }
                                uri      = { format( path.text, id ) }
                            />

                            <div className='grid grid-cols-1 sm:grid-cols-2 gap-16'>
                                <Producer
                                    onChange = { this.change }
                                    thing    = { thing }
                                    uri      = { path.producer }
                                />
                                <Publisher
                                    onChange = { this.change }
                                    thing    = { thing }
                                    uri      = { path.publisher }
                                />
                                <Sponsor
                                    onChange = { this.change }
                                    thing    = { thing }
                                    uri      = { path.sponsor }
                                />
                            </div>

                        </div>
                    </TransitionGroup>
                </div>
            );
        }

        return null;
    }
}

About.defaultProps =
{
    ...ThingSubContainer.defaultProps
};

About.propTypes =
{
    ...ThingSubContainer.propTypes
};

export default withLocale( About ) ;
