import axios from 'axios'

import URI  from 'urijs'
import auth from '../configs/auth';

import tokensManager  from '../vo/TokensManager'
import getAccessToken from './auth/getAccessToken'

import Method from './Method'

const abort = message => console.log( 'UPLOAD failed' , message ) ;

const DEFAULT_OPTIONS =
{
    accessToken  : null ,
    api          : null ,
    cancel       : null ,
    canceler     : null ,
    datas        : null ,
    fail         : null ,
    filePropName : 'file' ,
    method       : Method.POST,
    progress     : null ,
    queries      : {} ,
    success      : null ,
    timeout      : process.env.REACT_APP_UPLOAD_TIMEOUT
};

/**
 * Upload a file with a webservice API.
 * @param uri The uri of the API method.
 * @param file The file to upload.
 * @param {Null|object} [options=null] - The configuration object to set the request.
 * @param {Object} [options.accessToken=null] - The optional accessToken object.
 * @param {Object} [options.api=null] - The optional api object.
 * @param {Function} [options.cancel=null] - A callback method invoked when the service is canceled.
 * @param {CancelTokenSource} [options.canceler=null] - The canceler of the request.
 * @param {Object} [options.datas=null] - The optional object to send datas to the API method.
 * @param {Function} [options.fail=null] - A callback function invoked when the request failed.
 * @param {string} [options.filePropName=null] .
 * @param {string} [options.method=null] .
 * @param {Object} [options.progress=null] A callback function invoked during the progress of the request.
 * @param {Object} [options.queries=null] The search queries to inject in the uri.
 * @param {Function} [options.success] - A callback function invoked when the request succeed.
 * @param {int} [options.timeout=18000] - Indicates the delay in ms to timeout the request.
 */
export default function UPLOAD( uri, file, options = null )
{
    const params = { ...DEFAULT_OPTIONS , ...( !!(options) && options ) } ;

    let {
        accessToken,
        api,
        cancel,
        canceler,
        datas,
        fail,
        filePropName,
        method,
        progress,
        queries,
        success,
        timeout
    }
    = params ;

    fail = fail instanceof Function ? fail : abort ;

    if( canceler === null )
    {
        canceler = axios.CancelToken.source() ;
    }

    if( api === null )
    {
        for( let prop in auth.apis )
        {
            if( auth.apis.hasOwnProperty( prop ) )
            {
                if( uri.startsWith( auth.apis[prop].url ) )
                {
                    api = auth.apis[prop] ;
                    break ;
                }
            }
        }

        if( !api )
        {
            fail( 'API definition not found with the uri: ' + uri );
            return canceler ;
        }
    }

    if( accessToken === null )
    {
        accessToken = tokensManager.read( api.name ) ; // get stored accessToken
    }

    if( !accessToken || accessToken.expired )
    {
        return getAccessToken(
        {
            accessToken,
            api,
            cancel,
            fail : response => fail(response),
            success : () => UPLOAD( uri, file,
            {
                accessToken,
                api,
                cancel,
                canceler,
                datas,
                fail,
                filePropName,
                method,
                progress,
                queries,
                success,
                timeout
            }),
            timeout
        });
    }
    
    uri = new URI(uri) ;

    uri.addSearch(queries) ;

    const request = axios.create(
    {
        cancelToken : canceler.token,
        headers     :
        {
            'Accept'        : 'application/json' ,
            'Authorization' : accessToken.token_type + ' ' + accessToken.access_token ,
            'Content-Type'  : 'multipart/form-data'
        },
        onUploadProgress : event =>
        {
            if( progress instanceof Function )
            {
                const { loaded, total } = event ;
                progress( loaded, total ) ;
            }
        },
        timeout
    });

    console.log( 'UPLOAD(' + uri + ')' , method , filePropName );

    const data = new FormData() ;
    
    if( datas )
    {
        for( let name in datas )
        {
            data.append( name , datas[name] );
        }
    }

    if( file )
    {
        data.append( filePropName , file );
    }

    let none = error =>
    {
        console.log( "UPLOAD fail" , error ) ;
        
        const { message, response, request } = error ;
        
        if ( axios.isCancel( error ) )
        {
            if( cancel instanceof Function )
            {
                cancel( error ) ;
            }
        }
        else if( response )
        {
            fail( response );
        }
        else if( request )
        {
            fail( request );
        }
        else
        {
            fail( message );
        }
    };
    
    let ok = response =>
    {
        const { status } = response ;
        console.log( 'UPLOAD status ' + status ) ;
        switch( status )
        {
            case 200 :
            {
                if( !response && !response.data && !response.data.result )
                {
                    fail( { data : { code : 400 , message : 'Bad Request' } } );
                    return ;
                }

                if( success instanceof Function)
                {
                    success( response );
                }

                break ;
            }

            default :
            {
                fail( response );
            }
        }
    };
    
    switch( method )
    {
        case Method.PATCH :
        {
            request.patch( uri, data ).then( ok ).catch( none );
            break ;
        }
        
        case Method.PUT :
        {
            request.put( uri, data ).then( ok ).catch( none );
            break ;
        }
        
        default :
        {
            request.post( uri, data ).then( ok ).catch( none );
        }
    }

    return canceler ;
}