import populateMedias from './populateMedias'

import CreativeWork from '../CreativeWork'

import generify from '../generify'
import Word     from '../Word'
import Website  from '../Website'

/**
 * An article, such as a news article or piece of investigative report. Newspapers and magazines have articles of many different types and this is intended to cover them all.
 * @memberOf things.creativework
 */
class Article extends CreativeWork
{
    /**
     * Creates a new MediaObject instance.
     * @constructor
     * @param object The generic object to initialize the object the first time.
     */
    constructor( object = null )
    {
        super() ;

        /**
         * The audios medias of this place.
         * @type {Array}
         */
        this.audios = null;

        /**
         * A list of articles related to the current article.
         * @type {Article|Array|null}
         */
        this.isRelatedTo = null ;

        /**
         * A list of articles similar with the current article.
         * @type {Article|Array|null}
         */
        this.isSimilarTo = null ;

        /**
         * Indicates the number of audios medias of this thing.
         * @type {Array}
         */
        this.numAudios = null;

        /**
         * Indicates the number of videos medias of this thing.
         * @type {Array}
         */
        this.numPhotos = null;

        /**
         * Indicates the number of videos medias of this thing.
         * @type {Array}
         */
        this.numVideos = null;

        /**
         * The photos collection of this place.
         * @type {Array}
         */
        this.photos = null ;

        /**
         * The videos medias of this place.
         * @type {Array}
         */
        this.videos = null;

        /**
         * The websites enumeration of this place.
         * @type {Website|array}
         */
        this.websites = null ;

        this.set( object ) ;
    }

    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        super.clear() ;
        this.audios =
        this.isRelatedTo =
        this.isSimilarTo =
        this.numAudios =
        this.numPhotos =
        this.numVideos =
        this.photos =
        this.videos =
        this.websites = null ;
        return this ;
    }

    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new Article( this.toObject() ) ;
    }

    /**
     * Populate the object with specific behaviors or types. Launched by default in the constructor and in the set methods.
     * @returns {Thing} The current reference.
     */
    populate()
    {
        const {
            additionalType,
            hasPart,
            isPartOf,
            isRelatedTo,
            isSimilarTo,
            websites
        } = this ;

        populateMedias( this ) ;

        if( additionalType && !(additionalType instanceof Word))
        {
            this.additionalType = new Word(additionalType) ;
        }

        if( hasPart instanceof Array )
        {
            this.hasPart = hasPart.map( item => item instanceof Article ? item : new Article(item) ) ;
        }

        if( isPartOf instanceof Array )
        {
            this.isPartOf = isPartOf.map( item => item instanceof Article ? item : new Article(item) ) ;
        }

        if( isRelatedTo instanceof Array )
        {
            this.isRelatedTo = isRelatedTo.map( item => item instanceof Article ? item : new Article(item) ) ;
        }

        if( isSimilarTo instanceof Array )
        {
            this.isSimilarTo = isSimilarTo.map( item => item instanceof Article ? item : new Article(item) ) ;
        }

        if( websites instanceof Array )
        {
            this.websites = websites.map( item => item instanceof Website ? item : new Website(item) ) ;
        }

        return this ;
    }

    /**
     * Returns the generic object representation of the item.
     * @return {Object} The generic object representation of the item.
     */
    toObject()
    {
        let
        {
            audios,
            isRelatedTo,
            isSimilarTo,
            numAudios,
            numPhotos,
            numVideos,
            photos,
            videos,
            websites
        }
        = this ;

        audios = generify(audios) ;
        photos = generify(photos) ;
        videos = generify(videos);

        isRelatedTo = generify(isRelatedTo);
        isSimilarTo = generify(isSimilarTo);
        websites    = generify(websites);

        return {
            ...super.toObject() ,
            audios,
            isRelatedTo,
            isSimilarTo,
            numAudios,
            numPhotos,
            numVideos,
            photos,
            videos,
            websites,
        }
    }
}

export default Article ;