import compose  from 'vegas-js-core/src/functors/compose'
import notEmpty from 'vegas-js-core/src/strings/notEmpty'

import { withRouter } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'

import AudiosList from '../../display/lists/AudiosList'
import PhotosList from '../../display/lists/PhotosList'
import VideosList from '../../display/lists/VideosList'

import withLocale from '../../contexts/i18n/withLocale'

import ThingTabsSubContainer , { styles } from '../../display/containers/ThingTabsSubContainer'

import AudioObject from '../../things/creativework/media/AudioObject'
import ImageObject from '../../things/creativework/media/ImageObject'
import VideoObject from '../../things/creativework/media/VideoObject'

import api from '../../configs/api'

const className = 'flex-auto p-0' ;

class Medias extends ThingTabsSubContainer
{
    getLocale = () => this.props.locale.views.medias ;

    getRouteUri = ( { id , uri } = {} ) =>
    {
        const { thing } = this.props ;
        if( thing )
        {
            let { url } = thing ;
            if( notEmpty( url ) )
            {
                uri = url.split(api.url)[1] + '/' + id ;
            }
        }
        return uri ;
    };
}

Medias.defaultProps =
{
    ...ThingTabsSubContainer.defaultProps,
    first  : 'photos' ,
    routes :
    [
        { id : 'photos' , RouteComponent:PhotosList , clazz:ImageObject , className } ,
        { id : 'videos' , RouteComponent:VideosList , clazz:VideoObject , className } ,
        { id : 'audios' , RouteComponent:AudiosList , clazz:AudioObject , className }
    ],
    tabs :
    [
        { id : 'photos' , badge : 'numPhotos' } ,
        { id : 'videos' , badge : 'numVideos' } ,
        { id : 'audios' , badge : 'numAudios' } ,
    ]
};

Medias.propTypes =
{
    ...ThingTabsSubContainer.propTypes
};

export default compose (
    withStyles( styles ),
    withRouter,
    withLocale
)( Medias );
