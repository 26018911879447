import events  from './annual/events'
import medical from './annual/medical'
import reviews from './annual/reviews'

const annual =
{
    // dependencies
    
    events,
    medical,
    reviews,
    
    // settings
    
    workplaces : 'Espaces de travail'
};

export default annual ;