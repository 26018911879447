import React  from 'react'

import clsx from 'clsx'

import compose from 'vegas-js-core/src/functors/compose'
import format  from 'vegas-js-core/src/strings/fastformat'

import { withRouter } from 'react-router-dom'

import DescriptionIcon from '@material-ui/icons/Notes'
import NoteIcon        from '@material-ui/icons/SpeakerNotes'
import SubtitleIcon    from '@material-ui/icons/ShortText'
import TextIcon        from '@material-ui/icons/Subject'
import TitleIcon       from '@material-ui/icons/Title'

import TransitionGroup from '../../../transitions/TransitionGroup'

import withLocale from '../../../contexts/i18n/withLocale'

import MultiLangEditor from '../../../components/editors/MultiLangEditor'

import Audio              from '../../../display/things/medias/Audio'
import Course             from '../../../display/things/Course'
import Image              from '../../../display/things/medias/Image'
import Metadatas          from '../../../display/things/Metadatas'
import RemoveCourseDialog from '../../../display/dialogs/remove/RemoveCourseDialog'
import ThingSubContainer  from '../../../display/containers/ThingSubContainer'
import Video              from '../../../display/things/medias/Video'

class About extends ThingSubContainer
{
    getLocale = () => this.props.locale.views.course.about ;

    render = () =>
    {
        const { thing } = this.props ;

        if( thing )
        {
            const locale = this.getLocale() ;

            const {
                className,
                onRemove,
                path,
                style,
            } = this.props ;

            const {
                alternativeHeadline,
                description,
                notes,
                headline,
                text,
                id
            } = thing ;

            return (
                <div
                    className = { clsx( 'flex-1 p-12' , className ) }
                    style     = { style }
                >
                    <TransitionGroup duration={250} enter={{animation: "transition.slideUpIn"}}>

                        <div className="flex-1 flex flex-col space-y-24">

                            <Metadatas
                                activable             = { true }
                                deletable             = { true }
                                onRemove              = { onRemove }
                                RemoveDialogComponent = { RemoveCourseDialog }
                                thing                 = { thing }
                                uri                   = { format(path.course,id) }
                            />

                            <Course
                                editUri  = { format(path.course,id) }
                                onChange = { this.change }
                                thing    = { thing }
                            />

                            <div className='grid grid-cols-1 sm:grid-cols-2 gap-16'>
                                <MultiLangEditor
                                    html     = { false }
                                    icon     = { <TitleIcon/> }
                                    input    = { true }
                                    locale   = { locale.headline }
                                    onChange = { this.changeProperty('headline') }
                                    text     = { headline }
                                    uri      = { format(path.headline, id ) }
                                    verbose  = { true }
                                />
                                <MultiLangEditor
                                    html     = { false }
                                    icon     = { <SubtitleIcon/> }
                                    input    = { true }
                                    locale   = { locale.alternativeHeadline }
                                    onChange = { this.changeProperty('alternativeHeadline') }
                                    text     = { alternativeHeadline }
                                    uri      = { format(path.alternativeHeadline, id ) }
                                    verbose  = { true }
                                />
                                <MultiLangEditor
                                    html     = { false }
                                    icon     = { <DescriptionIcon/> }
                                    locale   = { locale.description }
                                    onChange = { this.changeProperty('description') }
                                    text     = { description }
                                    uri      = { format( path.description, id ) }
                                />
                               <div>
                                    <Image
                                        onChange = { this.change }
                                        thing    = { thing }
                                        uri      = { path.image }
                                    />
                                </div>

                            </div>

                            <MultiLangEditor
                                icon     = { <TextIcon/> }
                                locale   = { locale.text }
                                onChange = { this.changeProperty('text') }
                                text     = { text }
                                uri      = { format(path.text, id ) }
                            />

                           <MultiLangEditor
                                icon     = { <NoteIcon/> }
                                locale   = { locale.notes }
                                onChange = { this.changeProperty('notes') }
                                text     = { notes }
                                uri      = { format( path.notes, id ) }
                            />

                           <div className='grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-16'>
                                <div>
                                    <Audio
                                        onChange = { this.change }
                                        thing    = { thing }
                                        uri      = { path.audio }
                                    />
                                </div>
                                <div>
                                    <Video
                                        onChange = { this.change }
                                        thing    = { thing }
                                        uri      = { path.video }
                                    />
                                </div>
                           </div>

                        </div>

                    </TransitionGroup>
                </div>
            );
        }

        return null;
    }
}

About.defaultProps =
{
    ...ThingSubContainer.defaultProps
};

About.propTypes =
{
    ...ThingSubContainer.propTypes
};

export default compose(
    withRouter,
    withLocale
)
( About );

