const sectorHealthEvent =
{
    ageRange    : 'Tranche d\'âge',
    datePattern : 'L',
    endDate     : 'Date de fin' ,
    empty       : '----' ,
    morbidity   : 'Taux de morbidité',
    mortality   : 'Taux de mortalité',
    startDate   : 'Date de début'
};

export default sectorHealthEvent ;