import initDialog from '../initDialog'

import { SelectThingDialog , styles } from '../SelectThingDialog'

import api from '../../../configs/api'

import Person from '../../../things/Person'

class SelectPersonDialog extends SelectThingDialog
{
    getLocale = () => this.props.locale.dialogs.select.person ;
}

SelectPersonDialog.defaultProps =
{
    ...SelectThingDialog.defaultProps,
    clazz : Person ,
    uri   : api.people.url
};

SelectPersonDialog.propTypes =
{
    ...SelectThingDialog.propTypes,
};

export default initDialog({ styles , useSearch:false })( SelectPersonDialog ) ;
