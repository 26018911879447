import initDialog from '../initDialog'

import PropTypes from "prop-types"

import { AddDialog } from '../AddDialog'

import api from '../../../configs/api'

import identify from '../../../things/identify'
import isString from 'vegas-js-core/src/isString';

const styles = () => ({});

export class AddStepDialog extends AddDialog
{
    getLocale = () => this.props.locale.dialogs.application.step.add ;

    prepare = item =>
    {
        let {
            name,
            headline,
            stage
        } = item ;

        const { config : { languages } = {} } = this.props ;

        if( (languages instanceof Array) && languages.length > 0 )
        {
            if ( headline )
            {
                languages.forEach( lang =>
                {
                    if ( !isString( headline[lang] ) )
                    {
                        headline[lang] = '';
                    }
                } )
            }
        }

        let object = {
            headline,
            stage : identify(stage) ,
            name
        };

        const { offset:position } = this.props ;
        if( position >= 0 )
        {
            object = { ...object , position } ;
        }

        return object ;
    };
}

AddStepDialog.defaultProps =
{
    ...AddDialog.defaultProps,
    mock : false ,
    uri  : api.steps.url
};

AddStepDialog.propTypes =
{
    ...AddDialog.propTypes,
    offset : PropTypes.number
};

export default initDialog({ styles })( AddStepDialog ) ;
