import React , { Component } from 'react'

import clsx from 'clsx'

import PropTypes from 'prop-types'

import format  from 'vegas-js-core/src/strings/fastformat'
import ucFirst from 'vegas-js-core/src/strings/ucFirst'

import initApp from '../../../../../../contexts/app/initApp'

import { withStyles } from '@material-ui/core/styles'

import {
    withWidth,
    Avatar,
    Divider,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from '@material-ui/core'

import { isWidthUp } from '@material-ui/core/withWidth'

import grey from '@material-ui/core/colors/blueGrey'

import api from '../../../../../../configs/api'

import { FaVectorSquare } from 'react-icons/fa'

import AmbienceReview     from './AmbienceReview'
import Preventions        from '../medical/Preventions'
import SectorHealthEvents from '../events/SectorHealthEvents'

import AnnualHealthReview from '../../../../../../things/livestock/reviews/AnnualHealthReview'
import Sector             from '../../../../../../things/Sector'

const styles = () => (
{
    avatar :
    {
        backgroundColor : grey[300]
    },
    root :
    {
        width : '100%'
    }
});

class SectorReview extends Component
{
    change = thing =>
    {
        const { onChange } = this.props ;
        if( onChange )
        {
            onChange( thing ) ;
        }
    };

    find = ( thing ) =>
    {
        if( thing )
        {
            const index = this.getIndex( thing ) ;
            const { subjectOf } = this.props ;
            if( subjectOf instanceof AnnualHealthReview )
            {
                return subjectOf.findSectorByIndex( index ) ;
            }
        }
        return null ;
    };

    getIndex = ( thing ) =>
    {
        if( thing )
        {
           const { about } = thing ;
           if( about )
           {
                const { id } = about ;
                return id ;
           }
        }
        return null ;
    };

    getHeader = ( thing ) =>
    {
        const {
            classes,
            lang,
            width
        } = this.props ;
        if( thing )
        {
            let {
                additionalType ,
                name
            } = thing ;

            let icon ;

            if( additionalType )
            {
                additionalType = additionalType.getLocaleName(lang);
                if( additionalType )
                {
                    additionalType = (
                    <Typography className="font-medium" variant='subtitle1'>
                        { ucFirst(additionalType) }
                    </Typography>);
                }
                else
                {
                    additionalType = null ;
                }
            }
            else
            {
                additionalType = null ;
            }

            if( name )
            {
                name = thing.getLocaleName(lang);
                if( name )
                {
                    name = (
                    <Typography className="mt-4" variant='subtitle2'>
                        { ucFirst(name) }
                    </Typography>);
                }
                else
                {
                    name = null ;
                }
            }
            else
            {
                name = null ;
            }

            if( isWidthUp( 'sm' , width ) )
            {
               icon = (
               <div className='mr-16'>
                    <Avatar className={classes.avatar} variant="rounded" >
                        <FaVectorSquare size={20} />
                    </Avatar>
                </div>
               );
            }

            return (
            <div className="flex flew-row justify-between items-center w-full">
                { icon }
                <div className="flex flex-col w-full">
                   { additionalType }
                   { name }
                </div>
            </div>
            ) ;
        }
        return null ;
    };

    getInfo = ( sector ) =>
    {
        if( sector instanceof Sector )
        {
            let { capacity , numAttendee } = sector ;
            if( ( capacity >= 0 ) || ( numAttendee >= 0 )  )
            {
                const locale = this.getLocale() ;

                const { rows } = locale ;

                let color = 'bg-blue text-white' ;
                if( numAttendee > capacity )
                {
                    color = 'bg-red text-white' ;
                }

                return (
                <div className='w-full p-16'>
                    <Table>
                        <TableBody>
                            { this.getNumericRow( numAttendee , rows.numAttendee , color )  }
                            { this.getNumericRow( capacity    , rows.capacity    , 'bg-green-700 text-white')  }
                        </TableBody>
                    </Table>
                </div>);
            }
        }
        return null ;
    };

    getLocale = () => this.props.locale.things.livestock.reviews.annual.reviews.sectorReview ;

    getNumericRow = ( value, label, color = 'bg-blue-500 text-white' ) =>
    {
        value = parseInt(value);
        if( isNaN(value) || value < 0 )
        {
            return null ;
        }

        color = (value >= 0) ? color : 'bg-grey-400' ;

        const css = `font-medium px-8 rounded-lg ${color}` ;

        value = <span className={ css }>{value}</span>;
        return (
        <TableRow className='py-16'>
            <TableCell className='px-4 font-medium'>{ label }</TableCell>
            <TableCell align='right'>{ value }</TableCell>
        </TableRow>
        )
    };

    render()
    {
        const {
            classes,
            className,
            grid,
            path,
            style,
            subjectOf,
            thing
        }
        = this.props ;

        let preventionsUri ;

        const sector = this.find( thing ) ;

        if( sector instanceof Sector )
        {
            const { additionalType } = sector ;
            if( additionalType )
            {
                const { id } = additionalType ;
                if( id )
                {
                    preventionsUri = format(api.settings.preventionsBySectorsTypes, id);
                }
            }
        }

        return (
        <Grid
            {...grid}
            item      = { true }
            className = { clsx( classes.root, className ) }
            style     = { style }
        >
            <Paper elevation={1} className="p-20">

                { this.getHeader( sector ) }

                <Divider light={true} variant='fullWidth' className='my-8' />

                { this.getInfo( sector ) }

                <Preventions
                    containerProps = {{ className : "bg-transparent w-full shadow-none px-4 py-12 mb-8" }}
                    editSearchUri  = { preventionsUri }
                    editUri        = { path }
                    onChange       = { this.change }
                    subjectOf      = { subjectOf }
                    thing          = { thing }
                />

                <AmbienceReview
                    containerProps = {{ className : "bg-transparent w-full shadow-none px-4 py-12 mb-8" }}
                    editUri        = { path }
                    onChange       = { this.change }
                    subjectOf      = { subjectOf }
                    thing          = { thing }
                />

                <SectorHealthEvents
                    addUri               = { path }
                    containerProps       = {{ className : 'bg-transparent w-full shadow-none px-4 py-12 mb-8' }}
                    editUri              = { path }
                    onChange             = { this.change }
                    cardBottomPaperProps = {{ className : 'flex items-center justify-center w-full p-16' , elevation:0 }}
                    path                 = { path }
                    removeUri            = { path }
                    subjectOf            = { subjectOf }
                    thing                = { thing }
                />

            </Paper>
        </Grid>
        ) ;
    }
}

SectorReview.defaultProps =
{
    className : null,
    grid      :
    {
        xs : 12
    },
    icon      : null,
    locale    : null,
    onChange  : null,
    path      : null,
    style     : null,
    subjectOf : null ,
    thing     : null
};

SectorReview.propTypes =
{
    className : PropTypes.string,
    grid      : PropTypes.object,
    icon      : PropTypes.element,
    locale    : PropTypes.object,
    onChange  : PropTypes.func,
    path      : PropTypes.oneOfType([PropTypes.object,PropTypes.string]),
    style     : PropTypes.object,
    subjectOf : PropTypes.object,
    thing     : PropTypes.object.isRequired
};

export default withWidth()( withStyles( styles )( initApp(SectorReview) ) ) ;
