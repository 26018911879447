import activity         from './activity'
import audio            from './audio'
import conceptualObject from './conceptualObject'
import connection       from './connection'
import course           from './course'
import disease          from './disease'
import event            from './event'
import favorite         from './favorite'
import livestock        from './livestock'
import mediaObject      from './mediaObject'
import observation      from './observation'
import organization     from './organization'
import person           from './person'
import place            from './place'
import session          from './session'
import stage            from './stage'
import team             from './team'
import technician       from './technician'
import thing            from './thing'
import user             from './user'
import veterinary       from './veterinary'
import video            from './video'
import word             from './word'

const cells =
{
    activity,
    audio,
    conceptualObject,
    connection,
    course,
    disease,
    event,
    favorite,
    livestock,
    mediaObject,
    observation,
    organization,
    person,
    place,
    session,
    stage,
    team,
    technician,
    thing,
    user,
    veterinary,
    video,
    word
};

export default cells ;
