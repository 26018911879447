import React from 'react'

import isString from 'vegas-js-core/src/isString'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import moment from 'moment/moment'

import EventIcon  from '@material-ui/icons/Event'
import InfoIcon   from '@material-ui/icons/Info'
import MediasIcon from '@material-ui/icons/PermMedia'

import api   from '../../configs/api'
import paths from '../../configs/paths'

import ThingView , { defaultStyle } from '../../display/views/ThingView'

import EventVO from '../../things/Event'

import getLocalePeriodLabel from '../../things/getLocalePeriodLabel'

import About  from './views/About'
import Info   from './views/Info'
import Medias from '../medias/Medias'

import initView from '../../display/views/initView'

const styles = defaultStyle();

class Event extends ThingView
{
    getLocale = () => this.props.locale.views.event ;

    getSubtitle = thing =>
    {
        if( thing instanceof EventVO )
        {
            const locale = this.getLocale() ;
            return getLocalePeriodLabel( thing , moment , locale.period );
        }
        return null ;
    };

    getTitle = thing =>
    {
        if( thing instanceof EventVO )
        {
            const { lang } = this.props ;

            let { headline } = thing ;

            if( headline )
            {
                headline = thing.getLocaleHeadline( lang ) ;
                if( isString(headline) && headline !== '' )
                {
                    return ucFirst(headline)  ;
                }
            }

            return ucFirst(thing.getLocaleName(lang))  ;
        }
        return null ;
    };
}

Event.defaultProps =
{
    ...ThingView.defaultProps,
    back       : true ,
    clazz      : EventVO,
    icon       : <EventIcon/>,
    path       : api.events,
    responsive : true ,
    preferable : true ,
    routes     :
    [
        { id : 'about'  , RouteComponent : About  } ,
        { id : 'info'   , RouteComponent : Info   } ,
        { id : 'medias' , RouteComponent : Medias }
    ],
    tabs :
    [
        { id : 'about'  , icon : <EventIcon  className='pr-8' /> } ,
        { id : 'info'   , icon : <InfoIcon   className='pr-8' /> } ,
        { id : 'medias' , icon : <MediasIcon className='pr-8' /> }
    ],
    titleAuto : false // Important to use always the getTitle() method
};

Event.propTypes =
{
    ...ThingView.propTypes,
    path : paths.events
};

export default initView( Event, styles );
