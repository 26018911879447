import React from 'react'

import PropTypes from 'prop-types'

import withi18n from '../../contexts/i18n/withi18n'

import { ThingCell } from './ThingCell'

import AddressLabel     from '../labels/AddressLabel'
import BirthLabel       from '../labels/BirthLabel'
import DeathLabel       from '../labels/DeathLabel'
import NationalityLabel from '../labels/NationalityLabel'

import Person from '../../things/Person'
import Thing  from '../../things/Thing'

import getHorizontalElements from '../getHorizontalElements'

class PersonCell extends ThingCell
{  
    getAddress = ( { className = null } = {} ) =>
    {
        const { addressable:visible } = this.props;
        const thing = this.getThing() ;
        if( visible && thing instanceof Thing )
        {
            const { address } = thing ;
            if ( address )
            {
                return <AddressLabel className={className} thing={thing} />;    
            }
        }
        return null;
    };

    getBirth = ( { className = null } = {} ) =>
    {
        const { birth:visible } = this.props;
        const thing = this.getThing() ;
        if( visible && thing instanceof Thing )
        {
            const { birthDate , birthPlace } = thing ;
            if ( birthDate || birthPlace )
            {
                return <BirthLabel className={className} thing={thing} />;
            }
        }
        return null;
    };

    getContent = () => 
    {
        const { thing } = this.props;
        if ( thing ) 
        {
            const elements = [ 'address' , 'nationality' , 'birth' , 'death' ] ;
            return getHorizontalElements( { elements , factory:this } ) ;
        }
        return null;
    };

    getDeath = ( { className = null } = {} ) =>
    {
        const { death:visible } = this.props;
        const thing = this.getThing() ;
        if( visible && thing instanceof Thing )
        {
            const { deathDate , deathPlace } = thing ;
            if ( deathDate || deathPlace )
            {
                return <DeathLabel className={className} thing={thing} />;
            }
        }
        return null;
    };
    
    getLocale = () => this.props.locale.display.cells.person;
    
    getLocaleTitle = () =>
    {
        const thing = this.getThing();
        if( thing instanceof Person )
        {
            return thing.getFullNameOrUsername( this.props.lang ) ;
        }
        return null ;
    }

    getNationality = ( { className = null } = {} ) =>
    {
        const { nationality:visible } = this.props;
        const thing = this.getThing() ;
        if( visible && thing instanceof Thing )
        {
            const { nationality } = thing ;
            if ( nationality )
            {
                return <NationalityLabel className={className} thing={thing} />;
            }
        }
        return null;
    };
}

PersonCell.defaultProps =
{
    ...ThingCell.defaultProps,
    addressable : true ,
    birth       : true ,
    death       : true ,
    nationality : true ,
};

PersonCell.propTypes =
{
    ...ThingCell.propTypes,
    addressable : PropTypes.bool,
    birth       : PropTypes.bool,
    death       : PropTypes.bool,
    nationality : PropTypes.bool,
};

export default withi18n(PersonCell) ;
