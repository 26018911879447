import axios from 'axios'

import isString from 'vegas-js-core/src/isString'

import URI       from 'urijs'

import auth      from '../configs/auth'

import tokensManager from '../vo/TokensManager'

import connect         from './auth/connect'
import getAccessToken  from './auth/getAccessToken'
import GrantType       from './auth/GrantType'

const DEFAULT_OPTIONS =
{
    accessToken : null ,
    api         : null ,
    cancel      : null ,
    canceler    : null ,
    debug       : false,
    facets      : null ,
    fail        : null ,
    queries     : {} ,
    offset      : 0 ,
    limit       : 20 ,
    random      : false ,
    search      : null,
    sort        : null,
    success     : null,
    timeout     : process.env.REACT_APP_TIMEOUT ,
    verbose     : true
};

const referrerID = process.env.REACT_APP_REFERRER_ID ;

/**
 * Get a list with a specific api path.
 * @param uri {string} The uri of the API method.
 * @param {Null|object} [options=null] - The configuration object to set the request.
 * @param {Object} [options.accessToken=null] - The optional accessToken object.
 * @param {Object} [options.api=null] - The optional api object.
 * @param {Function} [options.cancel=null] - A callback method invoked when the service is canceled.
 * @param {CancelTokenSource} [options.canceler=null] - The canceler of the request.
 * @param {Object} [options.datas=null] - The optional object to send datas to the API method.
 * @param {Function} [options.fail=null] - A callback function invoked when the request failed.
 * @param {Function} [options.success] - A callback function invoked when the request succeed.
 * @param {int} [options.timeout=18000] - Indicates the delay in ms to timeout the request.
 */
export default function LIST( uri , options )
{
    const params = { ...DEFAULT_OPTIONS , ...( !!(options) && options ) } ;

    let {
        accessToken,
        api,
        cancel,
        canceler,
        debug,
        facets,
        fail,
        queries,
        offset,
        limit,
        random,
        search,
        sort,
        success,
        timeout,
        verbose
    }
    = params ;

    if( canceler === null )
    {
        canceler = axios.CancelToken.source() ;
    }

    if( api === null )
    {
        for( let prop in auth.apis )
        {
            if( auth.apis.hasOwnProperty( prop ) )
            {
                if( uri.startsWith( auth.apis[prop].url ) )
                {
                    api = auth.apis[prop] ;
                    break ;
                }
            }
        }

        if( !api )
        {
            if( fail instanceof Function)
            {
                fail( 'api definition not found for this url!' );
            }
            return canceler ;
        }
    }

    if( accessToken === null )
    {
        // get stored accessToken
        accessToken = tokensManager.read( api.name ) ;
    }

    if( !accessToken || accessToken.expired )
    {
        return getAccessToken( {
            accessToken,
            api,
            cancel,
            timeout,
            fail    : response => fail(response),
            success : () => LIST( uri , {
                accessToken : null,
                api,
                cancel,
                canceler,
                debug,
                facets,
                fail,
                queries,
                offset,
                limit,
                random,
                search,
                sort,
                success,
                timeout,
                verbose
            })
        });
    }

    uri = new URI(uri) ;

    uri.addSearch({
        offset,
        limit,
        ...( random && { random : Math.random( new Date().valueOf() ) } ) ,
        ...( verbose && { active : 'false' } ) ,
        ...( !!(queries) && queries ) ,
        ...( !!(facets) && { facets } ) ,
        ...( !!(sort) && { sort } ) ,
        ...( isString( search ) && { search } )
    } ) ;

    console.log( "LIST uri:" , uri.toString() ) ;

    const request = axios.create({
        cancelToken : canceler.token,
        headers :
        {
            'Accept'        : 'application/json',
            'Authorization' : accessToken.token_type + ' ' + accessToken.access_token
        },
        timeout
    });

    // save referrer
    sessionStorage.setItem( referrerID , JSON.stringify(window.location.pathname) ) ;

    request
    .get( uri.toString() )
    .then( response =>
    {
        const { status } = response ;

        console.log( 'LIST status ' + status ) ;

        switch( status )
        {
            case 200 :
            {
                try
                {
                    if( success instanceof Function)
                    {
                        success( response );
                    }
                }
                catch( er )
                {
                    if( debug )
                    {
                        console.log( 'LIST then failed' , er ) ;
                    }

                    if( fail instanceof Function)
                    {
                        fail( response );
                    }
                }
                break ;
            }

            default :
            {
                if( fail instanceof Function)
                {
                    fail( response );
                }
            }
        }
    })
    .catch( error =>
    {
        console.log( 'LIST failed' , error ) ;

        const { message, response, request } = error ;

        if ( axios.isCancel( error ) )
        {
            if( cancel instanceof Function )
            {
                cancel( error ) ;
            }
        }
        else if( response )
        {
            const { data } = response ;
            if( data )
            {
                const { message } = data ;

                if( message && ( message === 'token revoked' || message === 'Signature verification failed' ) )
                {
                    if( api.grant_type === GrantType.AUTHORIZATION_CODE )
                    {
                        tokensManager.dispose( api.name ) ; // remove accessToken
                        connect( api.name ) ;
                        return ;
                    }

                    return getAccessToken( 
                    {
                        accessToken,
                        api,
                        cancel,
                        fail    : response => fail(response) ,
                        success : () => LIST( uri ,
                        {
                            accessToken : null,
                            api,
                            cancel,
                            canceler,
                            debug,
                            facets,
                            fail,
                            queries,
                            offset,
                            limit,
                            random,
                            search,
                            sort,
                            success,
                            timeout,
                            verbose
                        }),
                        timeout     : timeout
                    });
                }
            }

            if( fail instanceof Function )
            {
                fail( response );
            }
        }
        else if( request )
        {
            if( fail instanceof Function )
            {
                fail( request );
            }
        }
        else if( fail instanceof Function )
        {
            fail( message );
        }
    });

    return canceler ;
}
