import bars       from './bars'
import buttons    from './buttons'
import calendars  from './calendars'
import containers from './containers'
import dialogs    from './dialogs'
import editors    from './editors'
import forms      from './forms'
import grids      from './grids'
import headers    from './headers'
import inputs     from './inputs'
import maps       from './maps'
import pagination from './pagination'
import snackbars  from './snackbars'
import trees      from './trees'
import upload     from './upload'

export default
{
    bars,
    buttons,
    calendars,
    containers,
    dialogs,
    editors,
    forms,
    grids,
    headers,
    inputs,
    maps,
    pagination,
    snackbars,
    trees,
    upload
};
