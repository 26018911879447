import React  from 'react'

import clsx from 'clsx'

import format from 'vegas-js-core/src/strings/fastformat'

import { Grid } from '@material-ui/core'

import TransitionGroup from '../../../transitions/TransitionGroup'

import OAuthThing        from '../../../display/things/application/OAuth'
import Metadatas         from '../../../display/things/Metadatas'
import ThingSubContainer from '../../../display/containers/ThingSubContainer'

const full =
{
    md : 12 ,
    sm : 12 ,
    xs : 12
};

class OAuth extends ThingSubContainer
{
    render = () =>
    {
        const { thing } = this.props ;
        if( thing )
        {
            const { id } = thing ;
            const { className , path , style } = this.props ;
            return (
                <div
                    className = { clsx( 'flex-1' , className ) }
                    style     = { style }
                >
                    <TransitionGroup
                        duration = { 250 }
                        enter    = {{ animation: "transition.slideUpIn" }}
                    >
                        <Metadatas
                            activable = { false }
                            thing     = { thing }
                            uri       = { format(path.application, id) }
                        />
                        <Grid container spacing={3}>
                            <Grid item {...full}>
                                <OAuthThing
                                    onChange = { this.changeProperty('oAuth' , true) }
                                    path     = { path }
                                    thing    = { thing }
                                />
                            </Grid>
                        </Grid>
                    </TransitionGroup>
                </div>
            );
        }
        return null;
    }
}

OAuth.defaultProps =
{
    ...ThingSubContainer.defaultProps
};

OAuth.propTypes =
{
    ...ThingSubContainer.propTypes
};

export default OAuth ;
