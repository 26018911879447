import getLocaleProperty from './getLocaleProperty'

/**
 * Returns the locale headline expression of the thing.
 * @param {Thing} thing - The thing reference.
 * @param {string} lang - The lang of the expression.
 * @param {string} defaultLang - The lang of the expression.
 * @return {string} The locale headline expression of the thing.
 */
const getLocaleHeadline = getLocaleProperty( 'headline' )

export default getLocaleHeadline ;
