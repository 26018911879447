import list from './list'

const livestocks =
{
    ...list,
    helmet :
    {
        title : 'Élevages' ,
        metas : []
    },
    empty  : 'Aucun élevage trouvé',
    search : 'Rechercher un élevage ...' ,
    title  : 'Élevages'
};

export default livestocks ;
