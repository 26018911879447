import Person       from './Person'
import Organization from './Organization'

const createAgent = ( thing ) =>
{
    if( thing )
    {
        const { url } = thing ;
        if( url.indexOf('people') > -1 )
        {
            return new Person( thing ) ;
        }
        else if( url.indexOf('organizations') > -1 )
        {
            return new Organization( thing ) ;
        }
    }
    return null ;
};

export default createAgent ;