import about from './application/about'

const application =
{
    // dependencies
    about ,

    // settings
    helmet :
    {
        title : 'Application - {0}' ,
        metas : []
    },
    loading  : "Chargement ..." ,
    notFound : "Application inconnue" ,
    tabs     :
    {
        about   : 'À propos',
        oauth   : 'OAuth' ,
        medias  : 'Médias',
        setting : 'Paramètres'
    },
    tooltips :
    {
        back : 'Toutes les applications'
    }
};

export default application ;
