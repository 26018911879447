const medias =
{
    tabs :
    {
        audios : 'Audios' ,
        photos : 'Photos',
        videos : 'Vidéos'
    }
};

export default medias ;
