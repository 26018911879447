import React from 'react'

import PropTypes from 'prop-types'

import withi18n from '../../contexts/i18n/withi18n'

import Person      from '../../things/Person'
import PersonLabel from '../labels/PersonLabel'

import getHorizontalElements from '../getHorizontalElements'

import { EventCell } from './EventCell'

class ObservationCell extends EventCell
{
    getContent = () => 
    {
        const { thing } = this.props;
        if ( thing ) 
        {
            const elements = [ 'period', 'eventStatus', 'owner' ] ;
            return getHorizontalElements( { elements, factory:this }  ) ;
        }
        return null;
    };
    
    getLocale = () => this.props.locale.display.cells.observation;
    
    getOwner = () =>
    {
        const { owner: visible } = this.props;
        if (!visible) 
        {
            return null;
        }

        const thing = this.getThing();
        if ( thing ) 
        {
            const { owner } = thing;
            if ( owner instanceof Person ) 
            {
                const locale = this.getLocale() ;
                const { owner : { title } = {} } = locale ;
                return <PersonLabel clickable={false} thing={owner} title={title} /> ;
            }
        }
        return null;
    }
}

ObservationCell.defaultProps =
{
    ...EventCell.defaultProps,
    owner           : true ,
    subtitleVariant : 'none' // name
};

ObservationCell.propTypes =
{
    ...EventCell.propTypes,
    owner : PropTypes.bool
};

export default withi18n(ObservationCell) ;
