export default
{
    elevation    : parseFloat(process.env.REACT_APP_MAP_ELEVATION),
    latitude     : parseFloat(process.env.REACT_APP_MAP_LATITUDE),
    longitude    : parseFloat(process.env.REACT_APP_MAP_LONGITUDE),
    maxElevation : 8900,
    maxLatitude  : 90,
    maxLongitude : 180,
    maxZoom      : 19 ,
    minElevation : -11500,
    minLatitude  : -90,
    minLongitude : -180,
    minZoom      : 1,
    zoom         : 18
};
