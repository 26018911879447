import React from 'react'

import compose  from 'vegas-js-core/src/functors/compose'
import isString from 'vegas-js-core/src/isString'
import leading  from 'vegas-js-core/src/numbers/leading'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import initApp from '../../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { GiChecklist } from 'react-icons/gi'

import { Button, IconButton, Tooltip } from '@material-ui/core'

import AddIcon        from '@material-ui/icons/AddCircle'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import ThingCardChildren, { styles } from '../ThingCardChildren'

import LabelItem from '../../../components/items/LabelItem'

import Answer       from '../../../things/Answer'
import Question     from '../../../things/Question'
import QuestionGame from '../../../things/game/QuestionGame'

import AnswerImage from './AnswerImage'
import AnswerView  from './Answer'

import generify from '../../../things/generify'

class Answers extends ThingCardChildren
{
    createChild = ( child /*, editable = false*/ ) =>
    {
        if( child )
        {
            const { uri } = this.props ;
            return (
            <div className='w-full flex flex-wrap pt-12'>
                <AnswerView
                    className  = 'w-full md:w-1/3 lg:w-1/2 xl:1/2'
                    itemPolicy = {{ description:false , bgcolor:false }}
                    thing      = { child }
                />
                <AnswerImage
                    className = 'w-full md:w-2/2 lg:w-1/2 xl:1/2'
                    onChange  = { this.change }
                    thing     = { child }
                    removeUri = { uri }
                    uri       = { uri }
                />
            </div>);
        }
        return null ;
    };

    getAddButton = ( disabled = false ) =>
    {
        if( this.isEditable() )
        {
            const {
                addIcon,
                addButtonProps
            } = this.props ;

            const locale = this.getLocale() ;

            let button = (
                <Button
                    disabled = { disabled }
                    onClick  = { () => this.openAddDialog() }
                    color    = 'secondary'
                    variant  = 'contained'
                    { ...addButtonProps }
                >
                    { addIcon }
                    { locale.labels.add }
                </Button>);

            const { tooltips } = locale ;
            if( tooltips && !disabled )
            {
                const { add } = tooltips ;
                if( isString( add ) )
                {
                    button = (
                        <Tooltip
                            title     = { add }
                            placement = 'top'
                        >
                            { button }
                        </Tooltip>);
                }
            }
            return button ;
        }
        return null ;
    };

    getBottom = () => this.getCustomCardWithAddButton() ;

    getCardIcon = ( child ) => this.getAvatar( child , { marginRight : 8 } ) ;

    getCardLabel = ( child , editable , index ) =>
    {
        const unknown = this.getLocale().unknown ;
        if( child instanceof Answer )
        {
            const { lang } = this.props ;

            let label = child.getLocaleAlternateName(lang) ;

            if( !isString(label) || label === '' )
            {
                label = unknown ;
            }

            if( isString(label) )
            {
                return leading(index+1) + ' - ' + ucFirst(label) ;
            }
        }

        return this.props.cardLabel || unknown ;
    };

    getCardSubLabel = child =>
    {
        if( child )
        {
            let { lang } = this.props;
            let description = child.getLocaleDescription(lang);
            if( isString(description) && (description !== '') )
            {
                return (
                    <LabelItem>
                        {  ucFirst(description) }
                    </LabelItem>
                );
            }
        }
        return null ;
    };

    getCustomOptions = () => (
        <IconButton onClick={ () => this.collapse() } >
            { this.state.expanded ? <ExpandLessIcon/> : <ExpandMoreIcon/> }
        </IconButton>
    );

    getEmpty = () => this.getCustomCardWithAddButton() ;

    getLocale = () => this.props.locale.things.game.answers ;

    // ---- change the default behavior with the PATCH mode

    onAdd    = this.change ;
    onEdit   = this.change ;
    onRemove = this.change ;

    // --------

    onCardClick = child  => () => this.openEditDialog( child ) ;

    prepare = item =>
    {
        let {
            id = null ,
            alternateName,
            bgcolor,
            color,
            config,
            correct,
            description,
            image,
            score,
            subjectOf:question
        } = item ;

        const { languages } = config || {} ;

        if( !isString(bgcolor) || bgcolor === '')
        {
            bgcolor = '' ;
        }
        else
        {
            bgcolor = bgcolor.toLowerCase();
        }

        if( !isString(color) || color === '')
        {
            color = '' ;
        }
        else
        {
            color = color.toLowerCase();
        }

        if( image )
        {
            image = generify(image,true) ;
        }

        if( alternateName && languages )
        {
            languages.forEach( lang =>
            {
                if( !isString(alternateName[lang]) )
                {
                    alternateName[lang] = '' ;
                }
            });
        }

        if( description && languages )
        {
            languages.forEach( lang =>
            {
                if( !isString(description[lang]) )
                {
                    description[lang] = '' ;
                }
            });
        }

        if( isString(score) )
        {
            score = parseInt(score);
        }

        if( isNaN(score) || (score < 0) )
        {
            score = 0 ;
        }

        if( question instanceof Question )
        {
            let {
                id:key,
                choices,
                subjectOf:game
            } = question ;

            let position = -1 ;

            if( choices instanceof Array )
            {
                position = choices.findIndex( element => element.id === id ) ;
            }
            else
            {
                choices = [] ;
            }

            const answer =
            {
                id ,
                alternateName,
                bgcolor,
                color,
                correct,
                description,
                image,
                score
            };

            if( position > -1 )
            {
                choices[position] = answer ;
            }
            else
            {
                choices = [ ...choices , answer ] ;
            }

            question.choices = choices ;

            question = question.toObject() ;

            if( game instanceof QuestionGame )
            {
                game = game.toObject();

                let { quest } = game ;

                let position = -1 ;

                if( quest instanceof Array )
                {
                    position = quest.findIndex( element => element.id === key ) ;
                    if( position > -1 )
                    {
                        quest[position] = question ;
                    }
                }

                quest = JSON.stringify(quest) ;

                return { quest };
            }
        }

        return null ;

    };

    removePrepare = item =>
    {
        let { thing:question } = this.props ;

        if( question instanceof Question )
        {
            let {
                id:key,
                subjectOf:game
            }
            = question ;

            if( (game instanceof QuestionGame) && (item instanceof Answer) )
            {
                const { id } = item ;

                game = game.toObject() ;

                let { quest } = game ;

                if( (quest instanceof Array) && (quest.length > 0) )
                {
                    let len = quest.length ;
                    for( let i = 0 ; i<len ; i++ )
                    {
                        let question = quest[i] ;
                        if( question )
                        {
                            if( question.id === key )
                            {
                                let { choices } = question ;
                                if( choices instanceof Array )
                                {
                                    choices = choices.filter( element => element.id !== id ) ;
                                }
                                question.choices = choices ;
                                break ;
                            }
                        }
                    }

                }

                quest = JSON.stringify(quest) ;

                return { quest };
            }
        }

        return null ;
    };
}

Answers.defaultProps =
{
    ...ThingCardChildren.defaultProps ,
    addIcon  : <AddIcon className='mr-16 self-center'/>,
    clazz    : Answer,
    badge    : true ,
    icon     : <GiChecklist size='2.5em'/>,
    itemGrid :
    {
        xl : 12 ,
        lg : 12 ,
        md : 12 ,
        sm : 12 ,
        xs : 12
    },
    member     : 'choices',
    optionMode : 'custom' ,
    useUUID    : true
};

Answers.propTypes =
{
    ...ThingCardChildren.propTypes
};

export default compose( withStyles( styles ) , withRouter , initApp )( Answers ) ;
