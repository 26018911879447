import initDialog from '../../../../../dialogs/initDialog'

import { AddDialog } from '../../../../../dialogs/AddDialog'

import prepareVaccinationEvent from './prepare/prepareVaccinationEvent'

const styles = () => ({});

export class AddVaccinationEventDialog extends AddDialog
{
    getLocale = () => this.props.locale.things.livestock.reviews.abortionSerie.dialogs.vaccinationEvent.add ;
}

AddVaccinationEventDialog.defaultProps =
{
    ...AddDialog.defaultProps,
    member  : 'vaccinations' ,
    prepare : prepareVaccinationEvent
};

AddVaccinationEventDialog.propTypes =
{
    ...AddDialog.propTypes
};

export default initDialog({ styles })( AddVaccinationEventDialog ) ;
