import React from 'react'

import clsx from 'clsx'

import PropTypes from 'prop-types'

import { Avatar } from '@material-ui/core'

import CalendarIcon from '@material-ui/icons/CalendarToday'
import LockIcon     from '@material-ui/icons/Lock'

import ThingContainer from '../../containers/ThingContainer'

import OpeningHoursSpecification from '../../../things/OpeningHoursSpecification'

class HourSpecificationIcon extends ThingContainer
{
    render = () =>
    {
        const {
            avatar,
            className,
            style,
            thing
        } = this.props ;
        if( thing instanceof OpeningHoursSpecification )
        {
            let icon ;

            let {
                closes,
                dayOfWeek,
                opens ,
            } = thing ;

            if( (dayOfWeek === null) && (opens === '00:00') && (closes === '00:00') )
            {
                icon = <LockIcon className="text-red-700"/> ;
            }
            else
            {
                if( opens && closes )
                {
                    opens  = opens.split(',') ;
                    closes = closes.split(',') ;
                    const len1 = opens.length ;
                    const len2 = closes.length ;
                    if( len1 > 0 && len1 === len2 )
                    {
                        if( len1 === 1 && (opens[0] === '00:00') && (closes[0] === opens[0]) )
                        {
                            icon = <LockIcon className="text-red-700"/> ;
                        }
                        else
                        {
                            icon = <CalendarIcon className="text-grey-800"/> ;
                        }
                    }
                }
            }

            if( icon )
            {
                if( avatar )
                {
                    return (
                    <Avatar
                        className = { clsx( 'mr-16 self-center bg-grey-400' , className ) }
                        style     = { style }
                        variant   = "rounded"
                    >
                        { icon }
                    </Avatar>) ;
                }
                else
                {
                    return (
                    <div
                        className = { clsx( 'pr-16 self-center' , className ) }
                        style     = { style }
                    >
                        {icon}
                    </div>);
                }
            }
        }

        return null ;
    };
}

HourSpecificationIcon.defaultProps =
{
    ...ThingContainer.defaultProps,
    avatar : false
};

HourSpecificationIcon.propTypes =
{
    ...ThingContainer.propTypes,
    avatar : PropTypes.bool
};

export default HourSpecificationIcon ;
