import Form from '../../../display/forms/FormElements'

import Livestock    from '../../../things/Livestock'
import Organization from '../../../things/Organization'
import Thing        from '../../../things/Thing'

import api from '../../../configs/api'

import events        from './livestock/events'
import medical       from './livestock/medical'
import reviews       from './livestock/reviews'
import technicians   from './livestock/technicians'
import veterinarians from './livestock/veterinarians'
import workshops     from './livestock/workshops'

const elements =
[
    {
        type       : Form.SEARCH_SELECTOR ,
        clazz      : Organization,
        path       : api.organizations.url ,
        clearLabel : 'Vider' ,
        closeLabel : 'Fermer' ,
        init       :
        {
            id         : 'organization',
            key        : 'organization',
            label      : 'Organisation',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            required   : true,
            type       : 'search',
            variant    : 'outlined'
        },
        inputProps :
        {
            label : 'Organisation...'
        },
        limit  : 0,
        logics :
        {
            disabledSuggestions : ( props ) =>
            {
                const { rel } = props ;
                let suggestions = [] ;
                if( rel instanceof Thing )
                {
                    const { organization } = rel ;
                    if( organization instanceof Organization )
                    {
                        suggestions.push( organization ) ;
                    }
                }
                return suggestions ;
            }
        }
    }
];

const prepare = ( item ) =>
{
    let {
        organization,
    } = item ;
    
    return (
    {
        organization : organization instanceof Thing
                     ? String(organization.id)
                     : null
    });
};

const livestock =
{
    // dependencies
    
    events,
    medical,
    reviews,
    technicians,
    veterinarians,
    workshops,
    
    // settings
    
    edit  :
    {
        agree       : 'Modifier' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour modifier cet élevage.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...' ,
        success     : 'Mise à jour réussie!' ,
        title       : 'Modifier l\'élevage' ,
        elements,
        prepare
    },
    labels :
    {
        empty : '----' ,
        name  : 'Nom'
    },
    remove :
    {
        agree        : 'Supprimer' ,
        description  : 'Souhaitez-vous supprimer l\'élevage:' ,
        disagree     : 'Annuler' ,
        item         :
        {
            text :
            {
                primary : ( element , props ) =>
                {
                    if( element instanceof Livestock )
                    {
                        const { organization } = element ;
                        if( organization instanceof Thing )
                        {
                            return organization.getLocaleName(props.lang);
                        }
                    }
                    return 'Inconnu' ;
                }
            }
        },
        loading : 'Suppression en cours...' ,
        title   : 'Supprimer cet élevage ?' ,
        useID   : false
    },
    title    : 'Élevage' ,
    tooltips :
    {
        add    : 'Ajouter',
        edit   : 'Modifier',
        remove : 'Supprimer'
    }
};

export default livestock ;