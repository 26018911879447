import api   from '../../../../../../configs/api'
import Form  from '../../../../../../display/forms/FormElements'
import Thing from '../../../../../../things/Thing'

import Method from '../../../../../../net/Method'

const events =
{
    add :
    {
        agree       : 'Ajouter' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez sélectionner un événement en cliquant ci-dessous.' ,
        elements    :
        [
            {
                type       : Form.SEARCH_SELECTOR ,
                path       : api.events.from ,
                clearLabel : 'Vider' ,
                closeLabel : 'Fermer' ,
                init       :
                {
                    id        : 'value',
                    key       : 'value',
                    label     : 'événement',
                    autoFocus : false,
                    fullWidth : true,
                    margin    : 'normal',
                    required  : true,
                    type      : 'search',
                    variant   : 'outlined'
                },
                inputProps :
                {
                    label : 'événement...'
                },
                logics :
                {
                    disabledSuggestions : ( props ) =>
                    {
                        const { rel } = props ;
                        if( rel instanceof Thing )
                        {
                            const { subjectOf } = rel ;
                            if( subjectOf )
                            {
                                const { events } = subjectOf ;
                                if( events instanceof Array )
                                {
                                    return events ;
                                }
                            }
                        }
                        return null ;
                    }
                }
            }
        ],
        fail    : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading : 'Chargement en  cours...' ,
        method  : Method.PATCH ,
        success : 'Ajout du nouvel événement réussi!' ,
        title   : 'Ajouter un événement'
    },
    remove :
    {
        agree       : 'Supprimer' ,
        description : 'Souhaitez-vous supprimer cet événement:' ,
        disagree    : 'Annuler' ,
        loading     : 'Suppression en cours...' ,
        method      : Method.PATCH  ,
        mapUri      : ( item , uri ) => uri ,
        title       : 'Supprimer l\'événement ?'
    },
    title : 'événements mis en avant' ,
    tooltips :
    {
        add    : "Ajouter" ,
        edit   : "Éditer" ,
        remove : "Supprimer"
    }
};

export default events ;
