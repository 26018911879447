import getRoutes   from './getRoutes'
import getSettings from './getSettings'

import Me                from '../views/me/Me'
import Applications      from '../views/applications/Applications'
import Application       from '../views/application/Application'
import Articles          from '../views/articles/Articles'
import ConceptualObjects from '../views/conceptualObjects/ConceptualObjects'
import Courses           from '../views/courses/Courses'
import Diseases          from '../views/medical/diseases/Diseases'
import Events            from '../views/events/Events'
import Game              from '../views/game/Game'
import Games             from '../views/games/Games'
import Home              from '../views/home/Home'
import Livestock         from '../views/livestock/Livestock'
import Livestocks        from '../views/livestocks/Livestocks'
import MediaObjects      from '../views/mediaObjects/MediaObjects'
import Observation       from '../views/observation/Observation'
import Organizations     from '../views/organizations/Organizations'
import People            from '../views/people/People'
import Places            from '../views/places/Places'
import Register          from '../views/auth/Register'
import Set               from '../views/settings/Set'
import Settings          from '../views/settings/Settings'
import Stages            from '../views/stages/Stages'
import Team              from '../views/team/Team'
import Teams             from '../views/teams/Teams'
import Technician        from '../views/technician/Technician'
import Technicians       from '../views/technicians/Technicians'
import Thesauri          from '../views/thesauri/Thesauri'
import Thesaurus         from '../views/thesaurus/Thesaurus'
import Token             from '../views/auth/Token'
import User              from '../views/user/User'
import Users             from '../views/users/Users'
import Veterinarian      from '../views/veterinarian/Veterinarian'
import Veterinarians     from '../views/veterinarians/Veterinarians'
import Workshop          from '../views/workshop/Workshop'
import Welcome           from '../views/welcome/Welcome'

const slate    = getSettings({ theme : { main:'slate'     } }) ; // home
const settings = getSettings({ theme : { main:'sweetHues' } }) ; // default
const simple   = getSettings(
{
    theme   : { main    : 'defaultDark' } , // welcome, me, token
    footer  : { display : false } ,
    navbar  : { display : false } ,
    toolbar : { display : false }
});

export const defaultRoutes =
[
    // ------ account
    {
        routes : [ { path : '/me', component : Me } ] ,
        settings
    },

    // ------- applications
    {
        routes :
        [
            { path : '/applications/'    , component : Applications , exact : true } ,
            { path : '/applications/:id' , component : Application  }
        ],
        settings
    },

    // ------- articles
    {
        routes : [ { path : '/articles/' , component : Articles } ],
        settings
    },

    // ------- conceptualObjects
    {
        routes : [ { path : '/conceptualObjects/' , component : ConceptualObjects } ],
        settings
    },

    // ------- courses
    {
        routes : [ { path : '/courses/' , component : Courses } ],
        settings
    },

    // ------- diseases
    {
        routes : [ { path : '/diseases/' , component : Diseases } ],
        settings
    },

    // ------- events
    {
        routes : [ { path : '/events/' , component : Events } ],
        settings
    },

    // ------- games
    {
        routes :
        [
            { path : '/games/'              , component : Games , exact : true },
            { path : '/games/courses/:id'   , component : Game },
            { path : '/games/questions/:id' , component : Game },
            { path : '/games/:id'           , component : Game }
        ],
        settings
    },

    // ------- home
    {
        routes : [ { path : '/home' , component : Home } ] ,
        settings : slate
    },

    // ------- livestocks
    {
        routes :
        [
            { path : '/livestocks/'                 , component : Livestocks , exact : true } ,
            { path : '/livestocks/observations/:id' , component : Observation } ,
            { path : '/livestocks/workshops/:id'    , component : Workshop    } ,
            { path : '/livestocks/:id'              , component : Livestock   }
        ],
        settings
    },

    // ------- register
    {
        routes : [ { path : '/register', component : Register } ] ,
        settings : simple
    },

    // ------- mediaObjects
    {
        routes : [ { path : '/mediaObjects/' , component:MediaObjects , isExact:true } ] ,
        settings
    },

    // ------- organizations
    {
        routes : [ { path : '/organizations/' , component : Organizations } ],
        settings
    },

    // ------- people
    {
        routes : [ { path : '/people/' , component : People } ],
        settings
    },

    // ------- places
    {
        routes : [ { path : '/places/' , component : Places } ],
        settings
    },

    // ------- settings
    {
        routes :
        [
            { path : '/settings/'    , component : Settings  , exact : true } ,
            { path : '/settings/:id' , component : Set }
        ],
        settings
    },

    // ------- stages
    {
        routes :
        [
            { path : '/stages/' , component : Stages }
        ],
        settings
    },

    // ------- teams
    {
        routes :
        [
            { path : '/teams/'    , component : Teams , exact : true } ,
            { path : '/teams/:id' , component : Team  }
        ],
        settings
    },

    // ------- technicians
    {
        routes :
        [
            { path : '/technicians'     , component : Technicians , exact : true } ,
            { path : '/technicians/:id' , component : Technician  }
        ],
        settings
    },

    // ------- thesaurus
    {
        routes :
        [
            { path : '/thesaurus/'    , component : Thesauri  , exact : true } ,
            { path : '/thesaurus/:id' , component : Thesaurus }
        ],
        settings
    },

    // ------- token
    {
        routes : [ { path : '/redirect', component : Token } ] ,
        settings : simple
    },

    // ------- users
    {
        routes :
        [
            { path : '/users/'    , component : Users , exact : true } ,
            { path : '/users/:id' , component : User  }
        ] ,
        settings
    },

    // ------- veterinarians
    {
        routes :
        [
            { path : '/veterinarians/'    , component : Veterinarians , exact : true } ,
            { path : '/veterinarians/:id' , component : Veterinarian  }
        ],
        settings
    },

    // ------- welcome
    {
        routes : [ { path : '/welcome', component : Welcome } ] ,
        settings : simple
    }
];


export default getRoutes( { routes:defaultRoutes } ) ;
