import React from 'react'

import LocaleConsumer from './LocaleConsumer'

export const withLocale = Component => React.forwardRef( ( props , ref ) =>
(
    <LocaleConsumer>
        { store => <Component {...props} {...store} ref={ref} /> }
    </LocaleConsumer>
)) ;

export default withLocale ;
