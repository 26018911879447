const birth =
{
    copy :
    {
        snack   : 'Date de naissance copiée dans le presse-papiers.' ,
        tooltip : 'Copier la date de naissance'
    },
    date      : '{0}' ,
    pattern   : 'DD MMMM YYYY' ,
    place     : '{0}' ,
    separator : ' - ' ,
    title     : 'Naissance'
};

export default birth ;
