import React , { Component } from 'react'

import compose from 'vegas-js-core/src/functors/compose'
import get     from 'vegas-js-core/src/objects/get'

import cloneDeep from "lodash/cloneDeep"
import merge     from 'lodash/merge'

import PropTypes from 'prop-types'

import { withRouter } from 'react-router-dom'

import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'

import SettingsContext from './SettingsContext'

import generate from '../../display/navigation/generate'

import defaultProperties from './defaultProperties'
import variants          from '../../styles/theme/variants'
import defaultOptions    from "../../styles/theme/defaultOptions"
import mustHaveOptions   from "../../styles/theme/mustHaveOptions"

import withConfig from "../config/withConfig"
import withLocale from "../i18n/withLocale"
import withUser   from "../user/withUser"

import customMixins from '../../styles/theme/customMixins'

const { Provider } = SettingsContext ;

class SettingsProvider extends Component
{
    constructor( props )
    {
        super( props );

        this.scope = null ;

        let {
            defaultSettings = {} ,
            permissions     = {} ,
            themePath       = 'theme.main',
            themes          = {}
        }
        = props ;

        this.state =
        {
            ...defaultProperties,
            themes : this.updateThemes( themes )
        };

        let name = get( defaultSettings , themePath ) ;
        const { currentTheme, darkTheme, lightTheme } = this.updateTheme( name ) ;

        let navigation = this.updateNavigation( permissions ) ;

        this.state =
        {
            ...this.state,

            defaultSettings ,

            navigation,
            permissions,
            settings : defaultSettings ,

            currentTheme,
            darkTheme,
            lightTheme,

            navbarCloseFolded  : this.navbarCloseFolded,
            navbarCloseMobile  : this.navbarCloseMobile,
            navbarOpenFolded   : this.navbarOpenFolded,
            navbarOpenMobile   : this.navbarOpenMobile,
            navbarToggleFolded : this.navbarToggleFolded,
            navbarToggleMobile : this.navbarToggleMobile,

            resetRoles         : this.resetRoles,
            resetSettings      : this.resetSettings,

            setDefaultSettings : this.setDefaultSettings,
            setNavigation      : this.setNavigation,
            setRoles           : this.setRoles,
            setSettings        : this.setSettings
        };
    }

    componentDidUpdate( prevProps , prevState )
    {
        let change = null ;

        const { defaultSettings } = this.props ;
        if( prevProps.defaultSettings !== defaultSettings )
        {
            change = { ...change , defaultSettings }  ;
        }

        const { settings } = this.state;
        if( settings )
        {
            const currentThemeName = get( settings , this.props.path ) ;
            if( prevState.currentThemeName !== currentThemeName )
            {
                const {
                    currentTheme,
                    darkTheme,
                    lightTheme
                } = this.updateTheme( currentThemeName ) ;

                change = {
                    ...change ,
                    currentTheme,
                    currentThemeName,
                    darkTheme ,
                    lightTheme
                } ;
            }
        }

        const { user } = this.props ;
        if( user && (user.scope !== this.scope) )
        {
            const { locale } = this.props ;
            if( locale )
            {
                change = { ...change , navigation:this.updateNavigation() }  ;
            }
        }

        if( change )
        {
            this.setState({ ...change });
        }
    }

    getCurrentTheme = ( name , defaultTheme = 'default' ) =>
    {
        const { themes } = this.state ;
        if( themes )
        {
            return themes[ themes.hasOwnProperty(name) ? name : defaultTheme ] ;
        }
        return null ;
    };

    navbarCloseFolded = () =>
    {
        this.setState({ navbarFolded:false }) ;
    };

    navbarCloseMobile = () =>
    {
        this.setState({ navbarMobileOpen:false }) ;
    };

    navbarOpenFolded = () =>
    {
        this.setState({ navbarFolded:true }) ;
    };

    navbarOpenMobile = () =>
    {
        this.setState({ navbarMobileOpen:true }) ;
    };

    navbarToggleFolded = () =>
    {
        const { navbarFolded } = this.state ;
        this.setState({ navbarFolded:!navbarFolded }) ;
    };

    navbarToggleMobile = () =>
    {
        const { navbarFolded } = this.state ;
        this.setState({ navbarFolded:!navbarFolded }) ;
    };

    render()
    {
        const { currentTheme } = this.state ;
        return (
        <Provider value={ this.state }>
            {
                currentTheme &&
                <ThemeProvider theme={ currentTheme } >
                    { this.props.children}
                </ThemeProvider>
            }
        </Provider>);
    }

    resetPermissions = () =>
    {
        const { permissions } = this.props ;
        this.setState({ permissions });
    };

    resetSettings = () =>
    {
        const { defaultSettings } = this.props  ;
        this.setState({ settings:defaultSettings }) ;
    };

    setNavigation = navigation =>
    {
        this.setState({ navigation });
    };

    setDefaultSettings = settings =>
    {
        this.setState({ defaultSettings:settings });
    };

    setPermissions = permissions =>
    {
        this.setState({ permissions } );
    };

    setSettings = init =>
    {
        let { defaultSettings } = this.state ;
        const settings = merge( defaultSettings , init ) ;
        this.setState({ settings } );
    };

    updateNavigation = ( permissions = null ) =>
    {
        let { locale } = this.props ;
        if( locale )
        {
            let { user } = this.props ;
            if( user )
            {
                permissions = permissions || this.state.permissions ;
                this.scope = user.scope ;
                let { navigation:nav } = locale ;
                return generate(cloneDeep(nav),user,permissions) ;
            }
        }

        return null ;
    };

    updateTheme = name =>
    {
        let currentTheme = this.getCurrentTheme( name ) ;
        let darkTheme    = null ;
        let lightTheme   = null ;

        let { themes } = this.props ;
        if( themes && themes.hasOwnProperty(name) )
        {
            const theme = themes[name] ;
            if( theme )
            {
                darkTheme = createMuiTheme( merge(
                    {}, defaultOptions , theme, { palette : {
                        type : 'dark',
                        text :
                        {
                            primary   : "rgba(255, 255, 255, 0.87)",
                            secondary : "rgba(255, 255, 255, 0.54)",
                            disabled  : "rgba(255, 255, 255, 0.38)",
                            hint      : "rgba(255, 255, 255, 0.38)"
                        }
                    } , ...mustHaveOptions }
                )) ;
                lightTheme  = createMuiTheme( merge(
                    {}, defaultOptions , theme, { palette : {
                        type : 'light',
                        text :
                        {
                            primary   : "rgba(0, 0, 0, 0.87)",
                            secondary : "rgba(0, 0, 0, 0.54)",
                            disabled  : "rgba(0, 0, 0, 0.38)",
                            hint      : "rgba(0, 0, 0, 0.38)"
                        }
                    } , ...mustHaveOptions }
                )) ;
            }
        }

        return { currentTheme, darkTheme, lightTheme } ;
    };

    updateThemes = init =>
    {
        const { config } = this.props ;
        const { assets } = config ;
        let themes = {} ;
        let settings = Object.entries(init) ;
        settings.forEach(( [ key , value ] ) =>
        {
            let theme = merge( {} , defaultOptions, value, mustHaveOptions );
            themes = {
                ...themes ,
                ...{ [key] : createMuiTheme( merge( {} , theme , { assets , mixins : customMixins(theme) } ) ) }
            } ;
        });
        return themes ;
    }
}

SettingsProvider.defaultProps =
{
    defaultSettings : {} ,
    path            : 'theme.main',
    permissions     : {},
    themes          : {}
};

SettingsProvider.propTypes =
{
    defaultSettings : PropTypes.shape(
    {
        layout : PropTypes.shape(
        {
            config : PropTypes.shape(
            {
                footer : PropTypes.shape(
                {
                    display  : PropTypes.bool,
                    position : PropTypes.oneOf(['above','below']),
                    style    : PropTypes.oneOf(['fixed',null])
                }),
                mode   : PropTypes.oneOf(['fullwidth',null]),
                navbar : PropTypes.shape(
                {
                    display  : PropTypes.bool,
                    folded   : PropTypes.bool,
                    position : PropTypes.oneOf(['left','right']),
                }),
                scroll  : PropTypes.oneOf(['body','content']),
                toolbar : PropTypes.shape(
                {
                    display  : PropTypes.bool,
                    position : PropTypes.oneOf(['above','below']),
                    style    : PropTypes.oneOf(['fixed',null])
                })
            })
        }),
        theme : PropTypes.shape(
        {
            footer : PropTypes.oneOf(['dark','light']),
            main   : PropTypes.oneOf( variants ),
            navbar : PropTypes.oneOf(['dark','light']) ,
            toolbar: PropTypes.oneOf(['dark','light'])
        })
    }),
    permissions : PropTypes.object,
    themePath   : PropTypes.string,
    themes      : PropTypes.object
};

export default compose( withRouter, withConfig, withLocale, withUser )( SettingsProvider ) ;
