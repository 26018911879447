import React from 'react'

import PropTypes from 'prop-types'

import URLSearchParams from '@ungap/url-search-params'

import View from './View'

class SearchView extends View
{
    build = () =>
    {
        return (
            <div className='flex flex-col flex-1 w-full'>
                { this.getHelmet() }
                <div className="p-8 md:p-12 lg:p-16 flex flex-1">
                    { this.getContent() }
                </div>
            </div>
        );
    }

    getContent = () => null ;

    init = () =>
    {
        const { location, setSearch } = this.props ;

        if( setSearch )
        {
            const params = new URLSearchParams(location.search) ;
            const value  = params.get('search') ;

            let label ;
            const locale = this.getLocale() ;
            if( locale )
            {
                const { search:txt} = locale ;
                label = txt ;
            }
            setSearch(
            {
                search      : value,
                searchable  : true,
                searchBar   : !!(value),
                searchLabel : label
            }) ;
        }
    }

    unmount = () =>
    {
        const { resetSearch } = this.props ;
        if( resetSearch )
        {
            resetSearch() ;
        }
    }
}

SearchView.propTypes =
{
    ...View.propTypes,
    search    : PropTypes.string,
    setSearch : PropTypes.func
};

export default SearchView ;
