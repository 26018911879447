import ambienceReview       from './reviews/ambienceReview'
import sectorReview         from './reviews/sectorReview'
import waterTreatmentReview from './reviews/waterTreatmentReview'
import workplaceReview      from './reviews/workplaceReview'

const reviews =
{
    ambienceReview,
    sectorReview,
    waterTreatmentReview,
    workplaceReview
};

export default reviews ;