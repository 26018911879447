import React from 'react'

import Form from '../../../display/forms/FormElements'

import Method from "../../../net/Method"

import NumberInput from '../../../components/inputs/NumberInput'

import map from '../../../configs/map'

const {
    elevation,
    latitude,
    longitude,
    maxElevation,
    maxLatitude,
    maxLongitude,
    minElevation,
    minLatitude,
    minLongitude,
    zoom
}
= map ;

const elements =
[
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'latitude',
            key          : 'latitude',
            label        : 'Latitude',
            autoFocus    : false,
            fullWidth    : true,
            InputProps   : {
                inputComponent : ( input ) =>
                (
                    <NumberInput
                        allowNegative = {true}
                        floatCount    = { 7 }
                        maximum       = {maxLatitude}
                        minimum       = {minLatitude}
                        {...input}
                    />
                )
            },
            margin       : 'normal',
            placeholder  : `Ex: ${latitude}`,
            required     : false,
            select       : false,
            autoComplete : 'off' ,
            type         : 'text',
            variant      : 'outlined'
        }
    },
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'longitude',
            key          : 'longitude',
            label        : 'Longitude',
            autoFocus    : false,
            fullWidth    : true,
            InputProps   : {
                inputComponent : input =>
                (
                    <NumberInput
                        allowNegative = { true }
                        floatCount    = { 7 }
                        maximum       = { maxLongitude }
                        minimum       = { minLongitude }
                        {...input}
                    />
                )
            },
            margin       : 'normal',
            placeholder  : `Ex: ${longitude}`,
            required     : false,
            select       : false,
            autoComplete : 'off' ,
            type         : 'text',
            variant      : 'outlined'
        }
    },
    {
        type  : Form.MAP_GEO ,
        label : '...',
        init  :
        {
            id             : 'map_geo_editor',
            key            : 'map_geo_editor',
            fullscreenMode : true,
            style          :
            {
                borderRadius : 10,
                height       : 220,
                marginTop    : 8,
                marginBottom : 8
            },
            zoom
        }
    },
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'elevation',
            key          : 'elevation',
            label        : 'Élévation',
            autoFocus    : false,
            fullWidth    : true,
            InputProps   : {
                inputComponent : ( input ) =>
                (
                    <NumberInput
                        allowNegative = {true}
                        maximum       = {maxElevation}
                        minimum       = {minElevation}
                        suffix        = {' m'}
                        {...input}
                    />
                )
            } ,
            margin       : 'normal',
            placeholder  : `Ex: ${elevation} m`,
            required     : false,
            select       : false,
            autoComplete : 'off' ,
            type         : 'text',
            variant      : 'outlined'
        }
    }
];

const geo =
{
    edit :
    {
        agree       : 'Sauver' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour modifier les coordonnées GPS de ce lieu.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...' ,
        method      : Method.PATCH ,
        success     : 'Mise à jour réussie!' ,
        title       : 'Modifier les coordonnées GPS',
        elements
    },
    labels :
    {
        elevation        : 'Élévation' ,
        elevationPattern : '{0} m',
        empty            : '----' ,
        longitude        : 'Longitude' ,
        latitude         : 'Latitude'
    },
    title    : 'Localisation' ,
    tooltips :
    {
        edit : 'Modifier'
    }
};

export default geo ;
