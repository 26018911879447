import discover from '../stage/discover'

const stage =
{
    discover,
    first : 'discover' , // important
    tabs  :
    [
        {
            label : 'Infos' ,
            value : 'infos'
        },
        {
            label : 'Découvrir' ,
            value : 'discover' ,
            badge : props =>
            {
                const { thing : { stage : { discover } = {} } = {} } = props ;
                if( discover instanceof Array )
                {
                    return discover.length ;
                }
                return 0 ;
            }
        }
    ],
};

export default stage ;
