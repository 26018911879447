import React from 'react'

import clsx from 'clsx'

import format       from 'vegas-js-core/src/strings/format'
import generateUUID from 'vegas-js-core/src/random/generateUUID'
import isString     from 'vegas-js-core/src/isString'
import ucFirst      from 'vegas-js-core/src/strings/ucFirst'

import moment from 'moment/moment'

import PropTypes from 'prop-types'

import { Redirect } from "react-router-dom"

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { FaBarcode } from 'react-icons/fa'

import { Button, CircularProgress, Grid, Paper, Typography, Tooltip } from '@material-ui/core'

import AddIcon      from '@material-ui/icons/AddCircle'
import EventIcon    from '@material-ui/icons/Event'
import ScheduleIcon from '@material-ui/icons/Schedule'
import TodayIcon    from '@material-ui/icons/CalendarToday'

import withDialogs from '../../../../../../contexts/dialogs/withDialogs'
import withi18n    from '../../../../../../contexts/i18n/withi18n'
import withSnack   from '../../../../../../contexts/snack/withSnack'

import api from '../../../../../../configs/api'

import GET   from '../../../../../../net/GET'
import PATCH from '../../../../../../net/PATCH'

import LabelItem      from '../../../../../../components/items/LabelItem'
import ThingContainer from '../../../../../containers/ThingContainer'
import TitleHeader    from '../../../../../../components/headers/TitleHeader'
import RequestStatus  from '../../../../../../net/RequestStatus'
import SnackVariant   from '../../../../../../components/snackbars/SnackVariant'

import AbortionEventVO from '../../../../../../things/livestock/events/AbortionEvent'
import AbortionReview  from '../../../../../../things/livestock/reviews/AbortionReview'
import Disease         from '../../../../../../things/medical/Disease'
import MedicalAnalysis from '../../../../../../things/medical/MedicalAnalysis'
import MedicalMethod   from '../../../../../../things/medical/MedicalMethod'
import MedicalSampling from '../../../../../../things/medical/MedicalSampling'
import Word            from '../../../../../../things/Word'

import Analyses from '../medical/Analyses'

const half =
{
    md : 4 ,
    sm : 6 ,
    xs : 12
};

const styles = () => (
{
    progress :
    {

    },
    root :
    {
        width : '100%'
    }
});

class AbortionEvent extends ThingContainer
{
    constructor( props )
    {
        super( props ) ;

        this.canceler = null ;

        this.state =
        {
            ...this.state ,
            status : RequestStatus.NEW
        }
    }

    create = () =>
    {
        const { thing } = this.props ;
        if( thing )
        {
            const { additionalType } = thing ;
            if( additionalType )
            {
                const { id } = additionalType ;
                if( id )
                {
                    let { uri } = this.props ;
                    if( isString( uri ) )
                    {
                        const { status } = this.state ;
                        uri = api.url + format( uri , id ) ;
                        if( status !== RequestStatus.PROGRESS )
                        {
                            this.setState({ status:RequestStatus.PROGRESS }) ;
                            this.canceler = GET( uri , {
                                cancel  : this._cancel,
                                fail    : this._fail,
                                queries : { active : false } ,
                                random  : true ,
                                success : this._createSuccess
                            } );
                        }
                    }
                }
            }
        }
    };

    editPrepare = ( item ) =>
    {
        if( item )
        {
            let {
                identifier,
                date,
                period,
                subjectOf:observation,
            } = item ;

            identifier = isString(identifier) ? identifier : null ;

            date = date instanceof Date
                 ? moment(date).toISOString()
                 : null ;

            if( observation )
            {
                let { review } = observation ;
                if( review instanceof AbortionReview )
                {
                    const { event } = review ;
                    if( event )
                    {
                        event.identifier = identifier;
                        event.date       = date ;
                        event.period     = period ;
                    }
                    review = review.toObject() ;

                    // console.log( this + ' editPrepare' , review ) ;

                    return { review:JSON.stringify(review) } ;
                }
            }
        }
        return null ;
    };

    getAddButton = () =>
    {
        if( this.isEditable() )
        {
            const locale = this.getLocale() ;
            if( locale )
            {
                const { labels, tooltips } = locale ;
                const { addIcon, addButtonProps } = this.props ;
                let button = (
                <Button
                    className = 'px-16'
                    color     = 'secondary'
                    variant   = 'contained'
                    { ...addButtonProps }
                    onClick = { this.create }
                >
                    { addIcon }
                    { labels.add }
                </Button>);

                if( tooltips )
                {
                    const { add } = tooltips ;
                    if( isString(add) )
                    {
                        button = (
                        <Tooltip
                            placement = 'top'
                            title     = { add }
                        >
                            { button }
                        </Tooltip>);
                    }
                }

                return button ;
            }
            else
            {
                console.log( this + ' getAddButton failed, the locale object not must be null.');
            }
        }
        return null ;
    };

    getContent = () =>
    {
        const { thing } = this.props ;
        if( thing )
        {
            const { review } = thing ;
            if( review instanceof AbortionReview )
            {
                const { event } = review ;
                if( event instanceof AbortionEventVO )
                {
                    const locale = this.getLocale() ;

                    if( locale )
                    {
                        const {
                            datePattern,
                            labels
                        } = locale ;

                        const {
                            identifierIcon,
                            lang,
                            periodIcon,
                            scheduleIcon
                        }
                        = this.props  ;

                        let {
                            identifier,
                            date,
                            period
                        }
                        = event ;

                        if( isString( date ) )
                        {
                            date = moment(date).format(datePattern) ;
                        }

                        if( period instanceof Word )
                        {
                            period = period.getLocaleName( lang ) ;
                        }

                        return(
                        <div className='py-16'>
                            <Grid container spacing={4}>

                                <Grid item {...half}>
                                    <LabelItem
                                        divider   = { false }
                                        enabled   = {!!(identifier)}
                                        labelIcon = { identifierIcon }
                                        title     = { labels.identifier }
                                    >
                                        { isString(identifier) && identifier !== '' ? identifier : labels.empty }
                                    </LabelItem>
                                </Grid>

                                <Grid item {...half}>
                                    <LabelItem
                                        divider   = { false }
                                        enabled   = {!!(date)}
                                        labelIcon = { scheduleIcon }
                                        title     = { labels.date }
                                    >
                                        { isString(date) && date !== '' ? ucFirst(date) : labels.empty }
                                    </LabelItem>
                                </Grid>

                                <Grid item {...half}>
                                    <LabelItem
                                        divider   = { false }
                                        enabled   = {!!(period)}
                                        labelIcon = { periodIcon }
                                        title     = { labels.period }
                                    >
                                        { isString(period) && period !== '' ? ucFirst(period) : labels.empty }
                                    </LabelItem>
                                </Grid>
                            </Grid>

                            <Analyses
                                className      = 'mt-20'
                                containerProps = {{ className : "bg-transparent w-full shadow-none py-12" }}
                                grid           = {{ xs:12 , sm:6 , lg:4 }}
                                gridSpacing    = { 8 }
                                onChange       = { this.change }
                                subjectOf      = { event }
                                thing          = { event }
                            />
                        </div>);
                    }
                }
            }
        }
        return null ;
    };

    getEditButtonThing = () =>
    {
        const { thing } = this.props ;
        if( thing )
        {
            const { review } = thing ;
            if( review instanceof AbortionReview )
            {
                return review ;
            }
        }
        return null ;
    };

    getEntry = () =>
    {
        const { thing } = this.props ;
        if( thing )
        {
            const { review } = thing ;
            if( review instanceof AbortionReview )
            {
                let { event } = review ;
                if( event )
                {
                    event = new AbortionEventVO( event.toObject() ) ;
                    event.subjectOf = thing ;
                    return event ;
                }
            }
        }
        return null ;
    };

    getLocale = () => this.props.locale.things.livestock.reviews.abortion.events.abortionEvent ;

    notifyFail = () =>
    {
        const locale = this.getLocale() ;
        if( locale )
        {
            const { snack } = locale ;
            if( snack )
            {
                const { fail } = snack ;
                if( fail )
                {
                    this.notify(fail, SnackVariant.WARNING);
                }
            }
        }
    };

    notify = ( message , variant=SnackVariant.DEFAULT ) =>
    {
        const { notifySnack } = this.props ;
        if( notifySnack )
        {
            notifySnack(message, variant);
        }
    };

    notifySuccess = () =>
    {
        const locale = this.getLocale() ;
        if( locale )
        {
            const { snack } = locale ;
            if( snack )
            {
                const { success } = snack ;
                if( success )
                {
                    this.notify(success, SnackVariant.SUCCESS);
                }
            }
        }
    };

    onEdit = this.change ;

    render = () =>
    {
        const { status } = this.state ;

        if( status === RequestStatus.REVOKED )
        {
            const { location } = this.props ;
            return <Redirect to={{ pathname: "/welcome" , state: { referrer:location } }} />;
        }

        const {
            classes,
            className,
            containerClassName,
            ContainerComponent,
            containerProps,
            style,
            titleMode,
            thing
        } = this.props ;

        if( thing )
        {
            const { review } = thing ;
            if( review )
            {
                let content ;

                const locale = this.getLocale() ;
                const { event } = review ;

                if( status === RequestStatus.PROGRESS )
                {
                    content = (
                    <div className='flex flex-col items-center justify-center w-full'>
                        <CircularProgress
                           className = { classes.progress }
                           size      = { 28 }
                           thickness = { 7 }
                        />
                    </div>);
                }
                else if( event instanceof AbortionEventVO )
                {
                    content = (
                    <div className='w-full'>
                        <TitleHeader
                            className = 'w-full pt-8'
                            editable  = { titleMode === 'edit' && this.isEditable() }
                            icon      = { <EventIcon/> }
                            label     = { locale.labels.title }
                            onClick   = { () =>
                            {
                                if( titleMode === 'edit' )
                                {
                                    this.openEditDialog(this.getEditButtonThing()) ;
                                }
                            }}
                            options = { this.getOptions() }
                        />
                        { this.getContent() }
                    </div>);
                }
                else if( this.isEditable() )
                {
                    content = (
                    <div className='flex flex-col items-center justify-center w-full'>
                        { this.getAddButton() }
                    </div>);
                }
                else
                {
                    content = (
                    <Typography
                        className = 'w-full px-16 text-center'
                        variant   = 'subtitle2'
                    >
                        { locale.labels.emptyEvent }
                    </Typography>);
                }

                let defaultContainerProps = null ;

                const Container = ContainerComponent || Paper ;

                if( Container === Paper )
                {
                    defaultContainerProps = { elevation:2 } ;
                }

                return (
                    <div
                        className = { clsx(classes.root, className) }
                        style     = { style }
                    >
                        <Container
                            elevation = {2}
                            className = { clsx( "flex items-center justify-center bg-transparent" , containerClassName ) }
                            { ...defaultContainerProps }
                            { ...containerProps }
                        >
                            { content }
                        </Container>
                    </div>
                );
            }
        }

        return null  ;
    };

    unmount = () =>
    {
        const { status } = this.state ;
        if( status === RequestStatus.PROGRESS && !!(this.canceler) )
        {
            this.canceler.cancel(this + ' cancel') ;
        }
    };

    // ------------ PRIVATE

    _cancel = ( message ) =>
    {
        console.log( this + ' cancel : ' + message ) ;
    };

    _fail = ( response ) =>
    {
        if( response )
        {
            console.log( this + ' fail' , response ) ;
            const { data } = response ;
            if( data )
            {
                const { message } = data ;
                switch( message )
                {
                    case 'token revoked' :
                    {
                        console.log( this + " failed, status:" + response.status + ", message:" + data.message );
                        this.setState({ status:RequestStatus.REVOKED });
                        break ;
                    }
                    default :
                    {
                        console.log( this + " failed, status:" + response.status + ", message:" + data.message );
                    }
                }
            }
        }
        if( this.mounted )
        {
            this.notifyFail() ;
            this.setState({ status:RequestStatus.FAIL });
        }
    };

    _createSuccess = ( data ) =>
    {
        const { editUri } = this.props ;
        if( editUri instanceof Function )
        {
            const uri = api.url + editUri( null, this.props , this.state ) ;
            if( isString(uri) && this.mounted )
            {
                const { thing } = this.props ;
                if( thing )
                {
                    let { review } = thing ;
                    if( review instanceof AbortionReview )
                    {
                        let analyses = null ;

                        if( data )
                        {
                            const { result } = data ;

                            if( result instanceof Array && result.length > 0 )
                            {
                                analyses = result.map( item =>
                                {
                                    let disease  = new Disease(item) ;
                                    let analysis = new MedicalAnalysis(
                                    {
                                        id : generateUUID() ,
                                        disease
                                    }) ;

                                    let method ;
                                    let sampling ;

                                    let { analysisMethod, analysisSampling } = disease ;

                                    if( analysisMethod instanceof Array && analysisMethod.length > 0 )
                                    {
                                        analysisMethod = analysisMethod.filter( item =>
                                        {
                                            if( item instanceof MedicalMethod )
                                            {
                                                return !!(item.mandatory) && (item.additionalType instanceof Word) ;
                                            }
                                            return false ;
                                        }) ;

                                        if( analysisMethod instanceof Array )
                                        {
                                            method = analysisMethod.map( item => item.additionalType ) ;
                                        }
                                    }

                                    if( analysisSampling instanceof Array && analysisSampling.length > 0 )
                                    {
                                        analysisSampling = analysisSampling.filter( item =>
                                        {
                                            if( item instanceof MedicalSampling )
                                            {
                                                return !!(item.mandatory) && (item.additionalType instanceof Word) ;
                                            }
                                            return false ;
                                        }) ;

                                        if( analysisSampling instanceof Array )
                                        {
                                            sampling = analysisSampling.map( item => item.additionalType ) ;
                                        }
                                    }

                                    analysis.method   = method ;
                                    analysis.sampling = sampling ;

                                    return analysis ;
                                });
                            }
                        }

                        let event = new AbortionEventVO(
                        {
                            id       : generateUUID(),
                            analysis : analyses
                        }) ;

                        event  = event.toObject() ;
                        review = review.toObject() ;

                        review.event = event ;

                        review = { review:JSON.stringify(review) } ;

                        this.canceler = PATCH( uri, { success : this._saveSuccess, fail : this._fail, cancel : this._cancel, datas : review } ) ;
                    }
                }
            }
        }
    };

    _saveSuccess = ( response ) =>
    {
        if( response )
        {
            const { data } = response ;
            if( data )
            {
                const { result:observation } = data ;
                if ( observation )
                {
                    const { onChange } = this.props ;
                    if( onChange instanceof Function )
                    {
                        onChange( observation ) ;
                    }
                }
            }
        }

        this.notifySuccess();
        this.setState({ status:RequestStatus.SUCCESS }) ;
    }
}

AbortionEvent.defaultProps =
{
    ...ThingContainer.defaultProps,
    addButtonProps : null ,
    addIcon        : <AddIcon className='mr-16 self-center'/>,
    apiUrl         : api.url,
    editUri        : ( path, props ) =>
    {
        const { thing } = props ;
        if( thing )
        {
            const { id } = thing ;
            if( id )
            {
                return api.observations.url + '/' + id ;
            }
        }
        return null ;
    },
    identifierIcon : <FaBarcode className="mr-8 self-center" style={{ fontSize:18 }}/>,
    periodIcon     : <TodayIcon className="mr-8 self-center" style={{ fontSize:18 }}/>,
    scheduleIcon   : <ScheduleIcon className="mr-8 self-center" style={{ fontSize:18 }}/>,
    optionMode     : 'none',
    titleMode      : 'edit',
    uri            : api.settings.diseasesMandatoryByObservationsTypes + '?skin=full'
};

AbortionEvent.propTypes =
{
    ...ThingContainer.propTypes,
    addButtonProps : PropTypes.object ,
    addIcon        : PropTypes.element ,
    apiUrl         : PropTypes.string.isRequired,
    identifierIcon : PropTypes.element ,
    periodIcon     : PropTypes.element ,
    scheduleIcon   : PropTypes.element
};

export default withStyles( styles )( withRouter( withi18n( withSnack( withDialogs( AbortionEvent ) ) ) ) ) ;
