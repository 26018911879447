import audio from './audio'
import image from './image'
import logo  from './logo'
import video from './video'

const media =
{
    audio,
    image,
    logo,
    video
};

export default media ;
