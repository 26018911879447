import React from 'react'

import PropTypes from 'prop-types'

import clsx from 'clsx'

import { Card, CardMedia } from '@material-ui/core'

import ImageIcon      from '@material-ui/icons/Image'

import getLocaleAlternativeHeadline from '../../things/getLocaleAlternativeHeadline'
import getMediaSource               from '../../things/getMediaSource'

import withDatasGrid from './withDatasGrid'

import DatasGrid from './DatasGrid'

import RequestStatus from '../../net/RequestStatus'

import ThingCell , { modes, LINK } from '../cells/ThingCell'

import styles from './styles'

export class ThingsGrid  extends DatasGrid
{
    constructor( props )
    {
        super( props ) ;
        this.state =
        {
            ...this.state,
           preload  : true
        }
    }

    getItem = ( item , index ) =>
    {
        const { clazz } = this.props  ;
        if( item instanceof clazz )
        {
            let {
                backgroundColors,
                cellClassName,
                CellComponent,
                cellMode,
                cellProps,
                ItemClassName,
                ItemComponent,
                ItemProps,
                selectable,
                toggleSelected,
                selectedItems
            }
            = this.props;

            let style ;
            if( backgroundColors instanceof Array && backgroundColors.length > 0 )
            {
                style = { style : { background : backgroundColors[index % backgroundColors.length] } } ;
            }

            selectable = this.isEditable() && selectable ;

            const { id } = item ;

            const find = (selectedItems instanceof Array)
                      && selectedItems.findIndex( element => (id === element.id) ) > -1 ;

            return (
                <ItemComponent
                    className = { ItemClassName }
                    { ...style }
                    { ...ItemProps }
                >
                    { this.getMedia(item) }
                    <CellComponent
                        avatar     = { this.getCheckbox }
                        className  = { clsx( 'px-8' , cellClassName ) }
                        mode       = { cellMode }
                        onClick    = { () => this.onSelect(item) }
                        onSelect   = { () => toggleSelected( item , 'id' ) }
                        options    = { this.getRowOptions( item , index ) }
                        selectable = { selectable }
                        selected   = { !!(find) }
                        thing      = { item }
                        { ...cellProps}
                    />
                </ItemComponent>
            );
        }
    };

    getMedia = item =>
    {
        const { config , lang } = this.props ;
        if( item )
        {
            let { mediaIcon:icon , mediaProps , theme } = this.props ;

            mediaProps =
            {
                style : {
                    backgroundColor: theme.palette.secondary.dark ,
                    color          : theme.palette.secondary.contrastText,
                    height         : 150
                } ,
                ...mediaProps,
            }

            const { image } = item ;
            if( image )
            {
                const { contentUrl } = image ;
                const { image : imageConfig } = config ;

                const alt = getLocaleAlternativeHeadline( image , lang ) ;

                const resize = '?w=' + imageConfig.maxWidth + '&h=' + imageConfig.maxHeight ;

                const source = getMediaSource( image ) ;

                let sourceElements = [] ;

                if( source )
                {
                    sourceElements = source.map( element => <source key={element.encodingFormat} srcSet={ element.contentUrl + resize } type={ element.encodingFormat } /> ) ;
                }

                const imgElement = (
                    <img
                        key = { contentUrl }
                        alt = { alt }
                        src = { contentUrl + resize }
                        style = {{ width : '100%' , height : '100%' , objectFit : 'cover' }}
                    />
                );

                sourceElements = [ ...sourceElements , imgElement ] ;

                let { className } = mediaProps ;
                return (
                    <CardMedia
                        className = { className }
                        component = 'picture'
                        children  = { sourceElements }
                        { ...mediaProps }
                    />
                );

            }

            let { className , ...props } = mediaProps ;

            return (
                <div
                    className = { clsx( 'flex items-center justify-center shadow-inner' , className ) }
                    { ...props }
                >
                    { icon }
                </div>
            );
        }
        return null ;
    };

    getRowOptions = thing =>
    {
        if( thing )
        {
            if( this.isEditable() )
            {
                const {
                    activable,
                    preferable,
                    deletable
                } = this.props;
                return (
                    <div className="flex justify-end items-center">
                        { activable  && this.getActiveSwitch(thing) }
                        { preferable && this.getFavoriteButton(thing) }
                        { deletable  && this.getDeleteButton(thing) }
                    </div>
                );
            }
        }
        return null ;
    };

    onSelect = () => null ;
}

ThingsGrid.defaultProps =
{
    ...DatasGrid.defaultProps ,
    activeSwitchProps     : { size:'small' },
    AddDialogComponent    : null,
    addMode               : RequestStatus.REDIRECT,
    backgroundColors      : null,
    CellComponent         : ThingCell,
    cellMode              : LINK,
    cellProps             : null,
    contentTransition     : false,
    deleteProps           : { size:'small' },
    ItemComponent         : Card,
    ItemProps             : { elevation : 6 },
    media                 : true,
    mediaIcon             : <ImageIcon fontSize='large' />,
    mediaDefaultProps     : {} ,
    mediaProps            : {} ,
    preferable            : true ,
    RemoveDialogComponent : null,
    sortable              : true
};

ThingsGrid.propTypes =
{
    ...DatasGrid.propTypes,
    backgroundColors      : PropTypes.arrayOf(PropTypes.string),
    AddDialogComponent    : PropTypes.elementType,
    cellClassName         : PropTypes.string,
    CellComponent         : PropTypes.elementType.isRequired,
    cellMode              : PropTypes.oneOf(modes),
    cellProps             : PropTypes.object ,// ex: { variant:'simple' }
    media                 : PropTypes.bool,
    mediaIcon             : PropTypes.element,
    mediaDefaultProps     : PropTypes.object,
    mediaProps            : PropTypes.object,
    RemoveDialogComponent : PropTypes.elementType,
};

export default withDatasGrid( styles, ThingsGrid ) ;
