const thing =
{
    copy :
    {
        snack   : 'Lien dans le presse-papiers.' ,
        tooltip : 'Copier le lien'
    },
    label : '{0}',
    title : null
};

export default thing ;
