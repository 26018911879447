import React from 'react'

import withDatasList from './withDatasList'

import ApplicationIcon from '@material-ui/icons/Apps'

import ThingsList from './ThingsList'

import AddApplicationDialog    from '../dialogs/add/AddApplicationDialog'
import RemoveApplicationDialog from '../dialogs/remove/RemoveApplicationDialog'

import Application from '../../vo/Application'

class ApplicationsList extends ThingsList
{
    getLocale = () => this.props.locale.display.lists.applications ;
}

ApplicationsList.defaultProps =
{
    ...ThingsList.defaultProps ,
    AddDialogComponent    : AddApplicationDialog,
    clazz                 : Application,
    icon                  : <ApplicationIcon />,
    preferable            : false ,
    queries               : { sort : 'name' },
    RemoveDialogComponent : RemoveApplicationDialog,
    selectPolicy          : 'none' ,
    sortable              : true
};

ApplicationsList.propTypes =
{
    ...ThingsList.propTypes
};

export default withDatasList()( ApplicationsList ) ;
