import React from 'react'

import Icon from '@material-ui/icons/Group'

import withDatasList from './withDatasList'

import ThingsList from './ThingsList'

import RequestStatus from '../../net/RequestStatus'

import AddTeamDialog    from '../dialogs/add/AddTeamDialog'
import RemoveTeamDialog from '../dialogs/remove/RemoveTeamDialog'

import TeamCell from '../cells/TeamCell'
import Team     from '../../vo/Team'

class TeamList extends ThingsList
{
    getLocale = () => this.props.locale.display.lists.teams ;
}

TeamList.defaultProps =
{
    ...ThingsList.defaultProps ,
    activable             : false,
    AddDialogComponent    : AddTeamDialog,
    addMode               : RequestStatus.RELOAD,
    CellComponent         : TeamCell,
    clazz                 : Team,
    icon                  : <Icon />,
    queries               : { sort : 'identifier' },
    RemoveDialogComponent : RemoveTeamDialog,
    selectPolicy          : 'none'
};

TeamList.propTypes =
{
    ...ThingsList.propTypes
};

export default withDatasList()( TeamList ) ;
