export default
{
    url     : process.env.REACT_APP_API_URL ,
    limit   : parseInt(process.env.REACT_APP_DEFAULT_LIMIT),
    applications :
    {
        alternativeHeadline : '/applications/{0}/alternativeHeadline' ,
        application         : '/applications/{0}' ,
        audio               : '/applications/{0}/audio',
        audios              : '/applications/{0}/audios',
        description         : '/applications/{0}/description' ,
        headline            : '/applications/{0}/headline' ,
        image               : '/applications/{0}/image' ,
        notes               : '/applications/{0}/notes' ,
        oAuth               : '/applications/{0}/oAuth' ,
        photos              : '/applications/{0}/photos',
        producer            : '/applications/{0}/producer' ,
        publisher           : '/applications/{0}/publisher' ,
        secret              : '/applications/{0}/oAuth/secret' ,
        setting             : '/applications/{0}/setting' ,
        slogan              : '/applications/{0}/slogan',
        sponsor             : '/applications/{0}/sponsor' ,
        text                : '/applications/{0}/text' ,
        url                 : '/applications',
        video               : '/applications/{0}/video',
        videos              : '/applications/{0}/videos',
        websites            : '/applications/{0}/websites'
    },
    articles :
    {
        alternativeHeadline : '/articles/{0}/alternativeHeadline' ,
        article             : '/articles/{0}' ,
        audio               : '/articles/{0}/audio',
        audios              : '/articles/{0}/audios',
        description         : '/articles/{0}/description' ,
        hasPart             : '/articles/{0}/hasPart' ,
        headline            : '/articles/{0}/headline' ,
        image               : '/articles/{0}/image' ,
        isPartOf            : '/articles/{0}/isPartOf' ,
        isRelatedTo         : '/articles/{0}/isRelatedTo' ,
        isSimilarTo         : '/articles/{0}/isSimilarTo' ,
        notes               : '/articles/{0}/notes' ,
        photos              : '/articles/{0}/photos',
        text                : '/articles/{0}/text' ,
        url                 : '/articles' ,
        video               : '/articles/{0}/video',
        videos              : '/articles/{0}/videos',
        websites            : '/articles/{0}/websites',
    },
    auth :
    {

    },
    conceptualObjects :
    {
        url               : '/conceptualObjects' ,
        audio             : '/conceptualObjects/{0}/audio',
        audios            : '/conceptualObjects/{0}/audios',
        conceptualObjects : '/conceptualObjects/{0}',
        description       : '/conceptualObjects/{0}/description' ,
        alternateName     : '/conceptualObjects/{0}/alternateName',
        text              : '/conceptualObjects/{0}/text' ,
        image             : '/conceptualObjects/{0}/image',
        marks             : '/conceptualObjects/{0}/marks',
        materials         : '/conceptualObjects/{0}/materials',
        measurements      : '/conceptualObjects/{0}/measurements',
        numbers           : '/conceptualObjects/{0}/numbers',
        photos            : '/conceptualObjects/{0}/photos',
        productions       : '/conceptualObjects/{0}/productions',
        notes             : '/conceptualObjects/{0}/notes' ,
        video             : '/conceptualObjects/{0}/video',
        videos            : '/conceptualObjects/{0}/videos',
        websites          : '/conceptualObjects/{0}/websites',
        withStatus        : '/conceptualObjects/{0}/withStatus'
    },
    courses :
    {
        course                    : '/courses/{0}',
        url                       : '/courses' ,
        alternativeHeadline       : '/courses/{0}/alternativeHeadline' ,
        audio                     : '/courses/{0}/audio',
        audios                    : '/courses/{0}/audios',
        description               : '/courses/{0}/description',
        discover                  : '/courses/{0}/discover',
        headline                  : '/courses/{0}/headline',
        image                     : '/courses/{0}/image',
        notes                     : '/courses/{0}/notes',
        openingHoursSpecification : '/courses/{0}/openingHoursSpecification',
        photos                    : '/courses/{0}/photos' ,
        steps                     : '/courses/{0}/steps' ,
        text                      : '/courses/{0}/text' ,
        transportations           : '/courses/{0}/transportations',
        video                     : '/courses/{0}/video',
        videos                    : '/courses/{0}/videos',
        withStatus                : '/courses/{0}/withStatus'
    },
    diseases :
    {
        url                : '/diseases' ,
        alternateName      : '/diseases/{0}/alternateName',
        analysisMethod     : '/diseases/{0}/analysisMethod' ,
        analysisSampling   : '/diseases/{0}/analysisSampling' ,
        description        : '/diseases/{0}/description' ,
        disease            : '/diseases/{0}',
        notes              : '/diseases/{0}/notes',
        text               : '/diseases/{0}/text',
        transmissionMethod : '/diseases/{0}/transmissionMethod',
        withStatus         : '/diseases/{0}/withStatus'
    },
    events :
    {
        url                 : '/events' ,
        from                : '/events/from' ,
        alternateName       : '/events/{0}/alternateName' ,
        alternativeHeadline : '/events/{0}/alternativeHeadline' ,
        audio               : '/events/{0}/audio',
        audios              : '/events/{0}/audios',
        description         : '/events/{0}/description',
        event               : '/events/{0}' ,
        headline            : '/events/{0}/headline' ,
        image               : '/events/{0}/image',
        offers              : '/events/{0}/offers',
        organizer           : '/events/{0}/organizer',
        photos              : '/events/{0}/photos',
        notes               : '/events/{0}/notes',
        subEvent            : '/events/{0}/subEvent',
        superEvent          : '/events/{0}/superEvent',
        text                : '/events/{0}/text',
        video               : '/events/{0}/video',
        videos              : '/events/{0}/videos',
        workFeatured        : '/events/{0}/workFeatured',
        websites            : '/events/{0}/websites',
        withStatus          : '/events/{0}/withStatus'
    },
    games        :
    {
        url           : '/games',
        alternateName : '/games/{0}/alternateName',
        description   : '/games/{0}/description' ,
        game          : '/games/{0}',
        item          : '/games/{0}/item',
        notes         : '/games/{0}/notes' ,
        quest         : '/games/{0}/quest' ,
        text          : '/games/{0}/text' ,

        // specific games

        courses :
        {
            url           : '/games/courses',
            alternateName : '/games/courses/{0}/alternateName',
            description   : '/games/courses/{0}/description' ,
            game          : '/games/courses/{0}',
            item          : '/games/courses/{0}/item',
            notes         : '/games/courses/{0}/notes' ,
            quest         : '/games/courses/{0}/quest' ,
            text          : '/games/courses/{0}/text'
        },
        questions  :
        {
            url           : '/games/questions',
            alternateName : '/games/questions/{0}/alternateName',
            description   : '/games/questions/{0}/description' ,
            game          : '/games/questions/{0}',
            item          : '/games/questions/{0}/item',
            notes         : '/games/questions/{0}/notes' ,
            quest         : '/games/questions/{0}/quest' ,
            text          : '/games/questions/{0}/text'
        }
    },
    livestocks :
    {
        url           : '/livestocks' ,
        livestock     : '/livestocks/{0}' ,
        audios        : '/livestocks/{0}/audios',
        numbers       : '/livestocks/{0}/numbers' ,
        observations  : '/livestocks/{0}/observations',
        photos        : '/livestocks/{0}/photos',
        technicians   : '/livestocks/{0}/technicians',
        veterinarians : '/livestocks/{0}/veterinarians',
        videos        : '/livestocks/{0}/videos',
        workshops     : '/livestocks/{0}/workshops',
        withStatus    : '/livestocks/{0}/withStatus'
    },
    logout       :
    {
        url : '/logout'
    },
    me :
    {
        url        : '/me' ,
        activities : '/me/activityLogs' ,
        address    : '/me/address',
        favorites  : '/me/favorites',
        sessions   : '/me/sessions'
    },
    mediaObjects :
    {
        url    : '/mediaObjects',
        medium : '/mediaObjects/{0}'
    },
    observations :
    {
        url           : '/livestocks/observations' ,
        actor         : '/livestocks/observations/{0}/actor' ,
        alternateName : '/livestocks/observations/{0}/alternateName' ,
        attendee      : '/livestocks/observations/{0}/attendee' ,
        audios        : '/livestocks/observations/{0}/audios',
        authority     : '/livestocks/observations/{0}/authority' ,
        description   : '/livestocks/observations/{0}/description',
        observation   : '/livestocks/observations/{0}',
        notes         : '/livestocks/observations/{0}/notes',
        photos        : '/livestocks/observations/{0}/photos',
        text          : '/livestocks/observations/{0}/text',
        videos        : '/livestocks/observations/{0}/videos',
        withStatus    : '/livestocks/observations/{0}/withStatus'
    },
    organizations :
    {
        url                : '/organizations' ,
        description        : '/organizations/{0}/description' ,
        notes              : '/organizations/{0}/notes' ,
        address            : '/organizations/{0}/address',
        audio              : '/organizations/{0}/audio',
        audios             : '/organizations/{0}/audios',
        alternateName      : '/organizations/{0}/alternateName' ,
        department         : '/organizations/{0}/department' ,
        text               : '/organizations/{0}/text' ,
        employees          : '/organizations/{0}/employees' ,
        email              : '/organizations/{0}/email' ,
        founder            : '/organizations/{0}/founder' ,
        image              : '/organizations/{0}/image',
        logo               : '/organizations/{0}/logo',
        organization       : '/organizations/{0}',
        owns               : '/organizations/{0}/owns',
        memberOf           : '/organizations/{0}/memberOf',
        members            : '/organizations/{0}/members',
        numbers            : '/organizations/{0}/numbers',
        parentOrganization : '/organizations/{0}/parentOrganization' ,
        photos             : '/organizations/{0}/photos',
        providers          : '/organizations/{0}/providers' ,
        slogan             : '/organizations/{0}/slogan',
        subOrganization    : '/organizations/{0}/subOrganization',
        telephone          : '/organizations/{0}/telephone',
        video              : '/organizations/{0}/video',
        videos             : '/organizations/{0}/videos',
        websites           : '/organizations/{0}/websites',
        withStatus         : '/organizations/{0}/withStatus'
    },
    people :
    {
        audio       : '/people/{0}/audio',
        audios      : '/people/{0}/audios',
        description : '/people/{0}/description' ,
        address     : '/people/{0}/address',
        text        : '/people/{0}/text' ,
        civilStatus : '/people' ,
        email       : '/people/{0}/email',
        image       : '/people/{0}/image',
        job         : '/people/{0}/job' ,
        memberOf    : '/people/{0}/memberOf',
        person      : '/people/{0}',
        photos      : '/people/{0}/photos' ,
        notes       : '/people/{0}/notes',
        telephone   : '/people/{0}/telephone',
        url         : '/people',
        video       : '/people/{0}/video',
        videos      : '/people/{0}/videos',
        websites    : '/people/{0}/websites',
        worksFor    : '/people/{0}/worksFor',
        withStatus  : '/people/{0}/withStatus'
    },
    places :
    {
        url                       : '/places',
        description               : '/places/{0}/description',
        activities                : '/places/{0}/activities',
        audio                     : '/places/{0}/audio',
        address                   : '/places/{0}/address',
        alternateName             : '/places/{0}/alternateName',
        audios                    : '/places/{0}/audios',
        notes                     : '/places/{0}/notes',
        conceptualObjects         : '/places/{0}/conceptualObjects',
        containedInPlace          : '/places/{0}/containedInPlace',
        containsPlace             : '/places/{0}/containsPlace',
        text                      : '/places/{0}/text',
        email                     : '/places/{0}/email',
        events                    : '/places/{0}/events',
        image                     : '/places/{0}/image',
        geo                       : '/places/{0}/geo',
        logo                      : '/places/{0}/logo',
        keywords                  : '/places/{0}/keywords',
        museumType                : '/places/{0}/museumType',
        offers                    : '/places/{0}/offers',
        openingHoursSpecification : '/places/{0}/openingHoursSpecification',
        permits                   : '/places/{0}/permits',
        place                     : '/places/{0}',
        photos                    : '/places/{0}/photos',
        prohibitions              : '/places/{0}/prohibitions',
        services                  : '/places/{0}/services',
        slogan                    : '/places/{0}/slogan',
        status                    : '/places/{0}/status',
        telephone                 : '/places/{0}/telephone',
        video                     : '/places/{0}/video',
        videos                    : '/places/{0}/videos',
        websites                  : '/places/{0}/websites',
        withStatus                : '/places/{0}/withStatus'
    } ,
    settings :
    {
        url : '/settings',
        ageGroupsByObservationsTypes          : '/settings/observationsTypes/{0}/ageGroups',
        ageGroupsBySectorsTypes               : '/settings/sectorsTypes/{0}/ageGroups',
        analysisMethodsByDiseases             : '/settings/diseases/{0}/analysisMethods' ,
        causesByBreedingsTypes                : '/settings/breedingsTypes/{0}/medicalCauses',
        diseasesMandatoryByObservationsTypes  : '/settings/observationsTypes/{0}/diseasesMandatory' ,
        diseasesByObservationsTypes           : '/settings/observationsTypes/{0}/diseases' ,
        medicalConclusionsByObservationsTypes : '/settings/observationsTypes/{0}/medicalConclusions' ,
        medicalFrequenciesByObservationsTypes : '/settings/observationsTypes/{0}/medicalFrequencies' ,
        morbidityRatesTypesByBreedingsTypes   : '/settings/breedingsTypes/{0}/morbidityRates',
        mortalityRatesTypesByBreedingsTypes   : '/settings/breedingsTypes/{0}/mortalityRates',
        observationsTypesByBreedingsTypes     : '/settings/breedingsTypes/{0}/observationsTypes',
        productionsTypesByBreedingsTypes      : '/settings/breedingsTypes/{0}/productionsTypes' ,
        preventionsBySectorsTypes             : '/settings/sectorsTypes/{0}/preventions',
        riskFactorsByObservationsTypes        : '/settings/observationsTypes/{0}/riskFactors' ,
        samplingsByDiseases                   : '/settings/diseases/{0}/samplings' ,
        sectorsTypesByBreedingsTypes          : '/settings/breedingsTypes/{0}/sectorsTypes',
        symptomsBySectorsTypes                : '/settings/sectorsTypes/{0}/symptoms',
        vaccinesByBreedingsTypes              : '/settings/breedingsTypes/{0}/vaccines',
        withStatus                            : '/settings/{0}/withStatus'
    },
    stages :
    {
        url                 : '/stages' ,
        activities          : '/stages/{0}/activities',
        alternativeHeadline : '/stages/{0}/alternativeHeadline',
        audio               : '/stages/{0}/audio',
        audios              : '/stages/{0}/audios',
        description         : '/stages/{0}/description',
        discover            : '/stages/{0}/discover',
        courses             : '/stages/{0}/courses',
        events              : '/stages/{0}/events',
        headline            : '/stages/{0}/headline',
        image               : '/stages/{0}/image',
        notes               : '/stages/{0}/notes',
        conceptualObjects   : '/stages/{0}/conceptualObjects',
        organizations       : '/stages/{0}/organizations',
        people              : '/stages/{0}/people',
        photos              : '/stages/{0}/photos',
        places              : '/stages/{0}/places',
        stage               : '/stages/{0}',
        stages              : '/stages/{0}/stages',
        status              : '/stages/{0}/status',
        text                : '/stages/{0}/text',
        video               : '/stages/{0}/video',
        videos              : '/stages/{0}/videos',
        websites            : '/stages/{0}/websites',
        withStatus          : '/stages/{0}/withStatus'
    },
    steps :
    {
        url                 : '/courses/steps',
        alternativeHeadline : '/courses/steps/{0}/alternativeHeadline',
        audio               : '/courses/steps/{0}/audio',
        audios              : '/courses/steps/{0}/audios',
        description         : '/courses/steps/{0}/description',
        headline            : '/courses/steps/{0}/headline',
        image               : '/courses/steps/{0}/image',
        notes               : '/courses/steps/{0}/notes',
        photos              : '/courses/steps/{0}/photos',
        step                : '/courses/steps/{0}',
        text                : '/courses/steps/{0}/text',
        video               : '/courses/steps/{0}/video',
        videos              : '/courses/steps/{0}/videos',
    },
    teams :
    {
        url           : '/teams' ,
        alternateName : '/teams/{0}/alternateName',
        description   : '/teams/{0}/description',
        team          : '/teams/{0}'
    },
    technicians :
    {
        url                : '/technicians' ,
        livestocks         : '/technicians/{0}/livestocks',
        medicalSpecialties : '/technicians/{0}/medicalSpecialties',
        technician         : '/technicians/{0}',
        withStatus         : '/technicians/{0}/withStatus'
    },
    thesaurus :
    {
        url                            : '/thesaurus' ,
        thesaurus                      : '/thesaurus/{0}',
        activities                     : '/thesaurus/activities' ,
        analysis_methods               : '/thesaurus/analysis/methods' ,
        answers_generics               : '/thesaurus/answers/generics' ,
        applications_types             : '/thesaurus/applications/types' ,
        articles_types                 : '/thesaurus/articles/types' ,
        badgeItems_types               : '/thesaurus/badgeItems/types' ,
        breedings_types                : '/thesaurus/breedings/types' ,
        business_entity_types          : '/thesaurus/business/entity/types' ,
        courses_audiences              : '/thesaurus/courses/audiences',
        courses_levels                 : '/thesaurus/courses/levels',
        courses_paths                  : '/thesaurus/courses/paths',
        courses_status                 : '/thesaurus/courses/status',
        courses_types                  : '/thesaurus/courses/types',
        conceptualObject_technic       : '/thesaurus/conceptualObjects/techniques' ,
        diseases                       : '/thesaurus/medical/diseases' ,
        diseases_types                 : '/thesaurus/diseases/types' ,
        dimensions                     : '/thesaurus/measurements/dimensions' ,
        emails_types                   : '/thesaurus/emails/types' ,
        events_status                  : '/thesaurus/events/statusTypes' ,
        events_types                   : '/thesaurus/events/types' ,
        genders                        : '/thesaurus/genders' ,
        honorificPrefix                : '/thesaurus/honorificPrefix',
        languages                      : '/thesaurus/languages' ,
        livestocks_numbers_types       : '/thesaurus/livestocks/numbers/types' ,
        materials                      : '/thesaurus/materials' ,
        marks_types                    : '/thesaurus/marks/types' ,
        medical_levels                 : '/thesaurus/medical/levels' ,
        medical_samplings              : '/thesaurus/medical/samplings' ,
        medical_specialties            : '/thesaurus/medical/specialties' ,
        medical_transmissions_methods  : '/thesaurus/medical/transmissions/methods' ,
        movement                       : '/thesaurus/art/movements' ,
        museums_types                  : '/thesaurus/museums/types' ,
        conceptualObject_categories    : '/thesaurus/conceptualObjects/categories' ,
        conceptualObject_numbers_types : '/thesaurus/conceptualObjects/numbers/types' ,
        observations_status            : '/thesaurus/observations/status' ,
        observations_types             : '/thesaurus/observations/types' ,
        offers_categories              : '/thesaurus/offers/categories' ,
        organizations_naf              : '/thesaurus/organizations/naf' ,
        organizations_numbers_types    : '/thesaurus/organizations/numbers/types' ,
        organizations_types            : '/thesaurus/organizations/types' ,
        people_jobs                    : '/thesaurus/people/jobs',
        phoneNumbers_types             : '/thesaurus/phoneNumbers/types',
        places_regulations             : '/thesaurus/places/regulations' ,
        places_status                  : '/thesaurus/places/statusTypes',
        places_types                   : '/thesaurus/places/types' ,
        productions_types              : '/thesaurus/productions/types' ,
        questions_types                : '/thesaurus/questions/types' ,
        sector_types                   : '/thesaurus/sectors/types' ,
        services                       : '/thesaurus/services' ,
        temporal                       : '/thesaurus/art/temporal/entities',
        transportations                : '/thesaurus/transportations',
        units                          : '/thesaurus/measurements/units',
        water_origins                  : '/thesaurus/water/origins' ,
        websites_types                 : '/thesaurus/websites/types'
    },
    topics :
    {
        alternativeHeadline : '/topics/{0}/alternativeHeadline' ,
        description         : '/topics/{0}/description' ,
        headline            : '/topics/{0}/headline' ,
        image               : '/topics/{0}/image' ,
        notes               : '/topics/{0}/notes' ,
        text                : '/topics/{0}/text' ,
        topic               : '/topics/{0}' ,
        url                 : '/topics',
        withStatus          : '/topics/{0}/withStatus'
    },
    user :
    {
        url        : '/users' ,
        activities : '/activityLogs' ,
        sessions   : '/sessions'
    },
    users :
    {
        url        : '/users' ,
        activities : '/users/{0}/activityLogs' ,
        address    : '/users/{0}/address',
        sessions   : '/users/{0}/sessions',
        user       : '/users/{0}'
    },
    veterinarians :
    {
        url                : '/veterinarians' ,
        livestocks         : '/veterinarians/{0}/livestocks',
        medicalSpecialties : '/veterinarians/{0}/medicalSpecialties',
        veterinarian       : '/veterinarians/{0}',
        withStatus         : '/veterinarians/{0}/withStatus'
    },
    workplaces    :
    {
        url        : '/livestocks/workshops/workplaces' ,
        workplace  : '/livestocks/workshops/workplaces/{0}' ,
        sectors    : '/livestocks/workshops/{0}/workplaces/{1}/sectors',
        withStatus : '/livestocks/workshops/{0}/withStatus'
    },
    workshops     :
    {
        url          : '/livestocks/workshops' ,
        observations : '/livestocks/workshops/{0}/observations',
        sectors      : '/livestocks/workshops/{0}/workplaces/{1}/sectors' ,
        workplaces   : '/livestocks/workshops/{0}/workplaces',
        workshop     : '/livestocks/{0}/workshops/{1}',
        withStatus   : '/livestocks/{0}/workshops/{1}withStatus'
    }
} ;
