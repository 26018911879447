
/**
 * The OAuth.
 */
class OAuth
{
    /**
     * Creates a new OAuth instance.
     * @constructor
     * @param object The generic object to initialize the object the first time.
     */
    constructor( object = null )
    {
        this.client_id = null ;

        this.client_secret = null ;

        this.url_redirect = null ;

        if( object )
        {
            for (let prop in object)
            {
                this[prop] = object[prop];
            }
            this.populate() ;
        }
    }

    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        this.client_id     =
        this.client_secret =
        this.url_redirect  = null ;
        return this ;
    }

    /**
     * Returns a shallow copy of the object.
     * @return {OAuth} a shallow copy of the object.
     */
    clone()
    {
        return new OAuth( this.toObject() ) ;
    }

    /**
     * A utility function for implementing the <code>toString()</code> method in custom classes. Overriding the <code>toString()</code> method is recommended, but not required.
     * @name formatToString
     * @memberof things
     * @instance
     * @function
     * @param {...string} [rest] - rest The properties of the class and the properties that you add in your custom class.
     * @example
     * class CustomThing extends Thing
     * {
     *     constructor( name )
     *     {
     *         this.name = name;
     *     }
     *
     *     toString()
     *     {
     *         return this.formatToString( "name" );
     *     }
     * }
     */
    formatToString( ...rest )
    {
        if( this._constructorName === null )
        {
            Object.defineProperty( this , '_constructorName' , { value : this.constructor.name } );
        }
        const path = [ this._constructorName ] ;
        const len = rest.length ;
        for( let i = 0; i < len ; ++i )
        {
            if( rest[i] in this )
            {
                path.push( rest[i] + ":" + this[rest[i]] ) ;
            }
        }
        return "[" + path.join(' ') + "]" ;
    }

    /**
     * Populate the object with specific behaviors or types. Launched by default in the constructor and in the set methods.
     * @returns {OAuth} The current reference.
     */
    populate()
    {
        return this ;
    }

    /**
     * Returns the generic object representation of the item.
     * @return {Object} The generic object representation of the item.
     */
    toObject()
    {
        let {
            client_id,
            client_secret,
            url_redirect
        } = this ;

        return {
            client_id,
            client_secret,
            url_redirect
        };
    }

    toString()
    {
        return this.formatToString() ;
    }
}

export default OAuth ;