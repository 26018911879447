import React from "react"

import clsx from 'clsx'

import PropTypes  from 'prop-types'

import ONE_DAY_MS from 'vegas-js-core/src/date/ONE_DAY_MS'

import firstVisibleDay from 'vegas-js-core/src/date/firstVisibleDay'
import lastVisibleDay  from 'vegas-js-core/src/date/lastVisibleDay'

import compose  from 'vegas-js-core/src/functors/compose'
import isString from 'vegas-js-core/src/isString'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import moment   from 'moment'
import timezone from 'moment-timezone'

import { Calendar as BigCalendar , momentLocalizer } from 'react-big-calendar'

import { withRouter } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'

import { Fab, Tooltip } from '@material-ui/core'

import AddIcon from '@material-ui/icons/Add'

import Animate from '../../transitions/Animate'

import withConfig  from '../../contexts/config/withConfig'
import withDialogs from '../../contexts/dialogs/withDialogs'
import withi18n    from '../../contexts/i18n/withi18n'
import withLoading from '../../contexts/loading/withLoading'

import DatasContainer    from '../../display/containers/DatasContainer'
import CalendarHeader    from '../../components/headers/CalendarHeader'
import RemoveEventDialog from '../dialogs/remove/RemoveEventDialog'
import EventDialog       from '../dialogs/things/EventDialog'

import AddEventDialog from "../dialogs/add/AddEventDialog"

import Event from '../../things/Event'
import Thing from '../../things/Thing'
import Word  from '../../things/Word'

import styles from './styles'

const localizer = momentLocalizer(moment)  ;

class EventsCalendar extends DatasContainer
{
    constructor( props )
    {
        super( props ) ;

        let {
            defaultDate,
            defaultView
        } = this.props ;

        const firstDayOfWeek = moment.localeData().firstDayOfWeek() ;

        const date  = defaultDate instanceof Date ? defaultDate : new Date() ;
        const start = firstVisibleDay( date , firstDayOfWeek ) ;
        const end   = lastVisibleDay( date , firstDayOfWeek ) ;

        this.state =
        {
            ...this.state,
            event : null,
            first : false,
            view  : defaultView ,
            date,
            end,
            start
        } ;
    }

    autoload = () =>
    {
        const { autoload } = this.props ;
        if( autoload )
        {
            const { end, start } = this.state ;
            this.loadDateRange( start, end, 0 ) ;
        }
    };

    build = ( title = '' , loading = false ) =>  // eslint-disable-line no-unused-vars
    {
        const { classes, className, style } = this.props ;
        return (
            <div className={ clsx(classes.root, className) } style={style} >
                { this.getContent( loading ) }
            </div>
        );
    };

    eventPropGetter = ( event /*, start, end, isSelected*/ ) =>
    {
        let backgroundColor = '#01579B' ;
        let color           = '#E1F5FE' ;
        if( event )
        {
            const { resource } = event ;
            if( resource instanceof Event )
            {
                const { additionalType } = resource ;
                if( additionalType instanceof Word )
                {
                    const { bgcolor:bg, color:co } = additionalType ;
                    if( isString(bg) )
                    {
                        backgroundColor = '#' + bg ;
                    }
                    if( isString(co) )
                    {
                        color = '#' + co ;
                    }
                }
            }
        }
        return { style : { backgroundColor, color } } ;
    };

    getAddButton = () =>
    {
        if( this.isEditable() )
        {
            const { classes } = this.props ;

            let button = (
                <Fab
                    color      = 'primary'
                    aria-label = 'add'
                    className  = { classes.addButton }
                    onClick    = { this.openAddDialog }
                >
                    <AddIcon />
                </Fab>
            );

            const locale = this.getLocale() ;
            if( locale )
            {
                const { tooltips } = locale ;
                if( tooltips )
                {
                    const { add } = tooltips ;
                    if( add )
                    {
                        button = (
                        <Tooltip title={add} placement='left'>
                            { button }
                        </Tooltip>);
                    }
                }
            }

            return (
            <Animate enter="transition.expandIn" delay={500}>
                { button }
            </Animate>);
        }
        return null ;
    };

    getContent = loading =>
    {
        const { lang } = this.props ;

        let events = [] ;

        const { things } = this.state ;
        if( things instanceof Array )
        {
            events = things
            .map( item =>
            {
                if( item instanceof Event )
                {
                    let {
                        endDate   : end,
                        startDate : start,
                    } = item ;

                    let title = ucFirst(item.getLocaleHeadline(lang)) ;

                    if( !isString(title) || (title === '') )
                    {
                        title = ucFirst(item.getLocaleName(lang))
                    }

                    if( isString(start) )
                    {
                        start = new Date(start) ;
                    }
                    else
                    {
                        start = null ;
                    }

                    if( isString(end) )
                    {
                        end = new Date(end) ;
                        if( start && end.valueOf() < start.valueOf() )
                        {
                            end = start ;
                        }
                    }
                    else
                    {
                        end = start ;
                    }

                    if( start && end )
                    {
                        return(
                        {
                            title,
                            start,
                            end,
                            allDay   : false,
                            resource : item
                        });

                    }

                }
                return null ;
            })
            .filter( item => item !== null ) ;
        }

        const {
            classes ,
            defaultView,
            formats,
            selectable
        }
        = this.props ;

        const { date } = this.state ;

        //console.log( this + ' getContent locale' , moment.locale() )

        const { calendar:options } = this.getLocale() || {} ;

        return (
        <div>
            <BigCalendar
                components        = {{ toolbar : CalendarHeader }}
                className         = { classes.container }
                date              = { date }
                onNavigate        = { this.onNavigate }
                defaultView       = { defaultView }
                eventPropGetter   = { this.eventPropGetter }
                formats           = { formats }
                localizer         = { localizer }
                events            = { events }
                onRangeChange     = { this.onRangeChange }
                onSelectEvent     = { this.onSelectEvent }
                onSelectSlot      = { this.onSelectSlot  }
                resizable         = { true }
                selectable        = { selectable }
                showMultiDayTimes = { true }
                step              = { 60 }
                { ...options }
            />
            { !loading && this.getAddButton() }
        </div>);
    };

    getEntry = init =>
    {
        let { clazz, emptyClazz } = this.props ;

        if( emptyClazz )
        {
            clazz = emptyClazz ;
        }

        if( !clazz )
        {
            clazz = Thing ;
        }

        let generic ;
        if( init instanceof Thing )
        {
            generic = init.toObject() ;
        }
        else if( init )
        {
            generic = { ...init } ;
        }

        const object = new clazz(generic) ;

        object.subjectOf = this.getEntrySubjectOf() ;

        return object ;
    };

    getEntrySubjectOf = () => this.props.thing ;

    getLocale = () => this.props.locale.components.calendars.events ;

    getQueries = () =>
    {
        const { queries } = this.props ;
        return { timezone:timezone.tz.guess(true) , ...queries } ;
    };

    loadDateRange = ( from , to, offset = 0 , options = null ) =>
    {
        const {
            datePattern,
            defaultView
        }
        = this.props ;

        let view = defaultView ;

        if( options )
        {
            const { view:v } = options ;
            if( v )
            {
               view = v ;
            }
        }

        if( from instanceof Date )
        {
            if( options && view === 'day' )
            {
                to = new Date( from.valueOf() + ONE_DAY_MS ) ;
            }

            if( to instanceof Date && (from.valueOf() !== to.valueOf()) )
            {
                to = moment(to).format(datePattern);
            }
            else
            {
                to = null ;
            }

            from = moment(from).format(datePattern);
        }
        else
        {
            let now = moment() ;

            switch( view )
            {
                case 'agenda' :
                {
                    from = now.format(datePattern) ;
                    to   = now.add(31, 'd').format(datePattern) ;
                    break ;
                }

                case 'day'   :
                case 'month' :
                case 'week'  :
                {
                    from = now.startOf(view).format(datePattern) ;
                    to   = now.endOf(view).format(datePattern) ;
                    break ;
                }

                default :
                {
                    from = null ;
                    to   = null ;
                    break ;
                }
            }

        }

        let path = null ;

        if( from )
        {
            path = '/from/' + from ;
            if( to && (from !== to) )
            {
                path += '/to/' + to ;
            }
        }

        // console.log( this + 'loadDateRange from' , from, 'to' , to, 'path', path , 'options', options ) ;

        this.load( offset , options , path ) ;
    };

    onAdd = item =>
    {
        let {
            things,
            date
        } = this.state ;
        if( item )
        {
            let { startDate } = item ;
            if( isString(startDate) )
            {
                date = new Date(startDate) ;
            }

            if( things instanceof Array )
            {
                things.push(item) ;
            }
            else
            {
                things = [ item ] ;
            }

            things = this.populate(things);

            this.setState({ things, date }) ;
        }
    };

    onRangeChange = ( date , newView ) =>
    {
        clearTimeout( this.timeout ) ;

        let {
            end,
            start,
            view
        } = this.state ;

        if( newView && (newView !== view) )
        {
            view = newView ;
        }

        switch( view )
        {
            case 'agenda' :
            {
                if( date )
                {
                    const { end:last , start:first } = date ;
                    if( !start || ( start !== first && (start.valueOf() !== first.valueOf())) )
                    {
                        start = first ;
                    }

                    if( !end || ( end !== last && (end.valueOf() !== last.valueOf())) )
                    {
                        end = last ;
                    }
                }
                break ;
            }

            case 'day' :
            {
                if( date instanceof Array && date.length > 0 )
                {
                    const first = date[0] ;
                    if( !start || ( start !== first && (start.valueOf() !== first.valueOf())) )
                    {
                        start = first ;
                    }

                    if( !end || ( end !== start && (end.valueOf() !== start.valueOf())) )
                    {
                        end = new Date( start.valueOf() ) ;
                        end.setHours(23,59,59);
                    }
                }
                break ;
            }
            case 'month' :
            {
                if( date )
                {
                    const { end:last , start:first } = date ;
                    if( !start || ( start !== first && (start.valueOf() !== first.valueOf())) )
                    {
                        start = first ;
                    }

                    if( !end || ( end !== last && (end.valueOf() !== last.valueOf())) )
                    {
                        end = last ;
                    }
                }
                break ;
            }

            case 'week' :
            {
                if( date instanceof Array && date.length > 0 )
                {
                    const first = date[0] ;
                    const last  = date[date.length-1];

                    if( !end || ( end !== last && (end.valueOf() !== last.valueOf())) )
                    {
                        end = last ;
                    }

                    if( !start || ( start !== first && (start.valueOf() !== first.valueOf())) )
                    {
                        start = first ;
                    }
                }
                break ;
            }

            default :
            {
                return ;
            }
        }

        this.timeout = setTimeout( this.loadDateRange , 400 , start, end, 0, { things:null, view } ) ;
    };

    onRemove = id =>
    {
        const { clearDialog } = this.props ;
        if( clearDialog instanceof Function )
        {
            clearDialog() ;
        }

        if( id )
        {
            let { things } = this.state ;
            if( things instanceof Array && things.length > 0 )
            {
                things = things.filter( item => item.id !== id )
                this.setState({ things }) ;
            }
        }
    };

    onNavigate = date =>
    {
        this.setState({ date:moment(date).toDate() }) ;
    };

    onSelectEvent = item =>
    {
        if( item )
        {
            const { resource:event } = item ;
            if( event instanceof Event )
            {
                this.openEventDialog( event ) ;
            }
        }
    };

    onSelectSlot = ({ start, end }) =>
    {
        const endDate   = moment(end).toISOString();
        const startDate = moment(start).toISOString();

        let event = {} ;

        let e = end.valueOf() ;
        let s = start.valueOf() ;

        if( e === s )
        {
           event = { startDate } ;
        }
        else if ( e < s )
        {
            event = { startDate:endDate } ;
        }
        else
        {
            event = { startDate, endDate } ;
        }

        this.openAddDialog({ init:event } ) ;
    };

    openEventDialog = event =>
    {
        const { addDialog } = this.props;
        if ( addDialog )
        {
            const { uri } = this.props ;
            addDialog
            (
                EventDialog ,
                {
                    item     : event ,
                    onRemove : this.openRemoveDialog,
                    uri      : uri
                }
            );
        }
    };

    populate = things =>
    {
        const { clazz } = this.props ;
        if( things instanceof Array && clazz instanceof Function)
        {
            return things.map( item => item instanceof clazz ? item : new clazz(item) ) ;
        }
        return things ;
    };

    update = ( prevProps /*, prevState*/ ) =>
    {
        if( prevProps.search !== this.props.search )
        {
            this.load( this.state.offset );
        }
    };
}

EventsCalendar.defaultProps =
{
    ...DatasContainer.defaultProps,

    AddDialogComponent    : AddEventDialog ,
    RemoveDialogComponent : RemoveEventDialog ,

    clazz        : Event ,
    defaultDate  : null,
    datePattern  : 'YYYY-MM-DD',
    defaultView  : 'month',
    emptyClazz   : null ,
    formats      :
    {
        dayHeaderFormat      : date => moment( date ).format('dddd ll') ,
        dayRangeHeaderFormat : ( { start , end } ) =>
        {
            let year1;
            let year2 ;
            if( start instanceof Date )
            {
                year1 = start.getFullYear() ;
            }

            if( end instanceof Date )
            {
                year2 = end.getFullYear() ;
            }

            start = moment(start).format('MMM');
            end   = moment(end).format('MMM');

            if( year1 === year2 )
            {
                if( start === end )
                {
                    return `${start} ${year1}`;
                }
                else
                {
                    return `${start}-${end} ${year1}`;
                }
            }
            else
            {
                return `${start} ${year1} - ${end} ${year2}` ;
            }
        }
    },
    mode         : 'default',
    selectable   : true ,
    uri          : null
};

EventsCalendar.propTypes =
{
    ...DatasContainer.propTypes,

    AddDialogComponent    : PropTypes.elementType,
    RemoveDialogComponent : PropTypes.elementType,

    clazz       : PropTypes.func,
    datePattern : PropTypes.string,
    defaultView : PropTypes.oneOf(['agenda','day','month','week']),
    emptyClazz  : PropTypes.func,
    mode        : PropTypes.oneOf(['default','calendar']),
    selectable  : PropTypes.bool,
    uri         : PropTypes.string
};

export default compose(
    withStyles( styles ) ,
    withRouter ,
    withConfig ,
    withDialogs,
    withi18n,
    withLoading
)( EventsCalendar) ;
