import React from 'react'

import compose  from 'vegas-js-core/src/functors/compose'
import isString from 'vegas-js-core/src/isString'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import initApp from '../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { Fab, Typography } from '@material-ui/core'

import { parsePhoneNumberFromString } from 'libphonenumber-js'

import PhoneIcon from '@material-ui/icons/Phone'

import PropertyValue from '../../things/PropertyValue'
import Thing         from '../../things/Thing'
import Word          from '../../things/Word'

import AddTelephoneDialog    from '../dialogs/add/AddTelephoneDialog'
import RemoveTelephoneDialog from '../dialogs/remove/RemoveTelephoneDialog'

import ThingCardChildren , { styles } from './ThingCardChildren'

class Telephone extends ThingCardChildren
{
    createChild = child =>
    {
        if( child )
        {
            let { value } = child ;
            if( isString(value) )
            {
                let phone = parsePhoneNumberFromString(value) ;
                child = (
                <Fab
                    className = 'justify-start flex-0'
                    color     = 'secondary'
                    component = 'a'
                    href      = { phone.getURI() }
                    size      = 'small'
                    variant   = 'extended'
                >
                    <PhoneIcon className='text-white mr-4 ml-4'/>
                    <Typography className='text-white truncate text-left normal-case px-8' variant='body2'>
                        { phone.formatInternational() }
                    </Typography>
                </Fab>);
            }
            else
            {
                child = null ;
            }

            return (
            <div className="py-16">
                { child }
            </div>
            );
        }

        return null ;
    };

    getCardIcon = child =>
    {
        if( child  )
        {
            const { lang } = this.props ;
            let { additionalType } = child ;
            if( additionalType )
            {
                const { image } = additionalType ;
                const type = additionalType.getLocaleName(lang) ;
                if( isString(image) )
                {
                    return <img loading="lazy" className="mr-4" height={16} width={16} src={ image + "?w=30"} alt={type} /> ;
                }
            }
        }
        return this.props.cardIcon ;
    };

    getCardLabel = ( child ) =>
    {
        if( child instanceof Thing )
        {
            const { lang } = this.props ;
            let { additionalType } = child ;
            if( additionalType instanceof Word )
            {
                let type = additionalType.getLocaleName(lang) ;
                if( isString(type) )
                {
                    return ucFirst(type) ;
                }
            }
        }
        return this.props.cardLabel ;
    };

    getCardSubLabel = child =>
    {
        if( child instanceof Thing )
        {
            const { lang } = this.props ;
            let label = child.getLocaleAlternateName(lang) ;
            if( isString(label) )
            {
                return (
                <Typography
                    className = 'normal-case'
                    variant   = 'caption'
                >
                    { ucFirst(label) }
                </Typography>) ;
            }
        }
        return this.props.cardSubLabel ;
    };

    getLocale = () => this.props.locale.things.telephone ;

    onCardClick = child  => () => { this.openEditDialog(child) ; };

    prepare = item =>
    {
        let { alternateName, additionalType, value } = item ;

        if( alternateName )
        {
            const { config } = this.props ;
            if( config )
            {
                const { languages } = config ;
                languages.forEach( lang =>
                {
                    if( !isString(alternateName[lang]) )
                    {
                        alternateName[lang] = '' ;
                    }
                });
            }
        }

        return (
        {
            alternateName ,
            additionalType : additionalType instanceof Thing ? String(additionalType.id) : null ,
            value
        });
    };
}

Telephone.defaultProps =
{
    ...ThingCardChildren.defaultProps ,

    AddDialogComponent    : AddTelephoneDialog,
    RemoveDialogComponent : RemoveTelephoneDialog,

    cardDivider    : 'fullWidth',
    cardPaperProps : { elevation : 1 },
    clazz          : PropertyValue,
    containerProps :
    {
        className : "bg-grey-200 w-full p-20 mb-12",
        elevation : 1,
        square    : false
    },
    emptyIcon : null ,
    icon      : <PhoneIcon/>,
    member    : 'telephone'
};

Telephone.propTypes =
{
    ...ThingCardChildren.propTypes
};

export default compose( withStyles( styles ) , withRouter , initApp )( Telephone ) ;
