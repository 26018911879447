import React , { Fragment }  from 'react'

import clsx from 'clsx'

import PropTypes from 'prop-types'

import compose  from 'vegas-js-core/src/functors/compose'
import notEmpty from 'vegas-js-core/src/strings/notEmpty'

import { Button, Divider, Typography } from '@material-ui/core'

import ExitToAppIcon from '@material-ui/icons/ExitToApp'

import { withRouter } from 'react-router-dom'

import withDialogs from '../../contexts/dialogs/withDialogs'
import withLocale from '../../contexts/i18n/withLocale'
import withUser   from '../../contexts/user/withUser'

import DialogContainer        from '../../components/containers/DialogContainer'
import RevokeAllSessionDialog from '../dialogs/remove/RevokeAllSessionDialog'
import SessionsTable          from '../tables/SessionsTable'

import logout from '../../net/auth/logout'
import Roles  from '../../net/auth/Roles'

class Sessions extends DialogContainer
{
    constructor( props )
    {
        super( props ) ;
        this.state =
        {
            ...this.state ,
            reload : false ,
            reset  : false
        };
    }

    getActive = () =>
    {
        const { scope, uri } = this.props ;
        return (
            <SessionsTable
                active    = { true }
                limit     = { 5 }
                onReset   = { this.onReset }
                onRevoke  = { this.onRevoke }
                reset     = { this.state.reset }
                revokeAll = { true }
                scope     = { scope }
                uri       = { uri }
            />
        );
    };

    getDescription = () =>
    {
        const { description } = this.getLocale() || {} ;
        if( notEmpty(description) )
        {
            return (
                <Typography
                    align     = 'justify'
                    className = 'text-gray-800 w-full py-4 px-8 my-8'
                    variant   = 'caption'
                >
                    { description }
                </Typography>
            )
        }
        return null ;
    }

    getDivider = ( { className = 'my-12' } = {} ) => <Divider light className={className} /> ;

    getInactive = () =>
    {
        const { scope, uri } = this.props ;
        return (
            <SessionsTable
                active        = { false }
                autoloadDelay = { 1000 }
                limit         = { 12 }
                onReload      = { this.onReload }
                onReset       = { this.onReset }
                reload        = { this.state.reload }
                reset         = { this.state.reset  }
                scope         = { scope }
                uri           = { uri }
            />
        );
    };

    getLocale = () => this.props.locale.things.sessions ;

    getRevokeButton = () =>
    {
        const { revokeAll } = this.props ;
        if( revokeAll && this.isEditable() )
        {
            const { revokeAll } = this.getLocale() || {} ;

            return (
                <Fragment>
                    <Button
                        className = 'mt-12 items-center'
                        color     = 'secondary'
                        onClick   = { () => { this.openRevokeAllDialog() ; } }
                        size      = 'small'
                        startIcon = { this.props.revokeAllIcon }
                        variant   = 'contained'
                    >
                        { revokeAll }
                    </Button>
                    { this.getDivider() }
                </Fragment>
            )
        }
        return null ;
    };

    onReload = () =>
    {
        this.setState( { reload:false }) ;
    };

    onReset = () =>
    {
        this.setState( { reset:false }) ;
    };

    onRevoke = () =>
    {
        this.setState( { reload:true }) ;
    };

    onRevokeAll = () =>
    {
        const { revokeAllMode } = this.props ;
        if( revokeAllMode === 'logout' )
        {
            logout() ;
        }
        else
        {
            this.setState( { reset:true } ) ;
        }
    };

    openRevokeAllDialog = () =>
    {
        const { addDialog, RevokeAllSessionDialogComponent } = this.props;
        if ( addDialog && RevokeAllSessionDialogComponent )
        {
            const { uri } = this.props ;
            addDialog(
                RevokeAllSessionDialogComponent ,
                {
                    onRemove : this.onRevokeAll ,
                    uri      : uri + '/all'
                }
            );
        }
    };

    render = () =>
    {
        const { className , style } = this.props ;
        return (
            <div
                className = { clsx( "flex flex-1 flex-col p-12" , className ) }
                style     = { style }
            >
                { this.getRevokeButton() }
                { this.getDescription() }
                { this.getActive() }
                { this.getDivider() }
                { this.getInactive() }
            </div>
        );
    }
}

Sessions.defaultProps =
{
    ...DialogContainer.defaultProps,
    revokeAll                       : true ,
    revokeAllIcon                   : <ExitToAppIcon /> ,
    revokeAllMode                   : 'reload' ,
    RevokeAllSessionDialogComponent : RevokeAllSessionDialog ,
    roles                           : [ Roles.ADMIN ] ,
};

Sessions.propTypes =
{
    ...DialogContainer.propTypes,
    revokeAll                       : PropTypes.bool ,
    revokeAllIcon                   : PropTypes.element ,
    revokeAllMode                   : PropTypes.oneOf(['logout','reload']) ,
    RevokeAllSessionDialogComponent : PropTypes.elementType,
};

export default compose(
    withDialogs,
    withLocale,
    withRouter,
    withUser
)( Sessions ) ;
