import React from 'react'

import format from 'vegas-js-core/src/strings/fastformat'

import { Grid } from '@material-ui/core'

import TransitionGroup from '../../../transitions/TransitionGroup'

import withi18n from '../../../contexts/i18n/withi18n'

import Address                  from '../../../display/things/Address'
import Email                    from '../../../display/things/Email'
import MedicalSpecialties       from '../../../display/things/MedicalSpecialties'
import Metadatas                from '../../../display/things/Metadatas'
import RemoveVeterinarianDialog from '../../../display/dialogs/remove/RemoveVeterinarianDialog'
import Telephone                from '../../../display/things/Telephone'
import ThingSubContainer        from '../../../display/containers/ThingSubContainer'
import Veterinarian             from '../../../display/things/Veterinarian'
import Websites                 from '../../../display/things/Websites'

import PostalAddress from '../../../things/PostalAddress'

const half =
{
    md :  6 ,
    sm : 12 ,
    xs : 12
};

class About extends ThingSubContainer
{
    render = () =>
    {
        const { thing } = this.props ;
        if( thing )
        {
            const { path } = this.props ;

            const {
                authority,
                id
            } = thing ;

            let address ;

            if( authority )
            {
                const { address:addr } = authority ;
                if( addr instanceof PostalAddress )
                {
                    address = addr ;
                }
            }

            const { onRemove } = this.props ;

            return (
                <div className='w-full h-full p-16'>
                <TransitionGroup duration={250} enter={{animation: "transition.slideUpIn"}}>

                    <Metadatas
                        activable             = { true }
                        deletable             = { true }
                        onRemove              = { onRemove }
                        RemoveDialogComponent = { RemoveVeterinarianDialog }
                        thing                 = { thing }
                        uri                   = { format(path.veterinarian,id) }
                    />

                    <Grid container spacing={4}>
                        <Grid item {...half}>
                            <Veterinarian
                                editUri  = { format(path.veterinarian,id) }
                                onChange = { this.change }
                                thing    = { thing }
                            />
                            <MedicalSpecialties
                                onChange = { this.change }
                                thing    = { thing }
                                uri      = { path.medicalSpecialties }
                            />
                        </Grid>
                        <Grid item {...half}>
                            <Address
                                thing={ address }
                                optionMode='none'
                            />
                        </Grid>
                        <Grid item {...half}>
                            <Email
                                editable   = { false }
                                thing      = { authority }
                                optionMode = 'none'
                            />
                            <Websites
                                editable   = { false }
                                thing      = { authority }
                                optionMode = 'none'
                            />
                        </Grid>
                        <Grid item {...half}>
                            <Telephone
                                editable   = { false }
                                thing      = { authority }
                                optionMode = 'none'
                            />
                        </Grid>
                    </Grid>
                </TransitionGroup>
                </div>
            );
        }
        return null ;
    }
}

About.defaultProps =
{
    ...ThingSubContainer.defaultProps
};


About.propTypes =
{
    ...ThingSubContainer.propTypes
};

export default withi18n(About) ;
