import init from './thing'

const observation =
{
    ...init,
    owner  : 
    {
        title : 'Créée par' 
    },
    status :
    {
        title : 'État'
    }
};

export default observation ;
