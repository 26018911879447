import soundcloud from './providers/soundcloud'
import youtube    from './providers/youtube'

import
{
    SOUNDCLOUD ,
    YOUTUBE
} from '../../things/creativework/mimetypes'

const providers =
{
    [ SOUNDCLOUD ] : soundcloud ,
    [ YOUTUBE    ] : youtube
}

export default providers ;
