import React from 'react'

import copy from 'copy-to-clipboard'

import compose  from 'vegas-js-core/src/functors/compose'
import format   from 'vegas-js-core/src/strings/fastformat'
import isString from 'vegas-js-core/src/isString'

import initApp   from '../../../contexts/app/initApp'
import withSnack from '../../../contexts/snack/withSnack'

import { withRouter } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'

import { Grid, IconButton } from '@material-ui/core'

import FileCopyIcon from '@material-ui/icons/FileCopy'
import Icon         from '@material-ui/icons/VpnKey'

import LabelItem from '../../../components/items/LabelItem'

import SnackVariant   from '../../../components/snackbars/SnackVariant'
import ThingContainer from '../../containers/ThingContainer'

import Redirect from './oauth/Redirect'
import Secret   from './oauth/Secret'

import OAuthVO from '../../../vo/OAuth'
import ThingVO from '../../../things/Thing'

import auth from '../../../configs/auth'

const styles = () => ({});

const half =
{
    md :  6 ,
    sm : 12 ,
    xs : 12
};

const full =
{
    xs : 12
};

class OAuth extends ThingContainer
{
    getContent = () =>
    {
        let {path, thing} = this.props;

        const locale = this.getLocale();

        const {labels} = locale;

        let api = auth.apis['api'];

        let api_url       = api.url;
        let authorize_url = api.auth_url;
        let token_url     = api.token_url;

        let client_id;

        if( thing )
        {
            const {
                id,
                oAuth
            } = thing;

            if( oAuth instanceof OAuthVO )
            {
                let {
                    client_id: i
                } = oAuth;

                if( isString(i) )
                {
                    client_id = i;
                }
            }

            return (
            <Grid container spacing={4}>

                <Grid item {...half}>

                    <Grid container spacing={4}>

                        <Grid item {...full}>
                            <LabelItem
                                enabled = {!!(api_url)}
                                title   = {labels.api_url}
                            >
                                <IconButton
                                    size='small'
                                    onClick   = { () =>
                                    {
                                        copy( api_url ) ;
                                        const locale = this.getLocale() ;
                                        if( locale )
                                        {
                                            const { labels } = locale ;
                                            if( labels )
                                            {
                                                this.notify( labels.copy , SnackVariant.SUCCESS ) ;
                                            }
                                        }
                                    }}
                                >
                                    <FileCopyIcon />
                                </IconButton>
                                { isString(api_url) ? api_url : labels.empty }
                            </LabelItem>
                        </Grid>

                        <Grid item {...full}>
                            <LabelItem
                                enabled = {!!(authorize_url)}
                                title   = {labels.authorize_url}
                            >
                                <IconButton
                                    size='small'
                                    onClick   = { () =>
                                    {
                                        copy( authorize_url ) ;
                                        const locale = this.getLocale() ;
                                        if( locale )
                                        {
                                            const { labels } = locale ;
                                            if( labels )
                                            {
                                                this.notify( labels.copy , SnackVariant.SUCCESS ) ;
                                            }
                                        }
                                    }}
                                >
                                    <FileCopyIcon />
                                </IconButton>
                                { isString(authorize_url) ? authorize_url : labels.empty }
                            </LabelItem>
                        </Grid>

                        <Grid item {...full}>
                            <LabelItem
                                enabled = {!!(token_url)}
                                title   = {labels.token_url}
                            >
                                <IconButton
                                    size='small'
                                    onClick   = { () =>
                                    {
                                        copy( token_url ) ;
                                        const locale = this.getLocale() ;
                                        if( locale )
                                        {
                                            const { labels } = locale ;
                                            if( labels )
                                            {
                                                this.notify( labels.copy , SnackVariant.SUCCESS ) ;
                                            }
                                        }
                                    }}
                                >
                                    <FileCopyIcon />
                                </IconButton>
                                { isString(token_url) ? token_url : labels.empty }
                            </LabelItem>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item {...half}>

                    <Grid container spacing={4}>

                        <Grid item {...full}>
                            <LabelItem enabled={!!(client_id)} title={labels.client_id}>
                                <IconButton
                                    size='small'
                                    onClick   = { () =>
                                    {
                                        copy( client_id ) ;
                                        const locale = this.getLocale() ;
                                        if( locale )
                                        {
                                            const { labels } = locale ;
                                            if( labels )
                                            {
                                                this.notify( labels.copy , SnackVariant.SUCCESS ) ;
                                            }
                                        }
                                    }}
                                >
                                    <FileCopyIcon />
                                </IconButton>
                                {isString(client_id) ? client_id : labels.empty}
                            </LabelItem>
                        </Grid>

                        <Grid item {...full}>
                            <Secret
                                editUri  = { format(path.secret, id) }
                                onChange = { this.changeProperty('oAuth' , true) }
                                thing    = { oAuth }
                            />
                        </Grid>

                    </Grid>

                </Grid>

                <Grid item {...full}>
                    <Redirect
                        editUri  = { format(path.oAuth, id) }
                        onChange = { this.changeProperty('oAuth' , true) }
                        thing    = { oAuth }
                    />
                </Grid>
            </Grid>);
        }
    };

    getEntry = init =>
    {
        let empty = {} ;
        if( init instanceof ThingVO )
        {
            empty = init.toObject() ;
        }
        else if( init )
        {
            empty = { ...init } ;
        }
        return new OAuthVO(empty);
    };

    getLocale = () => this.props.locale.things.application.oauth;

    notify = ( message , variant = SnackVariant.DEFAULT ) =>
    {
        const { notifySnack } = this.props ;
        if( notifySnack )
        {
            notifySnack(message, variant);
        }
    };
}

OAuth.defaultProps =
{
    ...ThingContainer.defaultProps ,
    icon       : <Icon/> ,
    optionMode : 'none'
};

OAuth.propTypes =
{
    ...ThingContainer.propTypes
};

export default compose( withStyles( styles ) , withRouter , initApp , withSnack )(OAuth) ;
