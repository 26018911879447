import React , { Component } from 'react'

import PropTypes from "prop-types"

import { CircularProgress, Grid, MenuItem, TextField } from '@material-ui/core'

import api           from '../../configs/api'

import LIST       from '../../net/LIST'
import RequestStatus from '../../net/RequestStatus'

class Selector extends Component
{
    constructor(props)
    {
        super(props);

        this.canceler = null ;
    }

    state =
    {
        children : null ,
        errors   : null ,
        value    : '' ,
        status   : RequestStatus.NEW ,
    };

    componentDidMount()
    {
        const { apiUrl , uri, value } = this.props ;
        this.setState( {
            status : RequestStatus.PROGRESS ,
            value  : value || ''
        }) ;
        this.canceler = LIST( apiUrl + uri , {
            cancel  : this._cancel ,
            fail    : this._fail ,
            limit   : 0 ,
            queries : { 'active' : true },
            success : this._success
        }) ;
    }

    componentWillUnmount()
    {
        const { status } = this.state ;
        if( status === RequestStatus.PROGRESS && !!(this.canceler) )
        {
            this.canceler.cancel(this + ' cancel') ;
        }
    }

    render()
    {
        const { disabled , init } = this.props ;
        const { children , error , value , status } = this.state ;

        const { id } = init ;

        let progress = null ;
        if( status === RequestStatus.PROGRESS )
        {
            progress = <Grid item>
                <CircularProgress className="color:indigo[500]" size={28} thickness={7} />
            </Grid>;
        }

        return (
            <Grid container alignItems={"flex-end"} wrap={"nowrap"} spacing={8} direction={"row"} >
                { progress }
                <Grid item className="w-full">
                    <TextField
                        { ...init }
                        className    = "capitalize"
                        value        = { value }
                        disabled     = { disabled || (status === RequestStatus.PROGRESS) }
                        error        = { error }
                        onChange     = { this._change(id) }
                        select       = { Boolean(children) }
                    >
                        { children ? children : null }
                    </TextField>
                </Grid>
            </Grid>
        );
    }

    getChildren( items )
    {
        const { lang } = this.props ;
        return items instanceof Array ? items.map( item => this.getSelectItem( 'select-item-' + item.id , item.id , item.getLocaleName(lang)) ) : null ;
    }

    getSelectItem( key , value , label = null )
    {
        return <MenuItem key={key} className="capitalize" value={value}>{ label || value }</MenuItem> ;
    }

    populate( datas )
    {
        return datas ;
    }

    // ------- PROTECTED

    _cancel = () =>
    {
        this.setState( { errors:null , status : RequestStatus.NEW  } ) ;
        const { onCancel } = this.props ;
        if( onCancel instanceof Function )
        {
            onCancel() ;
        }
    };

    _change = () => event =>
    {
        if( event )
        {
            const { target } = event ;
            if( target )
            {
                const { value } = target ;
                //console.log( this + ' change' , name , target.value ) ;
                const { onChange } = this.props ;
                this.setState( { value } , () =>
                {
                    if( onChange instanceof Function )
                    {
                        onChange( event ) ;
                    }
                }) ;
            }
        }
    };

    _fail = response =>
    {
        if( response )
        {
            if( response.data )
            {
                let data = response.data ;

                if( data.status === 'error' )
                {
                    this.setState( { errors:data.message , status : RequestStatus.FAIL } ) ; // TEST
                    return ;
                }

                switch( data.message )
                {
                    case 'token revoked' :
                    {
                        break ;
                    }
                    default :
                    {
                        console.log( this + " failed, status:" + response.status + ", message:" + data.message , true );
                    }
                }
            }
        }
    };

    _success = response =>
    {
        try
        {
            let items = response.data.result ;
            if( (items instanceof Array) && items.length > 0 )
            {
                this.children = this.getChildren( this.populate(items) ) ;
            }
            else
            {
                this.children = null ;
            }

            this.setState( { status : RequestStatus.NEW , children : this.children } ) ;
        }
        catch( error )
        {
            console.log( this + " _success failed : " + error.message , error ) ;
        }
    };
}

Selector.defaultProps =
{
    apiUrl    : api.url,
    disabled  : false ,
    error     : false ,
    init      : null ,
    onCancel  : null ,
    onChange  : null ,
    uri       : null ,
    value     : ''
};

Selector.propTypes =
{
    apiUrl   : PropTypes.string.isRequired,
    disabled : PropTypes.bool ,
    error    : PropTypes.bool ,
    init     : PropTypes.object ,
    onCancel : PropTypes.func ,
    onChange : PropTypes.func ,
    uri      : PropTypes.string ,
    value    : PropTypes.string
};

export default Selector ;
