import generify from './generify'

import Thing from './Thing'
import Word  from './Word'

/**
 * A material definition object.
 * @memberOf things
 */
class Material extends Thing
{
    /**
     * Creates a new Material instance.
     * @constructor
     * @param object The generic object to initialize the object the first time.
     */
    constructor( object = null )
    {
        super() ;

        /**
         * The material of this object.
         * @type {object}
         */
        this.material = null ;

        /**
         * The technique of this object
         * @type {object}
         */
        this.technique = null ;

        this.set( object ) ;
    }

    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        super.clear() ;
        this.material  =
        this.technique = null ;
        return this ;
    }

    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new Material( this.toObject() ) ;
    }

    /**
     * Populate the object with specific behaviors or types. Launched by default in the constructor and in the set methods.
     * @returns {Thing} The current reference.
     */
    populate()
    {
        const {
            material,
            technique
        } = this;

        if( material )
        {
            this.material = new Word( material ) ;
        }

        if( technique )
        {
            this.technique = new Word( technique ) ;
        }

        return this ;
    }

    /**
     * Returns the generic object representation of the item.
     * @return {Object} The generic object representation of the item.
     */
    toObject()
    {
        let { material, technique } = this ;
        
        material  = generify(material) ;
        technique = generify(technique) ;
        
        return {
            ...super.toObject() ,
            material,
            technique
        };
    }
}

export default Material ;