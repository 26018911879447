
import Form   from '../../../../display/forms/FormElements'
import Method from "../../../../net/Method";

const elements =
[
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'minValue',
            key          : 'minValue',
            label        : 'Elévation minimum',
            autoFocus    : false,
            fullWidth    : true,
            /*InputProps   : {
                inputComponent : ( input ) =>
                (
                    <NumberInput
                        allowNegative = {false}
                        {...input}
                    />
                )
            },*/
            helperText   : 'Valeur en mètres',
            margin       : 'normal',
            required     : true,
            select       : false,
            autoComplete : 'off' ,
            type         : 'text',
            variant      : 'outlined'
        }
    },
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'maxValue',
            key          : 'maxValue',
            label        : 'Elévation minimum',
            autoFocus    : false,
            fullWidth    : true,
            /*InputProps   : {
                inputComponent : ( input ) =>
                (
                    <NumberInput
                        allowNegative = {false}
                        {...input}
                    />
                )
            },*/
            helperText   : 'Valeur en mètres',
            margin       : 'normal',
            required     : true,
            select       : false,
            autoComplete : 'off' ,
            type         : 'text',
            variant      : 'outlined'
        }
    },
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'gain',
            key          : 'gain',
            label        : 'Dénivelé positif',
            autoFocus    : false,
            fullWidth    : true,
            /*InputProps   : {
                inputComponent : ( input ) =>
                (
                    <NumberInput
                        allowNegative = {false}
                        {...input}
                    />
                )
            },*/
            helperText   : 'Valeur en mètres',
            margin       : 'normal',
            required     : true,
            select       : false,
            autoComplete : 'off' ,
            type         : 'text',
            variant      : 'outlined'
        }
    },
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'loss',
            key          : 'loss',
            label        : 'Dénivelé négatif',
            autoFocus    : false,
            fullWidth    : true,
            /*InputProps   : {
                inputComponent : ( input ) =>
                (
                    <NumberInput
                        allowNegative = {false}
                        {...input}
                    />
                )
            },*/
            helperText   : 'Valeur en mètres',
            margin       : 'normal',
            required     : true,
            select       : false,
            autoComplete : 'off' ,
            type         : 'text',
            variant      : 'outlined'
        }
    }
];

const prepare = ( item ) =>
{
    let {
        gain,
        loss,
        name,
        maxValue,
        minValue
    } = item ;
    
    const object = (
    {
        gain ,
        loss ,
        maxValue ,
        minValue ,
        name
    });
    return object ;
};

const elevation =
{
    edit :
    {
        agree       : 'Sauver' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour modifier les informations d\'élevation du parcours.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...' ,
        method      : Method.PATCH ,
        success     : 'Mise à jour réussie!' ,
        title       : 'Modifier l\'élévation' ,
        elements,
        prepare
    },
    labels :
    {
        empty    : '----' ,
        gain     : 'Dénivelé positif (m)',
        loss     : 'Dénivelé négatif (m)',
        maxValue : 'Elévation maximum (m)',
        minValue : 'Elévation minimum (m)'
    },
    title    : 'Élevation' ,
    tooltips :
    {
        add    : 'Ajouter',
        edit   : 'Modifier',
        remove : 'Supprimer'
    }
};

export default elevation ;