import React, { Component } from 'react'

import clsx from 'clsx'

import PropTypes from 'prop-types'

import isString from 'vegas-js-core/src/isString'

import { withStyles } from '@material-ui/core/styles'

import { Fab, Tooltip } from '@material-ui/core'

import EditIcon from '@material-ui/icons/Edit'

import Animate from '../../transitions/Animate'

const styles =
{
    root :
    {
        marginLeft : 10
    }
};

class EditButton extends Component
{
    render = () =>
    {
        const {
            className,
            classes,
            color,
            locale,
            onClick,
            placement
        } = this.props;

        let { icon } = this.props ;
        if( !icon )
        {
            icon = <EditIcon/>;
        }

        let button = (
        <Fab
            size    = 'small'
            color   = { color }
            onClick = { onClick }
        >
            { icon }
        </Fab>);

        if( locale )
        {
            const { tooltips } = locale ;
            if( tooltips )
            {
                const { edit } = tooltips ;
                if( isString(edit) )
                {
                   button = (
                    <Tooltip
                        placement = {placement}
                        title     = { edit }
                    >
                        { button }
                    </Tooltip>);
                }
            }
        }

        button = <div className={clsx(classes.option, className)}>{button}</div>;

        const { animate } = this.props;
        if (animate)
        {
            const { animateDelay } = this.props;
            return (
                <Animate enter="transition.expandIn" delay={animateDelay}>
                    {button}
                </Animate>);
        }
        else
        {
            return button;
        }
    };
}

EditButton.defaultProps =
{
    animate      : false,
    animateDelay : 1000,
    className    : null  ,
    color        : 'secondary' ,
    icon         : <EditIcon/>,
    locale       : null,
    onClick      : null,
    placement    : 'left'
};

EditButton.propTypes =
{
    animate      : PropTypes.bool,
    animateDelay : PropTypes.number,
    classes      : PropTypes.object.isRequired ,
    className    : PropTypes.string,
    color        : PropTypes.string,
    icon         : PropTypes.element,
    locale       : PropTypes.object,
    onClick      : PropTypes.func,
    placement    : PropTypes.oneOf(['bottom-end', 'bottom-start', 'bottom', 'left-end', 'left-start', 'left', 'right-end', 'right-start', 'right', 'top-end', 'top-start', 'top']),
};

export default withStyles( styles )( EditButton );
