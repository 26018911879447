import React from 'react'

import moment from 'moment/moment'

import compose  from 'vegas-js-core/src/functors/compose'
import isString from 'vegas-js-core/src/isString'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import initApp from '../../contexts/app/initApp'

import { Link , withRouter } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'

import { Grid } from '@material-ui/core'

import EventIcon    from '@material-ui/icons/Event'
import LabelIcon    from '@material-ui/icons/Label'
import ScheduleIcon from '@material-ui/icons/Schedule'
import TodayIcon    from '@material-ui/icons/CalendarToday'

import LabelItem from '../../components/items/LabelItem'

import ThingContainer from '../containers/ThingContainer'

import api from '../../configs/api'

import EventVO from '../../things/Event'
import ThingVO from '../../things/Thing'
import WordVO  from '../../things/Word'

const styles = () => ({});

const half =
{
    md :  6 ,
    sm : 12 ,
    xs : 12
};

class Event extends ThingContainer
{
    editPrepare = item =>
    {
        let {
            additionalType,
            endDate,
            eventStatus,
            location,
            name,
            startDate
        } = item ;

        return (
        {
            name,

            additionalType : additionalType instanceof ThingVO
                           ? String(additionalType.id)
                           : null ,

            endDate : endDate instanceof Date
                    ? moment(endDate).toISOString()
                    : null ,

            eventStatus : eventStatus instanceof ThingVO
                        ? String(eventStatus.id)
                        : null ,

            location : location instanceof ThingVO
                     ? String(location.id)
                     : null,

            startDate : startDate instanceof Date
                      ? moment(startDate).toISOString()
                      : null
        });
    };

    getContent = () =>
    {
        let { lang, thing } = this.props;

        const locale = this.getLocale() ;

        const { labels } = locale ;

        let indicator ;
        let end ;
        let location ;
        let name ;
        let options ;
        let type ;
        let start ;
        let status ;

        if( thing instanceof EventVO )
        {
            let {
                additionalType,
                endDate,
                eventStatus,
                location : l ,
                name : n,
                startDate,
            } = thing ;

            if( isString( n ) )
            {
                name = n ;
            }

            if( additionalType instanceof ThingVO )
            {
                type = additionalType.getLocaleName( lang ) ;
            }

            if( isString( endDate ) )
            {
                end = moment(endDate).format(locale.datePattern)
            }

            if( eventStatus instanceof WordVO )
            {
                const { color } = eventStatus ;
                if( isString( color ) )
                {
                    indicator = (
                    <LabelIcon
                        className = "mr-8 self-center"
                        style     = {{ color : "#" + color }}
                    />);
                }
                status = eventStatus.getLocaleName( lang )
            }

            if( l instanceof ThingVO )
            {
                options =
                {
                    component : Link ,
                    to        :
                    {
                        pathname  : l.url.split(api.url)[1]
                    }
                };
                location = l.getLocaleName( lang ) ;
            }
            else
            {
                location = null ;
            }

            if( isString( startDate ) )
            {
                start = moment(startDate).format(locale.datePattern)
            }
        }

        const { scheduleIcon } = this.props ;

        return (
            <Grid container spacing={4}>

                <Grid item {...half}>
                    <LabelItem
                        divider = { false }
                        enabled = {!!(name)}
                        title   = { labels.name }
                    >
                        { isString(name) ? ucFirst(name) : labels.empty }
                    </LabelItem>
                </Grid>

                <Grid item {...half}>
                    <LabelItem
                        divider   = { false }
                        enabled   = {!!(type)}
                        labelIcon = {<TodayIcon className="mr-8 self-center" style={{ fontSize:18 }}/>}
                        title     = { labels.type }
                    >
                        { isString(type) ? ucFirst(type) : labels.empty }
                    </LabelItem>
                </Grid>

                <Grid item {...half}>
                    <LabelItem
                        divider   = { false }
                        enabled   = {!!(start)}
                        labelIcon = { scheduleIcon }
                        title     = { labels.startDate }
                    >
                        { isString(start) ? ucFirst(start) : labels.empty }
                    </LabelItem>
                </Grid>
                <Grid item {...half}>
                    <LabelItem
                        divider   = { false }
                        enabled   = {!!(end)}
                        labelIcon = { scheduleIcon }
                        title     = { labels.endDate }
                    >
                        { isString(end) ? ucFirst(end) : labels.empty }
                    </LabelItem>
                </Grid>

                <Grid item {...half}>
                    <LabelItem
                        divider   = { false }
                        enabled   = {!!(status)}
                        title     = { labels.eventStatus }
                        labelIcon = { indicator }
                    >
                        { isString(status) ? ucFirst(status) : labels.empty }
                    </LabelItem>
                </Grid>

                <Grid item {...half}>
                    <LabelItem
                        divider = { false }
                        enabled = {!!(location)}
                        title   = { labels.location }
                        { ...options }
                    >
                        { isString(location) ? ucFirst(location) : labels.empty }
                    </LabelItem>
                </Grid>
            </Grid>
        );
    };

    getEntry = init =>
    {
        let empty = {} ;
        if( init instanceof ThingVO )
        {
            empty = init.toObject() ;
        }
        else if( init )
        {
            empty = { ...init } ;
        }
        return new EventVO(empty);
    };

    getLocale = () => this.props.locale.things.event;

    onEdit = item =>
    {
        const { onChange, thing } = this.props ;
        if( thing && item )
        {
            const {
                additionalType,
                endDate,
                eventStatus,
                location,
                name,
                startDate
            } = item ;

            thing.additionalType = additionalType ;
            thing.endDate        = endDate;
            thing.eventStatus    = eventStatus;
            thing.location       = location;
            thing.modified       = moment(new Date()).toISOString();
            thing.name           = name ;
            thing.startDate      = startDate ;

            if( thing instanceof ThingVO )
            {
                thing.populate();
            }

            if( this._mounted )
            {
                this.forceUpdate();
            }

            if( onChange instanceof Function )
            {
                onChange( thing ) ;
            }
        }
    };
}

Event.defaultProps =
{
    ...ThingContainer.defaultProps ,
    icon         : <EventIcon/> ,
    optionMode   : 'edit',
    scheduleIcon : <ScheduleIcon className="mr-8 self-center" style={{ fontSize:18 }}/>
};

Event.propTypes =
{
    ...ThingContainer.propTypes
};

export default compose( withStyles( styles ) , withRouter , initApp )( Event ) ;
