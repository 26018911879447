import React from 'react'

import withConfig  from '../../../../../../contexts/config/withConfig'
import withDialogs from '../../../../../../contexts/dialogs/withDialogs'
import withi18n    from '../../../../../../contexts/i18n/withi18n'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import blueGrey from '@material-ui/core/colors/blueGrey'

import { FaStethoscope } from 'react-icons/fa'

import ChipsContainer, { styles } from '../../../../../containers/ChipsContainer'

import AnnualHealthReview from '../../../../../../things/livestock/reviews/AnnualHealthReview'
import Disease            from '../../../../../../things/medical/Disease'
import Sector             from '../../../../../../things/Sector'
import SectorHealthEvent  from '../../../../../../things/livestock/events/SectorHealthEvent'
import Thing              from '../../../../../../things/Thing'

const css = () => (
{
    ...styles,
    chip :
    {
        backgroundColor : blueGrey[100],
        margin          : 8
    }
});

class Diseases extends ChipsContainer
{
    editClazz = () => Disease ;

    editInit = ( item ) =>
    {
        if( item )
        {
            const { hypothesis } = item ;
            if( hypothesis )
            {
                const { diseases } = hypothesis ;
                if( diseases instanceof Array )
                {
                    return diseases.map( element =>
                    {
                        if( element instanceof Thing )
                        {
                            return element.clone() ;
                        }
                        return null ;
                    })
                }
            }
        }
        return null ;
    };

    editPrepare = ( datas , props ) =>
    {
        // console.log( this + ' editPrepare datas ::::' , datas ) ;

        const { item:current } = props ;
        if( current instanceof SectorHealthEvent )
        {
            let {
                id:key,
                subjectOf:review
            }
            = current ;

            if( review instanceof AnnualHealthReview )
            {
                const { about } = current ;
                if( about instanceof Sector )
                {
                    const { id } = about ;
                    if( id )
                    {
                        let { subReview } = review ;
                        if( subReview instanceof Array )
                        {
                            let len1 = subReview.length ;
                            for( let i = 0 ; i<len1 ; i++ )
                            {
                                let workplace = subReview[i] ;
                                if( workplace )
                                {
                                    let { subReview } = workplace ;
                                    if( subReview instanceof Array )
                                    {
                                        let len2 = subReview.length ;
                                        for( let j = 0 ; j<len2 ; j++  )
                                        {
                                            let sector = subReview[j] ;
                                            if( sector )
                                            {
                                                let { about } = sector ;
                                                if( about )
                                                {
                                                    let { id:index } = about ;
                                                    if( index === id )
                                                    {
                                                        const { events } = sector ;
                                                        if( events instanceof Array )
                                                        {
                                                            let len3 = events.length ;
                                                            for( let z = 0 ; z<len3 ; z++ )
                                                            {
                                                                let event = events[z] ;
                                                                if( event )
                                                                {
                                                                    const { id } = event ;
                                                                    if( key === id )
                                                                    {
                                                                        const { hypothesis } = event ;
                                                                        if( hypothesis )
                                                                        {
                                                                            hypothesis.diseases = datas ;
                                                                        }

                                                                        review = review.toObject(false) ;

                                                                        // console.log( this + ' editPrepare review ::::' , review ) ;

                                                                        review = JSON.stringify(review) ;

                                                                        return { review } ;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        return null ;
    };

    getEntry = init =>
    {
        const { subjectOf } = this.props ;

        let empty = {} ;

        if( init instanceof Thing )
        {
            empty = init.toObject() ;
        }
        else if( init )
        {
            empty = { ...init } ;
        }

        return new SectorHealthEvent( { ...empty , subjectOf } ) ;
    };

    getLocale = () => this.props.locale.things.livestock.reviews.annual.medical.diseases ;

    getThing = () =>
    {
        const { thing } = this.props ;
        if( thing )
        {
            const { hypothesis } = thing ;
            return hypothesis ;
        }
        return null ;
    };

    onEdit = ( item ) =>
    {
        const { onChange } = this.props ;
        if( onChange )
        {
            onChange( item ) ;
        }
    };
}

Diseases.defaultProps =
{
    ...ChipsContainer.defaultProps ,
    clazz       : Disease,
    chipVariant : 'default' ,
    icon        : <FaStethoscope size={18}/>,
    member      : 'diseases'
};

Diseases.propTypes =
{
    ...ChipsContainer.propTypes
};

export default withStyles( css ) ( withRouter( withi18n( withConfig( withDialogs( Diseases ) ) ) ) ) ;
