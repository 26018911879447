import initDialog from '../initDialog'

import { SelectThingDialog , styles } from '../SelectThingDialog'

import api from '../../../configs/api'

import Article from '../../../things/creativework/Article'

class SelectArticleDialog extends SelectThingDialog
{
    getLocale = () => this.props.locale.dialogs.select.article ;
}

SelectArticleDialog.defaultProps =
{
    ...SelectThingDialog.defaultProps,
    clazz : Article ,
    uri   : api.articles.url
};

SelectArticleDialog.propTypes =
{
    ...SelectThingDialog.propTypes,
};

export default initDialog({ styles , useSearch:false })( SelectArticleDialog ) ;
