import React from 'react'

import compose from 'vegas-js-core/src/functors/compose'

import initApp from '../../../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { FaFlask } from 'react-icons/fa'

import AnalysisMethod from './AnalysisMethod'
import ThingChildren , { styles } from '../../ThingChildren'

import AddAnalysisMethodDialog from './dialogs/AddAnalysisMethodDialog'

import MedicalMethod  from '../../../../things/medical/MedicalMethod'
import Thing          from '../../../../things/Thing'

export const prepare = item =>
{
    let {
        additionalType,
        mandatory
    } = item ;

    return (
    {
        additionalType : additionalType instanceof Thing ? String(additionalType.id) : null ,
        mandatory
    });
};

class AnalysisMethods extends ThingChildren
{
    createChild = ( child , editable = false ) =>
    {
        return (
        <AnalysisMethod
            editable = { editable }
            thing    = { child }
            onClick  = { () => this.openEditDialog(child) }
        />);
    };

    editPrepare = prepare ;

    getLocale = () => this.props.locale.things.medical.analysisMethods ;

    openAddDialog = () =>
    {
        const { addDialog } = this.props;
        if ( addDialog )
        {
            const { addUri } = this.props ;
            addDialog(
                AddAnalysisMethodDialog ,
                {
                    item  : new MedicalMethod() ,
                    onAdd : this.onAdd ,
                    uri   : addUri
                }
            ) ;
        }
    }
}

AnalysisMethods.defaultProps =
{
    ...ThingChildren.defaultProps,
    clazz     : MedicalMethod,
    emptyIcon : null,
    icon      : <FaFlask className="self-center" style={{ fontSize:20 }}/>,
    member    : 'analysisMethod'
};

AnalysisMethods.propTypes =
{
    ...ThingChildren.propTypes
};

export default compose( withStyles( styles ) , withRouter , initApp )( AnalysisMethods ) ;
