// --------------------------------------------------------------------------------------------

import {
    YOUTUBE
}
from '../../things/creativework/mimetypes'

export const DEFAULT = 'default' ;
export const OEMBED  = 'oembed' ;

const defaultValidators =
[
    { encodingFormat : YOUTUBE , type : OEMBED }
];

export default defaultValidators ;

// --------------------------------------------------------------------------------------------

//import getYoutubeThumbnailUrl from './getYoutubeThumbnailUrl'
//import isYouTubeUrl           from './isYouTubeUrl'
// const defaultValidators =
// [
//     { encodingFormat:YOUTUBE , type:NORMAL validate:isYouTubeUrl , thumbify:getYoutubeThumbnailUrl }
// ];

// --------------------------------------------------------------------------------------------