import React from 'react'

import isString from "vegas-js-core/src/isString"
import ucFirst from "vegas-js-core/src/strings/ucFirst"

import { Typography } from '@material-ui/core'

import QuestionIcon from '@material-ui/icons/QuestionAnswer'

import Form        from '../../../../display/forms/FormElements'
import NumberInput from '../../../../components/inputs/NumberInput'

import api    from '../../../../configs/api'
import Method from "../../../../net/Method"

import Question from "../../../../things/Question"

const elements =
[
    {
        type : Form.I18N_EDITOR ,
        init :
        {
            id       : 'alternateName',
            key      : 'alternateName',
            input    : true,
            label    : 'Titre'
        }
    },
    {
        type       : Form.SEARCH_SELECTOR ,
        path       : api.thesaurus.questions_types,
        clearLabel : 'Vider' ,
        closeLabel : 'Fermer' ,
        init       :
        {
            id         : 'additionalType',
            key        : 'additionalType',
            label      : 'Type de question',
            autoFocus  : false,
            fullWidth  : true,
            helperText : 'Obligatoire',
            margin     : 'normal',
            required   : true,
            type       : 'search',
            variant    : 'outlined'
        },
        inputProps :
        {
            label : 'Type de question'
        },
        searchIcon : <QuestionIcon/>
    },
    {
        type : Form.I18N_EDITOR ,
        init :
        {
            id    : 'description',
            key   : 'description',
            editorStyle : { minHeight : 80 } ,
            input : false,
            label : 'Description'
        }
    },
    {
        type : Form.DIVIDER,
        init :
        {
            key     : 'divider_1',
            light   : false ,
            variant : 'fullWidth'
        }
    },
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'score',
            key          : 'score',
            label        : 'Score de la question',
            autoFocus    : false,
            fullWidth    : true,
            InputProps   : {
                inputComponent : ( input ) => (
                    <NumberInput
                        allowNegative = { false }
                        useFormatted  = { true }
                        //floatCount    = { 0 }
                        {...input}
                    />
                )
            },
            margin       : 'normal',
            select       : false,
            autoComplete : 'off' ,
            type         : 'text',
            variant      : 'outlined'
        }
    },
    // {
    //     type : Form.SWITCH ,
    //     init :
    //     {
    //         id    : 'multiple',
    //         key   : 'multiple',
    //         label : 'Choix multiple'
    //     }
    // },
    {
        type : Form.DIVIDER,
        init :
        {
            key     : 'divider_2',
            light   : false ,
            variant : 'fullWidth'
        }
    },
    {
        type : Form.I18N_EDITOR ,
        init :
        {
            id       : 'success',
            key      : 'success',
            input    : true,
            label    : 'Message de réussite'
        }
    },
    {
        type : Form.I18N_EDITOR ,
        init :
        {
            id       : 'fail',
            key      : 'fail',
            input    : true,
            label    : 'Message d\'erreur'
        }
    },
    {
        type : Form.I18N_EDITOR ,
        init :
        {
            id       : 'tip',
            key      : 'tip',
            input    : true,
            label    : 'Message d\'indice'
        }
    }
];

const questions =
{
    add :
    {
        elements,
        agree       : 'Créer' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour créer une nouvelle question.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...' ,
        method      : Method.PATCH,
        mock        : false ,
        success     : 'Ajout réussi de la nouvelle question !' ,
        title       : 'Ajouter une question'
    },
    edit :
    {
        elements,
        agree       : 'Sauver' ,
        disagree    : 'Annuler' ,
        description : null ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en  cours...' ,
        method      : Method.PATCH,
        mock        : false ,
        success     : 'Mise à jour réussie!' ,
        title       : 'Modifier la question'
    },
    labels :
    {
        add : 'Ajouter une question'
    },
    remove :
    {
        agree       : 'Supprimer' ,
        description : 'Souhaitez-vous supprimer la question :' ,
        disagree    : 'Annuler' ,
        item        :
        {
            render : ( item , props ) =>
            {
                let label ;

                if( item instanceof Question )
                {
                    const { lang } = props ;
                    label = item.getLocaleAlternateName(lang) ;
                }

                if( !isString(label) || label === '' )
                {
                    label = questions.unknown ;
                }

                if( isString(label) )
                {
                    label = ucFirst(label) ;
                }

                return <Typography className='px-16'>{label}</Typography> ;

            }
        },
        loading : 'Suppression en cours...' ,
        method  : Method.PATCH ,
        title   : 'Supprimer la question ?',
        useID   : false
    },
    unknown  : 'Inconnu' ,
    title    : 'Question(s)' ,
    tooltips :
    {
        add    : 'Ajouter' ,
        edit   : 'Éditer' ,
        remove : 'Supprimer'
    }
};

export default questions ;
