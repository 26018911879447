import Form from '../../../../display/forms/FormElements'

import Place from '../../../../things/Place'

import api from '../../../../configs/api'

const showLocation = ( { props : { thingRef } = {} } = {} ) => !( thingRef instanceof Place ) ;

const elements =
[
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'name',
            label        : 'Nom de l\'objet',
            autoFocus    : true,
            fullWidth    : true,
            helperText   : 'Obligatoire',
            margin       : 'normal',
            required     : true,
            select       : false,
            autoComplete : 'off' ,
            type         : 'text',
            variant      : 'outlined'
        }
    },
    {
        type : Form.SEARCH_SELECTOR ,
        path : api.thesaurus.conceptualObject_categories ,
        init :
        {
            id         : 'category',
            key        : 'category',
            label      : 'Catégorie',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            type       : 'search',
            variant    : 'outlined'
        },
        selector : { i18n :
        {
            empty       : 'Aucune catégorie disponible.' ,
            title       : 'Sélectionner une catégorie',
            description : 'Veuillez sélectionner une catégorie d\'objet ci-dessous.' ,
        }}
    },
    {
        type    : Form.SEARCH_SELECTOR ,
        visible : showLocation ,
        clazz   : Place ,
        path    : api.places.url ,
        init    :
        {
            id        : 'location',
            key       : 'location',
            label     : 'Localisation de l\'objet',
            autoFocus : false,
            fullWidth : true,
            margin    : 'normal',
            type      : 'search',
            variant   : 'outlined'
        },
        selector : { i18n :
        {
            empty       : 'Aucun lieu disponible.' ,
            title       : 'Sélectionner un lieu',
            description : 'Veuillez sélectionner un lieu ci-dessous.' ,
        }}
    }
];

export default
{
    add :
    {
        agree       : 'Créer' ,
        disagree    : 'Annuler' ,
        title       : 'Ajouter un objet' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour créer une nouvelle fiche.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en  cours...' ,
        success     : 'Ajout du nouvel enregistrement réussi!' ,
        elements
    },
    remove :
    {
        agree        : 'Supprimer' ,
        description  : 'Souhaitez-vous supprimer l\'objet :' ,
        descriptionX : 'Souhaitez-vous supprimer les {0} objets :' ,
        disagree     : 'Annuler' ,
        loading      : 'Suppression en cours...' ,
        title        : 'Supprimer cet objet ?',
        titleX       : 'Supprimer des objets ?'
    }
};
