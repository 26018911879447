import React , { Fragment } from 'react'

import clean    from 'vegas-js-core/src/objects/clean'
import isString from 'vegas-js-core/src/isString'

import withDialogs from '../../../../../../contexts/dialogs/withDialogs'
import withi18n    from '../../../../../../contexts/i18n/withi18n'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { FaCheckSquare } from 'react-icons/fa'
import { FaFlask       } from 'react-icons/fa'
import { FaMinusSquare } from 'react-icons/fa'
import { FaPenSquare   } from 'react-icons/fa'

import { Typography } from '@material-ui/core'

import ThingContainer from '../../../../../containers/ThingContainer'
import LabelItem      from '../../../../../../components/items/LabelItem'

import AnnualHealthReview from '../../../../../../things/livestock/reviews/AnnualHealthReview'
import MedicalAnalysis    from '../../../../../../things/medical/MedicalAnalysis'
import SectorHealthEvent  from '../../../../../../things/livestock/events/SectorHealthEvent'
import Thing              from '../../../../../../things/Thing'

const styles = () => ({});

class Analysis extends ThingContainer
{
    change = thing =>
    {
        const { onChange } = this.props ;
        if( onChange instanceof Function )
        {
            onChange( thing ) ;
        }
    };

    getContent = () =>
    {
        let {
            lang,
            thing
        } = this.props;

        let label ;
        let text ;

        if( thing )
        {
            const { analysis } = thing ;
            if( analysis instanceof MedicalAnalysis )
            {
                let {
                    confirmed,
                    description
                } = analysis ;

                const locale = this.getLocale() ;

                const { labels } = locale ;

                label = (
                <div className='flex flex-row items-center my-16 ml-16'>
                    { confirmed
                        ? <FaCheckSquare size='1.5em' className='mr-16' />
                        : <FaMinusSquare size='1.5em' className='mr-16' />
                    }
                    <LabelItem title={labels.confirmed}>
                        { confirmed ? labels.yes : labels.no }
                    </LabelItem>
                </div>);

                if( description )
                {
                    description = analysis.getLocaleDescription(lang) ;

                    if( isString(description) && description !== '' )
                    {
                        text = (
                        <div className='flex flex-row items-center my-16 ml-16'>
                            <FaPenSquare size='1.5em' className='mr-16' />
                            <Typography
                                className = 'w-full'
                                variant   = 'body2'
                            >
                                { description }
                            </Typography>
                        </div>
                        )
                    }
                }
            }

            return (
                <Fragment>
                    { label }
                    { text }
                </Fragment>
            )
        }

        return null ;
    };

    getEntry = init =>
    {
        if( init )
        {
            let empty = {} ;

            let { analysis } = init ;

            if( analysis instanceof Thing )
            {
                empty = analysis.toObject() ;
            }
            else if( analysis )
            {
                empty = { ...analysis } ;
            }

            analysis = new MedicalAnalysis(empty);

            analysis.about = init ;

            const { subjectOf } = this.props ;
            if( subjectOf )
            {
                analysis.subjectOf = subjectOf ;
            }

            return analysis ;
        }

        return null ;
    };

    getLocale = () => this.props.locale.things.livestock.reviews.annual.medical.analysis;

    onEdit = ( item ) =>
    {
        this.change( item ) ;
    };

    editPrepare = ( datas ) =>
    {
        if( datas )
        {
            let {
                about     : event,
                subjectOf : review,
                confirmed,
                description

            } = datas ;

            confirmed = !!(confirmed) ;

            if( (event instanceof SectorHealthEvent) && (review instanceof AnnualHealthReview) )
            {
                const { id : index } = event ;

                review = review.toObject() ;

                let { subReview } = review ;
                if( subReview instanceof Array )
                {
                    let len1 = subReview.length ;
                    for( let i = 0 ; i<len1 ; i++ )
                    {
                        let workplace = subReview[i] ;
                        if( workplace )
                        {
                            let { subReview } = workplace ;
                            if( subReview instanceof Array )
                            {
                                let len2 = subReview.length ;
                                for( let j = 0 ; j<len2 ; j++  )
                                {
                                    let sectorReview = subReview[j] ;
                                    if( sectorReview )
                                    {
                                        let { events } = sectorReview ;
                                        if( events instanceof Array )
                                        {
                                            let len3 = events.length ;
                                            for( let k = 0 ; k<len3 ; k++ )
                                            {
                                                let event = events[k] ;
                                                if( event && event.id )
                                                {
                                                    if( index === event.id )
                                                    {
                                                        event.analysis = clean(
                                                        {
                                                            confirmed,
                                                            description
                                                        });
                                                        review = JSON.stringify(review) ;
                                                        return { review } ;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        return null ;
    };
}

Analysis.defaultProps =
{
    ...ThingContainer.defaultProps ,
    icon       : <FaFlask size={20}/> ,
    optionMode : 'edit'
};

Analysis.propTypes =
{
    ...ThingContainer.propTypes
};

export default withStyles( styles )( withRouter( withi18n( withDialogs( Analysis ) ) ) ) ;
