import React from 'react'

import compose  from 'vegas-js-core/src/functors/compose'

import initApp from '../../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import TollIcon   from '@material-ui/icons/Toll'

import PropertyValue from '../../../things/PropertyValue'
import Word          from '../../../things/Word'

import ThingChildren, { styles } from '../ThingChildren'

class Jobs extends ThingChildren
{
    getLocale = () => this.props.locale.things.person.jobs ;
    
    sort = ( a , b ) =>
    {
        if( a instanceof Word && b instanceof Word )
        {
            return a.localeCompare(b,this.props.lang);
        }
        return 0;
    };
}

Jobs.defaultProps =
{
    ...ThingChildren.defaultProps ,
    clazz      : Word,
    emptyClazz : PropertyValue,
    emptyIcon  : null ,
    icon       : <TollIcon/> ,
    member     : 'job'
};

Jobs.propTypes =
{
    ...ThingChildren.propTypes
};

export default compose( withStyles( styles ) , withRouter , initApp )( Jobs ) ;