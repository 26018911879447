import React from 'react';

import clsx from 'clsx'

import { withRouter } from 'react-router-dom'

import format from 'vegas-js-core/src/strings/fastformat'

import ThingSubContainer from '../../../display/containers/ThingSubContainer'

import Activities                from '../../../display/things/Activities'
import ContainedInPlace          from '../../../display/things/place/ContainedInPlace'
import ContainsPlace             from '../../../display/things/place/ContainsPlace'
import Email                     from '../../../display/things/Email'
import Metadatas                 from '../../../display/things/Metadatas'
import Offers                    from '../../../display/things/Offers'
import OpeningHoursSpecification from '../../../display/things/OpeningHoursSpecification'
import Permits                   from '../../../display/things/Permits'
import Prohibitions              from '../../../display/things/Prohibitions'
import Services                  from '../../../display/things/Services'
import Telephone                 from '../../../display/things/Telephone'
import Websites                  from '../../../display/things/Websites'
import Logo                      from "../../../display/things/medias/Logo"

import RemovePlaceDialog from '../../../display/dialogs/remove/RemovePlaceDialog'

class Info extends ThingSubContainer
{
    render = () =>
    {
        const { thing } = this.props ;
        if( thing )
        {
            const { id } = thing ;

            const {
                className,
                path,
                onRemove,
                style
            } = this.props ;

            let view = (
            <div className="flex-1 flex flex-col space-y-16">

                <Metadatas
                    activable             = { true }
                    deletable             = { true }
                    RemoveDialogComponent = { RemovePlaceDialog }
                    onRemove              = { onRemove }
                    thing                 = { thing }
                    uri                   = { format(path.place,id) }
                />

                <div className='grid grid-cols-1 sm:grid-cols-2 gap-16'>
                    <div>
                        <Email
                            onChange = { this.change }
                            thing    = { thing }
                            uri      = { path.email }
                        />
                    </div>
                    <div>
                        <Telephone
                            onChange  = { this.change }
                            thing     = { thing }
                            uri       = { path.telephone }
                        />
                    </div>
                    <div>
                        <Websites
                            onChange = { this.change  }
                            thing    = { thing }
                            uri      = { path.websites }
                        />
                    </div>
                    <div>
                        <Logo
                            onChange = { this.change }
                            thing    = { thing }
                            uri      = { path.logo }
                        />
                    </div>
                    <div>
                        <OpeningHoursSpecification
                            onChange = { this.change }
                            thing    = { thing }
                            uri      = { path.openingHoursSpecification }
                        />
                    </div>
                    <div>
                        <Offers
                            onChange = { this.change }
                            thing    = { thing }
                            uri      = { path.offers }
                        />
                    </div>
                    <div>
                        <Services
                            editUri  = { format(path.services,id) }
                            onChange = { this.changeProperty('services') }
                            thing    = { thing }
                        />
                    </div>
                    <div>
                        <Activities
                            editUri  = { format(path.activities,id) }
                            onChange = { this.changeProperty('activities') }
                            thing    = { thing }
                        />
                    </div>
                    <div>
                        <Permits
                            editUri  = { format(path.permits,id) }
                            onChange = { this.changeProperty('permits') }
                            thing    = { thing }
                        />
                    </div>
                    <div>
                        <Prohibitions
                            editUri  = { format(path.prohibitions,id) }
                            onChange = { this.changeProperty('prohibitions') }
                            thing    = { thing }
                        />
                    </div>
                    <div>
                        <ContainsPlace
                            onChange = { this.change }
                            thing    = { thing }
                            uri      = { path.containsPlace }
                        />
                    </div>
                    <div>
                        <ContainedInPlace
                            onChange = { this.change }
                            thing    = { thing }
                            uri      = { path.containedInPlace }
                        />
                    </div>
                </div>

            </div> ) ;

            return (
            <div
                className = { clsx( 'flex-1 p-12' , className ) }
                style     = { style }
            >
                { this.renderWithTransition(view) }
            </div>
            );
        }
        return null ;
    }
}

Info.defaultProps =
{
    ...ThingSubContainer.defaultProps
};

Info.propTypes =
{
    ...ThingSubContainer.propTypes
};

export default withRouter( Info ) ;
