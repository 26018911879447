import React        from 'react'

import format  from 'vegas-js-core/src/strings/fastformat'

import clsx from 'clsx'

import DescriptionIcon from '@material-ui/icons/Notes'
import NoteIcon        from '@material-ui/icons/SpeakerNotes'
import SubtitleIcon    from '@material-ui/icons/ShortText'
import TextIcon        from '@material-ui/icons/Subject'
import TitleIcon       from '@material-ui/icons/Title'

import withLocale from '../../../contexts/i18n/withLocale'

import Audio               from '../../../display/things/medias/Audio'
import Image               from '../../../display/things/medias/Image'
import Metadatas           from '../../../display/things/Metadatas'
import Article             from '../../../display/things/Article'
import MultiLangEditor     from '../../../components/editors/MultiLangEditor'
import RemoveArticleDialog from '../../../display/dialogs/remove/RemoveArticleDialog'
import ThingSubContainer   from '../../../display/containers/ThingSubContainer'
import Video               from '../../../display/things/medias/Video'
import Websites            from '../../../display/things/Websites'

class About extends ThingSubContainer
{
    getLocale = () => this.props.locale.views.article.about ;

    render = () =>
    {
        const { thing } = this.props ;
        if( thing )
        {
            const {
                className,
                path,
                style,
            } = this.props ;

            const {
                alternativeHeadline,
                description,
                headline,
                id,
                notes,
                text
            } = thing ;

            const locale = this.getLocale() ;

            let view = (
            <div className="flex-1 flex flex-col space-y-24">

                <Metadatas
                    activable             = { true }
                    deletable             = { true }
                    onRemove              = { this.props.onRemove }
                    RemoveDialogComponent = { RemoveArticleDialog }
                    thing                 = { thing }
                    uri                   = { format(path.article,id) }
                />

                <Article
                    editUri  = { format(path.article,id) }
                    onChange = { this.change }
                    thing    = { thing }
                />

                <div className='grid grid-cols-1 sm:grid-cols-2 gap-16'>
                    <MultiLangEditor
                        html     = { false }
                        icon     = { <TitleIcon/> }
                        input    = { true }
                        locale   = { locale.headline }
                        onChange = { this.changeProperty('headline') }
                        text     = { headline }
                        uri      = { format( path.headline, id ) }
                    />
                    <MultiLangEditor
                        html     = { false }
                        icon     = { <SubtitleIcon/> }
                        input    = { true }
                        locale   = { locale.alternativeHeadline }
                        onChange = { this.changeProperty('alternativeHeadline') }
                        text     = { alternativeHeadline }
                        uri      = { format( path.alternativeHeadline, id ) }
                    />
                    <MultiLangEditor
                        html     = { false }
                        icon     = { <DescriptionIcon/> }
                        locale   = { locale.description }
                        onChange = { this.changeProperty('description') }
                        text     = { description }
                        uri      = { format( path.description, id ) }
                    />
                    <div>
                        <Image
                            onChange = { this.change }
                            thing    = { thing }
                            uri      = { path.image }
                        />
                    </div>
                </div>

                <MultiLangEditor
                    icon     = { <TextIcon/> }
                    locale   = { locale.text }
                    onChange = { this.changeProperty('text') }
                    text     = { text }
                    uri      = { format( path.text, id ) }
                />

                <MultiLangEditor
                    icon     = { <NoteIcon/> }
                    locale   = { locale.notes }
                    onChange = { this.changeProperty('notes') }
                    text     = { notes }
                    uri      = { format( path.notes, id ) }
                />

                <Websites
                    onChange = { this.change }
                    thing    = { thing }
                    uri      = { path.websites }
                />

                <div className='grid grid-cols-1 sm:grid-cols-2 gap-16'>
                    <div>
                        <Audio
                            onChange = { this.change }
                            thing    = { thing }
                            uri      = { path.audio }
                        />
                    </div>
                    <div>
                        <Video
                            onChange = { this.change }
                            thing    = { thing }
                            uri      = { path.video }
                        />
                    </div>
                </div>

            </div>) ;

            return (
            <div
                className = { clsx( 'flex-1 p-12' , className ) }
                style     = { style }
            >
                { this.renderWithTransition(view) }
            </div>
            );
        }
        return null ;
    }
}

About.defaultProps =
{
    ...ThingSubContainer.defaultProps
};

About.propTypes =
{
    ...ThingSubContainer.propTypes
};

export default withLocale(About) ;
