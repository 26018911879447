export default
{
    description : 'Veuillez sélectionner un point d\'intérêt ci-dessous.' ,
    disagree    : 'Fermer' ,
    empty       : 'Aucun point d\'intérêt disponible.' ,
    fail        : 'Impossible de charger les points d\'intérêt ...',
    loading     : 'Chargement en cours...' ,
    search      : 'Rechercher un point d\'intérêt ...' ,
    title       : 'Sélectionner un point d\'intérêt'
};
