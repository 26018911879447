import Form from '../../../../../../display/forms/FormElements'

import Method from '../../../../../../net/Method'

const elements =
[
    {
        type : Form.SWITCH ,
        init :
        {
            id    : 'agenda',
            key   : 'agenda',
            label : 'Afficher l\'agenda'
        }
    },
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id         : 'version',
            key        : 'version',
            label      : 'Version minimum',
            autoFocus  : false,
            fullWidth  : true,
            helperText : null,
            // InputProps :
            // {
            //     inputComponent : VersionInput
            // },
            margin       : 'normal',
            required     : false,
            select       : false,
            autoComplete : 'off' ,
            type         : 'text',
            variant      : 'outlined'
        }
    }
];

const info =
{
    edit :
    {
        elements,
        agree       : 'Sauver' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour modifier les paramètres principaux de cette application.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...' ,
        method      : Method.PATCH ,
        success     : 'Mise à jour réussie!' ,
        title       : 'Modifier les paramètres' ,
    },
    labels :
    {
        agenda  : 'Afficher l\'agenda',
        empty   : '----',
        no      : 'Non',
        version : 'Version Minimum' ,
        yes     : 'Oui'
    },
    title    : 'Informations' ,
    tooltips :
    {
        add    : 'Ajouter',
        edit   : 'Modifier',
        remove : 'Supprimer'
    }
};

export default info ;