import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import moment from 'moment/moment'

import clean    from 'vegas-js-core/src/objects/clean'
import compose  from 'vegas-js-core/src/functors/compose'
import format   from 'vegas-js-core/src/strings/fastformat'
import isString from 'vegas-js-core/src/isString'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import initApp from '../../../../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { Divider, Grid } from '@material-ui/core'

import { FaChartLine   }  from 'react-icons/fa'
import { FaCheckSquare }  from 'react-icons/fa'
import { FaMinusSquare }  from 'react-icons/fa'
import { FaPollH       }  from 'react-icons/fa'

import EventIcon      from '@material-ui/icons/Event'
import SwapHorizIcon  from '@material-ui/icons/SwapHoriz'
import RateReviewIcon from '@material-ui/icons/RateReview'

import api from '../../../../../configs/api'

import LabelItem      from '../../../../../components/items/LabelItem'
import PrintButton    from '../../../../../components/buttons/PrintButton'
import ThingContainer from '../../../../containers/ThingContainer'

import AbortionSerieReview from '../../../../../things/livestock/reviews/AbortionSerieReview'
import Thing               from '../../../../../things/Thing'
import Veterinarian        from '../../../../../things/Veterinarian'

import AbortionWorkplace from '../abortion/AbortionWorkplace'

import AbortionSerieDeclaration from '../../../../print/livestock/reviews/AbortionSerieDeclaration'

const cellStyle = 'flex flex-row items-center w-full' ;

const styles = () => (
{
    root :
    {
        width : '100%'
    }
});

const full =
{
    xs : 12
};

const third =
{
    md : 4 ,
    sm : 6 ,
    xs : 12
};

class AbortionSerie extends ThingContainer
{
    editPrepare = ( item ) =>
    {
        if( item )
        {
            let {
                completed,
                conclusion,
                date,
                frequency,
                distance,
                subjectOf:observation
            }
            = item ;

            date = date instanceof Date
                 ? moment(date).toISOString()
                 : null ;

            if( frequency instanceof Thing )
            {
                frequency = frequency.toObject();
            }
            else
            {
                frequency = null ;
            }

            if( conclusion instanceof Thing )
            {
                conclusion = conclusion.toObject();
            }
            else
            {
                conclusion = null ;
            }

            completed = !!(completed);

            distance = parseInt(distance) ;
            if( isNaN(distance) || (distance < 0) )
            {
                distance = 0 ;
            }

            if( observation )
            {
                let { review } = observation ;
                if( review )
                {
                    review.completed  = completed ;
                    review.conclusion = conclusion ;
                    review.date       = date ;
                    review.distance   = distance ;
                    review.frequency  = frequency ;

                    review = clean(review.toObject()) ;

                    return { review:JSON.stringify(review) } ;
                }
            }
        }
        return null ;
    };

    editUri = ( path, props ) =>
    {
        const { thing } = props ;
        if( thing )
        {
            const { id } = thing ;
            if( id )
            {
                return api.observations.url + '/' + id ;
            }
        }
        return null ;
    };

    getContent = () =>
    {
        let { lang , thing } = this.props;

        const locale = this.getLocale() ;

        if( thing )
        {
            const { review } = thing ;
            if( review instanceof AbortionSerieReview )
            {
                const { items } = locale ;
                if( items )
                {
                    let {
                        completed,
                        conclusion,
                        date,
                        distance,
                        frequency
                    } = review ;

                    completed = !!(completed) ;

                    if( conclusion instanceof Thing )
                    {
                        conclusion = conclusion.getLocaleName(lang) ;
                    }
                    else
                    {
                        conclusion = null ;
                    }

                    if( isString( date ) )
                    {
                         date = moment(date).format(locale.datePattern)
                    }

                    if( frequency instanceof Thing )
                    {
                        frequency = frequency.getLocaleName(lang) ;
                    }
                    else
                    {
                        frequency = null ;
                    }

                    return (
                    <Fragment>
                        <Grid container spacing={2}>
                            <Grid item {...third}>
                                <div className={cellStyle}>
                                    <EventIcon size='1.5em' className='mr-16' />
                                    <LabelItem title = { items.date }>
                                        { isString(date) && date !== '' ? date : items.empty }
                                    </LabelItem>
                                </div>
                            </Grid>

                            <Grid item {...third}>
                                <div className={cellStyle}>
                                    <SwapHorizIcon size='1.5em' className='mr-16' />
                                    <LabelItem title = { items.distance.title }>
                                        { format(items.distance.label,distance) }
                                    </LabelItem>
                                </div>
                            </Grid>

                            <Grid item {...third}>
                                <div className={cellStyle}>
                                    <FaChartLine size='1.5em' className='mr-16' />
                                    <LabelItem title = { items.frequency }>
                                        { (isString(frequency) && frequency !== '') ? ucFirst(frequency) : items.empty }
                                    </LabelItem>
                                </div>
                            </Grid>

                            <Grid item {...third}>
                                <div className={cellStyle}>
                                    { completed
                                        ? <FaCheckSquare size='1.5em' className='mr-16' />
                                        : <FaMinusSquare size='1.5em' className='mr-16' />
                                    }
                                    <LabelItem title={items.completed}>
                                        { completed ? items.yes : items.no }
                                    </LabelItem>
                                </div>
                            </Grid>

                            <Grid item {...third}>
                                <div className={cellStyle}>
                                    <FaPollH size='1.5em' className='mr-16' />
                                    <LabelItem title = { items.conclusion }>
                                        { (isString(conclusion) && conclusion !== '') ? ucFirst(conclusion) : items.empty }
                                    </LabelItem>
                                </div>
                            </Grid>

                            <Grid item {...full}>
                                <Divider light className='my-16'/>
                                <AbortionWorkplace
                                    containerProps = {{ className : "bg-transparent w-full shadow-none" }}
                                    onChange       = { this.change }
                                    thing          = { thing }
                                />
                            </Grid>

                        </Grid>

                    </Fragment>);
                }
            }
        }

        return null ;
    };

    getCustomOptions = () =>
    {
        const { printable } = this.props ;
        if( printable )
        {
            let veterinarians = null ;

            const { thing } = this.props ;
            if( thing )
            {
                let { authority } = thing ;
                if( authority instanceof Array && authority.length > 0 )
                {
                    veterinarians = authority.filter( item => item instanceof Veterinarian ) ;
                }
            }

            const locale = this.getLocale() ;
            return (
            <PrintButton
                disabled = { !(veterinarians instanceof Array) || ( veterinarians.length === 0) }
                locale   = { locale }
                onClick  = { () =>
                {
                    if( veterinarians instanceof Array )
                    {
                        if( veterinarians.length === 1 )
                        {
                            this.openPrintDialog( this.getDocument( veterinarians[0] ) ) ;
                        }
                        else
                        {
                            this.openSuggestionDialog( veterinarians ) ;
                        }
                    }
                }}
            />);

        }
        return null ;
    } ;

    getDocument = item =>
    (
        <AbortionSerieDeclaration
            thing        = { this.props.thing }
            veterinarian = { item }
        />
    );

    getEntry = () =>
    {
        const { thing } = this.props ;
        let init = null ;
        if( thing )
        {
            const { review } = thing ;
            if( review instanceof AbortionSerieReview )
            {
                const {
                    conclusion,
                    completed,
                    date,
                    distance,
                    frequency
                }
                = review ;

                init = {
                    completed,
                    conclusion,
                    date,
                    distance ,
                    frequency
                } ;
            }
        }
        return new AbortionSerieReview({ ...init, subjectOf:thing }) ;
    };

    getLocale = () => this.props.locale.things.livestock.reviews.abortionSerie ;

    onEdit = this.change ;

    onSuggest = item =>
    {
        this.timeout = setTimeout( this.openPrintDialog , 250, this.getDocument( item ) ) ;
    };

    unmount = () =>
    {
        clearTimeout(this.timeout) ;
        this.timeout = null ;
    };
}

AbortionSerie.defaultProps =
{
    ...ThingContainer.defaultProps,
    icon       : <RateReviewIcon/>,
    optionMode : 'custom',
    printable  : true,
    titleMode  : 'edit'
};

AbortionSerie.propTypes =
{
    ...ThingContainer.propTypes,
    printable : PropTypes.bool
};

export default compose( withStyles( styles ) , withRouter , initApp )( AbortionSerie ) ;
