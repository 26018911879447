import Method from '../../../../net/Method'

const uploadImage =
{
    tooltip : 'Téléverser l\'image' ,
    upload  :
    {
        agree        : 'Envoyer',
        disagree     : 'Fermer',
        filePropName : 'icon' , // important to send the picture with the API
        method       : Method.POST,
        mock         : false ,
        text         : 'Déposer un fichier .PNG avec la taille 512x512px maximum.',
        title        : 'Téléverser une image'
    }
};

export default uploadImage ;
