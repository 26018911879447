import React from 'react'

import isNotNull from 'vegas-js-core/src/isNotNull'
import notEmpty  from 'vegas-js-core/src/strings/notEmpty'

import { withRouter } from 'react-router-dom'

import { AppBar, Tab, Tabs } from '@material-ui/core'

import withConfig from '../../../contexts/config/withConfig'
import withi18n   from '../../../contexts/i18n/withi18n'

import Badge             from '../../../components/badges/Badge'
import Discover          from '../../stage/views/Discover'
import More              from '../../stage/views/More'
import StageCell         from '../../../display/cells/StageCell'
import ThingSubContainer from '../../../display/containers/ThingSubContainer'

import api from '../../../configs/api'

const a11yProps = index => ({ id : `simple-tab-${index}` , 'aria-controls' : `simple-tabpanel-${index}` }) ;

class Stage extends ThingSubContainer
{
    constructor( props )
    {
        super( props );

        let navigation ;

        const locale = this.getLocale() ;
        const { first , tabs } = locale ;
        if( notEmpty(first) )
        {
            navigation = first ;
        }
        else if( tabs instanceof Array && tabs.length > 0 )
        {
            const { value } = tabs[0] ;
            if( notEmpty(value) )
            {
                navigation = value ;
            }
        }

        this.state = { navigation };
    }

    changeStage = this.changeProperty('stage') ;

    getLocale = () => this.props.locale.views.step.stage ;

    init = () =>
    {
        let { location : { hash } = null } = this.props ;
        if( notEmpty(hash) )
        {
            this.setState( { navigation:hash.substring(1) } ) ;
        }
    };

    componentDidUpdate( prevProps , prevState )
    {
        const { navigation } = this.state ;
        if( prevState.navigation !== navigation )
        {
            const {  history } = this.props ;
            if( history )
            {
                history.replace({ hash:navigation }) ;
            }
        }
    }

    getDiscover = () =>
    {
        const { thing } = this.props ;
        if( thing )
        {
            const { stage } = thing ;
            if( stage )
            {
                return (
                    <Discover
                        className = 'w-full my-16'
                        onChange  = { this.changeStage }
                        thing     = { stage }
                    />
                );
            }
        }
        return null;
    };

    getInfos = () =>
    {
        const { thing } = this.props ;
        if( thing )
        {
            const { stage } = thing ;
            if( stage )
            {
                return (
                    <More
                        className = 'w-full my-16'
                        onChange  = { this.changeStage }
                        path      = { api.stages }
                        thing     = { stage }
                    />
                );
            }
        }
        return null;
    };

    getTabs = () =>
    {
        const { thing : { stage } = {} } = this.props ;
        const { navigation } = this.state ;
        if( stage )
        {
            const locale = this.getLocale() ;

            let { tabs } = locale ;
            if( tabs instanceof Array )
            {
                tabs = tabs.map( ( element , index ) =>
                {
                    if( element )
                    {
                        let { badge, label, value } = element ;
                        if( notEmpty(label) && notEmpty(value) )
                        {
                            if( badge instanceof Function )
                            {
                                badge = badge( this.props ) ;
                                if( badge >= 0 )
                                {
                                   badge = (
                                        <Badge
                                            className = 'ml-8'
                                            color     = { badge > 0 ? 'success' : 'gray' }
                                        >
                                            { badge }
                                        </Badge>
                                    ) ;
                                }
                                else
                                {
                                    badge = null ;
                                }
                            }

                            label = (
                                <div className='flex flex-row items-center'>
                                    { label }
                                    { badge }
                                </div>
                            );

                            return (
                                <Tab
                                    key  = { 'tab-item-' + index }
                                    { ...a11yProps(1) }
                                    value = { value }
                                    label = { label }
                                />
                            )
                        }
                    }
                    return null ;
                }).filter( isNotNull );

                if( tabs.length > 0 )
                {
                    return (
                        <AppBar color='inherit' position='sticky' className='z-50' >
                            <Tabs
                                centered = { true }
                                value    = { navigation }
                                onChange = { ( event , navigation ) => this.setState({ navigation } ) }
                                variant  = 'fullWidth'
                            >
                                { tabs }
                             </Tabs>
                        </AppBar>
                    );
                }
            }

        }

        return null;
    };

    render = () =>
    {
        const { thing : { stage } = {} } = this.props ;
        if( stage )
        {
            const { navigation } = this.state ;

            let content = null ;
            switch( navigation )
            {
                case 'discover' :
                {
                    content = this.getDiscover() ;
                    break ;
                }
                case 'infos' :
                {
                    content = this.getInfos() ;
                    break ;
                }
                default :
                {
                    break ;
                }
            }

            return (
                <div className='flex flex-col w-full'>
                    <div className='bg-white flex flex-col mx-8 my-16 px-8 py-8 shadow-md rounded-lg'>
                        <StageCell
                            className = 'px-8 py-8'
                            footer    = { false }
                            thing     = { stage }
                        />
                    </div>
                    { this.getTabs() }
                    { content }
                </div>
            );
        }

        return null;
    }
}

Stage.defaultProps =
{
    ...ThingSubContainer.defaultProps
};

Stage.propTypes =
{
    ...ThingSubContainer.propTypes
};

export default withConfig( withi18n( withRouter( Stage ) ) ) ;
