import containedInPlace from './place/containedInPlace'
import containsPlace from './place/containsPlace'
import museumType from './place/museumType'

import Form from '../../../display/forms/FormElements'

import Thing from '../../../things/Thing'
import Word  from '../../../things/Word'

import api from '../../../configs/api'

const elements =
[
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'name',
            key          : 'name',
            label        : 'Nom',
            autoFocus    : false,
            fullWidth    : true,
            helperText   : null,
            margin       : 'normal',
            required     : false,
            select       : false,
            autoComplete : 'off' ,
            type         : 'text',
            variant      : 'outlined'
        }
    },
    {
        type       : Form.SEARCH_SELECTOR ,
        path       : api.thesaurus.places_types ,
        clearLabel : 'Vider' ,
        closeLabel : 'Fermer' ,
        init       :
        {
            id        : 'additionalType',
            key       : 'additionalType',
            label     : 'Type de lieu',
            autoFocus : false,
            fullWidth : true,
            margin    : 'normal',
            required  : true,
            type      : 'search',
            variant   : 'outlined'
        },
        inputProps :
        {
            label : 'Type de lieu...'
        },
        limit  : 0,
        logics :
        {
            disabledSuggestions : props =>
            {
                const { rel } = props ;
                let suggestions = [] ;
                if( rel instanceof Thing )
                {
                    const { additionalType } = rel ;
                    if( additionalType instanceof Word )
                    {
                        suggestions.push( additionalType ) ;
                    }
                }
                return suggestions ;
            }
        }
    },
    {
        type       : Form.SEARCH_SELECTOR ,
        path       : api.thesaurus.places_status ,
        clearLabel : 'Vider' ,
        closeLabel : 'Fermer' ,
        init       :
        {
            id         : 'status',
            key        : 'status',
            label      : 'État du lieu',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            required   : true,
            type       : 'search',
            variant    : 'outlined'
        },
        inputProps : { label : 'État du lieu' },
        limit  : 0,
        logics :
        {
            disabledSuggestions : props =>
            {
                const { rel } = props ;
                let suggestions = [] ;
                if( rel instanceof Thing )
                {
                    const { status } = rel ;
                    if( status instanceof Word )
                    {
                        suggestions.push( status ) ;
                    }
                }
                return suggestions ;
            }
        }
    },
    {
        type  : Form.SELECTOR ,
        label : 'Accessible au public',
        init  :
        {
            id         : 'publicAccess',
            fullWidth  : true,
            margin     : 'normal',
            required   : false
        },
        options :
        [
            { value : ""    , label : '---' },
            { value : true  , label : 'Oui' },
            { value : false , label : 'Non' }
        ]
    }
];


const place =
{
    // -------- dependencies

    containedInPlace,
    containsPlace,
    museumType,

    // -------- settings

    edit  :
    {
        elements,
        agree       : 'Sauver' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour modifier ce lieu.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...' ,
        success     : 'Mise à jour réussie!' ,
        title       : 'Modifier le lieu'
    },
    labels :
    {
        empty        : '----' ,
        name         : 'Nom' ,
        no           : 'Non',
        publicAccess : 'Accessible au public' ,
        status       : 'État du lieu',
        type         : 'Type de lieu',
        yes          : 'Oui'
    },
    title    : 'Lieu' ,
    tooltips :
    {
        add    : 'Ajouter',
        edit   : 'Modifier',
        remove : 'Supprimer'
    }
};

export default place ;
