import numbers      from './conceptualObjects/numbers'
import mark         from './conceptualObjects/mark'
import marks        from './conceptualObjects/marks'
import material     from './conceptualObjects/material'
import materials    from './conceptualObjects/materials'
import measurement  from './conceptualObjects/measurement'
import measurements from './conceptualObjects/measurements'
import productions  from './conceptualObjects/productions'

import Form from '../../../display/forms/FormElements'

import Place from '../../../things/Place'
import Thing from '../../../things/Thing'

import api from '../../../configs/api'
import Word from '../../../things/Word'

const elements =
[
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id           : 'name',
            label        : 'Nom',
            autoComplete : 'off' ,
            autoFocus    : true,
            fullWidth    : true,
            helperText   : 'Obligatoire',
            margin       : 'normal',
            required     : true,
            select       : false,
            type         : 'text',
            variant      : 'outlined'
        }
    },
    {
        type  : Form.SEARCH_SELECTOR ,
        path  : api.thesaurus.conceptualObject_categories ,
        clazz : Word ,
        clearLabel : 'Vider' ,
        closeLabel : 'Fermer' ,
        init  :
        {
            id         : 'category',
            key        : 'category',
            label      : 'Catégorie',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            type       : 'search',
            variant    : 'outlined'
        },
        inputProps :
        {
            label : 'Sélectionner une catégorie d\'objet...'
        }
    },
    {
        type       : Form.SEARCH_SELECTOR ,
        path       : api.places.url ,
        clazz      : Place ,
        clearLabel : 'Vider' ,
        closeLabel : 'Fermer' ,
        init       :
        {
            id        : 'location',
            key       : 'location',
            label     : 'Localisation',
            autoFocus : false,
            fullWidth : true,
            margin    : 'normal',
            type      : 'search',
            variant   : 'outlined'
        },
        inputProps :
        {
            label : 'Sélectionner un lieu'
        }
    },
    {
        type  : Form.SEARCH_SELECTOR ,
        path  : api.thesaurus.temporal ,
        clazz : Word ,
        clearLabel : 'Vider' ,
        closeLabel : 'Fermer' ,
        init  :
        {
            id         : 'temporal',
            key        : 'temporal',
            label      : 'Temporalité',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            type       : 'search',
            variant    : 'outlined'
        },
        inputProps :
        {
            label : 'Sélectionner une époque...'
        }
    },
    {
        type  : Form.SEARCH_SELECTOR ,
        path  : api.thesaurus.movement ,
        clazz : Word ,
        clearLabel : 'Vider' ,
        closeLabel : 'Fermer' ,
        init  :
        {
            id         : 'movement',
            key        : 'movement',
            label      : 'Mouvement',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            type       : 'search',
            variant    : 'outlined'
        },
     inputProps :
        {
            label : 'Sélectionner une mouvement artistique...'
        }
    }
];

const prepare = ( item ) =>
{
    let {
        category,
        movement,
        temporal,
        location,
        name
    } = item ;

    if( category instanceof Thing )
    {
        category = String(category.id)
    }
    else
    {
        category = null ;
    }

    if( movement instanceof Thing )
    {
        movement = String(movement.id)
    }
    else
    {
        movement = null ;
    }
    if( temporal instanceof Thing )
    {
        temporal = String(temporal.id)
    }
    else
    {
        temporal = null ;
    }

    if( location instanceof Thing )
    {
        location = String(location.id)
    }
    else
    {
        location = null ;
    }

    return (
    {
        category,
        movement,
        temporal,
        location,
        name
    });
};

const conceptualObject =
{
    // -------- dependencies

    numbers,
    mark,
    marks,
    material,
    materials,
    measurement,
    measurements,
    productions,

    // -------- settings

    edit :
    {
        agree       : 'Sauver' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez remplir le formulaire ci-dessous pour modifier cet object.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...' ,
        success     : 'Mise à jour réussie!' ,
        title       : 'Modifier l\'objet' ,
        elements,
        prepare
    },
    labels :
    {
        empty     : '----' ,
        name      : 'Nom' ,
        category  : 'Catégorie',
        movement  : 'Mouvement artistique',
        temporal  : 'Temporalité',
        location  : 'Localisation',
    },
    title    : 'Objet' ,
    tooltips :
    {
        add    : 'Ajouter',
        edit   : 'Modifier',
        remove : 'Supprimer'
    }
};

export default conceptualObject ;
