import React from 'react'

import clsx from 'clsx'

import { Divider } from '@material-ui/core'

const getHorizontalDivider = (
{
    className = null,
    key       = undefined ,
    Component = Divider ,
    props     = null
} = {} ) => <Component key={key} className={ clsx('my-8' ,className ) } light={true} { ...props }/> ;

export default getHorizontalDivider ;
