import React from 'react'

import PropTypes from 'prop-types'

import isNotNull from 'vegas-js-core/src/isNotNull'

import withLang from '../../contexts/i18n/withLang'

import { withStyles } from '@material-ui/core/styles'

import { withWidth, MenuItem, TextField } from '@material-ui/core'

import Container from '../../components/containers/Container'

const styles = () =>
({
    root :
    {
        flexGrow : 1,
        width    : '100%' ,
        height   : 'auto'
    }
});

class Selector extends Container
{
    constructor( props )
    {
        super( props ) ;
        this.inputLabel = null ;
        this.state =
        {
            ...this.state,
            labelWidth : 0
        };
    }

    componentDidMount()
    {
        super.componentDidMount();
        if( this.inputLabel )
        {
            this.setState({ labelWidth:this.inputLabel.offsetWidth }) ;
        }
    }

    componentDidUpdate()
    {
        if( this.inputLabel )
        {
            const { labelWidth } = this.state ;
            if( labelWidth !== this.inputLabel.offsetWidth )
            {
                this.setState({ labelWidth:this.inputLabel.offsetWidth }) ;
            }
        }
    }

    render()
    {
        const {
            classes,
            disabled,
            error,
            init,
            label,
            options,
            value,
            variant
        } = this.props;

        return (
        <div
            className = { classes.root }
        >
            <TextField
                { ...init }
                label      = { label }
                onChange   = { this._change }
                select     = { true }
                value      = { isNotNull(value) ? value : "" }
                disabled  = { disabled }
                error     = { error }
                variant   = { variant }
            >
                { options instanceof Array && options.map( ( element , index ) => <MenuItem key={'item-'+index} value={ element.value }>{ element.label }</MenuItem> ) }
            </TextField>
        </div>
        );
    }

    /**
     * @param event
     * @private
     */
    _change = event  =>
    {
        const { onChange } = this.props;
        if( onChange )
        {
            onChange( event ) ;
        }
    };
}

Selector.defaultProps =
{
    disabled   : false ,
    error      : false ,
    init       : null ,
    label      : null ,
    multiple   : false,
    onChange   : null ,
    options    : null,
    rel        : null , // optional relating object
    value      : null,
    variant    :  'outlined'
};

Selector.propTypes =
{
    classes   : PropTypes.object.isRequired,
    disabled  : PropTypes.bool ,
    error     : PropTypes.bool ,
    init      : PropTypes.object ,
    label     : PropTypes.string,
    options   : PropTypes.arrayOf( PropTypes.shape({
        label : PropTypes.string,
        value : PropTypes.any
    })),
    multiple  : PropTypes.bool,
    onChange  : PropTypes.func ,
    rel       : PropTypes.object ,
    value     : PropTypes.any,
    variant   : PropTypes.oneOf(['standard','outlined','filled'])
};

export default withWidth()(withStyles( styles )( withLang(Selector) )) ;
