import generify from '../../generify'

import Event from '../../Event'
import Word  from '../../Word'

/**
 * A vaccination event.
 * @memberOf things.livestock.events
 * @extends Event
 */
class VaccinationEvent extends Event
{
    /**
     * Creates a new VaccinationEvent instance.
     * @constructor
     * @param object The generic object to initialize the object.
     */
    constructor( object = null )
    {
        super(object) ;
    }

    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new VaccinationEvent( this.toObject() ) ;
    }

    /**
     * Populate the object with specific behaviors or types.
     * Launched by default in the constructor and in the set methods.
     * @returns {Thing} The current reference.
     */
    populate()
    {
        super.populate() ;

        const {
            about
        } = this ;

        if( about && !(about instanceof Word) )
        {
            this.about = new Word(about)  ;
        }
        
        return this ;
    }

    /**
     * Returns the generic object representation of the item.
     * @param {boolean} [full=false] - Indicates if the generic object contains all the properties of only the basic implementation.
     * @return {Object} The generic object representation of the item.
     */
    toObject( full = false )
    {
        let {
            about,
            date,
            id
        } = this ;
        
        about = generify(about,true) ;
        
        return {
            ...( full && super.toObject() ),
            id,
            about,
            date
        };
    }
}

export default VaccinationEvent ;