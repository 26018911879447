import React , { Component } from 'react'

import PropTypes from 'prop-types'

import round from 'vegas-js-core/src/maths/round'

// Doc : https://github.com/s-yadav/react-number-format
import NumberFormat from 'react-number-format'

class PriceInput extends Component
{
    render()
    {
        const {
            floatCount,
            inputRef,
            onChange,
            prefix,
            ...other
        } = this.props;
        
        return (
        <NumberFormat
            {...other}
            getInputRef   = { inputRef }
            prefix        = { prefix }
            onValueChange = { values =>
            {
                onChange({ target: { value: round(values.floatValue,floatCount) } });
            }}
        />
      );
    }
}

PriceInput.defaultProps =
{
    decimalSeparator  : '.',
    floatCount        : 2,
    format            : null ,
    prefix            : '€ ',
    thousandSeparator : ' '
};

PriceInput.propTypes =
{
    decimalSeparator  : PropTypes.string,
    floatCount        : PropTypes.number,
    format            : PropTypes.oneOfType([PropTypes.string,PropTypes.func]),
    inputRef          : PropTypes.func.isRequired,
    onChange          : PropTypes.func.isRequired ,
    prefix            : PropTypes.string ,
    thousandSeparator : PropTypes.oneOfType([PropTypes.string,PropTypes.bool])
};

export default PriceInput ;