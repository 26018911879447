import React from 'react'

import compose from 'vegas-js-core/src/functors/compose'

import initApp from '../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { Grid } from '@material-ui/core'

import AssignmentIcon from '@material-ui/icons/Assignment'
import LabelItem from '../../components/items/LabelItem'

import ThingContainer from '../containers/ThingContainer'

import PostalAddress from '../../things/PostalAddress'
import Thing         from '../../things/Thing'

const styles = () => ({});

const half =
{
    sm : 6 ,
    xs : 12
};

class Address extends ThingContainer
{
    getContent = () =>
    {
        let { thing } = this.props;

        const locale = this.getLocale() ;

        const { empty } = locale ;

        let additionalAddress ;
        let addressCountry ;
        let addressDepartment;
        let addressLocality;
        let addressRegion;
        let postalCode;
        let postOfficeBoxNumber;
        let streetAddress;

        if( thing instanceof PostalAddress )
        {
            let {
                additionalAddress : add,
                addressCountry : c ,
                addressDepartment : d ,
                addressLocality : l ,
                addressRegion : r ,
                postalCode : p ,
                postOfficeBoxNumber : o ,
                streetAddress : s
            } = thing ;

            additionalAddress   = add ;
            addressCountry      = c ;
            addressDepartment   = d ;
            addressLocality     = l ;
            addressRegion       = r ;
            postalCode          = p ;
            postOfficeBoxNumber = o ;
            streetAddress       = s ;
        }

        return (
            <Grid container spacing={4}>
                <Grid item {...half}>
                    <LabelItem
                        enabled = { !!(streetAddress) }
                        title   = { locale.streetAddress }
                    >
                        { streetAddress || empty }
                    </LabelItem>
                </Grid>
                <Grid item {...half}>
                    <LabelItem
                        enabled = { !!(additionalAddress) }
                        title   = { locale.additionalAddress }
                    >
                        { additionalAddress || empty }
                    </LabelItem>
                </Grid>
                <Grid item {...half}>
                    <LabelItem
                        enabled = { !!(postalCode) }
                        title   = { locale.postalCode }
                    >
                        { postalCode || empty }
                    </LabelItem>
                </Grid>
                <Grid item {...half}>
                    <LabelItem
                        enabled = { !!(addressLocality) }
                        title   = { locale.addressLocality }
                    >
                        { addressLocality || empty }
                    </LabelItem>
                </Grid>
                <Grid item {...half}>
                    <LabelItem
                        enabled = { !!(postOfficeBoxNumber) }
                        title   = { locale.postOfficeBoxNumber }
                    >
                        { postOfficeBoxNumber || empty }
                    </LabelItem>
                </Grid>
                <Grid item {...half}>
                    <LabelItem
                        enabled = { !!(addressDepartment) }
                        title   = { locale.addressDepartment }
                    >
                        { addressDepartment || empty }
                    </LabelItem>
                </Grid>
                <Grid item {...half}>
                    <LabelItem
                        enabled = { !!(addressRegion) }
                        title   = { locale.addressRegion }
                    >
                        { addressRegion || empty }
                    </LabelItem>
                </Grid>
                <Grid item {...half}>
                    <LabelItem
                        enabled = { !!(addressCountry) }
                        title   = { locale.addressCountry }
                    >
                        { addressCountry || empty }
                    </LabelItem>
                </Grid>
            </Grid>
        );
    };

    getEntry = init =>
    {
        let empty = {} ;
        if( init instanceof Thing )
        {
            empty = init.toObject() ;
        }
        else if( init )
        {
            empty = { ...init } ;
        }
        return new PostalAddress(empty);
    };

    getLocale = () => this.props.locale.things.address ;

    prepare = item =>
    {
        let {
            additionalAddress,
            addressCountry,
            addressDepartment,
            addressLocality,
            addressRegion,
            postOfficeBoxNumber,
            postalCode,
            streetAddress
        } = item ;

        return (
            {
                additionalAddress,
                addressCountry,
                addressDepartment,
                addressLocality,
                addressRegion,
                postOfficeBoxNumber,
                postalCode,
                streetAddress
            });
    };
}

Address.defaultProps =
{
    ...ThingContainer.defaultProps ,
    icon       : <AssignmentIcon/> ,
    optionMode : 'edit'
};

Address.propTypes =
{
    ...ThingContainer.propTypes
};

export default compose( withStyles( styles ) , withRouter , initApp )( Address ) ;
