import api           from '../../../configs/api'
import Form          from '../../../display/forms/FormElements'
import Event         from '../../../things/Event'
import PropertyValue from '../../../things/PropertyValue'
import Method        from '../../../net/Method'
import Thing         from '../../../things/Thing'

const organizations =
{
    add :
    {
        agree       : 'Ajouter' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez sélectionner une organisation en cliquant ci-dessous.' ,
        elements    :
        [
            {
                type       : Form.SEARCH_SELECTOR ,
                path       : api.organizations.url ,
                clazz      : Event ,
                clearLabel : 'Vider' ,
                closeLabel : 'Fermer' ,
                init       :
                {
                    id         : 'value',
                    key        : 'value',
                    label      : 'Organisation',
                    autoFocus  : false,
                    fullWidth  : true,
                    margin     : 'normal',
                    required   : true,
                    type       : 'search',
                    variant    : 'outlined'
                },
                inputProps :
                {
                    label : 'Organisation...'
                },
                logics :
                    {
                        disabledSuggestions : ( props ) =>
                        {
                            const { rel } = props ;
                            if( rel instanceof Thing )
                            {
                                const { subjectOf } = rel ;
                                if( subjectOf instanceof Thing )
                                {
                                    const { organizations } = subjectOf ;
                                    if( organizations instanceof Array )
                                    {
                                        return organizations ;
                                    }
                                }
                            }
                            return null ;
                        }
                    }
            }
        ],
        fail    : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading : 'Chargement en  cours...' ,
        method  : Method.POST,
        prepare : ( item ) =>
        {
            return (
            {
                value : item.value
            });
        },
        mapUri : ( item , uri ) =>
        {
            if( item instanceof PropertyValue)
            {
                const { value } = item ;
                if( value instanceof Thing )
                {
                    return uri + '/' + value.id ;
                }
            }
            return uri ;
        },
        success : 'Ajout de l\'organisation réussi !' ,
        title   : 'Ajouter une organisation'
    },
    remove :
    {
        agree       : 'Supprimer' ,
        description : 'Souhaitez-vous supprimer cette organisation :' ,
        disagree    : 'Annuler' ,
        loading     : 'Suppression en cours...' ,
        title       : 'Supprimer l\'organisation ?'
    },
    title : 'Organisations' ,
    tooltips :
    {
        add    : "Ajouter" ,
        edit   : "Éditer" ,
        remove : "Supprimer"
    }
};

export default organizations ;
