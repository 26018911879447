import Form   from '../../../../../../../display/forms/FormElements'
import Method from '../../../../../../../net/Method'

const elements =
[
    {
        type : Form.SWITCH ,
        init :
        {
            id    : 'filtered',
            key   : 'filtered',
            label : 'Filtrations'
        }
    },
    {
        type : Form.SWITCH ,
        init :
        {
            id    : 'drinkingTreatment',
            key   : 'drinkingTreatment',
            label : 'Traitement potabilisant'
        }
    },
    {
        type : Form.SWITCH ,
        init :
        {
            id    : 'annualAnalysis',
            key   : 'annualAnalysis',
            label : 'Analyse de l\'eau annuelle'
        }
    },
    {
        type : Form.SWITCH ,
        init :
        {
            id    : 'annualPotability',
            key   : 'annualPotability',
            label : 'Potabilité (analyse de moins d\'un an)'
        }
    }
];

const waterTreatmentReview =
{
    edit  :
    {
        agree       : 'Sauver' ,
        disagree    : 'Annuler' ,
        description : 'Modifier les informations sur le traitement de l\'eau du site d\'élevage.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...' ,
        mock        : false ,
        method      : Method.PATCH,
        success     : 'Mise à jour réussie!' ,
        title       : 'Gestion du traitement de l\'eau' ,
        elements
    },
    labels:
    {
        annualAnalysis    : 'Analyse annuelle ',
        annualPotability  : 'Potabilité (analyse de moins d\'un an)',
        drinkingTreatment : 'Traitement potabilisant',
        filtered          : 'Filtrations' ,
        no                : 'Non',
        yes               : 'Oui'
    },
    title    : 'Traitement de l\'eau' ,
    tooltips :
    {
        edit : 'Modifier'
    }
};

export default waterTreatmentReview ;