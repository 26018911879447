import React from 'react'

import isNotNull from 'vegas-js-core/src/isNotNull'

import Icon from '@material-ui/icons/Work'

import withDatasList from './withDatasList'

import ThingsList from './ThingsList'

import AddTechnicianDialog    from '../dialogs/add/AddTechnicianDialog'
import RemoveTechnicianDialog from '../dialogs/remove/RemoveTechnicianDialog'
import Technician             from '../../things/Technician'
import TechnicianCell         from '../cells/TechnicianCell'

class TechniciansList extends ThingsList
{
    getEntry = () =>
    {
        let thing ;

        const { clazz } = this.props ;

        if( clazz )
        {
            thing = new clazz() ;
        }

        let { things } = this.state ;
        if( things instanceof Array && things.length > 0 )
        {
            things = things.map( item => item instanceof Technician ? item.person : null ).filter( isNotNull ) ;
            if( things.length > 0 )
            {
                thing.subjectOf = things ;
            }
        }

        return thing ;
    };

    getLocale = () => this.props.locale.display.lists.technicians ;
}

TechniciansList.defaultProps =
{
    ...ThingsList.defaultProps ,
    AddDialogComponent    : AddTechnicianDialog,
    CellComponent         : TechnicianCell,
    clazz                 : Technician,
    icon                  : <Icon/>,
    preferable            : true ,
    RemoveDialogComponent : RemoveTechnicianDialog
};

TechniciansList.propTypes =
{
    ...ThingsList.propTypes
};

export default withDatasList()( TechniciansList ) ;
