import React , { Component } from 'react'

import PropTypes  from 'prop-types'

import { withStyles } from '@material-ui/core/styles'

import CloseIcon from '@material-ui/icons/Close'

import { IconButton, Snackbar } from '@material-ui/core'

import SnackContent from './SnackContent'
import SnackVariant from './SnackVariant'

export const variants = SnackVariant;

const styles = () =>
({
    root:
    {
        //margin : 20
    }
});

class SnackMessage extends Component
{
    state =
    {
        open : false
    };

    close = ( event , reason ) =>
    {
        if ( reason === 'clickaway' )
        {
            return;
        }
        this.setState({ open: false });
        const { onClose } = this.props ;
        if( onClose instanceof Function )
        {
            onClose() ;
        }
    };

    componentDidMount()
    {
        const { open } = this.props ;
        this.setState({ open }) ;
    }

    componentDidUpdate( prevProps , prevState )
    {
        const { open } = this.props ;
        if( prevState.open !== open )
        {
            this.setState({ open });
        }
    }

    getCloseButton = ( key='close_button' ) =>
    {
        const { closable } = this.props ;
        if( closable )
        {
            return (
            <IconButton
                aria-label = "Close"
                color      = "inherit"
                key        = { key }
                onClick    = { this.close }
            >
                <CloseIcon />
            </IconButton>);
        }
        return null ;
    };

    open = () =>
    {
        this.setState({ open: true });
    };

    render()
    {
        const {
            autoHideDuration,
            describedby,
            classes,
            message,
            horizontal,
            variant,
            vertical
        } = this.props ;

        const { open } = this.state ;

        const action = this.getCloseButton() ;

        return (
            <Snackbar
                anchorOrigin     = {{ horizontal , vertical}}
                autoHideDuration = { autoHideDuration }
                className        = { classes.root }
                ContentProps     = {{ 'aria-describedby' : describedby }}
                open             = { open }
                onClose          = { this.close }
                action           = { action }
            >
                <SnackContent
                    message = { message }
                    onClose = { this.close }
                    variant = { variant }
                />
            </Snackbar>
        );
    }
}

SnackMessage.defaultProps =
{
    autoHideDuration : 6000 ,
    describedby      : 'message' ,
    closable         : true ,
    horizontal       : 'left' ,
    message          : null ,
    onClose          : null,
    open             : false,
    variant          : 'default',
    vertical         : 'bottom'
};

SnackMessage.propTypes =
{
    aria             : PropTypes.shape({ describedby : PropTypes.string }),
    closable         : PropTypes.bool ,
    autoHideDuration : PropTypes.number ,
    classes          : PropTypes.object.isRequired ,
    horizontal       : PropTypes.oneOf(['left','center','right']),
    onClose          : PropTypes.func,
    open             : PropTypes.bool,
    variant          : PropTypes.oneOf(['default','success', 'warning', 'error', 'info']),
    vertical         : PropTypes.oneOf(['bottom','top'])
};

export default withStyles(styles)(SnackMessage) ;
