import React from "react"

import clsx from 'clsx'

import PropTypes from 'prop-types'

import deepMerge from 'vegas-js-core/src/deepMerge'
import isString  from 'vegas-js-core/src/isString'
import ucFirst   from 'vegas-js-core/src/strings/ucFirst'

import api              from '../../configs/api'
import GET              from '../../net/GET'
import RequestStatus    from '../../net/RequestStatus'
import SimpleViewHeader from '../headers/SimpleViewHeader'
import TabsView         from './TabsView'
import Thing            from '../../things/Thing'

import SnackVariant from '../../components/snackbars/SnackVariant'

import getLocaleThingLabel from '../../things/getLocaleThingLabel'
import notEmpty from 'vegas-js-core/src/strings/notEmpty';

export const defaultStyle = ( custom = {} , options = {} ) => theme =>
{
    let style =
    {
        header :
        {
            backgroundColor : theme.palette.secondary.dark ,
            backgroundImage : `url(${theme.assets.header})`,
            backgroundSize  : 'cover',
            display         : 'flex',
            minHeight       : 100,
            flex            : 1,
        },
        root :
        {
            backgroundColor : theme.palette.background,
            display         : 'flex',
            flex            : 1,
            flexDirection   : 'column'
        },
        snack :
        {
            fontSize : 16
        },
        tab :
        {
            display        : 'flex',
            alignItems     : 'left',
            justifyContent : 'center',
            padding        : '0px 4px',
            width          : '100%',
            flexDirection  : 'column'
        }
    };

    if( custom )
    {
        if( custom instanceof Function )
        {
            custom = custom(theme) ;
        }
        style = deepMerge( style, custom , options ) ;
    }

    return style ;
};

class ThingView extends TabsView
{
    constructor( props )
    {
        super( props ) ;
        this.canceler         = null ;
        this.onRemove         = null ;
        this.populateOnChange = null ;
        this.state            =
        {
            ...this.state ,
            thing     : null ,
            status    : RequestStatus.NEW,
            subjectOf : null
        }
    }

    change = thing =>
    {
        if( this._mounted )
        {
            if( this.populateOnChange instanceof Function )
            {
                thing = this.populateOnChange( thing ) ;
            }
            this.setState( { thing } , () =>
            {
                const { onChange } = this.props ;
                if( onChange instanceof Function )
                {
                    onChange( thing ) ;
                }
            }) ;
        }
    };

    getBadgeValue = ( { id , badge } = {} ) =>
    {
        if( badge )
        {
            if( notEmpty(badge) )
            {
                const { thing } = this.state ;
                if( thing && thing.hasOwnProperty(badge) )
                {
                    return thing[badge] ;
                }
            }
            else if( badge instanceof Function )
            {
                return badge( { id , ref:this } ) ;
            }
        }
        return null ;
    };

    getHeader = () =>
    {
        const { status } = this.state ;

        const locale = this.getLocale() ;

        const {
            loading,
            notFound,
            tooltips
        } = locale ;

        let header ;
        let title ;

        switch( status )
        {
            case RequestStatus.FAIL :
            {
                title  = notFound ;
                header = <SimpleViewHeader title={notFound} error={true} /> ;
                break ;
            }

            case RequestStatus.PROGRESS :
            {
                title  = loading ;
                header = <SimpleViewHeader title={loading} progress={true} /> ;
                break ;
            }

            case RequestStatus.SUCCESS :
            {
                const { clazz } = this.props ;
                const { thing } = this.state ;

                if( clazz && (thing instanceof clazz) )
                {
                    const {
                        back,
                        config,
                        preferable,
                        titleAuto
                    } = this.props ;

                    let tip ;

                    if( tooltips )
                    {
                        const { back:tooltip } = tooltips ;
                        if( isString(tooltip) )
                        {
                            tip = tooltip ;
                        }
                        else if( tooltip instanceof Function )
                        {
                            tip = tooltip( this.props , this.state ) ;
                        }
                    }

                    title = this.getTitle(thing) ;

                    header = (
                        <SimpleViewHeader
                            back            = { back }
                            backTooltip     = { tip }
                            defaultImage    = { config.assets.profile }
                            icon            = { this.getIcon() }
                            onBack          = { this.onBack }
                            preferable      = { preferable }
                            preferableThing = { thing }
                            subtitle        = { this.getSubtitle(thing) }
                            title           = { title }
                            titleAuto       = { titleAuto }
                            thing           = { this.getHeaderThing() }
                        />
                    );
                }
                else
                {
                    title  = notFound ;
                    header = <SimpleViewHeader title={title} /> ;
                }
                break ;
            }

            default :
            {
                title  = notFound ;
                header = <SimpleViewHeader title={title} /> ;
            }
        }

        if( header )
        {
            const { classes } = this.props ;
            return (
            <div className={classes.header}>
                { this.getHelmet( title )}
                { header }
            </div>) ;
        }

        return null ;
    };

    getHeaderThing = () => this.state.thing ;

    getIcon = () => this.props.icon ;

    getPath = () => this.props.path ;

    getRouteElement = (
    {
        RouteComponent,
        path,
        uri,
        routeClazz,
        backOnRemove = true  ,
        onRemove ,
        ...options
    } = {} ) =>
    {
        const { thing } = this.state ;
        if( thing )
        {
            const { routeClassName , clazz } = this.props ;

            if( notEmpty(onRemove) && this.hasOwnProperty(onRemove) && this[onRemove] instanceof Function )
            {
                onRemove = this[onRemove] ;
            }

            onRemove = onRemove || this.onRemove ;

            let onDelete ;
            if( onRemove instanceof Function )
            {
                onDelete = ( value , item ) =>
                {
                    onRemove( { item , value , target:this } );
                }
            }
            else if( !!(backOnRemove) && this.onBack instanceof Function )
            {
                onDelete = () =>
                {
                    this.onBack();
                }
            }

            return (
                <RouteComponent
                    className = { clsx( 'p-12 sm:p-16' , routeClassName ) }
                    clazz     = { routeClazz || clazz }
                    onChange  = { this.change }
                    onRemove  = { onDelete }
                    path      = { path }
                    thing     = { thing }
                    uri       = { this.getRouteUri({ thing , uri }) }
                    { ...options }
                />
            )
        }
        return null ;
    };

    getRouteUri = ( { uri } = {} ) => uri ;

    getSubtitle = thing =>
    {
        if( thing )
        {
            const { additionalType } = thing ;
            if( additionalType instanceof Thing )
            {
                const { lang } = this.props ;
                return ucFirst(additionalType.getLocaleName(lang))  ;
            }
        }
        return null ;
    };

    getTitle = thing =>
    {
        if( thing instanceof Thing )
        {
            return getLocaleThingLabel( thing , this.props.lang ) ;
        }
        return null ;
    };

    getUri = () =>
    {
        const { apiUrl , match } = this.props ;
        const path = this.getPath() ;
        return apiUrl + path.url + '/' + match.params.id ;
    };

    init = () =>
    {
        let { clazz , location, thing } = this.props ;
        let { subjectOf } = this.state ;

        let options ;

        if( location )
        {
            const { state } = location ;
            if( state )
            {
                const { backOptions, backPath, thing:ref } = state ;

                options = {
                    ...(backOptions !== null) && { backOptions },
                    ...(backPath    !== null) && { backPath    }
                } ;

                if( ref instanceof clazz )
                {
                    thing = ref ;
                }
            }
        }

        if( thing instanceof clazz )
        {
            this.refresh(
                RequestStatus.SUCCESS ,
                thing ,
                subjectOf,
                options
            ) ;
            return
        }
        else
        {
            this.setState( { ...options } /*, () => console.log( this + " init OK" , this.state , state )*/ ) ;
        }

        const { loadOnMount } = this.props ;
        if( loadOnMount )
        {
            this.load() ;
        }
    };

    load = () =>
    {
        const { status } = this.state ;
        if( status !== RequestStatus.PROGRESS )
        {
            const { queries } = this.props ;
            this.refresh( RequestStatus.PROGRESS , null ) ;
            this.canceler = GET( this.getUri() , {
                cancel  : this._cancel,
                fail    : this._fail,
                queries : { active : false , ...queries } ,
                random  : true ,
                success : this._success
            } );
        }
    };

    notify = ( message , variant=SnackVariant.DEFAULT ) =>
    {
        const { notifySnack } = this.props ;
        if( notifySnack )
        {
            notifySnack(message, variant);
        }
    };

    notifySuccess = () =>
    {
        const locale = this.getLocale() ;
        if( locale )
        {
            const { snack } = locale ;
            if( snack )
            {
                const { success } = snack ;
                if( success )
                {
                    this.notify(success, SnackVariant.SUCCESS);
                }
            }
        }
    };

    onBack = () =>
    {
        const { history } = this.props ;
        const path = this.getPath() ;
        if( history && path )
        {
            const { url } = path ;
            if( url )
            {
                const { backPath : back1 , backOptions } = this.state ;
                const { backPath : back2 } = this.props ; // FIXME try to use the this.state.backPath
                const backPath = back1 || back2 || '' ;
                history.push(
                {
                    pathname : url + backPath ,
                    state    : backOptions
                });
            }
        }
    };

    populate = data =>
    {
        if( data )
        {
            const { clazz } = this.props ;
            const { result } = data ;
            if( clazz && result && !(result instanceof clazz ) )
            {
                const { object } = data ;
                if( object )
                {
                    result.subjectOf = object ;
                }
                data.result = new clazz( result ) ;
            }
        }
        return data ;
    };

    populateUri = uri =>
    {
        const { match } = this.props ;
        if( match )
        {
            const { params } = match ;
            if( params )
            {
                const { id } = params ;
                if( id )
                {
                    return uri + '/' + id ;
                }
            }

        }
        return uri ;
    }

    refresh( status = RequestStatus.NEW , thing = null , subjectOf = null , options = null )
    {
        if( this._mounted )
        {
            this.setState( { status, thing , subjectOf , ...options } ) ;
        }
    }

    showContent = () =>
    {
        const { clazz } = this.props ;
        const { status, thing } = this.state ;
        return ( status === RequestStatus.SUCCESS && clazz && (thing instanceof clazz) ) ;
    };

    unmount = () =>
    {
        const { status } = this.state ;
        if( status === RequestStatus.PROGRESS && !!(this.canceler) )
        {
            this.canceler.cancel(this + ' cancel') ;
        }
    };

    update = ( prevProps /*, prevState*/ ) =>
    {
        const { match } = this.props ;
        if( prevProps.match.params.id !== match.params.id )
        {
            this.load() ;
        }
    };

    // -------- PRIVATE

    _cancel = () =>
    {
        // console.log( this + ' cancel : ' + message ) ;
    };

    _fail = response =>
    {
        if( response )
        {
            console.log( this + ' fail' , response ) ;
            if( response.data )
            {
                let data = response.data ;
                switch( data.message )
                {
                    case 'token revoked' :
                    {
                        console.log( this + " failed, status:" + response.status + ", message:" + data.message );
                        this.refresh( RequestStatus.REVOKED );
                        break ;
                    }
                    default :
                    {
                        console.log( this + " failed, status:" + response.status + ", message:" + data.message );
                    }
                }
            }
        }
        if( this.mounted )
        {
            this.refresh( RequestStatus.FAIL );
        }
    };

    _success = data =>
    {
        if( this.mounted )
        {
            data = this.populate( data ) ;
            if( data )
            {
                const { object, result } = data ;
                if( result )
                {
                    this.notifySuccess();
                    this.refresh( RequestStatus.SUCCESS , result , object ) ;
                    return;
                }
            }
        }
        this.refresh( RequestStatus.FAIL ) ;
    };
}

ThingView.defaultProps =
{
    ...TabsView.defaultProps,
    apiUrl      : api.url,
    clazz       : Thing ,
    back        : false,
    backPath    : '',
    backOptions : null ,
    first       : 'about' ,
    icon        : null ,
    loadOnMount : true,
    path        : { url : '' },
    preferable  : false,
    queries     : null ,
    titleAuto   : true
};

ThingView.propTypes =
{
    ...TabsView.propTypes,
    apiUrl      : PropTypes.string.isRequired ,
    back        : PropTypes.bool,
    backPath    : PropTypes.string,
    backOptions : PropTypes.object,
    clazz       : PropTypes.func.isRequired ,
    icon        : PropTypes.element,
    loadOnMount : PropTypes.bool,
    onChange    : PropTypes.func,
    path        : PropTypes.object,
    preferable  : PropTypes.bool,
    queries     : PropTypes.object,
    titleAuto   : PropTypes.bool ,
    thing       : PropTypes.object
};

export default ThingView ;
