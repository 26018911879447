import React from 'react'

import Icon from '@material-ui/icons/ExitToApp'

import { DateLabel } from './DateLabel'

import withi18n  from '../../contexts/i18n/withi18n'
import withSnack from '../../contexts/snack/withSnack'

export class LogoutLabel extends DateLabel
{  
    getLocale = () => this.props.locale.display.labels.logout;
}
    
LogoutLabel.defaultProps =
{
    ...DateLabel.defaultProps,
    formatVariant : 'fromNow' ,
    icon          : <Icon className='text-gray-600' fontSize='small' />,
    labelProps    : { className : 'text-gray-600' },
    member        : 'logout'
}

LogoutLabel.propTypes =
{
    ...DateLabel.propTypes
}

export default withSnack( withi18n( LogoutLabel) );