const date =
{
    copy :
    {
        snack   : 'Date copiée dans le presse-papier.' ,
        tooltip : 'Copier la date'
    },
    label   : '{0}',
    pattern : 'DD MMMM YYYY' ,
    title   : null
};

export default date ;
