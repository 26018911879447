import table from './table'

const technicians =
{
    ...table,
    helmet :
    {
        title : 'Techniciens' ,
        metas : []
    },
    empty  : 'Aucun technicien trouvé',
    search : 'Rechercher un technicien ...' ,
    title  : 'Techniciens'
};

export default technicians ;
