import React from 'react'

import AssignmentIcon from '@material-ui/icons/Assignment'

import PropertyValue from '../../../things/PropertyValue'
import Article       from '../../../things/creativework/Article'
import Thing         from '../../../things/Thing'

import ThingChildren from '../ThingChildren'

export class ArticleChildren extends ThingChildren
{
    addMapUri = ( item , uri ) =>
    {
        if( item instanceof PropertyValue )
        {
            const { value } = item ;
            if( value instanceof Thing )
            {
                uri += '/' + value.id ;
            }
        }
        return uri ;
    };

    addPrepare = item => ({ value : item.value }) ;
}

ArticleChildren.defaultProps =
{
    ...ThingChildren.defaultProps ,
    clazz      : Article,
    emptyClazz : PropertyValue,
    emptyIcon  : null ,
    icon       : <AssignmentIcon/>
};

ArticleChildren.propTypes =
{
    ...ThingChildren.propTypes
};

export default ArticleChildren;