import grid from './grid'

const organizations =
{
    ...grid,
    helmet :
    {
        title : 'Organisation' ,
        metas : []
    },
    empty  : 'Aucune organisation trouvée',
    search : 'Rechercher une organisation ...' ,
    title  : 'Organisations'
};

export default organizations ;
