import table from './table'

const livestocks =
{
    ...table,
    helmet :
    {
        title : 'Élevages' ,
        metas : []
    },
    empty  : 'Aucun élevage trouvé',
    search : 'Rechercher un élevage ...' ,
    title  : 'Élevages'
};

export default livestocks ;
