import React from 'react'

import clsx from 'clsx'

import format from 'vegas-js-core/src/strings/fastformat'

import RemovePersonDialog from '../../../display/dialogs/remove/RemovePersonDialog';

import DescriptionIcon from '@material-ui/icons/Notes'
import NoteIcon        from '@material-ui/icons/SpeakerNotes'
import TextIcon        from '@material-ui/icons/Subject'

import withLocale from '../../../contexts/i18n/withLocale'

import Audio             from '../../../display/things/medias/Audio'
import Identity          from '../../../display/things/person/Identity'
import Image             from '../../../display/things/medias/Image'
import Metadatas         from '../../../display/things/Metadatas'
import ThingSubContainer from '../../../display/containers/ThingSubContainer'
import Video             from '../../../display/things/medias/Video'

import MultiLangEditor from '../../../components/editors/MultiLangEditor'

class About extends ThingSubContainer
{
    getLocale = () => this.props.locale.views.person.about ;

    render = () =>
    {
        const {
            className,
            path,
            style,
            thing
        } = this.props ;

        if( thing )
        {
            const {
                description,
                id,
                text,
                notes
            } = thing ;

            const locale = this.getLocale() ;

            let view = (
            <div className="flex-1 flex flex-col space-y-24">

                <Metadatas
                    activable             = { true }
                    deletable             = { true }
                    RemoveDialogComponent = { RemovePersonDialog }
                    onRemove              = { this.props.onRemove }
                    thing                 = { thing }
                    uri                   = { format(path.person,id) }
                />

                <Identity
                    editUri  = { format(path.person,id) }
                    onChange = { this.change }
                    thing    = { thing }
                />

                <div className='grid grid-cols-1 sm:grid-cols-2 gap-16'>
                    <MultiLangEditor
                        html     = { false }
                        icon     = { <DescriptionIcon/> }
                        locale   = { locale.description }
                        onChange = { this.changeProperty('description') }
                        text     = { description }
                        uri      = { format( path.description, id ) }
                    />
                     <div>
                        <Image
                            onChange = { this.change }
                            thing    = { thing }
                            uri      = { path.image }
                        />
                    </div>
                </div>

                <MultiLangEditor
                    icon     = { <TextIcon/> }
                    locale   = { locale.text }
                    onChange = { this.changeProperty('text') }
                    text     = { text }
                    uri      = { format(path.text, id ) }
                />

               <MultiLangEditor
                    icon     = { <NoteIcon/> }
                    locale   = { locale.notes }
                    onChange = { this.changeProperty('notes') }
                    text     = { notes }
                    uri      = { format( path.notes, id ) }
                />

                <div className='grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-16'>
                    <div>
                        <Audio
                            onChange = { this.change }
                            thing    = { thing }
                            uri      = { path.audio }
                        />
                    </div>
                    <div>
                        <Video
                            onChange = { this.change }
                            thing    = { thing }
                            uri      = { path.video }
                        />
                    </div>
                </div>

            </div>) ;

            return (
            <div
                className = { clsx( 'flex-1 p-12' , className ) }
                style     = { style }
            >
                { this.renderWithTransition(view) }
            </div>
            );
        }
        return null  ;
    };
}

About.defaultProps =
{
    ...ThingSubContainer.defaultProps
};

About.propTypes =
{
    ...ThingSubContainer.propTypes
};

export default withLocale( About ) ;
