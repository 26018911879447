import React from 'react'

import compose from 'vegas-js-core/src/functors/compose'

import initApp from '../../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import DomainIcon from '@material-ui/icons/Domain'

import PropertyValue from '../../../things/PropertyValue'
import Organization  from '../../../things/Organization'

import ThingChildren, { styles } from '../ThingChildren'

class MemberOf extends ThingChildren
{
    getLocale = () => this.props.locale.things.person.memberOf ;
}

MemberOf.defaultProps =
{
    ...ThingChildren.defaultProps ,
    clazz      : Organization,
    emptyClazz : PropertyValue,
    emptyIcon  : null ,
    icon       : <DomainIcon/> ,
    member     : 'memberOf'
};

MemberOf.propTypes =
{
    ...ThingChildren.propTypes
};

export default compose( withStyles( styles ) , withRouter , initApp )( MemberOf ) ;