import Thing from '../Thing'
import Word  from '../Word'

class BadgeItem extends Thing
{
    /**
     * Creates a new BadgeItem instance.
     * @constructor
     * @param object The generic object to initialize the object the first time.
     */
    constructor( object = null )
    {
        super();

        this.bgcolor = null ;

        this.color = null ;

        this.set( object ) ;
    }
    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        super.clear() ;
        this.bgcolor =
        this.color   = null ;
        return this ;
    }

    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new BadgeItem( this.toObject() ) ;
    }

    /**
     * Populate the object with specific behaviors or types. Launched by default in the constructor and in the set methods.
     * @returns {Thing} The current reference.
     */
    populate()
    {
        const {
            additionalType
        } = this ;

        if( additionalType && !(additionalType instanceof Word))
        {
            this.additionalType = new Word(additionalType) ;
        }

        return this ;
    }

    /**
     * Returns the generic object representation of the item.
     * @return {Object} The generic object representation of the item.
     */
    toObject()
    {
        let {
            bgcolor,
            color
        } = this ;

        return { ...super.toObject() , ...{
            bgcolor,
            color
        }}
    }
}

export default BadgeItem ;