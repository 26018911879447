import init from './thing'

const conceptualObject =
{
    ...init,
    movement : { title : 'Mouvement artistique' },
    temporal : { title : 'Temporalité' },
};

export default conceptualObject ;
