import Form from '../../../../../../../display/forms/FormElements'

import AmbienceReview     from '../../../../../../../things/livestock/reviews/AmbienceReview'
import AnnualHealthReview from '../../../../../../../things/livestock/reviews/AnnualHealthReview'
import Sector             from '../../../../../../../things/Sector'
import SectorReview       from '../../../../../../../things/livestock/reviews/SectorReview'

import Method from '../../../../../../../net/Method'

import api from '../../../../../../../configs/api'

const elements =
[
    {
        type        : Form.SEARCH_SELECTOR ,
        path        : api.thesaurus.answers_generics ,
        clearLabel  : 'Vider' ,
        closeLabel  : 'Fermer' ,
        iconifiable : false ,
        init        :
        {
            id         : 'comfort',
            key        : 'comfort',
            label      : 'Confort des animaux',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            type       : 'search',
            variant    : 'outlined'
        },
        queries    : { search : 'comfort/' } ,
        searchable : false ,
        title      : 'Type de confort'
    },
    {
        type       : Form.SEARCH_SELECTOR ,
        path       : api.thesaurus.answers_generics ,
        clearLabel : 'Vider' ,
        closeLabel : 'Fermer' ,
        init       :
        {
            id         : 'density',
            key        : 'density',
            label      : 'Densité des animaux',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            type       : 'search',
            variant    : 'outlined'
        },
        queries    : { search : 'density/' } ,
        searchable : false ,
        title      : 'Densité des animaux'
    },
    {
        type       : Form.SEARCH_SELECTOR ,
        path       : api.thesaurus.answers_generics ,
        clearLabel : 'Vider' ,
        closeLabel : 'Fermer' ,
        init       :
        {
            id         : 'ventilation',
            key        : 'ventilation',
            label      : 'Ventilation',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            type       : 'search',
            variant    : 'outlined'
        },
        queries    : { search : 'ventilation/' } ,
        searchable : false ,
        title      : 'Ventilation du secteur'
    },
    {
        type : Form.SWITCH ,
        init :
        {
            id    : 'heating',
            key   : 'heating',
            label : 'Chauffage'
        }
    },
    {
        type : Form.SWITCH ,
        init :
        {
            id    : 'couplingHeatingVentilation',
            key   : 'couplingHeatingVentilation',
            label : 'Couplage chauffage/ventilation'
        }
    },
    {
        type : Form.SWITCH ,
        init :
        {
            id    : 'heatingRegulation',
            key   : 'heatingRegulation',
            label : 'Régulation chauffage/ventilation'
        }
    },
    {
        type : Form.SWITCH ,
        init :
        {
            id    : 'regulationToolMastering',
            key   : 'regulationToolMastering',
            label : 'Maîtrise des outils de régulation'
        }
    }
];

const prepare = ( item ) =>
{
    if( item )
    {
        let {
            about:sectorReview,
            subjectOf:review
        } = item ;
        
        if(
            sectorReview instanceof SectorReview
            && review instanceof AnnualHealthReview
        )
        {
            const {
                ambience,
                about
            } = sectorReview ;
            
            if( about instanceof Sector && ambience instanceof AmbienceReview )
            {
                const { id } = about ;
                if( id )
                {
                    let { subReview } = review ;
                    if( subReview instanceof Array )
                    {
                        let len1 = subReview.length ;
                        for( let i = 0 ; i<len1 ; i++ )
                        {
                            let workplace = subReview[i] ;
                            if( workplace )
                            {
                                let { subReview } = workplace ;
                                if( subReview instanceof Array )
                                {
                                    let len2 = subReview.length ;
                                    for( let j = 0 ; j<len2 ; j++  )
                                    {
                                        let sector = subReview[j] ;
                                        if( sector )
                                        {
                                            let { about } = sector ;
                                            if( about )
                                            {
                                                let { id:index } = about ;
                                                if( index === id )
                                                {
                                                    sector.ambience = item ;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            
            return {
                review : JSON.stringify(review.toObject(false))
            };
        }
    }
    
    return null ;
};

const ambienceReview =
{
    edit  :
    {
        agree       : 'Sauver' ,
        disagree    : 'Annuler' ,
        description : 'Modifier les informations sur le traitement de l\'ambiance du secteur.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en cours...' ,
        method      : Method.PATCH,
        success     : 'Mise à jour réussie!' ,
        title       : 'Ambiance du secteur' ,
        elements,
        prepare
    },
    labels:
    {
        comfort                    : 'Confort des animaux',
        couplingHeatingVentilation : 'Couplage chauffage/ventilation',
        density                    : 'Densité des animaux',
        heating                    : 'Chauffage',
        heatingRegulation          : 'Régulation chauffage/ventilation',
        regulationToolMastering    : 'Maîtrise des outils de régulation',
        ventilation                : 'Ventilation',
        empty                      : '----' ,
        no                         : 'Non',
        yes                        : 'Oui'
    },
    title    : 'Ambiance du secteur' ,
    tooltips :
    {
        edit : 'Modifier'
    }
};

export default ambienceReview ;