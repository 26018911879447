import React from 'react'

import withi18n from '../../../../../../contexts/i18n/withi18n'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import blueGrey from '@material-ui/core/colors/blueGrey'

import { FaFileMedicalAlt } from 'react-icons/fa'

import ChipsContainer, { styles } from '../../../../../containers/ChipsContainer'

import withDialogs from '../../../../../../contexts/dialogs/withDialogs'

import generify from '../../../../../../things/generify'

import SectorHealthEvent from '../../../../../../things/livestock/events/SectorHealthEvent'
import SuspicionReview   from '../../../../../../things/livestock/reviews/SuspicionReview'
import Thing             from '../../../../../../things/Thing'
import Word              from '../../../../../../things/Word'

const css = () => (
{
    ...styles,
    chip :
    {
        backgroundColor : blueGrey[100],
        margin          : 8
    }
});

class Symptoms extends ChipsContainer
{
    getEntry = init =>
    {
        const { subjectOf } = this.props ;

        let empty = {} ;

        if( init instanceof Thing )
        {
            empty = init.toObject() ;
        }
        else if( init )
        {
            empty = { ...init } ;
        }

        return new SectorHealthEvent( { ...empty , subjectOf } ) ;
    };

    getLocale = () => this.props.locale.things.livestock.reviews.suspicion.medical.symptoms ;

    editInit = ( item ) =>
    {
        if( item )
        {
            const { symptoms } = item ;
            if( symptoms instanceof Array )
            {
                return symptoms.map( element =>
                {
                    if( element instanceof Word )
                    {
                        return element.clone() ;
                    }
                    return null ;
                })
            }
        }
        return null ;
    };

    onEdit = ( item ) =>
    {
        const { onChange } = this.props ;
        if( onChange )
        {
            onChange( item ) ;
        }
    };

    editPrepare = ( datas , props ) =>
    {
        const { item } = props ;
        if( item )
        {
            let { id, subjectOf:review } = item ;
            if( review instanceof SuspicionReview )
            {
                review = review.toObject() ;
                let { events } = review ;
                if( events instanceof Array && events.length > 0 )
                {
                    let len = events.length ;
                    for( let i = 0 ; i<len ; i++ )
                    {
                        let event = events[i] ;
                        if( event && event.id )
                        {
                            if( id === event.id )
                            {
                                if( datas )
                                {
                                    if( datas instanceof Array && datas.length === 0 )
                                    {
                                        datas = null ;
                                    }
                                    datas = generify(datas,true) ;
                                }
                                event.symptoms = datas ;
                                review = JSON.stringify(review) ;
                                return { review } ;
                            }
                        }
                    }
                }
            }
        }

        return null ;
    };
}

Symptoms.defaultProps =
{
    ...ChipsContainer.defaultProps ,
    chipVariant : 'default' ,
    icon        : <FaFileMedicalAlt size={20}/>,
    member      : 'symptoms'
};

Symptoms.propTypes =
{
    ...ChipsContainer.propTypes
};

export default withStyles( css ) ( withRouter( withi18n( withDialogs( Symptoms ) ) ) ) ;
