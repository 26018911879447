import React from "react"

import isString from 'vegas-js-core/src/isString'
import notEmpty from 'vegas-js-core/src/strings/notEmpty'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import MediasIcon from '@material-ui/icons/PermMedia'
import StageIcon  from '@material-ui/icons/Stars'
import StepIcon   from '@material-ui/icons/PinDrop'

import ThingView , { defaultStyle } from '../../display/views/ThingView'

import About  from './views/About'
import Medias from '../medias/Medias'
import Stage  from './views/Stage'

import StepVO from '../../things/Step'
import Thing from "../../things/Thing"

import api   from '../../configs/api'
import paths from '../../configs/paths'

import initView from '../../display/views/initView'

const styles = defaultStyle();

class Step extends ThingView
{
    getLocale = () => this.props.locale.views.step ;

    getSubtitle = thing =>
    {
        if( thing instanceof StepVO )
        {
            const subtitle = thing.getLocaleSubtitle( thing , this.props.lang ) ;
            if( notEmpty( subtitle ) )
            {
                return ucFirst( subtitle )  ;
            }
        }
        return null ;
    };

    getTitle = thing =>
    {
        if( thing instanceof StepVO )
        {
            const title = thing.getLocaleTitle( thing , this.props.lang ) ;
            if( notEmpty( title ) )
            {
                return ucFirst( title )  ;
            }
        }
        return null ;
    };

    onBack = () =>
    {
        let url ;

        const { thing } = this.state ;
        if( thing )
        {
            const { subjectOf } = thing ;
            if( subjectOf instanceof Thing )
            {
                const { url:uri } = subjectOf ;
                if( isString(uri) )
                {
                    url = uri.split(api.url)[1] + '/steps'
                }
            }
        }
        const { history } = this.props ;
        if( history && isString(url) )
        {
            history.push( url )
        }
    };
}

Step.defaultProps =
{
    ...ThingView.defaultProps,
    back       : true ,
    clazz      : StepVO ,
    icon       : <StepIcon/>, //TODO change icon
    path       : api.steps,
    responsive : true ,
    routes     :
    [
        { id : 'about'  , RouteComponent : About  } ,
        { id : 'stage'  , RouteComponent : Stage  } ,
        { id : 'medias' , RouteComponent : Medias }
    ],
    tabs :
    [
        { id : 'about'  , icon : <StepIcon   className='pr-8' /> },
        { id : 'stage'  , icon : <StageIcon  className='pr-8' /> },
        { id : 'medias' , icon : <MediasIcon className='pr-8' /> }
    ],
    titleAuto  : false // Important to use always the getTitle() method
};

Step.propTypes =
{
    ...ThingView.propTypes,
    path : paths.steps
};

export default initView( Step, styles );
