import React from 'react'

import clsx from 'clsx'

import compose from 'vegas-js-core/src/functors/compose'

import initApp from '../../../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { Grid } from '@material-ui/core'

import ThingContainer from '../../../containers/ThingContainer'

import Abortion          from './abortion/Abortion'
import AbortionEvent     from './abortion/events/AbortionEvent'
import AbortionWorkplace from './abortion/AbortionWorkplace'

const styles = () => (
{
    root :
    {
        width : '100%'
    }
});

const full =
{
    xs : 12
};
//
// const half =
// {
//     sm : 6,
//     xs : 12
// };

class AbortionReview extends ThingContainer
{
    getLocale = () => this.props.locale.things.livestock.reviews.abortion ;

    render = () =>
    {
        const {
            classes,
            className,
            style,
            thing
        } = this.props ;

        if( thing )
        {
            return (
            <div
                className = { clsx(classes.root, className) }
                style     = { style }
            >
                <Grid container spacing = { 4 } >

                    <Grid item {...full}>
                        <Abortion
                            onChange = { this.change }
                            thing    = { thing }
                        />
                    </Grid>

                    <Grid item {...full}>
                        <AbortionWorkplace
                            onChange   = { this.change }
                            thing      = { thing }
                            titleMode  = 'edit'
                        />
                    </Grid>

                    <Grid item {...full}>
                        <AbortionEvent
                            containerProps = {{ className : "w-full p-16" }}
                            onChange       = { this.change }
                            thing          = { thing }
                        />
                    </Grid>
                </Grid>
            </div>) ;
        }

        return null  ;
    }
}

AbortionReview.defaultProps =
{
    ...ThingContainer.defaultProps
};

AbortionReview.propTypes =
{
    ...ThingContainer.propTypes
};

export default compose( withStyles( styles ) , withRouter , initApp )( AbortionReview ) ;
