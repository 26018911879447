import React from 'react'

import Icon from '@material-ui/icons/Casino'

import withDatasList from './withDatasList'

import ThingsList from './ThingsList'

import Game from '../../things/Game'

import AddGameDialog from '../dialogs/add/AddGameDialog'
import RemoveGameDialog from '../dialogs/remove/RemoveGameDialog'

class GamesList extends ThingsList
{
    getLocale = () => this.props.locale.display.lists.games ;
}

GamesList.defaultProps =
{
    ...ThingsList.defaultProps ,
    AddDialogComponent    : AddGameDialog,
    clazz                 : Game,
    icon                  : <Icon />,
    queries               : { sort : 'name,-modified' },
    RemoveDialogComponent : RemoveGameDialog
};

GamesList.propTypes =
{
    ...ThingsList.propTypes
};

export default withDatasList()( GamesList ) ;
