import React, {Component} from 'react'

import clsx from 'clsx'

import PropTypes  from 'prop-types'

import isString from 'vegas-js-core/src/isString'

import { withStyles } from '@material-ui/core/styles'

import { Divider, Typography } from '@material-ui/core'

import ArrowRight from '@material-ui/icons/Link'

import grey from '@material-ui/core/colors/grey'

const styles = () =>
({
    disabled :
    {
        alignSelf : 'center',
        color     : grey[400]
    },
    label :
    {
        //backgroundColor : grey[100],
        alignSelf : 'center',
        color     : grey[900],
        wordWrap  : 'break-word',
        width     : '100%'
    },
    root :
    {
        paddingBottom : 6 ,
        paddingTop    : 6 ,
        width         : '100%'
    },
    title :
    {
        fontSize   : 15 ,
        fontWeight : 'bold'
    },
    titleWithIcon :
    {
        marginLeft : 6
    }
});

class LabelItem extends Component
{
    render()
    {
        let {
            classes,
            className,
            component,
            children,
            divider,
            enabled,
            icon,
            inline,
            labelIcon,
            labelProps,
            labelVariant,
            linkIcon,
            style,
            title,
            titleProps,
            titleVariant,
            to
        } = this.props ;

        if( isString(title) )
        {
            title = (
                <div className="flex flex-row items-center mb-6">
                    { icon }
                    <Typography
                        className = { clsx(classes.title, icon ? classes.titleWithIcon : null ) }
                        variant   = { titleVariant }
                        { ...titleProps }
                    >
                        {title}
                    </Typography>
                </div>
            )
        }
        else
        {
            title = null ;
        }

        if( isString(children) )
        {
            children = (
                <Typography
                    className = { enabled ? classes.label : classes.disabled }
                    variant   = { labelVariant }
                    component = { component }
                    to        = { to }
                    { ...labelProps }
                >
                    { children }
                </Typography>
            );
        }

        if( inline )
        {
            return (
                <div className={ clsx(classes.root,'inline-flex',className) } style={style}>
                    <div className="mr-8">
                        { title }
                    </div>
                    <div className="flex flex-row items-center w-full">
                        { labelIcon || (component && to && linkIcon) }
                        { children }
                    </div>
                </div>
            );

        }
        else
        {
            return (
                <div className={ clsx(classes.root,className) } style={style}>
                    { title }
                    { divider && <Divider light className='w-full my-8' /> }
                    <div className="flex flex-row items-center mt-4 w-full">
                        { labelIcon || (component && to && linkIcon) }
                        { children }
                    </div>
                </div>
            );
        }
    }
}

LabelItem.defaultProps =
{
    className    : null ,
    children     : null ,
    component    : null ,
    divider      : false ,
    enabled      : true ,
    icon         : null ,
    inline       : false ,
    labelIcon    : null ,
    labelProps   : null ,
    labelVariant : 'body2',
    linkIcon     : <ArrowRight className="mr-8"/>,
    style        : null ,
    title        : null ,
    titleProps   : null ,
    titleVariant : 'body2',
    to           : null
};

LabelItem.propTypes =
{
    children     : PropTypes.node ,
    classes      : PropTypes.object.isRequired ,
    className    : PropTypes.string,
    component    : PropTypes.oneOfType([PropTypes.string,PropTypes.func,PropTypes.object]),
    divider      : PropTypes.bool,
    enabled      : PropTypes.bool,
    icon         : PropTypes.element,
    inline       : PropTypes.bool,
    labelIcon    : PropTypes.element,
    labelProps   : PropTypes.object ,
    labelVariant : PropTypes.string ,
    linkIcon     : PropTypes.element,
    style        : PropTypes.object,
    title        : PropTypes.string,
    titleProps   : PropTypes.object ,
    titleVariant : PropTypes.string,
    to           : PropTypes.object
};

export default withStyles( styles , { withTheme: true } ) ( LabelItem ) ;
