import React from 'react'

import withDatasList from './withDatasList'

import PlaceIcon from '@material-ui/icons/Place'

import ThingsList from './ThingsList'

import Place from '../../things/Place'

import AddPlaceDialog    from '../dialogs/add/AddPlaceDialog'
import RemovePlaceDialog from '../dialogs/remove/RemovePlaceDialog'

import PlaceCell from '../cells/PlaceCell'

class PlacesList extends ThingsList
{
    getLocale = () => this.props.locale.display.lists.places ;
}

PlacesList.defaultProps =
{
    ...ThingsList.defaultProps ,
    AddDialogComponent    : AddPlaceDialog,
    CellComponent         : PlaceCell,
    cellProps             : { variant : 'default' },
    clazz                 : Place,
    icon                  : <PlaceIcon />,
    preferable            : true ,
    queries               : { sort : '-modified' },
    RemoveDialogComponent : RemovePlaceDialog,
    sortable              : true
};

PlacesList.propTypes =
{
    ...ThingsList.propTypes
};

export default withDatasList()( PlacesList ) ;
