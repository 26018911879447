export default
{
    apis :
    {
        [process.env.REACT_APP_API] :
        {
            name          : process.env.REACT_APP_API ,
            url           : process.env.REACT_APP_API_URL ,
            auth_url      : process.env.REACT_APP_API_AUTH_URL,
            client_id     : process.env.REACT_APP_API_CLIENT_ID,
            client_secret : process.env.REACT_APP_API_CLIENT_SECRET,
            grant_type    : process.env.REACT_APP_API_GRANT_TYPE,
            scope         : process.env.REACT_APP_API_SCOPE,
            token_url     : process.env.REACT_APP_API_TOKEN_URL
        },
        [process.env.REACT_APP_SUPPORT] :
        {
            name          : process.env.REACT_APP_SUPPORT ,
            url           : process.env.REACT_APP_SUPPORT_URL ,
            client_id     : process.env.REACT_APP_SUPPORT_CLIENT_ID,
            client_secret : process.env.REACT_APP_SUPPORT_CLIENT_SECRET,
            grant_type    : process.env.REACT_APP_SUPPORT_GRANT_TYPE,
            scope         : process.env.REACT_APP_SUPPORT_SCOPE,
            token_url     : process.env.REACT_APP_SUPPORT_TOKEN_URL
        }
    },
    homepage  : process.env.REACT_APP_HOMEPAGE ,
    providers :
    [
        'google.com' ,
        'facebook.com' ,
        'gravatar.com'
    ],
    redirect_uri : process.env.REACT_APP_REDIRECT
};
