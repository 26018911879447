import birth       from './person/birth'
import civilStatus from './person/civilStatus'
import death       from './person/death'
import identity    from './person/identity'
import jobs        from './person/jobs'
import legal       from './person/legal'
import memberOf    from './person/memberOf'
import nationality from './person/nationality'
import user        from './person/user'
import worksFor    from './person/worksFor'

const person =
{
    civilStatus,
    identity,
    jobs,
    legal,
    memberOf,
    nationality,
    worksFor,
    birth,
    death,
    user
};

export default person ;