import React from 'react'

import compose  from 'vegas-js-core/src/functors/compose'

import { withRouter } from 'react-router-dom'

import AddIcon   from '@material-ui/icons/LibraryMusic'
import AudioIcon from '@material-ui/icons/Audiotrack'

import initApp   from '../../../contexts/app/initApp'
import withSnack from '../../../contexts/snack/withSnack'

import Media from './Media'

import api from '../../../configs/api'

import RequestStatus from '../../../net/RequestStatus'

import AudioCell   from '../../cells/AudioCell'
import AudioObject from '../../../things/creativework/media/AudioObject'

export class Audio extends Media
{
    getContent = () =>
    {
        let view ;
        let thing = this.getThingMember() ;
        if( thing instanceof AudioObject )
        {
            view = (
                <AudioCell
                    mode    = 'button'
                    ref     = { ref => this.player = ref }
                    thing   = { thing }
                    onClick = { () =>
                    {
                        this.openMediaDialog( thing , { removable:false }) ;
                    }}
                />
            );
        }
        else
        {
            view = (
                <div className='flex-1 w-full h-160 flex items-center justify-center'>
                    <AudioIcon
                        color = 'action'
                        style = {{ fontSize : 40 }}
                    />
                </div>
            );
        }

        return (
        <div className='flex items-center justify-center w-full'>
            { view }
        </div>);
    };

    getEntry = media => media instanceof AudioObject ? media : new AudioObject(media) ;

    getLocale = () => this.props.locale.things.media.audio;

    onMedia = () =>
    {
        if( this._mounted )
        {
            this.forceUpdate() ;
        }
    };

    unmount = () =>
    {
        const { status } = this.state ;
        if( status === RequestStatus.PROGRESS && !!(this.canceler) )
        {
            this.canceler.cancel(this + ' cancel') ;
        }

        if( this.player )
        {
            this.player.stop() ;
        }
    };
}

Audio.defaultProps =
{
    ...Media.defaultProps ,
    icon             : <AudioIcon/> ,
    member           : 'audio' ,
    memberClazz      : AudioObject,
    optionMode       : 'custom',
    selectMediaIcon  : <AddIcon/>,
    selectMediaProps : ( { props : { config : { acceptedUploadMedias : { audios:acceptedFiles } = {} } = {} } = {} } = {} ) => (
    {
        disableEscapeKeyDown : true ,
        facets               : { encodingFormat : 'audio' } ,
        maxItems             : 1,
        multiple             : false,
        uploadable           : true,
        uploadMultiple       : false,
        uploadProps          : { acceptedFiles },
        uploadUri            : api.mediaObjects.url
    }),
    selectMediaUri : api.mediaObjects.url
};

Audio.propTypes =
{
    ...Media.propTypes
};

export default compose( initApp, withRouter , withSnack )( Audio ) ;
