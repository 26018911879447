import React from 'react'

import PropTypes from 'prop-types'

import isString from 'vegas-js-core/src/isString'
import notEmpty from 'vegas-js-core/src/strings/notEmpty'

// https://nel-co.github.io/react-preloading-component-demo
import {
    Grow  as Search ,
    Lines as Preloader
} from 'react-preloading-component'

import { MdLibraryAdd as Icon } from 'react-icons/md'

import { TextField, Typography, Zoom } from '@material-ui/core'

import InfoIcon from '@material-ui/icons/AddToQueue'

import CoreDialog from '../../components/dialogs/CoreDialog'
import Form       from '../forms/Form'

import Method        from '../../net/Method'
import RequestStatus from '../../net/RequestStatus'
import POST          from '../../net/POST'

import initDialog from './initDialog'

import createEmbedMediaObject from '../../net/external/createEmbedMediaObject'
import getMediaIcon           from '../medias/getMediaIcon'

import MediaObject from '../../things/creativework/MediaObject'
import { YOUTUBE } from '../../things/creativework/mimetypes'

import api from '../../configs/api'

export class EmbedMediaObjectDialog extends CoreDialog
{
    constructor( props )
    {
        super( props ) ;
        this.timer = null ;
        this.state =
        {
            ...this.state,
            encodingFormat : null ,
            embedUrl       : null ,
            media          : null ,
            preload        : true ,
            searching      : false
        };
    }

    agree = () =>
    {
        const { status } = this.state ;
        switch( status )
        {
            case RequestStatus.NEW :
            {
                const { media } = this.state ;
                if( media instanceof MediaObject )
                {
                    this.setState({ status:RequestStatus.READY } ) ;
                }
                break ;
            }
            case RequestStatus.READY :
            {
                this.save() ;
                break ;
            }
            default :
            {
               //
            }
        }
    }

    getAgreeLabel = () =>
    {
        const { agree , init : { agree : init } } = this.getLocale() || {} ;
        const { status } = this.state ;
        switch( status )
        {
            case RequestStatus.NEW :
            {
                return init ;
            }
            default :
            {
                return agree ;
            }
        }
    };

    getContent = () =>
    {
        const { status } = this.state ;
        switch( status )
        {
            case RequestStatus.NEW :
            {
                return this.getInit() ;
            }
            case RequestStatus.FAILED   :
            case RequestStatus.READY    :
            case RequestStatus.PROGRESS :
            {
                return this.getForm() ;
            }
            default :
            {
                return null ;
            }
        }
    }

    getLocale = () => this.props.locale.dialogs.embed ;

    getPreview = () =>
    {
        const { media , preload } = this.state ;
        if( media )
        {
            const height = 340 ;
            const { encodingFormat , embedUrl } = media ;
            switch( encodingFormat )
            {
                case YOUTUBE :
                {
                    return (
                        <div
                            className ='flex flex-1 bg-gray-800 items-center justify-center'
                            style     = {{ height }}
                        >
                            <iframe
                                id              = 'youtube-player'
                                allow           = "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen = { true }
                                frameBorder     = '0'
                                onLoad          = { () => { this.setState( { preload:false } ) ; } }
                                title           = 'youtube-preview'
                                width           = '100%'
                                height          = { height }
                                src             = { embedUrl }
                                style           = {{ display: preload ? 'none' : null }}
                                type            = 'text/html'
                            >
                            </iframe>
                            { preload && <Preloader color='#ffffff' size={16} /> }
                        </div>
                    )
                }
                default :
                {
                    return null ;
                }
            }
        }
        return null ;
    };

    prepare = item =>
    {
        let {
            author,
            alternativeHeadline,
            caption,
            description,
            editor,
            embedUrl,
            encodingFormat,
            headline,
            license,
            name,
            mentions,
            publisher,
            review,
            text,
            thumbnailUrl
        } = item ;

        return (
        {
            author,
            alternativeHeadline,
            caption,
            description,
            editor,
            embedUrl,
            encodingFormat,
            headline,
            license,
            mentions,
            name,
            publisher,
            review,
            text,
            thumbnailUrl
        });
    };

    reset = () =>
    {
        this.setState({
            embedUrl       : false ,
            encodingFormat : false ,
            error          : null ,
            media          : null ,
            status         : RequestStatus.NEW
        })
    }

    getForm = () =>
    {
        const { errors , media , status } = this.state ;
        if( media )
        {
            const { encodingFormat } = media ;

            let {
                form :
                {
                    elements,
                    encodingFormats :
                    {
                        [encodingFormat] : { label } = {}
                    }
                    = {}
                }
                = {}
            }
            = this.getLocale() || {} ;

            if( notEmpty(label) )
            {
                label = (
                    <Typography
                        align     = 'justify'
                        className = 'px-4 py-12'
                        variant   = 'body2'
                    >
                        { label }
                    </Typography>
                );
            }
            else
            {
                label = null ;
            }

            const preview = this.getPreview() ;

            let form ;
            if( elements instanceof Array && elements.length > 0 )
            {
                form = (
                    <Form
                        name     = 'media_form'
                        disabled = { status === RequestStatus.PROGRESS }
                        elements = { elements }
                        errors   = { errors }
                        onChange = { () => this.forceUpdate() }
                        thing    = { media }
                    />
                );
            }

            let content = (
                <div className='flex-1 flex flex-col rounded my-16' >
                    { preview }
                    { label }
                    { form }
                </div>
            ) ;

            return content ;
        }
        return null ;
    }

    getInit = () =>
    {
        const locale = this.getLocale() || {} ;

        let content ;

        let { init : { caption , encodingFormats = {} , create , label , search } = {} } = locale ;

        const {
            embedUrl,
            error ,
            media ,
            searching
        } = this.state ;

        let icon ;

        if( searching )
        {
            caption = search ;
            icon    = <div className='mx-8'><Search color='#666666' size={16} /></div> ;
        }
        else if( media )
        {
            if( notEmpty( create ) )
            {
                label = create ;
            }
            const { encodingFormat } = media ;
            if( notEmpty(encodingFormat) && encodingFormats.hasOwnProperty(encodingFormat) )
            {
                caption = encodingFormats[encodingFormat].caption || null ;
                icon    = getMediaIcon( media , { className : 'mr-4' } ) ;
            }
        }
        else
        {
            icon = <InfoIcon className='mr-4' color='action' fontSize='small' /> ;
        }

        if ( notEmpty(caption) )
        {
            caption = (
                <Typography
                    align     = 'justify'
                    className = 'px-4 py-12'
                    variant   = 'caption'
                >
                    { caption }
                </Typography>
            );

            if( icon )
            {
                caption = (
                    <div className='flex flex-row items-center flex-1'>
                        { icon }
                        { caption }
                    </div>
                )
            }
        }
        else
        {
            caption = null ;
        }

        content = (
            <div className='flex-1 flex flex-col rounded my-16' >
                <TextField
                    disabled    = { searching }
                    error       = { error }
                    fullWidth   = { true }
                    label       = { label }
                    multiline   = { true }
                    onChange    = { this.inputChange }
                    value       = { embedUrl || '' }
                    variant     = 'outlined'
                />
                { caption }
            </div>
        ) ;

        if( !this.isFullScreen() )
        {
            content = (
                <Zoom
                    in    = { true }
                    style = {{ transitionDelay : '250ms' }}
                >
                    { content }
                </Zoom>
            )
        }

        return content ;
    };

    inputChange = event =>
    {
        clearTimeout( this.timer ) ;

        if( !this._mounted )
        {
            return ;
        }

        let { target : { value } = {} } = event || {} ;

        this.setState( { embedUrl:value , encodingFormat:null , error:false , media:null } , () =>
        {
            this.timer = setTimeout( () =>
            {
                this.setState( { searching:true } , () =>
                {
                    createEmbedMediaObject( value )
                    .then( media =>
                    {
                        let embedUrl , encodingFormat ;
                        if( media instanceof MediaObject )
                        {
                            const { embedUrl:url = null , encodingFormat:format = null } = media || {} ;
                            embedUrl       = url ;
                            encodingFormat = format ;
                        }
                        else
                        {
                            media = null ;
                        }
                        this.setState( { embedUrl , encodingFormat , error:false , media , searching:false } ) ;
                    })
                    .catch( () =>
                    {
                        this.setState( { embedUrl:value , encodingFormat:null , error:notEmpty(value) , media:null , searching:false } ) ;
                    }) ;

                })
            }
            ,250 ) ;
        }) ;
    };

    save = () =>
    {
        const { media , status } = this.state ;
        if( media && status === RequestStatus.READY )
        {
            let {
                method,
                mock,
                prepare,
                uri
            } = this.props ;

            prepare = prepare || this.prepare ;

            let datas ;

            if( prepare instanceof Function )
            {
                datas = prepare(media) ;
            }

            if( mock )
            {
                console.log(
                    this + ' save, ' ,
                    'method:' + method ,
                    'uri:'    + api.url + uri ,
                    datas
                ) ;
            }
            else if( method === Method.POST )
            {
                this.setState({ status:RequestStatus.PROGRESS } );
                this.canceler = POST(
                    api.url + uri ,
                    {
                        datas,
                        cancel   : this._cancel,
                        fail     : this._fail,
                        success  : this._success
                    }
                );
            }
        }
    };

    showAgreeButton = () =>
    {
        const { showAgreeButton } = this.props ;
        const { status } = this.state ;
        switch( status )
        {
            case RequestStatus.NEW :
            {
                return showAgreeButton && notEmpty(this.state.encodingFormat) ;
            }

            case RequestStatus.FAILED   :
            case RequestStatus.READY    :
            case RequestStatus.PROGRESS :
            {
                const { media : { name } = {} } = this.state ;
                return showAgreeButton && ( isString(name) && name.length >= 2 ) ;
            }
            default :
            {
                return showAgreeButton ;
            }
        }
    }

    unmount = () =>
    {
        clearTimeout( this.timer ) ;
    };

    _cancel = () =>
    {
        if( this._mounted )
        {
            this.setState( { status:RequestStatus.ABORTED } ) ;
        }
        const { onCancel } = this.props ;
        if( onCancel instanceof Function )
        {
            onCancel(this.props) ;
        }
    };

    _fail = response =>
    {
        const { onFail } = this.props ;
        if( response )
        {
            const { data } = response ;
            if( data )
            {
                console.log( this + ' fail' , data ) ;

                const { message, status } = data ;

                if( status === 'error' )
                {
                    if( this._mounted )
                    {
                        this.setState( { errors:message , status:RequestStatus.FAILED } ) ;
                    }
                    if( onFail instanceof Function )
                    {
                        onFail( response, this.props ) ;
                    }
                    return ;
                }

                switch( message )
                {
                    case 'token revoked' :
                    {
                        console.log( this + " failed with a token revoked, status:" + status + ", message:" + message );
                        break ;
                    }
                    default :
                    {
                        console.log( this + " failed, status:" + status + ", message:" + message );
                    }
                }
            }
        }

        if( onFail instanceof Function )
        {
            onFail( response, this.props ) ;
        }
    };

    _success = response =>
    {
        if( response )
        {
            const { data } = response ;
            if( data )
            {
                if( this._mounted )
                {
                    this.setState( { status:RequestStatus.SUCCESS } );
                }

                const { result } = data ;
                if( result )
                {
                    const { onEmbedMedia } = this.props ;
                    if( onEmbedMedia instanceof Function )
                    {
                        onEmbedMedia( result, this.props ) ;
                    }
                }

                const { closeOnSuccess } = this.props ;
                if( closeOnSuccess )
                {
                    this.close() ;
                }
            }
        }
    }
}

EmbedMediaObjectDialog.defaultProps =
{
    ...CoreDialog.defaultProps ,
    acceptedFiles  : [ YOUTUBE ],
    closeOnSuccess : true ,
    icon           : <Icon className='mr-16' />,
    maxWidth       : 'md' ,
    method         : Method.POST,
    mock           : false,
    onEmbedMedia   : null,
    uri            : api.mediaObjects.url ,
    useIcon        : true
};

EmbedMediaObjectDialog.propTypes =
{
    ...CoreDialog.propTypes ,
    acceptedFiles  : PropTypes.arrayOf(PropTypes.string) ,
    closeOnSuccess : PropTypes.bool ,
    method         : PropTypes.oneOf([Method.POST]),
    onEmbedMedia   : PropTypes.func,
};

export default initDialog()( EmbedMediaObjectDialog ) ;
