import Thing from './Thing'

import isNotNull from 'vegas-js-core/src/isNotNull'
import notEmpty  from 'vegas-js-core/src/strings/notEmpty'

const DEFAULT_OPTIONS =
{
    clazz    : Thing,
    include  : false,
    ref      : 'subjectOf' ,
    refClazz : Thing,
    member   : null
}

/**
 * Returns the collections of existing things.
 * @param options - A setting object to configure the behavior of the function.
 * @returns {function(...[*]=)} A method to extract the collection of existing things.
 */
const getExistingSuggestions = ( options = null ) => props =>
{
    options = { ...DEFAULT_OPTIONS , ...options } ;

    const { rel } = props ;

    let { clazz , ref , refClazz } = options ;
    if( rel instanceof Thing && rel.hasOwnProperty(ref) )
    {
        ref = rel[ref] ;

        let suggestions = [] ;

        if( ref instanceof Array )
        {
            suggestions = ref.map( item => item instanceof clazz ? item : null ).filter( isNotNull ) ;
        }
        else if( ref instanceof refClazz )
        {
            let {
                include,
                member
            } = options ;

            if( include )
            {
                suggestions.push( ref ) ;
            }

            if( member )
            {
                if( notEmpty(member) )
                {
                    member = [ member ] ;
                }
                else if( member instanceof Array )
                {
                    member = member.filter( item => notEmpty(item)  ) ;
                }

                if( member instanceof Array && member.length > 0 )
                {
                    member.forEach( prop =>
                    {
                        let list = ref[prop] ;
                        if( list instanceof Array && list.length > 0 )
                        {
                            if( clazz instanceof Function )
                            {
                                list = list.filter( item => item instanceof clazz ) ;
                            }

                            if( list instanceof Array && list.length > 0 )
                            {
                                suggestions = [ ...suggestions , ...list ] ;
                            }
                        }
                    })
                }
            }
        }

        return suggestions ;
    }
    return null ;
};

export default getExistingSuggestions ;