import React from "react"

import initView        from '../../display/views/initView'
import TechniciansList from '../../display/lists/TechniciansList'
import View            from '../../display/views/View'

const styles = () => ({}) ;

class Technicians extends View
{
    build = () =>
    (
        <div className='flex flex-col w-full'>
            <TechniciansList parent = { this.element } />
        </div>
    );
    
    getLocale = () => this.props.locale.views.technicians ;
}

Technicians.defaultProps =
{
    ...View.defaultProps
};

Technicians.propTypes =
{
    ...View.propTypes
};

export default initView( Technicians, styles );