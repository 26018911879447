import React from 'react'

import moment from 'moment/moment'

import compose  from 'vegas-js-core/src/functors/compose'
import isString from 'vegas-js-core/src/isString'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import initApp from '../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { Grid } from '@material-ui/core'

import ArticleIcon from '@material-ui/icons/ChromeReaderMode'

import LabelItem    from '../../components/items/LabelItem'

import ThingContainer from '../containers/ThingContainer'

import identify from '../../things/identify'

import ArticleVO from '../../things/creativework/Article'
import ThingVO  from '../../things/Thing'

const styles = () => ({});

const half =
{
    sm : 6,
    xs : 12
};

class Article extends ThingContainer
{
    getContent = () =>
    {
        let { thing } = this.props;

        const locale = this.getLocale() ;

        const { labels } = locale ;

        let name ;
        let type ;

        if( thing instanceof ArticleVO )
        {
            let {
                additionalType,
                name : n
            } = thing ;

            if( isString( n ) )
            {
                name = n ;
            }

            if( additionalType instanceof ThingVO )
            {
                type = additionalType.getLocaleName( this.props.lang ) ;
            }
        }

        return (
            <Grid container spacing={4}>
                <Grid item {...half}>
                    <LabelItem enabled={!!(name)} title={ labels.name }>
                        { isString(name) ? ucFirst(name) : labels.empty }
                    </LabelItem>
                </Grid>

                <Grid item {...half}>
                    <LabelItem enabled={!!(type)} title={ labels.type }>
                        { isString(type) ? ucFirst(type) : labels.empty }
                    </LabelItem>
                </Grid>
            </Grid>
        );
    };

    getEntry = init =>
    {
        let empty = {} ;
        if( init instanceof ThingVO )
        {
            empty = init.toObject() ;
        }
        else if( init )
        {
            empty = { ...init } ;
        }
        return new ArticleVO(empty);
    };

    getLocale = () => this.props.locale.things.article;

    onEdit = item =>
    {
        const {
            onChange,
            thing
        } = this.props ;

        if( thing && item )
        {
            const {
                additionalType,
                name
            } = item ;

            thing.additionalType = additionalType ;
            thing.modified       = moment(new Date()).toISOString();
            thing.name           = name ;

            if( thing instanceof ThingVO )
            {
                thing.populate();
            }

            if( this._mounted )
            {
                this.forceUpdate() ;
            }

            if( onChange instanceof Function )
            {
                onChange(thing) ;
            }
        }
    }

    prepare = item =>
    {
        let {
            additionalType,
            name,
        } = item ;

        additionalType = identify(additionalType);

        return ({ additionalType, name });
    };
}

Article.defaultProps =
{
    ...ThingContainer.defaultProps ,
    icon       : <ArticleIcon/> ,
    optionMode : 'edit'
};

Article.propTypes =
{
    ...ThingContainer.propTypes
};

export default compose( withStyles( styles ) , withRouter , initApp )( Article ) ;
