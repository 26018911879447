import React from 'react'

import { withStyles } from '@material-ui/core/styles'

import format from 'vegas-js-core/src/strings/fastformat'

import { Grid } from '@material-ui/core'

import Address           from '../../../display/things/Address'
import Metadatas         from '../../../display/things/Metadatas'
import ThingSubContainer from '../../../display/containers/ThingSubContainer'
import TransitionGroup   from '../../../transitions/TransitionGroup'
import User              from '../../../display/things/person/User'

import UserVO from '../../../vo/User'

const half =
{
    md :  6 ,
    sm : 12 ,
    xs : 12
};

const styles = () => ({});

class About extends ThingSubContainer
{
    render = () =>
    {
        const { path, thing } = this.props ;
        if( thing )
        {
            let { address, uuid } = thing ;
            return (
            <div className="w-full p-16">
                <TransitionGroup duration={250} enter={{animation: "transition.slideUpIn"}}>
                    <Metadatas
                        activable = { false }
                        deletable = { false }
                        thing     = { thing }
                    />
                    <Grid container spacing={4}>
                        <Grid item {...half}>
                            <User
                                editUri  = { format(path.user,uuid) }
                                onChange = { this.change }
                                thing    = { new UserVO(thing) }
                            />
                        </Grid>
                        <Grid item {...half}>
                            <Address
                                editUri  = { format(path.address,uuid) }
                                onChange = { this.changeProperty('address',true) }
                                thing    = { address }
                                uri      = { !!(path && path.address) ? path.address : null }
                            />
                        </Grid>
                </Grid>
                </TransitionGroup>
            </div>
            );
        }
        return null ;
    }
}

About.defaultProps =
{
    ...ThingSubContainer.defaultProps
};

About.propTypes =
{
    ...ThingSubContainer.propTypes
};

export default withStyles( styles ) ( About ) ;
