const steps =
{
    empty  : 'Ajouter des étapes sur ce parcours.' ,
    labels :
    {
        add      : 'Ajouter',
        geoExist : 'GPS OK',
        geoNone  : 'GPS introuvable',
        location : 'Lieu: {0}',
        poi      : 'POI: {0}'
    },
    unknown  : 'Inconnu' ,
    title    : 'Étapes' ,
    tooltips :
    {
        active     : 'Activer',
        add        : 'Ajouter une étape' ,
        after      : 'Ajouter une étape ci-dessous' ,
        deactivate : 'Désactiver' ,
        down       : 'Descendre',
        edit       : 'Éditer' ,
        remove     : 'Supprimer' ,
        view       : 'Visualiser' ,
        up         : 'Remonter'
    }
};

export default steps ;
