import MediaObject from '../MediaObject'

/**
 * A video file.
 * @memberOf things
 */
class VideoObject extends MediaObject
{
    /**
     * Creates a new VideoObject instance.
     * @constructor
     * @param object The generic object to initialize the object the first time.
     */
    constructor( object = null )
    {
        super() ;

        /**
         * An actor, e.g. in tv, radio, movie, video games etc., or in an event. Actors can be associated with individual items or with a series, episode, clip.
         * @type {Array|Person}
         */
        this.actor = null ;

        /**
         * The caption for this object.
         * @type {string}
         */
        this.caption = null ;

        /**
         * A director of e.g. tv, radio, movie, video gaming etc. content, or of an event. Directors can be associated with individual items or with a series, episode, clip.
         * @type {Person|Array}
         */
        this.director = null ;

        /**
         * The composer of the soundtrack.
         * @type {Person|Array|MusicGroup}
         */
        this.musicBy = null ;

        /**
         * Thumbnail image for an image or video.
         * @type {string}
         */
        this.thumbnail = null ;

        /**
         * If this MediaObject is an AudioObject or VideoObject, the transcript of that object.
         * @type {string}
         */
        this.transcript = null ;

        /**
         * The frame size of the video.
         * @type {string}
         */
        this.videoFrameSize = null ;

        /**
         * The quality of the video.
         * @type {string}
         */
        this.videoQuality = null ;

        this.set(object) ;
    }

    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        super.clear() ;
        this.actor =
        this.caption =
        this.director =
        this.musicBy =
        this.thumbnail =
        this.transcript =
        this.videoFrameSize =
        this.videoQuality = null ;
        return this ;
    }

    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new VideoObject( this.toObject() ) ;
    }

    /**
     * Returns the generic object representation of the item.
     * @return {Object} The generic object representation of the item.
     */
    toObject()
    {
        let {
            actor,
            caption,
            director,
            musicBy,
            thumbnail,
            transcript,
            videoFrameSize,
            videoQuality
        }
        = this ;
        
        return {
            ...super.toObject() ,
            actor,
            caption,
            director,
            musicBy,
            thumbnail,
            transcript,
            videoFrameSize,
            videoQuality
        }
    }
}

export default VideoObject ;