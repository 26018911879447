
import isString from 'vegas-js-core/src/isString'

import AudioObject from './media/AudioObject'
import ImageObject from './media/ImageObject'
import VideoObject from './media/VideoObject'

const DEFAULT_OPTIONS =
{
    useAudio  : true,
    useAudios : true,
    useImage  : true,
    useLogo   : true,
    usePhotos : true,
    useVideo  : true,
    useVideos : true
};

/**
 * Populates the image, audios, photos and videos attributes of the specific thing reference.
 * @param {*} thing - The thing reference to populate.
 * @param {Object} options - The optional setting object.
 * @param {boolean} options.useAudios - Indicates if the audios attributes is populated.
 * @param {boolean} options.useImage - Indicates if the image attributes is populated.
 * @param {boolean} options.usePhotos - Indicates if the photos attributes is populated.
 * @param {boolean} options.useVideos - Indicates if the videos attributes is populated.
 * @returns {*} The thing reference.
 */
const populateMedias = ( thing , options = null ) =>
{
    if( thing )
    {
        options = { ...DEFAULT_OPTIONS , ...options } ;

        const {
            useAudio,
            useAudios ,
            useImage ,
            useLogo ,
            usePhotos ,
            useVideo,
            useVideos
        } = options ;

        if( useImage )
        {
            const { image } = thing ;
            if( image && !isString(image) && !( image instanceof ImageObject) )
            {
                thing.image = new ImageObject(image);
            }
        }

        if( useLogo )
        {
            const { logo } = thing ;
            if( logo && !isString(logo) && !( logo instanceof ImageObject) )
            {
                thing.logo = new ImageObject(logo);
            }
        }

        if( useAudio )
        {
            const { audio } = thing ;
            if( audio && !isString(audio) && !( audio instanceof AudioObject) )
            {
                thing.audio = new AudioObject(audio);
            }
        }

        if( useAudios )
        {
            const { audios } = thing ;
            if( audios instanceof Array && audios.length > 0 )
            {
               thing.audios = audios.map( item => item instanceof AudioObject ? item : new AudioObject(item) ) ;
            }
        }

        if( usePhotos )
        {
            const { photos } = thing ;
            if( photos instanceof Array )
            {
                thing.photos = photos.map( item => item instanceof ImageObject ? item : new ImageObject(item) ) ;
            }
        }

        if( useVideo )
        {
            const { video } = thing ;
            if( video && !isString(video) && !( video instanceof AudioObject) )
            {
                thing.video = new VideoObject(video);
            }
        }

        if( useVideos )
        {
            const { videos } = thing ;
            if( videos instanceof Array && videos.length > 0 )
            {
                thing.videos = videos.map(item => item instanceof VideoObject ? item : new VideoObject(item));
            }
        }
    }
    return thing ;
};

export default populateMedias ;