const user =
{
    loading  : "Chargement ..." ,
    notFound : "Utilisateur inconnu" ,
    tabs     :
    {
        about      : 'À propos' ,
        activities : 'Activités' ,
        sessions   : 'Sessions'
    },
    tooltips :
    {
        back : "Tous les utilisateurs"
    }
};

export default user ;