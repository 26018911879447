import list from './list'

const stages =
{
    ...list,
    helmet :
    {
        title : 'Points d\'intérêts' ,
        metas : []
    },
    empty  : 'Aucun point d\'intérêt trouvé',
    search : 'Rechercher un point d\'intérêt ...' ,
    title  : 'Points d\'intérêts'
};

export default stages ;
