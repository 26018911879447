import React from 'react'

import PropTypes from 'prop-types'

import clsx from 'clsx'

import isString from 'vegas-js-core/src/isString'
import notEmpty from 'vegas-js-core/src/strings/notEmpty'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import { Typography } from '@material-ui/core'

import ThingComponent from '../things/ThingComponent'

import Word from '../../things/Word'

import withi18n  from '../../contexts/i18n/withi18n'
import withSnack from '../../contexts/snack/withSnack'

export class TypeLabel extends ThingComponent
{
    render = () =>
    {
        let exist = false ;
        let image = null ;
        let style = {} ;
        let type  = null ;

        const { thing } = this.props ;
        if( thing )
        {
            const { member } = this.props ;
            if( notEmpty( member ) )
            {
                type = thing[member] ;
            }
        }

        if( type instanceof Word )
        {
            let {
                bgcolor,
                color,
                image:source,
                name
            } = type ;

            if( name )
            {
                const { lang } = this.props;
                type = type.getLocaleName(lang) ;
                if( notEmpty( type ) )
                {
                    if (isString(bgcolor))
                    {
                        exist = true ;
                        style = { ...style, backgroundColor: '#' + bgcolor };
                    }

                    if( isString(color) )
                    {
                        exist = true;
                        style = { ...style , color:'#'+color } ;
                    }

                    if( exist )
                    {
                        style = { ...style , paddingLeft:4 , paddingRight:4 }
                    }
                }
                else
                {
                    type = null ;
                }

                if( notEmpty(source) )
                {
                    image = (
                    <img
                        alt       = { type || '' }
                        className = "mr-8 self-center"
                        height    = { 16 }
                        width     = { 16 }
                        loading   = 'lazy'
                        src       = { source }
                    />)
                    ;
                }
            }
        }

        if ( notEmpty(type) )
        {
            const {
                className ,
                labelClassName,
                upperFirst
            } = this.props;
            return (
                <div className={ clsx("flex flex-row items-center", className )} >
                    { image }
                    <Typography
                        className = { clsx( labelClassName , exist ? "rounded" : null  ) }
                        style     = { style }
                        variant   = 'caption'
                    >
                        { upperFirst ? ucFirst(type) : type }
                    </Typography>
                </div>
            );
        }

        return null;
    }
}

TypeLabel.defaultProps =
{
    ...ThingComponent.defaultProps,
    member     : 'additionalType' ,
    upperFirst : true
}

TypeLabel.propTypes =
{
    ...ThingComponent.propTypes,
    member     : PropTypes.string ,
    upperFirst : PropTypes.bool
}

export default withSnack( withi18n( TypeLabel ) );
