import React from 'react'

import moment from 'moment/moment'

import compose  from 'vegas-js-core/src/functors/compose'
import isString from 'vegas-js-core/src/isString'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import initApp from '../../../contexts/app/initApp'

import { withRouter} from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { Grid } from '@material-ui/core'

import AccountBalanceIcon from '@material-ui/icons/AccountBalance'

import LabelItem from '../../../components/items/LabelItem'

import ThingContainer from '../../containers/ThingContainer'

import PersonVO from '../../../things/Person'
import ThingVO  from '../../../things/Thing'

const styles = () =>
({

});

const half =
{
    sm : 6 ,
    xs : 12
};

class Legal extends ThingContainer
{
    getContent = () =>
    {
        let { thing } = this.props;

        const locale = this.getLocale() ;

        const { labels } = locale ;

        let taxID ;
        let vatID ;

        if( thing instanceof PersonVO )
        {
            let {
                taxID : t,
                vatID : v

            } = thing ;

            if( isString( t ) )
            {
                taxID = t ;
            }

            if( isString( v ) )
            {
                vatID = v ;
            }
        }

        return (
            <Grid container spacing={4}>
                <Grid item {...half}>
                    <LabelItem enabled={!!(taxID)} title={ labels.taxID }>
                        { isString(taxID) ? ucFirst(taxID) : labels.empty }
                    </LabelItem>
                </Grid>
                <Grid item {...half}>
                    <LabelItem enabled={!!(vatID)} title={ labels.vatID }>
                        { isString(vatID) ? ucFirst(vatID) : labels.empty }
                    </LabelItem>
                </Grid>
            </Grid>
        );
    };

    getEntry = init =>
    {
        let empty = {} ;
        if( init instanceof ThingVO )
        {
            empty = init.toObject() ;
        }
        else if( init )
        {
            empty = { ...init } ;
        }
        return new PersonVO(empty);
    };

    getLocale = () => this.props.locale.things.person.legal ;

    onEdit = ( item ) =>
    {
        const { onChange, thing } = this.props ;
        if( item && thing instanceof ThingVO )
        {
            const {
                name ,
                taxID,
                vatID,
            } = item ;

            thing.name     = name ;
            thing.modified = moment(new Date()).toISOString();
            thing.taxID    = taxID ;
            thing.vatID    = vatID ;

            thing.populate();

            if( this._mounted )
            {
                this.forceUpdate() ;
            }

            if( onChange instanceof Function )
            {
                onChange(thing);
            }
        }
    };
}

Legal.defaultProps =
{
    ...ThingContainer.defaultProps ,
    icon       : <AccountBalanceIcon/> ,
    optionMode : 'edit'
};

Legal.propTypes =
{
    ...ThingContainer.propTypes
};

export default compose( withStyles( styles ) , withRouter , initApp )( Legal ) ;
