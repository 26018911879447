
import Livestock    from '../../Livestock'
import Observation  from '../../Observation'
import Technician   from '../../Technician'
import User         from '../../../vo/User'
import Veterinarian from '../../Veterinarian'
import Word         from '../../Word'
import Workshop     from '../../Workshop'

import AbortionReview      from '../reviews/AbortionReview'
import AbortionSerieReview from '../reviews/AbortionSerieReview'
import AnnualHealthReview  from '../reviews/AnnualHealthReview'
import SuspicionReview     from '../reviews/SuspicionReview'

/**
 * An Animal Health Observation definition.
 * @memberOf things.observations
 * @extends Observation
 */
class AnimalHealthObservation extends Observation
{
    /**
     * Creates a new AnimalHealthObservation instance.
     * @constructor
     * @param object The generic object to initialize the object.
     */
    constructor( object = null )
    {
        super( object ) ;
    }

    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new AnimalHealthObservation( this.toObject() ) ;
    }

    createAuthority( thing )
    {
        if( thing )
        {
            if( thing instanceof Array )
            {
                return thing.map( this.createAuthority )
            }

            const { id, url } = thing ;
            if( !(thing instanceof Veterinarian) && (url.indexOf( 'veterinarians/' + id ) > -1) )
            {
                return new Veterinarian( thing ) ;
            }
            else if( !(thing instanceof Technician) && (url.indexOf( 'technicians/' + id ) > -1) )
            {
                return new Technician( thing ) ;
            }

            return thing ;
        }

        return null ;
    }

    createOwner( thing )
    {
        if( thing && !(thing instanceof User) )
        {
            return new User(thing) ;
        }
        return thing ;
    }

    /**
     * Populate the object with specific behaviors or types.
     * Launched by default in the constructor and in the set methods.
     * @returns {Thing} The current reference.
     */
    populate()
    {
        super.populate() ;

        let {
            about,
            additionalType,
            review,
            subject
        } = this ;

        if( about && !(about instanceof Livestock))
        {
            this.about = new Livestock(about);
        }

        if( subject && !(subject instanceof Workshop))
        {
            this.subject = new Workshop(subject);
        }

        if( typeof(review) === 'string' || review instanceof String )
        {
            review = JSON.parse( review ) ;
        }

        if( additionalType instanceof Word )
        {
            const { alternateName } = additionalType ;

            switch( alternateName )
            {
                case 'abortion' :
                {
                    if( !(review instanceof AbortionReview))
                    {
                        review = new AbortionReview(review) ;
                        review.subjectOf = this ;
                    }
                    break ;
                }

                case 'abortion/series' :
                {
                    if( !(review instanceof AbortionSerieReview))
                    {
                        review = new AbortionSerieReview(review) ;
                        review.subjectOf = this ;
                    }
                    break ;
                }

                case 'aha' :
                {
                    if( !(review instanceof AnnualHealthReview))
                    {
                        review = new AnnualHealthReview(review) ;
                        review.subjectOf = this ;
                    }
                    break ;
                }

                case 'suspicion' :
                {
                    if( !(review instanceof SuspicionReview))
                    {
                        review = new SuspicionReview(review) ;
                        review.subjectOf = this ;
                    }
                    break ;
                }

                default :
                {
                    // do nothing
                }
            }
        }

        this.review = review ;

        return this ;
    }
}

export default AnimalHealthObservation ;
