import { AddDialog } from '../../../../dialogs/AddDialog'
import initDialog from '../../../../dialogs/initDialog'

import { prepare } from '../AnalysisSamplings'

const styles = () => ({});

export class AddAnalysisSamplingDialog extends AddDialog
{
    getLocale = () => this.props.locale.things.medical.analysisSamplings.add ;

    prepare = prepare ;
}

AddAnalysisSamplingDialog.defaultProps =
{
    ...AddDialog.defaultProps
};

AddAnalysisSamplingDialog.propTypes =
{
    ...AddDialog.propTypes
};

export default initDialog({ styles })( AddAnalysisSamplingDialog ) ;
