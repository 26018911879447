import api           from '../../../configs/api'
import Form          from '../../../display/forms/FormElements'
import Place         from '../../../things/Place'
import PropertyValue from '../../../things/PropertyValue'
import Thing         from '../../../things/Thing'

const places =
{
    add :
    {
        agree       : 'Ajouter' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez sélectionner un lieu en cliquant ci-dessous.' ,
        elements    :
        [
            {
                type       : Form.SEARCH_SELECTOR ,
                path       : api.places.url ,
                clazz      : Place ,
                clearLabel : 'Vider' ,
                closeLabel : 'Fermer' ,
                init       :
                {
                    id         : 'value',
                    key        : 'value',
                    label      : 'Lieu',
                    autoFocus  : false,
                    fullWidth  : true,
                    margin     : 'normal',
                    required   : true,
                    type       : 'search',
                    variant    : 'outlined'
                },
                inputProps :
                {
                    label : 'Lieu...'
                },
                logics :
                {
                    disabledSuggestions : ( props ) =>
                    {
                        const { rel } = props ;
                        if( rel instanceof Thing )
                        {
                            const { subjectOf } = rel ;
                            if( subjectOf instanceof Thing )
                            {
                                const { places } = subjectOf ;
                                if( places instanceof Array )
                                {
                                    return places ;
                                }
                            }
                        }
                        return null ;
                    }
                }
            }
        ],
        fail    : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading : 'Chargement en  cours...' ,
      //  method  : Method.POST,
        prepare : ( item ) =>
        {
            return (
            {
                value : item.value
            });
        },
        mapUri : ( item , uri ) =>
        {
            if( item instanceof PropertyValue)
            {
                const { value } = item ;
                if( value instanceof Thing )
                {
                    return uri + '/' + value.id ;
                }
            }
            return uri ;
        },
        success : 'Ajout du lieu réussi!' ,
        title   : 'Ajouter un lieu'
    },
    remove :
    {
        agree       : 'Supprimer' ,
        description : 'Souhaitez-vous supprimer ce lieu:' ,
        disagree    : 'Annuler' ,
        loading     : 'Suppression en cours...' ,
        title       : 'Supprimer le lieu ?'
    },
    title : 'Lieux' ,
    tooltips :
    {
        add    : "Ajouter" ,
        edit   : "Éditer" ,
        remove : "Supprimer"
    }
};

export default places ;
