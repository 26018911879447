import React from 'react'

import compose from 'vegas-js-core/src/functors/compose'
import ucFirst from 'vegas-js-core/src/strings/ucFirst'

import initApp from '../../../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { Chip } from '@material-ui/core'

import blueGrey from '@material-ui/core/colors/blueGrey'

import BlurCircularIcon from '@material-ui/icons/BlurCircular'

import ThingContainer from '../../../containers/ThingContainer'

import DiseaseVO from '../../../../things/medical/Disease'
import ThingVO   from '../../../../things/Thing'
import Word      from '../../../../things/Word'

const styles = theme => (
{
    chip :
    {
        borderColor : blueGrey[100],
        margin      : theme.spacing(0.5)
    }
});

class TransmissionMethod extends ThingContainer
{
    editInit = ( item ) =>
    {
        if( item )
        {
            const { transmissionMethod } = item ;
            if( transmissionMethod instanceof Array )
            {
                return transmissionMethod.map( element =>
                {
                    if( element instanceof Word )
                    {
                        return element.clone() ;
                    }
                    return null ;
                })
            }
        }
        return null ;
    };

    editPrepare = ( datas ) =>
    {
        if( datas instanceof Array )
        {
            datas = datas.map( item => item instanceof Word ? item.id : null ) ;
            return { transmissionMethod : datas.join(',')  } ;
        }
        return null ;
    };

    getBadgeLabel = () =>
    {
        const { thing } = this.props ;
        if( thing )
        {
            const { transmissionMethod } = thing  ;
            if( ( transmissionMethod instanceof Array) )
            {
                return transmissionMethod.length ;
            }
        }
        return 0 ;
    };

    getContent = () =>
    {
        const { classes, lang, thing } = this.props ;
        if( thing )
        {
            let { transmissionMethod } = thing ;

            if( transmissionMethod instanceof Array && transmissionMethod.length > 0 )
            {
                let css = { paddingLeft:8,marginRight:0, height:24, width:24 } ;

                transmissionMethod = transmissionMethod.map( ( item , index ) =>
                {
                    if( item instanceof Word )
                    {
                        const label = item.getLocaleName( lang );
                        const icon  = this.getImage(item,css);
                        return (
                        <Chip
                            className = { classes.chip }
                            clickable = { false }
                            key       = { 'observation_type_' + index }
                            icon      = { icon }
                            label     = { ucFirst(label) }
                            variant   = 'outlined'
                        />);
                    }
                    return null ;
                });

                return (
                    <div className="flex flex-row flex-wrap">
                        { transmissionMethod }
                    </div>
                )
            }
        }
        return null ;
    };

    getEntry = init =>
    {
        let empty = {} ;
        if( init instanceof ThingVO )
        {
            empty = init.toObject() ;
        }
        else if( init )
        {
            empty = { ...init } ;
        }
        return new DiseaseVO(empty) ;
    };

    getLocale = () => this.props.locale.things.medical.transmissionMethod ;
}

TransmissionMethod.defaultProps =
{
    ...ThingContainer.defaultProps ,
    badge      : true ,
    icon       : <BlurCircularIcon/>,
    optionMode : 'edit'
};

TransmissionMethod.propTypes =
{
    ...ThingContainer.propTypes
};

export default compose( withStyles( styles ) , withRouter , initApp )( TransmissionMethod );
