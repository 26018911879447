import React from 'react'

import compose from 'vegas-js-core/src/functors/compose'

import initApp from '../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import LocalHospitalIcon from '@material-ui/icons/LocalHospital'

import PropertyValue from '../../things/PropertyValue'

import ThingChildren, { styles } from './ThingChildren'

import VeterinaryCell from '../cells/VeterinaryCell'
import Veterinarian from '../../things/Veterinarian'
import Thing from '../../things/Thing';

class Veterinarians extends ThingChildren
{
    addMapUri = ( item , uri ) =>
    {
        if( item instanceof PropertyValue)
        {
            const { value } = item ;
            if( value instanceof Thing )
            {
                const { id } = value ;
                return uri + '/' + id ;
            }
        }
        return uri ;
    };

    addPrepare = item =>
    {
        const { value } = item ;
        return { value } ;
    }

    createChild = child => child instanceof Veterinarian
                         ? <VeterinaryCell thing={child} />
                         : null ;
    
    getLocale = () => this.props.locale.things.veterinarians ;
}

Veterinarians.defaultProps =
{
    ...ThingChildren.defaultProps ,
    animate    : true ,
    clazz      : Veterinarian ,
    emptyClazz : PropertyValue ,
    emptyIcon  : null ,
    icon       : <LocalHospitalIcon/> ,
    member     : 'veterinarians'
};

Veterinarians.propTypes =
{
    ...ThingChildren.propTypes
};

export default compose( withStyles( styles ) , withRouter , initApp )( Veterinarians ) ;