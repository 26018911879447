import generify from '../../generify'

import Event           from '../../Event'
import MedicalAnalysis from '../../medical/MedicalAnalysis'
import Word            from '../../Word'

/**
 * A livestock abortion event.
 * @memberOf things.livestock.events
 * @extends Event
 */
class AbortionEvent extends Event
{
    /**
     * Creates a new AbortionEvent instance.
     * @constructor
     * @param object The generic object to initialize the object.
     */
    constructor( object = null )
    {
        super() ;

        /**
         * The medical analysis information.
         * @type {MedicalAnalysis|Array}
         */
        this.analysis = null  ;

        /**
         * The gestation period information.
         * @type {Word}
         */
        this.period = null  ;
        
        this.set( object ) ;
    }

    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        super.clear() ;
        this.analysis =
        this.period   = null ;
        return this ;
    }

    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new AbortionEvent( this.toObject() ) ;
    }

    /**
     * Populate the object with specific behaviors or types.
     * Launched by default in the constructor and in the set methods.
     * @returns {Thing} The current reference.
     */
    populate()
    {
        super.populate() ;

        const {
            analysis,
            period
        } = this ;

        if( analysis instanceof Array )
        {
            this.analysis = analysis.map( item =>
            {
                if( !(item instanceof MedicalAnalysis) )
                {
                    item = new MedicalAnalysis(item) ;
                    item.subjectOf = this ;
                }
                return item ;
            }) ;
        }
        
        if( period && !(period instanceof Word))
        {
            this.period = new Word(period);
        }

        return this ;
    }

    /**
     * Returns the generic object representation of the item.
     * @param {boolean} [full=false] - Indicates if the generic object contains all the properties of only the basic implementation.
     * @return {Object} The generic object representation of the item.
     */
    toObject( full = false )
    {
        let {
            analysis,
            date,
            id,
            identifier,
            period
        } = this ;

        analysis = generify(analysis,true);
        period   = generify(period,true);

        return {
            ...( full && super.toObject() ),
            analysis,
            date,
            id,
            identifier,
            period
        };
    }
}

export default AbortionEvent ;