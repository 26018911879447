import React from 'react'

import compose  from 'vegas-js-core/src/functors/compose'
import isString from 'vegas-js-core/src/isString'
import ucFirst  from 'vegas-js-core/src/strings/ucFirst'

import initApp from '../../../contexts/app/initApp'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import { Typography } from '@material-ui/core'

import { FaBarcode } from 'react-icons/fa'

import NumberValue from '../../../things/NumberValue'
import ThingVO     from '../../../things/Thing'

import ThingChildren, { styles } from './../ThingChildren'
import Thing from '../../../things/Thing'

class Numbers extends ThingChildren
{
    createChild = ( child , editable = false ) =>
    {
        if( child )
        {
            const { lang } = this.props ;
            let { additionalType, value} = child ;

            let title = ucFirst(child.getLocaleName(lang)) ;

            if( editable )
            {
                title = (
                <Typography variant='subtitle2'>
                    <button className="text-blue-800 font-medium" onClick={ () => this.openEditDialog(child) }>
                        { title }
                    </button>
                </Typography>) ;
            }
            else
            {
                title = (
                    <Typography className = "text-gray-800 font-medium" variant='subtitle2'>
                        { title }
                    </Typography>
                );
            }

            if( isString(value) )
            {
                value = <Typography variant='body2' className='font-medium'>{ value }</Typography> ;
            }
            else
            {
                value = null ;
            }

            let type ;
            if ( additionalType instanceof ThingVO )
            {
                type = <Typography variant='body2'>{additionalType.getLocaleName(lang)}</Typography>;
            }

            let subtitle = (
                <div className="flex flex-row items-center">
                    { type }
                    { type && value && <Typography className="mx-8" variant='body2'>›</Typography> }
                    { value }
                </div>
            );

            return (
            <div className="py-8">
                { title }
                { subtitle }
            </div>
            );
        }

        return null ;
    };

    getLocale = () => this.props.locale.things.organization.numbers ;

    prepare = item =>
    {
        if( item instanceof NumberValue )
        {
            const { additionalType , name, value } = item ;
            return (
            {
                name ,
                additionalType : additionalType instanceof Thing ? String(additionalType.id) : null ,
                value
            });
        }
        return null ;
    };
}

Numbers.defaultProps =
{
    ...ThingChildren.defaultProps ,
    animate   : true ,
    clazz     : NumberValue,
    badge     : true ,
    emptyIcon : null ,
    icon      : <FaBarcode className="self-center" style={{ fontSize:22 }}/>,
    member    : 'numbers'
};

Numbers.propTypes =
{
    ...ThingChildren.propTypes
};

export default compose( withStyles( styles ) , withRouter , initApp )( Numbers ) ;
