import initDialog from '../initDialog'

import { RemoveDialog } from '../RemoveDialog'

const styles = () => ({});

class RemoveWebsiteDialog extends RemoveDialog
{
    getLocale = () => this.props.locale.dialogs.things.website.remove ;
}

RemoveWebsiteDialog.defaultProps =
{
    ...RemoveDialog.defaultProps
};

RemoveWebsiteDialog.propTypes =
{
    ...RemoveDialog.propTypes
};

export default initDialog({ styles })( RemoveWebsiteDialog );
