import getLocaleProperty from './getLocaleProperty'

/**
 * Returns the locale name expression of the thing.
 * @param {Thing} thing - The thing reference.
 * @param {string} lang - The lang of the expression.
 * @param {string} defaultLang - The lang of the expression.
 * @return {string} The locale name expression of the thing.
 */
const getLocaleName = getLocaleProperty('name' )

export default getLocaleName ;
