import Form from '../../../display/forms/FormElements'

export const elements =
[
    {
        type : Form.I18N_EDITOR ,
        init :
        {
            id    : 'headline' ,
            key   : 'headline' ,
            input : true,
            label : 'Titre'
        }
    },
    {
        type : Form.I18N_EDITOR ,
        init :
        {
            id    : 'alternativeHeadline',
            key   : 'alternativeHeadline',
            input : true,
            label : 'Texte alternatif',
        }
    },
    {
        type : Form.I18N_EDITOR ,
        init :
        {
            id    : 'caption',
            key   : 'caption',
            input : true,
            label : 'Légende'
        }
    },
    {
        type : Form.I18N_EDITOR ,
        init :
        {
            id    : 'description' ,
            key   : 'description' ,
            input : true,
            label : 'Description'
        }
    },
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id         : 'license',
            key        : 'license',
            label      : 'Licence',
            autoFocus  : false ,
            fullWidth  : true ,
            helperText : null ,
            margin     : 'dense' ,
            required   : false ,
            select     : false ,
            type       : 'text',
            variant    : 'outlined'
        }
    },
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id         : 'author',
            key        : 'author',
            label      : 'Auteur',
            autoFocus  : false ,
            fullWidth  : true ,
            helperText : null ,
            margin     : 'dense' ,
            required   : false ,
            select     : false ,
            type       : 'text',
            variant    : 'outlined'
        }
    },
    {
        type : Form.TEXTFIELD ,
        init :
        {
            id         : 'editor',
            key        : 'editor',
            label      : 'Éditeur',
            autoFocus  : false ,
            fullWidth  : true ,
            helperText : null ,
            margin     : 'dense' ,
            required   : false ,
            select     : false ,
            type       : 'text',
            variant    : 'outlined'
        }
    }
];

const audio =
[
    ...elements
];

const image =
[
    ...elements
];

const pdf =
[
    ...elements
];

const video =
[
    ...elements
];

const media =
{
    audio,
    image,
    pdf,
    video,
    agree      : 'Modifier' ,
    disagree   : 'Fermer' ,
    open       : 'Ouvrir dans une nouvelle page' ,
    remove     : 'Supprimer',
    labels     :
    {
        created   : 'Téléversé le :' ,
        dimension : 'Dimensions :' ,
        filesize  : 'Taille du fichier :' ,
        link      : 'Adresse web du fichier' ,
        modified  : 'Modifié le :' ,
        type      : 'Type du fichier : '
    },
    snacks :
    {
        copy : 'Lien copié dans le presse-papiers.'
    },
    tooltips   :
    {
        copy   : 'Copier le lien' ,
        remove : "Supprimer définitivement ?"
    },
    unknown : 'Inconnu'
};

export default media ;
