import React from 'react'

import format from 'vegas-js-core/src/strings/fastformat'

import clsx from 'clsx'

import { Typography } from '@material-ui/core'

import Animate from '../../transitions/Animate'
import View    from '../../display/views/View'

import initView from '../../display/views/initView'

const styles = theme =>
{
    return {
        logo:
        {
            marginBottom : 32,
            height       : 156
        },
        root:
        {
            backgroundColor : process.env.REACT_APP_THEME_COLOR ,
            background      : `url(${theme.assets.background}) no-repeat center center` ,
            backgroundSize  : 'cover' ,
            width           : '100%',
            height          : '100%'
        },
        shadow:
        {
            textShadow: "3px 3px 9px rgba(0,0,0,0.8)"
        }
    }
};

class Home extends View
{
    build = () =>
    {
        let {
            config ,
            classes
        } = this.props;

        //console.log( this + ' build' , this.props ) ;

        const locale = this.getLocale() ;

        return (
        <div className={classes.root}>
            { this.getHelmet() }

            <Animate
                animation = "transition.whirlIn"
                className = { classes.root }
                delay     = {1000}
            >
                <img className={ clsx( classes.logo , "mt-40 mx-40" ) } src={ config.assets.logo } alt="logo"/>
            </Animate>

            <Animate
                animation = "transition.whirlIn"
                className = {classes.root}
                delay     = {1000}
            >
                <Typography
                    className = 'mx-40 mb-12'
                    color     = 'inherit'
                    variant   = 'h5'
                >
                    { locale.title }
                </Typography>
            </Animate>
            <Animate
                animation = "transition.whirlIn"
                className = {classes.root}
                delay     = {1400}
            >
                <Typography
                    className = 'mx-40 mb-12'
                    color     = 'inherit'
                    variant   = 'body2'
                >
                    { locale.description }
                </Typography>
            </Animate>

            <Animate
                animation = "transition.whirlIn"
                className = {classes.root}
                delay     = {1800}
            >
                <Typography
                    className = 'mx-40 mb-12 italic'
                    color     = 'inherit'
                    variant   = 'caption'
                >
                    { format(locale.version, config.version) }
                </Typography>
            </Animate>
        </div>
        );
    }

    getLocale = () => this.props.locale.views.home ;
}

Home.propTypes =
{
    ...View.propTypes
};

export default initView( Home , styles ) ;
