export default
{
    description : 'Veuillez sélectionner une personne ci-dessous.' ,
    disagree    : 'Fermer' ,
    empty       : 'Aucune personne disponible.' ,
    fail        : 'Impossible de charger les personnes, veuillez relancer un peu plus tard.',
    loading     : 'Chargement en cours...' ,
    search      : 'Rechercher une personne ...' ,
    title       : 'Sélectionner une personne'
};
