import Thing from './Thing'

/**
 * Generates a function to sort two Things by position.
 * @param {object} options
 * @param {boolean} options.invert - Indicates if the sort is descending (true) or ascending (false).
 * @returns {Function} The Function to invoked to sort two things.
 */
const sortByPosition = ( { invert = false } = {} ) => ( thing1 , thing2 ) =>
{
    if( thing1 instanceof Thing && thing2 instanceof Thing )
    {
        const { position:pos1 } = thing1 ;
        const { position:pos2 } = thing2 ;
        if( pos1 < pos2 )
        {
            return invert ? 1 : -1 ;
        }
        else if( pos1 > pos2 )
        {
            return invert ? -1 : 1 ;
        }
    }
    return 0 ;
};

export default sortByPosition ;
