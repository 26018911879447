import add      from './add'
import addMedia from './addMedia'
import color    from './color'
import edit     from './edit'
import media    from './media'
import print    from './print'
import remove   from './remove'
import sort     from './sort'

export default
{
    add,
    addMedia,
    color,
    edit,
    media,
    print,
    remove,
    sort
};
