import NumberValue from './NumberValue'
import Sector from './Sector'
import Thing  from './Thing'

import generify from './generify'

/**
 * Creates a new workplace definition.
 * @memberOf things
 */
class Workplace extends Thing
{
    /**
     * Creates a new Workplace instance.
     * @constructor
     * @param object The generic object to initialize the object the first time.
     */
    constructor( object = null )
    {
        super() ;
    
        /**
         * Maximum number of elements who can be placed by a workplace.
         * @type {number|string}
         */
        this.capacity = null ;
        
        /**
         * The identifier of this workplace.
         * @type {Array}
         */
        this.identifier = null ;
        
        /**
         * The current number of attendee of this workplace.
         * @type {number|string}
         */
        this.numAttendee = null ;
        
        /**
         * The remainging attendee capacity of this workplace.
         * @type {number|string}
         */
        this.remainingAttendee = null;
        
        /**
         * The collection of all sectors register in this workplace.
         * @type {Array}
         */
        this.sectors = null ;

        this.set( object ) ;
    }

    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        super.clear() ;
        this.capacity          =
        this.identifier        =
        this.numAttendee       =
        this.remainingAttendee =
        this.sectors           = null ;
        return this ;
    }

    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new Workplace( this.toObject() ) ;
    }

    /**
     * Populate the object with specific behaviors or types. Launched by default in the constructor and in the set methods.
     * @returns {Thing} The current reference.
     */
    populate()
    {
        const { identifier, sectors } = this ;
        if( identifier && !(identifier instanceof NumberValue))
        {
            this.identifier = new NumberValue(identifier) ;
        }
        if( sectors instanceof Array )
        {
            this.sectors = sectors.map( item => item instanceof Sector ? item : new Sector(item) ) ;
        }
        return this ;
    }

    /**
     * Returns the generic object representation of the item.
     * @return {Object} The generic object representation of the item.
     */
    toObject()
    {
        let {
            capacity,
            identifier,
            numAttendee,
            remainingAttendee,
            sectors
        } = this ;
        
        identifier = generify(identifier,true);
        sectors    = generify(sectors);
        
        return {
            ...super.toObject() ,
            capacity,
            identifier,
            numAttendee,
            remainingAttendee,
            sectors
        }
    }
}

export default Workplace ;