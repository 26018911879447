import React, { Component } from 'react'

import PropTypes from 'prop-types'

import keycode from 'keycode'

import clsx from 'clsx'

import { withRouter } from 'react-router-dom'

import { IconButton, Input, Paper } from '@material-ui/core'

import CloseIcon  from '@material-ui/icons/Close'
import SearchIcon from '@material-ui/icons/Search'

import Animate from '../../transitions/Animate'

import withSearch from '../../contexts/search/withSearch'

class SearchBar extends Component
{
    constructor( props )
    {
        super( props ) ;
        this.state.search = this.props.search || '' ;
    }

    close = () =>
    {
        this.search( null );
        const { onClose } = this.props ;
        if( onClose )
        {
            onClose( this, this.props, this.state );
        }
    };

    componentWillUnmount()
    {
        clearTimeout(this.timeout) ;
        this.timeout = 0 ;
    }

    state =
    {
        search : ''
    };

    timeout = 0 ;

    render()
    {
        let {
            elevation,
            close,
            label,
            className,
            paperClassName,
            square,
            style
        } = this.props;

        const { search } = this.state ;

        if( !className )
        {
            className = "flex flex-1 flex-col items-center justify-center" ;
        }

        if( !paperClassName )
        {
            paperClassName = "flex items-center justify-between h-56 w-full max-w-lg" ;
        }
        else
        {
            paperClassName = clsx('flex items-center justify-between h-62', paperClassName) ;
        }

        return (
            <Animate animation="transition.expandIn" delay={50} duration={100}>
                <div className={ className } style={style} >
                    <Paper
                        className = { paperClassName }
                        elevation = { elevation }
                        square    = { square }
                    >
                        <SearchIcon color="action" className="ml-8"/>
                        <Input
                            autoFocus         = { true }
                            placeholder       = { label }
                            className         = "px-16"
                            disableUnderline  = { true }
                            fullWidth         = { true }
                            inputProps        = {{ 'aria-label': 'Search' }}
                            value             = { search || '' }
                            onChange          = { this.inputChange }
                            onKeyDown         = { this.inputKeyDown }
                        />
                        { close &&
                            <div>
                                <IconButton
                                    className = "mr-16"
                                    onClick   = { this.close }
                                >
                                    <CloseIcon
                                        color    = "action"
                                        fontSize = "small"
                                    />
                                </IconButton>
                            </div>
                        }
                    </Paper>
                </div>
            </Animate>
        );
    }

    inputChange = ( event ) =>
    {
        clearTimeout( this.timeout ) ;
        if( event )
        {
            const { target } = event ;
            if( target )
            {
                const { delay } = this.props ;
                const { value:search } = target ;
                this.setState( { search } );
                this.timeout = setTimeout( this.search, delay, search ) ;
            }
        }
    };

    inputKeyDown = ( event ) =>
    {
        const { keyCode } = event;

        switch( keyCode )
        {
            case keycode('enter') : // ENTER
            {
                this.search( event.target.value ) ;
                break ;
            }

            case keycode('escape') : // ESC
            {
                this.close() ;
                break ;
            }

            default :
            {
                //
            }
        }
    };

    search = search =>
    {
        const { setSearch } = this.props ;
        if( setSearch )
        {
            setSearch( { search, searchBar:!!(search) , page:null  } ) ;
        }
    };
}

SearchBar.defaultProps =
{
    autoComplete   : false ,
    className      : null,
    close          : false,
    delay          : 500 ,
    disabled       : true,
    elevation      : 1 ,
    label          : 'Search ...' ,
    onClose        : null,
    onSearch       : null,
    paperClassName : null,
    searchChange   : null,
    search         : '',
    square         : false,
    style          : null
};

SearchBar.propTypes =
{
    autoComplete   : PropTypes.bool,
    className      : PropTypes.string,
    close          : PropTypes.bool,
    delay          : PropTypes.number,
    disabled       : PropTypes.bool,
    elevation      : PropTypes.number,
    label          : PropTypes.string,
    onClose        : PropTypes.func,
    onSearch       : PropTypes.func,
    paperClassName : PropTypes.string,
    searchChange   : PropTypes.func,
    setSearch      : PropTypes.func,
    search         : PropTypes.string,
    square         : PropTypes.bool,
    style          : PropTypes.object
};

export default withRouter( withSearch(SearchBar) ) ;
