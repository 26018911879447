import Thing from './Thing'
import Word  from './Word'

/**
 * Creates a new workplace definition.
 * @memberOf things
 */
class Sector extends Thing
{
    /**
     * Creates a new Sector instance.
     * @constructor
     * @param object The generic object to initialize the object the first time.
     */
    constructor( object = null )
    {
        super() ;
    
        /**
         * Maximum number of elements who can be placed by a sector.
         * @type {*}
         */
        this.capacity = null ;
        
        /**
         * The current number of attendee of this sector.
         * @type {*}
         */
        this.numAttendee = null ;
        
        /**
         * The remainging attendee capacity of this sector.
         * @type {*}
         */
        this.remainingAttendee = null;
        
        this.set( object ) ;
    }

    /**
     * Clear the object.
     * @return {Object} The current item reference.
     */
    clear()
    {
        super.clear() ;
        this.capacity          =
        this.numAttendee       =
        this.remainingAttendee = null ;
        return this ;
    }
    
    /**
     * Returns a shallow copy of the object.
     * @return {Thing} a shallow copy of the object.
     */
    clone()
    {
        return new Sector( this.toObject() ) ;
    }

    /**
     * Populate the object with specific behaviors or types. Launched by default in the constructor and in the set methods.
     * @returns {Thing} The current reference.
     */
    populate()
    {
        if( this.additionalType && !(this.additionalType instanceof Word))
        {
            this.additionalType = new Word(this.additionalType) ;
        }
        return this ;
    }

    /**
     * Returns the generic object representation of the item.
     * @return {Object} The generic object representation of the item.
     */
    toObject()
    {
        let {
            capacity,
            numAttendee,
            remainingAttendee,
        } = this ;
        return {
            ...super.toObject() ,
            capacity,
            numAttendee,
            remainingAttendee
        };
    }
}

export default Sector ;