import grid from './grid'

const articles =
{
    ...grid,
    helmet :
    {
        title : 'Articles' ,
        metas : []
    },
    empty  : 'Aucun article trouvé',
    search : 'Rechercher un article...' ,
    title  : 'Articles'
};

export default articles ;
