import application from './application'
import debug       from '../debug'
import languages   from './languages'
import phone       from './phone_numbers'
import verbose     from '../verbose'

import acceptedUploadMedias from './acceptedUploadMedias'

const defaultPreferredAudios = [ 'audio/mpeg' , 'audio/mp4' , 'audio/aac' ] ; // filo
const defaultPreferredImages = [ 'image/webp' ] ;
const defaultPreferredVideos = [ 'video/mp4'  ] ;

const config =
{
    name       : process.env.REACT_APP_NAME ,
    homepage   : process.env.REACT_APP_HOMEPAGE ,
    home       : '/' ,
    timeout    : process.env.REACT_APP_TIMEOUT ,

    application,
    debug,
    languages,
    phone,
    verbose,

    // settings

    defaultLang  : process.env.REACT_APP_DEFAULT_LANG ,
    defaultTheme : process.env.REACT_APP_DEFAULT_THEME,
    lang         : process.env.REACT_APP_LANG ,
    themeColor   : process.env.REACT_APP_THEME_COLOR ,

    // storages

    cache      : { get : process.env.REACT_APP_CACHE_GET } ,
    referrerID : process.env.REACT_APP_REFERRER_ID,

    // dimensions

    avatar :
    {
        maxHeight : 100 ,
        maxWidth  : 100
    },
    icon :
    {
        maxHeight : 256 ,
        maxWidth  : 256
    },
    image :
    {
        maxHeight : 800 ,
        maxWidth  : 800
    },

    // metadatas

    helmet     :
    {
        defaultTitle  : process.env.REACT_APP_TITLE,
        titleTemplate : process.env.REACT_APP_TITLE + ' - %s' ,
        metas         :
        [
            { charSet : 'utf-8'} ,

            { name : 'description' , content : process.env.REACT_APP_DESCRIPTION } ,
            { name : 'keywords'    , content : process.env.REACT_APP_KEYWORDS } ,

            { property : "og:type" , content : 'article' }
        ]
    },

    // map

    map :
    {
        elevation    : parseFloat(process.env.REACT_APP_MAP_ELEVATION),
        latitude     : parseFloat(process.env.REACT_APP_MAP_LATITUDE),
        longitude    : parseFloat(process.env.REACT_APP_MAP_LONGITUDE),
        locale       : { attribution : process.env.REACT_APP_MAP_ATTRIBUTION },
        maxElevation : 8900,
        maxLatitude  : 90,
        maxLongitude : 180,
        maxZoom      : 19 ,
        minElevation : -11500,
        minLatitude  : -90,
        minLongitude : -180,
        minZoom      : 1,
        provider     : process.env.REACT_APP_MAP_PROVIDER ,
        zoom         : parseInt(process.env.REACT_APP_MAP_ZOOM)
    },

    // medias

    acceptedUploadMedias ,
    preferredMedia :
    {
        audio : process.env.REACT_APP_PREFERRED_AUDIO ? process.env.REACT_APP_PREFERRED_AUDIO.split(',') : defaultPreferredAudios,
        image : process.env.REACT_APP_PREFERRED_IMAGE ? process.env.REACT_APP_PREFERRED_IMAGE.split(',') : defaultPreferredImages,
        video : process.env.REACT_APP_PREFERRED_VIDEO ? process.env.REACT_APP_PREFERRED_VIDEO.split(',') : defaultPreferredVideos
    },

    // networks

    ip_api_url : 'http://ip-api.com/#',

    // html

    sanitizeAll :
    {
        allowedTags: [],
        allowedAttributes: {}
    },
    sanitizeOptions :
    {
        allowedTags :
        [
            'b', 'i', 'em', 'strong', 'a' , 'p' , 'del', 'ins',
            'h1' , 'h2' , 'h3', 'h4', 'h5', 'h6', 'blockquote', 'pre'
        ],
        allowedAttributes :
        {
            'a' : [ 'href' , 'target' ]
        },
        nonTextTags: [ 'style', 'script', 'textarea', 'noscript' ]
    }
} ;

export const getConfig = options =>
{
    return { ...config, ...options } ;
};

export default config ;
