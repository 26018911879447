import React from 'react'

import clsx from 'clsx'

import compose from 'vegas-js-core/src/functors/compose'

import initApp from '../../../../contexts/app/initApp'

import { withStyles } from '@material-ui/core/styles'

import Questions from '../Questions'

import ThingContainer from '../../../containers/ThingContainer'

import api from '../../../../configs/api'

import QuestionGame from '../../../../things/game/QuestionGame'

const styles = () => (
{
    root :
    {
        width : '100%'
    }
});

class QuestionQuest extends ThingContainer
{
    render = () =>
    {
        const {
            classes,
            className,
            style,
            thing
        }
        = this.props;

        if( thing instanceof QuestionGame )
        {
            let uri = this.getPath() ;

            // console.log( this + ' render' , thing.score ) ;

            return (
            <div
                className = { clsx(classes.root, className) }
                style     = { style }
            >
                <Questions
                    addUri         = { uri }
                    containerProps = {{
                        className : "flex flex-col flex-0 bg-transparent shadow-none p-12 mb-16",
                        //elevation : 0,
                        square    : true
                    }}
                    editUri   = { uri }
                    onChange  = { this.change }
                    removeUri = { uri }
                    subjectOf = { thing }
                    thing     = { thing }
                    uri       = { uri }
                />
            </div>);
        }

        return null ;
    }
}

QuestionQuest.defaultProps =
{
    ...ThingContainer.defaultProps,
    uri : api.games.questions.game
};

QuestionQuest.propTypes =
{
    ...ThingContainer.propTypes
};

export default compose( withStyles( styles ) , initApp )( QuestionQuest ) ;
