const team =
{
    copy :
    {
        snack   : 'Nom de l\'équipe dans le presse-papiers.' ,
        tooltip : 'Copier le nom de l\'équipe'
    },
    label : '{0}',
    title : null
};

export default team ;
