import ucFirst from 'vegas-js-core/src/strings/ucFirst'

import Form from '../../../display/forms/FormElements'

import Technician from '../../../things/Technician'
import Thing      from '../../../things/Thing'

import getLocaleName from '../../../things/getLocaleName'

import api from '../../../configs/api'

const elements =
[
    {
        type       : Form.SEARCH_SELECTOR ,
        path       : api.technicians.url ,
        clazz      : Technician ,
        clearLabel : 'Vider' ,
        closeLabel : 'Fermer' ,
        init       :
        {
            id         : 'value',
            key        : 'value',
            label      : 'Technicien',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            required   : true,
            type       : 'search',
            variant    : 'outlined'
        },
        inputProps :
        {
            label : 'Technicien...'
        },
        logics :
        {
            disabledSuggestions : props =>
            {
                const { rel } = props ;
                let suggestions = [] ;
                if( rel instanceof Thing )
                {
                    const { subjectOf } = rel ;
                    if( subjectOf instanceof Thing )
                    {
                        if( subjectOf instanceof Technician )
                        {
                            suggestions.push( subjectOf ) ;
                        }
                        const { technicians } = subjectOf ;
                        if( technicians instanceof Array )
                        {
                            return technicians ;
                        }
                    }
                }
                return suggestions ;
            }
        },
        suggestionRef : ( item ) =>
        {
            if( item instanceof Technician )
            {
                const { person } = item ;
                if( person instanceof Thing )
                {
                    return person;
                }
            }
            return item ;
        },
        valueLabel : ( item , props ) =>
        {
            if( item instanceof Technician )
            {
                const { person } = item ;
                if( person instanceof Thing )
                {
                    return ucFirst( getLocaleName( person, props.lang ) ) ;
                }
            }
            return item ;
        }
    }
];

const technicians =
{
    add :
    {
        elements,
        agree       : 'Ajouter' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez sélectionner un technicien en cliquant ci-dessous.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en  cours...' ,
        success     : 'Ajout du nouveau technicien réussi!' ,
        title       : 'Ajouter un technicien',
    },
    remove :
    {
        agree       : 'Supprimer' ,
        description : 'Souhaitez-vous supprimer ce technicien de cette liste ?' ,
        disagree    : 'Annuler' ,
        item        :
        {
            text :
            {
                primary : ( element , props ) =>
                {
                    if( element instanceof Technician )
                    {
                        const { lang } = props ;
                        const { person } = element ;
                        if( person instanceof Thing )
                        {
                            return getLocaleName( person , lang );
                        }
                    }
                    return 'Inconnu';
                }
            }
        },
        loading : 'Suppression en cours...' ,
        title   : 'Supprimer le technicien ?'
    },
    title : 'Techniciens' ,
    tooltips :
    {
        add    : "Ajouter" ,
        edit   : "Éditer" ,
        remove : "Supprimer"
    }
};

export default technicians ;
