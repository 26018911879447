import React, {Component} from 'react';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles'

import { IconButton, Snackbar } from '@material-ui/core'

import CloseIcon from '@material-ui/icons/Close'

import flash from "../../configs/flash"

const styles = () =>
({
    root:
    {
        margin: 20
    }
});

class Flash extends Component
{
    state =
    {
        open    : false,
        message : ''
    };

    close = (event, reason) =>
    {
        if ( reason === 'clickaway' )
        {
            return;
        }
        this.setState( { open: false , message : '' } );
    };

    componentDidMount()
    {
        const alert = sessionStorage.getItem( flash ) ;
        if( alert !== null )
        {
            sessionStorage.removeItem(flash);
            if( ( alert instanceof String) || ( typeof(alert) ==='string' ) )
            {
                if( alert.length > 0 )
                {
                    this.open( alert );
                }
            }
        }
    }

    open( message )
    {
        this.setState({ open: true , message });
    }

    render()
    {
        const { classes } = this.props ;
        const { message } = this.state ;
        return (
            <Snackbar
                className={ classes.root }
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center'}}
                open={ this.state.open }
                autoHideDuration={ 6000 }
                onClose={ this.close }
                ContentProps={{ 'aria-describedby': 'message-id' }}
                message={<span id="message-id">{ message }</span>}
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={this.handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                ]}
            />
        );
    }

}

Flash.propTypes =
{
    classes : PropTypes.object.isRequired
};

export default withStyles(styles, {withTheme: true})(Flash);
