import React from 'react'

import TransitionGroup from '../../../transitions/TransitionGroup'

import format from "vegas-js-core/src/strings/fastformat"

import { Grid } from '@material-ui/core'

import Workplaces from './Workplaces'

import Metadatas         from '../../../display/things/Metadatas'
import ThingSubContainer from '../../../display/containers/ThingSubContainer'
import Workshop          from '../../../display/things/Workshop'

const full =
{
    xs : 12
};

class About extends ThingSubContainer
{
    render = () =>
    {
        const { thing } = this.props ;

        if( thing )
        {
            const { path } = this.props ;

            const { id, subjectOf } = thing ;

            const editUri = format( path.workshop , subjectOf.id , id ) ;

            return (
            <div className='w-full h-full p-16'>
                <TransitionGroup duration={250} enter={{animation: "transition.slideUpBigIn"}}>

                    <Metadatas
                        activable = { false }
                        thing     = { thing }
                    />

                    <Grid container spacing={4}>
                        <Grid item {...full} >
                            <Workshop
                                editUri  = { editUri }
                                onChange = { this.change }
                                thing    = { thing }
                            />
                        </Grid>
                        <Grid item {...full}>
                            <Workplaces
                                addUri    = { format( path.workplaces , id ) }
                                onChange  = { this.change }
                                thing     = { thing }
                            />
                        </Grid>
                    </Grid>
                </TransitionGroup>
            </div>
            );
        }

        return null ;
    }
}

About.defaultProps =
{
    ...ThingSubContainer.defaultProps
};

About.propTypes =
{
    ...ThingSubContainer.propTypes
};

export default About ;
