export const article          = 'article' ;
export const conceptualObject = 'conceptualObject' ;
export const course           = 'course' ;
export const disease          = 'disease' ;
export const event            = 'event' ;
export const livestock        = 'livestock' ;
export const organization     = 'organization' ;
export const person           = 'person' ;
export const place            = 'place' ;
export const stage            = 'stage' ;
export const technician       = 'technician' ;
export const unknown          = 'unknown' ;
export const veterinarian     = 'veterinarian' ;

const FavoriteType =
{
    article,
    conceptualObject,
    course ,
    disease ,
    event,
    livestock,
    organization,
    person,
    place,
    stage,
    technician,
    unknown,
    veterinarian
} ;

export default FavoriteType ;
