import isString  from 'vegas-js-core/src/isString'

import initDialog from '../initDialog'

import { AddDialog } from '../AddDialog'

import Thing from "../../../things/Thing"

const styles = () => ({});

export class AddObservationDialog extends AddDialog
{
    getLocale = () => this.props.locale.dialogs.application.observation.add ;

    prepare = item =>
    {
        let {
            additionalType,
            alternateName
        } = item ;

        if( additionalType instanceof Thing )
        {
            additionalType = String(additionalType.id)
        }
        else
        {
            additionalType = null ;
        }

        if( alternateName )
        {
            const { config : { languages } = {} } = this.props ;
            if( languages )
            {
                languages.forEach( lang =>
                {
                    if( !isString(alternateName[lang]) )
                    {
                        alternateName[lang] = '' ;
                    }
                })
            }
        }

        return { additionalType, alternateName } ;
    };
}

AddObservationDialog.defaultProps =
{
    ...AddDialog.defaultProps
};

AddObservationDialog.propTypes =
{
    ...AddDialog.propTypes
};

export default initDialog({ styles })( AddObservationDialog ) ;
