import notEmpty from 'vegas-js-core/src/strings/notEmpty'

const iframeSrc = value =>
{
    if( notEmpty(value) )
    {
        const parser = new DOMParser();
        let iframe = parser.parseFromString( value , 'text/html' ) ;
        if( iframe )
        {
            iframe = iframe.getElementsByTagName("iframe");
            if( iframe )
            {
                return iframe[0].src ;
            }

        }
    }
    return null ;
}

export default iframeSrc ;