import api from '../configs/api'

import Article          from '../things/creativework/Article'
import ConceptualObject from '../things/ConceptualObject'
import Course           from '../things/Course'
import Event            from '../things/Event'
import Disease          from '../things/medical/Disease'
import Livestock        from '../things/Livestock'
import Organization     from '../things/Organization'
import Person           from '../things/Person'
import Place            from '../things/Place'
import Stage            from '../things/Stage'
import Step             from '../things/Step'
import Word             from '../things/Word'

const {
    articles,
    events,
    diseases,
    conceptualObjects,
    courses,
    livestocks,
    organizations,
    people,
    places,
    stages,
    steps,
    thesaurus,
    url
}
= api ;

const clazzFactory =
{
    [ url + articles.url ]          : Article,
    [ url + conceptualObjects.url ] : ConceptualObject,
    [ url + courses.url           ] : Course,
    [ url + events.url            ] : Event,
    [ url + diseases.url          ] : Disease,
    [ url + livestocks.url        ] : Livestock,
    [ url + organizations.url     ] : Organization,
    [ url + people.url            ] : Person,
    [ url + places.url            ] : Place,
    [ url + stages.url            ] : Stage,
    [ url + steps.url             ] : Step,
    [ url + thesaurus.url         ] : Word
};

/**
 * Store all the matching classes to a specific API URL.
 */
export default clazzFactory ;