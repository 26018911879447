import format from 'vegas-js-core/src/strings/fastformat'

const patterns =
{
    day       : '{0}' ,
    date      : 'ddd ll',
    dateTime  : 'ddd ll - HH:mm',
    interval  : 'From {0} to {1}' ,
    time      : 'HH:mm',
    timeRange : '{0} from {1} to {2}' ,
    until     : 'Until {0}'
};

/**
 * Returns the human readable event period label expression.
 * @param {Event} event - The Event reference.
 * @param {object} moment - The moment helper reference to format the label.
 * @param {Object} locale - The optional locale definitions to override the default patterns used to format the period expression.
 * @returns {null|string} the human readable event period label expression.
 */
const getLocalePeriodLabel = ( event , moment , locale = null ) =>
{
    if( event )
    {
        let { endDate, startDate } = event ;
        if ( endDate || startDate ) 
        {
            locale = { ...patterns, ...locale } ;
            
            let period ;

            let from ;
            if( startDate )
            {
                from = moment(startDate) ;
            }

            let to ;
            if( endDate )
            {
                to = moment(endDate) ;
            }

            if( from )
            {
                if( to )
                {
                    if ( from.isSame( to , 'day') )
                    {
                        if( from.isBefore( to , 'minutes') )
                        {
                            period = format( 
                                locale.timeRange, 
                                from.format( locale.date ) , 
                                from.format( locale.time ) , 
                                  to.format( locale.time ) 
                            )  ;
                        }
                        else
                        {
                            period = format( locale.day, from.format( locale.dateTime ) )  ;
                        }
                    }
                    else if( from.isBefore( to ,'day' ) )
                    {
                        period = format( locale.interval, from.format( locale.dateTime ), to.format( locale.dateTime ) ) ;
                    }
                }
                else
                {
                    period = format( locale.day, from.format( locale.dateTime ) ) ;
                }
            }
            else if( to )
            {
                period = format( locale.until, to.format( locale.dateTime ) ) ;
            }
            
            return period 
        }
    }
    
    return null ;
};

export default getLocalePeriodLabel ;
