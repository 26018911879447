import list from './list'

const events =
{
    ...list,
    helmet :
    {
        title : 'Événements' ,
        metas : []
    },
    empty  : 'Aucun événement trouvé',
    search : 'Rechercher un événement ...' ,
    title  : 'Événements'
};

export default events ;
