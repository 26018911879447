import list from './list'

const veterinarians =
{
    ...list,
    helmet :
    {
        title : 'Vétérinaires' ,
        metas : []
    },
    empty  : 'Aucun vétérinaire',
    search : 'Rechercher un vétérinaire ...' ,
    title  : 'Vétérinaire'
};

export default veterinarians ;
