import React, { Component } from 'react'

import 'velocity-animate/velocity.ui'

import PropTypes from 'prop-types'

import { VelocityTransitionGroup } from 'velocity-react'

/*

 # Transitions, ex: transition.fadeIn
 --------------------------------------------
 |         IN         |         OUT         |
 --------------------------------------------
 | fadeIn             | fadeOut             |
 | flipXIn            | flipXOut            |
 | flipYIn            | flipYOut            |
 | flipBounceXIn      | flipBounceXOut      |
 | flipBounceYIn      | flipBounceYOut      |
 | swoopIn            | swoopOut            |
 | whirlIn            | whirlOut            |
 | shrinkIn           | shrinkOut           |
 | expandIn           | expandOut           |
 | bounceIn           | bounceOut           |
 | bounceUpIn         | bounceUpOut         |
 | bounceDownIn       | bounceDownOut       |
 | bounceLeftIn       | bounceLeftOut       |
 | bounceRightIn      | bounceRightOut      |
 | slideDownIn        | slideDownOut        |
 | slideLeftIn        | slideLeftOut        |
 | slideRightIn       | slideRightOut       |
 | slideUpIn          | slideUpOut          |
 | slideDownBigIn     | slideDownBigOut     |
 | slideLeftBigIn     | slideLeftBigOut     |
 | slidRightBigIn     | slideRightBigOut    |
 | slideUpBigIn       | slideUpBigOut       |
 | perspectiveDownIn  | perspectiveDownOut  |
 | perspectiveLeftIn  | perspectiveLeftOut  |
 | perspectiveRightIn | perspectiveRightOut |
 | perspectiveUpIn    | perspectiveUpOut    |
 --------------------------------------------

 */

export const enterDefault =
{
    animation  : 'transition.fadeIn',
    stagger    : 50,
    duration   : 200,
    display    : null,
    visibility : 'visible',
    delay      : 0
};

export const leaveDefault =
{
    stagger   : 50,
    duration  : 200,
    display   : null,
    visibility: 'visible',
    delay     : 0
};

class TransitionGroup extends Component
{
    render()
    {
        const {
            children,
            enter,
            leave ,
            runOnMount,
            ...rest
        } = this.props ;

        const options =
        {
            enter : { ...enterDefault , ...enter },
            leave : { ...leaveDefault , ...leave },
            runOnMount ,
            ...rest
        };

        return (
            <VelocityTransitionGroup
                {...options}
            >
                { children }
            </VelocityTransitionGroup>
        );
    }
}

TransitionGroup.defaultProps =
{
    easing         : [ 0.4 , 0.0 , 0.2 , 1 ],
    enter          : null,
    enterHideStyle : { visibility : 'hidden' },
    enterShowStyle : { visibility : 'hidden' },
    leave          : null,
    runOnMount     : true
};

TransitionGroup.propTypes =
{
    children       : PropTypes.any,
    enter          : PropTypes.object,
    enterHideStyle : PropTypes.object ,
    enterShowStyle : PropTypes.object ,
    leave          : PropTypes.object,
    runOnMount     : PropTypes.bool
};


export default TransitionGroup;
