import React from 'react'

import StageIcon  from '@material-ui/icons/Stars'

import withDatasGrid from './withDatasGrid'
import { ThingsGrid } from './ThingsGrid'

import Stage     from '../../things/Stage'
import StageCell from '../cells/StageCell'

import AddStageDialog    from '../dialogs/add/AddStageDialog'
import RemoveStageDialog from '../dialogs/remove/RemoveStageDialog'

class StagesGrid extends ThingsGrid
{
    getLocale = () => this.props.locale.display.grids.stages ;
}

StagesGrid.defaultProps =
{
    ...ThingsGrid.defaultProps,
    AddDialogComponent    : AddStageDialog,
    CellComponent         : StageCell ,
    clazz                 : Stage,
    icon                  : <StageIcon />,
    mediaIcon             : <StageIcon fontSize='large' />,
    mediaProps            : { className : 'bg-hero-topography-2' },
    preferable            : true,
    RemoveDialogComponent : RemoveStageDialog
};

StagesGrid.propTypes =
{
    ...ThingsGrid.propTypes
};

export default withDatasGrid()( StagesGrid ) ;
