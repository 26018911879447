import api   from '../../../../configs/api'
import Form  from '../../../../display/forms/FormElements'
import Place from '../../../../things/Place'

import getExistingSuggestions from '../../../../things/getExistingSuggestions'

const elements =
[
    {
        type  : Form.SEARCH_SELECTOR ,
        path  : api.places.url ,
        clazz : Place ,
        disabledSuggestions : getExistingSuggestions({ member:'containedInPlace' , clazz:Place , include:true }) ,
        init  :
        {
            id         : 'value',
            key        : 'value',
            label      : 'Lieu',
            autoFocus  : false,
            fullWidth  : true,
            margin     : 'normal',
            required   : true,
            type       : 'search'
        },
        selector : { i18n :
        {
            empty       : 'Aucun lieu disponible.' ,
            title       : 'Sélectionner un lieu',
            description : 'Veuillez sélectionner un lieu ci-dessous.' ,
        }}
    }
];

const containedInPlace =
{
    add :
    {
        elements,
        agree       : 'Ajouter' ,
        disagree    : 'Annuler' ,
        description : 'Veuillez sélectionner un lieu parent en cliquant ci-dessous.' ,
        fail        : 'Veuillez corriger les erreurs ci-dessous!' ,
        loading     : 'Chargement en  cours...' ,
        success     : 'Ajout du nouveau lieu réussi!' ,
        title       : 'Ajouter un lieu parent'
    },
    remove :
    {
        agree       : 'Supprimer' ,
        description : 'Souhaitez-vous supprimer ce lieu:' ,
        disagree    : 'Annuler' ,
        loading     : 'Suppression en cours...' ,
        title       : 'Supprimer le lieu ?'
    },
    title : 'Lieux parents' ,
    tooltips :
    {
        add    : "Ajouter" ,
        edit   : "Éditer" ,
        remove : "Supprimer"
    }
};

export default containedInPlace ;