import about    from './stage/about'
import discover from './stage/discover'

const stage =
{
    about,
    discover,

    helmet :
    {
        title : 'Point d\'intérêt - {0}' ,
        metas : []
    },
    loading  : "Chargement ..." ,
    notFound : "Point d'intérêt inconnu" ,
    tabs :
    {
        about    : 'À propos',
        discover : 'À découvrir',
        info     : 'Infos',
        medias   : 'Médias'
    },
    tooltips :
    {
        back : 'Tous les Points d\'intérêts'
    }
};

export default stage ;
