import React from 'react'

import ThesaurusList from '../../display/lists/ThesaurusList'

import View from '../../display/views/View'

import api from '../../configs/api'

import initView from '../../display/views/initView'

const styles = () => ({}) ;

class Thesaurus extends View
{
    build = () =>
    (
        <ThesaurusList
            back   = { true }
            onBack = { () => this.props.history.push( api.thesaurus.url ) }
            parent = { this.element }
        />
    );

    getLocale = () => this.props.locale.views.thesaurus ;
}

Thesaurus.defaultProps =
{
    ...View.defaultProps
};

Thesaurus.propTypes =
{
    ...View.propTypes
};

export default initView( Thesaurus, styles );
